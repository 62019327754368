import React from 'react';
import PropTypes from 'prop-types';
import EmptyState from '../../../../../components/EmptyState';
import Icon from '../../../../../components/Icon';
import { Stack } from '@mui/material';

function RecoupmentDetails(props) {
  return (
    <Stack sx={{ justifyContent: 'center', width: '100%' }}>
      <EmptyState
        icon={<Icon width={60} height={60} name="ContractDelete" />}
        header="No recoupment data found."
      />
    </Stack>
  );
}

RecoupmentDetails.propTypes = {};

export default RecoupmentDetails;
