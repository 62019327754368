import React, { useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  LinearProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import usePageNav from '../../hooks/usePageNav';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCase from './AddCase';
import SearchCase from './Search';
import { DataGrid } from '@mui/x-data-grid';
import { postCaseSearch } from '../../utils/services/caseComments/apiRequests';
import FormSection from '../../components/FormSection';
import { postCaseNarrative } from '../../utils/services/caseComments/apiRequests';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import { useSelector } from 'react-redux';
import usePage from '../../hooks/usePage';

function CaseComments({
  handleCaseCommentsOpen,
  caseId,
  handleCaseCommentsClose,
  // id,
}) {
  // const { navProps } = usePage(id);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editCase, setEditCase] = useState('');
  const [loading, setLoading] = useState(false);
  const [caseSearch, setCaseSearch] = useState([]);
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const toggleComments = () => {
    setEditCase(false);
    setOpen(false);
    handleCaseCommentsOpen(false);
  };
  const handleClose = () => {
    setEditOpen(false);
  };
  const renderMemberName = (id) => {
    return houseMembers.find(
      ({ householdMemberId }) => householdMemberId == id
    );
  };
  const caseColumns = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
    },
    {
      field: 'householdMemberName',
      headerName: 'Household Member',
      flex: 1,
      renderCell: (params) => {
        const householdMemberId = params.row.householdMemberId;
        let member = renderMemberName(householdMemberId);
        if (member && member.personName) {
          const { personName } = member;
          return personName.firstName + ' ' + personName.lastName;
        } else {
          return 'All';
        }
      },
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      flex: 1,
      renderCell: (params) => {
        return params.value ? `${params.value.firstName}` : '';
      },
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 1,
      renderCell: (params) => {
        return params.value
          ? `${new Date(params.value).toLocaleDateString()} ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : '';
      },
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      flex: 1,
      renderCell: (params) => {
        return params.value
          ? `${new Date(params.value).toLocaleDateString()} ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : '';
      },
    },
    {
      field: 'narrative',
      headerName: 'Comments',
      flex: 1,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => editCaseData(params?.row)}>
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
  ];
  useEffect(() => {
    postSearch({ caseId });
  }, [caseId]);

  const createdDate = new Date(editCase.createdDate);
  const difference = !isNaN(createdDate)
    ? (new Date() - createdDate) / (1000 * 60 * 60)
    : '';

  const editCaseData = (data) => {
    setEditOpen(true);
    setEditCase(true);
    setEditCase(data);
  };
  const postNarrative = async (data) => {
    try {
      setLoading(true);
      const res = await postCaseNarrative(data, caseId);
      if (res.status === 200) {
        showSuccessToast('SuccessFully Added Case Comments');
        setCaseSearch(res.data);
        toggleComments();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const postSearch = async (data) => {
    try {
      setLoading(true);
      const res = await postCaseSearch(data, caseId);
      if (res.status === 200) {
        setCaseSearch(res.data?.narratives);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid>
      <Stack direction={'row'} mt="1.5rem" justifyContent="space-between">
        <Typography variant="h4">Case Comments</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCaseCommentsClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack direction={'row'} mt="0.5rem" ml="0.5rem" justifyContent="end">
        <Button onClick={() => setOpen(true)}>+ Add Comments</Button>
      </Stack>
      <SearchCase caseId={caseId} postSearch={postSearch} />
      <FormSection
        label="Comments History "
        mt="1.5rem"
        ml="0.5rem"
        mr="0.5rem"
        mb="0.5rem"
      >
        <DataGrid
          rows={caseSearch ?? []}
          columns={caseColumns}
          getRowId={(row) => {
            console.log('id -> ', row.narrativeId);
            return row.narrativeId;
          }}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
          }}
          slots={{
            noRowsOverlay: 'noData',
            loadingOverlay: LinearProgress,
          }}
        />
      </FormSection>

      <Dialog
        open={open}
        onClose={toggleComments}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle>Add Comments</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleComments}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <AddCase
            toggleComments={toggleComments}
            // postSearch={postSearch}
            postNarrative={postNarrative}
          />
        </DialogContent>
      </Dialog>
      {difference && (
        <Dialog
          open={editOpen}
          onClose={handleClose}
          sx={{
            '& .MuiDialog-container': {
              justifyContent: 'flex-end',
            },
          }}
        >
          <DialogTitle>Add Comments</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <AddCase
              toggleComments={toggleComments}
              handleClose={handleClose}
              editCase={editCase}
              disableFields={difference > 48}
              postNarrative={postNarrative}
            />
          </DialogContent>
        </Dialog>
      )}

      <Stack
        direction={'row'}
        spacing={1}
        justifyContent={'flex-end'}
        mt={2}
        mr={4}
        mb={2}
      >
        <Button variant="outlined" onClick={handleCaseCommentsClose}>
          Discard
        </Button>
      </Stack>
    </Grid>
  );
}

export default CaseComments;
