import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  getAssisment,
  workPlanssessment,
} from '../../../utils/services/Works/apiRequest';
import Field from '../../../components/Field';
import { boolean } from 'yup';

const WorkAssistance = ({
  getOptions,
  setOpen,
  caseId,
  changed,
  individualId,
  setChanged,
}) => {
  const [oldAssismentQsn, setOldAssismentQsn] = useState({});

  const setAssisments = async () => {
    const res = await getAssisment(caseId, individualId);
    if (res.status === 200) {
      setOldAssismentQsn(res.data);
    }
  };

  useEffect(() => {
    setAssisments();
  }, []);

  const groupedQuestions = useMemo(() => {
    return getOptions('AssesmentQuestion')?.map((obj) => {
      const indexx = oldAssismentQsn?.assesmentQuestionList?.find((inn) => {
        return parseInt(inn.qtnId) == parseInt(obj.value);
      });

      return {
        qtnComments: indexx ? indexx.qtnComments : '',
        qtnId: indexx ? indexx.qtnId : obj.value,
        qtnResult: indexx ? indexx.qtnResult : '',
        question: obj.label,
        wrkAssesmentQstnId: indexx ? indexx.wrkAssesmentQstnId : '',
        workAssesmentId: indexx ? indexx.workAssesmentId : '',
      };
    });
  }, [oldAssismentQsn]);

  const handleSubmit = async (data) => {
    try {
      const mapedData = data?.assesmentResults?.filter((obj) => {
        return obj.qtnResult;
      });

      if (mapedData.length) {
        const res = await workPlanssessment(
          caseId,
          individualId,
          mapedData,
          oldAssismentQsn
        );
        if (res.status == 200) {
          setOpen(false);
          setChanged(!changed);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const formik = useFormik({
    initialValues: {
      assesmentResults: groupedQuestions ?? [],
    },
    onSubmit: handleSubmit,
  });

  return (
    <Box
      sx={{
        padding: '1rem',
        margin: '1rem',
        borderRadius: '5px',
        border: '1px solid var(--grey-300)',
      }}
    >
      {formik?.values?.assesmentResults?.map((obj, key) => {
        return (
          <Box key={obj.qtnId} mb="0.8rem">
            <Typography variant="h6">{obj.question}</Typography>
            <Stack direction="row" spacing={2}>
              <Box
                width="15%"
                sx={{
                  border: '1px solid var(--grey-300)',
                  borderRadius: '5px',
                  alignContent: 'center',
                }}
              >
                <Field
                  formik={formik}
                  name={`assesmentResults.${[key]}.qtnResult`}
                >
                  <RadioGroup name={`assesmentResults.${[key]}.qtnResult`}>
                    <FormControlLabel
                      value="YES"
                      sx={{ alignSelf: 'center' }}
                      control={<Radio />}
                      label="Yes"
                      checked={
                        formik.values.assesmentResults[key].qtnResult === 'YES'
                      }
                    />
                    <FormControlLabel
                      value="NO"
                      sx={{ alignSelf: 'center' }}
                      control={<Radio />}
                      label="No"
                      checked={
                        formik.values.assesmentResults[key].qtnResult === 'NO'
                      }
                    />
                  </RadioGroup>
                </Field>
              </Box>
              <Field
                name={`assesmentResults.${[key]}.qtnComments`}
                formik={formik}
                sx={{ width: '80%', height: '100%' }}
              >
                <textarea
                  name={`assesmentResults.${[key]}.qtnComments`}
                  disabled={!formik.values?.assesmentResults[key].qtnResult}
                  formik={formik}
                  placeholder="Explanation"
                  style={{
                    border: '1px solid var(--grey-300)',
                    borderRadius: '5px',
                    backgroundColor: formik.values?.assesmentResults[key]
                      .qtnResult
                      ? 'white'
                      : 'var(--disablefield-100)',
                    height: '100%',
                  }}
                ></textarea>
              </Field>
            </Stack>
          </Box>
        );
      })}
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Discard
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={formik.handleSubmit}
          //   disabled={disableFields}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default WorkAssistance;
