import React, { useMemo, useState } from 'react';
import {
  Button,
  Stack,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
} from '@mui/material';
import Field from '../../components/Field';
import FormGroup from '../../components/FormGroup';
import Dropdown from '../../components/Dropdown';
import { useFormik } from 'formik';
import useLookup from '../../hooks/useLookup';
import { postCustomerSearch } from '../../utils/services/InquiryServices/requests';
import Accordion from '../../components/Accordion';
import { auto } from '@popperjs/core';
import IndividualDetailsTable from '../IndividualDetails/IndividualDetailsTable';
import useLoader from '../../hooks/useLoader';
import { maxLength, onlyAlphabet } from '../../utils/normalizers';

function CustomerSearch() {
  const [data, setData] = useState([]);
  const { getOptions, getLkpMatch, lkpRaw, lkpFlatten } = useLookup('Inquiry');
  const [showLoader, hideLoader] = useLoader();
  const formik = useFormik({
    initialValues: {
      customerId: '',
      maId: '',
      ssn: '',
      fName: '',
      mName: '',
      lName: '',
      suffix: '',
      gender: '',
      race: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      county: '',
      zipcode: '',
      soSecPart1: '',
      soSecPart2: '',
    },

    enableReinitialize: true,
    // validationSchema,
    onSubmit: (values) => {
      console.log(values);
      onCustomerSearch(values);
    },
  });
  const getRowId = (row) => {
    // return row?.sourceSystemKey;
    return row?.maId;
  };

  const columns = useMemo(
    () => [
      {
        field: 'personName.firstName',
        headerName: 'Name',
        flex: 1,
        renderCell: (params) => {
          return params.row
            ? `${params.row.personName.firstName} ${params.row.personName.lastName}`
            : '';
        },
      },
      {
        field: 'dob',
        headerName: 'DOB',
        flex: 1,
      },
      {
        field: 'gender',
        headerName: 'Gender',
        flex: 1,
        renderCell: (params) => getLkpMatch('Gender', params?.row?.gender),
      },
      {
        field: 'Race.primaryRace',
        headerName: 'Race',
        flex: 1,
        renderCell: (params) =>
          getLkpMatch('Race', params?.row?.race?.primaryRace),
      },

      {
        headerName: 'Case#',
        flex: 1,
        renderCell: (params) => {
          return params ? `${params.id}` : '';
        },
      },
    ],
    [getLkpMatch]
  );
  const { SuffixOpts, RaceOpts, CountyOpts, StateOpts, GenderOpts } =
    useMemo(() => {
      return {
        SuffixOpts: getOptions('Suffix'),
        RaceOpts: getOptions('Race'),
        CountyOpts: getOptions('County'),
        StateOpts: getOptions('State'),
        GenderOpts: getOptions('Gender'),
      };
    }, [getOptions]);

  const onCustomerSearch = async (data) => {
    try {
      showLoader();
      const res = await postCustomerSearch(data);

      if (res.status === 200) {
        console.log(res?.data?.results, 'cool');
        setData(res?.data?.results);
      }
    } catch (error) {
      console.log(error);
      // showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <Container>
      <FormGroup label="Student Status">
        <Grid container md={12} spacing={2}>
          <Grid item xs={4} sx={{ mb: '1rem' }}>
            <Field name="customerId" label="Customer ID" formik={formik}>
              <TextField placeholder="Enter Customer ID" />
            </Field>
          </Grid>

          <Grid item xs={4} sx={{ mb: '1rem' }}>
            <Field name="maId" label="MA ID" formik={formik}>
              <TextField placeholder="Enter MA ID" />
            </Field>
          </Grid>

          <Grid item xs={4} sx={{ mb: '1rem' }}>
            <Field name="ssn" label="Social Security Number" formik={formik}>
              <TextField placeholder="Enter SSN" maxLength={9} />
            </Field>
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup label="Name">
        <Grid container md={12} spacing={2}>
          <Grid item xs={3} sx={{ mb: '1rem' }}>
            <Field
              name="fName"
              label="First Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField placeholder="Enter First Name" />
            </Field>
          </Grid>

          <Grid item xs={3} sx={{ mb: '1rem' }}>
            <Field
              name="mName"
              label="Middle Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField placeholder="Enter Middle Name" />
            </Field>
          </Grid>

          <Grid item xs={3} sx={{ mb: '1rem' }}>
            <Field
              name="lName"
              label="Last Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField placeholder="Enter Last Name" />
            </Field>
          </Grid>
          <Grid item xs={3} sx={{ mb: '1rem' }}>
            <Field name="suffix" label="Suffix" formik={formik}>
              <Dropdown options={SuffixOpts} placeholder="Select Suffix" />
            </Field>
          </Grid>
        </Grid>
      </FormGroup>

      <FormGroup label="Gender & Race">
        <Grid container md={12} spacing={2}>
          <Grid item xs={6}>
            <Field name="gender" label="Gender" formik={formik}>
              <Dropdown options={GenderOpts} placeholder="Select Gender" />
            </Field>
          </Grid>
          <Grid item xs={6} sx={{ mb: '1rem' }}>
            <Field name="race" label="Race" formik={formik}>
              <Dropdown options={RaceOpts} placeholder="Select Race" />
            </Field>
          </Grid>
        </Grid>
      </FormGroup>

      <Grid container md={12}>
        <Accordion title="Advanced Search">
          <Grid container md={12} spacing={2}>
            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field name="addressLine1" label="Address Line 1" formik={formik}>
                <TextField placeholder="Enter Address Line 1" />
              </Field>
            </Grid>

            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field name="addressLine2" label="Address Line 2" formik={formik}>
                <TextField placeholder="Enter Address Line 2" />
              </Field>
            </Grid>

            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field name="city" label="City" formik={formik}>
                <TextField placeholder="Enter City" />
              </Field>
            </Grid>
            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field name="state" label="State" formik={formik}>
                <Dropdown options={StateOpts} placeholder="Select State" />
              </Field>
            </Grid>
            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field name="county" label="County" formik={formik}>
                <Dropdown options={CountyOpts} placeholder="Select County" />
              </Field>
            </Grid>
            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field name="zipcode" label="Zip Code" formik={formik}>
                <TextField placeholder="Enter Zip Code" />
              </Field>
            </Grid>
          </Grid>
        </Accordion>
      </Grid>

      <Stack
        direction={'row'}
        spacing={1}
        justifyContent={'flex-end'}
        mt={1}
        mb={2}
      >
        <Button variant="outlined" sx={{ marginRight: '1rem' }}>
          Clear
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Search
        </Button>
      </Stack>

      <IndividualDetailsTable
        columns={columns}
        rows={data}
        getRowId={getRowId}
      />
    </Container>
  );
}

export default CustomerSearch;
