export const JSON_RETRIEVE = '/eeapi/public/consumer/jsonRetrieve';
export const JSON_SAVE = '/eeapi/public/consumer/jsonSave/{0}/{1}';
export const CLEARANCE = '/eeapi/client-management/clearance';
export const INDIVIDUAL_ASSISTANCE =
  '/eeapi/client-management/{0}/individual-assistance';
export const POST_CLEARANCE = '/eeapi/client-management/new/{0}';
export const RECALL_INDIVIDUAL = '/eeapi/wp/recall/individual';
export const CONSUMER_MEMBER = '/eeapi/public/consumer/member';
export const CASECREATION_DATAVIEW =
  '/eeapi/case-management/cp_case_creation_dataview/{0}';
export const IMPORT_COMPLETION =
  '/eeapi/public/consumer/dataInsertAfterClearance/{0}';
export const CP_IMPORT_CASE = '/eeapi/case-management/{0}/cp_import_case/{1}';
