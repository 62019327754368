import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { GET_SHELTER, POST_SHELTER } from './apiUrls';
import { shelterPayload } from './apiPayload';

export const postShelter = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_SHELTER, caseId),
    shelterPayload(data, individualId)
  );
};

export const getShelter = (individualId) => {
  return HttpClient.get(format(GET_SHELTER, individualId));
};
