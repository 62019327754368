import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  clientManagementTransferDO,
  getCountiesList,
  getOfficeCounty,
  getOfficeWorkers,
} from '../../../utils/services/apiRequests';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';
import { map } from 'lodash';
import { useFormik } from 'formik';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';
import { getAuthToken } from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const AssignWorkers = ({ handleClose, open, selectedData, searchProgram }) => {
  const [showLoader, hideLoader] = useLoader();
  const [countiesList, setCountiesList] = useState([]);
  const [districtOfficeList, setDistrictOfficeList] = useState([]);
  const [workerNameList, setworkerNameList] = useState([]);
  const token = useSelector(getAuthToken);

  const validationSchema = Yup.object({
    county: Yup.string().required('Please Select the County'),
    districtOffice: Yup.string().required('Please Select the District Office'),
  });

  const formik = useFormik({
    initialValues: {
      county: '',
      districtOffice: '',
      assignedUserId: '',
    },
    validationSchema,
    onSubmit: (values) => {
      clientManagement(values);
    },
  });

  const getCounties = async () => {
    try {
      showLoader();
      const res = await getCountiesList();
      if (res.status === 200) {
        setCountiesList(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getOfficeCountyList = async (code) => {
    try {
      showLoader();
      const res = await getOfficeCounty(code);
      if (res.status === 200) {
        setDistrictOfficeList(res.data);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const getWorkers = async (code) => {
    try {
      showLoader();
      const res = await getOfficeWorkers(code);
      if (res.status === 200) {
        setworkerNameList(res.data);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useMemo(() => {
    getOfficeCountyList(formik.values.county);
  }, [formik.values.county]);

  useMemo(() => {
    getWorkers(formik.values.districtOffice);
  }, [formik.values.districtOffice]);

  const handleClear = () => {
    formik.handleReset();
    handleClose();
  };

  const clientManagement = async (value) => {
    const { county, ...rest } = value;
    const payload = {
      ...rest,
      caseIds: selectedData,
      userId: token.sub,
      sessionId: 'unknown',
    };
    try {
      showLoader();
      const res = await clientManagementTransferDO(payload);
      if (res.status === 200) {
        searchProgram();
        handleClear();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const countiesListOption = useMemo(() => {
    return map(countiesList, ({ ldssName, ldssCd }) => {
      return {
        label: ldssName,
        value: ldssCd,
      };
    });
  }, [countiesList]);

  const workerListOption = useMemo(() => {
    return map(workerNameList, ({ personName, workerId, ldssDoCd }) => {
      return {
        label: `${personName?.firstName} ${personName?.lastName}`,
        value: workerId,
      };
    });
  }, [workerNameList]);

  const districtOfficeOption = useMemo(() => {
    return map(
      districtOfficeList,
      ({ districtOfficeName, districtOfficeCode }) => {
        return {
          label: `${districtOfficeCode} ${districtOfficeName}`,
          value: districtOfficeCode,
        };
      }
    );
  }, [districtOfficeList]);

  useEffect(() => {
    getCounties();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClear}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '60rem',
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4">Assign Workers</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item xs={4}>
            <Field
              name="county"
              formik={formik}
              label="Program Status"
              required
            >
              <Dropdown
                options={countiesListOption}
                placeholder="Program Status"
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="districtOffice"
              formik={formik}
              label="District Office"
              required
            >
              <Dropdown
                options={districtOfficeOption}
                placeholder="District Office"
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="assignedUserId" formik={formik} label="Worker Name">
              <Dropdown options={workerListOption} placeholder="Worker Name" />
            </Field>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button onClick={handleClear} variant="outlined">
            Cancel
          </Button>
          <Button onClick={formik.handleSubmit} variant="contained" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default AssignWorkers;
