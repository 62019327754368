import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import Dropdown from '../../../components/Dropdown';
import FormGroup from '../../../components/FormGroup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';

function AddPreSchool({
  open,
  handleClose,
  preSchoolHealthVerificationOpts,
  onSavePreSchool,
  editData,
  AddPreSchool,
  dob,
}) {
  const validationSchema = yup.object({
    beginDate: yup.string().required('Please select the Begin Date'),
    veriffication: yup.string().required('Please select the Verification'),
  });

  const formik = useFormik({
    initialValues: {
      recordId: editData?.recordId ?? '',
      endDate: editData?.ppiEndDate ?? '',
      beginDate: editData?.ppiBeginDate ?? '',
      OTOMedicalCheckup: editData?.otoMedicalDate ?? '',
      veriffication: editData?.ppiVerification?.verificationSourceCode ?? '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (AddPreSchool) {
        onSavePreSchool(values, 'insert');
      } else {
        onSavePreSchool(values, 'update');
      }
    },
  });

  const onDateChange = (date, prop) => {
    formik.setFieldValue(prop, dayjs(date).format('YYYY-MM-DD'));
  };
  return (
    <Dialog
      onSubmit={formik.handleSubmit}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '50vw',
            height: '70vh',
          },
        },
      }}
    >
      <DialogTitle sx={{ fontSize: 20 }}>
        {AddPreSchool
          ? 'Add Pre School Health/One Time Only Medical'
          : 'Edit Pre School Health/One Time Only Medical'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form>
          <DialogContentText tabIndex={-1}>
            <Grid item xs={12}>
              <Grid>
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        required
                        name="beginDate"
                        label="Begin Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker
                          minDate={dayjs(dob)}
                          disableFuture
                          value={dayjs(formik.values.beginDate)}
                          onChange={(date) => onDateChange(date, 'beginDate')}
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="endDate"
                        label="End Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker
                          minDate={dayjs(formik.values.beginDate)}
                          disableFuture
                          requried
                          value={dayjs(formik.values.endDate)}
                          onChange={(date) => onDateChange(date, 'endDate')}
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="OTOMedicalCheckup"
                        label="OTO Medical Checkup Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker minDate={dayjs(dob)} disableFuture />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="veriffication"
                        label="Verification"
                        formik={formik}
                        required
                      >
                        <Dropdown
                          options={preSchoolHealthVerificationOpts}
                          placeholder="Select Verification"
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContentText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddPreSchool;
