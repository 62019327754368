import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.css';
import MinimizeIcon from '@mui/icons-material/Minimize';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import FullCalendar from 'fullcalendar-reactwrapper';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import HttpClient from '../../../utils/HttpClient';
import dayjs from 'dayjs';
import Field from '../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import useLoader from '../../../hooks/useLoader';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import {
  deleteHolidays,
  getHolidays,
  postHolidays,
  updateHolidays,
} from '../../../utils/services/Holidays/apiRequests';
import EditIcon from '@mui/icons-material/Edit';
import './customCal.css';

const HolidayModal = ({ holidayEvents, handleClose, year, getHolidayList }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // const currentYear = dayjs(year).format('YYYY-MM-DD');
  const localizer = momentLocalizer(moment);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setIsEditing(false);
    formik.resetForm();
  };

  const toggleMinimize = () => {
    setIsMinimized((prev) => !prev);
  };

  const validationSchema = Yup.object({
    description: Yup.string().required('Please Enter Description'),
    createdDt: Yup.date().required('Date is required'),
  });

  const formik = useFormik({
    initialValues: {
      description: '',
      createdDt: null,
    },
    validationSchema,
    onSubmit: (value) => {
      handleSave(value);
    },
  });

  const handleSave = async (data) => {
    try {
      showLoader();
      const res = data?.holidayId
        ? await updateHolidays(data)
        : await postHolidays(data);
      console.log(res);
      if (res.status === 200) {
        showSuccessToast('Successfully Added Holiday Event');
        await getHolidayList(year);
        setTimeout(() => {
          handleModalClose();
        }, 5500);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleItemClick = (index) => {
    setSelectedIndex(index);
  };

  const handleEdit = (index) => {
    const eventToEdit = holidayEvents[index];
    formik.setValues({
      description: eventToEdit.description,
      createdDt: dayjs(eventToEdit.start),
      holidayId: eventToEdit.holidayId,
    });
    setIsEditing(true);
    setOpenModal(true);
  };

  const handleDelete = async (index) => {
    const eventToDelete = holidayEvents[index];
    try {
      showLoader();
      const res = await deleteHolidays(eventToDelete.holidayId);
      if (res.status === 200) {
        showSuccessToast('Successfully Deleted Holiday Event');
        await getHolidayList(year);
        setSelectedIndex(null);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const { views } = useMemo(
    () => ({
      views: ['month', 'week', 'day'],
    }),
    []
  );

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" pb={1}>
        <Typography className={styles.modalTitle}>Add Holiday</Typography>
        <Stack direction="row" alignItems="center">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      {!isMinimized && (
        <>
          <Box
            sx={{
              border: '1px solid #6C6C6C',
              borderRadius: '10px',
              padding: '1rem',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Calendar
                  localizer={localizer}
                  events={holidayEvents}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 600 }}
                  views={views}
                  onSelectEvent={(event) => console.log(event)}
                  popup={true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Stack direction="column">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    pb={4}
                  >
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography className={styles.subTitleHoliday}>
                          Holidays
                        </Typography>
                        <span className={styles.num}>
                          {holidayEvents?.length < 10
                            ? `0${holidayEvents?.length}`
                            : holidayEvents?.length}
                        </span>
                      </Stack>

                      <Typography className={styles.day}>
                        List of Government Holidays
                      </Typography>
                    </Stack>
                    <Stack>
                      <Button
                        sx={{
                          backgroundColor: 'var(--primary-dark)',
                          color: 'white',
                          width: '4.5rem',
                          height: '2rem',
                          '&:hover': {
                            backgroundColor: 'var(--primary-dark)',
                          },
                        }}
                        onClick={handleClick}
                      >
                        <ControlPointOutlinedIcon
                          sx={{ paddingRight: '5px' }}
                        />
                        Add
                      </Button>
                    </Stack>
                  </Stack>
                  {holidayEvents &&
                    holidayEvents.map((item, index) => {
                      let months = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ];

                      const dateHoli =
                        dayjs(item?.start).date() < 10
                          ? `0${dayjs(item?.start).date()}`
                          : dayjs(item?.start).date();

                      const itemStyle = {
                        backgroundColor:
                          selectedIndex === index ? '#e6f4ff' : 'transparent',
                        borderRadius: selectedIndex === index ? '5px' : null,
                        padding: selectedIndex === index ? '10px' : '10px',
                        cursor: 'pointer',
                      };

                      return (
                        <Stack
                          direction={{ xs: 'column', sm: 'column', md: 'row' }}
                          justifyContent={{ md: 'space-between' }}
                          alignItems="center"
                          pb={1}
                          sx={itemStyle}
                          onClick={() => handleItemClick(index)}
                        >
                          <Stack alignItems="flex-start">
                            <Typography className={styles.day}>
                              {months[dayjs(item?.date).month()]}
                              <span style={{ paddingLeft: '5px' }}>
                                {dateHoli}
                              </span>
                            </Typography>
                            <Typography className={styles.eventHoliday}>
                              {item?.description}
                            </Typography>
                          </Stack>
                          {selectedIndex === index && (
                            <Stack direction="row" spacing={2}>
                              <IconButton onClick={() => handleEdit(index)}>
                                <EditIcon sx={{ color: 'var(--grey-400)' }} />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(index)}>
                                <DeleteOutlineOutlinedIcon
                                  sx={{ color: 'var(--grey-400)' }}
                                />
                              </IconButton>
                            </Stack>
                          )}
                        </Stack>
                      );
                    })}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Dialog
        open={openModal}
        onClose={handleModalClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction="row" spacing={6}>
              <Field isDate name="createdDt" formik={formik} label="Date">
                <DatePicker />
              </Field>
              <Field name="description" formik={formik} label="Description">
                <TextField
                  placeholder="Enter Holiday Description"
                  fullWidth
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '5px !important',
                    },
                  }}
                />
              </Field>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} variant="outlined">
            Discard
          </Button>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            autoFocus
            sx={{ marginRight: '1rem' }}
          >
            Save&Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HolidayModal;
