import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import styles from '../../index.module.css';
import { filter, map } from 'lodash';

export default function CPMemberInformation({
  data,
  getLkpMatch,
  handleCheckbox,
}) {
  let filteredData = filter(
    data,
    (item) => item?.clientContainer?.client?.headOfHouseholdYN !== 'Y'
  );
  return (
    <>
      {map(filteredData, (item, index) => {
        const { personName, dob, soSec, gender, maritalStatus } =
          item?.clientContainer?.client ?? {};
        const { firstName, middleName, lastName, suffix } = personName ?? {};
        const { soSecPart1, soSecPart2, soSecPart3 } = soSec ?? {};
        return (
          <Stack direction="row" pl={1}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Checkbox />}
              onChange={(event) => handleCheckbox(event, index)}
              checked={item?.importSelected}
            />
            <div className={styles.card}>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 1.5,
                      paddingBottom: '0.5rem',
                      border: 0,
                    },
                  }}
                >
                  <TableBody className={styles.tableBody}>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Name</Typography>
                      </TableCell>{' '}
                      <TableCell>
                        <Typography variant="subtitle2">{`${firstName ?? ''} ${
                          middleName ?? ''
                        } ${lastName ?? ''}`}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Suffix</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {suffix ?? ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">SSN</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">{`${
                          soSecPart1 ? '***' : ''
                        } ${soSecPart2 ? '**' : ''} ${
                          soSecPart3 ?? ''
                        }`}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">DOB</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">{dob}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Gender</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch('Gender', gender)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Marital Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch(
                            'MartialStatus',
                            maritalStatus?.marriedYN
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Stack>
        );
      })}
    </>
  );
}
