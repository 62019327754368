import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import UnearnedIncomeForm from './UnearnedIncomeForm';
import { useFormik } from 'formik';
import { saveUnearnedIncome } from '../../../utils/services/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import useLoader from '../../../hooks/useLoader';

const AddEditUnEarnedIncomeDetailsDialog = ({
  setOpen,
  open,
  isAdd,
  setIsAdd,
  lkpRaw,
  caseId,
  member,
  selectedData,
  fetchIncome,
  getLkpMatch,
}) => {
  const initialValues = {
    claimNumber: '',
    companyName: '',
    pymtEndDt: null,
    pymtStrtDt: null,
    subType: '',
    unearnedIncomeType: '',
    unearnedIncomeAmount: [
      {
        effectiveBeginDate: null,
        effectiveEndDate: null,
        expenses: [],
        amountModel: [],
        frequency: '',
        lateRptInd: 'N',
        useMonth: null,
        rptDt: null,
      },
    ],
  };

  const [showLoader, hideLoader] = useLoader();
  const [previousState, setPreviousState] = useState(null);
  const descriptionElementRef = useRef('');

  const validationSchema = Yup.object({
    subType: Yup.string().required('field is reuired'),
    unearnedIncomeType: Yup.string().required('field is required'),
    pymtStrtDt: Yup.date().required('Start Date is required'),
    pymtEndDt: Yup.date()
      .nullable()
      .min(Yup.ref('pymtStrtDt'), 'End Date must be after Start Date'),
    unearnedIncomeAmount: Yup.array().of(
      Yup.object().shape({
        effectiveBeginDate: Yup.date().required('Required'),
        effectiveEndDate: Yup.date()
          .nullable()
          .min(
            Yup.ref('effectiveBeginDate'),
            'End Date must be after Start Date'
          ),
        frequency: Yup.string().required('Required'),

        useMonth: Yup.string().when('lateRptInd', {
          is: (lateRptInd) => lateRptInd === 'Y',
          then: () => Yup.string().required('field is required'),
          otherwise: () => Yup.string().notRequired(),
        }),

        expenses: Yup.array().of(
          Yup.object().shape({
            amount: Yup.string().required('required'),
          })
        ),
      })
    ),
  });

  const customValidation = (values) => {
    let valid = true;

    // Custom validation for amountModel of unearnedIncomeAmount
    const errors = {};

    if (values.unearnedIncomeAmount) {
      errors.unearnedIncomeAmount = values.unearnedIncomeAmount.map((item) => {
        if (item.amountModel) {
          return {
            amountModel: item.amountModel.map((model, modelIndex, arr) => {
              const modelErrors = {};

              // Check if frequency inside the item object is either "BW" or "WE"
              if (item.frequency === 'BW' || item.frequency === 'WE') {
                // Skip validation for last index
                if (modelIndex !== arr.length - 1) {
                  if (!model.amount || !model.verificationSourceCode) {
                    valid = false;
                    modelErrors.amount = model.amount
                      ? undefined
                      : 'Amount is required';
                    modelErrors.verificationSourceCode =
                      model.verificationSourceCode
                        ? undefined
                        : 'Verification Source Code is required';
                  }
                }
              } else {
                // Validate all indices if frequency is not "BW" or "WE"
                if (!model.amount || !model.verificationSourceCode) {
                  valid = false;
                  modelErrors.amount = model.amount
                    ? undefined
                    : 'Amount is required';
                  modelErrors.verificationSourceCode =
                    model.verificationSourceCode
                      ? undefined
                      : 'Verification Source Code is required';
                }
              }

              return modelErrors;
            }),
          };
        }
        return {};
      });
    }

    return valid ? {} : errors; // Return empty object if all required fields are filled; otherwise, return errors
  };

  const onSubmit = async (values) => {
    try {
      showLoader();
      const res = await saveUnearnedIncome(
        caseId,
        values,
        member.individualId,
        isAdd
      );
      if (res.status === 200) {
        showSuccessToast(
          isAdd ? 'Unearned Income Added' : 'Unearned Income Updated'
        );
        fetchIncome();
        setOpen(false);
        formik.resetForm();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validate: customValidation,
    onSubmit,
  });

  useEffect(() => {
    if (!isAdd) {
      setPreviousState(selectedData);
    }
  }, [isAdd]);

  const handleDiscard = () => {
    formik.setValues({
      recordId: previousState?.recordId,
      pymtStrtDt: previousState?.pymtStrtDt ?? null,
      pymtEndDt: previousState?.pymtEndDt ?? null,
      claimNumber: previousState.claimNumber,
      companyName: previousState.companyName,
      subType: previousState.subType,
      unearnedIncomeAmount: previousState.unearnedIncomeAmount,
      unearnedIncomeType: previousState.unearnedIncomeType,
    });
  };

  const isButtonDisabled = (amountDetails) => {
    if (amountDetails) {
      return amountDetails.some((item) => {
        return isEmpty(item?.effectiveBeginDate) || isEmpty(item?.frequency);
      });
    }
  };

  const handleClose = () => {
    formik.resetForm();
    formik.setValues(initialValues);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="unEarnedIncome"
        aria-describedby="unEarnedIncomeDescription"
      >
        <DialogTitle id="unEarnedIncome">
          {isAdd ? 'Add Unearned' : 'Edit Unearned'} Income
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText
              id="unEarnedIncomeDescription"
              ref={descriptionElementRef}
            >
              <UnearnedIncomeForm
                lkpRaw={lkpRaw}
                member={member}
                isAdd={isAdd}
                formik={formik}
                selectedData={selectedData}
                getLkpMatch={getLkpMatch}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={isAdd ? handleClose : handleDiscard}
              variant="outlined"
            >
              Discard
            </Button>
            {isAdd ? (
              <Button
                type="submit"
                variant="contained"
                disabled={
                  isButtonDisabled(formik.values.unearnedIncomeAmount) ||
                  formik.values.pymtStrtDt === null ||
                  formik.values.subType === '' ||
                  formik.values.unearnedIncomeType === ''
                }
              >
                Save & Close
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disabled={!formik.dirty}
              >
                Update & Close
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddEditUnEarnedIncomeDetailsDialog;
