import React, { createRef, useEffect, useMemo, useState } from 'react';
import TabularView from '../../components/TabularView';
import SelectableView from '../../components/SelectableView';
import {
  getContactInfo,
  getHouseMembers,
} from '../../utils/services/apiRequests';
import { hideLoader, showLoader } from '../../store/slices/loaderSlice';
import { showErrorToast } from '../../utils/services/toast';
import { get } from 'lodash';
import usePageNav from '../../hooks/usePageNav';
import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';

import WaiverTable from './WaiverTable';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import PageContainer from '../../components/PageContainer';
import useLookup from '../../hooks/useLookup';
import usePage from '../../hooks/usePage';

export default function WaiverFacility({ id }) {
  const { navProps } = usePage(id);
  const selectableViewRef = createRef();
  const { caseId, navigations } = usePageNav();
  const [apiData, setApiData] = useState([]);
  const [waiverLoader, setWaiverLoader] = useState(false);
  const { houseMembers } = useSelector(selectHouseMembers);

  const { houseHoldMembers } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  }, [houseMembers]);

  const onNext = () => {
    navigations.toMedicareDetails();
  };
  const onBack = () => {
    navigations.toIncome();
  };

  return (
    <PageContainer
      title="Waiver and Facility Details"
      navProps={navProps}
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        ref={selectableViewRef}
        loading={waiverLoader}
        loadingContent={waiverLoader}
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <WaiverTable caseId={caseId} />
      </SelectableView>
    </PageContainer>
  );
}
