import React, { useMemo, useState } from 'react';
import useTabs from '../../hooks/useTabs';
import TabularView from '../../components/TabularView';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import PageContainer from '../../components/PageContainer';
import { useSelector } from 'react-redux';
import { selectEligilityResult } from '../../store/selectors/eligibilitySelector';
import { selectCaseDetails } from '../../store/selectors/caseSelector';
import {
  every,
  filter,
  fromPairs,
  includes,
  keys,
  map,
  upperCase,
} from 'lodash';
import useLookup from '../../hooks/useLookup';
import totalProgram from '../Application/Register/programs.json';
import { groupSubPrograms, numbersToDate } from '../../utils/helpers';
import usePageNav from '../../hooks/usePageNav';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import useLoader from '../../hooks/useLoader';
import { showErrorToast } from '../../utils/services/toast';
import { postConfirmation } from '../../utils/services/Confirmation/requests';
import usePage from '../../hooks/usePage';
import EmptyState from '../../components/EmptyState';
import NoRecord from '../../assets/icons/confirmation.svg';

export default function Confirmation({ id }) {
  const { navProps } = usePage(id);
  const [rowValue, setGetRowData] = useState([]);
  const [mainProgram, setMainProgram] = useState([]);
  const [open, setOpen] = useState(false);
  const caseDetails = useSelector(selectCaseDetails);
  const { getLkpMatch } = useLookup('EligibilityConfirmation');
  const { programs } = caseDetails;
  const [showLoader, hideLoader] = useLoader();
  const eligibilityData = useSelector(selectEligilityResult);
  const { eligilityResult } = eligibilityData;
  const subPrg = groupSubPrograms(totalProgram);
  const { navigations, caseId } = usePageNav();
  const selectProgram = useMemo(() => {
    if (programs) {
      return programs[0]?.programName;
    }
  }, [programs]);
  const { Tabs, Tab, onTabChange, activeTab } = useTabs(selectProgram);

  const onchangeHandler = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setGetRowData((prev) => {
        return [...prev, name];
      });
    } else {
      setMainProgram([]);
      setGetRowData((prev) => {
        return filter(rowValue, (val) => val !== name);
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Action',
        renderCell: (params) => (
          <Checkbox
            disabled={params.eligibilityStatusCd === 'PE'}
            onChange={onchangeHandler}
            name={params.programEligibilityId}
            checked={includes(rowValue, params.programEligibilityId.toString())}
          />
        ),
        width: '1rem',
      },
      {
        headerName: 'Program Name',
        getValue({ programName = {} }) {
          return `${getLkpMatch('Program', programName)}`;
        },
      },

      {
        headerName: 'Beneficiary',
        getValue({ targetFirstName, targetLastName }) {
          return `${targetFirstName} ${targetLastName}`;
        },
      },
      {
        headerName: 'Benefit Month',
        getValue({ payMonth = {} }) {
          const formattedPaymonth = dayjs(numbersToDate(payMonth)).format(
            'MM/YY'
          );

          return `${formattedPaymonth}`;
        },
      },
      {
        headerName: 'Eligibility Status ',
        getValue({ eligibilityStatusCd }) {
          return `${getLkpMatch('EligibilityStatusCode', eligibilityStatusCd)}`;
        },
      },
      {
        headerName: 'Benefit Group',
        getValue({ aidCd = '-' }) {
          return `${getLkpMatch('aidcd', aidCd)}`;
        },
        sx: {
          maxWidth: '300px',
        },
      },
      // {
      //   headerName: 'Benefit Amount',
      //   getValue({ personName = {} }) {
      //     return `${'$' + 0}`;
      //   },
      // },
      // {
      //   headerName: 'Minimum Supplement Amount',
      //   getValue({ personName = {} }) {
      //     return `${'$' + 0}`;
      //   },
      //   sx: { display: 'none' },
      // },
      {
        headerName: 'Start Date',
        getValue({ programBeginDate }) {
          return `${programBeginDate ?? '-'}`;
        },
      },
    ],
    [getLkpMatch]
  );
  // map(programs, (val) =>  ),

  const programsTabWise = useMemo(() => {
    const programsArray = map(programs, (p) => {
      const filteredPrograms = filter(eligilityResult, (value) => {
        const hasSubPrograms = includes(
          upperCase(subPrg[p.programName]),
          value.programName
        );
        if (value.programName === p.programName || hasSubPrograms) {
          return value;
        }
      });

      return [p?.programName, filteredPrograms];
    });

    return fromPairs(programsArray);
  }, [eligilityResult, programs, subPrg]);

  const curTabPrograms = useMemo(
    () => programsTabWise[activeTab],
    [programsTabWise, activeTab]
  );

  const selectedProgramsObj = useMemo(() => {
    const programsArray = map(programs, (p) => {
      const isSelected = every(programsTabWise[p.programName], (val) =>
        includes(rowValue, val.programEligibilityId.toString())
      );
      return [p?.programName, isSelected];
    });

    return fromPairs(programsArray);
  }, [rowValue, programs, programsTabWise]);

  const isCurrTabPgsSelected = useMemo(() => {
    return every(curTabPrograms, (val) =>
      includes(rowValue, val.programEligibilityId.toString())
    );
  }, [rowValue, curTabPrograms]);

  const togglerClose = () => {
    setOpen(false);
  };

  const confirmApi = async (data) => {
    // caseId
    try {
      showLoader();
      const res = await postConfirmation(data, caseId);
      if (res.status === 200) {
        navigations.toClientCorrespondence();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const onNext = () => {
    filter(
      programs,
      (value) => includes(mainProgram, value.programName) && confirmApi(value)
    );
    //
  };

  const onChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setMainProgram((prev) => {
        return [...prev, name];
      });
    } else {
      setMainProgram((prev) => {
        return filter(mainProgram, (val) => val !== name);
      });
    }
  };

  const onNextPage = () => {
    setOpen(true);
  };

  const onBack = () => {
    navigations.toEligSummary();
  };
  const isAllProgramsConfirmed =
    keys(selectedProgramsObj)?.length === mainProgram?.length;

  return (
    <PageContainer
      title="Eligibility Confirmation "
      onNext={onNextPage}
      onBack={onBack}
      // navProps={navProps}
      disableNext={!isAllProgramsConfirmed}
      // disableNext={!includes(selectedProgramsObj, true)}
    >
      <Dialog open={open} onClose={togglerClose}>
        <DialogContent>
          <Typography variant="h3">
            Are you sure that you want to confirm the benefits?
          </Typography>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="contained" color="error" onClick={togglerClose}>
              No
            </Button>
            <Button variant="contained" color="success" onClick={onNext}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Tabs
        sx={{
          '& .Mui-selected': {
            color: 'white !important',
            fontWeight: 700,
            backgroundColor: 'var(--Prim-1-N-L-Black, var(--primary-light))',
          },
          '& .MuiTabs-indicator ': {
            display: 'none',
          },
          minHeight: '64px !important',
          backgroundColor: 'white',
          color: 'var(--Prim-1-N-L-Black, var(--Dark-Grey, #6C6C6C))',
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        }}
        onChange={onTabChange}
        value={activeTab}
        variant="fullWidth"
      >
        {map(programs, (value) => (
          <Tab
            value={value.programName}
            sx={{
              textTransform: 'none',
              width: '100%',
              borderBottom: '1px solid #bababa',
              minHeight: '54px !important',
            }}
            iconPosition="start"
            label={getLkpMatch('Program', value.programName)}
          />
        ))}
      </Tabs>
      <TabularView
        component={Paper}
        columns={columns}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        data={curTabPrograms}
        renderHeader={(title) => <strong>{title}</strong>}
      />
      <Stack direction="row" justifyContent="space-between" mt={2}>
        {map(
          programs,
          (value) =>
            selectedProgramsObj[value.programName] && (
              <FormControlLabel
                sx={{ fontWeight: 700 }}
                control={
                  <Checkbox
                    onChange={onChange}
                    name={value.programName}
                    checked={includes(
                      mainProgram,
                      value.programName.toString()
                    )}
                  />
                }
                label={getLkpMatch('Program', value.programName)}
              />
            )
          //
        )}

        <Button variant="outlined" startIcon={<AddIcon />}>
          Add Auth Rep
        </Button>
      </Stack>
    </PageContainer>
  );
}
