export const roomerBoarderPayload = (data) => {
  const {
    beginDate,
    endDate,
    mealsDay,
    householdMembers,
    amountPaid,
    dirtyStatus,
    individualborderId,
  } = data;
  return {
    dirtyStatus: dirtyStatus,
    numberOfMealsPerDay: mealsDay,
    amountPaid: amountPaid,
    numberOfHouseholdMembers: householdMembers,
    brderInd: '',
    effectiveBeginDate: beginDate,
    effectiveEndDate: endDate ? endDate : null,
    individualborderId: individualborderId ?? '',
  };
};

export const boraderDeletePayload = (data) => {
  const {
    effectiveBeginDate,
    effectiveEndDate,
    numberOfMealsPerDay,
    numberOfHouseholdMembers,
    amountPaid,
    dirtyStatus,
    individualborderId,
    groupNumber,
    individualId,
  } = data;
  return {
    individualborderId: individualborderId,
    individualId: individualId,
    numberOfMealsPerDay: numberOfMealsPerDay,
    amountPaid: amountPaid,
    numberOfHouseholdMembers: numberOfHouseholdMembers,
    brderInd: 'Y',
    deleteInd: 'N',
    effectiveBeginDate: effectiveBeginDate,
    effectiveEndDate: effectiveEndDate,
    groupNumber: groupNumber,
    dirtyStatus: dirtyStatus,
  };
};
