import dayjs from 'dayjs';

export const holidayPayload = (data) => {
  const { description, createdDt } = data;
  return {
    deleteInd: 'N',
    holidayId: data?.holidayId ?? null,
    date: createdDt,
    description: description,
    year: dayjs(createdDt).year(),
  };
};
