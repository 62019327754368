import React, { useState, useMemo } from 'react';
import { Button, Stack, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormGroup from '../../components/FormGroup';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { getSearchData } from '../../utils/services/correspondence/apiRequest';
import dayjs from 'dayjs';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import useLoader from '../../hooks/useLoader';
import { useParams, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usePageNav from '../../hooks/usePageNav';

const selectRangeOpts = [
  {
    label: '1 month',
    value: '1',
  },
  {
    label: '3 months',
    value: '3',
  },
  {
    label: '6 months',
    value: '6',
  },

  {
    label: '9 months',
    value: '9',
  },
  {
    label: '12 months',
    value: '12',
  },
];

const SearchMode = ({ activeTab, setSearchData }) => {
  const { caseId } = usePageNav();
  const navigate = useNavigate();
  const [showLoader, hideLoader] = useLoader();

  const validationSchema = Yup.object({
    caseId: Yup.string().required('Case Id is required'),
    dateRange: Yup.string().required('Date range is required'),
    fromDt: Yup.date().required('From Date is required'),
    toDt: Yup.date().required('To Date is required'),
  });

  const validationSchemaCaseId = Yup.object({
    caseId: Yup.string().required('Case Id is required'),
  });

  const formik = useFormik({
    initialValues: {
      caseId: caseId ?? '',
      customerId: '',
      dateRange: '',
      fromDt: '',
      toDt: '',
    },
    enableReinitialize: true,
    validationSchema:
      activeTab === 1 ? validationSchema : validationSchemaCaseId,
    onSubmit: async (values) => {
      const updateValues = {
        caseId: values.caseId,
        customerId: values.customerId,
        dateRange: activeTab === 1 ? values.dateRange : '',
        fromDt: activeTab === 1 ? values.fromDt : '',
        toDt: activeTab === 1 ? values.toDt : '',
      };
      navigate(`/dashboard/caseInquiry/correspondenceSearch/${values.caseId}`);
      dateRangeSearchData(updateValues);
    },
  });

  const getFormattedDate = (monthsAgo) => {
    return dayjs().subtract(monthsAgo, 'month').format('YYYY-MM-DD');
  };

  // const dateRangeFrom = useMemo(() => {
  //   if (formik.values.dateRange) {
  //     const from = getFormattedDate(formik.values.dateRange);
  //     formik.setFieldValue('fromDt', dayjs(from).format('YYYY-MM-DD'));
  //     formik.setFieldValue('toDt', dayjs().format('YYYY-MM-DD'));
  //   }
  // }, [formik.values.dateRange]);

  const onChangeDropdown = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
    const from = getFormattedDate(value);
    if (from) {
      formik.setFieldValue('fromDt', dayjs(from).format('YYYY-MM-DD'));
      formik.setFieldValue('toDt', dayjs().format('YYYY-MM-DD'));
    }
  };

  const dateRangeSearchData = async (values) => {
    const { fromDt, toDt, caseId } = values;
    let paramsUrl = `?fromDate=${fromDt}&currentDate=${toDt}&canShowManSup=${
      activeTab === 2 ? 'Y' : 'N'
    }`;
    try {
      showLoader();
      const res = await getSearchData(caseId, paramsUrl);
      if (res.status === 200) {
        setSearchData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleClear = () => {
    formik.resetForm();
    formik.setFieldValue('caseId', '');
  };

  return (
    <FormGroup label="Search Mode">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field name="caseId" label="Case ID" formik={formik} required>
            <TextField placeholder="Enter Case Id" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="customerId" label="Customer ID" formik={formik}>
            <TextField placeholder="Enter Customer Id" />
          </Field>
        </Grid>
      </Grid>
      {activeTab === 1 && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={4}>
            {/* <Field name="dateRange" label="Date Range" required formik={formik}>
              <Dropdown options={selectRangeOpts} placeholder="Select range" />
            </Field> */}
            <Field
              name="dateRange"
              label="Date Range"
              required
              formik={formik}
              customHandler
            >
              <Dropdown
                name="dateRange"
                options={selectRangeOpts}
                placeholder="Select range"
                value={formik.values.dateRange}
                onChange={onChangeDropdown}
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field required name="fromDt" label="From" isDate formik={formik}>
              <DatePicker disableFuture value={dayjs(formik.values.fromDt)} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="toDt" label="To" required isDate formik={formik}>
              <DatePicker
                disableFuture
                value={dayjs(formik.values.toDt)}
                minDate={dayjs(formik.values.fromDt)}
              />
            </Field>
          </Grid>
        </Grid>
      )}

      <Stack
        direction="row"
        spacing={2}
        mt={3}
        sx={{ justifyContent: 'flex-end' }}
      >
        <Button variant="outlined" onClick={handleClear}>
          Clear
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Search
        </Button>
      </Stack>
    </FormGroup>
  );
};

export default SearchMode;
