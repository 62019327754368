import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.auth;

export const getAuthToken = createSelector(getState, (state) => state.token);

export const selectUniqueId = createSelector(
  getState,
  (state) => state.token?.sub
);
