import React, { useMemo, useState } from 'react';

import {
  Button,
  Stack,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
  Box,
  Container,
} from '@mui/material';
import Field from '../../components/Field';
import FormGroup from '../../components/FormGroup';
import Dropdown from '../../components/Dropdown';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from 'formik';
import useLookup from '../../hooks/useLookup';
import { postProviderSearch } from '../../utils/services/InquiryServices/requests';
import IndividualDetailsTable from '../IndividualDetails/IndividualDetailsTable';
import ActionButton from '../../components/ActionButtons';
import useLoader from '../../hooks/useLoader';

function ProviderSearch({ TypeOpts, StateOpts, CountyOpts }) {
  const [data, setData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const onProviderSearch = async (data) => {
    try {
      showLoader();
      const res = await postProviderSearch(data);

      if (res.status === 200) {
        setData(res?.data?.results);
      }
    } catch (error) {
      console.log(error);
      // showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const formik = useFormik({
    initialValues: {
      vendorId: '',
      vendorName: '',
      vendorCity: '',
      vendorState: '',
      vendorTypeCd: '',
      vendorZipcode: '',
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (values) => {
      console.log(values, 'submit');
      onProviderSearch(values);
    },
  });

  const columns = useMemo(
    () => [
      {
        field: 'vendorId',
        headerName: 'ID',
        flex: 1,
      },
      {
        field: 'vendorName',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'vendorTypeCd',
        headerName: 'Type',
        flex: 1,
      },
      {
        field: 'vendorZipcode',
        headerName: 'ZIP Code',
        flex: 1,
      },
      {
        field: 'vendorCity',
        headerName: 'City',
        flex: 1,
      },
      {
        field: 'statusCode',
        headerName: 'Status',
        flex: 1,
      },
    ],
    []
  );

  const getRowId = (row) => {
    return row.vendorId;
  };

  //   // Add other fields as needed
  // }));

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mb: '1rem' }}>
          <Field name="vendorId" label="Identity Info" formik={formik}>
            <TextField placeholder="Enter Customer ID" />
          </Field>
        </Grid>

        <Grid item xs={6} sx={{ mb: '1rem' }}>
          <Field name="vendorName" label="Name" formik={formik}>
            <TextField placeholder="Enter Name" />
          </Field>
        </Grid>

        <Grid item xs={6} sx={{ mb: '1rem' }}>
          <Field name="vendorTypeCd" label="Type" formik={formik}>
            <Dropdown options={TypeOpts} placeholder="Select Type" />
          </Field>
        </Grid>
        <Grid item xs={6} sx={{ mb: '1rem' }}>
          <Field name="vendorCity" label="City" formik={formik}>
            <TextField placeholder="Enter City" />
          </Field>
        </Grid>
        <Grid item xs={6} sx={{ mb: '1rem' }}>
          <Field name="vendorState" label="State" formik={formik}>
            <Dropdown options={StateOpts} placeholder="Select State" />
          </Field>
        </Grid>
        <Grid item xs={6} sx={{ mb: '1rem' }}>
          <Field name="vendorZipcode" label="ZipCode" formik={formik}>
            <TextField placeholder="Enter ZipCode" />
          </Field>
        </Grid>
        <Grid item xs={12} sx={{ mb: '1rem', textAlign: 'right' }}>
          <Button variant="outlined">Clear</Button>
          <Button
            sx={{ marginLeft: '1rem' }}
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Search
          </Button>
        </Grid>
      </Grid>

      {data.length > 0 && (
        <Grid container>
          <Grid item md={12}>
            <IndividualDetailsTable
              columns={columns}
              rows={data}
              getRowId={getRowId}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default ProviderSearch;
