import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import WorkModal from '../WorkModal';
import ActionButtons from '../../../components/ActionButtons';
import usePageNav from '../../../hooks/usePageNav';
import {
  getWork,
  postWork,
} from '../../../utils/services/WorkExemption/apiRequests';
import useLoader from '../../../hooks/useLoader';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLookup from '../../../hooks/useLookup';
import dayjs from 'dayjs';
import { getCaseDetails } from '../../../utils/services/apiRequests';
import DeleteConfiramation from '../DeleteConfiramation';
import { map } from 'lodash';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/working.svg';

export default function WorkDetails({ selected }) {
  const { individualId } = selected;
  const { caseId } = usePageNav();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [workData, setWorkData] = useState([]);
  const [workEdit, setWorkEdit] = useState('');
  const [showLoader, hideLoader] = useLoader();
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { getLkpMatch, getOptions } = useLookup('WorkExemption');

  const { verifyOpts } = useMemo(() => {
    return {
      verifyOpts: getOptions('YesNo'),
    };
  }, [getOptions]);

  const toggleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setWorkEdit(false);
  };

  const workEditData = (data) => {
    setEditOpen(true);
    setWorkEdit(data);
  };
  const columns = [
    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      width: 140,
      renderCell: (params) => {
        return dayjs(params?.row?.effectiveBeginDate).format('YYYY-MM-DD');
      },
    },
    {
      field: 'effectiveEndDate',
      headerName: 'End Date',
      width: 140,
      renderCell: (params) => {
        const endDate =
          params?.row?.effectiveEndDate === '9999-12-31T00:00:00+05:30'
            ? '-'
            : params?.row?.effectiveEndDate;
        return endDate;
      },
    },

    {
      field: 'programCd',
      headerName: 'Program',
      width: 130,
      renderCell: (params) => {
        return getLkpMatch('Program', params?.row?.programCd);
      },
    },
    {
      field: 'exemptionCode',
      headerName: 'Exemptions',
      width: 140,
      renderCell: (params) => {
        return getLkpMatch('FSExemptReason', params?.row?.exemptionCode);
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => workEditData(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (individualId) {
      getWorkDet();
    }
  }, [individualId]);

  const getWorkDet = async () => {
    try {
      showLoader();
      const res = await getWork(caseId, individualId);
      if (res.status === 200) {
        setWorkData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const deletePopup = async (rowData) => {
    setSelectedData(rowData);
    setOpenDeleteDialog(true);
  };

  const handleDelete = () => {
    deleteWorkExem();
  };

  const deleteWorkExem = async () => {
    const payload = {
      ...selectedData,
      dirtyStatus: 'delete',
    };
    try {
      showLoader();
      const res = await postWork(payload, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('Successfully deleted the Roomer Boarder Details');
        getWorkDet();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid mt={2}>
        <Stack direction="row" mb={1} justifyContent="end">
          <Button onClick={() => setOpen(true)}>+ Add Work Exemption</Button>
        </Stack>
        <DataGrid
          rows={workData ?? {}}
          columns={columns}
          getRowId={(row) => row?.worksExemptionId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '50vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '50vh',
            },
            minHeight: '50vh',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                description="Pleace click Add Work Exemption button to add"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
        />
      </Grid>
      <Dialog maxWidth={'md'} open={open} onClose={toggleClose}>
        <IconButton
          aria-label="close"
          onClick={toggleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <WorkModal
            toggleClose={toggleClose}
            caseId={caseId}
            createWork
            individualId={individualId}
            getWorkDet={getWorkDet}
            getOptions={getOptions}
            getLkpMatch={getLkpMatch}
            verifyOpts={verifyOpts}
          />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth={'md'} open={editOpen} onClose={toggleClose}>
        <DialogTitle>Add Work Exemption Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <WorkModal
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            workEdit={workEdit}
            getWorkDet={getWorkDet}
            getOptions={getOptions}
            getLkpMatch={getLkpMatch}
            verifyOpts={verifyOpts}
          />
        </DialogContent>
      </Dialog>
      <DeleteConfiramation
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        handleDelete={handleDelete}
      />
    </>
  );
}
