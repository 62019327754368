import React, { useState, useRef, createRef } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  Box,
  Stack,
  Tooltip,
  Dialog,
  Skeleton,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ArrowRight from '@mui/icons-material/ArrowRight';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import useCaseStatus from '../../../hooks/useCaseStatus';
import styles from './index.module.css';
import { useFormik } from 'formik';
import usePageNav from '../../../hooks/usePageNav';
import { useSelector } from 'react-redux';
import { selectCaseDetails } from '../../../store/selectors/caseSelector';
import { AttachMoney } from '@mui/icons-material';
import { isEmpty, map } from 'lodash';
import { getLastFiveCasesReq } from '../../../utils/services/case/apiRequests';
import { showErrorToast } from '../../../utils/services/toast';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionIcon from '@mui/icons-material/Description';
import CaseComments from '../../CaseComments';
import { useParams } from 'react-router';
import { normalizeFormField } from '../../../utils/formUtils';
import { alphaNumeric, maxLength, number } from '../../../utils/normalizers';

function CaseBar({ downloadScreenshot }) {
  const navigate = useNavigate();
  const caseDetails = useSelector(selectCaseDetails);
  const isInqueryFlow = !isEmpty(caseDetails);
  const showDeterminElg = caseDetails?.caseStatusCd !== 'IP' && isInqueryFlow;
  const [open, setOpen] = useState(false);
  const [lastFiveCases, setLastFiveCases] = useState([]);
  const [fetchingLast5Cases, setFetchingLast5Cases] = useState(false);
  const [showRecentCases, setShowRecentCases] = useState(false);

  const { caseId, navigations, isCaseHome } = usePageNav();
  const { getCaseStatus } = useCaseStatus();
  const [showBpMenu, toggleBpMenu] = useState(false); // Benefit process menu
  const [showCmMenu, toggleCmMenu] = useState(false); // Case management menu
  const [showInterfaceMenu, toggleInterfaceMenu] = useState(false); // Interface menu

  const params = useParams();

  const bpButton = useRef();
  const cmButton = useRef();
  const interfaceButton = useRef();
  const recentCasesRef = useRef();

  const handleBpMenu = () => {
    toggleBpMenu(!showBpMenu);
  };

  const handleCmMenu = () => {
    toggleCmMenu(!showCmMenu);
  };

  const handleInterfaceMenu = () => {
    toggleInterfaceMenu(!showInterfaceMenu);
  };

  const validationSchema = Yup.object({
    caseId: Yup.string()
      .max(20, 'Please enter valid case id')
      .required('Please enter case id'),
  });

  const formik = useFormik({
    initialValues: {
      caseId: '',
    },
    validationSchema,
    onSubmit: ({ caseId }) => {
      getCaseStatus(caseId);
    },
  });

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const fetchLastFiveCases = async () => {
    try {
      setFetchingLast5Cases(true);
      const res = await getLastFiveCasesReq();

      if (res.status === 200) {
        setLastFiveCases(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetchingLast5Cases(false);
    }
  };

  const openRecentCases = () => {
    setShowRecentCases(true);
    fetchLastFiveCases();
  };

  const closeRecentCases = () => {
    setShowRecentCases(false);
  };
  const handleCaseCommentsOpen = () => {
    setOpen(true);
  };

  const handleCaseCommentsClose = () => {
    setOpen(false);
  };

  const handleSettings = () => {
    navigations.toSettings();
  };

  return (
    <div className={styles.caseBar}>
      <Dialog maxWidth="xxl" open={open} onClose={handleCaseCommentsClose}>
        <CaseComments
          handleCaseCommentsOpen={handleCaseCommentsOpen}
          handleCaseCommentsClose={handleCaseCommentsClose}
          caseId={caseId}
        />
      </Dialog>

      <Button
        ref={bpButton}
        variant="text"
        endIcon={<ArrowDropDownIcon sx={{ marginLeft: '-10px' }} />}
        onClick={handleBpMenu}
        sx={{ border: 'none', color: '#ffffff', whiteSpace: 'nowrap' }}
      >
        Benefit Process
      </Button>
      <Menu
        anchorEl={bpButton.current}
        open={showBpMenu}
        onClose={handleBpMenu}
        MenuListProps={{
          'aria-labelledby': 'benefit-process-menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => navigations.toInquiry()}>Inquiry</MenuItem>
        <MenuItem onClick={() => navigations.toInitiate()}>
          Application Registration
        </MenuItem>
      </Menu>
      <Button
        ref={cmButton}
        variant="text"
        endIcon={<ArrowDropDownIcon sx={{ marginLeft: '-10px' }} />}
        sx={{ border: 'none', color: '#ffffff', whiteSpace: 'nowrap' }}
        onClick={handleCmMenu}
      >
        Case Management
      </Button>
      <Menu
        anchorEl={cmButton.current}
        open={showCmMenu}
        onClose={handleCmMenu}
        MenuListProps={{
          'aria-labelledby': 'case-management-menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => navigations.toAllCaseComments()}>
          Case Comments
        </MenuItem>
        <MenuItem component={Link} to={`/dashboard/redetermination`}>
          Recertification
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/dashboard/caseInquiry/correspondenceSearch/${
            params.caseId ? params.caseId : ''
          }`}
        >
          Notice & Letters
        </MenuItem>
        <MenuItem>Auto Renewal - ExParte</MenuItem>
        <MenuItem>Periodic Reporting</MenuItem>
      </Menu>
      <Button
        ref={interfaceButton}
        variant="text"
        endIcon={<ArrowDropDownIcon sx={{ marginLeft: '-10px' }} />}
        sx={{ border: 'none', color: '#ffffff' }}
        onClick={handleInterfaceMenu}
      >
        Interfaces
      </Button>
      <Menu
        anchorEl={interfaceButton.current}
        open={showInterfaceMenu}
        onClose={handleInterfaceMenu}
        MenuListProps={{
          'aria-labelledby': 'interfaces-menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem>SSA Search</MenuItem>
        <MenuItem>Accretion and Deletion Report</MenuItem>
        <MenuItem>SVES/SOLQi Request</MenuItem>
        <MenuItem>Income & Asset Verification</MenuItem>
        <MenuItem>Prison DOC</MenuItem>
        <MenuItem>Lottery Match</MenuItem>
        <MenuItem>Death MVS</MenuItem>
        <MenuItem>PARIS</MenuItem>
        <MenuItem
          onClick={handleInterfaceMenu}
          aria-haspopup="true"
          aria-expanded={showInterfaceMenu ? 'true' : undefined}
        >
          Federal Report
          <ArrowRight />
        </MenuItem>
      </Menu>
      <Button
        variant="text"
        sx={{ border: 'none', color: '#ffffff' }}
        component={Link}
        to={`/dashboard/caseInquiry/issuance`}
      >
        Issuance
      </Button>
      <Button
        ref={interfaceButton}
        variant="text"
        sx={{ border: 'none', color: '#ffffff' }}
        onClick={handleSettings}
      >
        Settings
      </Button>
      <Box className={styles.rightContainer}>
        <TextField
          name="caseId"
          fullWidth={false}
          autoComplete="off"
          size="small"
          placeholder="Go to case"
          inputProps={{ maxLength: 20 }}
          onKeyDown={onEnter}
          sx={{
            bgcolor: 'white',
            borderRadius: '2rem',
            '& fieldset': { border: 'none' },
            flexGrow: 1,
            px: '0.5rem',
            ' input': { padding: '0.2rem' },
          }}
          onChange={(e) => {
            e.target.value = normalizeFormField(e.target.value, [
              alphaNumeric,
              maxLength(20),
            ]);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
        />
        <IconButton
          onClick={formik.handleSubmit}
          //   onClick={getCase}
          aria-label="Search"
          size="small"
          sx={{ color: '#ffffff' }}
        >
          <SearchIcon />
        </IconButton>
        {/* <IconButton aria-label="Help" sx={{ color: '#ffffff' }}>
          <HelpIcon />
        </IconButton>
        {isInqueryFlow && (
          <Tooltip title="Case home">
            <IconButton
              disabled={isCaseHome}
              aria-label="Case home"
              sx={{ color: '#ffffff' }}
            >
              <HomeIcon onClick={() => navigations.toCaseHome()} />
            </IconButton>
          </Tooltip>
        )} */}

        {/* {isInqueryFlow && (
          <Tooltip title="Case Comments">
            <IconButton
              aria-label="Case Comments"
              sx={{ color: '#ffffff' }}
              onClick={handleCaseCommentsOpen}
            >
              <DescriptionIcon
              // caseId={caseId}
              />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Recent five cases">
          <IconButton
            onClick={openRecentCases}
            aria-label="Recent cases"
            sx={{ color: '#ffffff' }}
            ref={recentCasesRef}
          >
            <StarIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={recentCasesRef.current}
          open={showRecentCases}
          onClose={closeRecentCases}
          // onClick={noop}
          MenuListProps={{
            'aria-labelledby': 'recent-cases',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {fetchingLast5Cases ? (
            <Stack sx={{ p: '0.5rem 1rem', width: '8rem' }} spacing={1}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          ) : (
            map(lastFiveCases, (caseId) => (
              <MenuItem onClick={closeRecentCases}>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.activeLink : ''
                  }
                  to={`/dashboard/caseInquiry/caseHome/${caseId}`}
                >
                  {caseId}
                </NavLink>
              </MenuItem>
            ))
          )}
        </Menu> */}
        {/* {showDeterminElg && (
          <Tooltip title="Determine Eligibility">
            <IconButton
              onClick={() => navigations.toEligSummary()}
              aria-label="Star"
              sx={{ color: '#ffffff' }}
            >
              <AttachMoney />
            </IconButton>
          </Tooltip>
        )} */}
      </Box>
      <Tooltip title="Screenshort" placement="bottom">
        <IconButton
          aria-label="Camera"
          sx={{ color: '#ffffff' }}
          onClick={downloadScreenshot}
        >
          <PhotoCameraIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

CaseBar.propTypes = {};

export default CaseBar;
