import { getEducationDetails } from '../../../utils/services/educationDetailsServices/requests';
import usePageNav from '../../../hooks/usePageNav';
import { useEffect, useMemo, useState } from 'react';
import IndividualDetailsTable from '../../IndividualDetails/IndividualDetailsTable';
import ActionButton from '../../../components/ActionButtons';
import { Button, Stack, Box } from '@mui/material';
import AddEducation from './AddEducation';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../utils/services/toast';
import { postEducationDetails } from '../../../utils/services/educationDetailsServices/requests';
import DeleteModal from '../../IndividualDetails/DeleteModal';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import useLoader from '../../../hooks/useLoader';

function EduDetails({ selected, getOptions, getLkpMatch, lkpRaw }) {
  const [educationData, setEducationData] = useState([]);
  const { individualId } = selected;
  const { caseId } = usePageNav();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const [showLoader, hideLoader] = useLoader();

  useEffect(() => {
    if (individualId) {
      educationDetails();
    }
  }, [individualId]);

  const getRowId = (row) => {
    return row.recordId;
  };

  const educationDetails = async () => {
    try {
      const res = await getEducationDetails(caseId, individualId);
      if (res.status === 200) {
        setEducationData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (params) => {
    setDeleteData(params.row);
    setOpenDeleteModal(true);
  };

  const handleEdit = (params) => {
    setEditData(params.row);
    setOpenEditModal(true);
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  const {
    StudentStatusCodeOpts,
    StudentVerificationOpts,
    EducationalLevelOpts,
    HighestGradeOpts,
    GradVerificationOpts,
    GoodStandingOpts,
    GoodStandingVerificationOpts,
    StateOpts,
  } = useMemo(() => {
    return {
      StudentStatusCodeOpts: getOptions('StudentStatusCode'),
      StudentVerificationOpts: getOptions('CLSTDNTSTSVERCD'),
      EducationalLevelOpts: getOptions('EducationalLevel'),
      HighestGradeOpts: getOptions('HighestGrade'),
      GradVerificationOpts: getOptions('GRADDATEVERDCODES'),
      GoodStandingOpts: getOptions('GoodStandingSchoolAttendance'),
      GoodStandingVerificationOpts: getOptions('GOODSTUDENTVERCD'),
      StateOpts: getOptions('State'),
    };
  }, [getOptions]);

  const handleClose = () => setOpen(false);
  const columns = useMemo(
    () => [
      {
        field: 'effectiveBeginDate',
        headerName: 'Begin Date',
        width: 150,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        width: 140,
        renderCell: (params) => {
          const endDate =
            params?.row?.effectiveEndDate === '9999-12-31'
              ? ''
              : params?.row?.effectiveEndDate;
          return endDate;
        },
      },

      {
        field: 'studentStatus',
        headerName: 'Student Status',
        renderCell: (params) =>
          getLkpMatch('StudentStatusCode', params?.row?.studentStatus),
        width: 170,
      },

      {
        field: 'educationLevel',
        headerName: 'Educational Level',
        renderCell: (params) =>
          getLkpMatch('EducationalLevel', params?.row?.educationLevel),
        width: 200,
        sx: {
          color: 'red',
        },
      },

      {
        field: 'educationPlan',
        headerName: 'Educational plan',
        valueGetter: (params) => {
          return params?.row?.educationPlan ?? 'N/A';
        },
        width: 200,
      },

      {
        renderCell(params) {
          return (
            <span>
              <ActionButton
                onEdit={() => handleEdit(params)}
                onDelete={() => handleDelete(params)}
              />
            </span>
          );
        },
        headerName: 'Action',
        width: 100,
      },
    ],
    [getLkpMatch]
  );

  const onSaveEducation = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postEducationDetails(
        data,
        caseId,
        individualId,
        dirtyStatus
      );

      if (res.status === 200) {
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added New Education');
          handleClose();
        } else if (dirtyStatus === 'update') {
          showSuccessToast('Successfully Updated The Education');
          setOpenEditModal(false);
        } else {
          showSuccessToast('Successfully Deleted The Education');
          setOpenDeleteModal(false);
        }
        educationDetails();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <div>
      <TabPanel value={activeTab} index={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: '1rem', mt: '1rem' }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload files
          </Button>

          <Button id="addEducation" onClick={() => setOpen(true)}>
            + Add Education Details
          </Button>
        </Stack>
      </TabPanel>

      <IndividualDetailsTable
        columns={columns}
        rows={educationData}
        getRowId={getRowId}
      />

      <AddEducation
        open={open}
        handleClose={handleClose}
        StudentStatusCodeOpts={StudentStatusCodeOpts}
        StudentVerificationOpts={StudentVerificationOpts}
        EducationalLevelOpts={EducationalLevelOpts}
        HighestGradeOpts={HighestGradeOpts}
        GradVerificationOpts={GradVerificationOpts}
        GoodStandingOpts={GoodStandingOpts}
        GoodStandingVerificationOpts={GoodStandingVerificationOpts}
        StateOpts={StateOpts}
        onSaveEducation={onSaveEducation}
      />
      <AddEducation
        editEducation
        editData={editData}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        StudentStatusCodeOpts={StudentStatusCodeOpts}
        StudentVerificationOpts={StudentVerificationOpts}
        EducationalLevelOpts={EducationalLevelOpts}
        HighestGradeOpts={HighestGradeOpts}
        GradVerificationOpts={GradVerificationOpts}
        GoodStandingOpts={GoodStandingOpts}
        GoodStandingVerificationOpts={GoodStandingVerificationOpts}
        StateOpts={StateOpts}
        onSaveEducation={onSaveEducation}
      />

      <DeleteModal
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        deleteData={deleteData}
        fn={onSaveEducation}
      />

      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}

export default EduDetails;
