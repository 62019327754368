import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import Medicare from '../Medicare';
import ActionButtons from '../../../components/ActionButtons';
import Insurance from '../InsurancePolicy';
import InsuranceDetails from '../InsuranceDetailsModal';
import usePageNav from '../../../hooks/usePageNav';
import {
  getInsurance,
  healthInsurance,
  editDelMed,
} from '../../../utils/services/MedicareDetails/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';
import useLookup from '../../../hooks/useLookup';
import DeleteConfiramation from '../DeleteConfiramation';
import NoRecord from '../../../assets/icons/Insurance-pana.svg';
import NoRecordinHealth from '../../../assets/icons/health-professional-team.svg';
import EmptyState from '../../../components/EmptyState';

function HealthDisability({ selected }) {
  const { individualId } = selected;
  const [showLoader, hideLoader] = useLoader();
  const { caseId, navigations } = usePageNav();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [editInsurance, setEditInsurance] = useState(false);
  const [editMedicare, setEditMedicare] = useState(false);
  const [medOpen, setMedOpen] = useState(false);
  const [insurancePolicyData, setInsurancePolicyData] = useState([]);
  const [medicareData, setMedicareData] = useState([]);
  const [insuranceEdit, setInsuranceEdit] = useState('');
  const [medicareEdit, setMedicareEdit] = useState('');
  const [insuranceData, setInsuranceData] = useState([]);
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { getOptions, getLkpMatch } = useLookup('MediCare');
  const { houseHoldMembers, hasHoh } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  });
  useEffect(() => {
    if (individualId) {
      getInsurancePolicy();
    }
  }, [individualId]);

  const getInsurancePolicy = async () => {
    try {
      showLoader();
      const res = await getInsurance(caseId, individualId);
      if (res.status === 200) {
        setInsurancePolicyData(res.data.insurancePolicies);
        setMedicareData(res.data.medicare);
        setInsuranceData(res.data.insuranceInformation);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const columns = [
    {
      field: 'carrierName',
      headerName: 'Carrier Name',
      width: 150,
    },
    {
      field: 'policyNumber',
      headerName: 'Policy Number',
      width: 160,
    },
    {
      field: 'groupNumber',
      headerName: 'Group Number',
      width: 160,
    },
    {
      field: 'policySource',
      headerName: 'Policy Source',
      width: 170,
      renderCell: (params) =>
        getLkpMatch('PolicySource', params?.row?.policySource),
    },

    {
      field: 'policyHolderName',
      headerName: 'Policy Holder Name',
      width: 220,
      renderCell: (params) => (
        'PolicyHolderName',
        params?.row.policyHolderName?.firstName +
          ' ' +
          params?.row?.policyHolderName?.lastName
      ),
    },
    {
      field: 'relationship',
      headerName: 'Relationship',
      width: 140,
      renderCell: (params) => {
        const relationshipCd = params?.row?.relationshipCd;
        return relationshipCd
          ? getLkpMatch('RelationshipType', relationshipCd)
          : 'N/A';
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => insuranceEditData(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];
  const medicareEditData = (data) => {
    setEditMedicare(true);
    setMedicareEdit(data);
  };
  const insuranceEditData = (data) => {
    setEditInsurance(true);
    setInsuranceEdit(data);
  };

  const medColumns = [
    {
      field: 'medicareStartDate',
      headerName: 'Medicare Start Date',
      width: 210,
    },
    {
      field: 'medicareTypeA',
      headerName: 'Medicare Type A',
      width: 180,
      renderCell: (params) => {
        const medicareTypeA = params?.row?.medicareTypeA;
        return medicareTypeA ? getLkpMatch('YesNo', medicareTypeA) : 'No';
      },
    },

    {
      field: 'medicareTypeB',
      headerName: 'Medicare Type B',
      width: 180,
      renderCell: (params) => {
        const medicareTypeB = params?.row?.medicareTypeB;
        return medicareTypeB ? getLkpMatch('YesNo', medicareTypeB) : 'No';
      },
    },
    {
      field: 'medicareTypeC',
      headerName: 'Medicare Type C',
      width: 180,
      renderCell: (params) => {
        const medicareTypeC = params?.row?.medicareTypeC;
        return medicareTypeC ? getLkpMatch('YesNo', medicareTypeC) : 'No';
      },
    },
    {
      field: 'medicareTypeD',
      headerName: 'Medicare Type D',
      width: 180,
      renderCell: (params) => {
        const medicareTypeD = params?.row?.medicareTypeD;
        return medicareTypeD ? getLkpMatch('YesNo', medicareTypeD) : 'No';
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => medicareEditData(params?.row)}
              onDelete={() => deletePopupMedicare(params?.row)}
            />
          </div>
        );
      },
    },
  ];

  const toggleInsurance = () => {
    setOpen(false);
    setEditInsurance(false);
  };
  const toggleMedicare = () => {
    setMedOpen(false);
    setEditMedicare(false);
  };

  const deletePopup = async (rowData) => {
    console.log('rowData', rowData);
    setSelectedData(rowData);
    setSelectedProgram(1);
    setOpenDeleteDialog(true);
  };
  const deletePopupMedicare = async (rowData) => {
    setSelectedData(rowData);
    setSelectedProgram(2);
    setOpenDeleteDialog(true);
  };

  const deleteInsurancePolicy = async () => {
    const payload = {
      ...selectedData,
      dirtyStatus: 'delete',
    };
    try {
      showLoader();
      const res = await healthInsurance(payload, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('SuccesFully Deleted Insurance Details');
        getInsurancePolicy();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const medicareInsurancePolicy = async () => {
    const payload = {
      ...selectedData,
      dirtyStatus: 'delete',
    };
    try {
      showLoader();
      const res = await editDelMed(payload, caseId, individualId);
      console.log(res);
      if (res.status === 200) {
        showSuccessToast('SuccesFully Deleted Medicare Details');
        getInsurancePolicy();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleDeleteProgram = () => {
    switch (selectedProgram) {
      case 1:
        return deleteInsurancePolicy();
      case 2:
        return medicareInsurancePolicy();
      default:
        return;
    }
  };
  return (
    <div>
      <Grid mt={2}>
        <Stack
          direction={'row'}
          mb={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" color="#6C6C6C">
            Insurance Details
          </Typography>
          <Button onClick={() => setOpen(true)}>+ Add Insurance Details</Button>
        </Stack>
        <Box sx={{ display: 'grid' }}>
          <DataGrid
            rows={insurancePolicyData ?? []}
            columns={columns}
            getRowId={(row) => row?.recordId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            slots={{
              noRowsOverlay: () => (
                <EmptyState
                  header="Currently No Records Available"
                  description="Please add Insurance Details"
                  icon={
                    <img
                      src={NoRecord}
                      style={{
                        height: '160px',
                        width: '250px',
                      }}
                    />
                  }
                />
              ),
            }}
            pageSizeOptions={[5]}
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '30vh',
              },
              '& .MuiDataGrid-overlayWrapperInner': {
                minHeight: '30vh',
              },
              minHeight: '30vh',
              backgroundColor: 'white',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '18px',
                fontWeight: '700',
                padding: '0px 10px',
                color: '#000000',
              },
              '& .MuiDataGrid-row': {
                fontSize: '16px',
                color: 'black',
                padding: '0px 10px',
                textAlign: 'center',
              },
            }}
          />
        </Box>
      </Grid>
      <Grid mt={3}>
        <Stack
          direction={'row'}
          mb={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" color="#6C6C6C">
            Medicare Details
          </Typography>
          <Button onClick={() => setMedOpen(true)}>
            + Add Medicare Details
          </Button>
        </Stack>
        <Box sx={{ display: 'grid' }}>
          <DataGrid
            autoHeight
            rows={medicareData ?? []}
            columns={medColumns}
            getRowId={(row) => row?.recordId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            slots={{
              noRowsOverlay: () => (
                <EmptyState
                  header="Currently No Records Available"
                  description="Please add Medicare Details"
                  icon={
                    <img
                      src={NoRecordinHealth}
                      style={{
                        height: '160px',
                        width: '250px',
                      }}
                    />
                  }
                />
              ),
            }}
            pageSizeOptions={[5]}
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '30vh',
              },
              '& .MuiDataGrid-overlayWrapperInner': {
                minHeight: '30vh',
              },
              minHeight: '30vh',
              backgroundColor: 'white',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '18px',
                fontWeight: '700',
                padding: '0px 10px',
                color: '#000000',
              },
              '& .MuiDataGrid-row': {
                fontSize: '16px',
                color: 'black',
                padding: '0px 10px',
                textAlign: 'center',
              },
            }}
          />
        </Box>
      </Grid>
      <Grid mt={3}>
        <Typography variant="h4" color="#6C6C6C">
          Third Party Liability (TPL)
        </Typography>
        <Insurance
          insuranceData={insuranceData}
          selected={selected}
          individualId={individualId}
          caseId={caseId}
        />
      </Grid>
      <Dialog
        maxWidth={'xl'}
        open={open}
        onClose={toggleInsurance}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '80vw',
              height: '80vh',
            },
          },
        }}
      >
        <DialogTitle>Add Insurance Policy</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleInsurance}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <InsuranceDetails
            selected={selected}
            individualId={individualId}
            createInsurance
            toggleInsurance={toggleInsurance}
            getInsurancePolicy={getInsurancePolicy}
          />
        </DialogContent>
      </Dialog>{' '}
      <Dialog maxWidth={'xl'} open={editInsurance} onClose={toggleInsurance}>
        <DialogTitle>Add Insurance Policy</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleInsurance}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <InsuranceDetails
            selected={selected}
            insuranceEdit={insuranceEdit}
            individualId={individualId}
            toggleInsurance={toggleInsurance}
            getInsurancePolicy={getInsurancePolicy}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="xl"
        open={medOpen}
        onClose={toggleMedicare}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '80vw',
              height: '80vh',
            },
          },
        }}
      >
        <DialogTitle>Add Medicare Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleMedicare}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Medicare
            selected={selected}
            individualId={individualId}
            createMedicare
            toggleMedicare={toggleMedicare}
            getInsurancePolicy={getInsurancePolicy}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth={'xl'}
        open={editMedicare}
        onClose={toggleMedicare}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '80vw',
              height: '80vh',
            },
          },
        }}
      >
        <DialogTitle>Add Medicare Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleMedicare}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Medicare
            selected={selected}
            individualId={individualId}
            medicareEdit={medicareEdit}
            toggleMedicare={toggleMedicare}
            getInsurancePolicy={getInsurancePolicy}
          />
        </DialogContent>
      </Dialog>
      <DeleteConfiramation
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        handleDeleteProgram={handleDeleteProgram}
      />
    </div>
  );
}

export default HealthDisability;
