import Avatar from '../Avatar';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import styles from './index.module.css';

function AddButton({ text, add }) {
  return (
    <button onClick={add} className={styles.addButton}>
      <Avatar custom>
        <AddIcon />
      </Avatar>
      <Typography ml="0.5rem" variant="subtitle1">
        {text}
      </Typography>
    </button>
  );
}

export default AddButton;
