import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import FormSection from '../../../components/FormSection';

function UsCitizen({ formik, age }) {
  return (
    <FormSection label="US Citizen">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" mt={1} style={{ fontWeight: 'bold' }}>
            Voter Registration
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            If eligible to vote registration will be automatically filled or
            updated unless you choose an option
          </Typography>
          <RadioGroup
            {...formik.getFieldProps('voterRegistration')}
            aria-labelledby="person-lived-long"
          >
            <FormControlLabel
              value="AR"
              control={<Radio />}
              label="Already registered to vote"
            />
            <FormControlLabel
              value="DR"
              control={<Radio />}
              label="Decline to registered to vote"
            />
            <FormControlLabel
              value="DC"
              control={<Radio />}
              label="Decline to report any changes"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </FormSection>
  );
}

UsCitizen.propTypes = {};

export default UsCitizen;
