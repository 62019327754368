import React from 'react';
import PropTypes from 'prop-types';
import SummaryContent from '../SummaryContent';
import TabularView from '../../../components/TabularView';
import { IconButton, Tooltip } from '@mui/material';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';

function SSNNames({ getLkpMatch, houseMembers }) {
  const columns = [
    {
      field: '',
      headerName: 'Person Name',
      getValue({ personName = {} }) {
        return `${personName?.firstName} ${personName.lastName}`;
      },
    },
    {
      field: '',
      headerName: 'SSN',
      getValue({ soSec = {} }) {
        return `${'***-**-' + (soSec?.soSecPart3 ?? '-')} `;
      },
    },
    {
      // field: 'ssnReferral',
      headerName: 'SSN Referral',
      getValue({ ssnReferral }) {
        return getLkpMatch('SsnReferral', ssnReferral) ?? '-';
      },
    },
    {
      field: '',
      headerName: 'SSN App. Date',
      getValue({ soSec = {} }) {
        return `${soSec?.soSecPart1 ? 'None' : '-'} `;
      },
    },
    {
      field: '',
      headerName: 'Additional SSN',
      getValue() {
        return '-';
      },
    },
    {
      headerName: 'Update',
      renderCell: (params) => (
        <IconButton>
          <Tooltip>
            <RestoreRoundedIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];
  return (
    <SummaryContent title="SSN and Names">
      <TabularView
        sx={{
          table: {
            width: '100%',
          },
          td: {
            border: 0,
            padding: '0 1.25rem 0.5rem 0',
          },
          th: {
            border: 0,
            borderBottom: '1px solid var(--grey-300)',
            padding: '0 1.25rem 0.5rem 0',
          },
        }}
        headerComponent={{ variant: 'h6', color: 'var(--grey-400)' }}
        cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

SSNNames.propTypes = {};

export default SSNNames;
