import { format } from '../../helpers';
import HttpClient from '../../HttpClient';
import {
  DEL_VENDOR,
  GET_OWNERSHIP,
  POST_SEARCHVEN,
  POST_VENDOR,
} from './apiUrls';
import { addVendorPayload, searchVendorPayload } from './apiPayload';

export const postVendor = (data = {}) => {
  return HttpClient.post(format(POST_VENDOR), addVendorPayload(data));
};

export const searchVendor = (data = {}) => {
  return HttpClient.post(format(POST_SEARCHVEN), searchVendorPayload(data));
};

export const getVendor = (oldId, newId) => {
  return HttpClient.get(format(GET_OWNERSHIP, oldId, newId));
};

export const deleteVendor = (data = {}, venId) => {
  return HttpClient.post(format(DEL_VENDOR, venId), data);
};
