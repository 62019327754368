import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import React, { useMemo } from 'react';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../../components/Dropdown';
import useLookup from '../../../../hooks/useLookup';
import useLoader from '../../../../hooks/useLoader';
import { postVendor } from '../../../../utils/services/VendorDetails/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import * as Yup from 'yup';

export default function VendorForm({
  toggleClose,
  createVendor,
  vendorEdit,
  searchType,
}) {
  const { getOptions } = useLookup('VenderDetails');
  const [showLoader, hideLoader] = useLoader();

  const {
    stateOpts,
    countyOpts,
    codeOpts,
    reasonOpts,
    typeOpts,
    specialityOPts,
  } = useMemo(() => {
    return {
      stateOpts: getOptions('State'),
      countyOpts: getOptions('County'),
      codeOpts: getOptions('Status'),
      reasonOpts: getOptions('StatusReasonCode'),
      typeOpts: getOptions('VendorType'),
      specialityOPts: getOptions('SpecialtyCodes'),
    };
  }, [getOptions]);

  const validationSchema = Yup.object({
    beginDate: Yup.string().required('Plesae Select Begin Date'),
    vendorName: Yup.string().required('Please Enter Vendor Name'),
    line1: Yup.string().required('Please Enter Address line1'),
    city: Yup.string().required('Please Enter City'),
    state: Yup.string().required('Please Select Sate'),
    zipCode: Yup.string().required('Please Enter ZipCode'),
    amount: Yup.string().required('Please Enter Amount'),
  });

  const formik = useFormik({
    initialValues: {
      beginDate: '',
      endDate: '',
      vendor: 'V',
      vendorName: '',
      commonName: '',
      vendorType: '',
      vendorProviderId: '',
      vendorSpeciality: '',
      fName: '',
      mName: '',
      lName: '',
      type: '',
      providerId: '',
      speciality: '',
      line1: '',
      line2: '',
      city: '',
      state: '',
      county: '',
      zipCode: '',
      zip4Code: '',
      phoneNumb: '',
      amount: '',
      code: '',
      reasonCode: '',
    },
    validationSchema,
    onSubmit: (values) => {
      postAddVendorDet(values);
    },
  });

  const postAddVendorDet = async (data) => {
    try {
      showLoader();
      const res = await postVendor(data);
      if (res.status === 200) {
        showSuccessToast('Successfully added the Vendor Details');
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Box sx={{ border: '1px solid #C2C2B7' }}>
        {/* <Stack
          direction="row"
          justifyContent="space-between"
          ml="1rem"
          mt="1rem"
        >
          <Typography variant="h4">Add Vendor</Typography>
        </Stack> */}
        <Grid container spacing={2} padding="1rem">
          {searchType === 'R' && (
            <Grid item xs={12} mt="1rem">
              <Typography variant="h6">Regular</Typography>
            </Grid>
          )}
          {searchType === 'F' && (
            <Grid item xs={12} mt="1rem">
              <Typography variant="h6">Facility</Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              name="beginDate"
              label="Effective Begin Date"
              formik={formik}
              required
              isDate
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="endDate"
              label="Effective End Date"
              formik={formik}
              isDate
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="vendor" label="" formik={formik}>
              <RadioGroup
                name="vendor"
                row
                value={formik.values.vendor}
                required
              >
                <FormControlLabel
                  value={'V'}
                  control={<Radio />}
                  label="By Vendor"
                />
                <FormControlLabel
                  value={'N'}
                  sx={{ paddingLeft: '20rem' }}
                  control={<Radio />}
                  label="By Name"
                />
              </RadioGroup>
            </Field>
          </Grid>
          {formik.values.vendor === 'V' && (
            <>
              <Grid item xs={6}>
                <Field
                  name="vendorName"
                  label="Vendor Name"
                  formik={formik}
                  required
                >
                  <TextField placeholder="Enter Vendor Name" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="commonName" label="Common Name" formik={formik}>
                  <TextField placeholder="Enter Common Name" />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field name="vendorType" label="Type" formik={formik}>
                  <Dropdown placeholder="Select Type" options={typeOpts} />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="vendorProviderId"
                  label="MMIS LTC Provider ID"
                  formik={formik}
                >
                  <TextField placeholder="Enter MMIS LTC Provider ID " />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="vendorSpeciality"
                  label="Specialities"
                  formik={formik}
                >
                  <Dropdown
                    placeholder="Select Specialities"
                    options={specialityOPts}
                  />
                </Field>
              </Grid>
            </>
          )}
          {formik.values.vendor === 'N' && (
            <>
              <Grid item xs={4}>
                <Field name="fName" label="First Name" formik={formik} required>
                  <TextField placeholder="Enter First Name" />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field name="mName" label="Middle Name" formik={formik}>
                  <TextField placeholder="Enter Middle Name" />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field name="lName" label="Last Name" formik={formik} required>
                  <TextField placeholder="Enter Last Name" />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field name="type" label="Type" formik={formik}>
                  <Dropdown placeholder="Select Type" options={typeOpts} />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="providerId"
                  label="MMIS LTC Provider ID"
                  formik={formik}
                >
                  <TextField placeholder="Enter MMIS LTC Provider ID " />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field name="speciality" label="Specialities" formik={formik}>
                  <Dropdown
                    placeholder="Select Specialities"
                    options={specialityOPts}
                  />
                </Field>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h6">Address</Typography>
          </Grid>
          <Grid item xs={6}>
            <Field name="line1" label="Address Line1" formik={formik} required>
              <TextField placeholder="Line1" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="line2" label="Address Line2" formik={formik}>
              <TextField placeholder="Line2" />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="city" label="City" formik={formik} required>
              <TextField placeholder="Enter City Name" />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="state" label="State" formik={formik} required>
              <Dropdown placeholder="Select State" options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="county" label="County" formik={formik}>
              <Dropdown placeholder="Enter County" options={countyOpts} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="zipCode" label="Zip Code" formik={formik} required>
              <TextField placeholder="Enter Zip Code" />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="zip4Code" label="Zip +4 Code" formik={formik}>
              <TextField placeholder="Enter Zip Code" />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="phoneNumb" label="Phone Number" formik={formik}>
              <TextField placeholder="Enter Phone Number" />
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Rate</Typography>
          </Grid>
          <Grid item xs={6}>
            <Field name="amount" label="Amount" formik={formik} required>
              <TextField />
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Status</Typography>
          </Grid>
          <Grid item xs={6}>
            <Field name="code" label="Code" formik={formik}>
              <Dropdown
                name="code"
                placeholder="Select Code"
                options={codeOpts}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="reasonCode" label="Reason Code" formik={formik}>
              <Dropdown placeholder="Select Reason Code" options={reasonOpts} />
            </Field>
          </Grid>
        </Grid>
      </Box>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
