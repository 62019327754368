import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  TextField,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@mui/material';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DatePicker } from '@mui/x-date-pickers';
import { map, reverse } from 'lodash';
import { maxLength, onlyAlphabet } from '../../../utils/normalizers';

function CustomerDemoInfo({ getOptions, editData, onHandleChange }) {
  const dispatch = useDispatch();
  const [maskSsn, setMaskSsn] = useState(true);

  const {
    ssnRefOpts,
    maritialStsOpts,
    ethnicityOpts,
    genderOpts,
    raceOpts,
    suffixOpts,
  } = useMemo(() => {
    return {
      suffixOpts: getOptions('Suffix'),
      ssnRefOpts: getOptions('SsnReferral'),
      raceOpts: getOptions('Race'),
      maritialStsOpts: getOptions('MartialStatus'),
      ethnicityOpts: getOptions('Ethnicity'),
      genderOpts: getOptions('Gender'),
    };
  }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      firstName:
        editData?.clientContainer?.client?.personName?.firstName ??
        editData?.personName?.firstName,
      middleName:
        editData?.clientContainer?.client?.personName?.middleName ??
        editData?.personName?.middleName,
      lastName:
        editData?.clientContainer?.client?.personName?.lastName ??
        editData?.personName?.lastName,
      suffix: '',
      dob: editData?.clientContainer?.client?.dob
        ? dayjs(editData?.clientContainer?.client?.dob).format('MM-DD-YYYY')
        : editData?.dob,
      ssn: `${editData?.clientContainer?.client?.soSec?.soSecPart1} ${editData?.clientContainer?.client?.soSec?.soSecPart2} ${editData?.clientContainer?.client?.soSec?.soSecPart3}`,
      gender: editData?.clientContainer?.client?.gender ?? editData?.gender,
      race:
        editData?.clientContainer?.client?.race?.primaryRace ??
        editData?.race?.primaryRace,
      maritalStatus:
        editData?.clientContainer?.client?.maritalStatus?.marriedYN ??
        editData?.maritalStatus?.marriedYN,
      ethnicity: editData?.clientContainer?.client?.ethnicity,
      ssnReferral: editData?.ssnReferral,
      headOfHouseholdYN: editData?.clientContainer?.client?.headOfHouseholdYN,
    },
    enableReinitialize: true,
    onSubmit: (values) => {},
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    onHandleChange(name, value);
  };

  return (
    <Paper onSubmit={formik.handleSubmit} elevation={3}>
      <Grid container spacing={2} sx={{ padding: '15px' }}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            columnGap={2}
          >
            <Typography label="Name">Name</Typography>
            <Field
              name="firstName"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField placeholder="Enter First Name" disabled />
            </Field>
            <Field
              name="middlename"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField placeholder="Enter Middle Name" disabled />
            </Field>
            <Field
              name="lastName"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField placeholder="Enter Last Name" disabled />
            </Field>
            <Field name="suffix" formik={formik}>
              <Dropdown
                placeholder="Suffix"
                options={suffixOpts}
                disabled
                value={formik.values.suffix}
              />
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            columnGap={2}
          >
            <Typography label="dob">DOB</Typography>
            <Field name="dob" formik={formik} isDate>
              <DatePicker disabled />
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Typography label="ssn">SSN</Typography>
            <Field name="ssn" formik={formik}>
              <InputMask mask="999-99-9999" maskChar={null} disabled={true}>
                {(params) => (
                  <OutlinedInput
                    type={maskSsn ? 'password' : 'text'}
                    variant="outlined"
                    placeholder="Enter Ssn"
                    disabled={true}
                    endAdornment={
                      <InputAdornment position="start">
                        <IconButton onClick={() => setMaskSsn(!maskSsn)}>
                          {maskSsn ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Typography label="gender">Gender</Typography>
            <Field name="gender" formik={formik}>
              <RadioGroup
                name=""
                row
                // {...formik.getFieldProps('')}
                sx={{ marginLeft: 2 }}
              >
                {map(reverse(genderOpts), (item, index) => (
                  <FormControlLabel
                    value={item.value}
                    control={<Radio disabled />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Typography label="Name">Race</Typography>
            <Field name="race" formik={formik} customHandler>
              <Dropdown
                name="race"
                placeholder="Select Race"
                options={raceOpts}
                onChange={handleChange}
                value={formik.values.race}
              />
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Typography label="Name">Marital Status</Typography>
            <Field name="maritalStatus" formik={formik} customHandler>
              <Dropdown
                name="maritalStatus"
                placeholder="Select Marital Status"
                options={maritialStsOpts}
                onChange={handleChange}
                value={formik.values.maritalStatus}
                disabled
              />
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Typography label="Name">Ethnicity</Typography>
            <Field name="ethnicity" formik={formik} customHandler>
              <Dropdown
                name="ethnicity"
                placeholder="Select Ethnicity"
                options={ethnicityOpts}
                onChange={handleChange}
                value={formik.values.ethnicity}
              />
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Typography label="Name">SSN Referral</Typography>
            <Field name="ssnReferral" formik={formik}>
              <Dropdown
                name="ssnReferral"
                placeholder="Select Referral"
                options={ssnRefOpts}
                onChange={handleChange}
                value={formik.values.ssnReferral}
              />
            </Field>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" alignItems="center" columnGap={2}>
            <Checkbox
              id="headOfHouseholdYN"
              name="headOfHouseholdYN"
              checked={formik?.values?.headOfHouseholdYN}
              disabled
            />
            <Typography>Head of the Household</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

CustomerDemoInfo.propTypes = {
  close: PropTypes.func,
};

CustomerDemoInfo.defaultProps = {
  close: () => {},
};

export default CustomerDemoInfo;
