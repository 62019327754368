import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  WORKS_DASHBOARD,
  SAVE_WORKPLAN,
  WORKS_PLAN_ASSESSMENT,
  GETASSISMENT,
} from './apiUrls';
import { saveWorkPlans, assessment_workPlan } from './apiPayloads';

export const worksGetTable = (caseId, individualId) => {
  return HttpClient.get(format(WORKS_DASHBOARD, caseId, individualId));
};

export const saveWorkplan = (caseId, individualId, events, data) => {
  return HttpClient.post(
    format(SAVE_WORKPLAN, caseId),
    saveWorkPlans(data, events, individualId)
  );
};

export const getAssisment = (caseId, individualId, data) => {
  return HttpClient.get(
    format(GETASSISMENT, caseId, individualId),
    assessment_workPlan(data)
  );
};

export const workPlanssessment = (
  caseId,
  individualId,
  data,
  assesmentDetails
) => {
  return HttpClient.post(
    format(WORKS_PLAN_ASSESSMENT, caseId, individualId),
    assessment_workPlan(data, assesmentDetails)
  );
};
