export const saveHealthDisabilityPayload = (values, deleteStatus) => {
  const { verifiedYON, id, ...data } = values;

  const payload = {
    ...data,
    ...(deleteStatus && { dirtyStatus: deleteStatus }),
    disabilityApprovalSourceVerification: {
      verificationSourceCode: values.disabilityApprovalSource,
      verificationRequestedYN: 'Y',
      verifiedYN: 'Y',
    },
    disabilityIarVerification: {
      verificationSourceCode: verifiedYON,
      verificationRequestedYN: verifiedYON,
      verifiedYN: verifiedYON,
    },
    loanDate: null,
    medicalCheckupDate: null,
    medicalReviewReceived: '',
    swCoopYN: '',
    treatmentParticipation: '',
  };

  return [payload];
};

export const saveWbcchpDetailsPayload = (values, deleteStatus) => {
  if (deleteStatus === 'delete') {
    return [values];
  }

  const { verificationSourceCode, ...data } = values;
  const payload = {
    ...data,
    doctorNoteVerification: {
      dirtyStatus: '',
      messages: [],
      verificationDocumentCd: '',
      verificationRequestedYN: '',
      docNames: {},
      documentDetails: [],
      verificationType: '',
      verificationSourceCode: verificationSourceCode,
      verifiedYN: 'Y',
      dueDate: null,
      automatedVerificationYN: '',
    },
  };
  return [payload];
};
export const createBenefit = (obj, action) => {
  return {
    dirtyStatus: 'insert',
    receivingBenefit: obj?.receivingBenefit ?? '',
    status: obj?.status ?? '',
    statusDate: obj?.statusDate ?? '',
    result: obj?.result ?? null,
    resultDate: obj?.resultDate ?? null,
    resultReason: obj?.resultReason ?? null,
    ssbVerification: {
      verificationSourceCode: obj?.verificationSourceCode ?? '',
      documentDetails: [],
    },
    effectiveBeginDate: obj?.effectiveBeginDate ?? '',
    effectiveEndDate: obj?.effectiveEndDate ?? '',
  };
};

export const editBenefits = (obj, action) => {
  console.log(obj);
  return {
    dirtyStatus: 'update',
    recordId: obj.id,
    receivingBenefit: obj?.receivingBenefit ?? '',
    status: obj?.status ?? '',
    statusDate: obj?.statusDate ?? '',
    result: obj?.result ?? null,
    resultDate: obj?.resultDate ?? null,
    resultReason: obj?.resultReason ?? null,
    ssbVerification: {
      verificationSourceCode: obj?.verificationSourceCode ?? '',
      documentDetails: [],
    },

    effectiveBeginDate: obj?.effectiveBeginDate ?? '',
    effectiveEndDate: obj?.effectiveEndDate ?? '',
  };
};
