import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { postAlcoholDrugForm } from '../../../../utils/services/ManualNotices/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import { maxLength, onlyAlphabet, number } from '../../../../utils/normalizers';

export default function AlcoholDrugForm({
  toggleClose,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();
  const formik = useFormik({
    initialValues: {
      hohName: dataValue?.hoh ?? '',
      dssOffice: dataValue?.dssOffice ?? '',
      maNumb: dataValue?.maNum ?? '',
      auId: dataValue?.auId ?? '',
      ssNumb: dataValue?.ssNum ?? '',
      printName: dataValue?.printName ?? '',
      signature: dataValue?.sign ?? '',
      date: dataValue?.signDate ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      postAlcoholReq(values);
    },
  });

  const postAlcoholReq = async (data) => {
    try {
      showLoader();
      const res = await postAlcoholDrugForm(data);
      if (res.status === 200) {
        showSuccessToast('Succesfully Created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Grid container mt="2rem">
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              CONSENT FOR RELEASE OF INFORMATION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              CONFIDENTIAL ALCOHOL AND DRUG TREATMENT INFORMATION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              by Substance Abuse Assessment and Treatment Providers to
              Departments of Social Services
            </Typography>
          </Grid>
        </Grid>
        <Grid mt="2rem" ml="2rem" mb="1rem">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="hohName"
                formik={formik}
                label="Head of Household"
                normalizers={[maxLength(50), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="dssOffice"
                formik={formik}
                label="DSS Office"
                normalizers={[maxLength(50), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="maNumb"
                formik={formik}
                label="MA #"
                normalizers={[maxLength(20), number]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="auId"
                formik={formik}
                label="AU ID#"
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="ssNumb"
                formik={formik}
                label="SS #"
                normalizers={[maxLength(50), number]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1">
                I
                <Field name="printName" formik={formik}>
                  <TextField placeholder="Print Name" variant="standard" />
                </Field>
                authorize the substance abuse assessment or treatment provider
                that I am referred to for assessment or treatment, or that is
                treating me, to report to the Department of Social Services
                (DSS) office named above the information listed below, if it has
                this information about me:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="standard">
                •That the substance abuse treatment provider has received my
                consent form and referral for treatment from the Addictions
                Specialist;<br></br>•That I did not keep an appointment for a
                comprehensive substance abuse assessment ordered by the
                Addictions Specialist in the DSS office;<br></br>•That a
                comprehensive substance abuse assessment indicates that I am not
                in need of substance abuse treatment;<br></br>•That I have been
                referred for substance abuse treatment;<br></br>•That I did not
                schedule and appear for my first appointment for substance abuse
                treatment within 30 days of referral or as soon as I could get
                an appointment;<br></br>•That I am waiting for room for me in
                the kind of substance abuse treatment program I was referred to;
                <br></br>•That I am enrolled in a substance abuse treatment
                program;<br></br>•That I am not maintaining active attendance or
                participation in the treatment program;<br></br>•That I have
                been discharged from a treatment program for noncompliance;
                <br></br>•That I successfully completed the substance abuse
                treatment that I was referred to;<br></br>•That I was referred
                to another substance abuse treatment program and will provide
                the name of that program.<br></br>•That I have been tested for
                drug use and results of the test.
              </Typography>
            </Grid>
            <Grid item xs={10} mt="1rem" ml="1rem">
              <Typography variant="subtitle1">
                This release is necessary to comply with state law that requires
                this information be reported to your local DSS office if you are
                going to receive Temporary Cash Assistance (TCA) benefits and/or
                Supplemental Nutrition Assistance Program (“SNAP ”) if you have
                been convicted of drug kingpin or volume dealer drug felony.
                (*see definitions below)
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1">
                <span>•Drug kingpin</span> - An organizer, supervisor,
                financier, or manager who acts as a co-conspirator in a
                conspiracy to manufacture, distribute, dispense, transport in,
                or bring into the State a controlled dangerous substance
                <br></br>
                <span>•Volume dealer</span> - An individual, who manufactures,
                distributes, dispenses or possesses certain quantities of a
                controlled dangerous substance
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1">
                I understand that my records are protected under the federal
                regulations governing Confidentiality of Alcohol and Drug Abuse
                Patient Records, 42 CFR Part 2, and cannot be reported to anyone
                without my written consent unless those regulations provide
                otherwise. I also understand that I can cancel this consent at
                any time, but the cancellation will not apply to the past acts
                someone who was covered by this consent at the time and relied
                on it; if I do cancel this consent, I could lose my TCA or SNAP
                benefits. In any case, this consent will automatically be
                canceled when my TCA and SNAP benefits end.
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Field
                name="signature"
                formik={formik}
                label="Signature"
                normalizers={[maxLength(50), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="date" formik={formik} label="Signature" isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography
                variant="h5grey"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                PROHIBITION OF REDISCLOSURE
              </Typography>
            </Grid>
            <Grid item xs={10} mt="1rem">
              <Typography variant="subtitle1">
                This information has been disclosed to you from records
                protected by Federal confidentiality rules (42 CFR part 2). The
                federal rules prohibit any further disclosure of this
                information unless expressly permitted by the written consent of
                the person to whom it pertains or as otherwise permitted by 42
                CFR Part 2. A general authorization for the release of medical
                or other information is not sufficient for this purpose. The
                Federal rules restrict any use of the information to criminally
                investigate or prosecute any alcohol or drug abuse treatment
                patient.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
