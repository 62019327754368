import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EligibilityAccordion, { ElAccordionContent } from '..';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import Avatar from '../../../../../components/Avatar';
import TextContentGroup from '../../../../../components/TextContentGroup';
import { checkProgramOfAny, getFullName } from '../../../../../utils/helpers';
import mock from './mock.json';
import { get, map } from 'lodash';
import IndFinancialSummary from './IndFinancialSummary';
import TabularView from '../../../../../components/TabularView';

function IndividualFinancial({ programName, financialInfo, getLkpMatch }) {
  const [showSummary, setShowSummary] = useState(false);
  const isProgramOf = checkProgramOfAny(programName);
  const [detailsVisibility, setDetailsVisibility] = useState({});

  const agIndvBudgetResult = get(financialInfo, 'agIndvBudgetResult', []);

  const toggleSummary = () => {
    setShowSummary(!showSummary);
  };

  const toggleDetails = (index) => {
    setDetailsVisibility((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const renderContent = (indBudget, index) => {
    const {
      firstName,
      lastName,
      totalGrossEarnedIncome,
      totalGrossUnEarnedIncome,
      totalMedicalExpenses,
      totalAssetAmount,
      childSpousalSupportExpenses,
      unearnedIncomeDisregard,
      agIncomeDetail = [],
      agAssetDetail = [],
    } = indBudget;
    const name = getFullName(firstName, lastName);
    const textContents = [
      {
        title: 'Total Earned Income',
        subTitle: totalGrossEarnedIncome,
        type: 'currency',
      },
      {
        title: 'Total Unearned Income',
        subTitle: totalGrossUnEarnedIncome,
        type: 'currency',
      },
      {
        title: 'Total Medical Expenses',
        subTitle: totalMedicalExpenses,
        type: 'currency',
      },
      {
        title: 'Total Assets',
        subTitle: totalAssetAmount,
        type: 'currency',
      },
      {
        title: 'Total CSS Expense',
        subTitle: childSpousalSupportExpenses,
        type: 'currency',
      },
      {
        title: 'Unearned Income Disregard',
        subTitle: unearnedIncomeDisregard,
        type: 'currency',
      },
      {
        title: 'Gross Income',
        subTitle: 0, //#2780
        type: 'currency',
      },
      {
        title: 'Net Income',
        subTitle: 0,
        type: 'currency',
      },
      {
        title: 'Unearned Income Disregard',
        subTitle: 0,
        type: 'currency',
      },
    ];

    const columns = [
      {
        headerName: 'Type',
        getValue({ incomeType = {} }) {
          return `${getLkpMatch('EarningType', incomeType ?? '-')}`;
        },
      },
      {
        headerName: 'Amount',
        getValue({ totalAmt = {} }) {
          return `${totalAmt ?? '-'}`;
        },
      },
      {
        headerName: 'Countable',
        getValue({ countableYN = {} }) {
          return `${getLkpMatch('YesNo', countableYN ?? '-')}`;
        },
      },
    ];

    const columnsAsset = [
      {
        headerName: 'Type',
        getValue({ assetType }) {
          return `${getLkpMatch('AddAssetsAssetTypeParent', assetType ?? '-')}`;
        },
      },
      {
        headerName: 'SubType',
        getValue({ assetDetailType }) {
          return `${assetDetailType ?? '-'}`;
        },
      },
      {
        headerName: 'Amount',
        getValue({ totalAmt }) {
          return `${totalAmt ?? '-'}`;
        },
      },
      {
        headerName: 'Countable',
        getValue({ countableYN }) {
          return `${getLkpMatch('YesNo', countableYN ?? '-')}`;
        },
      },
    ];

    return (
      <ElAccordionContent key={index}>
        <Stack direction="row">
          <Box>
            <Stack height="100%" minWidth="15rem">
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  p: '0.5rem 0 1rem 1rem',
                  width: 'max-content',
                }}
              >
                <Avatar name={name} />
                <Typography variant="subtitle2" ml={'0.5rem'}>
                  {name}
                </Typography>
              </Stack>
              <Box mt="auto" textAlign="center">
                <Button onClick={() => toggleDetails(index)}>Details</Button>
              </Box>
            </Stack>
          </Box>
          <Box>
            <TextContentGroup
              alignRight
              col={4}
              single
              textContents={textContents}
            />
          </Box>
        </Stack>
        {detailsVisibility[index] && (
          <Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography>Income</Typography>
              </Grid>
              <Grid item xs={9}>
                <TabularView
                  columns={columns}
                  data={agIncomeDetail}
                  renderHeader={(title) => (
                    <Typography variant="body1" component="strong">
                      {title}
                    </Typography>
                  )}
                  sx={{
                    table: {
                      width: '100%',
                      borderCollapse: 'collapse',
                    },
                    th: {
                      padding: '12px 20px',
                      textAlign: 'center',
                      borderBottom: '2px solid #e2e2e7',
                    },
                    td: {
                      padding: '12px 20px',
                      textAlign: 'center',
                    },
                    tr: {
                      ':nth-child(even)': {
                        backgroundColor: '#f9f9f9',
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" mt="1rem" mb="1rem">
              <Grid
                item
                xs={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography>Assets</Typography>
              </Grid>
              <Grid item xs={9}>
                <TabularView
                  columns={columnsAsset}
                  data={agAssetDetail}
                  renderHeader={(title) => (
                    <Typography variant="body1" component="strong">
                      {title}
                    </Typography>
                  )}
                  sx={{
                    table: {
                      width: '100%',
                      borderCollapse: 'collapse',
                    },
                    th: {
                      padding: '12px 20px',
                      textAlign: 'center',
                      borderBottom: '2px solid #e2e2e7',
                    },
                    td: {
                      padding: '12px 20px',
                      textAlign: 'center',
                    },
                    tr: {
                      ':nth-child(even)': {
                        backgroundColor: '#f9f9f9',
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </ElAccordionContent>
    );
  };

  return (
    <Box>
      <IndFinancialSummary
        open={showSummary}
        close={toggleSummary}
        agIndvBudgetResult={agIndvBudgetResult}
        financialInfo={financialInfo}
      />
      {isProgramOf('FS', 'CH') && (
        <Stack sx={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button onClick={toggleSummary}>Summary</Button>
        </Stack>
      )}
      <Divider />
      <EligibilityAccordion>
        {map(agIndvBudgetResult, (indBudget, index) =>
          renderContent(indBudget, index)
        )}
      </EligibilityAccordion>
    </Box>
  );
}

IndividualFinancial.propTypes = {
  programName: PropTypes.string.isRequired,
  financialInfo: PropTypes.object.isRequired,
};

export default IndividualFinancial;
