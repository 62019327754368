export const POST_INCARCERATION =
  'eeapi/application-process/interview/{0}/household/{1}/add-incarceration';

export const POST_PRESCHOOL =
  '/eeapi/application-process/interview/{0}/household/{1}/primary-prevention';

export const POST_STRIKER =
  '/eeapi/application-process/interview/{0}/household/{1}/striker-information';

export const POST_VETERAN =
  'eeapi/application-process/interview/{0}/household/{1}/veteran-information';
