import React from 'react';
import PropTypes from 'prop-types';
import { Button, Paper, Stack, Typography } from '@mui/material';
import TabularView from '../../components/TabularView';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import usePageNav from '../../hooks/usePageNav';

const columns = [
  {
    headerName: 'AG Head',
  },
  {
    headerName: 'Name',
  },
  {
    headerName: 'Begin Date',
  },
  {
    headerName: 'End Date',
  },
  {
    headerName: 'Type',
  },
  {
    headerName: 'Address',
  },
  {
    headerName: 'Relation',
  },
  {
    headerName: 'Actions',
  },
];

function AuthorizedRep(props) {
  const { navigations } = usePageNav();
  const onNext = () => navigations.toAppDispose();

  return (
    <div>
      <Typography variant="h2">Authorized Representative</Typography>
      <Stack>
        <Button
          sx={{ alignSelf: 'flex-end' }}
          fullWidth={false}
          startIcon={<PersonAddAltIcon />}
          // onClick={toggleClearance}
        >
          Add Auth Rep
        </Button>
        <TabularView
          headerComponent={{ variant: 'subtitle1' }}
          component={Paper}
          columns={columns}
        />
      </Stack>
      <Stack
        direction={'row'}
        spacing={1}
        sx={{ mt: '1rem', justifyContent: 'flex-end' }}
      >
        <Button variant="outlined">Back</Button>
        <Button onClick={onNext} variant="contained">
          Next
        </Button>
      </Stack>
    </div>
  );
}

AuthorizedRep.propTypes = {};

export default AuthorizedRep;
