import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import styles from '../../index.module.css';

export default function CPShelterUtility({
  data,
  getLkpMatch,
  hohMemberData,
  handleCheckbox,
}) {
  const getMemberNameByIndividualId = useCallback(
    (individualId) => {
      let result = hohMemberData?.find(
        (item) => item.dummyIndividualId === individualId
      );
      return `${result?.firstName ?? ''} ${result?.lastName ?? ''}`;
    },
    [data, hohMemberData]
  );

  return (
    <>
      {map(data, (item, index) => {
        const { heatCoolInd, shelterExpenseTypes, shelterUtilityTypes } =
          item?.shelterUtilityExpense ?? {};
        return (
          <Stack direction="row" pl={1}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Checkbox />}
              onChange={(event) => handleCheckbox(event, index)}
              checked={item?.importSelected}
            />
            <div className={styles.card}>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 1.5,
                      paddingBottom: '0.5rem',
                      border: 0,
                    },
                  }}
                >
                  <TableBody className={styles.tableBody}>
                    <TableRow
                      sx={{ borderBottom: '1px solid var(--grey-350)' }}
                    >
                      <TableCell>
                        <Typography variant="h6grey">
                          Individual Name
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="h6">
                          {getMemberNameByIndividualId(item?.individualId) ??
                            ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          Is Heat Included
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {heatCoolInd === 'Y'
                            ? 'Yes'
                            : heatCoolInd === 'N'
                            ? 'NO'
                            : 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {map(shelterExpenseTypes, (element) => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6grey">Expense Type</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {getLkpMatch(
                              'ShelterExpense',
                              element?.shelterExpTypeCd
                            ) ?? 'NA'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6grey">Amount</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {element?.shelterExpenseAmount ?? 'NA'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    {map(shelterUtilityTypes, (element) => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6grey">Expense Type</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {getLkpMatch(
                              'UtilityExpense',
                              element?.utilityTypeCd
                            ) ?? 'NA'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6grey">Amount</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {element?.utilityExpenseAmount ?? 'NA'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Stack>
        );
      })}
    </>
  );
}
