import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import InComeDataGrid from './InComeDataGrid';
import DocumentFileUploader from './DocumentFileUploader';
import HttpClient from '../../utils/HttpClient';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  deleteFileUpl,
  documentUpload,
  editDocumentUpl,
  getDocumentsFiles,
  getUploadFilesDoc,
  saveIncomeUploadFiles,
} from '../../utils/services/apiRequests';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import dayjs from 'dayjs';
import useLoader from '../../hooks/useLoader';
import cx from 'classnames';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../store/selectors/authSelector';
import DeleteConfiramation from '../HealthAndDisability/DeleteConfiramation';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '70vh',
    boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  containerData: {
    boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '20px',
  },
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
}));

const DocumentUploadForm = ({
  lkpRaw,
  isAdd,
  member,
  caseId,
  setDocOpen,
  setIsAdd,
  // docmentFiles,
  // getUploadFiles,
  // setDocmentFiles,
  docOpen,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [deleteId, setToDeleteId] = useState();
  const [showLoader, hideLoader] = useLoader();
  const [selectedFile, setSelectedFile] = useState(null);
  const [docmentType, setDocmentType] = useState('');
  const [docmentNames, setDocmentNames] = useState([]);
  const token = useSelector(getAuthToken);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [documentId, setDocumentId] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [docId, setDocId] = useState(null);

  const getUploadFiles = async () => {
    try {
      showLoader();
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    if (docOpen) getUploadFiles();
  }, [docOpen]);

  const columns = [
    {
      field: 'documentTypeCd',
      headerName: 'Document Type',
      width: 250,
      editable: true,
    },
    {
      field: 'docCatagory',
      headerName: 'Document Name',
      width: 250,
      editable: true,
    },
    {
      field: 'docName',
      headerName: 'File Name',
      width: 150,
      editable: false,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 150,
      editable: false,
    },
    {
      field: 'createdDt',
      headerName: 'Created Date',
      width: 150,
      editable: false,
      valueFormatter: (params) => dayjs(params.value).format('YYYY/MM/DD'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isLessThan24Hours = checkIsLessThan24HoursAgo(
          params.row.created_date
        );
        return (
          <>
            {isLessThan24Hours && (
              <IconButton
                aria-label="edit"
                onClick={() => handleEdit(params.row)}
              >
                <EditIcon />
              </IconButton>
            )}
            <IconButton
              aria-label="delete"
              onClick={() => deletePopup(params?.row)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const validationSchema = Yup.object().shape({
    fileFormData: Yup.mixed().required('Please select a file'),
    documentTypeCd: Yup.string().required('Please select the name'),
    lookupShortVal: Yup.string().required('Please select the type'),
  });

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  // const handleConfirmDelete = () => {
  //   const deletedDoc = gridDocData.filter(
  //     (doc) => doc.primaryDocId !== deleteId
  //   );
  //   setDocmentFiles(deletedDoc);
  //   setOpenDeleteDialog(false);
  // };

  const initialState = {
    fileFormData: '',
    individualId: '',
    sourceCaseId: '',
    verificationName: null,
    documentTypeCd: '',
    lookupShortVal: '',
    user: token?.sub,
    role: 'EE_SUPVRS,EE_SUPUSR,EE_VEND_ADMN',
    createdBy: token?.name,
    sourceSystem: 'EEAPI',
  };

  function base64ToUint8Array(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  const formik = useFormik({
    initialValues: initialState,
    validationSchema,
    onSubmit: async (values) => {
      // let payload = {
      //   ...values,
      //   individualId: member.individualId,
      //   sourceCaseId: caseId,
      // };
      let payload = {
        fileFormData: values.fileFormData,
        individualId: member.individualId || values.individualId,
        sourceCaseId: caseId || values.sourceCaseId,
        verificationName: null,
        documentTypeCd: values.documentTypeCd,
        lookupShortVal: values.lookupShortVal,
        documentId: values.docId || '',
        user: token?.sub,
        role: 'EE_SUPVRS,EE_SUPUSR,EE_VEND_ADMN',
        createdBy: token?.name || values.createdBy,
        sourceSystem: 'KCARES',
      };
      if (!isAdd && docId) {
        // Add documentId to payload only in edit mode
        payload.documentId = docId;
      }

      try {
        showLoader();
        if (isAdd) {
          const res = await documentUpload(payload);
          if (res.status === 200) {
            showSuccessToast('File added');
            getUploadFiles();
          }
        } else {
          const res = await editDocumentUpl(payload);
          if (res.status === 200) {
            showSuccessToast('Updated Successfully');
            getUploadFiles();
          }
        }
        formik.resetForm();
      } catch (error) {
        showErrorToast(error);
      } finally {
        hideLoader();
      }
      // const res = await saveIncomeUploadFiles(caseId, payload);
      //   const res = await documentUpload(payload);
      //   if (res.status === 200) {
      //     showSuccessToast('File added');
      //     getUploadFiles();
      //     formik.resetForm();
      //   }
      // } catch (error) {
      //   showErrorToast(error);
      // } finally {
      //   hideLoader();
      // }
    },
  });

  const handleFileChange = (file) => {
    if (file) {
      setSelectedFile(file);
      formik.setFieldValue(`fileFormData`, file);
    } else {
      setSelectedFile(null);
      formik.setFieldValue(`fileFormData`, '');
    }
  };

  const handeChangeCallApi = async (event) => {
    let { value } = event.target;
    setDocmentType(value);
    formik.setFieldValue(`documentTypeCd`, value);
    let payLoad = [
      {
        lookupTypeCd: value,
      },
    ];
    const res = await fetchLookupForType(payLoad);
    setDocmentNames(res[value]);
  };

  const handleDocumentChange = (event) => {
    const { value, name } = event.target;
    formik.setFieldValue(`lookupShortVal`, value);
  };

  const fetchLookupForType = async (payload) => {
    try {
      showLoader();
      const res = await HttpClient.post(
        '/eeapi/public/consumer/lookup-search/',
        payload
      );
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('Lookup fetch failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  // useEffect(() => {
  //   if (docOpen) getFileUpl();
  // }, [docOpen]);

  // const getFileUpl = async () => {
  //   try {
  //     // debugger;
  //     showLoader();
  //     const res = await getUploadFilesDoc(documentIds);
  //     if (res.status === 200) {
  //       setFileData(res.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     hideLoader();
  //   }
  // };

  const deletePopup = async (rowData) => {
    setSelectedData(rowData);
    setDocumentId(rowData?.docId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = () => {
    deleteFileUpload(documentId);
  };

  const deleteFileUpload = async (documentId) => {
    try {
      showLoader();
      const res = await deleteFileUpl(documentId);
      if (res.status === 200) {
        showSuccessToast('Successfully Deleted');
        getUploadFiles();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleEdit = async (data) => {
    setIsAdd(false);
    setDocId(data.docId);

    setDocmentType(data.documentTypeCd);
    let payLoad = [
      {
        lookupTypeCd: data.documentTypeCd,
      },
    ];
    const res = await fetchLookupForType(payLoad);
    setDocmentNames(res[data.documentTypeCd]);
    formik.setValues({
      fileFormData: data.fileFormData || { name: data.docName },
      individualId: data.individualId,
      documentTypeCd: data.documentTypeCd,
      lookupShortVal: data.docCatagory,
      createdBy: data.createdBy,
      // docId: data.docId,
    });
  };

  const gridDocData = useMemo(() => {
    if (!docmentFiles) {
      return [];
    }
    return docmentFiles.map((item) => {
      return {
        docId: item.documentId,
        documentTypeCd: item.docType,
        docCatagory: item.docCat,
        docName: item.userFileName,
        createdBy: item.createdBy,
        createdDt: item.createdDt,
        primaryDocId: item.primaryDocId,
        individualId: item.individualId,
      };
    });
  }, [docmentFiles]);

  function checkIsLessThan24HoursAgo(date) {
    const createdDate = dayjs(date);
    const currentDate = dayjs();
    const hoursDifference = currentDate.diff(createdDate, 'hour');

    return hoursDifference < 24;
  }

  const handleReset = () => {
    formik.setValues(initialState);
    setIsAdd(true);
  };

  const validateIsEmpty = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) &&
      get(formik, `touched.${fieldName}`) &&
      get(formik, `values.${fieldName}`) === '';

    return !!hasErrors;
  };

  const validateIsError = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) && get(formik, `touched.${fieldName}`);

    return !!hasErrors;
  };

  return (
    <>
      <Container
        className={classes.container}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DocumentFileUploader
              handleChange={handleFileChange}
              formik={formik}
              formikError={
                formik.errors.fileFormData &&
                formik.touched.fileFormData &&
                formik.errors.fileFormData
              }
              isAdd={isAdd}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsError(
                  formik,
                  'documentTypeCd'
                ),
              })}
            >
              <InputLabel id="doc-type">Document Type</InputLabel>
              <Select
                labelId="doc-type"
                id="select-doc-type"
                label="Document Type"
                value={formik.values.documentTypeCd}
                name="documentTypeCd"
                onChange={(event) => handeChangeCallApi(event)}
              >
                {lkpRaw.Documents.map((item, index) => (
                  <MenuItem key={index} value={item.lookupLongVal}>
                    {item.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsError(formik, 'documentTypeCd') && (
                <FormHelperText error>
                  {formik.errors.documentTypeCd}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsEmpty(
                  formik,
                  'lookupShortVal'
                ),
              })}
            >
              <InputLabel id="doc-name">Document Name</InputLabel>
              <Select
                labelId="doc-name"
                id="select-doc-name"
                label="Document Name"
                name="lookupShortVal"
                value={formik.values.lookupShortVal}
                onChange={handleDocumentChange}
                disabled={isAdd && !formik.values.documentTypeCd}
              >
                {docmentNames.map((item, index) => (
                  <MenuItem key={index} value={item.lkpDesc}>
                    {item.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsEmpty(formik, 'lookupShortVal') && (
                <FormHelperText error>
                  {formik.errors.lookupShortVal}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              label="Created By"
              fullWidth
              margin="normal"
              name="createdBy"
              value={formik.values.createdBy}
              onChange={formik.handleChange}
              disabled
            />
            <div style={{ float: 'right', left: '0' }}>
              <Button variant="outlined" onClick={handleReset}>
                Reset
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '20px' }}
                type="submit"
              >
                Upload File
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.containerData}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InComeDataGrid
              columns={columns}
              rows={gridDocData}
              getRowId={(row) => row?.docId}
              autoHW
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <DeleteConfiramation
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          handleCancelDelete={handleCancelDelete}
          handleConfirmDelete={handleConfirmDelete}
        />
      </Container>
    </>
  );
};

export default DocumentUploadForm;
