import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import { getContactInfo } from '../../../utils/services/NonCustodial/apiRequests';
import { showErrorToast } from '../../../utils/services/toast';
import TabularView from '../../../components/TabularView';
import { filter } from 'lodash';
import CreateIcon from '@mui/icons-material/Create';
import { CloseRounded } from '@mui/icons-material';
import ContactModal from './ContactModal';
import useLookup from '../../../hooks/useLookup';

export default function ContactInfo({ absentParentId, caseId }) {
  const [contactData, setContactData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [contactEdit, setContactEdit] = useState('');
  const { getLkpMatch } = useLookup('NonCustodialParent');

  const columns = [
    {
      headerName: 'Care of',
      getValue({ addressCareOf }) {
        return `${addressCareOf}`;
      },
    },
    {
      headerName: 'Address',
      getValue({ addressLine1 }) {
        return `${addressLine1}`;
      },
    },
    {
      headerName: 'From Date',
      getValue({ addressStartDate }) {
        return `${addressStartDate}`;
      },
    },
    {
      headerName: 'End Date',
      getValue({ addressEndDate }) {
        return `${addressEndDate}`;
      },
    },
  ];

  const phoneColumns = [
    {
      field: 'col1',
      headerName: 'Begin Date',
      flex: 1,
    },
    {
      field: 'col2',
      headerName: 'End Date',
      flex: 1,
    },
    {
      field: 'col3',
      headerName: 'Phone Number',
      flex: 1,
    },
    {
      field: 'col4',
      headerName: 'Phone Number Ext',
      flex: 1,
    },
    {
      field: 'col5',
      headerName: 'Phone Number Type',
      flex: 1,
    },
  ];

  const contactEditData = (data) => {
    setEditOpen(true);
    setContactEdit(data);
  };
  const toggleClose = () => {
    setEditOpen(false);
    setContactEdit(false);
  };
  const {
    addressCareOf,
    addressStartDate,
    addressLine1,
    city,
    state,
    zipcode,
  } = contactData?.currentAddress ?? {};

  const { phoneNumber, phoneNumberType } = contactData?.phoneNumber ?? {};

  const rowData = contactData?.previousAddresses ?? {};

  useEffect(() => {
    if (caseId) {
      getContact();
    }
  }, [caseId]);

  const getContact = async () => {
    try {
      showLoader();
      const res = await getContactInfo(caseId, absentParentId);
      if (res.status === 200) {
        setContactData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const rowPhoneData = contactData?.previousPhoneNumber?.[0] ?? {};

  return (
    <>
      <Grid container spacing={2} mt="0.5rem">
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Current Address</Typography>
            <IconButton onClick={() => contactEditData(contactData)}>
              <CreateIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Care of</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{addressCareOf}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">From</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{addressStartDate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Address</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{addressLine1}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Phone</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{phoneNumber}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">City</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{city}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Type</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {phoneNumberType ? getLkpMatch('PhoneType', phoneNumberType) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Zip Code</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{zipcode}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Email</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{contactData?.email}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {state ? getLkpMatch('State', state) : '-'}
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="h4">Previous Address</Typography>
        </Grid> */}
        {/* <Grid item xs={12} mt="1rem"> */}
        {/* <TabularView
            columns={columns}
            data={rowData ?? []}
            headerComponent={{ variant: 'h4' }}
            sx={{
              border: '1px solid #f0f0f0',
            }}
          /> */}
        {/* <DataGrid
            rows={rows ?? []}
            columns={columns}
            // getRowId={(row) => row?.recordId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            sx={{
              backgroundColor: 'white',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '18px',
                fontWeight: '700',
                padding: '0px 10px',
                color: '#000000',
              },
              '& .MuiDataGrid-row': {
                fontSize: '16px',
                color: 'black',
                padding: '0px 10px',
                textAlign: 'center',
              },
            }}
            slots={{
              noRowsOverlay: 'noData',
            }}
          /> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} mt="1rem">
          <DataGrid
            rows={rowPhoneData ?? []}
            columns={phoneColumns}
            getRowId={(row) => row?.previousPhoneNumber?.phoneNumberId ?? {}}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            sx={{
              backgroundColor: 'white',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '18px',
                fontWeight: '700',
                padding: '0px 10px',
                color: '#000000',
              },
              '& .MuiDataGrid-row': {
                fontSize: '16px',
                color: 'black',
                padding: '0px 10px',
                textAlign: 'center',
              },
            }}
            slots={{
              noRowsOverlay: 'noData',
            }}
          />
        </Grid> */}
      </Grid>
      <Dialog open={editOpen} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Contact Information</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ContactModal
            toggleClose={toggleClose}
            caseId={caseId}
            absentParentId={absentParentId}
            contactEdit={contactEdit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
