import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from '../../../components/ActionButtons';
import AddLiquid from './AddLiquid';
import { CloseRounded, Search } from '@mui/icons-material';
import FinancialInstitution from './FinancialInstitution';
import { filter } from 'lodash';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import { useParams } from 'react-router-dom';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import { maxLength } from '../../../utils/normalizers';

export default function LiquidAsset({
  editData,
  insertAPI,
  getOptions,
  createNew,
  editApi,
  toggleClose,
  selected,
  lkpRaw,
}) {
  const [policy, setPolicy] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [institutionSearch, setInstitutionSearch] = useState(false);
  const [editRecord, setEditRecord] = useState('');
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const validationSchema = Yup.object({
    type: Yup.string().required('Please select type'),
    institution: Yup.string().required('Please select financial instition'),
  });
  useEffect(() => {
    if (policyData.length === 0 && editData) {
      setPolicyData((prev) => [...prev, ...editData?.assetAmounts]);
    }
  }, []);

  const { liquidTypeOpts, verificationLiquidOpts, stateOpts } = useMemo(() => {
    return {
      liquidTypeOpts: getOptions('LiquidAssetType'),
      verificationLiquidOpts: getOptions('CLLIQASSETAMTVERCD'),
      stateOpts: getOptions('State'),
    };
  }, [getOptions]);

  const params = useParams();

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const formik = useFormik({
    initialValues: {
      type: editData?.assetDetailType ?? '',
      accountNumber: editData?.accountNumber ?? '',
      institution: editData?.institutionName ?? '',
      jointly: editData?.jointOwnerYN ?? '',
      verified: editData?.assetVerification?.verificationSourceCode ?? '',
      Percentage: editData?.percentOfShare ?? '',
      assetType: 1,
      recordId: editData?.recordId ?? '',
      locationId: editData?.locationId ?? '',
      institutionId: editData?.institutionId ?? '',
      jointOwnerYN: editData?.jointOwnerYN ?? 'N',
    },
    validationSchema,
    onSubmit: (value) => {
      if (createNew) {
        insertAPI({ value, policyData });
      } else {
        editApi({ value, policyData });
      }
    },
  });

  const AddInstitutionDetails = () => {
    setPolicy(true);
  };
  const toggleClosePop = () => {
    setPolicy(false);
    setInstitutionSearch(false);
  };

  const liquidColumns = useMemo(
    () => [
      {
        field: 'effectiveBeginDate',
        headerName: 'Start Date',
        flex: 1,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        flex: 1,
      },
      {
        field: 'amount',
        headerName: 'Monthly Amount',
        flex: 1,
        renderCell: (params) => {
          return <Typography>${params.row.amount}</Typography>;
        },
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editRec(params?.row)}
                onDelete={() => deleteRecord(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [policyData]
  );

  const deleteRecord = (data) => {
    const newRecord = filter(
      policyData,
      (val) => val.recordId !== data.recordId
    );
    setPolicyData(newRecord);
  };
  const editRec = (data) => {
    setEditRecord(data);
    setPolicy(true);
  };

  const selectedValue = (data) => {
    formik.setFieldValue('institution', data.institutionName);
    formik.setFieldValue('locationId', data.locationId);
    formik.setFieldValue('institutionId', data.institutionId);
    setInstitutionSearch(false);
  };

  const searchInstitution = () => {
    setInstitutionSearch(true);
  };

  return (
    <div>
      <Dialog
        open={policy}
        onClose={toggleClosePop}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Typography variant="h3">Liquid Details</Typography>
            <IconButton onClick={toggleClosePop}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <AddLiquid
            editRecord={editRecord}
            setPolicyData={setPolicyData}
            toggleClosePop={toggleClosePop}
            policyData={policyData}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={institutionSearch} maxWidth={'md'} onClose={toggleClosePop}>
        {/* <DialogTitle>
          <Typography variant="h3">Search Institute</Typography>
        </DialogTitle> */}
        <DialogContent>
          <FinancialInstitution
            getOptions={getOptions}
            toggleClosePop={toggleClosePop}
            stateOpts={stateOpts}
            policyData={policyData}
            editRecord={editRecord}
            selectedValue={selectedValue}
          />
        </DialogContent>
      </Dialog>
      <FormSection label={'Account Details'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name="type" formik={formik} label="Type" required>
              <Dropdown options={liquidTypeOpts} placeholder="Select Type" />
            </Field>
          </Grid>

          <Grid item xs={6}>
            <Field
              name="accountNumber"
              formik={formik}
              label="Account Number"
              normalizers={[maxLength(16)]}
            >
              <TextField placeholder="Enter Account Number" />
            </Field>
          </Grid>

          <Grid item xs={6}>
            <Field
              name="institution"
              formik={formik}
              label="Financial Institution Name"
              required
            >
              <TextField
                placeholder="Financial Institution"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchInstitution}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="verified" formik={formik} label="Verified" required>
              <Dropdown
                options={verificationLiquidOpts}
                placeholder="Select Verification"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="Percentage"
              formik={formik}
              label="Percentage Of Share"
            >
              <TextField placeholder="Enter the Percentage" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              sx={{ pt: 2.7 }}
              control={
                <Checkbox
                  {...formik.getFieldProps('jointOwnerYN')}
                  checked={formik.values.jointOwnerYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('jointOwnerYN', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Jointly Owner"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color={'gray'}>
              Liquid Summary
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={'row'}
              mb={2}
              justifyContent={'space-between'}
              mt={2}
            >
              <Button
                variant="outlined"
                startIcon={<UploadFileIcon />}
                onClick={handleOpenDocModal}
              >
                Upload Files
              </Button>
              <Button onClick={AddInstitutionDetails} startIcon={<AddIcon />}>
                Add Asset
              </Button>
            </Stack>
            <div style={{ width: '100%' }}>
              <DataGrid
                getRowId={(rows) => rows.recordId}
                rows={policyData ?? []}
                columns={liquidColumns}
                autoHeight
                hideFooter
              />
            </div>
          </Grid>
        </Grid>
        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={() => toggleClose()}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={policyData.length === 0 || !formik.dirty}
          >
            Save
          </Button>
        </Stack>
      </FormSection>
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}
