import SelectableView from '../../components/SelectableView';
import { Button, Stack, Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getIndividual,
  postLivingArrangement,
  getDocumentsFiles,
} from '../../utils/services/apiRequests';
import useLookup from '../../hooks/useLookup';
import { get } from 'lodash';
import Modal from '@mui/material/Modal';
import ActionButton from '../../components/ActionButtons';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import AddLivingArrangement from './AddLivingArrangement';
import { CloseRounded } from '@mui/icons-material';
import useLoader from '../../hooks/useLoader';
import usePageNav from '../../hooks/usePageNav';
import PageHeader from '../../components/PageHeader';
import { stateName } from '../../constants/commonConstants';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import DocumentUploadFilesDialog from '../Income/DocumentUploadFilesDialog';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IndividualDetailsTable from '../IndividualDetails/IndividualDetailsTable';
import DeleteModal from './DeleteModal';
import PageContainer from '../../components/PageContainer';
import usePage from '../../hooks/usePage';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function LivingArrangement({ id }) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const { navProps } = usePage(id);
  const [openDeleteModal, setOpenDeletModal] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [getIndividualId, setGetIndividualId] = useState('');
  const [editData, setEditData] = useState('');
  const { getOptions, getLkpMatch, lkpFlatten, lkpRaw } =
    useLookup('LivingArrangement');
  const [activeTab, setActiveTab] = useState(0);
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const [livingArrangements, setLivingArrangements] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [fetching, setFetching] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const { caseId, navigations } = usePageNav();
  const toggleEditModal = () => {
    setEditData('');
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = () => {
    setOpenDeletModal(!openDeleteModal);
  };

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    if (editData) {
      setOpenEditModal(true);
    }
  }, [editData]);

  useEffect(() => {
    handleapi();
  }, []);

  useEffect(() => {
    getUploadFiles();
  }, []);

  const onSavelivingArr = async (data, dirtyStatus) => {
    try {
      showLoader();

      const res = await postLivingArrangement(
        data,
        params.caseId,
        getIndividualId,
        dirtyStatus
      );

      if (res.status === 200) {
        handleapi(res.data);
        handleClose();
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully added new living arrangement');
          handleClose();
        } else if (dirtyStatus === 'update') {
          showSuccessToast('Successfully updated the living arrangement');
          setOpenEditModal(!openEditModal);
        } else {
          showSuccessToast('Successfully deleted the living arrangement');
          setOpenDeletModal(!openDeleteModal);
        }
      }
    } catch (error) {
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  const params = useParams();

  const { fosterAdoptionOpts, livingArrOpts, livingArrVerificationOpts } =
    useMemo(() => {
      return {
        livingArrOpts: getOptions('LivingArrangement'),
        fosterAdoptionOpts: getOptions('FosterCarAdoptType'),
        livingArrVerificationOpts: getOptions('LivingArrangementVerificatin'),
        documentsOpts: getOptions('Documents'),
        documentNameOpts: getOptions('Applications'),
      };
    }, [getOptions]);

  const handleEdit = (params) => {
    setEditData(params.row);
  };

  const handleDelete = (params) => {
    setOpenDeletModal(true);
    setDeleteData(params.row);
  };

  const columns = [
    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      width: 150,
    },
    {
      field: 'effectiveEndDate',
      headerName: 'End Date',
      width: 150,
      renderCell: (params) => {
        const endDate =
          params?.row?.effectiveEndDate === '9999-12-31'
            ? ''
            : params?.row?.effectiveEndDate;
        return endDate;
      },
    },
    {
      field: 'livingArrangement',
      headerName: 'Living Arrangement',
      renderCell: (params) =>
        getLkpMatch('LivingArrangement', params?.row?.livingArrangement),
      width: 240,
    },

    {
      field: 'verifiedYN',
      headerName: 'Verification',
      renderCell: (params) =>
        getLkpMatch(
          'YesNo',
          params?.row?.livingArrangementVerification?.verifiedYN
        ),
      width: 150,
    },

    {
      headerName: 'Action',
      field: 'action',
      width: 100,
      renderCell(params) {
        return (
          <span>
            <ActionButton
              onDelete={() => handleDelete(params)}
              onEdit={() => handleEdit(params)}
            />
          </span>
        );
      },
      headerName: 'Action',
    },
  ];

  const handleapi = async () => {
    try {
      setFetching(true);
      const res = await getIndividual(params.caseId);

      if (res.status === 200) {
        setLivingArrangements(res.data);
      }
    } catch (error) {
      console.error('System is not responding');
    } finally {
      setFetching(false);
    }
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const onNext = () => {
    navigations.toAuthorizedRep();
  };

  const onBack = () => {
    navigations.toContact();
  };

  const getRowId = (row) => {
    return row.recordId;
  };
  return (
    <PageContainer
      navProps={navProps}
      title="Living Arrangement"
      toVerify="LIVING_ARRANGEMENT"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={livingArrangements}
        loading={fetching}
        loadingContent={fetching}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        showEdit={false}
        showDelete={false}
      >
        {(selected) => {
          setGetIndividualId(selected.individualId);

          const livingArrangements = get(
            selected,
            'individualDetails.livingArrangements',
            []
          );

          return (
            <div>
              <TabPanel value={activeTab} index={0}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: '1rem', mb: '1rem' }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>

                  <Button onClick={() => setOpen(true)}>
                    + Add Living Arrangement
                  </Button>
                </Stack>
              </TabPanel>

              <IndividualDetailsTable
                columns={columns}
                rows={livingArrangements}
                getRowId={getRowId}
              />

              <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
                <DialogContent>
                  <AddLivingArrangement
                    addLiving
                    onSavelivingArr={onSavelivingArr}
                    handleClose={handleClose}
                    livingArrOpts={livingArrOpts}
                    livingArrVerificationOpts={livingArrVerificationOpts}
                    fosterAdoptionOpts={fosterAdoptionOpts}
                    livingArrangements={livingArrangements}
                    state={stateName}
                  />
                </DialogContent>
              </Dialog>

              <Dialog
                open={openEditModal}
                fullWidth
                maxWidth="md"
                onClose={toggleEditModal}
              >
                <DialogContent>
                  <AddLivingArrangement
                    editLiving
                    editData={editData}
                    onSavelivingArr={onSavelivingArr}
                    handleClose={toggleEditModal}
                    livingArrOpts={livingArrOpts}
                    livingArrVerificationOpts={livingArrVerificationOpts}
                    fosterAdoptionOpts={fosterAdoptionOpts}
                    livingArrangements={livingArrangements}
                    state={stateName}
                  />
                </DialogContent>
              </Dialog>

              <DocumentUploadFilesDialog
                docOpen={docOpen}
                setDocOpen={setDocOpen}
                isAdd={isAdd}
                setIsAdd={setIsAdd}
                caseId={params.caseId}
                lkpRaw={lkpRaw}
                member={selected}
                docmentFiles={docmentFiles}
              />

              <DeleteModal
                open={openDeleteModal}
                close={() => setOpenDeletModal(false)}
                deleteData={deleteData}
                fn={onSavelivingArr}
              />
            </div>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}

export default LivingArrangement;
