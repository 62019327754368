import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { cond } from '../../utils/helpers';

function Status({
  status,
  icon,
  invert,
  children,
  fullWdith,
  size,
  paddingZero,
  onlyIndicator,
}) {
  const isCompleted = status === 'completed';
  const isPending = status === 'pending';
  const isRejected = status === 'rejected';

  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isLarge = size === 'large';

  const textVariant = cond([
    [isSmall, 'caption'],
    [isMedium, 'body2'],
    [isLarge, 'subtitle2'],
  ]);

  if (invert) {
    return (
      <div
        className={cx(styles.statusInvert, {
          [styles.fullWidth]: fullWdith,
          [styles.paddingZero]: paddingZero,
        })}
      >
        <span
          className={cx(styles.stsIndicator, {
            [styles.completed]: isCompleted,
            [styles.pending]: isPending,
            [styles.rejected]: isRejected,
            [styles.rejected]: isRejected,
            [styles.small]: isSmall,
            [styles.medium]: isMedium,
            [styles.large]: isLarge,
          })}
        />
        <Typography variant={textVariant}>{children}</Typography>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.status, {
        [styles.completed]: isCompleted,
        [styles.pending]: isPending,
        [styles.rejected]: isRejected,
        [styles.fullWidth]: fullWdith,
      })}
    >
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      {!onlyIndicator && (
        <span className={styles.textWrapper} title={children}>
          <Typography variant={textVariant}>{children}</Typography>
        </span>
      )}
    </div>
  );
}

Status.propTypes = {
  status: PropTypes.oneOf(['completed', 'pending', 'rejected']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullWdith: PropTypes.bool,
  invert: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.node,
  paddingZero: PropTypes.bool,
  onlyIndicator: PropTypes.bool,
};

Status.defaultProps = {
  status: '',
  size: 'large',
  fullWdith: true,
  invert: false,
  children: '',
  icon: '',
  paddingZero: false,
  onlyIndicator: false,
};

export default Status;
