import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import styles from '../../index.module.css';

export default function CPAssets({
  data,
  getLkpMatch,
  hohMemberData,
  handleCheckbox,
}) {
  const getMemberNameByIndividualId = useCallback(
    (individualId) => {
      let result = hohMemberData?.find(
        (item) => item.dummyIndividualId === individualId
      );
      return `${result?.firstName ?? ''} ${result?.lastName ?? ''}`;
    },
    [data, hohMemberData]
  );

  const formatAddress = useCallback(
    (addressLine1, addressLine2, city, state, zipcode) => {
      const parts = [addressLine1, addressLine2, city, state, zipcode].filter(
        Boolean
      );
      return parts.join(', ');
    },
    [data]
  );

  return (
    <>
      {map(data, (item, index) => {
        const {
          assetType,
          assetDetailType,
          accountNumber,
          institutionName,
          vehicalUse,
          make,
          model,
          year,
          state,
          vin,
          assetAmounts,
          jointOwnerYN,
          termInd,
          use,
          address,
          transferDate,
          discoveryDate,
          amountReceived,
        } = item?.asset ?? {};
        const {
          amount,
          encumberedAmount,
          fareMarketValue,
          faceAmount,
          cashAmount,
        } = assetAmounts?.[0] ?? {};
        const {
          addressLine1,
          addressLine2,
          city,
          state: reState,
          zipcode,
        } = address ?? {};
        return (
          <Stack direction="row" pl={1}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Checkbox />}
              onChange={(event) => handleCheckbox(event, index)}
              checked={item?.importSelected}
            />
            <div className={styles.card}>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 1,
                      paddingBottom: '0.5rem',
                      border: 0,
                    },
                  }}
                >
                  <TableBody className={styles.tableBody}>
                    <TableRow
                      sx={{ borderBottom: '1px solid var(--grey-350)' }}
                    >
                      <TableCell>
                        <Typography variant="h6grey">
                          Individual Name
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="h6">
                          {getMemberNameByIndividualId(item?.individualId) ??
                            ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Asset Type</Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="subtitle2">
                          {getLkpMatch('AddAssetsAssetTypeParent', assetType)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          Asset Detail Type
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="subtitle2">
                          {assetType === '2' &&
                            getLkpMatch('Insurance', assetDetailType)}
                          {assetType === '1' &&
                            getLkpMatch('LiquidAssetType', assetDetailType)}
                          {assetType === '3' &&
                            getLkpMatch('VehicleType', assetDetailType)}
                          {assetType === '5' &&
                            getLkpMatch('PropertyTypeOther', assetDetailType)}
                          {assetType === '4' &&
                            getLkpMatch('RealPropertyUse', assetDetailType)}
                          {assetType === '6' &&
                            getLkpMatch('TransferOfAssetType', assetDetailType)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow></TableRow>
                    {assetType === '2' && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Institution Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {institutionName ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Cash Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {cashAmount ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">Face Value</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {faceAmount ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Jointly Owned
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {jointOwnerYN === 'Y'
                                ? 'Yes'
                                : jointOwnerYN === 'N'
                                ? 'No'
                                : 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">Is Term?</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {termInd === 'Y'
                                ? 'Yes'
                                : termInd === 'N'
                                ? 'No'
                                : 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {assetType === '1' && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Account Number
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {accountNumber ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Asset Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {amount ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Institution Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {institutionName ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Jointly Owned
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {jointOwnerYN === 'Y'
                                ? 'Yes'
                                : jointOwnerYN === 'N'
                                ? 'No'
                                : 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {assetType === '3' && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Vehicle Use
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {vehicalUse ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">Year</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {year ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">Make</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {make ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">Model</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {model ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">VIN</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {vin ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">State</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {state ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Encumbered Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {encumberedAmount ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Fare Market Value
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {fareMarketValue ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Jointly Owned
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {jointOwnerYN === 'Y'
                                ? 'Yes'
                                : jointOwnerYN === 'N'
                                ? 'No'
                                : 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {assetType === '5' && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Jointly Owned
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {jointOwnerYN === 'Y'
                                ? 'Yes'
                                : jointOwnerYN === 'N'
                                ? 'No'
                                : 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Encumbered Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {encumberedAmount ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Fare Market Value
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {fareMarketValue ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {assetType === '4' && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">Address</Typography>
                          </TableCell>
                          <TableCell colSpan={3}>
                            <Typography variant="subtitle2">
                              {formatAddress(
                                addressLine1,
                                addressLine2,
                                city,
                                reState,
                                zipcode
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Encumbered Amount
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {encumberedAmount ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Fare Market Value
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {fareMarketValue ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Jointly Owned
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {jointOwnerYN === 'Y'
                                ? 'Yes'
                                : jointOwnerYN === 'N'
                                ? 'No'
                                : 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">Use</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {use ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {assetType === '6' && (
                      <>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Transfer Date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {transferDate ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Discovery Date
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {discoveryDate ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Amount Received
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {amountReceived ?? 'NA'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6grey">
                              Fare Market Value
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {fareMarketValue ?? 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6grey">
                              Jointly Owned
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">
                              {jointOwnerYN === 'Y'
                                ? 'Yes'
                                : jointOwnerYN === 'N'
                                ? 'No'
                                : 'NA'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Stack>
        );
      })}
    </>
  );
}
