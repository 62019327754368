export const POST_HEALTHINSURANCE =
  'eeapi/application-process/interview/{0}/{1}/health-insurance';

export const POST_INSURANCE =
  'eeapi/application-process/interview/{0}/household/{1}/insurance-info';

export const POST_MEDICARE =
  'eeapi/application-process/interview/{0}/household/{1}/add-medicare';

export const GET_HEALTH_DISABILITY =
  'eeapi/application-process/interview/{0}/household/{1}/get-health-disability';

export const PUT_EDIT_MEDICARE =
  'eeapi/application-process/interview/{0}/household/{1}/edit-medicare';
