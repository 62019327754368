import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from './index.module.css';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import { noop } from 'lodash';

function NavButton({ onNext, onBack, disableNext, disableBack }) {
  return (
    <div>
      <Tooltip title="Back">
        <button
          disabled={disableBack}
          className={cx(styles.prevBtn, { [styles.disabled]: disableBack })}
          onClick={onBack}
        >
          <ChevronLeftIcon sx={{ color: 'white' }} />
        </button>
      </Tooltip>
      <Tooltip title="Next">
        <button
          disabled={disableNext}
          className={cx(styles.nextBtn, { [styles.disabled]: disableNext })}
          onClick={onNext}
        >
          <ChevronRightIcon sx={{ color: 'white' }} />
        </button>
      </Tooltip>
    </div>
  );
}

NavButton.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  disableNext: PropTypes.bool,
  disableBack: PropTypes.bool,
};

NavButton.defaultProps = {
  onNext: noop,
  onBack: noop,
  disableBack: false,
  disableNext: false,
};

export default NavButton;
