import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Stack,
  Container,
  Paper,
  Grid,
  styled,
  TextField,
  Button,
  Box,
  Checkbox,
  Collapse,
} from '@mui/material';
import programsList from './programs.json';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  map,
  reject,
  filter,
  includes,
  find,
  flatten,
  some,
  reduce,
  keys,
  max,
} from 'lodash';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import DateInfo from '../../../components/DateInfo';
import useCaseStatus from '../../../hooks/useCaseStatus';
import * as yup from 'yup';

const programPeriod = {
  LT: 12,
  WV: 12,
  MA: 6,
  FS: 2,
  CH: 2,
  EA: 2,
};
function getIdsObjs(pgs) {
  let fObj = {};
  for (let obj of pgs) {
    fObj[obj.value] = obj?.notValidWith ?? [];
    if (obj?.subPrograms) {
      fObj = {
        ...fObj,
        ...getIdsObjs(obj.subPrograms),
      };
    }
  }
  return fObj;
}

function groupSubPrograms(pgs) {
  return reduce(
    pgs,
    (groupObj, p) => {
      const subPgs = p.subPrograms ? map(p.subPrograms, (sp) => sp.value) : [];
      groupObj[p.value] = subPgs ?? [];
      return groupObj;
    },
    {}
  );
}

const [today, currentMonth, currentYear] = dayjs(new Date())
  .format('DD-MMMM-YYYY')
  .split('-');

const notValidIdsObj = getIdsObjs(programsList);
const mainPrograms = map(programsList, (p) => p.value);
const groupedPgs = groupSubPrograms(programsList);
const isMainPg = (p) => {
  return includes(keys(groupedPgs), p);
};

function Register(props) {
  const { getCaseStatus } = useCaseStatus();
  const navigate = useNavigate();
  const state = useOutletContext();
  const [selectedPgs, setSelectedPgs] = useState([]);
  const [notValidIds, setNotValidIds] = useState([]);
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [ERRORMSG_DATE, setErrorDate] = useState('');

  const onSelectPg = (e) => {
    const { name, checked } = e.target;
    const unChecked = !checked;
    const isMainProgram = isMainPg(name);

    const newIds = checked
      ? [...selectedPgs, name]
      : reject(selectedPgs, (p) => p === name);
    const inValidPgs = map(newIds, (id) => notValidIdsObj[id]);
    let inValidPgsIds = flatten(inValidPgs);

    if (unChecked && isMainProgram) {
      inValidPgsIds = reject(inValidPgs, (p) => includes(groupedPgs[name], p));
    }

    const filteredPgs = reject(
      newIds,
      (p) =>
        includes(inValidPgsIds, p) ||
        (unChecked && isMainProgram && includes(groupedPgs[name], p))
    );

    setNotValidIds(inValidPgsIds);
    setSelectedPgs(filteredPgs);
    if (!e.target.checked && selectedPgs.length === 1) {
      let date = dayjs().format('YYYY-MM-DD');
      setErrorDate('');
      setDate(date);
    }
  };

  const onDateChange = (date) => {
    let MAXVAL = 0;
    let MAXOBJ = '';

    selectedPgs.forEach((item, _) => {
      if (programPeriod[item]) {
        MAXVAL = Math.max(MAXVAL, programPeriod[item]);

        MAXOBJ =
          MAXVAL === programPeriod[item] || selectedPgs.length === 1
            ? programsList.find((obj) => obj.value === item).label
            : MAXOBJ;
      }
    });
    const MINDATE = dayjs().subtract(setMIinDate(), 'month');

    if (date <= dayjs() && date >= MINDATE) {
      setDate(dayjs(date).format('YYYY-MM-DD'));

      setErrorDate('');
    } else {
      if (date < MINDATE) {
        setErrorDate(
          `For ${MAXOBJ} Application date cannot be older than ${
            MAXVAL * 30
          } days .`
        );
      } else if (date > dayjs()) {
        setErrorDate(`For ${MAXOBJ} Application date  shouldn't be in future.`);
      }
    }
  };

  useEffect(() => {}, []);
  const onNext = () => {
    const selectedMainPgms = filter(selectedPgs, (p) =>
      includes(mainPrograms, p)
    );
    const groupedPrograms = map(selectedMainPgms, (programName) => {
      const subPrograms = find(programsList, (p) => p.subPrograms)?.subPrograms;
      if (!subPrograms) {
        return { programName };
      }

      const programSubType = filter(subPrograms, (p) =>
        includes(selectedPgs, p.value)
      );

      return {
        programName,
        programSubType: programSubType.length
          ? map(programSubType, (p) => p.value)
          : null,
      };
    });
    navigate('/dashboard/application/customer-registration', {
      state: {
        programs: groupedPrograms,
        date: date,
      },
    });
  };

  const setMIinDate = () => {
    let MaxVal = 0;
    selectedPgs.forEach((item, _) => {
      if (programPeriod[item]) {
        MaxVal = Math.max(MaxVal, programPeriod[item]);
      }
    });

    return MaxVal;
  };

  return (
    <div className={styles.register}>
      <Grid container spacing={2}>
        <Grid item lg={4} xs={12} md={4}>
          <Container component={Paper} className={styles.section}>
            <Typography variant="h5">
              Application Date{' '}
              <span style={{ color: 'var(--error-500)' }}> *</span>
            </Typography>
            <DatePicker
              sx={{
                marginTop: 4,
                marginBottom: ERRORMSG_DATE ? 1 : 6,
              }}
              maxDate={dayjs()}
              minDate={dayjs().subtract(setMIinDate(), 'month')}
              requried
              value={dayjs(date)}
              disabled={!selectedPgs.length}
              name="datePick"
              onChange={onDateChange}
            />

            <Typography
              variant="body2"
              style={{ color: 'var(--error-500)', marginBottom: 3 }}
            >
              {ERRORMSG_DATE}
            </Typography>

            <Paper>
              <DateInfo
                time="Today"
                date={today}
                month={currentMonth}
                year={currentYear}
              />
            </Paper>
          </Container>
        </Grid>
        <Grid item lg={8} xs={12} md={8}>
          <Container component={Paper} className={styles.section}>
            <Typography variant="h5" marginBottom={4}>
              Programs<span style={{ color: 'var(--error-500)' }}> *</span>
            </Typography>
            <Box>
              {map(programsList, ({ label, value, disabled, subPrograms }) => {
                return (
                  <Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h5">{label}</Typography>
                      <Checkbox
                        disabled={disabled || includes(notValidIds, value)}
                        name={value}
                        checked={includes(selectedPgs, value)}
                        onChange={onSelectPg}
                      />
                    </Stack>
                    {subPrograms &&
                      map(subPrograms, (subPg) => {
                        return (
                          <Collapse in={includes(selectedPgs, value)}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              paddingLeft={2}
                              paddingRight={2}
                            >
                              <Typography variant="h6">
                                {subPg.label}
                              </Typography>
                              <Checkbox
                                name={subPg.value}
                                onChange={onSelectPg}
                                checked={includes(selectedPgs, subPg.value)}
                                disabled={includes(notValidIds, subPg.value)}
                              />
                            </Stack>
                          </Collapse>
                        );
                      })}
                  </Box>
                );
              })}
            </Box>
          </Container>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        marginTop={2}
      >
        <Button variant="outlined">Back</Button>
        <Button
          disabled={!selectedPgs.length || ERRORMSG_DATE}
          variant="contained"
          onClick={onNext}
        >
          Next
        </Button>
      </Stack>
    </div>
  );
}

Register.propTypes = {};

export default Register;
