import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Typography } from '@mui/material';
import TabularView from '../../../components/TabularView';
import {
  getRelationList,
  saveRelationShip,
} from '../../../utils/services/apiRequests';
import { find, map, concat, reject, size, some, noop } from 'lodash';
import Dropdown from '../../../components/Dropdown';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { Stack } from '@mui/system';
import RelationSkl from './RelationSkl';
import { useFormik } from 'formik';

function RelationType({
  caseId,
  genderLkp,
  houseMembers,
  selected,
  relationOptions,
  options,
}) {
  const formik = useFormik({
    initialValues: {
      relations: [],
    },
    onSubmit: null,
  });
  const { relations } = formik.values;
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const { onDown = noop } = options;

  const userList = useMemo(() => {
    return map(
      houseMembers,
      ({ householdMemberId, individualId, gender, personName }) => ({
        householdMemberId,
        individualId,
        gender: genderLkp[gender],
        name: `${personName?.firstName} ${personName?.lastName}`,
      })
    );
  }, [genderLkp, houseMembers]);
  const { individualId: currentUserId, householdMemberId } = selected;

  const onRelationChange = (prop, value, index) => {
    const newRelations = Object.assign([], relations, {
      [index]: {
        ...relations[index],
        [prop]: value,
      },
    });

    formik.setFieldValue('relations', newRelations);
  };

  const columns = useMemo(() => {
    return [
      {
        field: '',
        headerName: 'Relation',
        renderCell: (params, index) => {
          return (
            <Dropdown
              value={params.relationshipCd}
              onChange={(e) =>
                onRelationChange('relationshipCd', e.target.value, index)
              }
              options={relationOptions}
            />
          );
        },
      },
      {
        field: '',
        headerName: 'Name',
        renderCell: (params = {}) => {
          const { individualId } = params;
          const user = find(userList, { individualId }) ?? {};
          return (
            <Typography>
              <strong>{user.name}</strong> ({user.gender})
            </Typography>
          );
        },
      },
      {
        field: '',
        headerName: 'Care Taker',
        renderCell: (params, index) => (
          <Checkbox
            checked={params.specifiedCaretakerYN}
            onChange={(e, checked) =>
              onRelationChange('specifiedCaretakerYN', checked, index)
            }
          />
        ),
      },
      {
        field: '',
        headerName: 'SNAP Together / Prepare Together',
        renderCell: (params, index) => (
          <Checkbox
            checked={params.purchasePrepareTogetherYN}
            onChange={(e, checked) =>
              onRelationChange('purchasePrepareTogetherYN', checked, index)
            }
          />
        ),
      },
    ];
  }, [relationOptions, userList, relations]);

  const fetchHouseMembers = async (id) => {
    try {
      setFetching(true);
      const res = await getRelationList(caseId, id);

      if (res.status === 200) {
        const relationArr = map(res.data?.relations, (relation) => {
          const {
            relationshipCd,
            individualId,
            specifiedCaretakerYN,
            purchasePrepareTogetherYN,
            sponserIndYN,
            recordId,
            gender,
          } = relation;

          return {
            householdMemberId,
            relationshipCd,
            individualId,
            specifiedCaretakerYN: specifiedCaretakerYN === 'Y',
            purchasePrepareTogetherYN: purchasePrepareTogetherYN === 'Y',
            sponserIndYN,
            recordId,
            gender,
          };
        });

        if (size(relationArr) !== size(userList) - 1) {
          const filteredList = reject(
            userList,
            ({ individualId }) =>
              some(
                relationArr,
                (relation) => relation.individualId === individualId
              ) || individualId === currentUserId
          );

          const relationNotMapped = map(
            filteredList,
            ({
              householdMemberId,
              relationshipCd = '',
              individualId,
              specifiedCaretakerYN = false,
              purchasePrepareTogetherYN = false,
              sponserIndYN = false,
              recordId,
              gender,
            }) => ({
              householdMemberId,
              relationshipCd,
              individualId,
              specifiedCaretakerYN,
              purchasePrepareTogetherYN,
              sponserIndYN,
              recordId,
              gender,
            })
          );

          formik.resetForm({
            values: {
              relations: concat(relationArr, relationNotMapped),
            },
          });
        } else {
          formik.resetForm({
            values: {
              relations: relationArr,
            },
          });
        }
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (currentUserId) {
      fetchHouseMembers(selected.individualId);
    }
  }, [selected.individualId]);

  const onSaveRelations = async () => {
    try {
      setSaving(true);
      const res = await saveRelationShip(caseId, relations, currentUserId);

      if (res.status === 200) {
        showSuccessToast('Relationships Added');
        fetchHouseMembers(currentUserId);
        onDown();
      }
    } catch (error) {
      showErrorToast();
    } finally {
      setSaving(false);
    }
  };

  return fetching ? (
    <RelationSkl />
  ) : (
    <Stack sx={{ height: '100%' }}>
      <TabularView
        key={selected.individualId}
        columns={columns}
        data={relations}
        headerComponent={{ variant: 'h5', color: 'var(--grey-400)' }}
      />
      <Stack p="1rem" mt="auto" direction="row" justifyContent="flex-end">
        <Button
          sx={{ px: '2rem' }}
          variant="contained"
          onClick={onSaveRelations}
          disabled={saving || !formik.dirty}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
}

RelationType.propTypes = {};

export default RelationType;
