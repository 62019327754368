import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormGroup from '../../../components/FormGroup';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';

const HealthForm = ({ getOptions, formik }) => {
  const {
    disabilityApprovalSource,
    disabilityStatus,
    tdapType,
    disabilityBeginDate,
  } = formik.values;
  const {
    disabilityTypeOpts,
    disabilityApprovalSourceOpts,
    disabilityStatusOpts,
    TdapTypeOpts,
    disabilityVerificationOpts,
  } = useMemo(() => {
    return {
      disabilityTypeOpts: getOptions('DisabilityType'),
      disabilityApprovalSourceOpts: getOptions('DisabilityApprovalSource'),
      disabilityStatusOpts: getOptions('DisabilityStatus'),
      TdapTypeOpts: getOptions('TDAPType'),
      disabilityVerificationOpts: getOptions('DisabilityVerification'),
    };
  }, [getOptions]);

  const statusFilterOpts = useMemo(() => {
    const filterCriteria = {
      AA: ['AP', 'DE'],
      HO: ['AP', 'DE'],
      NV: ['AP', 'DE'],
      RR: ['AP', 'DE'],
      RS: ['AP', 'DE'],
      VA: ['AP', 'DE'],
      DD: ['AP', 'DE', 'DD'],
      HP: ['AP', 'DE', 'PE'],
      UC: ['AP', 'DE', 'PE'],
      MS: ['AP', 'DE', 'SR'],
    };

    if (disabilityApprovalSource in filterCriteria) {
      return disabilityStatusOpts.filter((option) =>
        filterCriteria[disabilityApprovalSource].includes(option.value)
      );
    }

    return disabilityStatusOpts;
  }, [disabilityApprovalSource, disabilityStatusOpts]);

  const handleChangeDropDown = (event) => {
    const { value } = event.target;
    formik.setFieldValue('disabilityApprovalSource', value);
    formik.setFieldValue('disabilityStatus', '');
  };

  return (
    <FormGroup label="Disability & Incapacity Information">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field name="disabilityType" label="Type" required formik={formik}>
            <Dropdown options={disabilityTypeOpts} placeholder="Select Type" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="disabilityApprovalSource"
            label="Verification Source"
            required
            formik={formik}
            customHandler
          >
            <Dropdown
              options={disabilityApprovalSourceOpts}
              placeholder="Select Source"
              onChange={handleChangeDropDown}
              value={disabilityApprovalSource}
            />
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="disabilityStatus"
            label="Status"
            required
            formik={formik}
          >
            <Dropdown options={statusFilterOpts} placeholder="Select Status" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="tdapType"
            label="Length Of Disability"
            formik={formik}
            required={disabilityStatus === 'AP'}
          >
            <Dropdown options={TdapTypeOpts} placeholder="Select Disability" />
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="statusDate"
            label="Status Date"
            formik={formik}
            required
            isDate
          >
            <DatePicker format="YYYY/MM/DD" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="interimAssistanceFormSigningDate"
            label="Interim Assistance Sign Date"
            formik={formik}
            required
            isDate
          >
            <DatePicker format="YYYY/MM/DD" />
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="verifiedYON"
            label="IAR Disability Verification"
            formik={formik}
            required
          >
            <Dropdown
              options={disabilityVerificationOpts}
              placeholder="Select Verification"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="referralDate"
            label="Referral Date"
            formik={formik}
            required
            isDate
          >
            <DatePicker format="YYYY/MM/DD" />
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="disabilityBeginDate"
            label="Disability Begin Date"
            formik={formik}
            isDate
            required={disabilityStatus === 'AP' || ['1', '2'].includes(tdapType) }
          >
            <DatePicker format="YYYY/MM/DD" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="disabilityEndDate"
            label="Disability End Date"
            formik={formik}
            isDate
            required={['1', '2'].includes(tdapType)}
          >
            <DatePicker format="YYYY/MM/DD" />
          </Field>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default HealthForm;
