import React, { useMemo } from 'react';
import {
  Box,
  Dialog,
  Stack,
  Button,
  Grid,
  Typography,
  DialogContent,
  DialogTitle,
  IconButton,
  FormLabel,
} from '@mui/material';
import * as yup from 'yup';
import { Formik, FieldArray, Form, Field, ErrorMessage } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../components/Dropdown';
import useLookup from '../../../hooks/useLookup';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { DeleteOutlined, Extension } from '@mui/icons-material';
import { showWarningToast } from '../../../utils/services/toast';
import dayjs from 'dayjs';

const SelectedProgramTimeLimit = ({
  open,
  programData,
  setOpen,
  title,
  HandleSumbitCase,
  addExtention,
}) => {
  const { getOptions } = useLookup('programTimeLimit');

  const { extensionType, verification, state, statusOption } = useMemo(() => {
    let statusOption = [];
    switch (title) {
      case 'ABAWD': {
        statusOption = getOptions('abwdtkstscd');
        break;
      }
      case 'Child Under One':
      case 'Time On Assistance': {
        statusOption = getOptions('tcatkstscd');
        break;
      }
      default: {
        statusOption = [];
      }
    }
    return {
      extensionType: getOptions('tmclkrmdrs'),
      verification: getOptions('vtmclkverf'),
      state: getOptions('State'),
      statusOption: statusOption,
    };
  }, [getOptions]);

  const handleValidations = yup.object({
    state: yup.string().required(),
    status: yup.string().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required('End date is Required field'),
    addTimeClockQtnsList: yup.array().of(
      yup.object({
        qtnsCd: yup.string().required('Extension Type is Required field'),
        verificationCd: yup.object({
          verificationSourceCode: yup
            .string()
            .required('Verification is required field'),
        }),
      })
    ),
  });

  const custonName = (name) => (
    <FormLabel sx={{ color: '#000000', textAlign: 'left' }}>
      {name} <span style={{ color: 'var(--error-500)' }}>*</span>
    </FormLabel>
  );

  return (
    <Box>
      <Dialog
        open={open}
        maxWidth="xl"
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Typography variant="h2" style={{ padding: '1rem 1rem 0rem 0.4rem' }}>
            {`${title} Program`}
          </Typography>
        </DialogTitle>

        <DialogContent
          style={{
            width: '50rem',
          }}
        >
          <Box
            style={{ padding: '1rem', border: '1px solid  var(--grey-250)' }}
          >
            <Formik
              enableReinitialize={true}
              validationSchema={handleValidations}
              initialValues={{
                state: programData?.staCd ?? '',
                status: programData?.clkStsCd ?? '',
                startDate: programData?.clkStsBgnDt
                  ? dayjs(programData?.clkStsBgnDt)
                  : '',
                endDate: programData?.clkStsBgnDt
                  ? dayjs(programData?.clkStsBgnDt)
                  : '',
                addTimeClockQtnsList: programData?.addTimeClockQtnsList?.length
                  ? programData.addTimeClockQtnsList
                  : title === 'Child Under One'
                  ? []
                  : [
                      {
                        qtnsCd: '',
                        verificationCd: {
                          verificationSourceCode: '',
                        },
                      },
                    ],
              }}
              onSubmit={HandleSumbitCase}
            >
              {({ values, errors, touched }) => {
                return (
                  <Form>
                    <FieldArray
                      name="addTimeClockQtnsList"
                      render={(arrayHelpers) => (
                        <>
                          <Grid container xs={12} spacing={2} mb={2}>
                            <Grid item xs={6}>
                              {custonName('Start Date')}
                              <Field
                                required
                                name="startDate"
                                label="Effective Begin Date"
                                formikHandler={false}
                                isDate
                              >
                                {({ field, form }) => (
                                  <DatePicker
                                    selected={field.value}
                                    error={
                                      errors?.startDate && touched?.startDate
                                    }
                                    isDate
                                    value={values.startDate}
                                    onChange={(date) =>
                                      form.setFieldValue('startDate', date)
                                    }
                                    onBlur={field.onBlur}
                                  />
                                )}
                              </Field>
                              {errors?.startDate && touched?.startDate && (
                                <div style={{ color: 'var(--error-500)' }}>
                                  {errors?.startDate}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              {custonName('End Date')}
                              <Field
                                name="endDate"
                                label="Effective End Date"
                                isDate
                                formikHandler={false}
                              >
                                {({ field, form }) => (
                                  <DatePicker
                                    selected={field.value}
                                    error={errors?.endDate && touched?.endDate}
                                    isDate
                                    value={values.endDate}
                                    onChange={(date) =>
                                      form.setFieldValue('endDate', date)
                                    }
                                    onBlur={field.onBlur}
                                  />
                                )}
                              </Field>
                              {errors?.endDate && touched?.endDate && (
                                <div style={{ color: 'var(--error-500)' }}>
                                  {errors?.endDate}
                                </div>
                              )}
                            </Grid>
                          </Grid>

                          <Grid container xs={12} spacing={2}>
                            <Grid item xs={6}>
                              {custonName('State')}
                              <Field name="state" type="" label="State">
                                {({ form, field }) => {
                                  return (
                                    <Dropdown
                                      label="State"
                                      error={errors?.state && touched?.state}
                                      options={state}
                                      placeholder="State"
                                      selected={field.value}
                                      value={values.state}
                                      onChange={(e, val) => {
                                        form.setFieldValue(
                                          'state',
                                          e.target.value
                                        );
                                      }}
                                    ></Dropdown>
                                  );
                                }}
                              </Field>

                              {errors?.state && touched?.state && (
                                <div style={{ color: 'var(--error-500) ' }}>
                                  {errors?.state}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              {statusOption.length > 0 && (
                                <>
                                  {custonName('Status')}
                                  <Field name="status" label="Status">
                                    {({ form, field }) => {
                                      return (
                                        <Dropdown
                                          error={
                                            errors?.status && touched?.status
                                          }
                                          label="Status"
                                          placeholder="Status"
                                          options={statusOption}
                                          selected={field.value}
                                          value={values.status}
                                          onChange={(e, val) => {
                                            form.setFieldValue(
                                              'status',
                                              e.target.value
                                            );
                                          }}
                                        ></Dropdown>
                                      );
                                    }}
                                  </Field>
                                  {errors.status && touched.status && (
                                    <div style={{ color: 'var(--error-500) ' }}>
                                      {errors.status}
                                    </div>
                                  )}
                                </>
                              )}
                            </Grid>
                          </Grid>
                          {addExtention && (
                            <>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{
                                  mt: '1rem',
                                  mb: '1rem',
                                }}
                              >
                                <Typography variant="h6">
                                  Time Limit Extension
                                </Typography>

                                <Button
                                  onClick={() => {
                                    arrayHelpers.push({
                                      qtnsCd: '',
                                      verificationCd: {
                                        providedVerificationId: '',
                                        verificationSourceCode: '',
                                      },
                                    });
                                  }}
                                >
                                  + Add Extension
                                </Button>
                              </Stack>
                              <Box
                                sx={{
                                  border: '1px solid  var(--grey-250)',
                                  borderRadius: '5px',
                                  padding: '0.5rem 0 0 1rem',
                                }}
                              >
                                {values.addTimeClockQtnsList.map(
                                  (objValue, index) => {
                                    return (
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={4}
                                          style={{ margin: '0.5rem 0.5rem' }}
                                        >
                                          <Field
                                            name={`addTimeClockQtnsList[${index}].qtnsCd`}
                                            label="Extension"
                                          >
                                            {({ form, field }) => {
                                              return (
                                                <Dropdown
                                                  options={extensionType}
                                                  selected={field.value}
                                                  placeholder="Extension type"
                                                  error={
                                                    errors
                                                      ?.addTimeClockQtnsList?.[
                                                      index
                                                    ]?.qtnsCd &&
                                                    touched
                                                      ?.addTimeClockQtnsList?.[
                                                      index
                                                    ]?.qtnsCd
                                                  }
                                                  value={
                                                    values
                                                      ?.addTimeClockQtnsList?.[
                                                      index
                                                    ]?.qtnsCd ?? ''
                                                  }
                                                  onChange={(e, val) => {
                                                    form.setFieldValue(
                                                      `addTimeClockQtnsList[${index}].qtnsCd`,
                                                      e.target.value
                                                    );
                                                  }}
                                                ></Dropdown>
                                              );
                                            }}
                                          </Field>
                                          {errors?.addTimeClockQtnsList?.[index]
                                            ?.qtnsCd &&
                                            touched?.addTimeClockQtnsList?.[
                                              index
                                            ]?.qtnsCd && (
                                              <div
                                                style={{
                                                  color: 'var(--error-500) ',
                                                }}
                                              >
                                                {
                                                  errors
                                                    ?.addTimeClockQtnsList?.[
                                                    index
                                                  ]?.qtnsCd
                                                }
                                              </div>
                                            )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          style={{ margin: '0.5rem 0.5rem' }}
                                        >
                                          <Field
                                            name={`addTimeClockQtnsList[${index}].verificationCd.verificationSourceCode`}
                                            label="Verification"
                                          >
                                            {({ form, field }) => {
                                              return (
                                                <Dropdown
                                                  options={verification}
                                                  selected={field.value}
                                                  error={
                                                    errors
                                                      ?.addTimeClockQtnsList?.[
                                                      index
                                                    ]?.verificationCd
                                                      ?.verificationSourceCode &&
                                                    touched
                                                      ?.addTimeClockQtnsList?.[
                                                      index
                                                    ]?.verificationCd
                                                      ?.verificationSourceCode
                                                  }
                                                  placeholder="Verification"
                                                  value={
                                                    values
                                                      .addTimeClockQtnsList?.[
                                                      index
                                                    ]?.verificationCd
                                                      ?.verificationSourceCode
                                                  }
                                                  onChange={(e, val) => {
                                                    form.setFieldValue(
                                                      `addTimeClockQtnsList[${index}].verificationCd.verificationSourceCode`,
                                                      e.target.value
                                                    );
                                                  }}
                                                ></Dropdown>
                                              );
                                            }}
                                          </Field>
                                          {errors?.addTimeClockQtnsList?.[index]
                                            ?.verificationCd
                                            ?.verificationSourceCode &&
                                            touched?.addTimeClockQtnsList?.[
                                              index
                                            ]?.verificationCd
                                              ?.verificationSourceCode && (
                                              <div
                                                style={{
                                                  color: 'var(--error-500) ',
                                                }}
                                              >
                                                {
                                                  errors
                                                    ?.addTimeClockQtnsList?.[
                                                    index
                                                  ]?.verificationCd
                                                    ?.verificationSourceCode
                                                }
                                              </div>
                                            )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          style={{ margin: '0.5rem 0.5rem' }}
                                        >
                                          <IconButton>
                                            <UploadFileIcon />
                                          </IconButton>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          style={{ margin: '0.5rem 0.5rem' }}
                                        >
                                          <IconButton
                                            onClick={() => {
                                              values.addTimeClockQtnsList
                                                .length > 1
                                                ? arrayHelpers.remove(index)
                                                : showWarningToast(
                                                    'Atleast one verication and Extension type to be added'
                                                  );
                                            }}
                                          >
                                            <DeleteOutlined
                                              style={{
                                                color: 'var(--error-500) ',
                                              }}
                                            />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                      //  </Box>
                                    );
                                  }
                                )}
                              </Box>
                            </>
                          )}
                          <Stack
                            direction={'row'}
                            spacing={1}
                            justifyContent={'flex-end'}
                            mt={2}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => setOpen(false)}
                            >
                              Discard
                            </Button>
                            <Button variant="contained" type="submit">
                              Save
                            </Button>
                          </Stack>
                        </>
                      )}
                    ></FieldArray>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SelectedProgramTimeLimit;
