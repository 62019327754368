import React, { useState, useEffect, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddEditPotentialIncomeDetailsDialog from './AddEditPotentialIncomeDetailsDialog';
import InComeDataGrid from '../InComeDataGrid';
import DocumentUploadFilesDialog from '../DocumentUploadFilesDialog';
import { savePotentialIncome } from '../../../utils/services/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { find } from 'lodash';
import DeleteConfiramation from '../DeleteConfiramation';
import useLoader from '../../../hooks/useLoader';

const PotentialIncome = ({
  member,
  setOpen,
  open,
  isAdd,
  setIsAdd,
  docOpen,
  setDocOpen,
  lkpRaw,
  lkpFlatten,
  getLkpMatch,
  fetchIncome,
  caseId,
  docmentFiles,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [selectedData, setSelectedData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const columns = [
    {
      field: 'anticipatedReceiptDt',
      headerName: 'Anticipated Receipt Date',
      width: 220,
    },
    {
      field: 'assetType',
      headerName: 'Potential Income Type',
      width: 200,
    },
    {
      field: 'attorneyName',
      headerName: 'Attorney Name',
      width: 200,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleEdit = (rowData) => {
    setOpen(true);
    setIsAdd(false);
    const getRow = find(member.potentialAssets, { recordId: rowData.recordId });
    setSelectedData(getRow);
  };

  const handleDelete = async (rowData) => {
    const getRow = find(member.potentialAssets, { recordId: rowData.recordId });
    setSelectedData(getRow);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      showLoader();
      const res = await savePotentialIncome(
        caseId,
        selectedData,
        member.individualId,
        isAdd,
        true
      );
      if (res.status === 200) {
        showSuccessToast('Potential Income Successfully Deleted');
        fetchIncome();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const getRowId = (row) => {
    return row.recordId;
  };

  const gridData = useMemo(() => {
    if (!member || !member.potentialAssets) {
      return [];
    }

    return member.potentialAssets.map((income) => {
      const { anticipatedReceiptDt, assetType, attorneyName, recordId } =
        income;

      return {
        recordId: recordId,
        assetType: getLkpMatch('PotentialAssetType', assetType),
        anticipatedReceiptDt,
        attorneyName,
      };
    });
  }, [member]);

  return (
    <>
      <InComeDataGrid
        rows={gridData}
        columns={columns}
        loading={false}
        getRowId={getRowId}
      />
      <AddEditPotentialIncomeDetailsDialog
        setOpen={setOpen}
        open={open}
        isAdd={isAdd}
        lkpRaw={lkpRaw}
        member={member}
        selectedData={selectedData}
        fetchIncome={fetchIncome}
        caseId={caseId}
      />
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        lkpRaw={lkpRaw}
        member={member}
        caseId={caseId}
        docmentFiles={docmentFiles}
      />
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default PotentialIncome;
