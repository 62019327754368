import React, { useEffect, useState } from 'react';
import {
  getNoncomplianceDashboard,
  deleteNonconplianceRecord,
} from '../../../utils/services/NonComplianceDetails/apiRequest';
import ActionButtons from '../../../components/ActionButtons';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { Stack } from '@mui/material';
import useLookup from '../../../hooks/useLookup';
import NonComplianceEvents from './NonComplianceEvents';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import DeleteConfiramation from '../DeleteConfiramation';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

const NonComplianceContent = ({ selected, caseId }) => {
  const { getLkpMatch, getOptions, lkpFlatten, lkpRaw } =
    useLookup('NonCompliane');
  const [dataChanged, setDataChanged] = useState(false);
  const [events, setEvents] = useState('insert');
  const [open, setOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState('');
  const [choosedEdit, setChoosedEdit] = useState({});
  const [alllistDatasa, setAllListData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [columns, setColumns] = useState([]);
  const [docOpen, setDocOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(null);

  const rows = [
    { field: 'nonCmplncBgnDt', headerName: 'Begin Date', width: 100, id: 1 },
    { field: 'nonCmplncEndDt', headerName: 'End Date', width: 100, id: 2 },
    { field: 'programCode', headerName: 'Program', width: 100, id: 3 },
    { field: 'cureComply', headerName: 'Cure Comply', width: 140, id: 4 },
    { field: 'typeCd', headerName: 'Type', width: 100, id: 5 },
    {
      field: 'action',
      id: 6,
      headerName: 'Action',
      width: 100,
      renderCell(params) {
        return (
          <span>
            <ActionButtons
              onEdit={() => eidtThisRecod(params)}
              onDelete={() => deleteThisRecord(params)}
            />
          </span>
        );
      },
    },
  ];

  const deleteThisRecord = (params) => {
    setOpenDeleteDialog(true);
    const todelete = alllistDatasa.find(
      (obj) => obj.clNonCmplncId === params.id
    );
    setToBeDeleted({ ...todelete, dirtyStatus: 'delete' });
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await deleteNonconplianceRecord(
        caseId,
        selected.individualId,
        toBeDeleted
      );
      if (res.status === 200) {
        setOpenDeleteDialog(false);
        setDataChanged(!dataChanged);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const eidtThisRecod = (params) => {
    setEvents('update');
    setOpen(true);
    const toEditRecord = alllistDatasa.find(
      (obj) => obj.clNonCmplncId === params.id
    );
    setChoosedEdit(toEditRecord);
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const getDashboard = async () => {
    try {
      const res = await getNoncomplianceDashboard(
        caseId,
        parseInt(selected.individualId)
      );
      if (res.status === 200) {
        setAllListData(res.data);
        const columnData = res.data?.map((obj) => {
          return {
            id: obj.clNonCmplncId,
            nonCmplncBgnDt: dayjs(obj.nonCmplncBgnDt).format('DD-MM-YYYY'),
            nonCmplncEndDt: dayjs(obj.nonCmplncEndDt).format('DD-MM-YYYY'),
            programCode: getLkpMatch('Program', obj.programCode),
            cureComply: getLkpMatch('YesNo', obj.cureComply),
            typeCd:
              obj.programCode === 'FS'
                ? getLkpMatch(
                    'NonComplianceSNAPReasonCodesAndDescriptions',
                    obj.typeCd
                  )
                : getLkpMatch('NonComplianceType'),
          };
        });
        setColumns(columnData);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  useEffect(() => {
    getDashboard();
  }, [selected, dataChanged]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" mt={2}>
        <Button variant="outlined" onClick={handleOpenDocModal}>
          Upload Files
        </Button>
        <Button
          onClick={() => {
            setOpen(true);
            setChoosedEdit({});
            setEvents('insert');
          }}
          startIcon={<AddIcon />}
        >
          Add Non-Compliance
        </Button>
      </Stack>
      <DataGrid
        sx={{
          marginTop: 2,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 16,
            fontWeight: 700,

            textAlign: 'center',
            color: 'black',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '30vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '30vh',
          },
          minHeight: '30vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Please click and Add Non-Compliance button add the details"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        hideFooter
        rows={columns ?? []}
        columns={rows ?? []}
      />
      {open && (
        <NonComplianceEvents
          getOptions={getOptions}
          setOpen={setOpen}
          caseId={caseId}
          individualId={selected.individualId}
          data={choosedEdit}
          events={events}
          getLkpMatch={getLkpMatch}
          dataChanged={dataChanged}
          setDataChanged={setDataChanged}
        />
      )}
      {openDeleteDialog && (
        <DeleteConfiramation
          openDeleteDialog={openDeleteDialog}
          handleCancelDelete={handleCancelDelete}
          handleConfirmDelete={handleConfirmDelete}
        />
      )}
      <DocumentUploadFilesDialog
        member={selected}
        lkpFlatten={lkpFlatten}
        getOptions={getOptions}
        getLkpMatch={getLkpMatch}
        lkpRaw={lkpRaw}
        setOpen={setOpen}
        open={open}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        docOpen={docOpen}
        setDocOpen={setDocOpen}
      />
    </Box>
  );
};

export default NonComplianceContent;
