import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { includes, map, reject } from 'lodash';
import { Stack, Typography } from '@mui/material';
import cx from 'classnames';
import styles from './index.module.css';
import Avatar from '../../Avatar';
import AddButton from '../../AddButton';

function UserMenu({ options, onAdd, value, setOpen }) {
  const [selected, setSelected] = useState(value);
  const handleSelect = (id) => {
    const isAdded = includes(selected, id);
    if (isAdded) {
      setSelected((prev) => reject(prev, (pId) => pId === id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleAdd = () => {
    onAdd(selected);
    setOpen(false);
  };

  return (
    <>
      <ul className={styles.listContainer}>
        {map(options, ({ label, value }, i) => {
          const isSelected = includes(selected, value);
          return (
            <Stack
              component="li"
              direction="row"
              sx={{ alignItems: 'center' }}
              className={cx({ [styles.selected]: isSelected })}
              area-labelid={value}
              role="button"
              tabIndex={-1}
              onClick={() => handleSelect(value)}
            >
              {/* <Checkbox /> */}
              <Avatar bordered name={label} />
              <Typography ml="0.5rem" variant="subtitle1">
                {label}
              </Typography>
            </Stack>
          );
        })}
        <div className={styles.addButton}>
          <AddButton text={'Add members'} add={handleAdd} />
        </div>
      </ul>
    </>
  );
}

UserMenu.propTypes = {};

export default UserMenu;
