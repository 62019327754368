import React, { useState, useMemo } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  DialogTitle,
  Typography,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Field from '../../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  postRedeterminationDetails,
  postRedeterminationSignature,
} from '../../../../utils/services/RedeterminationServices/requests';
import SignaturePad from '../../../../components/SignaturePad';
import { ReactComponent as FeatherIcon } from '../../../../assets/icons/feather.svg';

import { filter } from 'lodash';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import Icon from '../../../../components/Icon/index';
import { getRecentRecord } from '../../../../utils/helpers';
import { includes, map } from 'lodash';

function InitiateRedetermination({
  open,
  handleClose,
  data,
  getLkpMatch,
  caseId,
  redeterminationDetails,
}) {
  const [openSignatureModal, setOpenSignatureModal] = useState(false);
  const [signatureDate, setSignatureDate] = useState(false);
  const [signatureImage, setSignatureImage] = useState('');
  const [showLoader, hideLoader] = useLoader();
  const generatedCase = filter(data, {
    redeterminationStatusCd: 'GE',
  });

  const recentData = useMemo(() => {
    return getRecentRecord(data, 'redeterminationDueDate');
  }, [data]);

  const redetermination = async (data) => {
    try {
      showLoader();
      const res = await postRedeterminationDetails(data, caseId, generatedCase);
      if (res.status === 200) {
        showSuccessToast('Redetermination Done Successfully');
        redeterminationDetails();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const validationSchema = Yup.object({
    packetReceivedDate: Yup.string().required(
      'Please select the Received Date'
    ),
    signatureDate: Yup.string().when('signatureImg', {
      is: (signatureImg) => signatureImg,
      then: () => Yup.string().required('Signature date is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      packetReceivedDate: '',
      signatureDate: '',
      signatureImg: '',
    },
    validationSchema,
    onSubmit: (values) => {
      redetermination(values);
    },
  });

  const toggleSignature = async (signatureImage) => {
    if (!signatureImage.target) {
      formik.setFieldValue('signatureImg', signatureImage);
      setSignatureDate(true);
      const res = await postRedeterminationSignature(signatureImage, caseId);
      if (res.status === 200) {
        showSuccessToast('Signature Added Successfully');
      } else {
        showErrorToast('Signature Not Added');
      }
    }
    setOpenSignatureModal(false);
  };
  return (
    <Dialog
      onSubmit={formik.handleSubmit}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ fontSize: 20 }}>Redetermination</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          border: '1px solid lightGrey',
          borderRadius: '1rem',
          padding: '1rem',
        }}
      >
        <SignaturePad open={openSignatureModal} onClose={toggleSignature} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Stack direction="row">
              <Typography variant="h5" sx={{ color: 'var(--grey-400)' }}>
                Original Due Date
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              sx={{
                color: 'var(--grey-400)',
                fontWeight: 'bold',
                alignItems: 'center',
                mt: '1rem',
              }}
            >
              {/* <CalendarIcon /> */}
              <Icon width={20} height={20} name="CalendarIcon" />
              {recentData.originalDueDate}
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack direction="row">
              <Typography variant="h5" sx={{ color: 'var(--grey-400)' }}>
                Status
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              sx={{
                color: 'var(--grey-400)',
                fontWeight: 'bold',
                alignItems: 'center',
                mt: '1rem',
              }}
            >
              <Typography variant="h5">
                {getLkpMatch(
                  'RedeterminationStatus',
                  recentData.redeterminationStatusCd
                )}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row">
              <Typography variant="h5" sx={{ color: 'var(--grey-400)' }}>
                Redetermination Due Date
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              sx={{
                color: 'var(--grey-400)',
                fontWeight: 'bold',
                alignItems: 'center',
                mt: '1rem',
              }}
            >
              <Icon width={20} height={20} name="CalendarIcon" />
              {recentData.redeterminationDueDate}
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Field
              name="packetReceivedDate"
              label="Packet Received Date"
              isDate
              required
              formik={formik}
            >
              <DatePicker
                disableFuture
                value={dayjs(formik.values.packetReceivedDate)}
              />
            </Field>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle1">Provide Signature</Typography>
            {formik.values.signatureImg ? (
              <img
                src={formik.values.signatureImg}
                alt="sign"
                width={40}
                height={40}
              />
            ) : (
              <Button
                startIcon={<FeatherIcon />}
                onClick={() => setOpenSignatureModal(true)}
                variant="text"
              >
                Signature
              </Button>
            )}
          </Grid>

          {signatureDate === true && (
            <Grid item xs={4}>
              <Field
                name="signatureDate"
                label="Signature Date"
                isDate
                required
                formik={formik}
              >
                <DatePicker
                  disableFuture
                  value={dayjs(formik.values.signatureDate)}
                  minDate={dayjs().subtract(1, 'd')}
                  maxDate={dayjs()}
                />
              </Field>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Initiate
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InitiateRedetermination;
