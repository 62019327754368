import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { ELIGIBILITY_RESULTS, RUN_ELIGIBILITY, RUN_MONTHS } from './apiUrls';

export const getEligibilityResults = (caseId, data) =>
  HttpClient.post(format(ELIGIBILITY_RESULTS, caseId), data);

export const runMonthElibility = (caseId) =>
  HttpClient.post(format(RUN_MONTHS, caseId));

export const runEligibility = (caseId, data) =>
  HttpClient.post(format(RUN_ELIGIBILITY, caseId), data);

