import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveHealthDisability } from '../../../utils/services/healthAndDisability/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';
import HealthForm from './HealthForm';
import dayjs from 'dayjs';

const AddEditHealthDetailsDialog = ({
  setOpen,
  open,
  isAdd,
  member,
  caseId,
  getOptions,
  fetchData,
  selectedData,
  setSelectedData,
}) => {
  const {
    recordId,
    disabilityType,
    tdapType,
    disabilityApprovalSource,
    disabilityBeginDate,
    disabilityEndDate,
    disabilityStatus,
    statusDate,
    referralDate,
    interimAssistanceFormSigningDate,
    disabilityIarVerification,
  } = selectedData;

  const checkIsYearValid = (value) => {
    if (!value) {
      return null;
    }

    const date = dayjs(value);
    return date.isValid() && date.year() !== 9999 ? value : null;
  };

  const initialValues = {
    recordId: recordId ?? 0,
    dirtyStatus: isAdd ? 'insert' : 'update',
    disabilityType: disabilityType ?? '',
    tdapType: tdapType ?? '',
    disabilityApprovalSource: disabilityApprovalSource ?? '',
    disabilityBeginDate: disabilityBeginDate ?? null,
    disabilityEndDate: checkIsYearValid(disabilityEndDate) ?? null,
    disabilityStatus: disabilityStatus ?? '',
    statusDate: statusDate ?? null,
    referralDate: referralDate ?? null,
    interimAssistanceFormSigningDate: interimAssistanceFormSigningDate ?? null,
    verifiedYON: disabilityIarVerification?.verifiedYN ?? '',
  };

  const descriptionElementRef = useRef('');

  const [showLoader, hideLoader] = useLoader();

  const validationSchema = Yup.object({
    disabilityType: Yup.string().required('field is required'),
    disabilityApprovalSource: Yup.string().required('field is required'),
    disabilityStatus: Yup.string().required('field is required'),
    statusDate: Yup.string().required('field is required'),
    verifiedYON: Yup.string().required('field is required'),
    referralDate: Yup.string().required('field is required'),
    interimAssistanceFormSigningDate:
      Yup.string().required('field is required'),

    tdapType: Yup.string().when('disabilityStatus', {
      is: (disabilityStatus) => disabilityStatus === 'AP',
      then: () => Yup.string().required('field is required'),
      otherwise: () => Yup.string().notRequired(),
    }),

    disabilityBeginDate: Yup.date().when('disabilityStatus', {
      is: (disabilityStatus) => disabilityStatus === 'AP',
      then: () => Yup.date().required('field is required'),
      otherwise: () => Yup.date().notRequired(),
    }),

    disabilityEndDate: Yup.date().when('tdapType', {
      is: (tdapType) => tdapType === '1' || tdapType === '2',
      then: () =>
        Yup.date()
          .min(
            Yup.ref('disabilityBeginDate'),
            'End Date must be after Start Date'
          )
          .required('Field is required'),
      otherwise: () =>
        Yup.date().min(
          Yup.ref('disabilityBeginDate'),
          'End Date must be after Start Date'
        ).nullable(),
    }),

    // referralDate: Yup.string().when('disabilityStatus', {
    //   is: (disabilityStatus) =>
    //     disabilityStatus === 'DD' || disabilityStatus === 'SR',
    //   then: () => Yup.string().required('field is required'),
    //   otherwise: () => Yup.string().notRequired(),
    // }),
    // disabilityIarVerification: Yup.object().shape({
    //   verifiedYN: Yup.string().required('field is required'),
    // }),
  });

  const handleClose = () => {
    formik.resetForm();
    setSelectedData({});
    setOpen(false);
  };
  const onSubmit = async (values) => {
    try {
      showLoader();
      const res = await saveHealthDisability(
        caseId,
        member.individualId,
        values
      );

      if (res.status === 200) {
        showSuccessToast(
          isAdd
            ? 'Health & Disability Details Added'
            : 'Health & Disability Details Updated'
        );
        fetchData(member.individualId);
        handleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="earnedIncomeLabel"
        aria-describedby="earnedIncomeDescription"
      >
        <DialogTitle id="earnedIncomeLabel">
          {isAdd
            ? 'Add Health & Disability Details'
            : 'Edit Health & Disability Details'}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="earnedIncomeDescription"
            ref={descriptionElementRef}
          >
            <HealthForm getOptions={getOptions} formik={formik} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Discard
          </Button>
          {isAdd ? (
            <Button variant="contained" onClick={formik.handleSubmit}>
              Save & Close
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={!formik.dirty}
            >
              Update & Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddEditHealthDetailsDialog;
