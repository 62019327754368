import React, { useMemo, useState } from 'react';
import HealthDataGrid from '../HealthDataGrid';
import AddEditHealthDetailsDialog from './AddEditHealthDetailsDialog';
import ActionButton from '../../../components/ActionButtons';
import { get, map } from 'lodash';
import dayjs from 'dayjs';
import DeleteConfiramation from '../DeleteConfiramation';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { saveHealthDisability } from '../../../utils/services/healthAndDisability/apiRequests';
import useLoader from '../../../hooks/useLoader';
import { saveHealthDisabilityPayload } from '../../../utils/services/healthAndDisability/apiPayloads';

const HealthDetails = ({
  member,
  setOpen,
  open,
  isAdd,
  setIsAdd,
  lkpRaw,
  lkpFlatten,
  getLkpMatch,
  caseId,
  getOptions,
  disabilitiesData,
  fetchData,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleEdit = (params) => {
    setSelectedData(params.row);
    setOpen(true);
    setIsAdd(false);
  };

  const handleDelete = (params) => {
    setSelectedData(params.row);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      showLoader();
      const res = await saveHealthDisability(
        caseId,
        member.individualId,
        selectedData,
        'delete'
      );

      if (res.status === 200) {
        showSuccessToast('Health Details Successfully Deleted');
        fetchData(member.individualId);
        setOpenDeleteDialog(false);
        setSelectedData({});
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
    setOpenDeleteDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const columns = [
    {
      field: 'disabilityType',
      headerName: 'Disability Type',
      width: 130,
      renderCell: (params) =>
        lkpFlatten.DisabilityType[params?.row?.disabilityType],
    },
    {
      field: 'disabilityApprovalSource',
      headerName: 'Disability Verification Source',
      width: 240,
      renderCell: (params) =>
        lkpFlatten.DisabilityApprovalSource[
          params?.row?.disabilityApprovalSource
        ],
    },
    {
      field: 'disabilityStatus',
      headerName: 'Status',
      width: 80,
      editable: false,
      renderCell: (params) =>
        lkpFlatten.DisabilityStatus[params?.row?.disabilityStatus],
    },
    {
      field: 'statusDate',
      headerName: 'Status Date',
      width: 110,
      editable: false,
    },
    {
      field: 'disabilityBeginDate',
      headerName: 'Disability Begin Date',
      width: 180,
    },
    {
      field: 'disabilityEndDate',
      headerName: 'Disability End Date',
      width: 170,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span>
            <ActionButton
              onDelete={() => handleDelete(params)}
              onEdit={() => handleEdit(params)}
            />
          </span>
        );
      },
    },
  ];

  const rows = useMemo(
    () =>
      map(disabilitiesData.disabilities, (item, index) => {
        const year = dayjs(item?.disabilityEndDate).year();
        const displayDisabilityEndDate =
          year === 9999 ? '' : item.disabilityEndDate;
        return {
          ...item,
          id: index,
          disabilityType: get(item, 'disabilityType', ''),
          disabilityApprovalSource: get(item, 'disabilityApprovalSource', ''),
          disabilityStatus: get(item, 'disabilityStatus', ''),
          statusDate: get(item, 'statusDate', ''),
          disabilityBeginDate: get(item, 'disabilityBeginDate', ''),
          disabilityEndDate: displayDisabilityEndDate,
        };
      }),
    [disabilitiesData]
  );

  return (
    <>
      <HealthDataGrid rows={rows} columns={columns} loading={false} />
      <AddEditHealthDetailsDialog
        setOpen={setOpen}
        open={open}
        isAdd={isAdd}
        lkpRaw={lkpRaw}
        member={member}
        caseId={caseId}
        lkpFlatten={lkpFlatten}
        getLkpMatch={getLkpMatch}
        getOptions={getOptions}
        fetchData={fetchData}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default HealthDetails;
