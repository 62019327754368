import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import Field from '../../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { postFamilyInvestTemp } from '../../../../utils/services/ManualNotices/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import {
  maxLength,
  name,
  number,
  onlyAlphabet,
} from '../../../../utils/normalizers';
import InputMask from 'react-input-mask';

export default function FamilyInvestment({
  toggleClose,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();
  const formik = useFormik({
    initialValues: {
      name: `${dataValue?.firstName}  ${dataValue?.lastName}` ?? '',
      dob: dataValue?.dateOfBirth ?? '',
      participantSign: dataValue?.participantSignature ?? '',
      participantSignDate: dataValue?.signDate ?? '',
      improveAtten: dataValue?.activityType1 ?? '',
      ssn: dataValue?.ssn ?? '',
      street: dataValue?.streedAddr1 ?? '',
      organization: dataValue?.nameOrganz1 ?? '',
      cityState: dataValue?.cityStateZip1 ?? '',
      supervisorName: dataValue?.supervisorName1 ?? '',
      supervisorNumber: dataValue?.supervisorPhone1 ?? '',
      hours: dataValue?.hours1 ?? '',
      week: dataValue?.daysPerWeek1 ?? '',
      supervisorSign: dataValue?.supervisorSignature1 ?? '',
      signDate: dataValue?.supervisorDate1 ?? '',
      improveAtten1: dataValue?.activityType2 ?? '',
      street1: dataValue?.streedAddr2 ?? '',
      organization1: dataValue?.nameOrganz2 ?? '',
      cityState1: dataValue?.cityStateZip2 ?? '',
      supervisorName1: dataValue?.supervisorName2 ?? '',
      supervisorNumber1: dataValue?.supervisorPhone2 ?? '',
      hours1: dataValue?.hours2 ?? '',
      week1: dataValue?.daysPerWeek2 ?? '',
      supervisorSign1: dataValue?.supervisorSignature2 ?? '',
      signDate1: dataValue?.supervisorDate2 ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      console.log(values);

      familyInvest(values);
    },
  });

  const familyInvest = async (data) => {
    try {
      showLoader();
      const res = await postFamilyInvestTemp(data);
      if (res.status === 200) {
        showSuccessToast('Succesfully created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Stack direction="row" justifyContent="center" mt="2rem">
          <Typography variant="h3">
            FAMILY INVESTMENT ADMINISTRATION <br></br>VERIFICATION OF ACTIVITY
            PARTICIPATION
          </Typography>
        </Stack>
        <Grid ml="3rem" mr="3rem">
          <Grid container mt="0.2rem" spacing={2}>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Name:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="name"
                formik={formik}
                normalizers={[maxLength(30), name]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">D.O.B:</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="dob" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Last 4 Digits of SSN:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="ssn"
                formik={formik}
                normalizers={[maxLength(4), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.ssn}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
          </Grid>
          <Grid mt="1rem">
            <Typography variant="h5grey">
              To verify participation in an activity, this form must be
              completed/signed by both the Participant and Supervisor and can be
              used for combined activity reporting.
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5grey">
                I take part in the activity or activities listed below.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Participant's Signature:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="participantSign" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="participantSignDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
          </Grid>
          <Grid border="1px solid #555" mt="2rem" mb="1rem">
            <Grid container spacing={2}>
              <Grid item xs={12} mt="0.5rem" ml="0.5rem">
                <Typography variant="h4">
                  1.To be completed by the Participant: (complete for each
                  volunteer, education, job readiness or workactivity)
                </Typography>
              </Grid>
              {/* <Grid item xs={12} border="1px solid #55" /> */}
              <Grid item xs={4} mt="0.6rem" ml="0.5rem">
                <Typography variant="subtitle1">
                  Activity Type: (check one)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field name="improveAtten" formik={formik}>
                  <RadioGroup
                    name="improveAtten"
                    row
                    {...formik.getFieldProps('improveAtten')}
                    sx={{ marginLeft: 2 }}
                    value={formik.values.improveAtten}
                  >
                    <FormControlLabel
                      value={'V'}
                      control={<Radio />}
                      label="Volunteer"
                    />
                    <FormControlLabel
                      value={'E'}
                      control={<Radio />}
                      label="Education"
                    />
                    <FormControlLabel
                      value={'J'}
                      control={<Radio />}
                      label="Job Readiness"
                    />
                    <FormControlLabel
                      value={'W'}
                      control={<Radio />}
                      label="Work"
                    />
                  </RadioGroup>
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">
                  Name of Organization:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">Street Address:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field name="street" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Field name="organization" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">City, State, ZIP:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field name="cityState" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">Supervisor's Name:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="supervisorName"
                  formik={formik}
                  normalizers={[maxLength(30)]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">
                  Supervisor's Phone Number:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="supervisorNumber"
                  formik={formik}
                  normalizers={[maxLength(10), number]}
                >
                  <InputMask mask="(999)-999-9999" maskChar={null}>
                    {(params) => (
                      <OutlinedInput
                        variant="outlined"
                        placeholder="(000)-000-0000"
                        value={formik.values.supervisorNumber}
                        {...params}
                      />
                    )}
                  </InputMask>
                </Field>
              </Grid>
              <Grid item xs={12} ml="0.5rem">
                <Typography variant="h4">
                  To be completed by the Supervisor
                </Typography>
              </Grid>
              <Grid item xs={12} ml="0.5rem">
                <Typography variant="subtitle1">
                  What are the individual’s participation hours per week?{' '}
                  <br></br>
                  (example: 8:00 a.m. to 1:00 p.m./3 days per week)
                </Typography>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle">Hours:</Typography>
              </Grid>
              <Grid item xs={6} ml="0.5rem">
                <Typography variant="subtitle">Days per Week:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field name="hours" formik={formik} normalizers={[number]}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="week" formik={formik} normalizers={[number]}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={12} ml="0.5rem">
                <Typography variant="h4">
                  Days per Week: My signature verifies that the information I
                  have provided is true/correct and theindividual named above
                  currently participates for the reported number of hours/days
                  perweek.
                </Typography>
              </Grid>
              <Grid item xs={4} ml="0.2rem">
                <Typography variant="subtitle1">
                  Supervisor's Signature:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="supervisorSign"
                  formik={formik}
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Date:</Typography>
              </Grid>
              <Grid item xs={6} mb="1rem">
                <Field name="signDate" formik={formik} isDate>
                  <DatePicker
                    disableFuture
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                  />
                </Field>
              </Grid>
            </Grid>
          </Grid>
          <Grid border="1px solid #555" mt="2rem" mb="1rem">
            <Grid container spacing={2}>
              <Grid item xs={12} mt="0.5rem" ml="0.5rem">
                <Typography variant="h4">
                  2.To be completed by the Participant: (complete for each
                  volunteer, education, job readiness or workactivity)
                </Typography>
              </Grid>
              {/* <Grid item xs={12} border="1px solid #55" /> */}
              <Grid item xs={4} mt="0.6rem" ml="0.5rem">
                <Typography variant="subtitle1">
                  Activity Type: (check one)
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field name="improveAtten1" formik={formik}>
                  <RadioGroup
                    name="improveAtten1"
                    row
                    {...formik.getFieldProps('improveAtten1')}
                    sx={{ marginLeft: 2 }}
                    value={formik.values.improveAtten}
                  >
                    <FormControlLabel
                      value={'V'}
                      control={<Radio />}
                      label="Volunteer"
                    />
                    <FormControlLabel
                      value={'E'}
                      control={<Radio />}
                      label="Education"
                    />
                    <FormControlLabel
                      value={'J'}
                      control={<Radio />}
                      label="Job Readiness"
                    />
                    <FormControlLabel
                      value={'W'}
                      control={<Radio />}
                      label="Work"
                    />
                  </RadioGroup>
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">
                  Name of Organization:
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">Street Address:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field name="street1" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Field name="organization1" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">City, State, ZIP:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field name="cityState1" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">Supervisor's Name:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Field name="supervisorName1" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">
                  Supervisor's Phone Number:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="supervisorNumber1"
                  formik={formik}
                  normalizers={[number]}
                >
                  <InputMask mask="(999)-999-9999" maskChar={null}>
                    {(params) => (
                      <OutlinedInput
                        variant="outlined"
                        placeholder="(000)-000-0000"
                        value={formik.values.supervisorNumber1}
                        {...params}
                      />
                    )}
                  </InputMask>
                </Field>
              </Grid>
              <Grid item xs={12} ml="0.5rem">
                <Typography variant="h4">
                  To be completed by the Supervisor
                </Typography>
              </Grid>
              <Grid item xs={12} ml="0.5rem">
                <Typography variant="subtitle1">
                  What are the individual’s participation hours per week?
                  <br></br>
                  (example: 8:00 a.m. to 1:00 p.m./3 days per week)
                </Typography>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle">Hours:</Typography>
              </Grid>
              <Grid item xs={6} ml="0.5rem">
                <Typography variant="subtitle">Days per Week:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Field name="hours1" formik={formik} normalizers={[number]}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="week1" formik={formik} normalizers={[number]}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={12} ml="0.5rem">
                <Typography variant="h4">
                  Days per Week: My signature verifies that the information I
                  have provided is true/correct and theindividual named above
                  currently participates for the reported number of hours/days
                  perweek.
                </Typography>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">
                  Supervisor's Signature:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Field name="supervisorSign1" formik={formik}>
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={4} ml="0.5rem">
                <Typography variant="subtitle1">Date:</Typography>
              </Grid>
              <Grid item xs={6} mb="1rem">
                <Field name="signDate1" formik={formik} isDate>
                  <DatePicker
                    disableFuture
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                  />
                </Field>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt="1rem" spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Disacrd
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
