import React, { useEffect, useMemo, useState } from 'react';
import useTabs from '../../../hooks/useTabs';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from '../../../components/ActionButtons';
import TabularView from '../../../components/TabularView';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import {
  postDeleteNotice,
  postPdfDwnld,
} from '../../../utils/services/ManualNotices/apiRequest';
import useLoader from '../../../hooks/useLoader';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import DeleteConfiramation from '../DeleteConfirmation';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/letter.svg';

export default function SearchResult({
  historyData,
  handleNoticeEditOpen,
  historySearch,
  caseId,
  individualId,
  recdId,
  postSearchNotice,
}) {
  console.log(recdId, '0000');
  const { Tabs, Tab, TabContent, onTabChange, activeTab } = useTabs();
  const [columnsData, setColumnsData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [rowData, setRowData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [tmpltCd, setTmpltCd] = useState(null);
  const [mnlTmpltDataId, setManualId] = useState(null);

  // const [recdId, setRecdId] = useState(null);

  // const recdId = editData?.recordId;
  // console.log(recdId, '111');

  const { houseMembers } = useSelector(selectHouseMembers);
  console.log(houseMembers);
  const renderMemberName = (id) => {
    return houseMembers.find(({ individualId }) => individualId == id);
  };
  console.log();
  const deletePopup = async (rowData) => {
    setSelectedData(rowData);
    setTmpltCd(rowData?.tmpltCd);
    setManualId(rowData.mnlTmpltDataId);
    // setRecdId(editData?.recordId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteProgram = () => {
    deleteNotice(tmpltCd, mnlTmpltDataId);
  };
  const deleteNotice = async (tmpltCd, mnlTmpltDataId) => {
    try {
      const payload = {
        agencyCd: 'DHS',
        benefitCaseId: caseId,
        individualId: individualId,
        jsonTemplateData: '',
        programCd: 'CH',
        recordId: mnlTmpltDataId,
        templateCd: tmpltCd,
        triggerDate: dayjs(new Date()).format('YYYY-MM-DD'),
      };
      showLoader();
      const res = await postDeleteNotice(payload);
      if (res.status === 200) {
        showSuccessToast('Successfully Deleted');
        postSearchNotice();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const readyColumns = [
    {
      headerName: 'Case #',
      getValue({ caseId }) {
        return `${caseId}`;
      },
    },
    {
      headerName: 'Letter/Forms',
      getValue({ lookupLongVal }) {
        return `${lookupLongVal}`;
      },
    },
    {
      headerName: 'Individual Names',
      getValue: ({ indvdlId }) => {
        const member = renderMemberName(indvdlId);
        if (member && member.personName) {
          const { personName } = member;
          return personName.firstName + ' ' + personName.lastName;
        } else {
          return '-';
        }
      },
    },
    {
      headerName: 'Date',
      getValue({ trgrDt }) {
        return `${trgrDt}`;
      },
    },
    {
      headerName: 'Action',
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => handleNoticeEditOpen(params)}
              onDelete={() => deletePopup(params)}
            />
            <IconButton onClick={() => downloadPdf(params?.mnlTmpltDataId)}>
              <Tooltip title="Download" placement="top">
                <DownloadIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </div>
        );
      },
    },
  ];

  const historyColumns = [
    {
      headerName: 'Case #',
      getValue: () => '',
    },
    {
      headerName: 'Letter/Forms',
      getValue: () => '',
    },
    {
      headerName: 'Individual Names',
      getValue: () => '',
    },
    {
      headerName: 'Sent Date',
      getValue: () => '',
    },
    {
      headerName: 'Action',
      renderCell: (params) => {
        return <div></div>;
      },
    },
  ];

  const downloadPdf = async (mnlTmpltDataId) => {
    if (!mnlTmpltDataId) {
      showErrorToast('NO PDF URL available');
      return;
    }
    try {
      showLoader();
      const response = await postPdfDwnld(mnlTmpltDataId);
      console.log(response);
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (activeTab === 0) {
      setColumnsData(readyColumns);
      setRowData(historyData);
    }
    if (activeTab === 1) {
      setColumnsData(historyColumns);
      // setRowData(rows);
    }
  }, [activeTab, historyData]);

  return (
    <>
      <Tabs onChange={onTabChange} value={activeTab}>
        <Tab label="Ready To Be Sent" />
        <Tab label="History" />
      </Tabs>

      <Box
        sx={{
          marginTop: '1rem',
          // marginLeft: '1rem',
          marginRight: '4rem',
        }}
      >
        <TabularView
          columns={columnsData}
          data={historySearch ?? []}
          headerComponent={{ variant: 'h4' }}
          sx={{
            border: '1px solid #f0f0f0',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
        />
      </Box>
      <DeleteConfiramation
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        handleDeleteProgram={handleDeleteProgram}
      />
    </>
  );
}
