import {
  Dialog,
  Typography,
  Grid,
  TextareaAutosize,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Button } from '@mui/material';
import Field from '../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import * as yup from 'yup';
import { editAddNoncompliance } from '../../../utils/services/NonComplianceDetails/apiRequest';
import { selectCaseDetails } from '../../../store/selectors/caseSelector';
import useLoader from '../../../hooks/useLoader';

const NonComplianceEvents = ({
  data,
  getOptions,
  setOpen,
  events,
  dataChanged,
  setDataChanged,
  getLkpMatch,
  caseId,
  individualId,
}) => {
  const caseDetails = useSelector(selectCaseDetails);
  const [showLoader, hideLoader] = useLoader();
  const validatSchema = yup.object({
    typeCd: yup.string().required('Please Choose Type'),
    nonCmplncBgnDt: yup.string().required('Please Choose Type'),
    programCode: yup.string().required('Please Choose Type'),
    decisionDt: yup.string().when('typeCd', {
      is: (value) => {
        return !['QC', 'NC', 'SATS', 'VQ', 'WORK', 'VR', 'VQ', 'T'].includes(
          value
        );
      },
      then: () => yup.string().required('Decision Date is required'),
      otherwise: () => yup.string().nullable().notRequired(''),
    }),
  });

  const handleSubmit = async (values) => {
    showLoader();
    try {
      const res = await editAddNoncompliance(
        caseId,
        individualId,
        { ...data, ...values },
        events
      );
      if (res.status === 200) {
        setDataChanged(!dataChanged);
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      nonCmplncEndDt: data?.nonCmplncEndDt ?? null,
      programCode: data?.programCode ?? caseDetails.programs[0].programName,
      nonCmplncBgnDt: data?.nonCmplncBgnDt ?? null,
      cureComplyDt: data?.cureComplyDt ?? null,
      cureComply: data?.cureComply ?? '',
      decisionDt: data?.decisionDt ?? null,
      decisionType: data?.decisionType ?? '',
      goodCause: data?.goodCause ?? '',
      verificationSourceCode: data?.vrfctn?.verificationSourceCode ?? '',
      cmmnts: data?.cmmnts ?? '',
      typeCd: data?.typeCd ?? '',
    },
    validateOnChange: true,
    validationSchema: validatSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (
      ['QC', 'NC', 'SATS', 'VQ', 'WORK', 'VR', 'VQ', 'T'].includes(
        formik.values.typeCd
      )
    ) {
      formik.setFieldValue('decisionDt', null);
      formik.setFieldValue('decisionType', null);
    }
  }, [formik.values.typeCd]);

  const verification = getOptions('GOODSTUDENTVERCD');

  return (
    <>
      <Dialog open={true}>
        <Box sx={{ margin: '1rem' }}>
          <Typography variant="h2">Non-Compliance Details</Typography>
          <Box
            sx={{
              border: '1px solid var(--grey-350)',
              borderRadius: '5px',
              padding: '1rem',
              marginTop: '0.5rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  required
                  name="nonCmplncBgnDt"
                  showError={formik.errors?.nonCmplncBgnDt}
                  error={formik.errors?.nonCmplncBgnDt}
                  label="Begin Date"
                  formikHandler={false}
                  isDate
                  formik={formik}
                >
                  <DatePicker
                    required
                    value={formik.values.nonCmplncBgnDt}
                    disableFuture
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  error={formik.errors?.nonCmplncEndDt}
                  showError={formik.errors?.nonCmplncEndDt}
                  name="nonCmplncEndDt"
                  label="End Date"
                  formikHandler={false}
                  isDate
                  formik={formik}
                >
                  <DatePicker required value={formik.values.nonCmplncEndDt} />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  error={formik.errors?.programCode}
                  showError={formik.errors?.programCode}
                  label="Program"
                  name="programCode"
                  required
                  formik={formik}
                >
                  <Select name="programCode" placeholder="Select Program">
                    {caseDetails.programs?.map((obj, key) => {
                      {
                        return obj.programName === 'CH' ||
                          obj.programName === 'FS' ? (
                          <MenuItem value={obj?.programName} key={key}>
                            {getLkpMatch('Program', obj.programName)}
                          </MenuItem>
                        ) : (
                          ''
                        );
                      }
                    })}
                  </Select>
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  error={formik.errors?.typeCd}
                  showError={formik.errors?.typeCd}
                  label="Type"
                  name="typeCd"
                  required
                  formik={formik}
                >
                  <Dropdown
                    placeholder="Select Non-Compliance Type"
                    name="typeCd"
                    options={
                      formik.values.programCode === 'FS'
                        ? getOptions(
                            'NonComplianceSNAPReasonCodesAndDescriptions'
                          )
                        : getOptions('NonComplianceType')
                    }
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="cureComply"
                  label="Cure Comply"
                  showError={formik?.errors?.cureComply}
                  formik={formik}
                >
                  <Dropdown
                    name="cureComply"
                    placeholder="Select Curecomply"
                    options={getOptions('YesNo')}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  error={formik.errors?.cureComplyDt}
                  showError={formik.errors?.cureComplyDt}
                  name="cureComplyDt"
                  label="Cure Comply Date"
                  formikHandler={false}
                  isDate
                  formik={formik}
                >
                  <DatePicker required value={formik.values.cureComplyDt} />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field label="Good Source" name="goodCause" formik={formik}>
                  <Dropdown
                    name="goodCause"
                    options={getOptions('MRQRGoodCause')}
                    placeholder="Select goodcause"
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="verificationSourceCode"
                  label="Verification Source Code"
                  formik={formik}
                >
                  <Dropdown
                    name="verificationSourceCode"
                    placeholder="Select Verification Source Code"
                    options={verification}
                  />
                </Field>
              </Grid>
              {!['QC', 'NC', 'SATS', 'VQ', 'WORK', 'VR', 'VQ', 'T'].includes(
                formik.values.typeCd
              ) && (
                <>
                  <Grid item xs={6}>
                    <Field
                      name="decisionType"
                      label="Decision Type"
                      formik={formik}
                    >
                      <Dropdown
                        placeholder="Select Decision Type"
                        name="decisionType"
                        options={getOptions('DecisionType')}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      error={formik.errors?.decisionDt}
                      showError={formik.errors?.decisionDt}
                      required
                      name="decisionDt"
                      label="Decision Date"
                      formikHandler={false}
                      isDate
                      formik={formik}
                    >
                      <DatePicker required value={formik.values?.decisionDt} />
                    </Field>
                  </Grid>
                </>
              )}
              <Grid xs={12}>
                <Box sx={{ marginLeft: '1rem' }}>
                  <Field name="cmmnts" label="Comments" formik={formik}>
                    <textarea
                      rows="4"
                      name="cmmnts"
                      style={{
                        border: '1px solid var(--grey-350)',
                        borderRadius: '4px',
                      }}
                    ></textarea>
                  </Field>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'flex-end'}
            mt={2}
          >
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Discard
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              //   disabled={disableFields}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </>
  );
};

export default NonComplianceEvents;
