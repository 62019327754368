import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EmptyState from '../../components/EmptyState';
import NoRecord from '../../assets/icons/cash-Assistance.svg';

const InComeDataGrid = ({ rows, columns, getRowId, autoHW }) => {
  const gridStyle = {
    height: autoHW ? 250 : 340,
    width: autoHW ? 'auto' : '100%',
  };
  return (
    <div style={gridStyle}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        // getRowHeight={() => 'auto'}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '30vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '30vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        loading={false}
        rowsPerPageOptions={[5, 10, 15]}
        checkboxSelection={false}
        getRowId={getRowId}
        disableRowSelectionOnClick={true}
        disableColumnMenu={true}
      />
    </div>
  );
};

export default InComeDataGrid;
