export const medicarePayload = (data) => {
  const {
    medicareDate,
    parta,
    partb,
    partc,
    partd,
    partatype,
    partbtype,
    partctype,
    partdtype,
    solqi,
    claimid,
    applicationid,
    verificationclaim,
    verificationapplication,
    dirtyStatus,
    recordId,
  } = data;
  const appId = applicationid && applicationid.replace(/-/g, '');
  const claimId = claimid && claimid.replace(/-/g, '');
  return {
    applicationNum: appId,
    appliedInd: '',
    claimId: claimId,
    dirtyStatus,
    medicareApplicationVerification: {
      verificationSourceCode: verificationapplication,
    },
    medicareClaimVerification: {
      verificationSourceCode: verificationclaim,
    },
    medicareStartDate: medicareDate,
    medicareTypeA: parta,
    medicareTypeAStatus: partatype,
    medicareTypeB: partb,
    medicareTypeBStatus: partbtype,
    medicareTypeC: partc,
    medicareTypeCStatus: partctype,
    medicareTypeD: partd,
    medicareTypeDStatus: partdtype,
    medicareVerification: {
      verificationSourceCode: null,
    },
    rcvdInd: '',
    recordId,
    solqYN: solqi,
  };
};

export const deleteMedPayload = (data) => {
  const {
    medicareStartDate,
    applicationNum,
    appliedInd,
    claimId,
    verificationSourceCode,
    medicareTypeA,
    medicareTypeAStatus,
    medicareTypeB,
    medicareTypeBStatus,
    medicareTypeC,
    medicareTypeCStatus,
    medicareTypeD,
    medicareTypeDStatus,
    recordId,
    solqYN,
    dirtyStatus,
  } = data;
  const appId = applicationNum && applicationNum.replace(/-/g, '');
  const claim = claimId && claimId.replace(/-/g, '');
  return {
    applicationNum: appId,
    appliedInd: '',
    claimId: claim,
    dirtyStatus,
    medicareApplicationVerification: {
      verificationSourceCode: verificationSourceCode,
    },
    medicareClaimVerification: {
      verificationSourceCode: verificationSourceCode,
    },
    medicareStartDate: medicareStartDate,
    medicareTypeA: medicareTypeA ? true : false,
    medicareTypeAStatus: medicareTypeAStatus,
    medicareTypeB: medicareTypeB ? true : false,
    medicareTypeBStatus: medicareTypeBStatus,
    medicareTypeC: medicareTypeC ? true : false,
    medicareTypeCStatus: medicareTypeCStatus,
    medicareTypeD: medicareTypeD ? true : false,
    medicareTypeDStatus: medicareTypeDStatus,
    medicareVerification: {
      verificationSourceCode: null,
    },
    rcvdInd: '',
    recordId,
    solqYN: solqYN,
  };
};

export const insurancePayload = (data) => {
  const {
    healthInsurance,
    hmo,
    managedCare,
    insuranceDropped,
    wilingPremium,
    buyIn,
    thirdParty,
    liability,
  } = data;
  return {
    buyingYN: buyIn,
    dirtyStatus: 'insert',
    healthInsurance: healthInsurance,
    hmo: hmo,
    insuranceDroppedYN: insuranceDropped,
    liabilityCD: liability,
    managedCareYN: managedCare,
    purchaseForChildYN: null,
    recordId: 0,
    tplYN: thirdParty,
    willingToPayYN: wilingPremium,
  };
};

export const healthInsurancePayload = (data) => {
  const {
    policyNumber,
    groupNumber,
    fromDate,
    toDate,
    source,
    policyHolder,
    memberSelection,
    relationshipmem,
    firstName,
    middleName,
    lastName,
    suffix,
    relationship,
    addressLine1,
    addressLine2,
    city,
    zipCode,
    state,
    phoneNumber,
    carrierName,
    planName,
    address1,
    address2,
    cityCar,
    zipCar,
    stateCar,
    phoneCar,
    unionName,
    localName,
    address1Union,
    address2Union,
    cityUnion,
    zipUnion,
    stateUnion,
    phoneUnion,
    dirtyStatus,
    recordId,
  } = data;

  return [
    {
      carrierAddress: {
        addressLine1: address1,
        addressLine2: address2,
        city: cityCar,
        zipcode: zipCar,
        state: stateCar,
        addressVerifiedYN: '',
      },
      carrierName: carrierName,
      carrierPhone: {
        phoneNumber: phoneCar,
      },
      cobraYN: '',
      coverageEffectiveDate: null,
      coverageEndDate: null,
      coverageStartDate: null,
      dirtyStatus,
      empPremiumAmt: '',
      empPremiumAmtExpcted: '',
      empPremiumAmtUnknownYN: '',
      enrolledPersonIds: [],
      esiChangeExpectedYN: '',
      expectedCvrgChnge: '',
      groupNumber: groupNumber,
      hasOtherInsYN: '',
      healthInsByJobCurrentYN: '',
      healthInsStatus: '',
      lostCoverageYN: '',
      lowestCostHealthPlan: '',
      meetsMinValueStdUnknownYN: '',
      meetsMinValueStdYN: '',
      offeredCvrgEndDate: null,
      otherStateProgramType: '',
      planName: planName,
      policyEffectiveDate: fromDate,
      policyEndDate: toDate,
      policyHolderAddress: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        zipcode: zipCode,
        state: state,
        addressVerifiedYN: '',
      },
      policyHolderDateOfBirth: null,
      policyHolderFromHouseholdYN: policyHolder,
      policyHolderHouseholdId: '',
      policyHolderName: {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        suffix: suffix,
      },
      policyHolderPhone: {
        phoneNumber: phoneNumber,
      },
      policyNumber: policyNumber,
      policySource: source,
      premiumAmtChngDate: null,
      premiumCostAmt: '',
      programName: '',
      recordId,
      relationshipCd: relationship,
      retireePlanYN: '',
      unionAddress: {
        addressLine1: address1Union,
        addressLine2: address2Union,
        city: cityUnion,
        zipcode: zipUnion,
        state: stateUnion,
        addressVerifiedYN: '',
      },
      unionLocal: localName,
      unionName: unionName,
      unionPhone: {
        phoneNumber: phoneUnion,
      },
      updatedBy: null,
      updatedDate: null,
    },
  ];
};
