import { Button, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dropdown from '../../../components/Dropdown';
import styles from './index.module.css';
import HolidayModal from './HolidayModal';
import dayjs from 'dayjs';
import useLookup from '../../../hooks/useLookup';
import { getHolidays } from '../../../utils/services/Holidays/apiRequests';

const Holidays = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [holidayEvents, setHolidayEvents] = useState([]);
  const [year, setYear] = useState(String(dayjs().year()));
  const { getOptions } = useLookup('HolidayYears');

  const { holiDayYear } = useMemo(() => {
    return {
      holiDayYear: getOptions('HoliDayYear'),
    };
  }, [getOptions]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    getHolidayList(year);
  }, []);

  const getHolidayList = async (year) => {
    const res = await getHolidays(year);
    if (res.status === 200) {
      setHolidayEvents(
        res.data.map((item, index) => {
          return {
            ...item,
            title: item?.description,
            start: new Date(item?.date),
            end: new Date(item?.date),
            allDay: true,
            id: index,
          };
        })
      );
    }
  };

  const handleChange = async (e) => {
    await getHolidayList(e.target.value);
    setYear(e.target.value);
  };
  console.log(holidayEvents);

  return (
    <div style={{ backgroundColor: '#fff', padding: '0.5rem' }}>
      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        spacing={2}
        justifyContent="space-between"
        pb={5}
      >
        <Typography
          sx={{ fontSize: '24px', fontWeight: '700' }}
          color="var(--black-900)"
        >
          Holiday Calender {year ?? ''}
        </Typography>
        <Stack
          direction="row"
          justifyContent={{
            xs: 'flex-start',
            sm: 'flex-start',
            md: 'space-between',
          }}
          spacing={2}
          alignItems="center"
        >
          <Button
            variant="contained"
            sx={{ padding: '8px 12px', borderRadius: '6px' }}
            onClick={handleDialogOpen}
            disabled={
              new Date().getFullYear() > parseInt(year) ||
              parseInt(year) > new Date().getFullYear() + 1
            }
          >
            <AddIcon sx={{ marginRight: '10px' }} />
            Add Holiday
          </Button>
          <div style={{ width: '150px' }}>
            <Dropdown
              options={holiDayYear}
              placeholder="Select Year"
              customHandler
              onChange={handleChange}
              value={year}
            />
          </div>
        </Stack>
      </Stack>
      <Grid container spacing={3}>
        {months.map((item, index) => {
          const holList = holidayEvents.filter((item) => {
            return dayjs(item?.date).month() === index;
          });
          return (
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <div className={styles.innerGridContainer}>
                <Grid container>
                  <Grid item xs={12} className={styles.month}>
                    <Typography className={styles.monthTitle}>
                      {item} {year}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ padding: '15px' }}>
                    <Stack
                      direction="column"
                      spacing={1}
                      sx={{ height: '100%' }}
                    >
                      {holList.length > 0 ? (
                        holList.map((list) => {
                          const dateHoli =
                            dayjs(list?.start).date() < 10
                              ? `0${dayjs(list?.start).date()}`
                              : dayjs(list?.start).date();

                          return (
                            <Stack
                              direction="row"
                              spacing={4}
                              alignItems="center"
                            >
                              <Stack
                                direction="column"
                                style={{ flex: 4 }}
                                alignItems="flex-start"
                              >
                                <span className={styles.monthTitle}>
                                  {dateHoli}
                                </span>
                                <Typography className={styles.day}>
                                  {dayjs(list?.date).format('dddd')}
                                </Typography>
                              </Stack>
                              <div
                                style={{
                                  flex: 8,
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                <span className={styles.eventOfDay}>
                                  {list.title}
                                </span>
                              </div>
                            </Stack>
                          );
                        })
                      ) : (
                        <Typography
                          className={styles.eventOfDay}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          No Holiday
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent>
          <HolidayModal
            openDialog={openDialog}
            handleClose={handleClose}
            holidayEvents={holidayEvents}
            year={year}
            getHolidayList={getHolidayList}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Holidays;
