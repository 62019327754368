import React, { useMemo, useState } from 'react';
import SelectableView from '../../components/SelectableView';
import { useSelector } from 'react-redux';
import {
  selectHouseMembers,
  navigatePageName,
} from '../../store/selectors/caseSelector';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import PageContainer from '../../components/PageContainer';
import AssetContent from './AssetContent';
import usePageNav from '../../hooks/usePageNav';
import usePage from '../../hooks/usePage';

export default function Assets({ id }) {
  const verifyPageName = useSelector(navigatePageName);
  const [assetLoader, setAssetLoader] = useState(false);
  const { navProps } = usePage(id);
  const { navigations } = usePageNav();
  const { houseMembers } = useSelector(selectHouseMembers);

  const { houseHoldMembers } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  }, [houseMembers]);

  const onNext = () => {
    navigations.toIncome();
  };
  const onBack = () => {
    navigations.toDomesticViolence();
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Assets"
      // toVerify={['EI_AMT'].includes(verifyPageName) ? verifyPageName : '  '}

      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        loading={assetLoader}
        loadingContent={assetLoader}
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <AssetContent setAssetLoader={setAssetLoader} />
      </SelectableView>
    </PageContainer>
  );
}
