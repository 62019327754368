import { capitalize, trim } from 'lodash';

export function number(value) {
  return value.replace(/[^\d]/g, '');
}

export function alphaNumeric(value) {
  return value.replace(/[^a-zA-Z0-9\s]/g, '');
}

export function maxLength(count) {
  return (value) => value.substr(0, count);
}

export function trimString(value) {
  return trim(value);
}

export function name(value) {
  if (value) {
    return capitalize(value);
  }
  return value;
}

export function toLowerCase(value) {
  return value?.toLowerCase();
}

export function formatCurrency(value) {
  let convertedValue = value.toString().replace(/\D/g, '')?.substr(0, 10);
  convertedValue = convertedValue.replace(/(\d)(\d{2})$/, '$1.$2');
  return convertedValue;
}

export function onlyAlphabet(value) {
  return value.replace(/[^a-zA-Z]/g, '');
}

export function currencyDecimal(c) {
  const number = Number(c);
  if (isNaN(number)) {
    return '0.00';
  } else {
    return number?.toFixed(2);
  }
}
