import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import TabularView from '../../../components/TabularView';
import { showErrorToast } from '../../../utils/services/toast';
import { getBulletdata } from '../../../utils/services/apiRequests';
import dayjs from 'dayjs';
import useLoader from '../../../hooks/useLoader';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfiramation from '../../Income/DeleteConfiramation';
import Dialogs from './BulletinDialog';
import useLookup from '../../../hooks/useLookup';

const BulletinPostings = () => {
  const { getOptions } = useLookup('Bulletin');

  const [rowData, setRowData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getBulletinboard = async () => {
    const data = {
      ...selectedData,
      action: 'get',
    };
    try {
      showLoader();
      const res = await getBulletdata(data);
      if (res.status === 200) {
        setRowData(res.data);
      }
    } catch (error) {
      showErrorToast('Server error');
    } finally {
      hideLoader();
    }
  };
  const deleteBullet = async (payload) => {
    try {
      showLoader();
      const res = await getBulletdata(payload);
      if (res.status === 200) {
        getBulletinboard();
      }
    } catch (error) {
      showErrorToast('Server error');
    } finally {
      hideLoader();
    }
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Create Data',
        getValue({ createdDate }) {
          return `${dayjs(createdDate).format('YYYY-MM-DD') ?? '-'}`;
        },
      },
      {
        headerName: 'Message',
        getValue({ message }) {
          return `${message ?? '-'}`;
        },
      },
      {
        headerName: 'Portal',
        getValue({ portal }) {
          return `${portal ?? '-'}`;
        },
      },
      {
        headerName: 'Begin Date',
        getValue({ begin_dt }) {
          return `${dayjs(begin_dt).format('YYYY-MM-DD') ?? '-'}`;
        },
      },
      {
        headerName: 'End Date',
        getValue({ end_dt }) {
          return `${dayjs(end_dt).format('YYYY-MM-DD') ?? '-'}`;
        },
      },
      {
        headerName: 'Action',
        renderCell: (params) => action(params),
      },
    ],
    [rowData]
  );

  const action = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => deletePopup(params?.id)}>
          <Tooltip title="Delete" placement="right">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      </Grid>
    );
  };

  const deletePopup = (data) => {
    setSelectedData(data);
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    const payload = {
      title: null,
      message: null,
      portal: null,
      begin_dt: null,
      end_dt: null,
      createdBy: null,
      sessionId: null,
      delete_ind: null,
      bulletinId: null,
      createdDate: null,
      action: '',
    };
    setSelectedData(payload);

    getBulletinboard();
  }, []);

  const closeHandleDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    const payload = {
      ...selectedData,
      action: 'delete',
      delete_ind: null,
      sessionId: '',
      title: null,
    };
    deleteBullet(payload);
    closeHandleDelete();
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '1.5rem',
          fontWeight: '700',
          lineHeight: '2rem',
          textAlign: 'left',
          color: 'var(--grey-400)',
          paddingBottom: '20px',
        }}
      >
        Bulletins/Postings
      </Typography>
      <Grid container flexDirection={'column'} spacing={2}>
        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" onClick={handleClickOpen}>
            Add Bulletins/Postings
          </Button>
        </Grid>
        <Grid item>
          <Box
            sx={{
              border: '1px solid var(--grey-350)',
              borderRadius: '6px',
            }}
          >
            <TabularView
              data={rowData}
              columns={columns}
              getId={(houseHoldMember) => houseHoldMember}
              renderHeader={(title) => (
                <Typography variant="h5" component="strong">
                  {title}
                </Typography>
              )}
              sx={{
                table: {
                  width: '100%',
                  borderCollapse: 'collapse',
                },
                th: {
                  padding: '12px 20px',
                  textAlign: 'center',
                  borderBottom: '2px solid #e2e2e7',
                },
                td: {
                  padding: '12px 20px',
                  textAlign: 'center',
                },
                tr: {
                  ':nth-child(even)': {
                    backgroundColor: '#f9f9f9',
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <DeleteConfiramation
          setOpenDeleteDialog={setOpenDeleteDialog}
          openDeleteDialog={openDeleteDialog}
          handleCancelDelete={closeHandleDelete}
          handleConfirmDelete={handleDelete}
        />
        <Dialogs
          handleClose={handleClose}
          open={open}
          getOptions={getOptions}
          getBulletinboard={getBulletinboard}
        />
      </Grid>
    </Box>
  );
};

export default BulletinPostings;
