import { createContext, createRef, useMemo, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Layout from './pages/Layout';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import PageNotFound from './pages/PageNotFound';
import Application from './pages/Application';
import ApplicationContainer from './pages/Application/ApplicationContainer';
import DashboardContent from './pages/Dashboard/DashboardContent';
import Register from './pages/Application/Register';
import CustomerReg from './pages/CustomerReg';
import ContactInfo from './pages/ContactInformation';
import AuthorizedRep from './pages/AuthorizedRep';
import AppDisposition from './pages/AppDisposition';
import HttpClient from './utils/HttpClient';
import CaseInquiry from './pages/CaseInquiry';
import CaseHome from './pages/CaseHome';
import Citizenship from './pages/Citizenship';
import NewDashobard from './pages/NewDashboard';
import CaseSummary from './pages/CaseSummary';
import Income from './pages/Income';
import Relationship from './pages/Relationship';
import HouseMembers from './pages/HouseMembers';
import AlertSearch from './pages/AlertSearch';
import EligibilityReview from './containers/EligibilityReview';
import EligibilityDetails from './pages/Eligibility/EligibilityDetails';
import LivingArrangement from './pages/LivingArrangement';
import MedicareDetails from './pages/MedicareDetails';
import WaiverFacility from './pages/WaiverAndFacilities';
import IndividualDetails from './pages/IndividualDetails';
import DomesticViolence from './pages/DomesticViolence';
import Assets from './pages/MemberAssets';
import PregnancyInfo from './pages/PregnancyInfo';
import EducationDetails from './pages/EducationDetailsContainer';
import EligibilitySummary from './pages/Eligibility/ElibilitySummary';
import Inquiry from './pages/Inquiry';
import ProgramRequest from './pages/ProgramRequest';
import HouseholdDeclaration from './pages/HouseholdDecleration';
import AuthRep from './pages/AuthRepContainer';
import CaseComments from './pages/CaseComments';
import Correspondence from './pages/Correspondence';
import Confirmation from './pages/Confirmation';
import LDSSCapacity from './pages/LDSSCapacity';
import CaseReinitiate from './pages/CaseReinitiate';
import AllCaseComments from './pages/AllCaseComments';
import HealthDisability from './pages/HealthAndDisability';
import DashboardContainer from './containers/DashboardContainer';
import Redetermination from './pages/ReDetermination';
import RedeterminationHistory from './pages/ReDeterminationHistory';
import ReOpen from './pages/ReOpenFlow';
import CPImportContainer from './pages/CPImport';
import CaseCreation from './pages/CaseCreation';
import Import from './pages/Import';
import Completion from './pages/Completion';
import RunClearance from './pages/CaseCreation/RunClearance';
import VerificationStatus from './pages/VerificationStatus';
import MedicalExpenses from './pages/MemberAssets/MedicalExpenses';
import ProgramTimeLimit from './pages/ProgramTimeLimit';
import { useSelector } from 'react-redux';
import { getAuthToken } from './store/selectors/authSelector';

import ShelterExpenses from './pages/Expenses';
import Expenses from './pages/Expenses';
import ViewAlerts from './pages/ViewAlerts';
import AlertsAndWorkItem from './pages/Alerts&WorkItem';
import Recertification from './pages/Recertification';
import CaseReview from './pages/CaseReview';
import ReriodicReports from './pages/PeriodicReports';
import PeriodicReports from './pages/PeriodicReports';
import ScheduledInterview from './pages/ScheduledInterview';
import DashboardList from './pages/NewDashboard/DashboardList';
import EligibilityParameter from './pages/Settings/EligibilityParameter/EligibilityParameter';
import Holidays from './pages/Settings/Holidays/TotalHolidays';
import Issuance from './pages/Issuance';
import ManualNotices from './pages/ManualNotices';
import NonCustodial from './pages/NonCustodialParent';
import AuditTrail from './pages/AuditTrail';
import RoomerBoarder from './pages/RoamerBoarder';
import NonCompliance from './pages/NonCompliance';
import ContactInformation from './pages/ContactInfo';
import WorkExemption from './pages/WorkExemption';
import Setting from './pages/Settings';
import IndividualAssitance from './pages/IndividualAssistance';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import WorkPlan from './pages/Works/WorksPlan';
import SanctionDisqualification from './pages/SanctionDisquatification';
import RetroMonths from './pages/RetroPage';
import EligibilityHistory from './pages/EligibilityHistory';
import ViewTicket from './pages/ViewTicket';
import { useLocation } from 'react-router-dom';
import VerifiedDocument from './pages/Import/VerifiedDocument';

export const PageContext = createContext();

function App() {
  const [currentPage, setCurrentPage] = useState('Home page');
  const [expandSideMenu, setExpandSideMenu] = useState(true);
  const token = useSelector(getAuthToken);
  const [screenId, setScreenId] = useState(-1);
  const ref = createRef(null);
  const location = useLocation();
  const [imageName, setImageName] = useState('');

  useMemo(() => {
    HttpClient.interceptors.request.handlers = [];
    HttpClient.interceptors.request.use(
      (config) => {
        if (token && token?.sub) {
          config.headers = {
            ...config.headers,
            uniqueid: token?.sub,
            uid: 'localUser',
            role: 'cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN',
          };
        }
        // if (config.url.includes('uniqueId')) {
        //   config.url = config.url.replace(
        //     '{uniqueId}',
        //     config.headers.Uniqueid
        //   );
        // }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token]);

  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  });

  function getLastSegment(url) {
    url = url.replace(/\/$/, '');
    const segments = url.split('/');
    if (!isNaN(segments[segments.length - 1])) {
      segments.pop();
    }
    return segments[segments.length - 1];
  }

  useMemo(() => {
    setImageName(getLastSegment(location.pathname));
  }, [location.pathname]);

  const download = (image, { name = imageName, extension = 'jpg' } = {}) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => {
    takeScreenShot(ref.current).then(download);
  };

  return (
    <div className="App" ref={ref}>
      <PageContext.Provider
        value={{
          currentPage,
          setCurrentPage,
          expandSideMenu,
          setExpandSideMenu,
          screenId,
          setScreenId,
        }}
      >
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="" element={<Navigate to="login" />} />
          <Route
            path=""
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route path="" element={<Navigate to="/dashboard" />} />
            <Route
              path="settings/eligibility"
              element={<EligibilityParameter />}
            />
            {/* <Route path="settings" element={<Setting />} /> */}
            <Route
              path="dashboard"
              element={<Dashboard downloadScreenshot={downloadScreenshot} />}
            >
              <Route path="settings" element={<Setting />} />
              <Route path="settings/holidays" element={<Holidays />} />
              {/* <Route path="" element={<NewDashobard />} />
              <Route path="alerts" element={<AlertSearch />} /> */}
              <Route
                path="caseReinitiate/:caseId?"
                element={<CaseReinitiate />}
              />
              <Route path="caseReopen/:caseId?" element={<ReOpen />} />
              <Route path="alerts" element={<AlertSearch />} />
              <Route path="viewallcase" element={<ViewAlerts />} />
              <Route path="viewtickets" element={<ViewTicket />} />
              <Route path="issuance/:caseId?" element={<Issuance />} />
              <Route path="" element={<DashboardContainer />}>
                <Route path="" element={<NewDashobard />} />
                {/* <Route path="alerts" element={<AlertSearch />} /> */}
                <Route
                  path="alerts-work-item"
                  element={<AlertsAndWorkItem />}
                />
                <Route path="recertification" element={<Recertification />} />
                <Route path="case-review" element={<CaseReview />} />
                <Route path="periodic-reports" element={<PeriodicReports />} />
                <Route
                  path="scheduled-interview"
                  element={<ScheduledInterview />}
                />
                <Route path="applications" element={<DashboardList />} />
                <Route
                  path="caseReinitiate/:caseId?"
                  element={<CaseReinitiate />}
                />
                {/* <Route path="alerts" element={<AlertSearch />} /> */}
              </Route>
              {/* <Route path="dashboardnew" element={<NewDashobard />} /> */}
              <Route
                path="redetermination/:caseId?"
                element={<Redetermination />}
              />

              <Route
                path="redeterminationhistory/:caseId?"
                element={<RedeterminationHistory id={397} />}
              />
              <Route
                path="auditTrail/:caseId?"
                element={<AuditTrail id={387} />}
              />

              <Route
                path="application"
                element={<ApplicationContainer cPage="new page" />}
              >
                <Route path="" element={<Application />} />
                <Route path="register" element={<Register />} />
                <Route
                  path="customer-registration/:caseId?"
                  element={<CustomerReg />}
                />
                <Route
                  path="contact-info/:caseId"
                  element={<ContactInfo newCase />}
                />
                <Route path="auth-rep/:caseId" element={<AuthRep newCase />} />
                <Route
                  path="app-disposition/:caseId"
                  element={<AppDisposition />}
                />
              </Route>
              {/* CP import */}
              <Route path="cp-import" element={<CPImportContainer />}>
                <Route
                  path="casecreation/:caseId/:controlId"
                  element={<CaseCreation />}
                />
                <Route
                  path="verifiedDocument/:caseId/:controlId"
                  element={<VerifiedDocument />}
                />
                <Route
                  path="clearance/:caseId/:controlId"
                  element={<RunClearance />}
                />
                <Route path="import/:caseId/:controlId" element={<Import />} />
                <Route
                  path="completion/:caseId/:controlId"
                  element={<Completion />}
                />
              </Route>
              <Route path="caseInquiry" element={<CaseInquiry />}>
                <Route
                  path="WorkPlan/:caseId"
                  element={<WorkPlan id={390} />}
                />
                <Route
                  path="caseHome/:caseId"
                  element={<CaseHome id={210} />}
                />
                <Route
                  path="nonCompliance/:caseId?"
                  element={<NonCompliance id={300} />}
                />
                <Route
                  path="householdmembers/:caseId"
                  element={<HouseMembers id={211} />}
                />
                <Route
                  path="verificationChecklist/:caseId"
                  element={<VerificationStatus id={131} />}
                />

                <Route
                  path="relationship/:caseId"
                  element={<Relationship id={310} />}
                />
                <Route
                  path="citizenship/:caseId"
                  element={<Citizenship id={218} />}
                />
                <Route
                  path="summary/:caseId"
                  element={<CaseSummary id={235} />}
                />
                <Route
                  path="medicaredetails/:caseId"
                  element={<MedicareDetails id={221} />}
                />
                <Route
                  path="livingarrangement/:caseId"
                  element={<LivingArrangement id={225} />}
                />
                <Route
                  path="householdDecleration/:caseId"
                  element={<HouseholdDeclaration id={222} />}
                />
                <Route
                  path="domesticviolence/:caseId"
                  element={<DomesticViolence id={226} />}
                />
                <Route path="income/:caseId" element={<Income id={229} />} />
                <Route
                  path="waiverFacility/:caseId"
                  element={<WaiverFacility id={224} />}
                />
                <Route
                  path="individualdetails/:caseId"
                  element={<IndividualDetails id={217} />}
                />
                <Route path="assets/:caseId" element={<Assets id={228} />} />
                <Route
                  path="pregnancyinfo/:caseId"
                  element={<PregnancyInfo id={223} />}
                />
                <Route
                  path="educationdetails/:caseId"
                  element={<EducationDetails id={219} />}
                />
                <Route
                  path="healthDisability/:caseId"
                  element={<HealthDisability id={220} />}
                />
                <Route
                  path="eligibilityDetails/:caseId"
                  element={<EligibilityDetails />}
                />
                <Route
                  path="sanctionDisqualification/:caseId"
                  element={<SanctionDisqualification id={301} />}
                />
                <Route path="retroMonths/:caseId" element={<RetroMonths />} />
                <Route
                  path="eligibilitySummary/:caseId"
                  element={<EligibilitySummary id={238} />}
                />
                <Route path="authrep/:caseId" element={<AuthRep id={213} />} />
                <Route path="inquiry" element={<Inquiry />} />

                <Route
                  path="programRequest/:caseId"
                  element={<ProgramRequest id={215} />}
                />
                {/* <Route
                  path="programRequest/:caseId"
                  element={<ProgramRequest />}
                /> */}
                <Route path="caseComments/:caseId" element={<CaseComments />} />

                <Route
                  path="correspondenceSearch/:caseId?"
                  element={<Correspondence />}
                />
                <Route
                  path="confirmation/:caseId"
                  element={<Confirmation id={2381} />}
                />
                <Route path="ldssCapacity/:caseId" element={<LDSSCapacity />} />
                <Route
                  path="allcasecomments/:caseId?"
                  element={<AllCaseComments />}
                />
                <Route
                  path="medicalExpenses/:caseId?"
                  element={<MedicalExpenses id={2291} />}
                />

                <Route path="expenses/:caseId?" element={<Expenses />} />
                <Route
                  path="programTimeLimit/:caseId"
                  element={<ProgramTimeLimit id={298} />}
                />
                <Route path="issuance/:caseId?" element={<Issuance />} />
                <Route
                  path="manualNotices/:caseId"
                  element={<ManualNotices />}
                />
                <Route path="nonCustodial/:caseId" element={<NonCustodial />} />
                <Route
                  path="roomerBoarder/:caseId"
                  element={<RoomerBoarder />}
                />
                <Route
                  path="contactInformation/:caseId"
                  element={<ContactInformation id={210} />}
                />
                <Route
                  path="workExemption/:caseId"
                  element={<WorkExemption />}
                />
                <Route
                  path="individualAss/:caseId"
                  element={<IndividualAssitance />}
                />
                <Route
                  path="eligibilityHistory/:caseId"
                  element={<EligibilityHistory />}
                />
              </Route>
              {/* Eligibility */}
              {/* <Route path="eligibilityReview" element={<EligibilityReview />}>
                <Route
                  path="eligibilityDetails"
                  element={<EligibilityDetails />}
                />
                <Route
                  path="eligibilitySummary"
                  element={<EligibilitySummary />}
                />
              </Route> */}
            </Route>
            {/* <Route path="404" element={<PageNotFound />} /> */}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </PageContext.Provider>
    </div>
  );
}

export default App;
