import {
  getAuthRepDetails,
  postAuthRepDetails,
} from '../../../utils/services/authorizedRepServices/requests';
import { useEffect, useMemo, useState } from 'react';
import usePageNav from '../../../hooks/usePageNav';
import ActionButton from '../../../components/ActionButtons';
import { Button, Stack, Box } from '@mui/material';
import IndividualDetailsTable from '../../IndividualDetails/IndividualDetailsTable';
import AddAuthRep from '../AuthRep/AddAuthRep/index';
import {
  showSuccessToast,
  showErrorToast,
} from '../../../utils/services/toast';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import { find, map } from 'lodash';
import { getFullName } from '../../../utils/helpers';
import { selectCaseDetails } from '../../../store/selectors/caseSelector';
import DeleteModal from '../../IndividualDetails/DeleteModal';
import useLoader from '../../../hooks/useLoader';

function AuthorizedRep({
  getOptions,
  selected,
  getLkpMatch,
  authRepDetails,
  agHeadList,
}) {
  const { caseId } = usePageNav();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const caseDetails = useSelector(selectCaseDetails);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editData, setEditData] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [showLoader, hideLoader] = useLoader();
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  const authRepList = useMemo(() => {
    return (
      find(
        agHeadList,
        (agHead) => Number(agHead.agHeadId) === Number(selected.agHeadId)
      )?.authRepList ?? []
    );
  }, [agHeadList, selected.agHeadId]);

  const {
    classificationOpts,
    relationshipOpts,
    stateOpts,
    typeOpts,
    accesTypeOptions,
  } = useMemo(() => {
    return {
      classificationOpts: getOptions('AuthRepTypeCd'),
      relationshipOpts: getOptions('AuthRepRelation'),
      stateOpts: getOptions('State'),
      typeOpts: getOptions('PhoneType'),
      accesTypeOptions: getOptions('AuthRepAccessType'),
    };
  }, [getOptions]);

  useEffect(() => {
    authRepDetails();
  }, [caseId]);

  const getRowId = (row) => {
    return row.authRepId;
  };

  const handleDelete = (params) => {
    setDeleteData(params.row);
    setOpenDeleteModal(true);
  };

  const handleEdit = (params) => {
    setEditData(params.row);
    setOpenEditModal(true);
  };

  const onSaveAuthRep = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postAuthRepDetails(data, caseId, dirtyStatus);

      if (res.status === 200) {
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added New Authrep');
          handleClose();
        } else if (dirtyStatus === 'update') {
          showSuccessToast('Successfully Updated The Authrep');
          setOpenEditModal(false);
        } else {
          showSuccessToast('Successfully Deleted The Authrep');
          setOpenDeleteModal(false);
        }
      }
      authRepDetails();
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'personName',
        headerName: 'Auth Rep Name',

        renderCell: (params) => {
          const name =
            params?.row?.personName?.firstName +
            ' ' +
            params?.row?.personName?.lastName;
          return name;
        },

        width: 180,
      },
      {
        field: 'effectiveBeginDt',
        headerName: 'Begin Date',
        width: 150,
      },
      {
        field: 'effectiveEndDt',
        headerName: 'End Date',
        renderCell: (params) => {
          const endDate =
            params?.row?.effectiveEndDt === '9999-12-31'
              ? ''
              : params?.row?.effectiveEndDt;
          return endDate;
        },
        width: 150,
      },

      {
        field: 'authRepType',
        headerName: 'Type',
        width: 100,
        renderCell: (params) =>
          getLkpMatch('AuthRepTypeCd', params?.row?.authRepType),
      },

      {
        field: 'effectiveEndDate',
        headerName: 'Address',
        renderCell: (params) => {
          const address =
            params?.row?.address?.addressLine1 +
            ' ' +
            params?.row?.address?.addressLine2;
          return address;
        },
        width: 130,
      },

      {
        renderCell(params) {
          return (
            <span>
              <ActionButton
                onEdit={() => handleEdit(params)}
                onDelete={() => handleDelete(params)}
              />
            </span>
          );
        },
        headerName: 'Action',
        width: 100,
      },
    ],
    [getLkpMatch]
  );

  const { houseMembers } = useSelector(selectHouseMembers);

  const memberOpts = useMemo(() => {
    return map(houseMembers, ({ personName, individualId }) => {
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      return {
        label: fullName,
        value: individualId,
      };
    });
  }, [houseMembers]);

  return (
    <div>
      <TabPanel value={activeTab} index={0}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ mb: '1rem', mt: '1rem' }}
        >
          <Button onClick={() => setOpen(true)}>+ Add Auth Rep</Button>
        </Stack>
      </TabPanel>
      <IndividualDetailsTable
        columns={columns}
        rows={authRepList}
        getRowId={getRowId}
      />
      {open && (
        <AddAuthRep
          open={open}
          handleClose={handleClose}
          classificationOpts={classificationOpts}
          relationshipOpts={relationshipOpts}
          stateOpts={stateOpts}
          typeOpts={typeOpts}
          accesTypeOptions={accesTypeOptions}
          onSaveAuthRep={onSaveAuthRep}
          memberOpts={memberOpts}
          selected={selected}
          caseDetails={caseDetails}
          getLkpMatch={getLkpMatch}
          caseId={caseId}
        />
      )}

      <AddAuthRep
        editAuthrep
        editData={editData}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        classificationOpts={classificationOpts}
        relationshipOpts={relationshipOpts}
        stateOpts={stateOpts}
        typeOpts={typeOpts}
        accesTypeOptions={accesTypeOptions}
        onSaveAuthRep={onSaveAuthRep}
        memberOpts={memberOpts}
        selected={selected}
        caseDetails={caseDetails}
        getLkpMatch={getLkpMatch}
        caseId={caseId}
      />

      <DeleteModal
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        deleteData={deleteData}
        fn={onSaveAuthRep}
      />
    </div>
  );
}

export default AuthorizedRep;
