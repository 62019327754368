import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EmptyState from '../../components/EmptyState';
import { Box } from '@mui/material';
import NoRecord from '../../assets/icons/No-data-amico.svg';

const HealthDataGrid = ({ rows, columns }) => {
  return (
    <Box sx={{ display: 'grid' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
          height: '100%',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            p: '10px',
          },
        }}
        getRowHeight={() => 'auto'}
        loading={false}
        rowsPerPageOptions={[5, 10, 15]}
        checkboxSelection={false}
        disableRowSelectionOnClick={true}
        disableColumnMenu={true}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        autoHeight
      />
    </Box>
  );
};

export default HealthDataGrid;
