import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTabs from '../../../../hooks/useTabs';
import { map } from 'lodash';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Avatar from '../../../../components/Avatar';
import TextContent from '../../../../components/TextContent';
import Fieldset from '../../../../components/Fieldset';
import Status from '../../../../components/Status';
import { getStatus } from '../../../../utils/helpers';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import IndividualFinancial from '../EligibilityAccordion/IndividualFinancial';
import IndividualEligibility from '../EligibilityAccordion/IndividualEligibility';

// #586 penalities pending

function IndividualElSummary({
  financialInfo,
  agIndvEligResult,
  getLkpMatch,
  programName,
}) {
  const { Tabs, Tab, TabContent, onTabChange, activeTab } = useTabs();

  return (
    <div>
      <Tabs onChange={onTabChange} value={activeTab}>
        <Tab label="Individual Eligibility" />
        <Tab label="Individual Financial" />
      </Tabs>
      <TabContent sx={{ border: '1px solid #C2C2B7', borderRadius: '0.25rem' }}>
        <div area-label="Individual Eligibility">
          <IndividualEligibility
            agIndvEligResult={agIndvEligResult}
            getLkpMatch={getLkpMatch}
            programName={programName}
          />
        </div>
        <div area-label="Individual Financial">
          <IndividualFinancial
            financialInfo={financialInfo}
            programName={programName}
            getLkpMatch={getLkpMatch}
          />
        </div>
      </TabContent>
    </div>
  );
}

IndividualElSummary.propTypes = {};

export default IndividualElSummary;
