import Reac, { useState, useMemo } from 'react';
import FormSection from '../../../components/FormSection';
import { TextField, Box, Grid, Button, Typography, Stack } from '@mui/material';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  postReinitiate,
  postValidate,
} from '../../../utils/services/CaseReinitiate/apiRequests';
import useLoader from '../../../hooks/useLoader';
import useLookup from '../../../hooks/useLookup';
import { showErrorToast } from '../../../utils/services/toast';
import usePageNav from '../../../hooks/usePageNav';
import { useNavigate } from 'react-router-dom';

function ReOpenValid({ onClose, selectedProgramName }) {
  console.log(selectedProgramName);
  const { caseId, navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [reinitiate, setReinitiate] = useState(null);
  const [isvalidate, setIsValidate] = useState(null);
  const { getOptions } = useLookup('Reinitiate');
  const navigate = useNavigate();

  const filterOptions = (options, valuesToKeep) => {
    return options.filter((option) => valuesToKeep.includes(option.value));
  };
  const reasonOptions = filterOptions(
    getOptions('ReinstationReopeningReason'),
    ['NP', 'QC']
  );
  const { programOpts, reasonOpts } = useMemo(() => {
    return {
      programOpts: getOptions('ProgramCode'),
      // reasonOpts: getOptions('ReinstationReopeningReason'),
      reasonOpts: reasonOptions,
    };
  });
  const validationSchema = Yup.object({
    fname: Yup.string().required('Please Enter First Name'),
    lname: Yup.string().required('Please Enter Last Name'),
    dob: Yup.string().required('Please Enter First Name'),
    reason: Yup.string().when('isvalidate', {
      is: () => isvalidate,
      then: () => Yup.string().required('Please Select the Reason'),
      otherwise: () => Yup.string().notRequired(),
    }),
    date: Yup.string().when('isvalidate', {
      is: () => isvalidate,
      then: () => Yup.string().required('Please Select the Date'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });
  const formik = useFormik({
    initialValues: {
      caseId: caseId,
      program: selectedProgramName,
      fname: '',
      lname: '',
      dob: '',
      reason: '',
      date: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (!formik.isValid || isvalidate === null) {
          return;
        }

        if (formik.values.reason && formik.values.date) {
          await postReinitiateCase(values);
        } else {
          postValidateCase(values);
        }
      } catch (error) {
        showErrorToast(error);
      }
    },
  });
  const postValidateCase = async (data) => {
    try {
      showLoader();
      const res = await postValidate(data, caseId);
      if (res.status === 200 && res.data) {
        setIsValidate(res.data?.valid === 'Valid');
      } else {
        setIsValidate(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const postReinitiateCase = async (data) => {
    try {
      showLoader();
      const res = await postReinitiate(data, caseId);
      if (res.status === 200) {
        setReinitiate(res.data);
        // navigations.toCaseHome(caseId);
        navigate('/dashboard/application/register');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <Box>
      <FormSection label="ReOpen Validation">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field label="CaseId" formik={formik} name="caseId" required>
              <TextField placeholder="Enter Case Id" disabled />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field label="Program Name" name="program" required formik={formik}>
              <Dropdown
                options={programOpts}
                placeholder="Select the Program"
                disabled
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field label="First Name" formik={formik} name="fname" required>
              <TextField placeholder="Enter First Name" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field label="Last Name" formik={formik} name="lname" required>
              <TextField placeholder="Enter Last Name" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Date Of Birth"
              isDate
              formik={formik}
              name="dob"
              required
            >
              <DatePicker placeholder=" Select Date" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{ mt: '1.5rem' }}
              variant="contained"
              onClick={() => setIsValidate(formik.handleSubmit)}
            >
              Validate
            </Button>
          </Grid>
          {isvalidate && (
            <>
              <Grid item xs={6}>
                <Field label="Reason" name="reason" required formik={formik}>
                  <Dropdown
                    options={reasonOpts}
                    placeholder="Select the Reason"
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field label="Date" formik={formik} isDate name="date" required>
                  <DatePicker placeholder=" Select Date" />
                </Field>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" color={'red'}>
                  * MA Programs can be reinstated only before 60 days of program
                  status date for 'Tardy re-det returned', 30 days for
                  'Closed/denied in error', and 180 days for 'Verifications
                  returned'. CA and SNAP programs can only be reinstated before
                  30 days for 'Closed/denied in error' reason.
                </Typography>
              </Grid>
            </>
          )}
          {isvalidate === false && (
            <>
              <Grid item xs={8}>
                <Typography variant="h6" color="red">
                  Provided DOB, First and Last Name does not match with the
                  record in System.
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={onClose}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={() => setReinitiate(formik.handleSubmit)}
          >
            Save
          </Button>
        </Stack>
      </FormSection>
    </Box>
  );
}

export default ReOpenValid;
