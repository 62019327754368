import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown from '../../components/Dropdown';
import Field from '../../components/Field';
import { TextField, Grid, Stack } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useFormik } from 'formik';
import useLoader from '../../hooks/useLoader';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import {
  postSuppressReason,
  removeSuppression,
} from '../../utils/services/correspondence/apiRequest';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ReasonDialog = ({
  onClose,
  open,
  setOpen,
  activeTab,
  getOptions,
  printId,
  caseId,
  fetchSearchData,
}) => {
  const [showLoader, hideLoader] = useLoader();

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  // const onChangeDropdown = (event) => {
  //   const { name, value } = event.target;
  //   formik.setFieldValue(name, value);
  // };

  const validationSchema = Yup.object({
    supIndex: Yup.string().required('Required'),
    supOtherReason: Yup.string().when('supIndex', {
      is: (supIndex) => {
        return supIndex === '3';
      },
      then: () => Yup.string().required('Required'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const validationSchemaForResend = Yup.object({
    resend: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      supIndex: '',
      supOtherReason: '',
      printSmryId: printId ?? '',
      resend: '',
    },
    enableReinitialize: true,
    validationSchema:
      activeTab === 2 ? validationSchemaForResend : validationSchema,
    onSubmit: async (values) => {
      let updateValues = {
        printSmryId: values.printSmryId,
        supIndex: values.supIndex,
        supOtherReason: values.supIndex === '3' ? values.supOtherReason : '',
      };
      if (values.resend === 'N') {
        setOpen(false);
        return;
      }
      try {
        showLoader();
        const res =
          activeTab === 2
            ? await removeSuppression(printId)
            : await postSuppressReason(caseId, updateValues);
        if (res.status === 200) {
          showSuccessToast('Successfully Updated');
          fetchSearchData();
          handleClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoader();
      }
    },
  });

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      open={open}
      fullWidth={false}
      maxWidth="md"
      PaperProps={{
        style: { width: '25%', position: 'absolute', right: 0 },
      }}
    >
      <DialogTitle>
        {activeTab === 2 ? 'Re-Send Suppressed Form' : 'Suppress Reason'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {activeTab === 2 ? (
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <Field name="resend" label="Resend" formik={formik} required>
              <Dropdown
                placeholder="Select Resend"
                options={getOptions('YesNo')}
              />
            </Field>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <Field
              required
              name="supIndex"
              label="Suppress Reason"
              formikHandler={false}
              formik={formik}
            >
              <Dropdown
                // name="supIndex"
                options={getOptions('SuppressReason')}
                placeholder="Select Reason"
                // value={formik.values.supIndex}
                // onChange={onChangeDropdown}
              />
            </Field>
          </Grid>
          {formik.values.supIndex === '3' && (
            <Grid item xs={12}>
              <Field
                name="supOtherReason"
                label="Reason"
                formik={formik}
                required
              >
                <TextField placeholder="Reason" />
              </Field>
            </Grid>
          )}
        </Grid>
      )}
      <Stack direction="row" px={3} my={2} justifyContent="space-between">
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </Dialog>
  );
};

export default ReasonDialog;
