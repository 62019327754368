import IndividualDetailsTable from '../IndividualDetailsTable';
import { get } from 'lodash';
import ActionButton from '../../../components/ActionButtons';
import { useState, useEffect } from 'react';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import AddPreSchool from './AddPreSchool';
import { postPreSchool } from '../../../utils/services/individualDetailsServices/requests';
import { useParams } from 'react-router-dom';
import DeleteModal from '../DeleteModal';
import useLoader from '../../../hooks/useLoader';

function PreSchool({
  member,
  preSchoolHealthVerificationOpts,
  individualId,
  fetchIndividual,
  dob,
  open,
  handleClose,
}) {
  const [deleteData, setDeleteData] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editData, setEditData] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const params = useParams();

  const handleDelete = (params) => {
    setDeleteData(params.row);
    setOpenDeleteModal(true);
  };

  const handleEdit = (params) => {
    setEditData(params.row);
    setOpenEditModal(true);
  };

  const columns = [
    {
      field: 'ppiBeginDate',
      headerName: 'Begin Date',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'ppiEndDate',
      headerName: 'End Date',
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const endDate =
          params?.row?.ppiEndDate === '9999-12-31'
            ? ''
            : params?.row?.ppiEndDate;
        return endDate;
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      flex: 1,
      renderCell(params) {
        return (
          <span>
            <ActionButton
              onDelete={() => handleDelete(params)}
              onEdit={() => handleEdit(params)}
            />
          </span>
        );
      },
      headerName: 'Action',
    },
  ];

  const individualDetails = get(
    member,
    'individualDetails.primaryPrevention',
    []
  );

  useEffect(() => {
    if (editData) {
      setOpenEditModal(true);
    }
  }, [editData]);

  const getRowId = (row) => {
    return row.recordId;
  };

  const onSavePreSchool = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postPreSchool(
        data,
        params.caseId,
        individualId,
        dirtyStatus
      );

      if (res.status === 200) {
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added New PreSchool');
        } else if (dirtyStatus === 'update') {
          showSuccessToast('Successfully Updated The PreSchool');
        } else {
          showSuccessToast('Successfully Deleted The PreSchool');
        }
        fetchIndividual();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
      handleClose();
    }
  };

  return (
    <div>
      <IndividualDetailsTable
        rows={individualDetails}
        columns={columns}
        getRowId={getRowId}
      />

      <AddPreSchool
        AddPreSchool
        open={open}
        preSchoolHealthVerificationOpts={preSchoolHealthVerificationOpts}
        handleClose={handleClose}
        onSavePreSchool={onSavePreSchool}
        dob={dob}
      />
      <AddPreSchool
        dob={dob}
        editPreSchool
        editData={editData}
        preSchoolHealthVerificationOpts={preSchoolHealthVerificationOpts}
        onSavePreSchool={onSavePreSchool}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
      />
      <DeleteModal
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        deleteData={deleteData}
        fn={onSavePreSchool}
      />
    </div>
  );
}

export default PreSchool;
