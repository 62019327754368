import dayjs from 'dayjs';
import { map } from 'lodash';

export const redeterminationPayload = (data, generatedCases) => {
  const { packetReceivedDate, signatureProvided, signatureDate } = data;

  const result = map(generatedCases, (generatedCase) => ({
    ...generatedCase,
    packetReceivedDate,
    signatureDate,
    signatureProvided,
  }));

  return result;
};

export const redeterminationSignaturePayload = (signatureImage) => {
  return {
    signatoryName: 'sign',
    signatoryType: 'HM',
    signatureImage: signatureImage,
    signedDt: dayjs().format('YYYY-MM-DD'),
    signatureSource: 'screening',
    paperApplicationAvailableYN: 'Y',
    programId: null,
  };
};

export const redeterminationHistoryPayload = (data) => {
  const { programName, fromDate, toDate } = data;
  return {
    fromDate: fromDate,
    programName: programName,
    toDate: toDate ? toDate : '9999-12-31',
  };
};
