import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { getDomesticViolence } from '../../../utils/services/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import usePageNav from '../../../hooks/usePageNav';
import { createRef, useEffect, useMemo, useState } from 'react';
import useMount from '../../../hooks/useMount';
import useLoader from '../../../hooks/useLoader';
import { Button, Stack } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ActionButton from '../../../components/ActionButtons';
import AddDomesticViolence from '../DomesticViolence/AddDomesticViolence';
import {
  postDomesticViolence,
  getDocumentsFiles,
} from '../../../utils/services/apiRequests';
import DeleteModal from './DeleteModal';
import useLookup from '../../../hooks/useLookup';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

function DomVioDetail({ selected }) {
  const { individualId } = selected;
  const [open, setOpen] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const { caseId, navigations } = usePageNav();
  const [domesticViolenceData, setDomesticViolenceData] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editData, setEditData] = useState('');
  const [deleteData, setDeleteData] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const handleClose = () => setOpen(false);
  const { getLkpMatch, lkpRaw } = useLookup('LivingArrangement');
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);

  useEffect(() => {
    if (individualId) {
      GET_domesticViolence();
    }
  }, [individualId]);

  const handleDelete = (params) => {
    setDeleteData(params.row);
    setOpenDeleteModal(true);
  };

  const handleEdit = (params) => {
    setEditData(params.row);
    setOpenEditModal(true);
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const dob = selected.dob;

  const GET_domesticViolence = async () => {
    try {
      showLoader();
      const res = await getDomesticViolence(caseId, individualId);
      if (res.status === 200) {
        setDomesticViolenceData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  // useEffect(() => {
  //   getUploadFiles();
  // }, []);

  useMount(() => {
    getUploadFiles();
  });

  const columns = useMemo(
    () => [
      {
        field: 'effectiveBeginDt',
        headerName: 'Begin Date',
        width: 140,
      },
      {
        field: 'effectiveEndDt',
        headerName: 'End Date',
        width: 120,
      },

      {
        field: 'dmstcVoilenceInd',
        headerName: 'Victim Of Violence',
        renderCell: (params) =>
          getLkpMatch('YesNo', params?.row?.dmstcVoilenceInd),
        width: 210,
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 100,
        renderCell(params) {
          return (
            <span>
              {' '}
              <ActionButton
                onEdit={() => handleEdit(params)}
                onDelete={() => handleDelete(params)}
              />{' '}
            </span>
          );
        },
      },
    ],
    [getLkpMatch]
  );

  const getRowId = (row) => {
    return row.recordId;
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  const onSaveDomesticViolence = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postDomesticViolence(
        data,
        caseId,
        individualId,
        dirtyStatus
      );

      if (res.status === 200) {
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added New Domestic Violence');
          setOpen(!open);
        } else if (dirtyStatus === 'update') {
          showSuccessToast('Successfully Updated The  Domestic Violence');
          setOpenEditModal(!openEditModal);
        } else {
          showSuccessToast('Successfully Deleted The  Domestic Violence');
          setOpenDeleteModal(!openDeleteModal);
        }
        GET_domesticViolence();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <div>
      <TabPanel value={activeTab} index={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: '1rem', mt: '1rem' }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload files
          </Button>

          <Button onClick={() => setOpen(true)}>+ Add Domestic Violence</Button>
        </Stack>
      </TabPanel>
      <Box sx={{ display: 'grid' }}>
        <DataGrid
          rows={domesticViolenceData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          disableRowSelectionOnClick={true}
          disableColumnMenu={true}
          getRowId={getRowId}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '50vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '50vh',
            },
            minHeight: '50vh',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
          }}
        />
      </Box>

      <AddDomesticViolence
        adddomesticviolence
        open={open}
        handleClose={handleClose}
        onSaveDomesticViolence={onSaveDomesticViolence}
        dob={dob}
      />

      <AddDomesticViolence
        dob={dob}
        editData={editData}
        editdomesticviolence
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        onSaveDomesticViolence={onSaveDomesticViolence}
      />

      <DeleteModal
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        deleteData={deleteData}
        fn={onSaveDomesticViolence}
      />

      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}

export default DomVioDetail;
