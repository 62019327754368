import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import SelectableView from '../../components/SelectableView';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { getIncome, getHouseMembers } from '../../utils/services/apiRequests';
import { useParams } from 'react-router';
import { entries, get, map } from 'lodash';
import useLookup from '../../hooks/useLookup';
import RelationType from './RelationType';
import { showErrorToast } from '../../utils/services/toast';
import { getAge } from '../../utils/helpers';
import usePageNav from '../../hooks/usePageNav';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import PageContainer from '../../components/PageContainer';
import usePage from '../../hooks/usePage';

function Relationship({ id }) {
  const { caseId, navigations } = usePageNav(id);
  const { navProps } = usePage(id);
  const { lkpFlatten, getLkpMatch, lkpRaw } = useLookup('Relationship');
  const [houseMembers, setHouseMembers] = useState([]);
  const [fetchingMembers, setFetchingMembers] = useState(false);

  const houseHoldMembers = useMemo(() => {
    return get(houseMembers, 'householdMemberDetailsList');
  }, [houseMembers]);

  const relationOptions = useMemo(() => {
    const { IndividualRelationship = [] } = lkpFlatten;
    return map(entries(IndividualRelationship), ([value, label]) => ({
      value,
      label,
    }));
  }, [lkpFlatten]);

  const fetchRelations = async () => {
    try {
      setFetchingMembers(true);
      const res = await getHouseMembers(caseId);
      if (res.status === 200) {
        setHouseMembers(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetchingMembers(false);
    }
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  const onNext = () => {
    navigations.toCitizenship();
  };

  const onBack = () => {
    navigations.toHhMembers();
  };

  return (
    <PageContainer
      screenId={id}
      title="Relationship"
      onNext={onNext}
      onBack={onBack}
      navProps={navProps}
    >
      <SelectableView
        title="Members"
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        data={houseMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        showAdd={false}
        showDelete={false}
        showEdit={false}
      >
        <RelationType
          caseId={caseId}
          genderLkp={lkpFlatten?.Gender}
          houseMembers={houseMembers}
          relationOptions={relationOptions}
        />
      </SelectableView>
    </PageContainer>
  );
}

Relationship.propTypes = {};

export default Relationship;
