import React, { useEffect, useMemo, useState } from 'react';
import useTabs from '../../../hooks/useTabs';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ShelterExpenses from '../ShelterExpenses';
import { CloseRounded } from '@mui/icons-material';
import {
  getShelter,
  postShelter,
} from '../../../utils/services/ShelterExpenses/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';
import ActionButtons from '../../../components/ActionButtons';
import DeleteConfiramation from '../DeleteConfiramation';
import usePageNav from '../../../hooks/usePageNav';
import { map } from 'lodash';
import useLookup from '../../../hooks/useLookup';
import AddMember from '../../MemberAssets/MedicalExpenses/AddMember';
import { medicalExpenses } from '../../../utils/services/medicalExpenses.js/apiRequests';
import { deleteMedicalExpensesRecord } from '../../../utils/services/medicalExpenses.js/apiRequests';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/expenses.svg';

export default function ExpensesContent({ selected }) {
  const { Tabs, Tab, TabContent, onTabChange, activeTab } = useTabs();
  const [selectedIndividual, setSelectedIndividual] = useState({});
  const [shelterOpen, setShelterOpen] = useState(false);
  const { caseId } = usePageNav();
  const { individualId } = selected;
  const [shelterData, setShelterData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [shelterEdit, setShelterEdit] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [columnsData, setColumnsData] = useState([]);
  const [rowData, setRowData] = useState('');
  // const [medicalData, setMedicalData] = useState([])
  const [allMembers, setAllMembers] = useState([]);
  const [personName, setPersonName] = useState({});
  const [memberExpensesAry, setMemberExpensesAry] = useState([]);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState([]);
  const [reportEdited, setReportEdited] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { getLkpMatch } = useLookup('ShelterExpense');
  const { getOptions, lkpRaw } = useLookup('medicalExpenses');
  const [deleteThisRecod, setDeleteRecord] = useState({});
  const [deleteMedicalStatus, setMedicalDelete] = useState(false);
  const [currentTab, setCurrentTab] = useState('shelter');
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);

  useEffect(() => {
    if (individualId) {
      getShelterExp();
    }
  }, [individualId]);

  const totalAmount = (shelterExpenseTypes, utilityExpenseTypes) => {
    let totalShelter = 0;
    let totalUtility = 0;
    if (shelterExpenseTypes) {
      totalShelter = shelterExpenseTypes
        .map((expense) => parseFloat(expense?.shelterExpenseAmount || 0))
        .reduce((acc, amount) => acc + amount, 0);
    }
    if (utilityExpenseTypes) {
      totalUtility = utilityExpenseTypes
        .map((expense) => parseFloat(expense?.utilityExpenseAmount || 0))
        .reduce((acc, amount) => acc + amount, 0);
    }
    const total = totalShelter + totalUtility;
    return total.toFixed(2);
  };

  const expenseType = (shelterExpenseTypes, utilityExpenseTypes) => {
    let shelterUtilityType = [];
    if (shelterExpenseTypes) {
      map(shelterExpenseTypes, (types) =>
        shelterUtilityType.push(
          getLkpMatch('ShelterExpense', types?.shelterExpTypeCd)
        )
      );
    }
    if (utilityExpenseTypes) {
      map(utilityExpenseTypes, (types) =>
        shelterUtilityType.push(
          getLkpMatch('UtilityExpense', types?.utilityTypeCd)
        )
      );
    }
    return shelterUtilityType.toString();
  };

  const Shletercolumns = [
    {
      field: 'expenseType',
      headerName: 'Expense Type',
      renderCell: (params) => (
        <Tooltip
          title={expenseType(
            params.row?.shelterExpenseTypes,
            params.row?.shelterUtilityTypes
          )}
        >
          <span>
            {expenseType(
              params.row?.shelterExpenseTypes,
              params.row?.shelterUtilityTypes
            )}
          </span>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: 'bgnDt',
      headerName: 'Begin Date',
      flex: 1,
    },
    {
      field: 'endDt',
      headerName: 'End Date',
      renderCell: (params) => {
        const value = params.value;
        return value && value.includes('999') ? '-' : value;
      },
    },
    {
      field: 'expensesAmount',
      headerName: 'Amount',
      renderCell: (params) =>
        `$ ${totalAmount(
          params.row?.shelterExpenseTypes,
          params.row?.shelterUtilityTypes
        )}`,
      flex: 1,
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => shelterEditData(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];

  function handleEdit(params) {
    const memberToedit = memberExpensesAry?.find(
      (obj) => obj.recordId === params.id
    );
    setSelectedIndividual(memberToedit);
    setOpen(true);
  }
  const sampleColumn = [
    { field: 'provideName', headerName: 'Provider Name', flex: 1 },
    { field: 'expenseType', headerName: 'Expense Type', flex: 1 },
    { field: 'dateIncurred', headerName: 'Date Incurred', flex: 1 },
    { field: 'endDate', headerName: 'End Date', flex: 1 },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell(params) {
        return (
          <span>
            <ActionButtons
              onEdit={() => handleEdit(params)}
              onDelete={() => handlemedicaldelete(params)}
            />
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    getMemberTables();
  }, [selected, reportEdited]);

  async function getMemberTables() {
    showLoader();
    try {
      const memberDetails = await medicalExpenses(caseId);
      if (memberDetails.status === 200) {
        setAllMembers(memberDetails.data.householdMembers);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }

  function formatCurrency(value) {
    let converToCurrency = value.toString().replace(/\D/g, '');
    converToCurrency = converToCurrency.replace(/(\d)(\d{2})$/, '$1.$2');
    converToCurrency = converToCurrency.replace(/(?=(\d{3})+(\D))\B/g, ',');
    return converToCurrency;
  }

  const getAmountSum = (retroAmount) => {
    const sumedAmount = retroAmount.reduce(
      (acc, current) => current.amount + acc,
      0
    );

    return `$ ${formatCurrency(sumedAmount)}`;
  };

  useMemo(() => {
    const findMember = allMembers.find(
      (obj) => obj.individualId === selected.individualId
    );

    setPersonName(findMember?.personName);
    const designTable = findMember?.medicalExpenses.map((obj, id) => {
      return {
        id: obj?.recordId,
        provideName: obj?.providerName,
        expenseType: getLkpMatch('MedicalExpenseType', obj?.medicalExpenseType),

        dateIncurred: obj?.effectiveBeginDt,

        endDate: obj?.effectiveEndDt,
        amount: getAmountSum(obj?.retroMonths?.[0].amountModel),
      };
    });
    setMemberExpensesAry(findMember?.medicalExpenses ?? '');
    setRow(designTable ?? []);
  }, [selected, reportEdited, allMembers]);

  const gridData = useMemo(() => {
    if (activeTab === 0) {
      setColumnsData(Shletercolumns);
      setRowData(shelterData);
      setCurrentTab('shelter');
    }
    if (activeTab === 1) {
      setColumnsData(sampleColumn);
      setRowData(row);
      setCurrentTab('medical');
    }
  }, [activeTab, shelterData, row]);

  const toggleShelter = () => {
    setShelterOpen(false);
    setShelterEdit(false);
    setEditOpen(false);
  };

  const shelterEditData = (data) => {
    setEditOpen(true);
    setShelterEdit(data);
  };

  const deletePopup = async (rowData) => {
    setSelectedData(rowData);
    setOpenDeleteDialog(true);
  };

  const deleteShelter = async () => {
    const payload = {
      ...selectedData,
      dirtyStatus: 'delete',
    };
    try {
      showLoader();
      const res = await postShelter(payload, caseId);
      if (res.status === 200) {
        showSuccessToast('Succesfully Deleted The Shelter Expenses');
        getShelterExp();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleDelete = () => {
    deleteShelter();
  };

  const getShelterExp = async () => {
    try {
      showLoader();
      const res = await getShelter(individualId);
      if (res.status === 200) {
        setShelterData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const handleConfirmDeletemedical = async () => {
    showLoader();
    try {
      const res = await deleteMedicalExpensesRecord(
        deleteThisRecod.primaryData,
        deleteThisRecod.individualId,
        caseId,
        deleteThisRecod.recordId,
        deleteThisRecod.providedVerificationId
      );

      if (res.status === 200) {
        setMedicalDelete(false);
        showSuccessToast('Medical Expenses Deleted Successfully');
        setReportEdited(!reportEdited);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  function handlemedicaldelete(params) {
    const memberTodelete = memberExpensesAry?.find(
      (obj) => obj.recordId == params.id
    );

    const destrcJson = {
      dirtyStatus: 'delete',
      providerName: memberTodelete?.providerName ?? '',
      expenseDetails: memberTodelete?.medicalExpenseType ?? '',
      effectiveBDate: memberTodelete?.effectiveBeginDt ?? '',
      effectiveEDate: memberTodelete?.effectiveEndDt ?? '',
      serviceProvided: memberTodelete?.serviceProvided ?? '',
      snapConsider: memberTodelete?.notsnap ?? '',
      frequency: memberTodelete?.retroMonths?.[0]?.frequency ?? [],
      waiver: memberTodelete?.notwaiver ?? '',
      frequencyAmountAry: memberTodelete?.retroMonths?.[0]?.amountModel ?? [],
    };

    setDeleteRecord({
      primaryData: destrcJson,
      individualId: memberTodelete.individualId,
      recordId: memberTodelete.recordId,
      providedVerificationId:
        memberTodelete.medicalExpenseVerification.providedVerificationId,
    });
    setMedicalDelete(true);
  }

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };
  return (
    <>
      <Tabs onChange={onTabChange} value={activeTab}>
        {/* <Tab label="Dependent Care Expenses" /> */}
        <Tab label="Shelter Expenses" />
        <Tab label="Medical Expenses" />
        {/* <Tab label="Child Support or Alimony Expenses" /> */}
      </Tabs>
      <TabContent>
        {/* <div area-label="Dependent Care Expenses" style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              //   startIcon={<UploadFileIcon />}
              //   onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button
              // onClick={AddInstitutionDetails}
              startIcon={<AddIcon />}
            >
              Add Dependent Care Expenses
            </Button>
          </Stack>
        </div> */}
        <div area-label="Shelter Expenses" style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button
              onClick={() => setShelterOpen(true)}
              startIcon={<AddIcon />}
            >
              Add Shelter Expenses
            </Button>
          </Stack>
        </div>
        <div area-label="Medical Expenses" style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button
              onClick={() => {
                setSelectedIndividual({});
                setOpen(true);
              }}
              startIcon={<AddIcon />}
            >
              Add Medical Expenses
            </Button>
          </Stack>
        </div>
        {/* <div
          area-label="Child Support or Alimony Expenses"
          style={{ width: '100%' }}
        >
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              //   startIcon={<UploadFileIcon />}
              //   onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button
              // onClick={AddInstitutionDetails}
              startIcon={<AddIcon />}
            >
              Add Child Support or Alimony Expenses
            </Button>
          </Stack>
        </div> */}
      </TabContent>
      <Box sx={{ display: 'grid' }} mt="1rem">
        <DataGrid
          rows={rowData ?? []}
          columns={columnsData}
          getRowId={(row) => {
            return currentTab === 'shelter' ? row?.recordId : row?.id;
          }}
          sx={{
            marginTop: 2,
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: 16,
              fontWeight: 700,

              textAlign: 'center',
              color: 'black',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '30vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '30vh',
            },
            minHeight: '30vh',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          hideFooter
        />
      </Box>
      <Dialog
        open={shelterOpen}
        maxWidth={'lg'}
        onClose={toggleShelter}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '80vw',
              height: '80vh',
            },
          },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Shelter Expenses</Typography>
            <IconButton onClick={toggleShelter}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ShelterExpenses
            toggleShelter={toggleShelter}
            individualId={individualId}
            createShelter
            getShelterExp={getShelterExp}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={editOpen} maxWidth={'lg'} onClose={toggleShelter}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Shelter Expenses</Typography>
            <IconButton onClick={toggleShelter}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ShelterExpenses
            toggleShelter={toggleShelter}
            individualId={individualId}
            shelterEdit={shelterEdit}
            getShelterExp={getShelterExp}
          />
        </DialogContent>
      </Dialog>
      <DeleteConfiramation
        setOpenDeleteDialog={
          currentTab === 'shelter' ? setOpenDeleteDialog : setMedicalDelete
        }
        openDeleteDialog={
          currentTab === 'shelter' ? openDeleteDialog : deleteMedicalStatus
        }
        handleDelete={
          currentTab === 'shelter' ? handleDelete : handleConfirmDeletemedical
        }
      />
      {open && (
        <AddMember
          open={open}
          lkpRaw={lkpRaw}
          individualId={selected.individualId}
          getOptions={getOptions}
          personName={personName}
          selectedIndividual={selectedIndividual}
          reportEdited={reportEdited}
          setReportEdited={setReportEdited}
          setOpen={setOpen}
        />
      )}

      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </>
  );
}
