import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SummaryContent from '../SummaryContent';
import TabularView from '../../../components/TabularView';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import { Button, IconButton, Tooltip } from '@mui/material';

function HhComposition({ houseMembers, getLkpMatch }) {
  const columns = useMemo(
    () => [
      {
        field: '',
        headerName: 'Person Name',
        getValue({ personName = {} }) {
          return `${personName?.firstName} ${personName.lastName}`;
        },
      },
      {
        field: '',
        headerName: 'Gender',
        getValue({ gender }) {
          return getLkpMatch('Gender', gender);
        },
      },
      {
        field: 'dob',
        headerName: 'DOB',
      },
      {
        field: '',
        headerName: 'Race',
        getValue({ race }) {
          return getLkpMatch('Race', race?.primaryRace);
        },
      },
      {
        headerName: 'Ethinic',
        getValue({ ethnicity }) {
          return getLkpMatch('Ethnicity', ethnicity);
        },
      },
      {
        field: '',
        headerName: 'Marital Status',
        getValue({ maritalStatus }) {
          return maritalStatus?.marriedYN === 'Y' ||
            maritalStatus?.marriedYN === 'N'
            ? getLkpMatch('YesNo', maritalStatus?.marriedYN)
            : getLkpMatch('MartialStatus', maritalStatus?.marriedYN);
        },
      },
      {
        field: '',
        headerName: 'Update',
        renderCell: () => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [getLkpMatch]
  );
  return (
    <SummaryContent title="Household Composition">
      <TabularView
        sx={{
          table: {
            width: '100%',
          },
          td: {
            border: 0,
            padding: '0 1.25rem 0.5rem 0',
          },
          th: {
            border: 0,
            borderBottom: '1px solid var(--grey-300)',
            padding: '0 1.25rem 0.5rem 0',
          },
        }}
        headerComponent={{ variant: 'h6', color: 'var(--grey-400)' }}
        cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

HhComposition.propTypes = {};

export default HhComposition;
