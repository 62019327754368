import { GET_AGHEAD, GET_AUTHREP_DETAILS, POST_AUTHREP } from './url';
import HttpClient from '../../HttpClient/index';
import { format } from '../../helpers/index';
import { addAuthRepPayload } from './payLoads';

export const getAuthRepDetails = (caseId) => {
  return HttpClient.get(format(GET_AUTHREP_DETAILS, caseId));
};

export const postAuthRepDetails = (data = {}, caseId, dirtyStatus) => {
  return HttpClient.post(
    format(POST_AUTHREP, caseId),
    addAuthRepPayload(data, dirtyStatus)
  );
};

export const getAgHeadMembers = (caseId) => {
  return HttpClient.get(format(GET_AGHEAD, caseId));
};
