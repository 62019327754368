import { createSelector } from '@reduxjs/toolkit';
import { includes, some } from 'lodash';

const getState = (state) => state?.case ?? {};

export const selectCaseDetails = createSelector(
  getState,
  (state) => state.caseDetails
);

export const selectHouseMembers = createSelector(getState, (state) => ({
  houseMembers: state.houseMembers,
  fetchingMembers: state.isFetchingMembers,
}));

export const selectFetchingCaseDetails = createSelector(
  getState,
  (state) => state.isFetchingCaseDetails
);

export const navigatePageName = createSelector(
  getState,
  (state) => state.navigatePageName
);

export const selectCaseRules = createSelector(getState, (state) => {
  const hasFemaleMemebers = some(state.houseMembers, (m) => m.gender === 'F');
  const hasLtOrWvPgm = some(state?.caseDetails?.programs, (p) =>
    includes(['LT', 'WV'], p.programName)
  );
  const showRelationship = state.houseMembers?.length > 1;
  return {
    hasFemaleMemebers,
    hasLtOrWvPgm,
    showRelationship,
  };
});
