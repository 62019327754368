export const narrativePayload = (data) => {
  const {
    householdMember = '',
    title,
    comments,
    recordId,
    createdDate,
    householdMemberId,
  } = data;
  const [firstName = '', lastName = ''] = householdMember.split(' ');
  console.log(householdMemberId);
  return {
    createdByName: {
      dirtyStatus: '',
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      type: '',
    },
    createdDate: null,
    dirtyStatus: 'insert',
    editable: '',
    endDate: null,
    fromDate: null,
    householdMemberId: householdMemberId,
    recordId: recordId,
    householdMemberName: {
      dirtyStatus: '',
      firstName,
      lastName,
      middleName: '',
      type: '',
      suffix: '',
    },
    narrative: comments,
    title: title,
    updatedDate: null,
  };
};

export const searchPayload = (data) => {
  const {
    members,
    fromDate,
    toDate,
    keyWords,
    recordId,
    caseId,
    householdMemberId,
    narrativeId,
  } = data;

  return {
    caseId: caseId,
    narratives: [
      {
        agid: '',
        createdByName: {},
        createdDate: null,
        dirtyStatus: '',
        editable: false,
        endDate: null,
        fromDate: null,
        householdMemberId: householdMemberId,
        householdMemberName: {},
        narrative: '',
        narrativeId: narrativeId,
        program: { programName: '' },
        title: '',
        updatedDate: null,
      },
    ],
    paginationData: { pageNumber: '0', resultsPerPage: '5' },
  };
};
