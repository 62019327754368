import React, { useEffect, useMemo, useState } from 'react';
import styles from '../Dashboard/DashboardContent/index.module.css';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Button,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Circle } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import cx from 'classnames';
import {
  myDashboardApi,
  overDueApi,
  assignedApi,
  unassignedApi,
  getNextWork,
} from '../../utils/services/apiRequests';
import { showErrorToast, showWarningToast } from '../../utils/services/toast';
import dayjs from 'dayjs';
import useCaseStatus from '../../hooks/useCaseStatus';
import { map } from 'lodash';
import useLoader from '../../hooks/useLoader';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../store/selectors/authSelector';
import useLookup from '../../hooks/useLookup';
import MyDashboardTable from './DashboardTables/MyDashboardTable';
import UnAssignedTable from './DashboardTables/UnAssignedTable';
import AssignedTable from './DashboardTables/AssignedTable';
import OverdueTable from './DashboardTables/Overdue';
import { ReactComponent as WorkUpdate } from '../../assets/icons/QuickLinks/work_update.svg';
import IconLink from '../../components/IconLinks';

const AlertsAndWorkItem = () => {
  const { lkpFlatten, getLkpMatch } = useLookup('Application');
  const [showLoader, hideLoader] = useLoader();
  const [value, setValue] = useState(1);
  const [value2, setValue2] = useState(1);
  const token = useSelector(getAuthToken);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getWorkItemData = async () => {
    const payload = {
      alertAssignedTo: token.sub,
      alertType: 'AL',
    };
    try {
      showLoader();
      const res = await getNextWork(payload);
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const GetWorkItem = () => {
    return (
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={getWorkItemData}>
          <IconLink icon={WorkUpdate} iconlabel="Get Work Item" />
        </Button>
      </Grid>
    );
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
      }}
    >
      <Grid
        sx={{
          '& .Mui-selected': {
            color: 'black !important',
            fontWeight: 700,
          },
          width: '100%',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            textTransform: 'none',
            width: '100%',
            borderBottom: '1px solid #bababa',
          }}
        >
          <Tab
            sx={{ flex: 1, textTransform: 'none' }}
            value={1}
            label="My Dashboard"
          />
          <Tab
            sx={{ flex: 1, textTransform: 'none' }}
            value={2}
            label="Unassigned Applications"
          />
          <Tab
            sx={{ flex: 1, textTransform: 'none' }}
            value={3}
            label="Assigned Applications"
          />
          <Tab
            sx={{ flex: 1, textTransform: 'none' }}
            value={4}
            label="Overdue Applications"
          />
        </Tabs>
      </Grid>

      {value === 2 && (
        <Grid container justifyContent={'end'}>
          <Grid item>
            <Tabs
              value={value2}
              onChange={handleChange2}
              sx={{
                border: '1px solid #bababa',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                '& .Mui-selected': {
                  color: 'white !important',
                  fontWeight: 700,
                  borderBottom: 'none',
                  backgroundColor: 'var(--grey-400)',
                },

                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              <Tab
                sx={{ textTransform: 'none' }}
                value={1}
                label="Paper Apps"
              />
              <Tab
                sx={{ textTransform: 'none' }}
                value={2}
                label="Online Apps"
              />
            </Tabs>
          </Grid>
          <Grid item xs={6}>
            <GetWorkItem />
          </Grid>
        </Grid>
      )}
      {value === 1 && (
        <Grid container justifyContent={'end'}>
          <Grid item>
            <Tabs
              value={value2}
              onChange={handleChange2}
              sx={{
                border: '1px solid #bababa',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                '& .Mui-selected': {
                  color: 'white !important',
                  fontWeight: 700,
                  borderBottom: 'none',
                  backgroundColor: 'var(--grey-400)',
                },

                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              <Tab
                sx={{ textTransform: 'none' }}
                value={1}
                label="Online Reported Changes"
              />
              <Tab
                sx={{ textTransform: 'none' }}
                value={2}
                label="Worker Entered Changes"
              />
            </Tabs>
          </Grid>
          <Grid item xs={5}>
            <GetWorkItem />
          </Grid>
        </Grid>
      )}

      {value === 1 && (
        <MyDashboardTable getLkpMatch={getLkpMatch} newValue={value2} />
      )}
      {value === 2 && (
        <UnAssignedTable lkpFlatten={lkpFlatten} newValue={value2} />
      )}
      {value === 3 && (
        <AssignedTable
          lkpFlatten={lkpFlatten}
          getWorkItemData={getWorkItemData}
        />
      )}
      {value === 4 && (
        <OverdueTable
          lkpFlatten={lkpFlatten}
          getLkpMatch={getLkpMatch}
          getWorkItemData={getWorkItemData}
        />
      )}
    </div>
  );
};

export default AlertsAndWorkItem;
