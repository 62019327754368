import { Button, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import React, { useMemo } from 'react';
import Field from '../../../../components/Field';
import FormSection from '../../../../components/FormSection';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../../components/Dropdown';
import { map } from 'lodash';
import { formatCurrency } from '../../../../utils/normalizers';

export default function AddVehicle({
  setPolicyData,
  toggleClosePop,
  amoutOpts,
  fairOpts,
  editRecord,
  policyData,
}) {
  const validationSchema = Yup.object(
    {
      effectiveBeginDate: Yup.string().required('Please select start date'),
      encumberedVerify: Yup.string().when('encumberedAmount', {
        is: (encumberedAmount) => encumberedAmount,
        then: () => Yup.string().required('Please select the Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      fareMarketVerify: Yup.string().when('fareMarketValue', {
        is: (fareMarketValue) => fareMarketValue,
        then: () => Yup.string().required('Please select the Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
    },
    ['encumberedAmount', 'encumberedVerify'],
    ['fareMarketValue', 'fareMarketVerify']
  );

  const formik = useFormik({
    initialValues: {
      effectiveBeginDate: editRecord?.effectiveBeginDate ?? null,
      effectiveEndDate: editRecord?.effectiveEndDate ?? null,
      encumberedAmount: editRecord?.encumberedAmount ?? '',
      encumberedVerify:
        editRecord?.encumberedAmountVerification?.verificationSourceCode ?? '',
      fareMarketValue: editRecord?.fareMarketValue ?? '',
      fareMarketVerify:
        editRecord?.fareMarketValueVerification?.verificationSourceCode ?? '',
      recordId: editRecord?.recordId ?? `a${Math.random()}`,
    },
    validationSchema,
    onSubmit: (value) => {
      if (editRecord?.effectiveBeginDate) {
        setPolicyData(
          map(policyData, (val) =>
            val.recordId === editRecord?.recordId ? value : val
          )
        );
        toggleClosePop();
      } else {
        setPolicyData((prev) => [...prev, value]);
        toggleClosePop();
      }
    },
  });

  return (
    <div>
      <FormSection>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Field
              name="effectiveBeginDate"
              required
              isDate
              formik={formik}
              label={'Account Start Date'}
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item>
            <Field
              name="effectiveEndDate"
              isDate
              formik={formik}
              label={'Account Close Date'}
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item>
            <Field
              name="encumberedAmount"
              formik={formik}
              label="Encumbered Amount"
              normalizers={[formatCurrency]}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
          </Grid>
          <Grid item>
            <Field
              name="encumberedVerify"
              formik={formik}
              label="Encumbered Amount Verification"
              required
            >
              <Dropdown options={amoutOpts} placeholder="Select Verification" />
            </Field>
          </Grid>

          <Grid item>
            <Field
              name="fareMarketValue"
              formik={formik}
              label="Fare Market Value"
              normalizers={[formatCurrency]}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
          </Grid>
          <Grid item>
            <Field
              name="fareMarketVerify"
              formik={formik}
              label="Fare Market Value Verification"
            >
              <Dropdown options={fairOpts} placeholder="Select Verification" />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <Stack justifyContent={'center'} mt={1}>
        <Button onClick={formik.handleSubmit} variant="outlined">
          Add Asset
        </Button>
      </Stack>
    </div>
  );
}
