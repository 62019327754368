import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { useFormik } from 'formik';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import { map, get } from 'lodash';
import { getFullName } from '../../utils/helpers';
import * as Yup from 'yup';
import useLookup from '../../hooks/useLookup';
import useLoader from '../../hooks/useLoader';
import HttpClient from '../../utils/HttpClient';
import cx from 'classnames';
import { makeStyles } from '@mui/styles';
import { FileUploader } from 'react-drag-drop-files';
import styles from './index.module.css';
import FileUpload from './FileUploader';
import { getAuthToken } from '../../store/selectors/authSelector';
import {
  documentUpload,
  downloadDocument,
  getDocumentsFiles,
  saveIncomeUploadFiles,
} from '../../utils/services/apiRequests';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import usePageNav from '../../hooks/usePageNav';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import DWT from '../Scanner';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '70vh',
    boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  centeredLabel: {
    textAlign: 'left',
    display: 'block',
    width: '100%',
  },
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default function CaseDocuments({ handleCaseDocumentsClose }) {
  const params = useParams();
  const { caseId } = usePageNav();
  const classes = useStyles();
  const token = useSelector(getAuthToken);
  const { houseMembers } = useSelector(selectHouseMembers);
  const [docmentNames, setDocmentNames] = useState([]);
  const [docmentType, setDocmentType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const { lkpRaw } = useLookup('Income');
  const [showLoader, hideLoader] = useLoader();
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [payloadData, setPayloadData] = useState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    let payload = {
      fileFormData: formik.values.fileFormData,
      individualId: formik.values.householdMember,
      sourceCaseId: caseId,
      verificationName: formik.values.verificationName,
      documentTypeCd: formik.values.documentTypeCd,
      lookupShortVal: formik.values.lookupShortVal,
      user: token?.sub,
      role: 'EE_SUPVRS,EE_SUPUSR,EE_VEND_ADMN',
      sourceSystem: 'KCARES',
      lookupTypeCd: '',
      activeInd: '',
      controlId: '',
    };
    setPayloadData(payload);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [filters, setFilters] = useState({
    sDocuName: '',
    documentType: '',
    lookupShortValue: '',
  });

  const validateIsError = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) && get(formik, `touched.${fieldName}`);

    return !!hasErrors;
  };

  const validateIsEmpty = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) &&
      get(formik, `touched.${fieldName}`) &&
      get(formik, `values.${fieldName}`) === '';

    return !!hasErrors;
  };

  const fetchLookupForType = async (payload) => {
    try {
      showLoader();
      const res = await HttpClient.post(
        '/eeapi/public/consumer/lookup-search/',
        payload
      );
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('Lookup fetch failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const handeChangeCallApi = async (event) => {
    let { value } = event.target;
    setDocmentType(value);
    formik.setFieldValue(`documentTypeCd`, value);
    let payLoad = [
      {
        lookupTypeCd: value,
      },
    ];
    const res = await fetchLookupForType(payLoad);
    setDocmentNames(res[value]);
  };

  const memberOpts = useMemo(() => {
    return map(
      houseMembers,
      ({ personName, individualId }) => {
        const fullName = getFullName(
          personName?.firstName,
          personName?.lastName
        );
        return {
          label: fullName,
          value: individualId,
        };
      },
      [houseMembers]
    );
  });

  const getUploadFiles = async () => {
    try {
      showLoader();
      //   const res = await getDocumentsFiles(params.caseId);
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocumentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const gridDocData = useMemo(() => {
    if (!documentFiles) {
      return [];
    }
    return documentFiles.map((item) => {
      return {
        docId: item.documentId,
        documentTypeCd: item.docCat,
        docCatagory: item.docType,
        docName: item.userFileName,
        createdBy: item.createdBy,
        createdDt: item.createDt,
        primaryDocId: item.primaryDocId,
        individualId: item.individualId,
        sourceCaseId: item.sourceCaseId,
      };
    });
  }, [documentFiles]);
  const [filteredData, setFilteredData] = useState(gridDocData);
  const validationSchema = Yup.object().shape({
    fileFormData: Yup.mixed().required('Please Select File'),
    documentTypeCd: Yup.string().required('Please Select Document Type'),
    lookupShortVal: Yup.string().required('Please Select Document Name'),
    householdMember: Yup.string().required('Please Select Household Member'),
  });

  const initialState = {
    fileFormData: '',
    individualId: '',
    sourceCaseId: '',
    verificationName: null,
    documentTypeCd: '',
    lookupShortVal: '',
    user: token?.sub,
    role: 'EE_SUPVRS,EE_SUPUSR,EE_VEND_ADMN',
    fileId: '',
    sourceSystem: 'WP',
    lookupTypeCd: '',
    activeInd: '',
    controlId: '',
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema,
    onSubmit: async (values) => {
      let payload = {
        fileFormData: values.fileFormData,
        individualId: values.householdMember,
        sourceCaseId: caseId,
        verificationName: values.verificationName,
        documentTypeCd: values.documentTypeCd,
        lookupShortVal: values.lookupShortVal,
        user: token?.sub,
        role: 'EE_SUPVRS,EE_SUPUSR,EE_VEND_ADMN',
        sourceSystem: 'KCARES',
        lookupTypeCd: '',
        activeInd: '',
        controlId: '',
      };
      try {
        showLoader();
        // const res = await saveIncomeUploadFiles(caseId, payload);
        const res = await documentUpload(payload);
        if (res.status === 200) {
          showSuccessToast('File Uploaded Succesfully');
          getUploadFiles();
          formik.resetForm();
        }
      } catch (error) {
        showErrorToast(error);
      } finally {
        hideLoader();
      }
    },
  });

  const handleFileChange = (file) => {
    if (file) {
      setSelectedFile(file);
      formik.setFieldValue(`fileFormData`, file);
    } else {
      setSelectedFile(null);
      formik.setFieldValue(`fileFormData`, '');
    }
  };

  const handleDocumentChange = (event) => {
    const { value, name } = event.target;
    formik.setFieldValue(`lookupShortVal`, value);
  };

  const renderMemberName = (id) => {
    return houseMembers.find(({ individualId }) => individualId == id);
  };

  const handlePDFDownload = async (e, docId, sourceCaseId, userFileName) => {
    try {
      showLoader();
      const response = await downloadDocument(docId, sourceCaseId);
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        // window.open(pdfUrl, "_blank");
        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = userFileName;
        anchor.click();
        anchor.remove();
        window.URL.revokeObjectURL(pdfUrl);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.log('ERROR', err);
    } finally {
      hideLoader();
    }
  };
  const columns = [
    {
      field: 'individualId',
      headerName: 'IndividualName',
      flex: 1,
      renderCell: (params) => {
        const individualId = params?.row?.individualId;
        let member = renderMemberName(individualId);
        if (member && member.personName) {
          const { personName } = member;
          return personName.firstName + '' + personName.lastName;
        }
      },
    },
    { field: 'docName', headerName: 'Document Name', flex: 1 },
    { field: 'docCatagory', headerName: 'Document Type', flex: 1 },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
    },
    {
      field: 'createdDt',
      headerName: 'Upload',
      flex: 1,
      valueFormatter: (params) => dayjs(params.value).format('YYYY/MM/DD'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(e) =>
                handlePDFDownload(
                  e,
                  params?.row?.docId,
                  params?.row?.sourceCaseId,
                  params?.row?.docName
                )
              }
            >
              <Tooltip title="Download" placement="top">
                <DownloadIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton
              aria-label="delete"
              //   onClick={() => handleDelete(params.row)}
            >
              <Tooltip title="Delete" placement="top">
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </>
        );
      },
    },
  ];
  const onTabChange = (e, value) => {
    setActiveTab(value);
  };
  const onsubTabChange = (e, value) => {
    setActiveSubTab(value);
  };

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  useEffect(() => {
    const filterData = () => {
      let filtered = gridDocData;

      if (filters.sDocuName) {
        filtered = filtered.filter((row) =>
          row.documentName
            .toLowerCase()
            .includes(filters.sDocuName.toLowerCase())
        );
      }
      if (filters.documentType) {
        filtered = filtered.filter(
          (row) => row.documentType === filters.documentType
        );
      }
      if (filters.lookupShortValue) {
        filtered = filtered.filter(
          (row) => row.lookupShortValue === filters.lookupShortValue
        );
      }

      setFilteredData(filtered);
    };

    filterData();
  }, [filters, gridDocData]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid sx={{ padding: '1rem' }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">Case Documents</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCaseDocumentsClose}
            sx={{
              position: 'sticky',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            width: '100%',
            padding: '1rem',
            border: '1px solid #e2e2e2',
            mt: '1rem',
          }}
        >
          <Tabs
            onChange={onTabChange}
            value={activeTab}
            variant="fullWidth"
            centered
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#44A0E3',
                borderRadius: '5px',
                fontWeight: '500',
                color: '#fff !important',
                width: '100%',
                paddingLeft: '10px',
              },
              '& .MuiTab-root': {
                textTransform: 'unset',
                width: '100%',
                color: '#6c6c6c',
                fontSize: '16px !important',
                fontWeight: '600 !important',
                borderRadius: '5px',
              },
              '& .MuiTabs-indicator': {
                display: 'none',
              },
              backgroundColor: '#eeeeef',
            }}
          >
            <Tab label="Add Documents" />
            <Tab label="Search Documents" />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Tabs
              onChange={onsubTabChange}
              value={activeSubTab}
              // variant="fullWidth"
              left
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'unset',
                },
              }}
            >
              <Tab label="Upload Documents" />
              <Tab label="Scan Documents" />
            </Tabs>
            <TabPanel value={activeSubTab} index={0}>
              <Container>
                <Grid container spacing={3} mt="1rem">
                  <Grid item xs={6}>
                    <FileUpload
                      handleChange={handleFileChange}
                      formik={formik}
                      formikError={
                        formik.errors.fileFormData &&
                        formik.touched.fileFormData &&
                        formik.errors.fileFormData
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box mb={2}>
                      <FormControl
                        fullWidth
                        margin="normal"
                        className={cx({
                          [classes.customTextField]: validateIsError(
                            formik,
                            'documentTypeCd'
                          ),
                        })}
                      >
                        <InputLabel
                          id="doc-type"
                          className={classes.centeredLabel}
                        >
                          Document Type
                        </InputLabel>
                        <Select
                          labelId="doc-type"
                          id="select-doc-type"
                          label="Document Type"
                          value={formik.values.documentTypeCd}
                          name="documentTypeCd"
                          onChange={(event) => handeChangeCallApi(event)}
                        >
                          {lkpRaw?.Documents?.map((item, index) => (
                            <MenuItem key={index} value={item.lookupLongVal}>
                              {item.lookupLongVal}
                            </MenuItem>
                          ))}
                        </Select>
                        {validateIsError(formik, 'documentTypeCd') && (
                          <FormHelperText error>
                            {formik.errors.documentTypeCd}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>

                    <Box mb={2}>
                      <FormControl
                        fullWidth
                        margin="normal"
                        className={cx({
                          [classes.customTextField]: validateIsEmpty(
                            formik,
                            'lookupShortVal'
                          ),
                        })}
                      >
                        <InputLabel
                          id="doc-name"
                          className={classes.centeredLabel}
                        >
                          Document Name
                        </InputLabel>
                        <Select
                          labelId="doc-name"
                          id="select-doc-name"
                          label="Document Name"
                          placeholder="Select Document Name"
                          name="lookupShortVal"
                          value={formik.values.lookupShortVal}
                          onChange={handleDocumentChange}
                          disabled={!formik.values.documentTypeCd}
                        >
                          {docmentNames.map((item, index) => (
                            <MenuItem key={index} value={item.lkpDesc}>
                              {item.lookupLongVal}
                            </MenuItem>
                          ))}
                        </Select>
                        {validateIsEmpty(formik, 'lookupShortVal') && (
                          <FormHelperText error>
                            {formik.errors.lookupShortVal}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>

                    <Box mb={2}>
                      <Field name="householdMember" formik={formik}>
                        <Dropdown
                          placeholder="Select Household Member"
                          options={memberOpts}
                        />
                      </Field>
                    </Box>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mt="4rem"
                    >
                      <Button variant="contained" onClick={formik.handleSubmit}>
                        Upload Files
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value={activeSubTab} index={1}>
              <Grid container spacing={3} mt="1rem" p={0}>
                <Grid item xs={9}>
                  <DWT
                    features={[
                      'scan',
                      'camera',
                      'load',
                      'save',
                      'upload',
                      'barcode',
                      'uploader',
                    ]}
                    data={payloadData}
                    open={open}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Box mb={2}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      className={cx({
                        [classes.customTextField]: validateIsError(
                          formik,
                          'documentTypeCd'
                        ),
                      })}
                    >
                      <InputLabel
                        id="doc-type"
                        className={classes.centeredLabel}
                      >
                        Document Type
                      </InputLabel>
                      <Select
                        labelId="doc-type"
                        id="select-doc-type"
                        label="Document Type"
                        value={formik.values.documentTypeCd}
                        name="documentTypeCd"
                        onChange={(event) => handeChangeCallApi(event)}
                      >
                        {lkpRaw?.Documents?.map((item, index) => (
                          <MenuItem key={index} value={item.lookupLongVal}>
                            {item.lookupLongVal}
                          </MenuItem>
                        ))}
                      </Select>
                      {validateIsError(formik, 'documentTypeCd') && (
                        <FormHelperText error>
                          {formik.errors.documentTypeCd}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      className={cx({
                        [classes.customTextField]: validateIsEmpty(
                          formik,
                          'lookupShortVal'
                        ),
                      })}
                    >
                      <InputLabel
                        id="doc-name"
                        className={classes.centeredLabel}
                      >
                        Document Name
                      </InputLabel>
                      <Select
                        labelId="doc-name"
                        id="select-doc-name"
                        label="Document Name"
                        placeholder="Select Document Name"
                        name="lookupShortVal"
                        value={formik.values.lookupShortVal}
                        onChange={handleDocumentChange}
                        disabled={!formik.values.documentTypeCd}
                      >
                        {docmentNames.map((item, index) => (
                          <MenuItem key={index} value={item.lkpDesc}>
                            {item.lookupLongVal}
                          </MenuItem>
                        ))}
                      </Select>
                      {validateIsEmpty(formik, 'lookupShortVal') && (
                        <FormHelperText error>
                          {formik.errors.lookupShortVal}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  <Box mb={2}>
                    <Field name="householdMember" formik={formik}>
                      <Dropdown
                        placeholder="Select Household Member"
                        options={memberOpts}
                      />
                    </Field>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    mt="4rem"
                  >
                    {/* <Button
                      variant="outlined"
                      style={{ marginLeft: '20px' }}
                      type="submit"
                      onClick={handleClickOpen}
                    >
                      Scan Document
                    </Button> */}
                    {/* <Button variant="contained" onClick={formik.handleSubmit}>
                      Upload Files
                    </Button> */}
                  </Stack>
                </Grid>
              </Grid>
            </TabPanel>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Grid container spacing={2} mt="1rem">
              <Grid item xs={4} mt="1rem">
                <TextField
                  placeholder="Search by Document Name"
                  name="sDocuName"
                  value={filters.sDocuName}
                  onChange={handleFilterChange}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="doc-type">Document Type</InputLabel>
                  <Select
                    labelId="doc-type"
                    id="select-doc-type"
                    label="Document Type"
                    value={filters.documentType}
                    name="documentType"
                    onChange={handleFilterChange}
                  >
                    {lkpRaw?.Documents?.map((item, index) => (
                      <MenuItem key={index} value={item.lookupLongVal}>
                        {item.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="doc-name">Document Name</InputLabel>
                  <Select
                    labelId="doc-name"
                    id="select-doc-name"
                    label="Document Name"
                    name="lookupShortValue"
                    value={filters.lookupShortValue}
                    onChange={handleFilterChange}
                  >
                    {docmentNames.map((item, index) => (
                      <MenuItem key={index} value={item.lkpDesc}>
                        {item.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button variant="outlined">Search</Button>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={filteredData}
                  getRowId={(row) => row?.individualId}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
        {activeTab === 0 && (
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'flex-end'}
            mt={2}
          >
            <Button variant="outlined" onClick={handleCaseDocumentsClose}>
              Discard
            </Button>
            <Button
              variant="contained"
              //   disabled={!formik.isValid || !areMandatoryFieldsFilled()}
              onClick={handleNext}
            >
              Next
            </Button>
          </Stack>
        )}
        {activeTab === 1 && (
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'flex-end'}
            mt={2}
          >
            <Button variant="outlined" onClick={handleCaseDocumentsClose}>
              Discard
            </Button>
            <Button
              variant="contained"
              //   onClick={handleNext}
              //   disabled={!formik.isValid || !carrierMandatory()}
            >
              Ok
            </Button>
          </Stack>
        )}
      </Grid>
    </>
  );
}
