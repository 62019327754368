import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TabularView from '../../../components/TabularView';
import { Circle } from '@mui/icons-material';
import cx from 'classnames';
import styles from './index.module.css';

export default function BenefitDatas({ benefitData, getLkpMatch }) {
  const memberData = benefitData[0];
  const finanStatus = (params) => {
    const status = params?.nonFinacialStatus ?? 'PE';
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Grid container alignItems={'center'}>
          <Circle
            className={cx({
              [styles.pass]: status === 'PS',
              [styles.fail]: status === 'FL',
              [styles.pending]: status === 'PE',
            })}
          />
          {getLkpMatch('EligibilityStatusCode', status)}
        </Grid>
      </Grid>
    );
  };

  const columns = [
    {
      headerName: 'Non Financial Status',
      renderCell: (params) => finanStatus(params),
    },
    {
      headerName: 'Participation Status',
      getValue({ participationStatus }) {
        return `${getLkpMatch(
          'FinancialResponsibility',
          participationStatus ?? '-'
        )}`;
      },
    },
    {
      headerName: 'Categorically Eligible',
      getValue({ categoricallyEligible }) {
        return `${categoricallyEligible ?? '-'}`;
      },
    },
    {
      headerName: 'Special Specification',
      getValue({ specialSpecification }) {
        return `${specialSpecification ?? '-'}`;
      },
    },
  ];

  const workColumns = [
    {
      headerName: 'Participation Status Date',
      getValue({ workProgramStatusDate }) {
        return `${workProgramStatusDate ?? '-'}`;
      },
    },
    {
      headerName: 'Participation Status',
      getValue({ workParticipationStatusCd }) {
        return `${getLkpMatch(
          'WorkParticipationStatusCd',
          workParticipationStatusCd ?? '-'
        )}`;
      },
    },
    {
      headerName: 'MOE/TANF Category',
      getValue({ eligibilityStatus }) {
        return `${eligibilityStatus ?? '-'}`;
      },
    },
    {
      headerName: 'Reason',
      getValue({ eligibilityStatus }) {
        return `${eligibilityStatus ?? '-'}`;
      },
    },
  ];

  const workData = memberData?.workProgramStatusList ?? [];

  const eligibilityReasons = memberData?.eligibilityReason?.map(
    (reason) => `${getLkpMatch('EligibilityReasonCode', reason.reasonCod)}`
  ) ?? ['N/A'];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box sx={{ border: '1px solid #e2e2e7', padding: '1rem' }}>
            <Typography
              variant="h4"
              sx={{
                mt: '1rem',
                ml: '1rem',
              }}
            >
              Individual Eligibility Status
            </Typography>
            <TabularView
              data={[memberData]}
              columns={columns}
              expandable
              getId={(houseHoldMember) => houseHoldMember}
              renderHeader={(title) => (
                <Typography variant="h5" component="strong">
                  {title}
                </Typography>
              )}
              sx={{
                table: {
                  width: '100%',
                  borderCollapse: 'collapse',
                },
                th: {
                  padding: '12px 20px',
                  textAlign: 'center',
                  borderBottom: '2px solid #e2e2e7',
                },
                td: {
                  padding: '12px 20px',
                  textAlign: 'center',
                },
                tr: {
                  ':nth-child(even)': {
                    backgroundColor: '#f9f9f9',
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ border: '1px solid #e2e2e7', mt: '1rem' }}>
            <Typography
              variant="h4"
              sx={{
                mt: '1rem',
                ml: '1rem',
              }}
            >
              Work Registration/Participation
            </Typography>
            <TabularView
              data={workData}
              columns={workColumns}
              getId={(houseHoldMember) => houseHoldMember}
              renderHeader={(title) => (
                <Typography variant="h5" component="strong">
                  {title}
                </Typography>
              )}
              sx={{
                table: {
                  width: '100%',
                  borderCollapse: 'collapse',
                },
                td: {
                  padding: '12px 20px',
                  textAlign: 'center',
                },
                tr: {
                  padding: '10px',
                  ':nth-child(even)': {
                    backgroundColor: '#f9f9f9',
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              margin: 1,
              border: '1px solid #e2e2e7',
              paddingBottom: '7rem',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                mt: '1rem',
                ml: '1rem',
              }}
            >
              Eligibility Reason
            </Typography>
            {eligibilityReasons.map((reason, index) => (
              <Typography
                key={index}
                variant="subtitle1"
                sx={{ ml: '1rem', mt: '0.5rem' }}
              >
                {reason}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
