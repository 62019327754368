import React, { useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/PageContainer';
import usePageNav from '../../hooks/usePageNav';
import { useParams } from 'react-router-dom';
import SummaryHeader from '../CaseSummary/SummaryHeader';
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import {
  getCaseCreationDataviewData,
  getHoHData,
  postCpImportCase,
} from '../../utils/services/caseCreation/apiRequests';
import { showErrorToast } from '../../utils/services/toast';
import useLoader from '../../hooks/useLoader';
import useLookup from '../../hooks/useLookup';
import ImportAccordion from './Accordion';
import styles from './index.module.css';
import ProgramSelection from './ProgramSelection';
import CPYourInformation from './HeadofHousehold/YourInformation';
import CPAddressInfo from './HeadofHousehold/AddressInfo';
import CPContactInfo from './HeadofHousehold/ContactInfo';
import CPMemberInformation from './MemberInformation/MemberInfo';
import CPRelationshipInfo from './MemberInformation/RelationshipInfo';
import CPAuthRep from './MemberInformation/AuthRep';
import CPCitizenInfo from './MemberInformation/Citizeninfo';
import CPMedicareDetails from './MemberInformation/MedicareDetails';
import CPAssets from './FinancialInformation/Assets';
import CPEarnedIncome from './FinancialInformation/EarnedIncome';
import CPUnearnedIncome from './FinancialInformation/UnearnedIncome';
import CPStriker from './FinancialInformation/Striker';
import CPMedicalExpenses from './FinancialInformation/MedicalExpenses';
import CPCourtOrder from './FinancialInformation/CourtOrder';
import CPShelterUtility from './FinancialInformation/ShelterUtility';

const BorderedBox = styled(Box)({
  borderRadius: '0.25rem',
  border: '1px solid var(--grey-400)',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export default function Import() {
  const { navigations } = usePageNav();
  const params = useParams();
  const _caseId = params.caseId ?? '';
  const _controlId = params.controlId ?? '';
  const [hohData, setHoHData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const { getLkpMatch } = useLookup('CaseCreation');
  const [screenTitle, setScreenTitle] = useState('inAssistanceProgramSelect');
  const [radioValue, setRadioValue] = useState('CPA');
  const [rowData, setRowData] = useState({});

  const handleCheckbox = (event, index) => {
    let { checked } = event.target;
  };

  let screens = [
    {
      id: 'inAssistanceProgramSelect',
      title: 'Program Selection',
      component: (
        <ProgramSelection
          data={rowData?.inAssistanceProgramSelect}
          getLkpMatch={getLkpMatch}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inHeadOfHouseholdHohInfo',
      title: 'Your Information',
      component: (
        <CPYourInformation
          data={rowData?.inHeadOfHouseholdHohInfo}
          getLkpMatch={getLkpMatch}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inHeadOfHouseholdADDRESS',
      title: 'Address Information',
      component: (
        <CPAddressInfo
          data={rowData?.inHeadOfHouseholdADDRESS}
          getLkpMatch={getLkpMatch}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inHeadOfHouseholdContactInfo',
      title: 'Contact Information',
      component: (
        <CPContactInfo
          data={rowData?.inHeadOfHouseholdContactInfo}
          getLkpMatch={getLkpMatch}
          handleCheckbox={handleCheckbox}
        />
      ),
    },

    {
      id: 'inMembersMemberInfo',
      title: 'Member Information',
      component: (
        <CPMemberInformation
          data={rowData?.inMembersMemberInfo}
          getLkpMatch={getLkpMatch}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inMembersrelationship',
      title: 'Relationship Information',
      component: (
        <CPRelationshipInfo
          data={rowData?.inMembersrelationship}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inHeadOfHouseholdCitizenshipImmigration',
      title: 'Citizenship Immigration',
      component: (
        <CPCitizenInfo
          data={rowData?.inHeadOfHouseholdCitizenshipImmigration}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inHeadOfHouseholdauthReo',
      title: 'Authorized Representative',
      component: (
        <CPAuthRep
          data={rowData?.inHeadOfHouseholdauthReo}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inMembersMedicare',
      title: 'Medicare Details',
      component: (
        <CPMedicareDetails
          data={rowData?.inMembersMedicare}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inFinancialAssets',
      title: 'Assets',
      component: (
        <CPAssets
          data={rowData?.inFinancialAssets}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inFinancialEarned',
      title: 'Earned Income',
      component: (
        <CPEarnedIncome
          data={rowData?.inFinancialEarned}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inFinancialUnearned',
      title: 'Unearned Income',
      component: (
        <CPUnearnedIncome
          data={rowData?.inFinancialUnearned}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inFinancialStriker',
      title: 'Striker',
      component: (
        <CPStriker
          data={rowData?.inFinancialStriker}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inFinancialMediExpenses',
      title: 'Medical Expenses',
      component: (
        <CPMedicalExpenses
          data={rowData?.inFinancialMediExpenses}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inFinancialShelterUtility',
      title: 'Shelter Utility',
      component: (
        <CPShelterUtility
          data={rowData?.inFinancialShelterUtility}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      id: 'inFinancialCourtOrder',
      title: 'Court Order',
      component: (
        <CPCourtOrder
          data={rowData?.inFinancialCourtOrder}
          getLkpMatch={getLkpMatch}
          hohMemberData={rowData?.inhomePage}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
  ];

  const getHoHList = async () => {
    try {
      showLoader();
      let data = {
        caseId: _caseId,
        controlId: _controlId,
      };
      const res = await getHoHData(data);
      if (res?.status === 200 && res?.data) {
        let parsedData = JSON.parse(res.data);
        setHoHData(parsedData[0]);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getCPCaseCreationDataview = async () => {
    try {
      showLoader();
      const res = await getCaseCreationDataviewData(_controlId);
      if (res?.status === 200 && res?.data) {
        setRowData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const { personName } = hohData?.clientContainer?.client ?? {};
  const { firstName, middleName, lastName } = personName ?? {};
  const { caseId } = hohData ?? {};

  useEffect(() => {
    getHoHList();
    getCPCaseCreationDataview();
  }, []);

  const handleRadio = (event) => {
    let { value } = event.target;
    setRadioValue(value);
  };

  let screenData = useMemo(() => {
    return screens.find((item) => item.id === screenTitle);
  }, [screenTitle, rowData]);

  const cpImportCaseAPI = async () => {
    try {
      showLoader();
      let payload = cleanData(rowData);
      const res = await postCpImportCase(payload, caseId, _controlId);
      if (res?.status === 200 && res?.data) {
        onNext(res?.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const cleanData = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (
        (Array.isArray(obj[key]) && obj[key].length === 0) ||
        typeof obj[key] === 'string' ||
        !Array.isArray(obj[key])
      ) {
        delete obj[key];
      }
    });
    return obj;
  };

  const onNext = (response) => {
    navigations.toVerifiedDocument(_caseId, _controlId, response);
    // navigations.toCompletionMember(_caseId, _controlId, response);
  };

  return (
    <PageContainer
      showHeader={false}
      onBack={() => navigations.toRunClearance(_caseId, _controlId)}
      onNext={() => cpImportCaseAPI()}
    >
      <BorderedBox pb="1rem" mt={2}>
        <SummaryHeader
          title={`Web ID : ${_caseId ?? 'NA'}`}
          isHoh={`Head of Household : ${firstName ?? ''} ${middleName ?? ''} ${
            lastName ?? ''
          }`}
        />
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <ImportAccordion
              screenTitle={screenTitle}
              setScreenTitle={setScreenTitle}
            />
          </Grid>
          <Grid item xs={9} pr={1}>
            <Grid container spacing={1} pt={1}>
              <Grid item xs={6}>
                <Paper sx={{ paddingBottom: 0.2 }}>
                  <Typography variant="h4" className={styles.box}>
                    Consumer Portal Application
                  </Typography>
                  <Box
                    sx={{
                      margin: '0.5rem',
                      border: '1px solid var(--grey-350) !important',
                    }}
                    mb={1}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ background: 'var(--grey-100)' }}
                      p={1}
                    >
                      <Typography variant="h5grey">
                        {screenData?.title}
                      </Typography>
                      <RadioGroup row>
                        <FormControlLabel
                          value="CPA"
                          checked={radioValue === 'CPA'}
                          onChange={handleRadio}
                          control={<Radio />}
                          label=""
                        />
                      </RadioGroup>
                    </Stack>
                    {screenData?.component}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ paddingBottom: 0.2 }}>
                  <SummaryHeader title="Existing Case Information" />
                  <Box
                    sx={{
                      margin: '0.5rem',
                      border: '1px solid var(--grey-350) !important',
                    }}
                    mb={1}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ background: 'var(--grey-100)' }}
                      p={1}
                    >
                      <Typography variant="h5grey">
                        {screenData?.title}
                      </Typography>
                      <RadioGroup row>
                        <FormControlLabel
                          value="ECI"
                          onChange={handleRadio}
                          checked={radioValue === 'ECI'}
                          control={<Radio />}
                          label=""
                        />
                      </RadioGroup>
                    </Stack>
                    {screenData?.component}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BorderedBox>
    </PageContainer>
  );
}
