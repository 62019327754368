import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';

function NaturalizedCitizen({ formik, onChangeDate }) {
  return (
    <FormSection label="Natualized Citizen">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            name="beginDt"
            formik={formik}
            isDate
            label="Begin Date"
            required
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="endDt" label="End Date" formik={formik} isDate>
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" mt={1} style={{ fontWeight: 'bold' }}>
            Voter Registration
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            If eligible to vote registration will be automatically filled or
            updated unless you choose an option
          </Typography>
          <RadioGroup
            {...formik.getFieldProps('voterRegistration')}
            aria-labelledby="person-lived-long"
          >
            <FormControlLabel
              value="AR"
              control={<Radio />}
              label="Already registered to vote"
            />
            <FormControlLabel
              value="DR"
              control={<Radio />}
              label="Decline to registered to vote"
            />
            <FormControlLabel
              value="DC"
              control={<Radio />}
              label="Decline to report any changes"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </FormSection>
  );
}

NaturalizedCitizen.propTypes = {};

export default NaturalizedCitizen;
