import React, { useMemo } from 'react';
import FormSection from '../../../../components/FormSection';
import { Grid, OutlinedInput, TextField } from '@mui/material';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../../components/Dropdown';
import InputMask from 'react-input-mask';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';

export default function AbsentParents({ getOptions, formik }) {
  const { suffixOpts, stateOpts, typeOpts } = useMemo(() => {
    return {
      suffixOpts: getOptions('Suffix'),
      stateOpts: getOptions('State'),
      typeOpts: getOptions('PhoneType'),
    };
  }, [getOptions]);

  return (
    <>
      <FormSection label="Absent Parent's Father">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field
              name="fatherFName"
              label="First Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="fatherMName"
              label="Middle Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="fatherLName"
              label="Last Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="fatherSuffix" label="Suffix" formik={formik}>
              <Dropdown options={suffixOpts} />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="fatherline1"
              label="Line 1"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="fatherline2"
              label="Line 2"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="fatherCity"
              label="City"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="fatherState" label="State" formik={formik}>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="fatherZipCode"
              label="Zip Code"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="fatherphone" label="Phone" formik={formik}>
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.fatherphone}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="fatherExt"
              label="Ext"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="fatherPhoneType" label="Type" formik={formik}>
              <Dropdown options={typeOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Absent Parent's Mother" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field
              name="motherFName"
              label="First Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="motherMName"
              label="Middle Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="motherLName"
              label="Last Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="motherSuffix" label="Suffix" formik={formik}>
              <Dropdown options={suffixOpts} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="maidenName"
              label="Maiden Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="motherline1"
              label="Line 1"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="motherline2"
              label="Line 2"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="motherCity"
              label="City"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="motherState" label="State" formik={formik}>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="motherZipCode"
              label="Zip Code"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="motherPhone" label="Phone" formik={formik}>
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.motherPhone}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="motherExt"
              label="Ext"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="motherPhoneType" label="Type" formik={formik}>
              <Dropdown options={typeOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
}
