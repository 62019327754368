import HttpClient, { HttpClientFileUpload } from '../../HttpClient';
import { format } from '../../helpers';

import {
  POST_INCARCERATION,
  POST_PRESCHOOL,
  POST_STRIKER,
  POST_VETERAN,
} from './url';

import {
  addIncarcerationPayload,
  addPreschoolPayload,
  addStrikerPayload,
  addVeteranPayload,
} from './payLoads';

export const postIncarceration = (
  data = {},
  caseId,
  individualId,
  dirtyStatus
) =>
  HttpClient.post(
    format(POST_INCARCERATION, caseId, individualId),
    addIncarcerationPayload(data, dirtyStatus)
  );

export const postPreSchool = (data = {}, caseId, individualId, dirtyStatus) =>
  HttpClient.post(
    format(POST_PRESCHOOL, caseId, individualId),
    addPreschoolPayload(data, dirtyStatus)
  );

export const postStriker = (data = {}, caseId, individualId, dirtyStatus) =>
  HttpClient.post(
    format(POST_STRIKER, caseId, individualId),
    addStrikerPayload(data, dirtyStatus)
  );

export const postVeteran = (data = {}, caseId, individualId, dirtyStatus) =>
  HttpClient.post(
    format(POST_VETERAN, caseId, individualId),
    addVeteranPayload(data, dirtyStatus)
  );

export const updateVeteran = (data = {}, caseId, individualId, dirtyStatus) =>
  HttpClient.put(
    format(POST_VETERAN, caseId, individualId),
    addVeteranPayload(data, dirtyStatus)
  );
