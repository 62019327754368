import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  createStyles,
} from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import useLookup from '../../../hooks/useLookup';
import { useParams } from 'react-router-dom';
import {
  getHoHData,
  getIndividualAssistance,
  getMembersData,
  getRecallIndividual,
  getRunClearance,
  postConsumerMember,
  postJsonSave,
  postRunClearance,
} from '../../../utils/services/caseCreation/apiRequests';
import { showErrorToast } from '../../../utils/services/toast';
import { filter, map } from 'lodash';
import { Circle, Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import CustomerDemoInfo from '../CustomerDemoInfo';
import styles from './index.module.css';
import cx from 'classnames';
import useLoader from '../../../hooks/useLoader';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import usePageNav from '../../../hooks/usePageNav';
import PageContainer from '../../../components/PageContainer';
import SummaryHeader from '../../CaseSummary/SummaryHeader';

const useStyles = makeStyles((theme) =>
  createStyles({
    styledSpan: {
      color: '#000000',
      border: '1px solid var(--grey-350)',
      padding: '3px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
    },
    circle: {
      color: '#58f35f',
      padding: '0.2rem',
    },
    rightContainer: {
      padding: '8px 10px',
      borderRadius: '10px',
      height: '100%',
      border: '1px solid var(--grey-400)',
      marginBottom: '8px',
    },
    box: {
      borderRadius: '0.25rem',
      border: '1px solid var(--Prim-1-N-L-Black, var(--grey-400))',
      display: 'flex',
      flexDirection: 'column',
      background: 'var(--grey-300)',
      height: '100%',
    },
  })
);

const RunClearance = ({ selected }) => {
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const { getLkpMatch, getOptions } = useLookup('CaseCreation');
  const classes = useStyles();
  const params = useParams();
  const _caseId = params.caseId ?? '';
  const _controlId = params.controlId ?? '';
  const [hohData, setHoHData] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [selectedIndex, setSelectedindex] = useState(0);
  const [isIndividual, setIsIndividual] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [mdmId, setMdmId] = useState();
  const [editData, setEditData] = useState({});
  const [isSelectedCase, setIsSelectedCase] = useState(false);
  const [isSelectedCaseData, setIsSelectedCaseData] = useState([]);
  const [isReload, setIsReload] = useState(false);
  const [clearanceCaseId, setClearanceCaseId] = useState(null);

  const clearanceColumns = useMemo(
    () => [
      {
        flex: 1,
        field: 'name',
        headerName: 'Name',
        renderCell({ row }) {
          const name = `${row?.personName.firstName} ${row?.personName.lastName} `;
          return name;
        },
      },
      {
        flex: 1,
        field: 'dob',
        headerName: 'DOB',
        valueGetter({ row }) {
          return dayjs(row?.dob).format('MM-DD-YYYY') ?? '';
        },
      },
      {
        flex: 1,
        field: 'ssn',
        headerName: 'SSN',
      },
      {
        flex: 1,
        field: 'gender',
        headerName: 'Gender',
        valueGetter({ row }) {
          return getLkpMatch('Gender', row?.gender) ?? '';
        },
      },
      {
        flex: 1,
        field: 'race',
        headerName: 'Race',
        valueGetter({ row }) {
          return getLkpMatch('Race', row?.race?.primaryRace) ?? '';
        },
      },
      // {
      //   flex: 1,
      //   field: 'irn',
      //   headerName: 'IRN',
      // },
      {
        flex: 1,
        field: 'mdmId',
        headerName: 'MDM ID',
      },
      // {
      //   flex: 1,
      //   field: 'sourceSystem',
      //   headerName: 'Source',
      // },
      // {
      //   flex: 1,
      //   field: 'archivalData',
      //   headerName: 'Archival Data',
      // },
      {
        flex: 1,
        field: 'select',
        headerName: 'Select Member',
        renderCell: (params) => selectMember(params),
      },
      // {
      //   flex: 1,
      //   field: 'view',
      //   headerName: 'View Participation',
      //   renderCell: (params) => viewParticipation(params),
      // },
      // {
      //   flex: 1,
      //   field: 'erroneousIndicator',
      //   headerName: 'Erroneous Indicator',
      // },
    ],
    [getLkpMatch]
  );

  const selectMember = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => (
            setMdmId(params.row.mdmId),
            setIsSelected(true),
            generatePayload(params.row)
          )}
        >
          <CheckCircleOutlinedIcon sx={{ paddingRight: '2px' }} />
          Select
        </Button>
      </Grid>
    );
  };

  useEffect(() => {
    getHoHList();
    getMembersList();
  }, [isReload, _caseId, _controlId]);

  const generatePayload = (data) => {
    let obj = {
      irn: data?.irn ?? '',
      dirtyStatus: '',
      individualId: '',
      clearanceStatus: '',
      controlId: null,
      status: '',
      importSelected: true,
      editable: '',
      importedFromWp: '',
      realIndividualId: '',
      clientContainer: {
        results: [],
        paginationData: {},
        range: null,
        systemCd: '',
        associateCaseId: isSelectedCaseData?.[0]?.caseId ?? '',
        client: {
          personName: {
            firstName: data?.personName?.firstName ?? '',
            lastName: data?.personName?.lastName ?? '',
            middleName: data?.personName?.middleName ?? '',
            suffix: data?.personName?.middleName ?? null,
          },
          dob: dayjs(data?.dob).format('YYYY-MM-DD') ?? '',
          maritalStatus: {
            marriedYN: data?.maritalStatus?.marriedYN ?? '',
          },
          ethnicity: data?.ethnicity ?? '',
          gender: data?.gender ?? '',
          soSec: {
            dirtyStatus: '',
            soSecPart1: '',
            soSecPart2: '',
            soSecPart3: '',
            ssnVerification: {
              dirtyStatus: '',
              messages: [],
              verificationDocumentCd: '',
              verificationRequestedYN: '',
              docNames: {},
              documentDetails: [],
              verificationType: '',
              verificationSourceCode: '',
              verifiedYN: 'N',
              dueDate: null,
              automatedVerificationYN: '',
            },
          },
          headOfHouseholdYN: '',
          race: {
            primaryRace: data?.race?.primaryRace ?? '',
          },
          ssnReferral: '',
          pregnantYN: null,
          individualDetails: {
            dirtyStatus: '',
            deathDetails: {
              dirtyStatus: '',
              messages: [],
              verificationDocumentCd: '',
              verificationRequestedYN: '',
              docNames: {},
              documentDetails: [],
              verificationType: '',
              verificationSourceCode: '',
              verifiedYN: '',
              dueDate: null,
              automatedVerificationYN: '',
              dateOfDeath: null,
              state: '',
              deathCertificateNumber: '',
              deathVerification: {
                dirtyStatus: '',
                messages: [],
                verificationDocumentCd: '',
                verificationRequestedYN: '',
                docNames: {},
                documentDetails: [],
                verificationType: '',
                verificationSourceCode: '',
                verifiedYN: '',
                dueDate: null,
                automatedVerificationYN: '',
              },
              secondaryVerification: {
                dirtyStatus: '',
                messages: [],
                verificationDocumentCd: '',
                verificationRequestedYN: '',
                docNames: {},
                documentDetails: [],
                verificationType: '',
                verificationSourceCode: '',
                verifiedYN: '',
                dueDate: null,
                automatedVerificationYN: '',
              },
              recordId: 0,
            },
            voterRegistartionYN: '',
            voterRegistrationStatus: '',
            voterRegistration: {
              dirtyStatus: '',
              voterIdNumber: '',
              electionJudgeFlag: '',
              helpRequiredFlag: '',
              licenceNumber: '',
              voterRegLicenseIdAvailCd: '',
              otherPartyName: '',
            },
            veterans: [],
            maritals: [],
            livingArrangements: [],
            f99Details: [],
            x02Details: [],
            pregnancy: [],
            birthCity: '',
            birthState: '',
            hospital: '',
            destituteMigrantYN: '',
            concurrentOutStateBenefits: [],
            tcaCounter: '',
            exemptions: [],
            domesticViolanceYN: '',
            strikerStatus: '',
            penalties: [],
            primaryRace: {
              dirtyStatus: '',
              messages: [],
              verificationDocumentCd: '',
              verificationRequestedYN: '',
              docNames: {},
              documentDetails: [],
              verificationType: '',
              verificationSourceCode: '',
              verifiedYN: '',
              dueDate: null,
              automatedVerificationYN: '',
              primaryRace: '',
              otherRaces: [],
            },
            ethnicity: '',
            primaryVerificationSource: '',
            incarcerations: [],
            ssnApplicationDate: null,
            differentAddressYN: null,
            individualAddress: {
              dirtyStatus: '',
              addressCareOf: '',
              addressLine1: '',
              addressLine2: '',
              city: '',
              state: '',
              county: '',
              district: '',
              zipcode: '',
              zip4code: '',
              addressVerifiedYN: '',
              institutionName: '',
              institutionYN: '',
              homeAddressYN: '',
              addressStartDate: null,
              addressEndDate: null,
              ownedByMemberYN: '',
              localDistrictOfficeYN: '',
            },
            strikers: [],
            primaryPrevention: [],
          },
          dobVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          createMdmId: false,
          mdmId: data?.mdmId ?? null,
          maIdSuffix: null,
          irn: data?.irn ?? '',
          maId: data?.maId ?? '',
        },
        address: {
          dirtyStatus: '',
          addressCareOf: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          county: '',
          district: '',
          zipcode: '',
          zip4code: '',
          addressVerifiedYN: '',
          institutionName: '',
          institutionYN: '',
          homeAddressYN: '',
          addressStartDate: null,
          addressEndDate: null,
          ownedByMemberYN: '',
          localDistrictOfficeYN: '',
        },
        sourceSystem: 'C',
        selectedPrograms: [],
      },
      screenDefn: '',
      alertProceedYN: '',
      alertMessage: '',
      intakeProgramSelections: [
        {
          status: false,
          editable: true,
          controlId: _controlId ?? null,
          dirtyStatus: 'INSERT',
          individualId: '',
          importSelected: true,
          importedFromWp: '',
          clearanceStatus: '',
          programSelected: {
            programId: 0,
            dirtyStatus: 'insert',
            programName: '',
            applicationDate: null,
            selectedIndicator: '',
          },
        },
      ],
      memberCreatedInWp: '',
      caseId: null,
      populateExistingData: '',
    };
    setEditData(obj);
  };

  const getHoHList = async () => {
    try {
      showLoader();
      let data = {
        caseId: _caseId,
        controlId: _controlId,
      };
      const res = await getHoHData(data);
      if (res?.status === 200 && res?.data) {
        let parsedData = JSON.parse(res.data);
        setHoHData(parsedData);
        setClearanceCaseId(parsedData?.[0]?.caseId ?? null);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getMembersList = async () => {
    try {
      let data = {
        caseId: _caseId,
        controlId: _controlId,
      };
      const res = await getMembersData(data);
      if (res?.status === 200 && res?.data !== 'No Json Doc found') {
        let parsedData = JSON.parse(res.data);
        let filteredData = filter(
          parsedData,
          (item) => item?.clientContainer?.client?.headOfHouseholdYN !== 'Y'
        );
        setMembersData(filteredData);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
    }
  };

  const handleRunClearance = async () => {
    try {
      showLoader();
      const res = await getRunClearance(hohMemberData[selectedIndex]);
      if (res?.status === 200 && res?.data !== '') {
        setRowData(res.data?.results);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleItemClick = (index) => {
    setSelectedindex(index);
    setRowData(null);
  };

  const fetchIndividualAssistance = async () => {
    try {
      showLoader();
      const res = await getIndividualAssistance(mdmId);
      if (res.status === 200 && res.data?.length > 0) {
        setIsSelectedCaseData(res.data);
        setIsSelectedCase(true);
      } else {
        setIsIndividual(true);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const fetchRecallIndividual = async () => {
    try {
      showLoader();
      const res = await getRecallIndividual(editData);
      if (res?.status === 200 && res?.data?.recordExistsInEE === 'Y') {
        setIsIndividual(true);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleSave = async () => {
    try {
      showLoader();
      const res = await postRunClearance(editData, _controlId, clearanceCaseId);
      if (res?.status === 200 && res?.data !== '') {
        handleConsumerMember(
          res.data.caseId,
          res.data?.householdMembers?.[0]?.individualId
        );
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleConsumerMember = async (caseId, individualId) => {
    try {
      let effectiveCaseId = clearanceCaseId || caseId;
      showLoader();
      const res = await postConsumerMember(
        editData,
        effectiveCaseId,
        'UPDATE',
        individualId
      );
      if (res?.status === 200 && res?.data) {
        if (selectedIndex !== hohMemberData?.length - 1) {
          setSelectedindex((prev) => prev + 1);
        }
        jsonSave(caseId);
        setIsIndividual(false);
        setIsSelected(false);
        setIsSelectedCase(false);
        setRowData(null);
        // setIsReload((prev) => !prev);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const jsonSave = async (caseId) => {
    try {
      let effectiveCaseId = clearanceCaseId || caseId;
      showLoader();
      const res = await postJsonSave(
        editData,
        effectiveCaseId,
        'UPDATE',
        _controlId,
        hohMemberData
      );
      if (res?.status === 200 && res?.data) {
        setIsReload((prev) => !prev);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const hohMemberData = useMemo(() => {
    const sortedData = [...hohData, ...membersData];
    sortedData.sort((a, b) => a.individualId.localeCompare(b.individualId));
    return sortedData;
  }, [hohData, membersData]);

  let enableClearance = false;
  const shouldEnableClearance = () => {
    enableClearance = true;
  };

  const getClearanceButtonText = (hoh) => {
    if (
      hoh &&
      hoh?.clientContainer?.client?.headOfHouseholdYN === 'Y' &&
      (hoh?.clearanceStatus === 'N' ||
        hoh?.clearanceStatus === '' ||
        hoh?.clearanceStatus === null ||
        hoh?.caseId === '')
    ) {
      shouldEnableClearance();
      return 'RUN CLEARANCE';
    } else if (
      hoh &&
      hoh?.clientContainer?.client?.headOfHouseholdYN === 'N' &&
      (hoh?.clearanceStatus === 'N' ||
        hoh?.clearanceStatus === '' ||
        hoh?.clearanceStatus === null)
    ) {
      return 'RUN CLEARANCE';
    } else if (
      (hoh.clearanceStatus === 'Y' &&
        hoh?.clientContainer?.client?.headOfHouseholdYN === 'Y' &&
        hoh.caseId !== '') ||
      (hoh?.clearanceStatus === 'Y' &&
        hoh?.clientContainer?.client?.headOfHouseholdYN === 'N')
    ) {
      return 'CLEARED';
    }
  };

  const onHandleChange = (key, value) => {
    if (key === 'race') {
      setEditData((prevData) => ({
        ...prevData,
        clientContainer: {
          ...prevData.clientContainer,
          client: {
            ...prevData.clientContainer.client,
            race: {
              ...prevData.clientContainer.client.race,
              ['primaryRace']: value,
            },
          },
        },
      }));
    }
    if (key === 'ethnicity') {
      setEditData((prevData) => ({
        ...prevData,
        clientContainer: {
          ...prevData.clientContainer,
          client: {
            ...prevData.clientContainer.client,
            [key]: value,
          },
        },
      }));
    }
    if (key === 'maritalStatus') {
      setEditData((prevData) => ({
        ...prevData,
        clientContainer: {
          ...prevData.clientContainer,
          client: {
            ...prevData.clientContainer.client,
            maritalStatus: {
              ...prevData.clientContainer.client.maritalStatus,
              ['marriedYN']: value,
            },
          },
        },
      }));
    }
    if (key === 'ssnReferral') {
      setEditData((prevData) => ({
        ...prevData,
        clientContainer: {
          ...prevData.clientContainer,
          client: {
            ...prevData.clientContainer.client,
            [key]: value,
          },
        },
      }));
    }
  };

  const onBack = () => {
    navigations.toCPImport(_caseId, _controlId);
  };

  const onNext = () => {
    navigations.toImportMember();
  };

  let showNext = useMemo(() => {
    return hohMemberData.some((item) => {
      const hasQualifyingHeadOfHousehold = hohMemberData.some(
        (item) =>
          item?.clientContainer?.client?.headOfHouseholdYN === 'Y' &&
          item?.clearanceStatus === 'Y' &&
          (item?.caseId !== '' || item?.caseId !== null)
      );

      const hasQualifyingNonHeadOfHousehold = hohMemberData.some(
        (item) =>
          item?.clientContainer?.client?.headOfHouseholdYN === 'N' &&
          item?.clearanceStatus === 'Y'
      );
      return hasQualifyingHeadOfHousehold && hasQualifyingNonHeadOfHousehold;
    });
  }, [hohMemberData]);

  const { personName } = hohData?.[0]?.clientContainer?.client ?? {};
  const { firstName, middleName, lastName } = personName ?? {};

  return (
    <PageContainer
      showHeader={false}
      onBack={() => onBack()}
      onNext={() => onNext()}
      disableNext={!showNext}
    >
      <Box className={classes.box} pb="1rem" mt={2}>
        <SummaryHeader
          title={`Web ID : ${_caseId ?? 'NA'}`}
          isHoh={`Head of Household : ${firstName ?? ''} ${middleName ?? ''} ${
            lastName ?? ''
          }`}
        />
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Box p={1}>
              {map(hohMemberData, (item, index) => {
                let { personName, headOfHouseholdYN } =
                  item?.clientContainer?.client ?? {};
                return (
                  <Stack
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    className={cx(styles.baseMenuButton, {
                      [styles.selected]: selectedIndex === index,
                    })}
                    onClick={() => handleItemClick(index)}
                  >
                    <Stack alignItems="center">
                      <Avatar sx={{ width: 100, height: 100 }} />
                      <Typography variant="h5" mt={1} textAlign="center">
                        {`${personName?.firstName ?? ''} ${
                          personName?.lastName ?? ''
                        }`}
                      </Typography>
                      <Typography
                        color="var(--primary-dark)"
                        variant="h6"
                        fontWeight="600"
                      >
                        {headOfHouseholdYN === 'Y' ? 'HOH' : ''}
                      </Typography>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography color="var(--grey-400)" variant="h6">
                        Program
                      </Typography>
                      {(item?.intakeProgramSelections || []).map(
                        (ele, index) => (
                          <Typography
                            className={classes.styledSpan}
                            variant="body2"
                          >
                            <Circle className={classes.circle} />
                            {getLkpMatch(
                              'ProgramCode',
                              ele?.programSelected?.programName
                            ) ?? 'NA'}
                          </Typography>
                        )
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={
                          (item.clearanceStatus === 'Y' &&
                            headOfHouseholdYN === 'Y' &&
                            item?.caseId !== '') ||
                          (item.clearanceStatus === 'Y' &&
                            headOfHouseholdYN === 'N') ||
                          (enableClearance && headOfHouseholdYN === 'N')
                        }
                        onClick={() => handleRunClearance()}
                      >
                        {getClearanceButtonText(item)}
                      </Button>
                    </Stack>
                  </Stack>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <Box p={1}>
              {hohMemberData && hohMemberData.length && (
                <Paper className={classes.rightContainer}>
                  <Stack
                    direction="row"
                    p={1}
                    columnGap={2}
                    alignItems="center"
                    minHeight="190px"
                  >
                    <Stack alignItems="center" minWidth="135px">
                      <Avatar sx={{ width: 100, height: 100 }} />
                      <Typography variant="h5" mt={1} textAlign="center">
                        {`${
                          hohMemberData[selectedIndex]?.clientContainer?.client
                            ?.personName?.firstName ?? ''
                        } ${
                          hohMemberData[selectedIndex]?.clientContainer?.client
                            ?.personName?.lastName ?? ''
                        }`}
                      </Typography>
                      <Typography
                        color="var(--primary-dark)"
                        variant="h6"
                        fontWeight="600"
                      >
                        {hohMemberData[selectedIndex]?.clientContainer?.client
                          ?.headOfHouseholdYN === 'Y'
                          ? 'HOH'
                          : ''}
                      </Typography>
                    </Stack>
                    <Grid container pl={3}>
                      <Grid item xs={2}>
                        <Typography variant="h5grey">SSN</Typography>
                        <Typography variant="h5">{`${
                          hohMemberData[selectedIndex]?.clientContainer?.client
                            .soSec?.soSecPart1
                            ? '***'
                            : ''
                        }
                            ${
                              hohMemberData[selectedIndex]?.clientContainer
                                ?.client.soSec?.soSecPart2
                                ? '**'
                                : ''
                            }
                            ${
                              hohMemberData[selectedIndex]?.clientContainer
                                ?.client.soSec?.soSecPart3 ?? ''
                            }`}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h5grey">Date of Birth</Typography>
                        <Typography variant="h5">
                          {hohMemberData[selectedIndex]?.clientContainer?.client
                            ?.dob
                            ? dayjs(
                                hohMemberData[selectedIndex]?.clientContainer
                                  ?.client?.dob
                              ).format('MM-DD-YYYY')
                            : 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h5grey">Marital Status</Typography>
                        <Typography variant="h5">
                          {getLkpMatch(
                            'MartialStatus',
                            hohMemberData[selectedIndex]?.clientContainer
                              ?.client?.maritalStatus?.marriedYN
                          ) ?? 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h5grey">Ethnicity</Typography>
                        <Typography variant="h5">
                          {getLkpMatch(
                            'Ethnicity',
                            hohMemberData[selectedIndex]?.clientContainer
                              ?.client?.ethnicity
                          ) ?? 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h5grey">Gender</Typography>
                        <Typography variant="h5">
                          {getLkpMatch(
                            'Gender',
                            hohMemberData[selectedIndex]?.clientContainer
                              ?.client?.gender
                          ) ?? 'NA'}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="h5grey">Race</Typography>
                        <Typography variant="h5">
                          {getLkpMatch(
                            'Race',
                            hohMemberData[selectedIndex]?.clientContainer
                              ?.client?.race?.primaryRace
                          ) ?? 'NA'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Divider />
                  <Stack rowGap={1} p={1}>
                    {(hohMemberData?.[selectedIndex]?.clearanceStatus === 'N' &&
                      hohMemberData?.[selectedIndex]?.clientContainer?.client
                        ?.headOfHouseholdYN === 'Y' &&
                      (hohMemberData?.[selectedIndex]?.caseId === '' ||
                        hohMemberData?.[selectedIndex]?.caseId === null)) ||
                    (hohMemberData?.[selectedIndex]?.clearanceStatus === 'N' &&
                      hohMemberData?.[selectedIndex]?.clientContainer?.client
                        ?.headOfHouseholdYN === 'N') ? (
                      <Button
                        sx={{
                          alignSelf: 'flex-end',
                          border: '1px solid',
                        }}
                        startIcon={<PersonAddAltIcon />}
                        onClick={() => (
                          setIsIndividual(true),
                          setEditData(hohMemberData[selectedIndex])
                        )}
                      >
                        Create Individual
                      </Button>
                    ) : null}
                    <DataGrid
                      getRowId={(row) => row.mdmId ?? row?.caseId ?? row?.webId}
                      rows={rowData || []}
                      columns={clearanceColumns}
                      // rowCount={10}
                      // pageSizeOptions={[10, 20, 30]}
                      paginationMode="server"
                      // paginationModel={{
                      //   page: 0,
                      //   pageSize: 10,
                      // }}
                      onPaginationModelChange={() => {}}
                      autoHeight
                      localeText={{ noRowsLabel: 'No data found' }}
                      sx={{
                        backgroundColor: 'white',
                        '& .MuiDataGrid-columnHeaderTitle': {
                          fontWeight: '700',
                          color: '#000000',
                        },
                        '& .MuiDataGrid-row': {
                          color: 'black',
                          textAlign: 'center',
                        },
                      }}
                    />
                  </Stack>
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>

        <Dialog maxWidth="lg" open={isIndividual}>
          <DialogTitle>Customer Demographic Information</DialogTitle>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'var(--grey-400)',
            }}
            onClick={() => (setIsIndividual(false), setEditData({}))}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <CustomerDemoInfo
              getOptions={getOptions}
              editData={editData}
              onHandleChange={onHandleChange}
            />
          </DialogContent>
          <DialogActions sx={{ marginBottom: '5px' }}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => (setIsIndividual(false), setEditData({}))}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="sm" open={isSelected}>
          <DialogTitle></DialogTitle>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => (setIsSelected(false), setMdmId())}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} textAlign="center">
                  <ErrorOutlineIcon
                    sx={{ color: '#EFB725', fontSize: '100px' }}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Typography variant="h1" color="var">
                    Are you sure?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="var(--grey-400)">
                    The demographics of the individual you selected do not match
                    with what was provided by the consumer. Are you sure you
                    wish to proceed? Click "<b>Yes</b>" to proceed or "
                    <b>Cancel</b>" to close this window without making the
                    selection.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: 'center', marginBottom: '15px' }}
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => (setIsSelected(false), setMdmId())}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={fetchIndividualAssistance}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth="md" open={isSelectedCase}>
          <DialogTitle>List of Cases</DialogTitle>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setIsSelectedCase(false)}
          >
            <Close />
          </IconButton>
          <DialogContent>
            <Paper elevation={3} sx={{ padding: '10px' }}>
              <Typography variant="h5grey">
                Cases associated with the member
              </Typography>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 3,
                      paddingBottom: '0.5rem',
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5">Case Id</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">Program</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">Case Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5">Select</Typography>
                      </TableCell>
                    </TableRow>
                    {map(isSelectedCaseData, (ele, index) => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="body1">{ele.caseId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {ele.programCd}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">{ele.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {ele.caseStatus}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => fetchRecallIndividual()}
                          >
                            <CheckCircleOutlinedIcon
                              sx={{ paddingRight: '2px' }}
                            />
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: 'center', marginBottom: '15px' }}
          >
            <Button
              variant="contained"
              onClick={() => (
                setIsSelectedCase(false), setIsSelectedCaseData([])
              )}
            >
              Discard
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </PageContainer>
  );
};

RunClearance.propTypes = {};

export default RunClearance;
