import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import {
  Typography,
  Button,
  Stack,
  TableCell,
  TableContainer,
  TableBody,
  Tooltip,
  IconButton,
  TableRow,
  TableHead,
  Table,
  Paper,
  Dialog,
  ButtonGroup,
} from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { map, some } from 'lodash';
import { useLocation } from 'react-router-dom';
import ClearanceSearch from '../ClearanceSearch';
import DemographicInfo from './DemographicInfo';
import Wizard from '../../components/Wizard';
import useLookup from '../../hooks/useLookup';
import TabularView from '../../components/TabularView';
import usePageNav from '../../hooks/usePageNav';
import {
  CloseRounded,
  DeleteOutlined,
  ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { showErrorToast, showWarningToast } from '../../utils/services/toast';
import { getHouseMembers } from '../../utils/services/apiRequests';
import useLoader from '../../hooks/useLoader';
import MemberDeleteModal from '../HouseMembers/MemberDeleteModal';
function CustomerReg(props) {
  const location = useLocation();
  const [showLoader, hideLoader] = useLoader();
  const { navigations, caseId } = usePageNav();
  const { getOptions, getLkpMatch, lkpFlatten } = useLookup('Demographic');
  const [showClearance, setShowClearance] = useState(false);
  const [houseMembers, setHouseMembers] = useState([]);
  const [showDemographic, setShowDemographic] = useState(false);
  const [caseDetials, setCaseDetails] = useState({});
  const { programs = [], date = null } = location.state ?? {};
  const [fetchingMembers, setFetchingMembers] = useState(false);
  const [editMember, setEditMember] = useState({});
  const [showEditMember, setShowEditMember] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState({});
  const [clearanceData, setClearanceData] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const { caseId, householdMembers = [] } = caseDetials;
  const hasHoh = some(houseMembers, { headOfHouseholdYN: 'Y' });
  const onNext = () => {
    if (hasHoh) {
      navigations.toContact(caseId);
    } else {
      showWarningToast('Please add head of house to proceed further');
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'mdmId',
        headerName: 'Customer ID',
      },
      {
        headerName: 'Name',
        getValue({ personName = {} }) {
          const { firstName = '', lastName = '' } = personName;
          return `${firstName} ${lastName}`;
        },
      },
      {
        headerName: 'Gender',
        getValue({ gender }) {
          return getLkpMatch('Gender', gender);
        },
      },
      {
        headerName: 'DOB',
        field: 'dob',
      },
      {
        // field: 'ssn',
        headerName: 'SSN',
        getValue({ soSec = {} }) {
          const { soSecPart1, soSecPart2, soSecPart3 } = soSec;
          return soSecPart1 ? `*** ** ${soSecPart3}` : '-';
        },
      },
      {
        headerName: 'HOH',
        getValue({ headOfHouseholdYN }) {
          return headOfHouseholdYN === 'Y' ? 'Yes' : 'No';
        },
      },
      {
        headerName: 'Action',
        renderCell(member) {
          return (
            <ButtonGroup>
              <IconButton
                onClick={() => {
                  setEditMember(member);
                  setShowEditMember(true);
                }}
              >
                <Tooltip title="Edit" placement="top">
                  <ModeEditOutlineOutlined fontSize="small" />
                </Tooltip>
              </IconButton>

              <IconButton
                onClick={() => {
                  setMemberToDelete(member);
                  toggleDeleteModal();
                }}
              >
                <Tooltip title="Delete" placement="top">
                  <DeleteOutlined fontSize="small" />
                </Tooltip>
              </IconButton>
            </ButtonGroup>
          );
        },
      },
    ],
    [getLkpMatch, houseMembers]
  );

  const toggleClearance = (data) => {
    setClearanceData(data);
    setShowClearance(!showClearance);
  };

  const toggleDemographic = () => {
    setShowDemographic(!showDemographic);
  };

  const toggleEditMember = () => {
    setShowEditMember(!showEditMember);
  };

  const onAddIndividual = (data) => {
    toggleClearance(data);
    toggleDemographic();
  };

  const fetchHouseMembers = async () => {
    try {
      showLoader();
      setFetchingMembers(true);
      const res = await getHouseMembers(caseId);
      if (res.status === 200) {
        setHouseMembers(res.data ?? []);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetchingMembers(false);
      hideLoader();
    }
  };

  const onAddUser = (_caseId) => {
    if (!caseId) {
      navigations.toCustomerReg(_caseId);
    } else {
      fetchHouseMembers();
    }
  };

  useEffect(() => {
    if (caseId) {
      fetchHouseMembers();
    }
  }, [caseId]);

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const onDeleteMemberScs = () => {
    fetchHouseMembers();
  };

  return (
    <div className={styles.customerReg}>
      {showClearance && (
        <ClearanceSearch
          open={showClearance}
          close={toggleClearance}
          onAddIndividual={onAddIndividual}
        />
      )}
      <MemberDeleteModal
        open={openDeleteModal}
        close={toggleDeleteModal}
        onDelete={onDeleteMemberScs}
        reasons={getOptions('DeleteMemberReasonCode')}
        memberToDelete={memberToDelete}
        caseId={caseId}
      />
      {showDemographic && (
        <DemographicInfo
          date={date}
          caseId={caseId}
          hasHoh={hasHoh}
          close={toggleDemographic}
          open={showDemographic}
          programs={programs}
          clearanceData={clearanceData}
          getOptions={getOptions}
          setCaseDetails={setCaseDetails}
          onAddUser={onAddUser}
          lkpFlatten={lkpFlatten}
        />
      )}
      {showEditMember && (
        <DemographicInfo
          caseId={caseId}
          hasHoh={hasHoh}
          close={toggleEditMember}
          open={showEditMember}
          getOptions={getOptions}
          setCaseDetails={setCaseDetails}
          onAddUser={onAddUser}
          memberDetails={editMember}
          editMode
          fetchHouseMembers={fetchHouseMembers}
        />
      )}
      <Typography variant="h2">Customer Registration</Typography>
      <Stack className={styles.table}>
        <Button
          sx={{ alignSelf: 'flex-end' }}
          fullWidth={false}
          startIcon={<PersonAddAltIcon />}
          onClick={toggleClearance}
        >
          Add Household Member
        </Button>
        <TabularView
          headerComponent={{ variant: 'subtitle1' }}
          component={Paper}
          columns={columns}
          data={houseMembers}
        />
      </Stack>
      <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
        <Button variant="contained">Generate Customer ID</Button>
        <Stack direction={'row'} spacing={1}>
          {/* <Button variant="outlined">Back</Button> */}
          <Button
            onClick={onNext}
            // disabled={!houseMembers?.length}
            variant="contained"
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

CustomerReg.propTypes = {};

export default CustomerReg;
