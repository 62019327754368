import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import TabularView from '../../../components/TabularView';
import {
  every,
  filter,
  find,
  findIndex,
  first,
  get,
  includes,
  isEmpty,
  map,
  reject,
  some,
  values,
} from 'lodash';
import { cond, getFullName, yNtoBool } from '../../../utils/helpers';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../components/Dropdown';
import YesNoOption from '../../../components/YesNoOption';
import Avatar from '../../../components/Avatar';
import FormSection from '../../../components/FormSection';
import useMount from '../../../hooks/useMount';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ProgramTile from '../../../components/ProgramTile';
import AvatarGroup from '../../../components/AvatarGroup';
import dayjs from 'dayjs';
import SnapQuestionnaire from '../SnapQuestionnaire';
import Icon from '../../../components/Icon';
import { programIcons } from '../../../constants/programConstants';
import SignatoryTable from '../SignatoryTable';
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from '../../../utils/services/toast';
import {
  getDsnapIndicator,
  saveProgramRequest,
} from '../../../utils/services/programRequest/apiRequests';
import useLoader from '../../../hooks/useLoader';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';

const isSnapProgram = (pgs) =>
  some(pgs, ({ programName }) => programName === 'FS');

const DEFAULT_PG_DETAILS = {
  // programIndividualId: 0,
  // programId: 0,
  programStatus: '',
  requestedDate: null,
  requestIndicator: '',
  programSubType: '',
  specialRequestType: '',
  msnapInd: '',
  eidDetails: [],
  dirtyStatus: 'insert',
  statusReasons: [
    {
      dirtyStatus: '',
      noticeTypeCode: '',
      noticeTypeText: '',
      reasonCode: null,
      reasonText: '',
    },
  ],
};

function AddProgram({
  programs,
  requestedProgram,
  lkpRaw,
  getLkpMatch,
  caseId,
  onAddSuccess,
  getOptions,
}) {
  const getLkp = getLkpMatch('RequestedPrograms');
  const { houseMembers } = useSelector(selectHouseMembers);

  const [fetchingDsnapPeriod, setFetchingDsnapPeriod] = useState(false);
  const [dsnapIndicator, setDsnapIndicator] = useState('');
  // const [selectedPrograms, setSelectedPrograms] = useState([]);
  // const members = map(programs?.requestedProgram, (p) => {
  //   const { personName, programRequest } = p;
  //   return getFullName(personName?.firstName, personName?.lastName);
  // });
  const [showLoader, hideLoader] = useLoader();
  const [signatures, setSignatures] = useState([]);
  const existingPrograms = useMemo(() => {
    return map(requestedProgram, ({ programName }) => programName);
  }, [requestedProgram]);

  const individualList = useMemo(() => {
    return map(houseMembers, (indv) => {
      const { individualId, personName, headOfHouseholdYN, dob } = indv;
      return {
        individualId,
        personName,
        headOfHouseholdYN,
        dob,
      };
    });
  }, [houseMembers]);

  useEffect(() => {
    if (isEmpty(signatures) && !isEmpty(individualList)) {
      const hohMember = filter(individualList, { headOfHouseholdYN: 'Y' });
      const signList = map(hohMember, (indv) => {
        const { firstName = '', lastName = '' } = indv.personName ?? {};
        const signatoryName = getFullName(firstName, lastName);
        return { ...indv, signatoryName, programs: [], signature: {} };
      });
      setSignatures(signList);
    }
  }, [individualList, signatures]);

  const statusOptions = useMemo(() => {
    return map(get(lkpRaw, 'ReasonCode', []), ({ lookupLongVal, lkpDesc }) => {
      const label = `${lkpDesc} - ${lookupLongVal}`;
      return {
        label,
        value: lkpDesc,
      };
    });
  }, [lkpRaw]);

  const validationSchema = yup.object({
    individualPrograms: yup.array(yup.object({})),
    requestedProgram: yup
      .array(
        yup.object({
          applicationDate: yup
            .date()
            .required('Please select application date'),
        })
      )
      .min(1)
      .required(),
    monthlyRentAmount: yup.number().when(['requestedProgram', 'monthlyRent'], {
      is: (pgs, monthlyRent) => isSnapProgram(pgs) && yNtoBool(monthlyRent),
      then: () => yup.number().required(),
      otherwise: () => yup.number().notRequired(),
    }),
    grossMonthlyIncomeAmount: yup
      .number()
      .when(['requestedProgram', 'grossMonthlyIncomeResources'], {
        is: (pgs, grossMonthlyIncomeResources) =>
          isSnapProgram(pgs) && yNtoBool(grossMonthlyIncomeResources),
        then: () => yup.number().required(),
        otherwise: () => yup.number().notRequired(),
      }),
    resourcesAmount: yup
      .number()
      .when(['requestedProgram', 'grossMonthlyIncomeResources'], {
        is: (pgs, grossMonthlyIncomeResources) =>
          isSnapProgram(pgs) && yNtoBool(grossMonthlyIncomeResources),
        then: () => yup.number().required(),
        otherwise: () => yup.number().notRequired(),
      }),
  });

  const savePrograms = async (data) => {
    try {
      showLoader();
      const res = await saveProgramRequest(data, caseId);
      if (res?.status === 200) {
        const pgms = res.data;
        const prgrams = map(pgms, ({ programName }) => programName);
        onAddSuccess(prgrams);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const spacialRequestOptions = useCallback(
    (_programName) => {
      if (_programName === 'EA') {
        return getOptions('SpecialRequest');
      }

      if (_programName === 'MA') {
        return [
          {
            label: 'Family Assistance',
            value: 'EAF',
          },
          {
            label: 'Burial Assistance',
            value: 'BAS',
          },
        ];
      }
      return [];
    },
    [getOptions]
  );

  const formik = useFormik({
    initialValues: {
      // selectedPrograms: [],
      requestedProgram: [],
      monthlyRent: 'N',
      monthlyRentAmount: '',
      grossMonthlyIncomeResources: 'N',
      grossMonthlyIncomeAmount: '',
      resourcesAmount: '',
      migrantSeasonal: 'N',
    },
    validationSchema,
    onSubmit: async (data) => {
      const {
        requestedProgram = [],
        monthlyRent = 'N',
        monthlyRentAmount = 0,
        grossMonthlyIncomeResources = 'N',
        grossMonthlyIncomeAmount = 0,
        resourcesAmount = 0,
        migrantSeasonal = 'N',
      } = data;

      const expeditedFoodSuplimentObj = {
        expeditedFoodSupliment: {
          monthlyRent,
          monthlyRentAmount: {
            amount: monthlyRentAmount,
          },
          grossMonthlyIncomeResources,
          grossMonthlyIncomeAmount: {
            amount: grossMonthlyIncomeAmount,
          },
          resourcesAmount: {
            amount: resourcesAmount,
          },
          migrantSeasonal,
        },
      };

      const requestedProgramWithSign = map(requestedProgram, (program) => {
        const { programName, applicationDate, individuals } = program;
        const signedUsers = filter(signatures, ({ programs }) =>
          includes(programs, program.programName)
        );
        const signatureDetails = map(signedUsers, (user) => user.signature);

        return {
          ...program,
          applicationDate: dayjs(applicationDate).format('YYYY-MM-DD'),
          individuals,
          signatureDetails,
          ...(programName === 'FS' ? expeditedFoodSuplimentObj : {}),
        };
      });
      const payload = {
        requestedProgram: requestedProgramWithSign,
        caseLoadIndicator: 'Y',
        uniqueId: '7c438d573165cdd1552231ed3bdd33',
      };
      savePrograms(requestedProgramWithSign);
    },
  });

  const selectedPrograms = map(
    formik.values.requestedProgram,
    ({ programName }) => programName
  );

  const signedProgramsObj = useMemo(() => {
    const entries = map(selectedPrograms, (p) => {
      const users = filter(signatures, (s) => {
        return includes(s.programs, p);
      });
      const names = map(users, ({ personName = {} }) => {
        const { firstName = '', lastName = '' } = personName;
        return getFullName(firstName, lastName);
      });
      return [p, names];
    });
    return Object.fromEntries(entries);
  }, [selectedPrograms, signatures]);

  const onSubmit = () => {
    const isSignError = some(values(signedProgramsObj), isEmpty);
    if (isSignError) {
      showWarningToast('Please add signature for all programs!');
    } else {
      formik.handleSubmit();
    }
  };

  const members = useMemo(() => {
    return map(requestedProgram, (p) => {
      const { personName, individualId } = p;
      const name = getFullName(personName?.firstName, personName?.lastName);
      return {
        name,
        individualId,
      };
    });
  }, [requestedProgram]);

  const checkDsnapPeriod = async (cb) => {
    try {
      setFetchingDsnapPeriod(true);
      const res = await getDsnapIndicator();
      if (res.status) {
        if (res.data?.requestIndicator) {
          setDsnapIndicator(res.data?.requestIndicator);
        }
      }
    } catch (error) {
      //
    } finally {
      setFetchingDsnapPeriod(false);
    }
  };

  const setSelectedProgram = (selectedProgram) => {
    if (includes(selectedPrograms, selectedProgram)) {
      const newPrograms = reject(formik.values.requestedProgram, {
        programName: selectedProgram,
      });
      formik.setFieldValue('selectedPrograms', newPrograms);
      const newRequestedProgram = map(formik.values.requestedProgram, (p) => {
        const { programRequest } = p;
        const newProgramRequest = reject(
          programRequest,
          (pr) => pr.programName === selectedProgram
        );
        return {
          ...p,
          programRequest: newProgramRequest,
        };
      });
      formik.setFieldValue('requestedProgram', newPrograms);
    } else {
      // formik.setFieldValue('selectedPrograms', [
      //   ...selectedPrograms,
      //   selectedProgram,
      // ]);
      const newProgram = {
        programName: selectedProgram,
        signatureDetails: [],
        applicationDate: null,
        programStatus: 'PE',
        programSubType: '',
        dirtyStatus: 'insert',
        individuals: map(individualList, (indv) => ({
          ...indv,
          ...DEFAULT_PG_DETAILS,
        })),
      };

      formik.setFieldValue('requestedProgram', [
        newProgram,
        ...formik.values.requestedProgram,
      ]);
    }
  };

  const onSelectProgram = (selectedProgram) => {
    if (selectedProgram === 'DS') {
      checkDsnapPeriod(() => setSelectedProgram(selectedProgram));
    } else {
      setSelectedProgram(selectedProgram);
    }
  };

  const handleChange = (individualId, programName, prop, value) => {
    const programIndex = findIndex(formik.values.requestedProgram, {
      programName,
    });

    const indvIndex = findIndex(
      formik.values.requestedProgram[programIndex].individuals,
      {
        individualId,
      }
    );

    const newIndividuals = Object.assign(
      [],
      formik.values.requestedProgram[programIndex].individuals,
      {
        [indvIndex]: {
          ...formik.values.requestedProgram[programIndex].individuals[
            indvIndex
          ],
          [prop]: value,
        },
      }
    );

    const newRequestedProgram = Object.assign(
      [],
      formik.values.requestedProgram,
      {
        [programIndex]: {
          ...formik.values.requestedProgram[programIndex],
          individuals: newIndividuals,
        },
      }
    );

    // const newProgramRequest = Object.assign(
    //   [],
    //   formik.values.requestedProgram[indvIndex].programRequest,
    //   {
    //     [programIndex]: {
    //       ...formik.values.requestedProgram[indvIndex].programRequest[
    //         programIndex
    //       ],
    //       [prop]: value,
    //     },
    //   }
    // );
    // const newRequestedProgram = Object.assign(
    //   [],
    //   formik.values.requestedProgram,
    //   {
    //     [indvIndex]: {
    //       ...formik.values.requestedProgram[indvIndex],
    //       programRequest: newProgramRequest,
    //     },
    //   }
    // );

    formik.setFieldValue('requestedProgram', newRequestedProgram);
  };

  const columns = [
    {
      headerName: 'Member Name',
      renderCell({ personName = {} }) {
        const { firstName = '', lastName = '' } = personName;
        const name = getFullName(firstName, lastName);

        return (
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
            <Avatar name={name} />
            <Typography variant="subtitle1">{name}</Typography>
          </Stack>
        );
      },
    },
    {
      headerName: 'Requesting Assistance',
      renderCell({ individualId, requestIndicator }, index, p) {
        const onChange = (_e, checked) => {
          const value = checked ? 'Y' : 'N';
          handleChange(individualId, p, 'requestIndicator', value);
        };
        // const cMember = find(formik.values.requestedProgram, { individualId });
        // const cProgram = find(cMember?.programRequest, { programName: p });
        // const requestIndicator = cProgram?.requestIndicator;
        return (
          <Box style={{ textAlign: 'left' }}>
            <Checkbox
              checked={requestIndicator === 'Y'}
              onChange={onChange}
              ml="1rem"
            />
          </Box>
        );
      },
    },
    {
      hide: (params, _programName) => {
        return !(_programName === 'EA' || _programName === 'MA');
      },
      renderHeader: () => (
        <Typography variant="h5">
          Special request<span style={{ color: 'red' }}>*</span>
        </Typography>
      ),
      renderCell(
        { individualId, specialRequestType, requestIndicator },
        index,
        p
      ) {
        const thisIndex = findIndex(formik.values.individuals, {
          individualId,
        });
        const error =
          formik.errors.individuals?.[thisIndex]?.specialRequestType;

        const onChange = (e) => {
          const value = e.target.value;
          handleChange(individualId, p, 'specialRequestType', value);
        };
        return (
          <Box sx={{ width: '15rem' }}>
            <Dropdown
              error={error}
              options={spacialRequestOptions(p)}
              value={specialRequestType}
              onChange={onChange}
              disabled={!yNtoBool(requestIndicator)}
            />
          </Box>
        );
      },
    },
    {
      headerName: 'Request Date',
      renderCell({ individualId, requestedDate }, index, p) {
        const onChange = (date) => {
          const formattedDate = dayjs(date).format('YYYY-MM-DD');
          handleChange(individualId, p, 'requestedDate', formattedDate);
        };
        // const cMember = find(formik.values.requestedProgram, {
        //   individualId,
        // });
        // const cProgram = find(cMember?.programRequest, { programName: p });
        // const requestedDate = cProgram?.requestedDate;
        return (
          <Box sx={{ width: '15rem' }}>
            <DatePicker
              value={requestedDate ? dayjs(requestedDate) : null}
              onChange={onChange}
            />
          </Box>
        );
      },
    },
    {
      headerName: '	Close/Denial Reason',
      renderCell({ individualId, statusReasons, requestIndicator }, index, p) {
        const onChange = (e) => {
          const reasonCode = e.target.value;
          const newStatusReasons = [
            {
              dirtyStatus: '',
              noticeTypeCode: '',
              noticeTypeText: '',
              reasonCode,
              reasonText: '',
            },
          ];
          handleChange(individualId, p, 'statusReasons', newStatusReasons);
        };
        // const cMember = find(formik.values.requestedProgram, {
        //   individualId,
        // });
        // const cProgram = find(cMember?.programRequest, { programName: p });
        // const statusReasons = cProgram?.statusReasons;
        const reasonCode = statusReasons?.[0]?.reasonCode;
        return (
          <Box sx={{ width: '15rem' }}>
            <Dropdown
              options={statusOptions}
              value={reasonCode}
              onChange={onChange}
              disabled={!yNtoBool(requestIndicator)}
            />
          </Box>
        );
      },
    },
  ];

  const onChangeAppDt = (date, programName) => {
    const programIndex = findIndex(formik.values.requestedProgram, {
      programName,
    });
    const newRequestedProgram = Object.assign(
      [],
      formik.values.requestedProgram,
      {
        [programIndex]: {
          ...formik.values.requestedProgram[programIndex],
          applicationDate: dayjs(date).format('MM-DD-YYYY'),
        },
      }
    );
    formik.setFieldValue('requestedProgram', newRequestedProgram);
  };

  const programsList = useMemo(() => {
    const isLtSelected = includes(selectedPrograms, 'LT');
    const isWaivarSelected = includes(selectedPrograms, 'WV');
    const notLTOrWaivar = !(isLtSelected && isWaivarSelected);

    const isProgramDisabled = (programName) => {
      let disabled, disabledReason;
      if (programName === 'LT') {
        disabled =
          some(selectedPrograms, (p) => includes(['CA', 'EA', 'FS'], p)) ||
          some(existingPrograms, (p) => includes(['CA', 'EA', 'FS'], p));
        disabledReason = disabled
          ? "Can't be selected with Cash Assistance, Emergency Assistance and Snap programs"
          : '';
      }

      if (programName === 'WV') {
        disabled =
          some(selectedPrograms, (p) => includes(['CA', 'EA', 'FS'], p)) ||
          some(existingPrograms, (p) => includes(['CA', 'EA', 'FS'], p));
        disabledReason = disabled
          ? "Can't be selected with Cash Assistance, Emergency Assistance and Snap programs"
          : '';
      }

      if (includes(['CA', 'EA', 'FS'], programName)) {
        disabled = isLtSelected || isWaivarSelected;
        disabledReason = disabled
          ? "Can't be selected with Logn Term Care & Waiver Programs"
          : '';
      }

      if (programName === 'DS' && dsnapIndicator === 'N') {
        disabled = true;
        disabledReason = 'DSNAP cannot be added for current period';
      }

      return {
        disabled,
        disabledReason,
      };
    };

    return map(lkpRaw?.RequestedPrograms, ({ lkpDesc, lookupLongVal }) => {
      const isExist = includes(existingPrograms, lkpDesc);
      const selected = includes(selectedPrograms, lkpDesc) || isExist;
      const { disabled, disabledReason } = isProgramDisabled(lkpDesc);
      const selectedReason = cond([
        [isExist, 'Already added!'],
        [selected, 'Selected'],
      ]);
      return {
        title: lookupLongVal,
        programName: lkpDesc,
        disabled: isExist || disabled,
        selected,
        selectedReason,
        disabledReason,
      };
    });
  }, [
    dsnapIndicator,
    existingPrograms,
    lkpRaw?.RequestedPrograms,
    selectedPrograms,
  ]);

  return (
    <Stack spacing={1} sx={{ p: '1rem 0', height: '100%' }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Icon width="2.5rem" height="2.5rem" name="AddProgram" />
        <Typography variant="h5">Add Program(s)</Typography>
      </Stack>
      <Divider />
      <FormSection label="Available Programs">
        <Stack spacing={2} direction="row" sx={{}}>
          {map(programsList, (program) => {
            const {
              title,
              programName,
              disabled,
              selected,
              selectedReason,
              disabledReason,
            } = program;
            const loading = programName === 'DS' && fetchingDsnapPeriod;
            return (
              <ProgramTile
                disabled={disabled}
                selected={selected}
                title={title}
                programName={programName}
                description={disabledReason || selectedReason}
                onClick={() => onSelectProgram(programName)}
                loading={loading}
              />
            );
          })}
        </Stack>
      </FormSection>
      {formik.values.requestedProgram?.length > 0 && (
        <FormSection required label="Application Date">
          <Grid container spacing={1}>
            {map(
              formik.values.requestedProgram,
              ({ programName, applicationDate }, index) => {
                const error =
                  formik.errors.requestedProgram?.[index]?.applicationDate;
                return (
                  <Grid item xs={6}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Icon
                        width="2rem"
                        height="2rem"
                        name={programIcons[programName]}
                      />
                      <Typography variant="subtitle1">
                        {getLkp(programName)}
                      </Typography>
                      <DatePicker
                        sx={{ width: '15rem' }}
                        value={
                          applicationDate
                            ? dayjs(applicationDate)
                            : applicationDate
                        }
                        onChange={(date) => onChangeAppDt(date, programName)}
                        error={true}
                      />
                    </Stack>
                  </Grid>
                );
              }
            )}
          </Grid>
        </FormSection>
      )}
      {selectedPrograms.length > 0 && (
        <SignatoryTable
          individuals={individualList}
          selectedPrograms={selectedPrograms}
          getProgramLkp={getLkp}
          signatures={signatures}
          setSignatures={setSignatures}
        />
      )}
      {map(formik.values.requestedProgram, ({ programName, individuals }) => {
        return (
          <Stack spacing={1}>
            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Stack sx={{ alignItems: 'center' }} spacing={1} direction="row">
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  name={programIcons[programName]}
                />
                <Typography variant="subtitle1">
                  {getLkp(programName)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ alignItems: 'center', pr: '1rem' }}
              >
                <Typography variant="caption">Signed by:</Typography>
                {signedProgramsObj?.[programName]?.length > 0 ? (
                  <AvatarGroup
                    // customSize={0.8}
                    addable={false}
                    names={signedProgramsObj[programName]}
                  />
                ) : (
                  <Typography
                    sx={{ color: 'var(--error-500)' }}
                    variant="caption"
                  >
                    Not signed
                  </Typography>
                )}
              </Stack>
            </Stack>
            <TabularView
              headerComponent={{ variant: 'h5' }}
              sx={{
                table: {
                  // width: 'auto',
                  border: '2px solid #f5f5f5',
                },
                thead: {
                  backgroundColor: 'var(--grey-200)',
                  borderRadius: '0.25rem',
                },
                td: {
                  border: 0,
                  p: '1rem',
                },
                th: {
                  p: '1rem',
                  border: 0,
                },
              }}
              rootData={programName}
              data={individuals}
              columns={columns}
            />
            {programName === 'FS' && <SnapQuestionnaire formik={formik} />}
          </Stack>
        );
      })}
      <Stack spacing={1}>
        {/* <Typography variant="subtitle1" color="var(--grey-400)">
          Are you applied to the program?
        </Typography>
        <YesNoOption value="Y" /> */}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 'auto !important',
          mt: '1rem',
        }}
        direction="row"
        style={{ marginTop: 'auto' }}
      >
        <Button
          onClick={onSubmit}
          disabled={!formik.dirty || !formik.isValid}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
}

AddProgram.propTypes = {};

export default AddProgram;
