import React, { useMemo, useState } from 'react';
import HealthDataGrid from '../HealthDataGrid';
import AddEditWbcchpDetailsDialog from './AddEditWbcchpDetailsDialog';
import ActionButton from '../../../components/ActionButtons';
import { get, map } from 'lodash';
import DeleteConfiramation from '../DeleteConfiramation';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { saveWbcchpDetails } from '../../../utils/services/healthAndDisability/apiRequests';
import useLoader from '../../../hooks/useLoader';

const WbcchpDetails = ({
  member,
  setOpen,
  open,
  isAdd,
  setIsAdd,
  lkpRaw,
  lkpFlatten,
  getLkpMatch,
  caseId,
  getOptions,
  disabilitiesData,
  fetchData,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleEdit = (params) => {
    setOpen(true);
    setIsAdd(false);
    setSelectedData(params.row);
  };

  const handleDelete = (params) => {
    setSelectedData(params.row);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    const deletePayload = {
      dirtyStatus: 'delete',
      doctorNoteVerification: selectedData.doctorNoteVerification,
      doctorsNoteDate: selectedData.doctorsNoteDate,
      effectiveBeginDate: selectedData.effectiveBeginDate,
      effectiveEndDate: selectedData.effectiveEndDate,
      medicallyNecessaryYN: selectedData.medicallyNecessaryYN,
      ongoingTreatmentYN: selectedData.ongoingTreatmentYN,
      recordId: selectedData.recordId,
    };

    try {
      showLoader();
      const res = await saveWbcchpDetails(
        caseId,
        member.individualId,
        deletePayload,
        'delete'
      );

      if (res.status === 200) {
        showSuccessToast('WBCCHP Details Successfully Deleted');
        fetchData(member.individualId);
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
    setOpenDeleteDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const columns = [
    {
      field: 'effectiveBeginDate',
      headerName: 'Effective Begin Date',
      width: 180,
    },
    {
      field: 'effectiveEndDate',
      headerName: 'Effective End Date',
      width: 160,
    },
    {
      field: 'doctorsNoteDate',
      headerName: 'Doctors Note Date',
      width: 160,
      editable: false,
    },

    {
      field: 'verificationSourceCode',
      headerName: 'Verification',
      width: 120,
      renderCell: (params) =>
        lkpFlatten.VerificationWbcchp[params?.row?.verificationSourceCode],
    },
    {
      field: 'medicallyNecessaryYN',
      headerName: 'Medically Necessary Ind',
      width: 200,
      renderCell: (params) =>
        lkpFlatten.MedicalReviewReceivedGA[params?.row?.medicallyNecessaryYN],
    },
    {
      field: 'ongoingTreatmentYN',
      headerName: 'Ongoing Treatment Ind',
      width: 190,
      renderCell: (params) =>
        lkpFlatten.MedicalReviewReceivedGA[params?.row?.ongoingTreatmentYN],
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span>
            <ActionButton
              onDelete={() => handleDelete(params)}
              onEdit={() => handleEdit(params)}
            />
          </span>
        );
      },
    },
  ];

  const rows = useMemo(
    () =>
      map(disabilitiesData.wbcchpDetails, (item, index) => {
        return {
          ...item,
          id: index,
          effectiveBeginDate: get(item, 'effectiveBeginDate', ''),
          effectiveEndDate: get(item, 'effectiveEndDate', ''),
          doctorsNoteDate: get(item, 'doctorsNoteDate', ''),
          verificationSourceCode: get(
            item,
            'doctorNoteVerification.verificationSourceCode',
            ''
          ),
          medicallyNecessaryYN: get(item, 'medicallyNecessaryYN', ''),
          ongoingTreatmentYN: get(item, 'ongoingTreatmentYN', ''),
        };
      }),
    [disabilitiesData]
  );

  return (
    <>
      <HealthDataGrid rows={rows} columns={columns} loading={false} />
      <AddEditWbcchpDetailsDialog
        setOpen={setOpen}
        open={open}
        isAdd={isAdd}
        lkpRaw={lkpRaw}
        member={member}
        caseId={caseId}
        lkpFlatten={lkpFlatten}
        getLkpMatch={getLkpMatch}
        getOptions={getOptions}
        fetchData={fetchData}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default WbcchpDetails;
