import React, { useEffect, useState } from 'react';
import useTabs from '../../../hooks/useTabs';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Demographic from '../Demographic';
import { CloseRounded } from '@mui/icons-material';
import ContactModal from '../ContactInfo/ContactModal';
import EmployerModal from '../EmployerDetails/EmployerModal';
import OtherIncome from '../OtherIncome';
import useLookup from '../../../hooks/useLookup';
import EmployerDetails from '../EmployerDetails';
import CourtOrder from '../CourtOrder';
import ContactInfo from '../ContactInfo';
import DemographicModal from '../Demographic/DemographicModal';
import OtherIncomeModal from '../OtherIncome/OtherIncomeModal';
import usePageNav from '../../../hooks/usePageNav';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import { getContactInfo } from '../../../utils/services/NonCustodial/apiRequests';
import { showErrorToast } from '../../../utils/services/toast';

export default function CustodialParent({
  selected,
  childData,
  absentParentId,
  getDemographicDet,
  // absentParentId,
}) {
  const { individualId } = selected;
  const { caseId } = usePageNav();

  const [contactOpen, setContactOpen] = useState(false);
  const [employerOpen, setEmployerOpen] = useState(false);
  const [demographicOpen, setDemographicOpen] = useState(false);
  const [otherIncomeOpen, setOtherIncomeOpen] = useState(false);

  const { Tabs, Tab, TabContent, onTabChange, activeTab } = useTabs();

  const toggleClose = () => {
    setContactOpen(false);
    setDemographicOpen(false);
    setOtherIncomeOpen(false);
    setEmployerOpen(false);
  };

  return (
    <>
      <Tabs onChange={onTabChange} value={activeTab}>
        <Tab label="Demographic Details" />
        <Tab label="Contact Information" />
        <Tab label="Court Order" />
        <Tab label="Employer Details" />
        {/* <Tab label="Other Income" /> */}
      </Tabs>
      <TabContent>
        <div area-label="Demographic Details" style={{ width: '100%' }}>
          <Divider />
          <Demographic childData={childData} absentParentId={absentParentId} />
        </div>
        <div area-label="Contact Information" style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="end" mt={2}>
            <Button
              size="large"
              onClick={() => setContactOpen(true)}
              // startIcon={<AddIcon />}
            >
              + Add Contact Information
            </Button>
          </Stack>
          <Divider />
          <ContactInfo caseId={caseId} absentParentId={absentParentId} />
        </div>
        <div area-label="Court Order" style={{ width: '100%' }}>
          <CourtOrder caseId={caseId} absentParentId={absentParentId} />
        </div>
        <div area-label="Employer Details" style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="end" mt={2}>
            <Button
              size="large"
              onClick={() => setEmployerOpen(true)}
              // startIcon={<AddIcon />}
            >
              + Add Employer Details
            </Button>
          </Stack>
          <EmployerDetails caseId={caseId} absentParentId={absentParentId} />
        </div>
        {/* <div area-label="Other Income" style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="end" mt={2}>
            <Button
              size="large"
              onClick={() => setOtherIncomeOpen(true)}
              // startIcon={<AddIcon />}
            >
              + Add Other Income
            </Button>
          </Stack>
          <OtherIncome caseId={caseId} absentParentId={absentParentId} />
        </div> */}
      </TabContent>
      <Dialog open={demographicOpen} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Demographic Details</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DemographicModal
            toggleClose={toggleClose}
            getDemographicDet={getDemographicDet}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={contactOpen} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Contact Information</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <ContactModal
            toggleClose={toggleClose}
            caseId={caseId}
            createContact
            absentParentId={absentParentId}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={employerOpen} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Employer Information</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <EmployerModal
            toggleClose={toggleClose}
            caseId={caseId}
            createEmployer
            absentParentId={absentParentId}
          />
        </DialogContent>
      </Dialog>
      {/* <Dialog open={otherIncomeOpen} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Other Income</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <OtherIncomeModal
            toggleClose={toggleClose}
            caseId={caseId}
            absentParentId={absentParentId}
          />
        </DialogContent>
      </Dialog> */}
    </>
  );
}
