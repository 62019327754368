import { useNavigate } from 'react-router';
import {
  getCaseDetails,
  getCaseDetailesbyId,
} from '../utils/services/apiRequests';
import { showErrorToast, showWarningToast } from '../utils/services/toast';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../store/slices/loaderSlice';

function useCaseStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigations = {
    toCaseHome(caseId) {
      navigate(`/dashboard/caseInquiry/caseHome/${caseId}`);
    },
    toAppDispose(caseId) {
      navigate(`/dashboard/application/app-disposition/${caseId}`);
    },
    toCPImport(caseId, controlId) {
      navigate(`/dashboard/cp-import/caseCreation/${caseId}/${controlId}`);
    },
  };

  const viewCase = (caseId, caseStatus) => {
    switch (caseStatus) {
      case 'OP':
      case 'PE':
        navigations.toCaseHome(caseId);
        break;
      case 'IP':
        navigations.toAppDispose(caseId);
        break;
      default:
        showWarningToast('Case not found!');
    }
  };

  const getCaseStatus = async (caseId, redirect = true) => {
    try {
      showLoader();

      if (!(!isNaN(caseId) && !isNaN(parseFloat(caseId)))) {
        const res = await getCaseDetailesbyId(caseId);
        if (res.status === 200) {
          console.log(res);
          if (redirect) {
            navigations.toCPImport(caseId, res.data?.controlId);
          }

          return res.data;
        }
      } else {
        const res = await getCaseDetails(caseId);
        if (res.status === 200) {
          console.log(res);
          if (redirect) {
            viewCase(caseId, res.data?.caseStatusCd);
          }

          return res.data;
        }
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return {
    viewCase,
    getCaseStatus,
    navigations,
  };
}

export default useCaseStatus;
