import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Collapse, Typography } from '@mui/material';
import Icon from '../Icon';

function Accordion({ title, children }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.accordion}>
      <div
        role="button"
        tabIndex={-1}
        className={styles.title}
        onClick={toggleOpen}
      >
        <Typography variant="h4">{title}</Typography>
        <Icon
          style={{
            transform: `rotate(${open ? 180 : 0}deg)`,
            transition: 'transform 0.2s',
          }}
          name="ChevronSolid"
        />
      </div>
      <Collapse in={open}>
        <div className={styles.content}>{children}</div>
      </Collapse>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

Accordion.defaultProps = {
  title: '',
  children: null,
};

export default Accordion;
