import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import Field from '../../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import useLoader from '../../../../hooks/useLoader';
import { postDhaFia } from '../../../../utils/services/ManualNotices/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import InputMask from 'react-input-mask';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';

export default function DhsFia({
  toggleClose,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();
  const formik = useFormik({
    initialValues: {
      department: dataValue?.defaultCounty ?? '',
      depart: dataValue?.defaultCounty ?? '',
      montogomery: dataValue?.montgomeryCounty ?? '',
      initialPayment: dataValue?.initialPymnt ?? '',
      eligiblePayment: dataValue?.postELiPymnt ?? '',
      customerId: dataValue?.custIdNum ?? '',
      auNumb: dataValue?.auNum ?? '',
      category: dataValue?.category ?? '',
      ssn: dataValue?.soSecNum ?? '',
      district: dataValue?.distTerritory ?? '',
      federalCode: dataValue?.fedCode ?? '',
      countyFedCode: dataValue?.countyFedCode ?? '',
      applicantName: dataValue?.applicantName ?? '',
      address: dataValue?.address ?? '',
      signRecepient: dataValue?.signOfRecipient ?? '',
      date: dataValue?.recipientDt ?? '',
      signStateRecepient: dataValue?.signOfStateRecipient ?? '',
      dateState: dataValue?.stateRecipientDt ?? '',
      telephoneNum: dataValue?.stateTelephoneNum ?? '',
      grCode: dataValue?.grCode ?? '',
      recordId: dataValue?.mnlTmpltInfoId ?? 0,
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      console.log(values);
      dhFiaForm(values);
    },
  });

  const dhFiaForm = async (data) => {
    try {
      showLoader();
      const res = await postDhaFia(data);
      if (res.status === 200) {
        showSuccessToast('Succesfully Created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h3"
            >
              HUMAN SERVICES
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h3"
            >
              AUTHORIZATION FOR REIMBURSEMENT OF INTERIM ASSISTANCE
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h3"
            >
              INITIAL PAYMENT OR POST-ELIGIBILITY PAYMENT
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt="1rem" ml="2rem">
          <Grid item xs={10}>
            <Typography variant="h5grey">
              The term “STATE” means the Human Resources, through either (1) one
              of its local Departments of Social Services or (2) the Montgomery
              County Department of Health and Human Services.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textDecoration: 'underLine' }}>
              What actions are you authorizing when you sign this authorization
              and check the “Initial Claim Only” block? Initial Claim Only
            </Typography>
          </Grid>
          <Grid item xs={10} mt="0.5rem">
            <Typography variant="subtitle1">
              You are authorizing the Commissioner of the Social Security
              Administration (SSA) to reimburse the state for some or all of the
              money the state gives you. This money helps you while SSA decides
              if you are eligible to receive SSI benefits. If you become
              eligible, SSA pays the State from the retroactive SSI benefits due
              you. The reimbursement covers the time from the first month you
              are eligible to receive SSI benefits through the first month your
              monthly SSI benefit begins. If the state cannot stop the last
              payment made to you, SSA can reimburse the state for this
              additional payment amount.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
              What actions am I authorizing when I sign this authorization and I
              check the “Post eligibility Case Only” block?<br></br>{' '}
              Post-eligibility Case Only
            </Typography>
          </Grid>
          <Grid item xs={10} mt="0.5rem">
            <Typography variant="subtitle1">
              You are authorizing the Commissioner of the Social Security
              Administration (SSA) to reimburse the state for some or all of the
              money the state gives you. This money helps you while SSA decides
              if your SSI benefits can be reinstated after being terminated or
              suspended. If your SSI benefits resume, SSA pays the state from
              the retroactive SSI benefits due you. The reimbursement covers the
              time from the day of the month the reinstatement is effective
              through the first month your monthly SSI benefit resumes. If the
              state cannot stop the last payment made to you, SSA can reimburse
              the state for this additional payment amount.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
              How can the state use this form when blocks for initial claims and
              post-eligibility cases are both checked?
            </Typography>
          </Grid>
          <Grid item xs={10} mt="0.5rem">
            <Typography variant="subtitle1">
              The state can use this form for one case situation at a time,
              either an initial claim or a post-eligibility case. If both blocks
              are checked the form is not valid. You and the state must sign and
              date a new form with only one block checked.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
              What kind of state payment qualifies for reimbursement by SSA?
            </Typography>
          </Grid>
          <Grid item xs={10} mt="0.5rem">
            <Typography variant="subtitle1">
              SSA can reimburse a state for a payment that is paid only from
              state or local funds. The state cannot be reimbursed for payments
              made wholly or partially from Federal funds.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ textDecoration: ' underline ' }}>
              How does SSA determine how much of my SSI money to pay to the
              state.
            </Typography>
          </Grid>
          <Grid item xs={10} mt="0.5rem">
            <Typography variant="subtitle1">
              How does SSA determine how much of my SSI money to pay to the
              state. SSA decides the amount of payment based on two
              considerations. First, SSA looks at the amount of money claimed by
              the state, and second, SSA looks at the amount of your retroactive
              SSI money available to pay the state. SSA can reimburse the state
              for a payment made in a month only when you receive a state
              payment and an SSI payment for the same month. SSA will not pay
              more money then you have for the SSI retroactive period.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt="1rem" ml="2rem">
          <Grid item xs={2}>
            <FormControlLabel
              name="department"
              control={
                <Checkbox
                  {...formik.getFieldProps('department')}
                  checked={formik.values.department === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('department', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.department}
                />
              }
              // label={<Typography style={labelStyle}>Part A</Typography>}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name="depart"
              formik={formik}
              normalizers={[maxLength(50), onlyAlphabet]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              DEPARTMENT OF SOCIAL SERVICES OR
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              name="montogomery"
              control={
                <Checkbox
                  {...formik.getFieldProps('montogomery')}
                  checked={formik.values.montogomery === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('montogomery', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.montogomery}
                />
              }
              label={
                <Typography variant="subtitle1">
                  MONTOGOMERY COUNTY DEPARTMENT OF HEALTH AND HUMAN SERVICES
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name="initialPayment"
              control={
                <Checkbox
                  {...formik.getFieldProps('initialPayment')}
                  checked={formik.values.initialPayment === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('initialPayment', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.initialPayment}
                />
              }
              label={
                <Typography variant="subtitle1">
                  INITIAL PAYMENT ONLY
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name="eligiblePayment"
              control={
                <Checkbox
                  {...formik.getFieldProps('eligiblePayment')}
                  checked={formik.values.eligiblePayment === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      'eligiblePayment',
                      checked ? 'Y' : 'N'
                    );
                  }}
                  value={formik.values.eligiblePayment}
                />
              }
              label={
                <Typography variant="subtitle1">
                  POST-ELIGIBLE PAYMENT ONLY
                </Typography>
              }
            />
          </Grid>
          <Grid ml="1rem" mr="4rem" mt="2rem" mb="1rem" border="1px solid #555">
            <Grid container spacing={2} mt="1rem" ml="1rem" mr="2rem">
              <Grid item xs={2.5}>
                <Field
                  name="customerId"
                  label="Customer Id"
                  formik={formik}
                  normalizers={[maxLength(20)]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  name="auNumb"
                  label="AU Number"
                  formik={formik}
                  normalizers={[maxLength(30), number]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  name="category"
                  label="Category"
                  formik={formik}
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  name="ssn"
                  label="Social security Number"
                  formik={formik}
                >
                  <InputMask mask="(999)-999-9999" maskChar={null}>
                    {(params) => (
                      <OutlinedInput
                        variant="outlined"
                        placeholder="(000)-000-0000"
                        value={formik.values.number}
                        {...params}
                      />
                    )}
                  </InputMask>
                </Field>
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  name="district"
                  label="District/Territory"
                  formik={formik}
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  name="federalCode"
                  label="Federal Code"
                  formik={formik}
                  normalizers={[maxLength(10), number]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  name="countyFedCode"
                  label="County Federal Code"
                  formik={formik}
                  normalizers={[maxLength(20), number]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={10}>
                <Field
                  name="applicantName"
                  label="Applicant Name"
                  formik={formik}
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
              <Grid item xs={10} mb="1rem">
                <Field
                  name="address"
                  label="Address"
                  formik={formik}
                  normalizers={[maxLength(30)]}
                >
                  <TextField variant="standard" />
                </Field>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              I have read or have had read to me this form and I understand what
              it means. I agree to the content of this form.
            </Typography>
          </Grid>
          <Grid container spacing={2} ml="1rem" mt="1rem">
            <Grid item xs={5}>
              <Field
                name="signRecepient"
                label="Signature of Recepient"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="date" label="Date" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="signStateRecepient"
                label="Signature of State Representative"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="telephoneNum"
                formik={formik}
                label="Telephone Number"
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.telephoneNum}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>

            <Grid item xs={3}>
              <Field name="dateState" label="Date" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Field
                name="grCode"
                formik={formik}
                label="GR Code"
                normalizers={[maxLength(10), number]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                DHS/FIA 340 (Revised 02/2020)<br></br> DISTRIBUTION
                INSTRUCTIONS: White –Case Record Copy Yellow – Local Finance
                Office Pink - Customer
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt="2rem" ml="2rem">
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                How long is this authorization binding on the State and me if I
                checked the Initial Claim Only block?
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                This authorization is in effect for you and the state for twelve
                (12) months. The 12 months begin with the date the state
                notifies SSA through an electronic system that the state has
                received the authorization and it ends 12 months later. You and
                a state representative must sign and date the authorization for
                the authorization to be valid.
              </Typography>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                Exceptions apply to this rule.
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                The state must send SSA the authorization within a certain time
                frame. For a state using an electronic system, SSA must receive
                the authorization information within 30 calendar days of the
                state matching your SSI record with your state record. If the
                information sent by the state is not sent within the 30 calendar
                days, SSA will not accept the information. SSA will not pay any
                of your retroactive SSI benefits to the state. SSA will send you
                any SSI money that may be due you, based on SSA’s regular
                payment rules.
              </Typography>
            </Grid>
            <Grid item xs={10} mt="1rem">
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                Can the authorization stay effective longer than the 12-month
                period? Can the authorization end before or after the 12-month
                period ends?
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                The authorization can stay effective longer than the 12-month
                period, if you <br></br>
                apply for SSI benefits before the state has the authorization
                form, or<br></br> apply within the 12-month period the
                authorization is effective, or <br></br>file a valid appeal of
                SSA’s determination on your initial claim. The period of the
                authorization can end before the 12-month period ends, or end
                after the 12-month period ends when any of these actions take
                place: <br></br>SSA makes the first SSI payment on your initial
                claim; or<br></br>
                SSA makes a final determination on your claim; or<br></br> the
                state and you agree to terminate this authorization.<br></br>{' '}
                The authorization period will end with the day of the month any
                of these actions take place.
              </Typography>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                How long is this authorization effective for the state and you,
                if you checked the block called Post-eligibility Payment Only?
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                This authorization is in effect for you and the state for twelve
                (12) months. The 12 months begin with the date the state
                notifies SSA through an electronic system that the state has
                received the authorization and end 12 months later.
              </Typography>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                Exceptions apply to this rule.
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                The state must send SSA the authorization within a certain time
                frame. For a state using an electronic system, SSA must receive
                the authorization information within 30 calendar days of the
                state matching your SSI record with your state record. If the
                information sent by the state is not sent within the 30 calendar
                days, SSA will not accept the information. SSA will not pay any
                of your retroactive SSI benefits to the state. SSA will send you
                any SSI money that may be due you, based on SSA’s regular
                payment rules.
              </Typography>
            </Grid>
            <Grid item xs={10} mt="1rem">
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                Can the authorization stay effective longer than the 12-month
                period? Can the authorization end before or after the 12-month
                period ends?
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                The authorization can stay effective longer than the 12-month
                period, if you file a valid appeal. You must file your appeal
                within the time frames SSA requires.<br></br> The authorization
                can stay effective longer than the 12-month period, if you file
                a valid appeal. You must file your appeal within the time frames
                SSA<br></br>
                requires. SSA makes the first SSI payment on your
                post-eligibility case after a period of suspension or<br></br>
                termination; or<br></br> SSA makes a final determination on your
                claim; or the state and you agree to terminate this
                authorization
              </Typography>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                Does this authorization serve as a protective filing for SSI
                benefits?
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                Yes, if you checked the Initial Payment Block, signing this form
                serves as a signed statement of your intention to claim SSI
                benefits if you have not filed an SSI application as of the date
                this authorization is received by the State. Your eligibility
                for SSI benefits may begin as early as the date you sign this
                form if you file an application at a Social Security office for
                SSI benefits within 60 days after that date. This form also
                serves as a notice from SSA that you have sixty days from the
                date the State receives this form to file for SSI benefits.
                However, if you do not file an application for SSI benefits at a
                Social Security Office within 60 days after that date, then you
                understand that you cancel your intention to claim SSI benefits
                and this authorization no longer protects your filing date for
                SSI.
              </Typography>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                What rights and appeals are available to you under this
                authorization?
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="subtitle1">
                You can disagree with a decision the state made during the
                reimbursement process. You will receive a state notice telling
                you how to appeal the decision. You cannot appeal to SSA if you
                disagree with any state decision. Within 10 working days after
                the state receives the reimbursement money from SSA, the state
                must send you a notice. The notice will tell you three things:
                (1) the amount of the payments the state paid you (2) that SSA
                will send you a letter explaining how SSA will pay the remaining
                SSI money (if any) due you, and (3) about your right to a
                hearing with the state if you disagree , including how to
                request the hearing.
              </Typography>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h5">
                The State cannot be reimbursed for assistance it gave to you if
                that assistance was financed wholly or partly from Federal
                dollars.
              </Typography>
            </Grid>
            <Grid item xs={12} mt="2rem" mb="2rem">
              <Typography variant="body1">
                DHS/FIA 340 (Revised 02/2020)<br></br> Destroy all previous
                versions DISTRIBUTION INSTRUCTIONS: White –Case Record Copy
                Yellow – Local Finance Office Pink - Customer
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
