import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import styles from '../index.module.css';

export default function ProgramSelection({ data, getLkpMatch }) {
  return (
    <TableContainer>
      <Table
        sx={{
          td: {
            padding: 1.5,
            paddingBottom: '0.5rem',
            border: 0,
          },
        }}
      >
        <TableBody className={styles.tableBody}>
          {map(data, (item, index) => (
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  {index + 1}.{' '}
                  {getLkpMatch(
                    'ProgramCode',
                    item?.programSelected?.programName
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
