import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  groupBy,
  map,
  partition,
  sortBy,
  values,
  first,
  find,
  some,
} from 'lodash';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SmButton from './SmButton';
import { ReactComponent as BrandLogo } from '../../../assets/images/KConnectLogo.svg';
import { ReactComponent as BrandLogoShort } from '../../../assets/images/brand-logo-short.svg';
import styles from './index.module.css';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import usePage from '../../../hooks/usePage';
import usePageNav from '../../../hooks/usePageNav';
import Icon from '../../../components/Icon';
import cx from 'classnames';
import { selectNavLinks } from '../../../store/selectors/navigationSelector';
import { useSelector } from 'react-redux';
import navUrlsReference from './navUrlsReference.json';
import { selectCaseRules } from '../../../store/selectors/caseSelector';
import { useLocation } from 'react-router-dom';

function SideMenu() {
  const { caseId } = usePageNav();
  const location = useLocation();
  const caseRules = useSelector(selectCaseRules);
  const [hover, setHover] = useState(false);
  const navLinks = useSelector(selectNavLinks);
  const [openedNav, setOpenedNav] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const { expandSideMenu, setExpandSideMenu } = usePage();
  const { navigations } = usePageNav();
  const timeRef = useRef();

  const activeModule = useMemo(() => {
    const link = find(navLinks, ({ subNavs }) => {
      return some(subNavs, ({ nvgtn_id }) => {
        if (navUrlsReference[nvgtn_id]) {
          const { nav_url = '' } = navUrlsReference[nvgtn_id] ?? {};
          return location.pathname.replace(/\/\d+$/gm, '/:caseId') === nav_url;
        } else {
          return '';
        }
      });
    });
    return link?.nvgtn_id ?? '';
  }, [location.pathname, navLinks]);

  useEffect(() => {
    if (openedNav !== activeModule) {
      setOpenedNav(activeModule);
    }
  }, [activeModule]);

  const {
    hasFemaleMemebers = true,
    hasLtOrWvPgm = true,
    showRelationship = true,
  } = caseRules;

  const onExpand = () => {
    if (expandSideMenu) {
      setHover(false);
    }
    setExpandSideMenu(!expandSideMenu);
  };

  const handleHover = (isHover) => {
    if (isHover && timeRef.current) {
      timeRef.current = clearTimeout(timeRef.current);
      setHover(isHover);
    } else {
      timeRef.current = setTimeout(() => setHover(isHover), 1000);
    }
  };

  const navItems = [
    { text: 'Application', to: '', icon: <AppRegistrationIcon /> },
    { text: 'Contact Information', to: '', icon: <PermContactCalendarIcon /> },
    { text: 'App Disposition', to: '', icon: <AccountBalanceIcon /> },
    { text: 'Case Home', to: '', icon: <AssignmentIcon /> },
    { text: 'Member Details', to: '', icon: <PeopleAltIcon /> },
    { text: 'Relationship', to: '', icon: <Diversity3Icon /> },
    { text: 'Citizenship', to: '', icon: <DescriptionIcon /> },
    { text: 'Summary', to: '', icon: <SummarizeIcon /> },
    ...(caseId
      ? [
          {
            text: 'Correspondence',
            to: `/dashboard/caseInquiry/correspondenceSearch/${caseId}`,
            icon: <PeopleIcon />,
          },
        ]
      : []),
  ];

  return (
    <div
      onMouseOver={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      className={cx(styles.sideMenu, {
        [styles.expanded]: expandSideMenu || hover,
      })}
    >
      <button className={styles.actionBtn} onClick={onExpand}>
        {/* <IconButton> */}
        <ChevronRightIcon
          style={{
            transition: '0.5s transform',
            transform: expandSideMenu ? 'rotate(180deg)' : '',
          }}
        />
        {/* </IconButton> */}
      </button>
      <div className={styles.logo} onClick={() => navigations.toDashboard()}>
        {expandSideMenu || hover ? (
          <BrandLogo width={150} />
        ) : (
          <BrandLogoShort />
        )}
      </div>
      <div className={styles.dashboardBtn}>
        <SmButton
          expanded={expandSideMenu || hover}
          title="Dashboard"
          icon={<Icon width="30px" height="30px" name="NIDashboardNavIcon" />}
          to="dashboard"
          home
        />
      </div>
      <div className={styles.navWrapper}>
        <div className={styles.navLinks}>
          {map(navLinks, (nav) => {
            const hasSubNav = nav.subNavs.length > 0;
            const {
              tag_txt,
              nvgtn_id,
              // nav_icon = 'NICaseReview',
              nav_url,
              screen_id,
            } = nav;
            const nav_icon =
              navUrlsReference[nvgtn_id]?.nav_icon ?? 'NICaseReview';
            const isOpened = nav.nvgtn_id === openedNav;
            return (
              <div>
                {
                  <SmButton
                    id={nvgtn_id}
                    expanded={expandSideMenu || hover}
                    title={tag_txt}
                    icon={<Icon name={nav_icon} />}
                    to={
                      navUrlsReference[nvgtn_id]?.nav_url?.replace(
                        ':caseId',
                        caseId
                      ) ?? nav_url
                    }
                    collapsable={hasSubNav}
                    collapsed={isOpened}
                    nvgtn_id={screen_id}
                    onClick={() => setOpenedNav(isOpened ? 0 : nav.nvgtn_id)}
                    // onClick={setCollapsed()}
                  />
                }
                {hasSubNav && (expandSideMenu || hover) && (
                  <Box sx={{ paddingLeft: '1.5rem' }}>
                    {isOpened && (
                      <Divider
                        sx={{ borderColor: 'var(--grey-200)', mb: '0.5rem' }}
                      />
                    )}
                    <Collapse in={isOpened}>
                      {map(nav.subNavs, (snav) => {
                        const {
                          tag_txt,
                          nav_icon,
                          nvgtn_id,
                          nav_url,
                          screen_id,
                        } = snav;

                        if (nvgtn_id === 231 && !showRelationship) {
                          return null;
                        }

                        if (nvgtn_id === 119 && !hasFemaleMemebers) {
                          return null;
                        }

                        if (nvgtn_id === 120 && !hasLtOrWvPgm) {
                          return null;
                        }

                        return (
                          <SmButton
                            expanded={expandSideMenu || hover}
                            title={tag_txt}
                            collapsable={false}
                            id={screen_id}
                            icon={<Icon name={nav_icon} />}
                            to={
                              navUrlsReference[nvgtn_id]?.nav_url?.replace(
                                ':caseId',
                                caseId
                              ) ?? nav_url
                            }
                          />
                        );
                      })}
                    </Collapse>
                  </Box>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* {map(navItems, ({ text, icon, to }) => (
          <SmButton
            expanded={expandSideMenu}
            title={text}
            icon={icon}
            to={to}
          />
        ))} */}
    </div>
  );
}

export default SideMenu;
