import React, { useMemo } from 'react';
import FormSection from '../../../components/FormSection';
import Dropdown from '../../../components/Dropdown';
import {
  Grid,
  Box,
  TextField,
  TextareaAutosize,
  Stack,
  Button,
} from '@mui/material';
import * as Yup from 'yup';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import { find, map } from 'lodash';
import { useSelector } from 'react-redux';
import usePageNav from '../../../hooks/usePageNav';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import { getFullName } from '../../../utils/helpers';

function AddCase({
  toggleComments,
  postNarrative,
  editCase,
  handleClose,
  disableFields,
  createCase,
  narrativeId,
}) {
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const hohMember = find(houseMembers, { headOfHouseholdYN: 'Y' });
  const { caseId, navigations } = usePageNav();
  const memberOpts = useMemo(() => {
    const options = map(houseMembers, ({ personName, householdMemberId }) => {
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      return {
        label: fullName,
        // value: { householdMemberId, fullName },
        value: householdMemberId,
      };
    });
    return [...options, { label: 'All', value: 'All' }];
  }, [houseMembers]);

  const validationSchema = Yup.object({
    householdMemberId: Yup.string().required(
      "Select the HouseHold Member"
    ),
    title: Yup.string().required('Please Enter Title'),
    comments: Yup.string().required('Please Enter Comments').max(20000),
  });

  const formik = useFormik({
    initialValues: {
      dirtyStatus: createCase ? 'insert' : 'update',
      householdMemberId: editCase?.householdMemberId ? 'All' : '',
      title: editCase?.title ?? '',
      comments: editCase?.narrative ?? '',
      narrativeId: editCase?.narrativeId ?? null,
    },
    validationSchema,
    onSubmit: (values) => {
      const userName = find(memberOpts, {
        value: values?.householdMemberId,
      })?.label;
      postNarrative({ ...values, member: userName });
    },
  });

  return (
    <>
      <FormSection>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              label="Household Member"
              formik={formik}
              name="householdMemberId"
              required
            >
              <Dropdown
                options={memberOpts}
                placeholder="Select Member"
                disabled={disableFields}
              />
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field label="Title" formik={formik} name="title" required>
              <TextField placeholder="Enter Title" disabled={disableFields} />
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field label="Comments" formik={formik} name="comments" required>
              <TextareaAutosize
                style={{ outline: 'none' }}
                minRows={5}
                placeholder="Enter Text Here"
                disabled={disableFields}
              />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={formik.handleSubmit}
          disabled={disableFields}
        >
          Save
        </Button>
      </Stack>
    </>
  );
}

export default AddCase;
