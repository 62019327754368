import React from 'react';
import PropTypes from 'prop-types';
import { includes, map } from 'lodash';
import FormSection from '../../../../../components/FormSection';
import TextContent from '../../../../../components/TextContent';
import { Grid, Stack, Typography } from '@mui/material';
import TextContentGroup from '../../../../../components/TextContentGroup';

function FinancialElibility({
  aidCd,
  programName,
  netBenefitAmount,
  financialInfo,
}) {
  const {
    totalAssetAmount,
    totalAssets,
    protectedAmount,
    netAssetStdAmount,
    assetLimit,
    totalGrossIncome,
    totalNetIncome,
    netIncStdAmount,
    incomeLimit,
    totalGrossEarnedIncome,
    grossEarnedIncome,
    totalNetEarnedIncome,
    netEarnedIncome,
    totalGrossUnEarnedIncome,
    fplPctLmt,
    grossIncomeLimit,
    grossUnearnedIncome,
    totalNetUnEarnedIncome,
    netUnearnedIncome,
    earnedIncomeDeduction,
    adjustedNetIncome,
    spouseTotalGrossIncome,
    spouseTotalNetIncome,
    fullGrantAmt,
    excessIncomeAmt, // Income end
    earnedIncomeDisregard,
    unearnedIncomeDisregard,
    totalDeductions, // Disregard end
    totalPersonalNeedsAllowance,
    personalNeedsAllowance,
    totalGuardianAllowance,
    totalSupportAllowance,
    totalFamilyAllowance,
    disallowanceAmt,
    totalSpousalAllowance,
    otoMedicalBonusAmt,
    totalDependentAllowance,
    totalResidentialMaintenanceAllowance,
    totalRoomAndBoardAllowance,
    netIncomeLimit,
    paymentStandard,
    childSupportIVAmt,
    childSupportPassThroughAmt, // Allowance end
    monthlyPremiumAmount, //  Premium amount start-end Deductions/ Expenses
    totalEarnedIncomeExpenses,
    shelterDeduction,
    totalMedicalAllowance,
    medicalDeduction,
    dependentCareDeduction,
    utilityAllowanceType,
    standardDeduction,
    childSpousalSupportDeduction,
    shelterExpenses,
    childSpousalSupportExpenses,
    utilityExpenses,
    dependentCareExpenses,
    totalMedicalExpenses,
    medicalExpenses,
    totalFarmLoss, // Deductions/ Expenses end
    spendDownAmount,
    netSpenddownAmount, // Spend Down Amount end
    costOfCare,
    benefitAmt,
    maximumAllotmentAmt,
    actualBenefitAmt,
    fedSuppAmt,
    cashFullBenefitAmount,
    minimumSupplementAmt,
    fsEmergencyAllotment,
    // netBenefitAmount,
    pandemicGrantAmt,
    nonPandemicMaxAllotmentAmt, // Benefit Amount end
    costOfCareContribution, //  Cost of Care start-end #2363
  } = financialInfo ?? {};

  const isProgramOf = (...programs) => {
    return includes(programs, programName);
  };

  const textContents = [
    {
      label: 'Assets',
      showIf: isProgramOf('FS'),
      fields: [
        {
          title: 'Net Assets',
          subTitle: isProgramOf('CH') ? totalAssets : totalAssetAmount,
          type: 'currency',
        },
        {
          title: 'Protected Amount',
          subTitle: protectedAmount,
          type: 'currency',
        },
        {
          title: 'Asset Limit',
          subTitle: netAssetStdAmount,
          type: 'currency',
        },
      ],
    },
    {
      label: 'Income',
      fields: [
        {
          title: 'Gross Income',
          subTitle: totalGrossIncome,
          type: 'currency',
          showIf: isProgramOf('MA'),
        },
        {
          title: 'Adjusted Net Income',
          subTitle: totalNetIncome,
          type: 'currency',
        },
        {
          title: 'Income Limit',
          subTitle: isProgramOf('MA', 'LT', 'WV')
            ? netIncStdAmount
            : incomeLimit,
          type: 'currency',
        },
        {
          title: 'Gross Earned Income',
          subTitle: isProgramOf('MA', 'LT', 'WV')
            ? totalGrossEarnedIncome
            : grossEarnedIncome,
          type: 'currency',
        },
        {
          title: 'Net Earned Income',
          subTitle: isProgramOf('MA', 'LT', 'WV')
            ? totalNetEarnedIncome
            : netEarnedIncome,
          type: 'currency',
        },
        {
          title: 'Gross Unearned Income',
          subTitle: totalGrossUnEarnedIncome,
          type: 'currency',
          showIf: isProgramOf('MA', 'LT', 'WV'),
        },
        {
          title: '200%FPL',
          subTitle: incomeLimit,
          type: 'currency',
          showIf: isProgramOf('FS'),
        },
        {
          title: '130%FPL',
          subTitle: grossIncomeLimit,
          type: 'currency',
          showIf: isProgramOf('FS'),
        },
        {
          title: 'Gross Unearned Income',
          subTitle: grossUnearnedIncome,
          type: 'currency',
          showIf: isProgramOf('FS', 'EF', 'CH'), // ? duplicate names #1158
        },

        {
          title: 'Net Unearned Income',
          subTitle: isProgramOf('MA', 'LT', 'WV')
            ? totalNetUnEarnedIncome
            : netUnearnedIncome,
          type: 'currency',
        },
        {
          title: 'Earned Income Deduction',
          subTitle: earnedIncomeDeduction,
          type: 'currency',
          showIf: isProgramOf('FS', 'CH'),
        },
        {
          title: '30% Adjusted Net Income',
          subTitle: adjustedNetIncome,
          type: 'currency',
          showIf: isProgramOf('FS'),
        },
        {
          title: 'Spouse Total Gross Income',
          subTitle: spouseTotalGrossIncome,
          type: 'currency',
          showIf: isProgramOf('CH'),
        },
        {
          title: 'Deemed Income',
          subTitle: spouseTotalNetIncome,
          type: 'currency',
          showIf: isProgramOf('CH'),
        },
        {
          title: 'Full Grant Amount',
          subTitle: fullGrantAmt,
          type: 'currency',

          type: 'currency',
          showIf: isProgramOf('CH', 'FS'),
        },
        {
          title: 'Excess Amount',
          subTitle: excessIncomeAmt,
          type: 'currency',
          showIf: isProgramOf('MA', 'LT', 'WV'),
        },
      ],
    },
    {
      label: 'Disregard',
      showIf: isProgramOf('MA', 'LT', 'WV'),
      fields: [
        {
          title: 'Earned Income Disregard',
          subTitle: earnedIncomeDisregard,
          type: 'currency',
        },
        {
          title: 'Unearned Income Disregard',
          subTitle: unearnedIncomeDisregard,
          type: 'currency',
        },
        {
          title: 'Total Deduction',
          subTitle: totalDeductions,
          type: 'currency',
        },
      ],
    },
    {
      label: 'Allowance',
      fields: [
        {
          title: 'Personal Need Allowance',
          subTitle: totalPersonalNeedsAllowance,
          type: 'currency',
          showIf: isProgramOf('CH')
            ? personalNeedsAllowance
            : totalPersonalNeedsAllowance,
        },
        {
          title: 'Guardian Allowance',
          subTitle: totalGuardianAllowance,
          type: 'currency',
        },
        {
          title: 'Support Allowance',
          subTitle: totalSupportAllowance,
          type: 'currency',
        },
        {
          title: 'Family Allowance',
          subTitle: totalFamilyAllowance,
          type: 'currency',
        },
        {
          title: 'AFDC Disallowance',
          subTitle: disallowanceAmt,
          type: 'currency',
          showIf: isProgramOf('CH'),
        },
        {
          title: 'Spousal Allowance',
          subTitle: totalSpousalAllowance,
          type: 'currency',
        },
        {
          title: 'Oto Medical Bonus Amount',
          subTitle: otoMedicalBonusAmt,
          type: 'currency',
          showIf: isProgramOf('CH'),
        },
        {
          title: 'Dependent Allowance',
          subTitle: totalDependentAllowance,
          type: 'currency',
        },
        {
          title: 'Residential Maintenance Allowance',
          subTitle: totalResidentialMaintenanceAllowance,
          type: 'currency',
        },
        {
          title: 'Roomer and Boarder Allowance',
          subTitle: totalRoomAndBoardAllowance,
          type: 'currency',
        },
        {
          title: 'Basic Need Allowance',
          subTitle: totalRoomAndBoardAllowance,
          type: 'currency',
          showIf: isProgramOf('CH'),
        },
        {
          title: 'Payment Standard',
          subTitle: paymentStandard,
          type: 'currency',
          showIf: isProgramOf('CH'),
        },
        {
          title: 'Child Support IV-D',
          subTitle: childSupportIVAmt,
          type: 'currency',
          showIf: isProgramOf('CH', 'FS'),
        },
        {
          title: 'Child Pass thru Amount',
          subTitle: childSupportPassThroughAmt,
          type: 'currency',
          showIf: isProgramOf('CH'),
        },
      ],
    },
    {
      label: 'Premium Amount',
      showIf: aidCd === 'S13' || aidCd === 'S16',
      fields: [
        {
          title: 'Premium Amount',
          subTitle: monthlyPremiumAmount,
          type: 'currency',
        },
      ],
    },
    {
      label: 'Deductions/ Expenses',
      hide: isProgramOf('MA', 'LT', 'WV'),
      fields: [
        {
          title: 'Earned Income Expense',
          subTitle: totalEarnedIncomeExpenses,
          type: 'currency',
          showIf: isProgramOf('MA', 'LT', 'WV'),
        },
        {
          title: 'Shelter Deductions',
          subTitle: shelterDeduction,
          type: 'currency',
        },
        {
          title: 'Medical Deductions',
          subTitle: isProgramOf('FS')
            ? medicalDeduction
            : totalMedicalAllowance,
          type: 'currency',
        },
        {
          title: 'Dependent Care Deductions',
          subTitle: dependentCareDeduction,
          type: 'currency',
          showIf: isProgramOf('FS', 'CH'),
        },
        {
          title: 'Utility Allowance Type',
          subTitle: utilityAllowanceType ?? 'N/A',
          type: 'currency',
        },
        {
          title: 'Standard Deductions',
          subTitle: standardDeduction,
          type: 'currency',
        },
        {
          title: 'Child Spousal Support Deduction',
          subTitle: childSpousalSupportDeduction,
          type: 'currency',
          showIf: childSpousalSupportDeduction,
        },
        {
          title: 'Shelter Expense',
          subTitle: shelterExpenses,
          type: 'currency',
        },
        {
          title: 'Child Spousal Support Expenses',
          subTitle: childSpousalSupportExpenses,
          type: 'currency',
          showIf: childSpousalSupportExpenses,
        },
        {
          title: 'Utility Expense',
          subTitle: utilityExpenses,
          type: 'currency',
        },
        {
          title: 'Dependent Care Expense',
          subTitle: dependentCareExpenses,
          type: 'currency',
        },
        {
          title: 'Medical Expense',
          subTitle: totalMedicalExpenses,
          type: 'currency',
          showIf: isProgramOf('MA', 'LT', 'WV'),
        },
        {
          title: 'Medical Expense',
          subTitle: medicalExpenses,
          type: 'currency',
          showIf: isProgramOf('FS'),
        },
        {
          title: 'Total Farm Loss',
          subTitle: totalFarmLoss,
          type: 'currency',
        },
      ],
    },
    {
      label: 'Spend Down Amount',
      showIf:
        aidCd === 'L99' ||
        aidCd === 'T99' ||
        aidCd === 'F99' ||
        aidCd === 'S03' ||
        aidCd === 'S99' ||
        aidCd === 'G99' ||
        aidCd === 'S06' ||
        aidCd === 'S07' ||
        aidCd === 'S14' ||
        aidCd === 'S98',
      fields: [
        {
          title: 'Spenddown Amount',
          subTitle: spendDownAmount,
          type: 'currency',
        },
        {
          title: 'Medical Expense Amount',
          subTitle: totalMedicalExpenses,
          type: 'currency',
        },
        {
          title: 'Net Spenddown Amount',
          subTitle: netSpenddownAmount,
          type: 'currency',
        },
      ],
    },
    {
      label: 'Benefit Amount',
      hide: isProgramOf('MA', 'LT', 'WV'),
      fields: [
        {
          title: 'Grant Amount',
          subTitle: costOfCare,
          type: 'currency',
          showIf: isProgramOf('MA', 'LT', 'WV'), // ? not possible # 2134
        },
        {
          title: 'Grant Amount',
          subTitle: benefitAmt,
          type: 'currency',
          hide: isProgramOf('MA', 'LT', 'WV'), // ? not possible # 2134
        },
        {
          title: 'Thrifty Food Plan',
          subTitle: maximumAllotmentAmt,
          type: 'currency',
          showIf: isProgramOf('FS', 'EF'),
        },
        {
          title: 'Actual Benefit Amount',
          subTitle: actualBenefitAmt,
          type: 'currency',
          showIf: isProgramOf('FS', 'EF'),
        },
        {
          title: 'Federal Supplement',
          subTitle: fedSuppAmt,
          type: 'currency',
          showIf: isProgramOf('FS', 'EF'),
        },
        {
          title: 'Cash Full Benefit Amount',
          subTitle: cashFullBenefitAmount,
          type: 'currency',
          showIf: isProgramOf('FS'),
        },
        {
          title: 'Minimum Supplement',
          subTitle: minimumSupplementAmt,
          type: 'currency',
          showIf: isProgramOf('FS'),
        },
        {
          title: 'Enhanced Additional Amount',
          subTitle: fsEmergencyAllotment,
          type: 'currency',
          showIf: isProgramOf('FS', 'EF'),
        },
        {
          title: 'Benefit Amount',
          subTitle: netBenefitAmount,
          type: 'currency',
        },
        {
          title: '15% Fed Supp Amt',
          subTitle: pandemicGrantAmt,
          type: 'currency',
          showIf: isProgramOf('FS', 'EF'),
        },
        {
          title: 'Actual Max Supp Amt',
          subTitle: nonPandemicMaxAllotmentAmt,
          type: 'currency',
          showIf: isProgramOf('FS', 'EF'),
        },
      ],
    },
    {
      label: 'Cost of Care Contribution',
      showIf: isProgramOf('MA', 'LT', 'WV') && costOfCareContribution,
      fields: [
        {
          title: 'Cost of Care Contribution',
          subTitle: costOfCareContribution,
          type: 'currency',
        },
      ],
    },
  ];
  return <TextContentGroup textContents={textContents} />;
}

FinancialElibility.propTypes = {};

export default FinancialElibility;
