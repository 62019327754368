import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  SAVE_HOUSEHOLD_DECLARATION,
  GET_HOUSEHOLD_DECLARATION,
} from './apiUrls';

export const saveHouseholdDeclrationReq = (caseId, data) =>
  HttpClient.post(format(SAVE_HOUSEHOLD_DECLARATION, caseId), data);

export const getHouseholdDeclartion = (caseId) =>
  HttpClient.get(format(GET_HOUSEHOLD_DECLARATION, caseId));
