import { Button, Stack, Typography, IconButton } from '@mui/material';
import { Grid } from '@mui/material';
import React from 'react';
import { some, map } from 'lodash';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Dropdown from '../../components/Dropdown';
import FormGroup from '../../components/FormGroup';
import { ReactComponent as MyIcon } from '../../assets/icons/close-icon.svg';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Field from '../../components/Field';
import { toast } from 'react-toastify';
import { useMemo } from 'react';

function AddLivingArrangement({
  open,
  handleClose,
  livingArrOpts,
  livingArrVerificationOpts,
  onSavelivingArr,
  fosterAdoptionOpts,
  addLiving,
  editLiving,
  editData,
  livingArrangements,
  state,
}) {
  const validationSchema = Yup.object({
    beginDate: Yup.string().required('Please select the begin date'),
    programDetails: Yup.string().required('Please select the program'),
    verification: Yup.string().required('Please select the verification'),
    vermontResident: Yup.string().required('Please select the Resident'),
  });

  const formik = useFormik({
    initialValues: {
      recordId: editData?.recordId ?? '',
      endDate: editData?.effectiveEndDate ?? '',
      beginDate: editData?.effectiveBeginDate ?? '',
      programDetails: editData?.livingArrangement ?? '',
      verification: editData?.verificationSourceCode ?? '',
      adoptionType: editData?.fosterAdoptTypeCd ?? '',
      vermontResident: editData?.isMDResidentYN ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      const isDatesAreInValid = some(
        livingArrangements,
        ({ effectiveBeginDate, effectiveEndDate, recordId }) => {
          return (
            dateLiesBetween(
              effectiveBeginDate,
              effectiveEndDate,
              formik.values.beginDate,
              formik.values.recordId,
              recordId
            ) ||
            dateLiesBetween(
              effectiveBeginDate,
              effectiveEndDate,
              formik.values.endDate,
              formik.values.recordId,
              recordId
            )
          );
        }
      );
      if (isDatesAreInValid) {
        toast.error(
          'Dates cant be in between the Already Existing date period. Please Select Different Dates.'
        );
      } else {
        if (addLiving) {
          onSavelivingArr(values, 'insert');
        } else {
          onSavelivingArr(values, 'update');
        }
      }
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const onDateChange = (date, prop) => {
    formik.setFieldValue(prop, dayjs(date).format('YYYY-MM-DD'));
  };

  function dateLiesBetween(
    startDate,
    endDate,
    date,
    selectRecordId,
    existingRecordId
  ) {
    if (!date) {
      return false;
    }
    if (selectRecordId === existingRecordId) {
      return false;
    }
    return dayjs(date).isBetween(startDate, endDate, 'day', '[]');
  }

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">
          {addLiving ? 'Add Living Arrangements' : 'Edit Living Arrangements'}
        </Typography>
        <IconButton onClick={handleClose}>
          <MyIcon />
        </IconButton>
      </Stack>

      <Grid>
        <FormGroup label="Living Arrangement">
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field
                required
                name="beginDate"
                label="Begin Date"
                formikHandler={false}
                isDate
                formik={formik}
              >
                <DatePicker
                  disableFuture
                  required
                  value={formik.values.beginDate}
                  onChange={(date) => onDateChange(date, 'beginDate')}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="endDate"
                label="End Date"
                isDate
                formik={formik}
                formikHandler={false}
              >
                <DatePicker
                  disableFuture
                  minDate={dayjs(formik.values.beginDate) || dayjs()}
                  onChange={(date) => onDateChange(date, 'endDate')}
                  value={formik.values.endDate}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                name="programDetails"
                label="Living Arrangement"
                formik={formik}
              >
                <Dropdown
                  options={livingArrOpts}
                  placeholder="Select Living Arrangement"
                />
              </Field>
            </Grid>
            <Grid item xs={6} sx={{ mb: '1rem' }}>
              <Field
                name="verification"
                label="Verification"
                formik={formik}
                required
              >
                <Dropdown
                  options={livingArrVerificationOpts}
                  placeholder="Select Verification"
                />
              </Field>
            </Grid>
          </Grid>
        </FormGroup>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <FormGroup label="Residency">
              <Field
                name="vermontResident"
                label={`Are You a ${state} Resident`}
                formik={formik}
                required
              >
                <RadioGroup
                  {...formik.getFieldProps('vermontResident')}
                  row
                  defaultValue={''}
                >
                  <FormControlLabel
                    name="vermontResident"
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    name="vermontResident"
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <FormGroup label="Foster Adoption">
              <Field
                name="adoptionType"
                label="Foster/Adoption Type"
                formik={formik}
              >
                <Dropdown
                  options={fosterAdoptionOpts}
                  placeholder="Select Type"
                />
              </Field>
            </FormGroup>
          </Grid>
          <Grid item xs={6} sx={{ mb: '1rem' }}></Grid>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{ marginTop: '1.5rem', gap: '0.9rem' }}
      >
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save & Close
        </Button>
      </Stack>
    </Grid>
  );
}

export default AddLivingArrangement;
