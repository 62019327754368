import { Box, Typography } from '@mui/material';
import React from 'react';
import useTabs from '../../../hooks/useTabs';
import { map } from 'lodash';
import CashAssistance from './CashAssistance';
import MedicalAssistance from './MedicalAssistance';
import SNAP from './Snap';
import DSNAP from './DSNAP';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const EligibilityParameter = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <div>
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '1.5rem',
          fontWeight: '700',
          textAlign: 'left',
          color: 'var(--black-900)',
          paddingBottom: '20px',
        }}
      >
        Eligibility Parameters
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          '& .Mui-selected': {
            color: 'black !important',
            fontSize: '1rem',
            fontWeight: 700,
          },
          color: 'var(--grey-400)',
          fontSize: '1rem',
          fontWeight: '600',
        }}
      >
        <Tab
          label="Cash Assistance"
          sx={{ textTransform: 'capitalize', fontSize: '1rem !important' }}
        />
        <Tab
          label="Medical Assistance"
          sx={{ textTransform: 'capitalize', fontSize: '1rem !important' }}
        />
        <Tab label="SNAP" sx={{ fontSize: '1rem !important' }} />
        <Tab label="DSNAP" sx={{ fontSize: '1rem !important' }} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <CashAssistance />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <MedicalAssistance />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SNAP />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <DSNAP />
      </CustomTabPanel>
    </div>
  );
};

export default EligibilityParameter;
