import { DataGrid } from '@mui/x-data-grid';
import { useState, useMemo } from 'react';
import { Box, IconButton, Stack, Typography, Button } from '@mui/material';
import useLookup from '../../../hooks/useLookup';
import EditIcon from '@mui/icons-material/Edit';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/time-limit.svg';

const ProgramTable = ({ tabelDatas, HandleEvents, title, counter }) => {
  const { getLkpMatch, getOptions } = useLookup('programTimeLimit');
  const columnData = [
    { headerName: 'Begin Date', field: 'beginDate', flex: 1 },
    { headerName: 'End Date', field: 'endDate', flex: 1 },
    { headerName: 'Time Limit Type', field: 'timeLimitType', flex: 1 },

    { headerName: 'Status', field: 'status', flex: 1 },
    { headerName: 'Counter', field: 'counter', flex: 1 },
    {
      headerName: 'Actions',
      field: 'actions',
      flex: 1,
      renderCell(params) {
        return (
          <span>
            <IconButton onClick={() => HandleEvents('Update', params.id)}>
              <EditIcon />
            </IconButton>
          </span>
        );
      },
    },
  ];

  const rowData = useMemo(() => {
    if (tabelDatas) {
      let statusOption = '';
      switch (title) {
        case 'ABAWD': {
          statusOption = 'abwdtkstscd';
          break;
        }
        case 'Child Under One':
        case 'Time On Assistance': {
          statusOption = 'tcatkstscd';
          break;
        }
        default: {
          statusOption = '';
        }
      }
      return tabelDatas.map((tObj) => {
        return {
          id: tObj.indTmClkId,
          beginDate: tObj.clkStsBgnDt,
          endDate: tObj.clkStsEndDt,
          timeLimitType: getLkpMatch('ClockTypeCd', tObj.clkTypCd),
          status: getLkpMatch(statusOption, tObj.clkStsCd),
          counter: tObj.count,
        };
      });
    }
  }, [tabelDatas]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          mb: '1rem',
        }}
      >
        <Typography variant="h6"></Typography>

        <Button
          onClick={() => {
            HandleEvents('Insert', '');
          }}
        >
          + Add Extension
        </Button>
      </Stack>
      <Box sx={{ display: 'grid' }}>
        <DataGrid
          sx={{
            marginTop: 2,
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: 16,
              fontWeight: 700,
              textAlign: 'center',
              color: 'black',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '30vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '30vh',
            },
            minHeight: '30vh',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                description="Please Add Extension"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          hideFooter
          rows={tabelDatas ? rowData : []}
          columns={columnData ?? []}
        ></DataGrid>
      </Box>
    </Box>
  );
};
export default ProgramTable;
