import dayjs from 'dayjs';
import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { DELETE_NOTE, FETCH_NOTE, SAVE_NOTE, UPDATE_NOTE } from './apiUrls';

export const saveNote = (data) => {
  const payload = {
    ...data,
    statusDt: dayjs().format('YYYY-MM-DD'),
    // statusDt: new Date().toISOString(),
  };
  return HttpClient.post(SAVE_NOTE, payload);
};

export const editNote = (data) => {
  const payload = {
    workNotes: data.workNotes,
    title: data.title,
    uniqueId: data.uniqueId,
  };

  return HttpClient.put(format(UPDATE_NOTE, data.workerNotesId), payload);
};

export const fetchNotes = (uniqueId) => {
  return HttpClient.get(format(FETCH_NOTE, uniqueId));
};

export const deleteNote = (id) => {
  return HttpClient.post(format(DELETE_NOTE, id));
};
