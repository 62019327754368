import React, { useEffect, useState } from 'react';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import { useSelector } from 'react-redux';
import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import useLookup from '../../hooks/useLookup';
import AuthorizedRep from './AuthRep';
import { getAgHeadMembers } from '../../utils/services/authorizedRepServices/requests';
import usePageNav from '../../hooks/usePageNav';
import { getFullName } from '../../utils/helpers';
import useLoader from '../../hooks/useLoader';
import { getAuthRepDetails } from '../../utils/services/authorizedRepServices/requests';
import { isEmpty } from 'lodash';
import usePage from '../../hooks/usePage';
import { Button, Stack } from '@mui/material';

function AuthRep({ id, newCase }) {
  const { navProps } = usePage(id);
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const [agHeadMembers, setAgHeadMembers] = useState([]);
  const [agHeadList, setagHeadList] = useState([]);
  const { caseId } = usePageNav();
  const { getOptions, getLkpMatch, lkpRaw, lkpFlatten } =
    useLookup('AuthorizedRep');
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [apiError, setApiError] = useState(false);
  const [fetchingAuthRepDetails, setFetchingAuthRepDetails] = useState(false);
  useEffect(() => {
    // getAgHead();
    authRepDetails();
  }, [caseId]);

  const onNext = () => {
    navigations.toProgramRequest();
  };

  const onBack = () => {
    // navigations.toCitizenship();
    navigations.toAuthorizedRep();
  };

  const getAgHead = async () => {
    try {
      const res = await getAgHeadMembers(caseId);
      if (res.status === 200) {
        setAgHeadMembers(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const authRepDetails = async () => {
    try {
      if (apiError) {
        setApiError(false);
      }
      setFetchingAuthRepDetails(true);
      const res = await getAuthRepDetails(caseId);
      if (res.status === 200) {
        setagHeadList(res.data);
        if (isEmpty(res.data)) {
          setApiError(true);
        }
      }
    } catch (error) {
      // console.log(error);
      setApiError(true);
    } finally {
      setFetchingAuthRepDetails(false);
    }
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Authorized Representative"
      onNext={onNext}
      onBack={onBack}
      showNavButton={!newCase}
      showNavigations={!newCase}
    >
      <SelectableView
        noData={apiError}
        tryAgain={authRepDetails}
        loading={fetchingAuthRepDetails}
        data={agHeadList}
        getId={(params) => params.agHeadId}
        getName={(params) => {
          const { agHeadName } = params;
          return agHeadName;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        showEdit={false}
        showDelete={false}
        showAdd={false}
      >
        <AuthorizedRep
          getOptions={getOptions}
          getLkpMatch={getLkpMatch}
          lkpRaw={lkpRaw}
          authRepDetails={authRepDetails}
          agHeadList={agHeadList}
        />
      </SelectableView>
      {newCase && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: '1rem' }}
        >
          <Button variant="outlined" onClick={() => navigations.toContact()}>
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => navigations.toAppDispose()}
          >
            Next
          </Button>
        </Stack>
      )}
    </PageContainer>
  );
}

export default AuthRep;
