import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { LinearProgress } from '@mui/material';
function CaseCommentsTable({ rows, columns, getRowId }) {
  return (
    <div>
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={getRowId}
        // getRowId={(row) => {
        //   return row.narrativeId;
        // }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '14px',
            fontWeight: '700',
            padding: '0px 10px',
            color: '#000000',
          },
          '& .MuiDataGrid-row': {
            fontSize: '16px',
            color: 'black',
            padding: '0px 10px',
            textAlign: 'center',
          },
        }}
        slots={{
          noRowsOverlay: 'noData',
          loadingOverlay: LinearProgress,
        }}
      />
    </div>
  );
}

export default CaseCommentsTable;
