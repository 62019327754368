import React, { useState, useRef } from 'react';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AvatarIcon from '../../../assets/images/profile-pic.png';
import styles from './index.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken } from '../../../store/slices/authSlice';
import usePage from '../../../hooks/usePage';
import cx from 'classnames';
import { userDataResult } from '../../../store/selectors/userDeteailSelector';
import { getAuthToken } from '../../../store/selectors/authSelector';

const menuItems = ['Profile', 'Account', 'Dashboard'];

function NavBar() {
  const dispatch = useDispatch();
  const { expandSideMenu, setExpandSideMenu } = usePage();
  const userData = useSelector(userDataResult);
  const loginDetials = useSelector(getAuthToken);
  const [showProfileMenu, toggleProfileMenu] = useState(false);
  const btnRef = useRef();

  const handleProfileMenu = () => {
    toggleProfileMenu(!showProfileMenu);
  };

  const onLogout = () => {
    dispatch(setAuthToken(''));
  };

  return (
    <div
      className={cx(styles.navBar, {
        [styles.offsetLeft]: expandSideMenu,
      })}
    >
      <Typography variant="subtitle2">
        Integrated Eligibility & Enrollment
      </Typography>
      <div className={styles.rightSection}>
        <IconButton
          className="search-button"
          size="small"
          aria-label="search"
          color="inherit"
        >
          <SearchIcon fontSize="small" />
        </IconButton>

        <IconButton
          className="notifications-button"
          size="small"
          aria-label="notifications"
          color="inherit"
        >
          <NotificationsIcon fontSize="small" />
        </IconButton>

        <Button
          ref={btnRef}
          className="user-button"
          size="small"
          color="inherit"
          startIcon={
            <Avatar
              className="user-avatar"
              alt="User Avatar"
              src={AvatarIcon}
              sx={{ width: '1.5rem', height: '1.5rem' }}
            />
          }
          endIcon={<ArrowDropDownIcon fontSize="small" />}
          onClick={handleProfileMenu}
        >
          {loginDetials?.name}
        </Button>
        <Menu
          className="user-menu"
          id="menu-appbar"
          anchorEl={btnRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={showProfileMenu}
          onClose={handleProfileMenu}
        >
          {menuItems.map((item) => (
            <MenuItem key={item} onClick={handleProfileMenu}>
              <Typography className="menu-item-text" textAlign="center">
                {item}
              </Typography>
            </MenuItem>
          ))}
          <MenuItem
            onClick={() => {
              // cookie.remove('myToken');
              onLogout();
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default NavBar;
