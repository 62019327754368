import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';

function UndocImmigrant({ onChangeDate, formik }) {
  return (
    <FormSection label="Undocumented Immigrant">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            name="beginDt"
            label="Begin Date"
            isDate
            formik={formik}
            required
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="endDt" isDate formik={formik} label="End Date">
            <DatePicker />
          </Field>
        </Grid>
      </Grid>
    </FormSection>
  );
}

UndocImmigrant.propTypes = {};

export default UndocImmigrant;
