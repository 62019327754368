import React, { createElement, useMemo, useState } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@mui/material';
import { includes, isEmpty, isFunction, map, noop, reject } from 'lodash';
import PropTypes from 'prop-types';

function TabularView(props) {
  const {
    columns: _columns,
    data: _data,
    getId,
    rootData,
    headerAxis,
    renderHeader,
    cellComponent,
    headerComponent,
    expandable,
    multiExpand,
    slots,
    renderDetails,
    ...rest
  } = props;

  const isAxisX = headerAxis === 'x';
  const [expandRowId, setExpandRowId] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const data = useMemo(() => {
    return map(_data, (item, i) => {
      if (isFunction(getId)) {
        return {
          ...item,
          id: getId(item) ?? i,
        };
      }

      return item;
    });
  }, [_data, getId]);

  const columns = reject(_columns, ({ hide }) => {
    if (isFunction(hide)) {
      return hide(data, rootData);
    }
    return hide ?? false;
  });

  const handleExpand = (expandId) => {
    const isExpanded = includes(expandRowId, expandId);
    if (multiExpand) {
      if (isExpanded) {
        const newIds = reject(expandRowId, (item) => item === expandId);
        setExpandRowId(newIds);
      } else {
        setExpandRowId([...expandRowId, expandId]);
      }
    } else {
      if (isExpanded) {
        setExpandRowId([]);
      } else {
        setExpandRowId([expandId]);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const renderCellContent = (
    renderer,
    component = {},
    value,
    getValue,
    index,
    defaultValue = '-',
    sx = {}
  ) => {
    const content = isFunction(getValue) ? getValue(value, index) : value;
    const { type = Typography, ...props } = component;

    if (isFunction(renderer)) {
      return (
        <TableCell sx={sx}>
          {renderer(content, index, rootData, {
            setExpandRowId: handleExpand,
            isExpanded: includes(expandRowId, content?.id),
          })}
        </TableCell>
      );
    }

    return (
      <TableCell>
        {createElement(type, props, String(content ?? defaultValue))}
      </TableCell>
    );
  };

  const renderRows = () => {
    if (isAxisX) {
      return map(paginatedData, (d = {}, index) => {
        return (
          <>
            <TableRow>
              {map(
                columns,
                ({
                  field,
                  renderCell,
                  cellComponent,
                  getValue,
                  defaultValue,
                  sx,
                }) =>
                  renderCellContent(
                    renderCell,
                    cellComponent,
                    field ? d[field] : d,
                    getValue,
                    index,
                    defaultValue,
                    sx
                  )
              )}
            </TableRow>
            {expandable && (
              <TableRow>
                <TableCell sx={{ p: '0 !important' }} colSpan={columns.length}>
                  <Collapse in={includes(expandRowId, d.id)}>
                    {renderDetails(d)}
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </>
        );
      });
    }

    return map(columns, (col, i) => {
      const {
        field,
        value,
        values,
        headerName,
        renderCell,
        getValue,
        cellComponent: cc = cellComponent,
        sx = {},
      } = col;

      const isSingleValue = isEmpty(values);
      const _value = field ? data?.[field] : value;

      return (
        <TableRow>
          {renderCellContent(renderHeader, headerComponent, headerName, sx)}
          {isSingleValue
            ? renderCellContent(renderCell, cc, _value, getValue)
            : map(values, (v) =>
                renderCellContent(renderCell, cc, v, getValue)
              )}
        </TableRow>
      );
    });
  };

  const { noRowsOverlay } = slots;

  return (
    <TableContainer {...rest}>
      <Table>
        {isAxisX && (
          <TableHead>
            <TableRow>
              {map(
                columns,
                ({
                  renderHeader: rc = renderHeader,
                  headerComponent: hc = headerComponent,
                  headerName,
                }) => renderCellContent(rc, hc, headerName)
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {isEmpty(data) && noRowsOverlay()}
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[50, 100]}
      />
    </TableContainer>
  );
}

TabularView.propTypes = {
  headerAxis: PropTypes.string,
  slots: PropTypes.shape({
    noRowsOverlay: PropTypes.func,
  }),
  expandable: PropTypes.bool,
  multiExpand: PropTypes.bool,
  renderDetails: PropTypes.func,
};

TabularView.defaultProps = {
  headerAxis: 'x',
  slots: {
    noRowsOverlay: noop,
  },
  expandable: false,
  multiExpand: false,
  renderDetails: noop,
};

export default TabularView;
