import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Icon from '../Icon';
import styles from './index.module.css';
import { Typography } from '@mui/material';

function ExternalLink({ children, onClick }) {
  return (
    <div
      className={styles.externalLink}
      role="button"
      tabIndex={-1}
      onClick={onClick}
    >
      <Typography className={styles.link} variant="body1">
        {children}
      </Typography>
      <Icon width="18px" height="18px" name="ExternalLink" />
    </div>
  );
}

ExternalLink.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ExternalLink.defaultProps = {
  children: null,
  onClick: noop,
};

export default ExternalLink;
