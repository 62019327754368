import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TabularView from '../../../components/TabularView';
import { useMemo } from 'react';
import { Paper, Typography } from '@mui/material';
import { getDashboardCount } from '../../../utils/services/apiRequests';
import { getAuthToken } from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';

const renderCount = (count, url) => {
  return (
    <Typography
      component="a"
      href={url}
      sx={{ fontWeight: 700, color: '#44A0E3', textDecoration: 'none' }}
      variant="h4"
    >
      {count}
    </Typography>
  );
};

function WorkItemTable({}) {
  const token = useSelector(getAuthToken);
  const [rowData, setRowData] = useState([]);
  const [showLoader, hideLoader] = useLoader();

  const data = [
    {
      type: 'Applications',
      totalAssigned: `${rowData?.applicationDetail?.totalAssigned || '0'}`,
      totalUnassigned: `${rowData?.applicationDetail?.totalUnassigned || '0'}`,
      totalDueIn30Days: `${
        rowData?.applicationDetail?.totalDueIn30Days || '0'
      }`,
      totalDueIn5Days: `${rowData?.applicationDetail?.totalDueIn5Days || '0'}`,
      totalDueToday: `${rowData?.applicationDetail?.totalDueToday || '0'}`,
      totalOverDues: `${rowData?.applicationDetail?.totalOverDues || '0'}`,
    },
    {
      type: 'Redetermindations',
      totalAssigned: `${rowData?.redeterminationDetail?.totalAssigned || '0'}`,
      totalUnassigned: `${
        rowData?.redeterminationDetail?.totalUnassigned || '0'
      }`,
      totalDueIn30Days: `${
        rowData?.redeterminationDetail?.totalDueIn30Days || '0'
      }`,
      totalDueIn5Days: `${
        rowData?.redeterminationDetail?.totalDueIn5Days || '0'
      }`,
      totalDueToday: `${rowData?.redeterminationDetail?.totalDueToday || '0'}`,
      totalOverDues: `${rowData?.redeterminationDetail?.totalOverDues || '0'}`,
    },
    {
      type: 'Interim Changes',
      totalAssigned: `${
        rowData?.interimDetailChangesDetail?.totalAssigned || '0'
      }`,
      totalUnassigned: `${
        rowData?.interimDetailChangesDetail?.totalUnassigned || '0'
      }`,
      totalDueIn30Days: `${
        rowData?.interimDetailChangesDetail?.totalDueIn30Days || '0'
      }`,
      totalDueIn5Days: `${
        rowData?.interimDetailChangesDetail?.totalDueIn5Days || '0'
      }`,
      totalDueToday: `${
        rowData?.interimDetailChangesDetail?.totalDueToday || '0'
      }`,
      totalOverDues: `${
        rowData?.interimDetailChangesDetail?.totalOverDues || '0'
      }`,
    },
    {
      type: 'Alerts',
      totalAssigned: `${rowData?.alertDetail?.totalAssigned || '0'}`,
      totalUnassigned: `${rowData?.alertDetail?.totalUnassigned || '0'}`,
      totalDueIn30Days: `${rowData?.alertDetail?.totalDueIn30Days || '0'}`,
      totalDueIn5Days: `${rowData?.alertDetail?.totalDueIn5Days || '0'}`,
      totalDueToday: `${rowData?.alertDetail?.totalDueToday || '0'}`,
      totalOverDues: `${rowData?.alertDetail?.totalOverDues || '0'}`,
    },
    {
      type: 'Scheduled Interview',
      totalAssigned: `${
        rowData?.scheduledInterviewDetail?.totalAssigned || '0'
      }`,
      totalUnassigned: `${
        rowData?.scheduledInterviewDetail?.totalUnassigned || '0'
      }`,
      totalDueIn30Days: `${rowData?.alertDetail?.totalDueIn30Days || '0'}`,
      totalDueIn5Days: `${rowData?.alertDetail?.totalDueIn5Days || '0'}`,
      totalDueToday: `${rowData?.alertDetail?.totalDueToday || '0'}`,
      totalOverDues: `${rowData?.alertDetail?.totalOverDues || '0'}`,
    },
    {
      type: 'Case Reviews',
      totalAssigned: `${rowData?.caseReviewDetail?.totalAssigned || '0'}`,
      totalUnassigned: `${rowData?.caseReviewDetail?.totalUnassigned || '0'}`,
      totalDueIn30Days: `${rowData?.caseReviewDetail?.totalDueIn30Days || '0'}`,
      totalDueIn5Days: `${rowData?.caseReviewDetail?.totalDueIn5Days || '0'}`,
      totalDueToday: `${rowData?.caseReviewDetail?.totalDueToday || '0'}`,
      totalOverDues: `${rowData?.caseReviewDetail?.totalOverDues || '0'}`,
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        headerName: 'WORK ITEM CATEGORIES',
        field: 'type',
        renderCell(type) {
          return (
            <Typography sx={{ fontWeight: 700 }} variant="subtitle2">
              {type}
            </Typography>
          );
        },
      },
      {
        headerName: 'TOTAL ASSIGNED',
        renderCell: (params) => {
          if (params.type === 'Applications') {
            return renderCount(params.totalAssigned, '/dashboard/applications');
          } else if (params.type === 'Redetermindations') {
            return renderCount(
              params.totalAssigned,
              '/dashboard/recertification'
            );
          } else if (params.type === 'Alerts') {
            return renderCount(
              params.totalAssigned,
              '/dashboard/alerts-work-item'
            );
          } else if (params.type === 'Case Reviews') {
            return renderCount(params.totalAssigned, '/dashboard/case-review');
          } else if (params.type === 'Scheduled Interview') {
            return renderCount(
              params.totalAssigned,
              '/dashboard/scheduled-interview'
            );
          } else {
            return renderCount(params.totalAssigned, '');
          }
        },
      },
      {
        headerName: 'TOTAL UNASSIGNED',
        renderCell: (params) => {
          if (params.type === 'Applications') {
            return renderCount(
              params.totalUnassigned,
              '/dashboard/applications'
            );
          } else if (params.type === 'Redetermindations') {
            return renderCount(
              params.totalUnassigned,
              '/dashboard/recertification'
            );
          } else if (params.type === 'Alerts') {
            return renderCount(
              params.totalUnassigned,
              '/dashboard/alerts-work-item'
            );
          } else if (params.type === 'Case Reviews') {
            return renderCount(params.totalUnassigned, '/dashboard/case-review');
          } else if (params.type === 'Scheduled Interview') {
            return renderCount(
              params.totalUnassigned,
              '/dashboard/scheduled-interview'
            );
          } else {
            return renderCount(params.totalUnassigned, '');
          }
        },
      },
      {
        headerName: 'DUE WITHIN 30 DAYS',
        renderCell: (params) => {
          if (params.type === 'Applications') {
            return renderCount(
              params.totalDueIn30Days,
              '/dashboard/applications'
            );
          } else if (params.type === 'Redetermindations') {
            return renderCount(
              params.totalDueIn30Days,
              '/dashboard/recertification'
            );
          } else if (params.type === 'Alerts') {
            return renderCount(
              params.totalDueIn30Days,
              '/dashboard/alerts-work-item'
            );
          } else if (params.type === 'Case Reviews') {
            return renderCount(params.totalDueIn30Days, '/dashboard/case-review');
          } else if (params.type === 'Scheduled Interview') {
            return renderCount(
              params.totalDueIn30Days,
              '/dashboard/scheduled-interview'
            );
          } else {
            return renderCount(params.totalDueIn30Days, '');
          }
        },
      },
      {
        headerName: 'DUE WITHIN 05 DAYS',
        renderCell: (params) => {
          if (params.type === 'Applications') {
            return renderCount(
              params.totalDueIn5Days,
              '/dashboard/applications'
            );
          } else if (params.type === 'Redetermindations') {
            return renderCount(
              params.totalDueIn5Days,
              '/dashboard/recertification'
            );
          } else if (params.type === 'Alerts') {
            return renderCount(
              params.totalDueIn5Days,
              '/dashboard/alerts-work-item'
            );
          } else if (params.type === 'Case Reviews') {
            return renderCount(
              params.totalDueIn5Days,
              '/dashboard/case-review'
            );
          } else if (params.type === 'Scheduled Interview') {
            return renderCount(
              params.totalDueIn5Days,
              '/dashboard/scheduled-interview'
            );
          } else {
            return renderCount(params.totalDueIn5Days, '');
          }
        },
      },
      {
        headerName: 'TOTAL DUE TODAY',
        renderCell: (params) => {
          if (params.type === 'Applications') {
            return renderCount(params.totalDueToday, '/dashboard/applications');
          } else if (params.type === 'Redetermindations') {
            return renderCount(
              params.totalDueToday,
              '/dashboard/recertification'
            );
          } else if (params.type === 'Alerts') {
            return renderCount(
              params.totalDueToday,
              '/dashboard/alerts-work-item'
            );
          } else if (params.type === 'Case Reviews') {
            return renderCount(params.totalDueToday, '/dashboard/case-review');
          } else if (params.type === 'Scheduled Interview') {
            return renderCount(
              params.totalDueToday,
              '/dashboard/scheduled-interview'
            );
          } else {
            return renderCount(params.totalDueToday, '');
          }
        },
      },
      {
        headerName: 'TOTAL OVERDUE',
        renderCell: (params) => {
          if (params.type === 'Applications') {
            return renderCount(params.totalOverDues, '/dashboard/applications');
          } else if (params.type === 'Redetermindations') {
            return renderCount(
              params.totalOverDues,
              '/dashboard/recertification'
            );
          } else if (params.type === 'Alerts') {
            return renderCount(
              params.totalOverDues,
              '/dashboard/alerts-work-item'
            );
          } else if (params.type === 'Case Reviews') {
            return renderCount(params.totalOverDues, '/dashboard/case-review');
          } else if (params.type === 'Scheduled Interview') {
            return renderCount(
              params.totalOverDues,
              '/dashboard/scheduled-interview'
            );
          } else {
            return renderCount(params.totalOverDues, '');
          }
        },
      },
    ];
  }, []);

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    try {
      showLoader();
      const res = await getDashboardCount(token.sub);
      if (res.status === 200) {
        setRowData(res.data);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  return (
    <div>
      <TabularView
        component={Paper}
        headerComponent={{
          variant: 'subtitle2',
          sx: { fontWeight: 700, color: '#6C6C6C' },
        }}
        columns={columns}
        data={data}
      />
    </div>
  );
}

WorkItemTable.propTypes = {};

export default WorkItemTable;
