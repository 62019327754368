import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  getSupvDashboardUnassigned,
  scheduledAssignCaseWorker,
} from '../../../utils/services/apiRequests';
import dayjs from 'dayjs';
import useCaseStatus from '../../../hooks/useCaseStatus';
import { Grid, Box, Button, Stack } from '@mui/material';
import useMount from '../../../hooks/useMount';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';
import { useFormik } from 'formik';
import useLoader from '../../../hooks/useLoader';
import { find, map } from 'lodash';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import usePageNav from '../../../hooks/usePageNav';
import IconLink from '../../../components/IconLinks';
import { ReactComponent as WorkUpdate } from '../../../assets/icons/QuickLinks/work_update.svg';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

function UnAssignedTable({ lkpFlatten, getWorkItemData, getLkpMatch }) {
  const [fetching, setFetching] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const [rowData, setRowData] = useState([]);
  const { navigations } = usePageNav();
  const [option, setOption] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const { viewCase } = useCaseStatus();

  const formik = useFormik({
    initialValues: {
      assigned: null,
    },
    onSubmit: (values) => {
      assign(values?.assigned);
    },
  });

  const assign = async (uuid) => {
    try {
      showLoader();
      const payload = {
        dirtyStatus: '',
        caseWorkerId: uuid,
        programScheduleInterviewIdList: [],
      };
      payload.programScheduleInterviewIdList = map(
        selectedData,
        (item) => item?.programScheduleInterviewId
      );
      const res = await scheduledAssignCaseWorker(payload);
      if (res.status === 200) {
        showSuccessToast('Assigned Success Fully');
        fetchData();
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const handleRowSelectionChange = (newRowSelectionModel) => {
    const selectedData = map(newRowSelectionModel, (selectedId) => {
      return find(rowData, (item) => item.caseId === selectedId);
    });
    setSelectedData(selectedData);
  };

  const fetchData = async () => {
    try {
      setFetching(true);
      const res = await getSupvDashboardUnassigned(
        'get-supervisor-dashboard-unassigned'
      );
      if (res.status === 200) {
        const { supervisorDashboardDetailsList, caseWorkersList } = res.data;
        setRowData(supervisorDashboardDetailsList);
        setOption(caseWorkersList);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetching(false);
    }
  };

  useMount(() => {
    fetchData();
  });

  const columns = [
    {
      field: 'appointmentDate',
      headerName: 'Interview Date',
      renderCell: (params) => dayjs(params?.row?.dueDate).format('MM-DD-YYYY'),
      flex: 1,
      maxWidth: 250,
    },
    {
      field: 'interviewType',
      headerName: 'Interview Type',
      renderCell: (params) => {
        return getLkpMatch('InterviewType', params?.row?.interviewType);
      },
      flex: 1,
      maxWidth: 250,
    },
    { field: 'caseId', headerName: 'Case ID', flex: 1, maxWidth: 250 },
    { field: 'name', headerName: 'HOH', flex: 1, maxWidth: 250 },
    {
      field: 'programCd',
      headerName: 'Program Type',
      renderCell: (params) => lkpFlatten?.Program?.[params?.row?.programCd],
      flex: 1,
      maxWidth: 250,
    },
    {
      field: 'caseMode',
      headerName: 'Case Mode',
      renderCell: (params) => {
        return getLkpMatch('CaseMode', params?.row?.caseMode);
      },
      flex: 1,
      maxWidth: 250,
    },
  ];

  const lookupData = useMemo(() => {
    return map(option, (value) => {
      return {
        label: value.personName.firstName + ' ' + value.personName.lastName,
        value: value.workerId,
      };
    });
  }, [option]);

  return (
    <Box gap={2}>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          gap: '2rem',
        }}
        mb={2}
        xs={12}
      >
        <Grid
          xs={6}
          sx={{
            display: 'flex',
            alignItems: 'end',
            gap: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Stack width="50%">
            <Field name="assigned" formik={formik} label="Assign To">
              <Dropdown options={lookupData} />
            </Field>
          </Stack>
          <Stack>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Assign
            </Button>
          </Stack>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          xs={5}
        >
          <Button onClick={getWorkItemData}>
            <IconLink icon={WorkUpdate} iconlabel="Get Work Item" />
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        getRowId={(row) => (row.caseId ? row.caseId : row.webId || row.webID)}
        rows={rowData ?? []}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Note: Please click Get Work Item to Get Records"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        pageSizeOptions={[10, 20, 30]}
        checkboxSelection={true}
        autoHeight
        onRowSelectionModelChange={handleRowSelectionChange}
      />
    </Box>
  );
}

UnAssignedTable.propTypes = {};

export default UnAssignedTable;
