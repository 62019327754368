export const saveWorkPlans = (data, events, individualId) => {
  return {
    dirtyStatus: events,
    //dirtyStatus: 'insert',
    //wrkPrgPlanId: null,
    wrkPrgPlanId: events === 'insert' ? null : data?.wrkPrgPlanId ?? null,
    individualId: individualId,
    programId: '',
    clkCnt: data?.clkCnt ?? '',
    ldssCd: data?.ldssCd ?? '',
    wrkId: '',
    wrkPlanTypeCd: '',
    jobAtchd: '',
    story: '',
    longTrmGoal: '',
    shrtTrmGoal: '',
    prtcpntRspns: '',
    planStrtDt: data.planStrtDt ?? null,
    narration: data.narration,
    planNum: '',
    planStus: data.planStus ?? null,
    planStusDt: data.planStusDt ?? null,
    effectiveEndDt: data.effectiveEndDt ?? null,
    fedCore: data?.fedCore ?? '',
    fedNonCore: data?.fedNonCore ?? '',
    effectiveBeginDt: data.effectiveBeginDt ?? null,
    contactVendor: null,
    benefitsCaseId: null,
  };
};

export const assessment_workPlan = (data, assementDetails) => {
  return {
    assesmentQuestionList: data,
    assmntDt: assementDetails?.assmntDt ?? null,
    assmntSignDt: assementDetails?.assmntSignDt ?? null,
    dirtyStatus: 'update',
    individualId: assementDetails?.individualId,
    workAssesmentId: assementDetails?.workAssesmentId,
    workerUserId: assementDetails?.workerUserId,
  };
};
