import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import cx from 'classnames';

function SummaryHeader({ isOpen, title, renderRtContent, isHoh }) {
  return (
    <div
      className={cx(styles.title, {
        [styles.collapsed]: !isOpen,
      })}
    >
      <Typography variant="h4">{title}</Typography>
      {renderRtContent && renderRtContent()}
      {isHoh && <Typography variant="h4">{isHoh}</Typography>}
    </div>
  );
}

SummaryHeader.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  renderRtContent: PropTypes.func,
};

SummaryHeader.defaultProps = {
  isOpen: true,
  title: '',
  renderRtContent: null,
  isHoh: '',
};

export default SummaryHeader;
