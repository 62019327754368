import { format } from '../../helpers';
import HttpClient from '../../HttpClient';
import { CASEDATE, RETROMONTHS, RETROSUMBIT } from './urls';

export const getCaseData = (caseId) => {
  return HttpClient.get(format(CASEDATE, caseId));
};

export const retroMonthsList = (caseId, individualId) => {
  return HttpClient.get(format(RETROMONTHS, caseId, individualId));
};

export const retroListSubmit = (caseId, data) => {
  return HttpClient.post(format(RETROSUMBIT, caseId), data);
};
