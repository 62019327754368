import dayjs from 'dayjs';

export const saveMember = (initialStatus, record, providedVerificationId) => {
  console.log(initialStatus, 'lkjnbhg');
  const {
    frequencyAmountAry,
    dirtyStatus,
    expenseDetails,
    frequency,
    providerName,
    snapConsider,
    waiver,
    serviceProvided,
    effectiveEDate,
    recordId,
    effectiveBDate,
  } = initialStatus ?? {};
  return {
    dirtyStatus: dirtyStatus,
    retroSameAsCurrentYN: 'N',
    retroMonths: [],
    recordId: dirtyStatus === 'insert' ? '0' : record,

    expense: {
      frequency: frequency,
      amountModel: frequencyAmountAry,
    },
    serviceProvided: serviceProvided,
    providerName: providerName,
    effectiveBeginDt: effectiveBDate ?? null,
    effectiveEndDt: effectiveEDate ?? null,
    medicalExpenseVerification:
      dirtyStatus === 'insert'
        ? {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: 'N',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: 'N',
          }
        : {
            retryCount: 0,
            verificationRequestedYN: 'N',
            verificationType: '',
            providedVerificationId: providedVerificationId,
            verifiedYN: 'N',
            verificationSourceCode: '',
            updatedBy: 'RaymondC Smith',
            updatedDate: dayjs().format('YYYY-MM-DD'),
          },
    medicalExpenseType: expenseDetails,
    medBPayer: '',
    medCPayer: '',
    medDPayer: '',
    notsnap: snapConsider,
    notwaiver: waiver,
  };
};
