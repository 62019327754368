import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import SelectableView from '../../components/SelectableView';
import { ReactComponent as CashAssistance } from '../../assets/icons/cash.svg';
import { ReactComponent as Disaster } from '../../assets/icons/disaster.svg';
import { ReactComponent as Emergency } from '../../assets/icons/emergency.svg';
import { ReactComponent as Medicaid } from '../../assets/icons/medicaid.svg';
import { ReactComponent as Snap } from '../../assets/icons/snap.svg';
import { ReactComponent as LongTerm } from '../../assets/icons/longterm.svg';
import { ReactComponent as Waiver } from '../../assets/icons/waiver.svg';
import PageContainer from '../../components/PageContainer';
import { Button, Stack, Typography } from '@mui/material';
import BaseMenuButton from '../../components/SelectableView/BaseMenuButton';
import { map, get, find, includes } from 'lodash';
import AddProgramDialog from './AddProgram';
import {
  getDsnapIndicator,
  getProgramRequest,
  getSelectedProgramsReq,
  saveProgramRequest,
} from '../../utils/services/programRequest/apiRequests';
import { FETCH_CASE_DETAILS } from '../../store/types/caseTypes';
import useLookup from '../../hooks/useLookup';
import usePageNav from '../../hooks/usePageNav';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import styles from './index.module.css';
import mock from './reqPgmMock.json';
import AddProgram from './AddProgram';
import ReqProgramDetails from './ReqProgramDetails';
import { programIcons } from '../../constants/programConstants';
import Icon from '../../components/Icon';
import { useDispatch } from 'react-redux';
import Status from '../../components/Status';
import usePage from '../../hooks/usePage';

const icons = {
  CH: CashAssistance,
  DS: Disaster,
  EA: Emergency,
  FS: Snap,
  LT: LongTerm,
  MA: Medicaid,
  WV: Waiver,
};

function ProgramRequest({ id }) {
  const { navProps } = usePage(id);
  const dispatch = useDispatch();
  const { lkpFlatten, getLkpMatch, getOptions, lkpRaw, islkpFailed } =
    useLookup('ProgramRequest');
  const [apiError, setApiError] = useState(false);
  const params = useParams();
  const { caseId, navigations } = usePageNav();
  const [responseData, setResponseData] = useState([]);
  const [initialState, setInitialState] = useState([]);
  const [addProgramsList, setAddProgramsList] = useState([]);
  const [requestedProgram, setRequestedProgram] = useState([]);
  const [fetchingPrograms, setFetchingPrograms] = useState(false);
  const [open, setOpen] = useState(false);
  const { RequestedPrograms } = lkpFlatten;

  const fetchProgramRequest = async () => {
    try {
      if (apiError) {
        setApiError(false);
      }
      setFetchingPrograms(true);
      // setRequestedProgram(mock.requestedPrograms);
      const res = await getSelectedProgramsReq(params.caseId);
      if (res.status === 200) {
        setRequestedProgram(res.data);
      }
    } catch (error) {
      // showErrorToast(error);
      setApiError(true);
    } finally {
      setFetchingPrograms(false);
    }
  };

  useEffect(() => {
    fetchProgramRequest();
  }, [params.caseId]);

  const programsHoh = useMemo(() => {
    const foundHoh = find(responseData.requestedProgram, {
      headOfHouseholdYN: 'Y',
    });

    return foundHoh;
  }, [responseData]);

  const onSubmit = async (values) => {
    try {
      const res = await saveProgramRequest(
        values,
        responseData,
        caseId,
        'update'
      );
      showSuccessToast('Succesffuly Program Updated');
      formik.resetForm();
      setTimeout(() => {
        fetchProgramRequest();
      }, 2000);
    } catch (error) {
      showErrorToast();
    } finally {
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit,
  });

  const onSaveSuccess = () => {
    fetchProgramRequest();
  };

  const onBack = () => {
    navigations.toAuthorizedRep();
  };

  const onNext = () => {
    navigations.toLivingArrangement();
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Program Request"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        getId={(params) => params.programName}
        getName={(params) =>
          getLkpMatch('RequestedPrograms', params?.programName)
        }
        data={requestedProgram}
        title="Programs"
        loading={fetchingPrograms}
        loadingContent={fetchingPrograms}
        customHeader
        addable
        expandable={false}
        noData={apiError}
        tryAgain={fetchProgramRequest}
        renderAdd={(data, options) => {
          const { setSelectedId, setHighlightIds } = options;
          return (
            <AddProgram
              requestedProgram={data}
              getLkpMatch={getLkpMatch}
              lkpRaw={lkpRaw}
              getOptions={getOptions}
              caseId={caseId}
              onAddSuccess={(programs) => {
                setSelectedId(programs[0]);
                setHighlightIds(programs);
                fetchProgramRequest();
                dispatch({ type: FETCH_CASE_DETAILS, payload: caseId });
              }}
            />
          );
        }}
        renderMenuButton={(params, options) => {
          const { id, name, programName } = params;
          const iconName = programIcons[programName];
          const { selectedId, onSelect, highlightIds } = options;
          const selected = id === selectedId;
          const onClick = () => onSelect(id);
          const highlight = includes(highlightIds, id);
          return (
            <BaseMenuButton
              selected={selected}
              onClick={onClick}
              avatar={<Icon width="2rem" height="2rem" name={iconName} />}
              highlight={highlight}
              details={
                <>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">{name}</Typography>
                  </Stack>
                  {/* <Status onlyIndicator invert status={} /> */}
                  {/* <span className={styles.activeStatus}>●</span> */}
                </>
              }
            />
          );
        }}
      >
        {(selected) => {
          return (
            <ReqProgramDetails
              key={selected?.programName}
              program={selected}
              lkpRaw={lkpRaw}
              caseId={caseId}
              onSaveSuccess={onSaveSuccess}
              getOptions={getOptions}
            />
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}

ProgramRequest.propTypes = {};

export default ProgramRequest;
