export const FIRST_NAME_REQ = 'Please enter first name';
export const LAST_NAME_REQ = 'Please enter last name';
export const LAST_NAME_MIN = 'Last name should contain at least 3 letters';
export const DOB_REQ = 'Please select dob';
export const GENDER_REQ = 'Please select gender';
export const RACE_REQ = 'Please select race';
export const ETHNICITY_REQ = 'Please select ethnicity';
export const MARITAL_STATUS_REQ = 'Please select martial status';
export const SSN_REFERRAL_REQ = 'Please select ssn referral';
export const SSN_INVALID = 'Please enter valid ssn number';
export const VERIFICATIONDOC = 'Please select Verification Document';
export const BIRTHVERIFICATIONDOC = 'Please select birth Verification';
export const BIRTHCITY = 'Please select birth city';
export const BIRTHSTATE = 'Please select birth state';
export const VERFICATIONSOURCE = 'Please select Verification Source';
export const DEATHSTATE = 'Please select death state';
export const VERIFI = 'Please select verification';
export const DEATHCERT = 'Please select Death Cert No';
