export const reinstatePayload = (data) => {
  const { caseId } = data;
  return {
    reinstateReopenInd: 'RE' ? 'RO' : 'RE',
  };
};

export const revalidatePayload = (data) => {
  const { caseId, program, fname, lname, dob, reason, reasonType, date } = data;
  return {
    dateOfBirth: dob,
    firstName: fname,
    lastName: lname,
  };
};

export const reinitiatePayload = (data) => {
  const { caseId, program, fname, lname, dob, reason, reasonType, date } = data;
  return {
    programName: program,
    reportReceivedMethod: reason,
    rescindDt: date,
    rescindReason: reasonType,
  };
};
