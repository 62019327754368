import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from '../../../components/ActionButtons';
import AddVehicle from './AddEstateAsset';
import { CloseRounded } from '@mui/icons-material';
import { filter } from 'lodash';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import { useParams } from 'react-router-dom';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import { maxLength, number, onlyAlphabet } from '../../../utils/normalizers';

export default function Estate({
  editData,
  insertAPI,
  getOptions,
  createNew,
  editApi,
  toggleClose,
  lkpRaw,
  selected,
}) {
  const [policy, setPolicy] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [editRecord, setEditRecord] = useState('');
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const params = useParams();

  const validationSchema = Yup.object({
    type: Yup.string().required('Please select type'),
    use: Yup.string().required('Please select use'),
  });

  useEffect(() => {
    if (policyData.length === 0 && editData) {
      setPolicyData((prev) => [...prev, ...editData?.assetAmounts]);
    }
  }, []);

  const {
    EstatePropertiesUseOpts,
    insuranceTypeOpts,
    stateOpts,
    amoutOpts,
    fairOpts,
  } = useMemo(() => {
    return {
      EstatePropertiesUseOpts: getOptions(
        'AddAssetsAssetTypeRealEstateProperties'
      ),
      insuranceTypeOpts: getOptions('RealPropertyUse'),
      stateOpts: getOptions('State'),
      amoutOpts: getOptions('CLPRSNLENCUMBVERCD'),
      fairOpts: getOptions('CLPROPFMVAMTVERCD'),
    };
  }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      type: editData?.assetDetailType ?? '',
      use: editData?.use ?? '',
      line1: editData?.address?.addressLine1 ?? '',
      line2: editData?.address?.addressLine2 ?? '',
      city: editData?.address?.city ?? '',
      zipCode: editData?.address?.zipcode ?? '',
      stateAddress: editData?.address?.state ?? '',
      assetType: 4,
      recordId: editData?.recordId ?? '',
      jointOwnerYN: editData?.jointOwnerYN ?? 'N',
      tryingToSellYN: editData?.tryingToSellYN ?? 'N',
      intentBurialExpensesYN: editData?.intentBurialExpensesYN ?? 'N',
    },
    validationSchema,
    onSubmit: (value) => {
      console.log(value);
      // if (createNew) {
      //   insertAPI({ value, policyData });
      // } else {
      //   editApi({ value, policyData });
      // }
    },
  });
  const AddInstitutionDetails = () => {
    setPolicy(true);
  };
  const toggleClosePop = () => {
    setPolicy(false);
  };
  const motorColumns = useMemo(
    () => [
      {
        field: 'effectiveBeginDate',
        headerName: 'Start Date',
        flex: 1,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        flex: 1,
      },

      {
        field: 'encumberedAmount',
        headerName: 'Encumbered Amount',
        flex: 1,
        renderCell: (params) => {
          return <Typography>${params.row.encumberedAmount}</Typography>;
        },
      },
      {
        field: 'fareMarketValue',
        headerName: 'Fair Market Value',
        flex: 1,
        renderCell: (params) => {
          return <Typography>${params.row.fareMarketValue}</Typography>;
        },
      },

      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editRec(params?.row)}
                onDelete={() => deleteRecord(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [policyData]
  );

  const deleteRecord = (data) => {
    const newRecord = filter(
      policyData,
      (val) => val.recordId !== data.recordId
    );
    setPolicyData(newRecord);
  };
  const editRec = (data) => {
    setEditRecord(data);
    setPolicy(true);
  };

  const typeHandler = useMemo(() => {
    const value = formik.values.type;
    if (value !== 'BP' || value !== 'SB' || value !== 'DP') {
      formik.setFieldValue('intentBurialExpensesYN', 'N');
    }
  }, [formik.values.type]);

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  return (
    <div>
      <Dialog
        open={policy}
        onClose={toggleClosePop}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Typography variant="h3">Real Estate Details</Typography>
            <IconButton onClick={toggleClosePop}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <AddVehicle
            getOptions={getOptions}
            amoutOpts={amoutOpts}
            fairOpts={fairOpts}
            toggleClosePop={toggleClosePop}
            setPolicyData={setPolicyData}
            policyData={policyData}
            editRecord={editRecord}
          />
        </DialogContent>
      </Dialog>
      <FormSection label={'Account Details'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name="type" formik={formik} label="Type" required>
              <Dropdown options={insuranceTypeOpts} placeholder="Select Type" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="use" formik={formik} label="Use" required>
              <Dropdown
                options={EstatePropertiesUseOpts}
                placeholder="Select use"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="line1"
              formik={formik}
              label="Line 1"
              normalizers={[maxLength(30)]}
            >
              <TextField placeholder="Enter the Address Line 1" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="line2"
              formik={formik}
              label="Line 2"
              normalizers={[maxLength(30)]}
            >
              <TextField placeholder="Enter the Address Line 2" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="city"
              formik={formik}
              label="City"
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField placeholder="Enter the City" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="stateAddress" formik={formik} label="State">
              <Dropdown options={stateOpts} placeholder="Select State" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="zipCode"
              formik={formik}
              label="Zip Code"
              normalizers={[maxLength(5), number]}
            >
              <TextField placeholder="Enter the Zip Code" type="number" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              sx={{ pt: 2.7 }}
              control={
                <Checkbox
                  {...formik.getFieldProps('tryingToSellYN')}
                  checked={formik.values.tryingToSellYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('tryingToSellYN', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Trying To Sell"
            />
          </Grid>
          {(formik.values.type === 'BP' ||
            formik.values.type === 'SB' ||
            formik.values.type === 'DP') && (
            <Grid item xs={6}>
              <FormControlLabel
                sx={{ pt: 2.7 }}
                control={
                  <Checkbox
                    {...formik.getFieldProps('intentBurialExpensesYN')}
                    checked={formik.values.intentBurialExpensesYN === 'Y'}
                    onChange={(e, checked) => {
                      formik.setFieldValue(
                        'intentBurialExpensesYN',
                        checked ? 'Y' : 'N'
                      );
                    }}
                  />
                }
                label="Intent To Burrial"
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControlLabel
              sx={{ pt: 2.7 }}
              control={
                <Checkbox
                  {...formik.getFieldProps('jointOwnerYN')}
                  checked={formik.values.jointOwnerYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('jointOwnerYN', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Jointly Owner"
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={'row'}
              mb={2}
              justifyContent={'space-between'}
              mt={2}
            >
              <Button
                variant="outlined"
                startIcon={<UploadFileIcon />}
                onClick={handleOpenDocModal}
              >
                Upload Files
              </Button>
              <Button onClick={AddInstitutionDetails} startIcon={<AddIcon />}>
                Add Asset
              </Button>
            </Stack>
            <div style={{ width: '100%' }}>
              <DataGrid
                getRowId={(rows) => rows.recordId}
                rows={policyData ?? []}
                columns={motorColumns}
                autoHeight
                hideFooter
              />
            </div>
          </Grid>
        </Grid>
        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={() => toggleClose()}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={policyData.length === 0}
          >
            Save
          </Button>
        </Stack>
      </FormSection>

      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}
