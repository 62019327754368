import HttpClient from '../../HttpClient';
import { holidayPayload } from './apiPayloads';
import {
  DELETE_HOLIDAY,
  GET_HOLIDAY,
  POST_HOLIDAY,
  UPDATE_HOLIDAY,
} from './apiUrls';

export const getHolidays = (year) => HttpClient.get(GET_HOLIDAY(year));

export const postHolidays = (data) => {
  return HttpClient.post(POST_HOLIDAY, holidayPayload(data));
};

export const updateHolidays = (data) => {
  return HttpClient.post(UPDATE_HOLIDAY, holidayPayload(data));
};

export const deleteHolidays = (holidayId) => {
  return HttpClient.post(DELETE_HOLIDAY(holidayId));
};
