export const GET_HEALTH =
  '/eeapi/application-process/interview/{0}/household/{1}/get-health-disability';

export const POST_HEALTH =
  '/eeapi/application-process/interview/{0}/household/{1}/health-disability';

export const POST_WBCCHP =
  '/eeapi/application-process/interview/{0}/household/{1}/wo1-details';

export const SOCIAL_SECURITY_DB = `/eeapi/application-process/interview/{0}/social-security-benefits/get-ssb-info/{1}`;

export const CREATE_BENEFITS = `/eeapi/application-process/interview/{0}/social-security-benefits/save-ssb-info/{1}`;
