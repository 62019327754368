import React, { useMemo } from 'react';
import useLookup from '../../../hooks/useLookup';
import SelectableView from '../../../components/SelectableView';
import PageContainer from '../../../components/PageContainer';
import usePageNav from '../../../hooks/usePageNav';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import MemberMenuButton from '../../../components/SelectableView/MemberMenuButton';
import { useSelector } from 'react-redux';
import WorkPlanIndex from './WorkPlanIndex';

const WorkPlan = () => {
  const { caseId, navigations } = usePageNav();
  const { getLkpMatch, getOptions } = useLookup('worksPlan');
  const { houseMembers } = useSelector(selectHouseMembers);

  const onNext = () => {};

  const onBack = () => {};

  const { houseHoldMembers } = useMemo(() => {
    if (houseMembers) return { houseHoldMembers: houseMembers };
  }, [houseMembers]);

  return (
    <PageContainer
      // navProps={''}
      title="Work Page"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <WorkPlanIndex
          caseId={caseId}
          getLkpMatch={getLkpMatch}
          getOptions={getOptions}
        />
      </SelectableView>
    </PageContainer>
  );
};

export default WorkPlan;
