import React from 'react';
import { Divider, Box, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import styles from './index.module.css';

const DocumentFileUploader = ({ handleChange, formik, formikError, isAdd }) => {
  return (
    <div className={styles.document}>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={['JPEG', 'PNG', 'JPG', 'PDF']}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <DriveFolderUploadIcon sx={{ fontSize: 200, color: '#d9d9d9' }} />
          <Typography variant="h5" mb={3}>
            Drop your files here
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" alignItems="center">
          <span className={styles.orButton}>or</span>
        </Box>
        <div className={styles.browseButton}>Browse</div>
        <span style={{ fontSize: '12px' }}>
          Note: supported file formats are .jpg .png .jpeg
        </span>
        <p
          style={{
            fontSize: '14px',
            color: formik.values.fileFormData?.name ? 'green' : 'red',
          }}
        >
          {formikError ? formikError : formik.values.fileFormData?.name}
        </p>
      </FileUploader>
    </div>
  );
};

export default DocumentFileUploader;
