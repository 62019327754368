import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { getCaseWorkerDashboard } from '../../../utils/services/apiRequests';
import dayjs from 'dayjs';
import { map } from 'lodash';
import useMount from '../../../hooks/useMount';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

function MyDashboardTable({ getLkpMatch, newValue }) {
  const [fetching, setFetching] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const [rowData, setRowData] = useState([]);

  const fetchData = async () => {
    try {
      showLoader();
      setFetching(true);
      const res = await getCaseWorkerDashboard();
      if (res.status === 200) {
        setRowData(res?.data);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  useMount(() => {
    fetchData();
  });

  const columns = [
    {
      field: 'appointmentDate',
      headerName: 'Interview Date',
      renderCell: (params) => dayjs(params?.row?.dueDate).format('MM-DD-YYYY'),
      flex: 1,
      maxWidth: 250,
    },
    {
      field: 'interviewType',
      headerName: 'Interviwe Type',
      renderCell: (params) => {
        return getLkpMatch('InterviewType', params?.row?.interviewType);
      },
      flex: 1,
      maxWidth: 250,
    },
    { field: 'caseId', headerName: 'Case ID', flex: 1, maxWidth: 250 },
    { field: 'name', headerName: 'HOH', flex: 1, maxWidth: 250 },
    {
      field: 'programCd',
      headerName: 'Program Type',
      renderCell: (params) => {
        const programArray = params?.row?.programCd?.split(',');
        return map(programArray, (p) => getLkpMatch('Program', p))?.join(',');
      },
      flex: 1,
      maxWidth: 250,
    },
    {
      field: 'caseMode',
      headerName: 'Case Mode',
      renderCell: (params) => {
        return getLkpMatch('CaseMode', params?.row?.caseMode);
      },
      flex: 1,
      maxWidth: 250,
    },
  ];

  return (
    <>
      <DataGrid
        getRowId={(row) =>
          row.caseId ? row.caseId : row.programScheduleInterviewId
        }
        rows={rowData ?? []}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Note: Please click Get Work Item to Get Records"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 30]}
        autoHeight
      />
    </>
  );
}

MyDashboardTable.propTypes = {};

export default MyDashboardTable;
