export const workExemPayload = (data) => {
  const {
    beginDate,
    endDate,
    exemption,
    program,
    verification,
    dirtyStatus,
    worksExemptionId,
    programId,
    members,
  } = data;
  return {
    childIndividualId: members ?? null,
    worksExemptionId: worksExemptionId ?? null,
    dirtyStatus: dirtyStatus,
    programId: program,
    exemptionCode: exemption,
    clientVerification: {
      verificationSourceCode: verification,
      documentDetails: [],
    },
    effectiveBeginDate: beginDate,
    effectiveEndDate: endDate ? endDate : null,
  };
};
