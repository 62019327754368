import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { noop } from 'lodash';

function UpDownButtons({ onUp, onDown, disableUp, disableDown }) {
  return (
    <div>
      <IconButton onClick={onDown} disabled={disableDown}>
        <ArrowDownwardIcon />
      </IconButton>
      <IconButton onClick={onUp} disabled={disableUp}>
        <ArrowUpwardIcon />
      </IconButton>
    </div>
  );
}

UpDownButtons.propTypes = {
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
};

UpDownButtons.defaultProps = {
  onUp: noop,
  onDown: noop,
  disableUp: false,
  disableDown: false,
};

export default UpDownButtons;
