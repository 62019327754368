import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Stack } from '@mui/material';
import ActionButtons from '../../../components/ActionButtons';
import { worksGetTable } from '../../../utils/services/Works/apiRequest';
import WorkCreation from './WorkCreation';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

const WorkPlanIndex = ({ caseId, getLkpMatch, getOptions, selected }) => {
  const [changed, setChanged] = useState(false);
  const [row, setRow] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dataToChange, setDataToChange] = useState({});
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState('insert');

  useEffect(() => {
    handleDashboardData();
  }, [changed, selected]);

  const handleDashboardData = async () => {
    try {
      const res = await worksGetTable(caseId, selected?.individualId);

      if (res.status === 200) {
        setTableData(res.data);
        const rows = res?.data?.map((obj, key) => {
          return {
            programType: 'Cash Assistance',
            id: obj.benefitsCaseId,
            workReason: 'N/A',
            planStus: getLkpMatch('Program', obj.planStus),
            planStusDt: dayjs(obj.planStusDt).format('DD-MM-YYYY'),
            effectiveBeginDt: dayjs(obj.effectiveBeginDt).format('DD-MM-YYYY'),
            effectiveEndDt: dayjs(obj.effectiveEndDt).format('DD-MM-YYYY'),
          };
        });
        setRow(rows);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleEdit = (params) => {
    setEvents('update');
    const dataToAlter = tableData?.find(
      (obj) => obj.benefitsCaseId === params.id
    );
    setDataToChange(dataToAlter);
    setOpen(true);
  };

  const handleDelete = (params) => {};

  const columns = [
    { field: 'programType', headerName: 'Program Type', flex: 1 },
    { field: 'workReason', headerName: 'Work Reason', flex: 1 },
    {
      field: 'planStus',
      headerName: 'Plan Status',
      flex: 1,
    },
    { field: 'planStusDt', headerName: 'Plan Status Date', flex: 1 },
    {
      field: 'effectiveBeginDt',
      headerName: 'Begin Date',
      flex: 1,
    },
    {
      field: 'effectiveEndDt',
      headerName: 'End Date',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell(params) {
        return (
          <span>
            <ActionButtons
              onEdit={() => handleEdit(params)}
              onDelete={handleDelete}
            />
          </span>
        );
      },
    },
  ];
  return (
    <div>
      <Stack direction="row" justifyContent="end" mt={2}>
        <Button
          onClick={() => {
            setOpen(true);
            setDataToChange({});
            setEvents('insert');
          }}
          startIcon={<AddIcon />}
        >
          Add work plan/creation/Assessment
        </Button>
      </Stack>
      <DataGrid
        rows={row ?? []}
        columns={columns ?? []}
        sx={{
          marginTop: 2,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 16,
            fontWeight: 700,
            textAlign: 'center',
            color: 'black',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '30vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '30vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        autoHeight
      />
      {open && (
        <WorkCreation
          caseId={caseId}
          individualId={selected?.individualId}
          events={events}
          open={open}
          setOpen={setOpen}
          selected={selected}
          dataToChange={dataToChange}
          setEvents={setEvents}
          getOptions={getOptions}
          setChanged={setChanged}
          changed={changed}
        />
      )}
    </div>
  );
};

export default WorkPlanIndex;
