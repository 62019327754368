import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Grid,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  getCaseDetails,
  getHouseMembers,
} from '../../../utils/services/apiRequests';
import { find, isEmpty, map } from 'lodash';
import useLookup from '../../../hooks/useLookup';
import Status from '../../../components/Status';
import cx from 'classnames';
import styles from './index.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_CASE_DETAILS,
  FETCH_HOUSE_MEMBERS,
} from '../../../store/types/caseTypes';
import {
  selectCaseDetails,
  selectFetchingCaseDetails,
  selectHouseMembers,
} from '../../../store/selectors/caseSelector';
import {
  setCaseDetails,
  setHouseMembers,
} from '../../../store/slices/caseSlice';
import FlashingLoader from '../../../components/Loader/FlashingLoader';
import Copyable from '../../../components/Copyable';

function StatusBar({ caseId }) {
  const dispatch = useDispatch();
  const caseDetails = useSelector(selectCaseDetails);
  const fetchingCaseDetails = useSelector(selectFetchingCaseDetails);
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { getLkpMatch } = useLookup('StatusBar');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const hoh = find(houseMembers, {
    headOfHouseholdYN: 'Y',
  });

  const hohName = `${hoh?.personName?.firstName ?? ''} ${
    hoh?.personName?.lastName ?? ''
  }`;

  const [showPgmStatus, setShowPgmStatus] = useState(false);
  const programs = map(caseDetails?.programs ?? [], (p) => p.programName);
  const programEl = useRef();

  useEffect(() => {
    dispatch({ type: FETCH_HOUSE_MEMBERS, payload: caseId });
    dispatch({ type: FETCH_CASE_DETAILS, payload: caseId });

    return () => {
      dispatch(setCaseDetails({}));
      dispatch(setHouseMembers([]));
    };
  }, [caseId]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const togglePgsStatus = () => {
    setShowPgmStatus(!showPgmStatus);
  };

  return (
    <AppBar
      className={styles.statusBar}
      position="static"
      sx={{ backgroundColor: '#7ABBEB' }}
    >
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        sx={{ padding: '10px' }}
      >
        <Grid item>
          {fetchingMembers ? (
            <Stack alignItems="center" direction="row">
              <Box>
                <Typography variant="body1">HOH:&nbsp;</Typography>
              </Box>
              <FlashingLoader />
            </Stack>
          ) : (
            <Typography variant="body1">
              HOH: {hohName?.trim() ? hohName : 'Not added'}
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Copyable content={caseId}>
            <Typography variant="body1">Case ID: {caseId}</Typography>
          </Copyable>
        </Grid>
        <Grid item>
          {fetchingCaseDetails ? (
            <Stack alignItems="center" direction="row">
              <Box>
                <Typography variant="body1">Programs:&nbsp;</Typography>
              </Box>
              <FlashingLoader />
            </Stack>
          ) : (
            <Typography
              variant="body1"
              onClick={handlePopoverOpen}
              sx={{
                cursor: 'pointer',
              }}
            >
              Programs:&nbsp;
              {map(programs, (p) => getLkpMatch('Program', p))?.join(', ')}
            </Typography>
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Status invert status="pending">
              Pending
            </Status>
          </Popover>
        </Grid>
        <Grid item>
          {fetchingCaseDetails ? (
            <Stack alignItems="center" direction="row">
              <Box>
                <Typography variant="body1">Status:&nbsp;</Typography>
              </Box>
              <FlashingLoader />
            </Stack>
          ) : (
            <Typography variant="body1">
              Status: {getLkpMatch('CaseStatusCode', caseDetails?.caseStatusCd)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </AppBar>
  );
}

StatusBar.propTypes = {};

export default StatusBar;
