import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Collapse, Divider, IconButton, Stack } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export function ElAccordionContent({ children, expand, toggleExpand }) {
  return (
    <Collapse in={expand} collapsedSize="6rem">
      <Stack
        sx={{
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        {!expand && (
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: '4rem',
              background:
                'linear-gradient(rgb(255 255 255 / 43%), rgb(255 255 255))',
            }}
          />
        )}
        <Box flex={1}>{children}</Box>
        <Box flexBasis="2rem">
          <IconButton onClick={toggleExpand}>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Stack>
    </Collapse>
  );
}

function EligibilityAccordion({ children }) {
  const [current, setCurrent] = useState(-1);
  const childCount = Children.count(children);
  return (
    <Box
      sx={
        {
          // border: '1px solid #C2C2B7',
          // borderRadius: '6px',
          // padding: '0.1rem 0.5rem',
        }
      }
    >
      {Children.map(children, (child, i) => {
        const expand = i === current;
        const isLast = childCount === i - 1;
        return (
          <>
            {cloneElement(child, {
              expand,
              toggleExpand: () => {
                if (expand) {
                  setCurrent(-1);
                } else {
                  setCurrent(i);
                }
              },
            })}
            {!isLast && <Divider />}
          </>
        );
      })}
    </Box>
  );
}

EligibilityAccordion.propTypes = {};

export default EligibilityAccordion;
