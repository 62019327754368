import {
  GET_REDETERMINATIONDETAILS,
  POST_REDETERMINATION,
  POST_REDETERMINATION_HISTORY,
  POST_REDETERMINATION_History,
  POST_REDETERMINATION_SIGNATURE,
} from './urls';
import HttpClient from '../../HttpClient';
import { format } from '../../helpers/index';
import {
  redeterminationHistoryPayload,
  redeterminationPayload,
  redeterminationSignaturePayload,
} from './payLoads';

export const getRedeterminationDetails = (caseId) => {
  return HttpClient.get(format(GET_REDETERMINATIONDETAILS, caseId));
};

export const postRedeterminationDetails = (
  data = {},
  caseId,
  generatedCase
) => {
  return HttpClient.post(
    format(POST_REDETERMINATION, caseId),
    redeterminationPayload(data, generatedCase)
  );
};

export const postRedeterminationSignature = (data = {}, caseId) => {
  return HttpClient.post(
    format(POST_REDETERMINATION_SIGNATURE, caseId),
    redeterminationSignaturePayload(data)
  );
};

export const postRedeterminationHistory = (data = {}, caseId) => {
  return HttpClient.post(
    format(POST_REDETERMINATION_HISTORY, caseId),
    redeterminationHistoryPayload(data)
  );
};
