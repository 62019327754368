import React, { useEffect, useState } from 'react';
import ActionButtons from '../../../components/ActionButtons';
import { DataGrid } from '@mui/x-data-grid';
import useLoader from '../../../hooks/useLoader';
import {
  getEmployerDetails,
  postEmployerDetails,
} from '../../../utils/services/NonCustodial/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import EmployerModal from './EmployerModal';
import DeleteConfiramation from '../DeleteConfirmation';

export default function EmployerDetails({ caseId, absentParentId }) {
  const [showLoader, hideLoader] = useLoader();

  const [employerData, setEmployerData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [employerEdit, setEmployerEdit] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    getEmplyDet();
  }, [caseId]);

  const employerEditData = (data) => {
    setEditOpen(true);
    setEmployerEdit(data);
  };

  const columns = [
    {
      field: 'companyLegalName',
      headerName: 'Employer Name',
      flex: 1,
    },
    {
      field: 'occupation',
      headerName: 'Occupation',
      flex: 1,
    },
    {
      field: 'employmentBeginDate',
      headerName: 'Begin Date',
      flex: 1,
    },
    {
      field: 'employmentEndDate',
      headerName: 'End Date',
      flex: 1,
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => employerEditData(params?.row)}
              onDelete={() => deletePop(params?.row)}
            />
          </div>
        );
      },
    },
  ];

  const toggleClose = () => {
    setEditOpen(false);
    setEmployerEdit(false);
  };

  const getEmplyDet = async () => {
    try {
      showLoader();
      const res = await getEmployerDetails(caseId, absentParentId);
      if (res.status === 200) {
        const employers = res.data?.employers ?? [];
        setEmployerData(employers);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const deletePop = async (rowData) => {
    setSelectedData(rowData);
    setOpenDeleteDialog(true);
  };

  const handleDeleteProgram = () => {
    deleteEmployerDet();
  };

  const deleteEmployerDet = async () => {
    const payload = {
      ...selectedData,
      dirtyStatus: 'delete',
    };
    try {
      showLoader();
      const res = await postEmployerDetails(payload, caseId, absentParentId);
      if (res.status === 200) {
        showSuccessToast('Successfully Deleted Employer Details');
        setEmployerData((prevData) =>
          prevData.filter((item) => item.emplrId !== selectedData.emplrId)
        );
        setOpenDeleteDialog(false);
      } else {
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <DataGrid
        rows={employerData ?? []}
        columns={columns}
        getRowId={(row) => row?.emplrId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        sx={{
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '18px',
            fontWeight: '700',
            padding: '0px 10px',
            color: '#000000',
          },
          '& .MuiDataGrid-row': {
            fontSize: '16px',
            color: 'black',
            padding: '0px 10px',
            textAlign: 'center',
          },
        }}
        slots={{
          noRowsOverlay: 'noData',
        }}
      />

      <Dialog open={editOpen} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Employer Information</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <EmployerModal
            toggleClose={toggleClose}
            caseId={caseId}
            absentParentId={absentParentId}
            employerEdit={employerEdit}
            getEmplyDet={getEmplyDet}
          />
        </DialogContent>
      </Dialog>
      <DeleteConfiramation
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        handleDeleteProgram={handleDeleteProgram}
      />
    </>
  );
}
