import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'case',
  initialState: {
    caseDetails: {},
    houseMembers: [],
    isFetchingMembers: false,
    isFetchingCaseDetails: false,
    navigatePageName: '',
  },
  reducers: {
    setCaseDetails(state, action) {
      state.caseDetails = action.payload;
    },
    setHouseMembers(state, action) {
      state.houseMembers = action.payload;
    },
    setFetchingMembers(state, action) {
      state.isFetchingMembers = action.payload;
    },
    setFetchingCaseDetails(state, action) {
      state.isFetchingCaseDetails = action.payload;
    },
    setNavigatePageName(state, action) {
      state.navigatePageName = action.payload;
    },
  },
});

export const {
  setCaseDetails,
  setHouseMembers,
  setFetchingMembers,
  setFetchingCaseDetails,
  setNavigatePageName,
} = authSlice.actions;
export default authSlice.reducer;
