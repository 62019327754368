import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

function EligibilityReview() {
  return (
    <Box sx={{ p: '0.5rem 1rem' }}>
      <Outlet />
    </Box>
  );
}

export default EligibilityReview;
