import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  Stack,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Field from '../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import { DataGrid } from '@mui/x-data-grid';
import EmptyState from '../../../components/EmptyState';
import useLookup from '../../../hooks/useLookup';
import useLoader from '../../../hooks/useLoader';
import { postEligiHis } from '../../../utils/services/EligibilityHistory/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import usePageNav from '../../../hooks/usePageNav';
import dayjs from 'dayjs';
import SelectableView from '../../../components/SelectableView';
import { entries, groupBy, map } from 'lodash';
import Fieldset from '../../../components/Fieldset';
import { formatPayMonth, getStatus } from '../../../utils/helpers';
import BaseMenuButton from '../../../components/SelectableView/BaseMenuButton';
import Avatar from '../../../components/Avatar';
import Status from '../../../components/Status';
import SummaryDetails from '../../Eligibility/ElibilitySummary/SummaryDetails';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

export default function HistorryDetails({ id }) {
  const { caseId } = usePageNav();
  const { getOptions } = useLookup('EligibilityHistory');
  const [showLoader, hideLoader] = useLoader();
  const [historyData, setHistoryData] = useState([]);
  const { getLkpMatch } = useLookup('Eligibility');
  const [sortBy, setSortBy] = useState('P');

  const { rangeOpts, dataOpts } = useMemo(() => {
    return {
      rangeOpts: getOptions('DateRange'),
      dataOpts: getOptions('Data'),
    };
  }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      dateRange: '',
      startDate: '',
      endDate: '',
      dataRec: 'Y',
    },
    onSubmit: (values) => {
      postEligHistory(values);
    },
  });

  const onSort = (e) => {
    setSortBy(e.target.value);
  };

  useEffect(() => {
    if (formik.values.dateRange) {
      let endDate = dayjs();
      let startDate = dayjs();
      switch (formik.values.dateRange) {
        case 'OM':
          startDate = endDate.subtract(1, 'month');
          break;
        case 'TM':
          startDate = endDate.subtract(3, 'month');
          break;
        case 'SM':
          startDate = endDate.subtract(6, 'month');
          break;
        case 'NM':
          startDate = endDate.subtract(9, 'month');
          break;
        case 'WM':
          startDate = endDate.subtract(12, 'months');
          break;
        default:
          break;
      }
      formik.setFieldValue('startDate', startDate.format('YYYY-MM-DD'));
      formik.setFieldValue('endDate', endDate.format('YYYY-MM-DD'));
    }
  }, [formik.values.dateRange]);

  const postEligHistory = async (data) => {
    try {
      showLoader();
      const res = await postEligiHis(data, caseId);
      if (res.status === 200) {
        setHistoryData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  console.log(historyData, '0000');

  return (
    <>
      <Box
        sx={{
          padding: '1rem',
          border: '1px solid #e4e4e4',
          backgroundColor: 'white',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field name="dateRange" formik={formik} label="Date Range">
              <Dropdown options={rangeOpts} />
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Field name="startDate" formik={formik} label="Start Date" isDate>
              <DatePicker
                value={formik.values.startDate}
                onChange={(value) =>
                  formik.setFieldValue(
                    'startDate',
                    dayjs(value).format('YYYY-MM-DD')
                  )
                }
              />
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Field name="endDate" formik={formik} label="End Date" isDate>
              <DatePicker
                value={formik.values.endDate}
                onChange={(value) =>
                  formik.setFieldValue(
                    'endDate',
                    dayjs(value).format('YYYY-MM-DD')
                  )
                }
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="dataRec" formik={formik} label="Data">
              <Dropdown options={dataOpts} />
            </Field>
          </Grid>

          <Grid item xs={2} sx={{ marginTop: '1.3rem' }}>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Stack sx={{ display: 'flex', flexGrow: 1 }}>
        <SelectableView
          title="Eligibility History"
          data={historyData}
          customHeader
          customMenu
          image={
            <img
              src={NoRecord}
              style={{
                height: '160px',
                width: '250px',
              }}
            />
          }
          showAdd={false}
          showTitleBar={false}
          getId={({ programEligibilityId }) => programEligibilityId}
          getName={({ targetFirstName, targetLastName }) =>
            `${targetFirstName} ${targetLastName}`
          }
          renderMenu={(data, options) => {
            const { selectedId, onSelect } = options;
            const sortByDate = sortBy === 'D';
            const groupedData = groupBy(data, (d) =>
              sortByDate ? d?.eligibilityBeginDate : d?.programName
            );
            return (
              <Stack spacing={2}>
                <Stack
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minWidth: '17rem',
                  }}
                >
                  <Typography
                    sx={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                    variant="h5"
                  >
                    Sort by
                  </Typography>
                  <Select
                    onChange={onSort}
                    fullWidth={false}
                    placeholder="Sorty by"
                    value={sortBy}
                  >
                    <MenuItem value="D">Date</MenuItem>
                    <MenuItem value="P">Program</MenuItem>
                  </Select>
                </Stack>
                {map(entries(groupedData), ([title, programs]) => {
                  return (
                    <Fieldset
                      title={sortByDate ? dayjs(title).format('MM/YY') : title}
                    >
                      <Box p="1.5rem 0.5rem 0.5rem">
                        {map(programs, (p) => {
                          const {
                            id,
                            name,
                            aidCd,
                            eligibilityStatusCd,
                            eligibilityBeginDate,
                            programEligibilityId,
                            payMonth,
                          } = p;
                          const selected = id === selectedId;
                          const status = getStatus(eligibilityStatusCd);
                          const formattedPaymonth = formatPayMonth(payMonth);
                          return (
                            <BaseMenuButton
                              id={programEligibilityId}
                              avatar={<Avatar name={name} />}
                              details={
                                <Stack
                                  sx={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                  }}
                                >
                                  <Typography variant="subtitle1">
                                    {name}
                                  </Typography>
                                  <Fieldset
                                    title={getLkpMatch(
                                      'EligibilityStatusCode',
                                      eligibilityStatusCd
                                    )}
                                    size="small"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Status
                                        invert
                                        fullWdith={false}
                                        status={status}
                                        size="small"
                                      >
                                        {aidCd}
                                      </Status>
                                      <Typography
                                        variant="subtitle2"
                                        pr="0.5rem"
                                      >
                                        {formattedPaymonth}
                                      </Typography>
                                    </Stack>
                                  </Fieldset>
                                </Stack>
                              }
                              selected={selected}
                              onClick={() => onSelect(id)}
                            />
                          );
                        })}
                      </Box>
                    </Fieldset>
                  );
                })}
              </Stack>
            );
          }}
        >
          {(selected, options) => {
            const { renderUpDownButtons } = options;
            const { programEligibilityId } = selected;
            return (
              <div>
                {renderUpDownButtons()}
                <SummaryDetails
                  key={programEligibilityId}
                  getLkpMatch={getLkpMatch}
                  selected={selected}
                  caseId={caseId}
                />
              </div>
            );
          }}
        </SelectableView>
      </Stack>
    </>
  );
}
