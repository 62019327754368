import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import TabularView from '../../../../components/TabularView';
import Dropdown from '../../../../components/Dropdown';
import {
  doWorkerInfo,
  getLdssdocd,
  getSupvData,
} from '../../../../utils/services/apiRequests';
import { showErrorToast } from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import { filter, find, includes, map } from 'lodash';
import { useFormik } from 'formik';
import Field from '../../../../components/Field';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '../../../../components/Avatar';
import EmptyState from '../../../../components/EmptyState';
import NoRecord from '../../../../assets/icons/No-data-amico.svg';

const CaseWorkers = () => {
  const [rowData, setRowData] = useState([]);
  const [option, setOption] = useState([]);
  const [workerName, setWorkerName] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const columns = useMemo(
    () => [
      {
        headerName: 'Name',
        getValue({ firstName, lastName }) {
          return `${firstName + ' ' + lastName ?? '-'}`;
        },
      },
      {
        headerName: 'Email ID',
        getValue({ workerEmail }) {
          return `${workerEmail ?? '-'}`;
        },
      },
      {
        headerName: 'Roles',
        getValue({ roleName }) {
          if (typeof roleName !== 'string') {
            roleName = String(roleName);
          }
          return roleName.replace(/,/g, '  ') || '-';
        },
      },
    ],
    [rowData]
  );

  const getWorkInfo = async () => {
    try {
      showLoader();
      const res = await doWorkerInfo();
      if (res.status === 200) {
        setOption(res.data);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getWorkInfo();
  }, []);

  const formik = useFormik({
    initialValues: {
      ldssOffice: '',
      workerName: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      //   assign(values?.ldssOffice);
    },
  });

  const lookupData = useMemo(() => {
    return map(option, (value) => {
      return {
        label: value.districtOfficeName,
        value: value.districtOfficeCode,
      };
    });
  }, [option]);

  const nameData = useMemo(() => {
    return map(workerName, (worker) => ({
      label: `${worker?.first_name} ${worker?.last_name}`,
      value: worker.unique_id,
    }));
  }, [workerName]);

  const getOffice = async (value) => {
    try {
      showLoader();
      if (value != '') {
        const res = await getLdssdocd(value);
        if (res.status === 200) {
          setWorkerName(res.data);
        }
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const getSupervisorData = async (value) => {
    try {
      showLoader();
      const data = find(workerName, (item) => item.unique_id === value);
      setRoles(data);
      if (value != '') {
        const res = await getSupvData(value);
        if (res?.status === 200) {
          setRowData(res.data);
        }
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'ldssOffice') {
      formik.setFieldValue('ldssOffice', value);
      getOffice(value);
    } else if (name === 'workerName') {
      formik.setFieldValue('workerName', value);
      getSupervisorData(value);
    }
  };

  const worker = find(
    workerName,
    (item) => item.unique_id === formik.values.workerName
  );

  return (
    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
      <Box sx={{ border: '1px solid var(--grey-350)', borderRadius: '6px' }}>
        <Grid container sx={{ padding: '1rem' }}>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  background: '#EDF6FC',
                  borderRadius: '50%',
                  height: '4.5rem',
                  width: '4.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #44A0E3',

                  justifyContent: 'center',
                }}
              >
                <BusinessIcon />
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '1rem',
                  fontWeight: '700',

                  lineHeight: '2rem',
                  textAlign: 'left',
                  color: 'var(--grey-400)',
                }}
              >
                LDSS Office
              </Typography>
              <Field name="ldssOffice" formik={formik} customHandler>
                <Dropdown
                  name="ldssOffice"
                  options={lookupData}
                  value={formik?.values?.ldssOffice}
                  onChange={handleChange}
                />
              </Field>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  background: '#EDF6FC',
                  border: '1px solid #44A0E3',
                  borderRadius: '50%',
                  height: '4.3rem',
                  width: '4.3rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PersonIcon />
              </Box>
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '1rem',
                  fontWeight: '700',
                  lineHeight: '2rem',
                  textAlign: 'left',
                  color: 'var(--grey-400)',
                }}
              >
                Name
              </Typography>
              <Field name="workerName" formik={formik} customHandler>
                <Dropdown
                  name="workerName"
                  options={nameData}
                  value={formik?.values?.workerName}
                  onChange={handleChange}
                />
              </Field>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            padding: '1rem',
            borderRadius: '6px 6px 0px 0px',
            border: 'solid 1px var(--grey-350)',
            background: '#F2F9FD',
          }}
        >
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid
              item
              xs={4}
              sx={{ display: 'flex', gap: '6px', flexDirection: 'column' }}
            >
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '1rem',
                  fontWeight: '700',
                  lineHeight: '2rem',
                  textAlign: 'left',
                }}
              >
                Reporter Name
              </Typography>
              {worker?.first_name && (
                <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                  <Avatar
                    name={`${worker?.first_name ?? ''} ${
                      worker?.last_name ?? ''
                    }`}
                  />

                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '1.5rem',
                      fontWeight: '700',
                      color: 'var(--grey-400)',
                    }}
                  >
                    {`${worker?.first_name ?? ''} ${worker?.last_name ?? ''}`}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={7} sx={{ display: 'grid', gap: '6px' }}>
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '1rem',
                  fontWeight: '700',
                  lineHeight: '2rem',
                  textAlign: 'left',
                }}
              >
                Roles
              </Typography>
              <Stack
                sx={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}
              >
                {map(roles?.mdt_role_names, (item, index) => {
                  return <Typography variant="subtitle1">{item}</Typography>;
                })}
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            border: '1px solid var(--grey-350)',
          }}
        >
          <TabularView
            data={rowData ?? []}
            columns={columns}
            getId={(houseHoldMember) => houseHoldMember}
            slots={{
              noRowsOverlay: () => (
                <EmptyState
                  header="Currently No Records Available"
                  icon={
                    <img
                      src={NoRecord}
                      style={{
                        height: '160px',
                        width: '250px',
                      }}
                    />
                  }
                />
              ),
            }}
            renderHeader={(title) => (
              <Typography variant="h5" component="strong">
                {title}
              </Typography>
            )}
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '50vh',
              },
              minHeight: '50vh',
              table: {
                width: '100%',
                borderCollapse: 'collapse',
              },
              th: {
                padding: '12px 20px',
                textAlign: 'center',
                borderBottom: '2px solid #e2e2e7',
              },
              td: {
                padding: '12px 20px',
                textAlign: 'center',
              },
              tr: {
                ':nth-child(even)': {
                  backgroundColor: '#f9f9f9',
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CaseWorkers;
