import React, { useEffect } from 'react';
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Divider,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import {
  validateEarnIncomeAmountDetails,
  validateEarnIncomeDetails,
} from '../validationUtils';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
}));

const EarnedIncomeDetails = ({
  lkpRaw,
  incomeAmount,
  incomeIndex,
  formik,
  isAdd,
  getOptions,
}) => {
  const classes = useStyles();
  const frequencyLoop = {
    AC: 2,
    AN: 1,
    BM: 1,
    BW: 3,
    MO: 1,
    OT: 1,
    QU: 1,
    SA: 1,
    WE: 5,
  };
  const handleRemoveIncomeAmount = (incomeIndex) => {
    const updatedRemoveIncomeAmount = [...formik.values.earnedIncomeAmount];
    updatedRemoveIncomeAmount.splice(incomeIndex, 1);
    formik.setFieldValue('earnedIncomeAmount', updatedRemoveIncomeAmount);
  };

  const handleAddExpenses = (incomeIndex) => {
    const updatedAddExpenses = [...formik.values.earnedIncomeAmount];
    updatedAddExpenses[incomeIndex].expenses.push({
      type: '',
      amount: '',
      hours: '',
      verificationSourceCode: '',
    });

    formik.setFieldValue('earnedIncomeAmount', updatedAddExpenses);
  };

  const handleRemoveExpenses = (incomeIndex, expenseIndex) => {
    const updatedRemoveExpenses = [...formik.values.earnedIncomeAmount];
    updatedRemoveExpenses[incomeIndex].expenses.splice(expenseIndex, 1);
    formik.setFieldValue('earnedIncomeAmount', updatedRemoveExpenses);
  };

  const handleLateReportChange = (event, index) => {
    const { name, value, checked } = event.target;
    formik.setFieldValue(
      `earnedIncomeAmount[${incomeIndex}].${name}`,
      checked ? 'Y' : 'N'
    );
  };

  const handleEarnIncomeChange = (event, incomeIndex) => {
    const { name, value } = event.target;
    formik.setFieldValue(`earnedIncomeAmount[${incomeIndex}].${name}`, value);
    const loopCount = frequencyLoop[value];
    const fields = Array.from({ length: loopCount }, (_, index) => ({
      type: '',
      amount: '',
      hours: '',
      verificationSourceCode: '',
    }));
    formik.setFieldValue(
      `earnedIncomeAmount[${incomeIndex}].amountModel`,
      fields
    );
  };

  function formatCurrency(value) {
    let converToCurrency = value.toString().replace(/\D/g, '');
    converToCurrency = converToCurrency.replace(/(\d)(\d{2})$/, '$1.$2');
    converToCurrency = converToCurrency.replace(/(?=(\d{3})+(\D))\B/g, ',');
    return converToCurrency;
  }

  const handlePayAmount = (event, index) => {
    const { name, value } = event.target;
    if (name === 'amount') {
      let usFormatCurrency = formatCurrency(value);

      formik.setFieldValue(
        `earnedIncomeAmount.[${incomeIndex}].amountModel[${index}].${name}`,
        usFormatCurrency
      );
    } else {
      formik.setFieldValue(
        `earnedIncomeAmount.[${incomeIndex}].amountModel[${index}].${name}`,
        value
      );
    }
  };

  const handleExpenseAmount = (event, index) => {
    const { name, value } = event.target;
    if (name === 'amount') {
      let usFormatCurrency = formatCurrency(value);
      formik.setFieldValue(
        `earnedIncomeAmount.[${incomeIndex}].expenses[${index}].${name}`,
        usFormatCurrency
      );
    } else {
      formik.setFieldValue(
        `earnedIncomeAmount.[${incomeIndex}].expenses[${index}].${name}`,
        value
      );
    }
  };

  const handleAmountDatePicker = (name, date, incomeIndex) => {
    if (name === 'useMonth') {
      formik.setFieldValue(
        `earnedIncomeAmount[${incomeIndex}].${name}`,
        dayjs(date).format('YYYYMM')
      );
    } else if (name === 'effectiveEndDate') {
      if (date === null) {
        formik.setFieldValue(
          `earnedIncomeAmount[${incomeIndex}].${name}`,
          null
        );
      }
    } else {
      formik.setFieldValue(
        `earnedIncomeAmount[${incomeIndex}].${name}`,
        dayjs(date).format('YYYY-MM-DD')
      );
    }
  };

  const chechExpenseFieldFilled = () => {
    const { earnedIncomeAmount } = formik.values;
    for (let i = 0; i < earnedIncomeAmount.length; i++) {
      const income = earnedIncomeAmount[i];
      let isEmptyAmountModel = income.expenses.some(
        (item) =>
          item.amount === '' ||
          item.verificationSourceCode === '' ||
          item.type === ''
      );
      if (isEmptyAmountModel) {
        return false;
      }
    }
    return true;
  };
  return (
    <>
      <Box mx={2}>
        <Stack direction="row" pt={1} justifyContent="space-between">
          <Typography variant="h6">Earned Income {incomeIndex + 1}</Typography>
          {formik?.values?.earnedIncomeAmount?.length > 1 && (
            <div
              onClick={() => handleRemoveIncomeAmount(incomeIndex)}
              style={{ cursor: 'pointer' }}
            >
              <DeleteIcon sx={{ color: 'red' }} />
            </div>
          )}
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mt={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateEarnIncomeDetails(
                      formik,
                      incomeIndex,
                      'effectiveBeginDate'
                    ),
                  })}
                  label="Amount Start Date *"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  minDate={dayjs(formik.values.employer.employmentBeginDate)}
                  slotProps={{ textField: { size: 'small' } }}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(date) =>
                    handleAmountDatePicker(
                      'effectiveBeginDate',
                      date,
                      incomeIndex
                    )
                  }
                  value={
                    incomeAmount.effectiveBeginDate
                      ? dayjs(incomeAmount.effectiveBeginDate)
                      : null
                  }
                />
              </DemoContainer>
              {validateEarnIncomeDetails(
                formik,
                incomeIndex,
                'effectiveBeginDate'
              ) && (
                <FormHelperText error sx={{ ml: '10px' }}>
                  {
                    formik.errors?.earnedIncomeAmount[incomeIndex]
                      ?.effectiveBeginDate
                  }
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box my={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateEarnIncomeDetails(
                      formik,
                      incomeIndex,
                      'effectiveEndDate'
                    ),
                  })}
                  label="Amount End Date"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  minDate={dayjs(formik.values.employer.employmentBeginDate)}
                  slotProps={{ textField: { size: 'small' } }}
                  name="effectiveEndDate"
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    incomeAmount.effectiveEndDate
                      ? dayjs(incomeAmount.effectiveEndDate).year() === 9999
                        ? null
                        : dayjs(incomeAmount.effectiveEndDate)
                      : null
                  }
                  onChange={(date) =>
                    handleAmountDatePicker(
                      'effectiveEndDate',
                      date,
                      incomeIndex
                    )
                  }
                />
              </DemoContainer>
            </Box>
            {validateEarnIncomeDetails(
              formik,
              incomeIndex,
              'effectiveEndDate'
            ) && (
              <FormHelperText error sx={{ ml: '10px' }}>
                {
                  formik.errors?.earnedIncomeAmount[incomeIndex]
                    ?.effectiveEndDate
                }
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        {incomeAmount.lateReportIndicator === 'Y' && (
          <Box>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="body1">Report Information</Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box mt={1} mb={1}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      className={cx({
                        [classes.customTextField]: validateEarnIncomeDetails(
                          formik,
                          incomeIndex,
                          'useMonth'
                        ),
                      })}
                      label="Use Month *"
                      sx={{ width: '100%' }}
                      margin="normal"
                      views={['year', 'month']}
                      openTo="month"
                      format="MM/YYYY"
                      slotProps={{ textField: { size: 'small' } }}
                      name="useMonth"
                      value={
                        incomeAmount.useMonth
                          ? dayjs(
                              incomeAmount.useMonth.toString(),
                              'YYYYMM'
                            ).endOf('month')
                          : null
                      }
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleAmountDatePicker('useMonth', date, incomeIndex)
                      }
                    />
                  </DemoContainer>
                  {validateEarnIncomeDetails(
                    formik,
                    incomeIndex,
                    'useMonth'
                  ) && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      Required
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1} mb={1}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label="Report Date"
                      sx={{ width: '100%' }}
                      margin="normal"
                      format="YYYY-MM-DD"
                      slotProps={{ textField: { size: 'small' } }}
                      name="reportDate"
                      value={
                        incomeAmount.reportDate
                          ? dayjs(incomeAmount.reportDate)
                          : null
                      }
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleAmountDatePicker('reportDate', date, incomeIndex)
                      }
                    />
                  </DemoContainer>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]:
                  validateEarnIncomeDetails(formik, incomeIndex, 'frequency') &&
                  incomeAmount.frequency === '',
              })}
            >
              <InputLabel id="frequency">Frequency *</InputLabel>
              <Select
                labelId="frequency"
                id="select-frequency"
                size="small"
                label="Frequency *"
                name="frequency"
                onChange={(event) => handleEarnIncomeChange(event, incomeIndex)}
                value={incomeAmount.frequency}
              >
                {getOptions('Frequency').map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              {validateEarnIncomeDetails(formik, incomeIndex, 'frequency') &&
                incomeAmount.frequency === '' && (
                  <FormHelperText error sx={{ ml: '10px' }}>
                    {formik.errors.earnedIncomeAmount[incomeIndex].frequency}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box mt={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleLateReportChange(e, incomeIndex)}
                    name="lateReportIndicator"
                    checked={incomeAmount.lateReportIndicator === 'Y'}
                  />
                }
                label="Late Report"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {incomeAmount?.amountModel.map((data, index) => {
        let amountModelLength = incomeAmount?.amountModel.length;
        return (
          <Box mx={2}>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="body1">
                {amountModelLength > 1 ? `Pay ${index + 1}` : 'Pay'}
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Hours"
                      fullWidth
                      value={data.hours}
                      name="hours"
                      InputProps={{
                        onInput: (event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]/g,
                            ''
                          );
                        },
                        inputProps: {
                          maxLength: 5,
                        },
                      }}
                      margin="normal"
                      onChange={(event) => handlePayAmount(event, index)}
                      size="small"
                      required
                      error={validateEarnIncomeAmountDetails(
                        formik,
                        incomeIndex,
                        index,
                        'amountModel',
                        'hours'
                      )}
                      helperText={
                        validateEarnIncomeAmountDetails(
                          formik,
                          incomeIndex,
                          index,
                          'amountModel',
                          'hours'
                        )
                          ? 'Required'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Amount"
                      fullWidth
                      value={data.amount}
                      name="amount"
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onChange={(event) => handlePayAmount(event, index)}
                      required
                      size="small"
                      error={validateEarnIncomeAmountDetails(
                        formik,
                        incomeIndex,
                        index,
                        'amountModel',
                        'amount'
                      )}
                      helperText={
                        validateEarnIncomeAmountDetails(
                          formik,
                          incomeIndex,
                          index,
                          'amountModel',
                          'amount'
                        )
                          ? 'Required'
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  className={cx({
                    [classes.customTextField]: validateEarnIncomeAmountDetails(
                      formik,
                      incomeIndex,
                      index,
                      'amountModel',
                      'verificationSourceCode'
                    ),
                  })}
                >
                  <InputLabel id="verification">
                    Select Verification *
                  </InputLabel>
                  <Select
                    labelId="verification"
                    id="select-verification"
                    name="verificationSourceCode"
                    value={data.verificationSourceCode}
                    size="small"
                    label="Select Verification *"
                    onChange={(event) => handlePayAmount(event, index)}
                  >
                    {getOptions('CLUIAMTVERCD').map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {validateEarnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'amountModel',
                    'verificationSourceCode'
                  ) && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      })}
      <Stack direction="row" justifyContent="space-between" mx={2} my={2}>
        <Typography variant="h6">Earned Income Expenses</Typography>
        <Button
          variant="text"
          size="small"
          p={0.5}
          color="primary"
          onClick={() => handleAddExpenses(incomeIndex)}
          sx={{ cursor: 'pointer' }}
          disabled={!chechExpenseFieldFilled()}
        >
          + Add Expense
        </Button>
      </Stack>
      {incomeAmount?.expenses &&
        incomeAmount?.expenses.map((data, index) => (
          <Box mx={2} key={index}>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="body1">Expenses {index + 1}</Typography>
              <div
                onClick={() => handleRemoveExpenses(incomeIndex, index)}
                style={{ cursor: 'pointer' }}
              >
                <DeleteIcon sx={{ color: 'red' }} />
              </div>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl
                  fullWidth
                  margin="normal"
                  className={cx({
                    [classes.customTextField]: validateEarnIncomeAmountDetails(
                      formik,
                      incomeIndex,
                      index,
                      'expenses',
                      'type'
                    ),
                  })}
                >
                  <InputLabel id="select-type">Select Type</InputLabel>
                  <Select
                    labelId="select-type"
                    id="select-type"
                    label="Select Type"
                    name="type"
                    size="small"
                    value={data.type}
                    onChange={(event) => handleExpenseAmount(event, index)}
                  >
                    {getOptions('EarnedIncomeExpense').map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {validateEarnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'expenses',
                    'type'
                  ) && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Amount"
                  fullWidth
                  margin="normal"
                  name="amount"
                  value={data.amount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  required
                  inputProps={{ maxLength: 11 }}
                  onChange={(event) => handleExpenseAmount(event, index)}
                  error={validateEarnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'expenses',
                    'amount'
                  )}
                  helperText={
                    validateEarnIncomeAmountDetails(
                      formik,
                      incomeIndex,
                      index,
                      'expenses',
                      'amount'
                    )
                      ? 'Required'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  className={cx({
                    [classes.customTextField]: validateEarnIncomeAmountDetails(
                      formik,
                      incomeIndex,
                      index,
                      'expenses',
                      'verificationSourceCode'
                    ),
                  })}
                >
                  <InputLabel id="verification">Select Verification</InputLabel>
                  <Select
                    labelId="verification"
                    id="select-verification"
                    name="verificationSourceCode"
                    value={data.verificationSourceCode}
                    label="Select Verification"
                    size="small"
                    onChange={(event) => handleExpenseAmount(event, index)}
                  >
                    {getOptions('EmploymentVerification').map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {validateEarnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'expenses',
                    'verificationSourceCode'
                  ) && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ))}
    </>
  );
};

export default EarnedIncomeDetails;
