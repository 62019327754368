import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import useCalendar from '../../../hooks/useCalendar';
import { map } from 'lodash';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import cx from 'classnames';
import {
  getCaseWorkerDashboard,
  getCaseWorkerDashboardNew,
} from '../../../utils/services/apiRequests';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';
import dayjs from 'dayjs';
import usePageNav from '../../../hooks/usePageNav';
export default function UpComing() {
  const {
    days,
    onPrevMonth,
    onNextMonth,
    onPrevWeek,
    onNextWeek,
    onNextDay,
    onPrevDay,
    onSelectDate,
  } = useCalendar();

  const [rowData, setRowData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { caseId, navigations } = usePageNav();

  const dateSelect = (data) => {
    onSelectDate(data);
    setSelectedDate(data);
  };

  const fetchData = async () => {
    const payload = {
      interviewFromDate: dayjs(selectedDate).format('YYYY-MM-DD'),
      interviewToDate: dayjs(selectedDate).format('YYYY-MM-DD'),
    };
    try {
      showLoader();
      const res = await getCaseWorkerDashboardNew(payload);
      if (res.status === 200) {
        setRowData(res?.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  return (
    <Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        p={1}
      >
        <Typography variant="h2">UpComing Events</Typography>
        <Stack direction={'row'} spacing={2}>
          <Button onClick={onPrevMonth} className={styles.muimonthbutton}>
            <KeyboardDoubleArrowLeftRoundedIcon />
          </Button>
          <ButtonGroup className={styles.muimonthbutton}>
            <Button onClick={onPrevWeek} className={styles.muimonthbutton}>
              <ArrowBackIosNewRoundedIcon fontSize="small" />
            </Button>
            <Button onClick={onNextWeek} className={styles.muimonthbutton}>
              <ArrowForwardIosRoundedIcon fontSize="small" />
            </Button>
          </ButtonGroup>
          <Button className={styles.muimonthbutton} onClick={onNextMonth}>
            <KeyboardDoubleArrowRightRoundedIcon />
          </Button>
        </Stack>
      </Stack>
      <Divider />

      <Grid container justifyContent={'center'} height={'100%'}>
        <Grid item className={styles.calendarOverview}>
          <Button onClick={onPrevDay} className={styles.muiButtonLeft}>
            {<ArrowBackIosNewRoundedIcon />}
          </Button>
          {map(days, (val) => (
            <Grid
              item
              sm={1.52}
              className={cx(styles.calendarDesign, {
                [styles.disable]: val.disabled,
                [styles.selected]: !val.disabled && val.isSelected,
              })}
              onClick={() => dateSelect(val.date)}
            >
              <Typography variant="subtitle1">{val.weekDay}</Typography>
              <Typography variant="h3">{val.day}</Typography>
              <Typography variant="h5">{val.month}</Typography>
            </Grid>
          ))}
          <Button className={styles.muiButtonRight} onClick={onNextDay}>
            {<ArrowForwardIosRoundedIcon />}
          </Button>
        </Grid>
      </Grid>
      {/* <Box sx={{ height: '15rem', overflow: 'scroll' }}>
        {map(rowData, (data) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              mt={2}
              padding={1}
              alignItems="center"
            >
              <Stack direction="row" alignItems="center">
                <Avatar>{data?.name}</Avatar>
                <Avatar name={data?.name} />
                <Stack paddingLeft={1}>
                  <Typography>
                    <strong style={{ color: 'var(--primary-dark)' }}>
                      {data?.caseId}
                    </strong>
                    &nbsp; Applied By &nbsp;
                    <strong style={{ color: 'var(--grey-400)' }}>
                      {data?.name}
                    </strong>
                  </Typography>
                  <Typography>
                    <strong style={{ color: 'var(--grey-400)' }}>
                      {dayjs(data?.appointmentDate).format(
                        'MMMM D, YYYY h:mm A'
                      )}
                    </strong>
                    &nbsp; To&nbsp;{' '}
                    <strong style={{ color: 'var(--grey-400)' }}>
                      12:45 PM
                    </strong>
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Button
                  variant="contained"
                  onClick={() => navigations.toCaseHome(data?.caseId)}
                >
                  Evaluate
                </Button>
              </Stack>
            </Stack>
          );
        })}
      </Box> */}

      <Divider />
    </Stack>
  );
}
