import {
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import React from 'react';
import Field from '../../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import {
  formatCurrency,
  maxLength,
  onlyAlphabet,
} from '../../../../utils/normalizers';
import { postHarship } from '../../../../utils/services/ManualNotices/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';

export default function HardshipExemption({
  toggleClose,
  noticeData,
  caseId,
  individualId,
  postSearchNotice,
  editData,
  programName,
}) {
  console.log(editData, 'edit');
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();

  const formik = useFormik({
    initialValues: {
      date: noticeData?.applicationDate ?? dataValue?.applicationDate ?? '',
      maNo: noticeData?.applicantDetails?.[0]?.maId ?? dataValue?.maNo ?? '',
      name: noticeData?.ldssDoName ?? dataValue?.ldssName ?? '',
      address: noticeData?.ldssStrtAddr1 ?? dataValue?.ldssAddr1 ?? '',
      address1:
        dataValue?.ldssAddr2 ??
        `${noticeData?.ldssCity ?? ''}, ${noticeData?.ldssState ?? ''}, ${
          noticeData?.ldssZipcode ?? ''
        }` ??
        '',
      myName:
        dataValue?.hohName ??
        `${noticeData?.applicantDetails?.[0]?.firstName} ${noticeData?.applicantDetails?.[0]?.lastName}` ??
        '',
      amount: dataValue?.medAmnt ?? '',
      reason1: dataValue?.reason1 ?? '',
      reason2: dataValue?.reason2 ?? '',
      reason3: dataValue?.reason3 ?? '',
      reason4: dataValue?.reason4 ?? '',
      reason5: dataValue?.reason5 ?? '',
      reason6: dataValue?.reason6 ?? '',
      reason7: dataValue?.reason7 ?? '',
      reason8: dataValue?.reason8 ?? '',
      socialSecurity: dataValue?.soSecIncm ?? '',
      earnigs: dataValue?.earningIncm ?? '',
      vaNum: dataValue?.vaIncm ?? '',
      other1: dataValue?.otherType1 ?? '',
      otherAmnt1: dataValue?.otherIncm1 ?? '',
      other2: dataValue?.otherType2 ?? '',
      otherAmnt2: dataValue?.otherIncm2 ?? '',
      grossIncome: dataValue?.totalgrossExp ?? '',
      mortage: dataValue?.mortageRentExp ?? '',
      medicalExp: dataValue?.medExp ?? '',
      food: dataValue?.foodExp ?? '',
      electric: dataValue?.electricGasExp ?? '',
      medicalGrossIncome: dataValue?.totalgrossIncm ?? '',
      signature: dataValue?.signtre ?? '',
      signDate: dataValue?.signdDate ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      hardshipExemption(values);
    },
  });

  const hardshipExemption = async (data) => {
    try {
      showLoader();
      const res = await postHarship(data);
      if (res.status === 200) {
        showSuccessToast('Successfully Created');

        toggleClose();
        postSearchNotice();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Grid container ml="2rem">
          <Grid item xs={10}>
            <Typography
              variant="h3"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              EMPLOYED INDIVIDUALS WITH DISABILITIES (EID) PROGRAM
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h3"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              APPLICANT/RECIPIENT CLAIM FOR HARDSHIP EXEMPTION
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h4grey"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Eligibility Determination Division (EDD)
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          mr="1rem"
          mt="1rem"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Grid item xs={8}>
            <Field name="date" label="Date" formik={formik} isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: 'flex',
              alignItems: 'end',
            }}
          >
            <Field name="maNo" label="MA No." formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
        </Grid>
        <Grid
          container
          mt="1rem"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Grid item xs={8}>
            <Field
              name="name"
              label="Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={8}>
            <Field name="address" label="Address" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={8}>
            <Field name="address1" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10} mt="1rem">
            <Typography variant="subtitle1">
              Dear Division of Eligibility Waiver Services Case Manager,
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Field name="myName" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={4} mt="0.5rem">
            <Typography variant="subtitle1">
              am requesting a review of my case for a “Claim
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1">
              of Hardship” (COMAR 10.09.41.07C.) for the exemption of paying my
              premium of
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Field name="amount" formik={formik} normalizers={[formatCurrency]}>
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              for Medical Assistance (Medicaid) benefits under the Employed
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1">
              Individuals with Disabilities (EID) Program.
            </Typography>
          </Grid>
          <Grid item xs={10} mt="1rem">
            <Typography variant="subtitle1">
              The reason I am requesting this is because:<br></br> (Please
              specifying the underlying circumstances payment of the enrollment
              fee would compromise your ability to obtain and provide basic
              food, shelter, and clothing. You may attach additional pages if
              needed.)
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason1" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason2" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason3" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason4" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason5" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason6" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason7" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Field name="reason8" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          mt="1rem"
          ml="1rem"
          mr="1rem"
          mb="1rem"
        >
          <Typography variant="h6">
            DHMH EID 2A Claim for Hardship Exemtion
          </Typography>
          <Typography variant="h6">Page 1 of 2</Typography>
        </Stack>
      </Grid>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Grid container spacing={2} ml="2rem" mr="2rem" mt="1rem">
          <Grid item xs={10}>
            <Typography variant="standard">
              I expect the hardship to prevent me from paying my premium and/or
              prevents me from working for (circle one): 1 2 3 4 5 6 months.
              (Hardship cannot be granted for more than six months.) Please
              include documentation to verify expenses, income and resources for
              the months which a hardship is being requested.
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1">
              Please list all sources of income you receive.
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="subtitle1"
              sx={{
                textDecoration: 'underLine',
              }}
            >
              Monthly Income
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="standard">Social Security:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Field
              name="socialSecurity"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.socialSecurity}
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="standard">Earings:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Field
              name="earnigs"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.earnigs}
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="standard">V.A:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Field name="vaNum" formik={formik} normalizers={[formatCurrency]}>
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.vaNum}
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="standard">Other:</Typography>
          </Grid>
          <Grid item xs={2}>
            <Field name="other1" formik={formik}>
              <TextField variant="standard" value={formik.values.other1} />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="otherAmnt1"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.otherAmnt1}
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="standard">Other:</Typography>
          </Grid>
          <Grid item xs={2}>
            <Field name="other2" formik={formik}>
              <TextField variant="standard" value={formik.values.other2} />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="otherAmnt2"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.otherAmnt2}
              />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Divider />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1">
              Total Monthly Gross Income
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="grossIncome"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.grossIncome}
              />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1">
              Please complete the information for the following expenses.
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="subtitle1"
              sx={{
                textDecoration: 'underLine',
              }}
            >
              Monthly Expenses:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Mortage or Rent</Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="mortage"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.mortage}
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Medical Expenses</Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="medicalExp"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.medicalExp}
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Food</Typography>
          </Grid>
          <Grid item xs={6}>
            <Field name="food" formik={formik} normalizers={[formatCurrency]}>
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.food}
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Electric or Gas</Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="electric"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.electric}
              />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Total Monthly Gross Income
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="medicalGrossIncome"
              formik={formik}
              normalizers={[formatCurrency]}
            >
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={formik.values.medicalGrossIncome}
              />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Divider />
          </Grid>
          <Grid item xs={5}>
            <Field name="signature" formik={formik} label="Signature">
              <TextField variant="standard" value={formik.values.signature} />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="signDate" formik={formik} label="Date" isDate>
              <DatePicker
                disableFuture
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
                value={formik.values.signDate}
              />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1">
              The Department shall evaluate the claim of hardship and notify you
              of its decision within 30 days of the Department's receipt of the
              written claim of hardship. If the Department determines that the
              applicant or recipient's claim of hardship is without merit, the
              applicant or recipient shall pay the applicable premium within 10
              days after the date the Department's notice is issued.
            </Typography>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          mt="1rem"
          ml="2rem"
          mr="1rem"
          mb="1rem"
        >
          <Typography variant="h6">
            DHMH EID 2A Claim for Hardship Exemtion
          </Typography>
          <Typography variant="h6">Page 2 of 2</Typography>
        </Stack>
        <Grid ml="2rem" mb="1rem" mt="1rem">
          <Typography variant="h5">MDH Form 800 Hardship Exemption</Typography>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt="1rem" spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Disacrd
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
