import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Dropdown from '../Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import { FormControl } from '@mui/material';
import dayjs from 'dayjs';
import { getDefaultDate } from '../../utils/helpers';
import { normalizeFormField } from '../../utils/formUtils';
import { noop } from 'lodash';

function Field({
  name,
  label,
  formik,
  required,
  fullWidth,
  children,
  errorText,
  isDate,
  customHandler,
  onBlur,
  showError,
  normalizers,
  ...rest
}) {
  const { onChange, value, ...fieldProps } = formik.getFieldProps(name);
  const error = (formik.touched[name] && formik.errors[name]) ?? errorText;
  const _value = isDate ? getDefaultDate(value) : value;

  const onChangeDate = (date) => {
    formik.setFieldValue(name, date ? dayjs(date).format('YYYY-MM-DD') : null);
  };

  const _onChange = (event) => {
    if (isDate) {
      onChangeDate(event);
    } else if (normalizers) {
      event.target.value = normalizeFormField(event.target.value, normalizers);
      onChange(event);
    } else {
      onChange(event);
    }
  };

  const hanldeBlur = (e) => {
    console.log('blur', e);
    // onBlur(e);
    if (isDate && !formik.touched[name]) {
      formik.setFieldTouched(name);
    }
  };

  const inputProps = customHandler
    ? {}
    : {
        ...fieldProps,
        onChange: _onChange,
        value: _value,
        name,
        id: name,
        error,
        // ...(isDate ? { onOpen: hanldeBlur } : {}),
      };

  return (
    <FormControl
      error={error}
      fullWidth={fullWidth}
      sx={{ position: 'relative' }}
    >
      <FormLabel sx={{ color: '#000000', textAlign: 'left' }}>
        <Typography variant="subtitle2">
          {label}
          {required && <span style={{ color: 'red' }}>*</span>}
        </Typography>
      </FormLabel>
      {cloneElement(children, inputProps)}

      {error && showError && (
        <FormHelperText
          sx={{ ml: 0, position: 'absolute', bottom: '-1.5rem' }}
          error
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
}

Field.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  formik: PropTypes.shape({
    getFieldProps: PropTypes.func,
    onChange: PropTypes.func,
  }),
  fullWidth: PropTypes.bool,
  date: PropTypes.bool,
  children: PropTypes.node,
  errorText: PropTypes.string,
  customHandler: PropTypes.bool,
  onBlur: PropTypes.func,
  showError: PropTypes.bool,
  normalizers: PropTypes.arrayOf(PropTypes.func),
};

Field.defaultProps = {
  name: '',
  label: '',
  formik: {
    getFieldProps: () => ({}),
    onChange: () => {},
    touched: {},
    errors: {},
  },
  fullWidth: true,
  date: false,
  children: '',
  errorText: '',
  customHandler: false,
  onBlur: noop,
  showError: true,
  normalizers: null,
};

export default Field;
