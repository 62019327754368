import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PageContainer from '../../../components/PageContainer';
import SelectableView from '../../../components/SelectableView';
import Status from '../../../components/Status';
import SummaryDetails from './SummaryDetails';
import BaseMenuButton from '../../../components/SelectableView/BaseMenuButton';
import Avatar from '../../../components/Avatar';
import { entries, groupBy, map } from 'lodash';
import useLookup from '../../../hooks/useLookup';
import mock from './mock.json';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Fieldset from '../../../components/Fieldset';
import {
  cond,
  formatPayMonth,
  getFullName,
  getStatus,
  numbersToDate,
} from '../../../utils/helpers';
import dayjs from 'dayjs';
import useMount from '../../../hooks/useMount';
import { showErrorToast } from '../../../utils/services/toast';
import {
  runEligibility,
  runMonthElibility,
} from '../../../utils/services/eligibility/apiRequests';
import usePageNav from '../../../hooks/usePageNav';
import FormSection from '../../../components/FormSection';
import { DatePicker } from '@mui/x-date-pickers';
import useLoader from '../../../hooks/useLoader';
import { useFormik } from 'formik';
import * as yup from 'yup';
import usePage from '../../../hooks/usePage';
import { useDispatch } from 'react-redux';
import { setEligibility } from '../../../store/slices/eligibilitySlice';
import useTabs from '../../../hooks/useTabs';
import TabularView from '../../../components/TabularView';
import EmptyState from '../../../components/EmptyState';
import { navigate } from '@storybook/addon-links';
import NoRecord from '../../../assets/icons/checklist-cuate.svg';

// const list = [
//   {
//     name: 'James Bond',
//     gender: 'Male',
//   },
//   {
//     name: 'Jennie Bond',
//     gender: 'Female',
//   },
//   {
//     name: 'Williams Bond',
//     gender: 'Male',
//   },
//   {
//     name: 'Catherene Bond',
//     gender: 'Female',
//   },
//   {
//     name: 'James Bond',
//     gender: 'Male',
//   },
//   {
//     name: 'James Bond',
//     gender: 'Male',
//   },
// ];

function EligibilitySummary({ id }) {
  const { Tabs, Tab, TabContent, onTabChange, activeTab } = useTabs();
  const [fetching, setFetching] = useState(false);
  const { navProps } = usePage(id);
  const { caseId, navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [eligbilityData, setEligbilityData] = useState({});
  const dispatch = useDispatch();
  // const [eligbilityData, setEligbilityData] = useState(mock);
  const { getLkpMatch } = useLookup('Eligibility');
  const [apiError, setApiError] = useState(false);
  const [sortBy, setSortBy] = useState('P');
  const { agEligResultList = [], checkListItem = [] } = eligbilityData ?? {};
  // const { eligibilityStartDate = null, eligibilityEndDate = null } =
  //   eligbilityData;

  const validationSchema = yup.object({
    fromDate: yup.string().required(),
    toDate: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      fromDate: null,
      toDate: null,
      waiveAdverseActionInd: 'N',
    },
    validationSchema,
  });

  const { fromDate, toDate } = formik.values;

  const onSort = (e) => {
    setSortBy(e.target.value);
  };

  const runMonths = async () => {
    try {
      if (apiError) {
        setApiError(false);
      }
      setFetching(true);
      // showLoader();
      const res = await runMonthElibility(caseId);

      if (res.status === 200) {
        const { agEligResultList } = res?.data;
        setEligbilityData(res.data);
        dispatch(setEligibility(agEligResultList));
        const { eligibilityStartDate = null, eligibilityEndDate = null } =
          res.data ?? {};
        formik.setValues({
          ...formik.values,
          fromDate: eligibilityStartDate,
          toDate: eligibilityEndDate,
          waiveAdverseActionInd: 'N',
        });
      }
    } catch (error) {
      setApiError(true);
      showErrorToast(error);
    } finally {
      setFetching(false);
      // hideLoader();
    }
  };

  const fetchEligibility = async () => {
    try {
      setFetching(true);
      const res = await runEligibility(caseId, formik.values);

      if (res.status === 200) {
        const { apiAgEligResults } = res?.data;
        if (apiAgEligResults) {
          dispatch(setEligibility(apiAgEligResults));
        }

        setEligbilityData({
          ...eligbilityData,
          agEligResultList: apiAgEligResults,
        });
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetching(false);
    }
  };

  useMount(() => {
    runMonths();
  });

  const onNextPage = () => {
    navigations.toConfirmation();
  };

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Person Name',
        getValue({ firstName, lastName }) {
          return getFullName(firstName, lastName);
        },
      },
      {
        headerName: 'Program',
        getValue({ programName }) {
          return getLkpMatch('Program', programName);
        },
      },
      {
        headerName: 'Pay Month',
        getValue({ payMonth }) {
          return formatPayMonth(payMonth, 'MM/YYYY');
        },
      },
      {
        headerName: 'Verification Name',
        getValue({ verificationName }) {
          return getLkpMatch('VerificationNames1052', verificationName);
        },
      },
      {
        headerName: 'Due Date',
        getValue({ dueDate }) {
          return dayjs(dueDate).format('MM/DD/YYYY');
        },
      },
    ];
  }, [getLkpMatch]);
  const onBackPage = () => {
    navigations.toRedeterminationhistory();
  };

  return (
    <PageContainer
      // navProps={navProps}
      title="Eligibility Details"
      onBack={onBackPage}
      onNext={onNextPage}
    >
      <Stack spacing={2}>
        <Grid
          container
          component={Paper}
          rowGap={1}
          sx={{ bgcolor: 'white', p: '1rem 1rem' }}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Eligibility Calculation Month</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" sx={{ alignItems: 'baseline' }}>
              <Typography mr="0.25rem">From</Typography>
              <DatePicker
                disabled={fetching}
                onChange={(date) =>
                  formik.setFieldValue(
                    'fromDate',
                    dayjs(date).format('YYYY-MM-DD')
                  )
                }
                maxDate={dayjs(toDate)}
                value={dayjs(fromDate)}
              />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" sx={{ alignItems: 'baseline' }}>
              <Typography ml="1rem" mr="0.25rem">
                To
              </Typography>
              <DatePicker value={dayjs(toDate)} disabled />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              sx={{ ml: '1rem' }}
              control={
                <Checkbox
                  disabled={fetching}
                  value={formik.values.waiveAdverseActionInd === 'Y'}
                  onChange={(_e, checked) =>
                    formik.setFieldValue(
                      'waiveAdverseActionInd',
                      checked ? 'Y' : 'N'
                    )
                  }
                />
              }
              label="Waive Adverse Action"
            />
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            item
            xs={3}
          >
            <Button
              disabled={fetching}
              variant="contained"
              onClick={fetchEligibility}
            >
              Run Eligibility
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Stack sx={{ display: 'flex', flexGrow: 1 }}>
        <Tabs onChange={onTabChange} value={activeTab}>
          <Tab label="Eligibility Summary" />
          <Tab label="Verification Checklist" />
        </Tabs>
        <TabContent
          sx={{ border: '1px solid #C2C2B7', borderRadius: '0.25rem' }}
        >
          <Stack sx={{ flexGrow: 1 }} area-label="eligibility summary">
            <SelectableView
              loading={fetching}
              loadingContent={fetching}
              title="Eligibility Summary"
              data={agEligResultList}
              getId={({ programEligibilityId }) => programEligibilityId}
              getName={({ targetFirstName, targetLastName }) =>
                `${targetFirstName} ${targetLastName}`
              }
              customHeader
              image={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
              customMenu
              showTitleBar={false}
              noData={apiError}
              tryAgain={fetchEligibility}
              renderMenu={(data, options) => {
                const { selectedId, onSelect } = options;
                const sortByDate = sortBy === 'D';
                const groupedData = groupBy(data, (d) =>
                  sortByDate ? d.programBeginDate : d.programName
                );
                return (
                  <Stack spacing={2}>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '17rem',
                      }}
                    >
                      <Typography
                        sx={{ marginLeft: 'auto', marginRight: '0.5rem' }}
                        variant="h5"
                      >
                        Sort by
                      </Typography>
                      <Select
                        onChange={onSort}
                        fullWidth={false}
                        placeholder="Sorty by"
                        value={sortBy}
                      >
                        <MenuItem value="D">Date</MenuItem>
                        <MenuItem value="P">Program</MenuItem>
                      </Select>
                    </Stack>
                    {map(entries(groupedData), ([title, programs]) => {
                      return (
                        <Fieldset
                          title={
                            sortByDate ? dayjs(title).format('MM/YY') : title
                          }
                        >
                          <Box p="1.5rem 0.5rem 0.5rem">
                            {map(programs, (p) => {
                              const {
                                id,
                                name,
                                aidCd,
                                eligibilityStatusCd,
                                programBeginDate,
                                programEligibilityId,
                                payMonth,
                              } = p;
                              const selected = id === selectedId;
                              const status = getStatus(eligibilityStatusCd);
                              const formattedPaymonth =
                                formatPayMonth(payMonth);

                              return (
                                <BaseMenuButton
                                  id={programEligibilityId}
                                  avatar={<Avatar name={name} />}
                                  details={
                                    <Stack
                                      sx={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flex: 1,
                                      }}
                                    >
                                      <Typography variant="subtitle1">
                                        {name}
                                      </Typography>
                                      <Fieldset
                                        title={getLkpMatch(
                                          'EligibilityStatusCode',
                                          eligibilityStatusCd
                                        )}
                                        size="small"
                                      >
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                        >
                                          <Status
                                            invert
                                            fullWdith={false}
                                            status={status}
                                            size="small"
                                          >
                                            {aidCd}
                                          </Status>
                                          <Typography
                                            variant="subtitle2"
                                            pr="0.5rem"
                                          >
                                            {formattedPaymonth}
                                          </Typography>
                                        </Stack>
                                      </Fieldset>
                                    </Stack>
                                  }
                                  selected={selected}
                                  onClick={() => onSelect(id)}
                                />
                              );
                            })}
                          </Box>
                        </Fieldset>
                      );
                    })}
                  </Stack>
                );
              }}
            >
              {(selected, options) => {
                const { renderUpDownButtons } = options;
                const { programEligibilityId } = selected;
                return (
                  <div>
                    {renderUpDownButtons()}
                    <SummaryDetails
                      key={programEligibilityId}
                      getLkpMatch={getLkpMatch}
                      selected={selected}
                      caseId={caseId}
                    />
                  </div>
                );
              }}
            </SelectableView>
          </Stack>
          <Box sx={{ width: '100%' }} area-label="verification checklist">
            <TabularView
              sx={{
                table: {
                  width: '100%',
                },
                // td: {
                //   border: 0,
                //   padding: '0 1.25rem 0.5rem 0',
                // },
              }}
              headerComponent={{ variant: 'subtitle1' }}
              component={Paper}
              columns={columns}
              autoHeight
              slots={{
                noRowsOverlay: () => (
                  <EmptyState
                    header="Currently No Records Available"
                    icon={
                      <img
                        src={NoRecord}
                        style={{
                          height: '160px',
                          width: '250px',
                        }}
                      />
                    }
                  />
                ),
              }}
              data={checkListItem}
            />
          </Box>
        </TabContent>
      </Stack>
    </PageContainer>
  );
}

EligibilitySummary.propTypes = {};

export default EligibilitySummary;
