import { all, takeLatest, put } from 'redux-saga/effects';
import {
  getCaseDetails,
  getHouseMembers,
} from '../../utils/services/apiRequests';
import {
  setCaseDetails,
  setFetchingCaseDetails,
  setFetchingMembers,
  setHouseMembers,
} from '../slices/caseSlice';
import { FETCH_CASE_DETAILS, FETCH_HOUSE_MEMBERS } from '../types/caseTypes';

export function* fetchCaseDetails(action) {
  try {
    const caseId = action.payload;
    yield put(setFetchingCaseDetails(true));
    const res = yield getCaseDetails(caseId);
    if (res.status === 200) {
      yield put(setCaseDetails(res.data));
    }
  } catch (error) {
    // showErrorToast('Unable get case details')
  } finally {
    yield put(setFetchingCaseDetails(false));
  }
}

export function* fetchHouseMembers(action) {
  try {
    yield put(setFetchingMembers(true));
    const caseId = action.payload;
    const res = yield getHouseMembers(caseId);
    if (res.status === 200) {
      yield put(setHouseMembers(res.data));
    }
  } catch (error) {
    // showErrorToast('Unable get house members')
  } finally {
    yield put(setFetchingMembers(false));
  }
}

export default function* watchCaseDetails() {
  yield all([
    takeLatest(FETCH_CASE_DETAILS, fetchCaseDetails),
    takeLatest(FETCH_HOUSE_MEMBERS, fetchHouseMembers),
  ]);
}
