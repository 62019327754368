import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import FormSection from '../../../../components/FormSection';
import Field from '../../../../components/Field';
import { Formik, useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { postManualTemplate } from '../../../../utils/services/ManualNotices/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import { CloseRounded } from '@mui/icons-material';
import usePageNav from '../../../../hooks/usePageNav';
import { maxLength, number } from '../../../../utils/normalizers';
import InputMask from 'react-input-mask';

export default function SchoolAttendance({
  toggleClose,
  noticeData,
  createNew,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  // console.log(individualId, 'caseId');
  // const data = editData?.jsonTemplateData;
  // const dataValue = JSON.parse(data) ?? {};
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();
  // const { individualId } = usePageNav();\
  const formik = useFormik({
    initialValues: {
      line1: dataValue?.ldssStrtAddr1 ?? noticeData?.ldssName ?? '',
      caseName: dataValue?.ldssName ?? '',
      line2: dataValue?.ldssCity ?? noticeData?.ldssStrtAddr1 ?? '',
      caseRecord: dataValue?.caseNumber ?? noticeData?.caseNumber ?? '',
      line3:
        dataValue?.ldssState ??
        `${noticeData?.ldssCity ?? ''}, ${noticeData?.ldssState ?? ''}, ${
          noticeData?.ldssZipcode ?? ''
        }` ??
        '',
      mailDate:
        dataValue?.applicationDate ?? noticeData?.applicationDate
          ? dayjs(noticeData?.applicationDate)
          : '',
      caseSchlName: dataValue?.ldssName ?? '',
      caseNumber: dataValue?.caseNumber ?? noticeData?.caseNumber ?? '',
      parentName: dataValue?.hohName ?? '',
      requestedDate: dataValue?.triggerDate ?? '',
      childName: dataValue?.childName ?? '',
      dob: dataValue?.dateOfBirth ?? '',
      ssn: dataValue?.ssn ?? '',
      parentSign: dataValue?.parentSignature ?? '',
      signatureDate: dataValue?.signatureDate ?? '',
      signNumber: dataValue?.tNumber ?? '',
      nameChild: dataValue?.childName ?? '',
      makingPeriod: dataValue?.markPeriod ?? '',
      daysEnrolled: dataValue?.noDaysEnrolled ?? '',
      daysAbsent: dataValue?.noDaysAbsent ?? '',
      gradeLevel: dataValue?.gradeLevel ?? '',
      graduationDate: dataValue?.expectedGraduation ?? '',
      disenrollmentDate: dataValue?.disenrollmentDate ?? '',
      improveAtten: dataValue?.improveAttd ?? '',
      initialContact: dataValue?.dateOfContact ?? '',
      calendarMonth: dataValue?.calendarMonth ?? '',
      enrolledDays: dataValue?.daysEnrolled ?? '',
      absentDays: dataValue?.daysAbsent ?? '',
      studentNumber: dataValue?.telNumber ?? '',
      studentAddress: dataValue?.hAddress ?? '',
      name1: dataValue?.p1Name ?? '',
      relationship1: dataValue?.p1Relationship ?? '',
      number1: dataValue?.p1Number ?? '',
      name2: dataValue?.p2Name ?? '',
      relationship2: dataValue?.p2Relationship ?? '',
      number2: dataValue?.p2Number ?? '',
      schoolOfficialSign: dataValue?.signSchoolOffical ?? '',
      stamp: dataValue?.stamp ?? '',
      title: dataValue?.title ?? '',
      number: dataValue?.titleNumber ?? '',
      telephoneNumb: dataValue?.title2Number ?? '',
      caseId,
      individualId,
      recordId: dataValue?.recordId ?? 0,
      programName,
    },
    onSubmit: (values) => {
      schoolAttend(values);
      console.log(values);
    },
  });

  const schoolAttend = async (data) => {
    try {
      showLoader();
      // const payloadData = JSON.stringify(data);
      // console.log(payloadData, 'payload');
      const res = await postManualTemplate(data, caseId);
      if (res.status === 200) {
        showSuccessToast('Successfully Created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Stack direction="row" justifyContent="center" mt="2rem">
          <Typography variant="h3">
            VERMONT FAMILY INVESTMENT ADMINISTRATION
          </Typography>
        </Stack>
        {/* <Stack direction="flex" justifyContent="flex-end">
          <IconButton onClick={toggleClose}>
            <CloseRounded />
          </IconButton>
        </Stack> */}
        <Grid ml="3rem" mr="3rem">
          <Grid container spacing={6} mt="0.2rem">
            <Grid item xs={6} mt="1rem">
              <Field name="line1" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="caseName"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Case Name:" />
              </Field>
            </Grid>
            <Grid item xs={6} mt="1rem">
              <Field name="line2" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="caseRecord" formik={formik}>
                <TextField variant="standard" label="Case Record #:" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="line3" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="mailDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
          </Grid>
          <Grid item xs={10} ml="25rem" mt="2rem">
            <Typography variant="h4">SCHOOL ATTENDANCE VERIFICATION</Typography>
          </Grid>
          <Grid container spacing={2} mt="1rem">
            <Grid item xs={4}>
              <Field
                name="caseSchlName"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Case Name" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="caseNumber"
                formik={formik}
                normalizers={[maxLength(50), number]}
              >
                <TextField variant="standard" label="Case Number" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="parentName"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Minor Parent Name" />
              </Field>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h5grey">
                Human Services requires that school attendance be verified for
                children who are age 7 through 18. In order to verify
                attendance, we are asking the school to complete Section 2 for
                each child and sign the form in Section 3. Please return the
                form by the requested date:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="requestedDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5grey">
                I authorize the school to give information about my child’s
                attendance for the child listed below to the Department of
                Social Services
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="red">
                (or should this be the Department of Human Services (statewide
                processing?))
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="childName"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Child's Name" />
              </Field>
            </Grid>
            <Grid item xs={4} mt="1rem">
              <Field name="dob" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Field name="ssn" formik={formik}>
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="standard"
                      placeholder="SSN"
                      value={formik.values.ssn}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.4rem">
              <Field name="parentSign" formik={formik}>
                <TextField
                  variant="standard"
                  label="Parent/Guardian Signature"
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="signatureDate"
                label="Signature Date"
                formik={formik}
                required
                isDate
              >
                <DatePicker
                  // label="Signature Date"
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.4rem">
              <Field
                name="signNumber"
                formik={formik}
                normalizers={([maxLength(10)], number)}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.signNumber}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt="2rem">
            <Grid item xs={12}>
              <Typography variant="h5grey">
                Section 2 A: ATTENDANCE INFORMATION FOR THE MOST RECENT MARKING
                PERIOD <br></br>(To Be Completed By School Official) <br></br>
                Please enter the school and attendance information for the child
                listed below for the current or most recent marking period.
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Field
                name="nameChild"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Child's Name" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="makingPeriod"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Making Period" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name="daysEnrolled" formik={formik} normalizers={[number]}>
                <TextField variant="standard" label="Number of Days Enrolled" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name="daysAbsent" formik={formik} normalizers={[number]}>
                <TextField variant="standard" label="Number of Days Absent" />
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.4rem">
              <Field name="gradeLevel" formik={formik}>
                <TextField variant="standard" label="Current Grade Level" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="graduationDate"
                formik={formik}
                isDate
                label="Graduation Date"
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="disenrollmentDate"
                formik={formik}
                isDate
                label="Disenrollment Date"
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>

            <Grid item xs={12} mt="1rem">
              <Typography variant="h5grey">
                If the child’s attendance is less than 80% for the marking
                period, is the parent/guardian working with the school to
                improve attendance?
              </Typography>
            </Grid>
            <Grid item xs={6} mt="1rem">
              <Field name="improveAtten" formik={formik}>
                <RadioGroup
                  name="improveAtten"
                  row
                  {...formik.getFieldProps('improveAtten')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.improveAtten}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="initialContact"
                label="Initial Contact"
                formik={formik}
                isDate
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5grey">
                Please enter the attendance for most recently completed calendar
                month if the child’s attendance has improved
              </Typography>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Field
                name="calendarMonth"
                formik={formik}
                isDate
                label="Calendar Month"
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4} mt="1rem">
              <Field name="enrolledDays" formik={formik} normalizers={[number]}>
                <TextField variant="standard" label="Number of Days Enrolled" />
              </Field>
            </Grid>
            <Grid item xs={4} mt="1rem">
              <Field name="absentDays" formik={formik} normalizers={[number]}>
                <TextField variant="standard" label="Number of Days Absent" />
              </Field>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h5grey">
                Section 2 B: COMPLETE THE ADDRESS AND PARENT/LEGAL GUARDIAN
                INFORMATION ON FILE FOR THE CHILD <br></br> (To Be Completed By
                School Official)
              </Typography>
            </Grid>
            <Grid item xs={6} mt="0.5rem">
              <Field name="studentNumber" formik={formik}>
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="standard"
                      placeholder="Number"
                      value={formik.values.studentNumber}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="studentAddress" formik={formik}>
                <TextField variant="standard" label="Student's Home Address" />
              </Field>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5grey">
                Provide Parents/Legal guardian (s) name, relationship and Work
                Number listed for the students:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="name1" formik={formik} normalizers={[maxLength(50)]}>
                <TextField variant="standard" label="Name" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="relationship1"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Relationship" />
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Field
                name="number1"
                formik={formik}
                normalizers={[maxLength(50), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="standard"
                      placeholder="Number"
                      value={formik.values.number1}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name="name2" formik={formik} normalizers={[maxLength(50)]}>
                <TextField variant="standard" label="Name" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="relationship2"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" label="Relationship" />
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Field
                name="number2"
                formik={formik}
                normalizers={[maxLength(50), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="standard"
                      placeholder="Number"
                      value={formik.values.number2}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h5grey">
                Section 3: SIGNATURE OF SCHOOL OFFICIAL
              </Typography>
            </Grid>
            <Grid item xs={6} normalizers={[maxLength(50)]}>
              <Field name="schoolOfficialSign" formik={formik}>
                <TextField
                  variant="standard"
                  label="Signature of School Official"
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="stamp" formik={formik} normalizers={[maxLength(50)]}>
                <TextField variant="standard" label="Stamp" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name="title" formik={formik} normalizers={[maxLength(50)]}>
                <TextField variant="standard" label="Title" />
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Field
                name="number"
                formik={formik}
                normalizers={[maxLength(50), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="standard"
                      placeholder="Number"
                      value={formik.values.number}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Field name="telephoneNumb" formik={formik}>
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="standard"
                      placeholder="Telephone Number"
                      value={formik.values.telephoneNumb}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
          </Grid>
          <Grid item xs={12} mt="1rem" mb="1rem">
            <Typography variant="h5grey">
              DHS FIA 604A SCHOOL ATTENDANCE VERIFICATION FORM
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt="1rem" spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Disacrd
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
