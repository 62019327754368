import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { map } from 'lodash';
import links from '../../../store/sagas/navUrlDashboard.json';
import navUrlsReference from '../../Layout/SideMenu/navUrlsReference.json';
import { Link } from 'react-router-dom';
import styles from './index.module.css';
import Icon from '../../../components/Icon';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function DashboardLinks() {
  return (
    <div>
      <Box p={1} direction={'row'} justifyContent={'space-between'}>
        <Typography variant="h3">Links</Typography>
      </Box>
      <Divider />
      <Stack p="1rem" spacing={1}>
        {map(links, (link) => {
          const { nav_url, nav_icon } = navUrlsReference[link.nvgtn_id];
          return (
            <Link className={styles.link} to={nav_url}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon name={nav_icon} />
                <Typography variant="subtitle1">{link.tag_txt}</Typography>
                <OpenInNewIcon sx={{ height: '1rem' }} />
              </Stack>
            </Link>
          );
        })}
      </Stack>
    </div>
  );
}

DashboardLinks.propTypes = {};

export default DashboardLinks;
