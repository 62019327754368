import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import styles from '../../index.module.css';

export default function CPMedicalExpenses({
  data,
  getLkpMatch,
  hohMemberData,
  handleCheckbox,
}) {
  const getMemberNameByIndividualId = useCallback(
    (individualId) => {
      let result = hohMemberData?.find(
        (item) => item.dummyIndividualId === individualId
      );
      return `${result?.firstName ?? ''} ${result?.lastName ?? ''}`;
    },
    [data, hohMemberData]
  );

  return (
    <>
      {map(data, (item, index) => {
        const { providerName, medicalExpenseType, serviceProvided, expense } =
          item?.medicalExpenses ?? {};
        const { frequency, amountModel } = expense?.[0];
        return (
          <Stack direction="row" pl={1}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Checkbox />}
              onChange={(event) => handleCheckbox(event, index)}
              checked={item?.importSelected}
            />
            <div className={styles.card}>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 1.5,
                      paddingBottom: '0.5rem',
                      border: 0,
                    },
                  }}
                >
                  <TableBody className={styles.tableBody}>
                    <TableRow
                      sx={{ borderBottom: '1px solid var(--grey-350)' }}
                    >
                      <TableCell>
                        <Typography variant="h6grey">
                          Individual Name
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="h6">
                          {getMemberNameByIndividualId(item?.individualId) ??
                            ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          Medical Provider Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {providerName ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Expense Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch(
                            'MedicalExpenseType',
                            medicalExpenseType
                          ) ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          Service Provided
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch('ServiceProvided', serviceProvided) ??
                            'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Frequency</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch('Frequency', frequency) ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {map(amountModel, (ele, ind) => (
                      <TableRow key={`amount-detail-${ind}`}>
                        <TableCell
                          colSpan={2}
                          sx={{
                            borderTop: `${
                              ind === 0 ? '1px solid #e6e6d9 !important' : ''
                            }`,
                          }}
                        >
                          {ind === 0 ? (
                            <Stack pb={2}>
                              <Typography variant="h6grey">
                                Amount Details :
                              </Typography>
                            </Stack>
                          ) : null}
                          <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6grey">Amount</Typography>
                            <Typography variant="subtitle2">
                              {ele?.amount ? `$${ele?.amount}` : 'NA'}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Stack>
        );
      })}
    </>
  );
}
