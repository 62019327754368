import { get } from 'lodash';
import ActionButton from '../../../components/ActionButtons';
import IndividualDetailsTable from '../IndividualDetailsTable';
import React, { useEffect, useMemo, useState } from 'react';
import AddVeteran from './AddVeteran';
import { postVeteran } from '../../../utils/services/individualDetailsServices/requests';
import { updateVeteran } from '../../../utils/services/individualDetailsServices/requests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { useParams } from 'react-router-dom';
import DeleteModal from '../DeleteModal';
import useLoader from '../../../hooks/useLoader';

function Veteran({
  member,
  getLkpMatch,
  veteranStatusOpts,
  individualId,
  fetchIndividual,
  dob,
  selected,
  open,
  handleClose,
}) {
  const [openDeleteModal, setOpenDeletModal] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [editData, setEditData] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const veterans = get(member, 'individualDetails.veterans', []);
  const params = useParams();
  const getRowId = (row) => {
    return row.recordId;
  };

  const handleEdit = (params) => {
    setEditData(params.row);
    setOpenEditModal(true);
  };

  const handleDelete = (params) => {
    setOpenDeletModal(true);
    setDeleteData(params.row);
  };

  const columns = [
    {
      field: 'veteranStatus',
      headerName: 'Veteran Status',
      renderCell: (params) =>
        getLkpMatch('VeteransStatus', params?.row?.veteranStatus),
      width: 180,
      editable: false,
    },
    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      width: 140,
      editable: false,
    },
    {
      field: 'effectiveEndDate',
      headerName: 'End Date',
      width: 130,
      editable: false,
    },

    {
      headerName: 'Action',
      field: 'action',
      width: 100,
      renderCell(params) {
        return (
          <span>
            <ActionButton
              onDelete={() => handleDelete(params)}
              onEdit={() => handleEdit(params)}
            />
          </span>
        );
      },
      headerName: 'Action',
    },
  ];

  const gridData = useMemo(() => {
    return veterans.map((item) => {
      return {
        effectiveBeginDate: item.effectiveBeginDate,
        effectiveEndDate: item.effectiveEndDate,
        recordId: item.recordId,
        veteranStatus: item.veteranStatus,
      };
    });
  }, [member]);

  const onSaveVeteran = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postVeteran(
        data,
        params.caseId,
        individualId,
        dirtyStatus
      );

      if (res.status === 200) {
        fetchIndividual();
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added New Veteran');
        } else {
          showSuccessToast('Successfully Deleted The  Veteran');
        }
      }
    } catch (error) {
      console.log(error);
      showErrorToast();
    } finally {
      hideLoader();
      handleClose();
    }
  };

  const onUpdateVeteran = async (data, dirtyStatus) => {
    try {
      const res = await updateVeteran(
        data,
        params.caseId,
        individualId,
        dirtyStatus
      );

      if (res.status === 200) {
        fetchIndividual();
        showSuccessToast('Successfully updated the veteran');
      }
    } catch (error) {
      console.log(error);
      showErrorToast();
    }
  };

  useEffect(() => {
    if (editData) {
      setOpenEditModal(true);
    }
  }, [editData]);

  return (
    <div>
      <IndividualDetailsTable
        columns={columns}
        rows={gridData}
        getRowId={getRowId}
      />
      <AddVeteran
        dob={dob}
        selected={selected}
        addVeteranStatus
        open={open}
        handleClose={handleClose}
        veteranStatusOpts={veteranStatusOpts}
        onSaveVeteran={onSaveVeteran}
      />
      <AddVeteran
        dob={dob}
        editVeteranStatus
        editData={editData}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        veteranStatusOpts={veteranStatusOpts}
        onUpdateVeteran={onUpdateVeteran}
      />

      <DeleteModal
        open={openDeleteModal}
        close={() => setOpenDeletModal(false)}
        deleteData={deleteData}
        fn={onSaveVeteran}
      />
    </div>
  );
}

export default Veteran;
