import React from 'react';
// import { Scheduler } from '@aldabil/react-scheduler';

import { Box } from '@mui/material';

const EVENTS = [
  {
    event_id: 1,
    title: 'Event 1',
    start: new Date(new Date(new Date().setHours(9)).setMinutes(0)),
    end: new Date(new Date(new Date().setHours(10)).setMinutes(0)),
    disabled: true,
    admin_id: [1, 2, 3, 4],
  },
  {
    event_id: 2,
    title: 'Event 2',
    start: new Date('01-19-2024 2:30 PM'),
    end: new Date('01-19-2024 3:30 PM'),
    admin_id: 2,
    color: '#50b500',
  },
];
export default function CalenderSchedule({ event }) {
  const onChange = () => {};
  return (
    <Box height={500}>
      {/* <Scheduler
        events={event}
        month={{
          startHour: 10,
          endHour: 18,
          // weekStartOn: 1,
          // weekDays: [0, 1, 2, 3, 4],
          selectedDate: new Date(),
        }}
        onConfirm={onChange}
        height="300"
      /> */}
    </Box>
  );
}
