import { createSelector } from '@reduxjs/toolkit';

const getEligibility = (state) => state.eligibility;

export const selectEligilityResult = createSelector(
  getEligibility,
  (state) => ({
    eligilityResult: state.getEligibility,
  })
);
