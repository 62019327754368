import HttpClient from '../../HttpClient';
import { saveMember } from './apiPayload';
import { format } from '../../helpers';
import {
  MEDICAL_EXPENSES,
  SAVE_MEDICALEXPENSES,
  DELETE_MEDICALRECORD,
} from './apiurls';

export const medicalExpenses = (caseId) => {
  return HttpClient.get(format(MEDICAL_EXPENSES, caseId));
};
export const saveMedicalExpenses = (
  data,
  individualId,
  caseId,
  recordId,
  providedVerificationId
) => {
  return HttpClient.put(
    format(SAVE_MEDICALEXPENSES, caseId, individualId),
    saveMember(data, recordId, providedVerificationId)
  );
};
export const deleteMedicalExpensesRecord = (
  data,
  individualId,
  caseId,
  recordId
) => {
  return HttpClient.put(
    format(DELETE_MEDICALRECORD, caseId, individualId),
    saveMember(data, recordId)
  );
};
