import React, { useState, useEffect, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddEditEarnedIncomeDetailsDialog from './AddEditEarnedIncomeDetailsDialog';
import InComeDataGrid from '../InComeDataGrid';
import DocumentUploadFilesDialog from '../DocumentUploadFilesDialog';
import { find } from 'lodash';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { saveEarnedIncome } from '../../../utils/services/apiRequests';
import DeleteConfiramation from '../DeleteConfiramation';
import dayjs from 'dayjs';
import useLoader from '../../../hooks/useLoader';
import { formatCurrency } from '../../../utils/normalizers';

const EarnedIncome = ({
  member,
  setOpen,
  open,
  isAdd,
  setIsAdd,
  docOpen,
  setDocOpen,
  lkpRaw,
  lkpFlatten,
  getLkpMatch,
  caseId,
  fetchIncome,
  fedEmplrList,
  getOptions,
}) => {
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [showLoader, hideLoader] = useLoader();
  const columns = [
    {
      field: 'incomeType',
      headerName: 'Income Type',
      width: 140,
    },
    {
      field: 'companyLegalName',
      headerName: 'Employer Name',
      width: 160,
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      width: 100,
      editable: false,
    },
    {
      field: 'employmentBeginDate',
      headerName: 'Employment Start Date',
      width: 190,
    },
    {
      field: 'employmentEndDate',
      headerName: 'Employment End Date',
      width: 190,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      width: 120,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleEdit = (rowData) => {
    const getRow = find(member.earnedIncomes, { recordId: rowData.recordId });
    setOpen(true);
    setIsAdd(false);
    setSelectedData(getRow);
  };

  const handleDelete = async (rowData) => {
    const getRow = find(member.earnedIncomes, { recordId: rowData.recordId });
    setSelectedData(getRow);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      showLoader();
      const res = await saveEarnedIncome(
        caseId,
        selectedData,
        member.individualId,
        isAdd,
        true
      );

      if (res.status === 200) {
        showSuccessToast('Earned Income Successfully Deleted');
        fetchIncome();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
    setOpenDeleteDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const getRowId = (row) => {
    return row.recordId;
  };

  const gridData = useMemo(() => {
    if (!member || !member.earnedIncomes) {
      return [];
    }

    return member.earnedIncomes.map((income) => {
      const { incomeType, employer, earnedIncomeAmount, recordId } = income;
      const {
        companyLegalName,
        employmentBeginDate,
        employmentEndDate = 'Present',
      } = employer;

      const totalAmount = earnedIncomeAmount.reduce((acc, item) => {
        const amounts = item.amountModel.map((model) => model.amount);
        return acc + amounts.reduce((sum, amount) => sum + amount, 0);
      }, 0);

      const year = dayjs(employmentEndDate).year();
      const displayEmploymentEndDate = year === 9999 ? '' : employmentEndDate;

      return {
        recordId: recordId,
        incomeType: getLkpMatch('EarningType', incomeType),
        companyLegalName,
        frequency: getLkpMatch('Frequency', earnedIncomeAmount[0].frequency),
        employmentBeginDate,
        employmentEndDate: displayEmploymentEndDate,
        amount: `$ ${totalAmount ? parseFloat(totalAmount).toFixed(2) : ''}`,
      };
    });
  }, [member]);

  return (
    <>
      <InComeDataGrid
        rows={gridData}
        columns={columns}
        loading={false}
        getRowId={getRowId}
      />
      <AddEditEarnedIncomeDetailsDialog
        setOpen={setOpen}
        open={open}
        isAdd={isAdd}
        lkpRaw={lkpRaw}
        member={member}
        selectedData={selectedData}
        caseId={caseId}
        fetchIncome={fetchIncome}
        fedEmplrList={fedEmplrList}
        getOptions={getOptions}
      />
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        lkpRaw={lkpRaw}
        lkpFlatten={lkpFlatten}
        member={member}
      />
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default EarnedIncome;
