import React, { useState, useEffect, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddEditUnEarnedIncomeDetailsDialog from './AddEditUnEarnedIncomeDetailsDialog';
import InComeDataGrid from '../InComeDataGrid';
import DocumentUploadFilesDialog from '../DocumentUploadFilesDialog';
import { find } from 'lodash';
import { saveUnearnedIncome } from '../../../utils/services/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import DeleteConfiramation from '../DeleteConfiramation';
import dayjs from 'dayjs';
import useLoader from '../../../hooks/useLoader';

const UnearnedIncome = ({
  member,
  setOpen,
  open,
  isAdd,
  setIsAdd,
  docOpen,
  setDocOpen,
  lkpFlatten,
  getLkpMatch,
  lkpRaw,
  caseId,
  fetchIncome,
  docmentFiles,
  getUploadFiles,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const columns = [
    {
      field: 'unearnedIncomeType',
      headerName: 'Type',
      width: 150,
    },
    {
      field: 'subType',
      headerName: 'Sub Type',
      width: 200,
    },
    {
      field: 'pymtStrtDt',
      headerName: 'Begin Date',
      width: 120,
      editable: false,
    },
    {
      field: 'pymtEndDt',
      headerName: 'End Date',
      width: 120,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      width: 120,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleEdit = (rowData) => {
    const getRow = find(member.unearnedIncomes, { recordId: rowData.recordId });
    setOpen(true);
    setIsAdd(false);
    setSelectedData(getRow);
  };

  const handleDelete = async (rowData) => {
    const getRow = find(member.unearnedIncomes, { recordId: rowData.recordId });
    setSelectedData(getRow);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      showLoader();
      const res = await saveUnearnedIncome(
        caseId,
        selectedData,
        member.individualId,
        isAdd,
        true
      );

      if (res.status === 200) {
        showSuccessToast('Unearned Income Successfully Deleted');
        fetchIncome();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const getRowId = (row) => {
    return row.recordId;
  };

  const gridData = useMemo(() => {
    if (!member || !member.unearnedIncomes) {
      return [];
    }

    return member.unearnedIncomes.map((income) => {
      const {
        unearnedIncomeType,
        subType,
        pymtStrtDt,
        pymtEndDt,
        unearnedIncomeAmount,
        recordId,
      } = income;

      const totalAmount =
        unearnedIncomeAmount &&
        unearnedIncomeAmount.reduce((acc, item) => {
          const amounts = item.amountModel.map((model) => model.amount);
          return acc + amounts.reduce((sum, amount) => sum + amount, 0);
        }, 0);

      let typeRemoveSpacesandSlashes = getLkpMatch(
        'UnearnedIncomeType',
        unearnedIncomeType
      ).replace(/\s|\//g, '');

      const year = dayjs(pymtEndDt).year();
      const displayPymtEndDt = year === 9999 ? '' : pymtEndDt;

      return {
        recordId: recordId,
        unearnedIncomeType: getLkpMatch(
          'UnearnedIncomeType',
          unearnedIncomeType
        ),
        subType:
          typeRemoveSpacesandSlashes &&
          getLkpMatch(typeRemoveSpacesandSlashes, subType),
        pymtStrtDt,
        pymtEndDt: displayPymtEndDt,
        amount: totalAmount,
      };
    });
  }, [member]);

  return (
    <>
      <InComeDataGrid
        rows={gridData}
        columns={columns}
        loading={false}
        getRowId={getRowId}
      />
      <AddEditUnEarnedIncomeDetailsDialog
        setOpen={setOpen}
        open={open}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        lkpRaw={lkpRaw}
        member={member}
        selectedData={selectedData}
        caseId={caseId}
        fetchIncome={fetchIncome}
        getLkpMatch={getLkpMatch}
      />
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        lkpRaw={lkpRaw}
        member={member}
      />
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default UnearnedIncome;
