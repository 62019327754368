import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { overDueApi } from '../../../../utils/services/apiRequests';
import { getAuthToken } from '../../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styles from '../MyDashboardTable/index.module.css';
import { Button, Grid } from '@mui/material';
import cx from 'classnames';
import useMount from '../../../../hooks/useMount';
import { Circle } from '@mui/icons-material';
import useLoader from '../../../../hooks/useLoader';
import { showErrorToast } from '../../../../utils/services/toast';
import IconLink from '../../../../components/IconLinks';
import { ReactComponent as WorkUpdate } from '../../../../assets/icons/QuickLinks/work_update.svg';
import EmptyState from '../../../../components/EmptyState';
import NoRecord from '../../../../assets/icons/No-data-amico.svg';

function OverdueTable({ lkpFlatten, getLkpMatch, getWorkItemData }) {
  const [fetching, setFetching] = useState(false);
  const [overdueApplication, setOverdueApplication] = useState([]);
  const token = useSelector(getAuthToken);
  const [showLoader, hideLoader] = useLoader();
  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const caseStatus = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Grid container alignItems={'center'}>
          <Circle
            className={cx({
              [styles.open]: params?.row?.caseStsCd === 'OP',
              [styles.pending]: params?.row?.caseStsCd === 'PE',
              [styles.closed]: params?.row?.caseStsCd === 'CL',
              [styles.inprogess]: params?.row?.caseStsCd === 'IP',
              [styles.defaultStatus]: params?.row?.caseStsCd === '',
            })}
          />
          {getLkpMatch('CaseStatusCode', params?.row?.caseStsCd)}
        </Grid>
      </Grid>
    );
  };

  const remainingDays = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Grid
          className={cx(styles.default, {
            [styles.neg]: params?.row?.daysRemaining < 0,
            [styles.plusTen]: params?.row?.daysRemaining > 10,
            [styles.lessTen]:
              params?.row?.daysRemaining > 1 && params?.row?.daysRemaining < 10,
          })}
        >
          Due days is{' '}
          {params?.row?.daysRemaining < 0 ? 0 : params?.row?.daysRemaining}
        </Grid>
      </Grid>
    );
  };

  const columns = [
    { field: 'caseId', headerName: 'Case ID', flex: 1, maxWidth: 150 },
    {
      field: 'alertType',
      headerName: 'Type',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        return getLkpMatch('WorkItemType', params?.row?.alertType);
      },
    },
    {
      field: 'alertSubType',
      headerName: 'Sub Type',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        return getLkpMatch('AlertSubType', params?.row?.alertSubType);
      },
    },
    {
      field: 'caseStsCd',
      headerName: 'Status',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => caseStatus(params),
    },
    {
      field: 'Program',
      headerName: 'Program',
      renderCell: (params) => lkpFlatten?.Program?.[params?.row?.programs],
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'hohName',
      headerName: 'HOH Name',
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'daysRemaining',
      headerName: 'Remaining',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => remainingDays(params),
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => dayjs(params?.row?.dueDate).format('MM-DD-YYYY'),
    },
    {
      field: 'distOffice',
      headerName: 'LDSS',
      flex: 1,
      maxWidth: 150,
    },
  ];

  const fetchData = async () => {
    try {
      setFetching(true);
      showLoader();
      const res = await overDueApi(token?.sub, 'AP');
      if (res.status === 200) {
        setOverdueApplication(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  useMount(() => {
    fetchData();
  });

  return (
    <div>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={getWorkItemData}>
          <IconLink icon={WorkUpdate} iconlabel="Get Work Item" />
        </Button>
      </Grid>
      <DataGrid
        getRowId={(row) => (row.caseId ? row.caseId : row.webId || row.webID)}
        rows={overdueApplication ?? []}
        columns={columns}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Note: Please click Get Work Item to Get Records"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 30]}
        paginationModel={page}
        autoHeight
      />
    </div>
  );
}

OverdueTable.propTypes = {};

export default OverdueTable;
