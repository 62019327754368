import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { SEARCH_ISSUANCE, HISTORY_ISSUANCE, VIEW_ISSUANCE } from './apiUrls';

export const searchIssuance = (data) =>
  HttpClient.post(SEARCH_ISSUANCE, data);

export const historyIssuance = (params) => {
 const { program, number, month } = params;
 return  HttpClient.get(
    format(HISTORY_ISSUANCE, program, number, month)
  );
}

export const viewIssuance = (params) => {
  const { program, id } = params;
  return HttpClient.get(format(VIEW_ISSUANCE, program, id));
};

