import React, { useEffect, useState } from 'react';
import ProgramTable from '../ProgramTables';
import { Box } from '@mui/material';
import {
  getChildUnderone,
  postTimeLimitRecord,
} from '../../../utils/services/ProgramTimeLimts/requests';
import useLoader from '../../../hooks/useLoader';
import SelectedProgramTimeLimit from '../SelectedProgramTimeLimit';

const ChildUnderOne = ({ caseId, individualId }) => {
  const APPID = caseId;
  const [counter, setCounter] = useState(0);
  const [showLoader, hideLoader] = useLoader();
  const [tableList, setTableList] = useState([]);
  const [editSelectedConsumer, setEditSelectedConsumer] = useState({});
  const [open, setOpen] = useState(false);
  const [dirtyStatus, setDirtyStatus] = useState('');
  const [programEditindTmClkId, setProgramindTmClkId] = useState('');

  useEffect(() => {
    abardTable();
  }, [individualId]);

  async function abardTable() {
    try {
      showLoader();
      const getData = await getChildUnderone(APPID, individualId);
      setTableList(getData.data.timeClockList);
      setCounter(getData.data.counter);
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }

  const HandleEvents = (event, propId) => {
    const findSelectedRecord = tableList?.find(
      (obj) => obj.indTmClkId === propId
    );
    setEditSelectedConsumer(findSelectedRecord);
    setOpen(!open);
    setDirtyStatus(event);
    setProgramindTmClkId(propId);
  };

  const HandleSumbitCase = async (payload) => {
    showLoader();
    try {
      const res = await postTimeLimitRecord(
        {
          indTmClkId: programEditindTmClkId,
          ...payload,
          clkTypCd: 'TC',
        },
        APPID,
        individualId,
        dirtyStatus
      );
      if (res.status === 200) {
        setOpen(false);
        abardTable();
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box>
      <ProgramTable
        tabelDatas={tableList}
        HandleEvents={HandleEvents}
        title="Child Under One"
      />
      <SelectedProgramTimeLimit
        open={open}
        setOpen={setOpen}
        programData={editSelectedConsumer}
        HandleSumbitCase={HandleSumbitCase}
        title="Child Under One"
        counter={counter}
      />
    </Box>
  );
};

export default ChildUnderOne;
