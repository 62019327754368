export const employerDetailsPayload = (data) => {
  const {
    employerName,
    occupation,
    startDate,
    endDate,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    number,
    extension,
    type,
    dirtyStatus,
    employerId,
  } = data;
  return {
    dirtyStatus,
    employers: [
      {
        emplrId: employerId,
        companyLegalName: employerName,
        occupation: occupation,
        employmentBeginDate: startDate,
        employmentEndDate: endDate ? endDate : null,
        employerAddress: {
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          city: city,
          zipcode: zipCode,
          state: state,
        },
        phoneNumber: {
          phoneNumber: number,
          phoneNumberType: type,
          phoneNumberExt: extension,
        },
        dirtyStatus,
      },
    ],
    otherIncome: {
      dirtyStatus: dirtyStatus,
      otherIncomes: [],
      unemploymentBenefitsYN: '',
    },
  };
};

export const demographicPayload = (data) => {
  const {
    fName,
    mName,
    lName,
    suffix,
    gender,
    ssn,
    verification,
    dob,
    approximateAge,
    dobVerification,
    race,
    relationship,
    city,
    state,
    country,
    ivd,
    ivdCoop,
    ivdCoopDate,
    assignRights,
    support,
    refferalDate,
    reason,
    reasonStatus,
    effectiveDate,
    maritalStatus,
    date,
    marriageCity,
    maritalState,
    maritalCountry,
    height,
    hairColor,
    weight,
    eyeColor,
    incarceration,
    institution,
    incarcerationCity,
    incarcerationState,
    incarcerationCountry,
    unionLocal,
    healthInsurance,
    drivingLicense,
    drivingState,
    licensePlate,
    licenseState,
    veteranStatus,
    veteranNumber,
    startDate,
    endDate,
    branch,
    payingAllotment,
    recipient,
    fatherFName,
    fatherMName,
    fatherLName,
    fatherSuffix,
    fatherline1,
    fatherline2,
    fatherCity,
    fatherState,
    fatherZipCode,
    fatherphone,
    fatherExt,
    fatherPhoneType,
    motherFName,
    motherMName,
    motherLName,
    maidenName,
    motherSuffix,
    motherline1,
    motherline2,
    motherCity,
    motherState,
    motherZipCode,
    motherPhone,
    motherExt,
    motherPhoneType,
    addChildSupport,
    dirtyStatus,
    recordId,
    cName,
    cDeprivation,
    cRelation,
  } = data;

  return {
    dirtyStatus: dirtyStatus,
    absentParentId: 0,
    abIdentification: {
      dirtyStatus: dirtyStatus,
      nameUnknownYN: 'N',
      referralDate: refferalDate ? refferalDate : null,
      ivdAssignRightsYN: assignRights,
      gender: gender,
      children: [
        {
          dirtyStatus: dirtyStatus,
          recordId: null,
          individualId: cName,
          relationship: cRelation,
          deprivation: cDeprivation,
          personName: {
            dirtyStatus: dirtyStatus,
            recordId: null,
            firstName: '',
            lastName: '',
            middleName: '',
            type: '',
            suffix: '',
          },
        },
      ],
      ivdNumber: ivd,
      childSpprt: support,
      soSec: {
        dirtyStatus: dirtyStatus,
        soSecPart1: data.ssn ? data.ssn.substr(0, 3) : '',
        soSecPart2: data.ssn ? data.ssn.substr(3, 2) : '',
        soSecPart3: data.ssn ? data.ssn.substr(5) : '',
        ssnVerification: {
          dirtyStatus: dirtyStatus,
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: verification,
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      absentParentName: {
        firstName: fName,
        middleName: mName,
        lastName: lName,
        suffix: suffix,
      },
    },
    abDemographicInfo: {
      dirtyStatus: dirtyStatus,
      relationshipTo: relationship,
      birthCity: city,
      birthState: state,
      approximateAge: approximateAge,
      birthCountry: country,
      dob: dob ? dob : null,
      race: race,
      gender: gender,
      dobVerification: {
        dirtyStatus: dirtyStatus,
        messages: [],
        verificationDocumentCd: '',
        verificationRequestedYN: '',
        docNames: {},
        documentDetails: [],
        verificationType: '',
        verificationSourceCode: dobVerification,
        verifiedYN: '',
        dueDate: null,
        automatedVerificationYN: '',
      },
    },
    abMaritalInfo: {
      dirtyStatus: dirtyStatus,
      maritalStatus: maritalStatus,
      marriageState: maritalState,
      marriageCountry: maritalCountry,
      maritalDate: date ? date : null,
      marriageCity: marriageCity,
    },
    abIncarceration: {
      dirtyStatus: dirtyStatus,
      incarcerationYN: incarceration,
      institution: institution,
      incarcerationCity: incarcerationCity,
      incarcerationState: incarcerationState,
      incarcerationCountry: incarcerationCountry,
    },
    abPhysicalAttributes: {
      dirtyStatus: dirtyStatus,
      height: height,
      weight: weight,
      hairColor: hairColor,
      eyeColor: eyeColor,
    },
    unionOrLocal: unionLocal,
    healthInsuranceYN: healthInsurance,
    effectiveEndDate: null,
    effectiveBeginDate: null,
    abMilitaryInfo: {
      dirtyStatus: dirtyStatus,
      veteranStatus: veteranStatus,
      veteranServiceBranch: branch,
      veteranPayingAllotmentYN: payingAllotment,
      veteranRecepient: recipient,
      veteranId: veteranNumber,
      veteranServiceStartDate: startDate ? startDate : null,
      veteranServiceEndDate: endDate ? endDate : null,
    },
    fatherName: {
      firstName: fatherFName,
      middleName: fatherMName,
      lastName: fatherLName,
      suffix: fatherSuffix,
      dirtyStatus: dirtyStatus,
    },
    fatherContactInformation: {
      homeAddress: {
        addressLine1: fatherline1,
        addressLine2: fatherline2,
        city: fatherCity,
        zipcode: fatherZipCode,
        state: fatherState,
      },
      phoneNumber: {
        phoneNumber: fatherphone,
        phoneNumberExt: fatherExt,
        phoneNumberType: fatherPhoneType,
      },
      dirtyStatus: dirtyStatus,
    },
    // abGoodCause: {
    //   dirtyStatus: dirtyStatus,
    //   goodCauseClaimInd: 'N',
    //   goodCauseReason: reason,
    //   goodCauseStatus: reasonStatus,
    //   goodCauseEffectiveDate: effectiveDate ?? null,
    //   ivdCoopIndEffectiveDate: ivdCoopDate ?? null,
    //   ivdCoopInd: 'Y',
    // },
    absentParentMVA: {
      dirtyStatus: dirtyStatus,
      drivingLicenseState: drivingState,
      drivingLicenseNumber: drivingLicense,
      licensePlateNumber: licensePlate,
      licensePlateState: licenseState,
    },
    motherName: {
      firstName: motherFName,
      middleName: motherMName,
      lastName: motherLName,
      suffix: motherSuffix,
      dirtyStatus: dirtyStatus,
    },
    motherMaidenName: maidenName,
    motherContactInformation: {
      homeAddress: {
        addressLine1: motherline1,
        addressLine2: motherline2,
        city: motherCity,
        zipcode: motherZipCode,
        state: motherState,
      },
      phoneNumber: {
        phoneNumber: motherPhone,
        phoneNumberExt: motherExt,
        phoneNumberType: motherPhoneType,
      },
      dirtyStatus: dirtyStatus,
    },
  };
};

export const contactInfoPayload = (data) => {
  const {
    careOf,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    fromDate,
    number,
    extension,
    type,
    email,
    previousAddressAdd,
    previousPhoneNumb,
    dirtyStatus,
  } = data;

  const previousAddresses = previousAddressAdd.map((address) => ({
    addressId: null,
    addressCareOf: address.coNum,
    addressLine1: address.line1,
    addressLine2: address.line2,
    city: address.cityAdd,
    zipcode: address.zipAdd,
    state: address.stateAdd,
    dirtyStatus: dirtyStatus,
    addressStartDate: address.fromDateAdd,
    addressEndDate: address.toDate,
  }));

  const previousPhoneNumbers = previousPhoneNumb.map((phone) => ({
    phoneNumberId: null,
    phoneNumber: phone.phone,
    phoneNumberType: phone.types,
    phoneNumberExt: phone.ext,
    dirtyStatus: dirtyStatus,
  }));

  return {
    dirtyStatus: dirtyStatus,
    previousAddresses,
    currentAddress: {
      addressCareOf: careOf,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      zipcode: zipCode,
      state: state,
      addressStartDate: fromDate,
      dirtyStatus: dirtyStatus,
    },
    phoneNumber: {
      phoneNumber: number,
      phoneNumberExt: extension,
      phoneNumberType: type,
      dirtyStatus: dirtyStatus,
    },
    previousPhoneNumbers,
    email: email,
  };
};

export const otherIncomePayload = (data) => {
  const { otherIncome, totalAmount, unemploymentBenefits, dirtyStatus } = data;
  return {
    dirtyStatus: '',
    employers: [
      {
        emplrId: '',
        companyLegalName: '',
        occupation: '',
        employmentBeginDate: '',
        employmentEndDate: '',
        employerAddress: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          state: '',
        },
        phoneNumber: {
          phoneNumber: '',
          phoneNumberType: '',
          phoneNumberExt: '',
        },
        dirtyStatus: '',
      },
    ],
    otherIncome: {
      dirtyStatus: 'insert',
      otherIncomes: otherIncome.map((income) => ({
        type: income.type,
        subType: income.subType,
        amount: income.amount,
      })),
      totalAmount: totalAmount,
      unemploymentBenefitsYN: unemploymentBenefits,
    },
  };
};
