import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { boraderDeletePayload, roomerBoarderPayload } from './apiPayload';
import { GET_ROOMER, POST_ROOMER } from './apiUrls';

export const postRoomer = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_ROOMER, caseId, individualId),
    roomerBoarderPayload(data)
  );
};

export const getRoomer = (caseId, individualId) => {
  return HttpClient.get(format(GET_ROOMER, caseId, individualId));
};

export const deleteRoomer = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_ROOMER, caseId, individualId),
    boraderDeletePayload(data)
  );
};
