import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import YearRangePickerComponent from '../../../components/YearRangePicker/YearRangePickerComponent';
import Dayjs from 'dayjs';
import { useFormik } from 'formik';
import Field from '../../../components/Field';
import useLookup from '../../../hooks/useLookup';
import Dropdown from '../../../components/Dropdown';
import { DataGrid } from '@mui/x-data-grid';
import { filter, isEmpty, map } from 'lodash';
import dayjs from 'dayjs';
import TabPanel from '../../../components/TabPanel';
import MonthPicker from '../../../components/MonthPicker';
import useLoader from '../../../hooks/useLoader';
import {
  getEligibleCase,
  runEligibleCase,
} from '../../../utils/services/apiRequests';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { getColaAftBenefit } from '../../../utils/services/Cola/apiRequests';
import { showSuccessToast } from '../../../utils/services/toast';
import TabularView from '../../../components/TabularView';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/cost-of-living.svg';

const Cola = () => {
  const [value, setValue] = useState(1);
  const [showLoader, hideLoader] = useLoader();
  const { getOptions, getLkpMatch, lkpRaw } = useLookup('Cola');
  const [row, setRow] = useState([]);
  const [afterData, setAfterData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [totalRecord, setTotalRecord] = useState([]);
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 100,
  });

  const [afterPageState, setAfterPageState] = useState({
    page: 1,
    pageSize: 50,
  });

  const handlePageChange = (event, newPage) => {
    setAfterPageState((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  const handleRowsPerPageChange = (event) => {
    setAfterPageState((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0, // Reset to first page
    }));
  };

  // const handlePagination = (pages) => {
  //   setPageState({
  //     page: pages.page,
  //     pageSize: pages.pageSize,
  //   });
  // };

  const handlePagination = (paginationModel) => {
    setPageState((prev) => ({
      ...prev,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    }));
  };

  const { programOpts } = useMemo(() => {
    const allProgramOpts = getOptions('Program');
    const filteredProgramOpts = allProgramOpts.filter(
      (option) => option.value === 'CH' || option.value === 'FS'
    );

    return {
      programOpts: filteredProgramOpts,
    };
  }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      colaType: 'Social Security',
      reasonCd: '',
      program: null,
      endYear: new Date().getFullYear() + 1,
      startYear: new Date().getFullYear(),
      month: '',
      date: '',
      time: '',
    },
    onSubmit: (values) => {
      // disposition(values);
      console.log(values);
    },
  });

  const getCaseDetails = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const payload = {
        programCd: formik.values.program ? [formik.values.program] : null,
        page: pageState.page || 1,
        size: pageState.pageSize || 10,
        year: formik.values.startYear || currentYear,
      };
      showLoader();
      const res = await getEligibleCase(payload);
      if (res.status === 200) {
        setRow(res.data?.caseDetailsModelList);
        setTotalCount(res.data?.totalCount);
      }
    } catch (error) {
      //
    } finally {
      hideLoader();
    }
  };

  const getColaAfter = async () => {
    try {
      const currentYear = new Date().getFullYear();
      showLoader();
      const payload = {
        page: pageState.page || 1,
        size: pageState.pageSize || 100,
        year: formik.values.startYear || currentYear,
        programCd: formik.values.program ? [formik.values.program] : null,
        // colaType: [formik.values.colaType] || '',
      };
      const res = await getColaAftBenefit(payload);
      if (res.status == 200) {
        setAfterData(res.data?.colaResponseModelList);
        setTotalRecord(res.data?.totalRecord);
      }
    } catch (error) {
      // showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    // getCaseDetails();
    getColaAfter();
  }, [pageState, afterPageState]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      getColaAfter();
    }
  };

  const columns = [
    { field: 'caseId', headerName: 'Case ID', flex: 1 },
    // {
    //   field: 'applicationDt',
    //   headerName: 'Application Date',
    //   renderCell: (params) => dayjs(params?.row?.dueDate).format('MM-DD-YYYY'), ///
    //   flex: 1,
    // },
    {
      field: 'hohName',
      headerName: 'HOH Name',
      flex: 1,
      renderCell: (params) => {
        return params.row?.hohFirstName + ' ' + params.row?.hohLastName;
      },
    },
    {
      field: 'programCd',
      headerName: 'Program Name',
      flex: 1,
      renderCell: (params) => getLkpMatch('Program', params?.row?.programCd),
    },
    {
      field: 'programStatusCd',
      headerName: 'Program Status',
      flex: 1,
      renderCell: (params) =>
        getLkpMatch('ProgramStatus', params?.row?.programStatusCd),
    },
    // {
    //   field: 'programModeCd',
    //   headerName: 'Program Mode',
    //   renderCell: (params) =>
    //     getLkpMatch('ProgramModeCode', params?.row?.programModeCd),
    //   flex: 1,
    // },
    {
      field: 'confirmationDt',
      headerName: 'Effective Date',
      flex: 1,
      renderCell: (params) => {
        const confirmationDt = params?.row?.confirmationDt;
        // return confirmationDt
        //   ? dayjs(confirmationDt).format('YYYY-MM-DD')
        //   : '-';
        return '10-01-2024';
      },
    },
    {
      field: 'netBnftAmt',
      headerName: 'Last Benefit Amount',
      flex: 1,
      renderCell: (params) => {
        return `$ ${params?.row?.netBnftAmt?.toFixed(2) ?? '0.00'}`;
      },
    },
    {
      field: 'netBnftAmt1',
      headerName: 'Revised Benefit Amount',
      flex: 1,
      renderCell: (params) => {
        return `$ ${
          (params?.row?.netBnftAmt + params?.row?.netBnftAmt * 0.0375)?.toFixed(
            2
          ) ?? '0.00'
        }`;
      },
    },
  ];

  const columnsAfterBenifit = [
    {
      headerName: 'Case ID',
      getValue({ caseId }) {
        return `${caseId}`;
      },
    },
    {
      headerName: 'Application Date',
      getValue(row) {
        const applicationDt = row?.applicationDt;
        return applicationDt ? dayjs(applicationDt).format('YYYY-MM-DD') : '-';
      },
      width: 130,
    },
    {
      headerName: 'HOH Name',
      getValue({ hohFirstName, hohLastName }) {
        return `${hohFirstName ?? '-'}  ${hohLastName ?? '-'}`;
      },
    },
    {
      headerName: 'Program Name',
      getValue({ programCd }) {
        return `${getLkpMatch('Program', programCd ?? '-')}`;
      },
    },
    {
      headerName: 'Program Status',
      getValue({ programStatusCd }) {
        return `${getLkpMatch('ProgramStatus', programStatusCd ?? '-')}`;
      },
    },
    {
      headerName: 'Program Mode',
      getValue({ programModeCd }) {
        return `${getLkpMatch('ProgramModeCode', programModeCd ?? '-')}`;
      },
    },
    {
      headerName: 'Status',
      getValue({ colaStatus }) {
        return `${colaStatus ?? '-'}`;
      },
    },
    {
      headerName: 'Last Confirmation Date',
      getValue({ priorConfirmationDt }) {
        // const priorConfirmationDt = row?.priorConfirmationDt;
        return priorConfirmationDt
          ? dayjs(priorConfirmationDt).format('YYYY-MM-DD')
          : '-';
      },
    },
    {
      headerName: 'Confirmation Date',
      getValue(row) {
        const confirmationDt = row?.confirmationDt;
        return confirmationDt
          ? dayjs(confirmationDt).format('YYYY-MM-DD')
          : '-';
      },
    },
    {
      headerName: 'Last Benefit Amount',
      getValue({ priorBenefitAmount }) {
        return `${priorBenefitAmount ?? '-'}`;
      },
    },
    {
      field: 'priorBenefitAmount',
      headerName: 'Current Benefit Amount',
      width: 190,
    },
  ];

  const runEligibleCaseDetails = async () => {
    try {
      showLoader();
      const selectedDate = formik.values.date;
      const schdlRunDt = `${selectedDate}`;
      const payload = map(row, (item) => ({
        programEligibilityId: item.programEligibilityId,
        benefitsCaseId: item.benefitsCaseId,
        pgmStatus: item.programStatusCd,
        schdlRunDt: schdlRunDt,
        massChgTypeCd: 'COLA',
        colaYear: formik.values.startYear,
        colaStatus: 'AP',
      }));
      const res = await runEligibleCase(payload);
      if (res.status === 200) {
        showSuccessToast('Successfully Runned');
      }
    } catch (error) {
      // Handle error
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleRunEligible = () => {
    runEligibleCaseDetails();
  };

  const colType = [{ label: 'Social Security', value: 'Social Security' }];

  return (
    <Box>
      <Typography
        sx={{ fontSize: '24px', fontWeight: '700' }}
        color="var(--black-900)"
      >
        Cost of Living Adjustment Review
      </Typography>
      <Grid container spacing={3} pt={2}>
        <Grid item xs={3}>
          <Box>
            <Typography variant="subtitle2">COLA Year</Typography>
            <YearRangePickerComponent
              minYear={dayjs().year()} // Current year
              maxYear={dayjs().add(2, 'year').year()} // Current year + 2
              startYear={formik.values.startYear}
              endYear={formik.values.endYear}
              onSelect={(startYear, endYear) => {
                formik.setFieldValue('startYear', startYear);
                formik.setFieldValue('endYear', endYear);
              }}
              disabled={true}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Field name="colaType" formik={formik} label="COLA Type">
            <Dropdown
              name="colaType"
              options={colType}
              placeholder="Select Reason"
              value={formik.values.colaType}
              disabled
            />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="program" formik={formik} label="Program">
            <Dropdown
              name="program"
              options={programOpts}
              placeholder="Select Program"
            />
          </Field>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button onClick={() => getCaseDetails()} variant="contained">
            Search
          </Button>
        </Grid>
      </Grid>
      {/* <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          textTransform: 'none',
          width: '100%',
          borderBottom: '1px solid #bababa',
        }}
      >
        <Tab
          //   sx={{ flex: 1, textTransform: 'none' }}
          value={1}
          label="Before Eligibility"
        />
        <Tab
          //   sx={{ flex: 1, textTransform: 'none' }}
          value={2}
          label="After Eligibility"
        />
      </Tabs>
      {value === 1 && (
        <Box>
          <Grid container spacing={2} xs={12} paddingTop="1rem">
            <Grid item xs={4}>
              <Field name="date" label="Schedule Date" formik={formik} isDate>
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={6} mt="1.5rem">
              <Button
                //   onSubmit={formik.handleSubmit}
                variant="contained"
                disabled={
                  //   formik.values.endYear === formik.values.month.year &&
                  isEmpty(row)
                }
                onClick={() => handleRunEligible()}
              >
                Schedule
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} paddingTop="1rem">
            <DataGrid
              getRowId={(row) =>
                row.programEligibilityId ?? row?.caseId ?? row?.benefitsCaseId
              }
              rows={row ?? []}
              columns={columns ?? []}
              rowCount={totalCount}
              pageSizeOptions={[50, 100]}
              paginationMode="server"
              paginationModel={{
                page: 0,
                pageSize: 50,
              }}
              onPaginationModelChange={handlePagination}
              autoHeight
              localeText={{ noRowsLabel: 'No data found' }}
              sx={{
                backgroundColor: 'white',
                minHeight: '350px',

                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: '700',
                  color: '#000000',
                },
                '& .MuiDataGrid-row': {
                  color: 'black',
                  textAlign: 'center',
                },
              }}
            />
          </Grid>
        </Box>
      )}
      {value === 2 && (
        <Grid pt={8}>
          <TabularView
            data={afterData ?? []}
            columns={columnsAfterBenifit ?? []}
            headerComponent={{ variant: 'h6' }}
            getId={(row) => row?.benefitsCaseId ?? row?.caseId}
            sx={{
              border: '1px solid #f0f0f0',
              table: {
                width: '100%',
                borderCollapse: 'collapse',
              },
              td: {
                padding: '12px 20px',
                // textAlign: 'center',
              },
              tr: {
                padding: '10px',
                ':nth-child(even)': {
                  backgroundColor: '#f9f9f9',
                },
              },
            }}
            slots={{
              noRowsOverlay: () => <EmptyState header="No Data" />,
            }}
          />
        </Grid>
      )} */}
      <DataGrid
        getRowId={(row) =>
          row.programEligibilityId ?? row?.caseId ?? row?.benefitsCaseId
        }
        rows={row ?? []}
        columns={columns ?? []}
        rowCount={totalCount}
        pageSizeOptions={[50, 100]}
        paginationMode="server"
        paginationModel={{
          page: 0,
          pageSize: 50,
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        onPaginationModelChange={handlePagination}
        autoHeight
        localeText={{ noRowsLabel: 'No data found' }}
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
          backgroundColor: 'white',
          minHeight: '350px',
          mt: '1rem',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '700',
            color: '#000000',
          },
          '& .MuiDataGrid-row': {
            color: 'black',
            textAlign: 'center',
          },
        }}
      />
    </Box>
  );
};

export default Cola;
