import React, { useEffect, useMemo, useState } from 'react';
import styles from '../Dashboard/DashboardContent/index.module.css';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Button,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Circle } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import cx from 'classnames';
import {
  myDashboardApi,
  overDueApi,
  assignedApi,
  unassignedApi,
} from '../../utils/services/apiRequests';
import { showErrorToast } from '../../utils/services/toast';
import dayjs from 'dayjs';
import useCaseStatus from '../../hooks/useCaseStatus';
import { map } from 'lodash';
import useLoader from '../../hooks/useLoader';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../store/selectors/authSelector';
import useLookup from '../../hooks/useLookup';

const PeriodicReports = () => {
  const { lkpFlatten, getLkpMatch } = useLookup('Dashboard');
  const [value, setValue] = useState(1);
  const token = useSelector(getAuthToken);
  const [appData, setAppData] = useState('');
  const [paperData, setPaperData] = useState('');
  const [value2, setValue2] = useState(1);
  const [rowsData, setRowsData] = useState([]);
  const [paperAppCount, setPaperAppCount] = useState(0);
  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [unasignPage, setUnasignPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [asignPage, setAsignPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [overDuePage, setOverDuePage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const { viewCase } = useCaseStatus();

  useEffect(() => {
    if (getLkpMatch) {
      dashboardRowData(page);
      setSelectedColumns(myDashboard);
    }
  }, []);

  useEffect(() => {
    selectApps();
  }, [value]);

  const caseStatus = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Grid container alignItems={'center'}>
          <Circle
            className={cx({
              [styles.open]: params?.row?.caseStsCd === 'OP',
              [styles.pending]: params?.row?.caseStsCd === 'PE',
              [styles.closed]: params?.row?.caseStsCd === 'CL',
              [styles.inprogess]: params?.row?.caseStsCd === 'IP',
              [styles.defaultStatus]: params?.row?.caseStsCd === '',
            })}
          />
          {getLkpMatch('CaseStatusCode', params?.row?.caseStsCd)}
        </Grid>
      </Grid>
    );
  };
  const remainingDays = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Grid
          className={cx(styles.default, {
            [styles.neg]: params?.row?.daysRemaining < 0,
            [styles.plusTen]: params?.row?.daysRemaining > 10,
            [styles.lessTen]:
              params?.row?.daysRemaining > 1 && params?.row?.daysRemaining < 10,
          })}
        >
          Due days is{' '}
          {params?.row?.daysRemaining < 0 ? 0 : params?.row?.daysRemaining}
        </Grid>
      </Grid>
    );
  };
  const action = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => viewCase(params.id, params.row.caseStsCd)}>
          <Tooltip title="View Case" placement="right">
            <VisibilityIcon />
          </Tooltip>
        </IconButton>
      </Grid>
    );
  };
  const disposeAlert = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => viewCase(params.id, params.row.caseStsCd)}>
          <Tooltip title="View Case" placement="right">
            <VisibilityIcon />
          </Tooltip>
        </IconButton>
      </Grid>
    );
  };
  const myDashboard = useMemo(
    () => [
      { field: 'caseId', headerName: 'Case ID', width: 150 },
      {
        field: 'alertType',
        headerName: 'Type',
        renderCell: (params) => {
          const programArray = params?.row?.programs?.split(',');
          return map(programArray, (p) => getLkpMatch('Program', p))?.join(',');
        },
        width: 150,
      },
      {
        field: 'alertTypeSubtype',
        headerName: 'Subtype',
        renderCell: (params) => {
          const programArray = params?.row?.programs?.split(',');
          return map(programArray, (p) => getLkpMatch('Program', p))?.join(',');
        },
        width: 150,
      },
      { field: 'hohName', headerName: 'HOH', width: 150 },
      {
        field: 'alertCreateDt',
        headerName: 'Create Date',
        renderCell: (params) =>
          dayjs(params?.row?.dueDate).format('MM-DD-YYYY'),
        width: 150,
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 150,
        renderCell: (params) => remainingDays(params),
      },
      {
        field: 'dueDate',
        headerName: 'Due Date',
        renderCell: (params) =>
          dayjs(params?.row?.dueDate).format('MM-DD-YYYY'),
        width: 150,
      },
      {
        field: 'distOffice',
        headerName: 'Dispose Alert',
        width: 150,
      },
      {
        field: 'Action',
        headerName: 'Action',
        width: 150,
        renderCell: (params) => action(params),
      },
    ],
    [getLkpMatch]
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const selectApps = () => {
    if (value === 1) {
      setValue2(1);
      setSelectedColumns(myDashboard);
      dashboardRowData(page);
    }
    if (value === 2) {
      try {
        showLoader();
      } catch (error) {
        showErrorToast();
      } finally {
        hideLoader();
      }
    }
  };

  const dashboardRowData = async ({ page, pageSize }) => {
    const paginationDataPaper = {
      resultsPerPage: pageSize,
      pageNumber: page,
    };

    try {
      setRowsData([]);
      showLoader();
      if (value === 1) {
        const res = await myDashboardApi(paginationDataPaper, token?.sub, 'BR');
        if (res.status === 200) {
          const { paginationPaperData = 0, resultsPaper } = res.data;
          setAppData(res.data);
          setRowsData(resultsPaper);
          setPaperAppCount(paginationPaperData?.totalRecords ?? 0);
        }
      }
    } catch (error) {
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  const onPageChange = (pages) => {
    if (value === 1) {
      setPage(pages);
      dashboardRowData(pages);
    }
    if (value === 2) {
      setUnasignPage(pages);
      dashboardRowData(pages);
    }

    if (value === 3) {
      setAsignPage(pages);
    }
    if (value === 4) {
      setOverDuePage(pages);
    }
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
    if (value === 1) {
      if (newValue === 1) {
        setRowsData(appData.resultsPaper);
      }
      if (newValue === 2) {
        setRowsData(appData.resultsRegular);
      }
    }
    if (value === 2) {
      if (newValue === 1) {
        setRowsData(paperData.unAssignedAlertsPaper);
      }
      if (newValue === 2) {
        setRowsData(paperData.casePgmList);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
      }}
    >
      <Grid
        sx={{
          '& .Mui-selected': {
            color: 'black !important',
            fontWeight: 700,
          },
          width: '100%',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            textTransform: 'none',
            width: '100%',
            borderBottom: '1px solid #bababa',
          }}
        >
          <Tab
            sx={{ flex: 1, textTransform: 'none' }}
            value={1}
            label="My Dashboard"
          />
        </Tabs>
      </Grid>

      {value === 1 && (
        <Grid container justifyContent={'center'}>
          <Tabs
            value={value2}
            onChange={handleChange2}
            sx={{
              border: '1px solid #bababa',
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              '& .Mui-selected': {
                color: 'white !important',
                fontWeight: 700,
                borderBottom: 'none',
                backgroundColor: 'var(--grey-400)',
              },

              '& .MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            <Tab
              sx={{ textTransform: 'none' }}
              value={1}
              label="Online Reported Changes"
            />
            <Tab
              sx={{ textTransform: 'none' }}
              value={2}
              label="Worker Entered Changes"
            />
          </Tabs>
        </Grid>
      )}

      <Stack marginTop={2}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            autoHeight
            getRowId={(row) =>
              row.caseId ? row.caseId : row.webId || row.webID
            }
            sx={{
              width: '100%',
              border: 'none',
              marginTop: 2,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '14px',
                fontWeight: '600',
                // padding: '0px 10px',
                textAlign: 'center',
                color: 'black',
                borderBottom: '1px solid var(--grey-400)',
              },
              '& .MuiDataGrid-row': {
                border: 'none',
              },
            }}
            slots={{
              noRowsOverlay: 'noData',
            }}
            rows={rowsData ?? []}
            rowCount={paperAppCount}
            columns={selectedColumns}
            pageSizeOptions={[10, 20, 30]}
            paginationMode="server"
            paginationModel={page}
            onPaginationModelChange={onPageChange}
            checkboxSelection={value === 2 || value === 3}
          />
        </Box>
      </Stack>
    </div>
  );
};

export default PeriodicReports;
