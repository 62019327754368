import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import FormSection from '../../../components/FormSection';
import { DatePicker } from '@mui/x-date-pickers';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { postAdditionalInfo } from '../../../utils/services/waiverAndFacility/apiRequest';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

export default function AddAdditionalInfo({
  caseId,
  getOptions,
  currentUserId,
  toggleClose,
  createNew,
  waiverAPI,
  AdditionData,
}) {
  const { yesNoOpts } = useMemo(() => {
    return {
      yesNoOpts: getOptions('YesNo'),
    };
  }, [getOptions]);

  const validationSchema = Yup.object({
    beginDate: Yup.string().required('Please select begin date'),
    guardianPersonAmount: Yup.string().when('guardianPerson', {
      is: (guardianPerson) => {
        return guardianPerson === 'Y';
      },
      then: () => Yup.string().required('Please enter fee amount'),
      otherwise: () => Yup.string().notRequired(),
    }),
    guardianPropertyAmount: Yup.string().when('guardianProperty', {
      is: (guardianProperty) => {
        return guardianProperty === 'Y';
      },
      then: () => Yup.string().required('Please enter fee amount'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      beginDate: AdditionData?.effectiveBeginDate ?? null,
      endDate: AdditionData?.effectiveEndDate ?? null,
      guardianPerson: AdditionData?.guardianPersonYN ?? 'N',
      guardianProperty: AdditionData?.guardianPropertyYN ?? 'N',
      guardianPersonAmount: AdditionData?.guardianPersonFeeAmount ?? '',
      guardianPropertyAmount: AdditionData?.guardianPropertyFeeAmount ?? '',
      maintenanceAllowance: AdditionData?.maintenanceAmountYN ?? 'N',
      goodCauseClaimYN: AdditionData?.goodCauseClaimYN ?? 'N',
      recordId: AdditionData?.recordId ?? 0,
    },
    validationSchema,
    onSubmit: (value) => {
      saveAdditinalInfo(value);
    },
  });

  const saveAdditinalInfo = async (waiverApiData) => {
    const addPayload = {
      dirtyStatus: '',
      institutions: [],
      institutionalAdditionalInfo: [
        {
          dirtyStatus: createNew ? 'insert' : 'update',
          effectiveBeginDate: waiverApiData?.beginDate,
          effectiveEndDate: waiverApiData?.endDate,
          goodCauseClaimYN: waiverApiData?.goodCauseClaimYN,
          guardianPersonYN: waiverApiData?.guardianPerson,
          guardianPersonFeeAmount: waiverApiData?.guardianPersonAmount,
          guardianPropertyFeeAmount: waiverApiData?.guardianPropertyAmount,
          guardianPropertyYN: waiverApiData?.guardianProperty,
          maintenanceAmountYN: waiverApiData?.maintenanceAllowance,
          recordId: waiverApiData?.recordId,
        },
      ],
      waivers: [],
    };

    // function formatCurrency(value) {
    //   let converToCurrency = value.toString().replace(/\D/g, '');
    //   converToCurrency = converToCurrency.replace(/(\d)(\d{2})$/, '$1.$2');
    //   converToCurrency = converToCurrency.replace(/(?=(\d{3})+(\D))\B/g, ',');
    //   return converToCurrency;
    // }
    try {
      showLoader();
      const res = await postAdditionalInfo(caseId, currentUserId, addPayload);
      if (res.status === 200) {
        createNew
          ? showSuccessToast('created Sucessfully')
          : showSuccessToast('Data Updated Sucessfully');

        toggleClose();
        waiverAPI(currentUserId);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box padding={2}>
      <FormSection>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={6}>
            <Field
              isDate
              name="beginDate"
              formik={formik}
              label="Begin Date"
              required
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field isDate name="endDate" formik={formik} label="End Date">
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Guardian Person</Typography>

            <RadioGroup
              row
              {...formik.getFieldProps('guardianPerson')}
              sx={{ marginLeft: 2 }}
            >
              <FormControlLabel value={'Y'} control={<Radio />} label="Yes" />
              <FormControlLabel value={'N'} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle2">Guardian Property</Typography>
            <RadioGroup
              row
              {...formik.getFieldProps('guardianProperty')}
              sx={{ marginLeft: 2 }}
            >
              <FormControlLabel value={'Y'} control={<Radio />} label="Yes" />
              <FormControlLabel value={'N'} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>

          {formik.values.guardianPerson === 'Y' && (
            <Grid item xs={6}>
              <Field
                name="guardianPersonAmount"
                label="Guardian Person Fee Amount"
                formik={formik}
                required={formik.errors.guardianPersonAmount}
              >
                <TextField placeholder="Enter the guardian gerson fee amount" />
              </Field>
            </Grid>
          )}
          {formik.values.guardianProperty === 'Y' && (
            <Grid item xs={6}>
              <Field
                name="guardianPropertyAmount"
                label="Guardian Property  Fee Amount"
                formik={formik}
                required={formik.errors.guardianPropertyAmount}
              >
                <TextField placeholder="Enter the guardian property  fee amount" />
              </Field>
            </Grid>
          )}

          <Grid item xs={6}>
            <Field
              name="maintenanceAllowance"
              formik={formik}
              label="Is Customer requesting Maintenance Allowance"
            >
              <Dropdown
                options={yesNoOpts}
                placeholder="Select the maintenance allowance"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('goodCauseClaimYN')}
                  checked={formik.values.goodCauseClaimYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      'goodCauseClaimYN',
                      checked ? 'Y' : 'N'
                    );
                  }}
                />
              }
              label="Good Cause Claim"
            />
          </Grid>
        </Grid>
      </FormSection>
      <Stack direction={'row'} justifyContent={'flex-end'} mt={1} spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button
          variant="contained"
          disabled={!createNew && !formik.dirty}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save & close
        </Button>
      </Stack>
    </Box>
  );
}
