export const clearancePayload = (data) => {
  const { firstName, lastName, ssn } = data;
  const [soSecPart1, soSecPart2, soSecPart3] = ssn.split('-');

  return {
    search: {
      individualId: '',
      headOfHouseholdYN: 'N',
      relationshipCd: '',
      altNames: [],
      additionalSsns: [],
      pregnantYN: 'N',
      individualDetails: {},
      aliasClientIds: [],
      memberClearedYN: 'N',
      memberEditableYN: 'N',
      cases: [],
      hasSsnYN: 'N',
      ssnReferral: '',
      personName: {
        firstName: firstName,
        lastName: lastName,
        middleName: '',
        type: '',
        suffix: '',
      },
      soSec: {
        dirtyStatus: '',
        soSecPart1: soSecPart1 ?? '',
        soSecPart2: soSecPart2 ?? '',
        soSecPart3: soSecPart3 ?? '',
        ssnVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: 'N',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: 'N',
        },
      },
      gender: '',
      race: {
        primaryRace: '',
        otherRaces: [],
      },
      deletedYN: 'N',
      eligibility: {},
      mdmId: null,
      dob: null,
    },
    paginationData: {
      resultsPerPage: 5,
      pageNumber: 0,
      totalRecords: 0,
    },
    range: null,
    systemCd: 'CID',
    address: null,
  };
};
