import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EarnedIncomeForm from './EarnedIncomeForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveEarnedIncome } from '../../../utils/services/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { some as Some, get, isEmpty } from 'lodash';
import useLoader from '../../../hooks/useLoader';

const AddEditEarnedIncomeDetailsDialog = ({
  setOpen,
  open,
  isAdd,
  lkpRaw,
  member,
  selectedData,
  caseId,
  fetchIncome,
  fedEmplrList,
  getOptions,
}) => {
  const initialValues = {
    employer: {
      employmentBeginDate: null,
      employmentEndDate: null,
      insurance: '',
      voluntaryQuitYN: 'N',
      voluntaryQuitGoodCause: 'N',
      quitDate: null,
      wagesPerHours: '',
      hoursPerMonth: '',
      rsnEmplmntQuit: '',
      employerAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipcode: '',
      },
      phoneNumber: {
        phoneNumber: '',
        phoneNumberExt: '',
        phoneNumberType: '',
      },
      companyLegalName: '',
      employmentVerification: {
        verificationSourceCode: '',
      },
      employerFederalId: '',
    },
    incomeType: '',
    earnedIncomeAmount: [
      {
        frequency: '',
        effectiveBeginDate: null,
        effectiveEndDate: null,
        amountModel: [],
        recordId: 0,
        lateReportIndicator: 'N',
        useMonth: '',
        reportDate: null,
        expenses: [],
      },
    ],
  };
  const descriptionElementRef = useRef('');

  const [showLoader, hideLoader] = useLoader();
  const [activeTab, setActiveTab] = useState(0);
  const [previousState, setPreviousState] = useState(null);

  const validationSchema = Yup.object({
    incomeType: Yup.string().required('field is reuired'),
    employer: Yup.object().shape({
      companyLegalName: Yup.string().required('field is required'),
      employmentBeginDate: Yup.date().required('Start Date is required'),
      employmentEndDate: Yup.date()
        .nullable()
        .min(
          Yup.ref('employmentBeginDate'),
          'End Date must be after Start Date'
        ),
      quitDate: Yup.date().when(
        'voluntaryQuitYN',
        (voluntaryQuitYN, schema) => {
          if (voluntaryQuitYN[0] === 'Y')
            return Yup.date().required('field is required');
          return Yup.date().nullable();
        }
      ),
      rsnEmplmntQuit: Yup.string().when(
        'voluntaryQuitYN',
        (voluntaryQuitYN, schema) => {
          if (voluntaryQuitYN[0] === 'Y')
            return Yup.string().required('field is required');
          return schema;
        }
      ),
    }),
    earnedIncomeAmount: Yup.array().of(
      Yup.object().shape({
        effectiveBeginDate: Yup.date().required('Required'),
        effectiveEndDate: Yup.date()
          .nullable()
          .min(
            Yup.ref('effectiveBeginDate'),
            'End Date must be after Start Date'
          ),
        frequency: Yup.string().required('Required'),

        useMonth: Yup.string().when(
          'lateReportIndicator',
          (lateReportIndicator, schema) => {
            if (lateReportIndicator[0] === 'Y')
              return Yup.string().required('field is required');
            return schema;
          }
        ),
        expenses: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required('required'),
            amount: Yup.string().required('required'),
            verificationSourceCode: Yup.string().required('Required'),
          })
        ),
      })
    ),
  });

  const customValidation = (values) => {
    let valid = true;

    // Custom validation for amountModel of earnedIncomeAmount
    const errors = {};

    if (values.earnedIncomeAmount) {
      errors.earnedIncomeAmount = values.earnedIncomeAmount.map((item) => {
        if (item.amountModel) {
          return {
            amountModel: item.amountModel.map((model, modelIndex, arr) => {
              const modelErrors = {};

              // Check if frequency inside the item object is either "BW" or "WE"
              if (item.frequency === 'BW' || item.frequency === 'WE') {
                // Skip validation for last index
                if (modelIndex !== arr.length - 1) {
                  if (!model.amount || !model.verificationSourceCode) {
                    valid = false;
                    modelErrors.hours = model.hours
                      ? undefined
                      : 'Hours is required';
                    modelErrors.amount = model.amount
                      ? undefined
                      : 'Amount is required';
                    modelErrors.verificationSourceCode =
                      model.verificationSourceCode
                        ? undefined
                        : 'Verification Source Code is required';
                  }
                }
              } else {
                // Validate all indices if frequency is not "BW" or "WE"
                if (!model.amount || !model.verificationSourceCode) {
                  valid = false;
                  modelErrors.hours = model.hours
                    ? undefined
                    : 'Hours is required';
                  modelErrors.amount = model.amount
                    ? undefined
                    : 'Amount is required';
                  modelErrors.verificationSourceCode =
                    model.verificationSourceCode
                      ? undefined
                      : 'Verification Source Code is required';
                }
              }

              return modelErrors;
            }),
          };
        }
        return {};
      });
    }

    return valid ? {} : errors; // Return empty object if all required fields are filled; otherwise, return errors
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    formik.setValues(initialValues);
    setActiveTab(0);
  };

  const onSubmit = async (values) => {
    try {
      showLoader();
      const res = await saveEarnedIncome(
        caseId,
        values,
        member.individualId,
        isAdd
      );

      if (res.status === 200) {
        showSuccessToast(
          isAdd ? 'Earned Income added' : 'Earned Income Updated'
        );
        fetchIncome();
        setOpen(false);
        setActiveTab(0);
        formik.resetForm();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    validate: customValidation,
    onSubmit,
  });

  useEffect(() => {
    if (!isAdd) {
      setPreviousState(selectedData);
    }
  }, [isAdd]);

  const handleDiscard = () => {
    formik.setValues({
      ...previousState,
      incomeType: previousState.incomeType,
      recordId: previousState?.recordId,
      earnedIncomeAmount: previousState.earnedIncomeAmount,
    });
  };

  const isIncomeTypeEmpty = Some([
    isEmpty(get(formik, 'values.incomeType')),
    isEmpty(get(formik, 'values.employer.employmentBeginDate')),
    isEmpty(get(formik, 'values.employer.companyLegalName')),
  ]);

  const isButtonDisabled = (amountDetails) => {
    if (amountDetails) {
      return amountDetails.some((item) => {
        return isEmpty(item?.effectiveBeginDate) || isEmpty(item?.frequency);
      });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="earnedIncomeLabel"
        aria-describedby="earnedIncomeDescription"
        sx={{
          '& .MuiDialog-container': {
            '& >.MuiPaper-root': {
              width: '80vw',
              height: '80vh',
            },
          },
        }}
      >
        <DialogTitle id="earnedIncomeLabel">
          {isAdd ? 'Add Earned' : 'Edit Earned'} Income
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="earnedIncomeDescription"
            ref={descriptionElementRef}
          >
            <EarnedIncomeForm
              lkpRaw={lkpRaw}
              member={member}
              isAdd={isAdd}
              formik={formik}
              selectedData={selectedData}
              fedEmplrList={fedEmplrList}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              getOptions={getOptions}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={isAdd ? handleClose : handleDiscard}
            variant="outlined"
          >
            Discard
          </Button>
          {activeTab === 0 ? (
            <Button
              variant="contained"
              disabled={
                isIncomeTypeEmpty ||
                formik.values.employer.employmentBeginDate === 'Invalid Date'
              }
              onClick={() => setActiveTab(1)}
            >
              Next
            </Button>
          ) : isAdd ? (
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={
                isButtonDisabled(formik.values.earnedIncomeAmount) ||
                formik.errors?.employer?.quitDate ||
                formik.errors?.employer?.rsnEmplmntQuit
              }
            >
              Save & Close
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={!formik.dirty}
            >
              Update & Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddEditEarnedIncomeDetailsDialog;
