import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import Field from '../../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { postSubstanceAbuse } from '../../../../utils/services/ManualNotices/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import InputMask from 'react-input-mask';

export default function SubstanceAbuse({
  toggleClose,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();
  const formik = useFormik({
    initialValues: {
      date: dataValue?.applicationDate ?? '',
      dssOffice: dataValue?.dssOffice ?? '',
      maNo: dataValue?.maNumber ?? '',
      hoh: dataValue?.hohName ?? '',
      auNo: dataValue?.auNumber ?? '',
      applicantName: dataValue?.recipientName ?? '',
      ssNo: dataValue?.ssNumber ?? '',
      address: dataValue?.address ?? '',
      telephoneNo: dataValue?.phone ?? '',
      zip: dataValue?.zip ?? '',
      felony: dataValue?.felonyDrugConvivtion ?? '',
      dob: dataValue?.dob ?? '',
      mco: dataValue?.mco ?? '',
      ldssManager: dataValue?.ldssCaseManager ?? '',
      managerTelephone: dataValue?.managerTelephone ?? '',
      checkbox1: dataValue?.checkbox1 ?? '',
      checkbox2: dataValue?.checkbox2 ?? '',
      checkbox3: dataValue?.checkbox3 ?? '',
      checkbox4: dataValue?.checkbox4 ?? '',
      checkbox5: dataValue?.checkbox5 ?? '',
      checkbox6: dataValue?.checkbox6 ?? '',
      checkbox7: dataValue?.checkbox7 ?? '',
      treatmentTo: dataValue?.referedTo ?? '',
      onDate: dataValue?.referredOn ?? '',
      checkbox8: dataValue?.checkbox8 ?? '',
      assessmentFailed: dataValue?.referredTreatment ?? '',
      checkbox9: dataValue?.checkbox9 ?? '',
      treatmentCurrently: dataValue?.treatmentAt ?? '',
      verifiedby: dataValue?.treatmentVerifiedBy ?? '',
      checkbox10: dataValue?.checkbox9 ?? '',
      refferalDate: dataValue?.referralMadeOn ?? '',
      checkbox11: dataValue?.checkbox11 ?? '',
      comt: dataValue?.comments ?? '',
      checkbox12: dataValue?.checkbox12 ?? '',
      drugTo: dataValue?.testingTo ?? '',
      drugOn: dataValue?.testingOn ?? '',
      checkbox13: dataValue?.checkbox13 ?? '',
      positive: dataValue?.positive ?? '',
      negative: dataValue?.negative ?? '',
      negativeDate: dataValue?.dateForNegative ?? '',
      noShow: dataValue?.noShow ?? '',
      additionSpecialist: dataValue?.additionSpecialist ?? '',
      specialistNumb: dataValue?.telephone ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      postSubstance(values);
    },
  });

  const postSubstance = async (data) => {
    try {
      showLoader();
      const res = await postSubstanceAbuse(data);
      if (res.status === 200) {
        showSuccessToast('Successfully Created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Grid container spacing={2} mt="1rem">
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h3"
            >
              Substance Abuse
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h4"
            >
              Screening referral form
            </Typography>
          </Grid>
          <Grid item xs={5} ml="2rem" mt="1rem">
            <Field name="date" formik={formik} isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt="1rem" ml="2rem" mr="2rem" mb="1rem">
          <Grid item xs={5}>
            <Field name="dssOffice" label="DSS Office" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="maNo" label="MA No." formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="hoh"
              label="Head of Household"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="auNo"
              label="AU No."
              formik={formik}
              normalizers={[maxLength(30), number]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="applicantName"
              label="Applicant/Receipent Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="ssNo"
              label="SS No."
              formik={formik}
              normalizers={[maxLength(30), number]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="address"
              label="Address"
              formik={formik}
              normalizers={[maxLength(50)]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="telephoneNo" label="Telephone No." formik={formik}>
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.telephoneNo}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="zip"
              label="Zip"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="felony" label="Felony Drug Conviction" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="dob" label="Date of Birth" formik={formik} isDate>
              <DatePicker
                disableFuture
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="mco" label="MCO (if applicable)" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="ldssManager" label="LDSS Case Manager" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="managerTelephone"
              label="Telephone No."
              formik={formik}
            >
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.managerTelephone}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
        </Grid>
        {/* <Divider
          sx={{
            marginTop: '1rem',
          }}
        /> */}
        <Grid item xs={12} mt="2rem">
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            variant="h4grey"
          >
            Addiction Specialist Completes
          </Typography>
        </Grid>
        <Grid container mt="1rem" ml="2rem" mb="1rem" spacing={2}>
          <Grid item xs={8}>
            <FormControlLabel
              name="checkbox1"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox1')}
                  checked={formik.values.checkbox1 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox1', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox1}
                />
              }
              label={
                <Typography variant="body1">
                  Customer failed to appear for screening
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              name="checkbox2"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox2')}
                  checked={formik.values.checkbox2 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox2', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox2}
                />
              }
              label={
                <Typography variant="body1">
                  Customer refused to be screened and/or assessed.
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              name="checkbox3"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox3')}
                  checked={formik.values.checkbox3 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox3', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox3}
                />
              }
              label={
                <Typography variant="body1">
                  Customer screen was negative
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              name="checkbox4"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox4')}
                  checked={formik.values.checkbox4 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox4', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox4}
                />
              }
              label={
                <Typography variant="body1">
                  Customer failed to sign 1176 when substance abuse screen was
                  positive.
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              name="checkbox5"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox5')}
                  checked={formik.values.checkbox5 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox5', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox5}
                />
              }
              label={
                <Typography variant="body1">
                  Customer's screen was positive. (Forward Independence Plan to
                  Addiction Specialist)
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              name="checkbox6"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox6')}
                  checked={formik.values.checkbox6 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox6', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox6}
                />
              }
              label={
                <Typography variant="body1">
                  Customer acknowledged a substance abuse problem. (Forward
                  Independence plan to addiction specialist)
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={8}>
            <FormControlLabel
              name="checkbox7"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox7')}
                  checked={formik.values.checkbox7 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox7', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox7}
                />
              }
              label={
                <Typography variant="body1">
                  Customer referred for assessment/treatment to:
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={5}>
            <Field name="treatmentTo" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">on</Typography>
          </Grid>
          <Grid item xs={4}>
            <Field name="onDate" formik={formik} isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name="checkbox8"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox8')}
                  checked={formik.values.checkbox8 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox8', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox8}
                />
              }
              label={
                <Typography variant="body1">
                  Customer failed to appear for referred assessment/treatment by
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Field name="assessmentFailed" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              name="checkbox9"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox9')}
                  checked={formik.values.checkbox9 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox9', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox9}
                />
              }
              label={
                <Typography variant="body1">
                  Customer currently in treatment at
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Field name="treatmentCurrently" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={2} mt="0.8rem">
            <Typography variant="subtitle1">Verified by</Typography>
          </Grid>
          <Grid item xs={2}>
            <Field name="verifiedby" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              name="checkbox10"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox10')}
                  checked={formik.values.checkbox10 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox10', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox10}
                />
              }
              label={
                <Typography variant="body1">Service Referral made</Typography>
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Field name="refferalDate" formik={formik} isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              name="checkbox11"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox11')}
                  checked={formik.values.checkbox11 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox11', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox11}
                />
              }
              label={<Typography variant="body1">Comments:</Typography>}
            />
          </Grid>
          <Grid item xs={6}>
            <Field name="comt" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={12} mt="0.5rem">
            <Typography
              variant="h5grey"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Definitions of drug kingpin and volume dealer:
            </Typography>
          </Grid>
          <Grid item xs={11} mt="0.5rem">
            <Typography variant="h6">
              Drug Kingpin- an organizer, supervisor,financier, or manager who
              acts as a co-conspirator in a conspiracy to manufacture,
              distribute, dispense, transport in, or being into the state a
              controlled dangerous substance.<br></br>Volume dealer- An
              individual, who manufactures, distributes, dispenses or possesses
              certain qualities of a controlled dangerous substance.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              name="checkbox12"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox12')}
                  checked={formik.values.checkbox12 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox12', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox12}
                />
              }
              label={
                <Typography variant="body1">
                  Referred for drug testing/assessment to
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Field name="drugTo" formik={formik}>
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={3} ml="0.5rem">
            <Field name="drugOn" formik={formik} isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              name="checkbox13"
              control={
                <Checkbox
                  {...formik.getFieldProps('checkbox13')}
                  checked={formik.values.checkbox13 === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('checkbox13', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.checkbox13}
                />
              }
              label={<Typography variant="body1">Results</Typography>}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              name="positive"
              control={
                <Checkbox
                  {...formik.getFieldProps('positive')}
                  checked={formik.values.positive === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('positive', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.positive}
                />
              }
              label={<Typography variant="body1">Positive</Typography>}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              name="negative"
              control={
                <Checkbox
                  {...formik.getFieldProps('negative')}
                  checked={formik.values.negative === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('negative', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.negative}
                />
              }
              label={<Typography variant="body1">Negative</Typography>}
            />
          </Grid>
          <Grid item xs={2}>
            <Field name="negativeDate" formik={formik} isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={2} ml="1rem">
            <FormControlLabel
              name="noShow"
              control={
                <Checkbox
                  {...formik.getFieldProps('noShow')}
                  checked={formik.values.noShow === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('noShow', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.noShow}
                />
              }
              label={<Typography variant="body1">No Show</Typography>}
            />
          </Grid>
          <Grid item xs={5}>
            <Field
              name="additionSpecialist "
              label="Addition Specialist"
              formik={formik}
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="specialistNumb"
              label="Telephone Number"
              formik={formik}
            >
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.specialistNumb}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
