import React, { useEffect, useMemo, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import styles from './index.module.css';
import PropTypes from 'prop-types';
import {
  Button,
  Stack,
  Tab,
  Tabs,
  Grid,
  TextField,
  Skeleton,
} from '@mui/material';
import { useParams } from 'react-router';
import { get, map } from 'lodash';
import useLoader from '../../hooks/useLoader';
import useLookup from '../../hooks/useLookup';
import PageHeader from '../../components/PageHeader';
import SearchMode from './SearchMode';
import SearchResult from './SearchResult';
import ReasonDialog from './ReasonDialog';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import BackHandIcon from '@mui/icons-material/BackHand';
import {
  getSearchData,
  pdfDownload,
} from '../../utils/services/correspondence/apiRequest';
import dayjs from 'dayjs';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import { toast } from 'react-toastify';
import usePageNav from '../../hooks/usePageNav';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ marginTop: '20px' }}
    >
      {value === index && children}
    </div>
  );
};

function Correspondence(props) {
  const { caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [printId, setPrintId] = useState('');
  const { lkpFlatten, getLkpMatch, getOptions, lkpRaw, islkpFailed } =
    useLookup('Notices');
  const [searchData, setSearchData] = useState([]);

  const onTabChange = (e, value) => {
    setActiveTab(value);
    setSearchData([]);
  };

  const columns = [
    { field: 'caseId', headerName: 'Case ID', flex: 1 },
    {
      field: 'noticeTypeCd',
      headerName: 'Notice Type',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'triggerDt',
      headerName: 'Trigger Date',
      flex: 1,
      sortable: false,
      align: 'end',
      disableColumnMenu: true,
    },
    {
      field: 'programCd',
      headerName: 'Program Name',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: 'spprsnReason',
      headerName: 'Reason',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'pdf_download',
      headerName: 'PDF',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      align: 'left',
      renderCell: (params) => (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => downloadPdf(params.row.printSmryId)}
          >
            <Typography style={{ color: '#7ABBEB' }}>Download</Typography>
            <IconButton aria-label="pdf_download">
              <GetAppIcon style={{ color: '#7ABBEB' }} />
            </IconButton>
          </Box>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: activeTab === 2 ? 'Re-generated / Re-send' : 'Suppress',
      // width: activeTab === 2 ? 200 : 150,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="suppress"
            onClick={() => {
              setOpen(true);
              setPrintId(params.row.printSmryId);
            }}
          >
            <BackHandIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const onClose = () => {
    setOpen(false);
  };

  const downloadPdf = async (pdfId) => {
    if (!pdfId) {
      toast.error('No PDF URL available');
      return;
    }

    try {
      showLoader();
      const response = await pdfDownload(pdfId);
      // if (response.status === 200 && response.data) {
      //   const blob = new Blob([response.data], { type: 'application/pdf' });
      //   const downloadUrl = window.URL.createObjectURL(blob);
      //   const link = document.createElement('a');
      //   link.href = downloadUrl;
      //   link.setAttribute('download', `downloaded_pdf_${pdfId}.pdf`);
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      //   window.URL.revokeObjectURL(downloadUrl);
      // }
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error while downloading the PDF:', error);
      toast.error('Failed to download PDF');
    } finally {
      hideLoader();
    }
  };

  const fetchSearchData = async (id) => {
    let paramsUrl = `?fromDate=${''}&currentDate=${''}&canShowManSup=${
      activeTab === 2 ? 'Y' : 'N'
    }`;
    try {
      showLoader();
      const res = await getSearchData(id, paramsUrl);
      if (res.status === 200) {
        setSearchData(res.data);
        // showSuccessToast('Successfully fetched');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if ((activeTab === 0 || activeTab === 2) && caseId) {
      fetchSearchData(caseId);
    }
  }, [activeTab]);

  const renderReason = (item) => {
    const suppressionInd = get(item, 'ccPrintSummary.suppressionInd', '');
    const reason = get(item, 'ccPrintSummary.spprsnReason', '');
    if (!suppressionInd) return '-';
    return reason !== '' ? reason : item.otherReason;
  };

  const rows = useMemo(
    () =>
      map(searchData, (item, index) => {
        return {
          ...item.ccPrintSummary,
          id: index,
          caseId: caseId ?? '',
          noticeTypeCd:
            lkpFlatten.NoticeType[get(item, 'ccPrintSummary.noticeTypeCd', '')],
          programCd:
            lkpFlatten.Program[get(item, 'ccPrintSummary.programCd', '')],
          triggerDt: dayjs(get(item, 'ccPrintSummary.triggerDt', '')).format(
            'YYYY-MM-DD'
          ),
          spprsnReason: renderReason(item),
        };
      }),
    [searchData]
  );

  return (
    <>
      <PageHeader title="Notices & Letters" />
      <div className={styles.container}>
        <Paper elevation={3} className={styles.viewPanel}>
          <div className={styles.searchContent}>
            <Tabs
              onChange={onTabChange}
              value={activeTab}
              variant="fullWidth"
              centered
              sx={{
                '& .Mui-selected': {
                  backgroundColor: '#7ABBEB !important',
                  color: '#fff !important',
                },
                borderRadius: '10px 10px 0 0',
              }}
            >
              <Tab label="Current Notice" />
              <Tab label="Historical Notice" />
              <Tab label="Suppressed Notice" />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <SearchMode setSearchData={setSearchData} activeTab={activeTab} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <SearchMode setSearchData={setSearchData} activeTab={activeTab} />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <SearchMode setSearchData={setSearchData} activeTab={activeTab} />
            </TabPanel>
          </div>
          <div className={styles.resultContent}>
            <SearchResult rows={rows} columns={columns} />
          </div>
        </Paper>
      </div>
      <ReasonDialog
        setOpen={setOpen}
        open={open}
        onClose={onClose}
        activeTab={activeTab}
        getOptions={getOptions}
        printId={printId}
        caseId={caseId}
        fetchSearchData={fetchSearchData}
      />
    </>
  );
}

export default Correspondence;
