export const searchVendorPayload = (payload) => {
  console.log(payload);
  return {
    paginationData: {
      pageNumber: 1,
      resultsPerPage: 4,
    },
    range: null,
    results: [],
    search: payload,
    systemCd: 'W',
  };
};
