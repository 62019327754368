import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import styles from '../../index.module.css';

export default function CPEarnedIncome({
  data,
  getLkpMatch,
  hohMemberData,
  handleCheckbox,
}) {
  const getMemberNameByIndividualId = useCallback(
    (individualId) => {
      let result = hohMemberData?.find(
        (item) => item.dummyIndividualId === individualId
      );
      return `${result?.firstName ?? ''} ${result?.lastName ?? ''}`;
    },
    [data, hohMemberData]
  );

  const formatAddress = useCallback(
    (addressLine1, addressLine2, city, state, zipcode) => {
      const parts = [addressLine1, addressLine2, city, state, zipcode].filter(
        Boolean
      );
      return parts.join(', ');
    },
    [data]
  );

  return (
    <>
      {map(data, (item, index) => {
        const { earnedIncomeAmount, employer, incomeType } =
          item?.earnedIncome ?? {};
        const { companyLegalName, employmentBeginDate, employerAddress } =
          employer ?? {};
        const { addressLine1, addressLine2, city, state, zipcode } =
          employerAddress ?? {};
        const { amountModel, frequency } = earnedIncomeAmount?.[0] ?? {};
        return (
          <Stack direction="row" pl={1}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Checkbox />}
              onChange={(event) => handleCheckbox(event, index)}
              checked={item?.importSelected}
            />
            <div className={styles.card}>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 1,
                      paddingBottom: '0.5rem',
                      border: 0,
                    },
                  }}
                >
                  <TableBody className={styles.tableBody}>
                    <TableRow
                      sx={{ borderBottom: '1px solid var(--grey-350)' }}
                    >
                      <TableCell>
                        <Typography variant="h6grey">
                          Individual Name
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="h6">
                          {getMemberNameByIndividualId(item?.individualId) ??
                            ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Employer</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {companyLegalName ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          Earned Income Type
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch('EarningType', incomeType)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          Employer's Address
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {formatAddress(
                            addressLine1,
                            addressLine2,
                            city,
                            state,
                            zipcode
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Begin Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {employmentBeginDate ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Frequency</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch('Frequency', frequency)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {map(amountModel, (ele, ind) => (
                      <>
                        <TableRow key={`amount-detail-${ind}`}>
                          <TableCell
                            colSpan={2}
                            sx={{
                              borderTop: `${
                                ind === 0 ? '1px solid #e6e6d9 !important' : ''
                              }`,
                            }}
                          >
                            {ind === 0 ? (
                              <Stack pb={2}>
                                <Typography variant="h6grey">
                                  Amount Details :
                                </Typography>
                              </Stack>
                            ) : null}
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Typography variant="h6grey">Hours</Typography>
                              <Typography variant="subtitle2">
                                {ele?.hours ?? 'NA'}
                              </Typography>
                              <Typography variant="h6grey">Amount</Typography>
                              <Typography variant="subtitle2">
                                {ele?.amount ? `$${ele?.amount}` : 'NA'}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Stack>
        );
      })}
    </>
  );
}
