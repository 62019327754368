import React, { useRef } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveWbcchpDetails } from '../../../utils/services/healthAndDisability/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';
import WbcchpForm from './WbcchpForm';

const AddEditWbcchpDetailsDialog = ({
  setOpen,
  open,
  isAdd,
  member,
  caseId,
  getOptions,
  fetchData,
  selectedData,
  setSelectedData,
}) => {
  const {
    recordId,
    effectiveBeginDate,
    effectiveEndDate,
    doctorsNoteDate,
    medicallyNecessaryYN,
    ongoingTreatmentYN,
    verificationSourceCode,
  } = selectedData;

  const initialValues = {
    recordId: recordId ?? 0,
    dirtyStatus: isAdd ? 'insert' : 'update',
    effectiveBeginDate: effectiveBeginDate ?? null,
    effectiveEndDate: effectiveEndDate ? effectiveEndDate : null,
    doctorsNoteDate: doctorsNoteDate ?? null,
    medicallyNecessaryYN: medicallyNecessaryYN ?? '',
    ongoingTreatmentYN: ongoingTreatmentYN ?? '',
    verificationSourceCode: verificationSourceCode ?? '',
  };
  const descriptionElementRef = useRef('');

  const [showLoader, hideLoader] = useLoader();

  const validationSchema = Yup.object({
    effectiveBeginDate: Yup.date().required('field is required'),
    verificationSourceCode: Yup.string().required('field is required'),
    doctorsNoteDate: Yup.date().when('verificationSourceCode', {
      is: (verificationSourceCode) => verificationSourceCode === 'RE',
      then: () => Yup.date().required('field is required'),
      otherwise: () => Yup.date().notRequired(),
    }),
  });

  const handleClose = () => {
    setOpen(false);
    setSelectedData({});
    formik.resetForm();
  };

  const onSubmit = async (values) => {
    try {
      showLoader();
      const res = await saveWbcchpDetails(caseId, member.individualId, values);

      if (res.status === 200) {
        showSuccessToast(
          isAdd ? 'WBCCHP details added' : 'WBCCHP details updated'
        );

        fetchData(member.individualId);
        handleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="earnedIncomeLabel"
        aria-describedby="earnedIncomeDescription"
      >
        <DialogTitle id="earnedIncomeLabel">
          {isAdd ? 'Add Wbcchp Details' : 'Edit Wbcchp Details'}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="earnedIncomeDescription"
            ref={descriptionElementRef}
          >
            <WbcchpForm getOptions={getOptions} formik={formik} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Discard
          </Button>
          {isAdd ? (
            <Button variant="contained" onClick={formik.handleSubmit}>
              Save & Close
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={!formik.dirty}
            >
              Update & Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddEditWbcchpDetailsDialog;
