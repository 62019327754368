import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Tooltip } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import cx from 'classnames';
import styles from './index.module.css';
import Icon from '../../../../components/Icon';
import { noop } from 'lodash';

function SmButton({
  expanded,
  icon,
  title,
  to,
  onClick,
  collapsable,
  collapsed,
  id,
  home,
}) {
  const ref = useRef();
  const ActualComponent = collapsable ? 'div' : NavLink;
  const actionProps = collapsable ? { onClick } : { to };

  if (!title) return null;

  return (
    <ActualComponent
      {...actionProps}
      className={cx(styles.smButton, {
        [styles.expanded]: !expanded,
        [styles.collapsable]: collapsable,
        [styles.collapsed]: collapsed,
        [styles.home]: home,
      })}
      end
      label-id={id}
      ref={ref}
      // title={id}
    >
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      {expanded && <Typography variant="body2">{title}</Typography>}
      {collapsable && expanded && (
        <div className={styles.chevron}>
          <Icon name="ChevronSolidDown" height="5px" width="10px" />
        </div>
      )}
    </ActualComponent>
  );
}

SmButton.propTypes = {
  expanded: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  collapsable: PropTypes.bool,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  home: PropTypes.bool,
};

SmButton.defaultProps = {
  expanded: false,
  icon: null,
  title: '',
  collapsable: false,
  collapsed: false,
  onClick: noop,
  to: '',
  home: false,
};

export default SmButton;
