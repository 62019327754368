import { Skeleton, Grid } from '@mui/material';
import React from 'react';

export default function RelationSkl() {
  return (
    <Grid container spacing={1}>
      {Array.from({ length: 4 }, () => (
        <>
          <Grid item xs={4}>
            <Skeleton height="3rem" animation="wave" />
          </Grid>
          <Grid item xs={4}>
            <Skeleton height="3rem" animation="wave" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height="3rem" animation="wave" />
          </Grid>
          <Grid item xs={2}>
            <Skeleton height="3rem" animation="wave" />
          </Grid>
        </>
      ))}
    </Grid>
  );
}
