import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TabularView from '../../../components/TabularView';
import ActionButtons from '../../../components/ActionButtons';
import CitizenshipForm from '../CitizenshipForm';
import { includes, isEmpty } from 'lodash';
import EmptyState from '../../../components/EmptyState';
import { Button, IconButton, Tooltip, Stack } from '@mui/material';
import { ModeEditOutlineOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import dayjs from 'dayjs';

const StyledTabularView = styled(TabularView)({
  table: {
    // width: 'auto',
    marginTop: '.15rem',
    border: '2px solid var(--grey-200)',
  },
  td: {
    // paddingBottom: '12px',
    border: 0,
    padding: '0.5rem',
    // padding: '0 1.25rem 0.62rem  0.5rem',
  },
  th: {
    padding: '0.5rem',
  },
  tr: {
    padding: '0 1.25rem 0.62rem  0',
    ':nth-child(even)': {
      //   backgroundColor: 'red',
      backgroundColor: 'var(--grey-200)',
    },
  },
});

function CitizenshipHistory({
  citizenships,
  getLkpMatch,
  caseId,
  getOptions,
  fetchCitizenship,
  addCitizenship,
  lkpRaw,
  selected,
  toggleAddModal,
}) {
  const [citizenshipToEdit, setCitizenshipToEdit] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const toggleEditModal = () => setOpenEditModal(!openEditModal);
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const params = useParams();

  const columns = [
    {
      headerName: '#',
      renderCell(_params, i) {
        return i + 1;
      },
    },
    {
      headerName: 'Begin Date',
      field: 'beginDt',
    },
    {
      headerName: 'End Date',
      field: 'endDt',
      getValue(endDt) {
        return includes(endDt, '9999') ? '-' : endDt;
      },
    },
    {
      headerName: 'Citizenship Status',
      getValue({ usCitizenshipStatus }) {
        return getLkpMatch('Citizenship', usCitizenshipStatus);
      },
    },
    {
      headerName: 'Action',
      sx: { width: '10rem', border: '1px solid red' },
      renderCell(params, i) {
        const onEdit = () => {
          setCitizenshipToEdit(params);
          setOpenEditModal(true);
        };
        const endDate = params?.endDt;
        const isEndDateInFuture = dayjs(endDate).isAfter(dayjs(), 'day');

        return (
          <IconButton
            onClick={isEndDateInFuture ? () => onEdit('edit') : null}
            disabled={!isEndDateInFuture}
          >
            <Tooltip
              title={isEndDateInFuture ? 'Edit' : 'End date has passed'}
              placement="top"
            >
              <ModeEditOutlineOutlined fontSize="small" />
            </Tooltip>
          </IconButton>
        );
      },
    },
  ];

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  if (isEmpty(citizenships)) {
    return (
      <EmptyState
        header="Citizenship has not been added."
        primaryAction={
          <Button onClick={addCitizenship} variant="contained">
            Add citizenship
          </Button>
        }
      />
    );
    // return (
    //   <Typography variant="h3" sx={{ m: '5rem', textAlign: 'center' }}>
    //     Citizenship has not been added.
    //   </Typography>
    // );
  }

  return (
    <>
      <TabPanel value={activeTab} index={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: '1rem', mb: '1rem' }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload files
          </Button>
          <Button variant="outlined" size="small" onClick={toggleAddModal}>
            + Add Citizenship
          </Button>
        </Stack>
      </TabPanel>
      {openEditModal && (
        <CitizenshipForm
          editMode
          citizenship={citizenshipToEdit}
          open={openEditModal}
          caseId={caseId}
          onClose={toggleEditModal}
          getOptions={getOptions}
          fetchCitizenship={fetchCitizenship}
          individualId={citizenshipToEdit?.individualId}
        />
      )}
      <StyledTabularView
        headerComponent={{ variant: 'subtitle1' }}
        columns={columns}
        data={citizenships}
      />
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </>
  );
}

CitizenshipHistory.propTypes = {};

export default CitizenshipHistory;
