import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Stack,
  Grid,
  TextField,
} from '@mui/material';
import styles from './index.module.css';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import useLoader from '../../hooks/useLoader';
import useLookup from '../../hooks/useLookup';
import PageHeader from '../../components/PageHeader';
import HistoryDialog from './HistoryDialog';
import ViewDetailsDialog from './ViewDetailsDialog';
import IconButton from '@mui/material/IconButton';
import {
  searchIssuance,
  historyIssuance,
  viewIssuance,
} from '../../utils/services/Issuance/apiRequests';
import dayjs from 'dayjs';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import { toast } from 'react-toastify';
import usePageNav from '../../hooks/usePageNav';
import SearchMode from './SearchMode';
import SearchResult from './SearchResult';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryIcon from '@mui/icons-material/History';

function Issuance({ caseHome }) {
  const { caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false);
  const [isOpenViewDialog, setIsOpenViewDialog] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [viewIssuanceData, setViewIssuanceData] = useState({});
  const { lkpFlatten, getLkpMatch, getOptions, lkpRaw, islkpFailed } =
    useLookup('Issuance');

  const columns = [
    { field: 'programCd', headerName: 'Program', flex: 1 },
    {
      field: 'bnftIssncNum',
      headerName: 'Issuance #',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'agHeadName',
      headerName: 'AG Head',
      flex: 1,
      sortable: false,
      align: 'end',
      disableColumnMenu: true,
    },
    {
      field: 'vendorId',
      headerName: 'Vendor ID',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issuanceMonth',
      headerName: 'Issuance Month',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issncDt',
      headerName: 'Issuance Date',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issuanceAmount',
      headerName: 'Issuance Amount',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issuanceStatusCd',
      headerName: 'Issuance Status',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issuanceStatusDt',
      headerName: 'Status Date',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="suppress"
            onClick={() => {
              setIsOpenViewDialog(true);
              fetchViewDetailsData(params.row);
            }}
            color="primary"
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            aria-label="suppress"
            onClick={() => {
              setIsOpenHistoryDialog(true);
              fetchHistoryData(params.row);
            }}
            color="primary"
          >
            <HistoryIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const onClose = () => {
    setIsOpenHistoryDialog(false);
    setIsOpenViewDialog(false);
  };

  const fetchSearchData = async (id) => {
    const payload = {
      caseId: id,
      caseBIDailyBenefitsId: null,
      individualId: null,
      ssn: {
        dirtyStatus: '',
        soSecPart1: '',
        soSecPart2: '',
        soSecPart3: '',
        ssnVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      issuanceMethodCd: null,
      issuanceStatusCd: null,
      issuanceBeginMonth: null,
      issuanceEndMonth: null,
      checkNumber: null,
      programCd: null,
      programSubType: null,
      vendorId: null,
      bnftIssncNum: null,
    };

    try {
      showLoader();
      const res = await searchIssuance(payload);
      if (res.status === 200) {
        setSearchData(res.data.caseBIBaseModelList);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const fetchHistoryData = async (value) => {
    const paramsValue = {
      program: value.programShort,
      month: value.issuMonth,
      number: value.bnftIssncNum,
    };

    try {
      showLoader();
      const res = await historyIssuance(paramsValue);
      if (res.status === 200) {
        setHistoryData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const fetchViewDetailsData = async (value) => {
    const paramsValue = {
      program: value.programShort,
      id: value.biId,
    };

    try {
      showLoader();
      const res = await viewIssuance(paramsValue);
      if (res.status === 200) {
        setViewIssuanceData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (caseId) {
      fetchSearchData(caseId);
    }
  }, []);

  const rows = useMemo(
    () =>
      map(searchData, (item, index) => {
        const programCd = get(item, 'programCd', '');
        const issuanceStatusCd = get(item, 'issuanceStatusCd', '');
        return {
          ...item,
          id: index + 1,
          vendorId: item.vendorId ? item.vendorId : 'N/A',
          programShort: programCd,
          programCd: lkpFlatten?.RequestedPrograms?.[programCd] || '',
          issuMonth: item.issuanceMonth,
          issuanceMonth: dayjs(get(item, 'issuanceMonth', '')).format(
            'MM-YYYY'
          ),
          issuanceStatusCd: lkpFlatten?.Issuancstatus?.[issuanceStatusCd] || '',
        };
      }),
    [searchData, lkpFlatten]
  );

  return (
    <>
      <Box backgroundColor="#d9d9d9" padding="1rem" borderRadius="5px">
        <div className={styles.container}>
          {!caseHome && (
            <>
              <Typography m="0.5rem" variant="h2">
                Issuance
              </Typography>
              <Paper elevation={3} className={styles.searchContentView}>
                <div className={styles.searchContent}>
                  <SearchMode
                    setSearchData={setSearchData}
                    getOptions={getOptions}
                  />
                </div>
              </Paper>
            </>
          )}
          <Paper elevation={3} className={styles.resultContentView}>
            <div className={styles.resultContent}>
              <SearchResult rows={rows} columns={columns} />
            </div>
          </Paper>
        </div>
      </Box>
      {isOpenHistoryDialog && (
        <HistoryDialog
          setOpen={setIsOpenHistoryDialog}
          open={isOpenHistoryDialog}
          onClose={onClose}
          getOptions={getOptions}
          caseId={caseId}
          historyData={historyData}
          lkpFlatten={lkpFlatten}
        />
      )}
      {isOpenViewDialog && (
        <ViewDetailsDialog
          setOpen={setIsOpenViewDialog}
          open={isOpenViewDialog}
          onClose={onClose}
          caseId={caseId}
          viewIssuanceData={viewIssuanceData}
          lkpFlatten={lkpFlatten}
        />
      )}
    </>
  );
}

export default Issuance;
