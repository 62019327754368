export const addVendorPayload = (data) => {
  const {
    beginDate,
    endDate,
    vendor,
    vendorName,
    commonName,
    vendorType,
    vendorProviderId,
    vendorSpeciality,
    fName,
    mName,
    lName,
    type,
    providerId,
    speciality,
    line1,
    line2,
    city,
    state,
    county,
    zipCode,
    zip4Code,
    phoneNumb,
    amount,
    code,
    reasonCode,
  } = data;
  return {
    vendorId: '',
    vendorFirstName: fName,
    vendorMiddleName: mName,
    vendorLastName: lName,
    vendorCommonName: commonName,
    vendorName: vendorName,
    corpYN: null,
    vendorTypeCd: vendorType ?? type,
    mmisVendorId: vendorProviderId ?? providerId,
    mmisVendorSpecialityCd: vendorSpeciality ?? speciality,
    vendorAreacode: null,
    vendorAddressId: null,
    effectiveBeginDt: beginDate,
    effectiveEndDt: endDate ? endDate : null,
    vendorAddressBeginDt: null,
    vendorStreetAddress: line2,
    vendorLine1Address: line1,
    ldssId: '',
    vendorCity: city,
    vendorState: state,
    vendorZipcode: zipCode,
    vendorTelNum: zip4Code,
    vendorRateId: null,
    vendorRateBeginDt: beginDate,
    rateAmount: amount,
    vendorStatusId: null,
    vendorStatusBeginDt: beginDate,
    statusCode: code,
    statusReasonCode: reasonCode,
    vendorStatusEndDt: endDate ? endDate : null,
    type: vendorType,
  };
};

export const searchVendorPayload = (data) => {
  const {
    searchId,
    vendorName,
    type,
    firstName,
    middleName,
    lastName,
    city,
    state,
    zipcode,
    facilityId,
    facilityName,
    facilityType,
    providerId,
    specialities,
  } = data;
  return {
    search: {
      vendorId: searchId ? searchId : facilityId ?? null,
      vendorName: vendorName ? vendorName : facilityName ?? null,
      vendorCommonName: null,
      vendorTypeCd: type ? type : facilityType ?? null,
      // facilityType :
      mmisVendorId: providerId ?? null,
      mmisVendorSpecialityCd: specialities ?? null,
      vendorAreacode: null,
      vendorStreetAddress: null,
      vendorLine1Address: null,
      vendorCity: city ?? null,
      vendorState: state ?? null,
      vendorZipcode: zipcode ?? null,
      vendorTelNum: null,
      vendorFirstName: firstName ?? null,
      vendorMiddleName: middleName ?? null,
      vendorLastName: lastName ?? null,
      settingsFlag: true,
      caseId: '',
    },
    results: [],
    paginationData: {
      resultsPerPage: 5,
      pageNumber: 0,
    },
    range: null,
    systemCd: 'W',
  };
};
