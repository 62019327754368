const { useState } = require('react');
const dayjs = require('dayjs');

function useCalendar() {
  const [startDt, setStartDt] = useState(dayjs().subtract(3, 'd'));
  const [selectedDt, setSelectedDt] = useState(dayjs().startOf('d'));

  const onNextDay = () => {
    setStartDt(dayjs(startDt).add(1, 'd'));
  };

  const onPrevDay = () => {
    setStartDt(dayjs(startDt).subtract(1, 'd'));
  };

  const onNextWeek = () => {
    setStartDt(dayjs(startDt).add(1, 'w').startOf('w'));
  };

  const onPrevWeek = () => {
    setStartDt(dayjs(startDt).subtract(1, 'w').startOf('w'));
  };

  const onNextMonth = () => {
    setStartDt(dayjs(startDt).add(1, 'M').startOf('M'));
  };

  const onPrevMonth = () => {
    setStartDt(dayjs(startDt).subtract(1, 'M').startOf('M'));
  };

  const onSelectDate = (date) => {
    setSelectedDt(dayjs(date).startOf('d'));
  };

  const getDays = () => {
    return Array.from({ length: 7 }, (_item, index) => {
      const date = dayjs(startDt).add(index, 'd').startOf('d');
      const isSelected = dayjs(selectedDt).isSame(date);
      const day = dayjs(date).day();
      return {
        date,
        day: date.date(),
        month: date.format('MMM'),
        weekDay: date.format('ddd'),
        isSelected,
        disabled: day === 0 || day === 6,
      };
    });
  };

  return {
    onNextWeek,
    onPrevWeek,
    onNextMonth,
    onPrevMonth,
    onSelectDate,
    onNextDay,
    onPrevDay,
    days: getDays(),
  };
}

export default useCalendar;
