import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  OutlinedInput,
} from '@mui/material';
import Field from '../../../components/Field';
import CalenderSchedule from './CalenderModule';
import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../components/Dropdown';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import {
  postScheduleAppointment,
  postSlot,
} from '../../../utils/services/apiRequests';
import { find, includes, map } from 'lodash';
import TabularView from '../../../components/TabularView';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { useSelector } from 'react-redux';
import { selectCaseDetails } from '../../../store/selectors/caseSelector';
import EmailIcon from '@mui/icons-material/Email';
import { maxLength } from '../../../utils/normalizers';
const localizer = momentLocalizer(moment);

const Toolbar = ({ label, onView, onNavigate, localizer }) => (
  <Stack direction="row" justifyContent="space-between">
    <Button
      variant="text"
      size="large"
      color="inherit"
      //={<NavigateBeforeIcon />}
      onClick={() => onNavigate('PREV')}
    >
      Prev
    </Button>
    <span
      className="rbc-toolbar-label"
      style={{ fontWeight: 600, fontSize: '1.3rem' }}
    >
      {label}
    </span>
    <Button
      variant="text"
      color="inherit"
      size="large"
      //endIcon={<NavigateNextIcon />}
      onClick={() => onNavigate('NEXT')}
    >
      Next
    </Button>
  </Stack>
);
export default function ScheduleAppoinment({
  interviewTypes,
  toggleAvailability,
  getOptions,
  ldssDistrict,
  caseID,
  getScheduleApi,
  scheduleDetails,
}) {
  const [startTime, SetStartTime] = useState('');
  const [selectedDate, setSelectedDate] = useState(moment());
  const [endTime, setEndTime] = useState('');
  const [showLoader, hideLoader] = useLoader();
  const [event, setEvent] = useState([]);
  const [availableSlot, setAvailableSlot] = useState([]);
  const params = useParams();
  const casedetails = useSelector(selectCaseDetails);

  useEffect(() => {
    if (scheduleDetails) {
      // availabilitySlot();
    }
  }, []);

  const appDate = casedetails?.programs?.[0]?.applicationDate;
  const checkProgram = (data) => {
    const prmgs = find(casedetails.programs, {
      programName: data.toUpperCase(),
    });
    if (prmgs) {
      return 'S';
    } else {
      return '';
    }
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string().when('interviewType', {
      is: (interviewType) => interviewType === 'P',
      then: () => Yup.string().required('Please enter phone number'),
      otherwise: () => Yup.string().notRequired(),
    }),
    interviewType: Yup.string().required('Please select interview type'),
    interviewStatus: Yup.string().required('Please select interview status'),
    startTime: Yup.string().when('interviewType', {
      is: (interviewType) => interviewType !== 'MI',
      then: () => Yup.string().required('Please enter phone number'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const dayPropGetter = (date) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return {
        style: {
          backgroundColor: 'lightgrey',
          pointerEvents: 'none',
        },
      };
    }

    return {};
  };

  const formik = useFormik({
    initialValues: {
      interviewDate: scheduleDetails?.interviewDate ?? null,
      startTime: scheduleDetails?.startTime ?? '',
      endTime: scheduleDetails?.endTime ?? '',
      interviewType: scheduleDetails?.interviewType ?? '',
      phoneNumber: scheduleDetails?.phoneNumber ?? '',
      caseMode: 'In Take',
      interviewStatus: scheduleDetails?.interviewStatus ?? '',
      ldssDoCd: ldssDistrict ?? '',
      fsInd: checkProgram('FS'),
      chInd: checkProgram('CH'),
      maInd: checkProgram('MA'),
      eaInd: checkProgram('EA'),
      wvInd: checkProgram('WV'),
      ltInd: checkProgram('LT'),
    },
    validationSchema,
    onSubmit: (value) => {
      ScheduleAPI(value);
      // let startDate = `${value.interviewDate} ${dayjs(startTime).format('LT')}`;
      // let endDate = `${value.interviewDate} ${dayjs(endTime).format('LT')}`;
      // console.log(endTime);
      // const dataValue = {
      //   event_id: Math.random(),
      //   start: new Date(startDate),
      //   end: new Date(endDate),
      //   title: params?.caseId,
      // };
      // setEvent((prev) => [...prev, dataValue]);
    },
  });

  const ScheduleAPI = async (value) => {
    try {
      showLoader();
      const res = await postScheduleAppointment(value, caseID);
      if (res.status === 200) {
        setAvailableSlot(res.data);
        getScheduleApi();
        toggleAvailability();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const appointments = [
    { date: '2024-06-03', slots: 'all' },
    { date: '2024-06-06', slots: 'half' },
    { date: '2024-06-12', slots: 'all' },
    { date: '2024-06-14', slots: 'all' },
    { date: '2024-06-18', slots: 'half' },
    { date: '2024-06-20', slots: 'half' },
    { date: '2024-06-24', slots: 'half' },
    { date: '2024-06-26', slots: 'all' },
    { date: '2024-06-27', slots: 'all' },
  ];

  const getSlotColor = (date) => {
    const appointment = appointments.find((appt) =>
      moment(appt.date).isSame(date, 'day')
    );
    if (appointment) {
      if (appointment.slots === 'all') return '#FBCFCC';
      if (appointment.slots === 'half') return '#FCEDB7';
    }
    return '#E6FBD8';
  };
  const { statusOpts } = useMemo(() => {
    return {
      statusOpts: getOptions('InterviewStatus'),
    };
  }, [getOptions]);

  const enValue = useMemo(() => {
    setEndTime(dayjs(startTime).add(30, 'm'));
  }, [startTime]);
  const dateCellWrapper = ({ value }) => {
    const today = moment();
    const currentMonth = today.month();
    const currentYear = today.year();
    const dateMonth = moment(value).month();
    const dateYear = moment(value).year();
    const color = getSlotColor(value);
    const isActive = selectedDate && moment(selectedDate).isSame(value, 'day');
    const isCurrentMonth =
      dateMonth === currentMonth && dateYear === currentYear;
    const isWeekend = value.getDay() === 0 || value.getDay() === 6;

    return (
      <div
        // onClick={() => !isWeekend && handleDateClick(value)}
        style={{
          backgroundColor: isWeekend ? '#E2EFFC' : color,
          border: isActive ? '2px solid #0C66E4' : '0.1px solid #C2C2B7',
          height: '100%',
          width: '100%',
          cursor: !isWeekend ? 'pointer' : 'default',
          boxSizing: 'border-box',
          borderCollapse: 'collapse',
        }}
      ></div>
    );
  };

  const interviewTypeOpts = [
    {
      label: 'Phone',
      value: 'P',
    },
    {
      label: 'In Person',
      value: 'IP',
    },
    {
      label: 'Mail In',
      value: 'MI',
    },
  ];

  const availabilitySlot = async (data) => {
    formik.setFieldValue(
      'interviewDate',
      dayjs(data).format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD')
    );
    const payload = {
      scheduleDate: dayjs(data).format('YYYY-MM-DD'),
      ldssDoCd: ldssDistrict,
    };

    formik.setFieldValue('startTime', '');
    formik.setFieldValue('endTime', '');
    try {
      showLoader();
      const res = await postSlot(payload, params.caseId);
      if (res.status === 200) {
        setAvailableSlot(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const selectSlot = (data) => {
    formik.setFieldValue('startTime', data.startTime);
    formik.setFieldValue('endTime', data.endTime);
  };

  const columns = useMemo(
    () => [
      {
        headerName: 'Start Time',
        getValue({ startTime }) {
          return `${startTime ?? '-'}`;
        },
      },
      {
        headerName: 'End Time',
        getValue({ endTime }) {
          return `${endTime ?? '-'}`;
        },
      },
      {
        field: '',
        headerName: 'Slot Select',
        renderCell: (params) => (
          <Button
            onClick={() => selectSlot(params)}
            startIcon={<TaskAltRoundedIcon />}
            variant="contained"
          >
            Select
          </Button>
        ),
      },
    ],
    [availableSlot]
  );

  const minDate = appDate ? dayjs(appDate).subtract(1, 'week') : null;

  return (
    <Box>
      <Grid container direction={'row'} padding={2} spacing={2}>
        <Grid item xs={6}>
          <Grid mt={2}>
            <Field
              name="interviewDate"
              isDate
              formik={formik}
              label="Interview Date"
              required
              customHandler
            >
              <DatePicker
                disablePast
                minDate={minDate}
                value={dayjs(formik.values.interviewDate)}
                onChange={(val) => availabilitySlot(val)}
              />
            </Field>
          </Grid>
          <Grid mt={2}>
            <Field
              required
              name="interviewType"
              formik={formik}
              label="Interview Type"
            >
              <Dropdown options={interviewTypeOpts} />
            </Field>
          </Grid>
          <Grid mt={2}>
            <Field
              name="phoneNumber"
              formik={formik}
              label="Phone Number"
              required={formik.values.interviewType === 'P'}
            >
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.number}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>

          <Grid mt={2}>
            <Field name="caseMode" formik={formik} label="Case Mode">
              <TextField disabled />
            </Field>
          </Grid>

          <Grid mt={2}>
            <Field
              name="interviewStatus"
              formik={formik}
              label="Interview Status"
              required
            >
              <Dropdown
                options={statusOpts}
                placeholder="Select Interview Status"
              />
            </Field>
          </Grid>
          {formik.errors.startTime && (
            <Typography mt={2} color="red">
              Note : Please Select slot
            </Typography>
          )}
          <Stack direction="row" justifyContent="flex-end" mt={2} spacing={2}>
            <Button variant="outlined" onClick={toggleAvailability}>
              Discard
            </Button>
            <Button
              onClick={formik.handleSubmit}
              // disabled={!startTime}
              variant="contained"
            >
              Schedule
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Calendar
            localizer={localizer}
            events={[]}
            startAccessor="start"
            endAccessor="end"
            views={['month']}
            defaultView="month"
            style={{ height: 400, width: '100%' }}
            dayPropGetter={dayPropGetter}
            components={{
              dateCellWrapper,
              toolbar: Toolbar,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
