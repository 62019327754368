import React, { useState, useMemo } from 'react';
import {
  FormControlLabel,
  Box,
  Grid,
  Typography,
  Checkbox,
  TextField,
  Stack,
  OutlinedInput,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Field from '../../../components/Field';
import * as Yup from 'yup';
import Radio from '@mui/material/Radio';
import useLookup from '../../../hooks/useLookup';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import FormSection from '../../../components/FormSection';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as HomeMed } from '../../../assets/images/home.svg';
import { ReactComponent as Pill } from '../../../assets/images/pill.svg';
import { ReactComponent as Stethoscope } from '../../../assets/images/stethoscope.svg';
import { ReactComponent as Combine } from '../../../assets/images/combine.svg';
import Dropdown from '../../../components/Dropdown';
import usePageNav from '../../../hooks/usePageNav';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import InputMask from 'react-input-mask';
import {
  editMed,
  medicareAdd,
} from '../../../utils/services/MedicareDetails/apiRequests';

const labelStyle = {
  fontSize: '16px',
  fontWeight: '700',
};

function Medicare({
  selected,
  individualId,
  toggleMedicare,
  getInsurancePolicy,
  medicareEdit,
  createMedicare,
}) {
  const { getOptions } = useLookup('MediCare');
  const { individualId: currentUserId } = selected;
  const { caseId, navigations } = usePageNav();
  const { medicareOpts } = useMemo(() => {
    return {
      medicareOpts: getOptions('MedicareVerf'),
    };
  }, [getOptions]);
  const validationSchema = Yup.object({
    medicareDate: Yup.string().required('Please Select Date'),
    claimid: Yup.string().when(
      ['partatype', 'partbtype', 'partctype', 'partdtype'],
      {
        is: (partatype, partbtype, partctype, partdtype) => {
          return (
            [partatype, partbtype, partctype, partdtype], (type) => type === 'R'
          );
        },
        then: () =>
          Yup.string()
            .required(
              'Please Enter Claim id validation',
              'The MBI’s 2nd, 5th, 8th, and 9th characters are always letters,Characters 1, 4, 7, 10, and 11 are always numbers,except for S, L, O, I, B, and Z'
            )
            .matches(/[^SLOIBZ]/),
        otherwise: () => Yup.string().notRequired(),
      }
    ),
    verificationclaim: Yup.string().when(
      ['partatype', 'partbtype', 'partctype', 'partdtype'],
      {
        is: (partatype, partbtype, partctype, partdtype) => {
          return (
            [partatype, partbtype, partctype, partdtype], (type) => type === 'R'
          );
        },
        then: () => Yup.string().required('Please Select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }
    ),
    applicationid: Yup.string().when(
      ['partatype', 'partbtype', 'partctype', 'partdtype'],
      {
        is: (partatype, partbtype, partctype, partdtype) => {
          return (
            [partatype, partbtype, partctype, partdtype], (type) => type === 'A'
          );
        },
        then: () =>
          Yup.string()
            .required('Please Enter Application id validation')
            .matches(/[^SLOIBZ]/),
        otherwise: () => Yup.string().notRequired(),
      }
    ),
    verificationapplication: Yup.string().when(
      ['partatype', 'partbtype', 'partctype', 'partdtype'],
      {
        is: (partatype, partbtype, partctype, partdtype) => {
          return (
            [partatype, partbtype, partctype, partdtype], (type) => type === 'A'
          );
        },
        then: () => Yup.string().required('Please Select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }
    ),
  });
  const onDateChange = (date, prop) => {
    formik.setFieldValue(prop, dayjs(date).format('YYYY-MM-DD'));
  };

  const formik = useFormik({
    initialValues: {
      dirtyStatus: createMedicare ? 'insert' : 'update',
      medicareDate: medicareEdit?.medicareStartDate ?? '',
      parta: medicareEdit?.medicareTypeA ?? '',
      partb: medicareEdit?.medicareTypeB ?? '',
      partc: medicareEdit?.medicareTypeC ?? '',
      partd: medicareEdit?.medicareTypeD ?? ' ',
      partatype: medicareEdit?.medicareTypeAStatus ?? '',
      partbtype: medicareEdit?.medicareTypeBStatus ?? '',
      partctype: medicareEdit?.medicareTypeCStatus ?? '',
      partdtype: medicareEdit?.medicareTypeDStatus ?? '',
      solqi: medicareEdit?.solqYN ?? '',
      claimid: medicareEdit?.claimId ?? '',
      applicationid: medicareEdit?.applicationNum ?? '',
      verificationclaim:
        medicareEdit?.medicareClaimVerification?.verificationSourceCode ?? '',
      verificationapplication:
        medicareEdit?.medicareApplicationVerification?.verificationSourceCode ??
        '',
      recordId: medicareEdit?.recordId ?? 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (createMedicare) {
        postMedicare(values);
      } else {
        editMedicareDetails(values);
      }
    },
  });

  const postMedicare = async (data) => {
    try {
      showLoader();
      const res = await medicareAdd(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('SuccessFully Added Medicare Details');
        toggleMedicare();
        getInsurancePolicy();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const editMedicareDetails = async (data) => {
    try {
      showLoader();
      const res = await editMed(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('SuccesFully Updated Medicare Details');
        toggleMedicare();
        getInsurancePolicy();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormSection label="Medicare Details" mt="1rem">
        <Grid container>
          <Grid item xs={4}>
            <Field
              label="Medicare Start Date"
              isDate
              formik={formik}
              name="medicareDate"
              required
            >
              <DatePicker
                disableFuture
                placeholder="Select Date"
                value={dayjs(formik.values.medicareDate)}
                onChange={(date) => onDateChange(date, 'medicareDate')}
              />
            </Field>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            padding: '20px',
            gap: '20px',
            flexWrap: 'nowrap',
            paddingTop: '30px',
          }}
        >
          <Grid item xs={3} border={2} borderColor={'silver'} borderRadius={2}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <HomeMed />
              <Typography>
                Hospital <br /> Insurance
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                // marginLeft: '70px',
              }}
            >
              <FormControlLabel
                name="parta"
                control={
                  <Checkbox
                    {...formik.getFieldProps('parta')}
                    checked={formik.values.parta === 'Y'}
                    onChange={(e, checked) => {
                      formik.setFieldValue('parta', checked ? 'Y' : 'N');
                    }}
                    value={formik.values.parta}
                  />
                }
                label={<Typography style={labelStyle}>Part A</Typography>}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                // marginLeft: '70px',
              }}
            >
              {formik.values.parta === 'Y' && (
                <RadioGroup
                  name="partatype"
                  row
                  {...formik.getFieldProps('partatype')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.partatype}
                >
                  <FormControlLabel
                    value={'R'}
                    control={<Radio />}
                    label="Receiving"
                  />
                  <FormControlLabel
                    value={'A'}
                    control={<Radio />}
                    label="Applied"
                  />
                </RadioGroup>
              )}
            </div>
          </Grid>
          <Grid item xs={3} border={2} borderColor={'silver'} borderRadius={2}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Stethoscope />
              <Typography>
                Medical <br /> Insurance
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                name="partb"
                control={
                  <Checkbox
                    {...formik.getFieldProps('partb')}
                    checked={formik.values.partb === 'Y'}
                    onChange={(e, checked) => {
                      formik.setFieldValue('partb', checked ? 'Y' : 'N');
                    }}
                    value={formik.values.partb}
                  />
                }
                label={<Typography style={labelStyle}>Part B</Typography>}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                // marginLeft: '70px',
              }}
            >
              {formik.values.partb === 'Y' && (
                <RadioGroup
                  name="partbtype"
                  row
                  {...formik.getFieldProps('partbtype')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.partbtype}
                >
                  <FormControlLabel
                    value={'R'}
                    control={<Radio />}
                    label="Receiving"
                  />
                  <FormControlLabel
                    value={'A'}
                    control={<Radio />}
                    label="Applied"
                  />
                </RadioGroup>
              )}
            </div>
          </Grid>
          <Grid item xs={3} border={2} borderColor={'silver'} borderRadius={2}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Combine />
              <Typography>
                Combine <br /> A + B + D
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                name="partc"
                control={
                  <Checkbox
                    {...formik.getFieldProps('partc')}
                    checked={formik.values.partc === 'Y'}
                    onChange={(e, checked) => {
                      formik.setFieldValue('partc', checked ? 'Y' : 'N');
                    }}
                    value={formik.values.partc}
                  />
                }
                label={<Typography style={labelStyle}>Part C</Typography>}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                // marginLeft: '70px',
              }}
            >
              {formik.values.partc === 'Y' && (
                <RadioGroup
                  name="partctype"
                  row
                  {...formik.getFieldProps('partctype')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.partctype}
                >
                  <FormControlLabel
                    value={'R'}
                    control={<Radio />}
                    label="Receiving"
                  />
                  <FormControlLabel
                    value={'A'}
                    control={<Radio />}
                    label="Applied"
                  />
                </RadioGroup>
              )}
            </div>
          </Grid>
          <Grid item xs={3} border={2} borderColor={'silver'} borderRadius={2}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Pill />
              <Typography>
                Prescription <br /> Drugs
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                name="partd"
                control={
                  <Checkbox
                    {...formik.getFieldProps('partd')}
                    checked={formik.values.partd === 'Y'}
                    onChange={(e, checked) => {
                      formik.setFieldValue('partd', checked ? 'Y' : 'N');
                    }}
                    value={formik.values.partd}
                  />
                }
                label={<Typography style={labelStyle}>Part D</Typography>}
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
              }}
            >
              {formik.values.partd === 'Y' && (
                <RadioGroup
                  name="partdtype"
                  row
                  {...formik.getFieldProps('partdtype')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.partdtype}
                >
                  <FormControlLabel
                    value={'R'}
                    control={<Radio />}
                    label="Receiving"
                  />
                  <FormControlLabel
                    value={'A'}
                    control={<Radio />}
                    label="Applied"
                  />
                </RadioGroup>
              )}
            </div>
          </Grid>
        </Grid>

        {formik.values.parta === 'Y' ||
        formik.values.partb === 'Y' ||
        formik.values.partc === 'Y' ||
        formik.values.partd === 'Y' ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                label="Claim Id"
                formik={formik}
                name="claimid"
                required={formik.errors.claimid}
              >
                <InputMask mask="9aa9-aa9-aa99" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="Enter Claim Id"
                      value={formik.values.claimid}
                      {...params}
                    />
                  )}
                </InputMask>
                {/* <TextField
                  placeholder="Enter Claim Id"
                  value={formik.values.claimid}
                /> */}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Verification"
                name="verificationclaim"
                formik={formik}
                required={formik.errors.verificationclaim}
              >
                <Dropdown
                  options={medicareOpts}
                  placeholder="Select Verification"
                  value={formik.values.verificationclaim}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Application Id"
                formik={formik}
                name="applicationid"
                required={formik.errors.applicationid}
              >
                <InputMask mask="9aa9-aa9-aa99" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="Enter Application Id"
                      value={formik.values.applicationid}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Verification"
                name="verificationapplication"
                formik={formik}
                required={formik.errors.verificationapplication}
              >
                <Dropdown
                  options={medicareOpts}
                  placeholder="Select Verification"
                  // value={formik.values.verificationapplication}
                />
              </Field>
            </Grid>
          </Grid>
        ) : (
          <>
            <FormControlLabel
              name="solqi"
              control={
                <Checkbox
                  {...formik.getFieldProps('solqi')}
                  checked={formik.values.solqi === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('solqi', checked ? 'Y' : 'N');
                  }}
                  value={formik.values.solqi}
                />
              }
              label={
                <Typography>
                  Verify SOLQI interface Medicare information from SSA.If data
                  is unavailable,mark the box to defer MA
                </Typography>
              }
            />
          </>
        )}
      </FormSection>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleMedicare}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default Medicare;
