import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import useMount from '../../hooks/useMount';
import { useDispatch } from 'react-redux';
import { FETCH_NAV_OPTIONS } from '../../store/types/navTypes';

export default function CaseInquiry() {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch({ type: FETCH_NAV_OPTIONS, payload: { caseId: '', pageType: 'I' } });
  });

  return (
    <Box sx={{ p: '1rem' }}>
      <Outlet />
    </Box>
  );
}
