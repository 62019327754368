import React, { useMemo, useState } from 'react';
import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import { useSelector } from 'react-redux';
import {
  selectCaseDetails,
  selectHouseMembers,
} from '../../store/selectors/caseSelector';
import HealthDisability from './HealthDisability';
import usePageNav from '../../hooks/usePageNav';
import { map } from 'lodash';
import usePage from '../../hooks/usePage';

function MedicareDetails({ id }) {
  const { caseId, navigations } = usePageNav();
  const { navProps } = usePage(id);
  const caseDetails = useSelector(selectCaseDetails);
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { houseHoldMembers, hasHoh } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  }, [houseMembers]);

  const programs = map(
    caseDetails?.programs ?? [],
    (value) => value.programName
  );

  const onNext = () => {
    navigations.toPregnancyInfo();
  };

  const onBack = () => {
    if (programs.includes('LT' || 'WV')) {
      navigations.toWaiverAndFacility();
    } else {
      navigations.toIncome();
    }
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Medicare Details"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={houseHoldMembers}
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <HealthDisability />
      </SelectableView>
    </PageContainer>
  );
}

export default MedicareDetails;
