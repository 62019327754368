import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import styled from '@emotion/styled';
import { DatePicker } from '@mui/x-date-pickers';
import TabularView from '../../../components/TabularView';
import usePage from '../../../hooks/usePage';

const StyledTitle = styled(Typography)({
  color: 'var(--grey-400)',
  marginBottom: '0.5rem',
});

function EligibilityDetails({ id }) {
  const { navProps } = usePage(id);
  const columns = useMemo(
    () => [
      {
        field: '',
        headerName: 'Program Name',
      },
      {
        field: '',
        headerName: 'AG Head',
      },
      {
        field: '',
        headerName: 'Benefits Coverage',
      },
      {
        field: '',
        headerName: 'Eligible Month',
      },
      {
        field: '',
        headerName: 'Eligibility Status',
      },
      {
        field: '',
        headerName: 'Actions',
      },
    ],
    []
  );

  return (
    <div>
      <PageHeader
        navProps={navProps}
        title="Eligibility Details"
        showNavigations={false}
      />
      <Box
        component={Paper}
        sx={{ bgcolor: 'var(--grey-100)', p: '0.5rem 1rem', borderRadius: 0 }}
      >
        <StyledTitle variant="h4">Eligibility Calculation Month</StyledTitle>
        <Grid
          container
          component={Paper}
          sx={{ bgcolor: 'white', p: '1rem 1rem' }}
        >
          <Grid item xs={3}>
            <Stack direction="row" sx={{ alignItems: 'baseline' }}>
              <Typography mr="0.25rem">From</Typography>
              <DatePicker />
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" sx={{ alignItems: 'baseline' }}>
              <Typography ml="1rem" mr="0.25rem">
                To
              </Typography>
              <DatePicker />
            </Stack>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            item
            xs={6}
          >
            <Button variant="contained">Run Eligibility</Button>
          </Grid>
        </Grid>
        <StyledTitle mt="1rem" variant="h4">
          Eligibility Summary
        </StyledTitle>
        <TabularView
          headerComponent={{ variant: 'h5' }}
          component={Paper}
          columns={columns}
        />
        {/* <StyledTitle mt="1rem" variant="h4">
          Generate Verification Checklist
        </StyledTitle> */}
      </Box>
    </div>
  );
}

EligibilityDetails.propTypes = {};

export default EligibilityDetails;
