import React, { useEffect, useState } from 'react';
import { last } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Button,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import { ErrorMessage } from 'formik';
import { Grid } from '@mui/material';
import useLoader from '../../../../hooks/useLoader';
import DeleteConfiramation from '../';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { saveMedicalExpenses } from '../../../../utils/services/medicalExpenses.js/apiRequests';
import * as yup from 'yup';
import { useFormik, FieldArray, getIn } from 'formik';
import Field from '../../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../../components/Dropdown';
import dayjs from 'dayjs';
import usePageNav from '../../../../hooks/usePageNav';
import {
  formatCurrency,
  maxLength,
  onlyAlphabet,
} from '../../../../utils/normalizers';

const AddMember = ({
  open,
  selectedIndividual,
  setReportEdited,
  reportEdited,
  individualId,
  getOptions,
  setOpen,
  lkpRaw,
}) => {
  const { caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();

  const handleSubmits = async (values) => {
    showLoader();
    try {
      let allValue = values;
      if (
        (values.frequency === 'BW' && values.frequencyAmountAry.length === 3) ||
        (values.frequency === 'WE' && values.frequencyAmountAry.length === 5)
      ) {
        if (['BW', 'WE'].includes(values.frequency)) {
          let data;
          data = last(values.frequencyAmountAry);

          if (data?.amount === '') {
            allValue.frequencyAmountAry.pop();
          }
        }
      }

      const statusRes = await saveMedicalExpenses(
        allValue,
        individualId,
        caseId,
        selectedIndividual.recordId,
        selectedIndividual.medicalExpenseVerification?.providedVerificationId ??
          ''
      );

      if (statusRes.status === 200) {
        setOpen(false);
        setReportEdited(!reportEdited);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const validateSchema = yup.object({
    providerName: yup.string().required('Plaese Enter Medical Provider Name'),
    expenseDetails: yup.string().required('Please Enter Expense Details '),
    effectiveBDate: yup
      .string()
      .required('Please select Effective Begin Date '),
    effectiveEDate: yup.string().required('Please select Effective End Date '),
    serviceProvided: yup.string().required('Please Select Service Provided'),
    frequencyAmountAry: yup.array().of(
      yup.object().shape({
        amount: yup.string().required('Please Enter Amount'),
        verificationSourceCode: yup
          .string()
          .required('Please Enter Verification Source Code'),
      })
    ),
    frequency: yup.string().required('Please select Frequency'),
  });

  const medicalProvider = getOptions('ServiceProvided');
  const expenseType = getOptions('MedicalExpenseType');
  const frequency = getOptions('Frequency');
  const CLMEDEXPNSEAMTVERCD = getOptions('CLMEDEXPNSEAMTVERCD');

  const formik = useFormik({
    initialValues: {
      dirtyStatus: selectedIndividual?.providerName ? 'update' : 'insert',
      providerName: selectedIndividual?.providerName ?? '',
      expenseDetails: selectedIndividual?.medicalExpenseType ?? '',
      effectiveBDate: selectedIndividual?.effectiveBeginDt ?? null,
      effectiveEDate: selectedIndividual?.effectiveEndDt ?? null,
      serviceProvided: selectedIndividual?.serviceProvided ?? '',
      frequency: selectedIndividual?.retroMonths?.[0]?.frequency ?? '',
      frequencyAmountAry:
        selectedIndividual?.retroMonths?.[0]?.amountModel ?? [],
    },
    validationSchema: validateSchema,
    validateOnChange: true,
    onSubmit: handleSubmits,
  });

  const frequencyLoop = {
    AC: 1,
    AN: 1,
    BM: 1,
    BW: 3,
    MO: 1,
    OT: 1,
    QU: 1,
    SA: 1,
    WE: 5,
  };

  const medicalAmount = (event) => {
    const { value, name } = event.target;
    const loopCount = frequencyLoop[value];
    formik.setFieldValue('frequency', value);

    const fields = Array.from({ length: loopCount }, (_, index) => ({
      recordId: 0,
      amount: '',
      sortOrder: index + 1,
      verificationSourceCode: '',
    }));

    formik.setFieldValue(`frequencyAmountAry`, fields);
  };

  const [frequencyOptions, setFrequencyOptions] = useState(lkpRaw?.Frequency);

  const forNoMedicalExpenses = () => {
    const lists = lkpRaw?.Frequency.find((obj) => {
      return obj.lookupLongVal === 'One Time Only';
    });

    return lists;
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <Typography variant="h2" style={{ padding: '1rem 1rem 0rem 1.8rem' }}>
        {selectedIndividual?.providerName ?? 'Add Medical Expenses'}
      </Typography>

      <DialogContent>
        <Box style={{ padding: '1rem', border: '1px solid #e2e2e2' }}>
          <Box style={{ display: 'flex', gap: '20px', margin: '1.5rem 0' }}>
            <Field
              label="Medical Provider Name"
              name="providerName"
              formik={formik}
              required
              normalizers={[maxLength(30), onlyAlphabet]}
            >
              <TextField
                name="providerName"
                placeholder="Enter Medical Provider Name"
              ></TextField>
            </Field>
            <Field
              error={formik.errors?.expenseDetails}
              showError={formik.errors?.expenseDetails}
              label="Expense Details"
              name="expenseDetails"
              formik={formik}
              required
            >
              <Dropdown
                options={expenseType}
                placeholder="Select Expense Details"
              />
            </Field>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ mb: '1.5rem' }}>
              <Field
                required
                name="effectiveBDate"
                label="Effective Begin Date"
                formikHandler={false}
                isDate
                formik={formik}
              >
                <DatePicker required value={formik.values.effectiveBDate} />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="effectiveEDate"
                required
                label="Effective End Date"
                error={formik?.errors?.effectiveEDate}
                showError={formik?.errors?.effectiveEDate}
                isDate
                formik={formik}
                formikHandler={false}
              >
                <DatePicker value={formik.values.effectiveEDate} />
              </Field>
            </Grid>
          </Grid>

          <Box style={{ display: 'flex', gap: '20px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('snapConsider')}
                  checked={formik.values.snapConsider === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('snapConsider', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Not Consider for SNAP Program"
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('waiver')}
                  checked={formik.values.waiver === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('waiver', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Not Consider for Waiver Program"
            />
          </Box>
          <Box style={{ display: 'flex', gap: '20px', margin: '1.5rem 0' }}>
            <Field
              formik={formik}
              name="serviceProvided"
              required
              label="Service Provided"
            >
              <Dropdown
                options={medicalProvider}
                placeholder="Select Service Provided"
              />
            </Field>

            <Field
              formik={formik}
              label="Frequency"
              errorText={
                formik?.touched?.frequency && formik?.errors?.frequency
              }
              required
              customHandler
              error={formik?.touched?.frequency && formik?.errors?.frequency}
              showError={
                formik?.touched?.frequency ? formik?.errors?.frequency : ''
              }
            >
              <>
                <Select
                  labelId="verification"
                  id="select-verification"
                  onChange={medicalAmount}
                  value={formik.values.frequency}
                  name="frequency"
                >
                  {formik.values.expenseDetails === 'NO'
                    ? lkpRaw?.Frequency?.filter(
                        (obj) => obj.lookupLongVal === 'One Time Only'
                      ).map((item, id) => {
                        return (
                          <MenuItem key={id} value={item.lkpDesc}>
                            {item?.lookupLongVal}
                          </MenuItem>
                        );
                      })
                    : lkpRaw?.Frequency?.map((item, id) => {
                        return (
                          <MenuItem key={id} value={item.lkpDesc}>
                            {item?.lookupLongVal}
                          </MenuItem>
                        );
                      })}
                </Select>
              </>
            </Field>
          </Box>

          {formik.values.frequencyAmountAry?.map((obj, key) => {
            return (
              <Box
                key={key}
                style={{ display: 'flex', gap: '20px', margin: '1rem 0' }}
              >
                <Field
                  name={`frequencyAmountAry.${key}.amount`}
                  error={
                    formik?.touched?.frequencyAmountAry?.[key]?.amount &&
                    formik.errors?.frequencyAmountAry?.[key]?.amount
                  }
                  showError={
                    formik?.touched?.frequencyAmountAry?.[key]?.amount &&
                    formik.errors?.frequencyAmountAry?.[key]?.amount
                  }
                  formik={formik}
                  normalizers={[formatCurrency]}
                  errorText={
                    formik?.touched?.frequencyAmountAry?.[key]?.amount
                      ? formik.errors?.frequencyAmountAry?.[key]?.amount
                      : ''
                  }
                >
                  <TextField
                    InputProps={{
                      // onInput: (event) => {
                      //   event.target.value = event.target.value.replace(
                      //     /[^0-9]/g,
                      //     ''
                      //   );
                      // },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    // inputProps={{ maxLength: 11 }}
                    placeholder="Enter Amount"
                  />
                </Field>
                <Field
                  name={`frequencyAmountAry.${key}.verificationSourceCode`}
                  formik={formik}
                  error={
                    formik?.touched?.frequencyAmountAry?.[key]
                      ?.verificationSourceCode &&
                    formik.errors?.frequencyAmountAry?.[key]
                      ?.verificationSourceCode
                  }
                  showError={
                    formik?.touched?.frequencyAmountAry?.[key]
                      ?.verificationSourceCode &&
                    formik.errors?.frequencyAmountAry?.[key]
                      ?.verificationSourceCode
                  }
                  errorText={
                    formik?.touched?.frequencyAmountAry?.[key]
                      ?.verificationSourceCode
                      ? formik.errors?.frequencyAmountAry?.[key]
                          ?.verificationSourceCode
                      : ''
                  }
                >
                  <Dropdown
                    options={CLMEDEXPNSEAMTVERCD}
                    name={`frequencyAmountAry.${key}.verificationSourceCode`}
                  ></Dropdown>
                </Field>
              </Box>
            );
          })}
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'flex-end'}
            mt={2}
          >
            <Button variant="outlined" onClick={() => setOpen(false)}>
              Discard
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
              //   disabled={disableFields}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddMember;
