import dayjs from 'dayjs';
import { map } from 'lodash';

export const saveRelationPayload = (data, userId) => {
  const relations = map(
    data,
    ({
      recordId,
      // individualId,
      relationshipCd,
      householdMemberId,
      specifiedCaretakerYN,
      purchasePrepareTogetherYN,
      individualId,
      gender,
    }) => ({
      relationshipCd,
      householdMemberId,
      individualId,
      // effectiveBeginDate: '2023-11-05',
      // effectiveEndDate: null,
      specifiedCaretakerYN: specifiedCaretakerYN ? 'Y' : 'N',
      purchasePrepareTogetherYN: purchasePrepareTogetherYN ? 'Y' : 'N',
      sponserIndYN: 'N',
      recordId,
      gender,
      dirtyStatus: recordId ? 'update' : 'insert',
    })
  );

  return {
    individualId: userId,
    relations,
    earnedIncomes: [],
    unearnedIncomes: [],
    dependentCareExpenses: [],
    unearnedIncomeApplications: [],
    shelterExpenses: [],
    shelterUtilityExpenses: [],
    utilityExpenses: [],
    sourceSystem: 'Enterprise',
    fortyQuartersInd: false,
    createMdmId: true,
    gender: 'F',
  };
};

export const addNewMemberPayload = (
  data = {},
  caseId = 'new',
  programs = [],
  date
) => {
  const {
    firstName = '',
    middleName = '',
    lastName = '',
    suffix = '',
    dob = '',
    ssn = '',
    gender = '',
    race = '',
    marriedYN = '',
    ethnicity = '',
    ssnReferral = '',
    headOfHouse = false,
    birthCity = '', // New member
    birthState = '', // New member
    additionRace = '', // New member
    verSource = '', // New member
    dateOfDeath = null, // New member
    deathVerification = '', // New member
    verificationDoc = '', // New member
    ssnAppDt = '', // New member
    dobDoc = '', // New member
    deathState = '', // New member
    maritalStatus = '',

    dirtyStatus,
  } = data;

  const [soSecPart1, soSecPart2, soSecPart3] = ssn.split('-');
  const selectedPrograms =
    caseId === 'new'
      ? map(programs, ({ programName, programSubType = [] }) => ({
          dirtyStatus: 'insert',
          programId: 0,
          programName,
          programStatusCode: '',
          applicationDate: date ?? null,
          selectedIndicator: 'Y',
          programSubType: programSubType?.length
            ? programSubType.join(',')
            : null,
          finalizeProgramIndicator: '',
          programMode: '',
          msnapInd: '',
          spendDownBeginDt: null,
          rescndDate: null,
          rescndReason: null,
          spendDownEndDt: null,
        }))
      : [];

  return {
    results: [],
    paginationData: {},
    range: null,
    systemCd: '',
    associateCaseId: caseId,
    client: {
      personName: {
        firstName,
        middleName,
        lastName,
        suffix,
      },
      dob,
      maritalStatus: { marriedYN: marriedYN },
      ethnicity,
      gender,
      soSec: {
        dirtyStatus: '',
        soSecPart1,
        soSecPart2,
        soSecPart3,
        ssnVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: verificationDoc,
          verifiedYN: 'N',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      headOfHouseholdYN: headOfHouse ? 'Y' : 'N',
      race: { primaryRace: race },
      ssnReferral,
      pregnantYN: '',
      individualDetails: {
        dirtyStatus: '',
        deathDetails: {
          dirtyStatus,
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: deathVerification,
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          dateOfDeath, //New member
          state: deathState,
          deathCertificateNumber: '',
          deathVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          secondaryVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          recordId: 0,
        },
        voterRegistartionYN: '',
        voterRegistrationStatus: '',
        voterRegistration: {
          dirtyStatus: '',
          voterIdNumber: '',
          electionJudgeFlag: '',
          helpRequiredFlag: '',
          licenceNumber: '',
          voterRegLicenseIdAvailCd: '',
          otherPartyName: '',
        },
        veterans: [],
        maritals: [],
        livingArrangements: [],
        f99Details: [],
        x02Details: [],
        pregnancy: [],
        birthCity, //New member
        birthState, //New member
        hospital: '',
        destituteMigrantYN: '',
        concurrentOutStateBenefits: [],
        tcaCounter: '',
        exemptions: [],
        domesticViolanceYN: '',
        strikerStatus: '',
        penalties: [],
        primaryRace: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          primaryRace: race,
          otherRaces: additionRace ? [additionRace] : [],
        },
        ethnicity: '',
        primaryVerificationSource: verSource,
        incarcerations: [],
        ssnApplicationDate: null,
        differentAddressYN: null,
        individualAddress: {
          dirtyStatus: '',
          addressCareOf: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          county: '',
          district: '',
          zipcode: '',
          zip4code: '',
          addressVerifiedYN: '',
          institutionName: '',
          institutionYN: '',
          homeAddressYN: '',
          addressStartDate: null,
          addressEndDate: null,
          ownedByMemberYN: '',
          localDistrictOfficeYN: '',
        },
        strikers: [],
        primaryPrevention: [],
      },
      dobVerification: {
        dirtyStatus: '',
        messages: [],
        verificationDocumentCd: '',
        verificationRequestedYN: '',
        docNames: {},
        documentDetails: [],
        verificationType: '',
        verificationSourceCode: dobDoc,
        verifiedYN: '',
        dueDate: null,
        automatedVerificationYN: '',
      },
      createMdmId: true,
    },
    address: {
      dirtyStatus: '',
      addressCareOf: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      county: '',
      district: '',
      zipcode: '',
      zip4code: '',
      addressVerifiedYN: '',
      institutionName: '',
      institutionYN: '',
      homeAddressYN: '',
      addressStartDate: null,
      addressEndDate: null,
      ownedByMemberYN: '',
      localDistrictOfficeYN: '',
    },
    sourceSystem: 'W',
    selectedPrograms,
  };
};

export const saveUnearnedIncomePayload = (payload, isAdd, isDelete) => {
  if (isDelete) {
    payload = {
      ...payload,
      dirtyStatus: 'DELETE',
    };
    return payload;
  }
  const {
    effectiveBeginDt,
    effectiveEndDt,
    updatedBy,
    updatedDate,
    exmptCoc,
    ...updatedPayloadWithoutDates
  } = payload;
  const updatedPayload = {
    ...updatedPayloadWithoutDates,
    dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
    unearnedIncomeAmount: map(payload.unearnedIncomeAmount, (item) => ({
      ...item,
      dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
      expenses: Array.isArray(item.expenses)
        ? map(item.expenses, (expense) => ({
            ...expense,
            dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
            amount:
              typeof expense.amount === 'string'
                ? Number(expense.amount.replace(/,/g, ''))
                : Number(expense.amount),
          }))
        : [],
      amountModel: map(item.amountModel, (amount) => ({
        ...amount,
        dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
        amount:
          typeof amount.amount === 'string'
            ? Number(amount.amount.replace(/,/g, ''))
            : Number(amount.amount),
      })),
    })),
  };

  return updatedPayload;

  // if (isDelete) {
  //   payload.dirtyStatus = 'DELETE';
  //   return payload;
  // }

  // payload.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';

  // payload.unearnedIncomeAmount.forEach((item) => {
  //   item.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';

  //   if (Array.isArray(item.expenses)) {
  //     item.expenses.forEach((expense) => {
  //       expense.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';
  //       expense.amount = Number(expense.amount);
  //     });
  //   } else {
  //     item.expenses = [];
  //   }

  //   item.amountModel.forEach((amount) => {
  //     amount.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';
  //     amount.amount = Number(amount.amount);
  //   });
  // });

  // return payload;
};

export const saveEarnedIncomePayload = (payload, isAdd, isDelete) => {
  if (isDelete) {
    return {
      ...payload,
      dirtyStatus: 'DELETE',
    };
  }
  console.log(payload);
  const updatedPayload = {
    ...payload,
    dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
    employer: {
      ...payload.employer,
      dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
      wagesPerHours: Number(payload.employer.wagesPerHours),
    },
    earnedIncomeAmount: map(payload.earnedIncomeAmount, (item) => ({
      ...item,
      dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
      expenses: Array.isArray(item.expenses)
        ? map(item.expenses, (expense) => ({
            ...expense,
            dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
            amount:
              typeof expense.amount === 'string'
                ? Number(expense.amount.replace(/,/g, ''))
                : Number(expense.amount),
          }))
        : [],
      amountModel: map(item.amountModel, (amount) => ({
        ...amount,
        dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
        amount:
          typeof amount.amount === 'string'
            ? Number(amount.amount.replace(/,/g, ''))
            : Number(amount.amount),
      })),
    })),
  };

  return updatedPayload;

  // if (isDelete) {
  //   payload.dirtyStatus = 'DELETE';
  //   return payload;
  // }
  // payload.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';
  // payload.employer.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';
  // payload.employer.wagesPerHours = Number(payload.employer.wagesPerHours);

  // payload.earnedIncomeAmount.forEach((item) => {
  //   item.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';

  //   if (Array.isArray(item.expenses)) {
  //     item.expenses.forEach((expense) => {
  //       expense.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';
  //       expense.amount = Number(expense.amount);
  //     });
  //   } else {
  //     item.expenses = [];
  //   }
  //   item.amountModel.forEach((amount) => {
  //     amount.dirtyStatus = isAdd ? 'INSERT' : 'UPDATE';
  //     amount.amount = Number(amount.amount);
  //   });
  // });

  // return payload;
};
export const addWorkerPayload = (data) => {
  // const {} =data
  return [
    {
      alertId: null,
      caseId: '300008715',
      benefitsCaseId: null,
      alertType: 'AP',
      alertSubType: 'AI',
      alertSource: null,
      alertCreateDt: null,
      alertDueDt: '2024-03-30',
      alertDescription: 'Pending Application',
      consumerControlId: null,
      programCd: null,
      dispType: null,
      dispositionedBy: null,
      dispositionSource: null,
      dispositionDt: null,
      actnType: null,
      assignedTo: '544b80181ed8c8e455e2bbd48de471',
      assignedDt: null,
      individualId: null,
      subjDesc: null,
      deleteInd: null,
    },
  ];
};

export const saveUnEarnedIncomeAppPayload = (
  payload,
  isAdd,
  isDelete,
  userId
) => {
  if (isDelete) {
    return {
      appliedUnearnedIncomeId: payload.appliedUnearnedIncomeId,
      appliedUnearnedIncomeTypeCd: payload.appliedUnearnedIncomeTypeCd,
      subTypeCd: payload.subTypeCd,
      appliedUnearnedIncomeStatusCd: payload.appliedUnearnedIncomeStatusCd,
      verificationCd: {
        verificationSourceCode: payload.verificationCd.verificationSourceCode,
      },
      appliedDt: payload.appliedDt,
      expectedDt: payload.expectedDt,
      dirtyStatus: 'DELETE',
    };
  }

  const updatedPayload = {
    ...payload,
    dirtyStatus: isAdd ? 'INSERT' : 'UPDATE',
    individualId: userId,
  };

  return updatedPayload;
};

export const savePotentialIncomePayload = (payload, isAdd, isDelete) => {
  if (isDelete) {
    return {
      ...payload,
      dirtyStatus: 'delete',
      anticipatedReceiptDt: payload.anticipatedReceiptDt,
      assetType: payload.assetType,
      description: payload.description,
      attorneyName: payload.attorneyName,
      attorneyTelephone: payload.attorneyTelephone,
      recordId: payload.recordId,
    };
  }

  let postPutPayload = {
    ...payload,
    dirtyStatus: isAdd ? 'insert' : 'update',
    anticipatedReceiptDt: payload.anticipatedReceiptDt,
    assetType: payload.assetType,
    description: payload.description,
    attorneyName: payload.attorneyName,
    attorneyTelephone: payload.attorneyTelephone,
    recordId: payload.recordId,
  };
  return postPutPayload;
};

export const editMemberPayload = (caseId, data = {}, individualId) => {
  const {
    firstName = '',
    middleName = '',
    lastName = '',
    suffix = '',
    dob = '',
    ssn = '',
    gender = '',
    race = '',
    ethnicity = '',
    ssnReferral = '',
    headOfHouseholdYN = 'N',
    headOfHouse = false,
    birthCity = '', // New member
    birthState = '', // New member
    additionRace = '', // New member
    verSource = '', // New member
    dateOfDeath = null, // New member
    deathVerification = '', // New member
    verificationDoc = '', // New member
    ssnAppDt = '', // New member
    dobDoc = '', // New member
    deathState = '', // New member
    maritalStatus = '',
    marriedYN = '',
    masterid = '',
    irn = '',
  } = data;
  // const {
  //   soSecPart1 = '',
  //   soSecPart2 = '',
  //   soSecPart3 = '',
  // } = ssn.match(
  //   /^(?<soSecPart1>\d{3}).(?<soSecPart2>\d{2}).(?<soSecPart3>\d{4})$/
  // ).groups;
  // const [soSecPart1, soSecPart2, soSecPart3] = ssn.split(' ');
  const [soSecPart1, soSecPart2, soSecPart3] = ssn.split('-');

  console.log(ssn);

  return {
    results: [],
    paginationData: {},
    range: null,
    systemCd: '',
    associateCaseId: caseId,
    client: {
      irn,
      masterid,
      personName: {
        firstName,
        middleName,
        lastName,
        suffix,
      },
      dob,
      maritalStatus: { marriedYN: marriedYN },
      ethnicity,
      gender,
      soSec: {
        dirtyStatus: 'update',
        soSecPart1,
        soSecPart2,
        soSecPart3,
        ssnVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: 'N',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      headOfHouseholdYN: headOfHouse ? 'Y' : 'N',
      race: { primaryRace: race },
      ssnReferral,
      pregnantYN: '',
      individualDetails: {
        dirtyStatus: 'update',
        deathDetails: {
          dirtyStatus: 'insert',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: deathVerification,
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          dateOfDeath, //New member
          state: deathState,
          deathCertificateNumber: '',
          deathVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          secondaryVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          recordId: 0,
        },
        voterRegistartionYN: '',
        voterRegistrationStatus: '',
        voterRegistration: {
          dirtyStatus: '',
          voterIdNumber: '',
          electionJudgeFlag: '',
          helpRequiredFlag: '',
          licenceNumber: '',
          voterRegLicenseIdAvailCd: '',
          otherPartyName: '',
        },
        veterans: [],
        maritals: [],
        livingArrangements: [],
        f99Details: [],
        x02Details: [],
        pregnancy: [],
        birthCity, //New member
        birthState, //New member
        hospital: '',
        destituteMigrantYN: '',
        concurrentOutStateBenefits: [],
        tcaCounter: '',
        exemptions: [],
        domesticViolanceYN: '',
        strikerStatus: '',
        penalties: [],
        primaryRace: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          primaryRace: race,
          otherRaces: additionRace ? [additionRace] : [],
        },
        ethnicity: '',
        primaryVerificationSource: '',
        incarcerations: [],
        ssnApplicationDate: null,
        differentAddressYN: null,
        individualAddress: {
          dirtyStatus: '',
          addressCareOf: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          county: '',
          district: '',
          zipcode: '',
          zip4code: '',
          addressVerifiedYN: '',
          institutionName: '',
          institutionYN: '',
          homeAddressYN: '',
          addressStartDate: null,
          addressEndDate: null,
          ownedByMemberYN: '',
          localDistrictOfficeYN: '',
        },
        strikers: [],
        primaryPrevention: [],
      },
      dobVerification: {
        dirtyStatus: '',
        messages: [],
        verificationDocumentCd: '',
        verificationRequestedYN: '',
        docNames: {},
        documentDetails: [],
        verificationType: '',
        verificationSourceCode: '',
        verifiedYN: '',
        dueDate: null,
        automatedVerificationYN: '',
      },
      individualId,
      createMdmId: masterid ? false : true,
    },
    address: {
      dirtyStatus: '',
      addressCareOf: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      county: '',
      district: '',
      zipcode: '',
      zip4code: '',
      addressVerifiedYN: '',
      institutionName: '',
      institutionYN: '',
      homeAddressYN: '',
      addressStartDate: null,
      addressEndDate: null,
      ownedByMemberYN: '',
      localDistrictOfficeYN: '',
    },
    sourceSystem: 'W',
  };
};

export const editMember = (data = {}, individualId) => {
  const {
    firstName = '',
    middleName = '',
    lastName = '',
    suffix = '',
    dob = '',
    ssn = '',
    gender = '',
    race = '',
    ethnicity = '',
    ssnReferral = '',
    headOfHouseholdYN = 'N',
    headOfHouse = false,
    birthCity = '', // New member
    birthState = '', // New member
    additionRace = '', // New member
    verSource = '', // New member
    dateOfDeath = null, // New member
    deathVerification = '', // New member
    verificationDoc = '', // New member
    ssnAppDt = '', // New member
    dobDoc = '', // New member
    deathState = '', // New member
    maritalStatus = '',
    recordIdSSN = '',
    recordIdRace = '',
    recordIdMarital = '',
    recordIdDeath = '',
    changeInNameYN = '',
    changeInSSNYN = '',
    changeInDemographicYN = '',
    changeInRaceYN = '',
    changeInMaritalYN = '',
    ssnVerificationId,
    marriedYN = '',
    dobVerificationId = '',
  } = data;

  // const {
  //   soSecPart1 = '',
  //   soSecPart2 = '',
  //   soSecPart3 = '',
  // } = ssn?.match(
  //   /^(?<soSecPart1>\d{3}).(?<soSecPart2>\d{2}).(?<soSecPart3>\d{4})$/
  // )?.groups;
  const [soSecPart1, soSecPart2, soSecPart3] = ssn.split('-');

  return [
    {
      individualId: individualId,
      // beginDate: '2024-01-05',
      // endDate: '9999-12-30',
      beginDate: dayjs().format('YYYY-MM-DD'),
      // effectiveBeginDate: null,
      personName: {
        dirtyStatus: 'update',
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        suffix: suffix,
      },
      aliasNames: [],
      soSec: {
        dirtyStatus: 'update',
        recordId: soSecPart1 ? recordIdSSN : 0,
        soSecPart1: soSecPart1 ?? '',
        soSecPart2: soSecPart2 ?? '',
        soSecPart3: soSecPart3 ?? '',
        ssnVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: 'N',
          documentDetails: [],
          verificationType: '',
          providedVerificationId: ssnVerificationId, //=======================
          verificationSourceCode: verificationDoc, //=======================
          verifiedYN: 'Y',
          dueDate: null,
          automatedVerificationYN: 'N',
        },
      },

      dobVerification: {
        retryCount: 0,
        verificationRequestedYN: 'N',
        providedVerificationId: dobVerificationId, //==============
        verifiedYN: 'Y',
        verificationSourceCode: dobDoc, //================
      },
      soSecReferral: ssnReferral,

      ssnApplicationDate: ssnAppDt,
      secondarySSN: null,
      dob: dob,
      gender: gender,
      birthHospital: '',
      birthCity: birthCity,
      birthState: birthState,
      race: {
        recordId: recordIdRace,
        primaryRace: race,
        otherRaces: additionRace ? [additionRace] : [],
        dirtyStatus: 'update',
      },
      headOfHouseholdYN: headOfHouse ? 'Y' : 'N',
      voterRegistrationStatus: '',
      primaryVerificationSource: verSource,
      ethnicity: ethnicity,
      maritalStatus: {
        recordId: recordIdMarital,
        dirtyStatus: 'update',
        marriedYN: marriedYN,
        verificationSourceCode: null,
        effectiveBeginDate: null,
        effectiveEndDate: null,
        marriageEndDate: null,
      },
      changeInNameYN,
      changeInSSNYN,
      changeInDemographicYN,
      changeInRaceYN,
      changeInMaritalYN,
      deathDetails: {
        recordId: recordIdDeath,
        dateOfDeath: null,
        state: null,
        deathCertificateNumber: null,
        deathVerification: {
          verificationSourceCode: null,
          providedVerificationId: 0,
        },
        secondaryVerification: {
          verificationSourceCode: null,
          providedVerificationId: 0,
        },
        dirtyStatus: 'update',
      },
    },
  ];
};

export const smatyStreetPayload = (data) => {
  const {
    care = '',
    line1 = '',
    line2 = '',
    city = '',
    State = '',
    zipCode = '',
    zipCode4 = '',
    Country = '',
  } = data;

  return {
    addressSearches: [
      {
        search: {
          addressId: 0,
          dirtyStatus: 'insert',
          homeAddressYN: false,
          institutionYN: false,
          vendorId: '',
          institutionName: '',
          addressLine1: line1,
          addressLine2: line2,
          city: city,
          zipcode: zipCode,
          zip4code: zipCode4,
          state: State,
          county: Country,
          district: '',
          addressVerifiedYN: '',
        },
      },
    ],
  };
};

export const contactInfoPayload = (data) => {
  const {
    Hearing = '',
    Visually = '',
    Physicallly = '',
    Interpreter = '',
    addressType = '',
    mailAsSame = '',
    mailingaddresstype = '',
    language = '',
    care = '',
    line1 = '',
    line2 = '',
    city = '',
    State = '',
    zipCode = '',
    zipCode4 = '',
    county = '',
    mailCare = '',
    mailLine1 = '',
    mailLine2 = '',
    mailCity = '',
    mailState = '',
    mailZipCode = '',
    mailZipCode4 = '',
    mailcountry = '',
    phoneNumber = '',
    ext = '',
    numberType = '',
    altPhoneNumber = '',
    altExt = '',
    altNumberType = '',
    email = '',
    communicationMode = '',
    communicationlang = '',
    addressId = '',
    hoh = [],
    caseAddressId = '',
    mailingAddressId = '',
  } = data;

  return {
    additionalInformation: {
      preferredLanguage: language,
      otherLanguage: '',
      interpreterNeededYN: Interpreter ? 'Y' : 'N',
      visuallyImpairedYN: Visually ? 'Y' : 'N',
      hearingImpairedYN: Hearing ? 'Y' : 'N',
      physicallyImpairedYN: Physicallly ? 'Y' : 'N',
      hearingImpairedType: '',
    },
    contactInformation: {
      homeAddress: {
        caseAddressId: caseAddressId ?? null,
        addressId,
        dirtyStatus: addressId ? 'update' : 'insert',
        // formValues.addressId ? "update" :'insert'
        homeAddressYN: 'Y',
        institutionYN: addressType === 'Institution' ? 'Y' : 'N',
        vendorId: 0,
        institutionName: '',
        addressCareOf: care,
        addressLine1: line1,
        addressLine2: line2,
        city: city,
        zipcode: zipCode,
        zip4code: zipCode4,
        state: State,
        county: county,
        district: '',
        addressVerifiedYN: 'Y',
      },
      sameMailingAddressYN: mailAsSame ? 'Y' : 'N',
      mailingAddress: {
        caseAddressId: mailingAddressId ?? null,
        addressId: addressId,
        dirtyStatus: addressId ? 'update' : 'insert',
        localDistrictOfficeYN: mailingaddresstype === 'local' ? 'Y' : 'N',
        addressCareOf: mailAsSame ? care : mailCare,
        addressLine1: mailAsSame ? line1 : mailLine1,
        addressLine2: mailAsSame ? line2 : mailLine2,
        city: mailAsSame ? city : mailCity,
        zipcode: mailAsSame ? zipCode : mailZipCode,
        zip4code: mailAsSame ? zipCode4 : mailZipCode4,
        state: mailAsSame ? State : mailState,
        county: mailAsSame ? county : mailcountry,
        district: '',
        addressVerifiedYN: '',
      },
      phoneNumber: {
        phoneNumber: phoneNumber,
        phoneNumberExt: ext,
      },
      altPhoneNumber: {
        phoneNumber: altPhoneNumber,
        phoneNumberExt: altExt,
      },
      previousAddresses: null,
      preferredModeOfCommunication: communicationMode,
      preferredContactTime: '',
      communicationLanguage: communicationlang,
      email: email,
      phoneNumberDetails: {
        phoneNumber: phoneNumber,
        phoneNumberTypeCd: numberType,
        phoneNumberExtension: ext,
        altPhoneNumber: altPhoneNumber,
        altPhoneNumberExtension: altExt,
        altPhoneNumberTypeCd: altNumberType,
      },
    },
    householdMemberDetails: hoh,
  };
};

export const scheduleInterviewPayload = (
  { county, districtOffice, workerId },
  signatures,
  uuid
) => {
  console.log(signatures);
  return {
    scheduleInterview: {
      appointmentStartTime: null,
      appointmentEndTime: null,
      appointmentType: '',
      county,
      // districtOffice: "(300) CENTRAL ADMIN",
      districtOffice,
      worker: {
        workerId,
      },
      emailAssistanceRequestFormYN: '',
    },
    interviewSignature: {
      appointmentStartTime: null,
      appointmentEndTime: null,
      appointmentType: '',
      signatures,
    },
    caseLoadIndicator: 'Y',
    uniqueId: uuid,
  };
};

function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export const saveUploadFilesPayload = (payload) => {
  const formData = new FormData();
  formData.append('files', payload.fileFormData);
  formData.append('docType', payload.documentTypeCd);
  formData.append('individualId', payload.individualId);
  formData.append('docCat', payload.lookupShortVal);
  formData.append('verificationName', payload.verificationName);
  formData.append('role', payload.role);
  formData.append('user', payload.user);
  formData.append('sourceCaseId', payload.sourceCaseId);
  formData.append('sourceSystem', payload.sourceSystem);
  if (payload.documentId) {
    formData.append('documentId', payload.documentId);
  }

  return formData;
};

export const addLivingArrangementPayload = (data, dirtyStatus) => {
  const {
    endDate,
    beginDate,
    adoptionType,
    vermontResident,
    programDetails,
    verification,
    effectiveBeginDate,
    effectiveEndDate,
    livingArrangement,
    verificationSourceCode,
    isMDResidentYN,
    fosterAdoptTypeCd,
    recordId,
  } = data;

  return {
    recordId: recordId,
    dirtyStatus: dirtyStatus,
    livingArrangement: programDetails || livingArrangement,
    livingArrangementVerification: {
      verificationSourceCode: verification || verificationSourceCode,
      verificationRequestedYN: 'Y',
      verifiedYN: 'Y',
    },
    verificationDocumentCd: null,
    verificationRequestedYN: null,
    verificationType: null,
    dueDate: null,
    effectiveBeginDate: beginDate || effectiveBeginDate,
    effectiveEndDate: endDate || effectiveEndDate,
    isMDResidentYN: vermontResident || isMDResidentYN,
    fosterAdoptTypeCd: adoptionType || fosterAdoptTypeCd,
    parentalStatus: null,
    parentalStatusVerification: {
      verificationSourceCode: null,
    },
  };
};

export const domesticViolencePayload = (data, dirtyStatus) => {
  const { beginDate, endDate, victimStatus, individualId, recordId } = data;
  return {
    dirtyStatus: dirtyStatus,
    dmstcVoilenceInd: victimStatus,
    effectiveBeginDt: beginDate,
    effectiveEndDt: endDate,
    immSrvcCd: '',
    immSrvcInd: '',
    individualId: individualId,
    recordId: recordId,
    sprtSrvcCd: '',
    sprtSrvcInd: '',
  };
};

export const schedulePayload = (data) => {
  const {
    caseMode,
    interviewDate,
    interviewStatus,
    interviewType,
    phoneNumber,
    ldssDoCd,
    startTime,
    endTime,
    fsInd,
    chInd,
    maInd,
    eaInd,
  } = data;

  return {
    interviewStatus: interviewStatus,
    missedInterviewDate: null,
    interviewDate: interviewDate,
    fsInd,
    chInd,
    maInd,
    eaInd,
    interviewType: interviewType,
    startTime: startTime,
    endTime: endTime,
    dirtyStatus: 'insert',
    ldssRecordId: 8,
    caseMode: 'IN',
    phoneNumber: phoneNumber ?? '',
    ldssDoCd: ldssDoCd,
    programInterviewScheduleId: '0',
  };
};
