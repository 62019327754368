import HttpClient from '../HttpClient';
import { format } from '../helpers';
import {
  GET_CASE_DETAILS,
  GET_CITIZENSHIP,
  GET_HHRELATIONSHIPS,
  GET_HOUSE_MEMBERS,
  GET_INCOME,
  GETCASE_ID,
  POST_CITIZENSHIP,
  POST_NEW_MEMBER,
  POST_RELATIONSHIP,
  POST_UNEARNEDINCOME,
  POST_EARNEDINCOME,
  POST_UNEARNEDINCOME_APPLICATION,
  POST_POTENTIAL_APPLICATION,
  SUMMARY_DATA,
  SCHEDULE_INTERVIEW,
  DELETE_POTENTIAL_APPLICATION,
  POST_SMARTY_STREET,
  POST_CONTACT_INFOMATION,
  GET_CONTACT_INFOMATION,
  PUT_EDIT_MEMBER,
  POST_INCOME_UPLOADFILES,
  GET_CASEHOME,
  PUT_DELETE_MEMBER,
  POST_MYDASHBOARD,
  POST_UNASSIGNED,
  GET_ASSIGNED,
  GET_OVERDUE,
  SEARCH_WORK_ITEM,
  POST_LIVINGARRANGEMENT,
  Get_INDIVIDUAL,
  GET_DOMESTIC_VIOLENCE,
  POST_DOMESTIC_VIOLENCE,
  GET_MEMBERS,
  GET_PROGRAM_REQ,
  CompleteCase,
  GET_DISPOSTION,
  POST_SLOT,
  POST_SCHEDULE,
  GET_SCHEDULE,
  WORKER_WORK_ITEM,
  CLIENT_MNGM_ITEM,
  GET_COUNTYWORKER,
  GET_UNIQUEID,
  POST_ADDWORKER,
  GET_WORKITEM_MY_DASHBOARD,
  GET_WORKITEM_UNASSIGNED_APPLICATION,
  GET_WORKITEM_ASSIGNED_APPLICATION,
  GET_WORKEITEM_OVERDUE,
  GET_WORKITEM_OVERDUE,
  GET_RECERTIFICATION_MY_DASHBOARD,
  GET_RECERTIFICATION_UNASSIGNED_APPLICATION,
  GET_RECERTIFICATION_ASSIGNED_APPLICATION,
  GET_RECERTIFICATION_OVERDUE,
  CASE_ASSIGNMENT,
  CASE_RE_ASSIGNMENT,
  GET_HOLIDAY,
  CLOSE_NEW,
  ASSIGN_WORK_ITEM,
  GET_QUESTION,
  GET_DASHBOARD_COUNT,
  CASE_SAVE_AND_SUBMIT,
  ASSIGN_CASEWRKR,
  GET_CASEWORKER_DASHBOARD,
  GET_SUPV_DASHOARD_UNASSIGNED,
  GET_ASSIGN_CASEWORKER,
  SCHEDULED_INTERVIEW_ASSIGN_CASEWORKER,
  ACTIVE_WORKERS,
  ADD_WORK_ITEM,
  GET_USER,
  DO_WORKER_INFO,
  GET_LDSSDOCD_UM,
  GET_SUPERCISORS_UM,
  BULLETIN_BOARD,
  OFF_BOARDED,
  SEARCH_PROGRAM,
  GET_COUNTIES,
  GET_OFFICE_WORKERS,
  GET_OFFICE_COUNTY,
  TRANSFER_DO,
  GET_CASEWORKER_DASHBOARD_NEW,
  GET_NEXT_WORK_ITEM,
  GET_ELIGIBLE_CASE,
  RUN_ELIGIBLE,
} from './apiUrls';

import {
  addNewMemberPayload,
  saveRelationPayload,
  smatyStreetPayload,
  contactInfoPayload,
  scheduleInterviewPayload,
  saveUnearnedIncomePayload,
  saveEarnedIncomePayload,
  saveUnEarnedIncomeAppPayload,
  savePotentialIncomePayload,
  editMemberPayload,
  saveUploadFilesPayload,
  addLivingArrangementPayload,
  domesticViolencePayload,
  editMember,
  schedulePayload,
  addWorkerPayload,
} from './apiPayloads';

export const authenticateUser = () => HttpClient.get('/auth');
export const getCaseDetailesbyId = (caseId) =>
  HttpClient.get(format(GETCASE_ID, caseId));
export const getSummaryData = (caseId) =>
  HttpClient.get(format(SUMMARY_DATA, caseId));

export const getIndividual = (caseId) =>
  HttpClient.get(format(Get_INDIVIDUAL, caseId));

export const getIncome = (caseId) => HttpClient.get(format(GET_INCOME, caseId));

export const getHouseMembers = (caseId) =>
  HttpClient.get(format(GET_HOUSE_MEMBERS, caseId));

export const getRelationList = (caseId, individualId) =>
  HttpClient.get(format(GET_HHRELATIONSHIPS, caseId, individualId));

export const saveRelationShip = (caseId, relation, userId) => {
  return HttpClient.post(
    format(POST_RELATIONSHIP, caseId, userId),
    saveRelationPayload(relation, userId)
  );
};

export const getCaseDetails = (caseId) =>
  HttpClient.get(format(GET_CASE_DETAILS, caseId));

export const addNewMember = (data = {}, caseId, programs, date) =>
  HttpClient.post(
    POST_NEW_MEMBER,
    addNewMemberPayload(data, caseId ? caseId : 'new', programs, date)
  );

export const postLivingArrangement = (
  data = {},
  caseId,
  individualId,
  dirtyStatus
) =>
  HttpClient.post(
    format(POST_LIVINGARRANGEMENT, caseId, individualId),
    addLivingArrangementPayload(data, dirtyStatus)
  );

export const saveUnearnedIncome = (caseId, values, userId, isAdd, isDelete) => {
  if (isDelete) {
    return HttpClient.put(
      format(POST_UNEARNEDINCOME, caseId, userId),
      saveUnearnedIncomePayload(values, isAdd, isDelete)
    );
  } else if (!isAdd) {
    return HttpClient.put(
      format(POST_UNEARNEDINCOME, caseId, userId),
      saveUnearnedIncomePayload(values, isAdd)
    );
  } else {
    return HttpClient.post(
      format(POST_UNEARNEDINCOME, caseId, userId),
      saveUnearnedIncomePayload(values, isAdd)
    );
  }
};

export const saveEarnedIncome = (caseId, values, userId, isAdd, isDelete) => {
  if (isDelete) {
    return HttpClient.put(
      format(POST_EARNEDINCOME, caseId, userId),
      saveEarnedIncomePayload(values, isAdd, isDelete)
    );
  } else if (!isAdd) {
    return HttpClient.put(
      format(POST_EARNEDINCOME, caseId, userId),
      saveEarnedIncomePayload(values, isAdd)
    );
  } else {
    return HttpClient.post(
      format(POST_EARNEDINCOME, caseId, userId),
      saveEarnedIncomePayload(values, isAdd)
    );
  }
};

export const saveUnEarnedIncomeApp = (
  caseId,
  values,
  userId,
  isAdd,
  isDelete
) => {
  if (isDelete) {
    return HttpClient.put(
      POST_UNEARNEDINCOME_APPLICATION,
      saveUnEarnedIncomeAppPayload(values, isAdd, isDelete)
    );
  } else if (!isAdd) {
    return HttpClient.put(
      POST_UNEARNEDINCOME_APPLICATION,
      saveUnEarnedIncomeAppPayload(values, isAdd, false, userId)
    );
  } else {
    return HttpClient.post(
      POST_UNEARNEDINCOME_APPLICATION,
      saveUnEarnedIncomeAppPayload(values, isAdd, false, userId)
    );
  }
};

export const savePotentialIncome = (
  caseId,
  values,
  userId,
  isAdd,
  isDelete
) => {
  if (isDelete) {
    return HttpClient.post(
      format(DELETE_POTENTIAL_APPLICATION, caseId, userId),
      savePotentialIncomePayload(values, isAdd, isDelete)
    );
  } else if (!isAdd) {
    return HttpClient.put(
      format(POST_POTENTIAL_APPLICATION, caseId, userId),
      savePotentialIncomePayload(values, isAdd)
    );
  } else {
    return HttpClient.post(
      format(POST_POTENTIAL_APPLICATION, caseId, userId),
      savePotentialIncomePayload(values, isAdd)
    );
  }
};

export const editMemberDetails = (caseId, data = {}, individualId) => {
  return HttpClient.put(
    format(PUT_EDIT_MEMBER, caseId),
    editMemberPayload(caseId, data, individualId)
  );
};

export const smartyStreet = (data = {}, caseId) =>
  HttpClient.post(format(POST_SMARTY_STREET, caseId), smatyStreetPayload(data));

export const contactInfomaton = (data = {}, caseId) =>
  HttpClient.post(
    format(POST_CONTACT_INFOMATION, caseId),
    contactInfoPayload(data)
  );

export const getContactInfo = (caseId) =>
  HttpClient.get(format(GET_CONTACT_INFOMATION, caseId));

export const scheduleInterview = (caseId, data = {}, signatures, uuid) => {
  return HttpClient.post(
    format(SCHEDULE_INTERVIEW, caseId),
    scheduleInterviewPayload(data, signatures, uuid)
  );
};

export const getDisposition = (caseId) => {
  return HttpClient.get(format(GET_DISPOSTION, caseId));
};

export const getCountyWorker = (caseId) => {
  return HttpClient.get(format(GET_COUNTYWORKER, caseId));
};

export const getUniqueId = (uuid) => {
  return HttpClient.get(format(GET_UNIQUEID, uuid));
};
export const PostAddWorker = (data = {}, caseId) => {
  return HttpClient.post(
    format(POST_ADDWORKER, caseId),
    addWorkerPayload(data)
  );
};

export const postSlot = (data = {}, caseId) =>
  HttpClient.post(format(POST_SLOT, caseId), data);

export const postScheduleAppointment = (data = {}, caseId) =>
  HttpClient.post(
    format(POST_SCHEDULE, caseId),
    data?.programInterviewScheduleId !== 0 ? schedulePayload(data) : data
  );

export const getSchedule = (caseId) => {
  return HttpClient.get(format(GET_SCHEDULE, caseId));
};

export const getCitizenship = (caseId) => {
  return HttpClient.get(format(GET_CITIZENSHIP, caseId));
};

export const saveCitizenship = (caseId, individualId, data = {}) => {
  return HttpClient.post(format(POST_CITIZENSHIP, caseId, individualId), {
    citizenshipImmigration: [data],
  });
};

export const getCaseHome = (caseId) => {
  return HttpClient.get(format(GET_CASEHOME, caseId));
};

export const deleteMemberApi = (caseId, data = {}) => {
  console.log(data);
  return HttpClient.put(format(PUT_DELETE_MEMBER, caseId), data);
};

export const myDashboardApi = (paginationDataPaper = {}, uuid, name) => {
  const data = { paginationDataPaper };
  return HttpClient.post(format(POST_MYDASHBOARD, name, uuid), data);
};

export const unassignedApi = (paginationDataPaper = {}, uuid, name) => {
  const data = {
    paginationData: {},
    paginationDataPaper,
  };
  return HttpClient.post(format(POST_UNASSIGNED, name, uuid), data);
};

export const caseAssigne = (data, name) => {
  return HttpClient.post(format(CASE_ASSIGNMENT, name), data);
};

export const caseReAssigne = (data, name) => {
  return HttpClient.post(format(CASE_RE_ASSIGNMENT, name), data);
};

export const assignedApi = (paginationDataPaper = {}, uuid, name) => {
  const data = { paginationDataPaper };
  return HttpClient.get(format(GET_ASSIGNED, name, uuid), data);
};

export const overDueApi = (uuid, name) => {
  return HttpClient.get(format(GET_OVERDUE, name, uuid));
};

export const searchWorkItem = (data = {}) => {
  return HttpClient.post(SEARCH_WORK_ITEM, data);
};

export const closeNew = (data) => {
  return HttpClient.post(CLOSE_NEW, data);
};

export const assignWorkItem = (data) => {
  return HttpClient.post(ASSIGN_WORK_ITEM, data);
};

export const workersWorkItem = () => {
  return HttpClient.get(format(WORKER_WORK_ITEM));
};

export const clientMgmtItem = () => {
  return HttpClient.get(format(CLIENT_MNGM_ITEM));
};

export const saveIncomeUploadFiles = (caseId, payload) => {
  return HttpClient({
    method: 'POST',
    url: format(POST_INCOME_UPLOADFILES, caseId),
    data: saveUploadFilesPayload(payload),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const documentUpload = (initialState = {}) => {
  return HttpClient.post(
    `${process.env.REACT_APP_DMS_URL}/docControl/documentDetails/upload`,
    saveUploadFilesPayload(initialState),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const editDocumentUpl = (initialState = {}) => {
  return HttpClient.post(
    `${process.env.REACT_APP_DMS_URL}/docControl/documentDetails/edit`,
    saveUploadFilesPayload(initialState),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const getDocumentsFiles = (sourceCaseId) => {
  return HttpClient.get(
    `${process.env.REACT_APP_DMS_URL}/docControl/getDocumentsByCaseId/${sourceCaseId}/KCARES`
  );
};

export const getUploadFilesDoc = (documentIds) => {
  return HttpClient.get(
    `${process.env.REACT_APP_DMS_URL}/docControl/getDocuments/${documentIds}`
  );
};

export const deleteFileUpl = (documentId) => {
  return HttpClient.delete(
    `${process.env.REACT_APP_DMS_URL}/docControl/documentDetails/delete/${documentId}`
  );
};

export const downloadDocument = (docId, sourceCaseId) => {
  return HttpClient.post(
    `${process.env.REACT_APP_DMS_URL}/docControl/downloadDocumentBy/${docId}/${sourceCaseId}/KCARES`,
    {},
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/json',
        'access-control-allow-methods': 'PUT, GET, HEAD, POST, DELETE, OPTIONS',
      },
    }
  );
};

// export const getDocumentsFiles = (caseId) => {
//   return HttpClient.get(
//     format('/eeapi/application-process/screening/documents/{0}', caseId)
//   );
// };

export const getDomesticViolence = (caseId, individualId) => {
  return HttpClient.get(format(GET_DOMESTIC_VIOLENCE, caseId, individualId));
};

export const postEditMember = (caseId, data, individualId) => {
  return HttpClient.post(
    format(GET_HOUSE_MEMBERS, caseId),
    editMember(data, individualId)
  );
};

export const postDomesticViolence = (
  data = {},
  caseId,
  individualId,
  dirtyStatus
) => {
  return HttpClient.post(
    format(POST_DOMESTIC_VIOLENCE, caseId, individualId),
    domesticViolencePayload(data, dirtyStatus)
  );
};

export const getProgramRequest = (caseId) =>
  HttpClient.get(format(GET_PROGRAM_REQ, caseId));

export const postCaseComplete = (caseId, YesNo) => {
  console.log(YesNo);
  return HttpClient.post(format(CompleteCase, caseId, YesNo));
};

export const getQuestion = (caseId, program) => {
  return HttpClient.get(format(GET_QUESTION, caseId, program));
};

export const postCaseSaveAndSubmit = (data) => {
  return HttpClient.post(format(CASE_SAVE_AND_SUBMIT), data);
};
export const postAssignCaseWorker = (caseId) => {
  return HttpClient.post(format(ASSIGN_CASEWRKR, caseId));
};

export const getDashboardCount = (uuid) => {
  return HttpClient.get(format(GET_DASHBOARD_COUNT, uuid));
};

export const getCaseWorkerDashboard = () => {
  return HttpClient.get(format(GET_CASEWORKER_DASHBOARD));
};

export const getSupvDashboardUnassigned = (url) => {
  return HttpClient.get(format(GET_SUPV_DASHOARD_UNASSIGNED, url));
};

export const scheduledAssignCaseWorker = (data) => {
  return HttpClient.post(format(SCHEDULED_INTERVIEW_ASSIGN_CASEWORKER), data);
};

export const getActiveWorkers = () => {
  return HttpClient.get(format(ACTIVE_WORKERS));
};

export const addWorkItem = (data) => {
  return HttpClient.post(format(ADD_WORK_ITEM), data);
};

export const userManagementGetUsers = (uuid) => {
  return HttpClient.get(format(GET_USER, uuid));
};

export const doWorkerInfo = () => {
  return HttpClient.get(format(DO_WORKER_INFO));
};

export const getLdssdocd = (officeCode) => {
  return HttpClient.get(format(GET_LDSSDOCD_UM, officeCode));
};

export const getSupvData = (uuid) => {
  return HttpClient.get(format(GET_SUPERCISORS_UM, uuid));
};

export const getBulletdata = (data) => {
  return HttpClient.post(format(BULLETIN_BOARD), data);
};

export const getOffboardedWorkers = () => {
  return HttpClient.get(OFF_BOARDED);
};

export const searchProgram = (data) => {
  return HttpClient.post(format(SEARCH_PROGRAM), data);
};

export const getCountiesList = () => {
  return HttpClient.get(GET_COUNTIES);
};

export const getOfficeCounty = (code) => {
  return HttpClient.get(format(GET_OFFICE_COUNTY, code));
};

export const getOfficeWorkers = (code) => {
  return HttpClient.get(format(GET_OFFICE_WORKERS, code));
};

export const clientManagementTransferDO = (data) => {
  return HttpClient.post(format(TRANSFER_DO), data);
};

export const getCaseWorkerDashboardNew = (data) => {
  return HttpClient.post(format(GET_CASEWORKER_DASHBOARD_NEW), data);
};
export const getNextWork = (data) => {
  return HttpClient.post(format(GET_NEXT_WORK_ITEM), data);
};

export const getEligibleCase = (data) => {
  return HttpClient.post(GET_ELIGIBLE_CASE, data);
};

export const runEligibleCase = (data) => {
  return HttpClient.post(RUN_ELIGIBLE, data);
};