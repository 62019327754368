import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../components/FormSection';
import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import YesNoOption from '../../../components/YesNoOption';
import { yNtoBool } from '../../../utils/helpers';

function SnapQuestionnaire({ formik }) {
  const { monthlyRent, grossMonthlyIncomeResources } = formik.values;
  return (
    <FormSection label="You may get SNAP right away if you meet one of the following conditions:">
      <Stack spacing={2}>
        <Box>
          <Stack spacing={1}>
            <Typography variant="subtitle1" color="var(--grey-400)">
              Is your household's income and resources less than your
              household's monthly rent or mortgage and utilities?
            </Typography>
            <YesNoOption {...formik.getFieldProps('monthlyRent')} />
          </Stack>
          {yNtoBool(monthlyRent) && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" color="var(--grey-600)">
                How much is your monthly rent or mortgage and utilities?
              </Typography>
              <Box width={300}>
                <TextField
                  name="monthlyRentAmount"
                  placeholder="0.00"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 11, type: 'number' }}
                  error={
                    formik.touched.monthlyRentAmount &&
                    formik.errors.monthlyRentAmount
                  }
                  {...formik.getFieldProps('monthlyRentAmount')}
                />
              </Box>
            </Stack>
          )}
        </Box>
        <Divider />
        <Box>
          <Stack spacing={1}>
            <Typography variant="subtitle1" color="var(--grey-400)">
              Is your household's gross monthly income less than $150 and your
              resources as bank accounts $100 or less?
            </Typography>
            <YesNoOption
              {...formik.getFieldProps('grossMonthlyIncomeResources')}
            />
          </Stack>
          {yNtoBool(grossMonthlyIncomeResources) && (
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="var(--grey-600)">
                  How much is your gross monthly income ?
                </Typography>
                <Box width={300}>
                  <TextField
                    name="grossMonthlyIncomeAmount"
                    placeholder="0.00"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 11, type: 'number' }}
                    error={
                      formik.touched.grossMonthlyIncomeAmount &&
                      formik.errors.grossMonthlyIncomeAmount
                    }
                    {...formik.getFieldProps('grossMonthlyIncomeAmount')}
                  />
                </Box>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" color="var(--grey-600)">
                  How much do you have in resources, such as bank accounts ?
                </Typography>
                <Box width={300}>
                  <TextField
                    name="resourcesAmount"
                    placeholder="0.00"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 11, type: 'number' }}
                    error={
                      formik.touched.resourcesAmount &&
                      formik.errors.resourcesAmount
                    }
                    {...formik.getFieldProps('resourcesAmount')}
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </Box>
        <Divider />
        <Stack spacing={1}>
          <Typography variant="subtitle1" color="var(--grey-400)">
            Is your household's income and resources less than your household's
            monthly rent or mortgage and utilities?
          </Typography>
          <YesNoOption {...formik.getFieldProps('migrantSeasonal')} />
        </Stack>
      </Stack>
    </FormSection>
  );
}

SnapQuestionnaire.propTypes = {};

export default SnapQuestionnaire;
