import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import Icon from '../Icon';

function CurrencyField({ type, alignRight, amount }) {
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      justifyContent={alignRight ? 'flex-end' : 'flex-start'}
    >
      <Icon width={10} height={12} name="DollarSign" />
      <Box ml="2px" component="span">
        {amount.toFixed(2)}
      </Box>
    </Stack>
  );
}

CurrencyField.propTypes = {
  type: PropTypes.string,
  amount: PropTypes.number,
};

CurrencyField.defaultProps = {
  type: 'dollar',
  amount: 0,
};

export default CurrencyField;
