import { Button, Grid, Stack, Typography, IconButton } from '@mui/material';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useState, useEffect, useMemo } from 'react';
import useLoader from '../../../hooks/useLoader';
import {
  getLDSSCode,
  getLDSSOption,
  postCaseLDSSCode,
} from '../../../utils/services/EligibilityParameters/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import TabularView from '../../../components/TabularView';
import Dropdown from '../../../components/Dropdown';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DatePicker } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import useLookup from '../../../hooks/useLookup';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../../store/selectors/authSelector';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/grocery-shopping.svg';

const DSNAP = () => {
  const [showLoader, hideLoader] = useLoader();
  const [selectedEligibilityData, setSelectedEligibilityData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState({});
  const [ldssCodes, setLdssCodes] = useState([]);
  const { getLkpMatch, getOptions } = useLookup('dSNAP');
  const token = useSelector(getAuthToken);
  const uniqueIdToken = token?.sub;

  const { statusOpts } = useMemo(() => {
    return {
      statusOpts: getOptions('DisasterStatus'),
    };
  }, [getOptions]);

  const validationSchema = Yup.object({
    type: Yup.string()
      .required('Please enter disaster Type')
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        'Type should not contain special characters'
      ),
    createdBeginDt: Yup.date().required('Start Date is required'),
    createdEndDt: Yup.date().required('End Date is required'),
    status: Yup.string().required('Please select Status'),
    LDSScode: Yup.string().required('Please select LDSS Code'),
  });

  useEffect(() => {
    getEligibility();
  }, []);

  useEffect(() => {
    if (openDialog) {
      getLdss();
    }
  }, [openDialog]);

  const getLdss = async () => {
    try {
      showLoader();
      const res = await getLDSSOption();
      if (res.status === 200) {
        let arr = res.data.map((item) => ({
          label: `${item.ldssName}[${item.districtOfficeCode}]`,
          value: item.districtOfficeCode,
          ...item,
        }));
        setLdssCodes(arr);
      } else {
        showErrorToast('Failed to fetch eligibility data');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getEligibility = async () => {
    try {
      showLoader();
      const res = await getLDSSCode(uniqueIdToken);
      if (res.status === 200) {
        setSelectedEligibilityData(res.data);
      } else {
        showErrorToast('Failed to fetch eligibility data');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'disasterName',
        headerName: 'Type of Disaster',
      },
      {
        field: 'disasterStatus',
        headerName: 'Status',
        renderCell: (params) => {
          return getLkpMatch('DisasterStatus', params) || '';
        },
      },
      {
        field: 'ldssCd',
        headerName: 'LDSS Code',
      },
      {
        field: 'effectiveBeginDate',
        headerName: 'Begin Date',
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
      },
      {
        headerName: 'Action',
        renderCell: (params) => {
          return (
            <IconButton onClick={() => editEligibilityData(params)}>
              <EditIcon />
            </IconButton>
          );
        },
      },
    ],
    [getLkpMatch]
  );

  const handleDialogOpen = () => {
    setEditData(null);
    formik.resetForm();
    setOpenDialog(true);
  };

  const handleClose = () => {
    setEditData(null);
    formik.resetForm();
    setOpenDialog(false);
  };

  const handleSaveLDSS = async (data, editData) => {
    try {
      showLoader();
      const res = await postCaseLDSSCode(data, editData ?? {}, uniqueIdToken);
      if (res.status === 200) {
        showSuccessToast('Rule Changed Successfully');
        await getEligibility();
        setTimeout(() => {
          handleClose();
        }, 5500);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      createdBeginDt: editData ? dayjs(editData.effectiveBeginDate) : null,
      createdEndDt: editData ? dayjs(editData.effectiveEndDate) : null,
      type: editData ? editData.disasterName : null,
      status: editData ? editData.disasterStatus : null,
      LDSScode: editData ? editData.ldssCd : null,
    },
    validationSchema,
    onSubmit: (value) => {
      handleSaveLDSS(value, editData);
    },
  });

  const editEligibilityData = (params) => {
    setOpenDialog(true);

    formik.setValues({
      createdBeginDt: params ? dayjs(params.effectiveBeginDate) : null,
      createdEndDt: params ? dayjs(params.effectiveEndDate) : null,
      type: params ? params.disasterName : null,
      status: params ? params.disasterStatus : null,
      LDSScode: params ? params.ldssCd : null,
    });
  };

  return (
    <div
      style={{ padding: '10px', maxWidth: '100%' }}
      onSubmit={formik.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} sx={{ paddingTop: '0px !important' }}>
          <div
            style={{
              border: '2px solid var(--grey-350)',
              padding: '1rem',
              borderRadius: '6px',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              pb={1}
            >
              <Button onClick={handleDialogOpen}>
                <AddCircleOutlineIcon />
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '600',
                    paddingLeft: '5px',
                  }}
                >
                  Add DSNAP
                </span>
              </Button>
            </Stack>
            <div
              style={{ border: '1px solid --grey-300', borderRadius: '6px' }}
            >
              <TabularView
                slots={{
                  noRowsOverlay: () => (
                    <EmptyState
                      header="Currently No Records Available"
                      icon={
                        <img
                          src={NoRecord}
                          style={{
                            height: '160px',
                            width: '250px',
                          }}
                        />
                      }
                    />
                  ),
                }}
                sx={{
                  table: {
                    width: '100%',
                  },
                  td: {
                    border: 0,
                    borderBottom: '1px solid var(--grey-300)',
                    padding: '1rem',
                  },
                  th: {
                    border: 0,
                    borderBottom: '1px solid var(--grey-300)',
                    padding: '1rem',
                    fontWeight: 'bold',
                    color: '#000000 !important',
                  },
                }}
                headerComponent={{ variant: 'h5', color: '#000' }}
                columns={columns}
                data={selectedEligibilityData}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent>
          <Stack direction="row" spacing={4} alignItems="center" pt={4} pb={3}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: '600',
                color: '--grey-400',
                minWidth: '15ch',
              }}
            >
              Disaster Dates
            </Typography>

            <Field
              isDate
              name="createdBeginDt"
              formik={formik}
              label="Start Date"
            >
              <DatePicker />
            </Field>
            <Field isDate name="createdEndDt" formik={formik} label="End Date">
              <DatePicker />
            </Field>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center" pt={4} pb={3}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: '600',
                color: '--grey-400',
                minWidth: '15ch',
              }}
            >
              Disaster Reason
            </Typography>
            <Field name="type" formik={formik} label="Type">
              <TextField
                placeholder="Enter Type"
                fullWidth
                value={formik.values.type}
                onChange={formik.handleChange}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: ' 5px !important',
                  },
                }}
              />
            </Field>
            <Field name="status" formik={formik} label="Status">
              <Dropdown options={statusOpts} />
            </Field>
            <Field
              name="LDSScode"
              formik={formik}
              label="LDSS Code"
              key={formik.values.LDSScode}
            >
              <Dropdown options={ldssCodes} />
            </Field>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Discard
          </Button>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            autoFocus
            sx={{ marginRight: '15px' }}
          >
            Save&Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DSNAP;
