import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import Immigrant from './Immigrant';
import NaturalizedCitizen from './NaturalizedCitizen';
import UsCitizen from './UsCitizen';
import UndocImmigrant from './UndocImmigrant';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { saveCitizenship } from '../../../utils/services/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { isEmpty, pick } from 'lodash';
import * as Yup from 'yup';
import useLoader from '../../../hooks/useLoader';
import { DatePicker } from '@mui/x-date-pickers';

const initValues = {
  alienNumber: '',
  beginDt: null,
  cardNumber: null,
  countryOfPassport: null,
  docExpirationDate: null,
  endDt: null,
  entryDate: null,
  i40QuarterIndYN: 'N',
  i94Number: null,
  immigrationDocument: null,
  lawfulResidentFiveYearsYN: 'N',
  refugeeSettlementAgency: null,
  residentshipPeriodYN: 'N',
  sevisId: null,
  sponsoredAlienYN: 'N',
  uscisStatus: null,
  uscisStatusDate: null,
  visaNumber: null,
  passportNumber: null,
  voterRegistration: '',
};

function CitizenshipForm({
  open,
  onClose,
  caseId,
  getOptions,
  recordId,
  citizenship,
  editMode,
  fetchCitizenship,
  individualId,
  age,
}) {
  const [showLoader, hideLoader] = useLoader();
  const {
    citizenshipOpts,
    immigrationDocOpts,
    countryOfOriginOpts,
    uscisStatusOpts,
    refugeeSmtAgencyOpts,
  } = useMemo(() => {
    return {
      citizenshipOpts: getOptions('Citizenship'),
      immigrationDocOpts: getOptions('CitizenshipVerification'), //USCIS Document
      countryOfOriginOpts: getOptions('CountryOfOrigin'), //Coutry of passport
      uscisStatusOpts: getOptions('INSStatus'), //USCIS Status
      refugeeSmtAgencyOpts: getOptions('RefugeeResettlementAgency'),
    };
  }, [getOptions]);

  const onSaveCitizenship = async (data) => {
    const getPayload = () => {
      switch (data.usCitizenshipStatus) {
        case 'L':
          // return data;
          const payloadIm = pick(data, [
            'usCitizenshipStatus',
            'beginDt',
            'endDt',
            'voterRegistration',
            'dirtyStatus',
            'recordId',
            'alienNumber',
            'uscisStatus',
            'uscisStatusDate',
            'entryDate',
          ]);

          return {
            ...payloadIm,
            usCitizenshipVerification: {
              verificationDocumentCd: '',
              verificationRequestedYN: 'Y',
              docNames: {},
              automatedVerificationYN: 'N',
              documentDetails: [],
              verificationType: '',
              providedVerificationId: 0,
              verifiedYN: 'Y',
              dueDate: null,
              onChecklistYN: '',
              verificationSourceCode: '',
              verificationSource: data?.verificationDoc,
              verificationName: '',
              verificationNameCode: '',
            },
          };

        case 'N':
          const payload = pick(data, [
            'usCitizenshipStatus',
            'beginDt',
            'endDt',
            'voterRegistration',
            'dirtyStatus',
            'recordId',
          ]);

          return {
            ...payload,
            usCitizenshipVerification: {
              verificationDocumentCd: '',
              verificationRequestedYN: 'Y',
              docNames: {},
              automatedVerificationYN: 'N',
              documentDetails: [],
              verificationType: '',
              providedVerificationId: 0,
              verifiedYN: 'Y',
              dueDate: null,
              onChecklistYN: '',
              verificationSourceCode: '',
              verificationSource: data?.verificationDoc,
              verificationName: '',
              verificationNameCode: '',
            },
          };
        case 'C':
          const payloadUS = pick(data, [
            'usCitizenshipStatus',
            'voterRegistration',
            'dirtyStatus',
            'recordId',
            'beginDt',
            'endDt',
          ]);
          return {
            ...payloadUS,
            usCitizenshipVerification: {
              verificationDocumentCd: '',
              verificationRequestedYN: 'N',
              docNames: {},
              automatedVerificationYN: 'N',
              documentDetails: [],
              verificationType: '',
              providedVerificationId: '',
              verifiedYN: 'Y',
              dueDate: null,
              onChecklistYN: '',
              verificationSourceCode: 0,
              verificationSource: data?.verificationDoc,
              verificationName: '',
              verificationNameCode: '',
            },
          };
        case 'I':
          return pick(data, [
            'usCitizenshipStatus',
            'beginDt',
            'dirtyStatus',
            'recordId',
            'endDt',
          ]);
        case 'R':
          const payloadUSBorn = pick(data, [
            'usCitizenshipStatus',
            'dirtyStatus',
            'recordId',
            'beginDt',
            'endDt',
          ]);
          return {
            ...payloadUSBorn,
            usCitizenshipVerification: {
              verificationDocumentCd: '',
              verificationRequestedYN: 'N',
              docNames: {},
              automatedVerificationYN: 'N',
              documentDetails: [],
              verificationType: '',
              providedVerificationId: '',
              verifiedYN: 'Y',
              dueDate: null,
              onChecklistYN: '',
              verificationSourceCode: 0,
              verificationSource: data?.verificationDoc,
              verificationName: '',
              verificationNameCode: '',
            },
          };
        default:
          return;
      }
    };
    try {
      showLoader();
      const payload = getPayload();
      const res = await saveCitizenship(caseId, individualId, {
        ...initValues,
        ...payload,
      });

      if (res.status === 200) {
        showSuccessToast('Successfully Added Citizenship');
        fetchCitizenship();
        onClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const validationSchema = Yup.object({
    beginDt: Yup.string().when('usCitizenshipStatus', {
      is: (usCitizenshipStatus) => {
        return ['L', 'I', 'N'].includes(usCitizenshipStatus) && age > 16;
      },
      then: () => Yup.string().required('Begin date is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    uscisStatusDate: Yup.string().when('usCitizenshipStatus', {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === 'L';
      },
      then: () => Yup.string().required('USCIS Status Date is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    alienNumber: Yup.string().when('usCitizenshipStatus', {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === 'L';
      },
      then: () =>
        Yup.string()
          .matches(/[aA]\d{9}/, 'Alien number is incorrect.')
          .required('Alien number is required.'),
      otherwise: () => Yup.string().notRequired(),
    }),
    uscisStatus: Yup.string().when('usCitizenshipStatus', {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === 'L';
      },
      then: () => Yup.string().required('USCIS Status is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    entryDate: Yup.string().when('usCitizenshipStatus', {
      is: (usCitizenshipStatus) => {
        return usCitizenshipStatus === 'L';
      },
      then: () => Yup.string().required('Entry Date is required.'),
      otherwise: () => Yup.string().notRequired(),
    }),

    verificationDoc: Yup.string().when('usCitizenshipStatus', {
      is: (usCitizenshipStatus) => {
        return (
          usCitizenshipStatus === 'N' ||
          usCitizenshipStatus === 'C' ||
          usCitizenshipStatus === 'R'
        );
      },
      then: () => Yup.string().required('Entry Date is required.'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      beginDt: citizenship?.beginDt ?? null,
      endDt: citizenship?.endDt ?? null,
      immigrationDocument: citizenship?.immigrationDocument ?? '', //USCIS Document
      uscisStatusDate: citizenship?.uscisStatusDate ?? null,
      docExpirationDate: citizenship?.docExpirationDate ?? null,
      i94Number: citizenship?.i94Number ?? 'N', //Completed forty quarters of qualified work in the U.S?
      alienNumber: citizenship?.alienNumber ?? '',
      countryOfPassport: citizenship?.countryOfPassport ?? '',
      passportNumber: citizenship?.passportNumber ?? '',
      uscisStatus: citizenship?.uscisStatus ?? '', //USCIS Status
      sevisId: citizenship?.sevisId ?? '',
      entryDate: citizenship?.entryDate ?? null,
      visaNumber: citizenship?.visaNumber ?? '',
      cardNumber: citizenship?.cardNumber ?? null,
      dirtyStatus: editMode ? 'update' : 'insert',
      i40QuarterIndYN: citizenship?.i40QuarterIndYN ?? '',
      lawfulResidentFiveYearsYN: citizenship?.lawfulResidentFiveYearsYN ?? 'N', //Has this person lived in U.S as a lawful resident for a least five years?
      recordId: citizenship?.recordId ?? undefined,
      refugeeSettlementAgency: citizenship?.refugeeSettlementAgency ?? '',
      residentshipPeriodYN: citizenship?.residentshipPeriodYN ?? 'N', //Has this person lived in U.S since August 22, 1996?
      sponsoredAlienYN: citizenship?.sponsoredAlienYN ?? 'N',
      usCitizenshipStatus: citizenship?.usCitizenshipStatus ?? '',
      voterRegistration: citizenship?.voterRegistration ?? '',
      verificationDoc:
        citizenship?.lawfulPresenceVerification?.verificationSourceCode ?? '',
      providedVerificationId:
        citizenship?.lawfulPresenceVerification?.providedVerificationId ?? 0,
    },
    validationSchema,
    onSubmit: (values) => {
      onSaveCitizenship(values);
    },
  });

  const onChangeDate = (prop, date) => {
    formik.setFieldValue(prop, dayjs(date).format('YYYY-MM-DD'));
  };

  const renderForm = () => {
    switch (formik.values.usCitizenshipStatus) {
      case 'L':
        return (
          <Immigrant
            formik={formik}
            onChangeDate={onChangeDate}
            immigrationDocOpts={immigrationDocOpts}
            countryOfOriginOpts={countryOfOriginOpts}
            uscisStatusOpts={uscisStatusOpts}
            refugeeSmtAgencyOpts={refugeeSmtAgencyOpts}
          />
        );
      case 'N':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="verificationDoc"
                  label="Verification Type"
                  formik={formik}
                  required
                >
                  <Dropdown
                    placeholder="Select citizenship Doc Type"
                    options={immigrationDocOpts}
                  />
                </Field>
              </Grid>
            </Grid>
            {age > 16 && (
              <NaturalizedCitizen formik={formik} onChangeDate={onChangeDate} />
            )}
          </>
        );

      case 'I':
        return <UndocImmigrant formik={formik} />;
      case 'R':
        return (
          <Stack>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ mb: '1rem' }}>
                  <Field
                    name="beginDt"
                    label="Begin Date"
                    isDate
                    formik={formik}
                    required
                  >
                    <DatePicker />
                  </Field>
                </Grid>
                <Grid item xs={6} sx={{ mb: '1rem' }}>
                  <Field name="endDt" isDate formik={formik} label="End Date">
                    <DatePicker />
                  </Field>
                </Grid>
              </Grid>
            </FormGroup>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="verificationDoc"
                    label="Verification Type"
                    formik={formik}
                    required
                  >
                    <Dropdown
                      placeholder="Select citizenship Doc Type"
                      options={immigrationDocOpts}
                      // {...formik.getFieldProps('usCitizenshipStatus')}
                      // onChange={(e) => {
                      //   formik.handleChange(e);
                      //   formik.resetForm();
                      // }}
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormGroup>
          </Stack>
        );
      case 'C':
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="beginDt"
                  isDate
                  formik={formik}
                  label="Begin Date"
                  required
                >
                  <DatePicker id="beginDt" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="endDt" isDate formik={formik} label="End Date">
                  <DatePicker />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="verificationDoc"
                  label="Verification Type"
                  formik={formik}
                  required
                >
                  <Dropdown
                    placeholder="Select citizenship Doc Type"
                    options={immigrationDocOpts}
                    // {...formik.getFieldProps('usCitizenshipStatus')}
                    // onChange={(e) => {
                    //   formik.handleChange(e);
                    //   formik.resetForm();
                    // }}
                  />
                </Field>
              </Grid>
            </Grid>
            {age > 16 && <UsCitizen formik={formik} age={age} />}
          </>
        );

      default:
        return;
    }
  };

  return (
    <Dialog
      open={open}
      onSubmit={formik.handleSubmit}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      component="form"
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '80vw',
            height: '80vh',
          },
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h4">
          {editMode ? 'Edit  Citizenship' : 'Add Citizenship'}/Immigration
          Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb="1rem">
          <Grid item xs={6}>
            <Field name="usCitizenshipStatus" formik={formik}>
              <Dropdown
                placeholder="Select citizenship"
                options={citizenshipOpts}
                // {...formik.getFieldProps('usCitizenshipStatus')}
                // onChange={(e) => {
                //   formik.handleChange(e);
                //   formik.resetForm();
                // }}
              />
            </Field>
          </Grid>
        </Grid>
        {renderForm()}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Discard
        </Button>
        <Button
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          onClick={formik.handleSubmit}
          variant="contained"
        >
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CitizenshipForm.propTypes = {
  editMode: PropTypes.bool,
};

CitizenshipForm.defaultProps = {
  editMode: false,
};

export default CitizenshipForm;
