import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid,
  Stack,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ActionButtons from '../../../components/ActionButtons';
import PregnancyModal from '../PregnancyModal';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import {
  getPregnancy,
  updatePregnancy,
} from '../../../utils/services/PregnancyInfo/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';

import useMount from '../../../hooks/useMount';
import useLoader from '../../../hooks/useLoader';
import DeleteConfiramation from '../../MedicareDetails/DeleteConfiramation';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import useLookup from '../../../hooks/useLookup';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/pregnancy-stages.svg';

function PregnancyDetails({ selected, caseId }) {
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { lkpRaw } = useLookup('Pregnancy');
  const [open, setOpen] = useState(false);
  const [pregnancyData, setPregnancyData] = useState([]);
  const { individualId } = selected;
  const [showLoader, hideLoader] = useLoader();
  const [editOpen, setEditOpen] = useState(false);
  const [pregnancyEdit, setPregnancyEdit] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const pregnancyEditData = (data) => {
    setEditOpen(true);
    setPregnancyEdit(data);
  };
  const deletePopup = async (rowData) => {
    setSelectedData(rowData);
    setOpenDeleteDialog(true);
  };
  useMount(() => {
    getUploadFiles();
  });
  const { getOptions } = useLookup('Pregnancy');
  const { verificationOpts } = useMemo(() => {
    return {
      verificationOpts: getOptions('CLPREGVERCD'),
    };
  });
  const renderMemberName = (id) => {
    return houseMembers.find(({ individualId }) => individualId == id);
  };
  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };
  const columns = [
    {
      field: 'pregnancyStartDate',
      headerName: 'Pregnancy Start Date',
      width: 90,
      width: 230,
    },
    {
      field: 'pregnancyDueDate',
      headerName: 'Pregnancy Due Date',
      width: 210,
    },
    {
      field: 'numberExpected',
      headerName: 'Number of Unborn(s)',
      width: 220,
    },
    {
      field: 'relatedIndividualId',
      headerName: 'Parent',
      width: 100,
      renderCell: (params) => {
        const individualId = params.row.relatedIndividualId;
        let member = renderMemberName(individualId);
        if (member && member.personName) {
          const { personName } = member;
          return personName.firstName + ' ' + personName.lastName;
        } else {
          return '';
        }
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => pregnancyEditData(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];
  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };
  const handleDeleteProgram = () => {
    deletePregnancy();
  };
  const togglePregnancy = () => {
    setOpen(false);
    setPregnancyEdit(false);
    setEditOpen(false);
  };
  useEffect(() => {
    if (individualId) {
      getPregnancyInfo();
    }
  }, [individualId]);

  const getPregnancyInfo = async () => {
    try {
      showLoader();
      const res = await getPregnancy(individualId);
      if (res.status === 200) {
        setPregnancyData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const deletePregnancy = async () => {
    const payload = {
      ...selectedData,
      dirtyStatus: 'delete',
    };
    try {
      showLoader();
      const res = await updatePregnancy(payload, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('Successfully Deleted the Pregnancy Details');
        getPregnancyInfo();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <div>
      <Grid mt={2}>
        <Stack direction={'row'} mb={1} justifyContent="space-between">
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload files
          </Button>

          <Button onClick={() => setOpen(true)}>+ Add Pregnancy Details</Button>
        </Stack>
        <Box sx={{ display: 'grid' }}>
          <DataGrid
            rows={pregnancyData}
            columns={columns}
            getRowId={(row) => row?.recordId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '30vh',
              },
              '& .MuiDataGrid-overlayWrapperInner': {
                minHeight: '30vh',
              },
              minHeight: '30vh',
              backgroundColor: 'white',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '18px',
                fontWeight: '700',
                padding: '0px 10px',
                color: '#000000',
              },
              '& .MuiDataGrid-row': {
                fontSize: '16px',
                color: 'black',
                padding: '0px 10px',
                textAlign: 'center',
              },
            }}
            slots={{
              noRowsOverlay: () => (
                <EmptyState
                  header="Currently No Records Available"
                  description="Please Add Pregnancy Details"
                  icon={
                    <img
                      src={NoRecord}
                      style={{
                        height: '160px',
                        width: '250px',
                      }}
                    />
                  }
                />
              ),
            }}
          />
        </Box>
      </Grid>
      <Dialog maxWidth={'lg'} open={open} onClose={togglePregnancy}>
        <DialogTitle>Add Pregnancy Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={togglePregnancy}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <PregnancyModal
            selected={selected}
            individualId={individualId}
            createPregnancy
            togglePregnancy={togglePregnancy}
            getPregnancyInfo={getPregnancyInfo}
            verificationOpts={verificationOpts}
          />
        </DialogContent>
      </Dialog>

      <Dialog maxWidth={'lg'} open={editOpen} onClose={togglePregnancy}>
        <DialogTitle>Add Pregnancy Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={togglePregnancy}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <PregnancyModal
            selected={selected}
            individualId={individualId}
            pregnancyEdit={pregnancyEdit}
            togglePregnancy={togglePregnancy}
            getPregnancyInfo={getPregnancyInfo}
            verificationOpts={verificationOpts}
          />
        </DialogContent>
      </Dialog>
      <DeleteConfiramation
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        handleDeleteProgram={handleDeleteProgram}
      />
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}

export default PregnancyDetails;
