import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../PageHeader';
import { Stack } from '@mui/system';
import { noop } from 'lodash';
import { Box, Button } from '@mui/material';
import styles from './index.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { navigatePageName } from '../../store/selectors/caseSelector';
import { setNavigatePageName } from '../../store/slices/caseSlice';
import usePageNav from '../../hooks/usePageNav';
import ShortCut from '../ShortCut';
function PageContainer({
  title,
  showHeader,
  showNavButton,
  disableBack,
  disableNext,
  onBack,
  onNext,
  children,
  navProps,
  toVerify,
  showNavigations,
  validateBeforeNextPage,
  validateBeforePrevPage,
}) {
  const { navigations } = usePageNav();
  const dispatch = useDispatch();
  const selector = useSelector;
  const {
    toNextPage = onNext,
    toPrevPage = onBack,
    isFirstPage,
    isLastPage,
  } = navProps;

  const handleNext = async () => {
    if (validateBeforeNextPage) {
      const flag = await validateBeforeNextPage();
      if (flag) {
        toNextPage();
      }
    } else {
      toNextPage();
    }
  };

  const handleBack = () => {
    if (validateBeforePrevPage) {
      const flag = validateBeforeNextPage();
      if (flag) {
        toPrevPage();
      }
    } else {
      toPrevPage();
    }
  };
  const handleVerify = () => {
    dispatch(setNavigatePageName(''));
    navigations.toVerificationCheckbox();
  };

  return (
    <div className={styles.pageContainer}>
      {showHeader && (
        <PageHeader
          disableBack={disableBack || isFirstPage}
          disableNext={disableNext || isLastPage}
          title={title}
          onNext={handleNext}
          onBack={handleBack}
          showNavigations={showNavigations}
        />
      )}

      {children}
      {/* <ShortCut /> */}
      {toVerify === selector(navigatePageName) ? (
        <Box styles={{ width: '100%' }}>
          <Button
            style={{
              display: 'inline-block',
              float: 'right',
              margin: '1rem 0',
            }}
            variant="contained"
            onClick={handleVerify}
          >
            To Verify
          </Button>
        </Box>
      ) : (
        showNavButton && (
          <Stack direction={'row'} justifyContent={'flex-end'} mt={2}>
            <Stack direction={'row'} spacing={1}>
              <Button
                disabled={disableBack || isFirstPage}
                onClick={handleBack}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                disabled={disableNext || isLastPage}
                onClick={handleNext}
                variant="contained"
              >
                Next
              </Button>
            </Stack>
          </Stack>
        )
      )}
    </div>
  );
}

PageContainer.propTypes = {
  title: PropTypes.string,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  showHeader: PropTypes.bool,
  showNavigations: PropTypes.bool,
  showNavButton: PropTypes.bool,
  children: PropTypes.node,
  navProps: PropTypes.shape({}),
  validateBeforeNextPage: PropTypes.func,
  validateBeforePrevPage: PropTypes.func,
};

PageContainer.defaultProps = {
  title: '',
  onNext: noop,
  onBack: noop,
  showHeader: true,
  showNavButton: true,
  showNavigations: true,
  children: '',
  navProps: {
    isFirstPage: false,
    isLastPage: false,
  },
  validateBeforeNextPage: null,
  validateBeforePrevPage: null,
};

export default PageContainer;
