import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import TabularView from '../../../components/TabularView';
import SummaryContent from '../SummaryContent';

function CaseInfo({ caseId, headOfHouse, getLkpMatch }) {
  const columns = useMemo(() => {
    const {
      dob = '',
      gender = '',
      maritalStatus = '',
      HOHPin = '',
      race = '',
    } = headOfHouse;

    return [
      {
        headerName: 'Case ID',
        value: caseId,
      },
      {
        headerName: 'DOB',
        value: dob,
      },
      {
        headerName: 'Gender',
        value: getLkpMatch('Gender', gender),
      },
      {
        headerName: 'Marital Status',
        value:
          maritalStatus?.marriedYN === 'Y' || maritalStatus?.marriedYN === 'N'
            ? getLkpMatch('YesNo', maritalStatus?.marriedYN)
            : getLkpMatch('MartialStatus', maritalStatus?.marriedYN),
      },
      {
        headerName: 'HOH PIN No.',
        value: HOHPin,
      },
      {
        headerName: 'Race',
        value: getLkpMatch('Race', race?.primaryRace),
      },
    ];
  }, [getLkpMatch, headOfHouse, caseId]);

  const hohName = useMemo(() => {
    const { personName = {} } = headOfHouse;
    const { firstName = '', lastName = '' } = personName;
    return `${firstName} ${lastName}`;
  }, [headOfHouse]);

  return (
    <SummaryContent title="Case Information">
      <Stack direction="row" spacing={4}>
        <Stack alignItems="center" spacing={1}>
          <Avatar sx={{ width: 100, height: 100 }} />
          <Typography variant="h6">{hohName}</Typography>
          <Typography color="var(--primary-light)" variant="h5">
            HOH
          </Typography>
        </Stack>
        <Box>
          <TabularView
            sx={{
              table: {
                width: 'auto',
              },
              td: {
                border: 0,
                padding: '0 1.25rem 0.5rem 0',
              },
            }}
            headerAxis="y"
            columns={columns}
            headerComponent={{ variant: 'h6', color: 'var(--grey-400)' }}
            cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
          />
        </Box>
      </Stack>
    </SummaryContent>
  );
}

CaseInfo.propTypes = {
  headOfHouse: PropTypes.shape({}),
};

CaseInfo.defaultProps = {
  headOfHouse: {},
};

export default CaseInfo;
