import React, { useMemo, useState } from 'react';
import SelectableView from '../../../components/SelectableView';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import { useSelector } from 'react-redux';
import MemberMenuButton from '../../../components/SelectableView/MemberMenuButton';
import PageContainer from '../../../components/PageContainer';
import usePageNav from '../../../hooks/usePageNav';
import usePage from '../../../hooks/usePage';
import MedicalContent from './MedicalContent';
import { select } from 'redux-saga/effects';

export default function MedicalExpenses({ id }) {
  const [assetLoader, setAssetLoader] = useState(false);
  const { navProps } = usePage(id);
  const { navigations } = usePageNav();
  const { houseMembers } = useSelector(selectHouseMembers);

  const { houseHoldMembers } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  }, [houseMembers]);

  const onNext = () => {
    navigations.toProgramTimeLimit();
  };
  const onBack = () => {
    navigations.toDomesticViolence();
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Medical Expenses"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        loading={assetLoader}
        loadingContent={assetLoader}
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <MedicalContent />
      </SelectableView>
    </PageContainer>
  );
}
