import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { maxLength } from '../../../utils/normalizers';

function Immigrant({
  formik,
  onChangeDate,
  immigrationDocOpts,
  countryOfOriginOpts,
  uscisStatusOpts,
  yesNoOpts,
  refugeeSmtAgencyOpts,
}) {
  return (
    <FormSection label="Immigrant">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            name="beginDt"
            isDate
            formik={formik}
            label="Begin Date"
            required
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name="endDt" isDate formik={formik} label="End Date">
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            // name="immigrationDocument"
            name="verificationDoc"
            formik={formik}
            label="USCIS Document"
            required
          >
            <Dropdown
              options={immigrationDocOpts}
              placeholder="USCIS Document"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="uscisStatusDate"
            formik={formik}
            label="USICIS Status Date"
            required
            isDate
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="docExpirationDate"
            formik={formik}
            label="Document Expiration Date"
            isDate
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="i94Number"
            formik={formik}
            label="i-94 Number"
            normalizers={[maxLength(11)]}
          >
            <TextField placeholder="i-94 Number" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="alienNumber"
            formik={formik}
            label="Alient Number"
            required
            normalizers={[maxLength(9)]}
          >
            <TextField placeholder="Alient Number" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="countryOfPassport"
            formik={formik}
            label="Country of Passport"
          >
            <Dropdown
              options={countryOfOriginOpts}
              placeholder="Select country"
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="passportNumber"
            formik={formik}
            label="Passport Number"
            normalizers={[maxLength(12)]}
          >
            <TextField placeholder="Passport Number" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="uscisStatus"
            formik={formik}
            label="USCIS Status"
            required
          >
            <Dropdown placeholder="USCIS Status" options={uscisStatusOpts} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="sevisId"
            formik={formik}
            label="Sevis ID"
            normalizers={[maxLength(11)]}
          >
            <TextField placeholder="Sevis ID" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            required
            name="entryDate"
            formik={formik}
            label="Date of Entry"
            isDate
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="visaNumber"
            formik={formik}
            label="Visa Number"
            normalizers={[maxLength(16)]}
          >
            <TextField placeholder="Visa Number" />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" mt={1} style={{ fontWeight: 'bold' }}>
            Resident Information
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            Has this person lived in U.S since August 22, 1996?
          </Typography>
          <RadioGroup
            row
            aria-labelledby="person-lived-long"
            name="row-radio-buttons-group-long"
            {...formik.getFieldProps('residentshipPeriodYN')}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            Has this person lived in U.S as a lawful resident for a least five
            years?
          </Typography>
          <RadioGroup
            row
            aria-labelledby="person-lived-short"
            name="row-radio-buttons-group-short"
            {...formik.getFieldProps('lawfulResidentFiveYearsYN')}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" mt={1} style={{ fontWeight: 'bold' }}>
            Additional Information
          </Typography>
          <Divider />
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('sponsoredAlienYN')}
                  checked={formik.values.sponsoredAlienYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      'sponsoredAlienYN',
                      checked ? 'Y' : 'N'
                    );
                  }}
                />
              }
              label="Sponsored Alien"
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Field name="refugeeSettlementAgency" formik={formik}>
            <Dropdown
              options={refugeeSmtAgencyOpts}
              placeholder="Refugee Resettlement Agency"
            />
          </Field>
        </Grid>
      </Grid>
    </FormSection>
  );
}

Immigrant.propTypes = {};

export default Immigrant;
