import React from 'react';
import PageContainer from '../../components/PageContainer';
import HistorryDetails from './EligibilityHistDet';

export default function EligibilityHistory() {
  return (
    <PageContainer title="Eligibility History">
      <HistorryDetails />
    </PageContainer>
  );
}
