import {
  GET_ABAWD_TABLEDATA,
  GET_CHILDUNDERONE_TABLEDATA,
  GET_TDAP1_TABLEDATA,
  GET_TIMEONASSISTANCE,
  POST_TIMELIMIT_RECORD,
} from './urls';
import { format } from '../../helpers/index';
import HttpClient from '../../HttpClient';
import { programTimeLimitPostApi } from './payLoads';

export const getAbardTableData = (caseId, individualId) => {
  return HttpClient.get(format(GET_ABAWD_TABLEDATA, individualId, caseId));
};

export const getChildUnderone = (caseId, individualId) => {
  return HttpClient.get(
    format(GET_CHILDUNDERONE_TABLEDATA, individualId, caseId)
  );
};

export const getTdap1 = (caseId, individualId) => {
  return HttpClient.get(format(GET_TDAP1_TABLEDATA, individualId, caseId));
};

export const getTimeOneAssistance = (caseId, individualId) => {
  return HttpClient.get(format(GET_TIMEONASSISTANCE, individualId, caseId));
};

export const postTimeLimitRecord = (
  payLoadData,
  caseId,
  individualId,
  dirtyStatus
) => {
  return HttpClient.post(
    format(POST_TIMELIMIT_RECORD),
    programTimeLimitPostApi(dirtyStatus, payLoadData, individualId, caseId)
  );
};
