import { Box, Grid, Paper, Typography, IconButton } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import TabularView from '../../../components/TabularView';
import EmptyState from '../../../components/EmptyState';
import useLookup from '../../../hooks/useLookup';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';
import {
  getSearchData,
  pdfDownload,
} from '../../../utils/services/correspondence/apiRequest';
import usePageNav from '../../../hooks/usePageNav';
import GetAppIcon from '@mui/icons-material/GetApp';
import BackHandIcon from '@mui/icons-material/BackHand';
import { getVerifyCheck } from '../../../utils/services/VerificationChecklist/apiRequests';
import { formatPayMonth, getFullName } from '../../../utils/helpers';
import dayjs from 'dayjs';
import { get, map } from 'lodash';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import ReasonDialog from '../../Correspondence/ReasonDialog';
import NoRecord from '../../../assets/icons/checklist-cuate.svg';

export default function VerificationNotices({ StyledPaper }) {
  const { lkpFlatten, getLkpMatch, getOptions, lkpRaw, islkpFailed } =
    useLookup('Notices');

  const [showLoader, hideLoader] = useLoader();
  const [searchData, setSearchData] = useState([]);
  const [verifyData, setVerifyData] = useState([]);

  const { caseId } = usePageNav();

  const columnsNotices = [
    { field: 'caseId', headerName: 'Case ID', flex: 1 },
    {
      field: 'noticeTypeCd',
      headerName: 'Notice Type',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'triggerDt',
      headerName: 'Trigger Date',
      flex: 1,
      sortable: false,
      align: 'end',
      disableColumnMenu: true,
    },
    {
      field: 'programCd',
      headerName: 'Program Name',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: 'spprsnReason',
      headerName: 'Reason',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'pdf_download',
      headerName: 'PDF',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      // align: 'left',
      renderCell: (params) => (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => downloadPdf(params.row.printSmryId)}
          >
            <Typography style={{ color: '#7ABBEB' }}>Download</Typography>
            <IconButton aria-label="pdf_download">
              <GetAppIcon style={{ color: '#7ABBEB' }} />
            </IconButton>
          </Box>
        </>
      ),
    },
  ];

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Person Name',
        getValue({ firstName, lastName }) {
          return getFullName(firstName, lastName);
        },
      },
      {
        headerName: 'Program',
        getValue({ programName }) {
          return getLkpMatch('Program', programName);
        },
      },
      {
        headerName: 'Pay Month',
        getValue({ payMonth }) {
          return formatPayMonth(payMonth, 'MM/YYYY');
        },
      },
      {
        headerName: 'Verification Name',
        getValue({ verificationName }) {
          return getLkpMatch('VerificationNames1052', verificationName);
        },
      },
      {
        headerName: 'Due Date',
        getValue({ dueDate }) {
          return dayjs(dueDate).format('MM/DD/YYYY');
        },
      },
    ];
  }, [getLkpMatch]);

  const downloadPdf = async (pdfId) => {
    if (!pdfId) {
      toast.error('No PDF URL available');
      return;
    }

    try {
      showLoader();
      const response = await pdfDownload(pdfId);
      // if (response.status === 200 && response.data) {
      //   const blob = new Blob([response.data], { type: 'application/pdf' });
      //   const downloadUrl = window.URL.createObjectURL(blob);
      //   const link = document.createElement('a');
      //   link.href = downloadUrl;
      //   link.setAttribute('download', `downloaded_pdf_${pdfId}.pdf`);
      //   document.body.appendChild(link);
      //   link.click();
      //   document.body.removeChild(link);
      //   window.URL.revokeObjectURL(downloadUrl);
      // }
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error while downloading the PDF:', error);
      toast.error('Failed to download PDF');
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (caseId) {
      getNotices();
      getVerification();
    }
  }, [caseId]);

  const getNotices = async () => {
    let paramsUrl = `?fromDate=${''}&currentDate=${''}`;
    try {
      showLoader();
      const res = await getSearchData(caseId, paramsUrl);
      if (res.status === 200) {
        setSearchData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getVerification = async () => {
    try {
      showLoader();
      const res = await getVerifyCheck(caseId);
      if (res.status === 200) {
        setVerifyData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const rows = useMemo(
    () =>
      map(searchData, (item, index) => {
        return {
          ...item.ccPrintSummary,
          id: index,
          caseId: caseId ?? '',
          noticeTypeCd:
            lkpFlatten.NoticeType[get(item, 'ccPrintSummary.noticeTypeCd', '')],
          programCd:
            lkpFlatten.Program[get(item, 'ccPrintSummary.programCd', '')],
          triggerDt: dayjs(get(item, 'ccPrintSummary.triggerDt', '')).format(
            'YYYY-MM-DD'
          ),
        };
      }),
    [searchData]
  );

  return (
    <>
      <Box backgroundColor="#d9d9d9" padding="1rem" borderRadius="5px">
        <StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h4">Verification Checklist</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                <TabularView
                  sx={{
                    table: {
                      width: '100%',
                    },
                  }}
                  headerComponent={{ variant: 'subtitle1' }}
                  component={Paper}
                  columns={columns}
                  data={verifyData ?? {}}
                  autoHeight
                  slots={{
                    noRowsOverlay: () => (
                      <EmptyState
                        header="Currently No Records Available"
                        icon={
                          <img
                            src={NoRecord}
                            style={{
                              height: '160px',
                              width: '250px',
                            }}
                          />
                        }
                      />
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h4">Notices & Letters</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ width: '100%', display: 'grid' }}>
                <DataGrid
                  autoHeight
                  rows={rows}
                  columns={columnsNotices}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontSize: '15px',
                      fontWeight: '600',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minHeight: '50vh',
                    },
                    '& .MuiDataGrid-overlayWrapperInner': {
                      minHeight: '50vh',
                    },
                    minHeight: '50vh',
                  }}
                  slots={{
                    noRowsOverlay: () => (
                      <EmptyState
                        header="Currently No Records Available"
                        icon={
                          <img
                            src={NoRecord}
                            style={{
                              height: '160px',
                              width: '250px',
                            }}
                          />
                        }
                      />
                    ),
                  }}
                  loading={false}
                  rowsPerPageOptions={[5, 10, 15]}
                  checkboxSelection={false}
                  disableRowSelectionOnClick={true}
                  disableColumnMenu={true}
                />
              </Box>
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
    </>
  );
}
