import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import {
  clientMgmtItem,
  getOffboardedWorkers,
  searchProgram,
  workersWorkItem,
} from '../../utils/services/apiRequests';
import useLoader from '../../hooks/useLoader';
import { showErrorToast } from '../../utils/services/toast';
import dayjs from 'dayjs';
import useLookup from '../../hooks/useLookup';
import { entries, filter, find, map } from 'lodash';
import AssignWorkers from './AssignWorkers';
import TabularView from '../../components/TabularView';
import { maxLength, number } from '../../utils/normalizers';
import EmptyState from '../../components/EmptyState';
import NoRecord from '../../assets/icons/No-data-amico.svg';

function ViewAlerts(props) {
  const { getOptions, lkpFlatten } = useLookup('AlertSearch');
  const [showLoader, hideLoader] = useLoader();
  const [searchItemData, setSearchItemData] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [offboardedWorkers, setOffboardedWorkers] = useState([]);
  const [workerddOptions, setWorkerddOptions] = useState([]);
  const [tableVisible, setTableVisible] = useState(false);
  const [assign, setAssign] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const detailColumns = [
    {
      field: 'caseID',
      headerName: 'Case Id',
    },
    {
      field: 'hoh',
      headerName: 'HOH',
    },
    {
      field: 'programCd',
      headerName: 'Program',
      renderCell: (params) => lkpFlatten?.Program?.[params],
    },
    {
      field: 'programStatus',
      headerName: 'Program Status',
      renderCell: (params) => lkpFlatten?.ProgramStatus?.[params],
    },
    {
      field: 'caseStatusCd',
      headerName: 'Case Status',
      renderCell: (params) => lkpFlatten?.CaseStatusCode?.[params],
    },
    {
      field: 'applicationDt',
      headerName: 'App Date',
      renderCell: (params) => dayjs(params).format('MM-DD-YYYY'),
    },
    {
      field: 'ldssCd',
      headerName: 'LDSS',
    },
    {
      field: 'assignedTo',
      headerName: 'Assigned To',
    },
  ];

  const columns = [
    {
      headerName: 'Case Id',
      sx: { width: '90%' },
      renderCell(params, i, rootData, { setExpandRowId, isExpanded }) {
        return (
          <Box
            sx={{ width: '100%' }}
            onClick={() => setExpandRowId(params.caseId)}
          >
            {params.caseId}
          </Box>
        );
      },
    },
    {
      headerName: 'Action',
      renderCell(params) {
        return (
          <Checkbox
            checked={selectedIds.includes(params.caseId)}
            onChange={() => handleCheckboxChange(params.caseId)}
          />
        );
      },
    },
  ];

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      let updatedSelectedIds;
      if (prevSelectedIds.includes(id)) {
        updatedSelectedIds = prevSelectedIds.filter(
          (selectedId) => selectedId !== id
        );
      } else {
        updatedSelectedIds = [...prevSelectedIds, id];
      }
      setAssign(updatedSelectedIds.length > 0);
      return updatedSelectedIds;
    });
  };

  const searchWorkItemCall = async (data) => {
    const updatedData = {
      ...data,
      assignedTo:
        data?.assignedTo === '' ? formik.values.ldssCd : data?.assignedTo,
    };
    try {
      showLoader();
      const res = await searchProgram(updatedData);
      if (res.status === 200) {
        setTableVisible(true);
        const tableData = map(
          entries(res?.data?.programDataMap),
          ([caseId, benefitDetails]) => ({
            caseId,
            benefitDetails,
          })
        );
        setSearchItemData(tableData);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      programCd: null,
      programStatus: '',
      workerUniqueId: '',
      createdBeginDt: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
      createdEndDt: dayjs().format('YYYY-MM-DD'),
      caseID: null,
      hoh: '',
      programSubType: '',
      programStatusCd: null,
      applicationDt: null,
      programModeCd: '',
      rescndRsn: '',
      rescndDt: null,
      ldssCd: null,
      effectiveBeginDt: null,
      effectiveEndDt: null,
      createDt: null,
      createUserId: '',
      msnapInd: '',
      expeditedIndicator: '',
      programActionCode: '',
      paginationData: {
        resultsPerPage: 20,
        pageNumber: 0,
        totalRecords: 0,
      },
    },
    onSubmit: searchWorkItemCall,
  });

  const handleClear = () => {
    formik.handleReset();
    setTableVisible(false);
  };

  const { ProgramOpts, ProgramStsOpts } = useMemo(() => {
    return {
      DispRsnOpts: getOptions('AlertDispRsnCd'),
      AlertTypeOpts: getOptions('AlertSubType'),
      AlertOpts: getOptions('AlertType'),
      ProgramOpts: getOptions('Program'),
      aidcdOpts: getOptions('aidcd'),
      ProgramStsOpts: getOptions('ProgramStatus'),
    };
  }, [getOptions]);

  useEffect(() => {
    getDistrictOfc();
    getWorkerOptions();
    getWorkers();
  }, []);

  const getDistrictOfc = async () => {
    try {
      showLoader();
      const res = await clientMgmtItem();
      if (res.status === 200 && res.data) {
        setDistrictOptions(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getWorkers = async () => {
    try {
      showLoader();
      const res = await getOffboardedWorkers();
      if (res.status === 200) {
        setOffboardedWorkers(res?.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getWorkerOptions = async () => {
    try {
      showLoader();
      const res = await workersWorkItem();
      if (res.status === 200 && res.data) {
        setWorkerddOptions(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const workerOptions = useMemo(() => {
    const workerData = filter(
      workerddOptions,
      (item) => item.ldssDoCd === formik?.values?.doNum
    );
    return map(workerData, ({ firstName, lastName, uniqueId }) => {
      const label = `${firstName} ${lastName}`;
      return {
        label,
        value: uniqueId,
      };
    });
  }, [formik.values.doNum]);

  const offboardedOptions = useMemo(() => {
    return [
      ...map(offboardedWorkers, ({ firstName, lastName, uniqueId }) => {
        const label = `${firstName} ${lastName}`;
        return {
          label,
          value: uniqueId,
        };
      }),
      { label: 'Not Assigned', value: 'Not Assigned' },
    ];
  }, [offboardedWorkers]);

  const districtOfcOptions = useMemo(() => {
    return map(districtOptions, ({ doName, doCd }) => {
      const label = `${doName}`;
      return {
        label,
        value: doCd,
      };
    });
  }, [districtOptions]);

  return (
    <Box p="1rem" m="1rem" component={Paper}>
      <Typography mb="1rem" variant="h2">
        Case Search
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Field name="programCd" formik={formik} label="Program">
            <Dropdown options={ProgramOpts} placeholder="Program" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field
            name="caseID"
            formik={formik}
            normalizers={[number, maxLength(9)]}
            label="Case ID"
          >
            <TextField placeholder="Case ID" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="programStatusCd" formik={formik} label="Program Status">
            <Dropdown options={ProgramStsOpts} placeholder="Program Status" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="assignedTo" formik={formik} label="Offboarded Workers">
            <Dropdown
              options={offboardedOptions}
              disabled={
                formik.values.ldssCd && formik.values.doNum ? true : false
              }
              placeholder="Offboarded Workers Name"
            />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="doNum" formik={formik} label="District Office">
            <Dropdown
              options={districtOfcOptions}
              placeholder="District Office"
            />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="ldssCd" formik={formik} label="Assigned Worker">
            <Dropdown
              disabled={formik.values.assignedTo ? true : false}
              options={workerOptions}
              placeholder="Assigned Worker"
            />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field
            isDate
            name="createdBeginDt"
            formik={formik}
            label="Create Date From"
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field
            isDate
            name="createdEndDt"
            formik={formik}
            label="Create Date To"
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" spacing={1} mt={2.7}>
            <Button type="reset" onClick={handleClear} variant="outlined">
              Clear
            </Button>
            <Button
              type="submit"
              // disabled={!formik.isValid || !formik.dirty}
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Search
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Box
        mt="2rem"
        // sx={{ visibility: tableVisible === true ? 'visible' : 'hidden' }}
      >
        <Grid container sx={{ display: assign === true ? 'flex' : 'none' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '.5rem',
            }}
          >
            <Button variant="contained" onClick={handleClickOpen}>
              Assign
            </Button>
          </Grid>
        </Grid>
        <TabularView
          expandable
          data={searchItemData}
          columns={columns}
          getId={({ caseId }) => caseId}
          multiExpand
          renderHeader={(title) => (
            <Typography variant="h5" component="strong">
              {title}
            </Typography>
          )}
          renderDetails={(params) => {
            return (
              <TabularView
                sx={{
                  minHight: '50vh',
                  p: '6px',
                }}
                slots={{
                  noRowsOverlay: () => (
                    <EmptyState
                      header="Currently No Records Available"
                      description="Note: Please click Get Work Item to Get Records"
                      icon={
                        <img
                          src={NoRecord}
                          style={{
                            height: '160px',
                            width: '250px',
                          }}
                        />
                      }
                    />
                  ),
                }}
                getId={(params) => params.caseId}
                columns={detailColumns}
                renderHeader={(title) => (
                  <Typography variant="h5" component="strong">
                    {title}
                  </Typography>
                )}
                data={params.benefitDetails}
              />
            );
          }}
          sx={{
            th: {
              color: 'var(--black-900)',
            },
            td: {
              border: 0,
              padding: '6px',
            },
            tr: {
              padding: '10px ',
              ':nth-child(even)': {},
              border: '1px solid var(--grey-300)',
              padding: '10px !important',
            },
          }}
        />
      </Box>
      <AssignWorkers
        handleClose={handleClose}
        open={open}
        selectedData={selectedIds}
        searchProgram={formik.handleSubmit}
      />
    </Box>
  );
}

ViewAlerts.propTypes = {};

export default ViewAlerts;
