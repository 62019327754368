import {
  Button,
  Checkbox,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { getRedeterminationDetails } from '../../../utils/services/RedeterminationServices/requests';
import { useFormik } from 'formik';
import Field from '../../../components/Field';
import { Grid } from '@mui/material';
import IndividualDetailsTable from '../../IndividualDetails/IndividualDetailsTable';
import InitiateRedetermination from './InitiateRedetermination';
import useLookup from '../../../hooks/useLookup';
import * as Yup from 'yup';
import usePageNav from '../../../hooks/usePageNav';
import { useParams, useNavigate } from 'react-router';
import { showErrorToast } from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';
import { filter } from 'lodash';

function Redetermin() {
  const [data, setData] = useState('');
  const [open, setOpen] = useState(false);
  const { getOptions, getLkpMatch, lkpRaw, lkpFlatten } =
    useLookup('Redetermination');
  const { caseId } = usePageNav();
  const navigate = useNavigate();
  const [showLoader, hideLoader] = useLoader();
  const redeterminationDetails = async () => {
    try {
      showLoader();
      let value = formik.values.caseId;

      const res = await getRedeterminationDetails(value);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      setData([]);
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'selection',
        headerName: 'Action',
        renderCell: (params) => (
          <Checkbox
            disabled
            checked={params.row.redeterminationStatusCd === 'GE'}
            name={params.id}
          />
        ),

        flex: 1,
      },
      {
        field: 'programName',
        headerName: 'Program Name',
        renderCell: (params) =>
          getLkpMatch('ProgramCode', params?.row?.programName),
        flex: 1,
      },
      {
        field: 'redeterminationDueDate',
        headerName: 'Redetermination Due Date',
        flex: 1,
      },
      {
        field: 'redeterminationStatusDt',
        headerName: 'Redetermination Status Date',
        flex: 1,
      },
      {
        field: 'redeterminationStatusCd',
        headerName: 'Status',
        renderCell: (params) =>
          getLkpMatch(
            'RedeterminationStatus',
            params?.row?.redeterminationStatusCd
          ),

        flex: 1,
      },
    ],
    [getLkpMatch]
  );

  const validationSchema = Yup.object({
    caseId: Yup.string().required('Please enter a valid 9 digit code'),
  });
  const formik = useFormik({
    initialValues: {
      caseId: caseId ?? '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      redeterminationDetails(values);
      navigate(`/dashboard/redetermination/${values.caseId}`);
      resetForm();
    },
  });
  const handleClose = () => setOpen(false);
  const generatedCase = filter(data, {
    redeterminationStatusCd: 'GE',
  });

  return (
    <Paper onSubmit={formik.handleSubmit}>
      <Stack
        direction="row"
        sx={{ width: '100%' }}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={3}>
          <Typography variant="h4" sx={{ color: 'var(--grey-400)' }}>
            Redetermination
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={4} sx={{ mb: '2rem' }}>
            <Field name="caseId" label="Case ID" formik={formik} required>
              <TextField placeholder="Enter Case ID" />
            </Field>
          </Grid>
          <Grid item xs={3} sx={{ mt: '1.5rem', ml: '1.5rem' }}>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Go to Redetermination
            </Button>
          </Grid>
        </Grid>
      </Stack>

      {data.length > 0 && (
        <div>
          <IndividualDetailsTable
            rows={data}
            columns={columns}
            getRowId={(row) => row.redeterminationDetailId}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ mb: '1rem', mt: '1rem' }}
          >
            {generatedCase.length === 0 ? (
              <Button variant="contained" disabled>
                Initiate Redetermination
              </Button>
            ) : (
              <Button variant="contained" onClick={() => setOpen(true)}>
                Initiate Redetermination
              </Button>
            )}
          </Stack>

          <InitiateRedetermination
            open={open}
            handleClose={handleClose}
            data={data}
            getLkpMatch={getLkpMatch}
            caseId={formik.values.caseId}
            redeterminationDetails={redeterminationDetails}
          />
        </div>
      )}
    </Paper>
  );
}

export default Redetermin;
