import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import Field from '../../../components/Field';
import { Search } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { postInstitution } from '../../../utils/services/waiverAndFacility/apiRequest';
import PropTypes from 'prop-types';
import SearchIntitution from './SearchInstitution';
import { map, some } from 'lodash';
import { isDateLiesBetween } from '../../../utils/helpers';
import dayjs from 'dayjs';

var range = [];
export default function AddInstitution({
  caseId,
  currentUserId,
  toggleClose,
  getOptions,
  waiverAPI,
  createNew,
  editMode,
  instituteData,
  rowsData,
}) {
  const [institutionSearch, setInstitutionSearch] = useState(false);
  const [addProvider, setAddProvider] = useState(false);
  const [providerDetails, setProviderDetails] = useState('');

  const validationSchema = Yup.object({
    institutionType: Yup.string().required('Please select institution type'),
    providerName: Yup.string().required('Please enter provider name'),
    entryDate: Yup.string().required('Please enter entry date'),
    levelOfCare: Yup.string().when('institutionType', {
      is: (institutionType) => {
        return institutionType === 'PH' || institutionType === 'NH';
      },
      then: () => Yup.string().required('Please select level Of care'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      stateFacility: instituteData?.outOfStateBenefit ?? 'N',
      institutionType: instituteData?.institutionType ?? '',
      vendorid: '',
      instituteId: instituteData?.instituteId ?? 0,
      providerName: instituteData?.providerName ?? '',
      providerId: instituteData?.providerId ?? '',
      mmisprovider: '',
      entryDate: instituteData?.entryDate ?? null,
      exitDate: instituteData?.exitDate ?? null,
      levelOfCare: instituteData?.levelOfCare ?? '',
      paymentAuthDate: null,
      medicareDenialDate: null,
      returnHome: instituteData?.returnHomeIn6MonthsYN ?? 'N',
    },
    validationSchema,
    onSubmit: (value) => {
      const isDatesAreInValid = some(
        rowsData,
        ({ entryDate = null, exitDate = null, instituteId = null }) => {
          return (
            dateLiesBetween(
              entryDate,
              exitDate,
              formik.values.entryDate,
              formik.values.instituteId,
              instituteId
            ) ||
            dateLiesBetween(
              entryDate,
              exitDate,
              formik.values.exitDate,
              formik.values.instituteId,
              instituteId
            )
          );
        }
      );
      if (isDatesAreInValid) {
        showErrorToast(
          'Dates cant be in between the Already Existing date period. Please Select Different Dates.'
        );
      } else {
        saveInstitution(value);
      }
    },
  });

  function dateLiesBetween(
    startDate,
    endDate,
    date,
    selectRecordId,
    existingRecordId
  ) {
    if (!date) {
      return false;
    }
    if (selectRecordId === existingRecordId) {
      return false;
    }
    return dayjs(date).isBetween(startDate, endDate, 'day', '[]');
  }

  const saveInstitution = async (institute) => {
    const addPayload = {
      dirtyStatus: createNew ? 'insert' : 'update',
      institutions: [
        {
          authorizationDate: null,
          dirtyStatus: createNew ? 'insert' : 'update',
          entryDate: institute.entryDate,
          exitDate: institute.exitDate,
          instituteId: institute.instituteId,
          institutionType: institute.institutionType,
          levelOfCare: institute.levelOfCare,
          medicareCertificationCode: '',
          medicareDenialDate: null,
          outOfStateBenefit: institute.stateFacility,
          providerId: institute.providerId,
          providerName: institute.providerName,
          returnHomeIn6MonthsYN: institute.returnHome,
        },
      ],
      institutionalAdditionalInfo: [],
      waivers: [],
    };
    try {
      showLoader();
      const res = await postInstitution(caseId, currentUserId, addPayload);
      if (res.status === 200) {
        createNew
          ? showSuccessToast('created Sucessfully')
          : showSuccessToast('Data updated Sucessfully');
        toggleClose();
        waiverAPI(currentUserId);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const { institutionOpts, levelofCareOpts } = useMemo(() => {
    return {
      institutionOpts: getOptions('InstitutionType'),
      levelofCareOpts: getOptions('LevelAuth'),
    };
  }, [getOptions]);

  const searchIntitution = () => {
    setInstitutionSearch(true);
  };

  const searchProvider = () => {
    setInstitutionSearch(true);
  };
  const toggleInstitution = () => {
    setInstitutionSearch(false);
  };
  const toggleProvider = () => {
    setAddProvider(false);
  };

  const selectedValue = (data) => {
    formik.setFieldValue('providerName', data.vendorName);
    formik.setFieldValue('providerId', data.vendorId);
    toggleInstitution();
  };

  // const datesToBeDisabled = useMemo(
  //   () =>
  //     map(
  //       rowsData,
  //       ({ entryDate = null, exitDate = null, instituteId = null }) =>
  //         exitDate !== '9999-12-31' && {
  //           instituteId,
  //           entryDate,
  //           exitDate,
  //         }
  //     ),
  //   [rowsData]
  // );

  return (
    <Box padding={2}>
      <Dialog
        maxWidth={'xl'}
        open={institutionSearch}
        onClose={toggleInstitution}
      >
        <SearchIntitution
          setProviderDetails={setProviderDetails}
          selectedValue={selectedValue}
          toggleInstitution={toggleInstitution}
        />
      </Dialog>
      <Dialog open={addProvider} onClose={toggleProvider}></Dialog>

      <Stack
        mt={2}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid container alignItems={'center'}>
          <Typography color={'var(--grey-400)'} variant="h4">
            Is it a out of state facility
          </Typography>

          <RadioGroup
            row
            {...formik.getFieldProps('stateFacility')}
            sx={{ marginLeft: 2 }}
          >
            <FormControlLabel value={'Y'} control={<Radio />} label="Yes" />
            <FormControlLabel value={'N'} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>

        {!formik.values.institutionType ||
        formik.values.institutionType == 'NH' ? (
          ''
        ) : (
          <Tooltip title="Add new institution" placement="left">
            <IconButton onClick={searchIntitution}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <FormSection label={'Institution Details'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              name="institutionType"
              formik={formik}
              label="Institution Type"
              required
            >
              <Dropdown
                options={institutionOpts}
                placeholder="Select Institution Type"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="vendorid" formik={formik} label="Vendor ID">
              <TextField placeholder="Enter vendor ID" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="providerName"
              formik={formik}
              label="Provider Name"
              required
            >
              <TextField
                placeholder="Provider Name"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchProvider}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="mmisprovider" formik={formik} label="MMIS Provider ID">
              <TextField placeholder="Enter MMIS Provider ID" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              isDate
              name="entryDate"
              formik={formik}
              label="Entry Date"
              required
            >
              <DatePicker
              // shouldDisableDate={(date) => {
              //   return some(
              //     datesToBeDisabled,
              //     ({ entryDate, exitDate, instituteId }) => {
              //       if (instituteId !== instituteData?.instituteId) {
              //         return isDateLiesBetween(entryDate, exitDate, date);
              //       }
              //     }
              //   );
              // }}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="exitDate" isDate formik={formik} label={'Leave Date'}>
              <DatePicker
              // shouldDisableDate={(date) => {
              //   return some(
              //     datesToBeDisabled,
              //     ({ entryDate, exitDate, instituteId }) => {
              //       if (instituteId !== instituteData?.instituteId) {
              //         return isDateLiesBetween(entryDate, exitDate, date);
              //       }
              //     }
              //   );
              // }}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="levelOfCare"
              formik={formik}
              label="Level Of Care"
              required={formik.errors.levelOfCare}
            >
              <Dropdown
                options={levelofCareOpts}
                placeholder="Select Level Of Care"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              isDate
              name="paymentAuthDate"
              formik={formik}
              label={'Payment Authorization Date'}
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              isDate
              name="medicareDenialDate"
              formik={formik}
              label={'Medicare Denial Date'}
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('returnHome')}
                  checked={formik.values.returnHome === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('returnHome', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Return Home In 6 Month"
            />
          </Grid>
        </Grid>
      </FormSection>
      <Stack direction={'row'} justifyContent={'flex-end'} mt={1} spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button
          variant="contained"
          disabled={!createNew && !formik.dirty}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save & close
        </Button>
      </Stack>
    </Box>
  );
}
AddInstitution.propTypes = {
  createNew: PropTypes.bool,
  editMode: PropTypes.bool,
};

AddInstitution.defaultProps = {
  createNew: false,
  editMode: false,
};
