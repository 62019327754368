import React, { useEffect, useState } from 'react';
import useTabs from '../../hooks/useTabs';
import TabularView from '../../components/TabularView';
import { Box, Button, Grid, IconButton, Paper, Stack } from '@mui/material';
import PageContainer from '../../components/PageContainer';
import useLoader from '../../hooks/useLoader';
import { showErrorToast } from '../../utils/services/toast';
import {
  getCapacityApi,
  getCapacityList,
} from '../../utils/services/LDSSCapacity/apiRequest';
import { useFormik } from 'formik';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';
import { numbersToDate } from '../../utils/helpers';
import FormSection from '../../components/FormSection';

export default function LDSSCapacity() {
  const [showLoader, hideLoader] = useLoader();
  const [ldssSelect, setLdssSelect] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    getLDSS();
  }, []);

  const getLDSS = async () => {
    try {
      showLoader();
      const res = await getCapacityApi();
      if (res.status === 200) {
        setLdssSelect(res.data);
      }
    } catch (error) {
      showErrorToast(error);
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      ldss: '',
    },
    onSubmit: ({ ldss }) => {
      console.log(ldss);
      capacityList(ldss);
    },
  });

  const capacityList = async (id) => {
    try {
      showLoader();
      const res = await getCapacityList(id);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const viewData = (data) => {
    console.log(data);
  };

  const columns = [
    {
      headerName: 'LDSS',
      getValue({ ldssDoCd = {} }) {
        return `${ldssDoCd} `;
      },
    },
    {
      headerName: 'Month and Year',
      getValue({ monthYear = {} }) {
        return `${dayjs(numbersToDate(monthYear)).format('MM/YY')} `;
      },
    },
    {
      headerName: 'Time Length',
      getValue({ timeLength = {} }) {
        return `${timeLength} `;
      },
    },
    {
      headerName: 'Total Hours',
      getValue({ totalHours = {} }) {
        return `${totalHours} `;
      },
    },
    {
      headerName: 'Worker Count',
      getValue({ workerCount = {} }) {
        return `${workerCount} `;
      },
    },
    {
      headerName: 'Action',
      renderCell: (params) => (
        <IconButton onClick={() => viewData(params)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <PageContainer title="Available Time Slots">
      <Box bgcolor="white" borderRadius={1}>
        <FormSection>
          <Stack direction="row" spacing={2} mb={2}>
            <Grid xs={6}>
              <Field name="ldss" formik={formik}>
                <Dropdown
                  options={ldssSelect}
                  mapProps={['districtOfficeName', 'districtOfficeCode']}
                />
                {/* <Autocomplete /> */}
              </Field>
            </Grid>
            <Button onClick={formik.handleSubmit} variant="contained">
              Submit
            </Button>
          </Stack>
          <TabularView
            columns={columns}
            data={data}
            renderHeader={(title) => <strong>{title}</strong>}
          />
        </FormSection>
      </Box>
    </PageContainer>
  );
}
