import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  contactInfoPayload,
  demographicPayload,
  employerDetailsPayload,
  otherIncomePayload,
} from './apiPayload';
import {
  GET_CONTACTINFO,
  GET_COURTORDER,
  GET_DEMOGRAPHIC,
  GET_EMPLOYERDET,
  POST_DEMOGRAPHIC,
  POST_EMPLOYERDET,
  PUT_CONTACT,
} from './apiUrl';

export const getEmployerDetails = (caseId, absentParentId) => {
  return HttpClient.get(format(GET_EMPLOYERDET, caseId, absentParentId));
};

export const postEmployerDetails = (data = {}, caseId, absentParentId) => {
  return HttpClient.post(
    format(POST_EMPLOYERDET, caseId, absentParentId),
    employerDetailsPayload(data)
  );
};

export const postIncomeDetails = (data = {}, caseId, absentParentId) => {
  return HttpClient.post(
    format(POST_EMPLOYERDET, caseId, absentParentId),
    otherIncomePayload(data)
  );
};

export const postDemographic = (data = {}, caseId) => {
  return HttpClient.post(
    format(POST_DEMOGRAPHIC, caseId),
    demographicPayload(data)
  );
};

export const getDemographic = (caseId) => {
  return HttpClient.get(format(GET_DEMOGRAPHIC, caseId));
};

export const putContactInfo = (data = {}, caseId, absentParentId) => {
  return HttpClient.put(
    format(PUT_CONTACT, caseId, absentParentId),
    contactInfoPayload(data)
  );
};

export const getContactInfo = (caseId, absentParentId) => {
  return HttpClient.get(format(GET_CONTACTINFO, caseId, absentParentId));
};

export const getCourtOrderInfo = (caseId, absentParentId) => {
  return HttpClient.get(format(GET_COURTORDER, caseId, absentParentId));
};
