import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import StickyNote from '../../../components/StickyNote';
import EmptyState from '../../../components/EmptyState';
import { Field } from 'formik';
import AddNote from './AddNote';
import {
  deleteNote,
  fetchNotes,
} from '../../../utils/services/Note/apiRequests';
import { includes, map, reject } from 'lodash';
import { showErrorToast } from '../../../utils/services/toast';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectUniqueId } from '../../../store/selectors/authSelector';

export default function Notes() {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletingIds, setDeletingIds] = useState([]);
  const [noteToEdit, setNoteToEdit] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const uniqueId = useSelector(selectUniqueId);

  const toggleAddNote = () => {
    setOpen(!open);
  };

  const toggledEditNote = () => {
    setOpenEdit(!openEdit);
  };

  const getNotes = async () => {
    try {
      if (apiError) {
        setApiError(false);
      }
      setLoading(true);
      const res = await fetchNotes(uniqueId);
      if (res.status === 204) {
        setNotes([]);
      } else if (res.status === 200) {
        setNotes(res.data);
      }
    } catch (error) {
      setApiError(true);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteNote = async (id) => {
    try {
      setDeletingIds([...deletingIds, id]);
      const res = await deleteNote(id);
      if (res.status === 200) {
        getNotes();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      const newIds = reject(deletingIds, (dId) => dId === id);
      setDeletingIds(newIds);
    }
  };

  const openEditNote = (note) => {
    setNoteToEdit(note);
    setOpenEdit(true);
  };

  useEffect(() => {
    getNotes();
  }, []);

  const renderNotes = () => {
    if (loading && !notes.length) {
      return (
        <Grid container spacing={1} p="0.5rem">
          {Array.from({ length: 4 }).map(() => (
            <Grid item xs={6}>
              <Skeleton
                variant="rectangular"
                sx={{ borderRadius: '0.25rem' }}
                width="100%"
                height="100px"
              />
            </Grid>
          ))}
        </Grid>
      );
    }

    if (apiError) {
      return (
        <EmptyState
          description="Something went wrong!"
          primaryAction={
            <Button variant="contained" onClick={getNotes}>
              Try again
            </Button>
          }
        />
      );
    }

    return notes.length > 0 ? (
      <Grid>
        <Grid container>
          {map(notes, (note) => {
            const { title, workNotes, workerNotesId, updatedDt, createdDt } =
              note;

            const deleting = includes(deletingIds, workerNotesId);
            const displayDate = dayjs(updatedDt || createdDt).format();
            return (
              <Grid item justifyContent="center" xs={12}>
                <StickyNote
                  title={title ?? 'Note'}
                  subTitle={workNotes}
                  id={workerNotesId}
                  onDelete={onDeleteNote}
                  deleting={deleting}
                  onEdit={() => openEditNote(note)}
                  date={displayDate}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    ) : (
      <EmptyState
        description="Seems like there is no note added yet!"
        primaryAction={
          <Button
            variant="contained"
            onClick={toggleAddNote}
            startIcon={<NoteAddIcon />}
          >
            Add Note
          </Button>
        }
      />
    );
  };

  return (
    <Box className={styles.noteContent}>
      {open && (
        <AddNote
          getNotes={getNotes}
          open={open}
          close={toggleAddNote}
          uniqueId={uniqueId}
        />
      )}
      {openEdit && (
        <AddNote
          editMode
          note={noteToEdit}
          getNotes={getNotes}
          open={openEdit}
          close={toggledEditNote}
          uniqueId={uniqueId}
        />
      )}

      <Grid container justifyContent={'space-between'} padding={1}>
        <Typography variant="h3">Notes</Typography>
        <Button onClick={toggleAddNote} startIcon={<NoteAddIcon />}>
          Add Note
        </Button>
      </Grid>
      <Divider />
      <div className={styles.content}>{renderNotes()}</div>
      {/* <Typography variant="h5grey">Pinned</Typography> */}
    </Box>
  );
}
