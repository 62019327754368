import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
  createStyles,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import usePageNav from '../../hooks/usePageNav';
import { Circle } from '@mui/icons-material';
import { showErrorToast } from '../../utils/services/toast';
import {
  getDisability,
  getHoHData,
  getMembersData,
} from '../../utils/services/caseCreation/apiRequests';
import { filter, map } from 'lodash';
import dayjs from 'dayjs';
import useLookup from '../../hooks/useLookup';
import PageContainer from '../../components/PageContainer';
import SummaryHeader from '../CaseSummary/SummaryHeader';

const BorderedBox = styled(Box)({
  borderRadius: '0.25rem',
  border: '1px solid var(--grey-400)',
  display: 'flex',
  flexDirection: 'column',
  background: '#D9D9D9',
  height: '100%',
});

const StyledPaper = styled(Paper)(() => ({
  padding: '15px 20px',
  borderRadius: '10px',
  height: '100%',
  width: '100%',
}));

const useStyles = makeStyles((theme) =>
  createStyles({
    styledSpan: {
      color: '#000000',
      border: '1px solid var(--grey-350)',
      padding: '3px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
    },
    circle: {
      color: '#58f35f',
      padding: '0.2rem',
    },
  })
);

const CaseCreation = () => {
  const classes = useStyles();
  const { navigations } = usePageNav();
  const params = useParams();
  const _caseId = params.caseId ?? '';
  const _controlId = params.controlId ?? '';
  const [hohData, setHoHData] = useState([]);
  const [disability, setDisability] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const { getLkpMatch } = useLookup('CaseCreation');

  const onRunClearance = () => {
    navigations.toRunClearance(_caseId);
  };

  useEffect(() => {
    getHoHList();
    getMembersList();
    getDisablityInfo();
  }, [_caseId, _controlId]);

  const getHoHList = async () => {
    try {
      let data = {
        caseId: _caseId,
        controlId: _controlId,
      };
      const res = await getHoHData(data);
      if (res?.status === 200 && res?.data) {
        let parsedData = JSON.parse(res.data);
        setHoHData(parsedData);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
    }
  };
  const getDisablityInfo = async () => {
    try {
      let data = {
        caseId: _caseId,
        controlId: _controlId,
      };
      const res = await getDisability(data);
      if (res?.status === 200 && res?.data) {
        let parsedData = JSON.parse(res.data);
        setDisability(parsedData);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
    }
  };

  const getMembersList = async () => {
    try {
      let data = {
        caseId: _caseId,
        controlId: _controlId,
      };
      const res = await getMembersData(data);
      if (res?.status === 200 && res?.data !== 'No Json Doc found') {
        let parsedData = JSON.parse(res.data);
        let filteredData = filter(
          parsedData,
          (item) => item?.clientContainer?.client?.headOfHouseholdYN !== 'Y'
        );
        setMembersData(filteredData);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
    }
  };

  const hohMemberData = useMemo(() => {
    return [...hohData, ...membersData];
  }, [hohData, membersData]);

  let hoh = hohData.filter(
    (item) => item.clientContainer.client.headOfHouseholdYN === 'Y'
  );

  let enableClearance = false;
  const shouldEnableClearance = () => {
    enableClearance = true;
  };

  const getClearanceButtonText = (hoh) => {
    if (
      hoh &&
      hoh.clientContainer.client.headOfHouseholdYN === 'Y' &&
      (hoh.clearanceStatus === 'N' ||
        hoh.clearanceStatus === '' ||
        hoh.clearanceStatus === null ||
        hoh.caseId === '')
    ) {
      shouldEnableClearance();
      return 'RUN CLEARANCE';
    } else if (
      hoh &&
      hoh.clientContainer.client.headOfHouseholdYN === 'N' &&
      (hoh.clearanceStatus === 'N' ||
        hoh.clearanceStatus === '' ||
        hoh.clearanceStatus === null)
    ) {
      return 'RUN CLEARANCE';
    } else if (
      (hoh.clearanceStatus === 'Y' &&
        hoh.clientContainer.client.headOfHouseholdYN === 'Y' &&
        hoh.caseId !== '') ||
      (hoh.clearanceStatus === 'Y' &&
        hoh.clientContainer.client.headOfHouseholdYN === 'N')
    ) {
      return 'CLEARED';
    }
  };

  const onBack = () => {
    navigations.toAlerts();
  };

  const onNext = () => {
    navigations.toRunClearance(_caseId, _controlId);
  };

  const { personName } = hohData?.[0]?.clientContainer?.client ?? {};
  const { firstName, middleName, lastName } = personName ?? {};

  return (
    <PageContainer
      showHeader={false}
      onBack={() => onBack()}
      onNext={() => onNext()}
    >
      <BorderedBox pb="1rem" mt={2}>
        <SummaryHeader
          title={`Web ID : ${_caseId ?? 'NA'}`}
          isHoh={`Head of Household : ${firstName ?? ''} ${middleName ?? ''} ${
            lastName ?? ''
          }`}
        />
        <Grid
          container
          xs={12}
          spacing={2}
          sx={{ padding: '1rem 0 1rem 1rem' }}
        >
          {map(hohMemberData, (item, index) => {
            let {
              personName,
              headOfHouseholdYN,
              soSec,
              ethnicity,
              dob,
              race,
              maritalStatus,
              gender,
            } = item?.clientContainer?.client ?? {};
            return (
              <Grid item xs={6}>
                <StyledPaper>
                  <Stack flexDirection="row" columnGap={2}>
                    <Box sx={{ width: '20%' }}>
                      <Stack
                        //   minWidth="150px"
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                      >
                        <Avatar sx={{ width: 100, height: 100 }} />
                        <Typography variant="h5" mt={1} textAlign="center">
                          {`${personName?.firstName ?? ''} ${
                            personName?.lastName ?? ''
                          }`}
                        </Typography>
                        <Typography
                          color="var(--primary-dark)"
                          variant="h6"
                          fontWeight="600"
                        >
                          {headOfHouseholdYN === 'Y' ? 'HOH' : ''}
                        </Typography>
                      </Stack>
                    </Box>

                    <Box sx={{ width: '50%' }}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5grey" sx={{ width: '50%' }}>
                          SSN
                        </Typography>
                        <Typography variant="body1" sx={{ width: '50%' }}>
                          {`${soSec?.soSecPart1 ? '***' : ''}
                            ${soSec?.soSecPart2 ? '**' : ''}
                            ${soSec?.soSecPart3 ?? ''}`}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5grey" sx={{ width: '50%' }}>
                          Ethnicity
                        </Typography>
                        <Typography variant="body1" sx={{ width: '50%' }}>
                          {headOfHouseholdYN === 'Y'
                            ? getLkpMatch('Ethnicity', disability?.ethnicity)
                            : getLkpMatch('Ethnicity', ethnicity) ?? 'NA'}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5grey" sx={{ width: '50%' }}>
                          DOB
                        </Typography>
                        <Typography variant="body1" sx={{ width: '50%' }}>
                          {dob ? dayjs(dob).format('MM-DD-YYYY') : 'NA'}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5grey" sx={{ width: '50%' }}>
                          Race
                        </Typography>
                        <Typography variant="body1" sx={{ width: '50%' }}>
                          {headOfHouseholdYN === 'Y'
                            ? getLkpMatch('Race', disability?.race)
                            : getLkpMatch('Race', race?.primaryRace) ?? 'NA'}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5grey" sx={{ width: '50%' }}>
                          Martial Status
                        </Typography>
                        <Typography variant="body1" sx={{ width: '50%' }}>
                          {getLkpMatch(
                            'MartialStatus',
                            maritalStatus?.marriedYN
                          ) ?? 'NA'}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h5grey" sx={{ width: '50%' }}>
                          Gender
                        </Typography>
                        <Typography variant="body1" sx={{ width: '50%' }}>
                          {getLkpMatch('Gender', gender) ?? 'NA'}
                        </Typography>
                      </Stack>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5grey"
                          sx={{ marginRight: '1rem' }}
                        >
                          Program
                        </Typography>
                        {(item?.intakeProgramSelections || []).map((ele) => (
                          <>
                            <Typography
                              className={classes.styledSpan}
                              variant="body2"
                            >
                              <Circle className={classes.circle} />
                              {getLkpMatch(
                                'ProgramCode',
                                ele?.programSelected?.programName
                              ) ?? 'NA'}
                            </Typography>
                          </>
                        ))}
                      </Box>

                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ padding: '0.6rem 0rem' }}
                        fullWidth
                        onClick={onRunClearance}
                        disabled={
                          (item.clearanceStatus === 'Y' &&
                            headOfHouseholdYN === 'Y' &&
                            item?.caseId !== '') ||
                          (item.clearanceStatus === 'Y' &&
                            headOfHouseholdYN === 'N') ||
                          (enableClearance && headOfHouseholdYN === 'N')
                        }
                      >
                        {getClearanceButtonText(item)}
                      </Button>
                    </Box>
                  </Stack>
                </StyledPaper>
              </Grid>
            );
          })}
        </Grid>
      </BorderedBox>
    </PageContainer>
  );
};

CaseCreation.propTypes = {};

export default CaseCreation;
