import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SummaryContent from '../SummaryContent';
import TabularView from '../../../components/TabularView';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import { Grid, IconButton, Tooltip } from '@mui/material';

function CitizenshipSts({ houseMembers, getLkpMatch }) {
  const columns = useMemo(
    () => [
      {
        field: '',
        headerName: 'Citizenship Status',
        getValue({
          priorCitizenshipImmigration = {},
          citizenshipImmigration = {},
        }) {
          return `${
            getLkpMatch(
              'Citizenship',
              priorCitizenshipImmigration[0]?.usCitizenshipStatus ??
                citizenshipImmigration?.usCitizenshipStatus
            ) ?? '-'
          } `;
        },
      },
      {
        field: '',
        headerName: 'Alien Number',
        getValue({ priorCitizenshipImmigration = {} }) {
          return `${priorCitizenshipImmigration[0]?.alienNumber ?? '-'} `;
        },
      },
      {
        field: '',
        headerName: 'Sevis Number',
        getValue({ priorCitizenshipImmigration = {} }) {
          return `${priorCitizenshipImmigration[0]?.sevisId ?? '-'} `;
        },
      },
      {
        headerName: 'Update',
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [houseMembers, getLkpMatch]
  );
  return (
    <SummaryContent title="Citizenship Status">
      <TabularView
        sx={{
          table: {
            width: '100%',
          },
          td: {
            border: 0,
            padding: '0 1.25rem 0.5rem 0',
          },
          th: {
            border: 0,
            borderBottom: '1px solid var(--grey-300)',
            padding: '0 1.25rem 0.5rem 0',
          },
        }}
        headerComponent={{ variant: 'h6', color: 'var(--grey-400)' }}
        cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
        columns={columns}
        data={houseMembers}
      />
    </SummaryContent>
  );
}

CitizenshipSts.propTypes = {};

export default CitizenshipSts;
