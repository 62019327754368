import React, { Children, useState } from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Field } from 'formik';

const CustomDataGrid = ({ rows = [], column = [] }) => {
  return <DataGrid rows={rows} columns={column} />;
};

const CustomTabPanel = ({ Children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${value}`}
    >
      {Children}
    </Box>
  );
};
const sanctionGridColumn = [
  { field: '', headerName: 'Sanction Status' },
  { field: '', headerName: 'Sanction Level' },
  { field: '', headerName: 'Sanction Type' },
  { field: '', headerName: 'Program' },
  { field: '', headerName: 'Begin Date' },
  { field: '', headerName: 'End Date' },
  { field: '', headerName: 'Discovery Date' },
  { field: '', headerName: 'Action' },
];

const SanctionsDisqualifictaionContent = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const HandleTabChange = (_, index) => {
    setCurrentTab(index);
  };

  return (
    <Box>
      <Tabs value={currentTab} onChange={HandleTabChange}>
        <Tab label="Sanctions" />
        <Tab label="Disqualifications" />
      </Tabs>
      <CustomTabPanel value={0}>
        <CustomDataGrid rows={[]} />
      </CustomTabPanel>
      <CustomTabPanel value={1}>
        <CustomDataGrid column={[]} />
      </CustomTabPanel>
    </Box>
  );
};

export default SanctionsDisqualifictaionContent;
