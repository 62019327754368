import React, { Children, isValidElement, useEffect, useState } from 'react';
import { Box, Tab, Tabs as TabsMui } from '@mui/material';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  if (value !== index) {
    return null;
  }

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ display: 'flex', flexGrow: 1 }}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

export default function useTabs(defaultTab = 0) {
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  const onTabChange = (_e, value) => {
    setActiveTab(value);
  };

  //   const Tabs = ({ children }) => {
  //     return (
  //       <TabsMui onChange={onTabChange} value={activeTab}>
  //         {children}
  //       </TabsMui>
  //     );
  //   };

  const TabContent = ({ children, ...props }) => {
    if (!children) {
      return;
    }

    return Children.map(children, (child, index) => {
      return (
        <TabPanel {...props} value={activeTab} index={index}>
          {child}
        </TabPanel>
      );
    });
  };

  return {
    Tabs: TabsMui,
    Tab,
    TabPanel,
    TabContent,
    activeTab,
    onTabChange,
    setActiveTab,
  };
}
