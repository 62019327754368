import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import useLookup from '../../../../hooks/useLookup';
import { map } from 'lodash';
import useLoader from '../../../../hooks/useLoader';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import {
  assignWorkItem,
  closeNew,
  workersWorkItem,
} from '../../../../utils/services/apiRequests';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Field from '../../../../components/Field';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Dropdown from '../../../../components/Dropdown';
import Avatar from '../../../../components/Avatar';
import { ReactComponent as TransferBlue } from '../../../../assets/icons/transferBlue.svg';
import { ReactComponent as Transfer } from '../../../../assets/icons/transfer.svg';
import { ReactComponent as Dispose } from '../../../../assets/icons/dispose.svg';
import { ReactComponent as DisposeBlue } from '../../../../assets/icons/disposeBlue.svg';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../../../store/selectors/authSelector';

const Disposition = ({ open, dispositionData, handleClose }) => {
  const { getOptions } = useLookup('AlertSearch');
  const [workerddOptions, setWorkerddOptions] = useState([]);
  const token = useSelector(getAuthToken);
  const [showLoader, hideLoader] = useLoader();
  const [selectedStack, setSelectedStack] = useState(0);

  const { alertDispOpts } = useMemo(() => {
    return {
      alertDispOpts: getOptions('AlertDispRsnCd'),
      // alertTypeOpts: getOptions('AlertType'),
      // alertSubTypeOpts: getOptions('AlertSubType'),
    };
  }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      reasonCd: '',
      dispositionComments: '',
      transferComments: '',
      assignWorker: '',
    },
    onSubmit: (values) => {
      disposition(values);
    },
  });

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const disposition = async (values) => {
    const payloadDispose = [
      {
        alertId: dispositionData.alertId,
        alertSubType: dispositionData.alertSubType,
        caseId: dispositionData.caseId,
        dispositionComments: values.dispositionComments,
        dispositionedBy: token.sub,
        reasonCd: values.reasonCd,
      },
    ];
    const payloadTransfer = {
      caseId: dispositionData.caseId,
      createdBy: null,
      typeCd: dispositionData?.alertType,
      uniqueId: values?.assignWorker,
      updatedBy: token.sub,
      workItemId: [dispositionData.alertId],
    };

    try {
      showLoader();
      if (selectedStack === 1) {
        const resClose = await closeNew(payloadDispose);
        if (resClose.status === 200) {
          showSuccessToast('Dispose Successfully');
          onClose();
        }
      } else if (selectedStack === 2) {
        const resAssign = await assignWorkItem(payloadTransfer);
        if (resAssign.status === 200) {
          showSuccessToast('Transfer Successfully');
          onClose();
        }
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const getWorkerOptions = async () => {
    try {
      showLoader();
      const res = await workersWorkItem();
      if (res.status === 200 && res.data) {
        setWorkerddOptions(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const lookupData = useMemo(() => {
    return map(workerddOptions, (value) => {
      return {
        label: value.firstName + ' ' + value.lastName,
        value: value.uniqueId,
      };
    });
  }, [workerddOptions]);

  useEffect(() => {
    getWorkerOptions();
  }, []);

  const handleClick = (value) => {
    setSelectedStack(value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '50rem',
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">Alert Disposition</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // padding: '1rem',
            border: 'solid 1px var(--grey-350)',
          }}
        >
          <Grid item xs={12} sx={{ padding: '1rem' }}>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Avatar size="medium" bordered name={dispositionData?.hohName} />
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: '5px' }}>
                {' '}
                <Typography variant="h4">{dispositionData?.hohName}</Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ color: 'var(--primary-dark)' }}
                >
                  (HOH)
                </Typography>
              </Box>
            </Box>
            <Box>
              <Grid
                item
                xs={12}
                spacing={2}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  paddingLeft: '3rem',
                }}
              >
                <Stack>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold', color: 'var(--grey-400)' }}
                    >
                      Case ID
                    </Typography>
                    <Typography variant="h6">
                      {dispositionData?.caseId}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold', color: 'var(--grey-400)' }}
                    >
                      Type
                    </Typography>
                    <Typography variant="h6">
                      {dispositionData?.alertType}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold', color: 'var(--grey-400)' }}
                    >
                      SubType
                    </Typography>
                    <Typography variant="h6">
                      {dispositionData?.alertSubType}
                    </Typography>
                  </Box>
                </Stack>
                <Stack>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold', color: 'var(--grey-400)' }}
                    >
                      Create Date
                    </Typography>
                    <Typography variant="h6">
                      {dayjs(dispositionData?.alertCreateDt).format(
                        'MM-DD-YYYY'
                      )}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold', color: 'var(--grey-400)' }}
                    >
                      Create Date
                    </Typography>
                    <Typography variant="h6">
                      {dayjs(dispositionData?.dueDate).format('MM-DD-YYYY')}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 'bold', color: 'var(--grey-400)' }}
                    >
                      Description
                    </Typography>
                    <Typography variant="h6">
                      {dispositionData?.description}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Box>
          </Grid>
          <Divider />
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '2rem',
              padding: '1rem',
            }}
          >
            <Stack
              xs={12}
              sx={{
                width: '100%',
                padding: '1rem',
                border:
                  selectedStack === 1
                    ? '1px solid var(--primary-dark)'
                    : '1px solid var(--grey-350)',
                cursor: 'pointer',
                borderRadius: '6px',
              }}
              onClick={() => handleClick(1)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '2rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {selectedStack === 1 ? <DisposeBlue /> : <Dispose />}
                  <Typography variant="h4" sx={{ color: 'var(--grey-400)' }}>
                    Dispose Alert
                  </Typography>
                </Box>
                <Field
                  name="reasonCd"
                  formik={formik}
                  label="Disposition Reason"
                  required
                >
                  <Dropdown
                    options={alertDispOpts}
                    placeholder="Select Reason"
                    disabled={selectedStack === 1 ? false : true}
                  />
                </Field>
                <Field
                  label="Comments"
                  showError={false}
                  formik={formik}
                  name="dispositionComments"
                >
                  <TextField
                    multiline
                    rows={3}
                    placeholder="Enter Comments"
                    disabled={selectedStack === 1 ? false : true}
                  />
                </Field>
              </Box>
            </Stack>
            <Stack
              xs={12}
              sx={{
                width: '100%',
                padding: '1rem',
                border:
                  selectedStack === 2
                    ? '1px solid var(--primary-dark)'
                    : '1px solid var(--grey-350)',
                cursor: 'pointer',
                borderRadius: '6px',
              }}
              onClick={() => handleClick(2)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: '2rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {selectedStack === 2 ? <TransferBlue /> : <Transfer />}
                  <Typography variant="h4" sx={{ color: 'var(--grey-400)' }}>
                    Transfer Alert
                  </Typography>
                </Box>
                <Field
                  name="assignWorker"
                  formik={formik}
                  label="Assign Worker"
                  required
                >
                  <Dropdown
                    options={lookupData}
                    placeholder="Select Worker"
                    disabled={selectedStack === 2 ? false : true}
                  />
                </Field>
                <Field
                  label="Comments"
                  showError={false}
                  formik={formik}
                  name="transferComments"
                >
                  <TextField
                    multiline
                    rows={3}
                    placeholder="Enter Comments"
                    disabled={selectedStack === 2 ? false : true}
                  />
                </Field>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          autoFocus
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Disposition.propTypes = {};

export default Disposition;
