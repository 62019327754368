export const providerSearchPayload = (data) => {
  const {
    vendorId,
    vendorName,
    vendorCity,
    vendorState,
    vendorZipcode,
    vendorType,
  } = data;
  return {
    search: {
      vendorSearchBy: null,
      vendorId: vendorId,
      vendorName: vendorName,
      vendorTypeCd: vendorType,
      mmisVendorId: '',
      mmisVendorSpecialityCd: null,
      vendorCity: vendorCity,
      vendorState: vendorState,
      vendorZipcode: vendorZipcode,
      caseId: '',
    },
    results: [],
    paginationData: {
      resultsPerPage: 4,
      pageNumber: 0,
    },
    range: null,
    systemCd: 'W',
  };
};

export const customerSearchPayload = (data) => {
  const {
    customerId,
    maId,
    ssn,
    fName,
    mName,
    lName,
    suffix,
    gender,
    race,
    addressLine1,
    addressLine2,
    city,
    state,
    county,
    zipcode,
  } = data;

  const formattedSSN =
    ssn.length >= 9
      ? `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
      : ssn;

  const [part1, part2, part3] = formattedSSN.split('-');
  console.log({ part1, part2, part3 }, 'lop');
  return {
    search: {
      dirtyStatus: '',
      personName: {
        dirtyStatus: '',
        firstName: fName,
        lastName: lName,
        middleName: mName ? mName : null,
        type: '',
        suffix: suffix ? suffix : null,
      },
      dob: null,
      soSec: {
        dirtyStatus: '',
        soSecPart1: part1,
        soSecPart2: part2,
        soSecPart3: part3,
        ssnVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: null,
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      gender: gender ? gender : null,
      race: {
        dirtyStatus: '',
        messages: [],
        verificationDocumentCd: '',
        verificationRequestedYN: '',
        docNames: {},
        documentDetails: [],
        verificationType: '',
        verificationSourceCode: '',
        verifiedYN: '',
        dueDate: null,
        automatedVerificationYN: '',
        primaryRace: race ? race : null,
        otherRaces: [],
      },
      deletedYN: '',
      boarderStatusYN: '',
      individualId: '',
      headOfHouseholdYN: '',
      relationshipCd: '',
      relations: [],
      altNames: [],
      additionalSsns: [],
      pregnantYN: '',
      aliasClientIds: [],
      memberClearedYN: '',
      memberEditableYN: '',
      cases: [],
      overrideEligibility: [],
      dobVerification: {
        dirtyStatus: '',
        messages: [],
        verificationDocumentCd: '',
        verificationRequestedYN: '',
        docNames: {},
        documentDetails: [],
        verificationType: '',
        verificationSourceCode: '',
        verifiedYN: '',
        dueDate: null,
        automatedVerificationYN: '',
      },
      hasSsnYN: '',
      eligibility: {
        dirtyStatus: '',
        programs: [],
        expeditedFoodStampsYN: '',
        scrEligibilityRunTimestamp: '',
      },
      approvedEligibility: {
        dirtyStatus: '',
        programs: [],
        expeditedFoodStampsYN: '',
        scrEligibilityRunTimestamp: '',
      },
      maritalStatus: {
        dirtyStatus: '',
        marriedYN: '',
        verificationSourceCode: '',
      },
      ethnicity: '',
      financialResposibility: '',
      applicablePrograms: [],
      ssnReferralDate: null,
      individualDetails: {
        dirtyStatus: '',
        deathDetails: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          dateOfDeath: null,
          state: '',
          deathCertificateNumber: '',
          deathVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          secondaryVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          recordId: 0,
        },
        voterRegistartionYN: '',
        voterRegistrationStatus: '',
        voterRegistration: {
          dirtyStatus: '',
          voterIdNumber: '',
          electionJudgeFlag: '',
          helpRequiredFlag: '',
          licenceNumber: '',
          voterRegLicenseIdAvailCd: '',
          otherPartyName: '',
        },
        veterans: [],
        maritals: [],
        livingArrangements: [],
        f99Details: [],
        x02Details: [],
        birthCity: '',
        birthState: '',
        hospital: '',
        destituteMigrantYN: '',
        concurrentOutStateBenefits: [],
        tcaCounter: '',
        exemptions: [],
        domesticViolanceYN: '',
        strikerStatus: '',
        penalties: [],
        primaryRace: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          primaryRace: '',
          otherRaces: [],
        },
        ethnicity: '',
        primaryVerificationSource: '',
        incarcerations: [],
        ssnApplicationDate: null,
        differentAddressYN: '',
        individualAddress: {
          dirtyStatus: '',
          addressCareOf: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          county: '',
          district: '',
          zipcode: '',
          zip4code: '',
          addressVerifiedYN: '',
          institutionName: '',
          institutionYN: '',
          homeAddressYN: '',
          addressStartDate: null,
          addressEndDate: null,
          ownedByMemberYN: '',
          localDistrictOfficeYN: '',
        },
        strikers: [],
        primaryPrevention: [],
      },
      medicare: {
        dirtyStatus: '',
        medicareStartDate: null,
        medicareTypeA: '',
        medicareTypeB: '',
        medicareTypeC: '',
        medicareTypeD: '',
        medicareTypeAStatus: '',
        medicareTypeBStatus: '',
        medicareTypeCStatus: '',
        medicareTypeDStatus: '',
        medicareVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        medicareClaimVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        medicareApplicationVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        premiumAmount: '',
        claimId: '',
        applicationNum: '',
        appliedInd: '',
        rcvdInd: '',
        solqYN: '',
      },
      citizenshipImmigration: {
        dirtyStatus: '',
        usCitizenshipStatus: '',
        usCitizenshipVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        uscisStatus: '',
        uscisStatusDate: null,
        uscisStatusVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        sponsoredAlienYN: '',
        countryOfOrigin: '',
        entryDate: null,
        uscisNumber: '',
        uscisNumberVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        uscisNumberVerificationDate: null,
        i40QuarterIndYN: '',
        refugeeSettlementAgency: '',
        specifyAgency: '',
        statePaidProgramYN: '',
        alienNumber: '',
        docExpirationDate: null,
        i94Number: '',
        passportNumber: '',
        sevisId: '',
        lawfulResidentFiveYearsYN: '',
        residentshipPeriodYN: '',
        countryOfPassport: '',
        immigrationDocument: '',
        visaNumber: '',
        otherTypeDescription: '',
        lawfulPresenceVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        beginDt: null,
        endDt: null,
        voterRegistration: null,
        changeInCitizenshipYN: '',
        changeInVoterYN: '',
      },
      priorCitizenshipImmigration: [],
      studentInfo: [],
      disabilities: [],
      insuranceInformation: {
        dirtyStatus: '',
        recordId: 0,
        healthInsurance: '',
        hmo: '',
        managedCareYN: '',
        insuranceDroppedYN: '',
        willingToPayYN: '',
        buyingYN: '',
        tplYN: '',
        liabilityCD: '',
        purchaseForChildYN: '',
      },
      institutionalInformation: {
        dirtyStatus: '',
        institutions: [],
        institutionalAdditionalInfo: [],
        waivers: [],
      },
      assets: [],
      earnedIncomes: [],
      unearnedIncomes: [],
      appliedUnearnedIncomes: [],
      potentialAssets: [],
      unearnedIncomeApplications: [],
      dependentCareExpenses: [],
      shelterExpenses: [],
      shelterUtilityExpenses: [],
      utilityExpenseType: '',
      utilityExpenses: [],
      specialNeeds: [],
      ccTriggerInsert: [],
      medicalExpenses: [],
      childSpousalExpenses: [],
      disabledYN: '',
      refugeeYN: '',
      blindYN: '',
      destituteMigrantYN: '',
      shelterIncludesMealsYN: '',
      protectiveLivingArrangementYN: '',
      communityBasedWaiverYN: '',
      medicarePartAEntitlementYN: '',
      absentParentYN: '',
      programRequest: [],
      insurancePolicies: [],
      outStandingVerifications: [],
      individualTaxStatus: {
        dirtyStatus: '',
        lastYearTaxFilingStatusCode: '',
        thisYearTaxFilingStatusCode: '',
        lastYearPrimaryFilerIndicator: '',
        thisYearPrimaryFilerIndicator: '',
        lastYearDependentClaimingPersonId: '',
        thisYearDependentClaimingPersonId: '',
        thisYearIncomeAmount: '',
        dataMatchPercentDifference: '',
        incomeVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      irn: null,
      taxFilingStatuses: [],
      createMdmId: true,
      mdmId: null,
      errInd: '',
      maId: maId,
      maIdSuffix: '',
      individualBoarder: [],
      caseStatus: '',
      pregnancy: [],
      aliasNames: [],
      delteMemberIndicator: '',
      institution: null,
      institutionName: '',
      aboutMember: {},
      hohVerifiedYN: '',
    },
    results: [],
    paginationData: {
      resultsPerPage: 5,
      pageNumber: 0,
    },
    range: null,
    systemCd: 'CID',
    associateCaseId: '',
    client: {
      dirtyStatus: '',
      personName: {
        dirtyStatus: '',
        firstName: '',
        lastName: '',
        middleName: '',
        type: '',
        suffix: '',
      },
      dob: null,
      soSec: {
        dirtyStatus: '',
        soSecPart1: '',
        soSecPart2: '',
        soSecPart3: '',
        ssnVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      gender: '',
      race: {
        dirtyStatus: '',
        messages: [],
        verificationDocumentCd: '',
        verificationRequestedYN: '',
        docNames: {},
        documentDetails: [],
        verificationType: '',
        verificationSourceCode: '',
        verifiedYN: '',
        dueDate: null,
        automatedVerificationYN: '',
        primaryRace: '',
        otherRaces: [],
      },
      deletedYN: '',
      boarderStatusYN: '',
      individualId: '',
      headOfHouseholdYN: '',
      relationshipCd: '',
      relations: [],
      altNames: [],
      additionalSsns: [],
      pregnantYN: '',
      aliasClientIds: [],
      memberClearedYN: '',
      memberEditableYN: '',
      cases: [],
      overrideEligibility: [],
      dobVerification: {
        dirtyStatus: '',
        messages: [],
        verificationDocumentCd: '',
        verificationRequestedYN: '',
        docNames: {},
        documentDetails: [],
        verificationType: '',
        verificationSourceCode: '',
        verifiedYN: '',
        dueDate: null,
        automatedVerificationYN: '',
      },
      hasSsnYN: '',
      soSecReferral: '',
      eligibility: {
        dirtyStatus: '',
        programs: [],
        expeditedFoodStampsYN: '',
        scrEligibilityRunTimestamp: '',
      },
      approvedEligibility: {
        dirtyStatus: '',
        programs: [],
        expeditedFoodStampsYN: '',
        scrEligibilityRunTimestamp: '',
      },
      maritalStatus: {
        dirtyStatus: '',
        marriedYN: '',
        verificationSourceCode: '',
      },
      ethnicity: '',
      financialResposibility: '',
      applicablePrograms: [],
      ssnReferralDate: null,
      individualDetails: {
        dirtyStatus: '',
        deathDetails: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          dateOfDeath: null,
          state: '',
          deathCertificateNumber: '',
          deathVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          secondaryVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
          },
          recordId: 0,
        },
        voterRegistartionYN: '',
        voterRegistrationStatus: '',
        voterRegistration: {
          dirtyStatus: '',
          voterIdNumber: '',
          electionJudgeFlag: '',
          helpRequiredFlag: '',
          licenceNumber: '',
          voterRegLicenseIdAvailCd: '',
          otherPartyName: '',
        },
        veterans: [],
        maritals: [],
        livingArrangements: [],
        f99Details: [],
        x02Details: [],
        pregnancy: [],
        birthCity: '',
        birthState: '',
        hospital: '',
        destituteMigrantYN: '',
        concurrentOutStateBenefits: [],
        tcaCounter: '',
        exemptions: [],
        domesticViolanceYN: '',
        strikerStatus: '',
        penalties: [],
        primaryRace: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
          primaryRace: '',
          otherRaces: [],
        },
        ethnicity: '',
        primaryVerificationSource: '',
        incarcerations: [],
        ssnApplicationDate: null,
        differentAddressYN: '',
        individualAddress: {
          dirtyStatus: '',
          addressCareOf: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          county: '',
          district: '',
          zipcode: '',
          zip4code: '',
          addressVerifiedYN: '',
          institutionName: '',
          institutionYN: '',
          homeAddressYN: '',
          addressStartDate: null,
          addressEndDate: null,
          ownedByMemberYN: '',
          localDistrictOfficeYN: '',
        },
        strikers: [],
        primaryPrevention: [],
      },
      medicare: {
        dirtyStatus: '',
        medicareStartDate: null,
        medicareTypeA: '',
        medicareTypeB: '',
        medicareTypeC: '',
        medicareTypeD: '',
        medicareTypeAStatus: '',
        medicareTypeBStatus: '',
        medicareTypeCStatus: '',
        medicareTypeDStatus: '',
        medicareVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        medicareClaimVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        medicareApplicationVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        premiumAmount: '',
        claimId: '',
        applicationNum: '',
        appliedInd: '',
        rcvdInd: '',
        solqYN: '',
      },
      citizenshipImmigration: {
        dirtyStatus: '',
        usCitizenshipStatus: '',
        usCitizenshipVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        uscisStatus: '',
        uscisStatusDate: null,
        uscisStatusVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        sponsoredAlienYN: '',
        countryOfOrigin: '',
        entryDate: null,
        uscisNumber: '',
        uscisNumberVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        uscisNumberVerificationDate: null,
        i40QuarterIndYN: '',
        refugeeSettlementAgency: '',
        specifyAgency: '',
        statePaidProgramYN: '',
        alienNumber: '',
        docExpirationDate: null,
        i94Number: '',
        passportNumber: '',
        sevisId: '',
        lawfulResidentFiveYearsYN: '',
        residentshipPeriodYN: '',
        countryOfPassport: '',
        immigrationDocument: '',
        visaNumber: '',
        otherTypeDescription: '',
        lawfulPresenceVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        beginDt: null,
        endDt: null,
        voterRegistration: null,
        changeInCitizenshipYN: '',
        changeInVoterYN: '',
      },
      priorCitizenshipImmigration: [],
      studentInfo: [],
      disabilities: [],
      insuranceInformation: {
        dirtyStatus: '',
        recordId: 0,
        healthInsurance: '',
        hmo: '',
        managedCareYN: '',
        insuranceDroppedYN: '',
        willingToPayYN: '',
        buyingYN: '',
        tplYN: '',
        liabilityCD: '',
        purchaseForChildYN: '',
      },
      institutionalInformation: {
        dirtyStatus: '',
        institutions: [],
        institutionalAdditionalInfo: [],
        waivers: [],
      },
      assets: [],
      earnedIncomes: [],
      unearnedIncomes: [],
      appliedUnearnedIncomes: [],
      potentialAssets: [],
      unearnedIncomeApplications: [],
      dependentCareExpenses: [],
      shelterExpenses: [],
      shelterUtilityExpenses: [],
      utilityExpenseType: '',
      utilityExpenses: [],
      specialNeeds: [],
      ccTriggerInsert: [],
      medicalExpenses: [],
      childSpousalExpenses: [],
      disabledYN: '',
      refugeeYN: '',
      blindYN: '',
      destituteMigrantYN: '',
      shelterIncludesMealsYN: '',
      protectiveLivingArrangementYN: '',
      communityBasedWaiverYN: '',
      medicarePartAEntitlementYN: '',
      absentParentYN: '',
      programRequest: [],
      insurancePolicies: [],
      outStandingVerifications: [],
      individualTaxStatus: {
        dirtyStatus: '',
        lastYearTaxFilingStatusCode: '',
        thisYearTaxFilingStatusCode: '',
        lastYearPrimaryFilerIndicator: '',
        thisYearPrimaryFilerIndicator: '',
        lastYearDependentClaimingPersonId: '',
        thisYearDependentClaimingPersonId: '',
        thisYearIncomeAmount: '',
        dataMatchPercentDifference: '',
        incomeVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
      },
      irn: null,
      taxFilingStatuses: [],
      createMdmId: true,
      mdmId: null,
      errInd: '',
      maId: '',
      maIdSuffix: '',
      individualBoarder: [],
      caseStatus: '',
      pregnancy: [],
      delteMemberIndicator: '',
      institution: null,
      institutionName: '',
      aboutMember: {},
      hohVerifiedYN: '',
    },
    address: null,
    // {
    //   dirtyStatus: '',
    //   addressCareOf: '',
    //   addressLine1: addressLine1,
    //   addressLine2: addressLine2,
    //   city: city,
    //   state: state,
    //   county: county,
    //   district: '',
    //   zipcode: zipcode,
    //   zip4code: '',
    //   addressVerifiedYN: '',
    //   institutionName: '',
    //   institutionYN: '',
    //   homeAddressYN: '',
    //   addressStartDate: null,
    //   addressEndDate: null,
    //   ownedByMemberYN: '',
    //   localDistrictOfficeYN: '',
    // }
    sourceSystem: '',
    selectedPrograms: [],
  };
};
