import React from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import Icon from '../Icon';
import cx from 'classnames';
import styles from './index.module.css';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { cond } from '../../utils/helpers';
import { programIcons } from '../../constants/programConstants';

function ProgramTile({
  title,
  programName,
  selected,
  disabled,
  error,
  onClick,
  description,
  loading,
}) {
  const StatusIcon = cond([
    [selected, DoneIcon],
    [disabled, PriorityHighIcon],
  ]);

  const handleSelect = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Box
      className={cx(styles.programTile, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
      })}
      onClick={handleSelect}
      role="button"
      tabIndex={-1}
    >
      {loading && (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      )}
      {StatusIcon && (
        <Box
          className={cx(styles.stsIcon, {
            [styles.disabled]: disabled,
          })}
        >
          <Tooltip title={description}>
            <StatusIcon />
          </Tooltip>
        </Box>
      )}
      <Icon className={styles.icon} name={programIcons[programName]} />
      <Typography
        sx={{
          color: disabled ? 'var(--grey-300)' : 'var(--grey-400)',
          mt: '0.25rem',
          textAlign: 'center',
          lineHeight: 1.2,
        }}
        variant="subtitle2"
      >
        {title}
      </Typography>
    </Box>
  );
}

ProgramTile.propTypes = {
  programName: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
};

ProgramTile.defaultProps = {
  programName: '',
  selected: false,
  disabled: false,
  title: '',
  description: '',
  loading: false,
};

export default ProgramTile;
