import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import useLoader from '../../../hooks/useLoader';
import {
  getQuestion,
  postAssignCaseWorker,
  postCaseSaveAndSubmit,
} from '../../../utils/services/apiRequests';
import { filter, find, findIndex, map } from 'lodash';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import useLookup from '../../../hooks/useLookup';
import Field from '../../../components/Field';
import { getAuthToken } from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';

const ReviewQuestion = ({ open, handleClose, rowsData, getLkpMatch }) => {
  const { getOptions } = useLookup('Questionary');
  const [showLoader, hideLoader] = useLoader();
  const token = useSelector(getAuthToken);
  const [questions, setQuestions] = useState([]);
  const [caseWorker, setCaseWorker] = useState(true);
  const onClose = () => {
    handleClose();
    // formik?.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      questionResponse: '',
      narrative: '',
    },
  });

  const { alertDispOpts } = useMemo(() => {
    return {
      alertDispOpts: getOptions('ReviewReason'),
    };
  }, [getOptions]);

  useEffect(() => {
    const caseWorkerData = find(
      token?.resource_access?.devwp?.roles,
      (item) => item === 'EE_SUPVRS' || item === 'EE_EGWKR'
    );
    const setCaseWorkerValue = caseWorkerData !== null ? false : true;
    console.log(setCaseWorkerValue, 'setCaseWorkerValue');
    setCaseWorker(setCaseWorkerValue);
  }, [token]);

  const review = async () => {
    try {
      const res = await getQuestion(rowsData[0].caseId, rowsData[0].programs);
      if (res.status === 200) {
        setQuestions(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    }
  };

  useEffect(() => {
    if (open === true) {
      review();
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const index = findIndex(
      questions.programInfoList,
      (question) => question?.questionId === name
    );
    if (index !== -1) {
      const updatedQuestions = questions?.programInfoList;
      updatedQuestions[index] = {
        ...updatedQuestions[index],

        questionResponse: value,
      };
      formik.setValues({
        programInfoList: updatedQuestions,
        supervisorQandAInfoList: updatedQuestions,
        narrative: '',
      });
    }
  };

  const caseSumbit = async (action) => {
    const payload = {
      actionInd: action,
      caseId: questions.caseId,
      spReviewId: questions.spReviewId,
      spUniqueId: questions.spUniqueId,
      programInfoList: formik.values?.programInfoList,
      supervisorQandAInfoList: formik.values?.supervisorQandAInfoList,
    };
    try {
      showLoader();
      const res = await postCaseSaveAndSubmit(payload);
      if (res.status === 200) {
        showSuccessToast('Submited Successfully');
        onClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const caseAssign = async () => {
    try {
      const res = await postAssignCaseWorker(rowsData[0].caseId);
      if (res.status === 200) {
        showSuccessToast('Assigned Successfully');
      }
    } catch (error) {
      showErrorToast('Server Error');
    }
  };

  const enableSubmit = () => {
    if (formik.values.programInfoList?.length > 0) {
      const find = filter(formik.values.programInfoList, (data) => {
        return (
          data.questionResponse != 'CR' &&
          data.questionResponse != 'Y' &&
          data.questionResponse != 'X'
        );
      });

      if (find?.length > 0) {
        console.log('trye');
        return true;
      }
    }
  };

  const enableSave = () => {
    if (formik.values.programInfoList?.length > 0) {
      const find = filter(formik.values.programInfoList, (data) => {
        return (
          data.questionResponse != 'AR' &&
          data.questionResponse != 'CR' &&
          data.questionResponse != 'Y' &&
          data.questionResponse != 'A' &&
          data.questionResponse != 'N'
        );
      });

      if (find?.length > 0) {
        return true;
      }
    }
  };

  const enableAssign = () => {
    if (formik.values.programInfoList?.length > 0) {
      const find = filter(formik.values.programInfoList, (data) => {
        return data.questionResponse != 'AR' && data.questionResponse != 'N';
      });

      if (find?.length > 0) {
        return true;
      }
    }
  };

  useMemo(() => {
    enableSave();
    enableAssign();
    enableSubmit();
  }, [formik.values.programInfoList]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '75rem',
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3">Review Summary</Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {map(questions.programInfoList, (item, index) => (
              <Grid
                container
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={index}
              >
                <Grid item sx={{ display: 'flex', gap: '1rem' }} xs={10}>
                  <Stack>{index + 1}</Stack>
                  <Stack>{getLkpMatch('Program', item.programCd)}</Stack>
                  <Stack>{item.questionText}</Stack>
                </Grid>
                <Grid item xs={2}>
                  <Field name="questionResponse" customHandler>
                    <Dropdown
                      options={alertDispOpts}
                      name={item.questionId}
                      placeholder="Select Reason"
                      onChange={handleChange}
                      value={
                        formik.values?.programInfoList?.[index]
                          .questionResponse ?? item?.questionResponse
                      }
                    />
                  </Field>
                </Grid>
              </Grid>
            ))}

            <Field name="narrative" formik={formik}>
              <TextField
                label="Narrative"
                multiline
                rows={3}
                placeholder="Enter Comments"
              />
            </Field>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button onClick={onClose} variant="outlined">
            Discard
          </Button>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Button
              onClick={caseAssign}
              variant="contained"
              disabled={enableAssign() || caseWorker}
            >
              Assign
            </Button>
            <Button
              onClick={() => caseSumbit('SA')}
              variant="contained"
              disabled={enableSave() || caseWorker}
            >
              Save
            </Button>
            <Button
              variant="contained"
              disabled={enableSubmit() || caseWorker}
              onClick={() => caseSumbit('SU')}
            >
              Submit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewQuestion;
