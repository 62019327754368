import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { useFormik } from 'formik';
import useLoader from '../../hooks/useLoader';
import { map } from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import {
  addWorkItem,
  getActiveWorkers,
} from '../../utils/services/apiRequests';
import dayjs from 'dayjs';
import * as yup from 'yup';

const CreateWorkItem = ({
  handleClickOpenWorkItem,
  handleCloseWorkItem,
  caseWorkItemOpen,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [activeWorker, setActiveWorker] = useState([]);

  const validateSchma = yup.object({
    caseId: yup.string().required('Case ID is Required field'),
    cmnts: yup.string().required('Comments is Required field'),
    dueDate: yup.string().required('Due date is Required field'),
    assignedTo: yup.string().required('Assigned To is Required field'),
  });

  const formik = useFormik({
    initialValues: {
      assignedTo: '',
      caseId: '',
      cmnts: '',
      dueDate: '',
    },
    onSubmit: (values) => {
      workItem(values);
    },
    validationSchema: validateSchma,
  });

  const workItem = async (data) => {
    try {
      showLoader();
      const res = await addWorkItem(data);
      if (res.status === 201) {
        showSuccessToast('Successfully Allerted');
        handleCloseWorkItem();
        formik.resetForm();
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const getWorker = async () => {
    try {
      showLoader();
      const res = await getActiveWorkers();
      if (res.status === 200) {
        setActiveWorker(res?.data);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const lookupData = useMemo(() => {
    return map(activeWorker, (value) => {
      return {
        label: value.firstName + ' ' + value.lastName,
        value: value.uniqueId,
      };
    });
  }, [activeWorker]);

  useEffect(() => {
    getWorker();
  }, []);

  return (
    <>
      <Dialog
        open={caseWorkItemOpen}
        onClose={handleCloseWorkItem}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '50rem',
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h3"> Assign Alert</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
            >
              <Field label="Case ID" formik={formik} name="caseId" required>
                <TextField placeholder="Case ID" />
              </Field>
              <Field
                name="assignedTo"
                formik={formik}
                label="Assign To"
                required
              >
                <Dropdown options={lookupData} />
              </Field>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
            >
              <Field label="Note" formik={formik} name="cmnts" required>
                <TextField
                  multiline
                  rows={2}
                  placeholder="Enter Note"
                  //   disabled={selectedStack === 1 ? false : true}
                />
              </Field>
              <Field
                name="dueDate"
                formik={formik}
                label="Response date"
                isDate
                required
              >
                <DatePicker
                  placeholder="Select Date"
                  disablePast
                  value={dayjs(formik.values.dueDate) || dayjs()}
                />
              </Field>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button onClick={handleCloseWorkItem} variant="outlined">
            Discard
          </Button>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            variant="contained"
          >
            Save & Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateWorkItem;
