import React, { useMemo } from 'react';
import useTabs from '../../../hooks/useTabs';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Field from '../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import FormSection from '../../../components/FormSection';
import YesNoOption from '../../../components/YesNoOption';
import { CheckBox } from '@mui/icons-material';
import Dropdown from '../../../components/Dropdown';
import { formatCurrency } from '../../../utils/normalizers';
import * as Yup from 'yup';
import useLookup from '../../../hooks/useLookup';
import { postShelter } from '../../../utils/services/ShelterExpenses/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import usePageNav from '../../../hooks/usePageNav';
import dayjs from 'dayjs';

export default function ShelterExpenses({
  toggleShelter,
  individualId,
  getShelterExp,
  shelterEdit,
  createShelter,
}) {
  const {
    Tabs,
    Tab,
    TabContent,
    TabPanel,
    onTabChange,
    activeTab,
    setActiveTab,
  } = useTabs();

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };
  const { caseId } = usePageNav();

  const validationSchema = Yup.object().shape(
    {
      beginDate: Yup.string().required('Please Select Begin date'),
      energyAssist: Yup.string().required('Please select Energy assistance'),
      heatingCooling: Yup.string().required('Please Select heating cooling'),
      subsidized: Yup.string().required('Please Select subsidized'),
      condo: Yup.string().when(['condoVerify'], {
        is: (condoVerify) => condoVerify,
        then: () => Yup.string().required('Please enter amount'),
        otherwise: () => Yup.string().notRequired(),
      }),
      condoVerify: Yup.string().when('condo', {
        is: (condo) => condo,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      groundRent: Yup.string().when(['groundVerify'], {
        is: (groundVerify) => groundVerify,
        then: () => Yup.string().required('Please enter amount'),
        otherwise: () => Yup.string().notRequired(),
      }),
      groundVerify: Yup.string().when('groundRent', {
        is: (groundRent) => groundRent,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      homeOwners: Yup.string().when(['homeVerify'], {
        is: (homeVerify) => homeVerify,
        then: () => Yup.string().required('Please enter amount'),
        otherwise: () => Yup.string().notRequired(),
      }),
      homeVerify: Yup.string().when('homeOwners', {
        is: (homeOwners) => homeOwners,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      moratge: Yup.string().when(['moratgeVerify'], {
        is: (moratgeVerify) => moratgeVerify,
        then: () => Yup.string().required('Please enter amount'),
        otherwise: () => Yup.string().notRequired(),
      }),
      moratgeVerify: Yup.string().when('moratge', {
        is: (moratge) => moratge,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      rent: Yup.string().when(['rentVerify'], {
        is: (rentVerify) => rentVerify,
        then: () => Yup.string().required('Please enter amount'),
        otherwise: () => Yup.string().notRequired(),
      }),
      rentVerify: Yup.string().when('rent', {
        is: (rent) => rent,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      taxes: Yup.string().when(['taxesVerify'], {
        is: (taxesVerify) => taxesVerify,
        then: () => Yup.string().required('Please enter amount'),
        otherwise: () => Yup.string().notRequired(),
      }),
      taxesVerify: Yup.string().when('taxes', {
        is: (taxes) => taxes,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      electric: Yup.string().when('electricVerify', {
        is: (electricVerify) => electricVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      electricVerify: Yup.string().when('electric', {
        is: (electric) => electric,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      garbage: Yup.string().when('garbageVerify', {
        is: (garbageVerify) => garbageVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      garbageVerify: Yup.string().when('garbage', {
        is: (garbage) => garbage,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      gas: Yup.string().when('gasVerify', {
        is: (gasVerify) => gasVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      gasVerify: Yup.string().when('gas', {
        is: (gas) => gas,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      oil: Yup.string().when('oilVerify', {
        is: (oilVerify) => oilVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      oilVerify: Yup.string().when('oil', {
        is: (oil) => oil,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      other: Yup.string().when('otherVerify', {
        is: (otherVerify) => otherVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      otherVerify: Yup.string().when('other', {
        is: (other) => other,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      phone: Yup.string().when('phoneVerify', {
        is: (phoneVerify) => phoneVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      phoneVerify: Yup.string().when('phone', {
        is: (phone) => phone,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      sewage: Yup.string().when('sewageVerify', {
        is: (sewageVerify) => sewageVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      sewageVerify: Yup.string().when('sewage', {
        is: (sewage) => sewage,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      water: Yup.string().when('waterVerify', {
        is: (waterVerify) => waterVerify,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      waterVerify: Yup.string().when('water', {
        is: (water) => water,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
    },
    [
      ['condo', 'condoVerify'],
      ['groundRent', 'groundVerify'],
      ['homeOwners', 'homeVerify'],
      ['moratge', 'moratgeVerify'],
      ['rent', 'rentVerify'],
      ['taxes', 'taxesVerify'],
      ['electric', 'electricVerify'],
      ['garbage', 'garbageVerify'],
      ['gas', 'gasVerify'],
      ['oil', 'oilVerify'],
      ['other', 'otherVerify'],
      ['phone', 'phoneVerify'],
      ['sewage', 'sewageVerify'],
      ['water', 'waterVerify'],
    ]
  );

  const { getOptions } = useLookup('ShelterExpense');
  const { verifyOpts } = useMemo(() => {
    return {
      verifyOpts: getOptions('CLMEDEXPNSEAMTVERCD'),
    };
  }, [getOptions]);

  const shelterMandatoryFields = () => {
    const shelterMandatory = [
      'beginDate',
      'energyAssist',
      'heatingCooling',
      'subsidized',
    ];
    return shelterMandatory.every((field) => !!formik.values[field]);
  };

  const formik = useFormik({
    initialValues: {
      dirtyStatus: createShelter ? 'insert' : 'update',
      beginDate: shelterEdit?.bgnDt ?? '',
      endDate: shelterEdit?.endDt ?? '',
      energyAssist: shelterEdit?.meapDnd ?? '',
      heatingCooling: shelterEdit?.heatCoolInd ?? '',
      subsidized: shelterEdit?.subzHousingInd ?? '',
      heatEat: '',
      condo: shelterEdit?.shelterExpenseTypes?.[0]?.shelterExpenseAmount ?? '',
      condoVerify:
        shelterEdit?.shelterExpenseTypes?.[0]
          ?.clientProvidedVerificationByShelterExpenseVerificationId
          ?.verificationSourceCode ?? '',
      groundRent:
        shelterEdit?.shelterExpenseTypes?.[1]?.shelterExpenseAmount ?? '',
      groundVerify:
        shelterEdit?.shelterExpenseTypes?.[1]
          ?.clientProvidedVerificationByShelterExpenseVerificationId
          ?.verificationSourceCode ?? '',
      homeOwners:
        shelterEdit?.shelterExpenseTypes?.[2]?.shelterExpenseAmount ?? '',
      homeVerify:
        shelterEdit?.shelterExpenseTypes?.[2]
          ?.clientProvidedVerificationByShelterExpenseVerificationId
          ?.verificationSourceCode ?? '',
      moratge:
        shelterEdit?.shelterExpenseTypes?.[3]?.shelterExpenseAmount ?? '',
      moratgeVerify:
        shelterEdit?.shelterExpenseTypes?.[3]
          ?.clientProvidedVerificationByShelterExpenseVerificationId
          ?.verificationSourceCode ?? '',
      rent: shelterEdit?.shelterExpenseTypes?.[4]?.shelterExpenseAmount ?? '',
      rentVerify:
        shelterEdit?.shelterExpenseTypes?.[4]
          ?.clientProvidedVerificationByShelterExpenseVerificationId
          ?.verificationSourceCode ?? '',
      taxes: shelterEdit?.shelterExpenseTypes?.[5]?.shelterExpenseAmount ?? '',
      taxesVerify:
        shelterEdit?.shelterExpenseTypes?.[5]
          ?.clientProvidedVerificationByShelterExpenseVerificationId
          ?.verificationSourceCode ?? '',
      electric:
        shelterEdit?.shelterUtilityTypes?.[0]?.utilityExpenseAmount ?? '',
      electricVerify:
        shelterEdit?.shelterUtilityTypes?.[0]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      garbage:
        shelterEdit?.shelterUtilityTypes?.[1]?.utilityExpenseAmount ?? '',
      garbageVerify:
        shelterEdit?.shelterUtilityTypes?.[1]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      gas: shelterEdit?.shelterUtilityTypes?.[2]?.utilityExpenseAmount ?? '',
      gasVerify:
        shelterEdit?.shelterUtilityTypes?.[2]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      oil: shelterEdit?.shelterUtilityTypes?.[3]?.utilityExpenseAmount ?? '',
      oilVerify:
        shelterEdit?.shelterUtilityTypes?.[3]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      other: shelterEdit?.shelterUtilityTypes?.[4]?.utilityExpenseAmount ?? '',
      otherVerify:
        shelterEdit?.shelterUtilityTypes?.[4]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      phone: shelterEdit?.shelterUtilityTypes?.[5]?.utilityExpenseAmount ?? '',
      phoneVerify:
        shelterEdit?.shelterUtilityTypes?.[5]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      sewage: shelterEdit?.shelterUtilityTypes?.[6]?.utilityExpenseAmount ?? '',
      sewageVerify:
        shelterEdit?.shelterUtilityTypes?.[6]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      water: shelterEdit?.shelterUtilityTypes?.[7]?.utilityExpenseAmount ?? '',
      waterVerify:
        shelterEdit?.shelterUtilityTypes?.[7]
          ?.clientProvidedVerificationByutilityExpenseVerificationId
          ?.verificationSourceCode ?? '',
      recordId: shelterEdit?.recordId ?? 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      postShelterExpenses(values);
    },
  });

  const postShelterExpenses = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postShelter(data, caseId, individualId);
      if (res.status === 200) {
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added The Shelter Expense Details');
          toggleShelter();
          getShelterExp();
        } else {
          showSuccessToast('Successfully Updated The Shelter Expense Details');
          toggleShelter();
          getShelterExp();
        }
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          onChange={onTabChange}
          value={activeTab}
          variant="fullWidth"
          centered
        >
          <Tab label="Shelter Expenses" />
          <Tab label="Utility Expenses" />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2} mt="1rem">
              <Grid item xs={6}>
                <Field
                  name="beginDate"
                  label="Effective Begin Date"
                  formik={formik}
                  isDate
                  required
                >
                  <DatePicker
                    placeholder="Select Date"
                    value={formik.values.beginDate}
                    minDate={dayjs().subtract(5, 'year')}
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="endDate"
                  label="Effective End Date"
                  formik={formik}
                  isDate
                >
                  <DatePicker
                    placeholder="Select Date"
                    value={formik.values.endDate}
                    minDate={dayjs().subtract(5, 'year')}
                  />
                </Field>
              </Grid>
            </Grid>
            <Grid container>
              <FormSection label="Shelter Costs" mt="2rem">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="energyAssist"
                      formik={formik}
                      label="Have you received Energy Assistance during the current Program Year"
                      required
                    >
                      <YesNoOption {...formik.getFieldProps('energyAssist')} />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="heatingCooling"
                      formik={formik}
                      label="Do you pay for heating/cooling separately from your rent?"
                      required
                    >
                      <YesNoOption
                        {...formik.getFieldProps('heatingCooling')}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6} mt="1rem">
                    <Field
                      name="subsidized"
                      formik={formik}
                      label="Do you live in subsidized housing?"
                      required
                    >
                      <YesNoOption {...formik.getFieldProps('subsidized')} />
                    </Field>
                  </Grid>
                  <Grid item xs={6} mt="1rem">
                    <Field name="heatEat" formik={formik} label="Heat & Eat">
                      <FormControlLabel
                        name="heatEat"
                        control={
                          <Checkbox
                            disabled
                            {...formik.getFieldProps('heatEat')}
                            checked={formik.values.heatEat === 'Y'}
                            // onChange={(e, checked) => {
                            //   formik.setFieldValue('parta', checked ? 'Y' : 'N');
                            // }}
                            value={formik.values.heatEat}
                          />
                        }
                        label={<Typography>Heat & Eat</Typography>}
                      />
                    </Field>
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
            <FormSection label="Please select your expense type:" mt="2rem">
              <Box
                sx={{
                  display: 'flex',
                  gap: '2rem',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    border: '1px solid #e2e2e7',
                    width: '50%',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    gap: '1rem',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      gap: '2rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Field
                      name="condo"
                      formik={formik}
                      label="Condo Fee"
                      normalizers={[formatCurrency]}
                      required
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={formik.values.condo}
                      />
                    </Field>

                    <Field
                      name="condoVerify"
                      formik={formik}
                      label="Verification"
                      required
                    >
                      <Dropdown
                        options={verifyOpts}
                        value={formik.values.condoVerify}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      gap: '2rem',
                    }}
                  >
                    <Field
                      name="groundRent"
                      formik={formik}
                      label="Ground/Lot Rent"
                      normalizers={[formatCurrency]}
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={formik.values.groundRent}
                      />
                    </Field>

                    <Field
                      name="groundVerify"
                      formik={formik}
                      label="Verification"
                    >
                      <Dropdown
                        options={verifyOpts}
                        value={formik.values.groundVerify}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      gap: '2rem',
                    }}
                  >
                    <Field
                      name="homeOwners"
                      formik={formik}
                      label="HomeOwner's Insurance"
                      normalizers={[formatCurrency]}
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={formik.values.homeOwners}
                      />
                    </Field>

                    <Field
                      name="homeVerify"
                      formik={formik}
                      label="Verification"
                    >
                      <Dropdown
                        options={verifyOpts}
                        value={formik.values.homeVerify}
                      />
                    </Field>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    border: '1px solid #e2e2e7',
                    width: '50%',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    gap: '1rem',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      gap: '2rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Field
                      name="moratge"
                      formik={formik}
                      label="Mortage"
                      normalizers={[formatCurrency]}
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={formik.values.moratge}
                      />
                    </Field>

                    <Field
                      name="moratgeVerify"
                      formik={formik}
                      label="Verification"
                    >
                      <Dropdown
                        options={verifyOpts}
                        value={formik.values.moratgeVerify}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      gap: '2rem',
                    }}
                  >
                    <Field
                      name="rent"
                      formik={formik}
                      label="Rent"
                      normalizers={[formatCurrency]}
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={formik.values.rent}
                      />
                    </Field>

                    <Field
                      name="rentVerify"
                      formik={formik}
                      label="Verification"
                    >
                      <Dropdown
                        options={verifyOpts}
                        value={formik.values.rentVerify}
                      />
                    </Field>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      gap: '2rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <Field
                      name="taxes"
                      formik={formik}
                      label="Taxes"
                      normalizers={[formatCurrency]}
                    >
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        value={formik.values.taxes}
                      />
                    </Field>

                    <Field
                      name="taxesVerify"
                      formik={formik}
                      label="Verification"
                    >
                      <Dropdown
                        options={verifyOpts}
                        value={formik.values.taxesVerify}
                      />
                    </Field>
                  </Grid>
                </Box>
              </Box>
            </FormSection>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <FormSection label="Please select your utility types:" mt="2rem">
            <Box
              sx={{
                display: 'flex',
                gap: '2rem',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  border: '1px solid #e2e2e7',
                  width: '50%',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  gap: '1rem',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="electric"
                    formik={formik}
                    label="Electric"
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.electric}
                    />
                  </Field>

                  <Field
                    name="electricVerify"
                    formik={formik}
                    label="Verification"
                  >
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.electricVerify}
                    />
                  </Field>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="garbage"
                    formik={formik}
                    label="Garbage"
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.garbage}
                    />
                  </Field>

                  <Field
                    name="garbageVerify"
                    formik={formik}
                    label="Verification"
                  >
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.garbageVerify}
                    />
                  </Field>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="gas"
                    formik={formik}
                    label="Gas"
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.gas}
                    />
                  </Field>

                  <Field name="gasVerify" formik={formik} label="Verification">
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.gasVerify}
                    />
                  </Field>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="sewage"
                    label="Sewage"
                    formik={formik}
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.sewage}
                    />
                  </Field>

                  <Field
                    name="sewageVerify"
                    formik={formik}
                    label="Verification"
                  >
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.sewageVerify}
                    />
                  </Field>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  border: '1px solid #e2e2e7',
                  width: '50%',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  gap: '1rem',
                }}
              >
                {' '}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="oil"
                    formik={formik}
                    label="Oil"
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.oil}
                    />
                  </Field>

                  <Field name="oilVerify" formik={formik} label="Verification">
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.oilVerify}
                    />
                  </Field>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="other"
                    formik={formik}
                    label="Other Utilities"
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.other}
                    />
                  </Field>

                  <Field
                    name="otherVerify"
                    formik={formik}
                    label="Verification"
                  >
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.otherVerify}
                    />
                  </Field>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="phone"
                    formik={formik}
                    label="Phone"
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.phone}
                    />
                  </Field>

                  <Field
                    name="phoneVerify"
                    formik={formik}
                    label="Verification"
                  >
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.phoneVerify}
                    />
                  </Field>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    marginTop: '1rem',
                  }}
                >
                  <Field
                    name="water"
                    formik={formik}
                    label="Water"
                    normalizers={[formatCurrency]}
                  >
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      value={formik.values.water}
                    />
                  </Field>

                  <Field
                    name="waterVerify"
                    formik={formik}
                    label="Verification"
                  >
                    <Dropdown
                      options={verifyOpts}
                      value={formik.values.waterVerify}
                    />
                  </Field>
                </Grid>
              </Box>
            </Box>
            {/* <Grid container spacing={2}> */}

            {/* </Grid> */}
          </FormSection>
        </TabPanel>
        {activeTab === 0 && (
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'flex-end'}
            mt={2}
          >
            <Button variant="outlined" onClick={toggleShelter}>
              Discard
            </Button>
            <Button
              variant="contained"
              disabled={!formik.isValid || !shelterMandatoryFields()}
              onClick={handleNext}
            >
              Next
            </Button>
          </Stack>
        )}

        {activeTab === 1 && (
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'flex-end'}
            mt={2}
          >
            <Button variant="outlined" onClick={toggleShelter}>
              Discard
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Save
            </Button>
          </Stack>
        )}
      </Box>
    </>
  );
}
