import React, { useState } from 'react';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { formatCurrency, maxLength, number } from '../../../utils/normalizers';

import { postRoomer } from '../../../utils/services/RoomerBoarder/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';

export default function RoomerModal({
  toggleClose,
  createRoomer,
  individualId,
  caseId,
  getRoomerDet,
  roomerEdit,
}) {
  const [showLoader, hideLoader] = useLoader();
  const validationSchema = Yup.object({
    beginDate: Yup.string().required('Please select Begin Date'),
    householdMembers: Yup.string().required(
      'Please enter number of household members'
    ),
    mealsDay: Yup.number()
      .lessThan(4, 'Meals per day can not be more than 3')
      .required('Please enter the number of meals per day'),
  });
  const formik = useFormik({
    initialValues: {
      dirtyStatus: createRoomer ? 'insert' : 'update',
      beginDate: roomerEdit?.effectiveBeginDate ?? '',
      endDate: roomerEdit?.effectiveEndDate ?? '',
      mealsDay: roomerEdit?.numberOfMealsPerDay ?? '',
      householdMembers: roomerEdit?.numberOfMealsPerDay ?? '',
      amountPaid: roomerEdit?.amountPaid ?? '',
      individualborderId: roomerEdit?.individualborderId ?? '',
      groupNumber: roomerEdit?.groupNumber ?? '',
      individualId,
    },
    validationSchema,
    onSubmit: (values) => {
      postRoomerBoarderDet(values);
    },
  });

  const postRoomerBoarderDet = async (data) => {
    try {
      showLoader();
      const res = await postRoomer(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('Successfully added the Roomer Boarder Details');
        toggleClose();
        getRoomerDet();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Add Roomer and Boarder Details</Typography>
      </Stack>
      <Grid mt="0.5rem">
        <FormSection>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="beginDate"
                label="Effective Begin Date"
                formik={formik}
                required
                isDate
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="endDate"
                label="Effective End Date"
                formik={formik}
                isDate
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="mealsDay"
                label="Meals Per Day"
                formik={formik}
                required
                normalizers={[maxLength(1), number]}
              >
                <TextField />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="householdMembers"
                label="No. of Household Members"
                formik={formik}
                required
                normalizers={[maxLength(2), number]}
              >
                <TextField />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                name="amountPaid"
                label="Amount Paid"
                formik={formik}
                normalizers={[formatCurrency]}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Field>
            </Grid>
          </Grid>
        </FormSection>
      </Grid>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
