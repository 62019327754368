export const compliancePayload = (data, events) => {
  return {
    clNonCmplncId: data?.clNonCmplncId ?? '',
    dirtyStatus: events,
    programId: 1195,
    typeCd: data?.typeCd ?? '',
    cureComply: data?.cureComply ?? '',
    decisionDt: data?.decisionDt ?? null,
    decisionType: data?.decisionType ?? '',
    cureComplyDt: data?.cureComplyDt ?? null,
    goodCause: data?.goodCause ?? null,
    vrfctn: {
      verificationSourceCode: data?.verificationSourceCode ?? '',
      documentDetails: [],
    },
    nonCmplncBgnDt: data?.nonCmplncBgnDt ?? null,
    nonCmplncEndDt: data?.nonCmplncEndDt ?? null,
    cmmnts: data?.cmmnts ?? null,
  };
};
