const LookUpPayload = {
  CaseHome: [
    { lookupTypeCd: 'AlertType' },
    { lookupTypeCd: 'RCVMTHD' },
    { lookupTypeCd: 'AlertSubType' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'PrimaryLanguage' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'ProgramStatus' },
    { lookupTypeCd: 'aidcd' },
    { lookupTypeCd: 'CaseMode' },
    { lookupTypeCd: 'CaseStatusCode' },
    { lookupTypeCd: 'ProgramModeCode' },
    { lookupTypeCd: 'EligibilityStatusCode' },
    { lookupTypeCd: 'EligibilityReasonCode' },
    { lookupTypeCd: 'FinancialResponsibility' },
    { lookupTypeCd: 'WorkParticipationStatusCd' },
  ],
  Citizenship: [
    { lookupTypeCd: 'Citizenship' },
    { lookupTypeCd: 'CitizenshipVerification' },
    { lookupTypeCd: 'CountryOfOrigin' },
    { lookupTypeCd: 'VoterRegisteration' },
    { lookupTypeCd: 'INSStatus' },
    { lookupTypeCd: 'RefugeeResettlementAgency' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'Documents' },

    // { lookupTypeCd: 'ProgramStatus' },
    // { lookupTypeCd: 'aidcd' },
    // { lookupTypeCd: 'CLDEATHVERCD' },
    // { lookupTypeCd: 'ClEthnicity' },
    // { lookupTypeCd: 'DeathMatchSecVerf' },
    // { lookupTypeCd: 'Ethnicity' },
    // { lookupTypeCd: 'Gender' },
    // { lookupTypeCd: 'MartialStatus' },
    // { lookupTypeCd: 'Race' },
    // { lookupTypeCd: 'SsnReferral' },
    // { lookupTypeCd: 'State' },
    // { lookupTypeCd: 'Suffix' },
    // { lookupTypeCd: 'CitizenshipVerification' },
    // { lookupTypeCd: 'DeleteMemberReasonCode' },
  ],
  Demographic: [
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'MartialStatus' },
    { lookupTypeCd: 'Ethnicity' },
    { lookupTypeCd: 'Race' },
    { lookupTypeCd: 'SsnReferral' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'ProgramStatus' },
    { lookupTypeCd: 'DeleteMemberReasonCode' },
  ],
  Dashboard: [
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'CaseStatusCode' },
    { lookupTypeCd: 'County' },
  ],
  CaseSummary: [
    { lookupTypeCd: 'AddAssetsAssetTypeParent' },
    { lookupTypeCd: 'Applications' },
    { lookupTypeCd: 'AppliedUnearnedIcomeStatus' },
    { lookupTypeCd: 'AssetType' },
    { lookupTypeCd: 'AuthRepTypeCd' },
    { lookupTypeCd: 'BenefitsPensions' },
    { lookupTypeCd: 'CLUIAMTVERCD' },
    { lookupTypeCd: 'CashAssistance' },
    { lookupTypeCd: 'ChildSpousalExpense' },
    { lookupTypeCd: 'Citizenship' },
    { lookupTypeCd: 'CompensationPayments' },
    { lookupTypeCd: 'ConcurrentOutofStateVerification' },
    { lookupTypeCd: 'ContributionsInKind' },
    { lookupTypeCd: 'DisabilityApprovalSource' },
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'DisabilityType' },
    { lookupTypeCd: 'EarnedIncomeVerificationCode' },
    { lookupTypeCd: 'EarningType' },
    { lookupTypeCd: 'EducationalAssistance' },
    { lookupTypeCd: 'Ethnicity' },
    { lookupTypeCd: 'FosterCareAdoption' },
    { lookupTypeCd: 'Frequency' },
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'HMOType' },
    { lookupTypeCd: 'HealthInsurance' },
    // { lookupTypeCd: "INSStatus" },
    { lookupTypeCd: 'Institution' },
    { lookupTypeCd: 'InsuranceDropped' },
    { lookupTypeCd: 'InterviewStatus' },
    { lookupTypeCd: 'LevelOfCare' },
    { lookupTypeCd: 'LivingArrangements' },
    { lookupTypeCd: 'Loans' },
    { lookupTypeCd: 'LumpSum' },
    { lookupTypeCd: 'MartialStatus' },
    { lookupTypeCd: 'MedicalExpenseType' },
    { lookupTypeCd: 'MedicareCertificationCode' },
    { lookupTypeCd: 'NeedsBasedAssistance' },
    { lookupTypeCd: 'Other' },
    { lookupTypeCd: 'PrimaryLanguage' },
    { lookupTypeCd: 'ProjectIndependence' },
    { lookupTypeCd: 'Race' },
    { lookupTypeCd: 'Relocation' },
    { lookupTypeCd: 'ReturnOnInvestments' },
    { lookupTypeCd: 'ShelterExpense' },
    { lookupTypeCd: 'ShelterRelated' },
    { lookupTypeCd: 'SignatureType' },
    { lookupTypeCd: 'SocialSecurity' },
    { lookupTypeCd: 'SsnReferral' },
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'SupportAlimony' },
    { lookupTypeCd: 'TDAPType' },
    { lookupTypeCd: 'UnearnedIncomeType' },
    { lookupTypeCd: 'UtilityExpense' },
    { lookupTypeCd: 'VariableInfrequent' },
    { lookupTypeCd: 'VendorPayments' },
    { lookupTypeCd: 'VolunteerRelated' },
    { lookupTypeCd: 'VoterRegisteration' },
    { lookupTypeCd: 'YesNo' },
  ],
  AppDispose: [
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'SignatureType' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'InterviewStatus' },
    { lookupTypeCd: 'InterviewType' },
    { lookupTypeCd: 'Program' },
  ],
  Relationship: [
    { lookupTypeCd: 'IndividualRelationship' },
    { lookupTypeCd: 'Gender' },
  ],
  LivingArrangement: [
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'LivingArrangement' },
    { lookupTypeCd: 'LivingArrangementVerificatin' },
    { lookupTypeCd: 'ParentalStatus' },
    { lookupTypeCd: 'ParentalStatusVerif' },
    { lookupTypeCd: 'Eidicsprmpaid' },
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'CLDEATHVERCD' },
    { lookupTypeCd: 'PrisonMatchSecVerf' },
    { lookupTypeCd: 'FosterCarAdoptType' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'SupportService' },
    { lookupTypeCd: 'ConcurrentOutofStateVerification' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'ConcurrentOutofStateCA' },
    { lookupTypeCd: 'SupportService' },
    { lookupTypeCd: 'VeteransStatus' },
    { lookupTypeCd: 'prmryprvntnsrcverf' },
    { lookupTypeCd: 'OTOMedicalVerification' },
    { lookupTypeCd: 'Documents' },
    { lookupTypeCd: 'Applications' },
    { lookupTypeCd: 'State' },
  ],
  Income: [
    { lookupTypeCd: 'EarningType' },
    { lookupTypeCd: 'EmployerInsuran ceAvailabilityIndicator' },
    { lookupTypeCd: 'Frequency' },
    { lookupTypeCd: 'EarnedIncomeVerificationCode' },
    { lookupTypeCd: 'EmploymentQuitReason' },
    { lookupTypeCd: 'UnearnedIncomeType' },
    { lookupTypeCd: 'BenefitsPensions' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'Documents' },
    { lookupTypeCd: 'AppliedUnearnedIcomeStatus' },
    { lookupTypeCd: 'AppliedUnearnedIncomeType' },
    { lookupTypeCd: 'appliedunearnedassettype' },
    { lookupTypeCd: 'PotentialAssetType' },
    { lookupTypeCd: 'CLUIAMTVERCD' },
    { lookupTypeCd: 'UnearnedIncomeExpType' },
    { lookupTypeCd: 'PhoneType' },
    { lookupTypeCd: 'EmployerInsuranceAvailabilityIndicator' },
    { lookupTypeCd: 'EmploymentVerification' },
    { lookupTypeCd: 'EmploymentQuitReason' },
    { lookupTypeCd: 'EarnedIncomeExpense' },
    { lookupTypeCd: 'AppliedIncomeVerification' },
    { lookupTypeCd: 'BenefitsPensions' },
    { lookupTypeCd: 'CashAssistance' },
    { lookupTypeCd: 'CompensationPayments' },
    { lookupTypeCd: 'NeedsBasedAssistance' },
    { lookupTypeCd: 'ContributionsIn-Kind' },
    { lookupTypeCd: 'EducationalAssistance' },
    { lookupTypeCd: 'FosterCareAdoption' },
    { lookupTypeCd: 'Loans' },
    { lookupTypeCd: 'LumpSum' },
    { lookupTypeCd: 'Other' },
    { lookupTypeCd: 'ProjectIndependence' },
    { lookupTypeCd: 'Relocation' },
    { lookupTypeCd: 'ReturnOnInvestments' },
    { lookupTypeCd: 'ShelterRelated' },
    { lookupTypeCd: 'SocialSecurity' },
    { lookupTypeCd: 'SupportAlimony' },
    { lookupTypeCd: 'VariableInfrequent' },
    { lookupTypeCd: 'VendorPayments' },
    { lookupTypeCd: 'VolunteerRelated' },
  ],
  ContactInfo: [
    { lookupTypeCd: 'ContactTime' },
    { lookupTypeCd: 'CommunicationMode' },
    { lookupTypeCd: 'PhoneType' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'HearingImpaired' },
    { lookupTypeCd: 'PrimaryLanguage' },
  ],
  MemberDetails: [
    { lookupTypeCd: 'Citizenship' },
    { lookupTypeCd: 'ImmigrationDocumentType' },
    { lookupTypeCd: 'CountryOfOrigin' },
    { lookupTypeCd: 'INSStatus' },
    { lookupTypeCd: 'RefugeeResettlementAgency' },
    { lookupTypeCd: 'ProgramStatus' },
    { lookupTypeCd: 'aidcd' },
    { lookupTypeCd: 'CLDEATHVERCD' },
    { lookupTypeCd: 'ClEthnicity' },
    { lookupTypeCd: 'DeathMatchSecVerf' },
    { lookupTypeCd: 'Ethnicity' },
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'MartialStatus' },
    { lookupTypeCd: 'Race' },
    { lookupTypeCd: 'SsnReferral' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'VoterRegisteration' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'CitizenshipVerification' },
    { lookupTypeCd: 'DeleteMemberReasonCode' },
    { lookupTypeCd: 'CLSSNVERCD' },
    { lookupTypeCd: 'CLDOBVERCD' },
  ],
  AlertSearch: [
    { lookupTypeCd: 'AlertDispRsnCd' },
    { lookupTypeCd: 'AlertSubType' },
    { lookupTypeCd: 'AlertType' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'aidcd' },
    { lookupTypeCd: 'ProgramStatus' },
    { lookupTypeCd: 'CaseStatusCode' },
  ],
  StatusBar: [{ lookupTypeCd: 'CaseStatusCode' }, { lookupTypeCd: 'Program' }],
  MediCare: [
    { lookupTypeCd: 'PolicySource' },
    { lookupTypeCd: 'RelationshipType' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'MedicareVerf' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'HealthInsurance' },
    { lookupTypeCd: 'PremiumOK' },
    { lookupTypeCd: 'HMOType' },
    { lookupTypeCd: 'InsuranceDropped' },
  ],
  Eligibility: [
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'EligibilityStatusCode' },
    { lookupTypeCd: 'ProgramType' },
    { lookupTypeCd: 'aidCd' },
    { lookupTypeCd: 'HCBWaiverType' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'AbawdMonthStatus' },
    { lookupTypeCd: 'EarningType' },
    { lookupTypeCd: 'UnearnedIncomeType' },
    { lookupTypeCd: 'AddAssetsAssetTypeParent' },
    { lookupTypeCd: 'AddAssetsAssetTypeParent' },
    { lookupTypeCd: 'clmerrtypecd' },
    { lookupTypeCd: 'clmtypecd' },
    { lookupTypeCd: 'ClmStatus' },
    { lookupTypeCd: 'MedCoverageGroup' },
    { lookupTypeCd: 'ELIGSSTATUS' },
    { lookupTypeCd: 'ELIGOVRDE' },
    { lookupTypeCd: 'clmrcvrytype' },
    { lookupTypeCd: 'clmrcvrysrc' },
    { lookupTypeCd: 'FinancialResponsibility' },
    { lookupTypeCd: 'FSExemptReason' },
    { lookupTypeCd: 'WorkParticipationStatusCd' },
    { lookupTypeCd: 'EligibilityReasonCode' },
    { lookupTypeCd: 'VerificationNames1052' },
  ],
  Wavier: [
    { lookupTypeCd: 'InstitutionType' },
    { lookupTypeCd: 'LevelAuth' },
    { lookupTypeCd: 'HCBWaiverType' },
    { lookupTypeCd: 'Waiver' },
    { lookupTypeCd: 'Institution' },
    { lookupTypeCd: 'MedicareCertificationCode' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'SpecialtyCodes' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'Status' },
    { lookupTypeCd: 'AlertDiVendorTypespRsnCd' },
    { lookupTypeCd: 'StatusReasonCode' },
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'InstituteProvider' },
    { lookupTypeCd: 'VendorType' },
    { lookupTypeCd: 'WaiverDenial' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'Documents' },
  ],
  Assets: [
    { lookupTypeCd: 'LiquidAssetType' },
    { lookupTypeCd: 'CLLIQASSETAMTVERCD' },
    { lookupTypeCd: 'ProgramCode' },
    { lookupTypeCd: 'PenaltyReason' },
    { lookupTypeCd: 'CLPRSNLENCUMBVERCD' },
    { lookupTypeCd: 'CLPRSNLPROPFMVVERCD' },
    { lookupTypeCd: 'CLPROPFMVAMTVERCD' },
    { lookupTypeCd: 'CLPROPENCUMBVERCD' },
    { lookupTypeCd: 'CLVEHFMVAMTVERCD' },
    { lookupTypeCd: 'CLVEHENCUMBVERCD' },
    { lookupTypeCd: 'CLTRNSFFMVVERCD' },
    { lookupTypeCd: 'UndueHardshipReason' },
    { lookupTypeCd: 'AUTransfereeRelationship' },
    { lookupTypeCd: 'CLTRNSFAMTVERCD' },
    { lookupTypeCd: 'ReasonForTransfer' },
    { lookupTypeCd: 'TransferOfAssetType' },
    { lookupTypeCd: 'PropertyTypeOther' },
    { lookupTypeCd: 'AddAssetsAssetTypeRealEstateProperties' },
    { lookupTypeCd: 'RealPropertyUse' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'VehicleUse' },
    { lookupTypeCd: 'VehicleType' },
    { lookupTypeCd: 'InsuranceCashValue' },
    { lookupTypeCd: 'Insurance' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'Documents' },
  ],
  Pregnancy: [{ lookupTypeCd: 'CLPREGVERCD' }, { lookupTypeCd: 'Documents' }],
  HouseholdDeclaration: [{ lookupTypeCd: 'CPFelonyConvictionType' }],
  EducationDetails: [
    { lookupTypeCd: 'CLSTDNTSTSVERCD' },
    { lookupTypeCd: 'EducationalLevel' },
    { lookupTypeCd: 'GOODSTUDENTVERCD' },
    { lookupTypeCd: 'GRADDATEVERDCODES' },
    { lookupTypeCd: 'GoodStandingSchoolAttendance' },
    { lookupTypeCd: 'HighestGrade' },
    { lookupTypeCd: 'IEPVerify' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'StudentStatusCode' },
    { lookupTypeCd: 'Documents' },
  ],

  AuthorizedRep: [
    { lookupTypeCd: 'AuthRepTypeCd' },
    { lookupTypeCd: 'AuthRepRelation' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'PhoneType' },
    { lookupTypeCd: 'AuthRepAccessType' },
    { lookupTypeCd: 'RequestedPrograms' },
  ],
  Inquiry: [
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'Race' },
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'VendorType' },
    { lookupTypeCd: 'Gender' },
  ],
  ProgramRequest: [
    { lookupTypeCd: 'RequestedPrograms' },
    { lookupTypeCd: 'SpecialRequest' },
    { lookupTypeCd: 'ReasonCode' },
  ],
  Notices: [
    { lookupTypeCd: 'NoticeType' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'aidcd' },
    { lookupTypeCd: 'ProgramStatus' },
    { lookupTypeCd: 'SuppressReason' },
    { lookupTypeCd: 'YesNo' },
  ],
  Reinitiate: [
    { lookupTypeCd: 'ProgramCode' },
    { lookupTypeCd: 'ProgramStatus' },
    { lookupTypeCd: 'ReinstationReopeningReason' },
    { lookupTypeCd: 'RCVMTHD' },
    { lookupTypeCd: 'RequestedPrograms' },
    { lookupTypeCd: 'EligibilityReasonCode' },
  ],
  Health: [
    { lookupTypeCd: 'DisabilityType' },
    { lookupTypeCd: 'DisabilityApprovalSource' },
    { lookupTypeCd: 'TDAPType' },
    { lookupTypeCd: 'MedicalReviewReceivedGA' },
    { lookupTypeCd: 'TreatmentParticipation' },
    { lookupTypeCd: 'VerfSourceYesNo' },
    { lookupTypeCd: 'DisabilityStatus' },
    { lookupTypeCd: 'VerificationWbcchp' },
    { lookupTypeCd: 'DisabilityVerification' },
    { lookupTypeCd: 'Documents' },
    { lookupTypeCd: 'SocialSecurityBenefits' },
    { lookupTypeCd: 'CLUIAMTVERCD' },
    { lookupTypeCd: 'SocialSecurityBenefitsStatus' },
    { lookupTypeCd: 'SocialSecurityBenefitsResult' },
    { lookupTypeCd: 'SocialSecurityBenefitsReason' },
  ],
  EligibilityConfirmation: [
    { lookupTypeCd: 'EligibilityStatusCode' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'ProgramCode' },
    { lookupTypeCd: 'aidcd' },
    { lookupTypeCd: 'clmerrtypecd' },
    { lookupTypeCd: 'clmtypecd' },
  ],
  Clearance: [
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'Race' },
  ],
  Redetermination: [
    { lookupTypeCd: 'RedeterminationStatus' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'ProgramCode' },
  ],
  CaseCreation: [
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'Ethnicity' },
    { lookupTypeCd: 'Race' },
    { lookupTypeCd: 'ProgramCode' },
    { lookupTypeCd: 'MartialStatus' },
    { lookupTypeCd: 'SsnReferral' },
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'PhoneType' },
    { lookupTypeCd: 'IndividualRelationship' },
    { lookupTypeCd: 'AuthRepRelation' },
    { lookupTypeCd: 'Citizenship' },
    { lookupTypeCd: 'AddAssetsAssetTypeParent' },
    { lookupTypeCd: 'Insurance' },
    { lookupTypeCd: 'LiquidAssetType' },
    { lookupTypeCd: 'VehicleType' },
    { lookupTypeCd: 'PropertyTypeOther' },
    { lookupTypeCd: 'RealPropertyUse' },
    { lookupTypeCd: 'TransferOfAssetType' },
    { lookupTypeCd: 'MedicalExpenseType' },
    { lookupTypeCd: 'Frequency' },
    { lookupTypeCd: 'ServiceProvided' },
    { lookupTypeCd: 'ChildSpousalExpense' },
    { lookupTypeCd: 'ShelterExpense' },
    { lookupTypeCd: 'UtilityExpense' },
    { lookupTypeCd: 'EarningType' },
    { lookupTypeCd: 'UnearnedIncomeType' },
  ],
  RedeterminationHistory: [
    { lookupTypeCd: 'RedeterminationStatus' },
    { lookupTypeCd: 'RequestedPrograms' },
    { lookupTypeCd: 'YesNo' },
  ],
  medicalExpenses: [
    { lookupTypeCd: 'MedicalExpenseType' },
    { lookupTypeCd: 'ServiceProvided' },
    {
      lookupTypeCd: 'Frequency',
    },
    { lookupTypeCd: 'CLMEDEXPNSEAMTVERCD' },
    { lookupTypeCd: 'Documents' },
  ],
  programTimeLimit: [
    {
      lookupTypeCd: 'ClockTypeCd',
    },
    { lookupTypeCd: 'tcatkstscd' },
    { lookupTypeCd: 'vtmclkverf' },
    {
      lookupTypeCd: 'tmclkrmdrs',
    },
    { lookupTypeCd: 'abwdtkstscd' },
    { lookupTypeCd: 'tcatkstscd' },
    { lookupTypeCd: 'State' },
  ],
  ShelterExpense: [
    { lookupTypeCd: 'CLMEDEXPNSEAMTVERCD' },
    { lookupTypeCd: 'ShelterExpense' },
    { lookupTypeCd: 'UtilityExpense' },
  ],
  dSNAP: [{ lookupTypeCd: 'DisasterStatus' }],
  HolidayYears: [{ lookupTypeCd: 'HoliDayYear' }],
  Issuance: [
    { lookupTypeCd: 'RequestedPrograms' },
    { lookupTypeCd: 'IssuanceMethod' },
    { lookupTypeCd: 'aidcd' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'Issuancstatus' },
    { lookupTypeCd: 'issntypcd' },
    { lookupTypeCd: 'ownrtype' },
    { lookupTypeCd: 'ProgramStatus' },
  ],
  ManualNotice: [
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'ManualNotice' },
    { lookupTypeCd: 'Department' },
  ],
  NonCustodialParent: [
    { lookupTypeCd: 'PhoneType' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'UnearnedIncomeType' },
    { lookupTypeCd: 'RelationOfAbsentParentToChild' },
    { lookupTypeCd: 'Gender' },
    { lookupTypeCd: 'Suffix' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'Deprivation' },
    { lookupTypeCd: 'GoodCauseReasons' },
    { lookupTypeCd: 'GoodCauseStatus' },
    { lookupTypeCd: 'Race' },
    { lookupTypeCd: 'AbsentParentRelationship' },
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'CountryOfOrigin' },
    { lookupTypeCd: 'MartialStatus' },
    { lookupTypeCd: 'HairColor' },
    { lookupTypeCd: 'EyeColor' },
    { lookupTypeCd: 'APVeteransStatus' },
    { lookupTypeCd: 'VeteranBranch' },
    { lookupTypeCd: 'RecepientDropDown' },
    { lookupTypeCd: 'BenefitsPensions' },
    { lookupTypeCd: 'CashAssistance' },
    { lookupTypeCd: 'CompensationPayments' },
    { lookupTypeCd: 'ContributionsInKind' },
    { lookupTypeCd: 'EducationalAssistance' },
    { lookupTypeCd: 'FosterCareAdoption' },
    { lookupTypeCd: 'Loans' },
    { lookupTypeCd: 'LumpSum' },
    { lookupTypeCd: 'NeedsBasedAssistance' },
    { lookupTypeCd: 'Other' },
    { lookupTypeCd: 'ProjectIndependence' },
    { lookupTypeCd: 'Relocation' },
    { lookupTypeCd: 'ReturnOnInvestments' },
    { lookupTypeCd: 'ShelterRelated' },
    { lookupTypeCd: 'SocialSecurity' },
    { lookupTypeCd: 'SupportAlimony' },
    { lookupTypeCd: 'VariableInfrequent' },
    { lookupTypeCd: 'VendorPayments' },
    { lookupTypeCd: 'VolunteerRelated' },
    { lookupTypeCd: 'ChildSupport' },
  ],
  Questionary: [{ lookupTypeCd: 'ReviewReason' }],

  AuditTrail: [{ lookupTypeCd: 'ProgramModeCode' }],

  NonCompliane: [
    {
      lookupTypeCd: 'NonComplianceType',
    },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'NonComplianceSNAPReasonCodesAndDescriptions' },
    { lookupTypeCd: 'GOODSTUDENTVERCD' },
    { lookupTypeCd: 'MRQRGoodCause' },
    { lookupTypeCd: 'Documents' },
    { lookupTypeCd: 'DecisionType' },
  ],
  worksPlan: [
    { lookupTypeCd: 'WorkPlanType' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'AssesmentQuestion' },
    { lookupTypeCd: 'DistrictOffice' },
    { lookupTypeCd: 'Program' },
    {
      lookupTypeCd: 'FSExemptReason',
    },
  ],
  WorkExemption: [
    { lookupTypeCd: 'FSExemptReason' },
    { lookupTypeCd: 'YesNo' },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'CHExemptReason' },
  ],
  Application: [
    {
      lookupTypeCd: 'InterviewType',
    },
    {
      lookupTypeCd: 'CaseMode',
    },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'CaseStatusCode' },
    { lookupTypeCd: 'AlertSubType' },
    { lookupTypeCd: 'WorkItemType' },
  ],
  Bulletin: [
    {
      lookupTypeCd: 'Portal',
    },
  ],
  IndividualAssist: [
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'AidcdShort' },
  ],
  Cola: [
    {
      lookupTypeCd: 'ColaType',
    },
    { lookupTypeCd: 'Program' },
    { lookupTypeCd: 'ProgramStatus' },
    { lookupTypeCd: 'ProgramModeCode' },
  ],
  EligibilityHistory: [{ lookupTypeCd: 'DateRange' }, { lookupTypeCd: 'Data' }],
  VenderDetails: [
    { lookupTypeCd: 'State' },
    { lookupTypeCd: 'County' },
    { lookupTypeCd: 'VendorType' },
    { lookupTypeCd: 'SpecialtyCodes' },
    { lookupTypeCd: 'Status' },
    { lookupTypeCd: 'StatusReasonCode' },
  ],
};
export default LookUpPayload;
