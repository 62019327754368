export const GET_PROGRAM_REQ =
  '/eeapi/application-process/interview/{0}/selected-program';

export const GET_SELECTED_PROGRAMS_REQ =
  '/eeapi/application-process/interview/{0}/selected-program-request';

export const POST_PROGRAM_REQ =
  '/eeapi/application-process/interview/{0}/requested-program';

export const SAVE_PROGRAM_REQ =
  '/eeapi/application-process/interview/{0}/program-request';

export const GET_DNSAP_IND_REQ =
  '/eeapi/application-process/screening/getDSNAPIndicator/{0}';
