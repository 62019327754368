import React from 'react';
import PropTypes from 'prop-types';
import SummaryContent from '../SummaryContent';
import TabularView from '../../../components/TabularView';

const columns = [
  {
    field: '',
    headerName: 'Person Name',
  },
  {
    field: '',
    headerName: 'Health Insurance',
  },
  {
    field: '',
    headerName: 'HMO',
  },
  {
    field: '',
    headerName: 'Managed Care',
  },
  {
    field: '',
    headerName: 'Insurance Dropped',
  },
  {
    field: '',
    headerName: 'Willing to Pay Premium',
  },
  {
    field: '',
    headerName: 'Buy In',
  },
  {
    field: '',
    headerName: 'Third Party Liability',
  },
  {
    field: '',
    headerName: 'Liability',
  },
  {
    field: '',
    headerName: 'Entitled to Medicare Part A',
  },
  {
    field: '',
    headerName: 'Purchase of Child Care',
  },
  {
    field: '',
    headerName: 'Update',
  },
];

function InsuranceInfo(props) {
  return (
    <SummaryContent title="Health Insurance Information">
      <TabularView
        sx={{
          table: {
            width: 'auto',
          },
          td: {
            border: 0,
            padding: '0 1.25rem 0.5rem 0',
          },
        }}
        headerComponent={{ variant: 'h6', color: 'var(--grey-400)' }}
        cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
        columns={columns}
      />
    </SummaryContent>
  );
}

InsuranceInfo.propTypes = {};

export default InsuranceInfo;
