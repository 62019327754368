import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import styles from './index.module.css';
import React from 'react';

import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

export default function IconLink({ icon: Icon, iconlabel }) {
  return (
    <div>
      <Stack alignItems={'center'}>
        <Grid className={styles.iconStyle}>
          <Icon />
        </Grid>
        <Typography sx={{ textAlign: 'center' }} variant="subtitle2">
          {iconlabel}
        </Typography>
      </Stack>
    </div>
  );
}
