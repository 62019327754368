import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Field from '../../../../components/Field';
import Dropdown from '../../../../components/Dropdown';
import { postVendorSearch } from '../../../../utils/services/waiverAndFacility/apiRequest';
import { showErrorToast } from '../../../../utils/services/toast';
import { hideLoader, showLoader } from '../../../../store/slices/loaderSlice';
import { useFormik } from 'formik';
import usePageNav from '../../../../hooks/usePageNav';
import { DataGrid } from '@mui/x-data-grid';
import { CloseRounded } from '@mui/icons-material';
import { postInstitutionSearch } from '../../../../utils/services/AssetsAPI/apiRequest';

export default function FinancialInstitution({
  toggleClosePop,
  selectedValue,
  stateOpts,
  getOptions,
}) {
  const [rowsData, setRowsData] = useState('');
  const { caseId } = usePageNav();
  const Column = [
    { field: 'institutionId', headerName: 'ID', flex: 1 },
    { field: 'institutionName', headerName: 'Name', flex: 1 },
    {
      field: 'addressLine1',
      headerName: 'Address',
      flex: 1,
      renderCell: (params) => {
        const { addressLine1, city, state, zip } = params?.row;
        return (
          <Typography>{`${addressLine1} ${city} ${getOptions(
            'State',
            state
          )} ${zip}`}</Typography>
        );
      },
    },

    {
      field: 'select',
      headerName: 'Select',
      flex: 1,
      renderCell: (params) => {
        return (
          <Button onClick={() => selectedValue(params.row)}>Select</Button>
        );
      },
    },
  ];

  const searchVendorID = async (data) => {
    try {
      showLoader();
      const res = await postInstitutionSearch(data);
      if (res.status === 200) {
        setRowsData(res.data.results);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      institutionId: '',
      institutionName: '',
      line1: '',
      city: '',
      state: '',
      zipCode: '',
    },
    onSubmit: (value) => {
      searchVendorID(value);
    },
  });

  return (
    <Box padding={2}>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
        <Typography variant="h3">Institution Search</Typography>
        <IconButton onClick={toggleClosePop}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Field name="institutionId" formik={formik} label="ID">
            <TextField placeholder="Enter Id" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="institutionName" formik={formik} label="Name">
            <TextField placeholder="Enter the Name" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="line1" formik={formik} label="Address Line">
            <TextField placeholder="Enter Address Line" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="city" formik={formik} label="City">
            <TextField placeholder="Enter the City" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="state" formik={formik} label="State" required>
            <Dropdown options={stateOpts} placeholder="Enter the State" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="zipCode" formik={formik} label="Zip Code">
            <TextField placeholder="Enter the Zip Code" />
          </Field>
        </Grid>
      </Grid>
      <Stack spacing={2} direction={'row'} mt={2} justifyContent={'flex-end'}>
        <Button variant="outlined" onClick={toggleClosePop}>
          Cancel
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Search
        </Button>
      </Stack>

      {rowsData && (
        <Box sx={{ height: '20rem', width: '98%' }}>
          <DataGrid
            getRowId={(row) => row.locationId}
            sx={{
              marginTop: 2,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: 16,
                fontWeight: 700,
                padding: '0px 10px',
                textAlign: 'center',
                color: 'black',
              },
            }}
            slots={{
              noRowsOverlay: 'noData',
            }}
            columns={Column ?? []}
            rows={rowsData ?? []}
          />
        </Box>
      )}
    </Box>
  );
}
