import React from 'react';
import SideMenu from './SideMenu';
import NavBar from './NavBar';
import { Outlet } from 'react-router-dom';
import styles from './index.module.css';

function Layout() {
  return (
    <div className={styles.layout}>
      <div className={styles.leftSection}>
        <SideMenu />
      </div>
      <div className={styles.rightSection}>
        <NavBar />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
