import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import styles from './index.module.css';
import { cond } from '../../utils/helpers';

function Fieldset({ title, children, size }) {
  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isLarge = size === 'large';

  const textVariant = cond([
    [isSmall, 'caption'],
    [isMedium, 'subtitle1'],
    [isLarge, 'h4'],
  ]);

  return (
    <div className={styles.fieldset}>
      {title && (
        <div className={styles.legend}>
          <Typography
            lineHeight={1}
            className={styles.title}
            variant={textVariant}
          >
            {title}
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
}

Fieldset.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Fieldset.defaultProps = {
  title: '',
  children: null,
  size: 'medium',
};

export default Fieldset;
