import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { POST_REINSTATE, POST_VALIDATE, POST_REINITIATE } from './apiUrls';
import {
  reinstatePayload,
  revalidatePayload,
  reinitiatePayload,
} from './apiPayload';

export const postReinstate = (data = {}, caseId) => {
  return HttpClient.post(
    format(POST_REINSTATE, caseId),
    reinstatePayload(data)
  );
};

export const postValidate = (data = {}, caseId) => {
  return HttpClient.post(
    format(POST_VALIDATE, caseId),
    revalidatePayload(data)
  );
};

export const postReinitiate = (data = {}, caseId) => {
  return HttpClient.post(
    format(POST_REINITIATE, caseId),
    reinitiatePayload(data)
  );
};
