import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AvatarGroup from '../AvatarGroup';
import { Button, Checkbox, Popover, Stack, Typography } from '@mui/material';
import { filter, includes, map } from 'lodash';
import styles from './index.module.css';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '../Avatar';
import cx from 'classnames';
import UserMenu from './UserMenu';

// const users = ['John Peter', 'Alexander Powel', 'Due Joh', 'Annah Sarrah'];

function UserSelect({ options, value, onSelect, onAdd }) {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const selectedList = filter(options, (o) => includes(value, o.value));
  const users = map(selectedList, ({ label }) => label);
  return (
    <div className={styles.userSelect} ref={ref}>
      {selectedList.length > 0 ? (
        <AvatarGroup names={users} onAdd={() => setOpen(true)} />
      ) : (
        <div className={open ? styles.block : styles.show}>
          <button onClick={() => setOpen(true)} className={styles.addButton}>
            <Avatar custom>
              <AddIcon />
            </Avatar>
            <Typography ml="0.5rem" variant="subtitle1">
              Add member
            </Typography>
          </button>
        </div>
      )}
      <Popover
        open={open}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setOpen(false)}
      >
        {open && (
          <UserMenu
            options={options}
            onAdd={onAdd}
            value={value}
            setOpen={setOpen}
          />
        )}
      </Popover>
    </div>
  );
}

UserSelect.propTypes = {
  value: PropTypes.array,
};

UserSelect.defaultProps = {
  value: [],
};

export default UserSelect;
