import {
  Button,
  Stack,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';

import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import Dropdown from '../../../components/Dropdown';
import FormGroup from '../../../components/FormGroup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

const AddVeteran = ({
  open,
  handleClose,
  veteranStatusOpts,
  onSaveVeteran,
  onUpdateVeteran,
  addVeteranStatus,
  editData,
  dob,
}) => {
  const validationSchema = Yup.object({
    effectiveBeginDate: Yup.string().required('Please select the Begin Date'),
    effectiveEndDate: Yup.string().required('Please select the End Date'),
    veteranStatus: Yup.string().required('Please select the Veteran Status'),
  });

  const formik = useFormik({
    initialValues: {
      effectiveBeginDate: editData?.effectiveBeginDate ?? '',
      effectiveEndDate: editData?.effectiveEndDate ?? '',
      relationToVeteran: editData ? null : '',
      veteranStatus: editData?.veteranStatus ?? '',
      recordId: editData?.recordId ?? '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (addVeteranStatus) {
        onSaveVeteran(values, 'insert');
      } else {
        onUpdateVeteran(values, 'update');
      }
    },
  });

  const onDateChange = (date, prop) => {
    formik.setFieldValue(prop, dayjs(date).format('YYYY-MM-DD'));
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullwidth>
      <DialogTitle sx={{ fontSize: 20 }}>
        {addVeteranStatus
          ? 'Add Veteran Status Information'
          : 'Edit Veteran Status Information'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid item xs={12}>
          <Grid>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ mb: '1rem' }}>
                  <Field
                    required
                    name="effectiveBeginDate"
                    label="Begin Date"
                    isDate
                    formik={formik}
                    formikHandler={false}
                  >
                    <DatePicker
                      disableFuture
                      value={formik.values.effectiveBeginDate}
                      onChange={(date) =>
                        onDateChange(date, 'effectiveBeginDate')
                      }
                      minDate={dayjs(dob)}
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="effectiveEndDate"
                    label="End Date"
                    formik={formik}
                    isDate
                    required
                  >
                    <DatePicker
                      minDate={dayjs(formik.values.effectiveBeginDate)}
                      disableFuture
                      value={dayjs(formik.values.effectiveEndDate)}
                      onChange={(date) =>
                        onDateChange(date, 'effectiveEndDate')
                      }
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormGroup>

            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ mb: '1rem' }}>
                  <Field
                    required
                    name="veteranStatus"
                    label="Status"
                    formik={formik}
                  >
                    <Dropdown
                      options={veteranStatusOpts}
                      placeholder="Select Status"
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVeteran;
