import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Divider,
  Stack,
  Box,
  Tab,
  Tabs,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EarnedIncomeDetails from './EarnedIncomeDetails';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';
import { some as Some, get, isEmpty } from 'lodash';
import styles from '../index.module.css';
import InputMask from 'react-input-mask';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
  customTabDesign: {
    '& .Mui-selected': {
      backgroundColor: '#44A0E3',
      color: '#fff',
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const EarnedIncomeForm = ({
  lkpRaw,
  isAdd,
  member,
  formik,
  selectedData,
  fedEmplrList,
  activeTab,
  setActiveTab,
  getOptions,
}) => {
  const classes = useStyles();
  const [selectedEmplr, setSelectedEmplr] = useState(null);

  const handleEmplrChange = (event, value) => {
    formik.setFieldValue(
      `employer.employerFederalId`,
      value ? value?.fedId : ''
    );
    setSelectedEmplr(value);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddIncomeAmount = () => {
    formik.setFieldValue('earnedIncomeAmount', [
      ...formik.values.earnedIncomeAmount,
      {
        effectiveBeginDate: null,
        effectiveEndDate: null,
        expenses: [],
        amountModel: [],
        frequency: '',
        lateReportIndicator: '',
        reportDate: null,
        useMonth: '',
      },
    ]);
  };

  const handleVoluntaryChange = (event, index) => {
    const { name, checked } = event.target;
    formik.setFieldValue(`employer.${name}`, checked ? 'Y' : 'N');
  };

  const handleDateChangePicker = (name, date) => {
    formik.setFieldValue(`employer.${name}`, dayjs(date).format('YYYY-MM-DD'));
  };

  function formatCurrency(value) {
    let converToCurrency = value.toString().replace(/\D/g, '');
    converToCurrency = converToCurrency.replace(/(\d)(\d{2})$/, '$1.$2');
    converToCurrency = converToCurrency.replace(/(?=(\d{3})+(\D))\B/g, ',');
    return converToCurrency;
  }
  const handleQuitChange = (event) => {
    const { name, value } = event.target;
    const currencyValue = formatCurrency(value);
    formik.setFieldValue(`employer.${name}`, currencyValue);
  };

  const checkIsEmptyFieldEmployer = (obj) => {
    const fieldsToExclude = [
      'quitDate',
      'rsnEmplmntQuit',
      'voluntaryQuitGoodCause',
      'voluntaryQuitYN',
      'wagesPerHours',
      'hoursPerMonth',
    ];
    for (const key in obj) {
      if (!fieldsToExclude.includes(key)) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          if (checkIsEmptyFieldEmployer(obj[key])) {
            return true;
          }
        } else if (obj[key] === '') {
          return true;
        }
      }
    }
    return false;
  };

  // const isEmptyStringPresent = checkIsEmptyFieldEmployer(formik.values.employer);

  const areAllEarnedFieldsFilled = () => {
    const { earnedIncomeAmount } = formik.values;
    for (let i = 0; i < earnedIncomeAmount.length; i++) {
      const income = earnedIncomeAmount[i];
      let isEmptyAmountModel = income.amountModel.some(
        (item) =>
          item.amount === '' ||
          item.verificationSourceCode === '' ||
          item.hours === ''
      );
      if (
        income.effectiveBeginDate === '' ||
        income.effectiveEndDate === '' ||
        income.frequency === '' ||
        isEmptyAmountModel
      ) {
        return false;
      }
    }
    return true;
  };

  const isIncomeTypeEmpty = Some([
    isEmpty(get(formik, 'values.incomeType')),
    isEmpty(get(formik, 'values.employer.employmentBeginDate')),
    isEmpty(get(formik, 'values.employer.companyLegalName')),
  ]);

  const validateIsEmpty = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.employer.${fieldName}`) &&
      get(formik, `touched.employer.${fieldName}`) &&
      get(formik, `values.employer.${fieldName}`) === '';

    return !!hasErrors;
  };

  const validateIsError = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.employer.${fieldName}`) &&
      get(formik, `touched.employer.${fieldName}`);

    return !!hasErrors;
  };

  const validateIncomeType = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) && get(formik, `touched.${fieldName}`);

    return !!hasErrors;
  };

  useEffect(() => {
    if (!isAdd) {
      formik.setValues({
        ...selectedData,
        incomeType: selectedData.incomeType,
        recordId: selectedData?.recordId,
        earnedIncomeAmount: selectedData.earnedIncomeAmount,
      });

      formik.resetForm({ values: selectedData });
      setSelectedEmplr({
        emplrName: selectedData.employer.companyLegalName,
        fedId: selectedData.employer.employerFederalId,
      });
    }
  }, [isAdd]);

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Tabs
          onChange={handleChange}
          value={activeTab}
          variant="fullWidth"
          centered
          className={classes.customTabDesign}
        >
          <Tab label="Employer Details" />
          <Tab
            label="Financial Details"
            disabled={
              isIncomeTypeEmpty ||
              formik.values.employer.employmentBeginDate === 'Invalid Date'
            }
          />
        </Tabs>

        <TabPanel value={activeTab} index={0}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  className={cx({
                    [classes.customTextField]: validateIncomeType(
                      formik,
                      'incomeType'
                    ),
                  })}
                >
                  <InputLabel id="income_type">Earned Income Type *</InputLabel>
                  <Select
                    labelId="income_type"
                    id="select_income_type"
                    label="Earned Income Type *"
                    name="incomeType"
                    value={formik.values.incomeType}
                    onChange={formik.handleChange}
                  >
                    {getOptions('EarningType').map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {validateIncomeType(formik, 'incomeType') && (
                    <FormHelperText error>
                      {formik.errors.incomeType}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </Box>

          <Box mt={2}>
            <Typography variant="h6">Employer Name and Address</Typography>
            <Divider />
            <Grid container spacing={2} mt={0.2}>
              <Grid item xs={6}>
                <Autocomplete
                  className={cx({
                    [classes.customTextField]: validateIsEmpty(
                      formik,
                      'companyLegalName'
                    ),
                  })}
                  options={fedEmplrList}
                  getOptionLabel={(option) => option?.emplrName}
                  value={selectedEmplr}
                  onChange={handleEmplrChange}
                  inputValue={formik.values.employer.companyLegalName}
                  onInputChange={(event, newInputValue) => {
                    formik.setFieldValue(
                      'employer.companyLegalName',
                      newInputValue
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Name *" variant="outlined" />
                  )}
                />
                {validateIsEmpty(formik, 'companyLegalName') && (
                  <FormHelperText error sx={{ ml: '10px' }}>
                    {formik.errors.employer?.companyLegalName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Federal ID"
                  fullWidth
                  size="small"
                  name="employerFederalId"
                  value={
                    selectedEmplr
                      ? selectedEmplr?.fedId
                      : formik.values.employer.employerFederalId
                  }
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 1"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="employer.employerAddress.addressLine1"
                  value={formik.values.employer.employerAddress.addressLine1}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Address Line 2"
                  fullWidth
                  margin="normal"
                  size="small"
                  name="employer.employerAddress.addressLine2"
                  value={formik.values.employer.employerAddress.addressLine2}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="City"
                      fullWidth
                      margin="normal"
                      size="small"
                      name="employer.employerAddress.city"
                      value={formik.values.employer.employerAddress.city}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Zipcode"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        onInput: (event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]/g,
                            ''
                          );
                        },
                      }}
                      inputProps={{ maxLength: 5 }}
                      size="small"
                      name="employer.employerAddress.zipcode"
                      value={formik.values.employer.employerAddress.zipcode}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="state">State</InputLabel>
                  <Select
                    labelId="state"
                    id="select-state"
                    label="State"
                    size="small"
                    name="employer.employerAddress.state"
                    value={formik.values.employer.employerAddress.state}
                    onChange={formik.handleChange}
                  >
                    {getOptions('State').map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box mt={1}>
            <Typography variant="h6">Employer Phone</Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputMask
                  mask="(999) 999-9999"
                  maskChar="_"
                  value={formik.values.employer.phoneNumber.phoneNumber}
                  onChange={formik.handleChange}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="Number"
                      fullWidth
                      margin="normal"
                      type="tel"
                      name="employer.phoneNumber.phoneNumber"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Enter Ext"
                  InputProps={{
                    onInput: (event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]/g,
                        ''
                      );
                    },
                  }}
                  inputProps={{ maxLength: 4 }}
                  fullWidth
                  margin="normal"
                  name="employer.phoneNumber.phoneNumberExt"
                  value={formik.values.employer.phoneNumber.phoneNumberExt}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="select-type">Select Type</InputLabel>
                  <Select
                    labelId="select-type"
                    id="select-type"
                    label="Select Type"
                    name="employer.phoneNumber.phoneNumberType"
                    value={formik.values.employer.phoneNumber.phoneNumberType}
                    onChange={formik.handleChange}
                  >
                    {getOptions('PhoneType').map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box mt={1}>
            <Typography variant="h6">Employment Details</Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box mt={1} mb={1}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      className={cx({
                        [classes.customTextField]: validateIsError(
                          formik,
                          'employmentBeginDate'
                        ),
                      })}
                      label="Employment Start Date *"
                      sx={{ width: '100%' }}
                      margin="normal"
                      format="YYYY-MM-DD"
                      renderInput={(params) => <TextField {...params} />}
                      value={
                        formik.values.employer?.employmentBeginDate
                          ? dayjs(formik.values.employer.employmentBeginDate)
                          : null
                      }
                      onChange={(date) =>
                        handleDateChangePicker('employmentBeginDate', date)
                      }
                    />
                  </DemoContainer>
                  {validateIsError(formik, 'employmentBeginDate') && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      {formik.errors?.employer?.employmentBeginDate}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1} mb={1}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      className={cx({
                        [classes.customTextField]: validateIsError(
                          formik,
                          'employmentEndDate'
                        ),
                      })}
                      label="Employment End Date"
                      sx={{ width: '100%' }}
                      margin="normal"
                      format="YYYY-MM-DD"
                      minDate={dayjs(
                        formik.values.employer.employmentBeginDate
                      )}
                      renderInput={(params) => <TextField {...params} />}
                      value={
                        formik.values.employer?.employmentEndDate
                          ? dayjs(
                              formik.values.employer?.employmentEndDate
                            ).year() === 9999
                            ? null
                            : dayjs(formik.values.employer?.employmentEndDate)
                          : null
                      }
                      onChange={(date) =>
                        handleDateChangePicker('employmentEndDate', date)
                      }
                      disabled={!formik.values.employer.employmentBeginDate}
                    />
                  </DemoContainer>
                  {validateIsError(formik, 'employmentEndDate') && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      {formik.errors?.employer?.employmentEndDate}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="emp-insurance">Employer Insurance</InputLabel>
                  <Select
                    labelId="emp-insurance"
                    id="select-emp-insurance"
                    label="Employer Insurance"
                    name="employer.insurance"
                    value={formik.values.employer.insurance}
                    onChange={formik.handleChange}
                  >
                    {getOptions('EmployerInsuranceAvailabilityIndicator').map(
                      (item, index) => (
                        <MenuItem key={item.index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="emp-verification">
                    Employment Verification
                  </InputLabel>
                  <Select
                    labelId="emp-verification"
                    id="select-emp-verification"
                    label="Employment Verification"
                    name="employer.employmentVerification.verificationSourceCode"
                    value={
                      formik.values.employer.employmentVerification
                        .verificationSourceCode
                    }
                    onChange={formik.handleChange}
                  >
                    {getOptions('EmploymentVerification').map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Box mt={2}>
            <Typography variant="h6">Voluntary Quit Information</Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.employer.voluntaryQuitYN === 'Y'}
                      name="voluntaryQuitYN"
                      value={formik.values.employer.voluntaryQuitYN}
                      onChange={(e) => handleVoluntaryChange(e)}
                    />
                  }
                  label="Voluntary Quit"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formik.values.employer.voluntaryQuitGoodCause === 'Y'
                      }
                      name="voluntaryQuitGoodCause"
                      value={formik.values.employer.voluntaryQuitGoodCause}
                      onChange={(e) => handleVoluntaryChange(e)}
                    />
                  }
                  label="Voluntary Quit Good Cause"
                />
              </Grid>
            </Grid>
            {formik.values.employer.voluntaryQuitYN === 'Y' && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box mt={1} mb={1}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          className={cx({
                            [classes.customTextField]: validateIsError(
                              formik,
                              'quitDate'
                            ),
                          })}
                          label="Quit Date *"
                          sx={{ width: '100%' }}
                          margin="normal"
                          format="YYYY-MM-DD"
                          renderInput={(params) => <TextField {...params} />}
                          value={
                            formik.values.employer?.quitDate
                              ? dayjs(formik.values.employer.quitDate)
                              : null
                          }
                          onChange={(date) =>
                            handleDateChangePicker('quitDate', date)
                          }
                        />
                      </DemoContainer>
                      {validateIsError(formik, 'quitDate') && (
                        <FormHelperText error sx={{ ml: '10px' }}>
                          {formik.errors.employer?.quitDate}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Wages/Hours"
                      fullWidth
                      margin="normal"
                      name="wagesPerHours"
                      InputProps={{
                        Adornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      inputProps={{ maxLength: 11 }}
                      value={formik.values.employer.wagesPerHours}
                      onChange={(event) => handleQuitChange(event)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Hours Per Month"
                      fullWidth
                      margin="normal"
                      name="employer.hoursPerMonth"
                      value={formik.values.employer.hoursPerMonth}
                      onChange={formik.handleChange}
                      InputProps={{
                        onInput: (event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]/g,
                            ''
                          );
                        },
                      }}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      margin="normal"
                      className={cx({
                        [classes.customTextField]: validateIsError(
                          formik,
                          'rsnEmplmntQuit'
                        ),
                      })}
                    >
                      <InputLabel id="emp-quit">
                        Reason for Employment Quit *
                      </InputLabel>
                      <Select
                        labelId="emp-quit"
                        id="select-emp-quit"
                        label="Reason for Employment Quit *"
                        name="employer.rsnEmplmntQuit"
                        value={formik.values.employer.rsnEmplmntQuit}
                        onChange={formik.handleChange}
                      >
                        {getOptions('EmploymentQuitReason').map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {validateIsError(formik, 'rsnEmplmntQuit') && (
                        <FormHelperText error sx={{ ml: '10px' }}>
                          {formik.errors.employer?.rsnEmplmntQuit}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>

          <Box>
            <Stack direction="row" pt={1} justifyContent="space-between">
              <Typography variant="h6" p={0.5}>
                Earned Income Details
              </Typography>
              <Button
                variant="text"
                size="small"
                p={0.5}
                color="primary"
                onClick={handleAddIncomeAmount}
                sx={{ cursor: 'pointer' }}
                disabled={!areAllEarnedFieldsFilled()}
              >
                + Add Earned Income Amount
              </Button>
            </Stack>
          </Box>
          {formik.values?.earnedIncomeAmount.map((amount, index) => (
            <Box sx={{ border: '1px solid #e4e4e4', padding: '1rem' }}>
              <EarnedIncomeDetails
                key={index}
                incomeIndex={index}
                incomeAmount={amount}
                lkpRaw={lkpRaw}
                formik={formik}
                isAdd={isAdd}
                getOptions={getOptions}
              />
            </Box>
          ))}
        </TabPanel>
      </Box>
    </>
  );
};

export default EarnedIncomeForm;
