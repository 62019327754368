import React from 'react';
import styles from '../index.module.css';
import {
  Button,
  Container,
  FormGroup,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Field from '../../../components/Field';
import useCaseStatus from '../../../hooks/useCaseStatus';
import { number, maxLength } from '../../../utils/normalizers';
// import usePageNav from '../../hooks/usePageNav';

export default function Initiate() {
  const { getCaseStatus } = useCaseStatus();
  const navigate = useNavigate();

  const onNewCase = () => {
    navigate('register');
  };
  const validationSchema = Yup.object({
    caseId: Yup.string()
      .max(9, 'Please enter valid case id')
      .required('Please enter case id'),
  });

  const formik = useFormik({
    initialValues: {
      caseId: '',
    },
    validationSchema,
    onSubmit: ({ caseId }) => {
      getCaseStatus(caseId);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h5" marginBottom="3.5rem">
        Initiate
      </Typography>
      <Field
        name="caseId"
        label="CASE ID"
        normalizers={[number, maxLength(9)]}
        formik={formik}
      >
        <TextField placeholder="Enter Case Id" inputProps={{ maxLength: 9 }} />
      </Field>
      <Button
        type="submit"
        sx={{ marginTop: '5rem' }}
        variant="contained"
        fullWidth
      >
        Existing case
      </Button>
      <div className={styles.divider}>
        <span>or</span>
      </div>
      <Button variant="outlined" fullWidth onClick={onNewCase}>
        New case
      </Button>
    </form>
  );
}
