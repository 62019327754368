import React, { useEffect, useMemo, useState } from 'react';
import useTabs from '../../../hooks/useTabs';
import {
  getAssetApi,
  postPolicyInsert,
  putDelete,
  putPolicyInsert,
} from '../../../utils/services/AssetsAPI/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import usePageNav from '../../../hooks/usePageNav';
import InsurancePolicy from '../InsurancePolicy';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { find, map, sumBy } from 'lodash';
import useLookup from '../../../hooks/useLookup';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from '../../../components/ActionButtons';
import LiquidAsset from '../LiquidAsset';
import MotorVehicle from '../MotorVehicle';
import { CloseRounded, DeleteOutlineOutlined } from '@mui/icons-material';
import useLoader from '../../../hooks/useLoader';
import OtherAssets from '../OtherAsset';
import Estate from '../Estate';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../../store/selectors/authSelector';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/assets.svg';

export default function AssetContent({ selected, setAssetLoader }) {
  const [apiData, setApiData] = useState('');
  const [rowData, setRowData] = useState('');
  const [typeOne, setTypeOne] = useState([]);
  const [typeTwo, setTypeTwo] = useState([]);
  const [typeThree, setTypeThree] = useState([]);
  const [typeFour, setTypeFour] = useState([]);
  const [typeFive, setTypeFive] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [policyPopup, setPolicyPopup] = useState(false);
  const [policyPopupEdit, setPolicyPopupEdit] = useState(false);
  const [liquidPopup, setLiquidPopup] = useState(false);
  const [otherPopUp, setOtherPopup] = useState(false);
  const [estatePopUp, setEstatePopup] = useState(false);
  const [otherPopupEdit, setOtherPopupEdit] = useState(false);
  const [estatePopupEdit, setEstatePopupEdit] = useState(false);
  const [editData, setEditData] = useState('');
  const [deleteData, setDeleteData] = useState('');
  const [liquidPopupEdit, setLiquidPopupEdit] = useState(false);
  const [motorPopup, setMotorPopup] = useState(false);
  const [motorPopupEdit, setMotorPopupEdit] = useState(false);
  const [deletePop, setDeletePop] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const { getLkpMatch, getOptions, lkpRaw } = useLookup('Assets');
  const { Tabs, Tab, TabContent, onTabChange, activeTab } = useTabs();
  const { individualId } = selected;
  const { caseId } = usePageNav();
  const [docOpen, setDocOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(null);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const token = useSelector(getAuthToken);

  useEffect(() => {
    getAssets();
  }, []);
  useEffect(() => {
    individualData();
  }, [apiData]);
  const params = useParams();
  // const individualDetails = useMemo(() => {
  //   setTypeOne([]);
  //   setTypeTwo([]);
  //   setTypeThree([]);
  //   find(apiData, (value) => {
  //     if (value.individualId === individualId) {
  //       map(value?.assets, (assets) => {
  //         switch (assets?.assetType) {
  //           case '1':
  //             setTypeOne((prev) => [...prev, assets]);
  //             break;
  //           case '2':
  //             setTypeTwo((prev) => [...prev, assets]);
  //             break;
  //           case '3':
  //             setTypeThree((prev) => [...prev, assets]);
  //             break;
  //         }
  //       });
  //     }
  //   });
  // }, [apiData]);

  const individualData = () => {
    setTypeOne([]);
    setTypeTwo([]);
    setTypeThree([]);
    setTypeFive([]);
    setTypeFour([]);
    find(apiData, (value) => {
      if (value.individualId === individualId) {
        map(value?.assets, (assets) => {
          switch (assets?.assetType) {
            case '1':
              setTypeOne((prev) => [...prev, assets]);
              break;
            case '2':
              setTypeTwo((prev) => [...prev, assets]);
              break;
            case '3':
              setTypeThree((prev) => [...prev, assets]);
              break;
            case '4':
              setTypeFour((prev) => [...prev, assets]);
              break;
            case '5':
              setTypeFive((prev) => [...prev, assets]);
              break;
          }
        });
      }
    });
  };

  const getAssets = async () => {
    try {
      // setAssetLoader(true);
      const res = await getAssetApi(caseId);
      if (res.status === 200) {
        setApiData(res.data.householdMembers);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      // setAssetLoader(false);
    }
  };
  // PopUp

  const AddInstitutionDetails = () => {
    setPolicyPopup(true);
  };
  const AddLiquidAsset = () => {
    setLiquidPopup(true);
  };
  const AddMotorAsset = () => {
    setMotorPopup(true);
  };
  const AddotherAsset = () => {
    setOtherPopup(true);
  };
  const AddestateAsset = () => {
    setEstatePopup(true);
  };
  const toggleClose = () => {
    setPolicyPopup(false);
    setLiquidPopup(false);
    setMotorPopup(false);
    setPolicyPopupEdit(false);
    setLiquidPopupEdit(false);
    setMotorPopupEdit(false);
    setDeletePop(false);
    setOtherPopup(false);
    setEstatePopup(false);
    setOtherPopupEdit(false);
    setEstatePopupEdit(false);
  };

  const policyColumns = useMemo(
    () => [
      {
        field: 'institutionName',
        headerName: 'Institution Name',
        width: 140,
      },
      {
        field: 'policyNumber',
        headerName: 'Policy Number',
        width: 130,
      },
      {
        field: 'effectiveBeginDate',
        headerName: 'Begin Date',
        width: 100,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        renderCell: (params) =>
          params.row.effectiveEndDate === '9999-12-31' && '-',
        width: 100,
      },
      {
        field: 'assetDetailType',
        headerName: 'Type',
        width: 100,
        renderCell: (params) =>
          getLkpMatch('Insurance', params.row.assetDetailType),
      },
      {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editInsurance(params?.row)}
                onDelete={() => deletePopup(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch || apiData]
  );
  const liquidColumns = useMemo(
    () => [
      {
        field: 'institutionName',
        headerName: 'Institution Name',
        // LiquidAssetType
        width: 140,
      },
      {
        field: 'accountNumber',
        headerName: 'Account Number',
        width: 150,
      },
      {
        field: 'effectiveBeginDate',
        headerName: 'Begin Date',
        width: 100,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        renderCell: (params) =>
          params.row.effectiveEndDate === '9999-12-31' && '-',

        width: 100,
      },
      {
        field: 'assetDetailType',
        headerName: 'Type',
        width: 100,
        renderCell: (params) =>
          getLkpMatch('LiquidAssetType', params.row.assetDetailType),
      },
      {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editPocliy(params?.row)}
                onDelete={() => deletePopup(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch, apiData]
  );
  const motorColumns = useMemo(
    () => [
      {
        field: 'year',
        headerName: 'Year',
        width: 70,
      },
      {
        field: 'assetDetailType',
        headerName: 'Make & Model',
        renderCell: (params) =>
          getLkpMatch('VehicleType', params.row.assetDetailType),
        width: 140,
      },
      {
        field: 'licenseNumber',
        headerName: 'License Number',
        width: 140,
      },
      {
        field: 'effectiveBeginDate',
        headerName: 'Begin Date',
        width: 100,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        renderCell: (params) =>
          params.row.effectiveEndDate === '9999-12-31' && '-',
        width: 100,
      },

      {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editMotor(params?.row)}
                onDelete={() => deletePopup(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch, apiData]
  );

  const otherColumns = useMemo(
    () => [
      {
        field: 'assetDetailType',
        headerName: 'Make & Model',
        renderCell: (params) =>
          getLkpMatch('PropertyTypeOther', params.row.assetDetailType),

        width: 130,
      },

      {
        field: 'effectiveBeginDate',
        headerName: 'Begin Date',
        width: 100,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        renderCell: (params) =>
          params.row.effectiveEndDate === '9999-12-31' && '-',
        width: 100,
      },

      {
        field: 'Fair Market',
        headerName: 'Fair Market Value',
        renderCell: (params) => {
          const totalSumFair = sumBy(
            params.row.assetAmounts,
            (value) => value?.fareMarketValue
          );

          return '$' + ' ' + totalSumFair;
        },
        width: 160,
      },
      {
        field: 'Encumbered Amount',
        headerName: 'Encumbered Amount',
        renderCell: (params) => {
          const totalSumEncumbered = sumBy(
            params.row.assetAmounts,
            (value) => value.encumberedAmount
          );

          return '$' + ' ' + totalSumEncumbered;
        },
        width: 180,
      },

      {
        field: 'action',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editOther(params?.row)}
                onDelete={() => deletePopup(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch, apiData]
  );

  const estateColumns = useMemo(
    () => [
      {
        field: 'adress',
        headerName: 'Address',
        width: 90,
      },

      {
        field: 'use',
        headerName: 'Use',
        renderCell: (params) =>
          getLkpMatch('RealPropertyUse', params.row.assetDetailType),

        width: 90,
      },
      {
        field: 'effectiveBeginDate',
        headerName: 'Begin Date',
        width: 100,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        renderCell: (params) =>
          params.row.effectiveEndDate === '9999-12-31' && '-',
        width: 100,
      },

      {
        field: 'Fair Market',
        headerName: 'Fair Market Value',
        renderCell: (params) => {
          const totalSumFair = sumBy(
            params.row.assetAmounts,
            (value) => value?.fareMarketValue
          );

          return '$' + ' ' + totalSumFair;
        },
        width: 160,
      },
      {
        field: 'Encumbered Amount',
        headerName: 'Encumbered Amount',
        renderCell: (params) => {
          const totalSumEncumbered = sumBy(
            params.row.assetAmounts,
            (value) => value.encumberedAmount
          );

          return '$' + ' ' + totalSumEncumbered;
        },
        width: 150,
      },

      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editEstate(params?.row)}
                onDelete={() => deletePopup(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch, apiData]
  );

  // editEstate

  const gridData = useMemo(() => {
    if (activeTab === 0) {
      setColumnsData(policyColumns);
      setRowData(typeTwo);
    }
    if (activeTab === 1) {
      setColumnsData(liquidColumns);
      setRowData(typeOne);
    }
    if (activeTab === 2) {
      setColumnsData(motorColumns);
      setRowData(typeThree);
    }
    if (activeTab === 3) {
      setColumnsData(otherColumns);
      setRowData(typeFive);
    }
    if (activeTab === 4) {
      setColumnsData(estateColumns);
      setRowData(typeFour);
    }
  }, [activeTab, typeOne, typeTwo, typeThree, apiData, typeFour, typeFive]);

  const editInsurance = (data) => {
    setEditData(data);
    setPolicyPopupEdit(true);
  };
  const editPocliy = (data) => {
    setEditData(data);
    setLiquidPopupEdit(true);
  };
  const editMotor = (data) => {
    setEditData(data);
    setMotorPopupEdit(true);
  };

  const editOther = (data) => {
    setEditData(data);
    setOtherPopupEdit(true);
  };

  const editEstate = (data) => {
    setEditData(data);
    setEstatePopupEdit(true);
  };

  const deletePopup = (data) => {
    setDeleteData(data);
    setDeletePop(true);
  };

  const insertAPI = async (data) => {
    try {
      showLoader();
      const res = await postPolicyInsert(caseId, individualId, data);
      if (res.status === 200) {
        showSuccessToast('Record Created Successfully');
        getAssets();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const editApi = async (data) => {
    try {
      showLoader();
      const res = await putPolicyInsert(caseId, individualId, data);
      if (res.status === 200) {
        showSuccessToast('Record Updated Successfully');
        getAssets();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const deleteApi = async () => {
    try {
      showLoader();
      const res = await putDelete(
        caseId,
        individualId,
        deleteData,
        token?.name
      );
      if (res.status === 200) {
        getAssets();
        showSuccessToast('Record Deleted Successfully');
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  return (
    <div>
      <Dialog open={deletePop} onClose={toggleClose}>
        <DialogContent>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <DeleteOutlineOutlined
              sx={{ height: 100, width: 100, color: '#cf4242' }}
            />
            <Typography color="gray" variant="h3">
              Delete the record?
            </Typography>

            <Typography my="1rem" variant="subtitle1">
              Do you really want to delete ? You will not able to recover it.
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button color="error" variant="outlined" onClick={toggleClose}>
                Discard
              </Button>
              <Button
                color="error"
                sx={{ mt: '1rem' }}
                variant="contained"
                onClick={deleteApi}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog open={policyPopup} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Insurance Policy</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <InsurancePolicy
            createNew
            getOptions={getOptions}
            insertAPI={insertAPI}
            toggleClose={toggleClose}
            lkpRaw={lkpRaw}
            selected={selected}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={policyPopupEdit} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Insurance Policy</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <InsurancePolicy
            editData={editData}
            insertAPI={insertAPI}
            getOptions={getOptions}
            editApi={editApi}
            toggleClose={toggleClose}
            lkpRaw={lkpRaw}
            selected={selected}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={liquidPopup} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Liquid Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <LiquidAsset
            createNew
            getOptions={getOptions}
            insertAPI={insertAPI}
            toggleClose={toggleClose}
            lkpRaw={lkpRaw}
            selected={selected}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={liquidPopupEdit} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Liquid Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <LiquidAsset
            editData={editData}
            insertAPI={insertAPI}
            getOptions={getOptions}
            editApi={editApi}
            toggleClose={toggleClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={motorPopup} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Typography variant="h3">Add Motor Vehicle</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <MotorVehicle
            createNew
            getOptions={getOptions}
            insertAPI={insertAPI}
            toggleClose={toggleClose}
            lkpRaw={lkpRaw}
            selected={selected}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={motorPopupEdit} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Motor Vehicle</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <MotorVehicle
            editData={editData}
            insertAPI={insertAPI}
            getOptions={getOptions}
            editApi={editApi}
            toggleClose={toggleClose}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={otherPopUp} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Typography variant="h3">Add Other Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <OtherAssets
            createNew
            getOptions={getOptions}
            insertAPI={insertAPI}
            toggleClose={toggleClose}
            lkpRaw={lkpRaw}
            selected={selected}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={otherPopupEdit} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Other Asset</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <OtherAssets
            editData={editData}
            insertAPI={insertAPI}
            getOptions={getOptions}
            editApi={editApi}
            toggleClose={toggleClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={estatePopUp} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Typography variant="h3">
              Add Real Estate Property/Burial Plot
            </Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Estate
            createNew
            getOptions={getOptions}
            insertAPI={insertAPI}
            toggleClose={toggleClose}
            lkpRaw={lkpRaw}
            selected={selected}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={estatePopupEdit} maxWidth={'md'} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">
              Edit Real Estate Property/Burial Plot
            </Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Estate
            editData={editData}
            insertAPI={insertAPI}
            getOptions={getOptions}
            editApi={editApi}
            toggleClose={toggleClose}
          />
        </DialogContent>
      </Dialog>

      <Tabs onChange={onTabChange} value={activeTab}>
        <Tab label="Insurance Policy" />
        <Tab label="Liquid Asset" />
        <Tab label="Motor Vehicle" />
        <Tab label="Other Property" />
        <Tab label="Real Estate Property/Burial Plot" />
      </Tabs>
      <TabContent>
        <div area-label="Insurance Policy" style={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button onClick={AddInstitutionDetails} startIcon={<AddIcon />}>
              Add Insurance
            </Button>
          </Stack>
        </div>
        <div area-label="Liquid Asset" style={{ width: '100%' }}>
          <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button onClick={AddLiquidAsset} startIcon={<AddIcon />}>
              Add Liquid Asset
            </Button>
          </Stack>
        </div>
        <div area-label="Motor Vehicle" style={{ width: '100%' }}>
          <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button onClick={AddMotorAsset} startIcon={<AddIcon />}>
              Add Vehicle Asset
            </Button>
          </Stack>
        </div>
        <div area-label="Other Property" style={{ width: '100%' }}>
          <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button onClick={AddotherAsset} startIcon={<AddIcon />}>
              Add Other Property
            </Button>
          </Stack>
        </div>
        <div
          area-label="Real Estate Property/Burial Plot"
          style={{ width: '100%' }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
            <Button
              variant="outlined"
              startIcon={<UploadFileIcon />}
              onClick={handleOpenDocModal}
            >
              Upload Files
            </Button>
            <Button onClick={AddestateAsset} startIcon={<AddIcon />}>
              Add Real Estate Property/Burial Plot
            </Button>
          </Stack>
        </div>
      </TabContent>

      <Box sx={{ display: 'grid' }}>
        <DataGrid
          getRowId={(row) => row.recordId}
          sx={{
            marginTop: 2,
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: 16,
              fontWeight: 700,

              textAlign: 'center',
              color: 'black',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '30vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '30vh',
            },
            minHeight: '30vh',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          hideFooter
          rows={rowData ?? []}
          columns={columnsData ?? []}
        />
      </Box>
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}
