import { Button, Grid, TextField, IconButton, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import FormSection from '../../../components/FormSection';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { find, map } from 'lodash';
import * as Yup from 'yup';
import usePageNav from '../../../hooks/usePageNav';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import { getFullName } from '../../../utils/helpers';
function SearchCase({ postSearch, caseId }) {
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  // console.log(houseMembers);
  const hohMember = find(houseMembers, { headOfHouseholdYN: 'Y' });
  const memberOpts = useMemo(() => {
    const options = map(houseMembers, ({ personName, householdMemberId }) => {
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      return {
        label: fullName,
        // value: { householdMemberId, fullName },
        value: householdMemberId,
      };
    });
    return [...options, { label: 'All', value: hohMember?.individualId }];
  }, [houseMembers]);

  const validationSchema = Yup.object({
    householdMemberId: Yup.string().required('Please Select Member'),
  });

  const formik = useFormik({
    initialValues: {
      fromDate: '',
      toDate: '',
      keyWords: '',
      caseId: caseId,
      householdMemberId: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const userName = find(memberOpts, {
        value: values?.householdMemberId,
      })?.label;
      postSearch({ ...values, member: userName });
    },
  });

  return (
    <FormSection
      label="Search Form"
      mt="0.5rem"
      ml="0.5rem"
      mb="0.5rem"
      mr="0.5rem"
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Field
            label="Member"
            formik={formik}
            name="householdMemberId"
            required
          >
            <Dropdown options={memberOpts} placeholder="Select Member" />
          </Field>
        </Grid>

        <Grid item xs={3}>
          <Field label="From Date" isDate formik={formik} name="fromDate">
            <DatePicker disableFuture placeholder="Select Date" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field label="To Date" isDate formik={formik} name="toDate">
            <DatePicker placeholder="Select Date" />
          </Field>
        </Grid>
        <Grid item xs={1.5}>
          <Field label="Key Word" formik={formik} name="keyWord">
            <TextField placeholder="Key Word" />
          </Field>
        </Grid>
        <Grid item xs={1.5} mt={3}>
          <Button onClick={formik.handleSubmit} startIcon={<SearchIcon />}>
            Search
          </Button>
        </Grid>
      </Grid>
    </FormSection>
  );
}

export default SearchCase;
