import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import { DataGrid } from '@mui/x-data-grid';
import VendorForm from './VendorForm';
import useLookup from '../../../hooks/useLookup';
import useLoader from '../../../hooks/useLoader';
import {
  deleteVendor,
  getVendor,
  searchVendor,
} from '../../../utils/services/VendorDetails/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import ActionButtons from '../../../components/ActionButtons';
import CloseIcon from '@mui/icons-material/Close';
import DeleteConfiramation from './DeleteConfiramation';
import RestoreIcon from '@mui/icons-material/Restore';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/vendor.svg';

export default function VendorDetails() {
  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);
  const { getOptions, getLkpMatch } = useLookup('VenderDetails');
  const [showLoader, hideLoader] = useLoader();
  const [searchData, setSearchData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [vendorEdit, setVendorEdit] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [venId, setVendId] = useState(null);
  const [ownerShipData, setOwnerShipData] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const { stateOpts, typeOpts, specialityOPts } = useMemo(() => {
    return {
      stateOpts: getOptions('State'),
      typeOpts: getOptions('VendorType'),
      specialityOPts: getOptions('SpecialtyCodes'),
    };
  }, [getOptions]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      searchType: 'R',
      searchId: '',
      vendorName: '',
      type: '',
      firstName: '',
      middleName: '',
      lastName: '',
      city: '',
      state: '',
      zipcode: '',
      facilityId: '',
      facilityName: '',
      facilityType: '',
      providerId: '',
      specialities: '',
      oldVendor: '',
      newVendor: '',
    },
    onSubmit: (values) => {
      postSearchVendor(values);
    },
  });

  const postSearchVendor = async (data) => {
    try {
      showLoader();
      const res = await searchVendor(data);
      if (res.status === 200) {
        setSearchData(res.data?.results);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const vendorDetailsData = (data) => {
    setEditOpen(true);
    setVendorEdit(data);
  };

  const toggleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setVendorEdit(false);
  };

  const columns = [
    { field: 'vendorId', headerName: 'ID', flex: 1 },
    { field: 'vendorName', headerName: 'Name', flex: 1 },
    {
      field: 'vendorTypeCd',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) =>
        getLkpMatch('VendorType', params?.row?.vendorTypeCd),
    },
    {
      field: 'mmisVendorId',
      headerName: 'MMIS LTC Provider ID',
      flex: 1,
    },
    {
      field: 'mmisVendorSpecialityCd',
      headerName: 'Specialities',
      flex: 1,
      renderCell: (params) =>
        getLkpMatch('SpecialtyCodes', params?.row?.mmisVendorSpecialityCd),
    },
    { field: 'vendorStreetAddress', headerName: 'Address', flex: 1 },
    { field: 'vendorTelNum', headerName: 'Telephone', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => vendorDetailsData(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
            {/* <IconButton>
              <RestoreIcon />
            </IconButton> */}
          </div>
        );
      },
    },
  ];

  const handleDelete = () => {
    deleteVendorDetails(venId);
  };

  const deleteVendorDetails = async (venId) => {
    const payload = {
      dirtyStatus: 'delete',
      vendorId: venId,
    };
    try {
      showLoader();
      const res = await deleteVendor(payload, venId);
      if (res.status === 200) {
        showSuccessToast('Successfully Deleted the Vendor Details');
        postSearchVendor();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const deletePopup = async (rowData) => {
    setSelectedData(rowData);
    setVendId(rowData?.vendorId);
    setOpenDeleteDialog(true);
  };

  const getVendorOwnership = async () => {
    const oldId = formik.values.oldVendor;
    const newId = formik.values.newVendor;
    try {
      showLoader();
      const res = await getVendor(oldId, newId);
      if (res.status === 200) {
        setOwnerShipData(res.data?.vendorList);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleVendor = () => {
    getVendorOwnership();
  };

  const vendorColumns = [
    { field: 'vendorId', headerName: 'ID', flex: 1 },
    { field: 'vendorName', headerName: 'Name', flex: 1 },
    {
      field: 'vendorTypeCd',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) =>
        getLkpMatch('VendorType', params?.row?.vendorTypeCd),
    },
    { field: 'col4', headerName: 'MMIS LTC Provider ID', flex: 1 },
  ];

  const toggleFields = () => {
    setExpanded((prev) => !prev);
  };

  const searchType = formik.values.searchType;

  return (
    <>
      <Box>
        <Typography
          sx={{ fontSize: '24px', fontWeight: '700' }}
          color="var(--black-900)"
        >
          Vendor Details
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            textTransform: 'none',
            width: '100%',
            borderBottom: '1px solid #bababa',
          }}
        >
          <Tab value={1} label="Vendor Search" />

          <Tab value={2} label="Vendor Ownership" />
        </Tabs>
        {value === 1 && (
          <>
            <Grid container spacing={2} marginTop="0.5rem">
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Field
                  name="searchType"
                  label=""
                  formik={formik}
                  value={formik.values.searchType}
                >
                  <RadioGroup
                    name="regular"
                    row
                    value={formik.values.searchType}
                  >
                    <FormControlLabel
                      value={'R'}
                      control={<Radio />}
                      label="Regular"
                    />
                    <FormControlLabel
                      value={'F'}
                      // sx={{ paddingLeft: '20rem' }}
                      control={<Radio />}
                      label="Facility"
                    />
                  </RadioGroup>
                </Field>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  // sx={{ padding: '1rem' }}
                  onClick={() => setOpen(true)}
                >
                  +Add Vendor
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            {formik.values.searchType === 'R' && (
              <Grid container spacing={2} mt="1rem">
                <Grid item xs={4}>
                  <Field name="searchId" label="Search" formik={formik}>
                    <TextField placeholder="Enter ID" />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="vendorName" label="Vendor Name" formik={formik}>
                    <TextField placeholder="Enter Vendor Name" />
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field name="type" label="Type" formik={formik}>
                    <Dropdown placeholder="Select Type" options={typeOpts} />
                  </Field>
                </Grid>
                <Grid item xs={1} mt="1rem">
                  <IconButton onClick={toggleFields}>
                    {expanded ? (
                      <KeyboardDoubleArrowUpIcon />
                    ) : (
                      <KeyboardDoubleArrowDownIcon />
                    )}
                  </IconButton>
                </Grid>
                {expanded && (
                  <>
                    <Grid item xs={4}>
                      <Field
                        name="firstName"
                        label="First Name"
                        formik={formik}
                      >
                        <TextField placeholder="Enter First Name" />
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name="middleName"
                        label="Middle Name"
                        formik={formik}
                      >
                        <TextField placeholder="Enter Middle Name" />
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="lastName" label="Last Name" formik={formik}>
                        <TextField placeholder="Enter Last Name" />
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="city" label="City" formik={formik}>
                        <TextField placeholder="Enter City Name" />
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="state" label="State" formik={formik}>
                        <Dropdown
                          placeholder="Select State"
                          options={stateOpts}
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={4}>
                      <Field name="zipcode" label="Zip Code" formik={formik}>
                        <TextField placeholder="Enter Zip Code" />
                      </Field>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      variant="outlined"
                      onClick={() => formik.resetForm()}
                    >
                      Clear
                    </Button>
                    <Button variant="contained" onClick={formik.handleSubmit}>
                      Search
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {formik.values.searchType === 'F' && (
              <Grid container spacing={2} mt="0.5rem">
                <Grid item xs={4}>
                  <Field name="facilityId" label="ID" formik={formik}>
                    <TextField placeholder="Enter Id" />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="facilityName" label="Name" formik={formik}>
                    <TextField placeholder="Enter Facility Name" />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field name="facilityType" label="Type" formik={formik}>
                    <Dropdown placeholder="Select Type" options={typeOpts} />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="providerId"
                    label="MMIS LTC Provider ID"
                    formik={formik}
                  >
                    <TextField placeholder="Enter MMIS LTC Provider ID" />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="specialities"
                    label="Specialities"
                    formik={formik}
                  >
                    <Dropdown
                      placeholder="Select Specialities"
                      options={specialityOPts}
                    />
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button
                      variant="outlined"
                      onClick={() => formik.resetForm()}
                    >
                      Clear
                    </Button>
                    <Button variant="contained" onClick={formik.handleSubmit}>
                      Search
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} mt="1rem">
              <DataGrid
                autoHeight
                rows={searchData ?? {}}
                columns={columns}
                getRowId={(row) => row?.vendorId}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                slots={{
                  noRowsOverlay: () => (
                    <EmptyState
                      header="Currently No Records Available"
                      icon={
                        <img
                          src={NoRecord}
                          style={{
                            height: '160px',
                            width: '250px',
                          }}
                        />
                      }
                    />
                  ),
                }}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    minHeight: '50vh',
                  },
                  minHeight: '50vh',
                  backgroundColor: 'white',
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontSize: '14px',
                    fontWeight: '700',
                    padding: '0px 10px',
                    color: '#000000',
                  },
                  '& .MuiDataGrid-row': {
                    fontSize: '16px',
                    color: 'black',
                    padding: '0px 10px',
                    textAlign: 'center',
                  },
                }}
              />
            </Grid>
          </>
        )}
        {value === 2 && (
          <>
            <Grid container spacing={2} mt="1rem">
              <Grid item xs={6}>
                <Field name="oldVendor" label="Old Vendor ID" formik={formik}>
                  <TextField placeholder="Enter Old Vendor Id" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="newVendor" label="New Vendor ID" formik={formik}>
                  <TextField placeholder="Enter New Vendor Id" />
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button variant="outlined" onClick={() => formik.resetForm()}>
                    Clear
                  </Button>
                  <Button variant="contained" onClick={() => handleVendor()}>
                    Go
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={ownerShipData ?? {}}
                  columns={vendorColumns}
                  getRowId={(row) => row?.vendorId}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  slots={{
                    noRowsOverlay: () => (
                      <EmptyState
                        header="Currently No Records Available"
                        icon={
                          <img
                            src={NoRecord}
                            style={{
                              height: '160px',
                              width: '250px',
                            }}
                          />
                        }
                      />
                    ),
                  }}
                  sx={{
                    backgroundColor: 'white',
                    '& .MuiDataGrid-virtualScroller': {
                      minHeight: '50vh',
                    },
                    minHeight: '50vh',
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontSize: '14px',
                      fontWeight: '700',
                      padding: '0px 10px',
                      color: '#000000',
                    },
                    '& .MuiDataGrid-row': {
                      fontSize: '16px',
                      color: 'black',
                      padding: '0px 10px',
                      textAlign: 'center',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Dialog maxWidth="md" open={open} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Add Vendor</Typography>
            <IconButton
              aria-label="close"
              onClick={toggleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <VendorForm
            toggleClose={toggleClose}
            createVendor
            searchType={searchType}
          />
        </DialogContent>
      </Dialog>
      {editOpen && (
        <Dialog maxWidth="md" open={editOpen} onClose={toggleClose}>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Add Vendor</Typography>
              <IconButton
                aria-label="close"
                onClick={toggleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <VendorForm toggleClose={toggleClose} vendorEdit={vendorEdit} />
          </DialogContent>
        </Dialog>
      )}
      <DeleteConfiramation
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        handleDelete={handleDelete}
      />
    </>
  );
}
