import { all, takeEvery, put, takeLatest } from 'redux-saga/effects';
import HttpClient, { authApi } from '../../utils/HttpClient';
import { AUTHENTICATE } from '../types/authTypes';
import { setAuthToken } from '../slices/authSlice';
import { showErrorToast } from '../../utils/services/toast';

export function* authenticateUser(action) {
  try {
    const { username, password } = action.payload;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append('grant_type', 'password');
    bodyFormData.append('client_id', 'security-admin-console');
    bodyFormData.append('username', username);
    bodyFormData.append('password', password);
    bodyFormData.append('scope', 'openid');
    const res = yield authApi.post(
      '/auth/realms/testrealm/protocol/openid-connect/token',
      bodyFormData
    );
    if (res?.status === 200) {
      yield put(setAuthToken('token'));
    }
  } catch (error) {
    showErrorToast('Invalid username or password');
  }
}

export default function* watchAuth() {
  yield all([takeEvery(AUTHENTICATE, authenticateUser)]);
}
