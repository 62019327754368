import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';

function Wizard({
  title,
  open,
  close,
  isForm,
  onSubmit,
  children,
  renderFooter,
}) {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: 'white',
          position: 'fixed',
          top: '10rem',
          maxWidth: '80%',
          margin: 'auto',
          maxHeight: '80vh',
          overflow: 'scroll',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            p: '0.5rem',
            // position: 'fixed',
            // top: '10rem',
            // width: '80%',
            bgcolor: 'white',
            zIndex: 999,
          }}
        >
          <Typography variant="h2">{title}</Typography>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box mt="3rem">{children}</Box>
      </Box>
    </Modal>
  );
}

Wizard.propTypes = {
  renderFooter: PropTypes.func,
  close: PropTypes.func,
  title: PropTypes.string,
  isForm: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
  open: PropTypes.bool,
};

Wizard.defaultProps = {
  renderFooter: () => {},
  close: () => {},
  title: 'Demographic details',
  isForm: false,
  onSubmit: () => {},
  children: '',
  open: false,
};

export default Wizard;
