import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DocumentUploadForm from './DocumentUploadForm';
import { getDocumentsFiles } from '../../utils/services/apiRequests';
import { useParams } from 'react-router';
import useLoader from '../../hooks/useLoader';

const DocumentUploadFilesDialog = ({
  setDocOpen,
  docOpen,
  isAdd,
  caseId,
  lkpRaw,
  lkpFlatten,
  member,
  setIsAdd,
}) => {
  const params = useParams();
  const descriptionElementRef = useRef('');
  const [showLoader, hideLoader] = useLoader();
  // const [docmentFiles, setDocmentFiles] = useState([]);

  const handleClose = () => {
    setDocOpen(false);
    // setIsAdd(false);
  };

  // const getUploadFiles = async () => {
  //   try {
  //     showLoader();
  //     const res = await getDocumentsFiles(params.caseId);
  //     if (res.status === 200) {
  //
  //       setDocmentFiles(res.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     hideLoader();
  //   }
  // };
  // useEffect(() => {
  //   if (docOpen) getUploadFiles();
  // }, [docOpen]);

  return (
    <div>
      <Dialog
        open={docOpen}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="document"
        aria-describedby="documentDescription"
      >
        <DialogTitle id="document">Add Document</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute !important',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText
            id="documentDescription"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* Form */}
            <DocumentUploadForm
              lkpRaw={lkpRaw}
              lkpFlatten={lkpFlatten}
              member={member}
              isAdd={isAdd}
              caseId={caseId}
              // setDocmentFiles={setDocmentFiles}
              setDocOpen={setDocOpen}
              setIsAdd={setIsAdd}
              // docmentFiles={docmentFiles}
              // getUploadFiles={getUploadFiles}
              docOpen={docOpen}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ mr: '2rem', width: '6rem' }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentUploadFilesDialog;
