export const GET_EMPLOYERDET =
  'eeapi/application-process/interview/{0}/household/absent-parent/{1}/employment';

export const POST_EMPLOYERDET =
  'eeapi/application-process/interview/{0}/household/absent-parent/new/{1}/employment';

export const POST_DEMOGRAPHIC =
  '/eeapi/application-process/interview/{0}/household/absent-parent/new/identification';

export const GET_DEMOGRAPHIC =
  'eeapi/application-process/interview/household/absent-parent/{0}';

export const PUT_CONTACT =
  '/eeapi/application-process/interview/{0}/household/absent-parent/{1}/contact';

export const GET_CONTACTINFO =
  'eeapi/application-process/interview/{0}/household/absent-parent/{1}/contact';

export const GET_COURTORDER =
  'eeapi/application-process/interview/{0}/household/absent-parent/court-order/{1}';
