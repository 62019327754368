import React, { useEffect, useState } from 'react';
import usePageNav from '../../hooks/usePageNav';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer';
import useLoader from '../../hooks/useLoader';
import { showErrorToast } from '../../utils/services/toast';
import {
  getHoHData,
  postImportCompletion,
} from '../../utils/services/caseCreation/apiRequests';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import { useSearchParams } from 'react-router-dom';

export default function Completion() {
  const params = useParams();
  const navigate = useNavigate();
  const _caseId = params.caseId ?? '';
  const _controlId = params.controlId ?? '';
  const [showLoader, hideLoader] = useLoader();
  const [caseId, setCaseId] = useState(null);
  let [searchParams] = useSearchParams();
  const completionStatus = searchParams.get('status');

  useEffect(() => {
    getHoHList();
    // importCompletion();
  }, []);

  // const importCompletion = async () => {
  //   showLoader();
  //   try {
  //     let data = {
  //       importSteps: ['CASEDATASAVE'],
  //       caseDataSteps: [
  //         'CONTACT',
  //         'AUTHREP',
  //         'CITIZENSHIP',
  //         'MEDICALINSURANCE',
  //         'ASSET',
  //         'EARNEDINCOME',
  //         'UNEARNEDINCOME',
  //         'MEDICALEXPENSE',
  //         'MEDICARE',
  //         'COURTORDER',
  //         'RELATIONSHIP',
  //         'DISABILITY',
  //         'STUDENT',
  //         'STRIKER',
  //         // 'VETERAN',
  //         // 'TAX',
  //         // 'POTENTIALASSET',
  //         // 'UTILITYEXPENSE',
  //         // 'SIGNATURE',
  //         // 'ROOMER',
  //         // 'PREGNANCY',
  //         // 'PROGRAM',
  //         // 'LIVING',
  //         // 'DEPENDENTCARE',
  //         // 'UNEARNEDAPPS',
  //         // 'CITIZENSPONSOR',
  //         // 'ABSENTPARENT',
  //       ],
  //     };
  //     const res = await postImportCompletion(data, _controlId);
  //     if (
  //       res?.status === 200 &&
  //       res?.data?.status?.toLowerCase() === 'success'
  //     ) {
  //       setCaseId(res.data.benefitsCaseId);
  //     }
  //   } catch (error) {
  //     showErrorToast(error);
  //   } finally {
  //     hideLoader();
  //   }
  // };

  const getHoHList = async () => {
    showLoader();
    try {
      let data = {
        caseId: _caseId,
        controlId: _controlId,
      };
      const res = await getHoHData(data);
      if (res?.status === 200 && res?.data) {
        let parsedData = JSON.parse(res.data);
        setCaseId(parsedData?.[0]?.caseId);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const goToAppDisposition = () => {
    navigate(`/dashboard/application/app-disposition/${caseId}`);
  };

  return (
    <div>
      <PageHeader title="Case Completion" showNavigations={false} />
      <Box component={Paper} mt={1} p={2}>
        {completionStatus?.toLowerCase() === 'success' ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" color="green">
              <Link to={`/dashboard/application/app-disposition/${caseId}`}>
                {caseId}
              </Link>
              &nbsp; <b>Success!</b> All selections are imported successfully.
            </Typography>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="contained" onClick={goToAppDisposition}>
                Go to Case: {caseId}
              </Button>
              <Button variant="contained" onClick={goToDashboard}>
                Go to Dashboard
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle1" color="error">
              <b>Failed!</b> No selections were imported successfully.
            </Typography>
            <Button variant="contained" onClick={goToDashboard}>
              Go to Dashboard
            </Button>
          </Stack>
        )}
      </Box>
      {/* <Stack spacing={2} direction="row" justifyContent="flex-end" mt={2}>
        <Button
          onClick={() => navigations.toImportMember(_caseId, _controlId)}
          variant="outlined"
        >
          Back
        </Button>
      </Stack> */}
    </div>
  );
}
