import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import Avatar from '../../../../components/Avatar';
import FormSection from '../../../../components/FormSection';
import dayjs from 'dayjs';
import TextContent from '../../../../components/TextContent';
import Status from '../../../../components/Status';
import mock from './mock.json';
import { includes, map } from 'lodash';
import useTabs from '../../../../hooks/useTabs';
import useMount from '../../../../hooks/useMount';
import { showErrorToast } from '../../../../utils/services/toast';
import IndividualElSummary from '../IndividualElSummary';
import ProgramElibility from './ProgramEligibility';
import FinancialElibility from './FinancialElibility';
import RecoupmentDetails from './RecoupmentDetials';
import { getStatus, numbersToDate } from '../../../../utils/helpers';
import { getEligibilityResults } from '../../../../utils/services/eligibility/apiRequests';
import { selectEligilityResult } from '../../../../store/selectors/eligibilitySelector';
import { useSelector } from 'react-redux';

function SummaryDetails({ selected, caseId, getLkpMatch }) {
  const { Tabs, Tab, TabContent, onTabChange, activeTab } = useTabs();
  const { name, programBeginDate } = selected;
  const [fetching, setFetching] = useState(false);
  const [eligDetails, setEligDetails] = useState({});
  // const [eligDetails, setEligDetails] = useState(mock);
  const eligibilityData = useSelector(selectEligilityResult);
  console.log(eligibilityData, 'eligibilityData');
  const date = dayjs(programBeginDate).format('MM/YY');
  const { programName, payMonth, programEligibilityId } = selected;
  const formattedPaymonth = dayjs(numbersToDate(payMonth)).format('MM/YY');
  const {
    // programName,
    programType,
    aidCd,
    rmpInd,
    waiverType,
    tdapType,
    secondaryAidCd,
    eligibilityStatusCd,
    confirmationDt,
    createDt,
    applicationDate,
    nonFinancialStatusCd,
    financialStatusCd,
    resourceStatusCd,
    // programBeginDate,
    programEndDate,
    houseHoldSize,
    categoricallyEligible,
    expeditedSNAPIndicator,
    expeditedSNAPDecisionDt,
    moeTanfInd,
    programId,
    agEligReason,
    redeterminationDueDate,
    simplifiedReportDate1,
    spendDownBeginDate,
    spendDownEndDate,
    spendDownSecondaryBeginDate,
    spendDownSecondaryEndDate,
    agMbuEligResult,
    agFsBudgetResult,
    agChBudgetResult,
    agEaBudgetResult,
    agIndvEligResult, //#455,
    // payMonth,
    // programEligibilityId,
  } = eligDetails;

  const fetchEligibilityResults = async () => {
    try {
      setFetching(true);
      const res = await getEligibilityResults(caseId, {
        dirtyStatus: '',
        payMonth,
        programName,
        programEligibilityId,
      });

      if (res.status === 200) {
        setEligDetails(res.data);
      }
    } catch (error) {
      showErrorToast();
    } finally {
      setFetching(false);
    }
  };

  useMount(() => {
    fetchEligibilityResults();
  });

  const financialInfo = useMemo(() => {
    switch (programName) {
      case 'MA':
      case 'LT':
      case 'WV':
        return agMbuEligResult;
      case 'FS':
      case 'EF':
        return agFsBudgetResult;
      case 'CH':
        return agChBudgetResult;
      case 'EA':
        return agEaBudgetResult;
      default:
        return {};
    }
  }, [
    agChBudgetResult,
    agEaBudgetResult,
    agFsBudgetResult,
    agMbuEligResult,
    programName,
  ]);

  return (
    <div>
      <Divider />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        <Box>
          <Stack direction="row" alignItems="center">
            <Avatar name={name} />
            <Typography ml="0.5rem" variant="h4">
              {name}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">Program</Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle1">
                {getLkpMatch(
                  'Program',
                  eligibilityData?.eligilityResult[0]?.programName
                )}
              </Typography>
              <Typography variant="caption">
                ({eligibilityData?.eligilityResult[0]?.aidCd})
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">Status</Typography>
            {/* <Typography variant="subtitle1">Pass</Typography> */}
            <Status
              invert
              size="medium"
              status={getStatus(eligibilityStatusCd)}
            >
              {getLkpMatch('EligibilityStatusCode', eligibilityStatusCd)}
            </Status>
          </Stack>
        </Box>
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">Date</Typography>
            <Typography variant="subtitle1">{formattedPaymonth}</Typography>
          </Stack>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Tabs onChange={onTabChange} value={activeTab}>
          <Tab label="Program Eligibility" />
          <Tab label="Financial Eligibility" />
          <Tab label="Recoupment Details" />
        </Tabs>
      </Box>
      <TabContent
        sx={{
          border: '1px solid #C2C2B7',
          borderRadius: '0.25rem',
          p: '0.5rem',
        }}
      >
        <ProgramElibility
          eligDetails={eligDetails}
          name={name}
          programBeginDate={programBeginDate}
          getLkpMatch={getLkpMatch}
        />
        <FinancialElibility
          aidCd={aidCd}
          programName={programName}
          financialInfo={financialInfo?.[0]}
        />
        <RecoupmentDetails />
      </TabContent>
      <Box>
        <IndividualElSummary
          programName={programName}
          agIndvEligResult={agIndvEligResult}
          getLkpMatch={getLkpMatch}
          financialInfo={financialInfo?.[0]}
        />
      </Box>
    </div>
  );
}

SummaryDetails.propTypes = {};

export default SummaryDetails;
