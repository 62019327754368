import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { POST_NARRATIVE, POST_SEARCH } from './apiUrls';
import { narrativePayload, searchPayload } from './apiPayload';

export const postCaseNarrative = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_NARRATIVE, caseId, individualId),
    narrativePayload(data)
  );
};

export const postCaseSearch = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_SEARCH, caseId, individualId),
    searchPayload(data)
  );
};
