import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Box, Collapse, Stack, Typography, Checkbox } from '@mui/material';
import Icon from '../../../../../components/Icon';
import cx from 'classnames';
import { includes, map, noop, some } from 'lodash';

function AuthRepRights({
  accesTypeOptions,
  programName,
  onSelectProgram,
  onSelectAccess,
  getLkpMatch,
  addedAccesses,
  open,
}) {
  const handleClick = () => {
    onSelectProgram(programName);
  };

  const isIpadPro = window.innerWidth <= 1024 && window.innerWidth > 768;

  return (
    <Box className={styles.authRepRights}>
      <Box
        className={cx(styles.title, {
          [styles.collapsed]: !open,
        })}
      >
        <Checkbox checked={open} onChange={handleClick} />
        <Typography variant="body1">
          {getLkpMatch('RequestedPrograms', programName)}
        </Typography>
      </Box>
      <Collapse in={open}>
        <Stack spacing={1}>
          <Stack direction="row" p="0.5rem" alignItems="center">
            <Icon name="TickMark" />
            <Typography ml="0.5rem" variant="h4">
              Authorized {isIpadPro ? <br /> : null} Representative:
            </Typography>
          </Stack>
          <Stack spacing={0.5}>
            {map(accesTypeOptions, ({ label, value }) => {
              const isChecked = some(addedAccesses, {
                authRepAccsTypCd: value,
              });

              const disabledItem = {
                MA: ['COI', 'REC', 'REE'],
                FS: ['REM', 'REE'],
                CH: ['REM', 'REE'],
                WV: ['COI', 'REC', 'REE', 'REM'],
                LT: ['COI', 'REC', 'REE', 'REM'],
                EA: [],
                DS: [],
              };

              return (
                <Stack direction="row" alignItems="center">
                  <Checkbox
                    p="0.5rem"
                    name={label}
                    onChange={() => onSelectAccess(programName, value)}
                    checked={isChecked}
                    disabled={disabledItem[programName].includes(value)}
                  />
                  <Typography variant="body1">{label}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
}

AuthRepRights.propTypes = {
  open: PropTypes.bool,
  onChange: PropTypes.func,
  programOpts: PropTypes.array,
  programName: PropTypes.string,
};

AuthRepRights.defaultProps = {
  open: false,
  //   true
  onChange: noop,
  programOpts: [],
  programName: '',
};

export default AuthRepRights;
