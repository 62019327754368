import React, { useEffect, useState } from 'react';
import { Grid, Typography, createStyles } from '@mui/material';
import styled from '@emotion/styled';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import mockData from './mock.json';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: 'Open Sans',
      fontSize: '17px',
      fontWeight: '700',
      height: '83px',
      width: '100%',
      color: '#0F4E96 !important',
      '& .MuiAccordionSummary-contentGutters': {
        display: 'block !important',
      },
      background: '#C5E2F6 !important',
    },
    subTitle: {
      color: '#0F4E96 !important',
      fontSize: '16px !important',
      fontWeight: '600 !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between !important',
      height: '60px !important',
      width: '-webkit-fill-available !important',
      padding: '0px 5px 0px 38px',
      borderBottom: '1px solid var(--grey-300)',
    },
    groupingIconTextMark: {
      display: 'flex',
      flexDirection: 'row !important',
      alignItems: 'center !important',
      justifyContent: 'space-between !important',
    },
    groupingIconText: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '10px',
    },
    sidePadding: {
      '& .MuiAccordionDetails-root': {
        padding: '0px !important',
      },
    },
    parentPending: {
      backgroundColor: '#f8fdc9',
    },
    sideBorderChild: {
      borderRight: '7px solid var(--primary-dark)',
      backgroundColor: '#f2f8fd',
    },
  })
);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  //   borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ImportAccordion({ screenTitle, setScreenTitle }) {
  const classes = useStyles();
  const [expandedAccordion, setExpandedAccordion] = useState('panel0');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const handleClick = (event, title) => {
    setScreenTitle(title);
  };

  return (
    <>
      {mockData.map((navItem, index) => {
        // const Base64Icon = () => (
        //   <img
        //     src={navItem?.image}
        //     alt={`Navigation Icon ${index}`}
        //     style={{ width: '30px', height: '30px' }}
        //   />
        // );
        return (
          <Accordion
            expanded={`panel${navItem.modId - 1}` === expandedAccordion}
            onChange={handleChange(`panel${navItem.modId - 1}`)}
            key={navItem.modId}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className={classes.title}
            >
              <div className={classes.groupingIconTextMark}>
                <span className={classes.groupingIconText}>
                  <img src={navItem?.image} alt="" />
                  {/* <SmButton
                    title={'Import'}
                    expanded={null}
                    icon={Base64Icon}
                  /> */}
                  {navItem?.parent || ''}
                </span>
              </div>
            </AccordionSummary>
            <div className={classes.sidePadding}>
              <AccordionDetails>
                {navItem?.children.map((child) => (
                  <Grid item xs={12}>
                    <div
                      className={
                        screenTitle === child?.nvgtn_cd
                          ? classes.sideBorderChild
                          : null
                      }
                      style={{ cursor: 'pointer' }}
                      onClick={(event) => handleClick(event, child.nvgtn_cd)}
                    >
                      <Typography
                        key={child.tag_txt}
                        className={classes.subTitle}
                        sx={{
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Grid item xs={10}>
                          <Link style={{ textDecoration: 'none' }}>
                            {child.tag_txt}
                          </Link>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Typography>
                    </div>
                  </Grid>
                ))}
              </AccordionDetails>
            </div>
          </Accordion>
        );
      })}
    </>
  );
}
