export const shelterPayload = (data, individualId) => {
  const {
    beginDate,
    endDate,
    energyAssist,
    heatingCooling,
    subsidized,
    heatEat,
    condo,
    condoVerify,
    groundRent,
    groundVerify,
    homeOwners,
    homeVerify,
    moratge,
    moratgeVerify,
    rent,
    rentVerify,
    taxes,
    taxesVerify,
    electric,
    electricVerify,
    garbage,
    garbageVerify,
    gas,
    gasVerify,
    oil,
    oilVerify,
    other,
    otherVerify,
    phone,
    phoneVerify,
    sewage,
    sewageVerify,
    water,
    waterVerify,
    dirtyStatus,
    recordId,
  } = data;
  return {
    recordId,
    individualId: individualId ?? '',
    dirtyStatus,
    bgnDt: beginDate,
    endDt: endDate ? endDate : null,
    meapDnd: energyAssist,
    heatCoolInd: heatingCooling,
    subzHousingInd: subsidized,
    shelterExpenseTypes: [
      {
        dirtyStatus,
        recordId,
        shelterExpTypeCd: '4',
        shelterExpenseAmount: condo,
        clientProvidedVerificationByShelterExpenseVerificationId: {
          verificationSourceCode: condoVerify,
        },
        shelterExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        shelterExpTypeCd: '3',
        shelterExpenseAmount: groundRent,
        clientProvidedVerificationByShelterExpenseVerificationId: {
          verificationSourceCode: groundVerify,
        },
        shelterExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        shelterExpTypeCd: '6',
        shelterExpenseAmount: homeOwners,
        clientProvidedVerificationByShelterExpenseVerificationId: {
          verificationSourceCode: homeVerify,
        },
        shelterExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        shelterExpTypeCd: '2',
        shelterExpenseAmount: moratge,
        clientProvidedVerificationByShelterExpenseVerificationId: {
          verificationSourceCode: moratgeVerify,
        },
        shelterExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        shelterExpTypeCd: '1',
        shelterExpenseAmount: rent,
        clientProvidedVerificationByShelterExpenseVerificationId: {
          verificationSourceCode: rentVerify,
        },
        shelterExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        shelterExpTypeCd: '5',
        shelterExpenseAmount: taxes,
        clientProvidedVerificationByShelterExpenseVerificationId: {
          verificationSourceCode: taxesVerify,
        },
        shelterExpVerfDesc: '',
      },
    ],
    shelterUtilityTypes: [
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '8',
        utilityExpenseAmount: electric,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: electricVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '12',
        utilityExpenseAmount: garbage,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: garbageVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '7',
        utilityExpenseAmount: gas,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: gasVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '10',
        utilityExpenseAmount: oil,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: oilVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '14',
        utilityExpenseAmount: other,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: otherVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '13',
        utilityExpenseAmount: phone,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: phoneVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '11',
        utilityExpenseAmount: sewage,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: sewageVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
      {
        dirtyStatus,
        recordId,
        utilityTypeCd: '9',
        utilityExpenseAmount: water,
        utilityExpVerfDesc: '',
        clientProvidedVerificationByutilityExpenseVerificationId: {
          verificationSourceCode: waterVerify,
        },
        utilityOthrExpVerfDesc: '',
      },
    ],
  };
};
