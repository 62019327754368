import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  caseAssigne,
  unassignedApi,
} from '../../../../utils/services/apiRequests';
import { getAuthToken } from '../../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useCaseStatus from '../../../../hooks/useCaseStatus';
import { Grid, Tooltip, IconButton, Box, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useMount from '../../../../hooks/useMount';
import usePageNav from '../../../../hooks/usePageNav';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Field from '../../../../components/Field';
import Dropdown from '../../../../components/Dropdown';
import { useFormik } from 'formik';
import useLoader from '../../../../hooks/useLoader';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import { find, map } from 'lodash';
import EmptyState from '../../../../components/EmptyState';
import NoRecord from '../../../../assets/icons/No-data-amico.svg';

function UnAssignedTable({ lkpFlatten, newValue }) {
  const [fetching, setFetching] = useState(false);
  const [paperApps, setPaperApps] = useState([]);
  const { navigations, caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [onlineApps, setOnlineApps] = useState([]);
  const token = useSelector(getAuthToken);
  const { viewCase } = useCaseStatus();
  const [option, setOption] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const assign = async (uuid) => {
    try {
      showLoader();
      const payload = [
        {
          wrkrId: uuid,
          caseIdList: [],
          webIdList: [],
        },
      ];
      payload[0].caseIdList = map(selectedData, (item) => item.caseId);
      payload[0].webIdList = map(selectedData, (item) => item.webID);
      const res = await caseAssigne(payload, 'RD');
      if (res.status === 201) {
        showSuccessToast('Assigned Successfully');
        fetchData(page);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const handleRowSelectionChange = (newRowSelectionModel) => {
    const selectedData = map(newRowSelectionModel, (selectedId) => {
      if (newValue === 1) {
        return find(paperApps, (item) => item.caseId === selectedId);
      } else {
        return find(onlineApps, (item) => item.caseId === selectedId);
      }
    });
    setSelectedData(selectedData);
  };

  const formik = useFormik({
    initialValues: {
      assigned: null,
    },
    onSubmit: (values) => {
      assign(values?.assigned);
    },
  });

  const fetchData = async ({ pageSize, page }) => {
    try {
      showLoader();
      setFetching(true);
      const paginationDataPaper = {
        resultsPerPage: pageSize,
        pageNumber: page,
      };
      const res = await unassignedApi(paginationDataPaper, token?.sub, 'RD');
      if (res.status === 200) {
        const {
          unAssignedAlertsPaper,
          unAssignedAlertsReg,
          wrkrList,
          paginationList,
        } = res.data;
        setPaperApps(unAssignedAlertsPaper);
        setOnlineApps(unAssignedAlertsReg);
        setOption(wrkrList);
        setTotalRecords(paginationList);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  const onPageChange = (pages) => {
    setPage(pages);
    fetchData(pages);
  };

  useMount(() => {
    fetchData(page);
  });

  const columns = [
    {
      field: 'webID',
      headerName: 'Web ID',
      renderCell: (params) => params.webID && params.webRefId,
      flex: 1,
      maxWidth: 250,
    },
    { field: 'caseId', headerName: 'Case ID', flex: 1, maxWidth: 250 },
    {
      field: 'Program',
      headerName: 'Program',
      renderCell: (params) => lkpFlatten?.Program?.[params?.row?.programs],
      flex: 1,
      maxWidth: 250,
    },
    { field: 'alertDesc', headerName: 'Type', flex: 1, maxWidth: 250 },
    {
      field: 'appDt',
      headerName: 'Application Date',
      flex: 1,
      maxWidth: 250,
      renderCell: (params) => dayjs(params?.row?.appDt).format('MM-DD-YYYY'),
    },
    {
      field: 'distOffice',
      headerName: 'LDSS',
      flex: 1,
      maxWidth: 250,
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 1,
      maxWidth: 250,
      renderCell: (params) => action(params),
    },
  ];

  const onOpenCase = (caseId, controlId) => {
    navigations.toCPImport(caseId, controlId);
  };

  const lookupData = useMemo(() => {
    return map(option, (value) => {
      return {
        label: value.firstName + ' ' + value.lastName,
        value: value.uniqueId,
      };
    });
  }, [option]);

  const action = (params) => {
    if (
      params.row?.caseId === null &&
      params.row?.webRefID != null &&
      (params.row?.caseStsCd === '' || params.row?.caseStsCd === 'PE')
    ) {
      return (
        <>
          <Grid sx={{ cursor: 'pointer' }}>
            <IconButton
              onClick={() => onOpenCase(params.row.caseId, params.row.webId)}
            >
              <Tooltip title="Manual Import" placement="right">
                <MenuBookIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => viewCase(params.id, params.row.caseStsCd)}>
            <Tooltip title="View Case" placement="right">
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      );
    }
  };

  return (
    <Box gap={2}>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          gap: '2rem',
        }}
        mb={2}
        xs={12}
      >
        <Grid xs={2}>
          <Field name="assigned" formik={formik} label="Assign To">
            <Dropdown options={lookupData} />
          </Field>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Assign
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        getRowId={(row) => (row.caseId ? row.caseId : row.webId || row.webID)}
        rows={newValue === 1 ? paperApps ?? [] : onlineApps ?? []}
        columns={columns}
        rowCount={
          newValue === 1
            ? totalRecords?.paginationDataPaper?.totalRecords ?? 0
            : totalRecords?.paginationData?.totalRecords ?? 0
        }
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Note: Please click Get Work Item to Get Records"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        pageSizeOptions={[10, 20, 30]}
        paginationMode="server"
        paginationModel={page}
        onPaginationModelChange={onPageChange}
        checkboxSelection={true}
        onRowSelectionModelChange={handleRowSelectionChange}
        autoHeight
      />
    </Box>
  );
}

UnAssignedTable.propTypes = {};

export default UnAssignedTable;
