import React from 'react';
import { Box, Stack, Button, Typography, Grid } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { saveWorkplan } from '../../../utils/services/Works/apiRequest';

const WorkCreationIndex = ({
  setOpen,
  data,
  getOptions,
  caseId,
  individualId,
  setEvents,
  events,
  changed,
  setChanged,
}) => {
  const validationSchema = yup.object({
    planStus: yup.string().required('Plan Details are required'),
  });

  const handleSubmit = async (e) => {
    try {
      const res = await saveWorkplan(caseId, individualId, events, {
        ...e,
        wrkPrgPlanId: events === 'insert' ? null : data.wrkPrgPlanId,
      });
      if (res.status === 200) {
        setOpen(false);
        setChanged(!changed);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      planStus: data?.planStus ?? '',
      planStusDt: data?.planStusDt ?? dayjs().format('YYYY-DD-MM'),
      effectiveBeginDt: data?.effectiveBeginDt ?? null,
      fedCore: data?.fedCore ?? '',
      fedNonCore: data?.fedNonCore ?? '',
      effectiveEndDt: data?.effectiveEndDt ?? null,
      narration: data?.narration ?? '',
    },
    validateOnChange: true,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });
  return (
    <Box
      sx={{
        padding: '1rem',
        border: '1px solid var(--grey-250)',
        margin: '1rem',
        borderRadius: '5px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field formik={formik} label="Plan Details" name="planStus" required>
            <Dropdown name="planStus" options={getOptions('Program')} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            formik={formik}
            label="Plan Status Date"
            name="planStusDt"
            formikHandler={false}
            isDate
          >
            <DatePicker name="planStusDt" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            formik={formik}
            label="Federally Defined Core Acticities"
            name="fedCore"
          >
            <Dropdown name="fedCore" options={getOptions('WorkPlanType')} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            label="Federally Defined Non-Core Activities"
            formik={formik}
            name="fedNonCore"
          >
            <Dropdown name="fedNonCore" options={getOptions('WorkPlanType')} />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            label="Enrollment Date"
            isDate
            formik={formik}
            formikHandler={false}
            name="effectiveBeginDt"
          >
            <DatePicker name="effectiveBeginDt" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            label="Expected End Date"
            name="effectiveEndDt"
            isDate
            formikHandler={false}
            formik={formik}
          >
            <DatePicker name="effectiveEndDt" />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field label="Narration" name="narration" formik={formik}>
            <textarea
              rows="4"
              name="narration"
              style={{
                border: '1px solid var(--grey-350)',
                borderRadius: '5px',
              }}
            ></textarea>
          </Field>
        </Grid>
      </Grid>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Discard
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={formik.handleSubmit}
          //   disabled={disableFields}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default WorkCreationIndex;
