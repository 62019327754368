import HttpClient from '../../HttpClient';
import {
  GET_ELIGIBILITY,
  GET_ELIGIBILITY_POST,
  GET_LDSSCODE,
  GET_LDSS_OPTION,
  POST_ELIGIBILITY,
  POST_LDSSCODE,
} from './apiUrl';
import { eligibilityPayload, lDSSCodePayload } from './apiPayload';
import { getAuthToken } from '../../../store/selectors/authSelector';

export const getEligibilityParam = (Id) => {
  return HttpClient.get(`${GET_ELIGIBILITY}/${Id}`);
};

export const getEligibilityValue = (Id, selectedItem, num) => {
  return HttpClient.get(`${GET_ELIGIBILITY_POST}/${selectedItem}/${Id}/${num}`);
};

export const postCaseEligibility = (
  data = {},
  selectedItem,
  getEligbleId,
  id,
  selectedDetermFactor
) => {
  return HttpClient.put(
    POST_ELIGIBILITY,
    eligibilityPayload(
      data,
      selectedItem,
      getEligbleId,
      id,
      selectedDetermFactor
    )
  );
};

export const postCaseLDSSCode = (data = {}, editData = {}, uniqueIdToken) => {
  return HttpClient.post(POST_LDSSCODE(uniqueIdToken), lDSSCodePayload(data, editData), {
    headers: {
      Role: 'cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN',
      uid: 'localUser',
      Uniqueid: `${uniqueIdToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getLDSSCode = (uniqueIdToken) => {
  return HttpClient.get(GET_LDSSCODE(uniqueIdToken));
};

export const getLDSSOption = () => {
  return HttpClient.get(GET_LDSS_OPTION);
};
