import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import styles from '../../index.module.css';

export default function CPContactInfo({ data, getLkpMatch }) {
  const { phoneNumberDetails, email } =
    data?.[0]?.contactInformationDetails?.contactInformation ?? {};
  const {
    phoneNumber,
    phoneNumberTypeCd,
    altPhoneNumber,
    altPhoneNumberTypeCd,
  } = phoneNumberDetails ?? {};

  return (
    <TableContainer>
      <Table
        sx={{
          td: {
            padding: 1.5,
            paddingBottom: '0.5rem',
            border: 0,
          },
        }}
      >
        <TableBody className={styles.tableBody}>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Phone Number</Typography>
            </TableCell>
            <Typography variant="subtitle2">{phoneNumber ?? ''}</Typography>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Phone Number Type</Typography>
            </TableCell>
            <Typography variant="subtitle2">
              {getLkpMatch('PhoneType', phoneNumberTypeCd) ?? ''}
            </Typography>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Alternate Phone Number</Typography>
            </TableCell>
            <Typography variant="subtitle2">{altPhoneNumber ?? ''}</Typography>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">
                Alternate Phone Number Type
              </Typography>
            </TableCell>
            <Typography variant="subtitle2">
              {getLkpMatch('PhoneType', altPhoneNumberTypeCd) ?? ''}
            </Typography>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Email</Typography>
            </TableCell>
            <Typography variant="subtitle2"> {email ?? ''}</Typography>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
