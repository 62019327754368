import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { compliancePayload } from './apiPyload';
import { NONCOMPLIANCEDASHBOARD, NONCOMPLIANE_ADDEDIT } from './apiUrls';

export const getNoncomplianceDashboard = (caseId, individualId) => {
  return HttpClient.get(format(NONCOMPLIANCEDASHBOARD, caseId, individualId));
};

export const editAddNoncompliance = (caseId, individualId, data, events) => {
  return HttpClient.post(
    format(NONCOMPLIANE_ADDEDIT, caseId, individualId),
    compliancePayload(data, events)
  );
};

export const deleteNonconplianceRecord = (caseId, individualId, data) => {
  return HttpClient.post(
    format(NONCOMPLIANE_ADDEDIT, caseId, individualId),
    data
  );
};
