import dayjs from 'dayjs';

export const programTimeLimitPostApi = (
  dirtyStatus,
  payLoadData,
  individualId,
  caseId
) => {
  const addTimeClockQtnsList = payLoadData.addTimeClockQtnsList.map(
    (obj, ind) => {
      return {
        qtnsCd: obj.qtnsCd,
        verificationCd: {
          verificationSourceCode: obj.verificationCd.verificationSourceCode,
        },
      };
    }
  );

  return {
    clkStsBgnDt: dayjs(payLoadData.startDate).format('YYYY-MM-DD'),
    clkStsEndDt: dayjs(payLoadData.endDate).format('YYYY-MM-DD'),
    clkTypCd: payLoadData.clkTypCd,
    clkStsCd: payLoadData.status,
    staCd: payLoadData.state,
    cntyCd: null,
    caseId: caseId,
    individualId: individualId,
    divrInd: 'K',
    addTimeClockQtnsList: addTimeClockQtnsList,
    indTmClkId: dirtyStatus === 'insert' ? '' : payLoadData.indTmClkId,
    dirtyStatus: dirtyStatus,
  };
};
