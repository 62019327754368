import React, { useState, useEffect, useMemo } from 'react';
import ReinitiateModal from './ReinitiateModal';
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
} from '@mui/material';
import FormSection from '../../components/FormSection';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PageContainer from '../../components/PageContainer';
import CloseIcon from '@mui/icons-material/Close';
import usePageNav from '../../hooks/usePageNav';
import { postReinstate } from '../../utils/services/CaseReinitiate/apiRequests';
import { showErrorToast } from '../../utils/services/toast';
import useLoader from '../../hooks/useLoader';
import useLookup from '../../hooks/useLookup';
import TabularView from '../../components/TabularView';
import Field from '../../components/Field';
import { useFormik } from 'formik';
function CaseReinitiate() {
  const [open, setOpen] = useState(false);
  const { caseId, navigations } = usePageNav();
  const { getLkpMatch } = useLookup('Reinitiate');
  const [reinitiate, setReinstate] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [selectedProgramName, setSelectedProgramName] = useState(null);
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (caseId) {
      postReinitiateCase({ caseId });
    }
  }, [caseId]);

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Program Name',
        getValue({ programName = {} }) {
          return `${getLkpMatch('ProgramCode', programName)}`;
        },
      },
      {
        headerName: 'Program Status Code',
        getValue({ programStatusCode = {} }) {
          return `${getLkpMatch('ProgramStatus', programStatusCode)}`;
        },
      },
      {
        headerName: 'Reason',
        getValue({ statusReasonCode = {} }) {
          return `${getLkpMatch('EligibilityReasonCode', statusReasonCode)}`;
        },
      },
      {
        headerName: 'Program Status Date',
        getValue({ programStatusDate = {} }) {
          console.log(programStatusDate);
          return `${programStatusDate}`;
        },
      },
      {
        headerName: 'Benefits Received Until',
        getValue({ reopenDate }) {
          return `${reopenDate ?? 'N/A'}`;
        },
      },
      {
        headerName: 'Action',
        renderCell: (params) => {
          // console.log(params);
          return (
            <Button
              variant="contained"
              onClick={() => handleValidate(params?.programName)}
            >
              <TaskAltIcon />
              <Typography variant="h6" sx={{ paddingLeft: '5px' }}>
                Validate
              </Typography>
            </Button>
          );
        },
      },
    ];
  });

  const handleValidate = (programName) => {
    console.log(programName);
    setSelectedProgramName(programName);
    // console.log(setSelectedProgramName);
    setOpen(true);
  };

  const postReinitiateCase = async (data) => {
    try {
      showLoader();
      const res = await postReinstate(data, caseId);
      if (res.status === 200) {
        setReinstate(res?.data?.programNameList);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  // console.log(reinitiate);

  const formik = useFormik({
    initialValues: {
      caseId: caseId,
    },
  });
  return (
    <PageContainer title="Reinstate/Reopen">
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography
              variant="h5"
              color="gray"
              sx={{ pt: '2rem', pl: '1rem' }}
            >
              Reinstate/Reopen
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Field label="Case ID" formik={formik} name="caseId">
              <TextField defaultValue={caseId} disabled />
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={{
                marginTop: '1.5rem',
              }}
              variant="contained"
            >
              Reinstate
            </Button>
            <Button
              sx={{
                marginTop: '1.5rem',
                marginLeft: '1rem',
              }}
              variant="outlined"
              onClick={() => navigations.toCaseReOpen()}
            >
              Reopen
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Typography
            variant="h4"
            color="gray"
            sx={{
              paddingLeft: '1rem',
              mt: '1rem',
            }}
          >
            Selected Program
          </Typography>
          <FormSection mt="1rem" ml="1rem" mr="1rem">
            <TabularView
              columns={columns}
              data={reinitiate ?? []}
              headerComponent={{ variant: 'h4' }}
            />
          </FormSection>
        </Grid>
      </Paper>
      <Dialog maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>Reinstatement Validation</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ReinitiateModal
            onClose={onClose}
            selectedProgramName={selectedProgramName}
          />
        </DialogContent>
      </Dialog>
    </PageContainer>
  );
}

export default CaseReinitiate;
