import React, { useMemo } from 'react';
import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import NonComplianceContent from './NonComplianceContent/index.js';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import usePageNav from '../../hooks/usePageNav';

const NonCompliance = ({}) => {
  const { caseId, navigations } = usePageNav();
  const onNext = () => {
    navigations.toRoomerBoarder();
  };
  const onBack = () => {};
  const { houseMembers } = useSelector(selectHouseMembers);
  const { houseHoldMembers } = useMemo(() => {
    if (houseMembers) return { houseHoldMembers: houseMembers };
  }, [houseMembers]);

  return (
    <PageContainer
      // navProps={''}
      title="Add Non-Compliance Details"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <NonComplianceContent caseId={caseId} />
      </SelectableView>
    </PageContainer>
  );
};

export default NonCompliance;
