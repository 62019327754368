import React, { useRef, useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PotentialIncomeForm from './PotentialIncomeForm';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { savePotentialIncome } from '../../../utils/services/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';

const AddEditUnEarnedIncomeDetailsDialog = ({
  setOpen,
  open,
  isAdd,
  lkpRaw,
  member,
  caseId,
  fetchIncome,
  selectedData,
}) => {
  const descriptionElementRef = useRef('');
  const [showLoader, hideLoader] = useLoader();

  const initialValues = {
    anticipatedReceiptDt: null,
    assetType: '',
    description: '',
    attorneyName: '',
    attorneyTelephone: '',
  };

  const validationSchema = Yup.object({
    anticipatedReceiptDt: Yup.date('field is Required')
      .nullable()
      .typeError('field is required')
      .required('field is required'),
    assetType: Yup.string().required('field is reuired'),
    attorneyName: Yup.string().required('field is reuired'),
  });

  const handleClose = () => {
    formik.resetForm();
    formik.setValues(initialValues);
    setOpen(false);
  };

  const onSubmit = async (values) => {
    try {
      showLoader();
      const res = await savePotentialIncome(
        caseId,
        values,
        member.individualId,
        isAdd
      );

      if (res.status === 200) {
        showSuccessToast(
          isAdd
            ? 'Potential Income Application added'
            : 'Potential Income Application Updated'
        );
        fetchIncome();
        setOpen(false);
        formik.resetForm();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        scroll="paper"
        onClose={handleClose}
        aria-labelledby="potentialIncome"
        aria-describedby="potentialIncomeDescription"
      >
        <DialogTitle id="potentialIncome">
          {isAdd ? 'Add Potential' : 'Edit Potential'} Income
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText
              id="potentialIncomeDescription"
              ref={descriptionElementRef}
            >
              <PotentialIncomeForm
                lkpRaw={lkpRaw}
                member={member}
                isAdd={isAdd}
                formik={formik}
                selectedData={selectedData}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Discard
            </Button>
            <Button type="submit" variant="contained">
              Save & Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddEditUnEarnedIncomeDetailsDialog;
