import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack } from '@mui/material';
import { map } from 'lodash';
import Icon from '../../../../../../../components/Icon';

function CaseResults({ financialInfo }) {
  const {
    grossEarnedIncome = 0,
    grossUnearnedIncome = 0,
    totalFarmLoss = 0,
    totalGrossIncome = 0,
    dependentCareExpenses = 0,
    medicalExpenses = 0,
    shelterExpenses = 0,
    utilityExpenses = 0,
    childSpousalSupportExpenses = 0,
    totalAssets = 0,
    assetLimit = 0,
    incomeLimit = 0,
    earnedIncomeDeduction = 0,
    netEarnedIncome = 0,
    netUnearnedIncome = 0,
    standardDeduction = 0,
    medExpThresholdAmt = 0,
    medicalDeduction = 0,
    dependentCareDeduction = 0,
    childSpousalSupportDeduction = 0,
    shelterDednCapAmt = 0,
    homelessShelterAllowAmt = 0,
    shelterDeduction = 0,
    totalNetIncome = 0,
    maximumAllotmentAmt = 0,
    adjustedNetIncome = 0,
    fullGrantAmt = 0,
    minSupplementAmt = 0,
    benefitAmt = 0,
  } = financialInfo;

  const earnings = [
    {
      type: 'Gross Earned Income',
      amount: grossEarnedIncome,
    },
    {
      type: 'Gross Unearned Income',
      amount: grossUnearnedIncome,
    },
    {
      type: 'Total Farm Loss',
      amount: totalFarmLoss,
    },
    {
      type: 'Total Gross Income',
      amount: totalGrossIncome,
    },
    {
      type: 'Dependent Care Expenses',
      amount: dependentCareExpenses,
    },
    {
      type: 'Medical Expenses',
      amount: medicalExpenses,
    },
    {
      type: 'Shelter Expenses',
      amount: shelterExpenses,
    },
    {
      type: 'Utility Expenses',
      amount: utilityExpenses,
    },
    {
      type: 'Child Spousal Support Expenses',
      amount: childSpousalSupportExpenses,
    },
    {
      type: 'Total Assets',
      amount: totalAssets,
    },
    {
      type: 'Asset Limit',
      amount: assetLimit,
    },
    {
      type: 'Income Limit',
      amount: incomeLimit,
    },
    {
      type: 'Earned Income Deduction',
      amount: earnedIncomeDeduction,
    },
    {
      type: 'Net Earned Income',
      amount: netEarnedIncome,
    },
    {
      type: 'Net Unearned Income',
      amount: netUnearnedIncome,
    },
    {
      type: 'Standard Deduction',
      amount: standardDeduction,
    },
    {
      type: 'Med Exp Threshold Amount',
      amount: medExpThresholdAmt,
    },
    {
      type: 'Medical Deduction (8 - 25)',
      amount: medicalDeduction,
    },
    {
      type: 'Dependent Care Deduction',
      amount: dependentCareDeduction,
    },
    {
      type: 'Child Spousal Support Deduction',
      amount: childSpousalSupportDeduction,
    },
    // {
    //   type: 'Child Spousal Support Deductio',
    //   amount: 0,
    // },
    // {
    //   type: 'Child Spousal Support Deduction',
    //   amount: 0,
    // },
    // {
    //   type: 'Child Spousal Support Deduction',
    //   amount: 0,
    // },
    {
      type: 'Standard Amount Shelter Deduction',
      amount: shelterDednCapAmt,
    },
    {
      type: 'Homeless Shelter Allowance Amount',
      amount: homelessShelterAllowAmt,
    },
    {
      type: 'Shelter Deduction',
      amount: shelterDeduction,
    },
    {
      type: 'Total Net Income',
      amount: totalNetIncome,
    },
    // {
    //   type: 'Homeless Shelter Allowance Amount',
    //   amount: 0,
    // },
    // {
    //   type: 'Homeless Shelter Allowance Amount',
    //   amount: 0,
    // },
    {
      type: 'Maximum Allotment Amount',
      amount: maximumAllotmentAmt,
    },
    {
      type: 'Adjusted Net Income',
      amount: adjustedNetIncome,
    },
    {
      type: 'Full Grant Amount (42-43)',
      amount: fullGrantAmt,
    },
    {
      type: 'Minimum Supplement Amount',
      amount: minSupplementAmt,
    },
    {
      type: 'Benefit Amount',
      amount: benefitAmt,
    },
  ];

  return (
    <Grid container spacing={1}>
      {map(earnings, ({ type, amount }, index) => (
        <>
          <Grid item xs={8}>
            {`${index + 1}) ${type}`}
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" alignItems="baseline">
              <Icon width={10} height={12} name="DollarSign" />
              <Box ml="2px" component="span">
                {amount.toFixed(2)}
              </Box>
            </Stack>
          </Grid>
        </>
      ))}
    </Grid>
  );
}

CaseResults.propTypes = {
  financialInfo: PropTypes.shape({}),
};

CaseResults.defaultProps = {
  financialInfo: {},
};

export default CaseResults;
