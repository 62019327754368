import React, { useEffect, useMemo, useState } from 'react';
import { getVerification } from '../../utils/services/VerificationStatus/requests';
import useLoader from '../../hooks/useLoader';
import usePageNav from '../../hooks/usePageNav';
import SelectableView from '../../components/SelectableView';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import PageContainer from '../../components/PageContainer';
import { filter, map } from 'lodash';
import TabularView from '../../components/TabularView';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as EditDocumentIcon } from '../../assets/icons/edit-document.svg';
import { navigatePageName } from '../../store/selectors/caseSelector';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Icon from '../../components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigatePageName } from '../../store/slices/caseSlice';
import { Navigate } from 'react-router-dom';

export default function VerificationStatus() {
  const dispatch = useDispatch();
  const [verification, setVerification] = useState('');
  const { navigations, caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [verifyData, setVerifyData] = useState([]);
  const { houseMembers } = useSelector(selectHouseMembers);

  const getApi = async () => {
    showLoader();
    try {
      const res = await getVerification(caseId);
      if (res.status === 200) {
        setVerification(res.data);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getApi();
  }, []);

  const nextPage = (data) => {
    dispatch(setNavigatePageName(data));
    switch (data) {
      case 'LIVING_ARRANGEMENT':
        navigations.toLivingArrangement();

        break;
      case 'SS':
        break;
      case 'ED':
        break;
      case 'GOOD_CAUSE_VERIFICATION':
        break;
      case 'DOB':
        navigations.toHhMembers();
        break;
      case 'DEATH':
        navigations.toHhMembers();
        break;
      case 'LAWFUL_PRESENCE':
        navigations.toCitizenship();

        break;
      case 'MED':
        break;
      case 'TREATMENT':
        break;
      case 'OTO':
        break;
      case 'RMA':
        break;
      case 'EI_AMT':
      case 'EMPLYMENT':
        navigations.toIncome();
        break;

      case 'SHELTER_EXPENSE':
        break;
      case 'FI_EI':
        break;
      case 'FI_UI':
        break;
      case 'LIQUID_ASSET':
        navigations.toAsset();
        break;
      case 'FI_SA':
        break;
      case 'FI_UA':
        break;
      case 'FI_ME':
        break;
      case 'SSN':
        navigations.toHhMembers();
        break;
      case 'RACE':
        navigations.toHhMembers();
        break;
      case 'CITIZENSHIP':
        navigations.toCitizenship();
        break;
      case 'PREGNANCY': {
        navigations.toPregnancyInfo();
        break;
      }
      case 'INCOME':
        navigations.toIncome();
        break;
      case 'RACE_ETHNICITY':
        navigations.toHhMembers();
        break;
      case 'STUDENT_GRADUATION_DT':
      case 'STUDENT_STATUS':
      case 'STUDENT_GOOD_STANDING':
      case 'STUDENT_GRADUATION_DT': {
        navigations.toEducationDetails();
      }

      default:
    }
  };

  const onBack = () => {
    navigations.toProgramTimeLimit();
  };

  const onNext = () => {
    navigations.toHouseholdDeclaration();
  };
  const columns = useMemo(() => {
    return [
      {
        headerName: 'Verification Name',
        getValue({ verificationName }) {
          return verificationName;
        },
      },
      {
        headerName: 'Verification Source',
        getValue({ verificationSource }) {
          return verificationSource;
        },
      },
      {
        headerName: 'verified',
        renderCell: (params) => (
          <Stack>
            {params.verifiedYN === 'Y' ? (
              <Stack direction="row" color="#73985e" spacing={0.5}>
                {/* <checkedBoxIcon /> */}
                <Icon name="checkedBoxIcon" />
                <Typography variant="h5">Yes</Typography>
              </Stack>
            ) : (
              <Stack direction="row" spacing={0.5}>
                <CancelOutlinedIcon sx={{ color: 'var(--error-500)' }} />
                <Typography variant="h5">No</Typography>
              </Stack>
            )}
          </Stack>
        ),
      },
      {
        headerName: 'Action',
        renderCell: (params) => (
          <Button
            size="small"
            variant="contained"
            onClick={() => nextPage(params.verificationNameCode)}
            startIcon={<EditDocumentIcon />}
          >
            {params.verifiedYN === 'Y' ? 'Verified' : 'To Verify'}
          </Button>
        ),
      },
    ];
  });
  return (
    <PageContainer title="Verification Status" onNext={onNext} onBack={onBack}>
      <SelectableView
        // loading={waiverLoader}
        // loadingContent={waiverLoader}
        data={houseMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        {(selected) => {
          map(
            verification,
            (value) =>
              value.individualId === selected?.individualId &&
              setVerifyData(value?.clientVerifications)
          );

          return (
            <Stack mt={1}>
              <TabularView
                sx={{
                  table: {
                    width: '100%',
                    border: '2px solid var(--grey-300)',
                  },
                  td: {
                    border: 0,
                    padding: '.3rem .3rem 0.5rem .5rem',
                    bgcolor: 'var(--grey-100)',
                    margin: 1,
                  },
                  th: {
                    border: 0,
                    borderBottom: '1px solid var(--grey-300)',
                    padding: '.5rem 1.25rem 0.5rem .5rem',
                    margin: 1,
                  },
                }}
                headerComponent={{ variant: 'h4', color: 'var(--grey-700)' }}
                cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
                columns={columns}
                data={verifyData}
              />
            </Stack>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}
