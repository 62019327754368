import { createSlice } from '@reduxjs/toolkit';

export const userDataSlice = createSlice({
  name: 'userDetails',
  initialState: {
    getUserData: [],
  },
  reducers: {
    setUserData(state, action) {
      console.log(action.payload);
      state.getUserData = action.payload;
    },
  },
});

export const { setUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
