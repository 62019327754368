export const confirmPayload = (data) => {
  const { programName, programId, programSubType } = data;
  return [
    {
      dirtyStatus: '',
      programId: programId,
      programName: programName,
      programStatusCode: '',
      applicationDate: null,
      selectedIndicator: '',
      programSubType: programSubType ?? '',
      finalizeProgramIndicator: 'Y',
      programMode: '',
      msnapInd: '',
      spendDownBeginDt: null,
      rescndDate: null,
      rescndReason: null,
      spendDownEndDt: null,
    },
  ];
};
