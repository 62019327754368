import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import SelectableView from '../../components/SelectableView';
import styles from './index.module.css';
import { getIncome } from '../../utils/services/apiRequests';
import { useParams } from 'react-router';
import { get, map } from 'lodash';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import UnearnedIncome from './UnEarnedIncome';
import useLookup from '../../hooks/useLookup';
import EarnedIncome from './EarnedIncome';
import UnearnedIncomeApplication from './UnEarnedIncomeApplication';
import PotentialIncome from './PotentialIncome';
import DocumentUploadFilesDialog from './DocumentUploadFilesDialog';
import { getAge } from '../../utils/helpers';
import { showErrorToast } from '../../utils/services/toast';
import usePageNav from '../../hooks/usePageNav';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import { useSelector } from 'react-redux';
import { selectCaseDetails } from '../../store/selectors/caseSelector';
import PageContainer from '../../components/PageContainer';
import usePage from '../../hooks/usePage';
import { navigatePageName } from '../../store/selectors/caseSelector';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function Income({ id }) {
  const params = useParams();
  const { navProps } = usePage(id);
  const { caseId, navigations } = usePageNav();
  const [activeTab, setActiveTab] = useState(0);
  const [incomeList, setIncomeList] = useState([]);
  const [fetchingIncomes, setFetchingIncomes] = useState(false);
  const [open, setOpen] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(null);
  const [fedEmplrList, setFedEmplrList] = useState([]);

  const caseDetails = useSelector(selectCaseDetails);
  const { lkpFlatten, getLkpMatch, getOptions, lkpRaw, islkpFailed } =
    useLookup('Income');

  const programs = map(
    caseDetails?.programs ?? [],
    (value) => value.programName
  );
  const verifyPageName = useSelector(navigatePageName);
  const houseMembers = useMemo(() => {
    return get(incomeList, 'householdMembers');
  }, [incomeList]);

  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const fetchIncome = async () => {
    try {
      setFetchingIncomes(true);
      const res = await getIncome(params.caseId);
      if (res.status === 200) {
        setIncomeList(res.data);
        setFedEmplrList(res.data.fedEmplrList);
      }
    } catch (error) {
      showErrorToast();
    } finally {
      setFetchingIncomes(false);
    }
  };

  useEffect(() => {
    fetchIncome();
  }, []);

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const handleAddClick = () => {
    setOpen(true);
    setIsAdd(true);
  };

  const onNext = () => {
    if (programs.includes('LT') || programs.includes('WV')) {
      navigations.toWaiverAndFacility();
    } else {
      navigations.toMedicareDetails();
    }
  };

  const onBack = () => {
    navigations.toAsset();
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Income"
      toVerify={
        ['EI_AMT', 'EMPLYMENT'].includes(verifyPageName) ? verifyPageName : '  '
      }
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={houseMembers}
        loading={fetchingIncomes}
        title="Members"
        showEdit={false}
        showDelete={false}
        loadingContent={fetchingIncomes}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
      >
        {(selected) => {
          return (
            <div className={styles.income}>
              <Tabs
                onChange={onTabChange}
                value={activeTab}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable tab income"
              >
                <Tab label="Earned Income" />
                <Tab label="Unearned Income" />
                <Tab label="Unearned Income Application" />
                <Tab label="Potential Income" />
                <Tab label="Custodial Payment" />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <Stack
                  direction="row"
                  spacing={2}
                  my={2}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >
                    Add Earned Income
                  </Button>
                </Stack>
                <EarnedIncome
                  member={selected}
                  lkpFlatten={lkpFlatten}
                  getLkpMatch={getLkpMatch}
                  lkpRaw={lkpRaw}
                  setOpen={setOpen}
                  open={open}
                  isAdd={isAdd}
                  setIsAdd={setIsAdd}
                  caseId={params.caseId}
                  docOpen={docOpen}
                  setDocOpen={setDocOpen}
                  fetchIncome={fetchIncome}
                  fedEmplrList={fedEmplrList}
                  getOptions={getOptions}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Stack
                  direction="row"
                  spacing={2}
                  my={2}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >
                    Add Unearned Income
                  </Button>
                </Stack>
                <UnearnedIncome
                  member={selected}
                  lkpFlatten={lkpFlatten}
                  getLkpMatch={getLkpMatch}
                  lkpRaw={lkpRaw}
                  setOpen={setOpen}
                  open={open}
                  caseId={params.caseId}
                  isAdd={isAdd}
                  setIsAdd={setIsAdd}
                  docOpen={docOpen}
                  setDocOpen={setDocOpen}
                  fetchIncome={fetchIncome}
                  getOptions={getOptions}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Stack
                  direction="row"
                  spacing={2}
                  mb={1}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >
                    Add Unarned Income Application
                  </Button>
                </Stack>
                <UnearnedIncomeApplication
                  member={selected}
                  setOpen={setOpen}
                  open={open}
                  isAdd={isAdd}
                  setIsAdd={setIsAdd}
                  docOpen={docOpen}
                  setDocOpen={setDocOpen}
                  lkpFlatten={lkpFlatten}
                  getLkpMatch={getLkpMatch}
                  lkpRaw={lkpRaw}
                  fetchIncome={fetchIncome}
                  caseId={params.caseId}
                  getOptions={getOptions}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <Stack
                  direction="row"
                  spacing={2}
                  mb={1}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >
                    PotentialIncome
                  </Button>
                </Stack>
                <PotentialIncome
                  member={selected}
                  setOpen={setOpen}
                  open={open}
                  isAdd={isAdd}
                  setIsAdd={setIsAdd}
                  docOpen={docOpen}
                  setDocOpen={setDocOpen}
                  lkpFlatten={lkpFlatten}
                  getLkpMatch={getLkpMatch}
                  lkpRaw={lkpRaw}
                  fetchIncome={fetchIncome}
                  caseId={params.caseId}
                  getOptions={getOptions}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={4}>
                No child supports found for this individual.
                <Stack
                  direction="row"
                  spacing={2}
                  mt={2}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  {/* <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >Add Unarned Income
                  </Button> */}
                  <DocumentUploadFilesDialog
                    docOpen={docOpen}
                    setDocOpen={setDocOpen}
                    isAdd={isAdd}
                    setIsAdd={setIsAdd}
                    lkpRaw={lkpRaw}
                    member={selected}
                    caseId={params.caseId}
                  />
                </Stack>
              </TabPanel>
            </div>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}

Income.propTypes = {};

export default Income;
