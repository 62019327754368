import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography } from '@mui/material';
import TabularView from '../../../components/TabularView';
import { getFullName } from '../../../utils/helpers';
import Avatar from '../../../components/Avatar';
import SignaturePad from '../../../components/SignaturePad';
import { filter, findIndex, includes, map, reject } from 'lodash';
import Dropdown from '../../../components/Dropdown';
import dayjs from 'dayjs';

const DEFAULT_SIGNATURES = {
  signatureId: 0,
  signatoryName: '',
  signatoryType: 'HM',
  signatureImage: '',
  signedDt: null,
  signatureSource: 'screening',
  updatedBy: 'System',
  updatedDate: null,
  programId: 0,
  individualId: 0,
};

function SignatoryTable({
  individuals,
  selectedPrograms,
  getProgramLkp,
  signatures,
  setSignatures,
}) {
  const [currentSignId, setCurrentSignId] = useState('');

  const onSelectProgram = (individualId, program) => {
    const index = findIndex(signatures, { individualId });
    // const isIncluded = includes(signatures[index].programs, program);
    let newPrograms = program;
    // if (isIncluded) {
    //   newPrograms = reject(signatures[index].programs, (p) => p === program);
    // } else {
    //   newPrograms = [...signatures[index].programs, program];
    // }
    const newSignatures = Object.assign([], signatures, {
      [index]: {
        ...signatures[index],
        programs: newPrograms,
      },
    });

    setSignatures(newSignatures);
  };

  const toggleSignature = (signatureImage) => {
    if (signatureImage) {
      const index = findIndex(signatures, { individualId: currentSignId });
      const signedBy = signatures[index];
      const { signatoryName, individualId } = signedBy;
      const newSignatures = Object.assign([], signatures, {
        [index]: {
          ...signedBy,
          signature: {
            ...DEFAULT_SIGNATURES,
            signatoryName,
            individualId,
            signatureImage,
            signedDt: dayjs().format('YYYY-MM-DD'),
          },
        },
      });

      setSignatures(newSignatures);
    }
    setCurrentSignId('');
  };

  const programOptions = useMemo(() => {
    return map(selectedPrograms, (p) => {
      const label = getProgramLkp(p);
      return {
        label,
        value: p,
      };
    });
  }, [getProgramLkp, selectedPrograms]);

  const columns = [
    {
      headerName: 'Member Name',
      renderCell({ signatoryName, personName }) {
        // const { firstName = '', lastName = '' } = personName;
        // const name = getFullName(firstName, lastName);

        return (
          <Stack
            direction="row"
            sx={{ alignItems: 'center', width: '15rem' }}
            spacing={1}
          >
            <Avatar name={signatoryName} />
            <Stack>
              <Typography variant="subtitle1">{signatoryName}</Typography>
              <Typography
                sx={{ color: 'var(--primary-light)' }}
                variant="subtitle2"
              >
                HOH
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: 'SSN',
      //   headerName: 'Social Security Number',
      renderCell({ individualId }) {
        return <Box style={{ textAlign: 'center' }}>-</Box>;
      },
    },
    {
      headerName: 'Relation',
      renderCell({ individualId }) {
        return (
          <Box sx={{ width: '8rem' }}>
            <Typography variant="subtitle1">Self</Typography>
          </Box>
        );
      },
    },
    {
      headerName: 'Add Signature',
      renderCell(
        { individualId, signature, statusReasons, requestIndicator },
        index,
        p
      ) {
        const { signatureImage } = signature;
        return (
          <Box>
            {!signatureImage ? (
              <Button onClick={() => setCurrentSignId(individualId)}>
                Signature
              </Button>
            ) : (
              <Button onClick={() => setCurrentSignId(individualId)}>
                <img width={60} height={32} alt="sign" src={signatureImage} />
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      headerName: 'Signed programs',
      renderCell({ individualId, signature, programs }, index, p) {
        const { signatureImage } = signature;
        const onChange = (e) => {
          onSelectProgram(individualId, e.target.value);
        };

        return (
          <Dropdown
            multiple
            showCheckbox
            disabled={!signatureImage}
            options={programOptions}
            value={programs}
            onChange={onChange}
          />
        );
      },
    },
  ];

  return (
    <Stack>
      <SignaturePad open={!!currentSignId} onClose={toggleSignature} />
      <Typography variant="subtitle1">Add Signature</Typography>
      <TabularView
        headerComponent={{ variant: 'h5' }}
        sx={{
          table: {
            // width: 'auto',
            border: '2px solid #f5f5f5',
          },
          thead: {
            backgroundColor: 'var(--grey-200)',
            borderRadius: '0.25rem',
          },
          td: {
            border: 0,
            p: '1rem',
          },
          th: {
            p: '1rem',
            border: 0,
          },
        }}
        data={signatures}
        columns={columns}
      />
    </Stack>
  );
}

SignatoryTable.propTypes = {};

export default SignatoryTable;
