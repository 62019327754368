import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  CASECREATION_DATAVIEW,
  CLEARANCE,
  CONSUMER_MEMBER,
  CP_IMPORT_CASE,
  IMPORT_COMPLETION,
  INDIVIDUAL_ASSISTANCE,
  JSON_RETRIEVE,
  JSON_SAVE,
  POST_CLEARANCE,
  RECALL_INDIVIDUAL,
} from './apiUrls';
import {
  clearancePayload,
  disabilityPayload,
  hohPayload,
  jsonSavePayload,
  membersPayload,
  postClearancePayload,
  recallIndividualPayload,
} from './apiPayload';

export const getHoHData = (data = {}) => {
  return HttpClient.post(format(JSON_RETRIEVE), hohPayload(data));
};

export const getDisability = (data = {}) => {
  return HttpClient.post(format(JSON_RETRIEVE), disabilityPayload(data));
};

export const getMembersData = (data = {}) => {
  return HttpClient.post(format(JSON_RETRIEVE), membersPayload(data));
};

export const getRunClearance = (data = {}) => {
  return HttpClient.post(format(CLEARANCE), clearancePayload(data));
};

export const getIndividualAssistance = (mdmId) =>
  HttpClient.get(format(INDIVIDUAL_ASSISTANCE, mdmId));

export const getCaseCreationDataviewData = (controlId) =>
  HttpClient.get(format(CASECREATION_DATAVIEW, controlId));

export const postRunClearance = (data = {}, controlId, caseId) => {
  return HttpClient.post(
    format(POST_CLEARANCE, controlId),
    postClearancePayload(data, caseId)
  );
};

export const getRecallIndividual = (data = {}) => {
  return HttpClient.post(
    format(RECALL_INDIVIDUAL),
    recallIndividualPayload(data)
  );
};

export const postConsumerMember = (
  data = {},
  caseId,
  dirtyStatus,
  individualId = null
) => {
  return HttpClient.post(
    format(CONSUMER_MEMBER),
    postClearancePayload(data, caseId, dirtyStatus, individualId)
  );
};

export const postJsonSave = (
  data = {},
  caseId,
  dirtyStatus,
  controlId,
  hohMemberData
) => {
  return HttpClient.post(
    data?.clientContainer?.client?.headOfHouseholdYN === 'Y'
      ? format(JSON_SAVE, controlId, 148)
      : format(JSON_SAVE, controlId, 151),
    jsonSavePayload(data, caseId, dirtyStatus, hohMemberData),
    {
      headers: {
        'Content-Type': 'text/plain',
      },
    }
  );
};

export const postImportCompletion = (data = {}, controlId) => {
  return HttpClient.post(format(IMPORT_COMPLETION, controlId), data);
};

export const postCpImportCase = (data = {}, caseId, controlId) => {
  return HttpClient.post(format(CP_IMPORT_CASE, caseId, controlId), data);
};
