import { get } from 'lodash';

export const validateEarnIncomeDetails = (formik, incomeIndex, fieldName) => {
  const hasErrors =
    get(formik, `errors.earnedIncomeAmount[${incomeIndex}].${fieldName}`) &&
    get(formik, `touched.earnedIncomeAmount[${incomeIndex}].${fieldName}`);

  return !!hasErrors;
};

export const validateEarnIncomeAmountDetails = (
  formik,
  incomeIndex,
  index,
  keyName,
  fieldName
) => {
  const hasErrors =
    get(
      formik,
      `errors.earnedIncomeAmount[${incomeIndex}].${keyName}[${index}].${fieldName}`
    ) &&
    get(
      formik,
      `touched.earnedIncomeAmount[${incomeIndex}].${keyName}[${index}].${fieldName}`
    );

  return !!hasErrors;
};

export const validateUnearnIncomeDetails = (formik, incomeIndex, fieldName) => {
  const hasErrors =
    get(formik, `errors.unearnedIncomeAmount[${incomeIndex}].${fieldName}`) &&
    get(formik, `touched.unearnedIncomeAmount[${incomeIndex}].${fieldName}`);
  // return !!hasErrors;
  if (hasErrors)
    return get(
      formik,
      `errors.unearnedIncomeAmount[${incomeIndex}].${fieldName}`
    );
};

export const validateUnearnIncomeAmountDetails = (
  formik,
  incomeIndex,
  index,
  keyName,
  fieldName
) => {
  const hasErrors =
    get(
      formik,
      `errors.unearnedIncomeAmount[${incomeIndex}].${keyName}[${index}].${fieldName}`
    ) &&
    get(
      formik,
      `touched.unearnedIncomeAmount[${incomeIndex}].${keyName}[${index}].${fieldName}`
    );

  return !!hasErrors;
};
