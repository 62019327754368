import React, { useMemo, useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Checkbox,
  Stack,
} from '@mui/material';
import FormSection from '../../components/FormSection';
import TabularView from '../../components/TabularView';
import ReOpenValid from './ReOpenModal';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PageContainer from '../../components/PageContainer';
import CloseIcon from '@mui/icons-material/Close';
import usePageNav from '../../hooks/usePageNav';
import useLoader from '../../hooks/useLoader';
import { postReinstate } from '../../utils/services/CaseReinitiate/apiRequests';
import { showErrorToast } from '../../utils/services/toast';
import useLookup from '../../hooks/useLookup';
import useCaseStatus from '../../hooks/useCaseStatus';
import Field from '../../components/Field';
import { useFormik } from 'formik';
import { getCaseDetails } from '../../utils/services/apiRequests';
import { reject, some } from 'lodash';

function ReOpen() {
  const [open, setOpen] = useState(false);
  const { caseId, navigations } = usePageNav();
  const { getLkpMatch, lkpRaw } = useLookup('Reinitiate');
  const [showLoader, hideLoader] = useLoader();
  const [reinitiate, setReinstate] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState({});
  const [selectedProgramName, setSelectedProgramName] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [remainingProgramNames, setRemainingProgramNames] = useState([]);
  console.log('selectedPrograms', selectedPrograms);
  const { getCaseStatus } = useCaseStatus();
  // const caseStatus = getCaseStatus(caseId, false);

  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (caseId) {
      getProgramNames(caseId);
      postReinitiateCase({ caseId });
    }
  }, [caseId]);

  const checkPrograms = (programs) => {
    return programs.some(
      (program) => program.programName == 'WV' || program.programName == 'LT'
    );
  };
  const getProgramNames = async (caseId) => {
    try {
      const res = await getCaseDetails(caseId);
      console.log(res);
      if (res.status === 200) {
        const remainingPrograms = reject(
          lkpRaw?.RequestedPrograms,
          ({ lkpDesc }) => some(res?.data?.programs, { programName: lkpDesc })
        );
        const remainingProgramNames = remainingPrograms.map(
          ({ lookupLongVal }) => lookupLongVal
        );
        console.log(remainingProgramNames, 'remainingProgramNames');

        const isCheck = checkPrograms(reinitiate);
        if (isCheck) {
          const excludeProgram = remainingProgramNames.filter(
            (program) =>
              ![
                'Disaster SNAP',
                'Long Term Care',
                'Emergency Assistance',
                'SNAP',
                'Medicaid',
                'Cash Assistance',
              ].includes(program)
          );
          console.log('excludeProgram', excludeProgram);
          setRemainingProgramNames(excludeProgram);
        } else {
          const excludeProgram = remainingProgramNames.filter(
            (program) =>
              !['Long Term Care', 'Waiver', 'Disaster SNAP'].includes(program)
          );
          console.log('excludeProgram', excludeProgram);
          setRemainingProgramNames(excludeProgram);
        }
        // return remainingProgramNames;
      }
    } catch (error) {
      showErrorToast(error);
    }
  };
  console.log(reinitiate, 'lol');

  const handleCheckbox = (programName, isChecked) => {
    setSelectedPrograms((prevState) => ({
      ...prevState,
      [programName]: isChecked,
    }));
  };
  const programColumns = useMemo(() => {
    return [
      {
        headerName: 'Program Name',
        renderCell: (params) => {
          const programName = params?.programName;
          return (
            <div>
              <Checkbox
                checked={selectedPrograms[programName] || false}
                onChange={(e) => handleCheckbox(programName, e.target.checked)}
              />
              {`${getLkpMatch('ProgramCode', programName)}`}
            </div>
          );
        },
      },
      {
        headerName: 'Action',
        renderCell: (params) => {
          const programName = params?.programName;
          return (
            <Button
              variant="contained"
              disabled={!selectedPrograms[programName]}
              onClick={() => handleValidate(params?.programName)}
            >
              <TaskAltIcon />
              <Typography
                variant="h6"
                sx={{
                  paddingLeft: '5px',
                }}
              >
                Validate
              </Typography>
            </Button>
          );
        },
      },
    ];
  }, [selectedPrograms]);

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Program Name',
        getValue({ programName = {} }) {
          return `${getLkpMatch('ProgramCode', programName)}`;
        },
      },
      {
        headerName: 'Program Status Code',
        getValue({ programStatusCode = {} }) {
          return `${getLkpMatch('ProgramStatus', programStatusCode)}`;
        },
      },
      {
        headerName: 'Reason',
        getValue({ statusReasonCode = {} }) {
          return `${getLkpMatch('EligibilityReasonCode', statusReasonCode)}`;
        },
      },
      {
        headerName: 'Program Status Date',
        getValue({ programStatusDate = {} }) {
          console.log(programStatusDate);
          return `${programStatusDate}`;
        },
      },
      {
        headerName: 'Benefits Received Until',
        getValue({ reopenDate }) {
          return `${reopenDate ?? 'N/A'}`;
        },
      },
      {
        headerName: 'Action',
        renderCell: (params) => {
          // console.log(params);
          return (
            <Button
              variant="contained"
              onClick={() => handleValidate(params?.programName)}
            >
              <TaskAltIcon />
              <Typography
                variant="h6"
                sx={{
                  paddingLeft: '5px',
                }}
              >
                Validate
              </Typography>
            </Button>
          );
        },
      },
    ];
  });
  const postReinitiateCase = async (data) => {
    try {
      showLoader();
      const res = await postReinstate(data, caseId);
      if (res.status === 200) {
        setReinstate(res?.data?.programNameList);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const handleValidate = (programName) => {
    console.log(programName);
    setSelectedProgramName(programName);
    // console.log(setSelectedProgramName);
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      caseId: caseId,
    },
  });

  return (
    <PageContainer title="Reinstate/Reopen">
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography
              variant="h5"
              color="gray"
              sx={{ pt: '2rem', pl: '1rem' }}
            >
              Reinstate/Reopen
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Field label="Case ID" name="caseId" formik={formik}>
              <TextField defaultValue={caseId} disabled />
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Button
              sx={{
                marginTop: '1.5rem',
              }}
              variant="outlined"
              onClick={() => navigations.toCaseReinitiate()}
            >
              Reinstate
            </Button>
            <Button
              sx={{
                marginTop: '1.5rem',
                marginLeft: '1rem',
              }}
              variant="contained"
            >
              Reopen
            </Button>
          </Grid>
        </Grid>

        <Grid>
          <Typography
            variant="h4"
            color="gray"
            sx={{
              paddingLeft: '1rem',
              mt: '1rem',
            }}
          >
            Selected Program
          </Typography>
          <FormSection mt="1rem" ml="1rem" mr="1rem">
            <TabularView
              columns={columns}
              data={reinitiate ?? []}
              headerComponent={{ variant: 'h4' }}
            />
          </FormSection>
        </Grid>
        <Grid
          sx={{
            paddingTop: '1.5rem',
          }}
        >
          <Typography
            variant="h4"
            color="gray"
            sx={{
              paddingLeft: '1rem',
            }}
          >
            Unselected Program
          </Typography>
          <FormSection mt="1rem" ml="1rem" mr="1rem">
            <TabularView
              columns={programColumns}
              data={remainingProgramNames.map((programName) => {
                console.log('programName', programName);
                return {
                  programName,
                };
              })}
              //  data={}
              headerComponent={{ variant: 'h4' }}
            />
          </FormSection>
        </Grid>
      </Paper>

      {/* <TabularView
              columns={columns}
              data={reinitiate ?? []}
              headerComponent={{ variant: 'h4' }}
            /> */}

      <Dialog maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>ReOpen Validation</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ReOpenValid
            onClose={onClose}
            selectedProgramName={selectedProgramName}
          />
        </DialogContent>
      </Dialog>
    </PageContainer>
  );
}

export default ReOpen;
