import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import styles from '../../index.module.css';

export default function CPYourInformation({ data, getLkpMatch }) {
  const { personName, dob, soSec, gender, maritalStatus } =
    data?.[0]?.clientContainer?.client ?? {};
  const { firstName, middleName, lastName, suffix } = personName ?? {};
  const { soSecPart1, soSecPart2, soSecPart3 } = soSec ?? {};

  return (
    <TableContainer>
      <Table
        sx={{
          td: {
            padding: 1.5,
            paddingBottom: '0.5rem',
            border: 0,
          },
        }}
      >
        <TableBody className={styles.tableBody}>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Name</Typography>
            </TableCell>{' '}
            <TableCell>
              <Typography variant="subtitle2">{`${firstName ?? ''} ${
                middleName ?? ''
              } ${lastName ?? ''}`}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Suffix</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{suffix ?? ''}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">SSN</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{`${soSecPart1 ? '***' : ''} ${
                soSecPart2 ? '**' : ''
              } ${soSecPart3 ?? ''}`}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">DOB</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{dob}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Gender</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">
                {getLkpMatch('Gender', gender)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6grey">Marital Status</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">
                {getLkpMatch('MartialStatus', maritalStatus?.marriedYN)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
