import { POST_CUSTOMER_SEARCH, POST_EDUCATION_DETAILS } from './url';
import HttpClient from '../../HttpClient';
import { customerSearchPayload, providerSearchPayload } from './payLoads';
import { format } from '../../helpers/index';

export const postProviderSearch = (data = {}) => {
  return HttpClient.post(
    format(POST_EDUCATION_DETAILS),
    providerSearchPayload(data)
  );
};

export const postCustomerSearch = (data = {}) => {
  return HttpClient.post(
    format(POST_CUSTOMER_SEARCH),
    customerSearchPayload(data)
  );
};
