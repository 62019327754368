import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  assignedApi,
  myDashboardApi,
  overDueApi,
  unassignedApi,
} from '../../../utils/services/apiRequests';
import { getAuthToken } from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useCaseStatus from '../../../hooks/useCaseStatus';
import { Grid, Tooltip, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useMount from '../../../hooks/useMount';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

function Redetermination({ lkpFlatten, getLkpMatch }) {
  const [fetching, setFetching] = useState(false);
  const [overdueApplication, setOverdueApplication] = useState([]);
  const token = useSelector(getAuthToken);
  const [showLoader, hideLoader] = useLoader();
  const { viewCase } = useCaseStatus();

  const action = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => viewCase(params.id, params.row.caseStsCd)}>
          <Tooltip title="View Case" placement="right">
            <VisibilityIcon />
          </Tooltip>
        </IconButton>
      </Grid>
    );
  };

  const columns = [
    {
      field: 'appointmentDate',
      headerName: 'Interview Date',
      width: 150,
      renderCell: (params) =>
        dayjs(params?.row?.assignedDt).format('MM-DD-YYYY'),
    },

    { field: 'interviewType', headerName: 'Interview Type', width: 150 },

    { field: 'caseId', headerName: 'Case ID', width: 150 },

    { field: 'hohName', headerName: 'HOH', width: 150 },

    {
      field: 'programType',
      headerName: 'Program Type',
      renderCell: (params) => lkpFlatten?.Program?.[params?.row?.programs],
      width: 150,
    },

    { field: 'caseMode', headerName: 'Case Mode', width: 150 },

    {
      field: 'name',
      headerName: 'Case Worker',
      width: 150,
    },
  ];

  const fetchData = async () => {
    try {
      setFetching(true);
      showLoader();
      const res = await overDueApi(token?.sub, 'AL');
      if (res.status === 200) {
        setOverdueApplication(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  useMount(() => {
    fetchData();
  });

  return (
    <div>
      <DataGrid
        getRowId={(row) => (row.caseId ? row.caseId : row.webId || row.webID)}
        rows={[]}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Note: Please click Get Work Item to Get Records"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        autoHeight
        pageSizeOptions={[10, 20, 30]}
      />
    </div>
  );
}

Redetermination.propTypes = {};

export default Redetermination;
