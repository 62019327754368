import React, { useMemo, useEffect, useState } from 'react';
import HealthDataGrid from '../HealthDataGrid';
import { getSocialSecurityDM } from '../../../utils/services/healthAndDisability/apiRequests';
import useLoader from '../../../hooks/useLoader';
import AddSocialBenifits from './AddSocialBenifits';
import { Box } from '@mui/material';
import ActionButtons from '../../../components/ActionButtons';
import { deleteBenefits } from '../../../utils/services/healthAndDisability/apiRequests';
import DeleteConfiramation from '../DeleteConfiramation';

const SocialSecurityBenifits = ({
  member,
  setOpen,
  open,
  isAdd,
  setIsAdd,
  lkpRaw,
  lkpFlatten,
  getLkpMatch,
  caseId,
  getOptions,
  disabilitiesData,
  setAction,
  action,
  fetchData,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [allData, setAllData] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleEdit = (params) => {
    const getListfromRow = allData?.find((obj) => obj.recordId === params.id);
    setOpen(true);
    setCurrentData(getListfromRow);
    setAction('update');
  };

  const handleDelete = (params) => {
    const getListfromRow = allData?.find((obj) => obj.recordId === params.id);
    setDeleteData(getListfromRow);
    setOpenDeleteDialog(true);
  };

  const confrimDelete = async () => {
    try {
      const res = await deleteBenefits(caseId, member.id, {
        ...deleteData,
        dirtyStatus: 'delete',
      });
      if (res.status === 200) {
        setRefresh(!refresh);
      }
    } catch (e) {
    } finally {
      setDeleteData({});
      setOpenDeleteDialog(false);
    }
  };

  const setDashboard = async () => {
    showLoader();

    try {
      const res = await getSocialSecurityDM(caseId, member.id);
      if (res.status === 200) {
        setAllData(res.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      hideLoader();
    }
  };

  const columns = [
    {
      field: 'receivingBenefit',
      headerName: 'Receiving Benefit',
      width: 150,
      id: 1,
    },
    { field: 'status', headerName: ' Status', width: 80, id: 2 },
    { field: 'statusDate', headerName: 'Status Date', width: 110, id: 3 },
    { field: 'result', headerName: 'Result', width: 80, id: 4 },
    { field: 'resultDate', headerName: 'Result Date', width: 140, id: 5 },

    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      width: 100,
      id: 7,
    },
    { field: 'effectiveEndDate', headerName: 'End Date', width: 100, id: 8 },
    {
      field: '',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => {
        return (
          <ActionButtons
            onDelete={() => handleDelete(params)}
            onEdit={() => handleEdit(params)}
          />
        );
      },
    },
  ];

  const rows = useMemo(() => {
    return allData?.map((obj) => {
      return {
        receivingBenefit: getLkpMatch(
          'SocialSecurityBenefits',
          obj.receivingBenefit
        ),
        id: obj.recordId,
        status: getLkpMatch('SocialSecurityBenefitsStatus', obj.status),
        statusDate: obj.statusDate,
        result: getLkpMatch('SocialSecurityBenefitsResult', obj.result),
        effectiveBeginDate: obj.effectiveBeginDate,
        effectiveEndDate: obj.effectiveEndDate,
      };
    });
  }, [allData, refresh]);

  useEffect(() => {
    setDashboard();
  }, [refresh]);

  return (
    <Box>
      <HealthDataGrid rows={rows ?? []} columns={columns} loading={false} />
      {open && (
        <AddSocialBenifits
          open={open}
          setOpen={setOpen}
          getOptions={getOptions}
          dataObj={action === 'insert' ? {} : currentData}
          action={action}
          caseId={caseId}
          memberId={member.id}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        handleCancelDelete={() => {
          setDeleteData({});
          setOpenDeleteDialog(false);
        }}
        handleConfirmDelete={confrimDelete}
      />
    </Box>
  );
};

export default SocialSecurityBenifits;
