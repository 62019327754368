import React, { useEffect, useState } from 'react';
import styles from '../index.module.css';
import { Button, Grid, TextField, Typography } from '@mui/material';
import useCaseStatus from '../../../hooks/useCaseStatus';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Field from '../../../components/Field';

import usePageNav from '../../../hooks/usePageNav';
import {
  showErrorToast,
  showWarningToast,
} from '../../../utils/services/toast';
import { maxLength, number } from '../../../utils/normalizers';

export default function Reinitiate() {
  const { caseId, navigations } = usePageNav();
  const { getCaseStatus } = useCaseStatus();

  const validationSchema = Yup.object({
    caseId: Yup.string()
      .max(9, 'Please enter valid case id')
      .required('Please enter case id'),
  });

  const formik = useFormik({
    initialValues: {
      caseId: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const caseStatus = await getCaseStatus(values.caseId);
        if (
          (caseStatus && caseStatus.caseStatusCd === 'DE') ||
          caseStatus.caseStatusCd === 'CL'
        ) {
          if (formik.submitCount === 1) {
            navigations.toCaseReOpen(values.caseId);
          } else {
            navigations.toCaseReinitiate(values.caseId);
          }
        } else {
          navigations.toCaseHome(values.caseId);
          showWarningToast(
            'Cannot Redirect to Reinitiate This caseId is not Denied/Closed'
          );
        }
      } catch (error) {
        showErrorToast(error.message);
      }
    },
    // onSubmit: (value) => {
    //   if (value.submitCount === 1) {
    //     navigations.toCaseReOpen(value.caseId);
    //   } else {
    //     navigations.toCaseReinitiate(value.caseId);
    //   }
    // },
  });

  return (
    <Grid>
      <Typography variant="h5" marginBottom="3.5rem">
        Reinitiate \ Open
      </Typography>
      <Field
        normalizers={[number, maxLength(9)]}
        name="caseId"
        label="CASE ID"
        formik={formik}
      >
        <TextField placeholder="Enter Case Id" />
      </Field>
      <Button
        sx={{ marginTop: '5rem' }}
        fullWidth
        variant="contained"
        type="submit"
        onClick={() => {
          formik.setFieldValue('submitCount', 0);
          formik.handleSubmit();
        }}
      >
        Reinitiate
      </Button>
      <div className={styles.divider}>
        <span>or</span>
      </div>
      <Button
        fullWidth
        variant="outlined"
        type="submit"
        // onClick={formik.handleSubmit}
        onClick={() => {
          formik.setFieldValue('submitCount', 1);
          formik.handleSubmit();
        }}
      >
        Reopen
      </Button>
    </Grid>
  );
}
