import styled from '@emotion/styled';
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  iconButtonClasses,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as DymmyDonut } from '../../assets/images/dummy-donut.svg';
import styles from './index.module.css';
import useLookup from '../../hooks/useLookup';
import HttpClient from '../../utils/HttpClient';
import { filter, find, get } from 'lodash';
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as NotepadIcon } from '../../assets/icons/pending-actions.svg';
import { ReactComponent as ContractDelete } from '../../assets/icons/contract-delete.svg';
import { ReactComponent as StylusNote } from '../../assets/icons/stylus-note.svg';
import { ReactComponent as TaskAlt } from '../../assets/icons/task-alt.svg';
import Icon from '../../components/Icon';
import Status from '../../components/Status';
import usePageNav from '../../hooks/usePageNav';
import { getCaseHome } from '../../utils/services/apiRequests';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorToast } from '../../utils/services/toast';
import PageContainer from '../../components/PageContainer';
import usePage from '../../hooks/usePage';
import BenefitDetails from './BenefitDetails/index.js';
import Issuance from '../Issuance';
import CaseComments from '../CaseComments';
import AllCaseComments from '../AllCaseComments';
import VerificationNotices from './VerificationNotices/index.js';
import EmptyState from '../../components/EmptyState/index.jsx';
import NoRecord from '../../assets/icons/No-data-amico.svg';

const StyledPaper = styled(Paper)(() => ({
  padding: '8px 20px',
  borderRadius: '10px',
  height: '100%',
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className={styles.tabPanel}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default function CaseHome({ id }) {
  const { caseId, navigations } = usePageNav();
  const { navProps } = usePage(id);
  const [activeTab, setActiveTab] = useState(0);
  const [caseHomeData, setCaseHomeData] = useState({});
  const [fetchingCaseData, setFetchingCaseData] = useState(false);
  const [page, setPage] = useState({
    page: 0,
    pageSize: 3,
  });

  const [programPage, setProgramPage] = useState({
    page: 0,
    pageSize: 3,
  });
  const { lkpFlatten, getLkpMatch } = useLookup('CaseHome');
  const AlertTypeLkp = lkpFlatten.AlertType ?? {};
  const ProgramLkp = lkpFlatten.Program;
  const dispatch = useDispatch();
  const params = useParams();

  const { pendingWorkData, caseInfo, hohInfo, programDetails } = useMemo(() => {
    const hohDetails = find(
      get(caseHomeData, 'caseHomeCaseBenefitDetails.householdMembers', []),
      ({ headOfHouseholdYN }) => headOfHouseholdYN === 'Y'
    );
    const contactInfo = get(
      caseHomeData,
      'caseHomeCaseBenefitDetails.contactInformation',
      {}
    );
    const additionalInfo = get(
      caseHomeData,
      'caseHomeCaseBenefitDetails.additionalInformation',
      {}
    );

    var {
      addressLine1 = '',
      city = '',
      district = '',
      zipCode = '',
    } = get(contactInfo, 'homeAddress', {});
    const fullAddress = [addressLine1, city, district, zipCode].join(' ');
    const {
      firstName = '',
      lastName = '',
      middleName = '',
      suffix: srName = '',
      customerID = hohDetails?.individualId,
      facility = '',
    } = get(hohDetails, 'personName', {});

    return {
      pendingWorkData: get(
        caseHomeData,
        'workItemSearchResponse.workItemData',
        []
      ),
      caseInfo: {
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        middleName,
        srName,
        customerID,
        facility,
        interpreter: additionalInfo.hohPidNumber === 'Y' ? 'Yes' : 'No',
        penalty: '',
        hohPin: additionalInfo.hohPidNumber,
      },
      hohInfo: {
        name: `${firstName} ${lastName}`,
        relationship: '',
        pref: getLkpMatch('PrimaryLanguage', additionalInfo.preferredLanguage),
        residential: fullAddress,
        mailing: contactInfo.mailAddressSameAsHome,
        phoneNumber: contactInfo.phoneNumber?.phoneNumber,
        email: contactInfo.email,
      },
      programDetails: caseHomeData?.programDetails?.selectedPrograms ?? [],
    };
  }, [caseHomeData, getLkpMatch]);

  const fetchCaseHomeData = async () => {
    try {
      setFetchingCaseData(true);
      const res = await getCaseHome(caseId);
      if (res.status === 200) {
        setCaseHomeData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetchingCaseData(false);
    }
  };

  useEffect(() => {
    fetchCaseHomeData();
  }, [caseId]);

  const onTabChange = (e, i) => {
    setActiveTab(i);
  };

  const pendingWorkCols = useMemo(
    () => [
      {
        field: 'alertType',
        headerName: 'Type',
        flex: 1,
        valueGetter: ({ row }) => AlertTypeLkp[row?.alertType],
      },
      {
        field: 'alertSubType',
        headerName: 'Sub Type',
        flex: 1,
        valueGetter: ({ row }) =>
          getLkpMatch('AlertSubType', row?.alertSubType),
      },
      {
        field: 'assignedLastName',
        headerName: 'Assigned To',
        flex: 1,
        valueGetter: ({ row }) =>
          `${row.assignedFirstName ?? ''} ${row?.assignedLastName ?? ''}`,
      },
      {
        // field: 'alertType',
        headerName: 'Action',
        flex: 1,
        valueGetter: ({ row }) => AlertTypeLkp[row?.alertType],
      },
    ],
    [AlertTypeLkp, getLkpMatch]
  );

  const getStatus = (status) => {
    const iconNameByStatus = {
      Pending: 'PendingActions',
      Completed: 'StylusNote',
      Discontinued: 'ContractDelete',
    };

    const statuses = {
      Pending: 'pending',
      Completed: 'completed',
      Discontinued: 'info',
    };

    return (
      <Status
        size="small"
        icon={<Icon name={iconNameByStatus[status]} />}
        status={statuses[status]}
      >
        {status}
      </Status>
    );
  };

  const programInfoCols = useMemo(
    () => [
      {
        field: 'programName',
        headerName: 'Program Name',
        flex: 1,
        valueGetter: ({ row }) => getLkpMatch('Program', row.programName),
      },
      {
        field: 'applicationDate',
        headerName: 'App Date',
        flex: 1,
      },
      {
        field: 'programStatusDate',
        headerName: 'Program Status Date',
        flex: 1,
      },
      {
        field: 'programStatusCode',
        headerName: 'Status',
        flex: 1,
        renderCell: ({ row }) =>
          getStatus(getLkpMatch('ProgramStatus', row.programStatusCode)),
      },
      {
        field: 'expeditedSNAPIndicator',
        headerName: 'XSNAP Indic',
        flex: 1,
        valueGetter: ({ row }) =>
          getLkpMatch('YesNo', row.expeditedSNAPIndicator),
      },
      {
        field: 'expeditedSNAPDecisionDt',
        headerName: 'XSNAP Decis Date',
        flex: 1,
      },
    ],
    [getLkpMatch]
  );

  if (fetchingCaseData) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Skeleton height={250} width="100%" />
        </Grid>
        <Grid item xs={8}>
          <Skeleton component="div" height={250} width="100%" />
        </Grid>
      </Grid>
    );
  }

  const onNext = () => {
    navigations.toContactInformation();
  };

  const onPageChange = (pages) => {
    setPage(pages);
  };

  const onProgramPageChange = (pages) => {
    setProgramPage(pages);
  };

  return (
    <div>
      <PageContainer
        screenId={id}
        title={'Case Home'}
        // disableBack={true}
        // onNext={onNext}
        navProps={navProps}
      >
        <Tabs
          onChange={onTabChange}
          value={activeTab}
          sx={{
            '& .Mui-selected': {
              color: 'var(--Prim-1-N-L-Black, var(--Dark-Grey, #6C6C6C))',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              fontWeight: 700,
              backgroundColor: '#d9d9d9',
            },
          }}
          indicatorColor="white"
        >
          <Tab
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
            }}
            label="Case Info"
          />
          <Tab
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
            }}
            label="Benefit Details"
          />
          <Tab
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
            }}
            label="Issuance"
          />
          <Tab
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
            }}
            label="Case Comments"
          />
          <Tab
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
            }}
            label="Verification & Notices"
          />
        </Tabs>
        <TabPanel
          value={activeTab}
          index={0}
          // sx={{
          //   backgroundColor: '#d9d9d9',
          // }}
        >
          <Box backgroundColor="#d9d9d9" padding="1rem" borderRadius="5px">
            <Box paddingBottom="8px">
              <Grid container spacing={1}>
                <Grid item xs={5} md={11.5} lg={5}>
                  <StyledPaper>
                    <Typography marginBottom="20px" variant="h4grey">
                      Case Information
                    </Typography>
                    <Stack direction="row" columnGap={3}>
                      <Stack spacing={2} minWidth="150px" alignItems="center">
                        <Avatar sx={{ width: 100, height: 100 }} />
                        <Typography variant="body" fontWeight="600">
                          {caseInfo.name}
                        </Typography>
                      </Stack>
                      <TableContainer>
                        <Table
                          sx={{
                            td: {
                              padding: 0,
                              paddingBottom: '12px',
                              border: 0,
                            },
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="subtitle2"
                                >
                                  Customer ID
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="body2"
                                >
                                  {caseInfo.customerID}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="subtitle2"
                                >
                                  Facility
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="body2"
                                >
                                  {caseInfo.facility}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="subtitle2"
                                >
                                  Interpreter
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="body2"
                                >
                                  {caseInfo.interpreter}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="subtitle2"
                                >
                                  Penalty Period
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="body2"
                                >
                                  {caseInfo.penalty}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="subtitle2"
                                >
                                  HOH PIN No.
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{ color: 'var(--grey-400)' }}
                                  variant="body2"
                                >
                                  {caseInfo.hohPin}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </StyledPaper>
                </Grid>
                <Grid item xs={7} md={11.5} lg={7}>
                  <StyledPaper>
                    <Typography variant="h4grey">Pending Work Items</Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      marginTop="1rem"
                    >
                      <DymmyDonut />
                      <DataGrid
                        sx={{
                          '& .MuiDataGrid-virtualScroller': {
                            minHeight: '200px',
                          },
                          minHeight: '200px',
                          '& MuiDataGrid-footerContainer': {
                            height: 36,
                          },
                        }}
                        slots={{
                          noRowsOverlay: () => (
                            <EmptyState
                              header="Currently No Records Available"
                              headingLevel={6}
                              icon={
                                <img
                                  src={NoRecord}
                                  style={{
                                    height: '110px',
                                    width: '120px',
                                  }}
                                />
                              }
                            />
                          ),
                        }}
                        rowHeight={36}
                        columnHeaderHeight={40}
                        getRowId={(row) => row.caseId}
                        columns={pendingWorkCols}
                        rows={pendingWorkData}
                        pageSizeOptions={[3, 5]}
                        paginationModel={page}
                        onPaginationModelChange={onPageChange}
                      />
                    </Stack>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={5} md={11.5} lg={5}>
                  <StyledPaper>
                    <Typography variant="h4grey">Household Info</Typography>
                    <TableContainer>
                      <Table
                        sx={{
                          td: {
                            padding: 0,
                            paddingBottom: '12px',
                            border: 0,
                          },
                          marginTop: '20px',
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="subtitle2"
                              >
                                Name
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="body2"
                              >
                                {hohInfo.name}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="subtitle2"
                              >
                                Relationship
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="body2"
                              >
                                {hohInfo.relationship}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="subtitle2"
                              >
                                Pref. Language
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="body2"
                              >
                                {hohInfo.pref}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="subtitle2"
                              >
                                Residential Add
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="body2"
                              >
                                {hohInfo.residential}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="subtitle2"
                              >
                                Mailing Add
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="body2"
                              >
                                {hohInfo.mailing}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="subtitle2"
                              >
                                Phone No
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="body2"
                              >
                                {hohInfo.phoneNumber}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="subtitle2"
                              >
                                Email
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ color: 'var(--grey-400)' }}
                                variant="body2"
                              >
                                {hohInfo.email}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </StyledPaper>
                </Grid>
                <Grid item xs={7} md={11.5} lg={7}>
                  <StyledPaper>
                    <Typography variant="h4grey">Program Info</Typography>
                    <Box marginTop="1rem">
                      <DataGrid
                        sx={{
                          '& .MuiDataGrid-virtualScroller': {
                            minHeight: '200px',
                          },
                          minHeight: '200px',
                        }}
                        rowHeight={36}
                        columnHeaderHeight={40}
                        slots={{
                          noRowsOverlay: () => (
                            <EmptyState
                              header="Currently No Records Available"
                              headingLevel={6}
                              icon={
                                <img
                                  src={NoRecord}
                                  style={{
                                    height: '110px',
                                    width: '120px',
                                  }}
                                />
                              }
                            />
                          ),
                        }}
                        getRowId={(row) => row.programId}
                        columns={programInfoCols}
                        rows={programDetails}
                        pageSizeOptions={[5, 10]}
                        paginationModel={programPage}
                        onPaginationModelChange={onProgramPageChange}
                      />
                    </Box>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <BenefitDetails
            StyledPaper={StyledPaper}
            // caseHomeData={caseHomeData}
            getLkpMatch={getLkpMatch}
            caseId={caseId}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Issuance caseHome={true} />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <AllCaseComments />
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <VerificationNotices StyledPaper={StyledPaper} />
        </TabPanel>
      </PageContainer>
    </div>
  );
}
