import { Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import useLoader from '../../../hooks/useLoader';
import usePageNav from '../../../hooks/usePageNav';
import { showErrorToast } from '../../../utils/services/toast';
import { getIndAsst } from '../../../utils/services/IndividualAssitance/apiRequests';
import useLookup from '../../../hooks/useLookup';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/assets.svg';

export default function IndividualDetails({ selected }) {
  const { individualId } = selected;
  const [showLoader, hideLoader] = useLoader();
  const [assistData, setAssistData] = useState([]);
  const { caseId } = usePageNav();
  const { getLkpMatch } = useLookup('IndividualAssist');

  const columns = [
    {
      field: 'caseId',
      headerName: 'Case ID',
      flex: 1,
      renderCell: (params) => {
        const caseId = params?.row?.caseId;
        const href = `/dashboard/caseInquiry/caseHome/${caseId}`;
        return (
          <Typography
            variant="subtitle1"
            component="a"
            href={href}
            sx={{ fontWeight: 500, color: '#44A0E3', textDecoration: 'none' }}
          >
            {caseId}
          </Typography>
        );
      },
    },
    {
      field: 'programCd',
      headerName: 'Program Name',
      flex: 1,
      renderCell: (params) => getLkpMatch('Program', params?.row?.programCd),
    },
    {
      field: 'aidCd',
      headerName: 'Benefit Group',
      flex: 1,
      renderCell: (params) => getLkpMatch('AidcdShort', params?.row?.aidCd),
    },
    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      flex: 1,
    },
    {
      field: 'effectiveEndDate',
      headerName: 'End Date',
      flex: 1,
      renderCell: (params) => {
        const endDate =
          params?.row?.effectiveEndDate === '9999-12-31'
            ? 'Present'
            : params?.row?.effectiveEndDate;
        return endDate;
      },
    },
  ];

  useEffect(() => {
    if (individualId) {
      getIndividualAsst();
    }
  }, [individualId]);

  const getIndividualAsst = async () => {
    try {
      showLoader();
      const res = await getIndAsst(caseId);
      if (res.status === 200) {
        const assistanceData = res.data;
        const individualData = assistanceData.find(
          (data) => data.individualId === Number(individualId)
        );
        const individualAssistance =
          individualData?.assistanceForIndividuals || [];
        setAssistData(individualAssistance);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid mt={2}>
        <DataGrid
          rows={assistData}
          columns={columns}
          getRowId={(row) =>
            `${row.individualId}-${row.programCd}-${row.aidCd}`
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '30vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '30vh',
            },
            minHeight: '50vh',
          }}
          autoHeight
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
        />
      </Grid>
    </>
  );
}
