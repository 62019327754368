import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
// import {

// } from './apiPayload';
import { GET_CAPACITY, GET_CAPACITY_DETAILS } from './apiUrl';

export const getCapacityApi = () => HttpClient.get(GET_CAPACITY);

export const getCapacityList = (ldssId) => {
  console.log(ldssId);
  return HttpClient.get(format(GET_CAPACITY_DETAILS, ldssId));
};
