import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  POST_DELETE,
  POST_MANUALSEARCH,
  POST_MANUALTEMPLATE,
  POST_NOTICEINFO,
  POST_PDFDWNLD,
} from './apiUrls';
import {
  alcoholFormPayload,
  deletePayload,
  dhaFiaPayload,
  disabilityForm,
  editTemOpenPayload,
  familyInvestPayload,
  familyMedical,
  hardshipExempPayload,
  manualSearhPayload,
  manualTemplatePayload,
  medicalReportTemp,
  noticeInfoPayload,
  pdfPayload,
  preventionHealthPayload,
  substanceAbuse,
} from './apiPayload';

export const postManualSearch = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_MANUALSEARCH, caseId, individualId),
    manualSearhPayload(data)
  );
};

export const postNoticeInfo = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_NOTICEINFO, caseId, individualId),
    noticeInfoPayload(data)
  );
};

export const postManualTemplate = (data = {}) => {
  return HttpClient.post(
    format(POST_MANUALTEMPLATE),
    manualTemplatePayload(data)
  );
};

export const postMedicalTemplate = (data = {}) => {
  return HttpClient.post(format(POST_MANUALTEMPLATE), medicalReportTemp(data));
};

export const postFamilyInvestTemp = (data = {}) => {
  return HttpClient.post(
    format(POST_MANUALTEMPLATE),
    familyInvestPayload(data)
  );
};

export const postFamilyMedicalRep = (data = {}) => {
  return HttpClient.post(format(POST_MANUALTEMPLATE), familyMedical(data));
};

export const postDisabilityForm = (data = {}) => {
  return HttpClient.post(format(POST_MANUALTEMPLATE), disabilityForm(data));
};

export const postDhaFia = (data = {}) => {
  return HttpClient.post(format(POST_MANUALTEMPLATE), dhaFiaPayload(data));
};

export const postSubstanceAbuse = (data = {}) => {
  return HttpClient.post(format(POST_MANUALTEMPLATE), substanceAbuse(data));
};

export const postAlcoholDrugForm = (data = {}) => {
  return HttpClient.post(format(POST_MANUALTEMPLATE), alcoholFormPayload(data));
};

export const postPreventHelathForm = (data = {}) => {
  return HttpClient.post(
    format(POST_MANUALTEMPLATE),
    preventionHealthPayload(data)
  );
};

export const postHarship = (data = {}) => {
  return HttpClient.post(
    format(POST_MANUALTEMPLATE),
    hardshipExempPayload(data)
  );
};

export const postEditNoticeInfo = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_NOTICEINFO, caseId, individualId),
    editTemOpenPayload(data)
  );
};

export const postDeleteNotice = (data = {}) => {
  return HttpClient.post(format(POST_DELETE), data);
};

export const postPdfDwnld = (data = {}) => {
  return HttpClient.post(format(POST_PDFDWNLD, data), pdfPayload(data), {
    responseType: 'blob',
  });
};
