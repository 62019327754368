import React from 'react';
import Redetermin from './ReDeterminationContainer';
import PageHeader from '../../components/PageHeader';
function Redetermination() {
  return (
    <div>
      <PageHeader title="ReCertification" showNavigations={false} />
      <Redetermin />
    </div>
  );
}

export default Redetermination;
