import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { GET_PREGNANCY, POST_PREGNANCY, PUT_EDIT_PREGNANCY } from './apiUrls';
import { pregnancyPayload } from './apiPyloads';

export const getPregnancy = (individualId) => {
  return HttpClient.get(format(GET_PREGNANCY, individualId));
};

export const postPregnancy = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_PREGNANCY, caseId, individualId),
    pregnancyPayload(data)
  );
};

export const updatePregnancy = (data = {}, caseId, individualId) => {
  return HttpClient.put(
    format(PUT_EDIT_PREGNANCY, caseId, individualId),
    pregnancyPayload(data)
  );
};
