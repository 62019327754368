import {
  Box,
  Dialog,
  Stack,
  Tab,
  Tabs,
  Button,
  DialogContent,
  Typography,
  IconButton,
  DialogTitle,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import {
  getWaiver,
  postInstitution,
  postWaiver,
  postAdditionalInfo,
} from '../../../utils/services/waiverAndFacility/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import ActionButtons from '../../../components/ActionButtons';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import AddInstitution from '../AddInstitution';
import useLookup from '../../../hooks/useLookup';
import TabPanel from '../../../components/TabPanel';
import { CloseRounded, DeleteOutlineOutlined } from '@mui/icons-material';
import AddWaiver from '../AddWaiver';
import AddAdditionalInfo from '../AddInformation';
import useLoader from '../../../hooks/useLoader';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthToken } from '../../../store/selectors/authSelector';

export default function WaiverTable({ selected, caseId }) {
  const { getLkpMatch, getOptions, lkpRaw } = useLookup('Wavier');
  const [tabSelect, setTabSelect] = useState(1);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState('');
  const [institutionOpen, setInstitutionOpen] = useState(false);
  const [editInstitution, setEditInstitution] = useState(false);
  const [editWaiver, setEditWaiver] = useState(false);
  const [editAddition, setEditAddition] = useState(false);
  const [waiverOpen, setWaiverOpen] = useState(false);
  const [addtionOpen, setAddtionOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [instituteData, setInstituteData] = useState('');
  const [waiverData, setWaiverData] = useState('');
  const [AdditionData, setAdditionData] = useState('');
  const [rowsData, setRowsData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [docOpen, setDocOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(null);
  const { individualId: currentUserId } = selected;
  const [docmentFiles, setDocmentFiles] = useState([]);
  const token = useSelector(getAuthToken);

  const params = useParams();

  useEffect(() => {
    if (currentUserId) {
      setTabSelect(1);
      setSelectedColumns(InstitutionalColumn);
      waiverAPI(currentUserId);
    }
  }, [selected.individualId]);

  const waiverAPI = async (userId) => {
    try {
      showLoader();
      const res = await getWaiver(caseId, userId);
      if (res.status === 200) {
        setTableData(res.data);
        if (tabSelect === 1) {
          setRowsData(res.data?.institutions);
        }
        if (tabSelect === 2) {
          setRowsData(res.data?.waivers);
        }
        if (tabSelect === 3) {
          setRowsData(res.data?.institutionalAdditionalInfo);
        }
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const InstitutionalColumn = useMemo(
    () => [
      {
        field: 'institutionType',
        headerName: 'Institution Type',
        flex: 1,
        renderCell: (params) =>
          getLkpMatch('InstitutionType', params?.row?.institutionType),
      },
      { field: 'providerName', headerName: 'provider Name', flex: 1 },
      {
        field: 'levelOfCare',
        headerName: 'Level Of Care',
        flex: 1,
        renderCell: (params) =>
          getLkpMatch('LevelAuth', params?.row?.levelOfCare),
      },
      { field: 'entryDate', headerName: 'Entry Date', flex: 1 },
      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editPopup(params?.row)}
                onDelete={() => deletePopup(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [getLkpMatch]
  );

  const WaiverColumn = [
    { field: 'appDate', headerName: 'Application Date', flex: 1 },
    { field: 'effectiveBeginDate', headerName: 'Waiver Begin Date', flex: 1 },
    { field: 'waiverEndDate', headerName: 'Waiver End Date', flex: 1 },
    {
      field: 'waiverType',
      headerName: 'Waiver Type',
      flex: 1,
      renderCell: (params) =>
        getLkpMatch('HCBWaiverType', params?.row?.waiverType),
    },
    { field: 'mfp', headerName: 'MFP', flex: 1 },
    {
      field: 'atpStatusCd',
      headerName: 'ATP Status',
      flex: 1,
      renderCell: (params) => getLkpMatch('Waiver', params?.row?.atpStatusCd),
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editWaiverPopup(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];

  const AddInformation = [
    {
      field: 'guardianPersonYN',
      headerName: 'Guardian Person',
      flex: 1,
      renderCell: (params) =>
        getLkpMatch('YesNo', params?.row?.guardianPersonYN),
    },
    {
      field: 'guardianPropertyYN',
      headerName: 'Guardian Property',
      renderCell: (params) =>
        getLkpMatch('YesNo', params?.row?.guardianPropertyYN),
      flex: 1,
    },
    {
      field: 'guardianPersonFeeAmount',
      headerName: 'Guardian Person Fee Amount',
      flex: 1,
    },
    {
      field: 'guardianPropertyFeeAmount',
      headerName: 'Guardian Property Fee Amount ',
      flex: 1,
    },
    {
      field: 'maintenanceAmountYN',
      headerName: 'Is Customer Requesting Maintenance Allowance?',
      flex: 1,
      renderCell: (params) =>
        getLkpMatch('YesNo', params?.row?.maintenanceAmountYN),
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => editAdditional(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];

  const [currentTabColumn = [], currentTabData] = useMemo(() => {
    const { institutionalAdditionalInfo, institutions, waivers } = tableData;
    switch (tabSelect) {
      case 1:
        return [InstitutionalColumn, institutions];
      case 2:
        return [WaiverColumn, waivers];
      case 3:
        return [AddInformation, institutionalAdditionalInfo];
      default:
        return [[], []];
    }
  }, [tableData, InstitutionalColumn, AddInformation]);

  const handleChange = (event, newValue) => {
    setTabSelect(newValue);
  };

  const toggleClose = () => {
    setInstitutionOpen(false);
    setWaiverOpen(false);
    setAddtionOpen(false);
    setEditInstitution(false);
    setEditWaiver(false);
    setEditAddition(false);
  };

  // edit popups

  const editPopup = (data) => {
    setEditInstitution(true);
    setInstituteData(data);
  };
  const editWaiverPopup = (data) => {
    setEditWaiver(true);
    setWaiverData(data);
  };
  const editAdditional = (data) => {
    setEditAddition(true);
    setAdditionData(data);
  };

  // Delete records
  const deletePopup = (data) => {
    setOpenDelete(true);
    setDeleteData(data);
  };
  const deleteApi = () => {
    if (tabSelect === 1) {
      deleteInstitutionApi();
    }
    if (tabSelect === 2) {
      deleteWaiverApi();
    }
    if (tabSelect === 3) {
      deleteAdditionalInfoApi();
    }
  };
  const deleteInstitutionApi = async () => {
    const addPayload = {
      dirtyStatus: 'delete',
      institutions: [
        {
          authorizationDate: null,
          dirtyStatus: 'delete',
          entryDate: deleteData.entryDate,
          exitDate: null,
          instituteId: deleteData.instituteId,
          institutionType: deleteData.institutionType,
          levelOfCare: deleteData.levelOfCare,
          medicareCertificationCode: '',
          medicareDenialDate: null,
          outOfStateBenefit: deleteData.stateFacility,
          providerId: 0,
          providerName: deleteData.providerName,
          returnHomeIn6MonthsYN: deleteData.returnHome,
        },
      ],
      institutionalAdditionalInfo: [],
      waivers: [],
    };
    try {
      showLoader();
      const res = await postInstitution(caseId, currentUserId, addPayload);
      if (res.status === 200) {
        showSuccessToast('record Removed Sucessfully');
        waiverAPI(currentUserId);
        setOpenDelete(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const deleteWaiverApi = async () => {
    const addPayload = {
      dirtyStatus: 'update',
      institutions: [],
      institutionalAdditionalInfo: [],
      waivers: [
        {
          appDate: deleteData.appDate ?? '',
          atpSignDate: null,
          atpStatusCd: deleteData.atpStatusCd ?? '',
          dirtyStatus: 'delete',
          disabledInd: deleteData.disabledInd ?? '',
          effectiveBeginDate: deleteData.effectiveBeginDate ?? '',
          effectiveEndDate: null,
          mfp: deleteData.mfp ?? '',
          mfpEndDate: null,
          mfpStartDate: null,
          receivingMAGI: deleteData.receivingMAGI ?? '',
          recordId: deleteData.recordId ?? '',
          waiverDenialCd: '',
          waiverEndDate: null,
          waiverType: deleteData.waiverType ?? '',
          workerId: token?.name,
        },
      ],
    };
    try {
      showLoader();
      const res = await postWaiver(caseId, currentUserId, addPayload);
      if (res.status === 200) {
        showSuccessToast('record Removed Sucessfully');
        waiverAPI(currentUserId);
        setOpenDelete(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const deleteAdditionalInfoApi = async () => {
    const addPayload = {
      dirtyStatus: 'update',
      institutions: [],
      institutionalAdditionalInfo: [
        {
          dirtyStatus: 'delete',
          effectiveBeginDate: deleteData.effectiveBeginDate ?? '',
          effectiveEndDate: deleteData.effectiveEndDate ?? '',
          goodCauseClaimYN: deleteData.goodCauseClaimYN ?? '',
          guardianPersonYN: deleteData.guardianPersonYN ?? '',
          guardianPropertyFeeAmount: deleteData.guardianPropertyFeeAmount ?? '',
          guardianPropertyYN: deleteData.guardianPropertyYN ?? '',
          maintenanceAmountYN: deleteData.maintenanceAmountYN ?? '',
          recordId: deleteData.recordId ?? '',
          updatedBy: deleteData.updatedBy ?? '',
        },
      ],
      waivers: [],
    };
    try {
      showLoader();
      const res = await postAdditionalInfo(caseId, currentUserId, addPayload);
      if (res.status === 200) {
        showSuccessToast('record Removed Sucessfully');
        waiverAPI(currentUserId);
        setOpenDelete(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const toggleDelete = () => {
    setOpenDelete(false);
  };
  // Add new Data popups

  const AddNewInstitution = () => {
    setInstitutionOpen(true);
  };
  const AddNewWaiver = () => {
    setWaiverOpen(true);
  };
  const AddNewInfomation = () => {
    setAddtionOpen(true);
  };

  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={toggleDelete}
        component="form"
        maxWidth="lg"
      >
        <DialogContent>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <IconButton onClick={toggleDelete}>
              <CloseRounded />
            </IconButton>
          </Stack>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <DeleteOutlineOutlined
              sx={{ height: 100, width: 100, color: '#cf4242' }}
            />
            <Typography color="gray" variant="h3">
              Delete the institution record?
            </Typography>

            <Typography my="1rem" variant="subtitle1">
              Do you really want to delete ? You will not able to recover it.
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button color="error" variant="outlined" onClick={toggleDelete}>
                Discard
              </Button>
              <Button
                color="error"
                sx={{ mt: '1rem' }}
                variant="contained"
                onClick={deleteApi}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      {/* Institution */}
      <Dialog
        maxWidth="xl"
        sx={{ maxWidth: 'none' }}
        open={institutionOpen}
        onClose={toggleClose}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Institution</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <AddInstitution
          caseId={caseId}
          tableData={tableData}
          createNew
          waiverAPI={waiverAPI}
          rowsData={rowsData}
          currentUserId={currentUserId}
          getOptions={getOptions}
          toggleClose={toggleClose}
        />
      </Dialog>
      <Dialog
        maxWidth="xl"
        sx={{ maxWidth: 'none' }}
        open={editInstitution}
        onClose={toggleClose}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Institution</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <AddInstitution
          caseId={caseId}
          tableData={tableData}
          waiverAPI={waiverAPI}
          editMode
          instituteData={instituteData}
          currentUserId={currentUserId}
          getOptions={getOptions}
          toggleClose={toggleClose}
          rowsData={rowsData}
        />
      </Dialog>
      {/* Waiver */}
      <Dialog maxWidth={'lg'} open={waiverOpen} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Waiver</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <AddWaiver
          caseId={caseId}
          waiverAPI={waiverAPI}
          currentUserId={currentUserId}
          getOptions={getOptions}
          toggleClose={toggleClose}
          rowsData={rowsData}
          createNew
        />
      </Dialog>
      <Dialog maxWidth={'lg'} open={editWaiver} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Waiver</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <AddWaiver
          caseId={caseId}
          waiverAPI={waiverAPI}
          currentUserId={currentUserId}
          getOptions={getOptions}
          toggleClose={toggleClose}
          rowsData={rowsData}
          waiverData={waiverData}
        />
      </Dialog>
      {/* Additional Info */}
      <Dialog maxWidth={'md'} open={addtionOpen} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Additional Info</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <AddAdditionalInfo
          caseId={caseId}
          currentUserId={currentUserId}
          getOptions={getOptions}
          waiverAPI={waiverAPI}
          createNew
          toggleClose={toggleClose}
        />
      </Dialog>

      <Dialog maxWidth={'md'} open={editAddition} onClose={toggleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Edit Additional Info</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <AddAdditionalInfo
          caseId={caseId}
          currentUserId={currentUserId}
          AdditionData={AdditionData}
          getOptions={getOptions}
          waiverAPI={waiverAPI}
          toggleClose={toggleClose}
        />
      </Dialog>
      <Tabs
        value={tabSelect}
        onChange={handleChange}
        sx={{
          width: '100%',

          '& .MuiButtonBase-root': {
            flex: 1,
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 700,
          },
          '& .Mui-selected': {
            color: 'black !important',
            fontSize: 16,
            fontWeight: 700,
          },
        }}
      >
        <Tab value={1} label="Institutional History" />
        <Tab value={2} label="Waiver Details" />
        <Tab value={3} label="Additional Information" />
      </Tabs>
      <TabPanel value={tabSelect} index={1}>
        <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload Files
          </Button>
          <Button onClick={AddNewInstitution} startIcon={<AddIcon />}>
            Add Institution
          </Button>
        </Stack>
      </TabPanel>
      <TabPanel value={tabSelect} index={2}>
        <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload Files
          </Button>
          <Button onClick={AddNewWaiver} startIcon={<AddIcon />}>
            Add Waiver
          </Button>
        </Stack>
      </TabPanel>
      <TabPanel value={tabSelect} index={3}>
        <Stack direction={'row'} justifyContent={'space-between'} mt={2}>
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload Files
          </Button>
          <Button onClick={AddNewInfomation} startIcon={<AddIcon />}>
            Add Additional Info
          </Button>
        </Stack>
      </TabPanel>
      <Box sx={{ width: '99%' }} maxWidth={'1500px'}>
        <DataGrid
          getRowId={(row) => (row.recordId ? row.recordId : row.instituteId)}
          sx={{
            marginTop: 2,
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: 16,
              fontWeight: 700,
              padding: '0px 10px',
              textAlign: 'center',
              color: 'black',
            },
          }}
          slots={{
            noRowsOverlay: 'noData',
          }}
          hideFooter
          rows={currentTabData ?? []}
          columns={currentTabColumn ?? []}
        />
      </Box>
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}
