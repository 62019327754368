import React, { useEffect, useState } from 'react';
import ProgramTable from '../ProgramTables';
import {
  getAbardTableData,
  postTimeLimitRecord,
} from '../../../utils/services/ProgramTimeLimts/requests';
import SelectedProgramTimeLimit from '../SelectedProgramTimeLimit';
import useLoader from '../../../hooks/useLoader';

const AbawdProgram = ({ caseId, individualId }) => {
  const [showLoader, hideLoader] = useLoader();
  const [counter, setCounter] = useState(0);
  const [tableList, setTableList] = useState([]);
  const [dirtyStatus, setDirtyStatus] = useState('');
  const [editSelectedConsumer, setEditSelectedConsumer] = useState({});
  const [programEditindTmClkId, setProgramindTmClkId] = useState('');
  const [open, setOpen] = useState(false);
  const APPID = caseId;

  useEffect(() => {
    abardTable();
  }, [individualId]);

  async function abardTable() {
    showLoader();
    try {
      const getData = await getAbardTableData(APPID, individualId);
      setTableList(getData.data.timeClockList);
      setCounter(getData.data.counter);
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }

  const HandleEvents = (event, propId) => {
    const findSelectedRecord = tableList?.find(
      (obj) => obj.indTmClkId === propId
    );
    setEditSelectedConsumer(findSelectedRecord);
    setOpen(!open);
    setDirtyStatus(event);
    setProgramindTmClkId(propId);
  };

  const HandleSumbitCase = async (payload) => {
    showLoader();
    try {
      const res = await postTimeLimitRecord(
        {
          indTmClkId: programEditindTmClkId,
          ...payload,
          clkTypCd: 'AB',
        },
        APPID,
        individualId,
        dirtyStatus
      );
      if (res.status === 200) {
        setOpen(false);
        abardTable();
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <div>
      <ProgramTable
        tabelDatas={tableList}
        HandleEvents={HandleEvents}
        title="ABAWD"
      />
      <SelectedProgramTimeLimit
        open={open}
        setOpen={setOpen}
        programData={editSelectedConsumer}
        HandleSumbitCase={HandleSumbitCase}
        title="ABAWD"
        counter={counter}
        addExtention
      />
    </div>
  );
};

export default AbawdProgram;
