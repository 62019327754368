import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';
import { map, range, sortBy } from 'lodash';

const YearRangePickerComponent = ({
  minYear,
  maxYear,
  startYear,
  endYear,
  onSelect,
  disabled,
}) => {
  const initialDefaultRange = `${startYear}-${endYear}`;
  const [selectedRange, setSelectedRange] = useState(initialDefaultRange);
  const [yearRangeOptions, setYearRangeOptions] = useState([]);

  useEffect(() => {
    const ranges = map(range(minYear, maxYear), (year) => ({
      label: `${year}-${year + 1}`,
      startYear: year,
      endYear: year + 1,
    }));

    const sortedRanges = sortBy(ranges, (range) => -range.startYear);
    setYearRangeOptions(sortedRanges);
  }, [minYear, maxYear]);

  useEffect(() => {
    setSelectedRange(`${startYear}-${endYear}`);
  }, [startYear, endYear]);

  const handleYearRangeChange = (event) => {
    const { value } = event.target;
    setSelectedRange(value);
    const selectedYearRange = yearRangeOptions.find(
      (range) => range.label === value
    );
    if (selectedYearRange) {
      onSelect(selectedYearRange.startYear, selectedYearRange.endYear);
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="year-range-label"
        value={selectedRange}
        onChange={handleYearRangeChange}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
              overflowY: 'auto',
            },
          },
        }}
      >
        {yearRangeOptions.map((range, index) => (
          <MenuItem key={index} value={range.label}>
            {range.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default YearRangePickerComponent;
