import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { myDashboardApi } from '../../../../utils/services/apiRequests';
import { getAuthToken } from '../../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useCaseStatus from '../../../../hooks/useCaseStatus';
import styles from './index.module.css';
import { Grid, Tooltip, IconButton, Button } from '@mui/material';
import cx from 'classnames';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { map } from 'lodash';
import useMount from '../../../../hooks/useMount';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import usePageNav from '../../../../hooks/usePageNav';
import useLoader from '../../../../hooks/useLoader';
import { showErrorToast } from '../../../../utils/services/toast';
import IconLink from '../../../../components/IconLinks';
import { ReactComponent as WorkUpdate } from '../../../../assets/icons/QuickLinks/work_update.svg';
import EmptyState from '../../../../components/EmptyState';
import NoRecord from '../../../../assets/icons/No-data-amico.svg';

function MyDashboardTable({ getLkpMatch, newValue }) {
  const [fetching, setFetching] = useState(false);
  const [paperApps, setPaperApps] = useState([]);
  const { navigations, caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [onlineApps, setOnlineApps] = useState([]);
  const token = useSelector(getAuthToken);
  const { viewCase } = useCaseStatus();
  const [totalRecords, setTotalRecords] = useState();
  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchData = async ({ page, pageSize }) => {
    try {
      setFetching(true);
      showLoader();
      const paginationDataPaper = {
        resultsPerPage: pageSize,
        pageNumber: page,
      };
      const res = await myDashboardApi(paginationDataPaper, token?.sub, 'AP');
      if (res.status === 200) {
        const { resultsRegular, resultsPaper } = res.data;
        setPaperApps(resultsPaper);
        setOnlineApps(resultsRegular);
        setTotalRecords(res?.data?.paginationPaperData?.totalRecords);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  useMount(() => {
    fetchData(page);
  });

  const onOpenCase = (caseId, controlId) => {
    navigations.toCPImport(caseId, controlId);
  };

  const onPageChange = (pages) => {
    setPage(pages);
    fetchData(pages);
  };

  const remainingDays = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Grid
          className={cx(styles.default, {
            [styles.neg]: params?.row?.daysRemaining < 0,
            [styles.plusTen]: params?.row?.daysRemaining > 10,
            [styles.lessTen]:
              params?.row?.daysRemaining > 1 && params?.row?.daysRemaining < 10,
          })}
        >
          Due days is{' '}
          {params?.row?.daysRemaining < 0 ? 0 : params?.row?.daysRemaining}
        </Grid>
      </Grid>
    );
  };

  const action = (params) => {
    if (
      params.row?.caseId === null &&
      params.row?.webRefID != null &&
      (params.row?.caseStsCd === '' || params.row?.caseStsCd === 'PE')
    ) {
      return (
        <>
          <Grid sx={{ cursor: 'pointer' }}>
            <IconButton
              onClick={() => onOpenCase(params.row.caseId, params.row.webId)}
            >
              <Tooltip title="Manual Import" placement="right">
                <MenuBookIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => viewCase(params.id, params.row.caseStsCd)}>
            <Tooltip title="View Case" placement="right">
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      );
    }
  };

  const columns = [
    { field: 'caseId', headerName: 'Case ID', flex: 1, maxWidth: 150 },
    {
      field: 'caseStsCd',
      headerName: 'Status',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) =>
        getLkpMatch('CaseStatusCode', params?.row?.caseStsCd),
    },
    {
      field: 'pgmCd',
      headerName: 'Program',
      renderCell: (params) => {
        const programArray = params?.row?.programs?.split(',');
        return map(programArray, (p) => getLkpMatch('Program', p))?.join(',');
      },
      flex: 1,
      maxWidth: 150,
    },
    { field: 'hohName', headerName: 'HOH', flex: 1, maxWidth: 150 },
    {
      field: 'daysRemaining',
      headerName: 'Remaining',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => remainingDays(params),
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      renderCell: (params) => dayjs(params?.row?.dueDate).format('MM-DD-YYYY'),
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'distOffice',
      headerName: 'LDSS',
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => action(params),
    },
  ];

  return (
    <div>
      <DataGrid
        getRowId={(row) => (row.caseId ? row.caseId : row.webId || row.webID)}
        rows={newValue === 1 ? paperApps ?? [] : onlineApps ?? []}
        columns={columns}
        rowCount={totalRecords}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Note: Please click Get Work Item to Get Records"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        pageSizeOptions={[10, 20, 30]}
        paginationMode="server"
        paginationModel={page}
        onPaginationModelChange={onPageChange}
        autoHeight
      />
    </div>
  );
}

MyDashboardTable.propTypes = {};

export default MyDashboardTable;
