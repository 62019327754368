export const addAuthRepPayload = (data, dirtyStatus) => {
  const {
    category,
    firstName,
    lastName,
    middleName = null,
    dateOfBirth = null,
    relationship,
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
    phone = null,
    ext = null,
    type = null,
    email,
    effectiveBeginDate,
    effectiveEndDate,
    classification,
    targetIndividualId,
    houseHoldMember,
    authorizedRepresentativeDetails,
    addressCareOf,
    authRepId,
  } = data;
  const isCaseMember = category === 'case';
  const isNonCaseMember = category === 'noncase';
  const individualId = isCaseMember ? houseHoldMember : null;

  return [
    {
      authRepId: authRepId ? authRepId : '',
      dirtyStatus: dirtyStatus,
      householdMemberYN: isCaseMember ? 'Y' : 'N',
      targetIndividualId: targetIndividualId
        ? targetIndividualId
        : houseHoldMember,
      effectiveBeginDt: effectiveBeginDate,
      effectiveEndDt: effectiveEndDate ? effectiveEndDate : null,
      individualId: houseHoldMember,
      relationshipCd: isCaseMember || isNonCaseMember ? relationship : null,
      authRepType: classification,
      categoryType: 'UI',
      schoolJurisdiction: '',
      phoneNumber: {
        phoneNumber: phone,
        phoneNumberExt: ext,
        phoneNumberType: type,
      },
      personName: {
        dirtyStatus: '',
        firstName,
        lastName,
        middleName,
        type: '',
        suffix: '',
      },
      dateOfBirth,
      schoolName: '',
      title: '',
      address: {
        dirtyStatus: '',
        addressCareOf,
        addressLine1,
        addressLine2,
        city,
        state,
        county: null,
        district: '',
        zipcode,
        zip4code: '',
        addressVerifiedYN: '',
        institutionName: '',
        institutionYN: '',
        homeAddressYN: '',
        addressStartDate: null,
        addressEndDate: null,
        ownedByMemberYN: '',
        localDistrictOfficeYN: '',
      },
      providerName: '',
      providerId: '',
      email: email,
      authorizedRepresentativeDetails,
    },
  ];
};
