import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TabularView from '../../../components/TabularView';
import BenefitDatas from './BenefitData';
import useLookup from '../../../hooks/useLookup';
import { includes } from 'lodash';
import useLoader from '../../../hooks/useLoader';
import { getBenefitDetails } from '../../../utils/services/BenefitDetails/apiRequest';
import { showErrorToast } from '../../../utils/services/toast';
import { Circle } from '@mui/icons-material';
import cx from 'classnames';
import styles from './index.module.css';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

export default function BenefitDetails({ StyledPaper, caseId, getLkpMatch }) {
  const [showLoader, hideLoader] = useLoader();
  const [benefitData, setBenefitData] = useState(null);

  useEffect(() => {
    getBenefit({ caseId });
  }, [caseId]);

  const getBenefit = async () => {
    try {
      showLoader();
      const res = await getBenefitDetails(caseId);
      if (res.status === 200) {
        setBenefitData(res.data);
      }
    } catch (error) {
      // showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const eligiStatus = (params) => {
    const status = params?.eligibilityStatus ?? 'PE';
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <Grid container alignItems={'center'}>
          <Circle
            className={cx({
              [styles.pass]: status === 'PS',
              [styles.fail]: status === 'FL',
              [styles.pending]: status === 'PE',
            })}
          />
          {getLkpMatch('EligibilityStatusCode', status)}
        </Grid>
      </Grid>
    );
  };

  const columns = [
    {
      headerName: 'Household Members',
      renderCell(
        params,
        i,
        rootData,
        { setExpandRowId, isExpanded, expandRowId }
      ) {
        return (
          <Button onClick={() => setExpandRowId(params.id)}>
            {isExpanded ? (
              <IconButton>
                <ExpandLessIcon />
              </IconButton>
            ) : (
              <IconButton>
                <ExpandMoreIcon />
              </IconButton>
            )}{' '}
            {params.houseHoldMember}
          </Button>
        );
      },
    },
    {
      headerName: 'Eligibility Status',
      renderCell: (params) => eligiStatus(params),
    },
    {
      headerName: 'Program Name',
      getValue({ programName = {} }) {
        return `${getLkpMatch('Program', programName)}`;
      },
    },
    {
      headerName: 'Benefit Group',
      getValue({ benifitGroups = {} }) {
        return `${getLkpMatch('aidcd', benifitGroups)}`;
      },
    },
    {
      headerName: 'Redetermination Due Date',
      getValue({ redeterminationDueDate = {} }) {
        return `${redeterminationDueDate ? redeterminationDueDate : '-'}`;
      },
    },
    {
      headerName: 'Eligibility Begin Date',
      getValue({ eligibilityBeginDate = {} }) {
        return `${eligibilityBeginDate ? eligibilityBeginDate : '-'}`;
      },
    },
    {
      headerName: 'Eligibility End Date',
      getValue({ eligibilityEndDate = {} }) {
        return includes(eligibilityEndDate, '9999')
          ? '-'
          : eligibilityEndDate ?? '-';
      },
    },
    {
      headerName: 'Medicaid Card',
      getValue(rowData) {
        const programName = getLkpMatch('Program', rowData.programCd);
        return programName === 'FS' ? 'N/A' : 'N/A';
      },
    },
  ];

  const applicationDate = benefitData?.[0]?.appDate;

  return (
    <>
      <Box backgroundColor="#d9d9d9" padding="1rem" borderRadius="5px">
        <Grid>
          <StyledPaper>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" color="grey">
                Eligibility Summary
              </Typography>
              <Typography variant="h4grey">
                Application Date :{applicationDate}
              </Typography>
            </Stack>
            <TabularView
              data={benefitData ?? []}
              columns={columns}
              getId={({ houseHoldMember }) => {
                return houseHoldMember;
              }}
              slots={{
                noRowsOverlay: () => (
                  <EmptyState
                    header="Currently No Records Available"
                    icon={
                      <img
                        src={NoRecord}
                        style={{
                          height: '160px',
                          width: '250px',
                        }}
                      />
                    }
                  />
                ),
              }}
              expandables
              multiExpand
              renderDetails={(params) => {
                return (
                  <BenefitDatas
                    benefitData={[params]}
                    getLkpMatch={getLkpMatch}
                  />
                );
              }}
              renderHeader={(title) => (
                <Typography variant="h6" component="strong">
                  {title}
                </Typography>
              )}
              sx={{
                '& .MuiDataGrid-virtualScroller': {
                  minHeight: '50vh',
                },
                minHeight: '50vh',
                table: {
                  width: 'auto',
                },
                td: {
                  border: 0,
                },
                tr: {
                  padding: '10px',
                  ':nth-child(even)': {
                    // backgroundColor: 'red',
                  },
                },
              }}
            />
          </StyledPaper>
        </Grid>
      </Box>
    </>
  );
}
