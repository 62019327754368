import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { workExemPayload } from './apiPayload';
import { GET_WORKEXEMP, POST_WORKEXEM } from './apiUrls';

export const postWork = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_WORKEXEM, caseId, individualId),
    workExemPayload(data)
  );
};

export const getWork = (caseId, individualId) => {
  return HttpClient.get(format(GET_WORKEXEMP, caseId, individualId));
};
