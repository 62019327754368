import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import FormSection from '../../../../../../components/FormSection';
import Accordion from '../../../../../../components/Accordion';
import { map, noop } from 'lodash';
import { getFullName } from '../../../../../../utils/helpers';
import Icon from '../../../../../../components/Icon';
import CaseResults from './CaseResults';

function IndFinancialSummary({
  open,
  close,
  agIndvBudgetResult,
  financialInfo,
}) {
  const renderDetail = (detail) => {
    const {
      firstName,
      lastName,
      grossEarnedIncome = 0,
      grossUnearnedIncome = 0,
      earnedIncomeDisregard = 0,
      netEarnedIncome = 0,
      netUnearnedIncome = 0,
      totalFarmLoss = 0,
      totalGrossIncome = 0,
      totalNetIncome = 0,
      totalAssets = 0,
      dependentCareExpenses = 0,
      medicalExpenses = 0,
      shelterExpenses = 0,
      utilityExpenses = 0,
      childSpousalSupportExpenses = 0,
    } = detail;
    const memberName = getFullName(firstName, lastName);
    const earnings = [
      {
        type: 'a) Gross Earned Income',
        amount: grossEarnedIncome,
      },
      {
        type: 'b) Gross Unearned Income',
        amount: grossUnearnedIncome,
      },
      {
        type: 'c) Earned Income Disregard',
        amount: earnedIncomeDisregard,
      },
      {
        type: 'd) Net Earned Income (a - c)',
        amount: netEarnedIncome,
      },
      {
        type: 'e) Net Unearned Income (b)',
        amount: netUnearnedIncome,
      },
      {
        type: 'f) Total Farm Loss',
        amount: totalFarmLoss,
      },
      {
        type: 'g) Total Gross Income (a + b)',
        amount: totalGrossIncome,
      },
      {
        type: 'h) Total Net Income (d + e)',
        amount: totalNetIncome,
      },
      {
        type: 'i) Total Assets',
        amount: totalAssets,
      },
      {
        type: 'j) Dependent Care Expenses',
        amount: dependentCareExpenses,
      },
      {
        type: 'k) Medical Expenses',
        amount: medicalExpenses,
      },
      {
        type: 'l) Shelter Expenses',
        amount: shelterExpenses,
      },
      {
        type: 'm) Utility Expenses',
        amount: utilityExpenses,
      },
      {
        type: 'n) Child Spousal Support Expenses',
        amount: childSpousalSupportExpenses,
      },
    ];

    return (
      <Accordion title={memberName}>
        <Grid container spacing={1}>
          {map(earnings, ({ type, amount }) => (
            <>
              <Grid item xs={8}>
                {type}
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" alignItems="baseline">
                  <Icon width={10} height={12} name="DollarSign" />
                  <Box ml="2px" component="span">
                    {amount.toFixed(2)}
                  </Box>
                </Stack>
              </Grid>
            </>
          ))}
        </Grid>
      </Accordion>
    );
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="lg">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">Individual Results</Typography>
          <IconButton onClick={close}>
            <CloseRounded />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormSection label="Individual details">
          <Stack spacing={2}>{map(agIndvBudgetResult, renderDetail)}</Stack>
        </FormSection>
        <FormSection label="Case results">
          <CaseResults financialInfo={financialInfo} />
        </FormSection>
      </DialogContent>
      <Stack p="1rem" direction="row">
        <Button onClick={close} variant="contained">
          Discard
        </Button>
      </Stack>
    </Dialog>
  );
}

IndFinancialSummary.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  agIndvBudgetResult: PropTypes.shape({}),
};

IndFinancialSummary.defaultProps = {
  open: false,
  cloes: noop,
  agIndvBudgetResult: {},
};

export default IndFinancialSummary;
