import { createSlice } from '@reduxjs/toolkit';

export const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState: {
    getEligibility: [],
  },
  reducers: {
    setEligibility(state, action) {
      state.getEligibility = action.payload;
    },
  },
});

export const { setEligibility } = eligibilitySlice.actions;
export default eligibilitySlice.reducer;
