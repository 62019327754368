import {
  Checkbox,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import FormSection from '../../../../components/FormSection';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import InputMask from 'react-input-mask';

export default function MaritalInfo({ getOptions, formik }) {
  const { statusOpts, stateOpts, countryOpts, hairOpts, colorOpts } = useMemo(
    () => {
      return {
        statusOpts: getOptions('MartialStatus'),
        stateOpts: getOptions('State'),
        countryOpts: getOptions('CountryOfOrigin'),
        hairOpts: getOptions('HairColor'),
        colorOpts: getOptions('EyeColor'),
      };
    }
  );

  return (
    <>
      <FormSection label="Marital Info">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field name="maritalStatus" label="Status" formik={formik}>
              <Dropdown options={statusOpts} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="date" label="Date" formik={formik} isDate>
              <DatePicker disableFuture />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="marriageCity"
              label="Marriage City"
              formik={formik}
              normalizers={[maxLength(25), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="maritalState" label="Marriage State" formik={formik}>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="maritalCountry" label="Country" formik={formik}>
              <Dropdown options={countryOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Physical Attributes" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field name="height" label="Height (inches)" formik={formik}>
              <InputMask mask="9'-99\" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="0'0"
                    value={formik.values.height}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="hairColor" label="Hair Color" formik={formik}>
              <Dropdown options={hairOpts} />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="weight"
              label="Weight (lbs)"
              formik={formik}
              normalizers={[maxLength(10), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="eyeColor" label="Eye Color" formik={formik}>
              <Dropdown options={colorOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Incarceration" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <RadioGroup
              name="incarceration"
              row
              {...formik.getFieldProps('incarceration')}
              sx={{ marginLeft: 2 }}
              value={formik.values.incarceration}
            >
              <FormControlLabel
                value={'Y'}
                control={<Radio />}
                label="Currently"
              />
              <FormControlLabel
                sx={{
                  marginLeft: '5rem',
                }}
                value={'N'}
                control={<Radio />}
                label="Previously"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="institution"
              label="Institution"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="incarcerationCity"
              label="City"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="incarcerationState" label="State" formik={formik}>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="incarcerationCountry" label="Country" formik={formik}>
              <Dropdown options={countryOpts} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="unionLocal"
              label="Union/Local"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4} mt="1rem">
            <FormControlLabel
              name="healthInsurance"
              control={
                <Checkbox
                  {...formik.getFieldProps('healthInsurance')}
                  checked={formik.values.healthInsurance === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      'healthInsurance',
                      checked ? 'Y' : 'N'
                    );
                  }}
                  value={formik.values.healthInsurance}
                />
              }
              label={<Typography variant="h5">Health Insurance</Typography>}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
}
