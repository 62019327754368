import React, { useEffect } from 'react';
import {
  Button,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Divider,
  Stack,
  Box,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import {
  validateUnearnIncomeDetails,
  validateUnearnIncomeAmountDetails,
} from '../validationUtils';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
}));

const UnearnedIncomeDetails = ({
  lkpRaw,
  incomeAmount,
  incomeIndex,
  formik,
  isAdd,
}) => {
  const frequencyLoop = {
    AC: 2,
    AN: 1,
    BM: 1,
    BW: 3,
    MO: 1,
    OT: 1,
    QU: 1,
    SA: 1,
    WE: 5,
  };

  const classes = useStyles();

  const handleRemoveIncomeAmount = (incomeIndex) => {
    const updatedRemoveIncomeAmount = [...formik.values.unearnedIncomeAmount];
    updatedRemoveIncomeAmount.splice(incomeIndex, 1);
    formik.setFieldValue('unearnedIncomeAmount', updatedRemoveIncomeAmount);
  };

  const handleAddExpenses = (incomeIndex) => {
    const updatedAddExpenses = [...formik.values.unearnedIncomeAmount];
    if (!updatedAddExpenses[incomeIndex].expenses) {
      updatedAddExpenses[incomeIndex].expenses = [];
    }
    updatedAddExpenses[incomeIndex].expenses.push({
      type: '',
      amount: '',
      hours: '',
      verificationSourceCode: '',
    });

    formik.setFieldValue('unearnedIncomeAmount', updatedAddExpenses);
  };

  const handleRemoveExpenses = (incomeIndex, expenseIndex) => {
    const updatedRemoveExpenses = [...formik.values.unearnedIncomeAmount];
    updatedRemoveExpenses[incomeIndex].expenses.splice(expenseIndex, 1);
    formik.setFieldValue('unearnedIncomeAmount', updatedRemoveExpenses);
  };

  const handleLateReportChange = (event, index) => {
    const { name, value, checked } = event.target;
    formik.setFieldValue(
      `unearnedIncomeAmount[${incomeIndex}].${name}`,
      checked ? 'Y' : 'N'
    );
  };

  const handleEarnIncomeChange = (event, incomeIndex) => {
    const { name, value } = event.target;

    const loopCount = frequencyLoop[value];
    const fields = Array.from({ length: loopCount }, (_, index) => ({
      type: '',
      amount: '',
      hours: '',
      verificationSourceCode: '',
    }));
    formik.setFieldValue(`unearnedIncomeAmount[${incomeIndex}].${name}`, value);
    formik.setFieldValue(
      `unearnedIncomeAmount[${incomeIndex}].amountModel`,
      fields
    );
  };

  function formatCurrency(value) {
    let converToCurrency = value.toString().replace(/\D/g, '');
    converToCurrency = converToCurrency.replace(/(\d)(\d{2})$/, '$1.$2');
    converToCurrency = converToCurrency.replace(/(?=(\d{3})+(\D))\B/g, ',');
    return converToCurrency;
  }

  const handlePayAmount = (event, index) => {
    const { name, value } = event.target;
    if (name === 'amount') {
      let usFormatCurrency = formatCurrency(value);
      formik.setFieldValue(
        `unearnedIncomeAmount.[${incomeIndex}].amountModel[${index}].${name}`,
        usFormatCurrency
      );
    } else {
      formik.setFieldValue(
        `unearnedIncomeAmount.[${incomeIndex}].amountModel[${index}].${name}`,
        value
      );
    }
  };

  const handleExpenseAmount = (event, index) => {
    const { name, value } = event.target;
    if (name === 'amount') {
      let usFormatCurrency = formatCurrency(value);
      formik.setFieldValue(
        `unearnedIncomeAmount.[${incomeIndex}].expenses[${index}].${name}`,
        usFormatCurrency
      );
    } else {
      formik.setFieldValue(
        `unearnedIncomeAmount.[${incomeIndex}].expenses[${index}].${name}`,
        value
      );
    }
  };

  const handleAmountDatePicker = (name, date, incomeIndex) => {
    if (name === 'useMonth') {
      formik.setFieldValue(
        `unearnedIncomeAmount[${incomeIndex}].${name}`,
        dayjs(date).format('YYYYMM')
      );
    } else if (name === 'effectiveEndDate') {
      if (date === null) {
        formik.setFieldValue(
          `unearnedIncomeAmount[${incomeIndex}].${name}`,
          null
        );
      }
    } else {
      formik.setFieldValue(
        `unearnedIncomeAmount[${incomeIndex}].${name}`,
        dayjs(date).format('YYYY-MM-DD')
      );
    }
  };

  const chechExpenseFieldFilled = () => {
    const { unearnedIncomeAmount } = formik.values;
    for (let i = 0; i < unearnedIncomeAmount.length; i++) {
      const income = unearnedIncomeAmount[i];
      if (Array.isArray(income.expenses)) {
        let isEmptyAmountModel = income.expenses.some(
          (item) =>
            item.amount === '' ||
            item.verificationSourceCode === '' ||
            item.type === ''
        );
        if (isEmptyAmountModel) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      <Box mx={2}>
        <Stack direction="row" pt={1} justifyContent="space-between">
          <Typography variant="h6">
            Unearned Income {incomeIndex + 1}
          </Typography>
          {formik?.values?.unearnedIncomeAmount?.length > 1 && (
            <div
              onClick={() => handleRemoveIncomeAmount(incomeIndex)}
              style={{ cursor: 'pointer' }}
            >
              <DeleteIcon sx={{ color: 'red' }} />
            </div>
          )}
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mt={1} mb={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateUnearnIncomeDetails(
                      formik,
                      incomeIndex,
                      'effectiveBeginDate'
                    ),
                  })}
                  label="Amount Start Date *"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  slotProps={{ textField: { size: 'small' } }}
                  name="effectiveBeginDate"
                  minDate={
                    formik.values.pymtStrtDt && dayjs(formik.values.pymtStrtDt)
                  }
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(date) =>
                    handleAmountDatePicker(
                      'effectiveBeginDate',
                      date,
                      incomeIndex
                    )
                  }
                  value={
                    incomeAmount.effectiveBeginDate
                      ? dayjs(incomeAmount.effectiveBeginDate)
                      : null
                  }
                />
              </DemoContainer>
              {validateUnearnIncomeDetails(
                formik,
                incomeIndex,
                'effectiveBeginDate'
              ) && (
                <FormHelperText error sx={{ ml: '10px' }}>
                  Required
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box my={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateUnearnIncomeDetails(
                      formik,
                      incomeIndex,
                      'effectiveEndDate'
                    ),
                  })}
                  label="Amount End Date"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  slotProps={{ textField: { size: 'small' } }}
                  name="effectiveEndDate"
                  minDate={
                    formik.values.pymtStrtDt && dayjs(formik.values.pymtStrtDt)
                  }
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    dayjs(incomeAmount.effectiveEndDate).year() === 9999
                      ? null
                      : incomeAmount.effectiveEndDate
                      ? dayjs(incomeAmount.effectiveEndDate)
                      : null
                  }
                  onChange={(date) =>
                    handleAmountDatePicker(
                      'effectiveEndDate',
                      date,
                      incomeIndex
                    )
                  }
                />
              </DemoContainer>
              {validateUnearnIncomeDetails(
                formik,
                incomeIndex,
                'effectiveEndDate'
              ) && (
                <FormHelperText error sx={{ ml: '10px' }}>
                  {validateUnearnIncomeDetails(
                    formik,
                    incomeIndex,
                    'effectiveEndDate'
                  )}
                </FormHelperText>
              )}
            </Box>
          </Grid>
        </Grid>
        {incomeAmount.lateRptInd === 'Y' && (
          <Box>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="body1">Report Information</Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box mt={1} mb={1}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      className={
                        isAdd
                          ? cx({
                              [classes.customTextField]:
                                validateUnearnIncomeDetails(
                                  formik,
                                  incomeIndex,
                                  'useMonth'
                                ),
                            })
                          : formik.errors &&
                            formik.errors.unearnedIncomeAmount?.[incomeIndex]
                              ?.useMonth &&
                            classes.customTextField
                      }
                      label="Use Month *"
                      sx={{ width: '100%' }}
                      margin="normal"
                      views={['year', 'month']}
                      openTo="month"
                      format="MM/YYYY"
                      slotProps={{ textField: { size: 'small' } }}
                      name="useMonth"
                      value={
                        incomeAmount.useMonth
                          ? dayjs(
                              incomeAmount.useMonth.toString(),
                              'YYYYMM'
                            ).endOf('month')
                          : null
                      }
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleAmountDatePicker('useMonth', date, incomeIndex)
                      }
                    />
                  </DemoContainer>
                  {isAdd
                    ? validateUnearnIncomeDetails(
                        formik,
                        incomeIndex,
                        'useMonth'
                      ) && (
                        <FormHelperText error sx={{ ml: '10px' }}>
                          Required
                        </FormHelperText>
                      )
                    : formik.errors &&
                      formik.errors.unearnedIncomeAmount?.[incomeIndex]
                        ?.useMonth && (
                        <FormHelperText error sx={{ ml: '10px' }}>
                          Required
                        </FormHelperText>
                      )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1} mb={1}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label="Report Date"
                      sx={{ width: '100%' }}
                      margin="normal"
                      format="YYYY-MM-DD"
                      slotProps={{ textField: { size: 'small' } }}
                      value={
                        incomeAmount.rptDt ? dayjs(incomeAmount.rptDt) : null
                      }
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(date) =>
                        handleAmountDatePicker('rptDt', date, incomeIndex)
                      }
                    />
                  </DemoContainer>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]:
                  validateUnearnIncomeDetails(
                    formik,
                    incomeIndex,
                    'frequency'
                  ) && incomeAmount.frequency === '',
              })}
            >
              <InputLabel id="frequency">Frequency *</InputLabel>
              <Select
                labelId="frequency"
                id="select-frequency"
                size="small"
                label="Frequency *"
                name="frequency"
                onChange={(event) => handleEarnIncomeChange(event, incomeIndex)}
                value={incomeAmount.frequency}
              >
                {lkpRaw.Frequency.map((item, index) => (
                  <MenuItem key={index} value={item.lkpDesc}>
                    {item?.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateUnearnIncomeDetails(formik, incomeIndex, 'frequency') &&
                incomeAmount.frequency === '' && (
                  <FormHelperText error sx={{ ml: '10px' }}>
                    Required
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box mt={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleLateReportChange(e, incomeIndex)}
                    name="lateRptInd"
                    checked={incomeAmount.lateRptInd === 'Y'}
                  />
                }
                label="Late Report"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {incomeAmount?.amountModel.map((data, index) => {
        let amountModelLength = incomeAmount?.amountModel.length;
        return (
          <Box mx={2}>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="body1">
                {amountModelLength > 1 ? `Pay ${index + 1}` : 'Pay'}
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={
                    incomeAmount.frequency === 'BW' ||
                    incomeAmount.frequency === 'WE'
                      ? index !== incomeAmount?.amountModel.length - 1
                        ? 'Amount *'
                        : 'Amount'
                      : 'Amount *'
                  }
                  fullWidth
                  value={data.amount}
                  name="amount"
                  margin="normal"
                  InputProps={{
                    onInput: (event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]/g,
                        ''
                      );
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 11 }}
                  onChange={(event) => handlePayAmount(event, index)}
                  size="small"
                  error={validateUnearnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'amountModel',
                    'amount'
                  )}
                  helperText={
                    validateUnearnIncomeAmountDetails(
                      formik,
                      incomeIndex,
                      index,
                      'amountModel',
                      'amount'
                    )
                      ? 'Required'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  className={cx({
                    [classes.customTextField]:
                      validateUnearnIncomeAmountDetails(
                        formik,
                        incomeIndex,
                        index,
                        'amountModel',
                        'verificationSourceCode'
                      ),
                  })}
                >
                  <InputLabel id="verification">
                    Select Verification *
                  </InputLabel>
                  <Select
                    labelId="verification"
                    id="select-verification"
                    name="verificationSourceCode"
                    value={data.verificationSourceCode}
                    size="small"
                    label="Select Verification *"
                    onChange={(event) => handlePayAmount(event, index)}
                  >
                    {lkpRaw?.CLUIAMTVERCD.map((item) => (
                      <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                        {item?.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                  {validateUnearnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'amountModel',
                    'verificationSourceCode'
                  ) && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        );
      })}

      <Stack direction="row" justifyContent="space-between" mx={2} my={2}>
        <Typography variant="h6">Unearned Income Expenses</Typography>
        <Button
          variant="text"
          size="small"
          p={0.5}
          color="primary"
          onClick={() => handleAddExpenses(incomeIndex)}
          sx={{ cursor: 'pointer' }}
          disabled={!chechExpenseFieldFilled()}
        >
          + Add Expense
        </Button>
      </Stack>
      {incomeAmount?.expenses &&
        incomeAmount?.expenses.map((data, index) => (
          <Box mx={2} key={index}>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="body1">Expenses {index + 1}</Typography>
              <div
                onClick={() => handleRemoveExpenses(incomeIndex, index)}
                style={{ cursor: 'pointer' }}
              >
                <DeleteIcon sx={{ color: 'red' }} />
              </div>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="select-type">Select Type</InputLabel>
                  <Select
                    labelId="select-type"
                    id="select-type"
                    label="Select Type"
                    name="type"
                    size="small"
                    value={data.type}
                    onChange={(event) => handleExpenseAmount(event, index)}
                  >
                    {lkpRaw.UnearnedIncomeExpType.map((item, index) => (
                      <MenuItem key={index} value={item.lkpDesc}>
                        {item?.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Amount"
                  fullWidth
                  margin="normal"
                  name="amount"
                  value={data.amount}
                  InputProps={{
                    onInput: (event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]/g,
                        ''
                      );
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 11 }}
                  required
                  onChange={(event) => handleExpenseAmount(event, index)}
                  error={validateUnearnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'expenses',
                    'amount'
                  )}
                  helperText={
                    validateUnearnIncomeAmountDetails(
                      formik,
                      incomeIndex,
                      index,
                      'expenses',
                      'amount'
                    )
                      ? 'Required'
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  className={cx({
                    [classes.customTextField]:
                      validateUnearnIncomeAmountDetails(
                        formik,
                        incomeIndex,
                        index,
                        'expenses',
                        'verificationSourceCode'
                      ),
                  })}
                >
                  <InputLabel id="verification">Select Verification</InputLabel>
                  <Select
                    labelId="verification"
                    id="select-verification"
                    name="verificationSourceCode"
                    value={data.verificationSourceCode}
                    label="Select Verification *"
                    size="small"
                    onChange={(event) => handleExpenseAmount(event, index)}
                  >
                    {lkpRaw.CLUIAMTVERCD.map((item) => (
                      <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                        {item?.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>{' '}
                  {validateUnearnIncomeAmountDetails(
                    formik,
                    incomeIndex,
                    index,
                    'expenses',
                    'verificationSourceCode'
                  ) && (
                    <FormHelperText error sx={{ ml: '10px' }}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        ))}
    </>
  );
};

export default UnearnedIncomeDetails;
