import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import {
  postDeleteNotice,
  postEditNoticeInfo,
  postManualSearch,
  postNoticeInfo,
  postPdfDwnld,
} from '../../utils/services/ManualNotices/apiRequest';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import TabularView from '../../components/TabularView';
import SearchResult from './SearchResult';
import useLookup from '../../hooks/useLookup';
import usePageNav from '../../hooks/usePageNav';
import useLoader from '../../hooks/useLoader';
import { getFullName } from '../../utils/helpers';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolAttendance from './TemplateComponents/SchoolAttendance';
import MedicalReport from './TemplateComponents/MedicalReport';
import FamilyInvestment from './TemplateComponents/FamilyInvestment';
import DhsFia from './TemplateComponents/DhsFia340';
import FamilyMedicalRepo from './TemplateComponents/FamilyMedical';
import DisabilityForm from './TemplateComponents/DisabilityForm';
import {
  deletePayload,
  editTemOpenPayload,
  noticeInfoPayload,
  pdfPayload,
} from '../../utils/services/ManualNotices/apiPayload';
import SubstanceAbuse from './TemplateComponents/SubstanceAbuse';
import AlcoholDrugForm from './TemplateComponents/AlcoholDrugForm';
import PreventionHealth from './TemplateComponents/PreventionHealth';
import HardshipExemption from './TemplateComponents/HardshipExemption';
import DeleteConfiramation from './DeleteConfirmation';
import EmptyState from '../../components/EmptyState';
import NoRecord from '../../assets/icons/letter.svg';

export default function ManualNotices() {
  const { caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [historySearch, setHistorySearch] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const [lookupLongVal, setLookupLongVal] = useState('');
  const { houseMembers } = useSelector(selectHouseMembers);
  const [attendanceOpen, setAttendanceOpen] = useState(false);
  const [medicalOpen, setMedicalOpen] = useState(false);
  const [familyInvestOpen, setFamilyInvestOpen] = useState(false);
  const [dhsFiaOpen, setDhsFiaOpen] = useState(false);
  const [familyMedRepo, setFamilyMedRepo] = useState(false);
  const [disabiltyForm, setDisabilityForm] = useState(false);
  const [screeningOpen, setScreeningOpen] = useState(false);
  const [alcoholFormOpen, setAlcoholFormOpen] = useState(false);
  const [preventionOpen, setPreventionOpen] = useState(false);
  const [hardshipOpen, setHardshipOpen] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [editData, setEditData] = useState([]);
  const [recdId, setRecdId] = useState(null);
  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [programName, setProgramName] = useState('');

  const { getOptions, getLkpMatch } = useLookup('ManualNotice');
  const memberOpts = useMemo(() => {
    return map(
      houseMembers,
      ({ personName, individualId }) => {
        const fullName = getFullName(
          personName?.firstName,
          personName?.lastName
        );
        return {
          label: fullName,
          value: individualId,
        };
      },
      [houseMembers]
    );
  });

  const filterOptions = (options, valuesToKeep) => {
    return options.filter((option) => valuesToKeep.includes(option.value));
  };
  const agencyOptions = filterOptions(getOptions('Department'), ['DS', 'MH']);
  const agencyOpts = useMemo(() => agencyOptions, [getOptions]);

  const validationSchema = Yup.object({
    individualId: Yup.string().required('Please select the Individual'),
  });

  const formik = useFormik({
    initialValues: {
      caseId: caseId,
      agencyName: '',
      individualId: '',
      emptySearch: '',
      filledSearch: '',
    },
    validationSchema,
    onSubmit: (values) => {
      postSearchNotice(values);
    },
  });

  const filterColumns = [
    {
      headerName: 'Letter/Forms',
      getValue({ lookupLongVal = {} }) {
        return `${getLkpMatch('ManualNotice', lookupLongVal)}`;
      },
    },
    {
      headerName: 'Program',
      getValue({ pgmCd = {} }) {
        return `${getLkpMatch('Program', pgmCd)}`;
      },
    },
    {
      headerName: 'Category',
      getValue({ lkpCatgryTyp = {} }) {
        return `${getLkpMatch('ManualNotice', lkpCatgryTyp)}`;
      },
    },
    {
      headerName: 'Action',
      renderCell: (params) => {
        return (
          <Button variant="contained" onClick={() => handleNoticeOpen(params)}>
            Add
          </Button>
        );
      },
    },
  ];

  const handleClear = () => {
    formik.setValues({
      ...formik.values,
      agencyName: '',
      individualId: '',
      emptySearch: '',
      filledSearch: '',
    });
    setFilteredData([]);
    setHistorySearch([]);
  };

  const postSearchNotice = async (data) => {
    try {
      showLoader();
      let payload = {
        data,
        ...page,
      };
      const res = await postManualSearch(payload, caseId);
      if (res.status === 200) {
        setData(res.data?.manualNoticeSearch);
        setFilteredData(res.data?.manualNoticeSearch);
        setHistoryData(res.data?.historyManualNoticeSearch);
        setHistorySearch(res.data?.historyManualNoticeSearch);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const noticeInfo = async () => {
    try {
      showLoader();
      const payload = noticeInfoPayload(formik.values);
      const res = await postNoticeInfo(payload, caseId);
      if (res.status === 200) {
        setNoticeData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const editNoticeInfo = async (templateId) => {
    try {
      showLoader();
      let payloadEdit = editTemOpenPayload(formik.values);
      payloadEdit = { ...payloadEdit, templateId };
      const res = await postEditNoticeInfo(payloadEdit, caseId);
      if (res.status === 200) {
        setEditData(res.data);
        setRecdId(res.data?.recordId);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleNoticeOpen = async (value) => {
    const lookupLongVal = value?.lookupLongVal;
    const pgmCd = value?.pgmCd;
    setLookupLongVal(lookupLongVal);
    setProgramName(pgmCd);
    await noticeInfo();
    if (lookupLongVal === 'DHS FIA 604A SCHOOL ATTENDANCE VERIFICATION FORM') {
      setAttendanceOpen(true);
    } else if (
      lookupLongVal === 'DHS FIA 500C MEDICAL REPORT VERIFICATION FORM'
    ) {
      setMedicalOpen(true);
    } else if (
      lookupLongVal === 'DHS FIA 500B VERIFICATION OF ACTIVITY PARTICIPATION'
    ) {
      setFamilyInvestOpen(true);
    } else if (lookupLongVal === 'DHS FIA 340') {
      setDhsFiaOpen(true);
    } else if (lookupLongVal === 'DHS FIA 500A VERIFICATION OF DISABILITY') {
      setFamilyMedRepo(true);
    } else if (
      lookupLongVal ===
      'DHS FIA 4350 DISABILITY CERTIFICATION VERIFICATION FORM'
    ) {
      setDisabilityForm(true);
    } else if (lookupLongVal === 'DHS FIA 1177 Substance Abuse Screening') {
      setScreeningOpen(true);
    } else if (lookupLongVal === 'DHS FIA 1176 Consent to Release') {
      setAlcoholFormOpen(true);
    } else if (
      lookupLongVal === 'DHS FIA 1131 PRIMARY PREVENTIONHEALTH CARE FORM'
    ) {
      setPreventionOpen(true);
    } else if (lookupLongVal === 'MDH Form 800 Hardship Exemption') {
      setHardshipOpen(true);
    }
  };

  const handleNoticeEditOpen = async (value) => {
    const lookupLongVal = value?.lookupLongVal;
    const templateId = value?.mnlTmpltDataId;
    setLookupLongVal(lookupLongVal);
    setTemplateId(templateId);
    await editNoticeInfo(templateId);
    if (lookupLongVal === 'DHS FIA 604A SCHOOL ATTENDANCE VERIFICATION FORM') {
      setAttendanceOpen(true);
    } else if (
      lookupLongVal === 'DHS FIA 500C MEDICAL REPORT VERIFICATION FORM'
    ) {
      setMedicalOpen(true);
    } else if (
      lookupLongVal === 'DHS FIA 500B VERIFICATION OF ACTIVITY PARTICIPATION'
    ) {
      setFamilyInvestOpen(true);
    } else if (lookupLongVal === 'DHS FIA 340') {
      setDhsFiaOpen(true);
    } else if (lookupLongVal === 'DHS FIA 500A VERIFICATION OF DISABILITY') {
      setFamilyMedRepo(true);
    } else if (
      lookupLongVal ===
      'DHS FIA 4350 DISABILITY CERTIFICATION VERIFICATION FORM'
    ) {
      setDisabilityForm(true);
    } else if (lookupLongVal === 'DHS FIA 1177 Substance Abuse Screening') {
      setScreeningOpen(true);
    } else if (lookupLongVal === 'DHS FIA 1176 Consent to Release') {
      setAlcoholFormOpen(true);
    } else if (
      lookupLongVal === 'DHS FIA 1131 PRIMARY PREVENTIONHEALTH CARE FORM'
    ) {
      setPreventionOpen(true);
    } else if (lookupLongVal === 'MDH Form 800 Hardship Exemption') {
      setHardshipOpen(true);
    }
  };

  const toggleClose = () => {
    setAttendanceOpen(false);
    setMedicalOpen(false);
    setFamilyInvestOpen(false);
    setDhsFiaOpen(false);
    setFamilyMedRepo(false);
    setDisabilityForm(false);
    setScreeningOpen(false);
    setAlcoholFormOpen(false);
    setPreventionOpen(false);
    setHardshipOpen(false);
  };

  // const handleSearch = () => {
  //   const search = formik.values.emptySearch.toLowerCase();
  //   const filteredData = data.filter((item) =>
  //     Object.values(item).some((val) =>
  //       String(val).toLowerCase().includes(search)
  //     )
  //   );
  //   setFilteredData(filteredData);
  // };
  // const handleHistorySearch = () => {
  //   const searchHist = formik.values.filledSearch.toLowerCase();
  //   const historySearch = data.filter((item) =>
  //     Object.values(item).some((val) =>
  //       String(val).toLowerCase().includes(searchHist)
  //     )
  //   );
  //   setHistorySearch(historySearch);
  // };
  const { individualId } = formik.values;

  return (
    <>
      <Paper sx={{ height: '100%' }}>
        <Grid mt="1rem" ml="1rem" mr="1rem">
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h3">On Demand Letters</Typography>
          </Stack>
        </Grid>
        <Paper
          sx={{
            marginTop: '3rem',
            marginLeft: '2rem',
            marginRight: '2rem',
            padding: '2rem',
          }}
        >
          <Grid container spacing={2} ml="8rem">
            <Grid item xs={4}>
              <AssignmentIcon
                style={{
                  color: 'grey',
                  fontSize: '4.5rem',
                  backgroundColor: '#f0f0f0',
                  padding: '1rem',
                  borderRadius: '50%',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AdminPanelSettingsIcon
                style={{
                  color: 'grey',
                  fontSize: '4.5rem',
                  backgroundColor: '#f0f0f0',
                  padding: '1rem',
                  borderRadius: '50%',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <AccountCircleIcon
                style={{
                  color: 'grey',
                  fontSize: '4.5rem',
                  backgroundColor: '#f0f0f0',
                  padding: '1rem',
                  borderRadius: '50%',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Case ID</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Agency</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">Individual</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="1rem">
            <Grid item xs={4}>
              <Field name="caseId" formik={formik}>
                <TextField name="caseId" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name="agencyName" formik={formik}>
                <Dropdown options={agencyOpts} />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field name="individualId" formik={formik}>
                <Dropdown options={memberOpts} />
              </Field>
            </Grid>
          </Grid>
          <Stack mt="1rem" direction={'row'} justifyContent={'end'} spacing={2}>
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
            <Button variant="contained" onClick={formik.handleSubmit}>
              Search
            </Button>
          </Stack>
        </Paper>
        <Grid container spacing={2} ml="1rem" mt="1rem">
          <Grid item xs={12}>
            <Typography variant="h4">Empty Form</Typography>
          </Grid>

          {/* <Grid item xs={8}>
            <Field name="emptySearch" formik={formik}>
              <TextField
                placeholder="Search Letter or Forms"
                value={formik.values.emptySearch}
                onChange={formik.handleChange}
              />
            </Field>
          </Grid> */}
          {/* <Grid item xs={2}>
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>
          </Grid> */}
          <Grid item xs={12}>
            <Box
              sx={{
                marginTop: '1rem',
                marginRight: '4rem',
              }}
            >
              <TabularView
                columns={filterColumns}
                data={filteredData ?? []}
                headerComponent={{ variant: 'h4' }}
                sx={{
                  border: '1px solid #f0f0f0',
                }}
                slots={{
                  noRowsOverlay: () => (
                    <EmptyState
                      header="Currently No Records Available"
                      icon={
                        <img
                          src={NoRecord}
                          style={{
                            height: '160px',
                            width: '250px',
                          }}
                        />
                      }
                    />
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Filled Form</Typography>
          </Grid>
          {/* <Grid item xs={8}>
            <Field name="filledSearch" formik={formik}>
              <TextField placeholder="Search Letter or Forms" />
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" onClick={handleHistorySearch}>
              Search
            </Button> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <SearchResult
              historyData={historyData}
              historySearch={historySearch}
              caseId={caseId}
              individualId={individualId}
              handleNoticeEditOpen={handleNoticeEditOpen}
              recdId={recdId}
              postSearchNotice={postSearchNotice}
              // editData={editData}
            />
          </Grid>
        </Grid>
      </Paper>
      <Dialog open={attendanceOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <SchoolAttendance
            toggleClose={toggleClose}
            noticeData={noticeData}
            caseId={caseId}
            individualId={individualId}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={medicalOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <MedicalReport
            toggleClose={toggleClose}
            noticeData={noticeData}
            caseId={caseId}
            individualId={individualId}
            postSearchNotice={postSearchNotice}
            editData={editData}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={familyInvestOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <FamilyInvestment
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={dhsFiaOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <DhsFia
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={familyMedRepo} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <FamilyMedicalRepo
            toggleClose={toggleClose}
            noticeData={noticeData}
            caseId={caseId}
            individualId={individualId}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={disabiltyForm} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <DisabilityForm
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={screeningOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <SubstanceAbuse
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={alcoholFormOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <AlcoholDrugForm
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={preventionOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <PreventionHealth
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            noticeData={noticeData}
            editData={editData}
            postSearchNotice={postSearchNotice}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={hardshipOpen} maxWidth={'lg'} onClose={toggleClose}>
        <DialogContent>
          <HardshipExemption
            toggleClose={toggleClose}
            caseId={caseId}
            individualId={individualId}
            noticeData={noticeData}
            postSearchNotice={postSearchNotice}
            editData={editData}
            programName={programName}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
