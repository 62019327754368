import React, { useEffect, useState } from 'react';
import {
  getCaseData,
  retroMonthsList,
  retroListSubmit,
} from '../../utils/services/RetroMonths/request';
import { Box, Typography, Stack, Grid, Button } from '@mui/material';
import dayjs from 'dayjs';
import { Checkbox } from '@mui/material';
import styles from './index.module.css';

const RetroPageContent = ({ caseId, selected }) => {
  const [medicaidDate, setMedicaidData] = useState('');
  const [retroMonthlst, setRetroMonthlst] = useState([]);

  useEffect(() => {
    setMonthsretroList();
  }, [selected]);

  async function setMonthsretroList() {
    try {
      const res = await retroMonthsList(caseId, selected.individualId);
      if (res.status === 200 && res.data) {
        setRetroMonthlst(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSubmit(data) {
    console.log(retroMonthlst);
    console.log(data);
    try {
      const updatedData = data?.map((obj) => {
        return {
          retroIndicator: obj?.retroIndicator ?? '',
          benefitsCaseId: obj?.benefitsCaseId ?? '',
          programId: obj?.programId ?? '',
          effectiveBeginDate: obj?.effectiveBeginDate ?? null,
          effectiveEndDate: obj?.effectiveEndDate ?? null,
          retroMonth: obj?.retroMonth ?? '',
          applicationDate: obj?.applicationDate ?? null,
          monthText: obj?.monthText ?? '',
          retroBenefitId: obj?.retroBenefitId ?? '',
          individualId: obj?.individualId ?? '',
          confirmationIndicator: obj?.confirmationIndicator ?? '',
          dirtyStatus: 'update',
        };
      });
      const res = await retroListSubmit(caseId, updatedData);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  const getRetroDate = async () => {
    try {
      const res = await getCaseData(caseId);

      if (res.status === 200) {
        const findRequestedDate =
          res.data?.requestedProgram[0]?.programRequest.find(
            (obj) => obj.programName === 'FS'
          );
        setMedicaidData(findRequestedDate.requestedDate);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleChange = (e, ind) => {
    const res = [...retroMonthlst];
    res[ind] = {
      ...retroMonthlst[ind],
      retroIndicator: e.target.checked ? 'Y' : 'N',
    };
    setRetroMonthlst(res);
  };
  useEffect(() => {
    getRetroDate();
  }, []);

  return (
    <Box
      sx={{
        margin: '1rem',
        padding: '1rem',
        border: '1px solid var(--grey-250)',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">Application Date</Typography>
      <div className={styles.dateContainer}>
        <div className={styles.today}>{dayjs(medicaidDate).format('MMMM')}</div>
        <div className={styles.dateWrapper}>
          <span className={styles.todayDate}>{dayjs(medicaidDate).date()}</span>
        </div>
        <div className={styles.years}>{dayjs(medicaidDate).year()}</div>
      </div>
      <Box
        sx={{
          width: '100%',
          margin: '1rem',
          padding: '1rem 0rem 0 0',
          border: '1px solid var(--grey-250)',
          borderRadius: '5px',
        }}
      >
        <Typography variant="h5" sx={{ paddingLeft: '1.5rem' }}>
          Retro Indicator
        </Typography>
        <Grid
          container
          xs={12}
          justifyContent="space-around"
          sx={{ margin: '1.5rem 0' }}
        >
          {retroMonthlst?.map((obj, key) => {
            return (
              <Grid item>
                <div
                  className={styles.smallDate}
                  style={{ marginBottom: '1rem' }}
                >
                  <div className={styles.smallTodayDate}>
                    {dayjs(obj.monthText).format('MMMM')}
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Checkbox
                      checked={
                        retroMonthlst[key].retroIndicator === 'Y' ? true : false
                      }
                      onChange={(e) => handleChange(e, key)}
                    />
                  </div>
                  <div className={styles.yearSmall}>
                    {dayjs(obj.monthText).year()}
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Stack direction="row" justifyContent="end" sx={{ width: '100%' }}>
        <Button variant="contained" onClick={() => handleSubmit(retroMonthlst)}>
          Save
        </Button>
      </Stack>
    </Box>
  );
};

export default RetroPageContent;
