import {
  Button,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { postFamilyMedicalRep } from '../../../../utils/services/ManualNotices/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import InputMask from 'react-input-mask';

function createData(
  name,
  Activity,
  Unknown,
  NoRestrictions,
  Never,
  OneHour,
  TwoHours,
  ThreeHours,
  FourHours,
  FiveHours,
  SixHours,
  SevenHours,
  EightHours
) {
  // 1hr, 2hrs,3hrs, 4hrs, 5hrs, 6hrs, 7hrs, 8hrs
  return {
    name,
    Activity,
    Unknown,
    NoRestrictions,
    Never,
    OneHour,
    TwoHours,
    ThreeHours,
    FourHours,
    FiveHours,
    SixHours,
    SevenHours,
    EightHours,
  };
}

export default function FamilyMedicalRepo({
  toggleClose,
  noticeData,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();
  const formik = useFormik({
    initialValues: {
      socialService: dataValue?.ldssName ?? noticeData?.organizationName ?? '',
      localDsitrict: dataValue?.caseStrtAddr1 ?? noticeData?.ldssDoName ?? '',
      dateOffice:
        dataValue?.triggerDate ?? noticeData?.applicationDate
          ? dayjs(noticeData?.applicationDate)
          : '',
      caseManager:
        dataValue?.caseManagerName ?? noticeData?.caseManagerName ?? '',
      phoneNumber:
        dataValue?.ldssFaxNumber ?? noticeData?.caseManagerTelNmbr ?? '',
      customerName: dataValue?.titleCustomerName ?? '',
      customerId: dataValue?.titleMaId ?? '',
      patientName: dataValue?.sectionNameOfPatient ?? '',
      dob: dataValue?.sectionDateOfBirth ?? '',
      address:
        dataValue?.sectionAddr ??
        `${noticeData?.caseStrtAddr1 ?? ''}, ${
          noticeData?.caseStrtCity ?? ''
        }, ${noticeData?.ldssState ?? ''}, ${noticeData?.caseZipCode ?? ''} ` ??
        '',
      firstVisit: dataValue?.firstVisit ?? '',
      lastVisit: dataValue?.lastVisit ?? '',
      symptoms: dataValue?.presentingSymtoms ?? '',
      substanceAbuse: dataValue?.substanceAbuseIssue ?? '',
      medicalCondition: dataValue?.addiSubAbuse ?? '',
      impairment: dataValue?.visualImpairment ?? '',
      independently: dataValue?.continuousBasis ?? '',
      individualMental: dataValue?.mentalIll ?? '',
      mentalIllness: dataValue?.metalIllPrev ?? '',
      learningDisabilities: dataValue?.learningDisab ?? '',
      violentBehaviours: dataValue?.violentBehv ?? '',
      impairment12: dataValue?.last12Mon ?? '',
      fromDate: dataValue?.fromDate ?? '',
      toDate: dataValue?.toDate ?? '',
      sign: dataValue?.signature ?? '',
      printName: dataValue?.printName ?? '',
      title: dataValue?.title ?? '',
      license: dataValue?.license ?? '',
      helathAdd: dataValue?.nameAddr ?? '',
      date: dataValue?.date ?? '',
      phone: dataValue?.phoneNo ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      familyMedicalAdd(values);
    },
  });

  const familyMedicalAdd = async (data) => {
    try {
      showLoader();
      const res = await postFamilyMedicalRep(data);
      if (res.status === 200) {
        showSuccessToast('Successfully Created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const rows = [
    createData('Sit'),
    createData('Stand '),
    createData('Walk'),
    createData('Climb'),
    createData('Bend'),
    createData('Squat'),
    createData('Reach'),
  ];
  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Stack direction="row" justifyContent="center" mt="2rem">
          <Typography variant="h3">
            Family Investment Administration Medical Report Form 500A
          </Typography>
        </Stack>
        <Grid ml="3rem" mr="3rem">
          <Grid container spacing={2} mt="0.2rem" ml="2rem">
            <Grid item xs={6}>
              <Field name="socialService" formik={formik}>
                <TextField variant="standard" />
              </Field>
              {/* <Typography>Department of Social Services</Typography> */}
            </Grid>
            <Grid item xs={6} mt="1rem">
              <Typography variant="h5grey">
                Department of Social Services
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h5grey">
                The Family Investment Administration is committed to providing
                access, and reasonable accommodation in its services, programs,
                activities, education and employment for individuals with
                disabilities. If you need assistance or need to request a
                reasonable accommodation, please contact your case manager or
                call 1-800-332-6347.
              </Typography>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Typography variant="subtitle1">
                Local District Office:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="localDsitrict" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="dateOffice" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Case Manager:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="caseManager" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Phone Number:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="phoneNumber"
                formik={formik}
                normalizers={[maxLength(10), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.phoneNumber}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Customer's Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="customerName"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Customer ID#:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="customerId"
                formik={formik}
                normalizers={[maxLength(20), number]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5grey">
                The information provided on this form is used to detemine
                eligibility for Vermont's Temporary Disability Assistance
                Program (TDAP).
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h4">A. Patient Information</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Patient Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="patientName"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date of Birth:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="dob" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Address:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                name="address"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12} mt="0.5rem">
              <Typography variant="h4">B. Date/s of Examinatons</Typography>
            </Grid>
            <Grid item xs={4} mt="0.3rem">
              <Typography variant="subtitle1">First Visit:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="firstVisit" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Last Visit:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="lastVisit" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Presenting Symptoms:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="symptoms" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12} mt="0.5rem">
              <Typography variant="subtitle1">
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Health Provider:
                </span>
                Our goal is to help families gain the skills and knowledge
                needed to become self sufficient and independent of cash
                assistance programs. In terms of your patient’s attend training
                or attend an educational activity with a reasonable
                accommodation for any impairment, during an 8-hour day the
                patient can:
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="2rem">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ border: 'solid 1 px' }}>
                    <TableCell sx={{ border: '1px solid' }}>Activity</TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Unknown
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      No Restrictions
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Never
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      one Hour
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Two Hours
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Three Hours
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Four Hours
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Five Hours
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Six Hours
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Seven Hours
                    </TableCell>
                    <TableCell sx={{ border: '1px solid' }} align="right">
                      Eight Hours
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: '1px solid' }}>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        sx={{ border: '1px solid' }}
                        component="th"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField name={'Sit' + row.name} variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>{' '}
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="right">
                        <TextField variant="standard" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} mt="1rem">
              <Typography variant="subtitle1">
                1. Does this individual have a substance abuse issue?
              </Typography>
            </Grid>
            <Grid item xs={4} mt="1rem">
              <Field name="substanceAbuse" formik={formik}>
                <RadioGroup
                  name="substanceAbuse"
                  row
                  {...formik.getFieldProps('substanceAbuse')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.substanceAbuse}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={6} mt="1rem">
              <Typography variant="subtitle1">
                If yes, do other medical conditions exist in addition to
                substance abuse?
              </Typography>
            </Grid>
            <Grid item xs={4} mt="1rem">
              <Field name="medicalCondition" formik={formik}>
                <RadioGroup
                  name="medicalCondition"
                  row
                  {...formik.getFieldProps('medicalCondition')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.medicalCondition}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={6} mt="1rem">
              <Typography variant="subtitle1">
                Does this individual have a visual impairment or disease that
                limits or interferes with his or her ability?
              </Typography>
            </Grid>
            <Grid item xs={4} mt="1rem">
              <Field name="impairment" formik={formik}>
                <RadioGroup
                  name="impairment"
                  row
                  {...formik.getFieldProps('impairment')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.impairment}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={6} mt="1rem">
              <Typography variant="subtitle1">
                to function independently, appropriately and effectively on a
                continuous basis?
              </Typography>
            </Grid>
            <Grid item xs={4} mt="1rem">
              <Field name="independently" formik={formik}>
                <RadioGroup
                  name="independently"
                  row
                  {...formik.getFieldProps('independently')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.independently}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                C. Mental/Emotional Health Status
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Does this individual suffer from a mental illness?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="individualMental" formik={formik}>
                <RadioGroup
                  name="individualMental"
                  row
                  {...formik.getFieldProps('individualMental')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.individualMental}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Is the mental illness severe enough to prevent the patient from
                working, participating in a work, training or educational
                activity.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="mentalIllness" formik={formik}>
                <RadioGroup
                  name="mentalIllness"
                  row
                  {...formik.getFieldProps('mentalIllness')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.mentalIllness}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                To the best of your knowledge does the individual have any
                learning disabilities?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="learningDisabilities" formik={formik}>
                <RadioGroup
                  name="learningDisabilities"
                  row
                  {...formik.getFieldProps('learningDisabilities')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.learningDisabilities}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                To the best of your knowledge, does the individual exhibit any
                violent behaviors?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="violentBehaviours" formik={formik}>
                <RadioGroup
                  name="violentBehaviours"
                  row
                  {...formik.getFieldProps('violentBehaviours')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.violentBehaviours}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Can the individual’s impairment be expected to last at least 12
                months or more?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="impairment12" formik={formik}>
                <RadioGroup
                  name="impairment12"
                  row
                  {...formik.getFieldProps('impairment12')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.impairment12}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Please give the length of time the patient’s impairment is
                expected to last.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="fromDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">To</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="toDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Signature of a health care provider with independent diagnostic
                authority, who is authorized to evaluate, determine impairment,
                and independently treat medical, mental and/or emotional
                disorders and conditions, and who is providing services
                according to the requirements of the appropriate professional
                board.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Signature:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="sign"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Print Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="printName" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Title:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="title" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">License #:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="license"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Health Care Practice Name and Address:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="helathAdd" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="date" formik={formik}>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Phone #:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="phone"
                formik={formik}
                normalizers={[maxLength(10), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.phone}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={12} mt="2rem" mb="1rem">
              <Typography variant="h5">
                DHS FIA 500A VERIFICATION OF DISABILITY
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt="1rem" spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Disacrd
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
