import dayjs from 'dayjs';
import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { saveProgramRequestPayload } from './apiPayload';
import {
  GET_DNSAP_IND_REQ,
  GET_PROGRAM_REQ,
  GET_SELECTED_PROGRAMS_REQ,
  POST_PROGRAM_REQ,
  SAVE_PROGRAM_REQ,
} from './apiUrls';

export const getProgramRequest = (caseId) =>
  HttpClient.get(format(GET_PROGRAM_REQ, caseId));

export const getSelectedProgramsReq = (caseId) =>
  HttpClient.get(format(GET_SELECTED_PROGRAMS_REQ, caseId));

// export const saveProgramRequest = (values, data, caseId, method) => {
//   HttpClient.post(
//     format(POST_PROGRAM_REQ, caseId),
//     saveProgramRequestPayload(values, data, method)
//   );
// };

export const saveProgramRequest = (data, caseId) =>
  HttpClient.post(format(SAVE_PROGRAM_REQ, caseId), data);

export const getDsnapIndicator = () =>
  HttpClient.get(format(GET_DNSAP_IND_REQ, dayjs().format('YYYY-MM-DD')));
