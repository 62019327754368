import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import styles from '../../index.module.css';

export default function CPRelationshipInfo({
  data,
  getLkpMatch,
  hohMemberData,
  handleCheckbox,
}) {
  const getMemberNameByIndividualId = useCallback(
    (individualId) => {
      let result = hohMemberData?.find(
        (item) => item.dummyIndividualId === individualId
      );
      return `${result?.firstName ?? ''} ${result?.lastName ?? ''}`;
    },
    [data, hohMemberData]
  );

  return (
    <>
      {map(data, (item, index) => {
        return (
          <Stack direction="row" pl={1}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              onChange={(event) => handleCheckbox(event, index)}
              checked={item?.importSelected}
              control={<Checkbox />}
            />
            <div className={styles.card}>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 1.5,
                      paddingBottom: '0.5rem',
                      border: 0,
                    },
                  }}
                >
                  <TableBody className={styles.tableBody}>
                    <TableRow>
                      <TableCell rowSpan={1 + (item?.relations?.length || 0)}>
                        <Typography variant="h5grey">
                          {getMemberNameByIndividualId(item?.individualId) ??
                            ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {map(item?.relations, (ele, ind) => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            {getLkpMatch(
                              'IndividualRelationship',
                              ele?.relationshipCd
                            ) ?? ''}{' '}
                            of
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6grey">
                            {getMemberNameByIndividualId(ele?.individualId) ??
                              ''}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Stack>
        );
      })}
    </>
  );
}
