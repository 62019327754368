import React, { createRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import SelectableView from '../../components/SelectableView';
import { toast } from 'react-toastify';
import {
  getIncome,
  getHouseMembers,
  deleteMemberApi,
} from '../../utils/services/apiRequests';
import { useParams } from 'react-router';
import { entries, get, includes, isEmpty, map, size, some } from 'lodash';
import useLookup from '../../hooks/useLookup';
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from '../../utils/services/toast';
import { getAge } from '../../utils/helpers';
import MemberForm from './MemberForm';
import { Field, useFormik } from 'formik';
import usePageNav from '../../hooks/usePageNav';
import { CloseRounded, DeleteOutlineOutlined } from '@mui/icons-material';
import Dropdown from '../../components/Dropdown';
import PageHeader from '../../components/PageHeader';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../store/slices/loaderSlice';
import useLoader from '../../hooks/useLoader';
import * as yup from 'yup';
import ClearanceSearch from '../ClearanceSearch';
import MemberDeleteModal from './MemberDeleteModal';
import {
  selectFetchingMembers,
  selectHouseMembers,
} from '../../store/selectors/caseSelector';
import { navigatePageName } from '../../store/selectors/caseSelector';
import { FETCH_HOUSE_MEMBERS } from '../../store/types/caseTypes';
import PageContainer from '../../components/PageContainer';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import usePage from '../../hooks/usePage';

function HouseMembers({ id }) {
  const selectableViewRef = createRef();
  const navigatePagenav = useSelector(navigatePageName);
  const dispatch = useDispatch();
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const [showLoader, hideLoader] = useLoader();
  const { caseId, navigations } = usePageNav();
  const { navProps } = usePage(id);
  const [showClearance, setShowClearance] = useState(false);
  const { lkpFlatten, getLkpMatch, lkpRaw, getOptions } =
    useLookup('MemberDetails');
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState({});
  // const [houseMembers, setHouseMembers] = useState([]);
  const [deleteDate, setDeleteDate] = useState('');
  const [clearanceData, setClearanceData] = useState('');
  const params = useParams();

  const { houseHoldMembers, hasHoh } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
      hasHoh: some(houseMembers, {
        headOfHouseholdYN: 'Y',
      }),
    };
  }, [houseMembers]);

  const relationOptions = useMemo(() => {
    const { IndividualRelationship = [] } = lkpFlatten;
    return map(entries(IndividualRelationship), ([value, label]) => ({
      value,
      label,
    }));
  }, [lkpFlatten]);

  const fetchHouseMembers = () => {
    dispatch({ type: FETCH_HOUSE_MEMBERS, payload: caseId });
  };

  const onDeleteMember = (member = {}) => {
    setOpenDeleteModal(true);
    setMemberToDelete(member);
  };

  const onDeleteMemberScs = () => {
    fetchHouseMembers();
    selectableViewRef.current?.setSelectedIdx(0);
  };

  useEffect(() => {
    // newCheck();
    if (isEmpty(houseMembers)) {
      fetchHouseMembers();
    }
  }, []);

  const onNext = () => {
    // if (size(houseHoldMembers) > 1) {
    //   navigations.toRelationship();
    // } else {
    //   navigations.toCitizenship();
    // }
  };

  const onBack = () => {
    navigations.toContactInformation();
  };

  const toggleAddModal = (data) => {
    setShowClearance(false);
    setOpenAddModal(!openAddModal);
    setClearanceData(data);
  };

  const toggleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleClearance = (data) => {
    setShowClearance(!showClearance);
  };

  const validateMemberDetails = () => {
    const mamberData = map(houseMembers, (value) => {
      const flag = !isEmpty(
        (value?.soSec?.soSecPart1 && value?.ssnVerify) ||
          (value?.soSecReferral && value?.birthVerify)
      );
      if (!flag) {
        showWarningToast(
          `Please add mandatory details for ${
            value.personName.firstName + ' ' + value.personName.lastName
          }`
        );
      }
      return flag;
    });
    return !includes(mamberData, false);
  };
  // const newCheck = async () => {
  //   try {
  //     const res = await getMember(params.caseId);
  //     if (res.status === 200) {
  //       console.log(res.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //   }
  // };

  return (
    <PageContainer
      navProps={navProps}
      title="Member Details"
      toVerify={
        ['SSN', 'RACE', 'RACE_ETHNICITY', 'DOB', 'DEATH'].includes(
          navigatePagenav
        )
          ? navigatePagenav
          : '  '
      }
      onNext={onNext}
      onBack={onBack}
      validateBeforeNextPage={validateMemberDetails}
    >
      {showClearance && (
        <ClearanceSearch
          open={showClearance}
          close={toggleClearance}
          onAddIndividual={toggleAddModal}
        />
      )}
      <SelectableView
        ref={selectableViewRef}
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        title="Members"
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        onAdd={toggleClearance}
        onEdit={toggleEditModal}
        onDelete={onDeleteMember}
        isDisableDelete={({ headOfHouseholdYN }) => headOfHouseholdYN === 'Y'}
      >
        {(selected) => {
          return (
            <>
              <MemberForm
                key={selected?.individualId}
                memberDetails={selected}
                getOptions={getOptions}
                readOnly
              />
              <MemberDeleteModal
                open={openDeleteModal}
                close={toggleDeleteModal}
                onDelete={onDeleteMemberScs}
                reasons={getOptions('DeleteMemberReasonCode')}
                memberToDelete={memberToDelete}
                caseId={caseId}
              />
              {openAddModal && (
                <Dialog
                  open={openAddModal}
                  onClose={toggleAddModal}
                  component="form"
                  maxWidth="lg"
                >
                  <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h3">Add New Member</Typography>
                      <IconButton onClick={toggleAddModal}>
                        <CloseRounded />
                      </IconButton>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <MemberForm
                      hasHoh={hasHoh}
                      close={toggleAddModal}
                      key={selected?.individualId}
                      getOptions={getOptions}
                      createNew
                      fetchHouseMembers={fetchHouseMembers}
                      clearanceData={clearanceData}
                    />
                  </DialogContent>
                </Dialog>
              )}
              {openEditModal && (
                <Dialog
                  open={openEditModal}
                  onClose={toggleEditModal}
                  component="form"
                  maxWidth="lg"
                >
                  <DialogTitle>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h3">Edit Member Details</Typography>
                      <IconButton onClick={toggleEditModal}>
                        <CloseRounded />
                      </IconButton>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <MemberForm
                      hasHoh={hasHoh}
                      close={toggleEditModal}
                      key={selected?.individualId}
                      memberDetails={selected}
                      getOptions={getOptions}
                      editMode
                      fetchHouseMembers={fetchHouseMembers}
                    />
                  </DialogContent>
                </Dialog>
              )}
            </>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}

HouseMembers.propTypes = {};

export default HouseMembers;
