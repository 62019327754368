import React, { useMemo } from 'react';
import SummaryContent from '../SummaryContent';
import TabularView from '../../../components/TabularView';
import { includes, map } from 'lodash';
import { getRecentRecord } from '../../../utils/helpers';
import { IconButton, Tooltip } from '@mui/material';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';

function LivingArmnt({ getLkpMatch, houseMembers }) {
  // console.log(houseMembers[0]?.individualDetails?.livingArrangements);

  // array.reduce((a, b) => {
  //   return new Date(a.MeasureDate) > new Date() ? a : b;
  // });

  const livingDetails = useMemo(() => {
    return map(houseMembers, (member) => {
      const { livingArrangements = [] } = member?.individualDetails ?? [];
      const recentLivingArrangement = getRecentRecord(
        livingArrangements,
        'effectiveBeginDate'
      );

      return {
        ...member,
        recentLivingArrangement,
      };
    });
  }, [houseMembers]);
  console.log();

  const columns = useMemo(
    () => [
      {
        field: '',
        // livingArrangement
        headerName: 'Facility Name',
        getValue({ recentLivingArrangement = {} }) {
          console.log(recentLivingArrangement);
          return `${
            getLkpMatch(
              'LivingArrangements',
              recentLivingArrangement?.livingArrangement
            ) ?? '-'
          }`;
        },
      },
      {
        field: '',
        headerName: 'Eff. Begin Date',
        getValue({ recentLivingArrangement = {} }) {
          return `${recentLivingArrangement?.effectiveBeginDate ?? '-'}`;
        },
      },
      {
        field: '',
        headerName: 'Eff. End Date',
        getValue({ recentLivingArrangement = {} }) {
          console.log(recentLivingArrangement);
          const endDate = includes(
            recentLivingArrangement?.effectiveEndDate,
            '9999'
          );
          return `${
            endDate ? '-' : recentLivingArrangement?.effectiveEndDate ?? '-'
          }`;
        },
        // effectiveEndDate
      },
      {
        field: '',
        headerName: 'Update',
        renderCell: (params) => (
          <IconButton>
            <Tooltip>
              <RestoreRoundedIcon />
            </Tooltip>
          </IconButton>
        ),
      },
    ],
    [getLkpMatch]
  );
  return (
    <SummaryContent title="Living Arrangement">
      <TabularView
        sx={{
          table: {
            width: '100%',
          },
          td: {
            border: 0,
            padding: '0 1.25rem 0.5rem 0',
          },
          th: {
            border: 0,
            borderBottom: '1px solid var(--grey-300)',
            padding: '0 1.25rem 0.5rem 0',
          },
        }}
        headerComponent={{ variant: 'h6', color: 'var(--grey-400)' }}
        cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
        columns={columns}
        data={livingDetails}
      />
    </SummaryContent>
  );
}

LivingArmnt.propTypes = {};

export default LivingArmnt;
