import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import UserManagement from './User Management';
import BulletinPostings from './BulletinPostings';
import Holidays from './Holidays/TotalHolidays';
import EligibilityParameter from './EligibilityParameter/EligibilityParameter';
import Cola from './Cola';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import Field from '../../components/Field';
import VendorDetails from './VendorDetails';

const Setting = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    setSelectedIndex(1);
    setPage('eligibilityParameter');
  }, []);

  const searchData = [
    {
      labels: 'User Management',
      path: 'userManagement',
      icon: <ManageAccountsOutlinedIcon />,
      // subtitle: 'User Case Programs, Roles Management',
    },
    {
      labels: 'Eligibility Parameters',
      path: 'eligibilityParameter',
      icon: <AdminPanelSettingsOutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'Cost of Living Adjustment Review',
      path: 'cola',
      icon: <AddHomeWorkOutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'District Office',
      path: 'districtOffice',
      icon: <LocationOnOutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'Holidays',
      path: 'Holidays',
      icon: <DateRangeOutlinedIcon />,
      subtitle: 'Add Holidays, View Holidays',
    },
    {
      labels: 'Supervisor Action',
      path: 'supervisorAction',
      icon: <SupervisorAccountOutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'System Bulletin/Postings',
      path: 'sytemBulletinPostings',
      icon: <ScreenShareOutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'LDSS Capacity',
      path: 'LDSSCapacity',
      icon: <Groups2OutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'MDM Workitems',
      path: 'MDMWorkitems',
      icon: <StickyNote2OutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'CDC Notices Calendar',
      path: 'CDCNoticesCalendar',
      icon: <CalendarMonthOutlinedIcon />,
      // subtitle: '',
    },
    {
      labels: 'Vendor Details',
      path: 'vendorDetails',
      icon: <Diversity3OutlinedIcon />,
      subtitle: 'Add Vendor, Vendor Ownership',
    },
  ];

  const handleListItemClick = (index, item) => {
    setSelectedIndex(index);
    setPage(item.path);
  };

  return (
    <Box
      sx={{
        padding: '1rem',
        height: '100vh',
        overflow: 'auto',
        backgroundColor: '#fff',
      }}
    >
      {/* <Box sx={{ border: '1px solid #D9D9D9' }}> */}
      <Grid container paddingTop="1rem">
        <Grid
          item
          xs={4}
          // sx={{
          //   border: '1px solid #D9D9D9',
          //   // padding: '1rem',
          //   marginLeft: '1rem',
          // }}
        >
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '1.5rem',
              fontWeight: '700',
              lineHeight: '2rem',
              textAlign: 'left',
              paddingLeft: '1.7rem',
              color: '#2C2C2C',
            }}
          >
            <IconButton sx={{ color: 'black' }}>
              <SettingsOutlinedIcon />
            </IconButton>
            Settings
          </Typography>
        </Grid>
      </Grid>

      {/* </Box> */}
      <Grid container>
        <Grid
          item
          xs={4}
          sx={{
            padding: '0.5rem',
            position: 'sticky',
            top: 0,

            alignSelf: 'flex-start',
          }}
        >
          <List
            sx={{
              //  / border: '1px solid var(--grey-350)',
              borderRadius: '6px',
              padding: '0.5rem',
            }}
          >
            {searchData.map((item, index) => (
              <Fragment key={index}>
                <Box
                  sx={{
                    border: '1px solid #D9D9D9',
                    padding: '0.5rem',
                    backgroundColor:
                      selectedIndex === index
                        ? 'var(--primary-light)'
                        : 'inherit',
                  }}
                >
                  <ListItem
                    onClick={(e) => {
                      handleListItemClick(index, item || '');
                    }}
                    sx={{
                      cursor: 'pointer',
                      // backgroundColor:
                      //   selectedIndex === index
                      //     ? 'var(--primary-light)'
                      //     : 'inherit',
                      color: selectedIndex === index ? 'white' : 'inherit',
                    }}
                  >
                    {item.icon && (
                      <ListItemIcon
                        sx={{
                          minWidth: '35px',
                          color:
                            selectedIndex === index
                              ? '#fff'
                              : 'var(--black-900)',
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                    )}
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: selectedIndex === index ? '#fff' : '#2C2C2C',
                        fontWeight: '600',
                      }}
                    >
                      {item.labels || ''}
                    </Typography>
                  </ListItem>
                  {/* <Typography
                    fontSize="12px"
                    // sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {item.subtitle || ''}
                  </Typography> */}
                </Box>

                {index < searchData.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        </Grid>

        <Grid item xs={8}>
          <Box>
            {page === 'userManagement' && <UserManagement />}
            {page === 'syatemBulletinPostings' && <BulletinPostings />}
            {page === 'Holidays' && <Holidays />}
            {page === 'eligibilityParameter' && <EligibilityParameter />}
            {page === 'cola' && <Cola />}
            {page === 'vendorDetails' && <VendorDetails />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Setting;
