import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
  assignedApi,
  caseReAssigne,
} from '../../../../utils/services/apiRequests';
import { getAuthToken } from '../../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import useCaseStatus from '../../../../hooks/useCaseStatus';
import styles from '../MyDashboardTable/index.module.css';
import { Grid, Tooltip, IconButton, Box, Button, Stack } from '@mui/material';
import cx from 'classnames';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { find, map } from 'lodash';
import useMount from '../../../../hooks/useMount';
import Field from '../../../../components/Field';
import EmptyState from '../../../../components/EmptyState';
import Dropdown from '../../../../components/Dropdown';
import useLoader from '../../../../hooks/useLoader';
import { useFormik } from 'formik';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import usePageNav from '../../../../hooks/usePageNav';
import { ReactComponent as WorkUpdate } from '../../../../assets/icons/QuickLinks/work_update.svg';
import IconLink from '../../../../components/IconLinks';
import NoRecord from '../../../../assets/icons/No-data-amico.svg';

function AssignedTable({ lkpFlatten, getWorkItemData }) {
  const [fetching, setFetching] = useState(false);
  const [assignedApplication, setAssignedApplication] = useState([]);
  const token = useSelector(getAuthToken);
  const { viewCase } = useCaseStatus();
  const { navigations, caseId } = usePageNav();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [option, setOption] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchData = async ({ page, pageSize }) => {
    try {
      setFetching(true);
      showLoader();
      const paginationDataPaper = {
        resultsPerPage: pageSize,
        pageNumber: page,
      };
      const res = await assignedApi(paginationDataPaper, token?.sub, 'AP');
      if (res.status === 200) {
        const {
          paginationPaperData = 0,
          assignedCasesList,
          wrkrList,
        } = res.data;
        setAssignedApplication(assignedCasesList);
        setOption(wrkrList);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      setFetching(false);
      hideLoader();
    }
  };

  const handleRowSelectionChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);
    const selectedData = map(newRowSelectionModel, (selectedId) => {
      return find(assignedApplication, (item) => item.caseId === selectedId);
    });
    setSelectedData(selectedData);
  };

  const formik = useFormik({
    initialValues: {
      assigned: null,
    },
    onSubmit: (values) => {
      assign(values?.assigned);
    },
  });

  const assign = async (uuid) => {
    try {
      showLoader();
      const payload = [
        {
          wrkrId: uuid,
          caseIdList: [],
          webIdList: [],
        },
      ];
      payload[0].caseIdList = map(selectedData, (item) => item.caseId);
      payload[0].webIdList = map(selectedData, (item) => item.webID);
      const res = await caseReAssigne(payload, 'AP');
      if (res.status === 201) {
        showSuccessToast('Assigned Success Fully');
        fetchData(page);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const onPageChange = (pages) => {
    setPage(pages);
    fetchData(pages);
  };

  const columns = [
    { field: 'source', headerName: 'Source', flex: 1, maxWidth: 150 },
    { field: 'caseId', headerName: 'Case ID', flex: 1, maxWidth: 150 },
    {
      field: 'Program',
      headerName: 'Program',
      renderCell: (params) => lkpFlatten?.Program?.[params?.row?.programs],
      flex: 1,
      maxWidth: 150,
    },
    { field: 'alertDesc', headerName: 'Type', flex: 1, maxWidth: 150 },
    {
      field: 'assignedTo',
      headerName: 'Assign To',
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'assignedDt',
      headerName: 'Assigned Date',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) =>
        dayjs(params?.row?.assignedDt).format('MM-DD-YYYY'),
    },
    {
      field: 'distOffice',
      headerName: 'LDSS',
      flex: 1,
      maxWidth: 150,
    },
    {
      field: 'dueDays',
      headerName: 'Due Days',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) =>
        params?.row?.dueDays < 0 ? 0 : params?.row?.dueDays,
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => action(params),
    },
  ];

  const onOpenCase = (caseId, controlId) => {
    navigations.toCPImport(caseId, controlId);
  };

  const lookupData = useMemo(() => {
    return map(option, (value) => {
      return {
        label: value.firstName + ' ' + value.lastName,
        value: value.uniqueId,
      };
    });
  }, [option]);

  const action = (params) => {
    if (
      params.row?.caseId === null &&
      params.row?.webRefID != null &&
      (params.row?.caseStsCd === '' || params.row?.caseStsCd === 'PE')
    ) {
      return (
        <>
          <Grid sx={{ cursor: 'pointer' }}>
            <IconButton
              onClick={() => onOpenCase(params.row.caseId, params.row.webId)}
            >
              <Tooltip title="Manual Import" placement="right">
                <MenuBookIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => viewCase(params.id, params.row.caseStsCd)}>
            <Tooltip title="View Case" placement="right">
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      );
    }
  };

  useMount(() => {
    fetchData(page);
  });

  return (
    <Box gap={2}>
      <Grid
        container
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          gap: '2rem',
        }}
        mb={2}
        xs={12}
      >
        <Grid
          xs={6}
          sx={{
            display: 'flex',
            alignItems: 'end',
            gap: '1rem',
            justifyContent: 'flex-end',
          }}
        >
          <Stack width="50%">
            <Field name="assigned" formik={formik} label="Assign To">
              <Dropdown options={lookupData} />
            </Field>
          </Stack>
          <Stack>
            <Button
              variant="contained"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Assign
            </Button>
          </Stack>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          xs={5}
        >
          <Button onClick={getWorkItemData}>
            <IconLink icon={WorkUpdate} iconlabel="Get Work Item" />
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        getRowId={(row) =>
          row.caseId ? row.caseId : row.webRefId || row.webID
        }
        rows={assignedApplication ?? []}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '15px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
        }}
        // slots={{
        //   noRowsOverlay: NoDataFound,
        // }}
        // slotProps={{
        //   noRowsOverlay: {
        //     type: 'Record',
        //     message: 'Currently N o Records Available',
        //     Note: 'Please click Get Work Item to Get Records',
        //   },
        // }}
        pageSizeOptions={[10, 25, 30]}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              description="Note: Please click Get Work Item to Get Records"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        checkboxSelection={true}
        onPaginationModelChange={onPageChange}
        onRowSelectionModelChange={handleRowSelectionChange}
        autoHeight
      />
    </Box>
  );
}

AssignedTable.propTypes = {};

export default AssignedTable;
