import {
  Button,
  Divider,
  Grid,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import useLoader from '../../../../hooks/useLoader';
import { postPreventHelathForm } from '../../../../utils/services/ManualNotices/apiRequest';
import InputMask from 'react-input-mask';

export default function PreventionHealth({
  toggleClose,
  noticeData,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();
  const formik = useFormik({
    initialValues: {
      ldssDepart: dataValue?.ldssName ?? noticeData?.ldssDoName ?? '',
      caseName: dataValue?.caseName ?? '',
      street: dataValue?.ldssStrtAddr1 ?? noticeData?.ldssStrtAddr1 ?? '',
      caseRecord: dataValue?.caseNumber ?? noticeData?.caseNumber ?? '',
      city:
        dataValue?.titleLdssAddr2 ??
        `${noticeData?.ldssCity ?? ''}, ${noticeData?.ldssState ?? ''}, ${
          noticeData?.ldssZipcode ?? ''
        }` ??
        '',
      mailDate:
        dataValue?.triggerDate ?? noticeData?.applicationDate
          ? dayjs(noticeData?.applicationDate)
          : '',
      phone:
        dataValue?.titleCaseManagerTelNmbr ??
        noticeData?.caseManagerTelNmbr ??
        '',
      caseNamePrim: dataValue?.sectionCaseName ?? '',
      caseNumPrim: dataValue?.sectionCaseNumber ?? noticeData?.caseNumber ?? '',
      minorParent: dataValue?.sectionMinorParentName ?? '',
      healthDate: dataValue?.sectionDueDate ?? '',
      parentSign: dataValue?.parentSigntr ?? '',
      signDate: dataValue?.signDate ?? '',
      telephoneNumb: dataValue?.telephone ?? '',
      childName1: dataValue?.childName1 ?? '',
      dob1: dataValue?.dob1 ?? '',
      date1: dataValue?.examDt1 ?? '',
      childName2: dataValue?.childName2 ?? '',
      dob2: dataValue?.dob2 ?? '',
      date2: dataValue?.examDt2 ?? '',
      childName3: dataValue?.childName3 ?? '',
      dob3: dataValue?.dob3 ?? '',
      date3: dataValue?.examDt3 ?? '',
      childName4: dataValue?.childName4 ?? '',
      dob4: dataValue?.dob4 ?? '',
      date4: dataValue?.examDt4 ?? '',
      providerSign: dataValue?.authSign ?? '',
      title: dataValue?.title ?? '',
      telephone: dataValue?.authTel ?? '',
      date: dataValue?.authDate ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      postPrevnetion(values);
    },
  });
  const postPrevnetion = async (data) => {
    try {
      showLoader();
      const res = await postPreventHelathForm(data);
      if (res.status === 200) {
        showSuccessToast('Successfully Created');
        toggleClose();
        postSearchNotice();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Stack display="flex" alignItems="center" mt="2rem">
          <Typography variant="h3">Human Services</Typography>
        </Stack>
        <Grid container spacing={2} ml="2rem" mt="2rem">
          <Grid item xs={5}>
            <Field
              name="ldssDepart"
              formik={formik}
              label="Local Department of Social Services LDSS:"
            >
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="caseName" formik={formik} label="Case Name:">
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="street" formik={formik} label="Street:">
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="caseRecord" formik={formik} label="Case Record #:">
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="city" formik={formik} label="City, State, ZIP:">
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="mailDate" formik={formik} label="Mail Date:" isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field name="phone" formik={formik} label="Phone:">
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.phone}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="h5grey"
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              Primary Prevention Initiative Health Care Form
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Field name="caseNamePrim" formik={formik} label="Case Name">
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="caseNumPrim" formik={formik} label="Case Number">
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field name="minorParent" formik={formik} label="Minor Parent Name">
              <TextField variant="standard" />
            </Field>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="subtitle1">
              Human Services requires that annual health check ups be verified
              for all children birth through 6 years of age, 7 to 18 years of
              age & all adults by a health care provider. In order to verify, we
              are asking the providers to complete the sections provided below.
              Please return the form by the requested date at the above
              mentioned address.
            </Typography>
          </Grid>
          <Grid item={8}>
            <Field name="healthDate" formik={formik} label="Due Date" isDate>
              <DatePicker
                slotProps={{
                  textField: {
                    variant: 'standard',
                  },
                }}
              />
            </Field>
          </Grid>
        </Grid>
        <Grid ml="2rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
          <Grid container spacing={2} ml="0.5rem" mt="0.5rem" mb="0.5rem">
            <Grid item xs={10}>
              <Typography variant="h5">
                Section 1: AUTHORIZATION TO RELEASE HEALTH CHECK UP INFORMATION
                <br></br>
                (To Be Completed By Parent/Guardian)
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1">
                I authorize the health care provider to give information about
                all the household members to the Department of Social Services
                (or should this be the Department of Human Services (statewide
                processing?))
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Field
                name="parentSign"
                formik={formik}
                label="Parent/Guardian Signature:"
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={10}>
              <Field
                name="signDate"
                formik={formik}
                label="Signature Date:"
                isDate
              >
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={10}>
              <Field
                name="telephoneNumb"
                formik={formik}
                normalizers={[maxLength(10), number]}
                label="Telephone Number where I can be reached:"
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.telephoneNumb}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
          </Grid>
        </Grid>
        <Grid ml="2rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
          <Grid container mt="1rem" spacing={2} mb="1rem">
            <Grid item xs={10} ml="1rem">
              <Typography variant="h5">
                Section 2 : Please fill in the following information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={4}
              ml="1rem"
              style={{ borderRight: '1px solid grey' }}
            >
              <Typography variant="subtitle1">
                Child’s /Adult’s full name
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ borderRight: '1px solid grey' }}>
              <Typography variant="subtitle1">Date of Birth</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Date of most recent exam
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={4}
              ml="1rem"
              style={{ borderRight: '1px solid grey' }}
            >
              <Field
                name="childName1"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField
                  variant="standard"
                  sx={{
                    paddingRight: '2rem',
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3} style={{ borderRight: '1px solid grey' }}>
              <Field name="dob1" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="date1" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid
              item
              xs={4}
              ml="1rem"
              style={{ borderRight: '1px solid grey' }}
            >
              <Field
                name="childName2"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField
                  variant="standard"
                  sx={{
                    paddingRight: '2rem',
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3} style={{ borderRight: '1px solid grey' }}>
              <Field name="dob2" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="date2" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid
              item
              xs={4}
              ml="1rem"
              style={{ borderRight: '1px solid grey' }}
            >
              <Field
                name="childName3"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField
                  variant="standard"
                  sx={{
                    paddingRight: '2rem',
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3} style={{ borderRight: '1px solid grey' }}>
              <Field name="dob3" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="date3" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid
              item
              xs={4}
              ml="1rem"
              style={{ borderRight: '1px solid grey' }}
            >
              <Field
                name="childName4"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField
                  variant="standard"
                  sx={{
                    paddingRight: '2rem',
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3} style={{ borderRight: '1px solid grey' }}>
              <Field name="dob4" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="date4" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
          </Grid>
        </Grid>
        <Grid ml="2rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
          <Grid container spacing={2} mb="1rem">
            <Grid item xs={10} ml="1rem" mt="1rem">
              <Typography variant="h5">
                Section 3:,AUTHORIZATION BY HEALTH CARE PROVIDER
              </Typography>
            </Grid>
            <Grid item xs={4} mt="1rem" ml="1rem">
              <Typography variant="subtitle1">
                (Signature of the health care provider)
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="providerSign"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4} ml="1rem">
              <Typography variant="subtitle1">Title</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="title" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4} ml="1rem">
              <Typography variant="subtitle1">Telephone Number</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="telephone" formik={formik}>
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.telephone}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4} ml="1rem">
              <Typography variant="subtitle1">Date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="date" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} ml="2rem" mb="1rem">
          <Typography variant="h4">VOID IF ALTERED</Typography>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt="1rem" spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Disacrd
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
