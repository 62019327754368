import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React, { useState, useEffect, Fragment, useMemo } from 'react';
import useLoader from '../../../hooks/useLoader';
import {
  getEligibilityParam,
  getEligibilityValue,
  postCaseEligibility,
} from '../../../utils/services/EligibilityParameters/apiRequest';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import TabularView from '../../../components/TabularView';
import Dropdown from '../../../components/Dropdown';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DatePicker } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { currencyDecimal, formatCurrency } from '../../../utils/normalizers';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/grocery-shopping.svg';

const SNAP = () => {
  const [showLoader, hideLoader] = useLoader();
  const [eligibility, setEligibility] = useState([]);
  const [selectedEligibilityData, setSelectedEligibilityData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDetermFactor, setSelectedDetermFactor] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [editData, setEditData] = useState({});
  const [enableAddButton, setEnableAddButton] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const validationSchema = Yup.object({
    paramValue: Yup.string()
      .max(12, 'Maximum of 10 digits allowed')
      .required('Please enter param value'),
    createdBeginDt: Yup.date().required('Start Date is required'),
  });

  useEffect(() => {
    getEligibility01('03');
  }, []);

  const handleInputChange = (e, value) => {
    setSearchVal(value);
    handleListItemClick(value);
    // if (value === '') {
    //   setSearchData(eligibility);
    //   setFilteredItems(eligibility);
    // } else {
    //   let filtered = eligibility.filter(({ longDesc }) => {
    //     return (longDesc?.toLowerCase() ?? '').includes(value?.toLowerCase());
    //   });
    //   setSearchData(filtered);
    //   setFilteredItems(filtered);
    // }
  };

  const handleSearchClick = () => {
    if (searchVal) {
      let filtered = eligibility.filter(({ longDesc }) => {
        return (longDesc?.toLowerCase() ?? '').includes(
          searchVal?.toLowerCase()
        );
      });
      setSearchData(filtered);
      setFilteredItems(filtered);
    } else {
      setSearchData(eligibility);
      setFilteredItems(eligibility);
    }
  };

  const getEligibility01 = async (Id) => {
    try {
      showLoader();
      const res = await getEligibilityParam(Id);
      if (res.status === 200) {
        if (!searchVal) {
          setSearchVal(res.data[0]);
          handleListItemClick(res.data[0]);
        }
        setEligibility(res.data);
        setSearchData(res.data);
        setFilteredItems(res.data);
      } else {
        showErrorToast('Failed to fetch eligibility data');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getEligibility = async (caseId, selectedItem) => {
    try {
      showLoader();
      const res = await getEligibilityValue(
        caseId,
        selectedItem.eligParamDesc,
        '03'
      );
      if (res.status === 200) {
        setSelectedEligibilityData(res.data);
      } else {
        showErrorToast('Failed to fetch eligibility data');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleListItemClick = (item) => {
    // setSelectedIndex(index);
    setSelectedItem(item);
    getEligibility(item?.determFactors?.[0]?.determFactor, item);
    setSelectedDetermFactor(item?.determFactors?.[0]?.determFactor); //To show initial data(default)
    setSearchVal(item);
    setEnableAddButton(true);
  };

  const determineFactorFilter = eligibility.filter(
    (item) => item.determFactors && item.determFactors.length > 1
  );

  const determineFactorItem = determineFactorFilter.filter(
    (item) => item.longDesc === selectedItem.longDesc
  );

  const determineFactorFilterLength = eligibility.filter(
    (item) => item.determFactors && item.determFactors.length === 1
  );

  const determineFactorItemLength = determineFactorFilterLength.filter(
    (item) => item.longDesc === selectedItem.longDesc
  );

  const determFactorMapping = useMemo(() => {
    const mapping = {};
    if (determineFactorItemLength[0]?.determFactors.length === 1) {
      mapping[determineFactorItemLength[0]?.determFactors[0]?.determFactor] =
        determineFactorItemLength[0]?.longDesc;
    } else if (determineFactorItem[0]?.determFactors.length > 1) {
      determineFactorItem[0]?.determFactors.forEach((determ) => {
        mapping[determ?.determFactor] = determ.determFactorDesc;
      });
    }
    return mapping;
  }, [determineFactorItemLength, determineFactorItem]);

  const columns = [
    {
      field: 'parmValue',
      headerName: 'Eligibility Value',
      renderCell: (params) => {
        return `$ ${currencyDecimal(params)}`;
      },
    },
    {
      field: 'determFactor',
      headerName: 'Determine Factor',
      renderCell: (params) => {
        const determFactorLongValue = determFactorMapping[params];
        return (
          <Typography sx={{ fontWeight: '400', fontSize: '1rem' }}>
            {determFactorLongValue}
          </Typography>
        );
      },
    },
    {
      field: 'effectiveBeginDt',
      headerName: 'Begin Date',
      renderCell: (date) => {
        return dayjs(date).format('MM-DD-YYYY');
      },
    },
    {
      field: 'effectiveEndDt',
      headerName: 'End Date',
      renderCell: (date) => {
        return dayjs(date).format('MM-DD-YYYY');
      },
    },
    {
      headerName: 'Action',
      renderCell: (params) => {
        const hideEdit = params.effectiveEndDt === '9999-12-31';
        return (
          hideEdit && (
            <IconButton onClick={() => editEligibilityData(params)}>
              <EditIcon />
            </IconButton>
          )
        );
      },
    },
  ];

  const handleDialogOpen = () => {
    setEditData(null);
    formik.resetForm();
    setOpenDialog(true);
  };

  const handleClose = () => {
    setEditData(null);
    formik.resetForm();
    setOpenDialog(false);
  };

  const handleSaveCashAssistance = async (data, editData) => {
    try {
      showLoader();
      const res = await postCaseEligibility(
        data,
        selectedItem,
        editData ?? {},
        '03',
        selectedDetermFactor
      );
      if (res.status === 200) {
        showSuccessToast('Rule Changed Successfully');
        await getEligibility01('03');
        await getEligibility(selectedDetermFactor, selectedItem);
        handleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const editEligibilityData = (params) => {
    setEditData(params);
    setOpenDialog(true);
  };

  const formik = useFormik({
    initialValues: {
      paramValue: editData ? editData.parmValue : null,
      createdBeginDt: editData ? dayjs(editData.effectiveBeginDt) : null,
      createdEndDt: editData ? dayjs(editData.effectiveEndDt) : null,
    },
    validationSchema,
    onSubmit: (value) => {
      handleSaveCashAssistance(value, editData);
    },
  });

  const addButtonDisable = selectedEligibilityData.some(
    (item) => item.effectiveEndDt === '9999-12-31' || ''
  );

  return (
    <div
      style={{ padding: '10px', maxWidth: '100%' }}
      onSubmit={formik.handleSubmit}
    >
      <Stack justifyContent="flex-end" marginBottom={2} spacing={1}>
        <Grid xs={4}>
          <Autocomplete
            value={searchVal}
            onChange={handleInputChange}
            // inputValue={searchVal}
            onInputChange={(event, newInputValue) => {
              // setInputValue(newInputValue);
            }}
            options={searchData}
            getOptionLabel={(option) => option?.longDesc ?? ''}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Select" />}
            clearIcon={null}
          />
        </Grid>
      </Stack>
      <Grid container spacing={1}>
        {/* <Grid
          item
          xs={12}
          sm={4}
          sx={{
            padding: '10px 0px !important',
            border: '1px solid var(--grey-350)',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              pl={2}
              pr={2}
            >
              <TextField
                label="Search Letter or Forms"
                fullWidth
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    height: '34px !important',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px 0px 0px 5px !important',
                  },
                }}
                value={searchVal}
                onChange={handleInputChange}
              />
              <Button
                sx={{
                  padding: '5px',
                  height: '51px',
                  borderRadius: '0px 5px 5px 0px',
                }}
                variant="contained"
                onClick={handleSearchClick}
              >
                Search
              </Button>
            </Stack>
            <Box>
              <List>
                {searchData.map((item, index) => (
                  <Fragment key={index}>
                    <ListItem
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleListItemClick(index, item || '');
                      }}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedIndex === index
                            ? 'var(--primary-light)'
                            : 'inherit',
                        color: selectedIndex === index ? 'white' : 'inherit',
                      }}
                    >
                      <ListItemText
                        primary={`${index + 1}. ${item.longDesc || ''}`}
                      />
                    </ListItem>
                    {index < searchData.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </List>
            </Box>
          </Stack>
        </Grid> */}
        <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
          <Typography
            sx={{
              fontSize: '1.1rem',
              fontWeight: '700',
              color: 'var(--grey-400)',
            }}
          >
            {selectedItem.longDesc}
          </Typography>
          <div
            style={{
              border: '2px solid var(--grey-350)',
              padding: '1rem',
              borderRadius: '6px',
              marginTop: selectedItem.longDesc ? '10px' : '0px',
            }}
          >
            <Stack
              direction="row"
              justifyContent={
                determineFactorItem?.[0]?.determFactors.length > 1
                  ? 'space-between'
                  : 'flex-end'
              }
              alignItems="center"
              pb={1}
            >
              {determineFactorItem?.[0]?.determFactors.length > 1 && (
                <div style={{ width: '180px' }}>
                  <Dropdown
                    placeholder="Determine Factor"
                    options={determineFactorItem?.[0]?.determFactors}
                    mapProps={['determFactorDesc', 'determFactor']}
                    onChange={(val) => {
                      getEligibility(val.target.value, selectedItem);
                      setSelectedDetermFactor(val.target.value);
                    }}
                    value={selectedDetermFactor}
                  />
                </div>
              )}
              <Button
                onClick={handleDialogOpen}
                disabled={!enableAddButton || addButtonDisable}
              >
                <AddCircleOutlineIcon />
                <span
                  style={{
                    fontSize: '1rem',
                    fontWeight: '600',
                    paddingLeft: '5px',
                  }}
                >
                  Add Eligibility Parameters
                </span>
              </Button>
            </Stack>
            <div
              style={{
                border: '1px solid var(--grey-300)',
                borderRadius: '6px',
              }}
            >
              <TabularView
                slots={{
                  noRowsOverlay: () => (
                    <EmptyState
                      header="Currently No Records Available"
                      icon={
                        <img
                          src={NoRecord}
                          style={{
                            height: '160px',
                            width: '250px',
                          }}
                        />
                      }
                    />
                  ),
                }}
                sx={{
                  table: {
                    width: '100%',
                  },
                  td: {
                    border: 0,
                    borderBottom: '1px solid var(--grey-300)',
                    padding: '1rem',
                  },
                  th: {
                    border: 0,
                    borderBottom: '1px solid var(--grey-300)',
                    padding: '1rem',
                    fontWeight: 'bold',
                    color: '#000000 !important',
                  },
                }}
                headerComponent={{ variant: 'h5', color: '#000' }}
                columns={columns}
                data={selectedEligibilityData}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          <Stack direction="row" spacing={4} alignItems="center" pt={4} pb={3}>
            <Field
              name="paramValue"
              formik={formik}
              label="Eligibility Param Value"
              normalizers={[formatCurrency]}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                fullWidth
                error={
                  formik.touched.paramValue && Boolean(formik.errors.paramValue)
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px !important',
                  },
                }}
              />
            </Field>
            <Field
              isDate
              name="createdBeginDt"
              formik={formik}
              label="Start Date"
            >
              <DatePicker />
            </Field>
            <Field isDate name="createdEndDt" formik={formik} label="End Date">
              <DatePicker />
            </Field>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Discard
          </Button>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            autoFocus
            sx={{ marginRight: '1rem' }}
            disabled={
              !formik.values.paramValue || !formik.values.createdBeginDt
            }
          >
            Save&Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SNAP;
