import React, { useState, useMemo } from 'react';
import FormSection from '../../../components/FormSection';
import Dropdown from '../../../components/Dropdown';
import { Grid, TextField, Stack, Button } from '@mui/material';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import useLookup from '../../../hooks/useLookup';
import { showLoader, hideLoader } from '../../../store/slices/loaderSlice';
import { insuranceMed } from '../../../utils/services/./MedicareDetails/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';

function Insurance({
  insuranceData,
  caseId,
  individualId,
  getInsurancePolicy,
}) {
  const { getOptions } = useLookup('MediCare');
  const { managedOpts, healthOpts, wilingOpts, hmoOpts, insuranceOpts } =
    useMemo(() => {
      return {
        managedOpts: getOptions('YesNo'),
        healthOpts: getOptions('HealthInsurance'),
        wilingOpts: getOptions('PremiumOK'),
        hmoOpts: getOptions('HMOType'),
        insuranceOpts: getOptions('InsuranceDropped'),
      };
    }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      healthInsurance: insuranceData?.healthInsurance ?? '',
      hmo: insuranceData?.hmo ?? '',
      managedCare: insuranceData?.managedCareYN ?? '',
      insuranceDropped: insuranceData?.insuranceDroppedYN ?? '',
      wilingPremium: insuranceData?.willingToPayYN ?? '',
      buyIn: null,
      thirdParty: null,
      liability: null,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      postThirdPart(values);
    },
  });

  const postThirdPart = async (data) => {
    try {
      showLoader();
      const res = await insuranceMed(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('SuccesFully Added Third Party Liability Details');
        getInsurancePolicy();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <FormSection mt="1rem">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            label="Health Insurance"
            formik={formik}
            name="healthInsurance"
          >
            <Dropdown
              options={healthOpts}
              placeholder="Select Insurance"
              value={formik.values.healthInsurance}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field label="HMO" formik={formik} name="hmo">
            <Dropdown
              options={hmoOpts}
              placeholder="Select HMO"
              value={formik.values.hmo}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field label="Managed Care" formik={formik} name="managedCare">
            <Dropdown
              options={managedOpts}
              placeholder="Select Care"
              value={formik.values.managedCare}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            label="Insurance Dropped"
            formik={formik}
            name="insuranceDropped"
          >
            <Dropdown
              options={insuranceOpts}
              placeholder="Select Insurance Dropped"
              value={formik.values.insuranceDropped}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            label="Wiling To Pay Premium"
            formik={formik}
            name="wilingPremium"
          >
            <Dropdown
              options={wilingOpts}
              placeholder="Select Wiling to Pay Premium"
              value={formik.values.wilingPremium}
            />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field label="Buy In" formik={formik} name="buyIn">
            <TextField placeholder="Enter Buy In" disabled />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            label="Third Party Liability"
            formik={formik}
            name="thirdParty"
          >
            <TextField placeholder="Enter Third Party Liability" disabled />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field label="Liability" formik={formik} name="liability">
            <TextField placeholder="Enter Liability" disabled />
          </Field>
        </Grid>
      </Grid>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button
          variant="contained"
          type="submit"
          onClick={formik.handleSubmit}
          disabled={!formik.dirty}
        >
          Save
        </Button>
      </Stack>
    </FormSection>
  );
}

export default Insurance;
