import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import Dropdown from '../../../components/Dropdown';
import Field from '../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useLookup from '../../../hooks/useLookup';
import usePageNav from '../../../hooks/usePageNav';
import {
  postReinitiate,
  postValidate,
} from '../../../utils/services/CaseReinitiate/apiRequests';
import useLoader from '../../../hooks/useLoader';
import { showErrorToast } from '../../../utils/services/toast';

function Reinitiate({ onClose, selectedProgramName }) {
  // console.log(selectedProgramName);
  const { caseId, navigations } = usePageNav();
  const [isvalidate, setIsValidate] = useState(null);
  const [reinitiate, setReinitiate] = useState(null);
  const [showLoader, hideLoader] = useLoader();
  const { getOptions } = useLookup('Reinitiate');

  const validationSchema = Yup.object({
    fname: Yup.string().required('Please Enter First Name'),
    lname: Yup.string().required('Please Enter Last Name'),
    dob: Yup.string().required('Please Enter First Name'),
    reason: Yup.string().when('isvalidate', {
      is: () => isvalidate,
      then: () => Yup.string().required('Please Select the Reason'),
      otherwise: () => Yup.string().notRequired(),
    }),
    date: Yup.string().when('isvalidate', {
      is: () => isvalidate,
      then: () => Yup.string().required('Please Select the Date'),
      otherwise: () => Yup.string().notRequired(),
    }),
    // reasonType: Yup.string().when(['isvalidate', 'reason'], {
    //   is: (isvalidate, reason) => isvalidate && reason === 'BR',
    //   then: () => Yup.string().required('Please Select the Date'),
    //   otherwise: () => Yup.string().notRequired(),
    // }),
  });

  const { programOpts, reasonOpts, reasonTypeOPts } = useMemo(() => {
    return {
      programOpts: getOptions('ProgramCode'),
      reasonOpts: getOptions('ReinstationReopeningReason'),
      reasonTypeOPts: getOptions('RCVMTHD'),
    };
  }, [getOptions]);
  // console.log(selectedProgramName);
  const formik = useFormik({
    initialValues: {
      caseId: caseId,
      program: selectedProgramName,
      fname: '',
      lname: '',
      dob: '',
      reason: '',
      reasonType: '',
      date: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (!formik.isValid || isvalidate === null) {
          return;
        }

        if (
          formik.values.reason &&
          // formik.values.reasonType &&
          formik.values.date
        ) {
          await postReinitiateCase(values);
          // console.log(postReinitiateCase);
          // Close the dialog after successful submission
        } else {
          postValidateCase(values);
        }
      } catch (error) {
        showErrorToast(error);
      }
    },
    // onSubmit: (values) => {
    //   // console.log(values);
    //   if (values.reason && values.reasonType && values.date) {
    //     postReinitiateCase(values);
    //   }
    //   postValidateCase(values);
    // },
  });
  const postValidateCase = async (data) => {
    try {
      showLoader();
      const res = await postValidate(data, caseId);
      if (res.status === 200 && res.data) {
        setIsValidate(res.data?.valid === 'Valid');
      } else {
        setIsValidate(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const postReinitiateCase = async (data) => {
    try {
      showLoader();
      const res = await postReinitiate(data, caseId);
      if (res.status === 200) {
        setReinitiate(res.data);
        navigations.toCaseHome(caseId);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Box>
        <FormSection label="Reinstatement Validation">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field label="Case Id" name="caseId" formik={formik}>
                <TextField
                  placeholder="Enter Case Id"
                  defaultValue={caseId}
                  disabled
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field label="Program Name" name="program" formik={formik}>
                <Dropdown
                  placeholder="Select Program"
                  options={programOpts}
                  disabled
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field label="First Name" name="fname" formik={formik} required>
                <TextField placeholder="Enter First Name" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field label="Last Name" name="lname" formik={formik} required>
                <TextField placeholder="Enter Last Name" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Date of Birth"
                name="dob"
                isDate
                formik={formik}
                required
              >
                <DatePicker placeholder="Select Date" disableFuture />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{
                  marginTop: '1.5rem',
                }}
                variant="contained"
                onClick={() => setIsValidate(formik.handleSubmit)}
                // disabled={!formik.isValid}
              >
                Validate
              </Button>
            </Grid>
            {isvalidate && (
              <>
                <Grid item xs={6}>
                  <Field label="Reason" name="reason" formik={formik} required>
                    <Dropdown
                      options={reasonOpts}
                      placeholder="Select Reason"
                    />
                  </Field>
                </Grid>
                {formik.values.reason === 'BR' && (
                  <Grid item xs={6}>
                    <Field
                      label="Reason Type"
                      name="reasonType"
                      formik={formik}
                      required
                    >
                      <Dropdown
                        options={reasonTypeOPts}
                        placeholder="Select Reason Type"
                      />
                    </Field>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Field
                    label="Date"
                    name="date"
                    formik={formik}
                    isDate
                    required
                  >
                    <DatePicker placeholder="Select Date" disablePast />
                  </Field>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" color="red">
                    * MA Programs can be reinstated only before 60 days of
                    program status date for 'Tardy re-det returned', 30 days for
                    'Closed/denied in error', and 180 days for 'Verifications
                    returned'. CA and SNAP programs can only be reinstated
                    before 30 days for 'Closed/denied in error' reason.
                  </Typography>
                </Grid>
              </>
            )}
            {isvalidate === false && (
              <>
                <Grid item xs={8}>
                  <Typography variant="h6" color="red">
                    Provided DOB, First and Last Name does not match with the
                    record in System.
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'flex-end'}
            mt={2}
          >
            <Button variant="outlined" onClick={onClose}>
              Discard
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={() => setReinitiate(formik.handleSubmit)}
              // disabled={formik.isValid}
            >
              Save
            </Button>
          </Stack>
        </FormSection>
      </Box>
    </>
  );
}

export default Reinitiate;
