export const GET_ABAWD_TABLEDATA =
  'eeapi/application-process/inquiry/time-clock/{0}/clk-type/AB/caseid/{1}';

export const GET_CHILDUNDERONE_TABLEDATA =
  'eeapi/application-process/inquiry/time-clock/{0}/clk-type/CK/caseid/{1}';

export const GET_TDAP1_TABLEDATA =
  'eeapi/application-process/inquiry/time-clock/{0}/clk-type/TD/caseid/{1}';

export const GET_TIMEONASSISTANCE =
  'eeapi/application-process/inquiry/time-clock/{0}/clk-type/TC/caseid/{1}';

export const POST_TIMELIMIT_RECORD = `eeapi/application-process/inquiry/add-time-clock`;
