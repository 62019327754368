import { Tab, Tabs, Stack, Button, Paper, Grid } from '@mui/material';
import { useState, useMemo } from 'react';
import ProviderSearch from './ProviderSearch';
import CustomerSearch from './CustomerSearch';
import useLookup from '../../hooks/useLookup';
import usePageNav from '../../hooks/usePageNav';
import usePage from '../../hooks/usePage';

function Inquiry({ id }) {
  const { navProps } = usePage(id);
  const { getOptions, getLkpMatch, lkpRaw, lkpFlatten } = useLookup('Inquiry');
  const [activeTab, setActiveTab] = useState(0);
  const { caseId, navigations } = usePageNav();
  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const { SuffixOpts, RaceOpts, CountyOpts, StateOpts, TypeOpts } =
    useMemo(() => {
      return {
        SuffixOpts: getOptions('Suffix'),
        RaceOpts: getOptions('Race'),
        CountyOpts: getOptions('County'),
        StateOpts: getOptions('State'),
        TypeOpts: getOptions('VendorType'),
      };
    }, [getOptions]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };
  return (
    <Paper sx={{ height: '100%' }}>
      <Tabs
        onChange={onTabChange}
        value={activeTab}
        // variant="fullWidth"
        // variant="scrollable"

        centered
      >
        <Tab label="Customer Search" style={{ textTransform: 'capitalize' }} />
        <Tab label="Provider Search" style={{ textTransform: 'capitalize' }} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <CustomerSearch getLkpMatch={getLkpMatch} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <ProviderSearch
          TypeOpts={TypeOpts}
          StateOpts={StateOpts}
          CountyOpts={CountyOpts}
        />
      </TabPanel>
    </Paper>
  );
}

export default Inquiry;
