import React, { useEffect, useMemo, useState } from 'react';
import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import CustodialParent from './CustodialParent';
import DemographicModal from './Demographic/DemographicModal';
import usePageNav from '../../hooks/usePageNav';
import { hideLoader, showLoader } from '../../store/slices/loaderSlice';
import { showErrorToast } from '../../utils/services/toast';
import { getDemographic } from '../../utils/services/NonCustodial/apiRequests';
import { filter } from 'lodash';
import NoRecord from '../../assets/icons/falmily-child.svg';

export default function NonCustodial() {
  const [showAddMem, setShowAddMem] = useState(false);
  const [showEditMember, setEditMember] = useState(false);
  const [childData, setChildData] = useState([]);
  const { caseId } = usePageNav();
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { houseHoldMembers, hasHoh } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  }, [houseMembers]);

  const addMember = () => {
    setShowAddMem(!showAddMem);
  };

  const editMember = () => {
    setEditMember(!showEditMember);
  };

  const toggleClose = () => {
    setShowAddMem(false);
    setEditMember(false);
  };

  useEffect(() => {
    if (caseId) {
      getDemographicDet();
    }
  }, [caseId]);

  const getDemographicDet = async () => {
    try {
      showLoader();
      const res = await getDemographic(caseId);
      if (res.status === 200) {
        setChildData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const parentIds = childData.map((item) => item?.absentParentId);
  console.log(parentIds, '000000');

  return (
    <>
      {showAddMem && (
        <DemographicModal
          open={showAddMem}
          toggleClose={toggleClose}
          getDemographicDet={getDemographicDet}
        />
      )}
      <PageContainer title="Non-Custodial Parent">
        <SelectableView
          data={childData}
          loading={fetchingMembers}
          loadingContent={fetchingMembers}
          showAdd={true}
          showEdit={true}
          showDelete={false}
          addDataMsg="Add Non-Custodial Member"
          noDataMsg="No Non-Custodial member found!"
          image={
            <img
              src={NoRecord}
              style={{
                height: '160px',
                width: '250px',
              }}
            />
          }
          onAdd={() => addMember()}
          onEdit={() => editMember()}
          getId={(params) => params.absentParentId}
          getAge={(params) => {
            return params?.abDemographicInfo?.dob;
          }}
          getName={(params) => {
            const { firstName, lastName } =
              params?.abIdentification?.absentParentName ?? {};
            return `${firstName} ${lastName}`;
          }}
          renderMenuButton={(params, options) => {
            return (
              <MemberMenuButton
                member={{ ...params, dob: params.abDemographicInfo?.dob }}
                options={options}
              />
            );
          }}
        >
          {(selected) => {
            return (
              <>
                <CustodialParent
                  selected={selected}
                  childData={childData}
                  absentParentId={selected?.absentParentId}
                  getDemographicDet={getDemographicDet}
                  // absentParentId={absentParentId}
                />
                {showEditMember && (
                  <DemographicModal
                    isEditData
                    open={showEditMember}
                    toggleClose={toggleClose}
                    childData={childData}
                    absentParentId={selected?.absentParentId}
                    getDemographicDet={getDemographicDet}
                  />
                )}
              </>
            );
          }}
        </SelectableView>
      </PageContainer>
    </>
  );
}
