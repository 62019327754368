import React, { useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import styles from '../../index.module.css';

export default function CPAuthRep({
  data,
  getLkpMatch,
  hohMemberData,
  handleCheckbox,
}) {
  const getMemberNameByIndividualId = useCallback(
    (individualId) => {
      let result = hohMemberData?.find(
        (item) => item.dummyIndividualId === individualId
      );
      return `${result?.firstName ?? ''} ${result?.lastName ?? ''}`;
    },
    [data, hohMemberData]
  );
  const formatAddress = useCallback(
    (addressLine1, addressLine2, city, state, zipcode) => {
      const parts = [addressLine1, addressLine2, city, state, zipcode].filter(
        Boolean
      );
      return parts.join(', ');
    },
    [data]
  );

  return (
    <>
      {map(data, (item, index) => {
        const {
          relationshipCd,
          providerName,
          address,
          personName,
          phoneNumber,
          email,
        } = item?.authorizedRepresentative ?? {};
        const { addressLine1, addressLine2, city, state, zipcode } =
          address ?? {};
        const { firstName, lastName } = personName ?? {};
        const { phoneNumber: arPhoneNumber, phoneNumberType } =
          phoneNumber ?? {};
        return (
          <Stack direction="row" pl={1}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Checkbox />}
              onChange={(event) => handleCheckbox(event, index)}
              checked={item?.importSelected}
            />
            <div className={styles.card}>
              <TableContainer>
                <Table
                  sx={{
                    td: {
                      padding: 1.5,
                      paddingBottom: '0.5rem',
                      border: 0,
                    },
                  }}
                >
                  <TableBody className={styles.tableBody}>
                    <TableRow
                      sx={{ borderBottom: '1px solid var(--grey-350)' }}
                    >
                      <TableCell>
                        <Typography variant="h6grey">
                          Individual Name
                        </Typography>
                      </TableCell>
                      <TableCell colSpan={3}>
                        <Typography variant="h6">
                          {getMemberNameByIndividualId(item?.individualId) ??
                            ''}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          What is the Authorized Representative's relationship
                          to you?
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch('AuthRepRelation', relationshipCd) ??
                            'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {providerName ??
                            `${firstName ?? ''} ${lastName ?? ''}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Address</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {formatAddress(
                            addressLine1,
                            addressLine2,
                            city,
                            state,
                            zipcode
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Phone Number</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {arPhoneNumber ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">
                          Phone Number Type
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {getLkpMatch('PhoneType', phoneNumberType) ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6grey">Email</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2">
                          {email ?? 'NA'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Stack>
        );
      })}
    </>
  );
}
