import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { useFormik } from 'formik';
import useLookup from '../../hooks/useLookup';
import useLoader from '../../hooks/useLoader';
import { getActiveWorkers } from '../../utils/services/apiRequests';
import { showErrorToast } from '../../utils/services/toast';
import { map } from 'lodash';

const ViewTicket = () => {
  const { getOptions, lkpFlatten } = useLookup('AlertSearch');
  const [showLoader, hideLoader] = useLoader();
  const [workerName, setWoekerName] = useState([]);

  useEffect(() => {
    getWorkerList();
  }, []);

  const getWorkerList = async () => {
    try {
      showLoader();
      const res = await getActiveWorkers();
      if (res.status === 200) {
        setWoekerName(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      worker: '',
    },
    onSubmit: (value) => {},
  });

  const nameList = useMemo(() => {
    return map(workerName, ({ uniqueId, firstName, lastName }) => {
      const label = `${firstName} ${lastName}`;
      return {
        label,
        value: uniqueId,
      };
    });
  }, [workerName]);

  const handleClear = () => {
    formik.handleReset();
  };

  return (
    <Box p="1rem" m="1rem" component={Paper}>
      <Typography mb="1rem" variant="h2">
        JIRA Ticket Status
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <Field name="programCd" formik={formik} label="Worker">
            <Dropdown options={nameList} placeholder="Worker" />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="center" spacing={1} mt={2.7}>
            <Button type="reset" onClick={handleClear} variant="outlined">
              Clear
            </Button>
            <Button
              type="submit"
              // disabled={!formik.isValid || !formik.dirty}
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Search
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewTicket;
