export const addEducationDetailsPayload = (data, dirtyStatus) => {
  const {
    recordId,
    effectiveBeginDate,
    effectiveEndDate,
    studentStatus,
    verification,
    levelofeducation,
    lastgradecompleted,
    schoolname,
    schoolnumber,
    gradDate,
    schoolverification,
    goodstanding,
    standingverification,
    addressline1,
    addressline2,
    city,
    state,
    zipcode,
  } = data;

  return [
    {
      dirtyStatus: dirtyStatus,
      educationLevel: levelofeducation,
      educationalProgramInd: '',
      effectiveBeginDate: effectiveBeginDate,
      effectiveEndDate: effectiveEndDate ? effectiveEndDate : null,
      goodStanding: goodstanding,
      goodStandingVerification: {
        verificationSourceCode: standingverification,
      },
      graduationDate: gradDate,
      graduationDateVerification: {
        verificationSourceCode: schoolverification,
      },
      highestGrade: lastgradecompleted,
      programBeginDate: null,
      programEndDate: null,
      recordId: recordId,
      schoolAddress: {
        addressLine1: addressline1,
        addressLine2: addressline2,
        city: city,
        zipcode: zipcode,
        state: state,
      },
      schoolName: schoolname,
      schoolNumber: schoolnumber,
      studentStatus: studentStatus,
      studentStatusVerification: {
        verificationSourceCode: verification,
        documentDetails: null,
      },
    },
  ];
};
