import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import PolicyDetails from './PolicyDetails';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from '../../../components/ActionButtons';
import { filter } from 'lodash';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import { useParams } from 'react-router-dom';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import { maxLength } from '../../../utils/normalizers';

export default function InsurancePolicy({
  editData,
  insertAPI,
  getOptions,
  createNew,
  editApi,
  toggleClose,
  lkpRaw,
  selected,
}) {
  const [policy, setPolicy] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [editRecord, setEditRecord] = useState('');
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const params = useParams();

  const validationSchema = Yup.object({
    type: Yup.string().required('Please select type'),
    institution: Yup.string().required('Please enter institution name '),
    policyNumber: Yup.string().required('Please enter pulicy number '),
    verified: Yup.string().required('Please select verification'),
  });

  useEffect(() => {
    if (policyData.length === 0 && editData) {
      setPolicyData((prev) => [...prev, ...editData?.assetAmounts]);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      type: editData?.assetDetailType ?? '',
      policyNumber: editData?.policyNumber ?? '',
      institution: editData?.institutionName ?? '',
      assetType: 2,
      verified: editData?.assetVerification?.verificationSourceCode ?? '',
      recordId: editData?.recordId ?? '',
      ownedByHouseholdMemberYN: editData?.ownedByHouseholdMemberYN ?? 'N',
      jointOwnerYN: editData?.jointOwnerYN ?? 'N',
    },
    validationSchema,
    onSubmit: (value) => {
      if (createNew) {
        insertAPI({ value, policyData });
      } else {
        editApi({ value, policyData });
      }
    },
  });
  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const { verificationOpts, insuranceOpts } = useMemo(() => {
    return {
      verificationOpts: getOptions('CLLIQASSETAMTVERCD'),
      insuranceOpts: getOptions('Insurance'),
    };
  }, [getOptions]);

  const AddInstitutionDetails = () => {
    setPolicy(true);
  };
  const toggleClosePopup = () => {
    setPolicy(false);
  };

  const policyColumns = useMemo(
    () => [
      {
        field: 'effectiveBeginDate',
        headerName: 'Start Date',
        flex: 1,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'Close Date',
        flex: 1,
      },
      {
        field: 'faceAmount',
        headerName: 'Face Amount',
        flex: 1,
        renderCell: (params) => {
          return <Typography>${params.row.faceAmount}</Typography>;
        },
      },
      {
        field: 'cashAmount',
        headerName: 'Cash Amount',
        flex: 1,
        renderCell: (params) => {
          return <Typography>${params.row.cashAmount}</Typography>;
        },
      },

      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editRec(params?.row)}
                onDelete={() => deleteRecord(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [policyData]
  );

  const deleteRecord = (data) => {
    const newRecord = filter(
      policyData,
      (val) => val.recordId !== data.recordId
    );
    setPolicyData(newRecord);
  };
  const editRec = (data) => {
    setEditRecord(data);
    setPolicy(true);
  };
  return (
    <div>
      <Dialog
        open={policy}
        onClose={toggleClosePopup}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h3">Policy Details</Typography>
        </DialogTitle>
        <DialogContent>
          <PolicyDetails
            policyData={policyData}
            editRecord={editRecord}
            setPolicyData={setPolicyData}
            toggleClosePopup={toggleClosePopup}
          />
        </DialogContent>
      </Dialog>
      {/* editData */}
      <FormSection label={'Account Details'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name="type" formik={formik} label="Type" required>
              <Dropdown options={insuranceOpts} placeholder="Select Type" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="policyNumber"
              formik={formik}
              label="Policy Number"
              required
              normalizers={[maxLength(14)]}
            >
              <TextField placeholder="Enter Policy Number" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="institution"
              formik={formik}
              label="Institution Name"
              required
              normalizers={[maxLength(30)]}
            >
              <TextField placeholder="Enter Institution Name" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="verified"
              formik={formik}
              label="Verification"
              required
            >
              <Dropdown
                options={verificationOpts}
                placeholder="Select Verification"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('ownedByHouseholdMemberYN')}
                  checked={formik.values.ownedByHouseholdMemberYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      'ownedByHouseholdMemberYN',
                      checked ? 'Y' : 'N'
                    );
                  }}
                />
              }
              label="Owned By Household Member"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('jointOwnerYN')}
                  checked={formik.values.jointOwnerYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('jointOwnerYN', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Jointly Owner"
            />
          </Grid>
          <Grid item xs>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              mt={2}
              mb={2}
            >
              <Button
                variant="outlined"
                startIcon={<UploadFileIcon />}
                onClick={handleOpenDocModal}
              >
                Upload Files
              </Button>
              <Button onClick={AddInstitutionDetails} startIcon={<AddIcon />}>
                Add Asset
              </Button>
            </Stack>
            <div style={{ width: '100%' }}>
              <DataGrid
                getRowId={(rows) => rows.recordId}
                rows={policyData ?? []}
                columns={policyColumns}
                autoHeight
                hideFooter
              />
            </div>
          </Grid>
        </Grid>

        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={() => toggleClose()}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={policyData.length === 0}
          >
            Save
          </Button>
        </Stack>
      </FormSection>
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}
