import React, { useRef, useMemo } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { get, map } from 'lodash';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';

const HistoryDialog = ({
  open,
  setOpen,
  onClose,
  historyData,
  lkpFlatten,
}) => {

  const columns = [
    { field: 'pgmCd', headerName: 'Program', flex: 1 },
    {
      field: 'issuanceNumber',
      headerName: 'Issuance #',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'agHeadName',
      headerName: 'AG Head',
      flex: 1,
      sortable: false,
      align: 'end',
      disableColumnMenu: true,
    },
    {
      field: 'issncMonth',
      headerName: 'Issuance Month',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issncStsCd',
      headerName: 'Issuance Status',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issncStsDt',
      headerName: 'Issuance Date',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issncAmount',
      headerName: 'Issuance Amount',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issncMethod',
      headerName: 'Issuance Method',
      flex: 1,
      align: 'end',
      sortable: false,
      disableColumnMenu: true,
    },
  ];

      const rows = useMemo(
        () =>
          map(historyData, (item, index) => {
            const programCd = get(item, 'programCd', '');
            const issuanceStatusCd = get(item, 'issncStsCd', '');
            return {
              ...item,
              id: index + 1,
              programCd: lkpFlatten?.RequestedPrograms?.[programCd] || '',
              issncMonth: dayjs(get(item, 'issncMonth', '')).format('MM-YYYY'),
              issncStsCd: lkpFlatten?.Issuancstatus?.[issuanceStatusCd] || '',
            };
          }),
        [historyData, lkpFlatten]
      );

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="history"
      aria-describedby="historyDescription"
    >
      <DialogTitle id="history">Issuance Details History</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '15px',
              fontWeight: '600',
            },
          }}
          loading={false}
          rowsPerPageOptions={[5, 10, 15]}
          checkboxSelection={false}
          getRowId={(row) => row.biId}
          disableRowSelectionOnClick={true}
          disableColumnMenu={true}
          slots={{
            noRowsOverlay: () => (
              <h1 style={{ textAlign: 'center' }}>No Data</h1>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} variant="outlined">
          Discard
        </Button> */}
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryDialog;
