import {
  Button,
  Typography,
  TextField,
  IconButton,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import FormGroup from '../../../components/FormGroup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { maxLength, name } from '../../../utils/normalizers';

function AddStriker({
  open,
  handleClose,
  onSaveStriker,
  editData,
  addStriker,
  dob,
}) {
  const validationSchema = Yup.object({
    effectiveBeginDate: Yup.string().when('strikerStatus', {
      is: (strikerStatus) => {
        return strikerStatus === 'Y';
      },
      then: () => Yup.string().required('Begin Date is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    effectiveEndDate: Yup.string().when('strikerStatus', {
      is: (strikerStatus) => {
        return strikerStatus === 'Y';
      },
      then: () => Yup.string().required('End Date is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      recordId: editData?.recordId ?? '',
      effectiveBeginDate: editData?.effectiveBeginDate ?? '',
      effectiveEndDate: editData?.effectiveEndDate ?? '',
      employerName: editData?.employerName ?? '',
      relationToStriker: '',
      strikerStatus: editData?.strikerStatus ?? '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (addStriker) {
        onSaveStriker(values, 'insert');
      } else {
        onSaveStriker(values, 'update');
      }
    },
  });

  const onBeginDateChange = (date) => {
    const beginDate = dayjs(date).format('YYYY-MM-DD');
    formik.setFieldValue('beginDate', beginDate);
  };

  const onEndDateChange = (date) => {
    const endDate = dayjs(date).format('YYYY-MM-DD');
    formik.setFieldValue('endDate', endDate);
  };

  const { strikerStatus } = formik.values;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '50vw',
            height: '70vh',
          },
        },
      }}
    >
      <DialogTitle sx={{ fontSize: 20 }}>
        {addStriker
          ? 'Add Striker status Information'
          : 'Edit Striker status Information'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid item xs={12}>
          <Grid>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="h5"
                    marginTop="0.3rem"
                    marginBottom="1rem"
                  >
                    Status
                  </Typography>
                  <RadioGroup
                    {...formik.getFieldProps('strikerStatus')}
                    row
                    defaultValue={''}
                  >
                    <FormControlLabel
                      name="strikerStatus"
                      value={'Y'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      control={<Radio />}
                      label="Yes"
                    />

                    <FormControlLabel
                      name="strikerStatus"
                      value={'N'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </Grid>

                {strikerStatus === 'Y' && (
                  <Grid item xs={6}>
                    <Field
                      name="employerName"
                      formik={formik}
                      label="Employer Name"
                      normalizers={[maxLength(30), name]}
                    >
                      <TextField placeholder="Enter Employer Name" />
                    </Field>
                  </Grid>
                )}
                {strikerStatus === 'Y' && (
                  <Grid item xs={6} sx={{ mb: '1rem' }}>
                    <Field
                      name="effectiveBeginDate"
                      label="Begin Date"
                      isDate
                      required
                      formik={formik}
                    >
                      <DatePicker
                        minDate={dayjs(dob)}
                        disableFuture
                        value={dayjs(formik.values.beginDate)}
                        onChange={onBeginDateChange}
                      />
                    </Field>
                  </Grid>
                )}
                {strikerStatus === 'Y' && (
                  <Grid item xs={6}>
                    <Field
                      name="effectiveEndDate"
                      label="End Date"
                      isDate
                      required
                      formik={formik}
                    >
                      <DatePicker
                        minDate={
                          dayjs(formik.values.effectiveBeginDate) || dayjs()
                        }
                        disableFuture
                        requried
                        value={dayjs(formik.values.endDate)}
                        onChange={onEndDateChange}
                      />
                    </Field>
                  </Grid>
                )}
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save & Close{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddStriker;
