import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import cx from 'classnames';
import styles from './index.module.css';

function Copyable({ children, title, successMessage, content }) {
  const [showCopied, setCopied] = useState(false);

  if (!navigator?.clipboard) {
    return children;
  }

  const onCaseIdCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(content);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Tooltip title={title}>
      <div
        role="button"
        tabIndex={-1}
        onClick={onCaseIdCopy}
        label-scs-message={successMessage}
        className={cx(styles.copyContent, { [styles.show]: showCopied })}
      >
        {children}
      </div>
    </Tooltip>
  );
}

Copyable.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  copyText: PropTypes.string,
  content: PropTypes.string,
};

Copyable.defaultProps = {
  children: null,
  title: 'Click to copy',
  copyText: '',
  successMessage: 'Copied',
  content: '',
};

export default Copyable;
