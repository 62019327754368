import React, { useEffect } from 'react';
import {
  Button,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Divider,
  Stack,
  Box,
  FormHelperText,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
}));

const UnearnedIncomeApplicationForm = ({
  lkpRaw,
  isAdd,
  member,
  formik,
  selectedData,
}) => {
  const classes = useStyles();

  const handleDatePickerChange = (name, date) => {
    formik.setFieldValue(`${name}`, dayjs(date).format('YYYY-MM-DD'));
  };

  const validateIsError = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) && get(formik, `touched.${fieldName}`);

    return !!hasErrors;
  };

  const validateIsErrorObj = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.verificationCd.${fieldName}`) &&
      get(formik, `touched.verificationCd.${fieldName}`);

    return !!hasErrors;
  };

  useEffect(() => {
    if (!isAdd) {
      formik.setValues({
        ...selectedData,
        effectiveBeginDt: null,
        effectiveEndDt: null,
      });
    }
  }, [isAdd]);

  return (
    <>
      <Box m={2}>
        <Typography variant="h6">Payment Dates</Typography>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mt={1} mb={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateIsError(
                      formik,
                      'appliedDt'
                    ),
                  })}
                  label="Applied Date *"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    formik.values.appliedDt
                      ? dayjs(formik.values.appliedDt)
                      : null
                  }
                  onChange={(date) => handleDatePickerChange('appliedDt', date)}
                />
              </DemoContainer>
              {validateIsError(formik, 'appliedDt') && (
                <FormHelperText sx={{ ml: '10px' }} error>
                  {formik.errors.appliedDt}
                </FormHelperText>
              )}
            </Box>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsError(formik, 'subTypeCd'),
              })}
            >
              <InputLabel id="sub-type">Sub Type *</InputLabel>
              <Select
                labelId="sub-type"
                id="select-sub-type"
                label="Sub Type *"
                size="small"
                name="subTypeCd"
                value={formik.values.subTypeCd}
                onChange={formik.handleChange}
              >
                {lkpRaw.AppliedUnearnedIncomeType.map((item) => (
                  <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                    {item.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsError(formik, 'subTypeCd') && (
                <FormHelperText error>{formik.errors.subTypeCd}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsErrorObj(
                  formik,
                  'verificationSourceCode'
                ),
              })}
            >
              <InputLabel id="verification">Verification *</InputLabel>
              <Select
                labelId="verification"
                id="select-verification"
                label="Verification *"
                name="verificationCd.verificationSourceCode"
                value={formik.values.verificationCd.verificationSourceCode}
                onChange={formik.handleChange}
              >
                {lkpRaw.AppliedIncomeVerification.map((item) => (
                  <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                    {item.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsErrorObj(formik, 'verificationSourceCode') && (
                <FormHelperText error>
                  {formik.errors.verificationCd?.verificationSourceCode}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1} mb={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="Expected Date"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    formik.values.expectedDt
                      ? dayjs(formik.values.expectedDt)
                      : null
                  }
                  onChange={(date) =>
                    handleDatePickerChange('expectedDt', date)
                  }
                />
              </DemoContainer>
            </Box>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsError(
                  formik,
                  'appliedUnearnedIncomeStatusCd'
                ),
              })}
            >
              <InputLabel id="status">Status *</InputLabel>
              <Select
                labelId="status"
                id="select-status"
                label="Status *"
                name="appliedUnearnedIncomeStatusCd"
                value={formik.values.appliedUnearnedIncomeStatusCd}
                onChange={formik.handleChange}
              >
                {lkpRaw.AppliedUnearnedIcomeStatus.map((item) => (
                  <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                    {item.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsError(formik, 'appliedUnearnedIncomeStatusCd') && (
                <FormHelperText sx={{ ml: '10px' }} error>
                  {formik.errors.appliedUnearnedIncomeStatusCd}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UnearnedIncomeApplicationForm;
