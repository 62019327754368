import React, { useState, useMemo } from 'react';
import {
  Button,
  Stack,
  Grid,
  TextField,
  Typography,
  IconButton,
  Collapse,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { searchIssuance } from '../../utils/services/Issuance/apiRequests';
import dayjs from 'dayjs';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import useLoader from '../../hooks/useLoader';
import { useParams, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import usePageNav from '../../hooks/usePageNav';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowup.svg';
;

const SearchMode = ( { setSearchData, getOptions }) => {
  const [open, setOpen] = useState(false);
  const { caseId } = usePageNav();
  const navigate = useNavigate();
  const [showLoader, hideLoader] = useLoader();

    const {
      RequestedOpts,
      IssuanceOpts,
      aidcdOpts,
      ProgramOpts,
      IssuanceStatusOpts,
      ProgramStsOpts,
    } = useMemo(() => {
      return {
        RequestedOpts: getOptions('RequestedPrograms'),
        IssuanceOpts: getOptions('IssuanceMethod'),
        ProgramOpts: getOptions('Program'),
        aidcdOpts: getOptions('aidcd'),
        IssuanceStatusOpts: getOptions('Issuancstatus'),
        ProgramStsOpts: getOptions('ProgramStatus'),
      };
    }, [getOptions]);


  const formik = useFormik({
    initialValues: {
      caseId: caseId ?? '',
      caseBIDailyBenefitsId: null,
      individualId: null,
      issuanceMethodCd: null,
      issuanceStatusCd: null,
      issuanceBeginMonth: null,
      issuanceEndMonth: null,
      checkNumber: null,
      programCd: null,
      programSubType: null,
      vendorId: null,
      bnftIssncNum: null,
      issuanceDate: null,
    },
    onSubmit: async (values) => {
      const {issuanceDate, ...restValues} = values;
      issuanceSearchData(restValues);
    },
  });


  const issuanceSearchData = async (values) => {
    const payload = {
      ...values,
       ssn: {
            dirtyStatus: "",
            soSecPart1: "",
            soSecPart2: "",
            soSecPart3: "",
            ssnVerification: {
                dirtyStatus: "",
                messages: [],
                verificationDocumentCd: "",
                verificationRequestedYN: "",
                documentDetails: [],
                verificationType: "",
                verificationSourceCode: "",
                verifiedYN: "",
                dueDate: null,
                automatedVerificationYN: ""
            }
        },
    };
    try {
      showLoader();
      const res = await searchIssuance(payload);
      if (res.status === 200) {
        setSearchData(res.data.caseBIBaseModelList);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

    const onChangeDateChange = (date, name) => {
      if (name === 'issuanceDate') {
        formik.setFieldValue(name, dayjs(date).format('YYYY-MM-DD'));
      } else {
        formik.setFieldValue(name, dayjs(date).format('YYYYMM'));
      }
    };

  
  const handleClear = () => {
    formik.resetForm();
    formik.setFieldValue('caseId', '');
  };

  return (
    <>
      {open && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">Advance Search</Typography>
          <IconButton aria-label="arrow-up" onClick={() => setOpen(false)}>
            <ArrowUp />
          </IconButton>
        </Stack>
      )}
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={3}>
          <Field name="caseId" label="Search" formik={formik}>
            <TextField placeholder="Search by Case ID " />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="programCd" label="Program" formik={formik}>
            <Dropdown options={ProgramOpts} placeholder="Select Program" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field
            name="issuanceStatusCd"
            label="Issuance Status"
            formik={formik}
          >
            <Dropdown
              options={IssuanceStatusOpts}
              placeholder="Select Status"
            />
          </Field>
        </Grid>
        {!open ? (
          <Grid item xs={3}>
            <Button
              variant="contained"
              sx={{ height: '40px' }}
              fullWidth
              startIcon={<SearchIcon />}
              onClick={formik.handleSubmit}
            >
              Search
            </Button>
          </Grid>
        ) : (
          <Grid item xs={3}>
            <Field name="vendorId" label="Vendor" formik={formik}>
              <TextField placeholder="Enter Vendor Id" />
            </Field>
          </Grid>
        )}
      </Grid>
      <Collapse in={open} style={{ transitionDuration: '400ms' }}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={3}>
            <Field
              name="checkNumber"
              label="Issuance/Check Number"
              formik={formik}
            >
              <TextField placeholder="Enter District Office" />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="issuanceBeginMonth"
              label="Issuance Begin Month"
              formik={formik}
              customHandler
            >
              <DatePicker
                name="issuanceBeginMonth"
                value={
                  formik.values.issuanceBeginMonth
                    ? dayjs(formik.values.issuanceBeginMonth)
                    : null
                }
                disableFuture
                views={['month', 'year']}
                onChange={(date) =>
                  onChangeDateChange(date, 'issuanceBeginMonth')
                }
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="issuanceEndMonth"
              label="Issuance End Month"
              formik={formik}
              customHandler
            >
              <DatePicker
                name="issuanceEndMonth"
                value={
                  formik.values.issuanceEndMonth
                    ? dayjs(formik.values.issuanceEndMonth)
                    : null
                }
                disableFuture
                views={['month', 'year']}
                onChange={(date) =>
                  onChangeDateChange(date, 'issuanceEndMonth')
                }
              />
            </Field>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="issuanceDate"
              label="Issuance Date"
              formik={formik}
              customHandler
            >
              <DatePicker
                name="issuanceDate"
                value={
                  formik.values.issuanceDate
                    ? dayjs(formik.values.issuanceDate)
                    : null
                }
                disableFuture
                onChange={(date) => onChangeDateChange(date, 'issuanceDate')}
              />
            </Field>
          </Grid>
        </Grid>
      </Collapse>
      {!open ? (
        <Stack direction="row" mt={2} justifyContent="flex-end">
          <Typography
            variant="h6"
            color="primary"
            onClick={() => setOpen(true)}
            sx={{ cursor: 'pointer' }}
          >
            Advance Search
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction="row"
          spacing={2}
          mt={3}
          sx={{ justifyContent: 'flex-end' }}
        >
          <Button variant="outlined" onClick={handleClear}>
            Clear
          </Button>
          <Button variant="contained" onClick={formik.handleSubmit}>
            Search
          </Button>
        </Stack>
      )}
    </>
  );
};

export default SearchMode;
