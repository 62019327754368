import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import PageContainer from '../../components/PageContainer';
import useLookup from '../../hooks/useLookup';
import { find, includes, isEmpty, map, reject } from 'lodash';
import Dropdown from '../../components/Dropdown';
import Field from '../../components/Field';
import YesNoOption from '../../components/YesNoOption';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import { getFullName } from '../../utils/helpers';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import {
  getHouseholdDeclartion,
  saveHouseholdDeclrationReq,
} from '../../utils/services/householdDeclaration/apiRequests';
import usePageNav from '../../hooks/usePageNav';
import useLoader from '../../hooks/useLoader';
import { Refresh } from '@mui/icons-material';
import UserSelect from '../../components/UserSelect';
import usePage from '../../hooks/usePage';

function HouseholdDeclaration({ id }) {
  const { caseId } = usePageNav();
  const { navProps } = usePage(id);
  const [showLoader, hideLoader] = useLoader();
  const { lkpRaw = {} } = useLookup('HouseholdDeclaration');
  const [prevRecordId, setPrevRecordId] = useState('');
  const { houseMembers, fetchingHouseMembers } =
    useSelector(selectHouseMembers);
  const questions = lkpRaw?.CPFelonyConvictionType;

  const validationSchema = yup.object({
    houseHoldDeclarationQstns: yup.array(
      yup.object({
        qntsInd: yup.string().required(),
        individualIds: yup.array().when('qntsInd', {
          is: (qntsInd) => qntsInd === 'Y',
          then: () => yup.array().min(1),
          otherwise: () => yup.array().notRequired(),
        }),
      })
    ),
  });

  const onSave = async (values) => {
    const houseHoldDeclarationQstns = map(
      values.houseHoldDeclarationQstns,
      ({ dirtyStatus, individualIds, qntsInd, questionCd, recordId }) => ({
        dirtyStatus,
        individualIds: qntsInd === 'Y' ? individualIds : [],
        questionCd,
        recordId,
        qntsInd,
      })
    );

    const payload = {
      benefitsCaseId: caseId,
      dirtyStatus: prevRecordId ? 'update' : 'insert',
      recordId: prevRecordId ? prevRecordId : undefined,
      houseHoldDeclarationQstns,
    };

    try {
      showLoader();
      const res = await saveHouseholdDeclrationReq(caseId, payload);
      showSuccessToast('Successfully Added Household Declaration');
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      houseHoldDeclarationQstns: [],
    },
    validationSchema,
    onSubmit: (values) => {
      onSave(values);
    },
  });

  const fetchHouseholdDeclaration = async (initHouseHoldDeclarationQstns) => {
    try {
      const res = await getHouseholdDeclartion(caseId);

      if (res.status === 200) {
        const { houseHoldDeclarationQstns, recordId } = res.data;
        if (recordId) {
          setPrevRecordId(recordId);
        }
        if (houseHoldDeclarationQstns) {
          const prevHouseHoldDeclarationQstns = map(
            houseHoldDeclarationQstns,
            ({ individualIds = [], questionCd, qntsInd }) => {
              const prevQuestion = find(initHouseHoldDeclarationQstns, {
                questionCd,
              });
              return {
                ...prevQuestion,
                individualIds,
                questionCd,
                qntsInd,
                dirtyStatus: recordId ? 'update' : 'insert',
              };
            }
          );

          formik.setValues({
            houseHoldDeclarationQstns: prevHouseHoldDeclarationQstns,
          });
        }
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      //
    }
  };

  const setInitivalValues = () => {
    const houseHoldDeclarationQstns = map(
      questions,
      ({ lookupLongVal: question, lkpDesc: questionCd, recordId = 0 }) => {
        return {
          question,
          dirtyStatus: 'insert',
          individualIds: [],
          qntsInd: '',
          questionCd,
          recordId,
        };
      }
    );

    formik.setValues({ houseHoldDeclarationQstns });
    return houseHoldDeclarationQstns;
  };

  useEffect(() => {
    if (
      isEmpty(formik.values.houseHoldDeclarationQstns) &&
      !isEmpty(questions)
    ) {
      const houseHoldDeclarationQstns = setInitivalValues();
      fetchHouseholdDeclaration(houseHoldDeclarationQstns);
    }
  }, [formik, questions]);

  const membersOpts = map(houseMembers, ({ personName = {}, individualId }) => {
    const { firstName, lastName } = personName;
    const name = getFullName(firstName, lastName);
    return {
      label: name,
      value: individualId,
    };
  });

  const onSelectAnswer = (e, questionCd, i) => {
    const oldAnswer = find(formik.values.houseHoldDeclarationQstns, {
      questionCd,
    });
    const newAnswer = {
      ...oldAnswer,
      qntsInd: e.target.value,
      individualIds: [],
    };

    const newAnswers = Object.assign(
      [],
      formik.values.houseHoldDeclarationQstns,
      {
        [i]: newAnswer,
      }
    );

    formik.setFieldValue('houseHoldDeclarationQstns', newAnswers);
  };

  const onSelectMember = (ids, questionCd, i) => {
    // const individualId = e.target.value;
    const oldAnswer = find(formik.values.houseHoldDeclarationQstns, {
      questionCd,
    });
    // const newIds = includes(oldAnswer.individualIds, individualId)
    //   ? reject(oldAnswer.individualIds, (id) => id === individualId)
    //   : [...oldAnswer.individualIds, individualId];

    const newAnswer = {
      ...oldAnswer,
      individualIds: ids,
    };

    const newAnswers = Object.assign(
      [],
      formik.values.houseHoldDeclarationQstns,
      {
        [i]: newAnswer,
      }
    );

    formik.setFieldValue('houseHoldDeclarationQstns', newAnswers);
  };

  const handleSave = () => {
    onSave(formik.values);
  };

  console.log(formik.errors);

  return (
    <PageContainer
      title="Household’s Declaration Inquiry"
      disableNext={!formik.isValid}
      navProps={navProps}
    >
      <Typography variant="h4">
        All the questions listed below have to be answered accurately by the
        Household requesting for either Supplemental Nutrition Assistance
        Program Benefits or Temporary Cash Assistance Benefits. Please verify
        the responses provided by the Head of the Household.
      </Typography>
      <IconButton onClick={() => setInitivalValues()}>
        <Refresh />
      </IconButton>
      <Stack component={Paper} spacing={3} sx={{ p: '1rem' }}>
        {map(
          formik.values.houseHoldDeclarationQstns,
          ({ question, questionCd, qntsInd, individualIds }, index) => {
            return (
              <div area-labelId={questionCd}>
                <Stack direction="row" sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">
                    {index + 1}).&nbsp;
                  </Typography>
                  <Stack spacing={2} sx={{ flex: 1 }}>
                    <Typography variant="subtitle1">{question}</Typography>
                    <Stack
                      spacing={2}
                      direction="row"
                      p="1rem"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <YesNoOption
                        name={`YesNo-${index}`}
                        value={qntsInd}
                        onChange={(e) => onSelectAnswer(e, questionCd, index)}
                      />
                      {/* <RadioGroup
                        row
                        sx={{ minWidth: '10rem' }}
                        onChange={(e) => onSelectAnswer(e, questionCd, index)}
                        value={qntsInd}
                        // {...formik.getFieldProps('residentshipPeriodYN')}
                      >
                        <FormControlLabel
                          value="Y"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="N"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup> */}
                      {qntsInd === 'Y' && (
                        <UserSelect
                          value={individualIds}
                          options={membersOpts}
                          onAdd={(ids) =>
                            onSelectMember(ids, questionCd, index)
                          }
                        />
                      )}
                    </Stack>
                  </Stack>
                </Stack>
                <Divider />
              </div>
            );
          }
        )}
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </Stack>
      </Stack>
    </PageContainer>
  );
}

HouseholdDeclaration.propTypes = {};

export default HouseholdDeclaration;
