import {
  Box,
  TextField,
  Stack,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import React, { useEffect, use, useState } from 'react';
import Field from '../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { maxLength, number, onlyAlphabet } from '../../utils/normalizers';
import usePageNav from '../../hooks/usePageNav';
import {
  seachCase,
  workersNames,
} from '../../utils/services/AuditTrail/apiRequest';
import useLoader from '../../hooks/useLoader';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import useLookup from '../../hooks/useLookup';
import { map } from 'lodash';
import EmptyState from '../../components/EmptyState';
import NoRecord from '../../assets/icons/audit.svg';

const AuditTrail = () => {
  const [showLoader, hideLoader] = useLoader();
  const { getLkpMatch, getOptions } = useLookup('AuditTrail');
  const [workerName, setWorkerNames] = useState([]);
  const [workerState, setWokerState] = useState(null);
  const { caseId } = usePageNav();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    handleSubmit({
      actionType: null,
      beginDate: null,
      caseId: caseId,
      endDate: null,
      workerId: null,
    });
    getWorkerNames();
  }, []);

  const getWorkerNames = async () => {
    try {
      const res = await workersNames();
      if (res.status === 200) setWorkerNames(res.data);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleSubmit = async (data) => {
    showLoader();
    const res = await seachCase({ ...data, workerId: workerState });
    if (res.status === 200) {
      const framRows = res.data?.map((obj, key) => {
        return {
          id: key,
          caseId: obj.caseId,
          worker: obj.workerName ? obj.workerName : 'System',
          email: obj.email ? obj.email : obj.workerId,
          funct: obj.tagText,
          actionType: obj.actionType,
          processMode: getLkpMatch('ProgramModeCode', obj.processMode),
          screenName: obj.tagText,
          actionDt: dayjs(obj.actionDt).format('DD-MM-YYYY'),
        };
      });
      setRows(framRows);
    }
    try {
    } catch (e) {
      console.log(e);
    } finally {
      hideLoader();
    }
  };

  const columns = [
    { field: 'caseId', headerName: 'Case Id', width: 130 },
    {
      field: 'worker',
      headerName: 'Worker Name',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Login Id',
      width: 140,
    },
    {
      field: 'screenName',
      headerName: 'Screen Name',
      width: 150,
    },
    {
      field: 'funct',
      headerName: 'Functionality',
      width: 160,
    },
    { field: 'actionType', headerName: 'Action Type', width: 150 },
    { field: 'processMode', headerName: 'Mode of Process', id: 7, width: 200 },
    { headerName: 'Action Date', field: 'actionDt', id: 8, width: 140 },
  ];

  const formik = useFormik({
    initialValues: {
      actionType: null,
      beginDate: null,
      caseId: caseId,
      endDate: null,
      workerId: null,
    },
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <Box sx={{ background: 'white' }}>
      <Box
        sx={{
          border: '1px solid var(--grey-350)',
          borderRadius: '0.2rem',
          padding: '2rem 1rem ',
          margin: '2rem 1rem',
          height: 'auto',
        }}
      >
        <Typography
          variant="h3"
          sx={{ textAlign: 'center', margin: '0.5rem 0.5rem 1rem 0.5rem' }}
        >
          Audit Trail
        </Typography>
        <Box
          sx={{
            border: '1px solid var(--grey-350)',
            borderRadius: '0.2rem',
            padding: '1rem 0.5rem ',

            height: 'auto',
          }}
        >
          <Stack direction="row" spacing={3} alignItems="end">
            <Field
              label="Case Id"
              formik={formik}
              normalizers={[maxLength(10), number]}
              name="caseId"
            >
              <TextField value={formik.values.caseId} />
            </Field>
            <Field
              label="Worker Name"
              formik={formik}
              name="workerId"
              normalizers={[onlyAlphabet, maxLength(30)]}
            >
              <FormControl fullWidth>
                <Select
                  name="workerId"
                  onChange={(e) => setWokerState(e.target.value)}
                >
                  {map(workerName, (obj, key) => (
                    <MenuItem key={key} value={obj?.uniqueId}>
                      {`${obj?.firstName} ${obj?.lastName}`}{' '}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Field>
            <Field
              label="From Date"
              isDate
              formik={formik}
              formikHandler={false}
              name="beginDate"
            >
              <DatePicker value={formik.values.beginDate} />
            </Field>
            <Field
              label="End Date"
              isDate
              formik={formik}
              formikHandler={false}
              name="endDate"
            >
              <DatePicker value={formik.values.endDate} />
            </Field>
            <Button
              variant="contained"
              sx={{ height: 'fit-content' }}
              onClick={formik.handleSubmit}
            >
              Search
            </Button>
          </Stack>
        </Box>
        <DataGrid
          rows={rows ?? []}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          sx={{
            marginTop: '1.5rem',
            maxWidth: '99%',
            height: 'auto',
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '30vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '30vh',
            },
            minHeight: '50vh',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          columns={columns ?? []}
          rowsPerPageOptions={[10, 20, 35]}
        />
      </Box>
    </Box>
  );
};

export default AuditTrail;
