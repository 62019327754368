import SelectableView from '../../components/SelectableView';
import { Tab, Tabs, Stack, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getIndividual,
  getDocumentsFiles,
} from '../../utils/services/apiRequests';
import useLookup from '../../hooks/useLookup';
import { getAge } from '../../utils/helpers';
import PreSchool from './PreSchool';
import Veteran from './Veteran';
import Incarceration from './Incarceration';
import StrikerStatus from './Striker';
import PageHeader from '../../components/PageHeader';
import usePageNav from '../../hooks/usePageNav';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DocumentUploadFilesDialog from '../Income/DocumentUploadFilesDialog';
import PageContainer from '../../components/PageContainer';
import usePage from '../../hooks/usePage';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function IndividualDetails({ id }) {
  const { navProps } = usePage(id);
  const [activeTab, setActiveTab] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [response, setResponse] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const { getOptions, getLkpMatch, lkpRaw, lkpFlatten } =
    useLookup('LivingArrangement');
  const { caseId, navigations } = usePageNav();

  const {
    preSchoolHealthVerificationOpts,
    stateOpts,
    verificationOpts,
    secondaryVerificationOpts,
    veteranStatusOpts,
  } = useMemo(() => {
    return {
      livingArrOpts: getOptions('LivingArrangement'),
      preSchoolHealthVerificationOpts: getOptions('OTOMedicalVerification'),
      stateOpts: getOptions('State'),
      verificationOpts: getOptions('CLDEATHVERCD'),
      secondaryVerificationOpts: getOptions('PrisonMatchSecVerf'),
      veteranStatusOpts: getOptions('VeteransStatus'),
    };
  }, [getOptions]);

  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const params = useParams();
  const getMenuItems = (data = {}) => {
    const { personName = {}, dob, gender } = data;
    return {
      title1: `${personName.firstName} ${personName.lastName}`,
      title2: 'Age',
      subtitle2: getAge(dob),
    };
  };

  const fetchIndividual = async () => {
    try {
      setFetching(true);
      const res = await getIndividual(params.caseId);
      if (res.status === 200) {
        setResponse(res.data);
      }
    } catch (error) {
      console.error('System is not responding');
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchIndividual();
  }, []);

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const onNext = () => {
    navigations.toEducationDetails();
  };

  const onBack = () => {
    navigations.toLivingArrangement();
  };

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  return (
    <PageContainer
      navProps={navProps}
      title="Individual Details"
      //  toVerify=""
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={response}
        loading={fetching}
        loadingContent={fetching}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        getId={(params) => params.individualId}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        showEdit={false}
        showDelete={false}
        showAdd={false}
      >
        {(selected) => {
          const { dob, individualId } = selected;

          return (
            <div>
              <Tabs
                onChange={onTabChange}
                value={activeTab}
                // variant="fullWidth"
                variant="scrollable"
                centered
              >
                <Tab
                  label="Incarceration"
                  style={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Veteran Status"
                  style={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Striker Status"
                  style={{ textTransform: 'capitalize' }}
                />
                <Tab
                  label="Pre School Health/One Time Only Medical"
                  style={{ textTransform: 'capitalize' }}
                />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: '1rem', mb: '1rem' }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button onClick={() => setOpen(true)}>
                    + Add Incarceration
                  </Button>
                </Stack>
                <Incarceration
                  open={open}
                  handleClose={handleClose}
                  dob={dob}
                  member={selected}
                  stateOpts={stateOpts}
                  verificationOpts={verificationOpts}
                  secondaryVerificationOpts={secondaryVerificationOpts}
                  individualId={individualId}
                  fetchIndividual={fetchIndividual}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: '1rem', mb: '1rem' }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>

                  <Button onClick={() => setOpen(true)}>
                    + Add Veteran Status
                  </Button>
                </Stack>
                <Veteran
                  open={open}
                  handleClose={handleClose}
                  selected={selected}
                  dob={dob}
                  member={selected}
                  getLkpMatch={getLkpMatch}
                  veteranStatusOpts={veteranStatusOpts}
                  individualId={individualId}
                  fetchIndividual={fetchIndividual}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: '1rem', mb: '1rem' }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button onClick={() => setOpen(true)}>
                    + Add Striker Status
                  </Button>
                </Stack>
                <StrikerStatus
                  open={open}
                  handleClose={handleClose}
                  dob={dob}
                  member={selected}
                  individualId={individualId}
                  fetchIndividual={fetchIndividual}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={3}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ mt: '1rem', mb: '1rem' }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>

                  <Button onClick={() => setOpen(true)}>
                    + Add Pre School Health/One Time only Medical
                  </Button>
                </Stack>

                <PreSchool
                  open={open}
                  handleClose={handleClose}
                  dob={dob}
                  member={selected}
                  preSchoolHealthVerificationOpts={
                    preSchoolHealthVerificationOpts
                  }
                  individualId={individualId}
                  fetchIndividual={fetchIndividual}
                />
              </TabPanel>

              <DocumentUploadFilesDialog
                docOpen={docOpen}
                setDocOpen={setDocOpen}
                isAdd={isAdd}
                setIsAdd={setIsAdd}
                caseId={params.caseId}
                lkpRaw={lkpRaw}
                member={selected}
                docmentFiles={docmentFiles}
              />
            </div>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}

export default IndividualDetails;
