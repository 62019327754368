import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { searchVendorPayload } from '../waiverAndFacility/apiPayload';
import {
  GET_WAIVER,
  POST_ADDITIONAL_INFO,
  POST_VENDOR_SEARCH,
  POST_WAIVER,
  POST_iNSTITUTION,
} from '../waiverAndFacility/apiUrl';

export const getWaiver = (caseId, userId) =>
  HttpClient.get(format(GET_WAIVER, caseId, userId));

export const postInstitution = (caseId, userId, institutionPayload) =>
  HttpClient.post(format(POST_iNSTITUTION, caseId, userId), institutionPayload);

export const postWaiver = (caseId, userId, waiverPayload) =>
  HttpClient.post(format(POST_WAIVER, caseId, userId), waiverPayload);

export const postAdditionalInfo = (caseId, userId, AddtionalPayload) =>
  HttpClient.post(
    format(POST_ADDITIONAL_INFO, caseId, userId),
    AddtionalPayload
  );

export const postVendorSearch = (vendorPayload) =>
  HttpClient.post(
    format(POST_VENDOR_SEARCH),
    searchVendorPayload(vendorPayload)
  );
