export const POST_MANUALSEARCH =
  '/eeapi/application-process/cc/searchManualTrigger/{0}';

export const POST_NOTICEINFO =
  '/eeapi/application-process/cc/noticeInformation';

export const POST_MANUALTEMPLATE =
  'eeapi/application-process/cc/manualTemplateInsert';

export const POST_DELETE = 'eeapi/application-process/cc/deleteManualTrigger';

export const POST_PDFDWNLD = 'eeapi/application-process/manualnotice/{0}';
