import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import { CloseRounded, DeleteOutlineOutlined } from '@mui/icons-material';

const DeleteConfiramation = ({
  openDeleteDialog,
  handleCancelDelete,
  handleConfirmDelete,
}) => {
  return (
    <>
      <Dialog open={openDeleteDialog} onClose={handleCancelDelete}>
        <DialogContent sx={{ width: '30rem' }}>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={handleCancelDelete}>
              <CloseRounded />
            </IconButton>
          </Stack>
          <Stack justifyContent="center" alignItems="center">
            <DeleteOutlineOutlined
              sx={{ height: 100, width: 100, color: '#cf4242' }}
            />
            <Typography color="gray" variant="h3">
              Delete this record ?
            </Typography>
            <Stack justifyContent="center" alignItems="center" color="#9e9e9e">
              <span>Do you really want to delete these record ?</span>
              <span> You will not able to recover it.</span>
            </Stack>
            <Stack direction="row" spacing={1} mt={4}>
              <Button
                color="error"
                variant="outlined"
                onClick={handleCancelDelete}
              >
                Discard
              </Button>
              <Button
                color="error"
                sx={{ mt: '1rem' }}
                variant="contained"
                onClick={handleConfirmDelete}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteConfiramation;
