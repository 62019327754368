import React, { Children, useState } from 'react';
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
  Stack,
} from '@mui/material';
import WorkCreationIndex from './WorkCreationIndex';
import Avatar from '../../../components/Avatar';
import WorkAssistance from './WorkAssistance';

const CustomTab = ({ children, index, tabIndex }) => {
  return (
    <Box
      role="tabpanel"
      hidden={index != tabIndex}
      id={`full-width-tabpanel-${tabIndex}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {children}
    </Box>
  );
};

const WorkCreation = ({
  selectedData,
  open,
  setOpen,
  selected,
  getOptions,
  dataToChange,
  individualId,
  caseId,
  setChanged,
  changed,
  events,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const tabChange = (_, index) => {
    setCurrentIndex(index);
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        <Typography variant="h2">
          Work's Registration / Participation{' '}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: '1px solid var(--grey-250)', borderRadius: '5px' }}>
          <Box>
            <Stack
              direction="row"
              spacing={3}
              justifyContent="space-between"
              sx={{
                backgroundColor: '#F2F9FD',
                padding: '1rem 1.7rem 1rem 1rem',
              }}
            >
              <Box sx={{ paddingRight: '2rem' }}>
                <Typography variant="h6">Member Name</Typography>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    marginTop: '0.3rem',
                    alignItems: 'center',
                  }}
                >
                  <Avatar name={selected.name} />
                  <Typography variant="h4" sx={{ color: 'grey' }}>
                    {selected.name}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="h6">Clock Count</Typography>
                <Typography>{dataToChange?.clkCnt}</Typography>
              </Box>
              <Box>
                <Typography variant="h6">Program Type</Typography>
                <Typography></Typography>
              </Box>
              <Box>
                <Typography variant="h6">LDSS</Typography>
                <Typography>{dataToChange?.ldssCd}</Typography>
              </Box>
            </Stack>
          </Box>
          <Tabs
            onChange={tabChange}
            sx={{ width: '100%', margin: 0, padding: '1rem' }}
            value={currentIndex}
          >
            <Tab
              label="Plan Creation"
              style={{ textTransform: 'capitalize' }}
            ></Tab>
            <Tab
              label="Customer Assessment"
              style={{ textTransform: 'capitalize' }}
            ></Tab>
          </Tabs>
          <CustomTab index={0} tabIndex={currentIndex}>
            <WorkCreationIndex
              events={events}
              individualId={individualId}
              setChanged={setChanged}
              changed={changed}
              caseId={caseId}
              setOpen={setOpen}
              data={dataToChange}
              getOptions={getOptions}
            />
          </CustomTab>
          <CustomTab index={1} tabIndex={currentIndex}>
            <WorkAssistance
              getOptions={getOptions}
              setChanged={setChanged}
              changed={changed}
              setOpen={setOpen}
              caseId={caseId}
              individualId={individualId}
            />
          </CustomTab>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WorkCreation;
