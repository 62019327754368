import { map } from 'lodash';

export const insertPolicyPayload = (data) => {
  const {
    institution,
    policyNumber,
    recordId,
    type,
    verified,
    Percentage,
    accountNumber,
    assetType,
    license,
    make,
    model,
    registration,
    state,
    vehicle,
    vin,
    year,
    institutionId,
    locationId,
    ownedByHouseholdMemberYN,
    jointOwnerYN,
    use,
    line1,
    line2,
    city,
    zipCode,
    stateAddress,
    tryingToSellYN,
    intentBurialExpensesYN,
  } = data?.value;

  const assetAmounts = map(data.policyData, (value) => ({
    recordId: typeof recordId !== 'string' ? recordId : '',
    amount: value.amount,
    faceAmount: value.faceAmount,
    cashAmount: value.cashAmount,
    fareMarketValue: value.fareMarketValue,
    encumberedAmount: value.encumberedAmount,
    dirtyStatus: typeof recordId !== 'string' ? 'UPDATE' : 'INSERT',
    amountVerification: {
      verificationDocumentCd: '',
      verificationRequestedYN: 'N',
      docNames: null,
      verificationType: '',
      verificationSourceCode: '',
      dueDate: null,
    },
    fareMarketValueVerification: {
      verificationDocumentCd: '',
      verificationRequestedYN: 'N',
      docNames: null,
      verificationType: '',
      verificationSourceCode: value.fareMarketVerify,
      dueDate: null,
    },
    encumberedAmountVerification: {
      verificationDocumentCd: '',
      verificationRequestedYN: 'N',
      docNames: null,
      verificationType: '',
      verificationSourceCode: value.encumberedVerify,
      dueDate: null,
    },
    amountReceivedVerification: {
      verificationDocumentCd: '',
      verificationRequestedYN: 'N',
      docNames: null,
      verificationType: '',
      verificationSourceCode: '',
      dueDate: null,
    },
    effectiveBeginDate: value.effectiveBeginDate,
    effectiveEndDate: value.effectiveEndDate,
  }));

  return {
    dirtyStatus: recordId ? 'update' : 'insert',
    recordId: recordId,
    percentOfShare: Percentage,
    amount: '',
    assetType: assetType,
    assetDetailType: type,
    jointOwnerYN: jointOwnerYN,
    intentBurialExpensesYN: intentBurialExpensesYN,
    otherAssetName: '',
    accountNumber: accountNumber,
    fdic: '',
    institutionName: institution,
    institutionId: institutionId,
    locationId: locationId,
    vehicalUse: vehicle,
    termInd: 'Y',
    policyNumber: policyNumber,
    ownedByHouseholdMemberYN: ownedByHouseholdMemberYN,
    year: year,
    make: make,
    model: model,
    licenseNumber: license,
    vin: vin,
    registration: registration,
    state: state,
    use: use,
    tryingToSellYN: tryingToSellYN,
    address: {
      addressLine1: line1,
      addressLine2: line2,
      city: city,
      zipcode: zipCode,
      state: stateAddress,
    },
    transferDate: null,
    name: '',
    reasonOfTransfer: '',
    amountReceived: 0,
    transfereeRelationship: '',
    undueHardshipReason: '',
    discoveryDate: null,
    effectiveBeginDate: null,
    effectiveEndDate: null,
    assetVerification: {
      verificationSourceCode: verified,
    },
    assetAmounts: assetAmounts,
  };
};

export const deletePaload = (data, name) => {
  const { recordId, assetType } = data;
  return {
    recordId: recordId,
    assetType: assetType,
    jointOwnerYN: 'N',
    assetDetailType: 'DI',
    institutionName: 'asdfghj',
    policyNumber: '98765432123456',
    ownedByHouseholdMemberYN: 'Y',
    effectiveBeginDate: '2024-01-11',
    effectiveEndDate: '9999-12-31',
    assetVerification: {
      retryCount: 0,
      verificationRequestedYN: 'N',
      providedVerificationId: 3262,
      verifiedYN: 'Y',
      verificationSourceCode: 'LE',
      updatedBy: name,
      updatedDate: '2024-01-10',
    },
    assetAmounts: [
      {
        recordId: 33,
        faceAmount: 0.88,
        cashAmount: 0.66,
        effectiveBeginDate: '2024-01-11',
        effectiveEndDate: '9999-12-31',
        updatedBy: name,
        updatedDate: '2024-01-11T09:56:40.427Z',
      },
      {
        recordId: 32,
        faceAmount: 3.22,
        cashAmount: 23.3,
        effectiveBeginDate: '2024-01-11',
        effectiveEndDate: '9999-12-31',
        updatedBy: name,
        updatedDate: '2024-01-11T09:56:40.417Z',
      },
    ],
    updatedBy: name,
    updatedDate: '2024-01-11',
    dirtyStatus: 'delete',
  };
};

export const institutionPayload = (data) => {
  const { recordId, state } = data;
  console.log(data);
  return {
    search: {
      institutionId: null,
      institutionName: null,
      city: null,
      state: state,
      zip: null,
    },
    results: [],
    paginationData: {
      resultsPerPage: 6,
      pageNumber: 1,
    },
  };
};
