import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Grid, TextField } from '@mui/material';
import { toPng } from 'html-to-image';
import './fontSign.css';

export default function FontSign(props) {
  const fontStyles = [
    'fontStyle1',
    'fontStyle2',
    'fontStyle3',
    'fontStyle4',
    'fontStyle5',
    'fontStyle6',
    'fontStyle7',
    'fontStyle8',
  ];

  const [state, setState] = useState(fontStyles);
  const [enterText, setEnterText] = useState('Signature');
  useEffect(() => {
    setState(fontStyles);
  }, [props.clear]);
  var getRef = useRef({});
  const arr = [];

  const clickHandler = async (id) => {
    state.map((val, index) => (id === index ? arr.push(val) : ''));
    setState([arr]);
    toPng(getRef.current, { cacheBust: false })
      .then((dataUrl) => {
        props.setFontData(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onChangeHandler = (event) => {
    setEnterText(event.target.value);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        color={props.color}
      >
        <div className="grid-container">
          {state.map((font, index) => (
            <div
              key={index}
              onClick={() => clickHandler(index)}
              className="fontShow"
            >
              <div
                ref={getRef}
                // className={font}
              >
                <span
                  style={{ fontFamily: 'Kaushan scrip', fontWeight: 'bolder' }}
                >
                  {enterText !== '' ? enterText : 'Signature'}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Grid>
      <Grid>
        <Grid item marginBottom={1}>
          <span>Type Your Name</span>
        </Grid>

        <Grid item width="100%">
          <TextField
            fullWidth
            size="small"
            placeholder="Enter Your Name"
            onChange={onChangeHandler}
          />
        </Grid>
      </Grid>
    </div>
  );
}
