import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import styles from './index.module.css';

function YesNoOption({ name, value, onChange }) {
  return (
    <Stack direction="row" spacing={1} className={styles.yesNoOption}>
      <label className={styles.yes}>
        <input
          checked={value === 'Y'}
          id={`${name}Y`}
          type="radio"
          onClick={onChange}
          name={name}
          value="Y"
        />
        <div className={styles.labelText}>
          <Typography variant="subtitle1">Yes</Typography>
        </div>
      </label>
      <label className={styles.no}>
        <input
          type="radio"
          id={`${name}N`}
          checked={value === 'N'}
          onClick={onChange}
          name={name}
          value="N"
        />
        <div className={styles.labelText}>
          <Typography variant="subtitle1">No</Typography>
        </div>
      </label>
    </Stack>
  );
}

YesNoOption.propTypes = {
  name: PropTypes.string,
};

YesNoOption.defaultProps = {
  name: 'YesNo',
};

export default YesNoOption;
