import React from 'react';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import styles from '../../index.module.css';

export default function CPAddressInfo({ data }) {
  const { homeAddress, mailingAddress } =
    data?.[0]?.contactInformationDetails?.contactInformation ?? {};
  const { addressLine1, addressLine2, city, state, zipcode } =
    homeAddress ?? {};
  const {
    maaddressLine1 = addressLine1,
    maaddressLine2 = addressLine2,
    macity = city,
    mastate = state,
    mazipcode = zipcode,
  } = mailingAddress ?? {};

  return (
    <TableContainer>
      <Table
        sx={{
          td: {
            padding: 1.5,
            paddingBottom: '0.5rem',
            border: 0,
          },
        }}
      >
        <TableBody className={styles.tableBody}>
          <TableRow>
            <TableCell rowSpan={4}>
              <Typography variant="h6grey">Residential Address </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{`${addressLine1 ?? ''} ${
                addressLine2 ?? ''
              }`}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">{city ?? ''}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">{state ?? ''}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">{zipcode ?? ''}</Typography>
            </TableCell>
          </TableRow>
          <TableCell colSpan={2}>
            <Divider />
          </TableCell>
          <TableRow>
            <TableCell rowSpan={4}>
              <Typography variant="h6grey">Mailing Address </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2">{`${maaddressLine1 ?? ''} ${
                maaddressLine2 ?? ''
              }`}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">{macity ?? ''}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">{mastate ?? ''}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle2">{mazipcode ?? ''}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
