import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MapBanner from '../../assets/images/map.png';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SendIcon from '@mui/icons-material/Send';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ReactComponent as FeatherIcon } from '../../assets/icons/feather.svg';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ReactComponent as AccBalIcon } from '../../assets/icons/account-balance.svg';
import { ReactComponent as AccBoxIcon } from '../../assets/icons/account-box.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import styles from './index.module.css';
import AddSignature from './signature/add-signature';
import { concat, filter, includes, map, some } from 'lodash';
import { reject } from 'lodash';
import { Formik, useFormik } from 'formik';
import { findIndex } from 'lodash';
import HttpClient from '../../utils/HttpClient';
import Dropdown from '../../components/Dropdown';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers';
import useLookup from '../../hooks/useLookup';
import dayjs from 'dayjs';
import useLoader from '../../hooks/useLoader';
import { showErrorToast } from '../../utils/services/toast';
import {
  PostAddWorker,
  getCountyWorker,
  getDisposition,
  getSchedule,
  scheduleInterview,
} from '../../utils/services/apiRequests';
import { useParams } from 'react-router';
import useCaseStatus from '../../hooks/useCaseStatus';
import ScheduleAppoinment from './Scheduler';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import {
  selectCaseDetails,
  selectHouseMembers,
} from '../../store/selectors/caseSelector';
import usePageNav from '../../hooks/usePageNav';
import { getAuthToken } from '../../store/selectors/authSelector';

const SIGN_PROPS = {
  signatoryType: '',
  signedDt: null,
  signatoryName: '',
  signatureImage: undefined,
  signatureId: '',
  paperApplicationAvailableYN: '',
};
function AppDisposition(props) {
  const params = useParams();
  const caseDetails = useSelector(selectCaseDetails);
  const { getCaseStatus } = useCaseStatus();
  const { navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [open, setOpen] = useState(false);
  const { lkpRaw, getLkpMatch, getOptions } = useLookup('AppDispose');
  const [countyList, setCountyList] = useState([]);
  const [dispositionData, setDispostionData] = useState('');
  const [scheduleData, setScheduleData] = useState('');
  const [selectWorker, setSelectWorker] = useState('');
  const [districtOffices, setDistictOffices] = useState([]);
  const [caseWorkers, setCaseWorkers] = useState([]);
  const [signData, setSignData] = useState([SIGN_PROPS]);
  const [selectedSignRow, setSelectedSignRow] = useState('');
  const [indiId, setIndiId] = useState('');
  const getIndividual = useSelector(selectHouseMembers);
  const token = useSelector(getAuthToken);

  useEffect(() => {
    // getAppDispotion();
    getCountyWorkerApi();
    getScheduleApi();
  }, []);

  const selectedPrograms = useMemo(() => {
    return map(caseDetails?.programs, ({ programName }) => programName);
  }, [caseDetails]);

  const scheduled = useMemo(() => {
    return scheduleData.scheduledInterview;
  }, [scheduleData]);

  const getScheduleApi = async () => {
    try {
      showLoader();
      const res = await getSchedule(params.caseId);
      if (res.status === 200) {
        setScheduleData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getCountyWorkerApi = async () => {
    try {
      showLoader();
      const res = await getCountyWorker(params.caseId);

      if (res.status === 200) {
        setSelectWorker(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getAddWorkerApi = async () => {
    try {
      showLoader();
      const res = await PostAddWorker(params.caseId);

      if (res.status === 200) {
        setScheduleData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const caseWorkersOpts = useMemo(() => {
    return map(caseWorkers, ({ personName, workerId }) => ({
      label: `${personName?.firstName} ${personName?.lastName}`,
      value: workerId,
    }));
  }, [caseWorkers]);

  const { interviewStatus, interviewTypes, programs, signatoryTypes } =
    useMemo(() => {
      const {
        InterviewStatus = [],
        Program = [],
        SignatureType = [],
        InterviewType = [],
      } = lkpRaw ?? {};
      const filteredPrograms = filter(Program, (lang) => {
        return ['CH', 'EA', 'MA', 'FS', 'LT', 'WV'].includes(lang.lkpDesc);
      });
      return {
        interviewStatus: InterviewStatus,
        interviewTypes: InterviewType,
        programs: filteredPrograms,
        signatoryTypes: SignatureType,
      };
    }, [lkpRaw]);

  const showAvailability = () => {
    setOpen(true);
  };

  const getCountyList = async () => {
    try {
      showLoader();
      const res = await HttpClient.get(
        '/eeapi/application-process/screening/get-counties'
      );
      if (res?.data) {
        setCountyList(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getDistrictOffices = async (value) => {
    try {
      showLoader();
      const res = await HttpClient.get(
        `/eeapi/application-process/screening/get-county-offices?county=${value}`
      );
      if (res?.data) {
        setDistictOffices(res.data);
      }
    } catch (error) {
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  const getCaseWorkers = async (value) => {
    showLoader();
    try {
      const res = await HttpClient.get(
        `eeapi/application-process/screening/get-office-workers?office=${value}`
      );

      if (res?.data) {
        setCaseWorkers(res.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.userMessages?.[0].message);
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async (data) => {
    try {
      showLoader();
      const res = await scheduleInterview(
        params.caseId,
        data,
        signData,
        token?.sub
      );

      if (res.status === 200) {
        navigations.toCaseHome(params.caseId);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const validationSchema = yup.object({
    county: yup.string().required('Please select county'),
    districtOffice: yup.string().required('Please select district office'),
  });

  const { selectedDo, selectedCounty, selectedWorker } = useMemo(() => {
    return selectWorker;
  }, [selectWorker]);

  const formik = useFormik({
    initialValues: {
      // signData: [SIGN_PROPS],
      county: selectedCounty ?? '',
      programs: selectedPrograms ?? [],
      districtOffice: selectedDo?.name ?? '',
      // workerId: '7c438d573165cdd1552231ed3bdd33',
      workerId: selectedWorker?.workerId ?? '',
      programType: scheduled?.interviewStatus ?? 'SC',
      interviewType: '',
      interviewStatus: '',
      appointmentDt: null,
      individualId: indiId ? indiId : '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const fetchCaseStatus = async () => {
    const caseStatus = await getCaseStatus(params.caseId, false);
    const selectedPgs = map(
      caseStatus?.programs ?? [],
      ({ programName }) => programName
    );
    formik.setFieldValue('programs', selectedPgs);
  };

  const isValid =
    formik.isValid &&
    some(
      signData,
      ({ signatoryType, signedDt, signatoryName, signatureImage }) =>
        signatoryType && signedDt && signatoryName && signatureImage
    );

  const disableInterviewType = !some(formik.values.programs, (p) =>
    includes(['FS', 'CH'], p)
  );

  useEffect(() => {
    fetchCaseStatus();
  }, []);

  useEffect(() => {
    if (formik.values.county) {
      getDistrictOffices(formik.values.county);
    }
  }, [formik.values.county]);

  useEffect(() => {
    if (formik.values.districtOffice) {
      getCaseWorkers(selectedDo?.name);
    }
  }, [formik.values.districtOffice]);

  const onChangeDropdown = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    switch (name) {
      case 'county':
        setDistictOffices([]);
        getDistrictOffices(value);
        break;
      case 'districtOffice':
        setCaseWorkers([]);
        getCaseWorkers(value);
        break;
      default:
    }
  };

  useEffect(() => {
    getCountyList();
  }, []);

  const onChangeSignRow = (prop, value, index) => {
    const newSigns = Object.assign([], signData, {
      [index]: {
        ...signData[index],
        [prop]: value,
      },
    });

    setSignData(newSigns);
  };

  const toggleSignature = (signatureImage) => {
    if (signatureImage) {
      onChangeSignRow('signatureImage', signatureImage, selectedSignRow);
    }
    setSelectedSignRow('');
  };

  const addNewSignRow = () => {
    setSignData([...signData, SIGN_PROPS]);
  };

  const removeSignRow = (index) => {
    const newSignData = [...signData];
    newSignData.splice(index, 1);
    setSignData(newSignData);
  };

  const toggleAvailability = () => {
    setOpen(!open);
  };

  const onBack = () => {
    navigations.toAuthRep();
  };

  return (
    <Box sx={{ padding: '0.5rem' }}>
      <Dialog maxWidth="lg" open={open} fullWidth onClose={toggleAvailability}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          margin={1}
        >
          <Typography variant="h4">Schedule interview</Typography>
          <IconButton onClick={toggleAvailability}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <ScheduleAppoinment
            interviewTypes={interviewTypes}
            getLkpMatch={getLkpMatch}
            getOptions={getOptions}
            ldssDistrict={formik.values.districtOffice}
            toggleAvailability={toggleAvailability}
            caseID={params.caseId}
            getScheduleApi={getScheduleApi}
            scheduleDetails={scheduled}
          />
        </DialogContent>
      </Dialog>

      <AddSignature open={selectedSignRow !== ''} onClose={toggleSignature} />
      <Typography variant="h2">Application Disposition</Typography>
      <div className={styles.banner}>
        <img src={MapBanner} alt="map" />
        <div className={styles.appDisp}>
          <Typography variant="h2" className={styles.heading}>
            Application Registration Disposition
          </Typography>
          <Grid container columnSpacing={2}>
            <Grid item xs={4}>
              <Stack>
                <Stack alignItems="center">
                  <LocationIcon />
                  <Typography variant="subtitle1" color="var(--grey-400)">
                    County
                  </Typography>
                </Stack>
                <Dropdown
                  name="county"
                  placeholder="Select County"
                  options={countyList}
                  mapProps={['ldssName', 'ldssCd']}
                  value={formik.values.county}
                  onChange={onChangeDropdown}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Stack alignItems="center">
                  <AccBalIcon />
                  <Typography variant="subtitle1" color="var(--grey-400)">
                    District Office
                  </Typography>
                </Stack>
                <Dropdown
                  name="districtOffice"
                  placeholder="Select District office"
                  options={districtOffices}
                  value={formik.values.districtOffice}
                  mapProps={['districtOfficeName', 'districtOfficeCode']}
                  onChange={onChangeDropdown}
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Stack alignItems="center">
                  <AccBoxIcon />
                  <Typography variant="subtitle1" color="var(--grey-400)">
                    Case Worker
                  </Typography>
                </Stack>
                <Dropdown
                  name="workerId"
                  placeholder="Select Case worker"
                  options={caseWorkersOpts}
                  value={formik.values.workerId}
                  onChange={onChangeDropdown}
                />
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
      <Box>
        <Typography className={styles.heading} variant="h2">
          Schedule Appointment
        </Typography>
        <Paper sx={{ padding: '1.25rem' }}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={4} lg={4}>
              <Typography variant="h5" marginBottom="1rem">
                Program Details
              </Typography>
              <Dropdown
                name="programs"
                multiple
                showCheckbox
                options={programs}
                placeholder="Select Program"
                value={formik.values.programs}
                // value={formik.values.programs}
                mapProps={['lookupLongVal', 'lkpDesc']}
                onChange={formik.handleChange}
              />
            </Grid>
            {/* <Grid item sm={12} md={6} lg={3}>
              <Typography variant="h5" marginBottom="1rem">
                Interview Type
              </Typography>
              <Dropdown
                name="interviewStatus"
                options={interviewStatus}
                value={formik.values.interviewStatus}
                mapProps={['lookupLongVal', 'lkpDesc']}
                onChange={onChangeDropdown}
              />
            </Grid> */}
            <Grid item sm={12} md={4} lg={4}>
              <Typography variant="h5" marginBottom="1rem">
                Appointment
              </Typography>
              <Button
                onClick={showAvailability}
                startIcon={<CalendarMonthIcon />}
                variant="text"
                disabled={
                  !formik.values.county || !formik.values.districtOffice
                }
              >
                Availability
              </Button>
            </Grid>
            <Grid item sm={12} md={4} lg={4}>
              <Stack direction="row" spacing={2}>
                <Box>
                  <Typography variant="h5" marginBottom="1rem">
                    Interview Status
                  </Typography>
                  <Typography>
                    {getLkpMatch(
                      'InterviewStatus',
                      scheduled?.interviewStatus ?? '-'
                    )}
                  </Typography>
                  {/* <Dropdown
                    name="programType"
                    disabled
                    options={interviewStatus}
                    mapProps={['lookupLongVal', 'lkpDesc']}
                  /> */}
                </Box>
                <Box>
                  <Typography variant="h5" marginBottom="1rem">
                    Notification
                  </Typography>
                  <Button startIcon={<SendIcon />} variant="text">
                    Send
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography className={styles.heading} variant="h2">
            Signatures
          </Typography>
          <Button
            disabled={signData?.length === 3}
            startIcon={<PersonAddAltIcon />}
            onClick={addNewSignRow}
          >
            Add Another Signature
          </Button>
        </Stack>
        <Paper sx={{ padding: '1.25rem' }}>
          <Grid container spacing={2} marginBottom="1rem">
            <Grid item sm={12} md={6} lg={3}>
              <Typography variant="h5">Signature type</Typography>
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Typography variant="h5">Signature Date</Typography>
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Typography variant="h5">Signed By</Typography>
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Stack direction="row" spacing={2}>
                <Box width="100%">
                  <Typography variant="h5">Add Signature</Typography>
                </Box>
                <Box width="100%">
                  <Typography variant="h5" textAlign="center">
                    Actions
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {map(signData, (data, index) => (
              <>
                <Grid item sm={12} md={6} lg={3}>
                  <Dropdown
                    name="signatoryType"
                    placeholder="Select Signature Type"
                    options={signatoryTypes}
                    value={data.signatoryType}
                    mapProps={['lookupLongVal', 'lkpDesc']}
                    onChange={(e) =>
                      onChangeSignRow('signatoryType', e.target.value, index)
                    }
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={3}>
                  <DatePicker
                    disableFuture
                    placeholder="Select Date(MM/DD/YYYY)"
                    onChange={(date) =>
                      onChangeSignRow(
                        'signedDt',
                        dayjs(date).format('YYYY-MM-DD'),
                        index
                      )
                    }
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={3}>
                  <TextField
                    placeholder=" Enter Name"
                    onChange={(e) =>
                      onChangeSignRow('signatoryName', e.target.value, index)
                    }
                  />
                </Grid>
                <Grid item sm={12} md={6} lg={3}>
                  <Stack direction="row" spacing={2}>
                    <Box width="100%">
                      {data.signatureImage ? (
                        <img
                          src={data.signatureImage}
                          alt="sign"
                          width={40}
                          height={40}
                        />
                      ) : (
                        <Button
                          startIcon={<FeatherIcon />}
                          onClick={() => setSelectedSignRow(index)}
                          variant="text"
                        >
                          Signature
                        </Button>
                      )}
                    </Box>
                    <Box width="100%">
                      <Container
                        sx={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <IconButton
                          disabled={signData.length === 1}
                          onClick={() => removeSignRow(index)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Container>
                    </Box>
                  </Stack>
                </Grid>
              </>
            ))}
          </Grid>
        </Paper>
        <Stack
          direction="row"
          justifyContent="flex-end"
          padding="1rem"
          spacing={2}
        >
          <Button variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button
            disabled={!isValid || !scheduled?.interviewStatus}
            onClick={formik.handleSubmit}
            variant="contained"
          >
            Finish
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

AppDisposition.propTypes = {};

export default AppDisposition;
