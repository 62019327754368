import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import UserProgram from './UserProgram';
import CaseWorkers from './CaseWorkers';

const UserManagement = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '1.5rem',
          fontWeight: '700',
          lineHeight: '2rem',
          textAlign: 'left',
          color: 'var(--black-900)',
          paddingBottom: '20px',
        }}
      >
        User Management
      </Typography>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '& .Mui-selected': {
              color: 'black !important',
              fontSize: '1rem',
              fontWeight: 700,
            },
            color: 'var(--grey-400)',
            fontSize: '1rem',
            fontWeight: '600',
          }}
        >
          <Tab
            label="User Program"
            sx={{ textTransform: 'capitalize', fontSize: '1rem !important' }}
          />
          <Tab
            label="Case Worker"
            sx={{ textTransform: 'capitalize', fontSize: '1rem !important' }}
          />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <UserProgram />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CaseWorkers />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default UserManagement;
