import { get } from 'lodash';
import ActionButton from '../../../components/ActionButtons';
import IndividualDetailsTable from '../IndividualDetailsTable';
import { useState, useEffect } from 'react';
import AddIncarceration from './AddIncarceration';
import { useParams } from 'react-router-dom';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { postIncarceration } from '../../../utils/services/individualDetailsServices/requests';
import DeleteModal from '../DeleteModal';
import useLoader from '../../../hooks/useLoader';

function Incarceration({
  member,
  stateOpts,
  verificationOpts,
  secondaryVerificationOpts,
  individualId,
  fetchIndividual,
  dob,
  open,
  handleClose,
}) {
  const [deleteData, setDeleteData] = useState('');
  const [openDeleteModal, setOpenDeletModal] = useState(false);
  const [editData, setEditData] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const handleDelete = (params) => {
    setOpenDeletModal(true);
    setDeleteData(params.row);
  };

  const handleEdit = (params) => {
    setEditData(params.row);
    setOpenEditModal(true);
  };

  useEffect(() => {
    if (editData) {
      setOpenEditModal(true);
    }
  }, [editData]);

  const params = useParams();
  const columns = [
    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      flex: 1,
      editable: false,
    },
    {
      field: 'effectiveEndDate',
      headerName: 'End Date',
      flex: 1,
      editable: false,
    },
    {
      field: 'institution',
      headerName: 'Institution',
      flex: 1,
      editable: false,
    },
    {
      headerName: 'Action',
      field: 'action',
      flex: 1,
      renderCell(params) {
        return (
          <span>
            <ActionButton
              onDelete={() => handleDelete(params)}
              onEdit={() => handleEdit(params)}
            />
          </span>
        );
      },
      headerName: 'Action',
    },
  ];

  const individualDetails = get(member, 'individualDetails.incarcerations', []);

  const getRowId = (row) => {
    return row.recordId;
  };

  const onSaveIncarceration = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postIncarceration(
        data,
        params.caseId,
        individualId,
        dirtyStatus
      );

      if (res.status === 200) {
        fetchIndividual();
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added New Incarceration');
        } else if (dirtyStatus === 'delete') {
          showSuccessToast('Successfully Deleted The Incarceration ');
        } else {
          showSuccessToast('Successfully Updated The Incarceration');
        }
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
      handleClose();
    }
  };

  return (
    <div>
      <IndividualDetailsTable
        rows={individualDetails}
        columns={columns}
        getRowId={getRowId}
      />
      <AddIncarceration
        dob={dob}
        member={member}
        addIncarceration
        onSaveIncarceration={onSaveIncarceration}
        open={open}
        handleClose={handleClose}
        stateOpts={stateOpts}
        verificationOpts={verificationOpts}
        secondaryVerificationOpts={secondaryVerificationOpts}
        individualDetails={individualDetails}
      />

      <AddIncarceration
        dob={dob}
        editIncarceration
        editData={editData}
        onSaveIncarceration={onSaveIncarceration}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        stateOpts={stateOpts}
        verificationOpts={verificationOpts}
        secondaryVerificationOpts={secondaryVerificationOpts}
        individualDetails={individualDetails}
      />

      <DeleteModal
        open={openDeleteModal}
        close={() => setOpenDeletModal(false)}
        deleteData={deleteData}
        fn={onSaveIncarceration}
      />
    </div>
  );
}

export default Incarceration;
