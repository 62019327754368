import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { filter, find, includes, isEmpty, isNumber, map, size } from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
  },
};
function Dropdown(props) {
  const {
    options,
    mapProps,
    label,
    placeholder = 'Select Any Option',
    multiple,
    showCheckbox,
    value: _value,
    ...rest
  } = props;
  const menuItems = useMemo(() => {
    if (!mapProps) {
      return options;
    } else {
      const [labelProp, valueProp] = mapProps;
      return map(options, (option) => ({
        label: option[labelProp],
        value: option[valueProp],
      }));
    }
  }, [options, mapProps]);
  return (
    <Select
      displayEmpty
      MenuProps={MenuProps}
      renderValue={(value) => {
        if (isEmpty(value) && !isNumber(value)) {
          return <Box sx={{ color: '#a2a2a2' }}>{placeholder}</Box>;
        }

        if (multiple) {
          const selectedLabels = filter(menuItems, (item) =>
            includes(value, item.value)
          );
          return map(selectedLabels, (item) => item.label).join(', ');
        }

        const selectedLabel = find(menuItems, { value: _value });
        return selectedLabel?.label ?? selectedLabel;
      }}
      select
      value={_value}
      multiple={multiple}
      {...rest}
    >
      <MenuItem value="">Select</MenuItem>
      {map(menuItems, ({ label, value }) => {
        const isSelected = includes(_value, value);
        return (
          <MenuItem key={value} value={value}>
            {showCheckbox && <Checkbox checked={isSelected} />}
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

Dropdown.propTypes = {
  multiple: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

Dropdown.defaultProps = {
  multiple: false,
  showCheckbox: false,
};
export default Dropdown;
