import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
} from '@mui/material';
import React, { useMemo } from 'react';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import FormSection from '../../../components/FormSection';
import { DatePicker } from '@mui/x-date-pickers';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { postWaiver } from '../../../utils/services/waiverAndFacility/apiRequest';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { map, some } from 'lodash';

import dayjs from 'dayjs';
export default function AddWaiver({
  currentUserId,
  getOptions,
  toggleClose,
  caseId,
  waiverAPI,
  createNew,
  waiverData,
  rowsData,
}) {
  const { waiverTypeOpts, waiverOpts, waiverDenialOpts } = useMemo(() => {
    return {
      waiverTypeOpts: getOptions('HCBWaiverType'),
      waiverOpts: getOptions('Waiver'),
      waiverDenialOpts: getOptions('WaiverDenial'),
    };
  }, [getOptions]);

  const validationSchema = Yup.object({
    waiverType: Yup.string().required('Please select waiver type'),
    appDate: Yup.string().required('Please select application date'),
  });
  console.log(waiverData);
  const formik = useFormik({
    initialValues: {
      waiverType: waiverData?.waiverType ?? '',
      appDate: waiverData?.appDate ?? '',
      atpStatus: waiverData?.atpStatusCd ?? 'PE',
      waiverDenial: waiverData?.waiverDenialCd ?? '',
      waiverBegin: '',
      waiverEnd: waiverData?.waiverEnd ?? '',
      waiverWorkerID: waiverData?.workerId ?? '',
      magi: waiverData?.receivingMAGI ?? 'N',
      mfp: waiverData?.mfp ?? 'N',
      recordId: waiverData?.recordId ?? 0,
    },
    validationSchema,
    onSubmit: (value) => {
      const isDatesAreInValid = some(
        rowsData,
        ({ entryDate = null, exitDate = null, instituteId = null }) => {
          return (
            dateLiesBetween(
              entryDate,
              exitDate,
              formik.values.entryDate,
              formik.values.instituteId,
              instituteId
            ) ||
            dateLiesBetween(
              entryDate,
              exitDate,
              formik.values.exitDate,
              formik.values.instituteId,
              instituteId
            )
          );
        }
      );
      if (isDatesAreInValid) {
        showErrorToast(
          'Dates cant be in between the Already Existing date period. Please Select Different Dates.'
        );
      } else {
        saveWaiver(value);
      }
    },
  });
  console.log(rowsData);

  function dateLiesBetween(
    startDate,
    endDate,
    date,
    selectRecordId,
    existingRecordId
  ) {
    if (!date) {
      return false;
    }
    if (selectRecordId === existingRecordId) {
      return false;
    }
    return dayjs(date).isBetween(startDate, endDate, 'day', '[]');
  }

  const saveWaiver = async (waiverApiData) => {
    const addPayload = {
      dirtyStatus: createNew ? 'insert' : 'update',
      institutions: [],
      institutionalAdditionalInfo: [],
      waivers: [
        {
          dirtyStatus: createNew ? 'insert' : 'update',
          appDate: waiverApiData.appDate,
          atpStatusCd: waiverApiData.atpStatus,
          mfp: waiverApiData.mfp,
          mfpEndDate: null,
          mfpStartDate: null,
          receivingMAGI: waiverApiData.magi,
          recordId: waiverApiData.recordId,
          waiverDenialCd: waiverApiData.waiverDenial,
          waiverType: waiverApiData.waiverType,
          workerId: '',
        },
      ],
    };
    try {
      showLoader();
      const res = await postWaiver(caseId, currentUserId, addPayload);
      if (res.status === 200) {
        createNew
          ? showSuccessToast('created Sucessfully')
          : showSuccessToast('Data Updated Sucessfully');
        toggleClose();
        waiverAPI(currentUserId);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <Box padding={2}>
      <FormSection>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={6}>
            <Field
              name="waiverType"
              formik={formik}
              label="Waiver Type"
              required
            >
              <Dropdown
                options={waiverTypeOpts}
                placeholder="Select the Waiver Type"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              isDate
              name="appDate"
              formik={formik}
              label="Application Date"
              required
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="atpStatus" formik={formik} label="ATP Status">
              <Dropdown
                options={waiverOpts}
                placeholder="Select the ATP status"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="waiverDenial"
              formik={formik}
              label="Waiver Denial / Closure Reason"
            >
              <Dropdown
                options={waiverDenialOpts}
                placeholder="Select the Waiver Denial / Closure Reason"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="waiverBegin"
              isDate
              formik={formik}
              label="Waiver Begin Date"
            >
              <DatePicker disabled={formik.values.atpStatus !== 'AP'} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="waiverEnd"
              isDate
              formik={formik}
              label="Waiver End Date"
            >
              <DatePicker disabled={formik.values.atpStatus !== 'AP'} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="waiverWorkerID"
              formik={formik}
              label="Waiver Worker ID"
            >
              <Dropdown placeholder="Select the Waiver Worker Id" />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('magi')}
                  // checked={formik.values.magi === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('magi', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Is the person receiving MAGI?"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={formik.values.atpStatus !== 'AP'}
                  {...formik.getFieldProps('mfp')}
                  // checked={formik.values.mfp === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('mfp', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="MFP"
            />
          </Grid>
        </Grid>
      </FormSection>
      <Stack direction={'row'} justifyContent={'flex-end'} mt={1} spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button
          variant="contained"
          disabled={!createNew && !formik.dirty}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save & close
        </Button>
      </Stack>
    </Box>
  );
}

AddWaiver.propTypes = {
  createNew: PropTypes.bool,
  editMode: PropTypes.bool,
};

AddWaiver.defaultProps = {
  createNew: false,
  editMode: false,
};
