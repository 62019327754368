import {
  Checkbox,
  Grid,
  Paper,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Stack,
  Box,
  Button,
  TextField,
  Dialog,
  OutlinedInput,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../../components/FormSection';
import InputField from '../../components/InputField';
import styles from './index.module.css';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import useLookup from '../../hooks/useLookup';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { showLoader, hideLoader } from '../../store/slices/loaderSlice';
import { useDispatch } from 'react-redux';
import {
  smartyStreet,
  contactInfomaton,
  getContactInfo,
} from '../../utils/services/apiRequests';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import Wizard from '../../components/Wizard';
import usePageNav from '../../hooks/usePageNav';
import InputMask from 'react-input-mask';
import { maxLength, number, onlyAlphabet } from '../../utils/normalizers';
import PageContainer from '../../components/PageContainer';

export default function ContactInformation() {
  const [showSmarty, setShowSmarty] = useState(false);
  const [smartyValue, setSmartyValue] = useState('');
  const [contactRes, setContactRes] = useState('');
  const { navigations, caseId } = usePageNav();
  const [addressValue, setAddressValue] = useState('');
  const { getOptions, getLkpMatch } = useLookup('ContactInfo');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    contactInfo();
  }, []);

  const {
    ContactTimeOpts,
    CommunicationModeOpts,
    PhoneTypeOpts,
    StateOpts,
    CountyOpts,
    HearingImpairedOpts,
    PrimaryLanguageOpts,
  } = useMemo(() => {
    return {
      ContactTimeOpts: getOptions('ContactTime'),
      CommunicationModeOpts: getOptions('CommunicationMode'),
      PhoneTypeOpts: getOptions('PhoneType'),
      StateOpts: getOptions('State'),
      CountyOpts: getOptions('County'),
      HearingImpairedOpts: getOptions('HearingImpaired'),
      PrimaryLanguageOpts: getOptions('PrimaryLanguage'),
    };
  }, [getOptions]);

  const contactInfo = async () => {
    try {
      showLoader();
      const res = await getContactInfo(caseId);
      if (res.status === 200) {
        setContactRes(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const smartyStreetApi = async (data) => {
    if (formik.dirty) {
      try {
        showLoader();
        const res = await smartyStreet(data, caseId);
        if (res.status === 200) {
          setSmartyValue(res.data.addressSearches[0].results[0]);
          setShowSmarty(true);
        }
      } catch (error) {
        console.log(error);
        showErrorToast();
      } finally {
        hideLoader();
      }
    } else {
      navigations.toLivingArrangement();
    }
  };

  const selectedAddress = async () => {
    const payloadData =
      formik.values.selectAddress === '1'
        ? {
            ...addressValue,
            hoh: contactRes?.householdMemberDetails,
            caseAddressId: contInfoData?.caseAddressId,
            mailingAddressId: contInfoData?.mailingAddressId,
          }
        : {
            ...addressValue,
            line1: smartyValue?.addressLine1,
            State: smartyValue?.state,
            city: smartyValue?.city,
            zipCode: smartyValue?.zipcode,
            zipCode4: smartyValue?.zip4code,
            hoh: contactRes?.householdMemberDetails,
            caseAddressId: contInfoData?.caseAddressId,
            mailingAddressId: contInfoData?.mailingAddressId,
          };

    try {
      showLoader();
      const res = await contactInfomaton(payloadData, caseId);
      if (res.status === 200) {
        showSuccessToast('Successfully Added Contact Information');
        navigations.toLivingArrangement();
      }
    } catch (error) {
      console.log(error);
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  const toggleClearance = () => {
    setShowSmarty(!showSmarty);
  };

  const validationSchema = Yup.object({
    language: Yup.string().required('Please select the language'),
    line1: Yup.string().required('Please enter the address'),
    city: Yup.string().required('Please enter city'),
    State: Yup.string().required('Please select State'),
    zipCode: Yup.string().required('Please enter zipCode'),
    mailLine1: Yup.string().when('mailAsSame', {
      is: (mailAsSame) => {
        return mailAsSame === false || mailAsSame === 'N';
      },
      then: () => Yup.string().required('Please enter the address'),
      otherwise: () => Yup.string().notRequired(),
    }),
    mailCity: Yup.string().when('mailAsSame', {
      is: (mailAsSame) => {
        return mailAsSame === false || mailAsSame === 'N';
      },
      then: () => Yup.string().required('Please enter the city'),
      otherwise: () => Yup.string().notRequired(),
    }),
    mailState: Yup.string().when('mailAsSame', {
      is: (mailAsSame) => {
        return mailAsSame === false || mailAsSame === 'N';
      },
      then: () => Yup.string().required('Please enter the State'),
      otherwise: () => Yup.string().notRequired(),
    }),
    mailZipCode: Yup.string().when('mailAsSame', {
      is: (mailAsSame) => {
        return mailAsSame === false || mailAsSame === 'N';
      },
      then: () => Yup.string().required('Please enter the zipCode'),
      otherwise: () => Yup.string().notRequired(),
    }),
    mailcounty: Yup.string().when('mailAsSame', {
      is: (mailAsSame) => {
        return mailAsSame === false || mailAsSame === 'N';
      },
      then: () => Yup.string().required('Please enter the county'),
      otherwise: () => Yup.string().notRequired(),
    }),

    numberType: Yup.string().when('phoneNumber', {
      is: (phoneNumber) => {
        return phoneNumber && true;
      },
      then: () => Yup.string().required('Please select type'),
      otherwise: () => Yup.string().notRequired(),
    }),

    // altNumberType: Yup.string().when(('altPhoneNumber', 'mailAsSame'), {
    //   is: (altPhoneNumber, mailAsSame) => {
    //     return (
    //       mailAsSame === false || (mailAsSame === 'N' && altPhoneNumber && true)
    //     );
    //   },
    //   then: () => Yup.string().required('Please select type'),
    //   otherwise: () => Yup.string().notRequired(),
    // }),
  });

  const contInfoData = useMemo(() => {
    const contact = contactRes?.contactInformation?.homeAddress;
    const phone = contactRes?.contactInformation?.phoneNumberDetails;
    const lang = contactRes?.additionalInformation?.preferredLanguage;
    const info = contactRes?.contactInformation;
    const mailing = contactRes?.contactInformation?.mailingAddress;

    return {
      line1: contact?.addressLine1,
      city: contact?.city,
      State: contact?.state,
      zipCode: contact?.zipcode,
      zipCode4: contact?.zip4code,
      county: contact?.county,
      caseAddressId: contact?.caseAddressId,
      phoneNumber: phone?.phoneNumber,
      ext: phone?.phoneNumberExtension,
      altPhoneNumber: phone?.altPhoneNumber,
      altExt: phone?.altPhoneNumberExtension,
      preLang: lang,
      addressId: contact?.addressId,
      email: info?.email,
      communicationMode: info?.preferredModeOfCommunication,
      communicationlang: info?.communicationLanguage,
      numberType: phone?.phoneNumberTypeCd,
      maileLine1: mailing?.addressLine1,
      maileLine2: mailing?.addressLine2,
      maileCity: mailing?.city,
      maileState: mailing?.state,
      mailingZipCode: mailing?.zipcode,
      maileZipCode4: mailing?.zip4code,
      maileCounty: mailing?.county,
      maileCaseAddressId: mailing?.caseAddressId,
      mailingAddressId: mailing?.addressId,
    };
  }, [contactRes]);

  const formik = useFormik({
    initialValues: {
      Hearing: false,
      Visually: false,
      Physicallly: false,
      Interpreter: false,
      addressType: '',
      mailAsSame:
        contactRes?.contactInformation?.sameMailingAddressYN === 'Y' ?? true,
      mailingaddresstype: 'local',
      language: contInfoData.preLang ?? '',
      care: '',
      line1: contInfoData.line1 ?? '',
      line2: '',
      city: contInfoData.city ?? '',
      State: contInfoData.State ?? '',
      zipCode: contInfoData.zipCode ?? '',
      zipCode4: contInfoData.zipCode4 ?? '',
      county: contInfoData.county ?? '',
      mailCare: contInfoData.mailing,
      mailLine1: contInfoData.maileLine1,
      mailLine2: contInfoData.maileLine2,
      mailCity: contInfoData.maileCity,
      mailState: contInfoData.maileState,
      mailZipCode: contInfoData.mailingZipCode,
      mailZipCode4: contInfoData.maileZipCode4,
      mailcounty: contInfoData.maileCounty,
      phoneNumber: contInfoData.phoneNumber ?? '',
      ext: contInfoData.ext ?? '',
      numberType: contInfoData.numberType ?? '',
      altPhoneNumber: contInfoData.altPhoneNumber ?? '',
      altExt: contInfoData.altExt ?? '',
      altNumberType: '',
      email: contInfoData.email ?? '',
      communicationMode: contInfoData.communicationMode ?? '',
      communicationlang: contInfoData.communicationlang ?? '',
      selectAddress: '',
      addressId: contInfoData.addressId ?? 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (value) => {
      setAddressValue(value);
      smartyStreetApi(value);
    },
  });

  const disableMailingAddress = useMemo(() => {
    const { addressType, mailingaddresstype } = formik.values;
    if (addressType === 'Homeless') {
      return mailingaddresstype === 'local';
    }
    return false;
  }, [formik.values]);

  // const onNext = () => {
  //   navigations.toHhMembers();
  // };

  const onBack = () => {
    navigations.toCitizenship();
  };

  return (
    <Paper
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ padding: '10px' }}
    >
      <Dialog
        open={showSmarty}
        onClose={toggleClearance}
        title="Verify the address"
      >
        <Box sx={{ width: '500px', margin: 2, padding: 2 }}>
          <Typography variant="h4" marginBottom={2}>
            Home Address
          </Typography>

          <RadioGroup row>
            <Grid>
              <Typography variant="h5" color={'gray'}>
                Original Address:
              </Typography>
              <FormControlLabel
                name="selectAddress"
                value={1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                control={<Radio />}
                label={
                  formik.values.line1 +
                  ' ' +
                  formik.values.city +
                  ' ' +
                  formik.values.State +
                  ' ' +
                  formik.values.zipCode
                }
              />
            </Grid>

            <Grid>
              <Typography variant="h5" color={'gray'}>
                Suggested Address:
              </Typography>

              <FormControlLabel
                name="selectAddress"
                value={2}
                disabled={!smartyValue?.addressLine1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                control={<Radio />}
                label={
                  !smartyValue?.addressLine1
                    ? 'No address Found'
                    : smartyValue?.addressLine1 +
                      ' ' +
                      smartyValue?.city +
                      ' ' +
                      smartyValue?.state +
                      ' ' +
                      smartyValue?.zipcode +
                      ' ' +
                      smartyValue?.zip4code
                }
              />
            </Grid>
          </RadioGroup>

          <Stack justifyContent={'flex-end'}>
            <Button
              disabled={!formik.values.selectAddress}
              variant="contained"
              onClick={selectedAddress}
            >
              Save & Next
            </Button>
          </Stack>
        </Box>
      </Dialog>
      <Typography sx={{ margin: '1rem 0 2rem 0' }} variant="h2">
        Contact Information
      </Typography>

      <Grid container rowGap={5} marginTop={1}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Field name="language" label="Language" formik={formik} required>
              <Dropdown
                options={PrimaryLanguageOpts}
                placeholder="Select Language"
              />
            </Field>
          </Grid>

          <Grid item xs={4} alignSelf="end" className={styles.contactCheck}>
            <Checkbox
              id="Interpreter"
              name="Interpreter"
              {...formik.getFieldProps('Interpreter')}
            />
            <Typography variant="subtitle2">Interpreter Needed</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormSection label="Impairment">
            <Grid container spacing={2}>
              <Grid item xs={4} className={styles.contactCheck}>
                <Checkbox
                  id="Hearing"
                  name="Hearing"
                  {...formik.getFieldProps('Hearing')}
                />

                <Typography variant="subtitle2">Hearing Impaired</Typography>
              </Grid>

              <Grid item xs={4} className={styles.contactCheck}>
                <Checkbox
                  id="Visually"
                  name="Visually"
                  {...formik.getFieldProps('Visually')}
                />

                <Typography variant="subtitle2"> Visually Impaired</Typography>
              </Grid>

              <Grid item xs={4} className={styles.contactCheck}>
                <Checkbox
                  id="Physicallly"
                  name="Physicallly"
                  {...formik.getFieldProps('Physicallly')}
                />

                <Typography variant="subtitle2">
                  Physicallly Impaired
                </Typography>
              </Grid>
              {formik.values.Hearing && (
                <Grid item sm={3} mt={2} ml={1} direction={'column'}>
                  <Field name="HearinSelect" formik={formik}>
                    <Dropdown
                      options={HearingImpairedOpts}
                      placeholder="Select any option"
                    />
                  </Field>
                </Grid>
              )}
            </Grid>
          </FormSection>
        </Grid>

        <Grid item xs={12}>
          <FormSection label="Residential Address">
            <Grid container>
              <RadioGroup row defaultValue={''}>
                <FormControlLabel
                  name="addressType"
                  value={''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  control={<Radio />}
                  label="None"
                />
                <FormControlLabel
                  name="addressType"
                  value={'Homeless'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  control={<Radio />}
                  label="Homeless"
                />
                <FormControlLabel
                  name="addressType"
                  value={'Institution'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  control={<Radio />}
                  label="Institution"
                />
              </RadioGroup>
            </Grid>
            <Grid container spacing={2}>
              {formik.values.addressType !== 'Homeless' && (
                <>
                  <Grid item xs={12}>
                    <Field
                      name="care"
                      label="C/O"
                      formik={formik}
                      normalizers={[maxLength(30), onlyAlphabet]}
                    >
                      <TextField
                        disabled={formik.values.addressType === 'Institution'}
                        placeholder="Enter C/O"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="line1"
                      label="Address Line 1"
                      formik={formik}
                      required
                      normalizers={[maxLength(30)]}
                    >
                      <TextField
                        disabled={formik.values.addressType === 'Institution'}
                        placeholder="Enter Line 1"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="line2"
                      label="Address Line 2"
                      formik={formik}
                      normalizers={[maxLength(30), onlyAlphabet]}
                    >
                      <TextField
                        disabled={formik.values.addressType === 'Institution'}
                        placeholder="Enter Line 2"
                      />
                    </Field>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Field
                  name="city"
                  label="City"
                  formik={formik}
                  required
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField
                    disabled={formik.values.addressType === 'Institution'}
                    placeholder="Enter City"
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="State" label="State" formik={formik} required>
                  <Dropdown options={StateOpts} placeholder="Select State" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="zipCode"
                  label="Zip Code"
                  formik={formik}
                  required
                  normalizers={[maxLength(5), number]}
                >
                  <TextField placeholder="Enter Zip Code" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="zipCode4"
                  label="Zip+4 Code"
                  formik={formik}
                  normalizers={[maxLength(4), number]}
                >
                  <TextField
                    disabled={formik.values.addressType === 'Institution'}
                    placeholder="Enter Zip+4 Code"
                  />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="county" label="County" formik={formik} required>
                  <Dropdown options={CountyOpts} placeholder="Select County" />
                </Field>
              </Grid>
            </Grid>
          </FormSection>
          <Grid item mt={3} xs={12}>
            <FormSection label="Mailing Address">
              <Grid container>
                {formik.values.addressType !== 'Homeless' ? (
                  <Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // Checked={contactRes?.sameMailingAddressYN === 'Y'}
                          id="mailAsSame"
                          name="mailAsSame"
                          {...formik.getFieldProps('mailAsSame')}
                          checked={formik.values.mailAsSame}
                        />
                      }
                      label="Mailing address is same as residential address"
                    />
                  </Grid>
                ) : (
                  <RadioGroup row defaultValue={'local'}>
                    <FormControlLabel
                      name="mailingaddresstype"
                      value={'local'}
                      formik={formik}
                      control={<Radio />}
                      label="Loacal District Office"
                    />

                    <FormControlLabel
                      name="mailingaddresstype"
                      value={'mailAddress'}
                      formik={formik}
                      control={<Radio />}
                      label="Mailing Address"
                    />
                  </RadioGroup>
                )}
              </Grid>
              {!formik.values.mailAsSame && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="mailCare"
                      label="C/O"
                      formik={formik}
                      normalizers={[maxLength(5), onlyAlphabet]}
                    >
                      <TextField
                        disabled={disableMailingAddress}
                        placeholder="Enter C/O"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mailLine1"
                      label="Address Line 1"
                      formik={formik}
                      required
                      normalizers={[maxLength(30)]}
                    >
                      <TextField
                        disabled={disableMailingAddress}
                        placeholder="Enter Line 1"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mailLine2"
                      label="Address Line 2"
                      formik={formik}
                      normalizers={[maxLength(30)]}
                    >
                      <TextField
                        disabled={disableMailingAddress}
                        placeholder="Enter Line 2"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mailCity"
                      label="City"
                      formik={formik}
                      required
                      normalizers={[maxLength(30), onlyAlphabet]}
                    >
                      <TextField
                        disabled={disableMailingAddress}
                        placeholder="Enter City"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mailState"
                      label="State"
                      formik={formik}
                      required
                    >
                      <Dropdown
                        options={StateOpts}
                        placeholder="Select State"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mailZipCode"
                      label="Zip Code"
                      formik={formik}
                      required
                      normalizers={[maxLength(5), number]}
                    >
                      <TextField
                        disabled={disableMailingAddress}
                        placeholder="Enter Zip Code"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mailZipCode4"
                      label="Zip+4 Code"
                      formik={formik}
                      normalizers={[maxLength(4), number]}
                    >
                      <TextField
                        disabled={disableMailingAddress}
                        placeholder="Enter Zip+4 Code"
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mailcounty"
                      label="County"
                      formik={formik}
                      required
                    >
                      <Dropdown
                        options={CountyOpts}
                        disabled={disableMailingAddress}
                        placeholder="Select County"
                      />
                    </Field>
                  </Grid>
                </Grid>
              )}
            </FormSection>
          </Grid>
          <Grid mt={3}>
            <FormSection label="Phone">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Field
                    name="phoneNumber"
                    label="Phone Number"
                    formik={formik}
                  >
                    <InputMask mask="(999)999-9999" maskChar={null}>
                      {(params) => (
                        <OutlinedInput
                          variant="outlined"
                          placeholder="Enter Number"
                          {...params}
                        />
                      )}
                    </InputMask>
                    {/* <TextField
                      inputProps={{ maxLength: 10 }}
                      placeholder="Please enter Number"
                    /> */}
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="ext"
                    label="Ext."
                    formik={formik}
                    normalizers={[maxLength(5), number]}
                  >
                    <TextField placeholder="Enter Ext." />
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="numberType"
                    label="Type"
                    formik={formik}
                    required={formik.values.phoneNumber}
                  >
                    <Dropdown
                      options={PhoneTypeOpts}
                      placeholder="Select Type"
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>

          <Grid mt={3}>
            <FormSection label="Alternate Phone">
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Field
                    name="altPhoneNumber"
                    label="Phone Number"
                    formik={formik}
                  >
                    <InputMask mask="(999)999-9999" maskChar={null}>
                      {(params) => (
                        <OutlinedInput
                          variant="outlined"
                          placeholder="Enter Number"
                          {...params}
                        />
                      )}
                    </InputMask>
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="altExt"
                    label="Ext."
                    formik={formik}
                    normalizers={[maxLength(5), number]}
                  >
                    <TextField placeholder="Enter Ext." />
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="altNumberType"
                    label="Type"
                    formik={formik}
                    required={formik.values.altPhoneNumber}
                  >
                    <Dropdown
                      options={PhoneTypeOpts}
                      placeholder="Select Type"
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
          <Grid mt={3}>
            <FormSection label="Email">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field name="email" label="Email" formik={formik}>
                    <TextField placeholder="Enter Email" />
                  </Field>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
          <Grid mt={3}>
            <FormSection label="Preferred Mode of Communication">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name="communicationMode"
                    label="Mode of Communication"
                    formik={formik}
                  >
                    <Dropdown
                      options={CommunicationModeOpts}
                      placeholder="Select Mode of communication"
                    />
                  </Field>
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name="communicationlang"
                    label="Communication Language"
                    formik={formik}
                  >
                    <Dropdown
                      options={PrimaryLanguageOpts}
                      placeholder="Select Communication Language"
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{ padding: '2rem 1rem' }}
      >
        <Box>
          <Button onClick={onBack} sx={{ mr: '1rem' }} variant="outlined">
            Back
          </Button>
          <Button onClick={formik.handleSubmit} variant="contained">
            Next
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
}
