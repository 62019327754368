import {
  Grid,
  Paper,
  Stack,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import Field from '../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dropdown from '../../../components/Dropdown';
import useLookup from '../../../hooks/useLookup';
import { useFormik } from 'formik';
import { postRedeterminationHistory } from '../../../utils/services/RedeterminationServices/requests';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import usePageNav from '../../../hooks/usePageNav';
import * as Yup from 'yup';
import IndividualDetailsTable from '../../IndividualDetails/IndividualDetailsTable';
import { map } from 'lodash';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';

function RedeterminHistory() {
  const [data, setData] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const { getOptions, getLkpMatch } = useLookup('RedeterminationHistory');
  const navigate = useNavigate();
  const { caseId } = usePageNav();
  const { RequestedProgramOpts } = useMemo(() => {
    return {
      RequestedProgramOpts: getOptions('RequestedPrograms'),
    };
  }, [getOptions]);

  const redeterminationHistory = async (data) => {
    try {
      showLoader();
      const res = await postRedeterminationHistory(
        data,
        data.caseId ? data.caseId : caseId
      );
      if (res.status === 200) {
        const modifyData = map(res.data, (item, index) => {
          return { ...item, id: index };
        });

        setData(modifyData);
      }
    } catch (error) {
      setData([]);
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const validationSchema = Yup.object({
    caseId: Yup.string().required('Please enter a valid 9 digit code'),
    fromDate: Yup.string().required('Please select a date'),
    programName: Yup.string().required('Please select a Program'),
  });

  const columns = useMemo(
    () => [
      {
        field: 'redeterminationBeginDt',
        headerName: 'Redetermination Begin Date',
        flex: 1,
      },

      {
        field: 'redeterminationDueDate',
        headerName: 'Redetermination Due Date',
        flex: 1,
      },
      {
        field: 'redeterminationStatusCd',
        headerName: 'Status',
        renderCell: (params) =>
          getLkpMatch(
            'RedeterminationStatus',
            params?.row?.redeterminationStatusCd
          ),

        flex: 1,
      },
      {
        field: 'originalDueDate',
        headerName: 'Original Due Date',
        flex: 1,
      },
      {
        field: 'redeterminationStatusDt',
        headerName: 'Redetermination Status Date',
        flex: 1,
      },
      {
        field: 'packetReceivedDate',
        headerName: 'Packet Received Date',
        flex: 1,
      },
    ],
    [getLkpMatch]
  );

  const formik = useFormik({
    initialValues: {
      caseId: caseId ?? '',
      programName: '',
      fromDate: '',
      toDate: '',
    },
    validationSchema,
    onSubmit: (values) => {
      redeterminationHistory(values);
      navigate(`/dashboard/redeterminationhistory/${values.caseId}`);
    },
  });

  return (
    <Paper onSubmit={formik.handleSubmit} sx={{ padding: '1rem' }}>
      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ mb: '2rem' }}>
          <Field name="caseId" label="Case ID" formik={formik} required>
            <TextField placeholder="Enter Case ID" />
          </Field>
        </Grid>
        <Grid item xs={3} sx={{ mb: '2rem' }}>
          <Field
            name="programName"
            label="Program Name"
            formik={formik}
            required
          >
            <Dropdown
              options={RequestedProgramOpts}
              placeholder="Select Status"
            />
          </Field>
        </Grid>
        <Grid item xs={3} sx={{ mb: '1rem' }}>
          <Field
            required
            name="fromDate"
            label="From Date"
            isDate
            formik={formik}
          >
            <DatePicker disableFuture value={dayjs(formik.values.fromDate)} />
          </Field>
        </Grid>
        <Grid item xs={3} sx={{ mb: '1rem' }}>
          <Field name="toDate" label="To Date" isDate formik={formik}>
            <DatePicker disableFuture value={dayjs(formik.values.toDate)} />
          </Field>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Grid item xs={3} sx={{ mb: '1rem' }}>
          <Button variant="outlined" type="submit" onClick={formik.resetForm}>
            Clear
          </Button>
        </Grid>
        <Grid item xs={3} sx={{ mb: '1rem' }}>
          <Button
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Search
          </Button>
        </Grid>
      </Stack>
      {data.length > 0 && (
        <div>
          <Typography variant="h4">Redetermination History</Typography>
          <IndividualDetailsTable rows={data} columns={columns} />
        </div>
      )}
    </Paper>
  );
}

export default RedeterminHistory;
