import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Tabs,
  FormControlLabel,
  Typography,
  Checkbox,
  Tab,
  Grid,
  TextField,
  Stack,
  Button,
} from '@mui/material';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../components/Dropdown';
import Field from '../../../components/Field';
import FormSection from '../../../components/FormSection';
import { Form, useFormik } from 'formik';
import useLookup from '../../../hooks/useLookup';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import usePageNav from '../../../hooks/usePageNav';
import { map } from 'lodash';
import { getFullName } from '../../../utils/helpers';
import { healthInsurance } from '../../../utils/services/MedicareDetails/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { maxLength, number, onlyAlphabet } from '../../../utils/normalizers';

const labelStyle = {
  fontSize: '16px',
  fontWeight: '700',
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function InsuranceDetails({
  open,
  toggleInsurance,
  selected,
  individualId,
  createInsurance,
  insuranceEdit,
  getInsurancePolicy,
  toggleAddInsurance,
}) {
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { individualId: currentUserId } = selected;
  const { caseId, navigations } = usePageNav();
  const [activeTab, setActiveTab] = useState(0);

  const { getOptions, getLkpMatch } = useLookup('MediCare');

  const memberOpts = useMemo(() => {
    return map(houseMembers, ({ personName }) => {
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      return {
        label: fullName,
        value: fullName,
      };
    });
  }, [houseMembers]);

  const { suffixOpts, sourceOpts, stateOpts, relationOpts } = useMemo(() => {
    return {
      suffixOpts: getOptions('Suffix'),
      stateOpts: getOptions('State'),
      sourceOpts: getOptions('PolicySource'),
      relationOpts: getOptions('RelationshipType'),
    };
  }, [getOptions]);

  const handleNext = () => {
    setActiveTab((prevTab) => prevTab + 1);
  };

  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const validationSchemaPolicy = Yup.object({
    policyNumber: Yup.string()
      .required('Please Enter the Policy Number')
      .length(11, 'Policy Number must be exactly 11 characters'),
    fromDate: Yup.string().required('Please Enter The Date'),
    toDate: Yup.string().required('Please Enter The Date'),
    source: Yup.string().required('Please Select the Source'),
    memberSelection: Yup.string().required('Please Select the Member'),
    firstName: Yup.string().required('Please Enter the First Name'),
    lastName: Yup.string().required('Please Enter the Last Name'),
  });

  const validationSchemaCarrier = Yup.object({
    carrierName: Yup.string().required('Please Enter Carrier Name'),
    planName: Yup.string().required('Please Enter Plan Name'),
  });

  const policyValidation = Yup.object({
    memberSelection: Yup.string().when('policyHolder', {
      is: (policyHolder) => {
        return policyHolder === 'Y ';
      },
      then: () => Yup.string().required('Please Select Member'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      dirtyStatus: createInsurance ? 'insert' : 'update',
      policyNumber: insuranceEdit?.policyNumber ?? '',
      groupNumber: insuranceEdit?.groupNumber ?? '',
      fromDate: insuranceEdit?.policyEffectiveDate ?? '',
      toDate: insuranceEdit?.policyEndDate ?? '',
      source: insuranceEdit?.policySource ?? '',
      policyHolder: insuranceEdit?.policyHolderFromHouseholdYN ?? '',
      memberSelection: insuranceEdit?.policyHolderFromHouseholdYN ?? '',
      relationshipmem: insuranceEdit?.relationshipCd ?? '',
      firstName: insuranceEdit?.policyHolderName?.firstName ?? '',
      middleName: insuranceEdit?.policyHolderName?.middleName ?? '',
      lastName: insuranceEdit?.policyHolderName?.lastName ?? '',
      suffix: insuranceEdit?.policyHolderName?.suffix ?? '',
      relationship: insuranceEdit?.relationshipCd ?? '',
      addressLine1: insuranceEdit?.policyHolderAddress?.addressLine1 ?? '',
      addressLine2: insuranceEdit?.policyHolderAddress?.addressLine2 ?? '',
      city: insuranceEdit?.policyHolderAddress?.city ?? '',
      zipCode: insuranceEdit?.policyHolderAddress?.zipcode ?? '',
      state: insuranceEdit?.policyHolderAddress?.state ?? '',
      phoneNumber: insuranceEdit?.policyHolderPhone?.phoneNumber ?? '',
      carrierName: insuranceEdit?.carrierName ?? '',
      planName: insuranceEdit?.planName ?? '',
      address1: insuranceEdit?.carrierAddress?.addressLine1 ?? '',
      address2: insuranceEdit?.carrierAddress?.addressLine2 ?? '',
      cityCar: insuranceEdit?.carrierAddress?.city ?? '',
      zipCar: insuranceEdit?.carrierAddress?.zipcode ?? '',
      stateCar: insuranceEdit?.carrierAddress?.state ?? '',
      phoneCar: insuranceEdit?.carrierPhone?.phoneNumber ?? '',
      unionName: insuranceEdit?.unionName ?? '',
      localName: insuranceEdit?.unionLocal ?? '',
      address1Union: insuranceEdit?.unionAddress?.addressLine1 ?? '',
      address2Union: insuranceEdit?.unionAddress?.addressLine2 ?? '',
      cityUnion: insuranceEdit?.unionAddress?.city ?? '',
      zipUnion: insuranceEdit?.unionAddress?.zipcode ?? '',
      stateUnion: insuranceEdit?.unionAddress?.state ?? '',
      recordId: insuranceEdit?.recordId ?? 0,
      phoneUnion: insuranceEdit?.unionPhone?.phoneNumber ?? '',
    },
    enableReinitialize: true,
    policyValidation,
    validationSchemaPolicy,
    validationSchemaCarrier,
    onSubmit: (values) => {
      postInsurancePolicy(values);
    },
  });

  const areMandatoryFieldsFilled = () => {
    if(formik.values.policyHolder === "Y" ){
      return true;
    }else{
    const mandatoryFields = [
      'policyNumber',
      'fromDate',
      'toDate',
      'source',
      'firstName',
      'lastName',
    ];
    return mandatoryFields.every((field) => !!formik.values[field]);
    }
  };
  const carrierMandatory = () => {
      const carrierMandatory = ['carrierName', 'planName'];
      return carrierMandatory.every((field) => !!formik.values[field]);
  };
  
  const postInsurancePolicy = async (data) => {
    try {
      showLoader();
      const res = await healthInsurance(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('SuccesFully Added Insurance Details');
        toggleInsurance();
        getInsurancePolicy();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  console.log("qwerty",areMandatoryFieldsFilled());

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          onChange={onTabChange}
          value={activeTab}
          variant="fullWidth"
          centered
        >
          <Tab label="Policy & Holder" />
          <Tab label="Carrier & Address" />
          <Tab label="Union & Address" />
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <FormSection label="Policy" mt="1.5rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="Policy Number"
                  formik={formik}
                  name="policyNumber"
                  required
                  normalizers={[maxLength(13)]}
                >
                  <TextField placeholder="Enter Policy Number" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Group Number"
                  formik={formik}
                  name="groupNumber"
                  normalizers={[maxLength(13)]}
                >
                  <TextField placeholder="Enter Group Number" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="From"
                  formik={formik}
                  name="fromDate"
                  isDate
                  required
                >
                  <DatePicker disableFuture placeholder="Select Date" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field label="To" formik={formik} name="toDate" isDate required>
                  <DatePicker placeholder="Select Date" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field label="Source" formik={formik} name="source" required>
                  <Dropdown options={sourceOpts} placeholder="Select Source" />
                </Field>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection label="Policy Holder" mt="1.5rem">
            <Grid item xs={12}>
              <FormControlLabel
                name="policyHolder"
                control={
                  <Checkbox
                    {...formik.getFieldHelpers('policyHolder')}
                    checked={formik.values.policyHolder === 'Y'}
                    onChange={(e, checked) => {
                      formik.setFieldValue('policyHolder', checked ? 'Y' : 'N');
                    }}
                    value={formik.values.policyHolder}
                  />
                }
                label={
                  <Typography style={labelStyle}>
                    Is Policy Holder Someone From HouseHold?
                  </Typography>
                }
              />
            </Grid>
            {formik.values.policyHolder === 'Y' ? (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    label="Member"
                    name="memberSelection"
                    formik={formik}
                    required={formik.errors.memberSelection}
                  >
                    <Dropdown
                      options={memberOpts}
                      placeholder="Select Member"
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Relationship"
                    name="relationshipmem"
                    formik={formik}
                  >
                    <Dropdown
                      options={relationOpts}
                      placeholder="Select Relationship"
                    />
                  </Field>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} mt="0.5rem">
                <Grid item xs={3}>
                  <Field
                    label="First Name"
                    formik={formik}
                    name="firstName"
                    required
                    normalizers={[maxLength(30), onlyAlphabet]}
                  >
                    <TextField placeholder="Enter First Name" />
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    label="Middle Name"
                    formik={formik}
                    name="middletName"
                    normalizers={[maxLength(30), onlyAlphabet]}
                  >
                    <TextField placeholder="Enter Middle Name" />
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    label="Last Name"
                    formik={formik}
                    name="lastName"
                    required
                    normalizers={[maxLength(30), onlyAlphabet]}
                  >
                    <TextField placeholder="Enter Last Name" />
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field label="Suffix" formik={formik} name="suffix">
                    <Dropdown
                      options={suffixOpts}
                      placeholder="Select Suffix"
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Relationship"
                    formik={formik}
                    name="relationship"
                  >
                    <Dropdown
                      options={relationOpts}
                      placeholder="Select Relationship"
                    />
                  </Field>
                </Grid>
              </Grid>
            )}
          </FormSection>
          <FormSection label="Policy Holder Address">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="Address Line 1"
                  formik={formik}
                  name="addressLine1"
                  normalizers={[maxLength(30)]}
                >
                  <TextField placeholder="Enter Address Line 1 " />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  label="Address Line 2"
                  formik={formik}
                  name="addressLine2"
                  normalizers={[maxLength(30)]}
                >
                  <TextField placeholder="Enter Address Line 2" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="City"
                  formik={formik}
                  name="city"
                  normalizers={[maxLength(30)]}
                >
                  <TextField placeholder="Enter City" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Zip Code"
                  formik={formik}
                  name="zipCode"
                  normalizers={[maxLength(5), number]}
                >
                  <TextField placeholder="EnterZip Code" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field label="State" formik={formik} name="state">
                  <Dropdown options={stateOpts} placeholder="Select State" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Phone Number"
                  formik={formik}
                  name="phoneNumber"
                  normalizers={[maxLength(10), number]}
                >
                  <TextField placeholder="Enter Phone Number " />
                </Field>
              </Grid>
            </Grid>
          </FormSection>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <FormSection label="Carrier" mt="1.5rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="Carrier Name"
                  formik={formik}
                  name="carrierName"
                  required
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField placeholder="Enter Carrier Name" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Plan Name"
                  name="planName"
                  formik={formik}
                  required
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField placeholder="Enter Plan Name" />
                </Field>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection label="Carrier Address">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="Address Line 1"
                  formik={formik}
                  name="address1"
                  normalizers={[maxLength(30)]}
                >
                  <TextField placeholder="Enter Address Line 1 " />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  label="Address Line 2"
                  formik={formik}
                  name="address2"
                  normalizers={[maxLength(30)]}
                >
                  <TextField placeholder="Enter Address Line 2" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="City"
                  formik={formik}
                  name="cityCar"
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField placeholder="Enter City" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Zip Code"
                  formik={formik}
                  name="zipCar"
                  normalizers={[maxLength(5), number]}
                >
                  <TextField placeholder="EnterZip Code" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field label="State" formik={formik} name="stateCar">
                  <Dropdown options={stateOpts} placeholder="Select State" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Phone Number"
                  formik={formik}
                  name="phoneCar"
                  normalizers={[maxLength(10), number]}
                >
                  <TextField placeholder="Enter Phone Number " />
                </Field>
              </Grid>
            </Grid>
          </FormSection>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <FormSection label="Union" mt="1.5rem">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="Union Name"
                  formik={formik}
                  name="unionName"
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField placeholder="Enter Union Name" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Local Name"
                  name="localName"
                  formik={formik}
                  normalizers={[maxLength(4), onlyAlphabet]}
                >
                  <TextField placeholder="Enter Local Name" />
                </Field>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection label="Union Address">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  label="Address Line 1"
                  formik={formik}
                  name="address1Union"
                  normalizers={[maxLength(30)]}
                >
                  <TextField placeholder="Enter Address Line 1 " />
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field
                  label="Address Line 2"
                  formik={formik}
                  name="address2Union"
                  normalizers={[maxLength(30)]}
                >
                  <TextField placeholder="Enter Address Line 2" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="City"
                  formik={formik}
                  name="cityUnion"
                  normalizers={[maxLength(30), onlyAlphabet]}
                >
                  <TextField placeholder="Enter City" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Zip Code"
                  formik={formik}
                  name="zipUnion"
                  normalizers={[maxLength(5), number]}
                >
                  <TextField placeholder="EnterZip Code" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field label="State" formik={formik} name="stateUnion">
                  <Dropdown options={stateOpts} placeholder="Select State" />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  label="Phone Number"
                  formik={formik}
                  name="phoneUnion"
                  normalizers={[maxLength(10), number]}
                >
                  <TextField placeholder="Enter Phone Number " />
                </Field>
              </Grid>
            </Grid>
          </FormSection>
        </TabPanel>
      </Box>
      {activeTab === 0 && (
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={toggleInsurance}>
            Discard
          </Button>
          <Button
            variant="contained"
            disabled={!formik.isValid || !areMandatoryFieldsFilled()}
            onClick={handleNext}
          >
            Next1
          </Button>
        </Stack>
      )}

      {activeTab === 1 && (
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={toggleInsurance}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={!formik.isValid || !carrierMandatory()}
          >
            Next2
          </Button>
        </Stack>
      )}
      {activeTab == 2 && (
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={toggleInsurance}>
            Discard
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
          >
            Save
          </Button>
        </Stack>
      )}
    </>
  );
}

export default InsuranceDetails;
