import dayjs from 'dayjs';
import { map } from 'lodash';

export const hohPayload = (data) => {
  const { controlId } = data;

  return {
    controlId: controlId,
    screenDefn: '/dashboard/consumer-app/intake-app/hoh/hohInfo',
  };
};

export const disabilityPayload = (data) => {
  const { controlId } = data;

  return {
    controlId: controlId,
    screenDefn: '/dashboard/consumer-app/intake-app/hoh/disabilityInfo',
  };
};

export const membersPayload = (data) => {
  const { controlId } = data;

  return {
    controlId: controlId,
    screenDefn: '/dashboard/consumer-app/intake-app/members/memberInfo',
  };
};

export const recallIndividualPayload = (data) => {
  return {
    irn: data.irn,
  };
};

export const clearancePayload = (data) => {
  let client = data?.clientContainer?.client;
  return {
    address: null,
    paginationData: { resultsPerPage: 5, pageNumber: 0, totalRecords: 0 },
    range: null,
    search: {
      dirtyStatus: '',
      personName: client?.personName ?? {},
      dob: dayjs(client?.dob).format('YYYY-MM-DD') ?? '',
      soSec: client?.soSec ?? {},
      gender: client?.gender ?? '',
      race: client?.race ?? {},
      deletedYN: 'N',
      boarderStatusYN: 'N',
      individualId: data?.individualId ?? '',
      headOfHouseholdYN: client?.headOfHouseholdYN ?? '',
      relationshipCd: client?.relationshipCd ?? '',
      relations: [],
      altNames: [],
      additionalSsns: [],
      pregnantYN: '',
      aliasClientIds: [],
      memberClearedYN: 'N',
      memberEditableYN: 'N',
      cases: [],
      overrideEligibility: [],
      dobVerification: client?.dobVerification ?? {},
      hasSsnYN: client?.hasSsnYN ?? '',
      eligibility: {
        dirtyStatus: '',
        programs: [],
        expeditedFoodStampsYN: 'N',
        scrEligibilityRunTimestamp: '',
      },
      approvedEligibility: {
        dirtyStatus: '',
        programs: [],
        expeditedFoodStampsYN: 'N',
        scrEligibilityRunTimestamp: '',
      },
      maritalStatus: client?.maritalStatus ?? {},
      ethnicity: client?.ethnicity ?? '',
      financialResposibility: '',
      applicablePrograms: [],
      ssnReferralDate: null,
      individualDetails: {
        deathDetails: client?.individualDetails?.deathDetails,
        pregnancy: map(client?.individualDetails?.pregnancy, (ele) => {
          return {
            groupNumber: 0,
            pregnancyDueDate:
              dayjs(ele.pregnancyDueDate).format('YYYY-MM-DD') ?? '',
            pregnantYN: ele.pregnantYN,
            recordId: 0,
          };
        }),
      },
      medicare: {},
      citizenshipImmigration: {},
      priorCitizenshipImmigration: [],
      studentInfo: [],
      disabilities: [],
      insuranceInformation: {},
      institutionalInformation: {},
      assets: [],
      earnedIncomes: [],
      unearnedIncomes: [],
      appliedUnearnedIncomes: [],
      potentialAssets: [],
      unearnedIncomeApplications: [],
      dependentCareExpenses: [],
      shelterExpenses: [],
      shelterUtilityExpenses: [],
      utilityExpenseType: '',
      utilityExpenses: [],
      specialNeeds: [],
      ccTriggerInsert: [],
      medicalExpenses: [],
      childSpousalExpenses: [],
      disabledYN: 'N',
      refugeeYN: 'N',
      blindYN: 'N',
      destituteMigrantYN: 'N',
      shelterIncludesMealsYN: 'N',
      protectiveLivingArrangementYN: 'N',
      communityBasedWaiverYN: 'N',
      medicarePartAEntitlementYN: 'N',
      absentParentYN: 'N',
      programRequest: [],
      insurancePolicies: [],
      outStandingVerifications: [],
      individualTaxStatus: {},
      irn: null,
      taxFilingStatuses: [],
      createMdmId: true,
      mdmId: null,
      errInd: '',
      maId: '',
      maIdSuffix: '',
      individualBoarder: [],
      caseStatus: '',
      pregnancy: [],
      delteMemberIndicator: '',
      institution: null,
      institutionName: '',
      aboutMember: {},
      hohVerifiedYN: '',
      sourceSystem: client?.sourceSystem ?? '',
      fortyQuartersInd: false,
    },
    systemCd: data?.systemCd ?? '',
  };
};

export const postClearancePayload = (
  data,
  caseId,
  dirtyStatus,
  individualId
) => {
  let item = data?.clientContainer?.client;

  return {
    dirtyStatus: dirtyStatus ?? '',
    individualId: data?.individualId ?? null,
    clearanceStatus: caseId ? 'Y' : 'N',
    controlId: data?.controlId ?? null,
    status: '',
    importSelected: true,
    editable: '',
    importedFromWp: '',
    realIndividualId: individualId ?? null,
    clientContainer: {
      results: [],
      paginationData: {},
      range: null,
      systemCd: '',
      associateCaseId: caseId ? caseId : 'new',
      client: {
        personName: {
          firstName: item?.personName?.firstName ?? '',
          lastName: item?.personName?.lastName ?? '',
          middleName: item?.personName?.middleName ?? '',
          suffix: item?.personName?.suffix ?? null,
        },
        dob: item?.dob ? dayjs(item?.dob).format('YYYY-MM-DD') : null,
        maritalStatus: {
          marriedYN: item?.maritalStatus?.marriedYN ?? '',
        },
        ethnicity: item?.ethnicity ? item?.ethnicity : '',
        gender: item?.gender ? item?.gender : '',
        soSec: {
          dirtyStatus: '',
          soSecPart1: item?.soSec?.soSecPart1 ?? '',
          soSecPart2: item?.soSec?.soSecPart2 ?? '',
          soSecPart3: item?.soSec?.soSecPart3 ?? '',
          ssnVerification: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: 'N',
            dueDate: null,
            automatedVerificationYN: '',
          },
        },
        headOfHouseholdYN: item?.headOfHouseholdYN ?? '',
        race: {
          primaryRace: item?.race?.primaryRace ?? '',
        },
        ssnReferral: '',
        hasSsnYN: item?.hasSsnYN ? item?.hasSsnYN : 'N',
        pregnantYN: null,
        individualDetails: {
          dirtyStatus: '',
          deathDetails: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
            dateOfDeath: null,
            state: '',
            deathCertificateNumber: '',
            deathVerification: {
              dirtyStatus: '',
              messages: [],
              verificationDocumentCd: '',
              verificationRequestedYN: '',
              docNames: {},
              documentDetails: [],
              verificationType: '',
              verificationSourceCode: '',
              verifiedYN: '',
              dueDate: null,
              automatedVerificationYN: '',
            },
            secondaryVerification: {
              dirtyStatus: '',
              messages: [],
              verificationDocumentCd: '',
              verificationRequestedYN: '',
              docNames: {},
              documentDetails: [],
              verificationType: '',
              verificationSourceCode: '',
              verifiedYN: '',
              dueDate: null,
              automatedVerificationYN: '',
            },
            recordId: 0,
          },
          voterRegistartionYN: '',
          voterRegistrationStatus: '',
          voterRegistration: {
            dirtyStatus: '',
            voterIdNumber: '',
            electionJudgeFlag: '',
            helpRequiredFlag: '',
            licenceNumber: '',
            voterRegLicenseIdAvailCd: '',
            otherPartyName: '',
          },
          veterans: [],
          maritals: [],
          livingArrangements: [],
          f99Details: [],
          x02Details: [],
          pregnancy: [],
          birthCity: '',
          birthState: '',
          hospital: '',
          destituteMigrantYN: '',
          concurrentOutStateBenefits: [],
          tcaCounter: '',
          exemptions: [],
          domesticViolanceYN: '',
          strikerStatus: '',
          penalties: [],
          primaryRace: {
            dirtyStatus: '',
            messages: [],
            verificationDocumentCd: '',
            verificationRequestedYN: '',
            docNames: {},
            documentDetails: [],
            verificationType: '',
            verificationSourceCode: '',
            verifiedYN: '',
            dueDate: null,
            automatedVerificationYN: '',
            primaryRace: '',
            otherRaces: [],
          },
          ethnicity: '',
          primaryVerificationSource: '',
          incarcerations: [],
          ssnApplicationDate: null,
          differentAddressYN: null,
          individualAddress: {
            dirtyStatus: '',
            addressCareOf: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            county: '',
            district: '',
            zipcode: '',
            zip4code: '',
            addressVerifiedYN: '',
            institutionName: '',
            institutionYN: '',
            homeAddressYN: '',
            addressStartDate: null,
            addressEndDate: null,
            ownedByMemberYN: '',
            localDistrictOfficeYN: '',
          },
          strikers: [],
          primaryPrevention: [],
        },
        dobVerification: {
          dirtyStatus: '',
          messages: [],
          verificationDocumentCd: '',
          verificationRequestedYN: '',
          docNames: {},
          documentDetails: [],
          verificationType: '',
          verificationSourceCode: '',
          verifiedYN: '',
          dueDate: null,
          automatedVerificationYN: '',
        },
        createMdmId: true,
        mdmId: item?.mdmId ? item?.mdmId : null,
        maIdSuffix: null,
        irn: item?.irn ?? '',
        maId: item?.maId ?? '',
      },
      address: {
        dirtyStatus: '',
        addressCareOf: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        county: '',
        district: '',
        zipcode: '',
        zip4code: '',
        addressVerifiedYN: '',
        institutionName: '',
        institutionYN: '',
        homeAddressYN: '',
        addressStartDate: null,
        addressEndDate: null,
        ownedByMemberYN: '',
        localDistrictOfficeYN: '',
      },
      sourceSystem: data?.clientContainer?.sourceSystem ?? '',
      selectedPrograms: [],
    },
    screenDefn: data?.screenDefn ?? '',
    alertProceedYN: data?.alertProceedYN ? data?.alertProceedYN : 'N',
    alertMessage: '',
    intakeProgramSelections: map(data?.intakeProgramSelections, (ele) => {
      return {
        status: false,
        editable: true,
        controlId: ele?.controlId ? Number(ele?.controlId) : null,
        dirtyStatus: 'INSERT',
        individualId: '',
        importSelected: true,
        importedFromWp: '',
        clearanceStatus: '',
        programSelected: {
          programId: 0,
          dirtyStatus: 'insert',
          programName: ele?.programSelected?.programName ?? '',
          applicationDate: ele?.programSelected?.applicationDate
            ? dayjs(ele?.programSelected?.applicationDate).format('YYYY-MM-DD')
            : null,
          selectedIndicator: ele?.programSelected?.selectedIndicator ?? '',
        },
      };
    }),
    memberCreatedInWp: '',
    caseId: caseId ? Number(caseId) : null,
    populateExistingData: '',
  };
};

export const jsonSavePayload = (data, caseId, dirtyStatus, hohMemberData) => {
  let item = data?.clientContainer?.client;
  let filteredData = hohMemberData.filter(
    (item) => item.individualId !== data?.individualId
  );
  let obj = {
    caseId: caseId ? Number(caseId) : null,
    status: true,
    editSsn: true,
    editable: false,
    controlId: data?.controlId ?? null,
    screenDefn: data?.screenDefn ?? '',
    dirtyStatus: dirtyStatus ?? 'UPDATE',
    alertMessage:
      'Failure to provide your SSN may delay the processing of your application.',
    individualId: data?.individualId ?? '',
    alertProceedYN: 'Y',
    importSelected: true,
    clearanceStatus: caseId ? 'Y' : 'N',
    clientContainer: {
      client: {
        dob: item?.dob ? dayjs(item?.dob).format('YYYY-MM-DD') : null,
        race: {
          primaryRace: item?.race?.primaryRace ?? '',
        },
        soSec: {
          dirtyStatus: '',
          soSecPart1: item?.soSec?.soSecPart1 ?? '',
          soSecPart2: item?.soSec?.soSecPart2 ?? '',
          soSecPart3: item?.soSec?.soSecPart3 ?? '',
          ssnVerification: {
            retryCount: 0,
          },
        },
        gender: item?.gender ? item?.gender : '',
        hasSsnYN: item?.hasSsnYN ? item?.hasSsnYN : 'N',
        personName: {
          firstName: item?.personName?.firstName ?? '',
          lastName: item?.personName?.lastName ?? '',
          middleName: item?.personName?.middleName ?? '',
          suffix: item?.personName?.suffix ?? null,
        },
        createMdmId: true,
        sourceSystem: data?.clientContainer?.sourceSystem ?? '',
        maritalStatus: {
          marriedYN: item?.maritalStatus?.marriedYN ?? '',
        },
        relationshipCd: item?.relationshipCd ?? '',
        dobVerification: {
          retryCount: 0,
        },
        fortyQuartersInd: false,
        headOfHouseholdYN: item?.headOfHouseholdYN ?? '',
        individualDetails: {
          pregnancy: [
            {
              recordId: 0,
              groupNumber: 0,
              pregnantYN:
                data?.clientContainer?.client?.individualDetails?.pregnancy
                  ?.pregnantYN ?? '',
            },
          ],
          deathDetails: {
            retryCount: 0,
          },
        },
      },
      sourceSystem: data?.clientContainer?.sourceSystem ?? '',
      paginationData: {
        pageBuffer: 0,
        pageNumber: 1,
        totalRecords: 0,
        resultsPerPage: 5,
      },
      navigationFlowRequested: false,
    },
    memberCreatedInWp: '',
    populateExistingData: '',
    intakeProgramSelections: map(data?.intakeProgramSelections, (ele) => {
      return {
        status: false,
        editable: true,
        controlId: ele?.controlId ? Number(ele?.controlId) : null,
        dirtyStatus: 'INSERT',
        individualId: '',
        importSelected: true,
        importedFromWp: '',
        clearanceStatus: '',
        programSelected: {
          programId: 0,
          dirtyStatus: 'insert',
          programName: ele?.programSelected?.programName ?? '',
          applicationDate: ele?.programSelected?.applicationDate
            ? dayjs(ele?.programSelected?.applicationDate).format('YYYY-MM-DD')
            : null,
          selectedIndicator: ele?.programSelected?.selectedIndicator ?? '',
        },
      };
    }),
  };
  let isHoh = item?.headOfHouseholdYN === 'Y';
  if (isHoh) {
    return [obj];
  } else {
    return [...filteredData, obj];
  }
};
