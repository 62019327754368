import { useNavigate, useParams, useMatch } from 'react-router';

function usePageNav() {
  const navigate = useNavigate();
  const params = useParams();
  const isCaseHome = useMatch('/dashboard/caseInquiry/caseHome/:caseId');
  const _caseId = params.caseId ?? '';
  const _controlId = params.controlId ?? '';
  const onBack = () => navigate(-1);

  const navigations = {
    toDashboard() {
      navigate(`/dashboard`);
    },
    toInitiate() {
      navigate(`/dashboard/application`);
    },
    toCustomerReg(caseId = _caseId) {
      navigate(`/dashboard/application/customer-registration/${caseId}`);
    },
    toContact(caseId = _caseId) {
      navigate(`/dashboard/application/contact-info/${caseId}`);
    },
    toAuthRep(caseId = _caseId) {
      navigate(`/dashboard/application/auth-rep/${caseId}`);
    },
    toAppDispose(caseId = _caseId) {
      navigate(`/dashboard/application/app-disposition/${caseId}`);
    },
    toCaseHome(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/caseHome/${caseId}`);
    },
    toHhMembers(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/householdmembers/${caseId}`);
    },
    toRelationship(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/relationship/${caseId}`);
    },
    toCitizenship(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/citizenship/${caseId}`);
    },
    toRoomerBoarder(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/roomerBoarder/${caseId}`);
    },
    toSummary(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/summary/${caseId}`);
    },
    toIncome(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/income/${caseId}`);
    },
    toLivingArrangement(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/livingarrangement/${caseId}`);
    },
    toMedicareDetails(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/medicaredetails/${caseId}`);
    },
    toIndividualDetails(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/individualdetails/${caseId}`);
    },
    toDomesticViolence(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/domesticviolence/${caseId}`);
    },
    toWaiverAndFacility(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/waiverFacility/${caseId}`);
    },
    toAsset(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/assets/${caseId}`);
    },
    toPregnancyInfo(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/pregnancyinfo/${caseId}`);
    },
    toEducationDetails(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/educationdetails/${caseId}`);
    },
    toEligDetails(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/eligibilityDetails/${caseId}`);
    },
    toEligSummary(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/eligibilitySummary/${caseId}`);
    },
    toInquiry() {
      navigate(`caseInquiry/inquiry`);
    },
    toNonCompliance(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/nonCompliance/${caseId}`);
    },
    toCaseComments(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/caseComments/${caseId}`);
    },
    toProgramRequest(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/programrequest/${caseId}`);
    },
    toCaseReinitiate(caseId = _caseId) {
      navigate(`/dashboard/caseReinitiate/${caseId}`);
    },
    toAllCaseComments(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/allcasecomments/${caseId}`);
    },
    toAuthorizedRep(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/authrep/${caseId}`);
    },
    toConfirmation(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/confirmation/${caseId}`);
    },
    toClientCorrespondence(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/correspondenceSearch/${caseId}`);
    },
    toRecertification(caseId = _caseId) {
      navigate(`dashboard/redetermination/${caseId}`);
    },
    toCaseReOpen(caseId = _caseId) {
      navigate(`/dashboard/caseReOpen/${caseId}`);
    },
    toRedeterminationhistory(caseId = _caseId) {
      navigate(`/dashboard/redeterminationhistory/${caseId}`);
    },

    toAlerts(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/alerts`);
    },
    toCPImport(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/cp-import/caseCreation/${caseId}/${controlId}`);
    },
    toRunClearance(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/cp-import/clearance/${caseId}/${controlId}`);
    },
    toImportMember(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/cp-import/import/${caseId}/${controlId}`);
    },
    toVerifiedDocument(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/cp-import/verifieddocument/${caseId}/${controlId}`);
    },
    toCompletionMember(caseId = _caseId, controlId = _controlId, response) {
      const queryString = new URLSearchParams({
        status: response?.status,
      });
      navigate(
        `/dashboard/cp-import/completion/${caseId}/${controlId}?${queryString}`
      );
    },
    toProgramTimeLimit(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/caseInquiry/programTimeLimit/${caseId}`);
    },
    toHealthDisabilityForm(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/caseInquiry/healthDisability/${caseId}`);
    },
    toVerificationCheckbox(caseId = _caseId, controlId = _controlId) {
      navigate(`/dashboard/caseInquiry/verificationChecklist/${caseId}`);
    },
    toHouseholdDeclaration(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/householdDecleration/${caseId}`);
    },
    toContactInformation(caseId = _caseId) {
      navigate(`/dashboard/caseInquiry/contactInformation/${caseId}`);
    },
    toSettings() {
      navigate('/dashboard/settings');
    },
    toViewAllCase() {
      navigate('/dashboard/viewallcase');
    },
    toViewTicket() {
      navigate('/dashboard/viewtickets');
    },
  };

  return {
    params,
    onBack,
    navigations,
    caseId: _caseId,
    isCaseHome,
  };
}

export default usePageNav;
