export const GET_REDETERMINATIONDETAILS =
  '/eeapi/case-management/{0}/redetermination-details';

export const POST_REDETERMINATION =
  '/eeapi/case-management/{0}/redetermination';

export const POST_REDETERMINATION_SIGNATURE =
  'eeapi/application-process/interview/{0}/global-signature';

export const POST_REDETERMINATION_HISTORY =
  'eeapi/application-process/redetHistoryData/{0}';
