import { useContext, useEffect, useMemo } from 'react';
import { PageContext } from '../App';
import { useSelector } from 'react-redux';
import { selectNavLinks } from '../store/selectors/navigationSelector';
import {
  filter,
  find,
  findIndex,
  includes,
  isEmpty,
  map,
  reject,
} from 'lodash';
import navUrlsReference from '../pages/Layout/SideMenu/navUrlsReference.json';
import usePageNav from './usePageNav';
import { useNavigate } from 'react-router-dom';
import { selectCaseRules } from '../store/selectors/caseSelector';
import { getPageFlag } from '../utils/helpers';

function usePage(_screenId) {
  const caseRules = useSelector(selectCaseRules);
  const navigate = useNavigate();
  const {
    currentPage,
    setCurrentPage,
    expandSideMenu,
    setExpandSideMenu,
    screenId,
    setScreenId,
  } = useContext(PageContext);
  const navLinks = useSelector(selectNavLinks);
  const { caseId } = usePageNav();
  // useEffect(() => {
  //   if (_screenId) {
  //     setScreenId(_screenId);
  //   }
  // }, [_screenId, setScreenId]);

  const [prevPage, nextPage] = useMemo(() => {
    if (isEmpty(navLinks) || !_screenId) {
      return ['', ''];
    }
    const currentModule = find(navLinks, (n) => {
      const ids = map(n.subNavs, ({ screen_id }) => screen_id);
      return includes(ids, _screenId);
    });

    const mappedLinks = filter(currentModule?.subNavs, (l) => {
      const pageFlag = getPageFlag(l.nvgtn_id, caseRules);

      return !!navUrlsReference[l.nvgtn_id]?.nav_url && pageFlag;
    });

    const currNavIndex = findIndex(
      mappedLinks,
      (l) => l.screen_id === _screenId
    );
    const prevNavId = mappedLinks?.[currNavIndex - 1]?.nvgtn_id;
    const nextNavId = mappedLinks?.[currNavIndex + 1]?.nvgtn_id;

    return [
      navUrlsReference[prevNavId]?.nav_url ?? '',
      navUrlsReference[nextNavId]?.nav_url ?? '',
    ];
  }, [navLinks, _screenId, caseRules]);

  const next = nextPage.replace('/:caseId', `/${caseId}`);
  const back = prevPage.replace('/:caseId', `/${caseId}`);

  const toNextPage = () => {
    navigate(next);
  };

  const toPrevPage = () => {
    navigate(back);
  };

  return {
    currentPage,
    setCurrentPage,
    expandSideMenu,
    setExpandSideMenu,
    prevPage,
    nextPage,
    toNextPage,
    toPrevPage,
    navProps: {
      toNextPage,
      toPrevPage,
      isFirstPage: isEmpty(back),
      isLastPage: isEmpty(next),
    },
  };
}

export default usePage;
