import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import { ReactComponent as BrandLogo } from '../../assets/images/brand-logo.svg';
import styles from './index.module.css';
import { authApi } from '../../utils/HttpClient';
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { getAuthToken } from '../../store/selectors/authSelector';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { hideLoader, showLoader } from '../../store/slices/loaderSlice';
import { getUniqueId } from '../../utils/services/apiRequests';
import { showErrorToast } from '../../utils/services/toast';
import { setUserData } from '../../store/slices/userDetails';
import { decodeToken } from 'react-jwt';
import { setAuthToken } from '../../store/slices/authSlice';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector(getAuthToken);
  const [showPassword, setShowPassword] = useState(false);

  const onLogin = async (username, password) => {
    try {
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('grant_type', 'password');
      bodyFormData.append('client_id', 'security-admin-console');
      bodyFormData.append('username', username);
      bodyFormData.append('password', password);
      bodyFormData.append('scope', 'openid');
      const res = await authApi.post(
        '/auth/realms/devwp/protocol/openid-connect/token',
        bodyFormData
      );
      if (res?.status === 200) {
        const decryptToken = decodeToken(res?.data?.access_token);
        dispatch(setAuthToken(decryptToken));
      }
    } catch (error) {
      showErrorToast('Invalid username or password');
    }
  };

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    // onSubmit: ({ username, password }) => {
    //   dispatch({
    //     type: 'AUTHENTICATE',
    //     payload: {
    //       username,
    //       password,
    //     },
    //   });
    // },
  });
  const getUniqueIdApi = async () => {
    try {
      showLoader();
      const res = await getUniqueId(token?.sub);

      if (res.status === 200) {
        dispatch(setUserData(res.data));
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (token) {
      const from = location.state?.from?.pathname;
      // getUniqueIdApi(); not used
      // navigate(from, { replace: true });
      navigate('/dashboard', { replace: true });
      // return <Navigate to="/dashboard" replace />;
    }
  }, [location.state?.from?.pathname, navigate, token]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.loginContainer}>
      <Stack className={styles.login} alignItems="center" spacing={2} sx={{}}>
        <BrandLogo />
        <Stack
          component="form"
          spacing={2}
          alignItems="center"
          className={styles.loginForm}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            placeholder="Enter Username"
            {...formik.getFieldProps('username')}
          />
          <TextField
            placeholder="Enter Password"
            {...formik.getFieldProps('password')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div>
            <Button
              fullWidth={false}
              variant="contained"
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
              onClick={() =>
                onLogin(formik.values.username, formik.values.password)
              }
              // onClick={() =>
              //   dispatch({
              //     type: 'AUTHENTICATE',
              //   })
              // }
            >
              Login
            </Button>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

export default Login;
