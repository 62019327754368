export const pregnancyPayload = (data) => {
  const {
    pregnancyStartDate,
    pregnancyDueDate,
    pregnancyEndDate,
    unborns,
    parent,
    parentName,
    verification,
    dirtyStatus,
    recordId,
  } = data;
  return [
    {
      dirtyStatus,
      dueDateVerification: {
        retryCount: '',
        verificationSource: null,
        verificationRequestedYN: '',
        verificationType: verification,
        verifiedYN: verification === 'NO' || verification === 'RE' ? 'N' : 'Y',
      },
      fatherName: parentName,
      groupNumber: '',
      individualId: '',
      numberExpected: unborns,
      pregnancyDueDate: pregnancyDueDate,
      pregnancyEndDate: pregnancyEndDate,
      pregnancyStartDate: pregnancyStartDate,
      pregnantYN: '',
      recordId: recordId,
      relatedIndividualId: parent,
    },
    // {
    //   dirtyStatus: 'insert',
    //   dueDateVerification: {
    //     retryCount: '',
    //     verificationRequestedYN: '',
    //     verificationType: verification,
    //     verifiedYN: '',
    //   },
    //   groupNumber: '',
    //   individualId: '',
    //   numberExpected: unborns,
    //   pregnancyDueDate: pregnancyDueDate,
    //   pregnancyStartDate: pregnancyStartDate,
    //   pregnantYN: '',
    //   relatedIndividualId: '',
    // },
  ];
};
