import React, { useMemo } from 'react';
import FormSection from '../../../../components/FormSection';
import Field from '../../../../components/Field';
import { Button, Grid, OutlinedInput, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../../components/Dropdown';
import InputMask from 'react-input-mask';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import useLookup from '../../../../hooks/useLookup';
import * as Yup from 'yup';
import { showLoader, hideLoader } from '../../../../store/slices/loaderSlice';
import { postEmployerDetails } from '../../../../utils/services/NonCustodial/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';

export default function EmployerModal({
  toggleClose,
  caseId,
  absentParentId,
  createEmployer,
  employerEdit,
  getEmplyDet,
}) {
  const { getOptions } = useLookup('NonCustodialParent');
  const { stateOpts, typeOpts } = useMemo(() => {
    return {
      stateOpts: getOptions('State'),
      typeOpts: getOptions('PhoneType'),
    };
  }, [getOptions]);

  const validationSchema = Yup.object({
    employerName: Yup.string().required('Please Enter Employer Name'),
    startDate: Yup.string().required('Please Select the Date'),
  });
  const formik = useFormik({
    initialValues: {
      dirtyStatus: createEmployer ? 'insert' : 'update',
      employerName: employerEdit?.companyLegalName ?? '',
      occupation: employerEdit?.occupation ?? '',
      startDate: employerEdit?.employmentBeginDate ?? '',
      endDate: employerEdit?.employmentEndDate ?? '',
      addressLine1: employerEdit?.employerAddress?.addressLine1 ?? '',
      addressLine2: employerEdit?.employerAddress?.addressLine2 ?? '',
      city: employerEdit?.employerAddress?.city ?? '',
      state: employerEdit?.employerAddress?.state ?? '',
      zipCode: employerEdit?.employerAddress?.zipcode ?? '',
      number: employerEdit?.phoneNumber?.phoneNumber ?? '',
      extension: employerEdit?.phoneNumber?.phoneNumberExt ?? '',
      type: employerEdit?.phoneNumber?.phoneNumberType ?? '',
      employerId: employerEdit?.emplrId ?? 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      employerDetails(values);
    },
  });

  const employerDetails = async (data) => {
    try {
      showLoader();
      const res = await postEmployerDetails(data, caseId, absentParentId);
      if (res.status === 200) {
        showSuccessToast('Succesfully Added The Employer Details');
        toggleClose();
        getEmplyDet();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <FormSection label="Employer">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              name="employerName"
              label="Employer Name"
              formik={formik}
              required
              normalizers={[maxLength(25), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="occupation"
              label="Occupation"
              formik={formik}
              normalizers={[maxLength(25), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="startDate"
              formik={formik}
              required
              label="Start Date"
              isDate
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="endDate" formik={formik} label="End Date" isDate>
              <DatePicker />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Employer's Address" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              name="addressLine1"
              label="Address Line1"
              formik={formik}
              normalizers={[maxLength(25)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="addressLine2"
              label="Address Line2"
              formik={formik}
              normalizers={[maxLength(25)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="city"
              label="City"
              formik={formik}
              normalizers={[maxLength(25), onlyAlphabet]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="state" label="State" formik={formik}>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="zipCode"
              label="Zip Code"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Phone" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field name="number" label="Number" formik={formik}>
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.number}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="extension"
              label="Extension"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="type" label="Type" formik={formik}>
              <Dropdown options={typeOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
