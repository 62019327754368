import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import EmptyState from '../../components/EmptyState';
import NoRecord from '../../assets/icons/No-data-amico.svg';

function IndividualDetailsTable({ rows, columns, getRowId }) {
  return (
    <Box sx={{ display: 'grid' }}>
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        disableRowSelectionOnClick={true}
        disableColumnMenu={true}
        getRowId={getRowId}
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          '& .MuiDataGrid-overlayWrapperInner': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
          width: '100%',
          backgroundColor: 'white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '16px',
            fontWeight: '600',
            padding: '0px 10px',
            color: '#000000',
          },
          '& .MuiDataGrid-row': {
            fontSize: '16px',
            color: 'black',
            padding: '0px 10px',
            textAlign: 'center',
          },
        }}
      />
    </Box>
  );
}

export default IndividualDetailsTable;
