import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import ActionButtons from '../../../components/ActionButtons';
import RoomerModal from '../RoomerBoarderModal';
import CloseIcon from '@mui/icons-material/Close';
import usePageNav from '../../../hooks/usePageNav';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import {
  deleteRoomer,
  getRoomer,
  postRoomer,
} from '../../../utils/services/RoomerBoarder/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import DeleteConfiramation from '../DeleteConfiramation';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/sharing.svg';

export default function RoomerDetails({ selected }) {
  const { individualId } = selected;
  const { caseId } = usePageNav();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [roomerData, setRoomerData] = useState([]);
  const [roomerEdit, setRoomerEdit] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const toggleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setRoomerEdit(false);
  };

  const roomerEditData = (data) => {
    setEditOpen(true);
    setRoomerEdit(data);
  };

  const columns = [
    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      width: 130,
    },
    {
      field: 'effectiveEndDate',
      headerName: 'End Date',
      width: 130,
      renderCell: (params) => {
        const endDate =
          params?.row?.effectiveEndDate === '9999-12-31'
            ? '-'
            : params?.row?.effectiveEndDate;
        return endDate;
      },
    },
    {
      field: 'numberOfMealsPerDay',
      headerName: 'Meals Per Day',
      width: 160,
    },
    {
      field: 'numberOfHouseholdMembers',
      headerName: 'No. of Household Members',
      width: 280,
    },
    {
      field: 'amountPaid',
      headerName: 'Amount Paid',
      width: 150,
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <ActionButtons
              onEdit={() => roomerEditData(params?.row)}
              onDelete={() => deletePopup(params?.row)}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (individualId) {
      getRoomerDet();
    }
  }, [individualId]);

  const getRoomerDet = async () => {
    try {
      showLoader();
      const res = await getRoomer(caseId, individualId);
      if (res.status === 200) {
        setRoomerData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const deletePopup = async (rowData) => {
    setSelectedData(rowData);
    setOpenDeleteDialog(true);
  };

  const handleDelete = () => {
    deleteRoomerDet();
  };

  const deleteRoomerDet = async () => {
    const payload = {
      ...selectedData,
      dirtyStatus: 'delete',
    };
    try {
      showLoader();
      const res = await deleteRoomer(payload, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('Successfully deleted the Roomer Boarder Details');
        getRoomerDet();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Grid container mt={2} direction="column">
        <Grid
          item
          direction="row"
          mb={1}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button onClick={() => setOpen(true)}>
            + Add Roomer Boarder Detail
          </Button>
        </Grid>
        <DataGrid
          rows={roomerData ?? {}}
          columns={columns}
          getRowId={(row) => row?.individualborderId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '50vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '50vh',
            },
            minHeight: '50vh',
          }}
        />
      </Grid>
      <Dialog maxWidth={'md'} open={open} onClose={toggleClose}>
        {/* <DialogTitle>
          <Typography variant="h4">Add Roomer Boarder Details</Typography>
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={toggleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <RoomerModal
            toggleClose={toggleClose}
            createRoomer
            individualId={individualId}
            caseId={caseId}
            getRoomerDet={getRoomerDet}
          />
        </DialogContent>
      </Dialog>
      {editOpen && (
        <Dialog maxWidth={'md'} open={editOpen} onClose={toggleClose}>
          <DialogTitle>Add Roomer Boarder Details</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={toggleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <RoomerModal
              toggleClose={toggleClose}
              roomerEdit={roomerEdit}
              individualId={individualId}
              caseId={caseId}
              getRoomerDet={getRoomerDet}
            />
          </DialogContent>
        </Dialog>
      )}
      <DeleteConfiramation
        setOpenDeleteDialog={setOpenDeleteDialog}
        openDeleteDialog={openDeleteDialog}
        handleDelete={handleDelete}
      />
    </>
  );
}
