import React, { useContext, useState } from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import CaseBar from './CaseBar';
import StatusBar from './StatusBar';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { PageContext } from '../../App';
import cx from 'classnames';
import { cond } from '../../utils/helpers';

function Dashboard({ downloadScreenshot }) {
  const currentPage = useContext(PageContext);
  const params = useParams();
  const isCaseInquiry = useMatch('/dashboard/caseInquiry/*');
  const isContactInfo = useMatch('/dashboard/application/contact-info/:caseId');
  const isAppDipose = useMatch(
    '/dashboard/application/app-disposition/:caseId'
  );
  const isAuthRep = useMatch('/dashboard/application/auth-rep/:caseId');
  const isDashboard = useMatch('/dashboard', { end: true });
  const isApp = useMatch('/dashboard/application', { end: false });
  const isNew = isApp && !isDashboard;
  const tabs = [
    {
      title: 'Dashboard',
      to: '/dashboard',
      active: isDashboard,
    },
    {
      title: 'New',
      to: '/dashboard/application',
      active: isNew,
    },
  ];
  const navigate = useNavigate();
  const onNavigate = (path) => {
    navigate(path);
  };

  const key = cond([
    [isCaseInquiry, 'caseInquiry'],
    [isContactInfo, 'contactInfo'],
    [isAppDipose, 'appDispose'],
  ]);

  return (
    <div className={styles.dashboard}>
      <div className={styles.tabs}>
        {map(tabs, (tab) => (
          <button
            className={cx(styles.tab, {
              [styles.active]: tab.active,
            })}
            onClick={() => onNavigate(tab.to)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className={styles.sticky}>
        <CaseBar downloadScreenshot={downloadScreenshot} />
        {(isCaseInquiry || isContactInfo || isAppDipose || isAuthRep) && (
          <StatusBar key={key} caseId={params.caseId} />
        )}
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
