import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  IconButton,
  Grid,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import React, { useEffect } from 'react';
import Field from '../../../components/Field';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../components/Dropdown';
import { useFormik } from 'formik';
import {
  craeteBenefits,
  editBnfits,
} from '../../../utils/services/healthAndDisability/apiRequests';
import useLoader from '../../../hooks/useLoader';
import { showSuccessToast } from '../../../utils/services/toast';
import { showErrorToast } from '../../../utils/services/toast';

import * as yup from 'yup';

const AddSocialBenifits = ({
  open,
  setOpen,
  dataObj,
  getOptions,
  caseId,
  memberId,
  setRefresh,
  action,
  refresh,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const validateSchma = yup.object({
    status: yup.string().required('Status is Required field'),
    receivingBenefit: yup.string().required('Benefits is Required field'),
    effectiveBeginDate: yup.string().required('Begin date is Required field'),
    statusDate: yup.string().required('Status is Required field'),
    resultDate: yup.string().required('Result date is Required field'),
    effectiveEndDate: yup.string().required('End date is Required field'),
    verificationSourceCode: yup
      .string()
      .required('Verification is Required field'),
  });

  const onSubmit = async (objS, { resetForm }) => {
    showLoader();
    try {
      if (action === 'insert') {
        const res = await craeteBenefits(caseId, memberId, objS, action);
        if (res.status === 200) {
          showSuccessToast('Benefits Has Been Added');
          setRefresh(!refresh);
        }
      } else if (action === 'update') {
        const res = await editBnfits(
          caseId,
          memberId,
          { ...objS, id: dataObj.recordId },
          action
        );
        if (res.status === 200) {
          setRefresh(!refresh);
        }
      }
    } catch (e) {
      showErrorToast('Benefits not been added');
    } finally {
      hideLoader();
      setOpen(false);
      resetForm();
    }
  };
  const formik = useFormik({
    initialValues: {
      effectiveBeginDate: dataObj?.effectiveBeginDate ?? null,
      effectiveEndDate: dataObj?.effectiveEndDate ?? null,
      receivingBenefit: dataObj?.receivingBenefit ?? null,
      status: dataObj?.status ?? '',
      statusDate: dataObj?.statusDate ?? null,
      resultDate: dataObj?.resultDate ?? null,
      result: dataObj?.result ?? '',
      verificationSourceCode:
        dataObj?.ssbVerification?.verificationSourceCode ?? '',
    },
    onSubmit,
    validationSchema: validateSchma,
  });
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} maxWidth="lg" fullWidth scroll="paper">
      <DialogTitle>
        <Typography variant="h3">Add Social Security Benefits</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box
          sx={{
            border: '1px solid #C2C2B7',
            borderRadius: '5px',
            padding: '1rem',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                required
                label="Effective Begin Date "
                formik={formik}
                isDate
                name="effectiveBeginDate"
              >
                <DatePicker
                  required
                  name="effectiveBeginDate"
                  formikHandler={false}
                  isDate
                  formik={formik}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                label="Effective End Date "
                formik={formik}
                isDate
                name="effectiveEndDate"
              >
                <DatePicker
                  name="effectiveEndDate"
                  formikHandler={false}
                  isDate
                  formik={formik}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                label="Receiving Benefits"
                formik={formik}
                name="receivingBenefit"
              >
                <Dropdown
                  name="receivingBenefit"
                  options={getOptions('SocialSecurityBenefits')}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                label="Verification"
                formik={formik}
                name="verificationSourceCode"
              >
                <Dropdown
                  name="verificationSourceCode"
                  options={getOptions('CLUIAMTVERCD')}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field label="Status" formik={formik} name="status" required>
                <Dropdown
                  name="status"
                  options={getOptions('SocialSecurityBenefitsStatus')}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                required
                label="Status Date"
                isDate
                formik={formik}
                name="statusDate"
              >
                <DatePicker
                  required
                  name="statusDate"
                  formikHandler={false}
                  isDate
                  formik={formik}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field label="Result" formik={formik} name="result">
                <Dropdown
                  name="result"
                  options={getOptions('SocialSecurityBenefitsResult')}
                />
              </Field>
            </Grid>

            <Grid item xs={6}>
              <Field
                required
                label="Result Date"
                formik={formik}
                isDate
                name="resultDate"
              >
                <DatePicker
                  name="resultDate"
                  formikHandler={false}
                  isDate
                  formik={formik}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field label="Result Reason" formik={formik} name="resultReason">
                <Dropdown
                  name="resultReason"
                  options={getOptions('SocialSecurityBenefitsReason')}
                />
              </Field>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ marginRight: '1rem' }}>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(!open);
            }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={formik.handleSubmit}
            sx={{
              backgroundColor: '#0F4E96',
              color: 'white',
              borderRadius: '0px',
            }}
          >
            Save & Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AddSocialBenifits;
