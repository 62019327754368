import React, { useContext } from 'react';
import { Outlet, useLocation, useMatch } from 'react-router-dom';
import styles from './index.module.css';
import MultiStepper from '../../../components/MultiStepper';
import { PageContext } from '../../../App';
import usePage from '../../../hooks/usePage';
import { useDispatch } from 'react-redux';
import useMount from '../../../hooks/useMount';
import { FETCH_NAV_OPTIONS } from '../../../store/types/navTypes';

export default function ApplicationContainer({ cPage }) {
  usePage(cPage);
  const dispatch = useDispatch();
  const location = useLocation();
  const isAppPage = useMatch('/dashboard/application');
  const isRegPage = useMatch('/dashboard/application/register');
  const isCustRegPage = useMatch(
    '/dashboard/application/customer-registration/:caseId?'
  );
  const isContInfoPage = useMatch(
    '/dashboard/application/contact-info/:caseId'
  );
  const isAuthRepPage = useMatch('dashboard/application/auth-rep/:caseId');
  const isAppDisposPage = useMatch(
    '/dashboard/application/app-disposition/:caseId'
  );

  useMount(() => {
    dispatch({
      type: FETCH_NAV_OPTIONS,
      payload: { caseId: '', pageType: 'R' },
    });
  });

  const stages = [
    {
      title: 'Initiate',
      done: isAppPage,
    },
    {
      title: 'Register',
      done: isRegPage,
    },
    {
      title: 'Customer Registration',
      done: isCustRegPage,
    },
    {
      title: 'Contact Info',
      done: isContInfoPage,
    },
    {
      title: 'Authorized Rep',
      done: isAuthRepPage,
    },
    {
      title: 'App Disposition',
      done: isAppDisposPage,
    },
  ];
  return (
    <div className={styles.appContainer}>
      <div className={styles.multiStepBar}>
        <MultiStepper steps={stages} />
      </div>
      <div className={styles.formContent}>
        <Outlet />
      </div>
    </div>
  );
}
