import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import Field from '../../../components/Field';
import LanguageIcon from '@mui/icons-material/Language';
import Dropdown from '../../../components/Dropdown';
import useLookup from '../../../hooks/useLookup';
import dayjs from 'dayjs';
import { getAuthToken } from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import useLoader from '../../../hooks/useLoader';
import { getBulletdata } from '../../../utils/services/apiRequests';
import { showErrorToast } from '../../../utils/services/toast';

const Dialogs = ({ handleClose, open, getOptions, getBulletinboard }) => {
  const token = useSelector(getAuthToken);
  const [showLoader, hideLoader] = useLoader();

  const onClose = () => {
    handleClose();
    formik.resetForm();
  };

  const { portalDispOpts } = useMemo(() => {
    return {
      portalDispOpts: getOptions('Portal'),
    };
  }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      beginDate: '',
      endDate: '',
      message: '',
      portal: '',
    },
    onSubmit: (values) => {
      addBulletinposting(values);
      console.log(values, 'values');
    },
  });

  const handleChange = (e, name) => {
    formik.setFieldValue(name, dayjs(e).format('YYYY-MM-DDTHH:mm:ss'));
  };

  const addBulletinposting = async (values) => {
    const payload = {
      title: null,
      message: values.message,
      portal: values.portal,
      begin_dt: values.beginDate,
      end_dt: values.endDate,
      createdBy: token?.sub,
      sessionId: '',
      delete_ind: null,
      bulletinId: null,
      createdDate: null,
      action: 'add',
    };
    try {
      showLoader();
      const res = await getBulletdata(payload);
      if (res.status === 200) {
        getBulletinboard();
        onClose();
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '60rem',
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">Add Bulletin/Posting</Typography>
      </DialogTitle>

      <DialogContent>
        <Box>
          <Box
            sx={{ border: '1px solid var(--grey-350)', borderRadius: '6px' }}
          >
            <Grid container sx={{ padding: '1rem' }} spacing={4}>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      background: '#EDF6FC',
                      borderRadius: '50%',
                      height: '4.5rem',
                      width: '4.5rem',
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #44A0E3',

                      justifyContent: 'center',
                    }}
                  >
                    <CalendarMonthIcon />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '1rem',
                      fontWeight: '700',

                      lineHeight: '2rem',
                      textAlign: 'left',
                      color: 'var(--grey-400)',
                    }}
                  >
                    Begin Date
                  </Typography>
                  <Field name="beginDate" formik={formik} isDate customHandler>
                    <DateTimePicker
                      name="beginDate"
                      value={formik?.values?.beginDate}
                      onChange={(e) => handleChange(e, 'beginDate')}
                      disablePast
                    />
                  </Field>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      background: '#EDF6FC',
                      border: '1px solid #44A0E3',
                      borderRadius: '50%',
                      height: '4.3rem',
                      width: '4.3rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CalendarMonthIcon />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '1rem',
                      fontWeight: '700',
                      lineHeight: '2rem',
                      textAlign: 'left',
                      color: 'var(--grey-400)',
                    }}
                  >
                    End Date
                  </Typography>
                  <Field name="endDate" formik={formik} isDate customHandler>
                    <DateTimePicker
                      name="endDate"
                      value={formik?.values?.endDate}
                      minDate={
                        formik?.values?.beginDate
                          ? dayjs(formik.values.beginDate)
                          : null
                      }
                      onChange={(e) => handleChange(e, 'endDate')}
                      disablePast
                    />
                  </Field>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      background: '#EDF6FC',
                      border: '1px solid #44A0E3',
                      borderRadius: '50%',
                      height: '4.3rem',
                      width: '4.3rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <LanguageIcon />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: 'Open Sans',
                      fontSize: '1rem',
                      fontWeight: '700',
                      lineHeight: '2rem',
                      textAlign: 'left',
                      color: 'var(--grey-400)',
                    }}
                  >
                    Portal
                  </Typography>
                  <Field name="portal" formik={formik}>
                    <Dropdown
                      name="portal"
                      options={portalDispOpts}
                      value={formik?.values?.portal}
                    />
                  </Field>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex',
                padding: '1rem',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  // alignItems: 'center',
                  flexDirection: 'column',
                  // justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '1rem',
                    fontWeight: '700',
                    lineHeight: '2rem',
                    textAlign: 'left',
                    color: 'var(--grey-400)',
                  }}
                >
                  Message
                </Typography>
                <Field name="message" formik={formik}>
                  <TextField
                    name="message"
                    multiline
                    rows={3}
                    placeholder="Enter Comments"

                    // options={nameData}
                  />
                </Field>
              </Box>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          autoFocus
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Dialogs;
