import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import SummaryItem from './SummaryItem';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import CaseInfo from './CaseInfo';
import ContactInfo from './ContactInfo';
import HhComposition from './HhComposition';
import CitizenshipSts from './CitizenshipSts';
import SSNNames from './SSNNames';
import LivingArmnt from './LivingArmnt';
import InsuranceInfo from './InsuranceInfo';
import { Box } from '@mui/system';
import SummaryHeader from './SummaryHeader';
import DateInfo from '../../components/DateInfo';
import {
  getSchedule,
  getSummaryData,
  postCaseComplete,
  postScheduleAppointment,
} from '../../utils/services/apiRequests';
import useLookup from '../../hooks/useLookup';
import { find, get, map } from 'lodash';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import usePageNav from '../../hooks/usePageNav';
import PageHeader from '../../components/PageHeader';
import YesNoOption from '../../components/YesNoOption';
import usePage from '../../hooks/usePage';
import useLoader from '../../hooks/useLoader';
import { showErrorToast } from '../../utils/services/toast';
import dayjs from 'dayjs';
import Dropdown from '../../components/Dropdown';
import ScheduleAppoinment from '../AppDisposition/Scheduler';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { selectCaseDetails } from '../../store/selectors/caseSelector';
import { useSelector } from 'react-redux';
import Field from '../../components/Field';

// https://dtabbq7bhl64k.cloudfront.net/eeapi/application-process/interview/300002147/summary
// https://dtabbq7bhl64k.cloudfront.net/eeapi/application-process/interview/300000294/summary

const BorderedBox = styled(Box)({
  borderRadius: '0.25rem',
  border: '1px solid var(--Prim-1-N-L-Black, #6C6C6C)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
});

function CaseSummary({ id }) {
  const params = useParams();
  const { navProps, toPrevPage } = usePage(id);
  const { navigations } = usePageNav();
  const [summary, setSummary] = useState({});
  const [open, setOpen] = useState(false);
  const [openSlot, setOpenSlot] = useState(false);
  const [yesNo, setYesNo] = useState(false);
  const [caseValue, setCaseValue] = useState('');
  const [scheduleData, setScheduleData] = useState(false);
  const [showLoader, hideLoader] = useLoader();
  const [fetchingSummary, setFetchingSummary] = useState(false);
  const { lkpFlatten, getLkpMatch, lkpRaw, islkpFailed, getOptions } =
    useLookup('CaseSummary');
  const caseDetails = useSelector(selectCaseDetails);
  const [statusValue, setStatusValue] = useState([]);

  const fetchSummary = async () => {
    try {
      setFetchingSummary(true);
      const res = await getSummaryData(params.caseId);
      if (res.status === 200) {
        setSummary(res.data);
      }
    } catch (error) {
      toast.error('System is not responding, Please try again!');
    } finally {
      setFetchingSummary(false);
    }
  };

  useEffect(() => {
    fetchSummary();
    getScheduleApi();
  }, []);

  const scheduled = useMemo(() => {
    return scheduleData.scheduledInterview;
  }, [scheduleData]);
  const { statusOpts } = useMemo(() => {
    return {
      statusOpts: getOptions('InterviewStatus'),
    };
  }, [getOptions]);
  const getScheduleApi = async () => {
    try {
      showLoader();
      const res = await getSchedule(params.caseId);

      if (res.status === 200) {
        setScheduleData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const caseCompleteApi = async (data) => {
    if (caseValue !== data) {
      setCaseValue(data);
      if (data === 'Y') {
        setYesNo(true);
      } else {
        setYesNo(false);
      }
      try {
        showLoader();
        const res = await postCaseComplete(caseDetails?.benefitsCaseId, data);
        if (res.status === 200) {
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
    }
  };
  console.log(yesNo);
  const { caseId, headOfHouse, contactInfo, houseMembers, caseCompleteInd } =
    useMemo(() => {
      const {
        contactInformation = {},
        householdMembers = [],
        caseId: id,
        caseCompleteInd,
      } = get(summary, 'benefitsCase', {});
      const hoh = find(householdMembers, { headOfHouseholdYN: 'Y' });
      if (caseCompleteInd === 'Y') {
        setYesNo(true);
      }
      return {
        caseId: id,
        headOfHouse: hoh,
        contactInfo: contactInformation,
        houseMembers: householdMembers,
      };
    }, [summary]);

  if (fetchingSummary) {
    return (
      <>
        <Skeleton height={300} width="100%" />
        {map(Array.from({ length: 6 }), () => (
          <Skeleton height={60} width="100%" />
        ))}
      </>
    );
  }

  const onFinish = () => {
    navigations.toEligSummary();
  };
  const onBack = () => {
    navigations.toPregnancyInfo();
  };
  const toggleClose = () => {
    setOpen(false);
  };

  const checkHandler = (e) => {
    setStatusValue(e.target.value);
    if (e.target.value === 'RS') {
      setOpenSlot(true);
    } else {
      const payload = { ...scheduled, interviewStatus: e.target.value };
      ScheduleAPI(payload);
    }
  };

  const ScheduleAPI = async (value) => {
    try {
      showLoader();
      const res = await postScheduleAppointment(value, params.caseId);
      if (res.status === 200) {
        getScheduleApi();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const toggleAvailability = () => {
    setOpenSlot(false);
  };

  return (
    <div className={styles.summary}>
      <PageHeader title="Summary" disableNext onBack={toPrevPage} />
      <Dialog
        maxWidth="lg"
        open={openSlot}
        fullWidth
        onClose={toggleAvailability}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          margin={1}
        >
          <Typography variant="h4">Schedule interview</Typography>
          <IconButton onClick={toggleAvailability}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <ScheduleAppoinment
            // interviewTypes={interviewTypes}
            getLkpMatch={getLkpMatch}
            getOptions={getOptions}
            ldssDistrict={scheduled?.ldssDoCd}
            toggleAvailability={toggleAvailability}
            caseID={params.caseId}
            getScheduleApi={getScheduleApi}
            scheduleDetails={scheduled}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={open} maxWidth={'md'} onClose={toggleClose}>
        <DialogContent>
          <Typography variant="h3">Your Case complete successfully</Typography>
          <Stack justifyContent="center" direction="row" mt={2}>
            <Button
              sx={{
                padding: ' 3px,  5px',
                fontWeight: 600,
                fontSize: 22,
                color: 'gray',
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <div className={styles.summaryItems}>
        <SummaryItem
          defaultOpen
          title="Case Information and Contact Information"
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <CaseInfo
                caseId={caseId}
                headOfHouse={headOfHouse}
                getLkpMatch={getLkpMatch}
              />
            </Grid>
            <Grid item xs={6}>
              <ContactInfo
                contactInfo={contactInfo}
                getLkpMatch={getLkpMatch}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem title="Household Composition and Citizenship Status">
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <HhComposition
                getLkpMatch={getLkpMatch}
                houseMembers={houseMembers}
              />
            </Grid>
            <Grid item xs={4.5}>
              <CitizenshipSts
                getLkpMatch={getLkpMatch}
                houseMembers={houseMembers}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem title="SSN and Living Arrangement">
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <SSNNames getLkpMatch={getLkpMatch} houseMembers={houseMembers} />
            </Grid>
            <Grid item xs={4.5}>
              <LivingArmnt
                getLkpMatch={getLkpMatch}
                houseMembers={houseMembers}
              />
            </Grid>
          </Grid>
        </SummaryItem>
        <SummaryItem title="Health Insurance Information">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InsuranceInfo />
            </Grid>
          </Grid>
        </SummaryItem>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <BorderedBox p="2rem">
              <Typography color="#44A0E3" variant="h2">
                Case Completed?
              </Typography>
              <Typography variant="h4" m="1.5rem 0 3rem">
                Is the benefits Case Officially Completed?
              </Typography>
              <Stack direction="row" spacing={2}>
                <RadioGroup row>
                  <FormControlLabel
                    value={true}
                    control={
                      <Button
                        variant={yesNo ? 'contained' : 'outlined'}
                        onClick={() => caseCompleteApi('Y')}
                      >
                        Yes
                      </Button>
                    }
                  />
                  <FormControlLabel
                    value={false}
                    control={
                      <Button
                        onClick={() => caseCompleteApi('N')}
                        variant={yesNo ? 'outlined' : 'contained'}
                      >
                        No
                      </Button>
                    }
                  />
                </RadioGroup>
              </Stack>
            </BorderedBox>
          </Grid>
          <Grid item xs={6}>
            <BorderedBox pb="1rem">
              <SummaryHeader title="Scheduled Appointment" />
              <Typography mt={1} variant="h4">
                Appointment Status
              </Typography>
              <Stack mt={2}>
                <Field customHandler>
                  <Dropdown
                    defaultValue={scheduled?.interviewStatus}
                    options={statusOpts}
                    onChange={checkHandler}
                    value={statusValue}
                  />
                </Field>
              </Stack>
              <Typography m="1.5rem" variant="h4">
                Appointment Date
              </Typography>
              <DateInfo
                time={scheduled?.startTime}
                date={dayjs(scheduled?.interviewDate).format('DD')}
                month={dayjs(scheduled?.interviewDate).format('MMMM')}
                year={dayjs(scheduled?.interviewDate).format('YYYY')}
              />
            </BorderedBox>
          </Grid>

          <Grid item xs={12}>
            <BorderedBox>
              <SummaryHeader title="Signatures" />
              <Box sx={{ alignSelf: 'stretch', p: '1rem' }}>
                <Grid container spacing={2}>
                  {map(
                    summary?.benefitsCase?.interviewSignature?.signatures,
                    (val) => (
                      <Grid
                        item
                        xs={3}
                        sx={{ width: '300px', height: '200px', margin: 1 }}
                      >
                        <Typography variant="body1">
                          <strong>Updated Date:</strong> {val.updatedDate}
                        </Typography>
                        <Box
                          sx={{
                            border: '2px solid var(--grey-100)',
                            p: '0.5rem',
                          }}
                        >
                          {val.signatureImage ? (
                            <img
                              src={val.signatureImage}
                              height="150px"
                              width="250px"
                              alt="signature"
                            />
                          ) : (
                            <Typography>No signature avilable</Typography>
                          )}
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </BorderedBox>
          </Grid>
        </Grid>
      </div>
      <Stack sx={{ p: '1rem' }} justifyContent="flex-end" direction="row">
        <Button variant="contained" onClick={onFinish}>
          Finish
        </Button>
      </Stack>
    </div>
  );
}

CaseSummary.propTypes = {};

export default CaseSummary;
