import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../Avatar';
import TextContent from '../../TextContent';
import { getAge } from '../../../utils/helpers';
import BaseMenuButton from '../BaseMenuButton';
import { useMediaQuery } from '@mui/material';
import NameContent from '../../NameContent';

function MemberMenuButton({ member, options, genderLkp, disabled }) {
  // const { personName: { firstName = 'Unknown', lastName = '' } = {} } =
  //   data ?? {};
  // const name = `${firstName} ${lastName}`;
  const { onSelect, selectedId, expandMenu } = options;
  const { id, name, dob, gender, headOfHouseholdYN, agHeadAge } = member;
  const isHoh = headOfHouseholdYN === 'Y';
  const age = getAge(dob);
  const isSelected = id === selectedId;
  const subTitle = isHoh
    ? {
        content: 'HOH',
        variant: 'body2',
        sx: { color: 'var(--primary-dark)' },
      }
    : genderLkp[gender];

  const isIpad = useMediaQuery('(max-width:1024px)');
  const details = (
    <>
      <TextContent title={name} subTitle={subTitle} />
      <TextContent center title="Age" subTitle={agHeadAge || age} />
    </>
  );

  const shortDetails = (
    <>
      <NameContent title={name} subTitle={subTitle} age={agHeadAge || age} />
    </>
  );
  return (
    <BaseMenuButton
      id={id}
      onClick={() => onSelect(id)}
      selected={isSelected}
      avatar={<Avatar name={name} />}
      disabled={disabled}
      details={
        isIpad && expandMenu ? shortDetails : expandMenu ? details : null
      }
    />
  );
}

MemberMenuButton.propTypes = {
  data: PropTypes.shape({}),
  options: PropTypes.shape({}),
  genderLkp: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

MemberMenuButton.defaultProps = {
  data: {},
  options: {},
  genderLkp: {
    M: 'Male',
    F: 'Female',
  },
  disabled: false,
};

export default MemberMenuButton;
