import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import cx from 'classnames';
import { findIndex, map, size } from 'lodash';
import { Typography } from '@mui/material';

function MultiStepper({ steps }) {
  const currentIndex = findIndex(steps, (s) => s.done);
  const offset = (100 / size(steps) / 2).toFixed(2);
  const stepLength = 100 / (size(steps) - 1);

  return (
    <div class={styles.multiStep}>
      <div
        class={styles.progressBar}
        style={{
          '--offset': `${offset}%`,
          '--process-width': `${stepLength * currentIndex}%`,
        }}
      >
        <div class={styles.progress}></div>
      </div>
      <div class={styles.stepsContainer}>
        {map(steps, (step, index) => {
          const isActive = index < currentIndex;
          const isCurrent = index === currentIndex;
          return (
            <div class={styles.step}>
              <div
                class={cx(styles.indicator, {
                  [styles.active]: isActive,
                  [styles.current]: isCurrent,
                })}
              >
                {index + 1}
              </div>
              <div class={styles.stepTitle}>
                <Typography
                  lineHeight={1.75}
                  variant={isActive ? 'subtitle1' : isCurrent ? 'h5' : 'body2'}
                >
                  {step.title}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

MultiStepper.propTypes = {};

export default MultiStepper;
