import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Field from '../../../../components/Field';
import Dropdown from '../../../../components/Dropdown';
import { postVendorSearch } from '../../../../utils/services/waiverAndFacility/apiRequest';
import { showErrorToast } from '../../../../utils/services/toast';
import { hideLoader, showLoader } from '../../../../store/slices/loaderSlice';
import { useFormik } from 'formik';
import usePageNav from '../../../../hooks/usePageNav';
import { DataGrid } from '@mui/x-data-grid';
import { CloseRounded } from '@mui/icons-material';

export default function SearchIntitution({ toggleInstitution, selectedValue }) {
  const [rowsData, setRowsData] = useState('');
  const { caseId } = usePageNav();
  const Column = [
    { field: 'vendorId', headerName: 'ID', flex: 1 },
    { field: 'vendorName', headerName: 'Name', flex: 1 },
    { field: 'vendorTypeCd', headerName: 'Type', flex: 1 },
    { field: 'mmisVendorId', headerName: 'MMIS LTC Provider ID', flex: 1 },
    { field: 'mmisVendorSpecialityCd', headerName: 'Specialities', flex: 1 },
    {
      field: 'select',
      headerName: 'Select',
      flex: 1,
      renderCell: (params) => {
        return (
          <Button onClick={(e) => selectedValue(params?.row)}>Select</Button>
        );
      },
    },
  ];

  const searchVendorID = async (data) => {
    try {
      showLoader();
      const res = await postVendorSearch(data);
      if (res.status === 200) {
        setRowsData(res.data.results);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      caseId: caseId,
      corpYN: null,
      federalTaxNumber: null,
      ldssId: null,
      location: null,
      mmisVendorId: null,
      mmisVendorSpecialityCd: null,
      sourceSystem: 'W',
      vendorCity: null,
      vendorId: null,
      vendorName: null,
      vendorState: null,
      vendorTypeCd: null,
      vendorZipcode: null,
      workerId: null,
    },
    onSubmit: (value) => {
      searchVendorID(value);
    },
  });

  return (
    <Box padding={2}>
      <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
        <Typography variant="h3">Institution Search</Typography>
        <IconButton onClick={toggleInstitution}>
          <CloseRounded />
        </IconButton>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Field name="vendorId" formik={formik} label="ID">
            <TextField placeholder="Enter the Institution Id" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="vendorName" formik={formik} label="Name">
            <TextField placeholder="Enter the Name" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field name="mmisVendorId" formik={formik} label="Specialities">
            <Dropdown placeholder="Enter the Specialities" />
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field
            name="vendorTypeCd"
            formik={formik}
            label="MMIS LTC Provider ID"
          >
            <TextField placeholder="Enter the MMIS LTC Provider ID" />
          </Field>
        </Grid>
      </Grid>
      <Stack spacing={2} direction={'row'} mt={2} justifyContent={'flex-end'}>
        <Button variant="outlined" onClick={toggleInstitution}>
          Cancel
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Search
        </Button>
      </Stack>

      {rowsData && (
        <Box sx={{ height: '20rem', width: '98%' }}>
          <DataGrid
            getRowId={(row) => row.vendorId}
            sx={{
              marginTop: 2,
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: 16,
                fontWeight: 700,
                padding: '0px 10px',
                textAlign: 'center',
                color: 'black',
              },
            }}
            slots={{
              noRowsOverlay: 'noData',
            }}
            columns={Column ?? []}
            rows={rowsData ?? []}
          />
        </Box>
      )}
    </Box>
  );
}
