import {
  Button,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import { values } from 'lodash';
import { postMedicalTemplate } from '../../../../utils/services/ManualNotices/apiRequest';
import useLoader from '../../../../hooks/useLoader';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import InputMask from 'react-input-mask';

export default function MedicalReport({
  toggleClose,
  noticeData,
  caseId,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  const [showLoader, hideLoader] = useLoader();

  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }

  const formik = useFormik({
    initialValues: {
      socialService: dataValue?.ldssName ?? noticeData?.organizationName ?? '',
      localDsitrict: dataValue?.sectionAddr ?? noticeData?.ldssDoName ?? '',
      date:
        dataValue?.date ?? noticeData?.applicationDate
          ? dayjs(noticeData?.applicationDate)
          : null,
      caseManager:
        dataValue?.caseManagerName ?? noticeData?.caseManagerName ?? '',
      phoneNumber:
        dataValue?.titleCaseManagerTelNmbr ??
        noticeData?.caseManagerTelNmbr ??
        '',
      customerName: '',
      // `${dataValue?.firstName} ${dataValue?.lastName}` ?? '',
      maId: dataValue?.maId ?? '',
      patientName: dataValue?.sectionNameOfPatient ?? '',
      dob: dataValue?.dateOfBirth ?? '',
      address:
        dataValue?.nameAddr ??
        `${noticeData?.caseStrtAddr1 ?? ''}, ${
          noticeData?.caseStrtCity ?? ''
        }, ${noticeData?.ldssState ?? ''}, ${noticeData?.caseZipCode ?? ''} ` ??
        '',
      firstVisit: dataValue?.firstVisit ?? '',
      lastVisit: dataValue?.lastVisit ?? '',
      clinicalDiag: dataValue?.diagnosis ?? '',
      substanceIssue: dataValue?.substanceAbuseIssue ?? '',
      substanceAbuse: dataValue?.otherMedicalConditions ?? '',
      impairment: dataValue?.physicalImpairment ?? '',
      impairmentPrevent: dataValue?.impairment3Month ?? '',
      expecetedImpairment: dataValue?.impairmentPrevent ?? '',
      impairmentMore: dataValue?.impairment12Month ?? '',
      fromDate: dataValue?.fromTime ?? '',
      toDate: dataValue?.toTime ?? '',
      comments: dataValue?.clarificationsComments ?? '',
      signature: dataValue?.signature ?? '',
      printName: dataValue?.printName ?? '',
      title: dataValue?.title ?? '',
      license: dataValue?.license ?? '',
      healthCare: dataValue?.caseStrtState ?? '',
      signatureDate: dataValue?.titleApplicationDate ?? '',
      phone: dataValue?.titleCaseManagerTelNmbr ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      medicalReport(values);
    },
  });

  const medicalReport = async (data) => {
    try {
      showLoader();
      const res = await postMedicalTemplate(data, caseId);
      if (res.status === 200) {
        showSuccessToast('Successfully Created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Stack direction="row" justifyContent="center" mt="2rem">
          <Typography variant="h3">
            Family Investment Administration: <br></br> TDAP Medical Report Form
            500-C
          </Typography>
        </Stack>
        <Grid ml="3rem" mr="3rem">
          <Grid container spacing={2} mt="0.2rem" ml="2rem">
            <Grid item xs={6}>
              <Field name="socialService" formik={formik}>
                <TextField variant="standard" />
              </Field>
              {/* <Typography>Department of Social Services</Typography> */}
            </Grid>
            <Grid item xs={6} mt="1rem">
              <Typography variant="h5grey">
                Department of Social Services
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h5grey">
                The Family Investment Administration is committed to providing
                access, and reasonable accommodation in its services, programs,
                activities, education and employment for individuals with
                disabilities. If you need assistance or need to request a
                reasonable accommodation, please contact your case manager or
                call 1-800-332-6347.
              </Typography>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Typography variant="subtitle1">
                Local District Office:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="localDsitrict" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="date" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Case Manager:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="caseManager" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Phone Number:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="phoneNumber"
                formik={formik}
                normalizers={[maxLength(10), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.phoneNumber}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Customer's Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="customerName"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">MA ID#:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="maId" formik={formik} normalizers={[maxLength(20)]}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5grey">
                The information provided on this form is used to detemine
                eligibility for Vermont's Temporary Disability Assistance
                Program (TDAP).
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h4">A. Patient Information</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Patient Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="patientName"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date of Birth:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="dob" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Address:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field name="address" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12} mt="0.5rem">
              <Typography variant="h4">Health Provider</Typography>
            </Grid>
            <Grid item xs={12} mt="0.5rem">
              <Typography variant="h4">B. Date/s of Examinatons</Typography>
            </Grid>
            <Grid item xs={4} mt="0.3rem">
              <Typography variant="subtitle1">First Visit:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="firstVisit" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Last Visit:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="lastVisit" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h4">
                C. Information About Impairment(s):
              </Typography>
            </Grid>
            <Grid item xs={10} mt="0.5rem">
              <Typography variant="h5grey">
                1. Provide the clincial diagnosis and name of impairment:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Field name="clinicalDiag" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12} mt="1rem">
              <Typography variant="h4">D. Health Status</Typography>
            </Grid>
            <Grid item xs={8} mt="0.5rem">
              <Typography variant="h5grey">
                1. Does this individual have a substance abuse issue?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="substanceIssue" formik={formik}>
                <RadioGroup
                  name="substanceIssue"
                  row
                  {...formik.getFieldProps('substanceIssue')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.substanceIssue}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={8} mt="0.5rem">
              <Typography variant="h5grey">
                If yes, do other medical conditions exist in addition to
                substance abuse?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="substanceAbuse" formik={formik}>
                <RadioGroup
                  name="substanceAbuse"
                  row
                  {...formik.getFieldProps('substanceAbuse')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.substanceAbuse}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={8} mt="0.5rem">
              <Typography variant="h5grey">
                2. Does this individual suffer from a physical /mental/emotional
                impairment ?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="impairment" formik={formik}>
                <RadioGroup
                  name="impairment"
                  row
                  {...formik.getFieldProps('impairment')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.impairment}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={8} mt="0.5rem">
              <Typography variant="h5grey">
                If yes, is the impairment severe enough to prevent the patient
                from working, participating in a work, training or educational
                activity.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="impairmentPrevent" formik={formik}>
                <RadioGroup
                  name="impairmentPrevent"
                  row
                  {...formik.getFieldProps('impairmentPrevent')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.impairmentPrevent}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={8} mt="0.5rem">
              <Typography variant="h5grey">
                3. Can the individual’s impairment be expected to last at least
                3 months?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="expecetedImpairment" formik={formik}>
                <RadioGroup
                  name="expecetedImpairment"
                  row
                  {...formik.getFieldProps('expecetedImpairment')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.expecetedImpairment}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={8} mt="0.5rem">
              <Typography variant="h5grey">
                If yes, can the individual’s impairment be expected to last at
                least 12 months or more?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="impairmentMore" formik={formik}>
                <RadioGroup
                  name="impairmentMore"
                  row
                  {...formik.getFieldProps('impairmentMore')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.impairmentMore}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5grey">
                Please give the length of time the patient’s impairment is
                expected to last.
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5.5}>
                <Field name="fromDate" formik={formik} isDate>
                  <DatePicker
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                  />
                </Field>
              </Grid>
              <Grid item xs={1} mt="0.8rem">
                <Typography>To</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <Field name="toDate" formik={formik} isDate>
                  <DatePicker
                    slotProps={{
                      textField: {
                        variant: 'standard',
                      },
                    }}
                  />
                </Field>
              </Grid>
            </Grid>
            <Grid item xs={12} mt="0.5rem">
              <Typography variant="h4">
                E. Please add comments or clarifications here:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="comments" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5grey">
                <>Signature of a health care provider</>with independent
                diagnostic authority, who is authorized to evaluate, determine
                impairment, and independently treat medical, mental and/or
                emotional disorders and conditions, and who is providing
                services according to the requirements of the appropriate
                professional board.
              </Typography>
            </Grid>
            <Grid item xs={4} mt="0.5rem">
              <Typography variant="subtitle1">Signature:</Typography>
            </Grid>
            <Grid item xs={6} mt="0.5rem">
              <Field name="signature" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Print Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="printName" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Title:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="title" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">License #:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="license" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Health Care Practice Name and Address:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="healthCare" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="signatureDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Phone #:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="phone"
                formik={formik}
                normalizers={[number, maxLength(10)]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.phone}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={12} mt="1rem" mb="1rem">
              <Typography variant="h5grey">
                DHS FIA 500C MEDICAL REPORT VERIFICATION FORM
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt="1rem" spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Disacrd
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
