import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { Stack } from '@mui/system';
import { CloseRounded, DeleteOutlineOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { deleteMemberApi } from '../../../utils/services/apiRequests';
import useLoader from '../../../hooks/useLoader';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';

function MemberDeleteModal({
  open,
  close,
  memberToDelete,
  caseId,
  onDelete,
  reasons,
}) {
  const { individualId, personName } = memberToDelete ?? {};
  const memberName = `${personName?.firstName} ${personName?.lastName}`;
  const [showLoader, hideLoader] = useLoader();
  const deleteMember = async ({
    deleteMemberDt,
    deleteMemberReasonCode = 557,
  }) => {
    // setOpenDeleteModal(true);
    console.log(individualId);
    const deletePayload = {
      deleteMemberDt,
      deleteMemberReasonCode,
      dirtyStatus: 'DELETE',
      individualId: individualId,
    };
    try {
      showLoader();
      const res = await deleteMemberApi(caseId, deletePayload);
      if (res.status === 200) {
        showSuccessToast('Deleted Successfully');
        close();
        onDelete();
        // fetchHouseMembers();
        // selectableViewRef.current?.setSelectedIdx(0);
        formik.resetForm();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
      close();
    }
  };
  const validationSchema = yup.object({
    deleteMemberDt: yup.string().required(),
    deleteMemberReasonCode: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      deleteMemberDt: '',
      deleteMemberReasonCode: '',
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      deleteMember(values);
    },
  });

  return (
    <Dialog open={open} onClose={close} component="form" maxWidth="md">
      <DialogContent>
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <IconButton onClick={close}>
            <CloseRounded />
          </IconButton>
        </Stack>
        <Stack
          component="form"
          onSubmit={formik.handleSubmit}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <DeleteOutlineOutlined
            sx={{ height: 100, width: 100, color: '#cf4242' }}
          />
          <Typography color="gray" variant="h3">
            Delete the Member?
          </Typography>
          <Stack spacing={2} direction="row" mt="1rem">
            <DatePicker
              views={['year', 'month', 'day']}
              maxDate={dayjs()}
              // renderInput={(params) => <TextField {...params} />}
              onChange={(value) =>
                formik.setFieldValue(
                  'deleteMemberDt',
                  dayjs(value).format('YYYY-MM-DD')
                )
              }
              sx={{ width: '100%' }}
            />
            <Dropdown
              options={reasons}
              {...formik.getFieldProps('deleteMemberReasonCode')}
            />
          </Stack>
          <Typography my="1rem" variant="subtitle1">
            Do you really want to delete member <strong>{memberName}</strong>?
            You will not able to recover it.
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button color="error" variant="outlined" onClick={close}>
              Discard
            </Button>
            <Button
              disabled={!formik.isValid || !formik.dirty}
              color="error"
              sx={{ mt: '1rem' }}
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

MemberDeleteModal.propTypes = {};

export default MemberDeleteModal;
