import React, { useEffect } from 'react';
import styles from './index.module.css';
import Notes from './Notes';
import { Paper, Box, Typography, Grid } from '@mui/material';
import QuickLink from './QuickLink';
import Notification from './Notification';
import CaseDetails from './CaseDetails';
import DashboardList from './DashboardList';
import UpComing from './Upcoming';
import { Stack } from '@mui/system';
import useLookup from '../../hooks/useLookup';
import DashboardLinks from './DashboardLinks';
import WorkItemTable from '../Dashboard/WorkItemTable';
import ShortCut from '../../components/ShortCut';

export default function NewDashobard() {
  const { lkpFlatten, getLkpMatch } = useLookup('Dashboard');
  return (
    <Box sx={{ flexGrow: 1, padding: 1 }}>
      <Grid container spacing={1} sx={{ height: 'auto' }}>
        <Grid item xs={9} sx={{ height: '100%' }}>
          <Grid container spacing={1} sx={{ height: '100%' }}>
            <Grid item xs={4}>
              <Box sx={{ bgcolor: 'white', height: '100%' }} borderRadius={1}>
                <DashboardLinks />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box sx={{ height: '100%', bgcolor: 'white' }} borderRadius={1}>
                <UpComing />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Stack sx={{ height: '100%' }}>
                <CaseDetails />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} sx={{ height: '100%' }}>
          <Grid container spacing={1} sx={{ height: '100%' }}>
            <Grid item xs={12}>
              <Box sx={{ height: '102%', bgcolor: 'white' }} borderRadius={1}>
                <QuickLink />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ height: '98%', bgcolor: 'white' }} borderRadius={1}>
                <Notes />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <ShortCut />
        </Grid> */}
      </Grid>

      <Box mt={1}>
        {/* <DashboardList getLkpMatch={getLkpMatch} lkpFlatten={lkpFlatten} /> */}
        <WorkItemTable />
      </Box>
    </Box>
  );
}
