export const searchPayload = (data) => {
  console.log(data);

  return {
    caseId: data?.caseId ?? null,
    workerId: data?.workerId || null,
    beginDate: data?.beginDate ?? null,
    endDate: data?.endDate ?? null,
    actionType: data?.actionType ?? null,
  };
};
