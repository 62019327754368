import React, { useMemo } from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import FormGroup from '../../../components/FormGroup';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';

const WbcchpForm = ({ getOptions, formik }) => {
  const { verificationWbcchpOpts } = useMemo(() => {
    return {
      verificationWbcchpOpts: getOptions('VerificationWbcchp'),
    };
  }, [getOptions]);

  const { medicallyNecessaryYN, ongoingTreatmentYN } = formik.values;
  return (
    <FormGroup label="WBCCHP Details">
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="effectiveBeginDate"
            label="Effective Begin Date"
            formik={formik}
            isDate
            required
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="effectiveEndDate"
            label="Effective End Date"
            formik={formik}
            isDate
          >
            <DatePicker />
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Field
            name="doctorsNoteDate"
            label="Doctor's Note Date"
            isDate
            formik={formik}
            required={formik.values.verificationSourceCode === 'RE'}
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="verificationSourceCode"
            label="Verification"
            required
            formik={formik}
          >
            <Dropdown
              options={verificationWbcchpOpts}
              placeholder="Select Type"
            />
          </Field>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Checkbox
            id="medical"
            name="medicallyNecessaryYN"
            onChange={(_e, checked) => {
              formik.setFieldValue('medicallyNecessaryYN', checked ? 'Y' : 'N');
            }}
            checked={medicallyNecessaryYN === 'Y'}
          />
          <Typography variant="subtitle2">Medically Necessary</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Checkbox
            id="treatment"
            name="ongoingTreatmentYN"
            onChange={(_e, checked) => {
              formik.setFieldValue('ongoingTreatmentYN', checked ? 'Y' : 'N');
            }}
            checked={ongoingTreatmentYN === 'Y'}
          />

          <Typography variant="subtitle2">Ongoing Treatment</Typography>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default WbcchpForm;
