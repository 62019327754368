import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Divider,
  Stack,
  Box,
  Paper,
  FormHelperText,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import UnearnedIncomeDetails from './UnearnedIncomeDetails';
import HttpClient from '../../../utils/HttpClient';
import { makeStyles } from '@mui/styles';
import useLoader from '../../../hooks/useLoader';
import cx from 'classnames';
import { get } from 'lodash';
import { showErrorToast } from '../../../utils/services/toast';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
}));

const UnearnedIncomeForm = ({
  lkpRaw,
  isAdd,
  formik,
  selectedData,
  getLkpMatch,
}) => {
  const classes = useStyles();
  const [subTypes, setSubTypes] = useState([]);

  const [showLoader, hideLoader] = useLoader();

  const handleDatePickerChange = (name, date) => {
    formik.setFieldValue(`${name}`, dayjs(date).format('YYYY-MM-DD'));
  };

  const handleAddIncomeAmount = () => {
    formik.setFieldValue('unearnedIncomeAmount', [
      ...formik.values.unearnedIncomeAmount,
      {
        expenses: [],
        amountModel: [],
        frequency: '',
        lateRptInd: '',
        rptDt: null,
        useMonth: null,
      },
    ]);
  };

  const areAllFormsFilled = () => {
    const { unearnedIncomeAmount } = formik.values;
    for (let i = 0; i < unearnedIncomeAmount.length; i++) {
      const income = unearnedIncomeAmount[i];
      let isEmptyAmountModel = income.amountModel.some(
        (item) => item.amount === '' || item.verificationSourceCode === ''
      );
      if (
        income.effectiveBeginDate === '' ||
        income.effectiveEndDate === '' ||
        income.frequency === '' ||
        isEmptyAmountModel
      ) {
        return false;
      }
    }
    return true;
  };

  const fetchLookupForType = async (payload) => {
    try {
      showLoader();
      const res = await HttpClient.post(
        '/eeapi/public/consumer/lookup-search/',
        payload
      );
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('Lookup fetch failed');
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handeChangeCallSubTypeApi = async (event) => {
    let { value, name } = event.target;
    formik.setFieldValue(`unearnedIncomeType`, value);
    formik.setFieldValue(`subType`, '');
    handleChangeCallBack(value);
  };

  const handleChangeCallBack = async (value) => {
    let getTypeLongValue = getLkpMatch('UnearnedIncomeType', value);
    const stringWithoutSpaces = getTypeLongValue.replace(/\s|\//g, '');
    let payload = [
      {
        lookupTypeCd: stringWithoutSpaces,
      },
    ];
    const res = await fetchLookupForType(payload);
    let types = res[stringWithoutSpaces];
    setSubTypes(types ? types : []);
  };

  const validateIsEmpty = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) &&
      get(formik, `touched.${fieldName}`) &&
      get(formik, `values.${fieldName}`) === '';

    return !!hasErrors;
  };

  const validateIsError = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) && get(formik, `touched.${fieldName}`);

    return !!hasErrors;
  };

  useEffect(() => {
    if (!isAdd) {
      formik.setValues({
        recordId: selectedData?.recordId,
        pymtStrtDt: selectedData?.pymtStrtDt ?? null,
        pymtEndDt: selectedData?.pymtEndDt ?? null,
        claimNumber: selectedData.claimNumber,
        companyName: selectedData.companyName,
        exmptCoc: selectedData.exmptCoc,
        subType: selectedData.subType,
        unearnedIncomeAmount: selectedData.unearnedIncomeAmount,
        unearnedIncomeType: selectedData.unearnedIncomeType,
      });
      formik.resetForm({ values: selectedData });
      handleChangeCallBack(selectedData.unearnedIncomeType);
    }
  }, [isAdd]);

  return (
    <>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsEmpty(
                  formik,
                  'unearnedIncomeType'
                ),
              })}
            >
              <InputLabel id="type">Type *</InputLabel>
              <Select
                labelId="type"
                id="select-type"
                label="Type *"
                size="small"
                value={formik.values.unearnedIncomeType}
                onChange={(e) => handeChangeCallSubTypeApi(e)}
              >
                {lkpRaw.UnearnedIncomeType.map((item) => (
                  <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                    {item?.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsEmpty(formik, 'unearnedIncomeType') && (
                <FormHelperText error>
                  {formik.errors.unearnedIncomeType}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsError(formik, 'subType'),
              })}
            >
              <InputLabel id="sub-type">Sub Type *</InputLabel>
              <Select
                labelId="sub-type"
                id="select-sub-type"
                label="Sub Type *"
                name="subType"
                size="small"
                value={formik.values.subType}
                disabled={!formik.values.unearnedIncomeType}
                onChange={formik.handleChange}
              >
                {subTypes?.map((item) => (
                  <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                    {item?.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsError(formik, 'subType') && (
                <FormHelperText error>{formik.errors.subType}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box m={2}>
        <Typography variant="h6">Payment Dates</Typography>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box mt={1} mb={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateIsError(
                      formik,
                      'pymtStrtDt'
                    ),
                  })}
                  label="Payment Start Date *"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  name="pymtStrtDt *"
                  slotProps={{ textField: { size: 'small' } }}
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    formik.values.pymtStrtDt
                      ? dayjs(formik.values.pymtStrtDt)
                      : null
                  }
                  onChange={(date) =>
                    handleDatePickerChange('pymtStrtDt', date)
                  }
                />
              </DemoContainer>
              {validateIsError(formik, 'pymtStrtDt') && (
                <FormHelperText error>
                  {formik.errors.pymtStrtDt}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1} mb={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateIsError(
                      formik,
                      'pymtEndDt'
                    ),
                  })}
                  label="Payment End Date"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  name="pymtEndDt"
                  minDate={
                    formik.values.pymtStrtDt && dayjs(formik.values.pymtStrtDt)
                  }
                  slotProps={{ textField: { size: 'small' } }}
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    formik.values.pymtEndDt
                      ? dayjs(formik.values.pymtEndDt).year() === 9999
                        ? null
                        : dayjs(formik.values.pymtEndDt)
                      : null
                  }
                  onChange={(date) => handleDatePickerChange('pymtEndDt', date)}
                />
              </DemoContainer>
              {validateIsError(formik, 'pymtEndDt') && (
                <FormHelperText error>{formik.errors.pymtEndDt}</FormHelperText>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box ml={2} mr={2}>
        <Stack direction="row" pt={1} justifyContent="space-between">
          <Typography variant="h6" p={0.5}>
            Unearned Income Details
          </Typography>
          <Button
            variant="text"
            size="small"
            p={0.5}
            color="primary"
            onClick={handleAddIncomeAmount}
            sx={{ cursor: 'pointer' }}
            disabled={!areAllFormsFilled()}
          >
            + Add Unearned Income Amount
          </Button>
        </Stack>
      </Box>
      {formik.values?.unearnedIncomeAmount.map((amount, index) => (
        <Box sx={{ border: '1px solid #e4e4e4', padding: '1rem' }}>
          <UnearnedIncomeDetails
            key={index}
            incomeIndex={index}
            incomeAmount={amount}
            lkpRaw={lkpRaw}
            formik={formik}
            isAdd={isAdd}
          />
        </Box>
      ))}
    </>
  );
};

export default UnearnedIncomeForm;
