import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../components/Dropdown';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useLoader from '../../../hooks/useLoader';
import {
  addNewMember,
  editMemberDetails,
  postEditMember,
} from '../../../utils/services/apiRequests';
import usePageNav from '../../../hooks/usePageNav';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import * as yup from 'yup';
import {
  BIRTHCITY,
  BIRTHSTATE,
  BIRTHVERIFICATIONDOC,
  DEATHCERT,
  DEATHSTATE,
  DOB_REQ,
  ETHNICITY_REQ,
  FIRST_NAME_REQ,
  GENDER_REQ,
  LAST_NAME_REQ,
  MARITAL_STATUS_REQ,
  RACE_REQ,
  SSN_INVALID,
  SSN_REFERRAL_REQ,
  VERFICATIONSOURCE,
  VERIFI,
  VERIFICATIONDOC,
} from '../../../constants/validationsMessage';
import dayjs from 'dayjs';
import {
  maxLength,
  name,
  number,
  onlyAlphabet,
} from '../../../utils/normalizers';

function MemberForm({
  memberDetails,
  getOptions,
  readOnly,
  createNew,
  editMode,
  close,
  fetchHouseMembers,
  hasHoh,
  clearanceData,
}) {
  const { caseId } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [maskSsn, setMaskSsn] = useState(true);

  const saveNewMember = async (data) => {
    try {
      showLoader();
      const res = await addNewMember(data, caseId);

      if (res.status === 200) {
        showSuccessToast('Successfully Added New Member');
        fetchHouseMembers();
        close();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  const updateMember = async (data) => {
    try {
      showLoader();
      const res = await postEditMember(caseId, data, individualId);

      if (res.status === 200) {
        showSuccessToast('Successfully Updated The Member Details');
        fetchHouseMembers();
        close();
      }
    } catch (error) {
      console.log(error);
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const {
    personName = {},
    dob = null,
    dobVerification = {},
    soSec = {},
    race = {},
    ethnicity = '',
    individualId = '',
    gender = '',
    maritalStatus = {},
    birthCity = '',
    birthState = '',
    deathDetails = '',
    headOfHouseholdYN = 'N',
    primaryVerificationSource = '',
    ssnVerificationId = '',
    dobVerificationId = '',
    ssnVerify = '',
    birthVerify = '',
    soSecReferral = '',
    otherRace = '',
    ssnApplicationDate = null,
  } = memberDetails ?? clearanceData ?? {};

  const validationSchema = yup.object().shape(
    {
      firstName: yup.string().required(FIRST_NAME_REQ),
      lastName: yup.string().min(3, LAST_NAME_REQ).required(LAST_NAME_REQ),
      gender: yup.string().required(GENDER_REQ),
      dobDoc: yup.string().required(BIRTHVERIFICATIONDOC),

      deathState: yup.string().when('dateOfDeath', {
        is: (dateOfDeath) => dateOfDeath,
        then: () => yup.string().required(DEATHSTATE),
        otherwise: () => yup.string().notRequired(),
      }),
      deathVerification: yup.string().when('dateOfDeath', {
        is: (dateOfDeath) => dateOfDeath,
        then: () => yup.string().required(VERIFI),
        otherwise: () => yup.string().notRequired(),
      }),
      dethCert: yup.string().when('dateOfDeath', {
        is: (dateOfDeath) => dateOfDeath,
        then: () => yup.string().required(DEATHCERT),
        otherwise: () => yup.string().notRequired(),
      }),
      // verSource: yup.string().required(VERFICATIONSOURCE),
      dob: yup.string().when('headOfHouse', {
        is: (isHoh) => isHoh,
        then: () =>
          yup
            .string()
            .test('is-adult', 'Hoh should be 18 years old', (value) => {
              const years18 = dayjs().subtract(18, 'y');
              const isAdult = dayjs(value).isBefore(years18);
              return isAdult;
            })

            .required(DOB_REQ),
        otherwise: () => yup.string().required(DOB_REQ),
      }),
      race: yup.string().required(RACE_REQ),
      ethnicity: yup.string().required(ETHNICITY_REQ),
      // ssn: yup.string().min(11, SSN_INVALID).notRequired(),
      marriedYN: yup.string().required(MARITAL_STATUS_REQ),
      verificationDoc: yup.string().when('ssn', {
        is: (ssn) => ssn,
        then: () => yup.string().required(VERIFICATIONDOC),
        otherwise: () => yup.string().notRequired(),
      }),

      ssnAppDt: yup.string().when(['ssnReferral'], {
        is: (ssnReferral) => {
          if (ssnReferral === 'PS' || ssnReferral === 'A') {
            return true;
          }
        },
        then: () => yup.string().required('Plese select SSN app date'),
        otherwise: () => yup.string().notRequired(),
      }),
      ssnReferral: yup.string().when(['ssnAppDt'], {
        is: (ssnAppDt) => !ssnAppDt,
        then: () => yup.string().required(SSN_REFERRAL_REQ),
        otherwise: () => yup.string().notRequired(),
      }),
    }[(['ssnReferral'], ['ssnAppDt', 'ssnReferral'])]
  );

  const ssn = `${soSec.soSecPart1 ?? ''} ${soSec.soSecPart2 ?? ''} ${
    soSec.soSecPart3 ?? ''
  }`;

  const formik = useFormik({
    initialValues: {
      firstName: personName?.firstName ?? clearanceData?.firstName ?? '',
      middleName: personName?.middleName ?? clearanceData?.middleName ?? '',
      lastName: personName?.lastName ?? clearanceData?.lastName ?? '',
      suffix: personName?.suffix ?? clearanceData?.suffix ?? '',
      gender,
      ssn: clearanceData?.ssn?.trim() ?? ssn.trim(),
      verificationDoc:
        soSec?.ssnVerification?.verificationDoc ?? ssnVerify ?? '',
      ssnAppDt: ssnApplicationDate ?? null,
      ssnReferral: soSecReferral ?? null,
      dob,
      dobDoc: birthVerify ?? '',
      birthCity: birthCity ?? '',
      birthState: birthState ?? '',
      race: race.primaryRace ?? '',
      ethnicity: ethnicity,
      additionRace: otherRace ?? '',
      verSource: primaryVerificationSource ?? '',
      dateOfDeath: null,
      deathVerification: '',
      deathState: '',
      headOfHouse: headOfHouseholdYN === 'Y',
      marriedYN: maritalStatus?.marriedYN ?? '',
      recordIdSSN: soSec.recordId ?? 0,
      recordIdRace: race.recordId ?? 0,
      recordIdMarital: maritalStatus.recordId ?? 0,
      recordIdDeath: deathDetails.recordId ?? 0,
      ssnVerificationId,
      dobVerificationId,
    },
    validationSchema,

    onSubmit: (values) => {
      if (createNew) {
        saveNewMember(values);
      } else if (editMode) {
        let { soSecPart1, soSecPart2, soSecPart3 } = soSec;
        const payloadData = {
          ...values,
          changeInNameYN:
            personName.firstName !== values.firstName ||
            personName.lastName !== values.lastName
              ? 'Y'
              : 'N',
          changeInSSNYN:
            `${soSecPart1} ${soSecPart2} ${soSecPart3}` !== values.ssn
              ? 'Y'
              : 'N',
          changeInDemographicYN: 'Y',
          changeInRaceYN:
            race.primaryRace !== values.race ||
            ethnicity !== values.ethnicity ||
            otherRace !== values.additionRace ||
            primaryVerificationSource !== values.verSource
              ? 'Y'
              : 'N',
          // changeInMaritalYN:
          //   maritalStatus?.marriedYN !== values.maritalStatus ? 'Y' : 'N',
        };

        updateMember(payloadData);
      }
    },
  });

  const {
    genderOpts,
    suffixOpts,
    raceOpts,
    ethnicityOpts,
    ssnRefOpts,
    verSouceOpts,
    martialStsOpts,
    dobCertOpts,
    stateOpts,
    deathVerfOpts,
    verifyEthnicityOpts,
  } = useMemo(() => {
    return {
      genderOpts: getOptions('Gender'),
      suffixOpts: getOptions('Suffix'),
      raceOpts: getOptions('Race'),
      ethnicityOpts: getOptions('Ethnicity'),
      ssnRefOpts: getOptions('SsnReferral'),
      verSouceOpts: getOptions('CLSSNVERCD'),
      martialStsOpts: getOptions('MartialStatus'),
      dobCertOpts: getOptions('CLDOBVERCD'),
      stateOpts: getOptions('State'),
      deathVerfOpts: getOptions('CLDEATHVERCD'),
      verifyEthnicityOpts: getOptions('ClEthnicity'),
    };
  }, [getOptions]);

  return (
    <Box key={individualId} mb="3rem">
      <FormSection label="Name" mt="1.5rem">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              label="First Name"
              normalizers={[maxLength(30), onlyAlphabet]}
              formik={formik}
              name="firstName"
              required
            >
              <TextField
                disabled={createNew ? personName?.firstName : readOnly}
                placeholder="Enter First Name"
              />
            </Field>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              label="Middle Name"
              normalizers={[maxLength(30), onlyAlphabet]}
              formik={formik}
              name="middleName"
            >
              <TextField
                disabled={createNew ? personName?.middleName : readOnly}
                placeholder="Enter Middle Name"
              />
            </Field>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field
              label="Last Name"
              formik={formik}
              normalizers={[maxLength(30), onlyAlphabet]}
              name="lastName"
              required
            >
              <TextField
                disabled={createNew ? personName?.lastName : readOnly}
                placeholder="Enter Last Name"
              />
            </Field>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Field label="Suffix" formik={formik} name="suffix">
              <Dropdown
                disabled={readOnly}
                options={suffixOpts}
                placeholder="Select Suffix"
              />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Birth Information" mt="1.5rem">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              label="Date of Birth"
              isDate
              formik={formik}
              name="dob"
              required
            >
              <DatePicker disableFuture disabled={createNew ? dob : readOnly} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Verify DOB Document"
              formik={formik}
              name="dobDoc"
              required
            >
              <Dropdown
                disabled={readOnly}
                options={dobCertOpts}
                placeholder="Select DOB Doc Type"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Birth City"
              normalizers={[maxLength(30), name]}
              formik={formik}
              name="birthCity"
            >
              <TextField disabled={readOnly} placeholder="Enter birth city" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field label="Birth State" formik={formik} name="birthState">
              <Dropdown
                disabled={readOnly}
                options={stateOpts}
                placeholder="Select Birth State"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field label="Gender" formik={formik} name="gender" required>
              <Dropdown
                disabled={createNew ? gender : readOnly}
                options={genderOpts}
                placeholder="Select Gender"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Marital Status"
              formik={formik}
              name="marriedYN"
              required
            >
              <Dropdown
                disabled={readOnly}
                options={martialStsOpts}
                placeholder="Select Marital Status"
              />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="SSN Information" mt="1.5rem">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field label="SSN Number" formik={formik} name="ssn">
              <InputMask mask="999-99-9999" disabled={readOnly} maskChar={null}>
                {(params) => {
                  return (
                    <OutlinedInput
                      type={maskSsn ? 'password' : 'text'}
                      variant="outlined"
                      placeholder="Enter SSN"
                      // disabled={createNew ? soSec?.soSecPart3 : readOnly}
                      endAdornment={
                        <InputAdornment position="start">
                          <IconButton onClick={() => setMaskSsn(!maskSsn)}>
                            {maskSsn ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...params}
                    />
                  );
                }}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Verification Document"
              formik={formik}
              name="verificationDoc"
              required={formik.values.ssn}
            >
              <Dropdown
                disabled={readOnly}
                options={verSouceOpts}
                placeholder="Select SSN Doc Type"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="SSN App Date"
              formik={formik}
              isDate
              name="ssnAppDt"
              required={
                formik.values.ssnReferral === 'A' ||
                formik.values.ssnReferral === 'PS'
              }
            >
              <DatePicker disabled={readOnly} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="SSN Referral"
              required={!formik.values.ssnAppDt}
              formik={formik}
              name="ssnReferral"
            >
              <Dropdown
                disabled={readOnly}
                options={ssnRefOpts}
                placeholder="Select SSN Referral"
              />
            </Field>
          </Grid>
        </Grid>
      </FormSection>

      <FormSection label="Race & Ethnicity" mt="1.5rem">
        <Grid container spacing={2}>
          <Grid item xs={4} md={6}>
            <Field label="Race" formik={formik} name="race" required>
              <Dropdown
                disabled={readOnly}
                options={raceOpts}
                placeholder="Select Race"
              />
            </Field>
          </Grid>
          <Grid item xs={4} md={6}>
            <Field label="Ethnicity" formik={formik} name="ethnicity" required>
              <Dropdown
                disabled={createNew ? ethnicity : readOnly}
                options={ethnicityOpts}
                placeholder="Select Ethnicty"
              />
            </Field>
          </Grid>
          <Grid item xs={4} md={6}>
            <Field label="Addition Race" formik={formik} name="additionRace">
              <Dropdown
                disabled={readOnly}
                options={raceOpts}
                placeholder="Select Race"
              />
            </Field>
          </Grid>
          <Grid item xs={4} md={6}>
            <Field label="Verification Source" formik={formik} name="verSource">
              <Dropdown
                disabled={readOnly}
                options={verifyEthnicityOpts}
                placeholder="Select verification Source"
              />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Death Information" mt="1.5rem">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field label="Death Date" formik={formik} isDate name="dateOfDeath">
              <DatePicker disableFuture disabled={readOnly} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field label="Death State" formik={formik} name="deathState">
              <Dropdown
                disabled={readOnly}
                options={stateOpts}
                placeholder="Select Death State"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Death Verification"
              formik={formik}
              name="deathVerification"
            >
              <Dropdown
                disableFuture
                disabled={readOnly}
                options={deathVerfOpts}
                placeholder="Select Verification"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Death Certification No "
              formik={formik}
              normalizers={[maxLength(15)]}
              name="dethCert"
            >
              <TextField
                disabled={readOnly}
                placeholder="Death Certification No"
              />
            </Field>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              {...formik.getFieldProps('headOfHouse')}
              checked={formik.values.headOfHouse}
              control={<Checkbox />}
              disabled={readOnly || (hasHoh && !formik.values.headOfHouse)}
              label="Head of the Household"
            />
          </Grid>
        </Grid>
      </FormSection>
      {(editMode || createNew) && (
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined">Discard</Button>
          <Button
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
            variant="contained"
          >
            Save
          </Button>
        </Stack>
      )}
    </Box>
  );
}

MemberForm.propTypes = {
  createNew: PropTypes.bool,
  readOnly: PropTypes.bool,
  editMode: PropTypes.bool,
};

MemberForm.defaultProps = {
  createNew: false,
  readOnly: false,
  editMode: false,
};

export default MemberForm;
