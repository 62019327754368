import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { isNumber, isString, map } from 'lodash';
import FormSection from '../FormSection';
import TextContent from '../TextContent';
import CurrencyField from '../CurrencyField';

function TextContentGroup({ textContents, alignRight, single, col, spacing }) {
  const getSubtitle = (type, value) => {
    if (isString(value)) {
      return {
        renderSubtitle: () => <Typography variant="body2">{value}</Typography>,
      };
    }

    if (type === 'currency') {
      return {
        renderSubtitle: () => (
          <CurrencyField alignRight={alignRight} amount={value} />
        ),
      };

      if (isNumber(value)) {
        return {
          renderSubtitle: () => (
            <Typography variant="body2">
              <strong>$</strong>&nbsp;{(value ?? 0).toFixed(2)}
            </Typography>
          ),
        };
      }
      if (isString(value)) {
        return {
          renderSubtitle: () => (
            <Typography variant="body2">{value}</Typography>
          ),
        };
      }

      return {
        renderSubtitle: () => (
          <Typography variant="body2">
            {' '}
            <strong>$</strong>&nbsp;{(value ?? 0).toFixed(2)}
          </Typography>
        ),
      };
    }

    return {
      subTitle: {
        variant: 'body2',
        content: value,
      },
    };
  };

  if (single) {
    return (
      <Grid container spacing={spacing} p="0.5rem">
        {map(
          textContents,
          ({
            title,
            subTitle,
            type,
            hide = false,
            showIf = true,
            renderSubtitle: _renderSubtitle,
          }) => {
            if (hide || !showIf) {
              return null;
            }

            return (
              <Grid item xs={6} lg={col}>
                <TextContent
                  fullWidth
                  alignRight={alignRight}
                  title={{ variant: 'subtitle2', content: title }}
                  {...(_renderSubtitle
                    ? { renderSubtitle: _renderSubtitle }
                    : getSubtitle(type, subTitle))}
                  //   renderSubtitle={() => (
                  //     <Typography variant="body2">
                  //       <strong>$</strong>&nbsp;{subTitle.toFixed(2)}
                  //     </Typography>
                  //   )}
                />
              </Grid>
            );
          }
        )}
      </Grid>
    );
  }
  return (
    <Stack spacing={2}>
      {map(
        textContents,
        ({
          label,
          fields,
          hide: hideGroup = false,
          showIf: showGroup = true,
        }) => {
          if (hideGroup || !showGroup) {
            return null;
          }
          return (
            <FormSection paddingZero label={label}>
              <Grid container spacing={spacing} p="0.5rem">
                {map(
                  fields,
                  ({ title, subTitle, type, hide = false, showIf = true }) => {
                    if (hide || !showIf) {
                      return null;
                    }

                    return (
                      <Grid item xs={col}>
                        <TextContent
                          title={{ variant: 'subtitle2', content: title }}
                          {...getSubtitle(type, subTitle)}
                          //   renderSubtitle={() => (
                          //     <Typography variant="body2">
                          //       <strong>$</strong>&nbsp;{subTitle.toFixed(2)}
                          //     </Typography>
                          //   )}
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
            </FormSection>
          );
        }
      )}
    </Stack>
  );
}

TextContentGroup.propTypes = {
  textContents: PropTypes.array,
  col: PropTypes.number,
  spacing: PropTypes.number,
  single: PropTypes.bool,
  alignRight: PropTypes.bool,
};

TextContentGroup.defaultProps = {
  textContents: [],
  col: 3,
  spacing: 2,
  single: false,
  alignRight: false,
};

export default TextContentGroup;
