import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import Avatar from '../Avatar';
import { Box, Stack, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

function AvatarGroup({ names, onAdd, addable, customSize }) {
  return (
    <Stack direction="row">
      {map(names, (name) => {
        return (
          <Tooltip title={name}>
            <Box mr="-0.7rem">
              <Avatar customSize={customSize} bordered name={name} />
            </Box>
          </Tooltip>
        );
      })}
      {addable && (
        <Tooltip title="Select More">
          <Box
            component="button"
            sx={{ all: 'unset', cursor: 'pointer' }}
            onClick={onAdd}
          >
            <Avatar custom bordered>
              <AddIcon />
            </Avatar>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
}

AvatarGroup.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  addable: PropTypes.bool,
  customSize: PropTypes.number,
};

AvatarGroup.defaultProps = {
  names: ['John Peter', 'Alexander Powel', 'Due Joh', 'Annah Sarrah'],
  addable: true,
  customSize: undefined,
};

export default AvatarGroup;
