export const programIcons = {
  CH: 'CashAssistance',
  DS: 'Disaster',
  EA: 'Emergency',
  FS: 'Snap',
  LT: 'LongTerm',
  MA: 'Medicaid',
  WV: 'Waiver',
};

export const PROGRAM_COMBO = {
  CH: ['CH', 'DS', 'FS', 'MA'],
  DS: ['CH', 'DS', 'FS', 'MA'],
  EA: ['CH', 'DS', 'FS', 'MA'],
  FS: ['CH', 'DS', 'FS', 'MA'],
  LT: 'WV',
  MA: '',
  WV: 'LT',
};
