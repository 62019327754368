import React, { useState, useEffect, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddEditUnEarnedIncomeApplicationDetailsDialog from './AddEditUnEarnedIncomeApplicationDetailsDialog';
import InComeDataGrid from '../InComeDataGrid';
import DocumentUploadFilesDialog from '../DocumentUploadFilesDialog';
import { find } from 'lodash';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import { saveUnEarnedIncomeApp } from '../../../utils/services/apiRequests';
import DeleteConfiramation from '../DeleteConfiramation';
import useLoader from '../../../hooks/useLoader';

const UnearnedIncomeApplication = ({
  member,
  setOpen,
  open,
  isAdd,
  setIsAdd,
  docOpen,
  setDocOpen,
  lkpRaw,
  lkpFlatten,
  getLkpMatch,
  caseId,
  fetchIncome,
  docmentFiles,
}) => {
  const [showLoader, hideLoader] = useLoader();
  const [selectedData, setSelectedData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
    },
    {
      field: 'subTypeCd',
      headerName: 'Sub Type',
      width: 150,
    },
    {
      field: 'expectedDt',
      headerName: 'Received Date',
      width: 130,
      editable: false,
    },
    {
      field: 'verificationSourceCode',
      headerName: 'Verification',
      width: 150,
    },
    {
      field: 'appliedUnearnedIncomeStatusCd',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleEdit = (rowData) => {
    setOpen(true);
    setIsAdd(false);
    const getRow = find(member.unearnedIncomeApplications, {
      appliedUnearnedIncomeId: rowData.recordId,
    });
    setSelectedData(getRow);
  };

  const handleDelete = async (rowData) => {
    const getRow = find(member.unearnedIncomeApplications, {
      appliedUnearnedIncomeId: rowData.recordId,
    });

    setSelectedData(getRow);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      showLoader();
      const res = await saveUnEarnedIncomeApp(
        caseId,
        selectedData,
        member.individualId,
        isAdd,
        true
      );
      if (res.status === 200) {
        showSuccessToast('Unearned Income Application successfully deleted');
        fetchIncome();
        setOpenDeleteDialog(false);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  const getRowId = (row) => {
    return row.recordId;
  };

  const gridData = useMemo(() => {
    if (!member || !member.unearnedIncomeApplications) {
      return [];
    }

    return member.unearnedIncomeApplications.map((income) => {
      const {
        subTypeCd,
        verificationCd,
        expectedDt,
        appliedUnearnedIncomeId,
        appliedUnearnedIncomeStatusCd,
        appliedUnearnedIncomeTypeCd,
        recordId,
      } = income;

      return {
        recordId: appliedUnearnedIncomeId,
        appliedUnearnedIncomeStatusCd: getLkpMatch(
          'AppliedUnearnedIcomeStatus',
          appliedUnearnedIncomeStatusCd
        ),
        subTypeCd: getLkpMatch('AppliedUnearnedIncomeType', subTypeCd),
        verificationSourceCode: getLkpMatch(
          'AppliedIncomeVerification',
          verificationCd.verificationSourceCode
        ),
        expectedDt,
        type: 'Income',
      };
    });
  }, [member]);

  return (
    <>
      <InComeDataGrid
        rows={gridData}
        columns={columns}
        loading={false}
        getRowId={getRowId}
      />
      <AddEditUnEarnedIncomeApplicationDetailsDialog
        setOpen={setOpen}
        open={open}
        isAdd={isAdd}
        lkpRaw={lkpRaw}
        member={member}
        caseId={caseId}
        fetchIncome={fetchIncome}
        selectedData={selectedData}
      />
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        lkpRaw={lkpRaw}
        member={member}
        caseId={caseId}
        docmentFiles={docmentFiles}
      />
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default UnearnedIncomeApplication;
