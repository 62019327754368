import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SelectableView from '../../components/SelectableView';
import { Box, Button, Stack, Typography } from '@mui/material';
import TabularView from '../../components/TabularView';
import CitizenshipForm from './CitizenshipForm';
import useLookup from '../../hooks/useLookup';
import useLoader from '../../hooks/useLoader';
import { useParams } from 'react-router';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import { getCitizenship } from '../../utils/services/apiRequests';
import { getAge } from '../../utils/helpers';
import CitizenshipContent from './CitizenshipContent';
import { filter, find, isEmpty, size, some } from 'lodash';
import usePageNav from '../../hooks/usePageNav';
import PageContainer from '../../components/PageContainer';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import CitizenshipHistory from './CitizenshipHistory';
import usePage from '../../hooks/usePage';

function Citizenship({ id }) {
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { caseId, navigations } = usePageNav();
  const { navProps } = usePage(id);
  const [showLoader, hideLoader] = useLoader();
  const [fetchingCitizenship, setFetchingCitizenship] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [citizenships, setCitizenships] = useState([]);
  const { getOptions, lkpFlatten, getLkpMatch, lkpRaw } =
    useLookup('Citizenship');
  const toggleAddModal = () => setOpenAddModal(!openAddModal);
  const toggleEditModal = () => setOpenEditModal(!openEditModal);

  const disableNext =
    citizenships.length < houseMembers.length ||
    fetchingCitizenship ||
    fetchingMembers;

  const fetchCitizenship = async () => {
    try {
      setFetchingCitizenship(true);
      const res = await getCitizenship(caseId);

      if (res.status === 200) {
        setCitizenships(res.data?.citizenshipImmigration ?? []);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setFetchingCitizenship(false);
    }
  };

  useEffect(() => {
    fetchCitizenship();
  }, []);

  const onNext = () => {
    navigations.toAuthorizedRep();
  };

  const onBack = () => {
    if (size(houseMembers) > 1) {
      navigations.toRelationship();
    } else {
      navigations.toHhMembers();
    }
    // navigations.toRelationship();
  };

  return (
    <PageContainer
      screenId={id}
      title="Citizenship details"
      toVerify="LAWFUL_PRESENCE"
      onNext={onNext}
      onBack={onBack}
      disableNext={disableNext}
      navProps={navProps}
    >
      <SelectableView
        loading={fetchingMembers}
        loadingContent={fetchingCitizenship}
        data={houseMembers}
        title="Members"
        showEdit={false}
        showAdd={false}
        onAdd={toggleAddModal}
        onEdit={toggleEditModal}
        showDelete={false}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        // isDisableAdd={(c) => {
        //   const citizenship = find(
        //     citizenships,
        //     ({ individualId }) =>
        //       String(individualId) === String(c.individualId)
        //   );
        //   return !isEmpty(citizenship);
        // }}
      >
        {(selected) => {
          const citizenship = find(
            citizenships,
            ({ individualId }) =>
              String(individualId) === String(selected.individualId)
          );
          const citizenshipHistory = filter(
            citizenships,
            ({ individualId }) =>
              String(individualId) === String(selected.individualId)
          );
          const age = getAge(selected.dob);
          return (
            <>
              {openAddModal && (
                <CitizenshipForm
                  open={openAddModal}
                  caseId={caseId}
                  onClose={toggleAddModal}
                  getOptions={getOptions}
                  fetchCitizenship={fetchCitizenship}
                  individualId={selected.individualId}
                  age={age}
                />
              )}
              {openEditModal && (
                <CitizenshipForm
                  editMode
                  citizenship={citizenship}
                  open={openEditModal}
                  caseId={caseId}
                  onClose={toggleEditModal}
                  getOptions={getOptions}
                  fetchCitizenship={fetchCitizenship}
                  individualId={selected.individualId}
                  age={age}
                />
              )}
              {/* <CitizenshipContent
                selected={selected}
                citizenship={citizenship}
                getLkpMatch={getLkpMatch}
                addCitizenship={toggleAddModal}
              /> */}
              <CitizenshipHistory
                getLkpMatch={getLkpMatch}
                citizenships={citizenshipHistory}
                caseId={caseId}
                getOptions={getOptions}
                fetchCitizenship={fetchCitizenship}
                addCitizenship={toggleAddModal}
                lkpRaw={lkpRaw}
                selected={selected}
                toggleAddModal={toggleAddModal}
              />
            </>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}

Citizenship.propTypes = {};

export default Citizenship;
