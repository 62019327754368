import axios from 'axios';

const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_BASE_URL,
  // headers: {
  //   Role: 'cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN',
  //   uid: 'localUser',
  //   Uniqueid: '7c438d573165cdd1552231ed3bdd33',
  //   'Content-Type': 'application/json',
  // },
});

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_KEY_CLOCK,
  withCredentials: true,
});

export default HttpClient;
