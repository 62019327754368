import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DeleteOutline, ModeEditOutlineOutlined } from '@mui/icons-material';
import styles from './index.module.css';
import cx from 'classnames';
import { generateAvatarBg } from '../../utils/helpers';
import { noop } from 'lodash';
import dayjs from 'dayjs';

function StickyNote({ title, subTitle, date, id, onDelete, deleting, onEdit }) {
  return (
    <Box
      style={{ background: generateAvatarBg(`${title}-${id}`, 58, 81) }}
      className={cx(styles.stickynote)}
    >
      {deleting && <div className={styles.overlay} />}
      <Stack
        direction="row"
        className={styles.footer}
        // sx={{ alignItems: 'center' }}
      >
        <Typography
          className={styles.title}
          sx={{ fontWeight: '700' }}
          variant="h6"
        >
          {title}
        </Typography>
        <IconButton onClick={onEdit}>
          <Tooltip title="Edit" placement="top">
            <ModeEditOutlineOutlined fontSize="small" />
          </Tooltip>
        </IconButton>
      </Stack>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Tooltip title={subTitle}>
          <Typography className={styles.subTitle} variant="caption">
            {subTitle}
          </Typography>
        </Tooltip>
      </Stack>

      <Stack direction="row" className={styles.footer}>
        <Tooltip title={date}>
          <div className={styles.date}>
            <span>{dayjs(date).format('YYYY-MM-DD HH:mm:ss')}</span>
          </div>
        </Tooltip>
        <IconButton onClick={() => onDelete(id)}>
          <DeleteOutline />
        </IconButton>
      </Stack>
    </Box>
  );
}

StickyNote.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.number,
  onDelete: PropTypes.func,
  delete: PropTypes.bool,
};

StickyNote.defaultProps = {
  title: '',
  subTitle: '',
  date: 'Today, 11:15 am',
  id: 0,
  onDelete: noop,
  deleteting: false,
};

export default StickyNote;
