import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import InitiateCase from './InitiateCase';

function AllCaseComments() {
  return (
    <Box backgroundColor="#d9d9d9" padding="1rem" borderRadius="5px">
      <Grid>
        <InitiateCase />
      </Grid>
    </Box>
  );
}

export default AllCaseComments;
