import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import storage from 'redux-persist/lib/storage';
import authReducer from './authSlice';
import loaderReducer from './loaderSlice';
import caseReducer from './caseSlice';
import navigationReducer from './navigationSlice';
import eligibilityReducer from './eligibilitySlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

export function createReducer() {
  const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),
    loader: loaderReducer,
    case: caseReducer,
    navigation: navigationReducer,
    eligibility: eligibilityReducer,
  });

  return rootReducer;
}
