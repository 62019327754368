import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import MultiStepper from '../../../../components/MultiStepper';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import ChildSupport from './ChildSupport';
import MaritalInfo from './MaritalInfo';
import DMVMilitary from './DMVMilitary';
import AbsentParents from './AbsentParents';
import useLookup from '../../../../hooks/useLookup';
import { CloseRounded } from '@mui/icons-material';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../../store/selectors/caseSelector';
import { getAge, getFullName } from '../../../../utils/helpers';
import dayjs from 'dayjs';
import { filter, map } from 'lodash';
import { hideLoader, showLoader } from '../../../../store/slices/loaderSlice';
import { postDemographic } from '../../../../utils/services/NonCustodial/apiRequests';
import usePageNav from '../../../../hooks/usePageNav';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import { maxLength, onlyAlphabet, number } from '../../../../utils/normalizers';

export default function DemographicModal({
  open,
  toggleClose,
  isEditData,
  childData,
  getDemographicDet,
  demographicEdit,
  absentParentId,
}) {
  const { caseId, navigations } = usePageNav();

  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const memberOpts = useMemo(() => {
    const filteredMembers = houseMembers.filter(
      (item) => calculateAge(item.dob) <= 19
    );
    const options = map(filteredMembers, (item) => {
      const { personName } = item;
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      const id = item?.individualId;

      return {
        label: fullName,
        value: id,
      };
    });

    return options;
  }, [houseMembers]);

  const { getOptions, getLkpMatch } = useLookup('NonCustodialParent');
  const {
    suffixOpts,
    genderOpts,
    verifyOpts,
    raceOpts,
    realtionOpts,
    stateOpts,
    countryOpts,
  } = useMemo(() => {
    return {
      suffixOpts: getOptions('Suffix'),
      genderOpts: getOptions('Gender'),
      verifyOpts: getOptions('YesNo'),
      raceOpts: getOptions('Race'),
      realtionOpts: getOptions('AbsentParentRelationship'),
      stateOpts: getOptions('State'),
      countryOpts: getOptions('CountryOfOrigin'),
    };
  }, [getOptions]);

  const validationSchema = Yup.object({
    fName: Yup.string().required('Please Enter First Name'),
    lName: Yup.string().required('Please Enter Last Name'),
    support: Yup.string().required('Please Select the child support'),
    cName: Yup.string().required('Please select the Name'),
    cRelation: Yup.string().required('Please Select Relation'),
    cDeprivation: Yup.string().required('Please select Derprivation'),
  });

  const memberInfo = filter(
    childData,
    (item) => item?.absentParentId === absentParentId
  );
  const formik = useFormik({
    initialValues: {
      fName:
        memberInfo?.[0]?.abIdentification?.absentParentName?.firstName ?? '',
      mName:
        memberInfo?.[0]?.abIdentification?.absentParentName?.middleName ?? '',
      lName:
        memberInfo?.[0]?.abIdentification?.absentParentName?.lastName ?? '',
      suffix: memberInfo?.[0]?.abIdentification?.absentParentName?.suffix ?? '',
      gender: memberInfo?.[0]?.abDemographicInfo?.gender ?? '',
      ssn:
        memberInfo?.[0]?.abIdentification?.soSec?.soSecPart1 &&
        memberInfo?.[0]?.abIdentification?.soSec?.soSecPart2 &&
        memberInfo?.[0]?.abIdentification?.soSec?.soSecPart3
          ? `${memberInfo?.[0]?.abIdentification?.soSec?.soSecPart1}-${memberInfo?.[0]?.abIdentification?.soSec?.soSecPart2}-${memberInfo?.[0]?.abIdentification?.soSec?.soSecPart3}`
          : '',
      verification:
        memberInfo?.[0]?.abIdentification?.ssnVerification
          ?.verificationSourceCode ?? '',
      dob: memberInfo?.[0]?.abDemographicInfo?.dob ?? '',
      approximateAge: memberInfo?.[0]?.abDemographicInfo?.approximateAge ?? '',
      dobVerification:
        memberInfo?.[0]?.abDemographicInfo?.dobVerification
          ?.verificationSourceCode ?? '',
      race: memberInfo?.[0]?.abDemographicInfo?.race ?? '',
      relationship: memberInfo?.[0]?.abDemographicInfo?.relationshipTo ?? '',
      city: memberInfo?.[0]?.abDemographicInfo?.birthCity ?? '',
      state: memberInfo?.[0]?.abDemographicInfo?.birthState ?? '',
      country: memberInfo?.[0]?.abDemographicInfo?.birthCountry ?? '',
      ivd: memberInfo?.[0]?.abIdentification?.ivdNumber ?? '',
      ivdCoop: '',
      ivdCoopDate: memberInfo?.[0]?.abGoodCause?.ivdCoopIndEffectiveDate ?? '',
      assignRights: memberInfo?.[0]?.abIdentification?.ivdAssignRightsYN ?? '',
      support: memberInfo?.[0]?.abIdentification?.childSpprt ?? '',
      refferalDate: memberInfo?.[0]?.abIdentification?.referralDate ?? '',
      cName:
        memberInfo?.[0]?.abIdentification?.children?.[0]?.individualId ?? '',
      cRelation:
        memberInfo?.[0]?.abIdentification?.children?.[0]?.relationship ?? '',
      cDeprivation:
        memberInfo?.[0]?.abIdentification?.children?.[0]?.deprivation ?? '',
      childName:
        memberInfo?.[0]?.abIdentification?.children?.[0]?.personName
          ?.firstName ?? '',
      childRelation: memberInfo?.[0]?.abIdentification?.[0]?.relationship ?? '',
      childDeprivation:
        memberInfo?.[0]?.abIdentification?.[0]?.deprivation ?? '',
      reason: memberInfo?.[0]?.abGoodCause?.goodCauseReason ?? '',
      reasonStatus: memberInfo?.[0]?.abGoodCause?.goodCauseStatus ?? '',
      effectiveDate: memberInfo?.[0]?.abGoodCause?.goodCauseEffectiveDate ?? '',
      maritalStatus: memberInfo?.[0]?.abMaritalInfo?.maritalStatus ?? '',
      date: memberInfo?.[0]?.abMaritalInfo?.maritalDate ?? '',
      marriageCity: memberInfo?.[0]?.abMaritalInfo?.marriageCity ?? '',
      maritalState: memberInfo?.[0]?.abMaritalInfo?.marriageState ?? '',
      maritalCountry: memberInfo?.[0]?.abMaritalInfo?.marriageCountry ?? '',
      height: memberInfo?.[0]?.abPhysicalAttributes?.height ?? '',
      hairColor: memberInfo?.[0]?.abPhysicalAttributes?.hairColor ?? '',
      weight: memberInfo?.[0]?.abPhysicalAttributes?.heightFeet ?? '',
      eyeColor: memberInfo?.[0]?.abPhysicalAttributes?.eyeColor ?? '',
      incarceration: memberInfo?.[0]?.abIncarceration?.incarcerationYN ?? '',
      institution: memberInfo?.[0]?.abIncarceration?.institution ?? '',
      incarcerationCity:
        memberInfo?.[0]?.abIncarceration?.incarcerationCity ?? '',
      incarcerationState:
        memberInfo?.[0]?.abIncarceration?.incarcerationState ?? '',
      incarcerationCountry:
        memberInfo?.[0]?.abIncarceration?.incarcerationCountry ?? '',
      unionLocal: memberInfo?.[0]?.unionOrLocal ?? '',
      healthInsurance: memberInfo?.[0]?.healthInsuranceYN ?? '',
      drivingLicense:
        memberInfo?.[0]?.absentParentMVA?.drivingLicenseNumber ?? '',
      drivingState: memberInfo?.[0]?.absentParentMVA?.drivingLicenseState ?? '',
      licensePlate: memberInfo?.[0]?.absentParentMVA?.licensePlateNumber ?? '',
      licenseState: memberInfo?.[0]?.absentParentMVA?.licensePlateState ?? '',
      veteranStatus: memberInfo?.[0]?.abMilitaryInfo?.veteranStatus ?? '',
      veteranNumber: memberInfo?.[0]?.abMilitaryInfo?.veteranId ?? '',
      startDate: memberInfo?.[0]?.abMilitaryInfo?.veteranServiceStartDate ?? '',
      endDate: memberInfo?.[0]?.abMilitaryInfo?.veteranServiceEndDate ?? '',
      branch: memberInfo?.[0]?.abMilitaryInfo?.veteranServiceBranch ?? '',
      payingAllotment:
        memberInfo?.[0]?.abMilitaryInfo?.veteranPayingAllotmentYN ?? '',
      recipient: memberInfo?.[0]?.abMilitaryInfo?.veteranRecepient ?? '',
      fatherFName: memberInfo?.[0]?.fatherName?.firstName ?? '',
      fatherMName: memberInfo?.[0]?.fatherName?.middleName ?? '',
      fatherLName: memberInfo?.[0]?.fatherName?.lastName ?? '',
      fatherSuffix: memberInfo?.[0]?.fatherName?.suffix ?? '',
      fatherline1:
        memberInfo?.[0]?.fatherContactInformation?.homeAddress?.addressLine1 ??
        '',
      fatherline2:
        memberInfo?.[0]?.fatherContactInformation?.homeAddress?.addressLine2 ??
        '',
      fatherCity:
        memberInfo?.[0]?.fatherContactInformation?.homeAddress?.city ?? '',
      fatherState:
        memberInfo?.[0]?.fatherContactInformation?.homeAddress?.state ?? '',
      fatherZipCode:
        memberInfo?.[0]?.fatherContactInformation?.homeAddress?.zipcode ?? '',
      fatherphone:
        memberInfo?.[0]?.fatherContactInformation?.phoneNumber?.phoneNumber ??
        '',
      fatherExt:
        memberInfo?.[0]?.fatherContactInformation?.phoneNumber
          ?.phoneNumberExt ?? '',
      fatherPhoneType:
        memberInfo?.[0]?.fatherContactInformation?.phoneNumber
          ?.phoneNumberType ?? '',
      motherFName: memberInfo?.[0]?.motherName?.firstName ?? '',
      motherMName: memberInfo?.[0]?.motherName?.middleName ?? '',
      motherLName: memberInfo?.[0]?.motherName?.lastName ?? '',
      maidenName: memberInfo?.[0]?.motherMaidenName ?? '',
      motherSuffix: memberInfo?.[0]?.motherName?.suffix ?? '',
      motherline1:
        memberInfo?.[0]?.motherContactInformation?.homeAddress?.addressLine1 ??
        '',
      motherline2:
        memberInfo?.[0]?.motherContactInformation?.homeAddress?.addressLine2 ??
        '',
      motherCity:
        memberInfo?.[0]?.motherContactInformation?.homeAddress?.city ?? '',
      motherState:
        memberInfo?.[0]?.motherContactInformation?.homeAddress?.state ?? '',
      motherZipCode:
        memberInfo?.[0]?.motherContactInformation?.homeAddress?.zipcode ?? '',
      motherPhone:
        memberInfo?.[0]?.motherContactInformation?.phoneNumber?.phoneNumber ??
        '',
      motherExt:
        memberInfo?.[0]?.motherContactInformation?.phoneNumber
          ?.phoneNumberExt ?? '',
      motherPhoneType:
        memberInfo?.[0]?.motherContactInformation?.phoneNumber
          ?.phoneNumberType ?? '',
      addChildSupport:
        memberInfo?.[0]?.children?.map((child) => ({
          cName: child?.individualId,
          cRelation: child?.relationship,
          cDeprivation: child?.deprivation,
        })) || [],
      dityStatus: isEditData ? 'insert' : 'update',
      recordId: memberInfo?.[0]?.recordId ?? 0,
    },
    onSubmit: (values) => {
      postDemographicDetails(values);
    },
    validationSchema,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const stages = useMemo(() => {
    return [
      { title: 'Non-Custodial Parent Identification', done: currentStep === 0 },
      { title: 'Child Support Info', done: currentStep === 1 },
      { title: 'Marital & Other Info', done: currentStep === 2 },
      { title: 'DMV & Military Info', done: currentStep === 3 },
      { title: 'Absent Parents Info', done: currentStep === 4 },
    ];
  });

  const onBack = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onBackOrNext = async (newStep) => {
    setCurrentStep(newStep);
  };

  const postDemographicDetails = async (data) => {
    try {
      showLoader();
      const res = await postDemographic(data, caseId);
      if (res.status === 200) {
        showSuccessToast('Succesfully Added Demographic Details');
        toggleClose();
        getDemographicDet();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (formik.values.dob) {
      formik.setFieldValue('approximateAge', calculateAge(formik.values.dob));
    }
  }, [formik.values.dob]);

  return (
    <>
      <Dialog
        open={open}
        component="form"
        maxWidth="lg"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '80vw',
              height: '80vh',
            },
          },
        }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Add Demographic Details</Typography>
            <IconButton onClick={toggleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ minHeight: '100%' }}>
            <MultiStepper steps={stages} />
            <Box
              sx={{
                border: '1px solid lightgrey',
                borderRadius: '0.5rem',
                p: '1rem',
                height: '100%',
                marginTop: '2rem',
              }}
            >
              {currentStep === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      name="fName"
                      label="First Name"
                      formik={formik}
                      required
                      normalizers={[maxLength(25), onlyAlphabet]}
                    >
                      <TextField />
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="mName"
                      label="Middle Name"
                      formik={formik}
                      normalizers={[maxLength(25), onlyAlphabet]}
                    >
                      <TextField />
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="lName"
                      label="Last Name"
                      formik={formik}
                      required
                      normalizers={[maxLength(25), onlyAlphabet]}
                    >
                      <TextField />
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field name="suffix" label="Suffix" formik={formik}>
                      <Dropdown options={suffixOpts} />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="gender" label="Gender" formik={formik}>
                      <Dropdown options={genderOpts} />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="ssn"
                      label="SSN"
                      formik={formik}
                      normalizers={[maxLength(9), number]}
                    >
                      <TextField />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="verification"
                      label="Verification"
                      formik={formik}
                    >
                      <Dropdown options={verifyOpts} />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="dob" label="DOB" formik={formik} isDate>
                      <DatePicker
                        disableFuture
                        value={formik.values.dob}
                        onChange={(value) => formik.setFieldValue('dob', value)}
                        renderInput={(params) => (
                          <TextField {...params} name="dob" />
                        )}
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="approximateAge"
                      label="Approximate Age"
                      formik={formik}
                    >
                      <TextField
                        value={formik.values.approximateAge}
                        onChange={formik.handleChange}
                        disabled
                      />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="dobVerification"
                      label="Verification"
                      formik={formik}
                    >
                      <Dropdown options={verifyOpts} />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="race" label="Race" formik={formik}>
                      <Dropdown options={raceOpts} />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="relationship"
                      label="Relationship To Hoh"
                      formik={formik}
                    >
                      <Dropdown options={realtionOpts} />
                    </Field>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <Field
                      name="city"
                      label="City"
                      formik={formik}
                      normalizers={[maxLength(25), onlyAlphabet]}
                    >
                      <TextField />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="state" label="State" formik={formik}>
                      <Dropdown options={stateOpts} />
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="country" label="Country" formik={formik}>
                      <Dropdown options={countryOpts} />
                    </Field>
                  </Grid>
                </Grid>
              )}
              {currentStep === 1 && (
                <ChildSupport
                  getOptions={getOptions}
                  formik={formik}
                  memberOpts={memberOpts}
                />
              )}
              {currentStep === 2 && (
                <MaritalInfo getOptions={getOptions} formik={formik} />
              )}
              {currentStep === 3 && (
                <DMVMilitary getOptions={getOptions} formik={formik} />
              )}
              {currentStep === 4 && (
                <AbsentParents getOptions={getOptions} formik={formik} />
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="end">
            <Button
              size="large"
              sx={{ mr: '0.5rem' }}
              variant="outlined"
              disabled={currentStep === 0}
              onClick={() => onBack()}
            >
              Back
            </Button>

            {currentStep === stages.length - 1 && (
              <Button
                type="submit"
                size="large"
                // disabled={!errors}
                variant="contained"
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            )}
            {currentStep < stages.length - 1 && (
              <Button
                size="large"
                onClick={() => onBackOrNext(currentStep + 1)}
                variant="contained"
              >
                Next
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
