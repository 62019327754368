import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TabularView from '../../../components/TabularView';
import { Button, Stack, Typography } from '@mui/material';
import { find } from 'lodash';
import EmptyState from '../../../components/EmptyState';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import { useParams } from 'react-router-dom';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';

const StyledTabularView = styled(TabularView)({
  table: {
    width: 'auto',
  },
  td: {
    // paddingBottom: '12px',
    border: 0,
    // padding: 0,
    padding: '0 1.25rem 0.62rem  0',
  },
  tr: {
    padding: '10px',
    ':nth-child(even)': {
      //   backgroundColor: 'red',
    },
  },
});

const StyledTitle = styled(Stack)({
  paddingBottom: '0.62rem',
  borderBottom: '1px solid var(--Secon-3-Grey, #C2C2B7)',
  marginBottom: '1.25rem',
  marginTop: '1rem',
});

const headerComponent = {
  variant: 'h5',
  color: 'var(--grey-400)',
};

const cellComponent = {
  variant: 'body2',
  color: 'var(--grey-500)',
};

function CitizenshipContent({
  citizenship,
  getLkpMatch,
  addCitizenship,
  lkpRaw,
  selected,
}) {
  const citizenshipSts = citizenship?.usCitizenshipStatus;
  const isImmigrant = citizenshipSts === 'L';
  const isNatCitizen = citizenshipSts === 'N';
  const isUsBorn = citizenshipSts === 'R';
  const isUsCitizen = citizenshipSts === 'C';
  const isUnDocImmigrant = citizenshipSts === 'I';
  const [activeTab, setActiveTab] = useState(0);
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const params = useParams();

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };

  if (!citizenshipSts) {
    return (
      <EmptyState
        header="Citizenship has not been added."
        primaryAction={
          <Button onClick={addCitizenship} variant="contained">
            Add citizenship
          </Button>
        }
      />
    );
    // return (
    //   <Typography variant="h3" sx={{ m: '5rem', textAlign: 'center' }}>
    //     Citizenship has not been added.
    //   </Typography>
    // );
  }

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  return (
    <>
      <TabPanel value={activeTab} index={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: '1rem', mb: '1rem' }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<UploadFileIcon />}
            onClick={handleOpenDocModal}
          >
            Upload files
          </Button>
        </Stack>
      </TabPanel>
      <StyledTitle
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography variant="h4">Citizenship Details</Typography>
        <Typography variant="subtitle2">
          Citizen status: {getLkpMatch('Citizenship', citizenshipSts)}
        </Typography>
      </StyledTitle>
      <StyledTabularView
        data={citizenship}
        cellComponent={cellComponent}
        headerComponent={headerComponent}
        headerAxis="y"
        columns={[
          {
            field: 'beginDt',
            headerName: 'Begin Date',
            hide: isUsBorn || isUsCitizen,
          },
          {
            field: 'endDt',
            headerName: 'End Date',
            hide: isUsBorn || isUsCitizen || isUnDocImmigrant,
          },
          {
            // field: 'voterRegistration',
            value: getLkpMatch(
              'VoterRegisteration',
              citizenship?.voterRegistration
            ),
            headerName: 'Voter Registration',
            hide: isImmigrant || isUsBorn || isUnDocImmigrant,
          },
        ]}
      />
      {isImmigrant && (
        <>
          <StyledTitle>
            <Typography variant="h5">Immigration Details</Typography>
          </StyledTitle>
          <StyledTabularView
            headerAxis="y"
            cellComponent={cellComponent}
            headerComponent={headerComponent}
            columns={[
              // {
              //   headerName: 'USCIS Document',
              //   value: getLkpMatch(
              //     'CitizenshipVerification',
              //     citizenship?.immigrationDocument
              //   ),
              // },
              {
                headerName: 'USCIS Status Date',
                value: citizenship?.uscisStatusDate,
              },
              { headerName: 'Card', value: '' },
              { headerName: 'Alien #', value: citizenship?.alienNumber },
              { headerName: 'I 94 #', value: citizenship?.i94Number },
              { headerName: 'Passport #', value: citizenship?.passportNumber },
              {
                headerName: 'Country of Origin',
                value: getLkpMatch(
                  'CountryOfOrigin',
                  citizenship?.countryOfPassport
                ),
              },
              { headerName: 'SELVIS ID', value: citizenship?.sevisId },
              {
                headerName: 'USCIS Status',
                value: getLkpMatch('INSStatus', citizenship?.uscisStatus),
              },
              { headerName: 'Visa Number', value: citizenship?.visaNumber },
              { headerName: 'Date of Entry', value: citizenship?.entryDate },
            ]}
          />

          <StyledTitle>
            <Typography variant="h5">Resident Information</Typography>
          </StyledTitle>
          <StyledTabularView
            headerAxis="y"
            cellComponent={cellComponent}
            headerComponent={headerComponent}
            columns={[
              {
                headerName: 'Lived in the U.S. since August 22, 1196?',
                value: getLkpMatch('YesNo', citizenship?.residentshipPeriodYN),
              },
              {
                headerName:
                  'Lived in the U.S. as a lawful resident for at least five years?',
                value: getLkpMatch(
                  'YesNo',
                  citizenship?.lawfulResidentFiveYearsYN
                ),
              },
              {
                headerName:
                  'Completed forty quarters of qualified work in the U.S?',
                value: getLkpMatch('YesNo', citizenship?.i40QuarterIndYN),
              },
            ]}
          />
          <StyledTitle>
            <Typography variant="h5">Refugee Information</Typography>
          </StyledTitle>
          <StyledTabularView
            headerAxis="y"
            cellComponent={cellComponent}
            headerComponent={headerComponent}
            columns={[
              {
                headerName: 'Refugee Resettlement Agency',
                value: getLkpMatch(
                  'RefugeeResettlementAgency',
                  citizenship?.refugeeSettlementAgency
                ),
              },
            ]}
          />
        </>
      )}
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </>
  );
}

CitizenshipContent.propTypes = {};

export default CitizenshipContent;
