import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
  Checkbox,
  TextField,
  FormLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Dialog,
  DialogTitle,
} from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import InputField from '../../../components/InputField';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import {
  addNewMember,
  editMemberDetails,
} from '../../../utils/services/apiRequests';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import InputMask from 'react-input-mask';
import { CloseRounded } from '@mui/icons-material';
import {
  DOB_REQ,
  ETHNICITY_REQ,
  FIRST_NAME_REQ,
  GENDER_REQ,
  LAST_NAME_MIN,
  LAST_NAME_REQ,
  MARITAL_STATUS_REQ,
  RACE_REQ,
  SSN_INVALID,
  SSN_REFERRAL_REQ,
} from '../../../constants/validationsMessage';
import { values } from 'lodash';
import { getAge } from '../../../utils/helpers';
import useLoader from '../../../hooks/useLoader';
import { maxLength, onlyAlphabet } from '../../../utils/normalizers';

function DemographicInfo({
  hasHoh,
  caseId,
  programs,
  date,
  setCaseDetails,
  getOptions,
  open,
  close,
  onAddUser,
  memberDetails,
  editMode,
  fetchHouseMembers,
  clearanceData,
  lkpFlatten,
}) {
  const dispatch = useDispatch();
  const [showLoader, hideLoader] = useLoader();
  const [maskSsn, setMaskSsn] = useState(true);

  const {
    ssnRefOpts,
    maritialStsOpts,
    ethnicityOpts,
    genderOpts,
    raceOpts,
    suffixOpts,
  } = useMemo(() => {
    return {
      suffixOpts: getOptions('Suffix'),
      ssnRefOpts: getOptions('SsnReferral'),
      raceOpts: getOptions('Race'),
      maritialStsOpts: getOptions('MartialStatus'),
      ethnicityOpts: getOptions('Ethnicity'),
      genderOpts: getOptions('Gender'),
    };
  }, [getOptions]);

  const updateMember = async (data) => {
    try {
      showLoader();
      const res = await editMemberDetails(caseId, data, individualId);
      if (res.status === 200) {
        showSuccessToast('Successfully Updated The Member Details');
        fetchHouseMembers();
        close();
      }
    } catch (error) {
      console.log(error);
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const saveNewMember = async (data) => {
    try {
      showLoader();
      const res = await addNewMember(data, caseId, programs, date);

      if (res.status === 200) {
        showSuccessToast('Successfully Added New Member');
        setCaseDetails(res.data);
        onAddUser(res.data?.caseId);
        close();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required(FIRST_NAME_REQ),
    lastName: Yup.string().min(3, LAST_NAME_MIN).required(LAST_NAME_REQ),
    dob: Yup.string().when('headOfHouse', {
      is: (isHoh) => isHoh,
      then: () =>
        Yup.string()
          .test('is-adult', 'Hoh should be 18 years old', (value) => {
            const years18 = dayjs().subtract(18, 'y');
            const isAdult = dayjs(value).isBefore(years18);
            return isAdult;
          })
          .required(DOB_REQ),
      otherwise: () => Yup.string().required(DOB_REQ),
    }),
    gender: Yup.string().required(GENDER_REQ),
    race: Yup.string().required(RACE_REQ),
    ethnicity: Yup.string().required(ETHNICITY_REQ),
    marriedYN: Yup.string().required(MARITAL_STATUS_REQ),
    ssn: Yup.string().min(11, 'Please enter valid ssn').notRequired(),
    // Yup.string().required('1st Digit must not in 9').matches(/[^9]/),
    // ssn: Yup.string().min(11, SSN_INVALID).notRequired(),
    ssnReferral: Yup.string().when('ssn', {
      is: (ssn) => !!ssn,
      then: () => Yup.string().notRequired(),
      otherwise: () =>
        Yup.string().required(
          'SSN Referral is required if SSN is not provided'
        ),
    }),
  });

  const {
    personName = {},
    dob = null,
    dobVerification = {},
    soSec = {},
    race = {},
    ethnicity = '',
    individualId = '',
    gender = '',
    soSecReferral = '',
    maritalStatus = {},
    headOfHouseholdYN = 'N',
    irn = '',
    masterid = '',
    firstName = '',
    lastName = '',
  } = memberDetails ?? clearanceData ?? {};

  const ssn = `${soSec.soSecPart1 ?? ''} ${soSec.soSecPart2 ?? ''} ${
    soSec.soSecPart3 ?? ''
  }`;
  const formik = useFormik({
    initialValues: {
      firstName: personName?.firstName ?? firstName ?? '',
      middleName: personName?.middleName ?? '',
      lastName: personName?.lastName ?? lastName ?? '',
      suffix: personName?.suffix ?? '',
      gender,
      dob,
      ssn: ssn.trim(),
      race: race.primaryRace ?? race ?? '',
      ethnicity: ethnicity,
      marriedYN: maritalStatus?.marriedYN,
      ssnReferral: soSecReferral,
      headOfHouse: editMode ? headOfHouseholdYN === 'Y' : !hasHoh,
      dirtyStatus: editMode ? 'update' : 'insert',
      irn: editMode ? irn : '',
      masterid: editMode ? masterid : '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (editMode) {
        updateMember(values);
      } else {
        saveNewMember(values);
      }
    },
  });

  return (
    <Dialog open={open} onClose={close} maxWidth="lg">
      <DialogTitle
        component={Stack}
        direction="row"
        margin={1}
        justifyContent="space-between"
      >
        <Typography variant="h3">Customer Demographic Information</Typography>
        <IconButton onClick={close}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <Paper
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ padding: '10px' }}
      >
        {/* <Typography sx={{ margin: '1rem 0 2rem 0' }} variant="h2">
          Customer Demographic Information
        </Typography> */}
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            <FormSection label="Name">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Field
                    required
                    name="firstName"
                    label="First Name"
                    normalizers={[maxLength(30), onlyAlphabet]}
                    formik={formik}
                  >
                    <TextField
                      disabled={
                        (!editMode && personName.firstName) ||
                        (!editMode && firstName)
                      }
                      placeholder="Enter First Name"
                    />
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name="middleName"
                    label="Middle Name"
                    formik={formik}
                    normalizers={[onlyAlphabet, maxLength(30)]}
                  >
                    <TextField
                      disabled={!editMode && personName.middleName}
                      placeholder="Enter Middle Name"
                    />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    required
                    name="lastName"
                    label="Last Name"
                    normalizers={[maxLength(30), onlyAlphabet]}
                    formik={formik}
                  >
                    <TextField
                      disabled={
                        (!editMode && personName.lastName) ||
                        (!editMode && lastName)
                      }
                      placeholder="Enter Last Name"
                    />
                  </Field>
                </Grid>
                <Grid item xs={2}>
                  <Field name="suffix" label="Suffix" formik={formik}>
                    <Dropdown
                      options={suffixOpts}
                      placeholder="Select Suffix"
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>

          <Grid item xs={12}>
            <FormSection label="Birth Information">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Field
                    required
                    name="dob"
                    isDate
                    formik={formik}
                    label="Date of Birth"
                  >
                    <DatePicker
                      disabled={!editMode && dob}
                      maxDate={dayjs()}
                      disableFuture
                      requried

                      // value={dayjs(formik.values.dob)}
                      // onChange={onDobChange}
                    />
                  </Field>
                  <Typography
                    sx={{
                      color: 'var(--darkred)',
                      fontSize: '0.7rem',
                      fontWeight: '400',

                      padding: '0.4rem 0.2rem',
                    }}
                  >
                    {formik?.errors?.dob}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Field required name="gender" label="Gender" formik={formik}>
                    <Dropdown
                      disabled={!editMode && gender}
                      options={genderOpts}
                      placeholder="Select Gender"
                    />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field required name="race" label="Race" formik={formik}>
                    <Dropdown
                      disabled={!editMode && race}
                      options={raceOpts}
                      placeholder="Select Race"
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
          <Grid item xs={12}>
            <FormSection label="Identity Info">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field name="ssn" label="SSN" formik={formik}>
                    <InputMask
                      disabled={!editMode && soSec.soSecPart3}
                      mask="999-99-9999"
                      maskChar={null}
                    >
                      {(params) => (
                        <OutlinedInput
                          type={maskSsn ? 'password' : 'text'}
                          variant="outlined"
                          placeholder="Enter SSN"
                          endAdornment={
                            <InputAdornment position="start">
                              <IconButton onClick={() => setMaskSsn(!maskSsn)}>
                                {maskSsn ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          {...params}
                        />
                      )}
                    </InputMask>
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    required={!formik.values.ssn}
                    name="ssnReferral"
                    label="SSN Referral"
                    formik={formik}
                  >
                    <Dropdown
                      options={ssnRefOpts}
                      placeholder="Select SSN Referral"
                      // disabled={formik.values.ssn}
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
          <Grid item xs={12}>
            <FormSection label="Ethnicity & Marital Status">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    required
                    name="ethnicity"
                    label="Ethnicity"
                    formik={formik}
                  >
                    <Dropdown
                      // disabled={!editMode && ethnicity}
                      options={ethnicityOpts}
                      placeholder="Select Ethnicity"
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    required
                    name="marriedYN"
                    label="Marital Status"
                    formik={formik}
                  >
                    <Dropdown
                      options={maritialStsOpts}
                      disabled={!editMode && maritalStatus?.marriedYN}
                      placeholder="Select Marital Status"
                    />
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    sx={{ alignItems: 'center', height: '100%' }}
                  >
                    <Checkbox
                      id="headOfHouse"
                      name="headOfHouse"
                      disabled={
                        editMode ? hasHoh && headOfHouseholdYN !== 'Y' : hasHoh
                      }
                      {...formik.getFieldProps('headOfHouse')}
                      checked={formik.values.headOfHouse}
                      value={formik.values.headOfHouse}
                    />
                    <FormLabel htmlFor="headOfHouse">
                      <Typography>Head of the Household</Typography>
                    </FormLabel>
                  </Stack>
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{ padding: '2rem 1rem' }}
        >
          <Button
            type="submit"
            variant="contained"
            disabled={Object.keys(formik.errors).length > 0}
            // disabled={!formik.isValid || !formik.dirty}
            onClick={formik.handleSubmit}
          >
            Save
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
}

DemographicInfo.propTypes = {
  close: PropTypes.func,
};

DemographicInfo.defaultProps = {
  close: () => {},
};

export default DemographicInfo;
