import { GET_EDUCATION_DETAILS } from './url';
import HttpClient, { HttpClientFileUpload } from '../../HttpClient/index';
import { format } from '../../helpers/index';
import { POST_EDUCATION_DETAILS } from './url';
import { addEducationDetailsPayload } from './payLoads';

export const getEducationDetails = (caseId, individualId) => {
  return HttpClient.get(format(GET_EDUCATION_DETAILS, caseId, individualId));
};

export const postEducationDetails = (
  data = {},
  caseId,
  individualId,
  dirtyStatus
) => {
  return HttpClient.post(
    format(POST_EDUCATION_DETAILS, caseId, individualId),
    addEducationDetailsPayload(data, dirtyStatus)
  );
};
