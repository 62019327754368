import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import Field from '../../../../components/Field';
import { showErrorToast } from '../../../../utils/services/toast';
import {
  editNote,
  saveNote,
} from '../../../../utils/services/Note/apiRequests';
import useLoader from '../../../../hooks/useLoader';
import { noop } from 'lodash';
import * as yup from 'yup';

function AddNote({ note, editMode, open, close, getNotes, uniqueId }) {
  const [show, hide] = useLoader();
  const saveWorkNote = async (data) => {
    try {
      show();
      const res = await saveNote(data);
      if (res.status === 200) {
        close();
        getNotes();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hide();
    }
  };

  const updateNote = async (data) => {
    try {
      show();
      const res = await editNote(data);
      if (res.status === 200) {
        close();
        getNotes();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hide();
    }
  };

  const validationSchema = yup.object({
    workNotes: yup.string().required(),
    title: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      workNotes: note.workNotes,
      title: note.title,
      workerNotesId: note.workerNotesId,
      uniqueId,
    },
    validationSchema,
    onSubmit(values) {
      editMode ? updateNote(values) : saveWorkNote(values);
    },
  });

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Add Note</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Field
          sx={{ width: '20rem' }}
          label="Title"
          formik={formik}
          name="title"
          showError={false}
          required
        >
          <TextField placeholder="Enter title" />
        </Field>
        <Field
          label="Note"
          showError={false}
          formik={formik}
          name="workNotes"
          required
        >
          <TextareaAutosize
            style={{ minHeight: '10rem' }}
            placeholder="Enter Note"
          />
        </Field>
        <Stack p="0.5rem 0" alignItems="space-end" direction="row" spacing={1}>
          <Button variant="outlined" onClick={close}>
            Close
          </Button>
          <Button
            disabled={!formik.isValid || !formik.dirty}
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Save
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

AddNote.propTypes = {
  note: PropTypes.shape({}),
  editMode: PropTypes.bool,
  open: PropTypes.bool,
  close: PropTypes.func,
  getNotes: PropTypes.func,
};

AddNote.defaultProps = {
  note: {
    workNotes: '',
    title: '',
    workerNotesId: undefined,
  },
  editMode: false,
  open: false,
  close: noop,
  getNotes: noop,
};

export default AddNote;
