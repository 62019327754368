import {
  Button,
  Stack,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
} from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import Dropdown from '../../../../components/Dropdown';
import FormGroup from '../../../../components/FormGroup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';

function AddEducation({
  open,
  handleClose,
  StudentStatusCodeOpts,
  StudentVerificationOpts,
  EducationalLevelOpts,
  HighestGradeOpts,
  GradVerificationOpts,
  GoodStandingOpts,
  GoodStandingVerificationOpts,
  StateOpts,
  onSaveEducation,
  editData,
  editEducation,
}) {
  const validationSchema = Yup.object(
    {
      effectiveBeginDate: Yup.string().required('Please select the Begin Date'),
      gradDate: Yup.string().required('Please select the Graduation Date'),
      lastgradecompleted: Yup.string().required('Please select the Last Grade'),
      levelofeducation: Yup.string().when('studentStatus', {
        is: (studentStatus) => {
          return (
            studentStatus === 'FS' ||
            studentStatus === 'FT' ||
            studentStatus === 'HT' ||
            studentStatus === 'PT'
          );
        },
        then: () => Yup.string().required('Education Level is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      verification: Yup.string().when('studentStatus', {
        is: (studentStatus) => {
          return (
            studentStatus === 'PT' ||
            studentStatus === 'FT' ||
            studentStatus === 'HT'
          );
        },
        then: () => Yup.string().required('Education Level is required'),
        otherwise: () => Yup.string().notRequired(),
      }),
      schoolverification: Yup.string().when('gradDate', {
        is: (gradDate) => gradDate,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
      standingverification: Yup.string().when('goodstanding', {
        is: (goodstanding) => goodstanding,
        then: () => Yup.string().required('Please select Verification'),
        otherwise: () => Yup.string().notRequired(),
      }),
    },
    ['gradDate', 'schoolverification'],
    ['goodstanding', 'standingverification']
  );

  const formik = useFormik({
    initialValues: {
      recordId: editData?.recordId ?? '',
      effectiveBeginDate: editData?.effectiveBeginDate ?? '',
      effectiveEndDate: editData?.effectiveEndDate ?? '',
      studentStatus: editData?.studentStatus ?? '',
      verification:
        editData?.studentStatusVerification?.verificationSourceCode ?? '',
      levelofeducation: editData?.educationLevel ?? '',
      lastgradecompleted: editData?.highestGrade ?? '',
      schoolname: editData?.schoolName ?? '',
      schoolnumber: editData?.schoolNumber ?? '',
      gradDate: editData?.graduationDate ?? '',
      schoolverification:
        editData?.graduationDateVerification?.verificationSourceCode ?? '',
      goodstanding: editData?.goodStanding ?? '',
      standingverification:
        editData?.goodStandingVerification?.verificationSourceCode ?? '',
      addressline1: editData?.schoolAddress?.addressLine1 ?? '',
      addressline2: editData?.schoolAddress?.addressLine2 ?? '',
      city: editData?.schoolAddress?.city ?? '',
      state: editData?.schoolAddress?.state ?? '',
      zipcode: editData?.schoolAddress?.zipcode ?? '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (editEducation) {
        onSaveEducation(values, 'update');
      } else {
        onSaveEducation(values, 'insert');
      }
      resetForm();
    },
  });

  return (
    <Dialog
      onSubmit={formik.handleSubmit}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ fontSize: 20 }}>
        {editEducation ? 'Edit Education Details' : 'Add Education Details'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form>
          <DialogContentText tabIndex={-1}>
            <Grid item xs={12}>
              <Grid>
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        required
                        name="effectiveBeginDate"
                        label="Begin Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker
                          disableFuture
                          value={dayjs(formik.values.beginDate)}
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="effectiveEndDate"
                        label="End Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker
                          minDate={dayjs(formik.values.effectiveBeginDate)}
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup label="Student Status">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="studentStatus"
                        label="Student Status"
                        formik={formik}
                      >
                        <Dropdown
                          options={StudentStatusCodeOpts}
                          placeholder="Select Status"
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="verification"
                        label="Verification"
                        formik={formik}
                        required={['FT', 'HT', 'PT'].includes(
                          formik.values.studentStatus
                        )}
                      >
                        <Dropdown
                          options={StudentVerificationOpts}
                          placeholder="Select Verification"
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup label="School Information">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="levelofeducation"
                        label="Level of Education"
                        formik={formik}
                        required={['FS', 'FT', 'HT', 'PT'].includes(
                          formik.values.studentStatus
                        )}
                      >
                        <Dropdown
                          options={EducationalLevelOpts}
                          placeholder="Select Education Level"
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="lastgradecompleted"
                        label="Last Grade Completed"
                        formik={formik}
                        required
                      >
                        <Dropdown
                          options={HighestGradeOpts}
                          placeholder="Select Last Grade"
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="schoolname"
                        label="School Name"
                        formik={formik}
                        normalizers={[maxLength(30)]}
                      >
                        <TextField placeholder="Enter School Name" />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="schoolnumber"
                        label="Enter School Number"
                        formik={formik}
                        normalizers={[maxLength(3)]}
                      >
                        <TextField placeholder="Enter School Number" />
                      </Field>
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        name="gradDate"
                        label="Graduation Date"
                        isDate
                        required
                        formik={formik}
                      >
                        <DatePicker disableFuture requried />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="schoolverification"
                        label="Select Verification"
                        formik={formik}
                        required
                      >
                        <Dropdown
                          options={GradVerificationOpts}
                          placeholder="Select Verification"
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="goodstanding"
                        label="Good Standing"
                        formik={formik}
                      >
                        <Dropdown
                          options={GoodStandingOpts}
                          placeholder="Select Standing"
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="standingverification"
                        label="Select Verification"
                        formik={formik}
                      >
                        <Dropdown
                          options={GoodStandingVerificationOpts}
                          placeholder="Select Verification"
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup label="Address of School">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="addressline1"
                        label="Address Line 1"
                        formik={formik}
                        normalizers={[maxLength(30)]}
                      >
                        <TextField placeholder="Door No.,Flat,Building,Company,Apartment" />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="addressline2"
                        label="Address Line 2"
                        formik={formik}
                        normalizers={[maxLength(30)]}
                      >
                        <TextField placeholder="Area,Street,Sector" />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="city"
                        label="City"
                        formik={formik}
                        normalizers={[maxLength(20), onlyAlphabet]}
                      >
                        <TextField placeholder="Enter City" />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field name="state" label="Select State" formik={formik}>
                        <Dropdown
                          options={StateOpts}
                          placeholder="Select State"
                        />
                      </Field>
                    </Grid>

                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        name="zipcode"
                        label="Zip Code"
                        formik={formik}
                        normalizers={[maxLength(5), number]}
                      >
                        <TextField placeholder="Enter Zip Code" />
                      </Field>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContentText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEducation;
