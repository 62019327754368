import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import ChildUnderOne from '../ChildUnderOne';
import TDAP from '../TDAP';
import TimeOnAssistance from '../TimeOnAssistance';
import AbawdProgram from '../AbawdProgram';
import usePageNav from '../../../hooks/usePageNav';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default function ProgramTabs({ selected }) {
  const { caseId } = usePageNav();
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    setCurrentIndex(0);
  }, []);
  const setTabIndex = (_, newTab) => {
    setCurrentIndex(newTab);
  };

  return (
    <Box>
      <Tabs
        onChange={setTabIndex}
        value={currentIndex}
        variant="scrollable"
        sx={{ width: '100%' }}
        centered
      >
        <Tab label="ABAWD" style={{ textTransform: 'capitalize' }}></Tab>
        <Tab
          label="Child Under One"
          style={{ textTransform: 'capitalize' }}
        ></Tab>
        <Tab label="TDAP-1" style={{ textTransform: 'capitalize' }}></Tab>
        <Tab
          label="Time On Assistance"
          style={{ textTransform: 'capitalize' }}
        ></Tab>
      </Tabs>
      <TabPanel value={currentIndex} index={0}>
        <AbawdProgram caseId={caseId} individualId={selected.individualId} />
      </TabPanel>
      <TabPanel value={currentIndex} index={1}>
        <ChildUnderOne caseId={caseId} individualId={selected.individualId} />
      </TabPanel>
      <TabPanel value={currentIndex} index={2}>
        <TDAP caseId={caseId} individualId={selected.individualId} />
      </TabPanel>
      <TabPanel value={currentIndex} index={3}>
        <TimeOnAssistance
          caseId={caseId}
          individualId={selected.individualId}
        />
      </TabPanel>
    </Box>
  );
}
