import {
  Button,
  TextField,
  IconButton,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import Dropdown from '../../../components/Dropdown';
import FormGroup from '../../../components/FormGroup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import dayjs from 'dayjs';
import React from 'react';
import { some } from 'lodash';
import { toast } from 'react-toastify';
import { maxLength } from '../../../utils/normalizers';

function AddIncarceration({
  open,
  handleClose,
  stateOpts,
  verificationOpts,
  onSaveIncarceration,
  secondaryVerificationOpts,
  addIncarceration,
  editData,
  dob,
  individualDetails,
}) {
  const validationSchema = yup.object({
    effectiveBeginDate: yup
      .string()
      .when('$currentDate', (_, schema) => {
        const currentDate = dayjs().format('YYYY-MM-DD');

        return schema.test({
          test: (effectiveDate) => {
            if (effectiveDate < currentDate) {
              return true;
            }
            return false;
          },
          message: 'Begin Date must be before current date',
        });
      })
      .required('Please enter the Begin Date'),
    effectiveEndDate: yup.string().required('Please enter Begin Date'),
    primVerification: yup
      .string()
      .required('Please select Primary Verification'),
  });

  function dateLiesBetween(
    startDate,
    endDate,
    date,
    selectRecordId,
    existingRecordId
  ) {
    if (!date) {
      return false;
    }
    if (selectRecordId === existingRecordId) {
      return false;
    }
    return dayjs(date).isBetween(startDate, endDate, 'day', '[]');
  }

  const formik = useFormik({
    initialValues: {
      effectiveBeginDate: editData?.effectiveBeginDate ?? '',
      effectiveEndDate: editData?.effectiveEndDate ?? null,
      institution: editData?.institution ?? '',
      incarcerationCity: editData?.incarcerationCity ?? '',
      incarcerationState: editData?.incarcerationState ?? '',
      incarcerationCountry: editData?.incarcerationCountry ?? '',
      recordId: editData?.recordId ?? '',
      primVerification:
        editData?.primaryVerification?.verificationSourceCode ?? '',
      secondVerification:
        editData?.secondaryVerification?.verificationSourceCode ?? '',
      primaryVerificationID:
        editData?.primaryVerification?.providedVerificationId ?? 0,
      secondaryVerificationID:
        editData?.secondaryVerification?.providedVerificationId ?? 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const isDatesAreInValid = some(
        individualDetails,
        ({ effectiveBeginDate, effectiveEndDate, recordId }) => {
          return (
            dateLiesBetween(
              effectiveBeginDate,
              effectiveEndDate,
              formik.values.effectiveBeginDate,
              formik.values.recordId,
              recordId
            ) ||
            dateLiesBetween(
              effectiveBeginDate,
              effectiveEndDate,
              formik.values.effectiveEndDate,
              formik.values.recordId,
              recordId
            )
          );
        }
      );
      if (isDatesAreInValid) {
        toast.error(
          'Dates cant be in between the Already Existing date period. Please Select Different Dates.'
        );
      } else {
        if (addIncarceration) {
          onSaveIncarceration(values, 'insert');
        } else {
          onSaveIncarceration(values, 'update');
        }
      }
    },
  });

  return (
    <Dialog
      onSubmit={formik.handleSubmit}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ fontSize: 20 }}>
        {addIncarceration ? 'Add Incarceration' : 'Edit Incarceration'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <form>
          <DialogContentText tabIndex={-1}>
            <Grid item xs={12}>
              <Grid>
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        required
                        name="effectiveBeginDate"
                        label="Begin Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker minDate={dayjs(dob)} disableFuture />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        required
                        name="effectiveEndDate"
                        label="End Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker
                          required
                          minDate={dayjs(formik.values.effectiveBeginDate)}
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup label="Incarceration">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        name="institution"
                        label="Institution"
                        formik={formik}
                        normalizers={[maxLength(30)]}
                      >
                        <TextField placeholder="Enter Institution" />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="incarcerationCity"
                        label="City"
                        formik={formik}
                        normalizers={[maxLength(30)]}
                      >
                        <TextField placeholder="Enter City" />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="incarcerationState"
                        label="State"
                        formik={formik}
                      >
                        <Dropdown
                          options={stateOpts}
                          placeholder="Select State"
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="incarcerationCountry"
                        label="Country"
                        formik={formik}
                        normalizers={[maxLength(30)]}
                      >
                        <TextField placeholder="Enter Country" />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="primVerification"
                        label="Primary Verification"
                        formik={formik}
                        required
                      >
                        <Dropdown
                          options={verificationOpts}
                          placeholder="Select Verification"
                        />
                      </Field>
                    </Grid>
                    {(formik.values.primVerification === 'OB' ||
                      formik.values.primVerification === 'OI' ||
                      formik.values.primVerification === 'SS' ||
                      formik.values.primVerification === 'CC') && (
                      <Grid item xs={6}>
                        <Field
                          name="secondVerification"
                          label="Secondary Verification"
                          formik={formik}
                        >
                          <Dropdown
                            options={secondaryVerificationOpts}
                            placeholder="Select Verification"
                          />
                        </Field>
                      </Grid>
                    )}
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContentText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Discard
        </Button>
        <Button type="submit" variant="contained" onClick={formik.handleSubmit}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddIncarceration;
