import React, { useEffect, useState } from 'react';
import TabularView from '../../../../components/TabularView';
import { Box, Typography } from '@mui/material';
import { getAuthToken } from '../../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import useLoader from '../../../../hooks/useLoader';
import { userManagementGetUsers } from '../../../../utils/services/apiRequests';
import { showErrorToast } from '../../../../utils/services/toast';
import EmptyState from '../../../../components/EmptyState';
import NoRecord from '../../../../assets/icons/No-data-amico.svg';

const UserProgram = () => {
  const token = useSelector(getAuthToken);
  const [showLoader, hideLoader] = useLoader();
  const [rowData, setRowData] = useState([]);

  const columns = [
    {
      headerName: 'Name',
      //   renderCell: (params) => finanStatus(params),
    },
    {
      headerName: 'Email ID',
      //   getValue({ participationStatus }) {
      //     return `${getLkpMatch(
      //       'FinancialResponsibility',
      //       participationStatus ?? '-'
      //     )}`;
      //   },
    },
    {
      headerName: 'Supervisor Name',
      //   getValue({ categoricallyEligible }) {
      //     return `${categoricallyEligible ?? '-'}`;
      //   },
    },
    {
      headerName: 'Programs',
      //   getValue({ specialSpecification }) {
      //     return `${specialSpecification ?? '-'}`;
      //   },
    },
    {
      headerName: 'Actions',
    },
  ];

  const getUser = async () => {
    try {
      showLoader();
      const res = await userManagementGetUsers(token?.sub);
      if (res.status === 200) {
        setRowData(res.data);
      }
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Box>
      <TabularView
        data={rowData}
        columns={columns}
        getId={(houseHoldMember) => houseHoldMember}
        slots={{
          noRowsOverlay: () => (
            <EmptyState
              header="Currently No Records Available"
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '160px',
                    width: '250px',
                  }}
                />
              }
            />
          ),
        }}
        renderHeader={(title) => (
          <Typography variant="h5" component="strong">
            {title}
          </Typography>
        )}
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '50vh',
          },
          minHeight: '50vh',
          table: {
            width: '100%',
            borderCollapse: 'collapse',
          },
          th: {
            padding: '12px 20px',
            textAlign: 'center',
            borderBottom: '2px solid #e2e2e7',
          },
          td: {
            padding: '12px 20px',
            textAlign: 'center',
          },
          tr: {
            ':nth-child(even)': {
              backgroundColor: '#f9f9f9',
            },
          },
        }}
      />
    </Box>
  );
};

export default UserProgram;
