import React, { useMemo } from 'react';
import FormSection from '../../../../components/FormSection';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import { maxLength, number } from '../../../../utils/normalizers';

export default function DMVMilitary({ getOptions, formik }) {
  const { stateOpts, statusOpts, branchOpts, recipientOpts } = useMemo(() => {
    return {
      stateOpts: getOptions('State'),
      statusOpts: getOptions('APVeteransStatus'),
      branchOpts: getOptions('VeteranBranch'),
      recipientOpts: getOptions('RecepientDropDown'),
    };
  }, [getOptions]);

  return (
    <>
      <FormSection label="DMV Information">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field
              name="drivingLicense"
              label="Driving License"
              formik={formik}
              normalizers={[maxLength(20)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="drivingState" label="State" formik={formik}>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="licensePlate"
              label="License Plate"
              formik={formik}
              normalizers={[maxLength(20)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="licenseState" label="State" formik={formik}>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Military Statistics" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Veteran Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Field name="veteranStatus" label="Status" formik={formik}>
              <Dropdown options={statusOpts} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="veteranNumber"
              label="Veteran ID Number"
              formik={formik}
              normalizers={[maxLength(30), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Service Details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <Field name="startDate" label="Start Date" formik={formik} isDate>
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="endDate" label="End Date" formik={formik} isDate>
              <DatePicker />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="branch" label="Branch" formik={formik}>
              <Dropdown options={branchOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Allotment Details" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={6} mt="1rem">
            <FormControlLabel
              name="payingAllotment"
              control={
                <Checkbox
                  {...formik.getFieldProps('payingAllotment')}
                  checked={formik.values.payingAllotment === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue(
                      'payingAllotment',
                      checked ? 'Y' : 'N'
                    );
                  }}
                  value={formik.values.payingAllotment}
                />
              }
              label={<Typography variant="h6">Paying Allotment</Typography>}
            />
          </Grid>
          <Grid item xs={6}>
            <Field name="recipient" label="Recipient" formik={formik}>
              <Dropdown options={recipientOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
}
