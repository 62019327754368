import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import ActionButtons from '../../../components/ActionButtons';
import { Box } from '@mui/material';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import { getCourtOrderInfo } from '../../../utils/services/NonCustodial/apiRequests';
import { showErrorToast } from '../../../utils/services/toast';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import dayjs from 'dayjs';

export default function CourtOrder({ caseId, absentParentId }) {
  const { houseMembers } = useSelector(selectHouseMembers);
  const [courtDataOne, setCourtDataOne] = useState([]);
  const [courtDataTwo, setCourtDataTwo] = useState([]);

  useEffect(() => {
    if (caseId) {
      getCourtInfo();
    }
  }, [caseId]);

  const getCourtInfo = async () => {
    try {
      showLoader();
      const res = await getCourtOrderInfo(caseId, absentParentId);
      if (res.status === 200) {
        const dataOne = Array.isArray(res.data[0])
          ? res.data[0]
          : [res.data[0]];
        const dataTwo = Array.isArray(res.data[1])
          ? res.data[1]
          : [res.data[1]];

        setCourtDataOne(dataOne);
        setCourtDataTwo(dataTwo);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const renderMemberName = (id) => {
    return houseMembers.find(({ individualId }) => individualId == id);
  };

  const columns = [
    {
      field: 'individualId',
      headerName: 'Child',
      width: 100,
      renderCell: (params) => {
        const individualId = params?.row?.childrenCovered?.[0]?.individualId;
        let member = renderMemberName(individualId);
        if (member && member.personName) {
          const { personName } = member;
          return personName.firstName + ' ' + personName.lastName;
        }
      },
    },
    {
      field: 'dcktNum',
      headerName: 'Docket No.',
      width: 120,
    },
    {
      field: 'effectiveBeginDt',
      headerName: 'Begin Date',
      width: 100,
    },
    {
      field: 'effectiveEndDt',
      headerName: 'End Date',
      width: 100,
    },
    {
      field: 'pymtAmt',
      headerName: 'Amount',
      width: 100,
    },
    {
      field: 'pymtFreqCd',
      headerName: 'Frequency',
      width: 100,
    },
  ];

  const courtColumns = [
    {
      field: 'lastPaidDt',
      headerName: 'Paid Date',
      width: 100,
      renderCell: (params) => {
        return params.value
          ? `${new Date(params.value).toLocaleDateString()} ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : '';
      },
      // renderCell: (params) => {
      //   const date = params?.row?.lastPaidDt;
      //   return date ? dayjs(date).format('MM-DD-YYYY') : '';
      // },
    },
    { field: 'effectiveBeginDt', headerName: 'Begin Date', flex: 1 },
    {
      field: 'pymtAmt',
      headerName: 'Payment Amount',
      width: 100,
    },
    {
      field: 'agencyName',
      headerName: 'Agency Name',
      width: 100,
    },
  ];

  return (
    <>
      <Box sx={{ marginTop: '1rem' }}>
        <DataGrid
          rows={courtDataOne}
          columns={columns}
          getRowId={(row) => row?.childrenCovered?.[0]?.recordId ?? row.dcktNum}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
          }}
          slots={{
            noRowsOverlay: 'noData',
          }}
        />
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        <DataGrid
          rows={courtDataTwo}
          columns={courtColumns}
          getRowId={(row) => row?.childrenCovered?.[1]?.recordId ?? ''}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '18px',
              fontWeight: '700',
              padding: '0px 10px',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              fontSize: '16px',
              color: 'black',
              padding: '0px 10px',
              textAlign: 'center',
            },
          }}
          slots={{
            noRowsOverlay: 'noData',
          }}
        />
      </Box>
    </>
  );
}
