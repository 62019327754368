import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../../components/Dropdown';
import FormSection from '../../../../components/FormSection';
import { DeleteOutline } from '@mui/icons-material';
import { maxLength, onlyAlphabet } from '../../../../utils/normalizers';

export default function ChildSupport({ getOptions, memberOpts, formik }) {
  const { supportOpts, realtionOpts, deprivationOpts, reasonOpts, statusOpts } =
    useMemo(() => {
      return {
        supportOpts: getOptions('ChildSupport'),
        realtionOpts: getOptions('RelationOfAbsentParentToChild'),
        deprivationOpts: getOptions('Deprivation'),
        reasonOpts: getOptions('GoodCauseReasons'),
        statusOpts: getOptions('GoodCauseStatus'),
      };
    }, [getOptions]);

  const handleAddChild = () => {
    const updateAddChild = [...formik.values.addChildSupport];
    updateAddChild.push({
      childName: '',
      childRelation: '',
      childDeprivation: '',
    });
    formik.setFieldValue('addChildSupport', updateAddChild);
  };

  const handleRemoveChild = (index) => {
    const updateAddChild = [...formik.values.addChildSupport];
    updateAddChild.splice(index, 1);
    formik.setFieldValue('addChildSupport', updateAddChild);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Field name="ivd" label="IVD" formik={formik}>
            <TextField placeholder="Child Support Id" disabled />
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field name="ivdCoop" label="IV - D Coop" formik={formik}>
            <TextField disabled placeholder="Custodian will Cooperate" />
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            name="ivdCoopDate"
            label="IV - D Coop Date"
            formik={formik}
            isDate
          >
            <DatePicker disabled />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            name="assignRights"
            control={
              <Checkbox
                {...formik.getFieldProps('assignRights')}
                checked={formik.values.assignRights === 'Y'}
                onChange={(e, checked) => {
                  formik.setFieldValue('assignRights', checked ? 'Y' : 'N');
                }}
                value={formik.values.assignRights}
              />
            }
            label={
              <Typography variant="subtitle1">IVD Assign Rights</Typography>
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="support"
            label="Have you applied for child support for this non-custodial parent?"
            formik={formik}
            required
          >
            <Dropdown options={supportOpts} />
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Field
            name="refferalDate"
            label="Refferal Date"
            formik={formik}
            isDate
          >
            <DatePicker />
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Children</Typography>
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={handleAddChild}
            >
              + Add Child
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <FormSection>
        <Grid container spacing={2}>
          <Grid item xs={2.5}>
            <Field name="cName" label="Name" formik={formik} required>
              <Dropdown options={memberOpts} />
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="cRelation"
              label="Relationship"
              formik={formik}
              required
            >
              <Dropdown options={realtionOpts} />
            </Field>
          </Grid>
          <Grid item xs={2.5}>
            <Field
              name="cDeprivation"
              label="Deprivation"
              formik={formik}
              required
            >
              <Dropdown options={deprivationOpts} />
            </Field>
          </Grid>
          <Grid item xs={1} mt="1.5rem" ml="1rem">
            <div
              // onClick={() => handleRemoveChild(index)}
              style={{ cursor: 'pointer' }}
            >
              <DeleteOutline />
            </div>
          </Grid>
        </Grid>
      </FormSection>
      {(formik.values.addChildSupport || []).map((child, index) => (
        <Box mx={2} key={index}>
          <FormSection mt="1rem">
            <Grid container spacing={2}>
              <Grid item xs={2.5}>
                <Field
                  name={`addChildSupport[${index}].childName`}
                  label="Name"
                  formik={formik}
                  required
                >
                  <Dropdown options={memberOpts} />
                </Field>
              </Grid>
              <Grid item xs={5}>
                <Field
                  name={`addChildSupport[${index}].childRelationName`}
                  label="Relationship"
                  formik={formik}
                >
                  <Dropdown options={realtionOpts} />
                </Field>
              </Grid>
              <Grid item xs={2.5}>
                <Field
                  name={`addChildSupport[${index}].childDeprivation`}
                  label="Deprivation"
                  formik={formik}
                  required
                >
                  <Dropdown options={deprivationOpts} />
                </Field>
              </Grid>
              <Grid item xs={1} mt="1.5rem" ml="1rem">
                <div
                  onClick={() => handleRemoveChild(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <DeleteOutline />
                </div>
              </Grid>
            </Grid>
          </FormSection>
        </Box>
      ))}
      <FormSection label="Good Cause Claim" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={3.5}>
            <Field name="reason" label="Reason" formik={formik}>
              <Dropdown options={reasonOpts} disabled />
            </Field>
          </Grid>
          <Grid item xs={3.5}>
            <Field name="reasonStatus" label="Status" formik={formik}>
              <Dropdown options={statusOpts} disabled />
            </Field>
          </Grid>
          <Grid item xs={3.5}>
            <Field
              name="effectiveDate"
              label="Effective Date"
              formik={formik}
              isDate
            >
              <DatePicker disabled />
            </Field>
          </Grid>
          <Grid item xs={1} mt="1.5rem" ml="1rem">
            <div
              // onClick={() => handleRemoveIncome(index)}
              style={{ cursor: 'pointer' }}
            >
              <DeleteOutline />
            </div>
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
}
