import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../components/Dropdown';
import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from '../../../components/ActionButtons';
import AddVehicle from './AddVehicle';
import { CloseRounded } from '@mui/icons-material';
import { filter } from 'lodash';
import DocumentUploadFilesDialog from '../../Income/DocumentUploadFilesDialog';
import { getDocumentsFiles } from '../../../utils/services/apiRequests';
import { useParams } from 'react-router-dom';
import { alphaNumeric, maxLength, number } from '../../../utils/normalizers';

export default function MotorVehicle({
  editData,
  insertAPI,
  getOptions,
  createNew,
  editApi,
  toggleClose,
  lkpRaw,
  selected,
}) {
  const [policy, setPolicy] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [editRecord, setEditRecord] = useState('');
  const [docOpen, setDocOpen] = useState(false);
  const [docmentFiles, setDocmentFiles] = useState([]);
  const [isAdd, setIsAdd] = useState(null);
  const validationSchema = Yup.object({
    type: Yup.string().required('Please select type'),
    vehicle: Yup.string().required('Please select vehicle use'),
    year: Yup.string().required('Please enter the year'),
    make: Yup.string().required('Please enter the make'),
    model: Yup.string().required('Please enter the model'),
    vin: Yup.string().required('Please enter the vin'),
    state: Yup.string().required('Please enter the state'),
  });

  const params = useParams();

  useEffect(() => {
    if (policyData.length === 0 && editData) {
      setPolicyData((prev) => [...prev, ...editData?.assetAmounts]);
    }
  }, []);

  const { vehicleUseOpts, vehicleTypeOpts, stateOpts, amoutOpts, fairOpts } =
    useMemo(() => {
      return {
        vehicleUseOpts: getOptions('VehicleUse'),
        vehicleTypeOpts: getOptions('VehicleType'),
        stateOpts: getOptions('State'),
        amoutOpts: getOptions('CLVEHENCUMBVERCD'),
        fairOpts: getOptions('CLVEHFMVAMTVERCD'),
      };
    }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      type: editData?.assetDetailType ?? '',
      vehicle: editData?.vehicalUse ?? '',
      year: editData?.year ?? '',
      make: editData?.make ?? '',
      model: editData?.model ?? '',
      license: editData?.licenseNumber ?? '',
      vin: editData?.vin ?? '',
      registration: editData?.registration ?? '',
      state: editData?.state ?? '',
      assetType: 3,
      recordId: editData?.recordId ?? '',
      jointOwnerYN: editData?.jointOwnerYN ?? 'N',
    },
    validationSchema,
    onSubmit: (value) => {
      if (createNew) {
        insertAPI({ value, policyData });
      } else {
        editApi({ value, policyData });
      }
    },
  });
  const AddInstitutionDetails = () => {
    setPolicy(true);
  };
  const toggleClosePop = () => {
    setPolicy(false);
  };
  const motorColumns = useMemo(
    () => [
      {
        field: 'effectiveBeginDate',
        headerName: 'Start Date',
        flex: 1,
      },
      {
        field: 'effectiveEndDate',
        headerName: 'End Date',
        flex: 1,
      },

      {
        field: 'encumberedAmount',
        headerName: 'Encumbered Amount',
        flex: 1,
        renderCell: (params) => {
          return <Typography>${params.row.encumberedAmount}</Typography>;
        },
      },
      {
        field: 'fareMarketValue',
        headerName: 'Fair Market Value',
        flex: 1,
        renderCell: (params) => {
          return <Typography>${params.row.fareMarketValue}</Typography>;
        },
      },

      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <div>
              <ActionButtons
                onEdit={() => editRec(params?.row)}
                onDelete={() => deleteRecord(params?.row)}
              />
            </div>
          );
        },
      },
    ],
    [policyData]
  );

  const deleteRecord = (data) => {
    const newRecord = filter(
      policyData,
      (val) => val.recordId !== data.recordId
    );
    setPolicyData(newRecord);
  };
  const editRec = (data) => {
    setEditRecord(data);
    setPolicy(true);
  };

  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(params.caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  useEffect(() => {
    getUploadFiles();
  }, []);

  return (
    <div>
      <Dialog
        open={policy}
        onClose={toggleClosePop}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={'center'}
          >
            <Typography variant="h3">Motor Details</Typography>
            <IconButton onClick={toggleClosePop}>
              <CloseRounded />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <AddVehicle
            getOptions={getOptions}
            amoutOpts={amoutOpts}
            fairOpts={fairOpts}
            toggleClosePop={toggleClosePop}
            setPolicyData={setPolicyData}
            policyData={policyData}
            editRecord={editRecord}
          />
        </DialogContent>
      </Dialog>
      <FormSection label={'Account Details'}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Field name="type" formik={formik} label="Type" required>
              <Dropdown options={vehicleTypeOpts} placeholder="Select Type" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="vehicle" formik={formik} label="Vehicle Use" required>
              <Dropdown
                options={vehicleUseOpts}
                placeholder="Select vehicle use"
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="year"
              formik={formik}
              label="Year"
              required
              normalizers={[number, maxLength(4)]}
            >
              <TextField placeholder="Enter the Year" type="number" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="make"
              formik={formik}
              label="Make"
              required
              normalizers={[alphaNumeric, maxLength(17)]}
            >
              <TextField placeholder="Enter the Make" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="model"
              formik={formik}
              label="Model"
              required
              normalizers={[alphaNumeric, maxLength(17)]}
            >
              <TextField placeholder="Enter the Model" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="license"
              formik={formik}
              label="License Number"
              normalizers={[alphaNumeric, maxLength(10)]}
            >
              <TextField placeholder="Enter the License Number" type="number" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="vin"
              formik={formik}
              label="VIN"
              required
              normalizers={[alphaNumeric, maxLength(17)]}
            >
              <TextField placeholder="Enter the VIN" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="registration"
              formik={formik}
              label="Registration"
              normalizers={[alphaNumeric, maxLength(12)]}
            >
              <TextField
                placeholder="Enter the Registration"
                inputProps={{ maxLength: 11 }}
              />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="state" formik={formik} label="State" required>
              <Dropdown options={stateOpts} placeholder="Select Verification" />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              sx={{ pt: 2.7 }}
              control={
                <Checkbox
                  {...formik.getFieldProps('jointOwnerYN')}
                  checked={formik.values.jointOwnerYN === 'Y'}
                  onChange={(e, checked) => {
                    formik.setFieldValue('jointOwnerYN', checked ? 'Y' : 'N');
                  }}
                />
              }
              label="Jointly Owner"
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={'row'}
              mb={2}
              justifyContent={'space-between'}
              mt={2}
            >
              <Button
                variant="outlined"
                startIcon={<UploadFileIcon />}
                onClick={handleOpenDocModal}
              >
                Upload Files
              </Button>
              <Button onClick={AddInstitutionDetails} startIcon={<AddIcon />}>
                Add Asset
              </Button>
            </Stack>
            <div style={{ width: '100%' }}>
              <DataGrid
                getRowId={(rows) => rows.recordId}
                rows={policyData ?? []}
                columns={motorColumns}
                autoHeight
                hideFooter
              />
            </div>
          </Grid>
        </Grid>
        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} mt={2}>
          <Button variant="outlined" onClick={() => toggleClose()}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={policyData.length === 0}
          >
            Save
          </Button>
        </Stack>
      </FormSection>
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={params.caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
}
