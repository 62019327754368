export const GET_ELIGIBILITY = '/eeapi/application-process/elg-prm-desc';

export const POST_ELIGIBILITY =
  '/eeapi/application-process/eligibility-parameter';

export const GET_ELIGIBILITY_POST = '/eeapi/application-process/elg-prm-histry';

export const POST_LDSSCODE = (uniqueIdToken) =>
  `/eeapi/application-process/saveDSNAP/${uniqueIdToken}`;

export const GET_LDSSCODE = (uniqueIdToken) =>
  `/eeapi/application-process/getDSNAPRecord/${uniqueIdToken}`;

export const GET_LDSS_OPTION =
  '/eeapi/application-process/screening/DOWorkerInfo';
