import { get } from 'lodash';
import ActionButton from '../../../components/ActionButtons';
import IndividualDetailsTable from '../IndividualDetailsTable';
import React, { useState } from 'react';
import useLookup from '../../../hooks/useLookup';
import AddStriker from './AddStriker';
import { postStriker } from '../../../utils/services/individualDetailsServices/requests';
import { useParams } from 'react-router-dom';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import DeleteModal from '../DeleteModal';
import useLoader from '../../../hooks/useLoader';

function StrikerStatus({
  member,
  individualId,
  fetchIndividual,
  dob,
  open,
  handleClose,
}) {
  const [openDeleteModal, setOpenDeletModal] = useState(false);
  const [editData, setEditData] = useState('');
  const [deleteData, setDeleteData] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const { getLkpMatch, lkpRaw } = useLookup('LivingArrangement');
  const strikers = get(member, 'individualDetails.strikers', []);
  const [showLoader, hideLoader] = useLoader();
  const params = useParams();
  const getRowId = (row) => {
    return row.recordId;
  };

  const handleDelete = (params) => {
    setOpenDeletModal(true);
    setDeleteData(params.row);
  };

  const handleEdit = (params) => {
    setEditData(params.row);
    setOpenEditModal(true);
  };

  const columns = [
    {
      field: 'effectiveBeginDate',
      headerName: 'Begin Date',
      width: 140,
      editable: false,
    },
    {
      field: 'effectiveEndDate',
      headerName: 'End Date',
      width: 130,
      editable: false,
    },
    {
      field: 'strikerStatus',
      headerName: 'Striker Status',
      renderCell: (params) => getLkpMatch('YesNo', params?.row?.strikerStatus),
      width: 160,
      editable: false,
    },
    {
      field: 'employerName',
      headerName: 'Employer Name',
      width: 190,
      editable: false,
    },
    {
      headerName: 'Action',
      field: 'action',
      width: 100,
      renderCell(params) {
        return (
          <span>
            <ActionButton
              onDelete={() => handleDelete(params)}
              onEdit={() => handleEdit(params)}
            />
          </span>
        );
      },
      headerName: 'Action',
    },
  ];
  const onSaveStriker = async (data, dirtyStatus) => {
    try {
      showLoader();
      const res = await postStriker(
        data,
        params.caseId,
        individualId,
        dirtyStatus
      );

      if (res.status === 200) {
        if (dirtyStatus === 'insert') {
          showSuccessToast('Successfully Added New Striker Status');
        } else if (dirtyStatus === 'update') {
          showSuccessToast('Successfully Updated The Striker Status');
        } else {
          showSuccessToast('Successfully Deleted The Striker Status');
        }
        fetchIndividual();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
      handleClose();
    }
  };

  return (
    <div>
      <IndividualDetailsTable
        rows={strikers}
        columns={columns}
        getRowId={getRowId}
      />
      <AddStriker
        dob={dob}
        addStriker
        open={open}
        handleClose={handleClose}
        onSaveStriker={onSaveStriker}
      />
      <AddStriker
        dob={dob}
        editStriker
        editData={editData}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        onSaveStriker={onSaveStriker}
      />
      <DeleteModal
        open={openDeleteModal}
        close={() => setOpenDeletModal(false)}
        deleteData={deleteData}
        fn={onSaveStriker}
      />
    </div>
  );
}

export default StrikerStatus;
