import { all, takeEvery, put } from 'redux-saga/effects';
import HttpClient, { authApi } from '../../utils/HttpClient';
import { showErrorToast } from '../../utils/services/toast';
import navEnqueryUrls from './navUrls.json';
import navRegisterUrls from './navUrlRegister.json'
import navDashboardUrls from './navUrlDashboard.json'
import { groupBy, map, partition, sortBy, values, first } from 'lodash';
import { setNavLinks } from '../slices/navigationSlice';
import { FETCH_NAV_OPTIONS } from '../types/navTypes';
import { fetchNavReq } from '../../utils/services/navigations/apiRequests';

const navUrlsObj = {
  D: navDashboardUrls,
  R: navRegisterUrls,
  I: navEnqueryUrls,
}

const getNavLinks = (navUrls) => {
  if (navUrls.length) {
    const navGroups = groupBy(navUrls, (url) => url.prnt_desc);
    const navs = values(navGroups).map((navArray) => {
      const [parentNav, subNavs] = partition(navArray, (nav) => nav.is_parent);
      const sortedSubNavs = sortBy(subNavs, (nav) => nav.sort_order_nbr);
      const sortedSubNavsWithUrls = map(sortedSubNavs, (nav) => ({
        ...nav,
        nav_url: '',
        nav_icon: 'CaseDocuments',
      }));

      return {
        ...first(parentNav),
        nav_url: '',
        nav_icon: 'CaseDocuments',
        subNavs: sortedSubNavsWithUrls,
      };
    });
    return sortBy(navs, (nav) => nav.sort_order_nbr);
  }
  return [];
};

export function* fetchNavOptions(action) {
  try {
    const { caseId, pageType = "R" } = action.payload;
    const urls = navUrlsObj[pageType];
    yield put(setNavLinks(getNavLinks(urls)));
    // const res = fetchNavReq()
    // if (res?.status === 200) {
    //   yield put(setAuthToken('token'));
    // }
  } catch (error) {
    showErrorToast('Invalid username or password');
  }
}

export default function* watchNavigations() {
  yield all([takeEvery(FETCH_NAV_OPTIONS, fetchNavOptions)]);
}
