import React, { useMemo } from 'react';
import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import IndividualDetails from './IndividualDetails';

export default function IndividualAssitance() {
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { houseHoldMembers, hasHoh } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  }, [houseMembers]);
  return (
    <PageContainer title="Individual Assistance">
      <SelectableView
        data={houseHoldMembers}
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <IndividualDetails />
      </SelectableView>
    </PageContainer>
  );
}
