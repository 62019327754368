import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import useLookup from '../../hooks/useLookup';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import { useSelector } from 'react-redux';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import React, { useMemo } from 'react';
import usePageNav from '../../hooks/usePageNav';
import DomVioDetail from './DomesticViolence/DomesticViolence';
import usePage from '../../hooks/usePage';

function DomesticViolence({ id }) {
  const { navProps } = usePage(id);
  const { lkpFlatten } = useLookup('LivingArrangement');
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const { caseId, navigations } = usePageNav();
  const { houseHoldMembers } = useMemo(() => {
    return {
      houseHoldMembers: houseMembers,
    };
  }, [houseMembers]);

  const onNext = () => {
    navigations.toHealthDisabilityForm();
  };

  const onBack = () => {
    navigations.toEducationDetails();
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Domestic Violence"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        showEdit={false}
        showDelete={false}
      >
        <DomVioDetail />
      </SelectableView>
    </PageContainer>
  );
}

export default DomesticViolence;
