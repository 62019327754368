export const eligibilityPayload = (
  data,
  selectedItem,
  getEligbleId,
  id,
  selectedDetermFactor
) => {
  const { paramValue, createdBeginDt, createdEndDt } = data;
  console.log('dsds', getEligbleId);
  return {
    elgbltyParmId: getEligbleId?.elgbltyParmId
      ? getEligbleId?.elgbltyParmId
      : '',
    elgbltyParmDesc: selectedItem?.eligParamDesc ?? null,
    benfGrp: selectedItem?.benfGrp ?? '',
    determFactor: selectedDetermFactor ?? '',
    parmValue: paramValue ?? null,
    activeInd: 'Y',
    effectiveBeginDt: createdBeginDt ?? null,
    effectiveEndDt: createdEndDt ?? null,
    longDesc: selectedItem?.longDesc ?? null,
    pgmUiCd: id,
  };
};

export const lDSSCodePayload = (data, editData) => {
  const { createdBeginDt, createdEndDt, type, status, LDSScode } = data;

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  return {
    disasterId: editData?.disasterId ?? 0,
    dirtyStatus: editData && !isEmptyObject(editData) ? 'Update' : 'Insert',
    effectiveBeginDate: createdBeginDt,
    effectiveEndDate: createdEndDt,
    disasterName: type,
    ldssCd: LDSScode,
    disasterStatus: status,
  };
};
