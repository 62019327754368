import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import FormSection from '../../components/FormSection';
import EmptyState from '../../components/EmptyState';
import { Box } from '@mui/material';
import NoRecord from '../../assets/icons/No-data-amico.svg';

const SearchResult = ({ rows, columns }) => {
  return (
    <FormSection label="Search Results">
      <Box sx={{ width: '99%', display: 'grid' }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          sx={{
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '50vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '50vh',
            },
            minHeight: '50vh',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '15px',
              fontWeight: '600',
            },
          }}
          loading={false}
          rowsPerPageOptions={[5, 10, 15]}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          disableColumnMenu={true}
        />
      </Box>
    </FormSection>
  );
};

export default SearchResult;
