import { Button, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import React from 'react';
import Field from '../../../../components/Field';
import FormSection from '../../../../components/FormSection';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { map } from 'lodash';
import { formatCurrency } from '../../../../utils/normalizers';

export default function AddLiquid({
  policyData,
  setPolicyData,
  toggleClosePop,
  editRecord,
}) {
  const validationSchema = Yup.object({
    effectiveBeginDate: Yup.string().required('Please select start date'),
    // effectiveEndDate: Yup.string().required('Please select end date'),
    amount: Yup.string().required('Please enter cash amount'),
  });

  const formik = useFormik({
    initialValues: {
      effectiveBeginDate: editRecord?.effectiveBeginDate ?? null,
      effectiveEndDate: editRecord?.effectiveEndDate ?? null,
      amount: editRecord?.amount ?? '',
      recordId: editRecord?.recordId ?? `a${Math.random()}`,
    },
    validationSchema,
    onSubmit: (value) => {
      if (editRecord?.effectiveBeginDate) {
        setPolicyData(
          map(policyData, (val) =>
            val.recordId === editRecord?.recordId ? value : val
          )
        );
        toggleClosePop();
      } else {
        setPolicyData((prev) => [...prev, value]);
        toggleClosePop();
      }
    },
  });

  return (
    <div>
      <FormSection>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Field
              name="effectiveBeginDate"
              required
              isDate
              formik={formik}
              label={'Account Start Date'}
            >
              <DatePicker />
            </Field>
          </Grid>
          <Grid item>
            <Field
              name="effectiveEndDate"
              isDate
              formik={formik}
              label={'Account Close Date'}
              // required
            >
              <DatePicker />
            </Field>
          </Grid>

          <Grid item>
            <Field
              name="amount"
              formik={formik}
              label="Cash Amount"
              required
              normalizers={[formatCurrency]}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <Stack justifyContent={'center'} mt={1}>
        <Button onClick={formik.handleSubmit} variant="outlined">
          Add Asset
        </Button>
      </Stack>
    </div>
  );
}
