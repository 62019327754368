import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';

function DateInfo({ time, date, month, year }) {
  return (
    <div className={styles.dateContainer}>
      <div className={styles.today}>{time}</div>
      <div className={styles.dateWrapper}>
        <span className={styles.todayDate}>{date}</span>
        <span className={styles.monthYear}>{`${month} ${year}`}</span>
      </div>
    </div>
  );
}

DateInfo.propTypes = {};

export default DateInfo;
