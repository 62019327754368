import {
  Button,
  Grid,
  Paper,
  TextField,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import { useFormik } from 'formik';
import Field from '../../../components/Field';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';
import usePageNav from '../../../hooks/usePageNav';
import {
  getNarrative,
  postCaseNarrative,
  postCaseSearch,
} from '../../../utils/services/AllCaseComments/apiRequests';
import AddCase from '../AddCase';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import CaseCommentsTable from '../CaseCommentsData';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import { getFullName } from '../../../utils/helpers';
import { find, map } from 'lodash';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

function InitiateCase() {
  const [showLoader, hideLoader] = useLoader();
  // const [narrative, setNarrative] = useState([]);
  const [narrative, setNarrative] = useState(null);
  const { caseId, navigations } = usePageNav();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editCase, setEditCase] = useState('');
  const [narrtiveId, setNarrativeId] = useState(null);

  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const hohMember = find(houseMembers, { headOfHouseholdYN: 'Y' });
  const memberOpts = useMemo(() => {
    const options = map(houseMembers, ({ personName, householdMemberId }) => {
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      return {
        label: fullName,
        // value: { householdMemberId, fullName },
        value: householdMemberId,
      };
    });
    return [...options, { label: 'All', value: hohMember?.individualId }];
  }, [houseMembers]);

  const handleSearch = () => {
    setShowSearch(!showSearch);
  };
  const toggleComments = () => {
    setOpen(false);
    setEditOpen(false);
  };
  const editCaseData = (data) => {
    setEditOpen(true);
    setEditCase(data);
    setNarrativeId(data?.narrativeId);
  };

  const formik = useFormik({
    initialValues: {
      caseId: caseId,
      householdMemberId: '',
      fromDate: '',
      toDate: '',
      keyWord: '',
    },
    onSubmit: (values) => {
      postSearch(values);
    },
  });
  // const goFunction = () => {
  //   getCaseNarrative();
  // };
  const columns = [
    {
      field: 'createdDate',
      headerName: 'Created Date',
      width: 180,
      renderCell: (params) => {
        return params.value
          ? `${new Date(params.value).toLocaleDateString()} ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : '';
      },
    },
    {
      field: 'updatedDate',
      headerName: 'Updated Date',
      width: 180,
      renderCell: (params) => {
        return params.value
          ? `${new Date(params.value).toLocaleDateString()} ${new Date(
              params.value
            ).toLocaleTimeString()}`
          : '';
      },
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      width: 120,
      renderCell: (params) => {
        return params.value ? `${params.value.firstName}` : '';
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 150,
    },
    {
      field: 'householdMemberName',
      headerName: 'Household Member',
      width: 180,

      renderCell: (params) => {
        return params?.row?.householdMemberName
          ? `${params?.row?.householdMemberName?.firstName} ${params?.row?.householdMemberName?.lastName}`
          : 'All';
      },
    },

    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => editCaseData(params?.row)}>
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
  ];
  // const getCase = (_caseId) => {
  //   if (!caseId) {
  //     navigations.toAllCaseComments(_caseId);
  //   }
  // };
  useEffect(() => {
    if (caseId) {
      getCaseNarrative();
    }
  }, [caseId]);

  const disableFields = useMemo(() => {
    const createdDate = new Date(editCase.createdDate);
    const currentDate = new Date();
    const difference = dayjs(createdDate).add(48, 'h');
    const isdisable = dayjs(currentDate).isAfter(difference);
    return isdisable;
  }, [editCase.createdDate]);

  const getCaseNarrative = async () => {
    try {
      showLoader();
      let value = caseId ? caseId : formik.values.caseId;
      const res = await getNarrative(value);
      if (res.status === 200) {
        setNarrative(res.data);
        // getCase(value);
      } else {
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const postSearch = async (data) => {
    try {
      setLoading(true);
      let value = caseId ? caseId : formik.values.caseId;
      const res = await postCaseSearch(data, caseId);
      if (res.status === 200) {
        setNarrative(res.data?.narratives);
        // getCase(value);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const postNarrative = async (data) => {
    try {
      setLoading(true);
      const res = await postCaseNarrative(data, caseId);
      if (res.status === 200) {
        showSuccessToast('Successfully Added Case Comments');
        setNarrative(res.data);
        toggleComments();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setEditOpen(false);
  };
  return (
    <Paper>
      {/* <FormSection label="INITIATE CASE COMMENTS"> */}
      {/* <Grid
        sx={{
          paddingBottom: '10px',
          paddingTop: '15px',
        }}
      >
        <Stack direction={'row'} justifyContent="space-between">
          <Typography
            variant="h4"
            sx={{
              marginLeft: '22px',
            }}
          >
            INITIATE CASE COMMENTS
          </Typography>
          <Button onClick={() => setOpen(true)}>+ Add Case Comments</Button>
        </Stack>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          marginLeft: '5px',
          paddingBottom: '10px',
        }}
      >
        <Grid item xs={4}>
          <Field label="Case ID" formik={formik} name="caseId">
            <TextField placeholder="Enter Case Id" defaultValue={caseId} />
          </Field>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            sx={{
              mt: '1.5rem',
            }}
            type="submit"
            onClick={goFunction}
          >
            Go
          </Button>
        </Grid>
      </Grid> */}

      {narrative && narrative.length > 0 && (
        <div
          style={{
            padding: '1rem',
          }}
        >
          <Stack
            direction={'row'}
            ml="0.5rem"
            mb="0.5rem"
            justifyContent="space-between"
          >
            <Typography variant="h4">Case Comments History</Typography>
            {/* <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Search
              </Button> */}
            <Button onClick={() => setOpen(true)}>+ Add Case Comments</Button>
          </Stack>
          {/* {showSearch && ( */}
          {/* <FormSection
              label="Search Form"
              mt="0.5rem"
              ml="0.5rem"
              mb="0.5rem"
              mr="0.5rem"
            >
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Field
                    label="Member"
                    formik={formik}
                    name="householdMemberId"
                    required
                  >
                    <Dropdown
                      options={memberOpts}
                      placeholder="Select Member"
                    />
                  </Field>
                </Grid>

                <Grid item xs={3}>
                  <Field
                    label="From Date"
                    isDate
                    formik={formik}
                    name="fromDate"
                  >
                    <DatePicker disableFuture placeholder="Select Date" />
                  </Field>
                </Grid>
                <Grid item xs={3}>
                  <Field label="To Date" isDate formik={formik} name="toDate">
                    <DatePicker placeholder="Select Date" />
                  </Field>
                </Grid>
                <Grid item xs={1.5}>
                  <Field label="Key Word" formik={formik} name="keyWord">
                    <TextField placeholder="Key Word" />
                  </Field>
                </Grid>
                <Grid item xs={1.5} mt={3}>
                  <Button
                    onClick={formik.handleSubmit}
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </FormSection> */}
          {/* )} */}

          <CaseCommentsTable
            rows={narrative ?? []}
            columns={columns}
            getRowId={(row) => {
              return row.narrativeId;
            }}
          />

          <Stack
            direction={'row'}
            ml="0.5rem"
            mt="0.5rem"
            mb="0.5rem"
            mr="0.5rem"
            justifyContent="space-between"
          >
            <Button variant="contained">Print View</Button>
            <Button variant="contained">Next</Button>
          </Stack>
        </div>
      )}

      {narrative && narrative.length === 0 && (
        <>
          <Stack direction={'row'} ml="0.5rem" mb="0.5rem" justifyContent="end">
            <Button size="medium" onClick={() => setOpen(true)}>
              + Add Case Comments
            </Button>
          </Stack>
          <Grid
            sx={{
              ml: '1rem',
              mt: '1rem',
              mb: '1.5rem',
              padding: '3rem',
            }}
          >
            {/* <Button onClick={() => setOpen(true)}>+ Add Case Comments</Button> */}
            <EmptyState
              icon={
                <img
                  src={NoRecord}
                  style={{
                    height: '110px',
                    width: '120px',
                  }}
                />
              }
              description="Please click Add Case Comments"
              header="No History of Comments"
            />
          </Grid>
        </>
      )}

      <Dialog
        open={open}
        onClose={toggleComments}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle>Add Comments</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={toggleComments}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <AddCase
            toggleComments={toggleComments}
            postNarrative={postNarrative}
            createCase
            disableFields={false}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={editOpen}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogTitle>Add Comments</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleComments}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <AddCase
            toggleComments={toggleComments}
            handleClose={handleClose}
            editCase={editCase}
            postNarrative={postNarrative}
            disableFields={disableFields}
            narrativeId={narrtiveId}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
}

export default InitiateCase;
