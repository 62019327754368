import React, { useState } from 'react';
import styles from './index.module.css';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import IconLink from '../../../components/IconLinks';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { ReactComponent as ViewTickets } from '../../../assets/icons/QuickLinks/confirmation_number.svg';
import { ReactComponent as description } from '../../../assets/icons/QuickLinks/description.svg';
import { ReactComponent as grade } from '../../../assets/icons/QuickLinks/grade.svg';
import { ReactComponent as addNote } from '../../../assets/icons/QuickLinks/note_add.svg';
import { ReactComponent as activeNotifi } from '../../../assets/icons/QuickLinks/notifications_active.svg';
import { ReactComponent as workUpdate } from '../../../assets/icons/QuickLinks/work_update.svg';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import CreateWorkItem from '../../CreateWorkItem';
export default function QuickLink() {
  const iconValue = [
    { IconsSvg: addNote, label: 'Create Alert' },
    { IconsSvg: workUpdate, label: 'Get  Work Item' },
    { IconsSvg: activeNotifi, label: 'View  Alerts', url: 'alerts' },
    { IconsSvg: description, label: 'View   Cases', url: 'viewallcase' },
    { IconsSvg: ViewTickets, label: 'View  Tickets', url: 'viewtickets' },
    { IconsSvg: grade, label: 'View  Favourite' },
  ];
  const [caseWorkItemOpen, setCaseWorkItemOpen] = useState(false);

  const handleClickOpenWorkItem = () => {
    setCaseWorkItemOpen(true);
  };

  const handleClick = (value) => {
    if (value === 'Create Alert') {
      handleClickOpenWorkItem();
    }
  };

  const handleCloseWorkItem = () => {
    setCaseWorkItemOpen(false);
  };

  return (
    <Box>
      <Grid p={1}>
        <Typography variant="h3">Quick Links</Typography>
      </Grid>
      <Divider />
      <Grid container spacing={2} padding={3}>
        {map(iconValue, ({ IconsSvg, label, url = '' }) => (
          <Grid item container justifyContent={'center'} sm={4}>
            <Link
              className={styles.link}
              to={url}
              onClick={() => handleClick(label)}
            >
              <IconLink icon={IconsSvg} iconlabel={label} />
            </Link>
          </Grid>
        ))}
      </Grid>
      {caseWorkItemOpen && (
        <CreateWorkItem
          caseWorkItemOpen={caseWorkItemOpen}
          handleCloseWorkItem={handleCloseWorkItem}
          handleClickOpenWorkItem={handleClickOpenWorkItem}
        />
      )}
    </Box>
  );
}
