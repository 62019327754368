import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  saveHealthDisabilityPayload,
  saveWbcchpDetailsPayload,
  createBenefit,
  editBenefits,
} from './apiPayloads';
import {
  CREATE_BENEFITS,
  GET_HEALTH,
  POST_HEALTH,
  POST_WBCCHP,
  SOCIAL_SECURITY_DB,
} from './apiUrls';

export const getHealthDisabilityList = (caseId, individualId) =>
  HttpClient.get(format(GET_HEALTH, caseId, individualId));

export const saveHealthDisability = (
  caseId,
  individualId,
  values,
  deleteStatus
) => {
  return HttpClient.post(
    format(POST_HEALTH, caseId, individualId),
    saveHealthDisabilityPayload(values, deleteStatus)
  );
};

export const saveWbcchpDetails = (
  caseId,
  individualId,
  values,
  deleteStatus
) => {
  return HttpClient.post(
    format(POST_WBCCHP, caseId, individualId),
    saveWbcchpDetailsPayload(values, deleteStatus)
  );
};
export const getSocialSecurityDM = (caseId, memberId) => {
  return HttpClient.get(format(SOCIAL_SECURITY_DB, caseId, memberId));
};

export const craeteBenefits = (caseId, memberId, data, action) => {
  return HttpClient.post(
    format(CREATE_BENEFITS, caseId, memberId),
    createBenefit(data, action)
  );
};

export const deleteBenefits = (caseId, memberId, data) => {
  return HttpClient.post(format(CREATE_BENEFITS, caseId, memberId), data);
};

export const editBnfits = (caseId, memberId, data) => {
  return HttpClient.post(
    format(CREATE_BENEFITS, caseId, memberId),
    editBenefits(data)
  );
};
