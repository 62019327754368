import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TabularView from '../../../components/TabularView';
import SummaryContent from '../SummaryContent';
import { isEmpty } from 'lodash';

function ContactInfo({ contactInfo, getLkpMatch }) {
  console.log(contactInfo);

  const { residentialAddress, phoneNumber, altPhoneNumber, mailingAddress } =
    useMemo(() => {
      const {
        homeAddress = {},
        sameMailingAddressYN,
        mailingAddress = {},
        phoneNumber,
        altPhoneNumber,
      } = contactInfo;
      const {
        city = '',
        addressLine1 = '',
        state = '',
        zipcode = '',
      } = homeAddress;

      const {
        city: mailcity = '',
        addressLine1: mailaddressLine1,
        state: mailstate = '',
        zipcode: mailzipcode = '',
      } = mailingAddress;

      return {
        residentialAddress: `${addressLine1} ${city} ${getLkpMatch(
          'State',
          state
        )} ${zipcode}`,
        phoneNumber: phoneNumber?.phoneNumber ?? '-',
        altPhoneNumber: altPhoneNumber?.phoneNumber ?? '-',
        mailingAddress: isEmpty(contactInfo)
          ? '-'
          : sameMailingAddressYN === 'Y'
          ? // ? 'Same as residential address'
            `${mailaddressLine1} ${mailcity} ${getLkpMatch(
              'State',
              mailstate
            )} ${mailzipcode}`
          : `${mailaddressLine1}
          ${mailcity}
        ${getLkpMatch('State', mailstate)}
        ${mailzipcode}`,
      };
    }, []);

  const columns = useMemo(
    () => [
      {
        field: '',
        headerName: 'Residential Address',
        value: residentialAddress,
      },
      {
        field: '',
        headerName: 'Mailing Address',
        value: mailingAddress,
      },
      {
        field: 'email',
        headerName: 'Email',
      },
      {
        field: '',
        headerName: 'Contact No.',
        value: phoneNumber,
      },
      {
        field: '',
        headerName: 'Alt. Contact No.',
        value: altPhoneNumber,
      },
      {
        field: 'updatedBy',
        headerName: 'Updated By',
      },
      {
        field: 'updatedDate',
        headerName: 'Update Date',
      },
    ],
    [altPhoneNumber, mailingAddress, phoneNumber, residentialAddress]
  );

  return (
    <SummaryContent title="Contact Information">
      <TabularView
        sx={{
          table: {
            width: 'auto',
          },
          td: {
            border: 0,
            padding: '0 1.25rem 0.5rem 0',
          },
        }}
        headerAxis="y"
        columns={columns}
        data={contactInfo}
        headerComponent={{ variant: 'h6', color: 'var(--grey-400)' }}
        cellComponent={{ variant: 'body2', color: 'var(--grey-500)' }}
      />
    </SummaryContent>
  );
}

ContactInfo.propTypes = {};

export default ContactInfo;
