import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, ButtonGroup } from '@mui/material';
import { noop } from 'lodash';

function SwActionButton({
  editable,
  deletable,
  disableEdit,
  disableDelete,
  onEdit,
  onDelete,
}) {
  return (
    <ButtonGroup variant="outlined">
      {editable && (
        <Button
          onClick={onEdit}
          endIcon={<EditIcon />}
          disabled={disableEdit}
          size="small"
        >
          Edit
        </Button>
      )}
      {deletable && (
        <Button
          onClick={onDelete}
          endIcon={<DeleteOutlineIcon />}
          disabled={disableDelete}
          size="small"
        >
          Delete
        </Button>
      )}
    </ButtonGroup>
  );
}

SwActionButton.propTypes = {
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool,
};

SwActionButton.defaultProps = {
  editable: true,
  deletable: true,
  onEdit: noop,
  onDelete: noop,
  disableDelete: false,
  disableEdit: false,
};

export default SwActionButton;
