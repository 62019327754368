import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import useLookup from '../../hooks/useLookup';
import {
  selectCaseDetails,
  selectHouseMembers,
  navigatePageName,
} from '../../store/selectors/caseSelector';
import { useSelector } from 'react-redux';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import React, { useEffect, useMemo } from 'react';
import usePageNav from '../../hooks/usePageNav';
import EduDetails from './EducationDetails';
import usePage from '../../hooks/usePage';
import { getEducationDetails } from '../../utils/services/educationDetailsServices/requests';
import { find, isEmpty, some } from 'lodash';
import useLoader from '../../hooks/useLoader';
import { showWarningToast } from '../../utils/services/toast';

function EducationDetails({ id }) {
  const verifyPageName = useSelector(navigatePageName);
  const { navProps } = usePage(id);
  const [showLoader, hideLoader] = useLoader();
  const { caseId } = usePageNav();
  const { getOptions, getLkpMatch, lkpRaw, lkpFlatten } =
    useLookup('EducationDetails');
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const caseDetails = useSelector(selectCaseDetails);
  const isSnap = some(
    caseDetails?.programs,
    ({ programName }) => programName === 'FS'
  );
  const { navigations } = usePageNav();
  const hohIndvId = useMemo(() => {
    const hoh = find(houseMembers, { headOfHouseholdYN: 'Y' });
    return hoh?.individualId;
  }, [houseMembers]);

  const onNext = () => {
    navigations.toDomesticViolence();
  };

  const onBack = () => {
    navigations.toIndividualDetails();
  };

  const validateHohEduDetails = async () => {
    try {
      showLoader();
      const res = await getEducationDetails(caseId, hohIndvId);
      if (res.status === 200) {
        const flag = !isEmpty(res.data);
        if (!flag) {
          showWarningToast(
            'Please add education details for head of household'
          );
        }
        return flag;
      }
    } catch (err) {
      return false;
    } finally {
      hideLoader();
    }
  };

  return (
    <PageContainer
      navProps={navProps}
      title="Education Details"
      toVerify={
        [
          'STUDENT_GRADUATION_DT',
          'STUDENT_GRADUATION_DT',
          'STUDENT_STATUS',
          'STUDENT_GOOD_STANDING',
          'STUDENT_GRADUATION_DT',
        ].includes(verifyPageName)
          ? verifyPageName
          : '  '
      }
      onNext={onNext}
      onBack={onBack}
      validateBeforeNextPage={isSnap ? validateHohEduDetails : null}
    >
      <SelectableView
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        data={houseMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return (
            <MemberMenuButton
              member={params}
              options={options}
              genderLkp={lkpFlatten?.Gender}
            />
          );
        }}
        showEdit={false}
        showDelete={false}
      >
        <EduDetails
          getOptions={getOptions}
          getLkpMatch={getLkpMatch}
          lkpRaw={lkpRaw}
        />
      </SelectableView>
    </PageContainer>
  );
}

export default EducationDetails;
