import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import FormSection from '../../../components/FormSection';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import Dropdown from '../../../components/Dropdown';
import useLookup from '../../../hooks/useLookup';
import { postWork } from '../../../utils/services/WorkExemption/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import useLoader from '../../../hooks/useLoader';
import * as Yup from 'yup';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import {
  selectCaseDetails,
  selectHouseMembers,
} from '../../../store/selectors/caseSelector';
import { getFullName } from '../../../utils/helpers';

export default function WorkModal({
  toggleClose,
  caseId,
  individualId,
  createWork,
  workEdit,
  getWorkDet,
  getOptions,
  getLkpMatch,
  verifyOpts,
  // program,
}) {
  const [showLoader, hideLoader] = useLoader();
  const caseDetails = useSelector(selectCaseDetails);
  const { houseMembers } = useSelector(selectHouseMembers);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  console.log(houseMembers, '000');
  const memberOpts = useMemo(() => {
    const filteredMembers = houseMembers.filter(
      (item) => calculateAge(item.dob) <= 1
    );
    const options = map(filteredMembers, (item) => {
      const { personName } = item;
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      const id = item?.individualId;

      return {
        label: fullName,
        value: id,
      };
    });
    return options;
  }, [houseMembers]);

  const validationSchema = Yup.object({
    beginDate: Yup.string().required('Please select Begin Date'),
    exemption: Yup.string().required('Please select Exemption'),
    program: Yup.string().required('Plaese select Program'),
    verification: Yup.string().required('Plaese select Veriifcation'),
  });

  const formik = useFormik({
    initialValues: {
      dirtyStatus: createWork ? 'insert' : 'update',
      beginDate: workEdit?.effectiveBeginDate ?? '',
      endDate: workEdit?.effectiveEndDate ?? '',
      exemption: workEdit?.exemptionCode ?? '',
      program: workEdit?.programId ?? '',
      verification: workEdit?.exemptionVerificationCode ?? '',
      worksExemptionId: workEdit?.worksExemptionId ?? null,
      members: workEdit?.childIndividualId ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      postWorkDetails(values);
    },
  });

  const postWorkDetails = async (data) => {
    try {
      showLoader();
      const res = await postWork(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('Successfully Added the Work Exemption Details');
        toggleClose();
        getWorkDet();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Add Work Exemption</Typography>
      </Stack>
      <Box sx={{ width: '100%', marginTop: '0.5rem' }}>
        <FormSection>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                name="beginDate"
                label="Effective Begin Date"
                formik={formik}
                required
                isDate
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="endDate"
                label="Effective End Date"
                formik={formik}
                isDate
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="program" label="Program" formik={formik} required>
                <Select name="program" placeholder="Select Program">
                  {caseDetails.programs?.map((obj, key) => {
                    {
                      return obj.programName === 'CH' ||
                        obj.programName === 'FS' ? (
                        <MenuItem value={obj?.programId} key={obj?.programId}>
                          {getLkpMatch('Program', obj.programName)}
                        </MenuItem>
                      ) : (
                        ''
                      );
                    }
                  })}
                </Select>
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="exemption"
                label="Exemptions"
                formik={formik}
                required
              >
                <Dropdown
                  options={
                    caseDetails.programs?.find(
                      (p) => p.programId === formik.values.program
                    )?.programName === 'FS'
                      ? getOptions('FSExemptReason')
                      : getOptions('CHExemptReason')
                  }
                />
              </Field>
            </Grid>
            {formik.values.exemption === 'CK' && (
              <Grid item xs={6}>
                <Field name="members" formik={formik} label="Members">
                  <Dropdown options={memberOpts} />
                </Field>
              </Grid>
            )}
            <Grid item xs={6}>
              <Field
                name="verification"
                label="Verification"
                formik={formik}
                required
              >
                <Dropdown options={verifyOpts} />
              </Field>
            </Grid>

            {formik.values.exemption === 'CK' && (
              <Grid item xs={6} mt="2rem">
                <Typography variant="h6" color="blue">
                  Child Under One can't be added for Two Parent Case.
                </Typography>
              </Grid>
            )}
          </Grid>
        </FormSection>
      </Box>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
