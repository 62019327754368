import dayjs from 'dayjs';
export const narrativePayload = (data) => {
  const {
    householdMember = '',
    title,
    comments,
    recordId,
    createdDate,
    householdMemberId,
    dirtyStatus,
    narrativeId,
  } = data;
  const memberId = householdMemberId === 'All' ? '' : householdMemberId;
  const updatedDate =
    dirtyStatus === 'insert' ? null : dayjs().format('YYYY-MM-DD');
  const [firstName = '', lastName = ''] = householdMember.split(' ');
  console.log(householdMemberId);
  return {
    createdByName: {
      dirtyStatus,
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: null,
      type: null,
    },
    createdDate: null,
    dirtyStatus,
    editable: null,
    endDate: null,
    fromDate: null,
    householdMemberId: memberId ?? '',
    narrativeId: narrativeId,
    recordId: recordId,
    householdMemberName: {
      dirtyStatus,
      firstName,
      lastName,
      middleName: '',
      type: null,
      suffix: null,
    },
    narrative: comments,
    title: title,

    updatedDate: updatedDate,
  };
};

export const searchPayload = (data) => {
  const {
    members,
    fromDate,
    toDate,
    keyWords,
    recordId,
    caseId,
    householdMemberId,
    narrativeId,
  } = data;

  return {
    caseId: caseId,
    narratives: [
      {
        agid: '',
        createdByName: {},
        createdDate: null,
        dirtyStatus: '',
        editable: false,
        endDate: null,
        fromDate: null,
        householdMemberId: householdMemberId,
        householdMemberName: {},
        narrative: '',
        narrativeId: narrativeId,
        program: { programName: '' },
        title: '',
        updatedDate: null,
      },
    ],
    paginationData: { pageNumber: '0', resultsPerPage: '5' },
  };
};
