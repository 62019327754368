import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import {
  clientMgmtItem,
  searchWorkItem,
  workersWorkItem,
} from '../../utils/services/apiRequests';
import useLoader from '../../hooks/useLoader';
import { showErrorToast } from '../../utils/services/toast';
import dayjs from 'dayjs';
import useLookup from '../../hooks/useLookup';
import { DataGrid } from '@mui/x-data-grid';
import ExternalLink from '../../components/ExternalLink';
import { map } from 'lodash';
import usePageNav from '../../hooks/usePageNav';
import { utils, writeFileXLSX } from 'xlsx';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmptyState from '../../components/EmptyState';
import NoRecord from '../../assets/icons/No-data-amico.svg';

const caseStatus = [
  { label: 'Open', value: 'OP' },
  { label: 'Closed', value: 'CL' },
  { label: 'Pending', value: 'PE' },
  { label: 'In Progress', value: 'IP' },
];

const workStatus = [
  { label: 'Open', value: 'OP' },
  { label: 'Closed', value: 'CL' },
];

function AlertSearch(props) {
  const { getLkpMatch, lkpFlatten, getOptions } = useLookup('AlertSearch');
  const [showLoader, hideLoader] = useLoader();
  const { navigations, caseId } = usePageNav();
  const [workItemData, setWorkItemData] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [workerddOptions, setWorkerddOptions] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);

  const onOpenCase = (caseId, controlId) => {
    navigations.toCPImport(caseId, controlId);
  };

  const workerItemsColumns = useMemo(
    () => [
      {
        flex: 1,
        field: 'caseId',
        headerName: 'Case/Web ID',
        renderCell({ row }) {
          const id = row?.webId ?? row?.caseId ?? '';
          const controlId = row?.consumerControlId ?? '';
          return (
            <ExternalLink onClick={() => onOpenCase(id, controlId)}>
              {id} &nbsp;
            </ExternalLink>
          );
        },
      },
      {
        flex: 1,
        field: 'sprvsrStatus',
        headerName: 'Alert Status',
        valueGetter({ row }) {
          switch (row?.workItemStatus) {
            case 'OP':
              return 'OPEN';
            case 'CL':
              return 'CLOSED';
            case 'PE':
              return 'PENDING';
            case 'IP':
              return 'In Progress';
            default:
              return '-';
          }
        },
      },
      {
        flex: 1,
        field: 'caseStatus',
        headerName: 'Case Status',
        valueGetter({ row }) {
          switch (row?.caseStatus) {
            case 'OP':
              return 'OPEN';
            case 'CL':
              return 'CLOSED';
            case 'PE':
              return 'PENDING';
            case 'IP':
              return 'In Progress';
            default:
              return '-';
          }
        },
      },
      {
        field: 'alertCreateDt',
        headerName: 'App Date',
        valueGetter({ row }) {
          return dayjs(row?.alertCreateDt).format('YYYY-MM-DD');
        },
      },
      {
        flex: 1,
        field: 'hohName',
        headerName: 'HOH Name',
      },
      {
        flex: 1,
        field: 'alertType',
        headerName: 'Type',
        valueGetter({ row }) {
          return getLkpMatch('AlertType', row?.alertType);
        },
      },
      {
        field: 'alretSubType',
        headerName: 'Subtype',
        valueGetter({ row }) {
          return getLkpMatch('AlertSubType', row?.alertSubType);
        },
      },
      {
        flex: 1,
        field: 'doNum',
        headerName: 'Do Number',
      },
      {
        flex: 1,
        field: 'dueDate',
        headerName: 'Due Date',
        valueGetter({ row }) {
          return dayjs(row?.dueDate).format('YYYY-MM-DD');
        },
      },
      {
        flex: 1,
        field: 'assignedTo',
        headerName: 'Assigned To',
        valueGetter({ row }) {
          return `${row?.assignedFirstName ?? ''} ${
            row?.assignedLastName ?? ''
          }`;
        },
      },
    ],
    [getLkpMatch]
  );

  const searchWorkItemCall = async (data) => {
    try {
      if (data?.isAppCpYN === false || []) {
        data.isAppCpYN = 'N';
      } else if (data?.isAppCpYN === true) {
        data.isAppCpYN = 'Y';
      }
      showLoader();
      setAdvancedSearch(false);
      const res = await searchWorkItem(data);
      if (res.status === 200 && res.data?.workItemData) {
        setWorkItemData(res.data?.workItemData);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      webId: null,
      source: null,
      caseId: '',
      caseStatus: null,
      programCd: null,
      hohName: null,
      xsnap: null,
      createDt: null,
      dueDt: null,
      description: null,
      alertType: null,
      alertSubType: null,
      workItemStatus: null,
      doNum: null,
      createdBeginDt: dayjs()
        .subtract(1, 'months')
        .endOf('month')
        .format('YYYY-MM-DD'),
      createdEndDt: dayjs().format('YYYY-MM-DD'),
      dueBeginDt: null,
      dueEndDt: null,
      alertId: null,
      benefitsCaseId: null,
      consumerControlId: null,
      sprvsrStatus: null,
      dispositionedBy: null,
      dispositionReasonCd: null,
      dispositionDate: null,
      isAppCpYN: '',
    },
    onSubmit: searchWorkItemCall,
  });

  const handleClear = () => {
    formik.handleReset();
    setAdvancedSearch(false);
  };

  const {
    DispRsnOpts,
    AlertTypeOpts,
    AlertOpts,
    ProgramOpts,
    aidcdOpts,
    ProgramStsOpts,
  } = useMemo(() => {
    return {
      DispRsnOpts: getOptions('AlertDispRsnCd'),
      AlertTypeOpts: getOptions('AlertSubType'),
      AlertOpts: getOptions('AlertType'),
      ProgramOpts: getOptions('Program'),
      aidcdOpts: getOptions('aidcd'),
      ProgramStsOpts: getOptions('ProgramStatus'),
    };
  }, [getOptions]);

  useEffect(() => {
    getDistrictOfc();
    getWorkerOptions();
  }, []);

  const getDistrictOfc = async () => {
    try {
      showLoader();
      const res = await clientMgmtItem();
      if (res.status === 200 && res.data) {
        setDistrictOptions(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const getWorkerOptions = async () => {
    try {
      showLoader();
      const res = await workersWorkItem();
      if (res.status === 200 && res.data) {
        setWorkerddOptions(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const workerOptions = useMemo(() => {
    return map(workerddOptions, ({ firstName, lastName, uniqueId }) => {
      const label = `${firstName} ${lastName}`;
      return {
        label,
        value: uniqueId,
      };
    });
  }, [workerddOptions]);

  function exportData(rows) {
    const wb = utils.book_new();
    utils.book_append_sheet(wb, utils.json_to_sheet(rows));
    writeFileXLSX(wb, 'Alert Search.xlsx');
  }

  const districtOfcOptions = useMemo(() => {
    return map(districtOptions, ({ doName, doCd }) => {
      const label = `${doName}`;
      return {
        label,
        value: doCd,
      };
    });
  }, [districtOptions]);

  return (
    <Box p="1rem" m="1rem" component={Paper}>
      <Typography mb="1rem" variant="h2">
        Alert Search
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={advancedSearch ? 3 : 2}>
          <Field name="caseId" formik={formik} label="Case ID">
            <TextField placeholder="Case ID" />
          </Field>
        </Grid>
        <Grid item xs={advancedSearch ? 3 : 2}>
          <Field name="webId" formik={formik} label="Web ID">
            <TextField placeholder="Web ID" />
          </Field>
        </Grid>
        <Grid item xs={advancedSearch ? 3 : 2}>
          <Field name="programCd" formik={formik} label="Program">
            <Dropdown options={ProgramOpts} placeholder="Program" />
          </Field>
        </Grid>
        <Grid item xs={advancedSearch ? 3 : 2}>
          <Field name="caseStatus" formik={formik} label="Case Status">
            <Dropdown options={caseStatus} placeholder="Case Status" />
          </Field>
        </Grid>
        <Grid item xs={advancedSearch ? 3 : 2}>
          <Field
            name="workItemStatus"
            formik={formik}
            label="Worker Item Status"
          >
            <Dropdown options={workStatus} placeholder="Item Status" />
          </Field>
        </Grid>

        {advancedSearch ? (
          <>
            <Grid item xs={3}>
              <Field name="doNum" formik={formik} label="District Office">
                <Dropdown
                  options={workerOptions}
                  placeholder="District Office"
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="alertAssignedTo"
                formik={formik}
                label="Assigned Worker"
              >
                <Dropdown
                  options={workerOptions}
                  placeholder="Assigned Worker"
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="alertType" formik={formik} label="Worker Item Type">
                <Dropdown options={AlertOpts} placeholder="Worker Item Type" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="alertSubType"
                formik={formik}
                label="Worker Item Sub Type"
              >
                <Dropdown
                  options={AlertTypeOpts}
                  placeholder="Worker Item Sub Type"
                />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="dispositionedBy" formik={formik} label="Disposed By">
                <Dropdown options={workerOptions} placeholder="Disposed By" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="dispositionReasonCd"
                formik={formik}
                label="Dispose Reason"
              >
                <Dropdown options={DispRsnOpts} placeholder="Dispose Reason" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                isDate
                name="dispositionDate"
                formik={formik}
                label="Dispose Date"
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                isDate
                name="createdBeginDt"
                formik={formik}
                label="Create Date From"
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                isDate
                name="createdEndDt"
                formik={formik}
                label="Create Date To"
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                isDate
                name="dueBeginDt"
                formik={formik}
                label="Due Date from"
              >
                <DatePicker />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field isDate name="dueEndDt" formik={formik} label="Due Date To">
                <DatePicker />
              </Field>
            </Grid>
          </>
        ) : null}
        <Grid item xs={advancedSearch ? 12 : 2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: advancedSearch ? 'end' : 'flex-start',
            }}
          >
            <Stack
              sx={{ width: advancedSearch ? '15%' : '90%' }}
              direction="column"
              justifyContent="flex-end"
              textAlign="center"
              spacing={1}
              mt={2.7}
            >
              <Button
                type="submit"
                // disabled={!formik.isValid || !formik.dirty}
                variant="contained"
                onClick={formik.handleSubmit}
              >
                Search
              </Button>
              {!advancedSearch && (
                <Grid item xs={12}>
                  <Link
                    align="right"
                    sx={{ fontSize: '0.7rem' }}
                    component="button"
                    variant="body2"
                    underline="hover"
                    onClick={() => {
                      setAdvancedSearch(true);
                    }}
                  >
                    Advanced Search
                  </Link>
                </Grid>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid container paddingLeft={'1rem'} mt={1.7}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Field name="isAppCpYN" formik={formik}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Application Received from consumer portal only"
                />
              </Field>
              <Box>
                <IconButton onClick={() => exportData(workItemData)}>
                  <Tooltip title="Export as an Excel" placement="top">
                    <AssessmentIcon />
                  </Tooltip>
                </IconButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Box mt="1rem">
        {/* {workItemData.length ? ( */}

        <DataGrid
          getRowId={(row) => row.alertId ?? row?.caseId ?? row?.webId}
          rows={workItemData ?? []}
          columns={workerItemsColumns}
          // rowCount={10}
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: '700',
              color: '#000000',
            },
            '& .MuiDataGrid-row': {
              color: 'black',
              textAlign: 'center',
            },
            // '& .MuiDataGrid-columnHeaderTitle': {
            //   fontSize: '15px',
            //   fontWeight: '600',
            // },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '50vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '50vh',
            },
            minHeight: '50vh',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                description="Note: Please click Get Work Item to Get Records"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          pageSizeOptions={[10, 20, 30]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          paginationModel={{
            page: 0,
            pageSize: 10,
          }}
          autoHeight
        />
        {/* ) : (
          <Typography variant="h5"></Typography>
        )} */}
      </Box>
    </Box>
  );
}

AlertSearch.propTypes = {};

export default AlertSearch;
