import React, { useMemo } from 'react';
import FormSection from '../../../../components/FormSection';
import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import Dropdown from '../../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import InputMask from 'react-input-mask';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import useLookup from '../../../../hooks/useLookup';
import { hideLoader, showLoader } from '../../../../store/slices/loaderSlice';
import { putContactInfo } from '../../../../utils/services/NonCustodial/apiRequests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';

export default function ContactModal({
  toggleClose,
  absentParentId,
  caseId,
  getContact,
  createContact,
  contactEdit,
}) {
  const { getOptions } = useLookup('NonCustodialParent');
  const { stateOpts, typeOpts } = useMemo(() => {
    return {
      stateOpts: getOptions('State'),
      typeOpts: getOptions('PhoneType'),
    };
  }, [getOptions]);

  const formik = useFormik({
    initialValues: {
      dirtyStatus: createContact ? 'insert' : 'update',
      careOf: contactEdit?.currentAddress?.addressCareOf ?? '',
      addressLine1: contactEdit?.currentAddress?.addressLine1 ?? '',
      addressLine2: contactEdit?.currentAddress?.addressLine2 ?? '',
      city: contactEdit?.currentAddress?.city ?? '',
      state: contactEdit?.currentAddress?.state ?? '',
      zipCode: contactEdit?.currentAddress?.zipcode ?? '',
      fromDate: contactEdit?.currentAddress?.addressStartDate ?? '',
      number: contactEdit?.phoneNumber?.phoneNumber ?? '',
      extension: contactEdit?.phoneNumber?.phoneNumberExt ?? '',
      type: contactEdit?.phoneNumber?.phoneNumberType ?? '',
      email: contactEdit?.email ?? '',
      previousAddressAdd:
        contactEdit?.previousAddresses?.map((address) => ({
          coNum: address.addressCareOf ?? '',
          line1: address.addressLine1 ?? '',
          line2: address.addressLine2 ?? '',
          cityAdd: address.city ?? '',
          stateAdd: address.state ?? '',
          zipAdd: address.zipcode ?? '',
          fromDateAdd: address.addressStartDate ?? '',
          toDate: address.addressEndDate ?? '',
        })) || [],
      previousPhoneNumb:
        contactEdit?.previousPhoneNumber?.map((phone) => ({
          number: phone.phoneNumber ?? '',
          ext: phone.phoneNumberExt ?? '',
          types: phone.phoneNumberType ?? '',
        })) || [],
    },
    onSubmit: (values) => {
      contactInfo(values);
    },
  });

  const handleAddAddress = () => {
    const updatedPreviousAdd = [...formik.values.previousAddressAdd];
    updatedPreviousAdd.push({
      coNum: '',
      line1: '',
      line2: '',
      cityAdd: '',
      stateAdd: '',
      zipAdd: '',
      fromDateAdd: '',
      toDate: '',
    });
    formik.setFieldValue('previousAddressAdd', updatedPreviousAdd);
  };

  const handleRemoveAddress = (index) => {
    const updatedPreviousAdd = [...formik.values.previousAddressAdd];
    updatedPreviousAdd.splice(index, 1);
    formik.setFieldValue('previousAddressAdd', updatedPreviousAdd);
  };

  const handleAddPhoneNumber = () => {
    const updatePhoneNumb = [...formik.values.previousPhoneNumb];
    updatePhoneNumb.push({
      number: '',
      ext: '',
      types: '',
    });
    formik.setFieldValue('previousPhoneNumb', updatePhoneNumb);
  };

  const handleRemovePhone = (index) => {
    const updatePhoneNumb = [...formik.values.previousPhoneNumb];
    updatePhoneNumb.splice(index, 1);
    formik.setFieldValue('previousPhoneNumb', updatePhoneNumb);
  };

  const contactInfo = async (data) => {
    try {
      showLoader();
      const res = await putContactInfo(data, caseId, absentParentId);
      if (res.status === 200) {
        showSuccessToast('Successfully Added The Contact Info');
        toggleClose();
        getContact();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <FormSection label="Address">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field name="careOf" label="C/O" formik={formik}>
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="addressLine1"
              label="Address Line1"
              formik={formik}
              required
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="addressLine2"
              label="Address Line2"
              formik={formik}
              normalizers={[maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="city"
              label="City"
              formik={formik}
              required
              normalizers={[onlyAlphabet, maxLength(30)]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field name="state" label="State" formik={formik} required>
              <Dropdown options={stateOpts} />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="zipCode"
              label="Zip Code"
              formik={formik}
              required
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name="fromDate"
              label="From Date"
              formik={formik}
              required
              isDate
            >
              <DatePicker disableFuture />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Phone" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field name="number" label="Number" formik={formik}>
              <InputMask mask="(999)-999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="(000)-000-0000"
                    value={formik.values.number}
                    {...params}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name="extension"
              label="Extension"
              formik={formik}
              normalizers={[maxLength(5), number]}
            >
              <TextField />
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field name="type" label="Type" formik={formik}>
              <Dropdown options={typeOpts} />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Email" mt="1rem">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field name="email" label="Email" formik={formik}>
              <TextField />
            </Field>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label="Previous Contact Information">
        <Stack direction="row" justifyContent="space-between" mx={2} my={2}>
          <Typography variant="h6">Previous Address</Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={handleAddAddress}
          >
            + Add Previous Address
          </Button>
        </Stack>
        {(formik.values.previousAddressAdd || []).map((address, index) => (
          <Box mx={2} key={index}>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="h4">Address {index + 1}</Typography>
              <div
                onClick={() => handleRemoveAddress(index)}
                style={{ cursor: 'pointer' }}
              >
                <DeleteIcon sx={{ color: 'red' }} />
              </div>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name={`previousAddressAdd[${index}].coNum`}
                  label="C/O"
                  formik={formik}
                >
                  <TextField />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`previousAddressAdd[${index}].line1`}
                  label="Address Line1"
                  formik={formik}
                  required
                  normalizers={[maxLength(30)]}
                >
                  <TextField />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`previousAddressAdd[${index}].line2`}
                  label="Address Line2"
                  formik={formik}
                  normalizers={[maxLength(30)]}
                >
                  <TextField />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`previousAddressAdd[${index}].cityAdd`}
                  label="City"
                  formik={formik}
                  required
                  normalizers={[onlyAlphabet, maxLength(30)]}
                >
                  <TextField />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`previousAddressAdd[${index}].stateAdd`}
                  label="State"
                  formik={formik}
                  required
                >
                  <Dropdown options={stateOpts} />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`previousAddressAdd[${index}].zipAdd`}
                  label="Zip Code"
                  formik={formik}
                  required
                  normalizers={[maxLength(5), number]}
                >
                  <TextField />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`previousAddressAdd[${index}].fromDateAdd`}
                  label="From Date"
                  formik={formik}
                  required
                  isDate
                >
                  <DatePicker disableFuture />
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name={`previousAddressAdd[${index}].toDate`}
                  label="To Date"
                  formik={formik}
                  required
                  isDate
                >
                  <DatePicker disableFuture />
                </Field>
              </Grid>
            </Grid>
          </Box>
        ))}
        <Stack direction="row" justifyContent="space-between" mx={2} my={2}>
          <Typography variant="h6">Previous Phone Number</Typography>
          <Button
            variant="text"
            size="small"
            color="primary"
            onClick={handleAddPhoneNumber}
          >
            + Add Phone Number
          </Button>
        </Stack>
        {(formik.values.previousPhoneNumb || []).map((phones, index) => (
          <Box mx={2} key={index}>
            <Stack direction="row" justifyContent="space-between" mt={1}>
              <Typography variant="h4">Phone {index + 1}</Typography>
              <div
                onClick={() => handleRemovePhone(index)}
                style={{ cursor: 'pointer' }}
              >
                <DeleteIcon sx={{ color: 'red' }} />
              </div>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  name={`previousPhoneNumb[${index}].number`}
                  label="Number"
                  formik={formik}
                >
                  <InputMask mask="(999)-999-9999" maskChar={null}>
                    {(params) => (
                      <OutlinedInput
                        variant="outlined"
                        placeholder="(000)-000-0000"
                        value={phones.number}
                        {...params}
                      />
                    )}
                  </InputMask>
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`previousPhoneNumb[${index}].ext`}
                  label="Extension"
                  formik={formik}
                  normalizers={[maxLength(5), number]}
                >
                  <TextField />
                </Field>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`previousPhoneNumb[${index}].types`}
                  label="Type"
                  formik={formik}
                >
                  <Dropdown options={typeOpts} />
                </Field>
              </Grid>
            </Grid>
          </Box>
        ))}
      </FormSection>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
