import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { render } from '@testing-library/react';
import dayjs from 'dayjs';
import { find, map } from 'lodash';
import React, { useState } from 'react';
import Avatar from '../../../components/Avatar';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import EmptyState from '../../../components/EmptyState';
import NoDocument from '../../../assets/icons/No-data-pana.svg';

const VerifiedDocument = () => {
  const [selectedData, setSelectedData] = useState([]);
  //   const [rowData, setRowData] = useState([]);

  const handleRowSelectionChange = (newRowSelectionModel) => {
    const selectedData = map(newRowSelectionModel, (selectedId) => {
      return find(rowData, (item) => item.caseId === selectedId);
    });
    setSelectedData(selectedData);
  };

  const rowData = [
    {
      id: 1,
      docName: 'Your Information',
      name: 'James Bond',
      status: 'In Progress',
    },
    {
      id: 2,
      docName: 'Your Information',
      name: 'James Bond',
      status: 'In Progress',
    },
    {
      id: 3,
      docName: 'Your Information',
      name: 'James Bond',
      status: 'In Progress',
    },
    {
      id: 4,
      docName: 'Your Information',
      name: 'James Bond',
      status: 'In Progress',
    },
    {
      id: 5,
      docName: 'Your Information',
      name: 'James Bond',
      status: 'In Progress',
    },
  ];

  const columns = [
    {
      field: 'docName',
      headerName: 'Document Name',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar name={params?.row?.name} />
            <Typography>{params.row.name}</Typography>
          </Box>
        );
      },
      flex: 1,
    },
    { field: 'status', headerName: 'Status', flex: 1 },
    {
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <ImageOutlinedIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Grid container sx={{ background: '#FFF' }}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: '#27282A',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '5px 5px 0px 0px',
        }}
        p={2}
      >
        <Box
          sx={{
            color: '#FFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>Web ID:</Typography>
          <Typography variant="h6">WS0000342</Typography>
        </Box>
        <Box
          sx={{
            color: '#FFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>Application Status:</Typography>
          <Typography variant="h6">In Progress</Typography>
        </Box>
        <Box
          sx={{
            color: '#FFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>Head of Household:</Typography>
          <Typography variant="h6">James Bond</Typography>
        </Box>
      </Grid>
      <Grid container p={2} spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <Box
            sx={{
              backgroundColor: 'var(--primary-light)',
              borderRadius: '5px ',
              border: 'var(--grey-350) solid 1px',
            }}
            p={2}
          >
            <Typography variant="h5">In Progress Document</Typography>
          </Box>
          <DataGrid
            // getRowId={(row) => (row.id)}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '15px',
                fontWeight: '600',
              },
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '50vh',
              },
              minHeight: '50vh',
            }}
            rows={rowData}
            slots={{
              noRowsOverlay: () => (
                <EmptyState
                  header="No documents available"
                  description="Note: Please upload files to proceed. Ensure files are in PDF or DOC format and do not exceed 5MB."
                  icon={
                    <img
                      src={NoDocument}
                      style={{
                        height: '160px',
                        width: '250px',
                      }}
                    />
                  }
                />
              ),
            }}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 20, 30]}
            checkboxSelection={true}
            autoHeight
            onRowSelectionModelChange={handleRowSelectionChange}
          />
          <Grid
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" sx={{ width: 'fit-content' }}>
              Document Verified
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box
            sx={{
              backgroundColor: '#73985E',
              borderRadius: '5px',
              border: 'var(--grey-350) solid 1px',
            }}
            p={2}
          >
            <Typography variant="h5" sx={{ color: '#FFFFFF' }}>
              Verified Document
            </Typography>
          </Box>
          <DataGrid
            // getRowId={(row) => (row.id)}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '15px',
                fontWeight: '600',
              },
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '50vh',
              },
              minHeight: '50vh',
            }}
            rows={[]}
            slots={{
              noRowsOverlay: () => (
                <EmptyState
                  header="No documents available"
                  description="Note: Please Verify the document to proced."
                  icon={
                    <img
                      src={NoDocument}
                      style={{
                        height: '160px',
                        width: '250px',
                      }}
                    />
                  }
                />
              ),
            }}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 20, 30]}
            // checkboxSelection={true}
            autoHeight
            onRowSelectionModelChange={handleRowSelectionChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerifiedDocument;
