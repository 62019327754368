import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { CloseRounded, DeleteOutlineOutlined } from '@mui/icons-material';
import { Stack } from '@mui/system';

function DeleteModal({ open, close, deleteData, fn }) {
  return (
    <div>
      <Dialog open={open} onClose={close} component="form" maxWidth="md">
        <DialogContent>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <IconButton onClick={close}>
              <CloseRounded />
            </IconButton>
          </Stack>
          <Stack
            component="form"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <DeleteOutlineOutlined
              sx={{ height: 100, width: 100, color: '#cf4242' }}
            />
            <Typography color="gray" variant="h3">
              Delete this Record?
            </Typography>

            <Typography my="1rem" variant="subtitle1">
              Do you really want to delete member You will not able to recover
              it.
            </Typography>

            <Stack direction="row" spacing={1}>
              <Button color="error" variant="outlined" onClick={close}>
                Discard
              </Button>
              <Button
                color="error"
                sx={{ mt: '1rem' }}
                variant="contained"
                onClick={() => fn(deleteData, 'delete')}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DeleteModal;
