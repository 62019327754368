import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { DataGrid } from '@mui/x-data-grid';
import useLookup from '../../../hooks/useLookup';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import dayjs from 'dayjs';
import { filter } from 'lodash';
import CreateIcon from '@mui/icons-material/Create';

export default function Demographic({ childData, absentParentId }) {
  const { getLkpMatch } = useLookup('NonCustodialParent');
  const { houseMembers } = useSelector(selectHouseMembers);
  const renderGenderAge = (id) => {
    return houseMembers.find(({ individualId }) => individualId == id);
  };

  const columns = [
    {
      field: 'personName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.personName
          ? `${params?.row.personName?.firstName} ${params?.row.personName?.lastName}`
          : '-';
      },
    },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 1,
      renderCell: (params) => {
        const individualId = params?.row?.individualId;
        let gend = renderGenderAge(individualId);
        if (gend && gend.gender) {
          const { gender } = gend;
          return gender ? getLkpMatch('Gender', gender) : '-';
        }
      },
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      renderCell: (params) => {
        const individualId = params?.row?.individualId;
        let indiviData = renderGenderAge(individualId);
        if (indiviData.dob) {
          const { dob } = indiviData;
          const dobDate = dayjs(dob);
          const currentDate = dayjs();
          const age = currentDate.diff(dobDate, 'year');
          return age;
        }

        return '';
      },
    },
    {
      field: 'relationship',
      headerName: 'Relationship',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.relationship
          ? getLkpMatch(
              'RelationOfAbsentParentToChild',
              params?.row?.relationship
            )
          : '-';
      },
    },
    {
      field: 'deprivation',
      headerName: 'Deprivation',
      flex: 1,
      renderCell: (params) => {
        return params?.row?.deprivation
          ? getLkpMatch('Deprivation', params?.row?.deprivation)
          : '-';
      },
    },
  ];

  const rowData = childData?.[0]?.abIdentification?.children ?? [];

  const memberInfo = filter(
    childData,
    (item) => item?.absentParentId === absentParentId
  );

  const {
    relationshipTo,
    birthCity,
    birthCountry,
    birthState,
    gender,
    race,
    dob,
    approximateAge,
  } = memberInfo?.[0]?.abDemographicInfo;

  const { ivdNumber, childSpprt } = memberInfo?.[0]?.abIdentification ?? {};
  const { soSec } = memberInfo?.[0]?.abIdentification ?? {};
  const {
    maritalDate,
    maritalStatus,
    marriageCity,
    marriageCountry,
    marriageState,
  } = memberInfo?.[0]?.abMaritalInfo ?? {};

  const {
    incarcerationCity,
    incarcerationCountry,
    incarcerationState,
    institution,
  } = memberInfo?.[0]?.abIncarceration ?? {};

  const { unionOrLocal, healthInsuranceYN } = memberInfo?.[0];

  const {
    drivingLicenseNumber,
    drivingLicenseState,
    licensePlateNumber,
    licensePlateState,
  } = memberInfo?.[0]?.absentParentMVA ?? {};

  const {
    veteranId,
    veteranServiceBranch,
    veteranServiceStartDate,
    veteranServiceEndDate,
    veteranRecepient,
  } = memberInfo?.[0]?.abMilitaryInfo ?? {};

  const { firstName, lastName } = memberInfo?.[0]?.fatherName ?? {};

  const { addressLine1, city, state, zipcode } =
    memberInfo?.[0]?.fatherContactInformation?.homeAddress ?? {};
  const { phoneNumber, phoneNumberType } =
    memberInfo?.[0]?.fatherContactInformation?.phoneNumber ?? {};
  const { eyeColor, hairColor, height, weight } =
    memberInfo?.[0]?.abPhysicalAttributes ?? {};
  const { goodCauseReason, goodCauseStatus, ivdCoopIndEffectiveDate } =
    memberInfo?.[0]?.abGoodCause ?? {};
  const { soSecPart1, soSecPart2, soSecPart3 } = soSec ?? {};
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mt="1rem">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">
              Non-Custodial Parent Identification
            </Typography>
          </Stack>
        </Grid>
        <Divider />
        <Grid item xs={3}>
          <Typography variant="h5grey">SSN</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {soSecPart1 && soSecPart2 && soSecPart3
              ? `*** ** ${soSecPart3}`
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Relationship To HOH</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {relationshipTo
              ? getLkpMatch('AbsentParentRelationship', relationshipTo)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">IVD No</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{ivdNumber}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Birth City</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{birthCity}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">DOB</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{dob}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Birth State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {birthState ? getLkpMatch('State', birthState) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Gender</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {gender ? getLkpMatch('Gender', gender) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Birth Country</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {birthCountry ? getLkpMatch('CountryOfOrigin', birthCountry) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey"> Verification</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {memberInfo?.[0]?.abDemographicInfo?.dobVerification
              ?.verificationSourceCode
              ? getLkpMatch(
                  'YesNo',
                  memberInfo?.[0]?.abDemographicInfo?.dobVerification
                    ?.verificationSourceCode
                )
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey"> Approximate Age</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{approximateAge}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Race</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {race ? getLkpMatch('Race', race) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            Child Support(Have you applied for child support for this<br></br>
            Non-Custodial Parent)
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5grey">Child Support</Typography>
            <Button
              variant="outlined"
              sx={{
                color: 'green',
              }}
              startIcon={<CheckCircle />}
            >
              {childSpprt ? getLkpMatch('ChildSupport', childSpprt) : '-'}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Children</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            rows={rowData ?? []}
            columns={columns}
            getRowId={(row) => row?.individualId}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            sx={{
              backgroundColor: 'white',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '18px',
                fontWeight: '700',
                padding: '0px 10px',
                color: '#000000',
              },
              '& .MuiDataGrid-row': {
                fontSize: '16px',
                color: 'black',
                padding: '0px 10px',
                textAlign: 'center',
              },
            }}
            slots={{
              noRowsOverlay: 'noData',
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="1rem">
        <Grid item xs={6}>
          <Typography variant="h4">IV-D Coop</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4">Good Cause Claim</Typography>
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">IV-D Coop</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">Custodian will cooperate</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Reason</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {goodCauseReason
              ? getLkpMatch('GoodCauseReasons', goodCauseReason)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">IV-D Coop Date</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{ivdCoopIndEffectiveDate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Status</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {goodCauseStatus
              ? getLkpMatch('GoodCauseStatus', goodCauseStatus)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1"></Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="h5grey">Effecrive Date</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1"></Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="1rem">
        <Grid item xs={6}>
          <Typography variant="h4">Marital Information</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4">Physical Attributes</Typography>
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Status</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {maritalStatus ? getLkpMatch('MartialStatus', maritalStatus) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Height(inches)</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{height}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Date</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{maritalDate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Hair Color</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {hairColor ? getLkpMatch('HairColor', hairColor) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Marriage City</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{marriageCity}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Weight (lbs)</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{weight}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Marriage State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {marriageState ? getLkpMatch('State', marriageState) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Eye color</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {eyeColor ? getLkpMatch('EyeColor', eyeColor) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Country</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {marriageCountry
              ? getLkpMatch('CountryOfOrigin', marriageCountry)
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="1rem">
        <Grid item xs={6}>
          <Typography variant="h4">Incarceration</Typography>
        </Grid>
        <Grid item xs={3}>
          <RadioGroup
            name="incarceration"
            // {...formik.getFieldProps('incarceration')}
            // value={formik.values.incarceration}
          >
            <FormControlLabel
              value={'Y'}
              control={<Radio />}
              label="Currently"
            />
            <FormControlLabel
              value={'N'}
              control={<Radio />}
              label="Previously"
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Institution</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{institution}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Union</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{unionOrLocal}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">City</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{incarcerationCity}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Health Insurance</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {healthInsuranceYN ? getLkpMatch('YesNo', healthInsuranceYN) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {incarcerationState
              ? getLkpMatch('State', incarcerationState)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Country</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {incarcerationCountry
              ? getLkpMatch('CountryOfOrigin', incarcerationCountry)
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="1rem">
        <Grid item xs={6}>
          <Typography variant="h4">DMV Information</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4">Military Statistics</Typography>
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Driving License</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{drivingLicenseNumber}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Veteran Details</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {veteranRecepient
              ? getLkpMatch('RecepientDropDown', veteranRecepient)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {drivingLicenseState
              ? getLkpMatch('State', drivingLicenseState)
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Veteran ID Number</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{veteranId}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">License Plate</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{licensePlateNumber}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Start Date</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{veteranServiceStartDate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey"> State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {licensePlateState ? getLkpMatch('State', licensePlateState) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">End Date</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{veteranServiceEndDate}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Branch</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {veteranServiceBranch
              ? getLkpMatch('VeteranBranch', veteranServiceBranch)
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="1rem">
        <Grid item xs={6}>
          <Typography variant="h4">Absent Parent's Father</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4">Absent Parent's Mother</Typography>
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Divider />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {firstName + ' ' + lastName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {memberInfo?.[0]?.motherName?.firstName +
              ' ' +
              memberInfo?.[0]?.motherName?.lastName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Address</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{addressLine1}</Typography>
        </Grid>
        {/* <Grid item xs={3}>
          <Typography variant="h5grey"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1"></Typography>
        </Grid> */}
        <Grid item xs={3}>
          <Typography variant="h5grey">Maiden Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {' '}
            {memberInfo?.[0]?.motherName?.maidenName}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">City</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{city}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Address</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {
              memberInfo?.[0]?.motherContactInformation?.homeAddress
                ?.addressLine1
            }
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Zip Code</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{zipcode}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">City</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {memberInfo?.[0]?.motherContactInformation?.homeAddress?.city}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {state ? getLkpMatch('State', state) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Zip Code</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {memberInfo?.[0]?.motherContactInformation?.homeAddress?.zipcode}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Phone</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">{phoneNumber}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">State</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {memberInfo?.[0]?.motherContactInformation?.homeAddress?.state
              ? getLkpMatch(
                  'State',
                  memberInfo?.[0]?.motherContactInformation?.homeAddress?.state
                )
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Type</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {phoneNumberType ? getLkpMatch('PhoneType', phoneNumberType) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Phone</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {
              memberInfo?.[0]?.motherContactInformation?.phoneNumber
                ?.phoneNumber
            }
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1"></Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h5grey">Type</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            {memberInfo?.[0]?.motherContactInformation?.phoneNumber
              ?.phoneNumberType
              ? getLkpMatch(
                  'PhoneType',
                  memberInfo?.[0]?.motherContactInformation?.phoneNumber
                    ?.phoneNumberType
                )
              : '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
