import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  POST_HEALTHINSURANCE,
  POST_INSURANCE,
  POST_MEDICARE,
  GET_HEALTH_DISABILITY,
  PUT_EDIT_MEDICARE,
} from './apiurls';
import {
  healthInsurancePayload,
  insurancePayload,
  medicarePayload,
  deleteMedPayload,
} from './apiPayload';

export const healthInsurance = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_HEALTHINSURANCE, caseId, individualId),
    healthInsurancePayload(data)
  );
};

export const insuranceMed = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_INSURANCE, caseId, individualId),
    insurancePayload(data)
  );
};

export const medicareAdd = (data = {}, caseId, individualId) => {
  return HttpClient.post(
    format(POST_MEDICARE, caseId, individualId),
    medicarePayload(data)
  );
};
export const getInsurance = (caseId, individualId) => {
  return HttpClient.get(format(GET_HEALTH_DISABILITY, caseId, individualId));
};

export const editDelMed = (data = {}, caseId, individualId) => {
  return HttpClient.put(
    format(PUT_EDIT_MEDICARE, caseId, individualId),
    deleteMedPayload(data)
  );
};

export const editMed = (data = {}, caseId, individualId) => {
  return HttpClient.put(
    format(PUT_EDIT_MEDICARE, caseId, individualId),
    medicarePayload(data)
  );
};
