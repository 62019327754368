export const GET_WAIVER =
  '/eeapi/application-process/waiver-facility/get-details/{0}/{1}';

export const POST_iNSTITUTION =
  '/eeapi/application-process/waiver-facility/save-institute-details/{0}/{1}';

export const POST_WAIVER =
  '/eeapi/application-process/waiver-facility/save-waiver-details/{0}/{1}';

export const POST_ADDITIONAL_INFO =
  '/eeapi/application-process/waiver-facility/save-additional-info-details/{0}/{1}';

export const POST_VENDOR_SEARCH =
  '/eeapi/application-process/inquiry/vendor-search';
