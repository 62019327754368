import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import FormGroup from '../../components/FormGroup';
import InputField from '../../components/InputField';
import Field from '../../components/Field';
import Dropdown from '../../components/Dropdown';
import { useFormik } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CloseRounded } from '@mui/icons-material';
import FormSection from '../../components/FormSection';
import { DatePicker } from '@mui/x-date-pickers';
import InputMask from 'react-input-mask';
import { postClearance } from '../../utils/services/Clearance/apiRequest';
import useLoader from '../../hooks/useLoader';
import TabularView from '../../components/TabularView';
import useLookup from '../../hooks/useLookup';
import dayjs from 'dayjs';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import * as Yup from 'yup';
import { maxLength, onlyAlphabet } from '../../utils/normalizers';
function ClearanceSearch({ onAddIndividual, open, close }) {
  const [maskSsn, setMaskSsn] = useState(true);
  const [rowData, setRowData] = useState('');
  const [data, setData] = useState('');
  const [disable, setDisable] = useState(true);
  const [showLoader, hideLoader] = useLoader();
  const { getLkpMatch, getOptions } = useLookup('Clearance');

  const validationSchema = Yup.object({
    ssn: Yup.string().when('firstName', {
      is: (firstName) => !firstName,
      then: () => Yup.string().required('Please enter the SSN'),
      otherwise: () => Yup.string().notRequired(),
    }),

    lastName: Yup.string().when('firstName', {
      is: (firstName) => firstName,
      then: () => Yup.string().required('Please enter the Last Name'),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      ssn: '',
      customerID: '',
      firstName: '',
      lastName: '',
      suffix: '',
      middleName: '',
      gender: '',
      race: '',
      dob: '',
      range: '',
    },
    validationSchema,
    onSubmit: (formikData) => {
      setData(formikData);
      searchHandler(formikData);
    },
  });
  const { SuffixOpts, GenderOpts, StateOpts, CountyOpts, RaceOpts } =
    useMemo(() => {
      return {
        SuffixOpts: getOptions('Suffix'),
        GenderOpts: getOptions('Gender'),
        StateOpts: getOptions('State'),
        CountyOpts: getOptions('County'),
        RaceOpts: getOptions('Race'),
      };
    }, [getOptions]);

  const searchHandler = async (data) => {
    try {
      showLoader();

      const res = await postClearance(data);
      if (res.status === 200) {
        setRowData(res.data?.results);
        setDisable(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const columns = useMemo(
    () => [
      {
        field: '',
        headerName: 'Person Name',
        getValue({ personName = {} }) {
          return `${personName?.firstName} ${personName.lastName}`;
        },
      },
      {
        field: 'dob',
        headerName: 'DOB',
      },

      {
        field: 'soSec',
        headerName: 'SSN',
        getValue(soSec) {
          return `${soSec?.soSecPart3 ? '*** ** ' + soSec?.soSecPart3 : '-'}`;
        },
      },
      {
        field: 'gender',
        headerName: 'Gender',
        getValue(gender) {
          return getLkpMatch('Gender', gender);
        },
      },

      {
        field: 'race',
        headerName: 'Race',
        getValue({ primaryRace = '' }) {
          return getLkpMatch('Race', primaryRace);
        },
      },
      {
        field: 'maId',
        headerName: 'MDM ID',
      },
      {
        field: '',
        headerName: 'Select Member',
        renderCell: (paramas) => {
          return (
            <Button
              onClick={() => onAddIndividual(paramas)}
              startIcon={<TaskAltRoundedIcon />}
              variant="contained"
            >
              Select
            </Button>
          );
        },
      },
    ],
    [getLkpMatch, rowData]
  );

  return (
    <Dialog open={open} onClose={close} maxWidth="xl">
      <DialogTitle
        component={Stack}
        direction="row"
        margin={1}
        justifyContent="space-between"
      >
        <Typography variant="h3">Clearance Search</Typography>
        <IconButton onClick={close}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormSection label="Identity Info">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field name="customerID" label="Customer ID" formik={formik}>
                <TextField placeholder=" Enter Customer ID" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="ssn" label="SSN" formik={formik}>
                <InputMask mask="999-99-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      type={maskSsn ? 'password' : 'text'}
                      variant="outlined"
                      placeholder=" Enter SSN"
                      endAdornment={
                        <InputAdornment position="start">
                          <IconButton onClick={() => setMaskSsn(!maskSsn)}>
                            {maskSsn ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
          </Grid>
        </FormSection>
        <FormSection label="Name">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field
                name="firstName"
                label="First Name"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField placeholder=" Enter First Name" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="middleName"
                label="Middle Name"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField placeholder=" Enter Middle Name" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field
                name="lastName"
                label="Last Name"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField placeholder=" Enter Last Name" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="suffix" label="Suffix" formik={formik}>
                <Dropdown options={SuffixOpts} placeholder=" Select Suffix" />
              </Field>
            </Grid>
          </Grid>
        </FormSection>
        <FormSection label="Birth Information">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field name="dob" isDate formik={formik} label="Date of Birth">
                <DatePicker maxDate={dayjs()} disableFuture />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="range" label="Range" formik={formik}>
                <TextField placeholder=" Enter Range(0-20 Years)" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="gender" label="Gender" formik={formik}>
                <Dropdown options={GenderOpts} placeholder=" Select Gender" />
              </Field>
            </Grid>
            <Grid item xs={3}>
              <Field name="race" label="Race" formik={formik}>
                <Dropdown options={RaceOpts} placeholder="Select Race" />
              </Field>
            </Grid>
          </Grid>
        </FormSection>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          mt={2}
          mb={2}
        >
          {disable ? (
            <Button
              // onClick={onAddIndividual}
              startIcon={<PersonAddAltIcon />}
              disabled
            >
              Create New Individual
            </Button>
          ) : (
            <Button
              onClick={() => onAddIndividual(data)}
              startIcon={<PersonAddAltIcon />}
            >
              Create New Individual
            </Button>
          )}

          <Box>
            <Button sx={{ mr: '1rem' }} variant="outlined" onClick={close}>
              Cancel
            </Button>
            <Button variant="contained" onClick={formik.handleSubmit}>
              Search
            </Button>
          </Box>
        </Stack>
        {rowData.length > 0 && <TabularView columns={columns} data={rowData} />}
      </DialogContent>
    </Dialog>
  );
}

ClearanceSearch.propTypes = {};

export default ClearanceSearch;
