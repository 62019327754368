import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useState } from 'react';
import { getAuthToken } from '../../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import useLoader from '../../../hooks/useLoader';
import { myDashboardApi } from '../../../utils/services/apiRequests';
import { map } from 'lodash';
import dayjs from 'dayjs';
import { showErrorToast } from '../../../utils/services/toast';
import ReviewQuestion from '../ReviewQuestions';
import EmptyState from '../../../components/EmptyState';
import NoRecord from '../../../assets/icons/No-data-amico.svg';

const MyDashboard = ({ getLkpMatch, value }) => {
  const token = useSelector(getAuthToken);
  const [page, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [showLoader, hideLoader] = useLoader();
  const [rowsData, setRowsData] = useState([]);
  const [paperAppCount, setPaperAppCount] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (value) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const action = (params) => {
    return (
      <Grid sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => handleClickOpen(params)}>
          <Tooltip title="View Case" placement="right">
            <Typography>Review Questions</Typography>
          </Tooltip>
        </IconButton>
      </Grid>
    );
  };
  const myDashboard = useMemo(
    () => [
      { field: 'caseId', headerName: 'Case ID', flex: 1, maxWidth: 200 },
      {
        field: 'pgmCd',
        headerName: 'Program',
        renderCell: (params) => {
          const programArray = params?.row?.programs?.split(',');
          return map(programArray, (p) => getLkpMatch('Program', p))?.join(',');
        },
        flex: 1,
        maxWidth: 200,
      },
      { field: 'hohName', headerName: 'HOH', flex: 1, maxWidth: 200 },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        maxWidth: 200,
      },
      {
        field: 'assignedTo',
        headerName: 'Assign To',
        flex: 1,
        maxWidth: 200,
      },
      {
        field: 'alertCreateDt',
        headerName: 'Creation Date',
        renderCell: (params) =>
          dayjs(params?.row?.alertCreateDt).format('MM-DD-YYYY'),
        flex: 1,
        maxWidth: 200,
      },
      {
        field: 'dueDate',
        headerName: 'Due Date',
        renderCell: (params) =>
          dayjs(params?.row?.dueDate).format('MM-DD-YYYY'),
        flex: 1,
        maxWidth: 200,
      },

      {
        field: 'Action',
        headerName: 'Review',
        flex: 1,
        maxWidth: 200,
        renderCell: (params) => action(params?.row),
      },
    ],
    [getLkpMatch]
  );

  const dashboardRowData = async ({ page, pageSize }) => {
    const paginationDataPaper = {
      resultsPerPage: pageSize,
      pageNumber: page,
    };
    try {
      setRowsData([]);
      showLoader();
      if (value === 1) {
        const res = await myDashboardApi(paginationDataPaper, token?.sub, 'CR');
        if (res.status === 200) {
          const { paginationPaperData = 0, resultsRegular } = res.data;
          setRowsData(resultsRegular);
          setPaperAppCount(paginationPaperData?.totalRecords ?? 0);
        }
      }
    } catch (error) {
      showErrorToast();
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (getLkpMatch) {
      dashboardRowData(page);
      setSelectedColumns(myDashboard);
    }
  }, []);

  const selectApps = () => {
    if (value === 1) {
      setSelectedColumns(myDashboard);
      dashboardRowData(page);
    }
  };

  useEffect(() => {
    selectApps();
  }, [value]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          autoHeight
          getRowId={(row) => (row.caseId ? row.caseId : row.webId || row.webID)}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '15px',
              fontWeight: '600',
            },
            '& .MuiDataGrid-virtualScroller': {
              minHeight: '50vh',
            },
            '& .MuiDataGrid-overlayWrapperInner': {
              minHeight: '50vh',
            },
            minHeight: '50vh',
          }}
          slots={{
            noRowsOverlay: () => (
              <EmptyState
                header="Currently No Records Available"
                description="Note: Please click Get Work Item to Get Records"
                icon={
                  <img
                    src={NoRecord}
                    style={{
                      height: '160px',
                      width: '250px',
                    }}
                  />
                }
              />
            ),
          }}
          rows={rowsData ?? []}
          rowCount={paperAppCount}
          columns={myDashboard}
          pageSizeOptions={[10, 20, 30]}
          paginationMode="server"
          paginationModel={page}
        />
      </Box>

      <ReviewQuestion
        handleClose={handleClose}
        open={open}
        rowsData={rowsData}
        getLkpMatch={getLkpMatch}
      />
    </>
  );
};

export default MyDashboard;
