import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { Box, FormLabel, Typography } from '@mui/material';
import cx from 'classnames';

function FormSection(props) {
  const { label, children, required, paddingZero, ...rest } = props;
  return (
    <Box className={styles.formSection} {...rest}>
      {label && (
        <FormLabel>
          <Typography variant="subtitle1" color="var(--grey-400)" sx={{marginBottom:"0.5rem",fontWeight:"650"}}>
            {label}
            {required && <span style={{ color: 'red' }}>*</span>}
          </Typography>
        </FormLabel>
      )}
      <div
        className={cx(styles.formControls, {
          [styles.paddingZero]: paddingZero,
        })}
      >
        {children}
      </div>
    </Box>
  );
}

FormSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  paddingZero: PropTypes.bool,
};

FormSection.defaultProps = {
  label: '',
  required: false,
  paddingZero: false,
};

export default FormSection;
