import React from 'react';
import PropTypes from 'prop-types';
import TextContent from '../../../../../components/TextContent';
import { Box, Grid, Stack } from '@mui/material';
import FormSection from '../../../../../components/FormSection';
import IndividualElSummary from '../../IndividualElSummary';
import { map } from 'lodash';
import TextContentGroup from '../../../../../components/TextContentGroup';
import dayjs from 'dayjs';

function ProgramElibility({
  getLkpMatch,
  eligDetails,
  name,
  programBeginDate,
}) {
  const {
    programName,
    programType,
    aidCd,
    rmpInd,
    waiverType,
    tdapType,
    secondaryAidCd,
    eligibilityStatusCd,
    confirmationDt,
    createDt,
    applicationDate,
    nonFinancialStatusCd,
    financialStatusCd,
    resourceStatusCd,
    // programBeginDate,
    programEndDate,
    houseHoldSize,
    categoricallyEligible,
    expeditedSNAPIndicator,
    expeditedSNAPDecisionDt,
    moeTanfInd,
    programId,
    agEligReason,
    redeterminationDueDate,
    simplifiedReportDate1,
    spendDownBeginDate,
    spendDownEndDate,
    spendDownSecondaryBeginDate,
    spendDownSecondaryEndDate,
    agIndvEligResult, //#455
  } = eligDetails;

  const textContents = [
    {
      label: 'Program Details',
      fields: [
        {
          title: 'Program Type',
          subTitle: getLkpMatch('ProgramType', programType),
        },
        {
          title: 'Benefit Group',
          subTitle: getLkpMatch('aidcd', aidCd),
        },
        {
          title: 'RMP Indicator',
          subTitle: getLkpMatch('YesNo', rmpInd),
        },
      ],
    },
    {
      label: 'Status',
      fields: [
        {
          title: 'Eligibility Status',
          subTitle: getLkpMatch('EligibilityStatusCode', eligibilityStatusCd),
        },
        {
          title: 'Status Date',
          subTitle: dayjs(createDt).format('MM-DD-YYYY'),
        },
        {
          title: 'Application Date',
          subTitle: applicationDate,
        },
        {
          title: 'Non Financial Status',
          subTitle: getLkpMatch('EligibilityStatusCode', nonFinancialStatusCd),
        },
        {
          title: 'Financial Status',
          subTitle: getLkpMatch('EligibilityStatusCode', nonFinancialStatusCd),
        },
        {
          title: 'Resource Status',
          subTitle: getLkpMatch('EligibilityStatusCode', financialStatusCd),
        },
        {
          title: 'Program Begin Date',
          subTitle: programBeginDate,
          hide: eligibilityStatusCd === 'FL',
        },
        {
          title: 'Program End Date',
          subTitle: programEndDate,
          showIf: programEndDate,
        },
        {
          title: 'Household Size',
          subTitle: houseHoldSize,
        },
        {
          title: 'Categorically Eligible',
          subTitle: categoricallyEligible,
        },
        {
          title: 'XSNAP Indicator',
          subTitle: getLkpMatch('YesNo', expeditedSNAPIndicator),
        },
        {
          title: 'XSNAP Decision Date',
          subTitle: expeditedSNAPDecisionDt,
          showIf: programName === 'FS',
        },
      ],
    },
  ];

  console.log(agEligReason);
  return (
    <Stack spacing={2}>
      <TextContentGroup col={3} textContents={textContents} />
      <FormSection paddingZero label="Eligibility Reasons">
        <Box p="0.5rem">
          <Grid container spacing={2}>
            {map(agEligReason, ({ reasonCod, noticeTypCod }, index) => (
              <Grid item xs={4}>
                <TextContent
                  title={`Reason ${index + 1}`}
                  subTitle={getLkpMatch('EligibilityReasonCode', reasonCod)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </FormSection>
    </Stack>
  );
}

ProgramElibility.propTypes = {};

export default ProgramElibility;
