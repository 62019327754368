import { SEARCH, WORKERNAME } from './apiUrls';
import { searchPayload } from './apiPayload';
import { format } from '../../helpers';
import HttpClient from '../../HttpClient';

export const seachCase = (data) => {
  return HttpClient.post(format(SEARCH), searchPayload(data));
};

export const workersNames = () => {
  return HttpClient.get(format(WORKERNAME));
};
