import React from 'react';
import styles from './index.module.css';
import { Grid, Paper, Typography, Box, LinearProgress } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export default function CaseDetails() {
  const arr = [1, 2, 3];
  return (
    <Box className={styles.caseContent}>
      {/* caseCard */}

      <Grid container spacing={2}>
        <Grid item container xs={4}>
          <Grid className={styles.casecomplete}>
            <Grid container>
              <Grid>
                <CheckCircleOutlinedIcon
                  sx={{
                    backgroundColor: 'white',
                    margin: 1,
                    border: '.2rem solid #a4f797',
                    borderRadius: '.3rem',
                  }}
                />
              </Grid>
              <Grid>
                <Typography variant="h5" color={'white'}>
                  Case
                </Typography>
                <Typography variant="h5" color={'white'}>
                  Completed
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction={'row'}
              marginTop={2}
              spacing={2}
              alignItems={'center'}
            >
              <Grid item xs={10} lg={10.5}>
                <LinearProgress
                  variant="determinate"
                  sx={{
                    borderRadius: 3,
                    padding: 0.5,
                    border: 1,
                    borderColor: 'white',
                    backgroundColor: 'white',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#146d12',
                    },
                  }}
                  value={80}
                />
              </Grid>

              <Grid item xs={2} lg={1.5} ml={-1}>
                <Typography color={'white'} variant="h5">
                  80%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid className={styles.caseProcess}>
            <Grid container>
              <Grid>
                <RotateRightIcon
                  sx={{
                    backgroundColor: 'white',
                    margin: 1,
                    border: '.2rem solid #81c0ed',
                    borderRadius: '.3rem',
                  }}
                />
              </Grid>
              <Grid>
                <Typography variant="h5" color={'white'}>
                  Case
                </Typography>
                <Typography variant="h5" color={'white'}>
                  In Progress
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction={'row'}
              marginTop={2}
              spacing={2}
              alignItems={'center'}
            >
              <Grid item xs={10} lg={10.5}>
                <LinearProgress
                  variant="determinate"
                  sx={{
                    borderRadius: 3,
                    padding: 0.5,
                    border: 1,
                    borderColor: 'white',
                    backgroundColor: 'white',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#134795',
                    },
                  }}
                  value={60}
                />
              </Grid>
              <Grid item xs={2} lg={1.5} ml={-1}>
                <Typography color={'white'} variant="h5">
                  60%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={4}>
          <Grid className={styles.caseOverdude}>
            <Grid container>
              <Grid>
                <UpdateOutlinedIcon
                  sx={{
                    backgroundColor: 'white',
                    margin: 1,
                    border: '.2rem solid #f5d379',
                    borderRadius: '.3rem',
                  }}
                />
              </Grid>
              <Grid>
                <Typography variant="h5" color={'white'}>
                  Case
                </Typography>
                <Typography variant="h5" color={'white'}>
                  In Overdue
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction={'row'}
              marginTop={2}
              spacing={2}
              alignItems={'center'}
            >
              <Grid item xs={10} lg={10.5}>
                <LinearProgress
                  variant="determinate"
                  sx={{
                    borderRadius: 3,
                    padding: 0.5,
                    border: 0.5,
                    borderColor: 'white',
                    backgroundColor: 'white',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#ce980c',
                    },
                  }}
                  value={50}
                />
              </Grid>
              <Grid item xs={2} lg={1.5} ml={-1}>
                <Typography color={'white'} variant="h5">
                  50%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
