import React, { useMemo } from 'react';
import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import { useSelector } from 'react-redux';
import usePageNav from '../../hooks/usePageNav';
import SanctionsDisqualifictaionContent from './SanctionsDisqualifictaionContent';

const SanctionDisqualification = () => {
  const { caseId } = usePageNav();
  const onNext = () => {};
  const onBack = () => {};
  const houseMembers = useSelector(selectHouseMembers);
  const houseHoldMembers = useMemo(() => {
    if (houseMembers) return houseMembers.houseMembers;
  }, [houseMembers]);

  return (
    <PageContainer
      title="Sanctions/Disqualifictaions"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={houseHoldMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <SanctionsDisqualifictaionContent caseId={caseId} />
      </SelectableView>
    </PageContainer>
  );
};

export default SanctionDisqualification;
