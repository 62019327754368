import React, { useRef } from 'react';
import { Button, Typography, Box, Stack, Divider, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import styles from './index.module.css';
import dayjs from 'dayjs';

const ViewDetailsDialog = ({
  open,
  setOpen,
  onClose,
  viewIssuanceData,
  lkpFlatten,
}) => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleClose = () => {
    onClose();
  };

  const formatAmount = (amount) => {
  return (amount ?? 0) === 0 ? "00.00" : amount.toFixed(2);
}

const infoList = [
  {
    infoLabel: 'Case Id',
    infoValue: viewIssuanceData?.caseId,
  },
  {
    infoLabel: 'Aid Code',
    infoValue: viewIssuanceData?.aidCd,
  },
  {
    infoLabel: 'Do Name',
    infoValue: `${viewIssuanceData?.officeNum} - ${viewIssuanceData?.office}`,
  },
];

const amountLists = [
  {
    amountLabel: 'Recoupment Amount',
    amountValue: viewIssuanceData?.recoupmentAmt,
  },
  {
    amountLabel: 'Age/Abandoned Amount',
    amountValue: viewIssuanceData?.abandonAmt,
  },
  {
    amountLabel: 'State Funds',
    amountValue: viewIssuanceData?.stateFunds,
  },
  {
    amountLabel: 'Federal Funds',
    amountValue: viewIssuanceData?.federalFunds,
  },
  {
    amountLabel: 'Local Funds',
    amountValue: viewIssuanceData?.localFunds,
  },
];

const totalAmount = amountLists.reduce((fValue, list) => {
    const price = list.amountValue != null ? list.amountValue : 0;
    return fValue + price;
}, 0);


const issuanceInfoList = [
  {
    issuanceLabel: 'Issuance Month',
    issuanceValue: dayjs(viewIssuanceData?.paymentMonth, 'YYYYMM').format(
      'YYYY-MM'
    ),
  },
  {
    issuanceLabel: 'Issuance Status',
    issuanceValue: lkpFlatten?.Issuancstatus?.[viewIssuanceData?.issuanceStsCd],
  },
  {
    issuanceLabel: 'Issuance Status Date',
    issuanceValue: viewIssuanceData?.statusDt,
  },
  {
    issuanceLabel: 'Issuance Type',
    issuanceValue: `${viewIssuanceData?.issuanceType} - Initial Months Grant`,
  },
  {
    issuanceLabel: 'Issuance Method',
    issuanceValue:
      lkpFlatten?.IssuanceMethod?.[viewIssuanceData?.issuanceMethod],
  },
  {
    issuanceLabel: 'Issuance Source',
    issuanceValue:  lkpFlatten?.ownrtype?.[viewIssuanceData?.issuanceSource],
  },
];

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="view"
      aria-describedby="viewDescription"
    >
      <DialogTitle id="view">Issuance Details</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'var(--grey-400)',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className={styles.issuance_container} ref={printRef}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box>
              <Typography variant="body">Ag Head</Typography>
              <Typography variant="h1">
                {viewIssuanceData?.agHeadName}
              </Typography>
              <Typography variant="h6">
                {lkpFlatten?.RequestedPrograms?.[viewIssuanceData?.programCd] ||
                  ''}
              </Typography>
            </Box>
            <Box>
              {infoList.map((info) => (
                <div className={styles.info_row}>
                  <Typography className={styles.info_label}>
                    {info.infoLabel}:
                  </Typography>
                  <Typography className={styles.info_value}>
                    {info.infoValue}
                  </Typography>
                </div>
              ))}
            </Box>
          </Stack>
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Box>
              <div className={styles.info_row}>
                <Typography className={styles.info_label2}>
                  Vendor Id
                </Typography>
                <Typography className={styles.info_value}>
                  {viewIssuanceData?.venorId ?? 'N/A'}
                </Typography>
              </div>
              <div className={styles.info_row}>
                <Typography className={styles.info_label2}>
                  Vendor Name
                </Typography>
                <Typography className={styles.info_value}>
                  {viewIssuanceData?.vendorName ?? 'N/A'}
                </Typography>
              </div>
            </Box>
            <Box display="flex">
              <div className={`${styles.dateBox} ${styles.startDate}`}>
                <Typography variant="h6" className={styles.dateLabel}>
                  Start Date
                </Typography>
                <Typography variant="h5" className={styles.dateValue}>
                  {viewIssuanceData?.benefitStartDt}
                </Typography>
              </div>
              <div className={`${styles.dateBox} ${styles.endDate}`}>
                <Typography variant="h6" className={styles.dateLabel}>
                  End Date
                </Typography>
                <Typography variant="h5" className={styles.dateValue}>
                  {viewIssuanceData?.benefitEndDt}
                </Typography>
              </div>
            </Box>
            <Box>
              <div className={styles.info_row}>
                <Typography className={styles.info_label}>
                  Number of Adults
                </Typography>
                <Typography className={styles.info_value}>
                  {viewIssuanceData?.adults}
                </Typography>
              </div>
              <div className={styles.info_row}>
                <Typography className={styles.info_label}>
                  Number of Children
                </Typography>
                <Typography className={styles.info_value}>
                  {viewIssuanceData?.childrens}
                </Typography>
              </div>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} my={3} height={'380px'}>
            <Box width={'50%'}>
              <Typography color="var(--grey-400)">Issuance Info</Typography>
              <div className={styles.issuanceInfo}>
                <Stack direction="row" justifyContent="space-between" mb={2}>
                  <Box>
                    <Typography variant="body">Issuance Number</Typography>
                    <Typography variant="h4" className={styles.numberFont}>
                      {viewIssuanceData?.benefitIssncNum}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body">Issuance Amount</Typography>
                    <Typography variant="h2">
                      ${viewIssuanceData?.issuanceAmount}
                    </Typography>
                    <Typography variant="h6">
                      0n {viewIssuanceData?.issncDt}
                    </Typography>
                  </Box>
                </Stack>
                <Divider />
                <Box>
                  {issuanceInfoList?.map((issuance) => (
                    <div className={styles.issuanceInfoRow}>
                      <Typography className={styles.issuanceInfoLabel}>
                        {issuance.issuanceLabel}
                      </Typography>
                      <Typography className={styles.issuanceInfoValue}>
                        {issuance.issuanceValue}
                      </Typography>
                    </div>
                  ))}
                </Box>
              </div>
            </Box>
            <Box width={'50%'}>
              <Typography color="var(--grey-400)">Finance Info</Typography>
              <div className={styles.financeInfo}>
                <Box>
                  <div className={styles.financeNumRow}>
                    <Typography
                      variant="h5"
                      color="var(--grey-400)"
                      className={styles.financeNumLabel}
                    >
                      Bank Account Number
                    </Typography>
                    <Typography className={styles.financeNumValue}>
                      {viewIssuanceData?.accountCd ?? 'N/A'}
                    </Typography>
                  </div>
                  <div className={styles.financeNumRow}>
                    <Typography
                      variant="h5"
                      mt={2}
                      color="var(--grey-400)"
                      className={styles.financeNumLabel}
                    >
                      Sub Account Number
                    </Typography>
                    <Typography className={styles.financeNumValue}>
                      {viewIssuanceData?.subAccCd ?? 'N/A'}
                    </Typography>
                  </div>
                </Box>
                <Divider />
                <Box mb={2}>
                  {amountLists?.map((amount) => (
                    <div className={styles.financeInfoRow}>
                      <Typography className={styles.financeInfoLabel}>
                        {amount.amountLabel}
                      </Typography>
                      <Typography className={styles.financeInfoValue}>
                        ${formatAmount(amount.amountValue)}
                      </Typography>
                    </div>
                  ))}
                </Box>
                <Divider />
                <Box>
                  <div className={styles.financeInfoRow}>
                    <Typography className={styles.financeInfoLabel}>
                      Total
                    </Typography>
                    <Typography className={styles.financeInfoValue}>
                      ${formatAmount(totalAmount)}
                    </Typography>
                  </div>
                </Box>
              </div>
            </Box>
          </Stack>
          <Box>
            <Typography color="var(--grey-400)">
              Replacement Issuance Info
            </Typography>
            <div className={styles.replacementInfo}>
              <Stack direction="row" justifyContent="space-between">
                <Box width="340px">
                  <Typography color="var(--black-900)">
                    Original Issuance Number
                  </Typography>
                  <TextField
                    label=" Issuance Number"
                    variant="standard"
                    disabled
                    sx={{ marginTop: '-12px !important' }}
                  />
                  <Typography mt={2}>Requested By</Typography>
                  <TextField
                    label="Full Name"
                    variant="standard"
                    disabled
                    sx={{ marginTop: '-12px !important' }}
                  />
                </Box>
                <Box width="340px">
                  <Typography color="var(--black-900)">
                    Replaced Issuance Number
                  </Typography>
                  <TextField
                    label="Issuance Number"
                    variant="standard"
                    disabled
                    sx={{ marginTop: '-12px !important' }}
                  />
                  <Typography mt={2}>Approved By</Typography>
                  <TextField
                    label="Full Name"
                    variant="standard"
                    disabled
                    sx={{ marginTop: '-12px !important' }}
                  />
                  <TextField
                    label="Date"
                    variant="standard"
                    disabled
                    sx={{ marginTop: '-12px !important' }}
                  />
                </Box>
              </Stack>
            </div>
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          onClick={handlePrint}
          startIcon={<PrintIcon />}
        >
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewDetailsDialog;
