import React from 'react';
import styles from './index.module.css';
import {
  Button,
  Container,
  FormGroup,
  FormLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Initiate from './Initiate';
import Reinitiate from './Reinitiate';

export default function Application() {
  return (
    <div className={styles.application}>
      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        Application Registration
      </Typography>
      <div className={styles.formContainer}>
        <Stack direction="row" justifyContent="center" spacing={12}>
          <Container component={Paper} className={styles.formSection}>
            <Initiate />
          </Container>

          <Container component={Paper} className={styles.formSection}>
            <Reinitiate />
          </Container>
        </Stack>
      </div>
    </div>
  );
}
