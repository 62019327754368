import React from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import styles from './index.module.css';
import MultiStepper from '../../components/MultiStepper';

export default function ApplicationContainer() {
  const isCasePage = useMatch(
    '/dashboard/cp-import/caseCreation/:caseId/:controlId'
  );
  const isClearancePage = useMatch(
    '/dashboard/cp-import/clearance/:caseId/:controlId'
  );

  const isImportPage = useMatch(
    '/dashboard/cp-import/import/:caseId/:controlId'
  );
  const isVerifiedDocument = useMatch(
    '/dashboard/cp-import/verifieddocument/:caseId/:controlId'
  );
  const isCompletionPage = useMatch(
    '/dashboard/cp-import/completion/:caseId/:controlId'
  );

  const stages = [
    {
      title: 'Clearance/Case Creation',
      done: isCasePage || isClearancePage,
    },
    {
      title: 'Import',
      done: isImportPage,
    },
    {
      title: 'VerifiedDocument',
      done: isVerifiedDocument,
    },
    {
      title: 'Completion',
      done: isCompletionPage,
    },
  ];
  return (
    <div className={styles.appContainer}>
      <div className={styles.multiStepBar}>
        <MultiStepper steps={stages} />
      </div>
      <div className={styles.formContent}>
        <Outlet />
      </div>
    </div>
  );
}
