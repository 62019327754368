import { useEffect } from 'react';

export default function useMount(effect) {
  useEffect(() => {
    const cleanup = effect();
    return () => {
      cleanup && cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
