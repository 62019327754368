import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useMount from '../../hooks/useMount';
import { FETCH_NAV_OPTIONS } from '../../store/types/navTypes';
import { Outlet } from 'react-router-dom';

function DashboardContainer(props) {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch({
      type: FETCH_NAV_OPTIONS,
      payload: { caseId: '', pageType: 'D' },
    });
  });

  return <Outlet />;
}

DashboardContainer.propTypes = {};

export default DashboardContainer;
