import dayjs from 'dayjs';

export const manualSearhPayload = (data) => {
  const { caseId, agencyName = '', individualId } = data;
  return {
    agencyCd: null,
    individualId: individualId,
    page: '1',
    size: '50',
  };
};

export const noticeInfoPayload = (data) => {
  const { caseId, agencyName = '', individualId } = data;
  return {
    caseId: caseId,
    individualId: individualId,
    templateId: null,
  };
};

export const manualTemplatePayload = (data) => {
  const {
    line1,
    caseName,
    line2,
    caseRecord,
    line3,
    mailDate,
    caseSchlName,
    caseNumber,
    parentName,
    requestedDate,
    childName,
    dob,
    ssn,
    parentSign,
    signatureDate,
    signNumber,
    nameChild,
    makingPeriod,
    daysEnrolled,
    daysAbsent,
    gradeLevel,
    graduationDate,
    disenrollmentDate,
    improveAtten,
    initialContact,
    calendarMonth,
    enrolledDays,
    absentDays,
    studentNumber,
    studentAddress,
    name1,
    relationship1,
    number1,
    name2,
    relationship2,
    number2,
    schoolOfficialSign,
    stamp,
    title,
    number,
    telephoneNumb,
    caseId,
    individualId,
    recordId,
    programName,
  } = data;

  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: dayjs(new Date()).format('YYYY-MM-DD'),
    templateCd: 7276,
    agencyCd: 'DHS',
    recordId: recordId,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: dayjs(new Date()).format('YYYY-MM-DD'),
      templateCd: 7276,
      agencyCd: 'DHS',
      recordId: recordId,
      ldssName: caseName,
      ldssStrtAddr1: line1,
      ldssCity: line2,
      ldssState: line3,
      ldssZipcode: line3,
      caseNumber: caseNumber,
      applicationDate: requestedDate,
      applicationDueDate: '',
      caseStrtAddr1: line1,
      caseStrtCity: line2,
      caseStrtState: line3,
      caseZipCode: line3,
      hohName: parentName,
      childName: childName,
      dateOfBirth: dob,
      ssn: ssn,
      parentSignature: parentSign,
      signatureDate: signatureDate,
      tNumber: signNumber,
      markPeriod: makingPeriod,
      noDaysEnrolled: daysEnrolled,
      noDaysAbsent: daysAbsent,
      gradeLevel: gradeLevel,
      expectedGraduation: graduationDate,
      disenrollmentDate: disenrollmentDate,
      improveAttd: improveAtten,
      dateOfContact: initialContact,
      calendarMonth: calendarMonth,
      daysEnrolled: enrolledDays,
      daysAbsent: absentDays,
      telNumber: studentNumber,
      hAddress: studentAddress,
      p1Name: name1,
      p1Relationship: relationship1,
      p1Number: number1,
      p2Name: name2,
      p2Relationship: relationship2,
      p2Number: number2,
      signSchoolOffical: schoolOfficialSign,
      stamp: stamp,
      title: title,
      titleNumber: number,
      title2Number: telephoneNumb,
      titleLdssName: caseName,
      titleLdssAddr1: line1,
      titleLdssAddr2: line2,
      titleCaseName: line3,
      titleCaseNumber: caseNumber,
      titleMailDate: mailDate,
    }),
  };
};

export const medicalReportTemp = (data) => {
  const {
    socialService,
    localDsitrict,
    date,
    caseManager,
    phoneNumber,
    customerName,
    maId,
    patientName,
    dob,
    address,
    firstVisit,
    lastVisit,
    clinicalDiag,
    substanceIssue,
    substanceAbuse,
    impairment,
    impairmentPrevent,
    expecetedImpairment,
    impairmentMore,
    fromDate,
    toDate,
    comments,
    signature,
    printName,
    title,
    license,
    healthCare,
    signatureDate,
    phone,
    caseId,
    individualId,
    programName,
  } = data;

  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: date,
    templateCd: 7275,
    agencyCd: 'DHS',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: date,
      templateCd: 7275,
      agencyCd: 'DHS',
      recordId: 0,
      ldssName: socialService,
      applicationDate: date,
      caseManagerName: caseManager,
      ldssFaxNumber: phoneNumber,
      firstName: patientName,
      lastName: patientName,
      maId: maId,
      dateOfBirth: dob,
      caseStrtAddr1: address,
      caseStrtAddr2: address,
      caseStrtCity: address,
      caseStrtState: address,
      firstVisit: firstVisit,
      lastVisit: lastVisit,
      diagnosis: clinicalDiag,
      substanceAbuseIssue: substanceIssue,
      otherMedicalConditions: substanceAbuse,
      physicalImpairment: impairment,
      impairmentPrevent: impairmentPrevent,
      impairment3Month: expecetedImpairment,
      impairment12Month: impairmentMore,
      fromTime: fromDate,
      toTime: toDate,
      clarificationsComments: comments,
      signature: signature,
      printName: printName,
      title: title,
      license: license,
      nameAddr: healthCare,
      date: signatureDate,
      phoneNo: phone,
      titleOrganizationName: socialService,
      titleLdssDoName: socialService,
      titleApplicationDate: date,
      titleCaseManagerName: caseManager,
      titleCaseManagerTelNmbr: phoneNumber,
      titleCustomerName: customerName,
      titleMaId: maId,
      sectionNameOfPatient: patientName,
      sectionDateOfBirth: dob,
      sectionAddr: address,
    }),
  };
};

export const familyInvestPayload = (data) => {
  const {
    name,
    dob,
    participantSign,
    participantSignDate,
    improveAtten,
    street,
    ssn,
    organization,
    cityState,
    supervisorName,
    supervisorNumber,
    hours,
    week,
    supervisorSign,
    signDate,
    improveAtten1,
    street1,
    organization1,
    cityState1,
    supervisorName1,
    supervisorNumber1,
    hours1,
    week1,
    supervisorSign1,
    signDate1,
    caseId,
    individualId,
    programName,
  } = data;

  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: participantSignDate,
    templateCd: 7274,
    agencyCd: 'DHS',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: participantSignDate,
      templateCd: 7274,
      agencyCd: 'DHS',
      recordId: 0,
      firstName: name,
      lastName: name,
      dateOfBirth: dob,
      ssn: ssn,
      participantSignature: participantSign,
      signDate: participantSignDate,
      activityType1: improveAtten,
      nameOrganz1: organization,
      streedAddr1: street,
      cityStateZip1: cityState,
      supervisorName1: supervisorName,
      supervisorPhone1: supervisorNumber,
      hours1: hours,
      daysPerWeek1: week,
      supervisorSignature1: supervisorSign,
      supervisorDate1: signDate,
      activityType2: improveAtten1,
      nameOrganz2: organization1,
      streedAddr2: street1,
      cityStateZip2: cityState1,
      supervisorName2: supervisorName1,
      supervisorPhone2: supervisorNumber1,
      hours2: hours1,
      daysPerWeek2: week1,
      supervisorSignature2: supervisorSign1,
      supervisorDate2: signDate1,
      titleName: name,
      titleDob: dob,
      titleSsn4d: ssn,
    }),
  };
};

export const familyMedical = (data) => {
  const {
    socialService,
    localDsitrict,
    dateOffice,
    caseManager,
    phoneNumber,
    customerName,
    customerId,
    patientName,
    dob,
    address,
    firstVisit,
    lastVisit,
    symptoms,
    substanceAbuse,
    medicalCondition,
    impairment,
    independently,
    individualMental,
    mentalIllness,
    learningDisabilities,
    violentBehaviours,
    impairment12,
    fromDate,
    toDate,
    sign,
    printName,
    title,
    license,
    helathAdd,
    date,
    phone,
    caseId,
    individualId,
    programName,
  } = data;
  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: dateOffice,
    templateCd: 7273,
    agencyCd: 'DHS',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: dateOffice,
      templateCd: 7273,
      agencyCd: 'DHS',
      recordId: 0,
      ldssName: socialService,
      applicationDate: dateOffice,
      caseManagerName: caseManager,
      ldssFaxNumber: phoneNumber,
      firstName: patientName,
      lastName: patientName,
      maId: customerId,
      dateOfBirth: dob,
      caseStrtAddr1: localDsitrict,
      caseStrtAddr2: localDsitrict,
      caseStrtCity: localDsitrict,
      caseStrtState: localDsitrict,
      firstVisit: firstVisit,
      lastVisit: lastVisit,
      presentingSymtoms: symptoms,
      sitUnknown: '',
      sitNoRestr: '',
      sitNever: '',
      sit1h: '',
      sit2h: '',
      sit3h: '',
      sit4h: '',
      sit5h: '',
      sit6h: '',
      sit7h: '',
      sit8h: '',
      standUnknown: '',
      standNoRestr: '',
      standNever: '',
      stand1h: '',
      stand2h: '',
      stand3h: '',
      stand4h: '',
      stand5h: '',
      stand6h: '',
      stand7h: '',
      stand8h: '',
      walkUnknown: '',
      walkNoRestr: '',
      walkNever: '',
      walk1h: '',
      walk2h: '',
      walk3h: '',
      walk4h: '',
      walk5h: '',
      walk6h: '',
      walk7h: '',
      walk8h: '',
      climbUnknown: '',
      climbNoRestr: '',
      climbNever: '',
      climb1h: '',
      climb2h: '',
      climb3h: '',
      climb4h: '',
      climb5h: '',
      climb6h: '',
      climb7h: '',
      climb8h: '',
      bendUnknown: '',
      bendNoRestr: '',
      bendNever: '',
      bend1h: '',
      bend2h: '',
      bend3h: '',
      bend4h: '',
      bend5h: '',
      bend6h: '',
      bend7h: '',
      bend8h: '',
      squatUnknown: '',
      squatNoRestr: '',
      squatNever: '',
      squat1h: '',
      squat2h: '',
      squat3h: '',
      squat4h: '',
      squat5h: '',
      squat6h: '',
      squat7h: '',
      squat8h: '',
      reachUnknown: '',
      reachNoRestr: '',
      reachNever: '',
      reach1h: '',
      reach2h: '',
      reach3h: '',
      reach4h: '',
      reach5h: '',
      reach6h: '',
      reach7h: '',
      reach8h: '',
      substanceAbuseIssue: substanceAbuse,
      addiSubAbuse: medicalCondition,
      visualImpairment: impairment,
      continuousBasis: independently,
      mentalIll: individualMental,
      metalIllPrev: mentalIllness,
      learningDisab: learningDisabilities,
      violentBehv: violentBehaviours,
      last12Mon: impairment12,
      fromDate: fromDate,
      toDate: toDate,
      death: '',
      inablWrk: '',
      inHomeFull: '',
      comments: '',
      signature: sign,
      printName: printName,
      title: title,
      license: license,
      nameAddr: helathAdd,
      date: date,
      phoneNo: phone,
      titleOrganizationName: socialService,
      titleLdssName: socialService,
      titleApplicationDate: dateOffice,
      titleCaseManagerName: caseManager,
      titleCaseManagerTelNmbr: phoneNumber,
      titleCustomerName: customerName,
      titleMaId: customerId,
      sectionNameOfPatient: patientName,
      sectionDateOfBirth: dob,
      sectionAddr: address,
    }),
  };
};

export const disabilityForm = (data) => {
  const {
    representative,
    payee,
    representativePayee,
    payeeDate,
    ldssMangaerSign,
    ldssDate,
    facility,
    facilityAddress,
    telephoneNumber,
    serviceEligibilty,
    livingArrangemnet,
    levelCare,
    placementDate,
    mailCheck,
    address,
    patientName,
    dob,
    patientAddress,
    speciality,
    phone,
    firstVisit,
    lastVisit,
    symptoms,
    diagnosis,
    onsetDate,
    diagnosis1,
    onsetDate1,
    hearingLimitations,
    speakingLimitations,
    patientIllness,
    mentalIllnessPatient,
    violentBehaviors,
    listIfYes,
    od,
    os,
    va,
    od1,
    os1,
    va1,
    stable,
    deteriorating,
    improvement,
    uncertain,
    otherRecom,
    additional,
    sign,
    printName,
    title,
    telephone,
    license,
    maProvider,
    signDate,
    caseId,
    individualId,
    programName,
  } = data;
  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: payeeDate,
    templateCd: 7281,
    agencyCd: 'DHS',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: payeeDate,
      templateCd: 7281,
      agencyCd: 'DHS',
      recordId: 0,
      firstName: patientName,
      lastName: patientName,
      maId: maProvider,
      facilityName: facility,
      address: facilityAddress,
      telephoneNo: telephoneNumber,
      otherRecommendations: otherRecom,
      ldssName: facility,
      ldssStrtAddr1: facilityAddress,
      ldssStrtAddr2: facilityAddress,
      ldssCity: facilityAddress,
      ldssState: facilityAddress,
      ldssStaldssZipcodete: facilityAddress,
      ldssFaxNumber: telephoneNumber,
      payee: payee,
      payeeDt: payeeDate,
      caseManagerSign: ldssMangaerSign,
      caseManagerDt: ldssDate,
      established: serviceEligibilty,
      livingArrangment: livingArrangemnet,
      levelCare: levelCare,
      placeDt: placementDate,
      mailCheckTo: mailCheck,
      addr: address,
      nameOfPatient: patientName,
      s3dob: dob,
      physicianName: patientName,
      physicianAddr: patientAddress,
      specialty: speciality,
      specialtyPhone: phone,
      s3firstVisit: firstVisit,
      s3lastVisit: lastVisit,
      presentingSymptoms: symptoms,
      diagnosis1: diagnosis,
      s3OnsetDt1: onsetDate,
      diagnosis2: diagnosis1,
      s3OnsetDt2: onsetDate1,
      hearingLimitations: hearingLimitations,
      hearingLimitationsLevel: hearingLimitations,
      speakingLimitations: speakingLimitations,
      speakingLimitationsLevel: speakingLimitations,
      mentalIllness: patientIllness,
      mentalIllnessComments: mentalIllnessPatient,
      violentBehaviors: violentBehaviors,
      violentBehaviorsList: listIfYes,
      visualFieldOd: od,
      visualFieldOs: os,
      visualFieldVa: va,
      afterCrctOd: od1,
      afterCrctOs: os1,
      afterCrctVa: va1,
      stable: stable,
      deteriorating: deteriorating,
      capableImprovement: improvement,
      uncertain: uncertain,
      additionalComments: additional,
      s4signature: sign,
      s4printName: printName,
      s4Title: title,
      s4Telephone: telephone,
      s4license: license,
      maProvider: maProvider,
      s4maDt: signDate,
      repPayee: representativePayee,
      sectionMaId: maProvider,
    }),
  };
};

export const dhaFiaPayload = (data) => {
  const {
    department,
    depart,
    montogomery,
    initialPayment,
    eligiblePayment,
    customerId,
    auNumb,
    category,
    ssn,
    district,
    federalCode,
    countyFedCode,
    applicantName,
    address,
    signRecepient,
    date,
    signStateRecepient,
    dateState,
    telephoneNum,
    grCode,
    caseId,
    individualId,
    recordId,
    programName,
  } = data;
  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: date,
    templateCd: 10464,
    agencyCd: 'DHS',
    recordId: recordId,
    jsonTemplateData: JSON.stringify({
      defaultCounty: department,
      montgomeryCounty: montogomery,
      initialPymnt: initialPayment,
      postELiPymnt: eligiblePayment,
      custIdNum: customerId,
      auNum: auNumb,
      category: category,
      soSecNum: ssn,
      distTerritory: district,
      fedCode: federalCode,
      countyFedCode: countyFedCode,
      applicantName: applicantName,
      address: address,
      signOfRecipient: signRecepient,
      recipientDt: date,
      signOfStateRecipient: signStateRecepient,
      stateTelephoneNum: telephoneNum,
      stateRecipientDt: dateState,
      grCode: grCode,
      sectionOrganizationName: 'DHS',
    }),
  };
};

export const substanceAbuse = (data) => {
  const {
    date,
    dssOffice,
    maNo,
    hoh,
    auNo,
    applicantName,
    ssNo,
    address,
    telephoneNo,
    zip,
    felony,
    dob,
    mco,
    ldssManager,
    managerTelephone,
    failedScreening,
    refusedScreening,
    screeningNegative,
    failedtoSign,
    screeningPositive,
    substanceAbusePrblm,
    assessmentTreat,
    treatmentTo,
    onDate,
    failedAssessment,
    assessmentFailed,
    currentTreatment,
    treatmentCurrently,
    verifiedby,
    refferalMade,
    refferalDate,
    comments,
    comt,
    drugTesting,
    drugTo,
    drugOn,
    results,
    positive,
    negative,
    negativeDate,
    noShow,
    additionSpecialist,
    specialistNumb,
    caseId,
    individualId,
    programName,
  } = data;
  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: dayjs(new Date()).format('YYYY-MM-DD'),
    templateCd: '10463',
    agencyCd: 'DHS',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      applicationDate: dayjs(new Date()).format('YYYY-MM-DD'),
      dssOffice: dssOffice,
      maNumber: maNo,
      hohName: hoh,
      auNumber: auNo,
      recipientName: applicantName,
      ssNumber: ssNo,
      address: address,
      telephone: telephoneNo,
      zip: zip,
      felonyDrugConvivtion: felony,
      dob: dob,
      mco: mco,
      ldssCaseManager: ldssManager,
      managerTelephone: managerTelephone,
      checkbox1: failedScreening,
      checkbox2: refusedScreening,
      checkbox3: screeningNegative,
      checkbox4: failedtoSign,
      checkbox5: screeningPositive,
      checkbox6: substanceAbusePrblm,
      checkbox7: assessmentTreat,
      referedTo: treatmentTo,
      referredOn: onDate,
      checkbox8: failedAssessment,
      referredTreatment: assessmentFailed,
      checkbox9: currentTreatment,
      treatmentAt: treatmentCurrently,
      treatmentVerifiedBy: verifiedby,
      checkbox10: refferalMade,
      referralMadeOn: refferalDate,
      checkbox11: comments,
      comments: comt,
      participantSignature: '',
      checkbox12: drugTesting,
      testingTo: drugTo,
      testingOn: drugOn,
      checkbox13: results,
      positive: positive,
      negative: negative,
      dateForNegative: negativeDate,
      noShow: noShow,
      additionSpecialist: additionSpecialist,
      phone: specialistNumb,
    }),
  };
};

export const alcoholFormPayload = (data) => {
  const {
    hohName,
    dssOffice,
    maNumb,
    auId,
    ssNumb,
    printName,
    signature,
    date,
    caseId,
    individualId,
    programName,
  } = data;
  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: dayjs(new Date()).format('YYYY-MM-DD'),
    templateCd: 10458,
    agencyCd: 'DHS',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: dayjs(new Date()).format('YYYY-MM-DD'),
      templateCd: 10458,
      agencyCd: 'DHS',
      recordId: 0,
      hoh: hohName,
      dssOffice: dssOffice,
      maNum: maNumb,
      auId: auId,
      ssNum: ssNumb,
      printName: printName,
      sign: signature,
      signDate: date,
      facilityName: '',
      facilityAddress: '',
      facilityTel: '',
      repName: '',
      repTel: '',
      repAddress: '',
      caseManagerName: 'Amanda Bates',
      caseManagerTelNmbr: '',
    }),
  };
};

export const preventionHealthPayload = (data) => {
  const {
    ldssDepart,
    caseName,
    street,
    caseRecord,
    city,
    mailDate,
    phone,
    caseNamePrim,
    caseNumPrim,
    minorParent,
    healthDate,
    parentSign,
    signDate,
    telephoneNumb,
    childName1,
    dob1,
    date1,
    childName2,
    dob2,
    date2,
    childName3,
    dob3,
    date3,
    childName4,
    dob4,
    date4,
    providerSign,
    title,
    telephone,
    date,
    caseId,
    individualId,
    programName,
  } = data;
  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: mailDate,
    templateCd: '7277',
    agencyCd: 'DHS',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: mailDate,
      templateCd: '7277',
      agencyCd: 'DHS',
      recordId: 0,
      ldssName: ldssDepart,
      ldssStrtAddr1: street,
      ldssCity: city,
      ldssState: city,
      ldssZipcode: city,
      ldssFaxNumber: '',
      caseName: caseName,
      caseNumber: caseRecord,
      applicationDate: mailDate,
      firstName: '',
      lastName: '',
      applicationDueDate: healthDate,
      parentSigntr: parentSign,
      signDate: signDate,
      telephone: telephoneNumb,
      childName1: childName1,
      dob1: dob1,
      examDt1: date1,
      childName2: childName2,
      dob2: dob2,
      examDt2: date2,
      childName3: childName3,
      dob3: dob3,
      examDt3: date3,
      childName4: childName4,
      dob4: dob4,
      examDt4: date4,
      authSign: providerSign,
      title: title,
      authTel: telephone,
      authDate: date,
      titleLdssDoName: ldssDepart,
      titleLdssAddr1: street,
      titleLdssAddr2: city,
      titleCaseManagerTelNmbr: phone,
      titleCaseName: caseName,
      titleCaseNumber: caseRecord,
      titleMailDate: mailDate,
      sectionCaseName: caseNamePrim,
      sectionCaseNumber: caseNumPrim,
      sectionMinorParentName: minorParent,
      sectionDueDate: healthDate,
    }),
  };
};

export const hardshipExempPayload = (data) => {
  const {
    date,
    maNo,
    name,
    address,
    address1,
    myName,
    amount,
    reason1,
    reason2,
    reason3,
    reason4,
    reason5,
    reason6,
    reason7,
    reason8,
    socialSecurity,
    earnigs,
    vaNum,
    other1,
    otherAmnt1,
    other2,
    otherAmnt2,
    grossIncome,
    mortage,
    medicalExp,
    food,
    electric,
    medicalGrossIncome,
    signature,
    signDate,
    caseId,
    individualId,
    programName,
  } = data;
  return {
    benefitCaseId: caseId,
    individualId: individualId,
    programCd: programName,
    triggerDate: date,
    templateCd: '7125',
    agencyCd: 'MDH',
    recordId: 0,
    jsonTemplateData: JSON.stringify({
      benefitCaseId: caseId,
      individualId: individualId,
      programCd: programName,
      triggerDate: date,
      templateCd: 'MDH',
      agencyCd: 'MDH',
      recordId: 0,
      applicationDate: date ?? null,
      maNo: maNo,
      ldssName: name,
      ldssAddr1: address,
      ldssAddr2: address1,
      hohName: myName,
      medAmnt: amount,
      reason1: reason1,
      reason2: reason2,
      reason3: reason3,
      reason4: reason4,
      reason5: reason5,
      reason6: reason6,
      reason7: reason7,
      reason8: reason8,
      soSecIncm: socialSecurity,
      earningIncm: earnigs,
      vaIncm: vaNum,
      otherType1: other1,
      otherIncm1: otherAmnt1,
      otherType2: other2,
      otherIncm2: otherAmnt2,
      totalgrossIncm: grossIncome,
      mortgageRentExp: mortage,
      medExp: medicalExp,
      foodExp: food,
      electricGasExp: electric,
      totalgrossExp: medicalGrossIncome,
      signtre: signature,
      signdDate: signDate,
    }),
  };
};

export const editTemOpenPayload = (data) => {
  const { caseId, individualId, templateId } = data;
  return {
    caseId: caseId,
    individualId: individualId,
    templateId: templateId,
  };
};

export const pdfPayload = (data) => {
  return {
    manualdataid: data,
  };
};
