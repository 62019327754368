import {
  Button,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import Field from '../../../../components/Field';
import { useFormik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as Eye } from '../../../../assets/images/eyes.svg';
import { postDisabilityForm } from '../../../../utils/services/ManualNotices/apiRequest';
import useLoader from '../../../../hooks/useLoader';
import usePageNav from '../../../../hooks/usePageNav';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import { maxLength, number, onlyAlphabet } from '../../../../utils/normalizers';
import InputMask from 'react-input-mask';

export default function DisabilityForm({
  caseId,
  toggleClose,
  individualId,
  editData,
  postSearchNotice,
  programName,
}) {
  console.log(editData, 'edit');
  const data = editData?.jsonTemplateData;
  let dataValue = {};

  try {
    dataValue = JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON data:', error);
  }
  const [showLoader, hideLoader] = useLoader();

  const formik = useFormik({
    initialValues: {
      representative: '',
      payee: dataValue?.payee ?? '',
      representativePayee: dataValue?.repPayee ?? '',
      payeeDate: dataValue?.payeeDt ?? '',
      ldssMangaerSign: dataValue?.caseManagerSign ?? '',
      ldssDate: dataValue?.ldssDate ?? '',
      facility: dataValue?.facilityName ?? '',
      facilityAddress: dataValue?.address ?? '',
      telephoneNumber: dataValue?.telephoneNo ?? '',
      serviceEligibilty: dataValue?.established ?? '',
      livingArrangemnet: dataValue?.livingArrangment ?? '',
      levelCare: dataValue?.levelCare ?? '',
      placementDate: dataValue?.placeDt ?? '',
      mailCheck: dataValue?.mailCheckTo ?? '',
      address: dataValue?.addr ?? '',
      patientName: dataValue?.nameOfPatient ?? '',
      dob: dataValue?.s3dob ?? '',
      patientAddress: dataValue?.physicianAddr ?? '',
      speciality: dataValue?.specialty ?? '',
      phone: dataValue?.specialtyPhone ?? '',
      firstVisit: dataValue?.s3firstVisit ?? '',
      lastVisit: dataValue?.s3lastVisit ?? '',
      symptoms: dataValue?.presentingSymptoms ?? '',
      diagnosis: dataValue?.diagnosis1 ?? '',
      onsetDate: dataValue?.s3OnsetDt1 ?? '',
      diagnosis1: dataValue?.diagnosis2 ?? '',
      onsetDate1: dataValue?.s3OnsetDt2 ?? '',
      hearingLimitations: dataValue?.hearingLimitations ?? '',
      speakingLimitations: dataValue?.speakingLimitations ?? '',
      patientIllness: dataValue?.mentalIllness ?? '',
      mentalIllnessPatient: dataValue?.mentalIllnessComments ?? '',
      violentBehaviors: dataValue?.violentBehaviors ?? '',
      listIfYes: dataValue?.violentBehaviorsList ?? '',
      od: dataValue?.visualFieldOd ?? '',
      os: dataValue?.visualFieldOs ?? '',
      va: dataValue?.visualFieldVa ?? '',
      od1: dataValue?.afterCrctOd ?? '',
      os1: dataValue?.afterCrctOs ?? '',
      va1: dataValue?.afterCrctVa ?? '',
      stable: dataValue?.stable ?? '',
      deteriorating: dataValue?.deteriorating ?? '',
      improvement: dataValue?.capableImprovement ?? '',
      uncertain: dataValue?.uncertain ?? '',
      otherRecom: dataValue?.otherRecommendations ?? '',
      additional: dataValue?.additionalComments ?? '',
      sign: dataValue?.s4signature ?? '',
      printName: dataValue?.s4printName ?? '',
      title: dataValue?.s4Title ?? '',
      telephone: dataValue?.s4Telephone ?? '',
      license: dataValue?.s4license ?? '',
      maProvider: dataValue?.maProvider ?? '',
      signDate: dataValue?.s4maDt ?? '',
      caseId,
      individualId,
      programName,
    },
    onSubmit: (values) => {
      disabilityInfo(values);
    },
  });

  const disabilityInfo = async (data) => {
    try {
      showLoader();
      const res = await postDisabilityForm(data, caseId);
      if (res.status === 200) {
        showSuccessToast('Succesfully Created');
        postSearchNotice();
        toggleClose();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      <Grid ml="1rem" mr="1rem" mt="1rem" mb="1rem" border="1px solid #555">
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h3"
            >
              VERMONT
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h3"
            >
              DEPARTMENT OF HUMAN SERVICES
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h3"
            >
              FAMILY INVESTMENT ADMINISTRATION
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h4grey"
            >
              PUBLIC ASSISTANCE TO ADULTS DISABILITY CERTIFICATION FORM
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
              variant="h4grey"
            >
              SECTION I REPERSENTATIVE PAYEE'S AGREEMENT
            </Typography>
          </Grid>
        </Grid>
        <Grid ml="3rem" mr="3rem">
          <Grid container spacing={2} mt="0.2rem">
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                In becoming a Representative Payee for
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="representative"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="payee" formik={formik} normalizers={[maxLength(30)]}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                I understand and agree to the following:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                1. To use the assistance payment to obtain shelter, food,
                clothing, etc. for the customer.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                2. To provide some accounting so that the local department can
                know how the money was used.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                3. To the best of my ability, assist the customer in meeting
                daily needs; help with ongoing problems and to maintain a close
                contact with the customer
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                4. To report to the local department any change in the financial
                circumstances of the customer of the customer of which I am
                aware; or any change in my relationship to the customer.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="representativePayee" formik={formik}>
                <TextField variant="standard" label="Representative Payee" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="payeeDate" formik={formik} isDate>
                <DatePicker
                  label="Date"
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="ldssMangaerSign"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField
                  variant="standard"
                  label="LDSS Case Manager's Signature"
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field name="ldssDate" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  label="Date"
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12}>
              <Typography
                variant="h5grey"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                SECTION II REHABILITATIVE RESIDENCE CERTIFICATION
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5grey"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Rehabilitative Residence Completes
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                The above named client has been approved for service and will be
                placed in Rehabilitative Residence housing. A public assistance
                application for the personal needs allowance will be submitted.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Facility:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="facility"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Address:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="facilityAddress"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Telephone No:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="telephoneNumber"
                formik={formik}
                normalizers={[maxLength(10), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.number}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Service Eligibility has been established for:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="serviceEligibilty" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Is a protective living arrangement necessary?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="livingArrangemnet" formik={formik}>
                <RadioGroup
                  name="livingArrangemnet"
                  row
                  {...formik.getFieldProps('livingArrangemnet')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.livingArrangemnet}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Level of Care:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="levelCare" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Planned Placement Date:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="placementDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Mail Check to:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="mailCheck" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Address:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="address"
                formik={formik}
                normalizers={[maxLength(50)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12}>
              <Typography
                variant="h5grey"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                SECTION III MEDICAL REPORT
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5grey"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                The information provided on this form may be used to determine
                eligibility for <br></br>federal and state programs using Social
                Security disability criteria.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">
                Please Print or Type<br></br>PATIENT INFORMATION
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Name of Patient:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="patientName"
                formik={formik}
                normalizers={[maxLength(30), onlyAlphabet]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date of Birth</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="dob" formik={formik} isDate>
                <DatePicker
                  disableFuture
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Address:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="patientAddress"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Speciality:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="speciality"
                formik={formik}
                normalizers={[maxLength(30)]}
              >
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Phone:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="phone" formik={formik}>
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.phone}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                Date of Examination: First Visit:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="firstVisit" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Last Visit:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="lastVisit" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Presenting Symptoms:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="symptoms" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Diagnosis:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="diagnosis" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Onset Date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="onsetDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Diagnosis:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="diagnosis1" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Onset Date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="onsetDate1" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Hearing Limitations</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field name="hearingLimitations" formik={formik}>
                <RadioGroup
                  name="hearingLimitations"
                  row
                  {...formik.getFieldProps('hearingLimitations')}
                  //   sx={{ marginLeft: 2 }}
                  value={formik.values.hearingLimitations}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={'M'}
                    control={<Radio />}
                    label="Minimal"
                  />
                  <FormControlLabel
                    value={'MO'}
                    control={<Radio />}
                    label="Moderate"
                  />
                  <FormControlLabel
                    value={'E'}
                    control={<Radio />}
                    label="Extreme"
                  />
                  <FormControlLabel
                    value={'S'}
                    control={<Radio />}
                    label="Severe"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Speaking Limitations</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field name="speakingLimitations" formik={formik}>
                <RadioGroup
                  name="speakingLimitations"
                  row
                  {...formik.getFieldProps('speakingLimitations')}
                  //   sx={{ marginLeft: 2 }}
                  value={formik.values.speakingLimitations}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={'M'}
                    control={<Radio />}
                    label="Minimal"
                  />
                  <FormControlLabel
                    value={'MO'}
                    control={<Radio />}
                    label="Moderate"
                  />
                  <FormControlLabel
                    value={'E'}
                    control={<Radio />}
                    label="Extreme"
                  />
                  <FormControlLabel
                    value={'S'}
                    control={<Radio />}
                    label="Severe"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4grey"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Mental Health{' '}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                Does the patient suffer from mental illness? Does the patient
                suffer from mental illness?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="patientIllness" formik={formik}>
                <RadioGroup
                  name="patientIllness"
                  row
                  {...formik.getFieldProps('patientIllness')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.patientIllness}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={8}>
              <Field name="mentalIllnessPatient" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                To the best of your knowledge does the patient exhibit any
                violent behaviors?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="violentBehaviors" formik={formik}>
                <RadioGroup
                  name="violentBehaviors"
                  row
                  {...formik.getFieldProps('violentBehaviors')}
                  sx={{ marginLeft: 2 }}
                  value={formik.values.violentBehaviors}
                >
                  <FormControlLabel
                    value={'Y'}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={'N'}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">If yes, list below</Typography>
            </Grid>
            <Grid item xs={10}>
              <Field name="listIfYes" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="1rem">
            <Grid item xs={12}>
              <Typography variant="h5grey">
                SECTION IV VISUAL LIMITATIONS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Visual Field:</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">OD</Typography>
            </Grid>
            <Grid item xs={2}>
              <Field name="od" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">OS</Typography>
            </Grid>
            <Grid item xs={2}>
              <Field name="os" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">VA</Typography>
            </Grid>
            <Grid item xs={2}>
              <Field name="va" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">(After Corrections)</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">OD</Typography>
            </Grid>
            <Grid item xs={2}>
              <Field name="od1" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">OS</Typography>
            </Grid>
            <Grid item xs={2}>
              <Field name="os1" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">VA</Typography>
            </Grid>
            <Grid item xs={2}>
              <Field name="va1" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                PROGNOSIS AND RECOMMENDATIONS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Patient’s vision impairment LEVEL (PLEASE INDICATE BELOW)
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Stable</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="stable" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Deteriorating</Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="deteriorating" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2.5}>
              <Typography variant="subtitle1">
                Capable of Improvemnet
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Field name="improvement" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1">Uncertain</Typography>
            </Grid>
            <Grid item xs={3}>
              <Field name="uncertain" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Other recommendations (e.g., special eye consultation, special
                medical examination, low-vision aide, mobility training,
                prostheses etc.; explain):
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  // display: 'flex',
                  // flexDirection: 'column',
                  // alignItems: 'center',
                  width: '2rem',
                }}
              >
                <Eye />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Field name="otherRecom" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Additinal Comments</Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="additional" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Signature:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="sign" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Print Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="printName" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Title:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="title" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Telephone:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name="telephone"
                formik={formik}
                normalizers={[maxLength(10), number]}
              >
                <InputMask mask="(999)-999-9999" maskChar={null}>
                  {(params) => (
                    <OutlinedInput
                      variant="outlined"
                      placeholder="(000)-000-0000"
                      value={formik.values.telephone}
                      {...params}
                    />
                  )}
                </InputMask>
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">
                License or Federal ID#:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="license" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">MA Provider #:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="maProvider" formik={formik}>
                <TextField variant="standard" />
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Date:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Field name="signDate" formik={formik} isDate>
                <DatePicker
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={12} mt="1rem" mb="1rem">
              <Typography variant="h5">
                DHS FIA 4350 DISABILITY CERTIFICATION VERIFICATION FORM
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" mt="1rem" spacing={2}>
        <Button variant="outlined" onClick={toggleClose}>
          Disacrd
        </Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
