import React from 'react';
import PropTypes from 'prop-types';
import EligibilityAccordion, { ElAccordionContent } from '..';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import Avatar from '../../../../../components/Avatar';
import TextContentGroup from '../../../../../components/TextContentGroup';
import { map } from 'lodash';
import { getFullName } from '../../../../../utils/helpers';
import TextContent from '../../../../../components/TextContent';

function IndividualEligibility({ agIndvEligResult, getLkpMatch, programName }) {
  const renderContent = (elibility) => {
    const {
      indFirstName,
      indLastName,
      programBeginDate,
      targetFirstName,
      participationStatusCd,
      resourceStatusCd,
      eligibilityStatusCd, // ? ind level or program level
      nonFinancialStatusCd,
      programName: indProgramName,
      financialStatusCd,
      abawdMonthStatus,
      abawdIndicator,
      rmpInd,
      catEligInd,
      penalties, //#486,
      agIndvEligReason,
    } = elibility ?? {};

    const name = getFullName(indFirstName, indLastName);
    const textContents = [
      {
        title: 'Program Begin Date',
        subTitle: programBeginDate,
        showIf: resourceStatusCd && eligibilityStatusCd === 'FL',
      },
      {
        title: 'Eligibility Status',
        subTitle: getLkpMatch('EligibilityStatusCode', eligibilityStatusCd),
      },
      {
        title: 'Non Financial Status',
        subTitle: getLkpMatch('EligibilityStatusCode', nonFinancialStatusCd),
      },
      {
        title: 'Financial Status',
        subTitle: getLkpMatch('EligibilityStatusCode', financialStatusCd),
        showIf: indProgramName === 'MA',
      },
      {
        title: 'Resource Status',
        subTitle: getLkpMatch('EligibilityStatusCode', resourceStatusCd),
        showIf: !!resourceStatusCd,
      },
      // {
      //   title: 'Participation Status',
      //   subTitle: getLkpMatch('FinancialResponsibility', participationStatusCd),
      //   showIf: participationStatusCd,
      // },
      {
        title: 'ABAWD Indicator',
        subTitle: getLkpMatch('YesNo', abawdIndicator),
        showIf: programName === 'FS' || programName === 'EF',
      },
      {
        title: 'ABAWD Month Status',
        subTitle: getLkpMatch('AbawdMonthStatus', abawdMonthStatus) ?? 'N/A',
        showIf: programName === 'FS' || programName === 'EF',
      },
      {
        title: 'RMP Indicator',
        subTitle: getLkpMatch('YesNo', rmpInd),
      },
      {
        title: 'Categorically Eligible',
        subTitle: catEligInd,
      },
      {
        title: 'Reasons',
        renderSubtitle() {
          if (!agIndvEligReason?.length) {
            return <Typography variant="body2">N/A</Typography>;
          }

          return map(agIndvEligReason, ({ reasonCod }) => (
            <Tooltip title={getLkpMatch('EligibilityReasonCode', reasonCod)}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '100%',
                }}
                variant="body2"
              >
                {getLkpMatch('EligibilityReasonCode', reasonCod)}
              </Typography>
            </Tooltip>
          ));
        },
      },
    ];

    return (
      <ElAccordionContent>
        <Stack direction="row">
          <Stack sx={{ p: '0.5rem 0 1rem 1rem' }} spacing={1}>
            <Stack minWidth="15rem">
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: 'max-content',
                }}
              >
                <Avatar name={name} />
                <Typography variant="subtitle2" ml={'0.5rem'}>
                  {name}
                </Typography>
              </Stack>
            </Stack>
            <TextContent
              title={{
                content: 'Participation Status',
                variant: 'subtitle2',
              }}
              subTitle={{
                content: getLkpMatch(
                  'FinancialResponsibility',
                  participationStatusCd
                ),
                variant: 'caption',
              }}
            />
          </Stack>
          <Box>
            <TextContentGroup col={4} single textContents={textContents} />
          </Box>
        </Stack>
      </ElAccordionContent>
    );
  };

  return (
    <EligibilityAccordion>
      {map(agIndvEligResult, (indBudget) => renderContent(indBudget))}
    </EligibilityAccordion>
  );
}

IndividualEligibility.propTypes = {};

export default IndividualEligibility;
