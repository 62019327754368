import { Paper } from '@mui/material';
import React from 'react';
import PageHeader from '../../components/PageHeader';
import RedeterminHistory from './ReDeterminationContainer';

function RedeterminationHistory() {
  return (
    <div>
      <PageHeader
        title="Redetermination History"
        showNavigations={false}
      ></PageHeader>
      <RedeterminHistory />
    </div>
  );
}

export default RedeterminationHistory;
