export const SUMMARY_DATA = '/eeapi/application-process/interview/{0}/summary';
export const GET_INCOME =
  '/eeapi/application-process/interview/{0}/financial-information/incomes';

// export const GET_HOUSE_MEMBERS =
//   '/eeapi/application-process/get-household-members/{0}';

export const GET_HHRELATIONSHIPS =
  '/eeapi/application-process/screening/{0}/household-relationship/{1}';

export const POST_RELATIONSHIP =
  '/eeapi/application-process/screening/{0}/household/{1}/relationship';

export const Get_INDIVIDUAL =
  '/eeapi/application-process/interview/{0}/household/individual';

export const GET_CASE_DETAILS = '/eeapi/application-process/casedetails/{0}';

export const POST_LIVINGARRANGEMENT =
  'eeapi/application-process/interview/{0}/household/{1}/add-living-arrangement';

export const POST_NEW_MEMBER =
  '/eeapi/client-management/new-case-indv-creation';

export const POST_UNEARNEDINCOME =
  '/eeapi/application-process/interview/{0}/financial-information/{1}/unearned-income';

export const POST_EARNEDINCOME =
  '/eeapi/application-process/interview/{0}/financial-information/{1}/earned-income';

export const POST_UNEARNEDINCOME_APPLICATION =
  '/eeapi/application-process/interview/financial-information/unearned-income-applications';

export const POST_POTENTIAL_APPLICATION =
  '/eeapi/application-process/interview/{0}/financial-information/{1}/potentialAsset';

export const DELETE_POTENTIAL_APPLICATION =
  '/eeapi/application-process/interview/{0}/financial-information/{1}/delete-potentialAsset';

export const PUT_EDIT_MEMBER = '/eeapi/application-process/{0}/edit-member';

export const POST_SMARTY_STREET = '/eeapi/verification/{0}/address';

export const POST_SLOT = '/eeapi/application-process/get-available-slots/{0}';

export const POST_SCHEDULE = '/eeapi/application-process/save-time-slots/{0}';

export const GET_SCHEDULE = '/eeapi/application-process/get-appointment/{0}';

export const POST_CONTACT_INFOMATION =
  '/eeapi/application-process/screening/{0}/contact';

export const GET_CONTACT_INFOMATION =
  '/eeapi/application-process/screening/{0}/contact-information';

export const SCHEDULE_INTERVIEW =
  '/eeapi/application-process/screening/{0}/schedule-interview';

export const GET_COUNTYWORKER =
  'eeapi/application-process/screening/get-countyDoWorker-Info?caseId={0}';
export const GET_UNIQUEID = '/eeapi/public/get-doNameAndCounty/{0}';
export const POST_ADDWORKER = '/eeapi/work-item/create';

export const GET_DISPOSTION =
  'eeapi/application-process/screening/schedule-interview/{0}';

export const GET_CITIZENSHIP =
  'eeapi/application-process/interview/{0}/household/citizenship-immigration';

export const POST_CITIZENSHIP =
  '/eeapi/application-process/interview/{0}/household/{1}/citizenship-immigration';

export const POST_INCOME_UPLOADFILES = '/eeapi/document/{0}/upload';

export const GET_CASEHOME = '/eeapi/application-process/case-home-complete/{0}';

export const PUT_DELETE_MEMBER = '/eeapi/application-process/{0}/delete-member';

export const POST_MYDASHBOARD =
  '/eeapi/application-process/dashboard-new/{0}/{1}';

export const POST_UNASSIGNED =
  '/eeapi/application-process/get-unassigned-cases/{0}/{1}';

export const GET_ASSIGNED =
  '/eeapi/application-process/get-assigned-cases/{0}/{1}';

export const CASE_ASSIGNMENT =
  '/eeapi/application-process/case-mass-assignment/{0}';
export const CASE_RE_ASSIGNMENT =
  '/eeapi/application-process/re-assign-cases/{0}';

export const GET_OVERDUE = '/eeapi/work-item/overdue-alerts/{0}/{1}';

export const SEARCH_WORK_ITEM = '/eeapi/work-item/search-new';
export const WORKER_WORK_ITEM = '/eeapi/work-item/workers';
export const CLIENT_MNGM_ITEM = '/eeapi/client-management/allDO';

export const CLOSE_NEW = '/eeapi/work-item/close-new';

export const ASSIGN_WORK_ITEM = '/eeapi/work-item/assign-work-item-Id';

export const GET_DOMESTIC_VIOLENCE =
  'eeapi/application-process/{0}/household/get-domestic-voilence-details/{1}';

export const POST_DOMESTIC_VIOLENCE =
  'eeapi/application-process/{0}/household/save-domestic-voilence-details/{1}';

export const GET_HOUSE_MEMBERS =
  '/eeapi/application-process/interview/{0}/memberDetails';
export const GET_PROGRAM_REQ =
  '/eeapi/application-process/interview/{0}/selected-program';

export const CompleteCase =
  '/eeapi/public/schedule-interview/save-case-status/{0}/{1}';

export const GETCASE_ID = '/eeapi/application-process/caseId/{0}';

export const GET_QUESTION =
  '/eeapi/application-process/supervisor-review/case/{0}/{1}/getqstns';

export const CASE_SAVE_AND_SUBMIT =
  '/eeapi/application-process/supervisor-review/case/saveandsubmit';

export const ASSIGN_CASEWRKR =
  'eeapi/application-process/supervisor-review/case/{0}/assign-casewrkr';

export const GET_DASHBOARD_COUNT =
  '/eeapi/application-process/getCountForEachDetail/{0}/SU';

export const GET_CASEWORKER_DASHBOARD =
  '/eeapi/public/schedule-interview/get-caseworker-dashboard';

export const GET_SUPV_DASHOARD_UNASSIGNED =
  '/eeapi/public/schedule-interview/{0}';

export const SCHEDULED_INTERVIEW_ASSIGN_CASEWORKER =
  '/eeapi/public/schedule-interview/assign-caseworker';

export const ACTIVE_WORKERS = '/eeapi/application-process/get-all-active-wrkrs';

export const ADD_WORK_ITEM = '/eeapi/application-process/add-work-item';

export const GET_USER =
  'eeapi/application-process/user-management/get-users/{0}/N';

export const DO_WORKER_INFO =
  '/eeapi/application-process/screening/DOWorkerInfo';

export const GET_LDSSDOCD_UM =
  '/eeapi/application-process/user-management/get-ldssdocd/{0}';

export const GET_SUPERCISORS_UM =
  '/eeapi/application-process/user-management/get-supervisors/{0}';

export const BULLETIN_BOARD = '/eeapi/public/bulletinboard';

export const OFF_BOARDED = '/eeapi/work-item/offboarded-workers';

export const SEARCH_PROGRAM = '/eeapi/work-item/search-program';

export const GET_COUNTIES = '/eeapi/application-process/screening/get-counties';

export const GET_OFFICE_COUNTY =
  'eeapi/application-process/screening/get-county-offices?county={0}';

export const GET_OFFICE_WORKERS =
  '/eeapi/application-process/screening/get-office-workers?office={0}';

export const TRANSFER_DO = '/eeapi/cases/client-management/transferDO';

export const GET_CASEWORKER_DASHBOARD_NEW =
  '/eeapi/public/schedule-interview/get-caseworker-dashboard-new';

export const GET_NEXT_WORK_ITEM = '/eeapi/work-item/get-next';

export const GET_ELIGIBLE_CASE = '/eeapi/cola/getEligibleCaseDetails';

export const RUN_ELIGIBLE = '/eeapi/cola/approveCasesForCola';
