import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import {
  deletePaload,
  insertPolicyPayload,
  institutionPayload,
} from './apiPayload';
import { GET_ASSETS, POST_POLICY, POST_INSTITUTION_SEARCH } from './apiUrl';

export const getAssetApi = (caseId) =>
  HttpClient.get(format(GET_ASSETS, caseId));

export const postPolicyInsert = (caseId, individualId, data) =>
  HttpClient.post(
    format(POST_POLICY, caseId, individualId),
    insertPolicyPayload(data)
  );

export const putPolicyInsert = (caseId, individualId, data) =>
  HttpClient.put(
    format(POST_POLICY, caseId, individualId),
    insertPolicyPayload(data)
  );
  
export const putDelete = (caseId, individualId, data, name) =>
  HttpClient.put(
    format(POST_POLICY, caseId, individualId),
    deletePaload(data, name)
  );

export const postInstitutionSearch = (data) =>
  HttpClient.post(format(POST_INSTITUTION_SEARCH), institutionPayload(data));
