import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { FormLabel, Typography } from '@mui/material';

function FormGroup(props) {
  const { label, children, required } = props;
  return (
    <div className={styles.formGroup}>
      <FormLabel required={required}>
        <Typography variant="subtitle2" color="var(--grey-400)">
          {label}
        </Typography>
      </FormLabel>
      <div className={styles.formControls}>{children}</div>
    </div>
  );
}

FormGroup.propTypes = {};

export default FormGroup;
