import React, { useState, useMemo } from 'react';
import PageContainer from '../../components/PageContainer';
import SelectableView from '../../components/SelectableView';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import usePageNav from '../../hooks/usePageNav';
import PregnancyDetails from './PregnancyDetails';
import dayjs from 'dayjs';
import usePage from '../../hooks/usePage';

function PregnancyInfo({ id }) {
  const { caseId, navigations } = usePageNav();
  const { navProps } = usePage(id);
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const femaleMembers = useMemo(() => {
    return houseMembers.filter((member) => member.gender === 'F');
  }, [houseMembers]);

  const calculateAge = (dob) => {
    const birthDate = dayjs(dob);
    const currentDate = dayjs();
    const age = currentDate.diff(birthDate, 'year');
    return age;
  };
  const onNext = () => {
    navigations.toSummary();
  };

  const onBack = () => {
    navigations.toMedicareDetails();
  };
  return (
    <PageContainer
      navProps={navProps}
      title="Pregnancy Details"
      toVerify="PREGNANCY"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={femaleMembers}
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          const age = calculateAge(params.dob);
          const isBelow13 = age < 13;
          return (
            <MemberMenuButton
              member={params}
              options={options}
              disabled={isBelow13}
            />
          );
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        <PregnancyDetails caseId={caseId} />
      </SelectableView>
    </PageContainer>
  );
}

export default PregnancyInfo;
