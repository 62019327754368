import React, { useMemo, useEffect } from 'react';
import FormSection from '../../../components/FormSection';
import { Box, Grid, TextField, Stack, Button } from '@mui/material';
import Field from '../../../components/Field';
import Dropdown from '../../../components/Dropdown';
import { DatePicker } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import usePageNav from '../../../hooks/usePageNav';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import useLookup from '../../../hooks/useLookup';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import { getFullName } from '../../../utils/helpers';
import { hideLoader, showLoader } from '../../../store/slices/loaderSlice';
import { maxLength, number } from '../../../utils/normalizers';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../utils/services/toast';
import {
  postPregnancy,
  updatePregnancy,
} from '../../../utils/services/PregnancyInfo/apiRequests';
import dayjs from 'dayjs';

function PregnancyModal({
  togglePregnancy,
  selected,
  individualId,
  getPregnancyInfo,
  pregnancyEdit,
  createPregnancy,
  verificationOpts,
}) {
  const { houseMembers } = useSelector(selectHouseMembers);

  const { individualId: currentUserId } = selected;
  const { caseId, navigations } = usePageNav();

  const memberOpts = useMemo(() => {
    const options = map(houseMembers, (item) => {
      const { personName } = item;
      const fullName = getFullName(personName?.firstName, personName?.lastName);
      const id = item?.individualId;

      return {
        label: fullName,
        value: id,
      };
    });
    options.push({
      label: 'Other',
      value: 'Other',
    });
    return options;
  }, [houseMembers]);

  const validationSchema = Yup.object({
    pregnancyStartDate: Yup.string().required(
      'Please Select the Pregnancy Start Date'
    ),
    pregnancyDueDate: Yup.string().required(
      'Please Select the Pregnancy Due Date'
    ),
    pregnancyEndDate: Yup.string().required(
      'Please Select the Pregnancy Due Date'
    ),
    unborns: Yup.string().required('Please Enter the Unborns'),
    verification: Yup.string().required('Please Select Verification'),
  });

  const postPregnancyDetails = async (data) => {
    try {
      showLoader();
      const res = await postPregnancy(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('SuccessFully Added Pregnancy Details');
        togglePregnancy();
        getPregnancyInfo();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const editPregnancyDetails = async (data) => {
    try {
      showLoader();
      const res = await updatePregnancy(data, caseId, individualId);
      if (res.status === 200) {
        showSuccessToast('SuccesFily Updated Pregnancy Details');
        togglePregnancy();
        getPregnancyInfo();
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const renderMemberName = (id) => {
    return houseMembers.find(({ individualId }) => individualId == id);
  };

  const formik = useFormik({
    initialValues: {
      dirtyStatus: createPregnancy ? 'insert' : 'update',
      pregnancyStartDate: pregnancyEdit?.pregnancyStartDate ?? '',
      pregnancyDueDate: pregnancyEdit?.pregnancyDueDate ?? '',
      pregnancyEndDate: pregnancyEdit?.pregnancyEndDate ?? '',
      unborns: pregnancyEdit?.numberExpected ?? '',
      parent: pregnancyEdit?.relativeIndividualId ?? '',
      parentName: pregnancyEdit?.fatherName ?? '',
      verification: pregnancyEdit?.dueDateVerification?.verificationType ?? '',
      recordId: pregnancyEdit?.recordId ?? '',
    },

    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (createPregnancy) {
        postPregnancyDetails(values);
      } else {
        editPregnancyDetails(values);
      }
    },
  });

  if (pregnancyEdit?.relativeIndividualId) {
    const member = renderMemberName(pregnancyEdit.relativeIndividualId);
    if (member && member.personName) {
      // Update parentName dynamically
      formik.setFieldValue(
        'parent',
        member.personName.firstName + ' ' + member.personName.lastName
      );
    }
  }
  const { parent } = formik.values;

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <FormSection label="Pregnancy Details" mt="0.5rem">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field
                label="Pregnancy Start Date"
                formik={formik}
                name="pregnancyStartDate"
                isDate
                required
              >
                <DatePicker
                  placeholder="Select Date"
                  disableFuture
                  value={formik.values.pregnancyStartDate}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Pregnancy Due Date"
                formik={formik}
                name="pregnancyDueDate"
                isDate
                required
              >
                <DatePicker
                  placeholder="Select Date"
                  value={formik.values.pregnancyDueDate}
                  minDate={dayjs(formik.values.pregnancyStartDate)}
                  maxDate={dayjs(formik.values.pregnancyStartDate).add(10, 'M')}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Pregnancy End Date"
                formik={formik}
                name="pregnancyEndDate"
                isDate
                required
              >
                <DatePicker
                  placeholder="Select Date"
                  value={formik.values.pregnancyEndDate}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Number Of Unborns"
                formik={formik}
                name="unborns"
                normalizers={[number, maxLength(1)]}
                required
              >
                <TextField
                  placeholder="Number Of unborns"
                  value={formik.values.unborns}
                />
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field label="Parent" formik={formik} name="parent">
                <Dropdown options={memberOpts} value={formik.values.parent} />
              </Field>
            </Grid>
            {parent === 'Other' && (
              <Grid item xs={6}>
                <Field label="Parent Name" formik={formik} name="parentName">
                  <TextField
                    placeholder="Parent Name"
                    value={formik.values.parentName}
                  />
                </Field>
              </Grid>
            )}
            <Grid item xs={6}>
              <Field
                label="Verification"
                formik={formik}
                name="verification"
                required
              >
                <Dropdown
                  options={verificationOpts}
                  placeholder="Select Verification"
                  value={formik.values.verification}
                />
              </Field>
            </Grid>
          </Grid>
        </FormSection>
      </Box>
      <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} mt={2}>
        <Button variant="outlined" onClick={togglePregnancy}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}

export default PregnancyModal;
