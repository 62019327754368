import React, { useState } from 'react';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import useLookup from '../../hooks/useLookup';
import MyDashboard from './MyDashboardTable';
import useLoader from '../../hooks/useLoader';
import { getAuthToken } from '../../store/selectors/authSelector';
import { useSelector } from 'react-redux';
import { getNextWork } from '../../utils/services/apiRequests';
import { showErrorToast } from '../../utils/services/toast';
import { ReactComponent as WorkUpdate } from '../../assets/icons/QuickLinks/work_update.svg';
import IconLink from '../../components/IconLinks';

export default function CaseReview() {
  const { lkpFlatten, getLkpMatch } = useLookup('Application');
  const [value, setValue] = useState(1);
  const [showLoader, hideLoader] = useLoader();
  const token = useSelector(getAuthToken);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getWorkItemData = async () => {
    const payload = {
      alertAssignedTo: token.sub,
      alertType: 'CR',
    };
    try {
      showLoader();
      const res = await getNextWork(payload);
    } catch (error) {
      showErrorToast('Server Error');
    } finally {
      hideLoader();
    }
  };

  const GetWorkItem = () => {
    return (
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={getWorkItemData}>
          <IconLink icon={WorkUpdate} iconlabel="Get Work Item" />
        </Button>
      </Grid>
    );
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
      }}
    >
      <Grid
        sx={{
          '& .Mui-selected': {
            color: 'black !important',
            fontWeight: 700,
          },
          width: '100%',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            textTransform: 'none',
            width: '100%',
            borderBottom: '1px solid #bababa',
          }}
        >
          <Tab
            sx={{ flex: 1, textTransform: 'none' }}
            value={1}
            label="My Dashboard"
          />
        </Tabs>
      </Grid>
      <GetWorkItem />

      {value === 1 && <MyDashboard getLkpMatch={getLkpMatch} value={value} />}
    </div>
  );
}
