import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  medicalExpenses,
  deleteMedicalExpensesRecord,
} from '../../../../utils/services/medicalExpenses.js/apiRequests';
import usePageNav from '../../../../hooks/usePageNav';
import { Button } from '@mui/material';
import AddMember from '../AddMember';
import { Stack, InputAdornment } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ActionButtons from '../../../../components/ActionButtons';
import useLookup from '../../../../hooks/useLookup';
import DeleteConfiramation from '../';
import useLoader from '../../../../hooks/useLoader';
import { getDocumentsFiles } from '../../../../utils/services/apiRequests';
import DocumentUploadFilesDialog from '../../../Income/DocumentUploadFilesDialog';

const MedicalContent = ({ selected }) => {
  const { caseId } = usePageNav();
  const [rowData, setRow] = useState([]);
  const [docOpen, setDocOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(null);
  const [showLoader, hideLoader] = useLoader();
  const [open, setOpen] = useState(false);
  const [allMembers, setAllMembers] = useState([]);
  const [memberExpensesAry, setMemberExpensesAry] = useState([]);
  const [selectedIndividual, setSelectedIndividual] = useState({});
  const [personName, setPersonName] = useState({});
  const [docmentFiles, setDocmentFiles] = useState([]);
  const { getLkpMatch, getOptions, lkpRaw } = useLookup('medicalExpenses');
  const [deleteThisRecod, setDeleteRecord] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reportEdited, setReportEdited] = useState(false);
  const expenseType = getOptions('MedicalExpenseType');

  useEffect(() => {
    getMemberTables();
  }, [selected, reportEdited]);

  async function getMemberTables() {
    showLoader();
    try {
      const memberDetails = await medicalExpenses(caseId);

      if (memberDetails.status === 200) {
        setAllMembers(memberDetails.data.householdMembers);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }

  function formatCurrency(value) {
    let converToCurrency = value.toString().replace(/\D/g, '');
    converToCurrency = converToCurrency.replace(/(\d)(\d{2})$/, '$1.$2');
    converToCurrency = converToCurrency.replace(/(?=(\d{3})+(\D))\B/g, ',');
    return converToCurrency;
  }

  const getAmountSum = (retroAmount) => {
    const sumedAmount = retroAmount.reduce(
      (acc, current) => (parseFloat(current.amount) || 0) + acc,
      0
    );
    return `$ ${formatCurrency(sumedAmount)}`;
  };

  useMemo(() => {
    const findMember = allMembers.find(
      (obj) => obj.individualId === selected.individualId
    );
    setPersonName(findMember?.personName);
    const designTable = findMember?.medicalExpenses.map((obj, id) => {
      return {
        id: obj?.recordId,
        provideName: obj?.providerName,
        expenseType: getLkpMatch('MedicalExpenseType', obj?.medicalExpenseType),
        dateIncurred: obj?.effectiveBeginDt,
        endDate: obj?.effectiveEndDt,
        amount: getAmountSum(obj?.retroMonths?.[0].amountModel),
      };
    });
    setMemberExpensesAry(findMember?.medicalExpenses ?? '');
    setRow(designTable ?? []);
  }, [selected, reportEdited, allMembers]);

  function handleEdit(params) {
    const memberToedit = memberExpensesAry?.find(
      (obj) => obj.recordId === params.id
    );
    setSelectedIndividual(memberToedit);
    setOpen(true);
  }
  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const handleConfirmDelete = async () => {
    showLoader();
    try {
      const res = await deleteMedicalExpensesRecord(
        deleteThisRecod.primaryData,
        deleteThisRecod.individualId,
        caseId,
        deleteThisRecod.recordId,
        deleteThisRecod.providedVerificationId
      );

      if (res.status === 200) {
        setOpenDeleteDialog(false);
        setReportEdited(!reportEdited);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  function handleDelete(params) {
    const memberTodelete = memberExpensesAry?.find(
      (obj) => obj.recordId == params.id
    );

    const destrcJson = {
      dirtyStatus: 'delete',
      providerName: memberTodelete?.providerName ?? '',
      expenseDetails: memberTodelete?.medicalExpenseType ?? '',
      effectiveBDate: memberTodelete?.effectiveBeginDt ?? '',
      effectiveEDate: memberTodelete?.effectiveEndDt ?? '',
      serviceProvided: memberTodelete?.serviceProvided ?? '',
      snapConsider: memberTodelete?.notsnap ?? '',
      frequency: memberTodelete?.retroMonths?.[0]?.frequency ?? [],
      waiver: memberTodelete?.notwaiver ?? '',
      frequencyAmountAry: memberTodelete?.retroMonths?.[0]?.amountModel ?? [],
    };

    setDeleteRecord({
      primaryData: destrcJson,
      individualId: memberTodelete.individualId,
      recordId: memberTodelete.recordId,
      providedVerificationId:
        memberTodelete.medicalExpenseVerification.providedVerificationId,
    });
    setOpenDeleteDialog(true);
  }
  const getUploadFiles = async () => {
    try {
      const res = await getDocumentsFiles(caseId);
      if (res.status === 200) {
        setDocmentFiles(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setDeleteRecord({});
  };

  const sampleColumn = [
    { field: 'provideName', headerName: 'Provider Name', flex: 1 },
    { field: 'expenseType', headerName: 'Expense Type', flex: 1 },
    { field: 'dateIncurred', headerName: 'Date Incurred', flex: 1 },
    { field: 'endDate', headerName: 'End Date', flex: 1 },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell(params) {
        return (
          <span>
            <ActionButtons
              onEdit={() => handleEdit(params)}
              onDelete={() => handleDelete(params)}
            />
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ mt: '1rem', mb: '1rem' }}
      >
        <Button
          variant="outlined"
          size="small"
          startIcon={<UploadFileIcon />}
          onClick={handleOpenDocModal}
        >
          Upload files
        </Button>

        <Button
          onClick={() => {
            setSelectedIndividual({});
            setOpen(true);
          }}
        >
          + Add Medical Expenses
        </Button>
      </Stack>

      <DataGrid
        sx={{
          marginTop: 2,
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: 16,
            fontWeight: 700,

            textAlign: 'center',
            color: 'black',
          },
        }}
        slots={{
          noRowsOverlay: 'noData',
        }}
        hideFooter
        rows={rowData ?? []}
        columns={sampleColumn ?? []}
      />

      {open && (
        <AddMember
          open={open}
          lkpRaw={lkpRaw}
          individualId={selected.individualId}
          getOptions={getOptions}
          personName={personName}
          selectedIndividual={selectedIndividual}
          reportEdited={reportEdited}
          setReportEdited={setReportEdited}
          setOpen={setOpen}
        />
      )}
      <DeleteConfiramation
        openDeleteDialog={openDeleteDialog}
        handleCancelDelete={handleCancelDelete}
        handleConfirmDelete={handleConfirmDelete}
      />
      <DocumentUploadFilesDialog
        docOpen={docOpen}
        setDocOpen={setDocOpen}
        isAdd={isAdd}
        setIsAdd={setIsAdd}
        caseId={caseId}
        lkpRaw={lkpRaw}
        member={selected}
        docmentFiles={docmentFiles}
      />
    </div>
  );
};

export default MedicalContent;
