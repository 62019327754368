import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  Button,
  Stack,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from '../../../../components/Field';
import FormGroup from '../../../../components/FormGroup';
import Dropdown from '../../../../components/Dropdown';
import dayjs from 'dayjs';
import AuthRepRights from './AuthRepRights';
import SearchIntitution from '../../../WaiverAndFacilities/AddInstitution/SearchInstitution/index';
import { Search } from '@mui/icons-material';
import { findIndex, includes, map, reject, some } from 'lodash';
import * as yup from 'yup';
import { postAuthRepDetails } from '../../../../utils/services/authorizedRepServices/requests';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../utils/services/toast';
import {
  maxLength,
  name,
  number,
  onlyAlphabet,
} from '../../../../utils/normalizers';
import useMediaQuery from '@mui/material/useMediaQuery';

const DEFAULT_ACCESS_TYPES = {
  authRepAccsTypCd: 'COI',
  authRepAccsTypId: null,
  authRepDtlsId: null,
  effectiveBeginDt: null,
  effectiveEndDt: null,
  dirtyStatus: 'insert',
};

const DEFAULT_AUTH_REP_DETAILS = {
  authRepDtlsId: null,
  authRepId: 0,
  effectiveBeginDt: null,
  targetIndividualId: 999,
  effectiveEndDt: null,
  programId: '',
  programCd: '',
  dirtyStatus: 'insert',
  authRepAccessType: [],
};

function AddAuthRep({
  open,
  handleClose,
  classificationOpts,
  relationshipOpts,
  stateOpts,
  typeOpts,
  accesTypeOptions,
  memberOpts,
  selected,
  getLkpMatch,
  editAuthrep,
  editData,
  caseDetails,
  caseId,
  onSaveAuthRep,
}) {
  const [institutionSearch, setInstitutionSearch] = useState(false);
  const [showSmarty, setShowSmarty] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [providerDetails, setProviderDetails] = useState('');
  const targetIndividualId = selected.agHeadId;

  const programs = map(
    caseDetails?.programs ?? [],
    (value) => value.programName
  );

  const programIds = Object.fromEntries(
    map(caseDetails?.programs, (p) => [p.programName, p.programId])
  );

  const selectedValue = (data) => {
    formik.setFieldValue('providerName', data.vendorName);
    formik.setFieldValue('providerId', data.vendorId);
    toggleInstitution();
  };
  const validationSchema = yup.object({
    firstName: yup.string().when('category', {
      is: (category) => category === 'noncase',
      then: () => yup.string().required('First Name is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    lastName: yup.string().when('category', {
      is: (category) => category === 'noncase',
      then: () => yup.string().required('Last Name is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    addressLine1: yup.string().when('category', {
      is: (category) => category === 'noncase' || category === 'case',
      then: () => yup.string().required('address is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    city: yup.string().when('category', {
      is: (category) => category === 'noncase' || category === 'case',
      then: () => yup.string().required('city is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    state: yup.string().when('category', {
      is: (category) => category === 'noncase' || category === 'case',
      then: () => yup.string().required('state is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    zipcode: yup.string().when('category', {
      is: (category) => category === 'noncase' || category === 'case',
      then: () => yup.string().required('zipcode is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    houseHoldMember: yup.string().when('category', {
      is: (category) => category === 'case',
      then: () => yup.string().required('Household Member is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    relationship: yup.string().when('category', {
      is: (category) => category === 'case' || category === 'noncase',
      then: () => yup.string().required('Relationship is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    effectiveBeginDate: yup.string().required('Begin Date is required'),
    classification: yup.string().required('classification is required'),
  });

  const isIpad = useMediaQuery('(min-width:768px) and (max-width:1024px)');
  // const onSaveAuthRep = async (data) => {
  //   try {
  //     const res = await postAuthRepDetails(data, caseId);

  //     if (res.status === 200) {
  //       // authRepDetails();
  //     }

  //     showSuccessToast('Successfully added new authrep');
  //   } catch (error) {
  //     showErrorToast(error);
  //   }
  // };
  const _category = useMemo(() => {
    if (editData?.categoryType === 'UI') {
      if (editData?.householdMemberYN === 'N') {
        return 'noncase';
      } else {
        return 'case';
      }
    } else {
      return 'provider';
    }
  }, [editData]);

  const formik = useFormik({
    initialValues: {
      firstName: editData?.personName?.firstName ?? '',
      lastName: editData?.personName?.lastName ?? '',
      middleName: editData?.personName?.middleName ?? '',
      dateOfBirth: null,
      relationship: editData?.relationshipCd ?? '',
      addressLine1: editData?.address?.addressLine1 ?? '',
      addressLine2: editData?.address?.addressLine2 ?? '',
      city: editData?.address?.city ?? '',
      state: editData?.address?.state ?? '',
      zipcode: editData?.address?.zipcode ?? '',
      phone: editData?.phoneNumber?.phoneNumber ?? null,
      ext: null,
      type: null,
      email: editData?.email ?? '',
      effectiveBeginDate: editData?.effectiveBeginDt ?? '',
      effectiveEndDate: editData?.effectiveEndDt ?? '',
      classification: editData?.authRepType ?? '',
      authorizedRepresentativeDetails:
        editData?.authorizedRepresentativeDetails ?? [],
      targetIndividualId,
      category: _category ?? '',
      addressCareOf: null,
      authRepId: editData?.authRepId ?? '',
      houseHoldMember: editData?.individualId,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (editAuthrep) {
        onSaveAuthRep(values, 'update');
      } else {
        onSaveAuthRep(values, 'insert');
      }
    },
  });

  const nameSet = useMemo(() => {
    map(memberOpts, (val) => {
      if (formik.values.houseHoldMember === val.value) {
        let arrName = val.label.split(' ');

        formik.setFieldValue('firstName', arrName[0]);
        formik.setFieldValue('lastName', arrName[1]);
      }
    });
  }, [formik.values.houseHoldMember]);

  const onSelectProgram = (programCd) => {
    const { authorizedRepresentativeDetails } = formik.values;
    const programIndex = findIndex(authorizedRepresentativeDetails, {
      programCd,
    });

    let newAuthorizedRepresentativeDetails;

    if (programIndex === -1) {
      newAuthorizedRepresentativeDetails = [
        ...authorizedRepresentativeDetails,
        {
          ...DEFAULT_AUTH_REP_DETAILS,
          programCd,
          targetIndividualId,
          programId: programIds[programCd],
        },
      ];
    } else {
      newAuthorizedRepresentativeDetails = reject(
        authorizedRepresentativeDetails,
        {
          programCd,
          programId: programIds[programCd],
        }
      );
    }

    formik.setFieldValue(
      'authorizedRepresentativeDetails',
      newAuthorizedRepresentativeDetails
    );
  };

  const onSelectAccess = (programCd, authRepAccsTypCd, targetIndividualId) => {
    const { authorizedRepresentativeDetails } = formik.values;
    const programIndex = findIndex(authorizedRepresentativeDetails, {
      programCd,
    });

    const structure = {
      authRepAccsTypCd: authRepAccsTypCd,
      authRepAccsTypId: null,
      authRepDtlsId: null,
      dirtyStatus: 'insert',
      effectiveBeginDt: null,
      effectiveEndDt: null,
    };

    const authTypeIndex = findIndex(
      authorizedRepresentativeDetails[programIndex]?.authRepAccessType,
      { authRepAccsTypCd }
    );

    let newAuthRepAccessType, newAuthorizedRepresentativeDetails;

    if (authTypeIndex === -1) {
      newAuthRepAccessType = [
        ...authorizedRepresentativeDetails[programIndex]?.authRepAccessType,
        {
          ...DEFAULT_ACCESS_TYPES,
          authRepAccsTypCd,
          // targetIndividualId,
        },
      ];

      // newAuthRepAccessType = Object.assign(
      //   [],
      //   authorizedRepresentativeDetails[programIndex].authRepAccessType,
      //   {
      //     [authTypeIndex]: {
      //       ...DEFAULT_ACCESS_TYPES,
      //       authRepAccsTypCd,
      //       targetIndividualId,
      //     },
      //   }
      // );
    } else {
      newAuthRepAccessType = reject(
        authorizedRepresentativeDetails[programIndex].authRepAccessType,
        { authRepAccsTypCd }
      );
    }
    newAuthorizedRepresentativeDetails = Object.assign(
      [],
      authorizedRepresentativeDetails,
      {
        [programIndex]: {
          ...authorizedRepresentativeDetails[programIndex],
          authRepAccessType: newAuthRepAccessType,
        },
      }
    );

    formik.setFieldValue(
      'authorizedRepresentativeDetails',
      newAuthorizedRepresentativeDetails
    );
  };

  const searchIntitution = () => {
    setInstitutionSearch(true);
  };

  const toggleInstitution = () => {
    setInstitutionSearch(false);
  };

  const searchProvider = () => {
    setInstitutionSearch(true);
  };

  //   const smartyStreetApi = async (data) => {
  //     if (formik.dirty) {
  //       try {
  //         dispatch(showLoader());
  //         const res = await smartyStreet(data, caseId);
  //         if (res.status === 200) {
  //           setSmartyValue(res.data.addressSearches[0].results[0]);
  //           setShowSmarty(true);
  //         }
  //       } catch (error) {
  //         console.log(error);
  //         showErrorToast();
  //       } finally {
  //         dispatch(hideLoader());
  //       }
  //     } else {
  //       navigations.toAppDispose();
  //     }
  //   };

  return (
    <Dialog
      onSubmit={formik.handleSubmit}
      open={open}
      onClose={handleClose}
      maxWidth={isIpad ? 'xl' : 'lg'}
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '80vw',
            height: '80vh',
          },
        },
      }}
    >
      <DialogTitle sx={{ fontSize: 20 }}>
        {editAuthrep
          ? 'Edit Authorized Representative'
          : 'Add Authorized Representative'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText tabIndex={-1}>
          <Box>
            <Dialog
              maxWidth={'xl'}
              open={institutionSearch}
              onClose={toggleInstitution}
            >
              <SearchIntitution
                setProviderDetails={setProviderDetails}
                selectedValue={selectedValue}
                toggleInstitution={toggleInstitution}
              />
            </Dialog>
            <FormGroup>
              <Grid container spacing={2}>
                <Grid item xs={4} sx={{ mb: '1rem' }}>
                  <Field
                    required
                    name="effectiveBeginDate"
                    label="Begin Date"
                    isDate
                    formik={formik}
                  >
                    <DatePicker
                      disableFuture
                      //   value={dayjs(formik.values.beginDate)}
                    />
                  </Field>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="effectiveEndDate"
                    label="End Date"
                    isDate
                    formik={formik}
                  >
                    <DatePicker
                      // minDate={dayjs(formik.values.effectiveBeginDate)}
                      requried
                    />
                  </Field>
                </Grid>
                <Grid item xs={4} sx={{ mb: '1rem' }}>
                  <Field
                    name="classification"
                    label="Classification"
                    formik={formik}
                    required
                  >
                    <Dropdown
                      options={classificationOpts}
                      placeholder="Select a Classification"
                    />
                  </Field>
                </Grid>
              </Grid>
            </FormGroup>
            <Stack direction="row" spacing={1}>
              <Box item xs={4}>
                <FormGroup>
                  <Typography variant="subtitle1">Program</Typography>
                  <Stack sx={{ minWidth: 'max-content' }}>
                    {programs.map((program) => {
                      const programIndex = findIndex(
                        formik.values.authorizedRepresentativeDetails,
                        { programCd: program }
                      );
                      const isOpen = programIndex !== -1;
                      const addedAccesses =
                        formik.values.authorizedRepresentativeDetails?.[
                          programIndex
                        ]?.authRepAccessType ?? [];

                      return (
                        <AuthRepRights
                          accesTypeOptions={accesTypeOptions}
                          programName={program}
                          onSelectProgram={onSelectProgram}
                          onSelectAccess={onSelectAccess}
                          getLkpMatch={getLkpMatch}
                          open={isOpen}
                          addedAccesses={addedAccesses}
                        />
                      );
                    })}
                  </Stack>
                  {/* <Stack sx={{ minWidth: 'max-content' }}>
                    <AuthRepRights
                      accesTypeOptions={accesTypeOptions}
                      programName="SNAP"
                    />
                  </Stack> */}
                </FormGroup>
              </Box>
              <Box item>
                <FormGroup>
                  <h4>Category</h4>
                  <RadioGroup
                    row
                    aria-labelledby="category"
                    name="category"
                    {...formik.getFieldProps('category')}
                  >
                    <FormControlLabel
                      value="provider"
                      control={<Radio />}
                      label="Provider"
                    />

                    {formik.values.classification === 'OTH' && (
                      <FormControlLabel
                        value="case"
                        control={<Radio />}
                        label="Case Member"
                      />
                    )}
                    {formik.values.classification === 'PRP' && (
                      <FormControlLabel
                        value="case"
                        control={<Radio />}
                        label="Case Member"
                      />
                    )}
                    {formik.values.classification === 'FAM' && (
                      <FormControlLabel
                        value="case"
                        control={<Radio />}
                        label="Case Member"
                      />
                    )}

                    <FormControlLabel
                      value="noncase"
                      control={<Radio />}
                      label="Non Case Member"
                    />
                  </RadioGroup>
                  {formik.values.category === 'provider' && (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={{ mb: '1rem' }}>
                        <Field
                          name="providerName"
                          label="Provider Name"
                          formik={formik}
                        >
                          <TextField
                            placeholder="Enter Provider Name"
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={searchProvider}>
                                    <Search />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Field>
                      </Grid>
                    </Grid>
                  )}

                  {formik.values.category === 'case' && (
                    <Grid container spacing={2}>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="houseHoldMember"
                          label="Select HouseHold Member"
                          formik={formik}
                          required
                        >
                          <Dropdown
                            options={memberOpts}
                            placeholder="Select Household Member"
                          />
                        </Field>
                      </Grid>

                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="relationship"
                          label="Select Relationship"
                          formik={formik}
                          required
                        >
                          <Dropdown
                            options={relationshipOpts}
                            placeholder="Select Relationship"
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="dateOfBirth"
                          label="DOB"
                          isDate
                          formik={formik}
                        >
                          <DatePicker
                            disableFuture
                            // value={dayjs(formik.values.dateOfBirth)}
                          />
                        </Field>
                      </Grid>

                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="addressCareOf"
                          label="C/O"
                          formik={formik}
                          normalizers={[maxLength(30), name, onlyAlphabet]}
                        >
                          <TextField placeholder="Enter C/O" />
                        </Field>
                      </Grid>

                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="addressLine1"
                          label="Address Line 1"
                          formik={formik}
                          required
                          normalizers={[maxLength(30)]}
                        >
                          <TextField placeholder="Door No.,Flat,Building,Company,Apartment" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="addressline2"
                          label="Address Line 2"
                          formik={formik}
                          normalizers={[maxLength(30)]}
                        >
                          <TextField placeholder="Area,Street,Sector" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="city"
                          label="City"
                          formik={formik}
                          required
                          normalizers={[maxLength(30), name]}
                        >
                          <TextField placeholder="Enter City" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="state"
                          label="Select State"
                          formik={formik}
                          required
                        >
                          <Dropdown
                            options={stateOpts}
                            placeholder="Select State"
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="zipcode"
                          label="Zip Code"
                          normalizers={[maxLength(5), number]}
                          formik={formik}
                          required
                        >
                          <TextField placeholder="Enter Zip Code" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="zipcode4"
                          label="Zip +4 Code"
                          normalizers={[maxLength(5), number]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter Zip +4 Code" />
                        </Field>
                      </Grid>
                    </Grid>
                  )}

                  {formik.values.category === 'noncase' && (
                    <Grid container spacing={2}>
                      <Grid item xs={4} sx={{ mb: '1rem' }}>
                        <Field
                          required
                          name="firstName"
                          label="First Name"
                          normalizers={[maxLength(30), onlyAlphabet]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter First Name" />
                        </Field>
                      </Grid>
                      <Grid item xs={4} sx={{ mb: '1rem' }}>
                        <Field
                          name="middleName"
                          label="Middle Name"
                          normalizers={[maxLength(30), onlyAlphabet]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter Middle Name" />
                        </Field>
                      </Grid>
                      <Grid item xs={4} sx={{ mb: '1rem' }} required>
                        <Field
                          name="lastName"
                          label="Last Name"
                          normalizers={[maxLength(30), onlyAlphabet]}
                          formik={formik}
                          required
                        >
                          <TextField placeholder="Enter Last Name" />
                        </Field>
                      </Grid>
                      {formik.values.classification === 'FAM' && (
                        <Grid item xs={6} sx={{ mb: '1rem' }}>
                          <Field
                            name="dateOfBirth"
                            label="dateOfBirth"
                            isDate
                            formik={formik}
                          >
                            <DatePicker
                              disableFuture
                              // value={dayjs(formik.values.dateOfBirth)}
                            />
                          </Field>
                        </Grid>
                      )}

                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          required
                          name="relationship"
                          label="Select Relationship"
                          formik={formik}
                        >
                          <Dropdown
                            options={relationshipOpts}
                            placeholder="Select Relationship"
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="addressLine1"
                          label="Address Line 1"
                          normalizers={[maxLength(30), name]}
                          formik={formik}
                          required
                        >
                          <TextField placeholder="Door No.,Flat,Building,Company,Apartment" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="addressLine2"
                          label="Address Line 2"
                          normalizers={[maxLength(30), name]}
                          formik={formik}
                        >
                          <TextField placeholder="Area,Street,Sector" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="city"
                          label="City"
                          normalizers={[maxLength(30), onlyAlphabet]}
                          formik={formik}
                          required
                        >
                          <TextField placeholder="Enter City" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="state"
                          label="Select State"
                          formik={formik}
                          required
                        >
                          <Dropdown
                            options={stateOpts}
                            placeholder="Select State"
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="zipcode"
                          label="Zip Code"
                          normalizers={[maxLength(5), number]}
                          formik={formik}
                          required
                        >
                          <TextField placeholder="Enter Zip Code" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="zipcode4"
                          label="Zip +4 Code"
                          normalizers={[maxLength(4), number]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter Zip +4 Code" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="phone"
                          label="Phone Number"
                          normalizers={[maxLength(10), number]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter Phone Number" />
                        </Field>
                      </Grid>
                      <Grid item xs={3} sx={{ mb: '1rem' }}>
                        <Field
                          name="ext"
                          label="Ext"
                          normalizers={[maxLength(3), number]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter Ext" />
                        </Field>
                      </Grid>
                      <Grid item xs={3} sx={{ mb: '1rem' }}>
                        <Field name="type" label="Select Type" formik={formik}>
                          <Dropdown
                            options={typeOpts}
                            placeholder="Select Type"
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="email"
                          label="Email"
                          normalizers={[maxLength(30)]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter Email" />
                        </Field>
                      </Grid>
                      <Grid item xs={6} sx={{ mb: '1rem' }}>
                        <Field
                          name="alternateEmail"
                          label="Alternate Email"
                          normalizers={[maxLength(30)]}
                          formik={formik}
                        >
                          <TextField placeholder="Enter Alternate Email" />
                        </Field>
                      </Grid>
                    </Grid>
                  )}
                </FormGroup>
              </Box>
            </Stack>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Discard
        </Button>
        <Button variant="contained" type="submit" onClick={formik.handleSubmit}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddAuthRep;
