import dayjs from 'dayjs';

export const addPreschoolPayload = (data, dirtyStatus) => {
  const {
    endDate,
    beginDate,
    OTOMedicalCheckup,
    ppiVerification,
    veriffication,
    recordId,
    otoMedicalDate,
    ppiBeginDate,
    ppiEndDate,
    providedVerificationId,
    verifiedYN,
    verificationRequestedYN,
  } = data;
  console.log(data);
  return {
    dirtyStatus: dirtyStatus,
    otoMedicalDate: OTOMedicalCheckup || otoMedicalDate,
    ppiBeginDate: beginDate || ppiBeginDate,
    ppiEndDate: endDate || ppiEndDate,
    ppiVerification: {
      retryCount: 0,
      verificationRequestedYN: verificationRequestedYN,
      providedVerificationId: providedVerificationId,
      verifiedYN: verifiedYN,
      verificationSourceCode: veriffication,
    },
    preSchoolHealthInd: '',
    recordId: recordId,
  };
};

export const addIncarcerationPayload = (data, dirtyStatus) => {
  const {
    effectiveEndDate,
    effectiveBeginDate,
    institution,
    incarcerationCity,
    incarcerationState,
    incarcerationCountry,
    primVerification,
    secondVerification,
    recordId,
    primaryVerificationID,
    secondaryVerificationID,
    primaryVerification,
    secondaryVerification,
  } = data;
  console.log(data);
  return {
    dirtyStatus: dirtyStatus,
    institution: institution,
    incarcerationCity: incarcerationCity,
    incarcerationState: incarcerationState,
    primaryVerification: {
      verificationSourceCode: primVerification,
      providedVerificationId:
        primaryVerificationID || primaryVerification?.providedVerificationId,
    },
    recordId: recordId,
    secondaryVerification: {
      verificationSourceCode: secondVerification,
      providedVerificationId:
        secondaryVerificationID ||
        secondaryVerification?.providedVerificationId,
    },
    incarcerationCountry: incarcerationCountry,
    effectiveBeginDate: effectiveBeginDate,
    effectiveEndDate: effectiveEndDate,
  };
};

export const addStrikerPayload = (data, dirtyStatus) => {
  const {
    effectiveBeginDate,
    effectiveEndDate,
    employerName,
    strikerStatus,
    recordId,
  } = data;
  return {
    recordId: recordId || 0,
    dirtyStatus: dirtyStatus,
    effectiveBeginDate:
      strikerStatus === 'Y' ? effectiveBeginDate : dayjs().format('YYYY-MM-DD'),
    effectiveEndDate: strikerStatus === 'Y' ? effectiveEndDate : null,
    employerName: employerName,
    relationToStriker: '',
    strikerStatus: strikerStatus,
  };
};

export const addVeteranPayload = (data, dirtyStatus) => {
  const {
    effectiveBeginDate,
    effectiveEndDate,
    veteranStatus,
    recordId,
    relationToVeteran,
  } = data;
  return {
    recordId: recordId,
    dirtyStatus: dirtyStatus,
    effectiveBeginDate: effectiveBeginDate,
    effectiveEndDate: effectiveEndDate,
    relationToVeteran: relationToVeteran,
    veteranStatus: veteranStatus,
  };
};
