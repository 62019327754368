import HttpClient from '../../HttpClient';
import { format } from '../../helpers';
import { SEARCH, PDF_DOWNLOAD, SUPPRESS, SUPPRESS_REMOVE } from './apiUrls';

export const getSearchData = (caseId, paramsUrl) =>
  HttpClient.get(format(SEARCH, caseId) + paramsUrl);

export const pdfDownload = (pdfId) =>
  HttpClient.post(
    format(PDF_DOWNLOAD, pdfId),
    {},
    {
      responseType: 'blob',
    }
  );

export const postSuppressReason = (caseId, payload) =>
  HttpClient.post(format(SUPPRESS, caseId), payload);

export const removeSuppression = (printId) =>
  HttpClient.post(format(SUPPRESS_REMOVE, printId));
