import React, { useEffect } from 'react';
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Divider,
  Box,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
import { get } from 'lodash';
import InputMask from 'react-input-mask';
import Field from '../../../components/Field';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&:hover fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
        color: '#d32f2f',
      },
    },
    '& label.Mui-focused': {
      color: '#d32f2f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#d32f2f',
      },
    },
    '& .MuiFormLabel-root': {
      borderColor: '#d32f2f',
      color: '#d32f2f',
    },
  },
}));

const PotentialIncomeForm = ({
  lkpRaw,
  isAdd,
  member,
  formik,
  selectedData,
}) => {
  const classes = useStyles();

  const handleDatePickerChange = (name, date) => {
    formik.setFieldValue(`${name}`, dayjs(date).toISOString());
  };

  const validateIsError = (formik, fieldName) => {
    const hasErrors =
      get(formik, `errors.${fieldName}`) && get(formik, `touched.${fieldName}`);

    return !!hasErrors;
  };

  useEffect(() => {
    if (!isAdd) {
      formik.setValues({
        ...selectedData,
      });
    }
  }, [isAdd]);

  return (
    <>
      <Box m={2}>
        <Typography variant="TextBl1300">Payment Dates</Typography>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              margin="normal"
              className={cx({
                [classes.customTextField]: validateIsError(formik, 'assetType'),
              })}
            >
              <InputLabel id="type">Type *</InputLabel>
              <Select
                labelId="type"
                id="select-type"
                label="Type *"
                name="assetType"
                value={formik.values.assetType}
                onChange={formik.handleChange}
              >
                {lkpRaw.PotentialAssetType.map((item) => (
                  <MenuItem key={item.lkpDesc} value={item.lkpDesc}>
                    {item.lookupLongVal}
                  </MenuItem>
                ))}
              </Select>
              {validateIsError(formik, 'assetType') && (
                <FormHelperText sx={{ ml: '10px' }} error>
                  {formik.errors.assetType}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              label="Attorney Name *"
              fullWidth
              margin="normal"
              name="attorneyName"
              value={formik.values.attorneyName}
              onChange={formik.handleChange}
              error={formik.touched.attorneyName && formik.errors.attorneyName}
              helperText={
                formik.touched.attorneyName && formik.errors.attorneyName
              }
            />
            <Box mt={1} mb={1}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  className={cx({
                    [classes.customTextField]: validateIsError(
                      formik,
                      'anticipatedReceiptDt'
                    ),
                  })}
                  label="Anticipated Receipt Date *"
                  sx={{ width: '100%' }}
                  margin="normal"
                  format="YYYY-MM-DD"
                  renderInput={(params) => <TextField {...params} />}
                  value={
                    formik.values.anticipatedReceiptDt
                      ? dayjs(formik.values.anticipatedReceiptDt)
                      : null
                  }
                  onChange={(date) =>
                    handleDatePickerChange('anticipatedReceiptDt', date)
                  }
                />
              </DemoContainer>
              {validateIsError(formik, 'anticipatedReceiptDt') && (
                <FormHelperText sx={{ ml: '10px' }} error>
                  {formik.errors.anticipatedReceiptDt}
                </FormHelperText>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Description"
              fullWidth
              margin="normal"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            {/* <TextField
              label="Attorney Telephone"
              fullWidth
              margin="normal"
              name="attorneyTelephone"
              value={formik.values.attorneyTelephone}
              onChange={formik.handleChange}
            /> */}
            <Field name="attorneyTelephone" formik={formik}>
              <InputMask mask="(999)999-9999" maskChar={null}>
                {(params) => (
                  <OutlinedInput
                    variant="outlined"
                    placeholder="Attorney Telephone"
                    {...params}
                    sx={{ marginTop: '1rem' }}
                  />
                )}
              </InputMask>
            </Field>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PotentialIncomeForm;
