import {
  Button,
  Stack,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  Grid,
} from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import FormGroup from '../../../components/FormGroup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from '../../../components/Field';
import { useFormik } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

function AddDomesticViolence({
  open,
  handleClose,
  onSaveDomesticViolence,
  adddomesticviolence,
  editData,
  dob,
}) {
  const validationSchema = Yup.object({
    beginDate: Yup.date()
      .max(new Date(), 'Begin Date cannot be a future date')
      .required('Please select the Begin Date'),
    endDate: Yup.date()
      .max(new Date(), 'End Date cannot be a future date')
      .required('Please select the End Date'),
  });

  const formik = useFormik({
    initialValues: {
      beginDate: editData?.effectiveBeginDt ?? '',
      endDate: editData?.effectiveEndDt ?? '',
      victimStatus: editData?.dmstcVoilenceInd ?? '',
      recordId: editData?.recordId ?? '',
    },
    enableReinitialize: true,
    validationSchema,

    onSubmit: (values, { resetForm }) => {
      if (adddomesticviolence) {
        onSaveDomesticViolence(values, 'insert');
      } else {
        onSaveDomesticViolence(values, 'update');
      }
      resetForm();
    },
  });

  const onDateChange = (date, prop) => {
    formik.setFieldValue(prop, dayjs(date).format('YYYY-MM-DD'));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      fullwidth
    >
      <DialogTitle id="scroll-dialog-title" sx={{ fontSize: 20 }}>
        {adddomesticviolence
          ? 'Add Domestic Violence'
          : 'Edit Domestic Violence'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {' '}
        <form>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Grid item xs={12}>
              <Grid>
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ mb: '1rem' }}>
                      <Field
                        required
                        name="beginDate"
                        label="Begin Date"
                        isDate
                        formik={formik}
                      >
                        <DatePicker
                          minDate={dayjs(dob)}
                          disableFuture
                          value={dayjs(formik.values.beginDate)}
                          onChange={(date) => onDateChange(date, 'beginDate')}
                        />
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="endDate"
                        label="End Date"
                        isDate
                        formik={formik}
                        required
                      >
                        <DatePicker
                          minDate={dayjs(formik.values.beginDate)}
                          disableFuture
                          requried
                          value={dayjs(formik.values.endDate)}
                          onChange={(date) => onDateChange(date, 'endDate')}
                        />
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      marginTop="0.3rem"
                      marginBottom="1rem"
                    >
                      Has the customer reported being a victim of domestic
                      violence?
                    </Typography>
                    <RadioGroup
                      {...formik.getFieldProps('victimStatus')}
                      row
                      defaultValue={''}
                    >
                      <FormControlLabel
                        value={'Y'}
                        name="victimStatus"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        name="victimStatus"
                        value={'N'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Grid>
                </FormGroup>
              </Grid>
              <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{ marginTop: '1.5rem', gap: '0.9rem' }}
              >
                <Button variant="outlined" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Save & Close
                </Button>
              </Stack>
            </Grid>
          </DialogContentText>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddDomesticViolence;
