import React, { useState, useEffect } from 'react';
import PageContainer from '../../components/PageContainer';
import { Button, Stack, Tab, Tabs } from '@mui/material';
import SelectableView from '../../components/SelectableView';
import MemberMenuButton from '../../components/SelectableView/MemberMenuButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../store/selectors/caseSelector';
import usePageNav from '../../hooks/usePageNav';
import useLookup from '../../hooks/useLookup';
import { showErrorToast, showSuccessToast } from '../../utils/services/toast';
import useLoader from '../../hooks/useLoader';
import HealthDetails from './HealthDetails';
import WbcchpDetails from './WbcchpDetails';
import { getHealthDisabilityList } from '../../utils/services/healthAndDisability/apiRequests';
import DocumentUploadFilesDialog from '../Income/DocumentUploadFilesDialog';
import SocialSecurityBenifits from './SocialSecurityBenefits';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function HealthAndDisability() {
  const [disabilitiesData, setDisabilitiesData] = useState([]);
  const { caseId, navigations } = usePageNav();
  const [showLoader, hideLoader] = useLoader();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(null);
  const [action, setAction] = useState('');
  const { lkpFlatten, getLkpMatch, getOptions, lkpRaw, islkpFailed } =
    useLookup('Health');
  const { houseMembers, fetchingMembers } = useSelector(selectHouseMembers);
  const [selectedId, setSelectedId] = useState(null);

  const handleSelect = (selected) => {
    setSelectedId(selected.individualId);
  };

  const onTabChange = (e, value) => {
    setActiveTab(value);
  };

  const handleOpenDocModal = () => {
    setDocOpen(true);
    setIsAdd(true);
  };

  const handleAddClick = () => {
    setOpen(true);
    setIsAdd(true);
    setAction('insert');
  };

  const onNext = () => {
    navigations.toMedicareDetails();
  };

  const onBack = () => {
    navigations.toDomesticViolence();
  };
  const fetchData = async (id) => {
    try {
      showLoader();
      const res = await getHealthDisabilityList(caseId, id);
      if (res.status === 200) {
        setDisabilitiesData(res.data);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (selectedId) {
      fetchData(selectedId);
    }
  }, [selectedId]);

  return (
    <PageContainer
      title="Health and Disability"
      onNext={onNext}
      onBack={onBack}
    >
      <SelectableView
        data={houseMembers}
        loading={fetchingMembers}
        loadingContent={fetchingMembers}
        getId={(params) => params.individualId}
        getName={({ personName = {} }) => {
          return `${personName.firstName} ${personName.lastName}`;
        }}
        renderMenuButton={(params, options) => {
          return <MemberMenuButton member={params} options={options} />;
        }}
        showAdd={false}
        showEdit={false}
        showDelete={false}
      >
        {(selected) => {
          handleSelect(selected);
          return (
            <div>
              <Tabs
                onChange={onTabChange}
                value={activeTab}
                variant="fullWidth"
                centered
                sx={{
                  '& .MuiButtonBase-root': {
                    color: '#6C6C6C',
                    fontWeight: '600',
                    letterSpacing: 'normal',
                    textTransform: 'capitalize',
                    padding: '12px 0px',
                    fontSize: {
                      md: '13px',
                      lg: 'inherit !important',
                    },
                  },
                  'Button.Mui-selected': {
                    color: '#000',
                    fontWeight: 700,
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#174c92',
                  },
                }}
              >
                <Tab label="Health & Disability Details" />
                <Tab label="Social Security Benefits" />
                <Tab label="WBCCHP Details" />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <Stack
                  direction="row"
                  spacing={2}
                  my={2}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >
                    Add Details
                  </Button>
                </Stack>
                <HealthDetails
                  member={selected}
                  lkpFlatten={lkpFlatten}
                  getOptions={getOptions}
                  getLkpMatch={getLkpMatch}
                  lkpRaw={lkpRaw}
                  setOpen={setOpen}
                  open={open}
                  isAdd={isAdd}
                  setIsAdd={setIsAdd}
                  caseId={caseId}
                  docOpen={docOpen}
                  setDocOpen={setDocOpen}
                  disabilitiesData={disabilitiesData}
                  fetchData={fetchData}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Stack
                  direction="row"
                  spacing={2}
                  my={2}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >
                    Add Details
                  </Button>
                </Stack>
                <WbcchpDetails
                  member={selected}
                  lkpFlatten={lkpFlatten}
                  getOptions={getOptions}
                  getLkpMatch={getLkpMatch}
                  lkpRaw={lkpRaw}
                  setOpen={setOpen}
                  open={open}
                  isAdd={isAdd}
                  setIsAdd={setIsAdd}
                  caseId={caseId}
                  docOpen={docOpen}
                  setDocOpen={setDocOpen}
                  disabilitiesData={disabilitiesData}
                  fetchData={fetchData}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Stack
                  direction="row"
                  spacing={2}
                  my={2}
                  justifyContent="space-between"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="inherit"
                    startIcon={<UploadFileIcon />}
                    onClick={handleOpenDocModal}
                  >
                    Upload files
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddClick}
                  >
                    Add Details
                  </Button>
                </Stack>
                <SocialSecurityBenifits
                  member={selected}
                  lkpFlatten={lkpFlatten}
                  getOptions={getOptions}
                  getLkpMatch={getLkpMatch}
                  lkpRaw={lkpRaw}
                  setOpen={setOpen}
                  open={open}
                  isAdd={isAdd}
                  setIsAdd={setIsAdd}
                  setAction={setAction}
                  action={action}
                  caseId={caseId}
                  docOpen={docOpen}
                  setDocOpen={setDocOpen}
                  disabilitiesData={disabilitiesData}
                  fetchData={fetchData}
                />
              </TabPanel>
              <DocumentUploadFilesDialog
                member={selected}
                lkpFlatten={lkpFlatten}
                getOptions={getOptions}
                getLkpMatch={getLkpMatch}
                lkpRaw={lkpRaw}
                setOpen={setOpen}
                open={open}
                isAdd={isAdd}
                setIsAdd={setIsAdd}
                caseId={caseId}
                docOpen={docOpen}
                setDocOpen={setDocOpen}
              />
            </div>
          );
        }}
      </SelectableView>
    </PageContainer>
  );
}

export default HealthAndDisability;
