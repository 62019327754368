import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import Icon from '../../../components/Icon';
import { programIcons } from '../../../constants/programConstants';
import {
  find,
  findIndex,
  get,
  includes,
  isEqual,
  map,
  noop,
  reject,
} from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Dropdown from '../../../components/Dropdown';
import Avatar from '../../../components/Avatar';
import TabularView from '../../../components/TabularView';
import SnapQuestionnaire from '../SnapQuestionnaire';
import { getFullName, yNtoBool } from '../../../utils/helpers';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { selectHouseMembers } from '../../../store/selectors/caseSelector';
import useLoader from '../../../hooks/useLoader';
import { saveProgramRequest } from '../../../utils/services/programRequest/apiRequests';
import { showErrorToast } from '../../../utils/services/toast';

const isSnapProgram = (programName) => programName === 'FS';
const isEAProgram = (programName) => programName === 'EA';
const isMadicareProgram = (programName) => programName === 'MA';

const DEFAULT_STATUS_REASONS = {
  dirtyStatus: '',
  noticeTypeCode: '',
  noticeTypeText: '',
  reasonCode: '',
  reasonText: '',
};

const DEFAULT_PG_DETAILS = {
  // programIndividualId: 0,
  // programId: 0,
  programStatus: '',
  requestedDate: null,
  requestIndicator: '',
  programSubType: '',
  specialRequestType: '',
  msnapInd: '',
  eidDetails: [],
  dirtyStatus: 'insert',
  statusReasons: [
    {
      dirtyStatus: '',
      noticeTypeCode: '',
      noticeTypeText: '',
      reasonCode: null,
      reasonText: '',
    },
  ],
};

function ReqProgramDetails({
  program,
  lkpRaw,
  lkpMatch,
  caseId,
  onSaveSuccess,
  getOptions,
}) {
  const { houseMembers } = useSelector(selectHouseMembers);
  const [showLoader, hideLoader] = useLoader();

  const individualList = useMemo(() => {
    return map(houseMembers, (indv) => {
      const { individualId, personName, headOfHouseholdYN, dob } = indv;
      return {
        individualId,
        personName,
        headOfHouseholdYN,
        dob,
      };
    });
  }, [houseMembers]);

  const {
    programName = '',
    individuals = [],
    signatureDetails = [],
    msnapInd = 'N',
    expeditedFoodSupliment = {},
    expeditedYN = 'N',
    tfsYN = 'N',
    specialRequestType = '',
    applicationDate = null,
  } = program;

  const {
    monthlyRent = '',
    monthlyRentAmount = {},
    grossMonthlyIncomeResources = '',
    grossMonthlyIncomeAmount = {},
    resourcesAmount = {},
    migrantSeasonal = '',
  } = useMemo(() => {
    if (programName === 'FS') {
      return expeditedFoodSupliment;
    } else {
      return {};
    }
  }, [expeditedFoodSupliment, programName]);

  const validationSchema = yup.object({
    individuals: yup.array(
      yup.object({
        specialRequestType: yup.string().when('requestIndicator', {
          is: (requestIndicator) =>
            requestIndicator === 'Y' && isEAProgram(programName),
          then: () => yup.string().required(),
          otherwise: () => yup.string().notRequired(),
        }),
      })
    ),
    monthlyRentAmount: yup.number().when(['programName', 'monthlyRent'], {
      is: (programName, monthlyRent) =>
        isSnapProgram(programName) && yNtoBool(monthlyRent),
      then: () => yup.number().moreThan(0).required(),
      otherwise: () => yup.number().notRequired(),
    }),
    grossMonthlyIncomeAmount: yup
      .number()
      .when(['programName', 'grossMonthlyIncomeResources'], {
        is: (programName, grossMonthlyIncomeResources) =>
          isSnapProgram(programName) && yNtoBool(grossMonthlyIncomeResources),
        then: () => yup.number().moreThan(0).required(),
        otherwise: () => yup.number().notRequired(),
      }),
    resourcesAmount: yup
      .number()
      .when(['programName', 'grossMonthlyIncomeResources'], {
        is: (programName, grossMonthlyIncomeResources) =>
          isSnapProgram(programName) && yNtoBool(grossMonthlyIncomeResources),
        then: () => yup.number().moreThan(0).required(),
        otherwise: () => yup.number().notRequired(),
      }),
  });

  const deleteProgram = async (data) => {
    const { name, id, ...rest } = program;

    const indvWithDirtyStatus = map(program.individuals, (individual) => {
      return {
        ...individual,
        dirtyStatus: 'delete',
      };
    });

    const payload = {
      ...rest,
      dirtyStatus: 'delete',
      individuals: indvWithDirtyStatus,
    };

    try {
      showLoader();
      const res = await saveProgramRequest([payload], caseId);
      if (res?.status === 200) {
        const pgms = res.data;
        const prgrams = map(pgms, ({ programName }) => programName);
        onSaveSuccess(prgrams);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const savePrograms = async (data) => {
    try {
      showLoader();
      const res = await saveProgramRequest([data], caseId);
      if (res?.status === 200) {
        const pgms = res.data;
        const prgrams = map(pgms, ({ programName }) => programName);
        onSaveSuccess(prgrams);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      hideLoader();
    }
  };

  const formik = useFormik({
    initialValues: {
      programName,
      applicationDate,
      individuals,
      signatureDetails,
      //   expeditedFoodSupliment 👇 ,
      monthlyRent,
      monthlyRentAmount: monthlyRentAmount?.amount,
      grossMonthlyIncomeResources,
      grossMonthlyIncomeAmount: grossMonthlyIncomeAmount?.amount,
      resourcesAmount: resourcesAmount?.amount,
      migrantSeasonal,
      msnapInd,
      expeditedYN,
      tfsYN,
      specialRequestType,
    },
    validationSchema,
    onSubmit: (values) => {
      const indvWithDirtyStatus = map(values.individuals, (individual) => {
        const { dirtyStatus, individualId } = individual;
        if (dirtyStatus === 'insert') {
          return individual;
        } else {
          const oldInvidual = find(individuals, { individualId });
          const isDirty = !isEqual(oldInvidual, individual);

          if (isDirty) {
            return {
              ...individual,
              dirtyStatus: 'update',
            };
          } else {
            return individual;
          }
        }
      });

      const {
        monthlyRent = 'N',
        monthlyRentAmount = 0,
        grossMonthlyIncomeResources = 'N',
        grossMonthlyIncomeAmount = 0,
        resourcesAmount = 0,
        migrantSeasonal = 'N',
        programName,
        signatureDetails,
        msnapInd,
        tfsYN,
        applicationDate,
      } = values;

      const expeditedFoodSuplimentObj = {
        expeditedFoodSupliment: {
          monthlyRent,
          monthlyRentAmount: {
            amount: monthlyRentAmount,
          },
          grossMonthlyIncomeResources,
          grossMonthlyIncomeAmount: {
            amount: grossMonthlyIncomeAmount,
          },
          resourcesAmount: {
            amount: resourcesAmount,
          },
          migrantSeasonal,
        },
        msnapInd,
        tfsYN,
      };

      const payload = {
        programName,
        signatureDetails,
        applicationDate,
        dirtyStatus: 'update',
        individuals: indvWithDirtyStatus,
        ...(programName === 'FS' ? { ...expeditedFoodSuplimentObj } : {}),
      };

      savePrograms(payload);
    },
  });

  const statusOptions = useMemo(() => {
    return map(get(lkpRaw, 'ReasonCode', []), ({ lookupLongVal, lkpDesc }) => {
      const label = `${lkpDesc} - ${lookupLongVal}`;
      return {
        label,
        value: lkpDesc,
      };
    });
  }, [lkpRaw]);

  const handleChange = (individualId, prop, value) => {
    const resetProps =
      prop === 'requestIndicator' && value === 'N'
        ? { statusReasons: [DEFAULT_STATUS_REASONS], specialRequestType: '' }
        : {};

    const index = findIndex(formik.values.individuals, { individualId });

    let newIndv;
    if (index === -1) {
      const filteredIndv = reject(formik.values.individuals, { individualId });
      newIndv = [
        ...filteredIndv,
        {
          ...DEFAULT_PG_DETAILS,
          individualId,
          [prop]: value,
          dirtyStatus: 'insert',
        },
      ];
    } else {
      newIndv = Object.assign([], formik.values.individuals, {
        [index]: {
          ...formik.values.individuals[index],
          [prop]: value,
          ...resetProps,
        },
      });
    }
    const touchedIndex = index === -1 ? 0 : index;
    const isTouched = get(
      formik.touched,
      `individuals[${touchedIndex}].${prop}`,
      false
    );
    if (!isTouched) {
      formik.setFieldTouched(`individuals[${touchedIndex}].${prop}`);
    }

    formik.setFieldValue('individuals', newIndv);
  };

  const getProgramIndividual = (individualId) => {
    const { individuals } = formik.values;
    const thisIndividual = find(individuals, { individualId }) ?? {};
    return thisIndividual;
  };

  const spacialRequestOptions = useMemo(() => {
    if (isEAProgram(programName)) {
      return getOptions('SpecialRequest');
    }

    if (isMadicareProgram(programName)) {
      return [
        {
          label: 'Family Assistance',
          value: 'EAF',
        },
        {
          label: 'Burial Assistance',
          value: 'BAS',
        },
      ];
    }
    return [];
  }, [getOptions, programName]);

  const hideSpecialRequest = useMemo(() => {
    return !includes(['EA', 'MA'], programName);
  }, [programName]);

  const columns = [
    {
      headerName: 'Member Name',
      renderCell({ personName }) {
        const { firstName = '', lastName = '' } = personName ?? {};
        const name = getFullName(firstName, lastName);
        return (
          <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
            <Avatar name={name} />
            <Typography variant="subtitle1">{name}</Typography>
          </Stack>
        );
      },
    },
    {
      headerName: 'Requesting Assistance',
      renderCell({ individualId }, index) {
        const thisIndividual = getProgramIndividual(individualId);
        const { requestIndicator = '' } = thisIndividual;

        const onChange = (_e, checked) => {
          const value = checked ? 'Y' : 'N';
          handleChange(individualId, 'requestIndicator', value);
        };

        return (
          <Box style={{ textAlign: 'left' }}>
            <Checkbox
              checked={requestIndicator === 'Y'}
              onChange={onChange}
              ml="1rem"
            />
          </Box>
        );
      },
    },
    {
      hide: hideSpecialRequest,
      renderHeader: () => (
        <Typography variant="h5">
          Special request<span style={{ color: 'red' }}>*</span>
        </Typography>
      ),
      renderCell({ individualId }) {
        const thisIndividual = getProgramIndividual(individualId);
        const { specialRequestType = '', requestIndicator = '' } =
          thisIndividual;
        const thisIndex = findIndex(formik.values.individuals, {
          individualId,
        });
        const error =
          formik.errors.individuals?.[thisIndex]?.specialRequestType;

        const onChange = (e) => {
          const value = e.target.value;
          handleChange(individualId, 'specialRequestType', value);
        };
        return (
          <Box sx={{ width: '13rem' }}>
            <Dropdown
              error={error}
              options={spacialRequestOptions}
              value={specialRequestType}
              onChange={onChange}
              disabled={!yNtoBool(requestIndicator)}
            />
          </Box>
        );
      },
    },
    {
      headerName: 'Request Date',
      renderCell({ individualId }, index) {
        const onChange = (date) => {
          const formattedDate = dayjs(date).format('YYYY-MM-DD');
          handleChange(individualId, 'requestedDate', formattedDate);
        };

        const thisIndividual = getProgramIndividual(individualId);
        const { requestedDate = '' } = thisIndividual;
        return (
          <Box sx={{ width: '13rem' }}>
            <DatePicker
              value={requestedDate ? dayjs(requestedDate) : null}
              onChange={onChange}
            />
          </Box>
        );
      },
    },
    {
      headerName: '	Close/Denial Reason',
      renderCell({ individualId }, index) {
        const onChange = (e) => {
          const reasonCode = e.target.value;
          const newStatusReasons = [
            {
              dirtyStatus: '',
              noticeTypeCode: '',
              noticeTypeText: '',
              reasonCode,
              reasonText: '',
            },
          ];
          handleChange(individualId, 'statusReasons', newStatusReasons);
        };

        const thisIndividual = getProgramIndividual(individualId);
        const { statusReasons, requestIndicator } = thisIndividual;

        const reasonCode = statusReasons?.[0]?.reasonCode ?? '';
        return (
          <Box sx={{ width: '13rem' }}>
            <Dropdown
              options={statusOptions}
              value={reasonCode}
              onChange={onChange}
              disabled={!yNtoBool(requestIndicator)}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Stack direction="row">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Icon width="2rem" height="2rem" name={programIcons[programName]} />
          <Typography variant="h4">{program.name}</Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{ ml: 'auto', alignItems: 'center' }}
          spacing={1}
        >
          {program.name != 'Cash Assistance' && (
            <FormControlLabel
              onChange={(e) => {
                const checked = e.target.checked;
                formik.setFieldValue('msnapInd', checked ? 'Y' : 'N');
              }}
              checked={formik.values.msnapInd === 'Y'}
              control={<Checkbox />}
              // disabled={}
              label="MSNAP"
            />
          )}
          <Button
            onClick={deleteProgram}
            variant="contained"
            size="small"
            color="error"
          >
            Delete
          </Button>
          {programName === 'FS' && (
            <Button size="small" variant="contained">
              EDRS
            </Button>
          )}
        </Stack>
      </Stack>
      <Box sx={{ border: '2px solid #f5f5f5' }}>
        <TabularView
          headerComponent={{ variant: 'h5' }}
          sx={{
            table: {
              // width: 'auto',
              // border: '2px solid #f5f5f5',
            },
            thead: {
              backgroundColor: 'var(--grey-200)',
              borderRadius: '0.25rem',
            },
            td: {
              border: 0,
              p: '1rem',
            },
            th: {
              p: '1rem',
              border: 0,
            },
          }}
          // rootData={p}
          data={formik.values.individuals}
          columns={columns}
        />
        <Stack sx={{ px: '1rem', alignItems: 'center' }} direction="row">
          {programName === 'FS' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                onChange={(e) => {
                  const checked = e.target.checked;
                  formik.setFieldValue('tfsYN', checked ? 'Y' : 'N');
                }}
                checked={formik.values.tfsYN === 'Y'}
                control={<Checkbox />}
                // disabled={}
                label="Do you want to break your TFS benefits?"
              />
            </Box>
          )}
          <Box sx={{ ml: 'auto' }}>
            <Typography color="primary" variant="body2">
              That case was reviewed for expedited determination
            </Typography>
          </Box>
        </Stack>
      </Box>
      {programName === 'FS' && <SnapQuestionnaire formik={formik} />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 'auto !important',
          py: '1rem',
        }}
        direction="row"
      >
        <Button
          onClick={formik.handleSubmit}
          disabled={!formik.dirty || !formik.isValid}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
}

ReqProgramDetails.propTypes = {
  program: PropTypes.shape({
    programName: PropTypes.string,
    individuals: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onSaveSuccess: PropTypes.func,
};

ReqProgramDetails.defaultProps = {
  program: {
    programName: '',
    individuals: [],
  },
  onSaveSuccess: noop,
};

export default ReqProgramDetails;
